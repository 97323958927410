import React from 'react'

const SearchPageFooter = () => {
    return (
        <div><footer className="footer p-10 bg-base-200 text-base-content">
           
        </footer>
            <footer className="footer px-10 py-4 border-t bg-base-200 text-base-content border-base-300">

            </footer></div>
    )
}

export default SearchPageFooter
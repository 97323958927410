import React from 'react'
import { useParams } from 'react-router-dom'
import { placesStore10 } from '../store/Venue10'
import { placesStore4 } from '../store/Venue4'
import { placesStore13 } from '../store/Venue13'
import { placesStore20 } from '../store/Venue20'
import { placesStore15 } from '../store/Venue15'
import { placesStore17 } from '../store/Venue17'
import { placesStore21 } from '../store/Venue21'
import { placesStore22 } from '../store/Venue22'
import { placesStore26 } from '../store/Venue26'
import { placesStore30 } from '../store/Venue30'
import { placesStore32 } from '../store/Venue32'
import { placesStore33 } from '../store/Venue33'
import { placesStore34 } from '../store/Venue34'
import { placesStore35 } from '../store/Venue35'
import "../styles/SinglePage.css"
import { useState } from 'react';
import "../styles/Tabs.css";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';


// Merge all places stores into a single array
const allPlaces = [
    ...placesStore10,
    ...placesStore4,
    ...placesStore13,
    ...placesStore20,
    ...placesStore15,
    ...placesStore17,
    ...placesStore21,
    ...placesStore22,
    ...placesStore26,
    ...placesStore30,
    ...placesStore32,
    ...placesStore33,
    ...placesStore34,
    ...placesStore35
];

const SinglePage = () => {

    const [startDate, setStartDate] = useState(null);

    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const [fullName, setFullName] = useState('');

    const [selectedNumber, setSelectedNumber] = useState(2);

    const handleNumberChange = (e) => {
        setSelectedNumber(parseInt(e.target.value, 10));
    };

    const generateNumberOptions = (max) => {
        const options = [];
        for (let i = 1; i <= max; i++) {
            options.push(<option key={i} value={i}>{i}</option>);
        }
        return options;
    };


    const handleFullNameChange = (e) => {
        setFullName(e.target.value);
    };

    const params = useParams();
    const { id } = params;

    const placeClicked = allPlaces.find((item) => item.venueId === parseInt(id, 10));

    const GoHome = () => {
        const url = `/`;

        // Open a new webpage
        window.open(url);
    };

    const switchImagesHandler = () => {
        // Increment the image index, and reset to 0 if it exceeds the total number of images
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % Math.min(4, placeClicked.medias.length));
    };


    // Dummy data for hotel information
    const hotelInfo = {
        name: placeClicked.title,
        description: placeClicked.description,
        location: placeClicked.city,
    };

    // Validate if placeClicked.personalities is defined and has at least 1 element at index 0
    const firstPersonality = placeClicked.personalities && placeClicked.personalities.length > 0
        ? placeClicked.personalities[0]
        : null;

    // Validate if placeClicked.personalities is defined and has at least 2 elements
    const secondPersonality = placeClicked.personalities && placeClicked.personalities.length > 1
        ? placeClicked.personalities[1]
        : null;

    // Validate if placeClicked.personalities is defined and has at least 3 elements
    const thirdPersonality = placeClicked.personalities && placeClicked.personalities.length > 2
        ? placeClicked.personalities[2]
        : null;

    // Create an array of personalities
    const personalities = [firstPersonality, secondPersonality, thirdPersonality].filter(Boolean).map((personality, index) => ({
        tagName: personality.tagName,
        // Add other properties if needed
        key: index,
    }));

    // Validate if placeClicked.tags is defined and has at least 1 element at index 0
    const firstTag = placeClicked.tags && placeClicked.tags.length > 0
        ? placeClicked.tags[0]
        : null;

    // Validate if placeClicked.tags is defined and has at least 2 elements
    const secondTag = placeClicked.tags && placeClicked.tags.length > 1
        ? placeClicked.tags[1]
        : null;

    // Validate if placeClicked.tags is defined and has at least 3 elements
    const thirdTag = placeClicked.tags && placeClicked.tags.length > 2
        ? placeClicked.tags[2]
        : null;

    // Validate if placeClicked.tags is defined and has at least 4 elements
    const fourthTag = placeClicked.tags && placeClicked.tags.length > 3
        ? placeClicked.tags[3]
        : null;

    // Validate if placeClicked.tags is defined and has at least 5 elements
    const fifthTag = placeClicked.tags && placeClicked.tags.length > 4
        ? placeClicked.tags[4]
        : null;

    // Validate if placeClicked.tags is defined and has at least 6 elements
    const sixthTag = placeClicked.tags && placeClicked.tags.length > 5
        ? placeClicked.tags[5]
        : null;

    // Validate if placeClicked.tags is defined and has at least 7 elements
    const seventhTag = placeClicked.tags && placeClicked.tags.length > 6
        ? placeClicked.tags[6]
        : null;

    // Create an array of tags
    const tags = [firstTag, secondTag, thirdTag, fourthTag, fifthTag, sixthTag, seventhTag].filter(Boolean).map((tag, index) => ({
        tagName: tag.tagName,
        // Add other properties if needed
        key: index,
    }));

    const sendWhatsApp = () => {
        // Your additional variables
        const hotel = placeClicked.title;
        // Directly use the selectedNumber state
        const numberOfGuests = selectedNumber;
        // Format startDate to DD/MM/YYYY
        const formattedStartDate = format(startDate, 'dd/MM/yyyy');

        // Create the message with additional variables
        const message = encodeURIComponent(`Booking request from TravelStart+\nHotel: ${hotel} \nStart Date: ${formattedStartDate}\nFull Name: ${fullName}\nGuests: ${numberOfGuests}`);

        const url = `https://wa.me/447360536627?text=${message}`;

        // Open a new webpage
        window.open(url, '_blank');
    };


    const getPriceSymbol = (price) => {
        switch (price) {
            case 1:
                return '$';
            case 2:
                return '$$';
            case 3:
                return '$$$';
            default:
                return ''; // Handle other cases if needed
        }
    };


    return (

        <div>
            <div class="bg-white dark:bg-white py-8 text-black">
                <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">

                    <div class="flex flex-col md:flex-row">
                        <div className="px-2">
                            {/* Breadcrumb navigation */}
                            <div className="flex items-center text-sm mb-2">
                                <span className="text-gray-500 dark:text-gray-400" onClick={() => GoHome()}>Home</span>
                                <span className="mx-2">{'>>'}</span>
                                <span className="text-teal-800 dark:text-teal-800">{hotelInfo.name}</span>
                            </div>
                        </div>
                    </div>


                    <div class="flex flex-col md:flex-row">

                        <div class="md:flex-1 px-4 rounded-lg overflow-hidden pt-5 mt-5 pb-5">
                            <h1 className="text-3xl font-bold text-teal-800 dark:text-teal-800 mb-4 text-center">{hotelInfo.name}</h1>

                            <div className="relative rounded-lg bg-gray-300 dark:bg-gray-700">
                                <img
                                    className="w-full h-auto rounded-lg"
                                    src={placeClicked.medias && placeClicked.medias.length > 0 ? placeClicked.medias[currentImageIndex].url_Low : ''}
                                    alt={placeClicked.title}
                                />
                                <button
                                    className="absolute bottom-4 right-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                    onClick={switchImagesHandler}
                                >
                                    Switch Images
                                </button>
                            </div>

                        </div>




                        <div class="md:flex-1 px-4 rounded-lg overflow-hidden pt-5 mt-5 pb-5">
                            <h2 class="text-3xl font-bold text-teal-800 dark:text-teal-800 mb-4 text-center">Booking Details</h2>

                            <div class="md:flex-1 px-4">
                                <div class="mb-6">
                                    <label for="startDate" class="block mb-2 text-sm font-medium text-teal-900 dark:text-teal">Start Date</label>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        minDate={new Date()} // Set minDate to today
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="Select a date"
                                        className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                                    />
                                </div>
                                <div class="mb-6">
                                    <label for="guests" class="block mb-2 text-sm font-medium text-teal-900 dark:text-teal">Guests</label>
                                    <select
                                        id="numberPicker"
                                        value={selectedNumber}
                                        onChange={handleNumberChange}
                                        className='bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                                    >
                                        {generateNumberOptions(10)}
                                    </select>
                                </div>
                                <div class="mb-6">
                                    <label for="fullName" class="block mb-2 text-sm font-medium text-teal-900 dark:text-teal">Full Name</label>
                                    <input
                                        type="text"
                                        id="fullName"
                                        value={fullName}
                                        onChange={handleFullNameChange}
                                        placeholder="Enter full name"
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    />
                                </div>
                                <div class="mb-6">
                                    <button
                                        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                        onClick={() => sendWhatsApp()}
                                    >
                                        Send Enquiry
                                    </button>
                                </div>
                            </div>
                        </div>


                        <div class="md:flex-1 px-4 rounded-lg overflow-hidden pt-5 mt-5 pb-5">
                            <h2 class="text-3xl font-bold text-teal-800 dark:text-teal-800 mb-4 text-center">More info</h2>

                            <div class="md:flex-1 px-4">
                                <div class="flex mb-4">
                                    <div class="mr-4">
                                        <span class="font-bold text-teal-300 dark:text-teal-300">Location</span>
                                    </div>
                                    <div class="ml-auto">
                                        <span class="font-bold text-teal-800 dark:text-teal-800">{hotelInfo.location}</span>

                                    </div>
                                </div>

                                <div class="flex mb-4">
                                    <div class="mr-4">
                                        <span class="font-bold text-teal-300 dark:text-teal-300">Price</span>
                                    </div>
                                    <div class="ml-auto">
                                        <span class="font-bold text-teal-800 dark:text-teal-800">{getPriceSymbol(placeClicked.dollar)}</span>
                                    </div>
                                </div>

                                <div class="flex mb-4">
                                    <div class="mr-4">
                                        <span class="font-bold text-teal-300 dark:text-teal-300">Availability</span>
                                    </div>
                                    <div class="ml-auto">
                                        <span class="font-bold text-teal-800 dark:text-teal-800">Available</span>
                                    </div>
                                </div>

                                <div class="flex mb-4 flex-wrap justify-center">
                                    {personalities.map((personality, index) => (
                                        <button type="button" class="text-white bg-blue-400 dark:bg-blue-500 cursor-not-allowed font-medium rounded-lg text-xs px-5 mr-5  py-1  mb-2 text-center" disabled>{personality.tagName}</button>

                                    ))}
                                </div>

                                <div class="flex mb-4 flex-wrap justify-center">
                                    {tags.map((tag, index) => (

                                        <button type="button" class="text-white bg-green-400 dark:bg-green-500 cursor-not-allowed font-medium rounded-lg text-xs px-5 mr-5  py-1  mb-2 text-center" disabled>{tag.tagName}</button>

                                    ))}
                                </div>


                                <div>
                                    <p class="text-teal-600 dark:text-teal-800 text-sm mt-2">
                                        {hotelInfo.description}
                                    </p>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>



        </div>
    )
}


export default SinglePage;
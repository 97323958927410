// 20240123100509
// https://app.pks-list.com/api/Venue/v1/getlist/35

export const placesStore22=
[
  {
    "venueId": 3003,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Cheval Blanc Paris",
    "description": "Under the Pont-Neuf flows the Seine. In the heart of the City of Light, a few steps away from the Louvre and the Marais, in front of the Left Bank, rises Cheval Blanc Paris with majesty.\n\nWith only 72 rooms and suites, Cheval Blanc Paris is a confidential haven where lovers, families and friends meet. Seize the day overlooking the scenic panorama, bite in a warm croissant just out of the oven, sink into the foam of a delicately scented bath, capture the soul of Paris from your winter garden.\n\nA bustling place to live, an unmissable rendez-vous for Parisians, a haven of serenity where travellers and their families feel at home, Cheval Blanc holds the key to the heart of Paris.",
    "brand": "Cheval Blanc",
    "blockQuote": "The Dior Spa Cheval Blanc with its spectacular 30-metre swimming pool is a must visit.",
    "emailAddress": "info.paris@chevalblanc.com",
    "website": "chevalblanc.com/en/maison/paris/",
    "bookingURL": "https://reservation.chevalblanc.com/?_ga=2.36560608.1930030372.1662456799-589211743.1662456799&adult=2&agencyid=96027890&arrive=2022-09-06&chain=23061&child=0&currency=EUR&depart=2022-09-07&hotel=9732&level=hotel&locale=en-US&rooms=1",
    "redirectNotes": "Cheval Blanc Paris' reservations system",
    "phoneNumber": "+33 1 40 28 00 00",
    "addressOne": "8",
    "addressTwo": "Quai du Louvre",
    "city": "Paris",
    "county": "",
    "region": "",
    "country": "France",
    "rooms": 72,
    "dollar": 3,
    "updated": "2023-12-23T09:25:31.63",
    "created": "2020-11-23T17:16:15.673",
    "medias": [
      {
        "mediaId": 54771,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/3e67930e-a1f3-49d1-85d9-baccb1334265/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3e67930e-a1f3-49d1-85d9-baccb1334265/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3e67930e-a1f3-49d1-85d9-baccb1334265/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 57679,
        "title": "Cheval Blanc Paris Hotel Is LVMH's Newest Bauble at $1,500 a Night ...",
        "url": "https://pkslist.azureedge.net/media/images/1/b863af2a-fddf-45f5-9936-f0c7a5eac386/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b863af2a-fddf-45f5-9936-f0c7a5eac386/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b863af2a-fddf-45f5-9936-f0c7a5eac386/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 54780,
        "title": "CHEVAL BLANC PARIS - Updated 2022 Prices & Hotel Reviews (France)",
        "url": "https://pkslist.azureedge.net/media/images/1/790ea709-5448-49ce-994e-7bbc9f9cbc35/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/790ea709-5448-49ce-994e-7bbc9f9cbc35/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/790ea709-5448-49ce-994e-7bbc9f9cbc35/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 54782,
        "title": "Review: Cheval Blanc Paris",
        "url": "https://pkslist.azureedge.net/media/images/1/13dacb49-8db3-4f2a-aed2-7733c0a1d5a6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/13dacb49-8db3-4f2a-aed2-7733c0a1d5a6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/13dacb49-8db3-4f2a-aed2-7733c0a1d5a6/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 54775,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a447ffe9-9d2b-4212-8a34-142327d2eaf3/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a447ffe9-9d2b-4212-8a34-142327d2eaf3/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a447ffe9-9d2b-4212-8a34-142327d2eaf3/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 54773,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/4ec67976-2f13-4ee5-9558-8fcf9efb6e0b/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4ec67976-2f13-4ee5-9558-8fcf9efb6e0b/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4ec67976-2f13-4ee5-9558-8fcf9efb6e0b/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 57677,
        "title": "Review: Cheval Blanc Paris - One Mile at a Time",
        "url": "https://pkslist.azureedge.net/media/images/1/f8022664-8ea2-4bfc-8718-2a326718c4ca/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f8022664-8ea2-4bfc-8718-2a326718c4ca/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f8022664-8ea2-4bfc-8718-2a326718c4ca/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 54777,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/9cc7e1a6-3306-4919-8668-ef8910a95784/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9cc7e1a6-3306-4919-8668-ef8910a95784/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9cc7e1a6-3306-4919-8668-ef8910a95784/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 54776,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c406055b-7010-4453-b07d-3ede33c6f4ee/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c406055b-7010-4453-b07d-3ede33c6f4ee/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c406055b-7010-4453-b07d-3ede33c6f4ee/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 54781,
        "title": "Le Cheval Blanc Paris, La Samaritaine's luxury hotel opens ...",
        "url": "https://pkslist.azureedge.net/media/images/1/2a1c9769-abeb-498e-83fa-9b4b21d9e0fe/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2a1c9769-abeb-498e-83fa-9b4b21d9e0fe/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2a1c9769-abeb-498e-83fa-9b4b21d9e0fe/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 54783,
        "title": "Le Cheval Blanc Paris, La Samaritaine's luxury hotel opens ...",
        "url": "https://pkslist.azureedge.net/media/images/1/13688df1-33f6-45ae-8c9d-ef6791e1b9c6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/13688df1-33f6-45ae-8c9d-ef6791e1b9c6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/13688df1-33f6-45ae-8c9d-ef6791e1b9c6/256x192.jpg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2789,
      "latitude": 48.8586507,
      "longitude": 2.341837200000001,
      "parentGeographicAreaId": 0,
      "title": "8 Quai du Louvre, 75001 Paris, France",
      "updated": null,
      "created": "2020-11-23T17:24:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 51,
        "tagName": "Style Icon",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 155,
        "tagName": "Art",
        "tagType": 2
      },
      {
        "tagId": 216,
        "tagName": "Museum",
        "tagType": 2
      },
      {
        "tagId": 222,
        "tagName": "Michelin Star dining",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 332,
        "tagName": "Fashion",
        "tagType": 2
      },
      {
        "tagId": 404,
        "tagName": "Sightseeing",
        "tagType": 2
      },
      {
        "tagId": 466,
        "tagName": "Winter Garden",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 767,
        "venueCategoryId": 35,
        "venueId": 3003,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 828,
        "venueCategoryId": 22,
        "venueId": 3003,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 22,
          "title": "Paris Top 10 ",
          "region": "Europe",
          "viewOrder": 9,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1253,
    "venueCategoryId": 20,
    "status": 1,
    "title": "Hotel de Crillon, A Rosewood Hotel",
    "description": "Hotel de Crillon, A Rosewood Hotel, is more than just one of the world’s most iconic hotels. It is a timeless Parisian landmark. Dating back to the 18th century, this historic treasure reopened in 2017 as a luxury palace hotel for 21st century travelers. Commissioned by King Louis XV in 1758 and built by Ange-Jacques Gabriel, the greatest architect of his time, it would later come to be the personal residence of the family of the Count of Crillon before becoming a palace hotel in 1909. Over the years, this legendary palace has captivated generations of guests including royalty, heads of state and luminaries drawn to the City of Light. Located at 10 Place de la Concorde in the heart of Paris, Hotel de Crillon’s past provides a rich backdrop to its modern-day story.",
    "brand": "Rosewood",
    "blockQuote": "For the ultimate Parisian experience, stay in the Karl Lagerfeld-designed Grand Apartments.",
    "emailAddress": "crillon@rosewoodhotels.com",
    "website": "rosewoodhotels.com/en/hotel-de-crillon",
    "bookingURL": "https://reservations.rosewoodhotels.com/?adult=1&agencyid=96027890&arrive=2021-06-08&chain=5159&child=0&config=gtm&currency=EUR&depart=2021-06-09&filter=&hotel=75077&level=hotel&locale=en-US&rooms=1",
    "redirectNotes": "Hotel de Crillon's reservations system",
    "phoneNumber": "+33 1 44 71 15 00",
    "addressOne": "10",
    "addressTwo": "Place de la Concorde",
    "city": "Paris",
    "county": "",
    "region": "",
    "country": "France",
    "rooms": 124,
    "dollar": 3,
    "updated": "2021-06-08T14:14:47.063",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 36123,
        "title": "Hotel Hôtel de Crillon, A Rosewood Hotel, Paris - trivago.com",
        "url": "https://pkslist.azureedge.net/media/images/1/7a61c179-90a2-4096-b2cd-7f828b01a80c/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7a61c179-90a2-4096-b2cd-7f828b01a80c/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7a61c179-90a2-4096-b2cd-7f828b01a80c/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 36120,
        "title": "Hotel de Crillon, Paris, France - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/2db79148-675c-4cae-80d4-204102f899a2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2db79148-675c-4cae-80d4-204102f899a2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2db79148-675c-4cae-80d4-204102f899a2/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 13016,
        "title": "Hôtel de Crillon Paris: New Renovation Photos - Vogue",
        "url": "https://pkslist.azureedge.net/media/images/1/24915e31-86cc-4441-a128-9339c5506ec4/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/24915e31-86cc-4441-a128-9339c5506ec4/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/24915e31-86cc-4441-a128-9339c5506ec4/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 36119,
        "title": "Hôtel de Crillon A Rosewood Hotel in Paris | Hotel Rates & Reviews ...",
        "url": "https://pkslist.azureedge.net/media/images/1/64a7fcab-a800-4127-95fb-dcb6b1985dc9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/64a7fcab-a800-4127-95fb-dcb6b1985dc9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/64a7fcab-a800-4127-95fb-dcb6b1985dc9/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 36122,
        "title": "Hôtel de Crillon: Inside The Parisian Hotel's US$300 Million ...",
        "url": "https://pkslist.azureedge.net/media/images/1/11b7c600-99ac-4950-8da5-fe28f6a9a598/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/11b7c600-99ac-4950-8da5-fe28f6a9a598/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/11b7c600-99ac-4950-8da5-fe28f6a9a598/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 36118,
        "title": "Hôtel de Crillon A Rosewood Hotel (Paris, France) | Jetsetter",
        "url": "https://pkslist.azureedge.net/media/images/1/23a05cc3-06c3-48ce-b7c6-06c3ece4fda0/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/23a05cc3-06c3-48ce-b7c6-06c3ece4fda0/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/23a05cc3-06c3-48ce-b7c6-06c3ece4fda0/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 36124,
        "title": "Hôtel De Crillon",
        "url": "https://pkslist.azureedge.net/media/images/1/61ecc260-ce96-4bb6-8204-34d975153db8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/61ecc260-ce96-4bb6-8204-34d975153db8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/61ecc260-ce96-4bb6-8204-34d975153db8/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 13035,
        "title": "Hotel de Crillon reopens in Paris after 200 million-dollars ...",
        "url": "https://pkslist.azureedge.net/media/images/1/946cff78-01ff-4b0a-b2bc-82969363554a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/946cff78-01ff-4b0a-b2bc-82969363554a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/946cff78-01ff-4b0a-b2bc-82969363554a/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 13037,
        "title": "Inside the Renovated Hôtel de Crillon - The basement-level pool ...",
        "url": "https://pkslist.azureedge.net/media/images/1/8658f867-70f0-47a3-b1ab-c33515fe8340/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8658f867-70f0-47a3-b1ab-c33515fe8340/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8658f867-70f0-47a3-b1ab-c33515fe8340/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1077,
      "latitude": 48.8676155,
      "longitude": 2.321403700000019,
      "parentGeographicAreaId": 0,
      "title": "10 Place de la Concorde, 75008 Paris, France",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 48,
        "tagName": "Iconic/Landmark ",
        "tagType": 1
      },
      {
        "tagId": 51,
        "tagName": "Style Icon",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 60,
        "tagName": "Gastronomic",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 105,
        "tagName": "Afternoon Tea",
        "tagType": 2
      },
      {
        "tagId": 156,
        "tagName": "Fireplace",
        "tagType": 2
      },
      {
        "tagId": 199,
        "tagName": "Art Gallery",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 216,
        "tagName": "Museum",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 323,
        "tagName": "City Walk",
        "tagType": 2
      },
      {
        "tagId": 332,
        "tagName": "Fashion",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 228,
        "venueCategoryId": 20,
        "venueId": 1253,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 20,
          "title": "BucketList Hotels",
          "region": "Global",
          "viewOrder": 0,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 330,
        "venueCategoryId": 22,
        "venueId": 1253,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 22,
          "title": "Paris Top 10 ",
          "region": "Europe",
          "viewOrder": 9,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 274,
    "venueCategoryId": 22,
    "status": 1,
    "title": "Le Royal Monceau Raffles Paris",
    "description": "Located on the prestigious Avenue Hoche, just a few steps from the Champs-Elysees and with views of the Arc de Triomphe, Le Royal Monceau features 149 luxurious rooms and suites, a 99-seat cinema, an art gallery, an exclusive service of Art Concierge and a private art collection. The Spa My Blend by Clarins, 1,500 sqm of ethereal white paradise, offers the ultimate urban escape for complete relaxation. With the longest swimming pool in a Paris hotel, personal trainers, a wide range of yoga and Pilates classes, a hammam, watsu basin and exclusive treatment rooms, this award-winning spa takes rejuvenation to another level. From the modern Japanese cuisine of Matsuhisa to the celebration of Italian regional food in the Michelin-starred restaurant Il Carpaccio, dining at Le Royal Monceau - Raffles Paris’ restaurants is a truly memorable experience. The desserts, all created by pastry superstar Pierre Herme, are the perfect finale to any meal.",
    "brand": null,
    "blockQuote": "The calming spa is home to a 23 meter pool, the longest ever built in a Paris luxury hotel.",
    "emailAddress": "bookus.paris@raffles.com",
    "website": "leroyalmonceau.com/",
    "bookingURL": "http://www.booking.com/hotel/fr/royal-monceau.html?aid=925669",
    "redirectNotes": "",
    "phoneNumber": "+33 1 42 99 88 00",
    "addressOne": "37",
    "addressTwo": "Avenue Hoche",
    "city": "Paris",
    "county": "",
    "region": "",
    "country": "France",
    "rooms": 149,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 904,
        "title": "Le Royal Monceau - Raffles Paris, Île-de-France, France",
        "url": "https://pkslist.azureedge.net/media/images/1/f05876d4-bfca-437a-b97e-4e86f6c89ad5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f05876d4-bfca-437a-b97e-4e86f6c89ad5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f05876d4-bfca-437a-b97e-4e86f6c89ad5/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 22837,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ad1cd2c4-df92-41a1-a03d-92922a28a4ef/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ad1cd2c4-df92-41a1-a03d-92922a28a4ef/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ad1cd2c4-df92-41a1-a03d-92922a28a4ef/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 22841,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/5c69cb28-7968-4c69-9f1b-54212fc7619a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5c69cb28-7968-4c69-9f1b-54212fc7619a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5c69cb28-7968-4c69-9f1b-54212fc7619a/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 22842,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/46afc7b4-8347-473d-b886-b8f6e439c02c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/46afc7b4-8347-473d-b886-b8f6e439c02c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/46afc7b4-8347-473d-b886-b8f6e439c02c/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 22838,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/18d78d0a-de2f-456a-8a64-320f08beb6bb/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/18d78d0a-de2f-456a-8a64-320f08beb6bb/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/18d78d0a-de2f-456a-8a64-320f08beb6bb/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 901,
        "title": "Hôtel Le Royal Monceau Raffles Hotel Paris, France - Tariff ...",
        "url": "https://pkslist.azureedge.net/media/images/1/b2f5be08-041f-42ea-b101-ca582b3ed2a0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b2f5be08-041f-42ea-b101-ca582b3ed2a0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b2f5be08-041f-42ea-b101-ca582b3ed2a0/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 22839,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/241617dc-c40b-4aaf-9206-baaaa0dba0b2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/241617dc-c40b-4aaf-9206-baaaa0dba0b2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/241617dc-c40b-4aaf-9206-baaaa0dba0b2/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 22836,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/114bb19f-b4e1-4a79-8533-1a3d95cdf57f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/114bb19f-b4e1-4a79-8533-1a3d95cdf57f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/114bb19f-b4e1-4a79-8533-1a3d95cdf57f/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 895,
        "title": "Le Royal Monceau - Raffles Paris, Île-de-France, France",
        "url": "https://pkslist.azureedge.net/media/images/1/672d92ef-e295-4852-b593-974171067b47/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/672d92ef-e295-4852-b593-974171067b47/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/672d92ef-e295-4852-b593-974171067b47/256x192.jpg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 141,
      "latitude": 48.8756359,
      "longitude": 2.3004966999999397,
      "parentGeographicAreaId": 0,
      "title": "37 Avenue Hoche, 75008 Paris, France",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 48,
        "tagName": "Iconic/Landmark ",
        "tagType": 1
      },
      {
        "tagId": 51,
        "tagName": "Style Icon",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 101,
        "tagName": "Cinema",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 212,
        "tagName": "Hammam",
        "tagType": 2
      },
      {
        "tagId": 216,
        "tagName": "Museum",
        "tagType": 2
      },
      {
        "tagId": 222,
        "tagName": "Michelin Star dining",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 33,
        "venueCategoryId": 22,
        "venueId": 274,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 22,
          "title": "Paris Top 10 ",
          "region": "Europe",
          "viewOrder": 9,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 273,
    "venueCategoryId": 22,
    "status": 1,
    "title": "The Peninsula Paris",
    "description": "Housed in an exquisitely restored 19th century French building in the heart of the elegant 16th arondissement, The Peninsula Paris offers spectacular terraces, gardens and skyline views, the finest accommodations, superlative dining and the legendary Peninsula service.\nThe Peninsula houses 200 luxurious rooms, including 86 suites. Inspired by Haute Couture, the theme suites at The Peninsula Paris hotel – some of the French capital’s most spacious and the world’s most highly customised – are veritable showcases of French heritage and savoir-faire.",
    "brand": "Peninsula",
    "blockQuote": "You'll feel like a Parisian sipping cocktails at Le Rooftop whilst taking in panoramic views over the city.",
    "emailAddress": "ppr@peninsula.com",
    "website": "paris.peninsula.com/",
    "bookingURL": "https://secure.peninsula.com/?_ga=2.52863080.19801321.1691413353-1391920660.1691413353&adult=2&agencyid=96027890&arrive=2023-08-07&chain=5440&child=0&currency=EUR&depart=2023-08-08&hotel=60935&level=hotel&locale=en-GB&rooms=1",
    "redirectNotes": "The Peninsula Paris' reservations system",
    "phoneNumber": "+33 1 58 12 28 88",
    "addressOne": "19",
    "addressTwo": "Avenue Kléber",
    "city": "Paris",
    "county": "",
    "region": "",
    "country": "France",
    "rooms": 200,
    "dollar": 3,
    "updated": "2023-08-07T13:12:37.19",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 22844,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/416b91d1-8a7e-4cd0-ab9b-f686f05b0197/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/416b91d1-8a7e-4cd0-ab9b-f686f05b0197/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/416b91d1-8a7e-4cd0-ab9b-f686f05b0197/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 892,
        "title": "The Peninsula Paris awarded coveted “Palace” distinction - eHotelier",
        "url": "https://pkslist.azureedge.net/media/images/1/c8798d5e-2ed4-4abe-94c0-16741dd3595c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c8798d5e-2ed4-4abe-94c0-16741dd3595c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c8798d5e-2ed4-4abe-94c0-16741dd3595c/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 878,
        "title": "Meeting Rooms at Hotel The Peninsula Paris, 19 Avenue Kléber ...",
        "url": "https://pkslist.azureedge.net/media/images/1/4985d6a6-a95e-4079-a598-69daca301a94/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4985d6a6-a95e-4079-a598-69daca301a94/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4985d6a6-a95e-4079-a598-69daca301a94/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 22850,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/7d380eb9-66ad-4c36-b65f-eb43db9e6cf1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7d380eb9-66ad-4c36-b65f-eb43db9e6cf1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7d380eb9-66ad-4c36-b65f-eb43db9e6cf1/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 22848,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ae965d28-d186-4bbc-b3ca-44b9d3270aca/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ae965d28-d186-4bbc-b3ca-44b9d3270aca/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ae965d28-d186-4bbc-b3ca-44b9d3270aca/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 22845,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d270be2c-74b8-4381-86bd-267bee679919/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d270be2c-74b8-4381-86bd-267bee679919/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d270be2c-74b8-4381-86bd-267bee679919/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 880,
        "title": "The Peninsula Paris Best New Hotel 2014",
        "url": "https://pkslist.azureedge.net/media/images/1/89deeac2-de85-4fe7-89ed-1fa600809a75/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/89deeac2-de85-4fe7-89ed-1fa600809a75/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/89deeac2-de85-4fe7-89ed-1fa600809a75/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 22849,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/3bff2b35-5094-4081-b969-edb6930d3cfd/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3bff2b35-5094-4081-b969-edb6930d3cfd/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3bff2b35-5094-4081-b969-edb6930d3cfd/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 22847,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b34ff5c1-157e-4b87-80ce-9e2b66e23baf/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b34ff5c1-157e-4b87-80ce-9e2b66e23baf/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b34ff5c1-157e-4b87-80ce-9e2b66e23baf/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 22846,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c7e43b8a-966b-4d65-8f02-414f720ea95c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c7e43b8a-966b-4d65-8f02-414f720ea95c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c7e43b8a-966b-4d65-8f02-414f720ea95c/256x192.jpg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 140,
      "latitude": 48.87106050000001,
      "longitude": 2.2933537000000115,
      "parentGeographicAreaId": 0,
      "title": "19 Avenue Kléber, 75116 Paris, France",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 44,
        "tagName": "Grand Dame ",
        "tagType": 1
      },
      {
        "tagId": 48,
        "tagName": "Iconic/Landmark ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 68,
        "tagName": "Business",
        "tagType": 2
      },
      {
        "tagId": 73,
        "tagName": "Outdoor Terraces",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 100,
        "tagName": "Wedding",
        "tagType": 2
      },
      {
        "tagId": 193,
        "tagName": "Outdoor Dining",
        "tagType": 2
      },
      {
        "tagId": 293,
        "tagName": "Meeting Space",
        "tagType": 2
      },
      {
        "tagId": 297,
        "tagName": "Rooftop Restaurant",
        "tagType": 2
      },
      {
        "tagId": 323,
        "tagName": "City Walk",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 32,
        "venueCategoryId": 22,
        "venueId": 273,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 22,
          "title": "Paris Top 10 ",
          "region": "Europe",
          "viewOrder": 9,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 249,
    "venueCategoryId": 22,
    "status": 1,
    "title": "Mandarin Oriental Paris",
    "description": "Situated on rue Saint-Honoré and just steps from Place Vendôme, one of the most stylish districts in the world, Mandarin Oriental, Paris enjoys a wonderfully chic location. Surrounded by haute couture and only steps from the Louvre, our five-star hotel is the epitome of elegant luxury.\n\nLying at the heart of one of the world’s most beautiful cities, Mandarin Oriental, Paris is defined by its beautiful interiors, acclaimed dining by Thierry Marx, a boutique spa and legendary five-star service.",
    "brand": "Mandarin Oriental",
    "blockQuote": "Located in Paris city centre, the hotel is in close walking distance to Place Vendome, Tuileries Garden and the famous Champs Elysées.",
    "emailAddress": "mopar-reservations@mohg.com",
    "website": "mandarinoriental.com/paris",
    "bookingURL": "https://www.mandarinoriental.com/reservations/rooms?adults=2&children=0&rooms=1&arrive=2018-08-29&depart=2018-08-30&hotel=52453&promo=&iata=96027890&group=&rate=&language=en-GB",
    "redirectNotes": "Mandarin Oriental Paris' reservations system",
    "phoneNumber": "+33 1 70 98 78 88 ",
    "addressOne": "251",
    "addressTwo": "Rue Saint Honoré",
    "city": "Paris",
    "county": "",
    "region": "",
    "country": "France",
    "rooms": 138,
    "dollar": 3,
    "updated": "2020-05-15T07:56:08.973",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 682,
        "title": "Mandarin Oriental Paris — Perfect Living In The Perfect City ...",
        "url": "https://pkslist.azureedge.net/media/images/1/8287d1a7-5870-4ff9-af78-24f0a8d3e037/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8287d1a7-5870-4ff9-af78-24f0a8d3e037/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8287d1a7-5870-4ff9-af78-24f0a8d3e037/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 685,
        "title": "Mandarin Oriental Paris by Wilmotte Associes S.A. (34) | HomeDSGN",
        "url": "https://pkslist.azureedge.net/media/images/1/40c4a838-d495-47ea-a152-6071e4653fc6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/40c4a838-d495-47ea-a152-6071e4653fc6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/40c4a838-d495-47ea-a152-6071e4653fc6/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 683,
        "title": "Hotel Mandarin Oriental, Paris, France - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/6af21e65-5ea3-4ff9-82a9-3b205fa70b06/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6af21e65-5ea3-4ff9-82a9-3b205fa70b06/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6af21e65-5ea3-4ff9-82a9-3b205fa70b06/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 681,
        "title": "MANDARIN ORIENTAL, PARIS LAUNCHES LOVE BY MESSIKA PACKAGE FOR ...",
        "url": "https://pkslist.azureedge.net/media/images/1/be39d215-62af-40c2-b695-083710cb9f3a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/be39d215-62af-40c2-b695-083710cb9f3a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/be39d215-62af-40c2-b695-083710cb9f3a/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 686,
        "title": "Luxury hotel, Mandarin Oriental Paris, Paris, France - Luxury ...",
        "url": "https://pkslist.azureedge.net/media/images/1/c2aa5978-ea4d-4fed-8c21-09a6fb960879/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c2aa5978-ea4d-4fed-8c21-09a6fb960879/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c2aa5978-ea4d-4fed-8c21-09a6fb960879/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 684,
        "title": "Mandarin Oriental Paris | Roda",
        "url": "https://pkslist.azureedge.net/media/images/1/d590c957-8861-4d88-80d9-54e9c985cd72/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d590c957-8861-4d88-80d9-54e9c985cd72/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d590c957-8861-4d88-80d9-54e9c985cd72/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 679,
        "title": "Mandarin Oriental, Paris (France) - UPDATED 2016 Hotel Reviews ...",
        "url": "https://pkslist.azureedge.net/media/images/1/e29961e8-6dcc-4a90-92da-11d6964575a5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e29961e8-6dcc-4a90-92da-11d6964575a5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e29961e8-6dcc-4a90-92da-11d6964575a5/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 677,
        "title": "Mandarin Oriental, Paris - InTomorrow",
        "url": "https://pkslist.azureedge.net/media/images/1/263377fb-9ce4-4749-b8d2-25fed34f02ac/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/263377fb-9ce4-4749-b8d2-25fed34f02ac/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/263377fb-9ce4-4749-b8d2-25fed34f02ac/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 675,
        "title": "Mandarin Oriental, Paris, Île-de-France, France",
        "url": "https://pkslist.azureedge.net/media/images/1/e9f634fd-7e98-482c-a547-a947be58d151/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e9f634fd-7e98-482c-a547-a947be58d151/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e9f634fd-7e98-482c-a547-a947be58d151/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 118,
      "latitude": 48.8668814,
      "longitude": 2.3271577000000434,
      "parentGeographicAreaId": 0,
      "title": "251 Rue Saint Honoré, 75001 Paris, France",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 45,
        "tagName": "Historic Sense",
        "tagType": 1
      },
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 62,
        "tagName": "Nightlife",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 216,
        "tagName": "Museum",
        "tagType": 2
      },
      {
        "tagId": 222,
        "tagName": "Michelin Star dining",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 323,
        "tagName": "City Walk",
        "tagType": 2
      },
      {
        "tagId": 332,
        "tagName": "Fashion",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 29,
        "venueCategoryId": 22,
        "venueId": 249,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 22,
          "title": "Paris Top 10 ",
          "region": "Europe",
          "viewOrder": 9,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 248,
    "venueCategoryId": 22,
    "status": 1,
    "title": "Ritz Paris",
    "description": "The Ritz Paris is reborn and invites you to experience a new the City of Light. Now freshly restored, its grand décor and intimate salons welcome guests to rediscover a unique atmosphere and the inimitable French art de vivre.\n\nThe legendary hotel opens its doors with 71 luxurious suites, including 15 historic Prestige Suites, and 71 rooms. Renowned interior architect Thierry W Despont oversaw the restoration which unveils three restaurants, three bars including famed Bar Hemingway and gorgeous gardens and terraces with retractable roofs and floor heating to be enjoyed year-round. École Ritz Escoffier will benefit from a third demonstration kitchen and the historic Salon Proust with its wood-burning fireplace offers Ritz afternoon tea. The Ritz Club welcomes \"CHANEL au Ritz Paris,\" the exclusive area dedicated to the art of CHANEL skin care for a unique sensorial and customized beauty experience. This two-story wellness universe also features an indoor pool and a modern fitness center. A discreet tunnel connecting the parking lot to the hotel is available for completely private guest arrivals and departures.",
    "brand": "",
    "blockQuote": "A truly timeless getaway, but for the most memorable stay, sleep in one of the historic Prestige Suites.",
    "emailAddress": "reservations@ritzparis.com",
    "website": "ritzparis.com/",
    "bookingURL": "https://booking.ritzparis.com/api/bg/book.php?apikey=ff2f7cbfef63574c6a96d621b3e46cb8&s=default&l=en-GB&iata=96027890",
    "redirectNotes": "Ritz Paris' reservations sysytem",
    "phoneNumber": "+33 1 43 16 30 30",
    "addressOne": "15",
    "addressTwo": "Place Vendôme",
    "city": "Paris",
    "county": "",
    "region": "",
    "country": "France",
    "rooms": 142,
    "dollar": 3,
    "updated": "2023-07-25T08:50:26.687",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 22825,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/cd06abbe-851e-46ea-9400-562cae43eddb/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cd06abbe-851e-46ea-9400-562cae43eddb/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cd06abbe-851e-46ea-9400-562cae43eddb/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 22830,
        "title": "Hotel Ritz Paris - Luxury Resorts | Berkeley Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/48c4e191-2006-4640-b557-4bc841c27c1b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/48c4e191-2006-4640-b557-4bc841c27c1b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/48c4e191-2006-4640-b557-4bc841c27c1b/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 22834,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b834de56-3473-40c6-b911-e419a9acf798/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b834de56-3473-40c6-b911-e419a9acf798/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b834de56-3473-40c6-b911-e419a9acf798/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 22833,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/88307ad5-b95f-42f8-aadc-c78d7fbfa396/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/88307ad5-b95f-42f8-aadc-c78d7fbfa396/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/88307ad5-b95f-42f8-aadc-c78d7fbfa396/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 22828,
        "title": "Reopening of the Ritz Paris -",
        "url": "https://pkslist.azureedge.net/media/images/1/082f11ee-7d48-4b09-bb4c-2e1ea8ebbbd6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/082f11ee-7d48-4b09-bb4c-2e1ea8ebbbd6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/082f11ee-7d48-4b09-bb4c-2e1ea8ebbbd6/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 22835,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/16367084-5c91-41a2-ba3e-fda8f697f4e8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/16367084-5c91-41a2-ba3e-fda8f697f4e8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/16367084-5c91-41a2-ba3e-fda8f697f4e8/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 22832,
        "title": "Hotel Ritz Paris, France - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/1e16b20f-06a8-4a5c-8471-32d56fa2800b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1e16b20f-06a8-4a5c-8471-32d56fa2800b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1e16b20f-06a8-4a5c-8471-32d56fa2800b/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 22829,
        "title": "Le Ritz Paris: Paris Hotels Review - 10Best Experts and Tourist ...",
        "url": "https://pkslist.azureedge.net/media/images/1/d89641a5-d8fb-4406-8fb9-cd551e6f0119/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d89641a5-d8fb-4406-8fb9-cd551e6f0119/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d89641a5-d8fb-4406-8fb9-cd551e6f0119/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 22831,
        "title": "Ritz Paris | Luxury Hotel in Grand Luxury Hotels Paris",
        "url": "https://pkslist.azureedge.net/media/images/1/adfa8320-bf00-4232-88d7-e125a854ccc3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/adfa8320-bf00-4232-88d7-e125a854ccc3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/adfa8320-bf00-4232-88d7-e125a854ccc3/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 22824,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/88d8676e-3aa0-4534-83bc-e4f31871113a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/88d8676e-3aa0-4534-83bc-e4f31871113a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/88d8676e-3aa0-4534-83bc-e4f31871113a/256x192.jpg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 117,
      "latitude": 48.867658,
      "longitude": 2.3287050000000136,
      "parentGeographicAreaId": 0,
      "title": "15 Place Vendôme, 75001 Paris, France",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 48,
        "tagName": "Iconic/Landmark ",
        "tagType": 1
      },
      {
        "tagId": 51,
        "tagName": "Style Icon",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 216,
        "tagName": "Museum",
        "tagType": 2
      },
      {
        "tagId": 222,
        "tagName": "Michelin Star dining",
        "tagType": 2
      },
      {
        "tagId": 276,
        "tagName": "Gym",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 289,
        "tagName": "Antiques",
        "tagType": 2
      },
      {
        "tagId": 323,
        "tagName": "City Walk",
        "tagType": 2
      },
      {
        "tagId": 332,
        "tagName": "Fashion",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 28,
        "venueCategoryId": 22,
        "venueId": 248,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 22,
          "title": "Paris Top 10 ",
          "region": "Europe",
          "viewOrder": 9,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 247,
    "venueCategoryId": 22,
    "status": 1,
    "title": "Le Meurice",
    "description": "With views of the Tuileries Garden, Le Meurice has been ranked among the most elegant hotels in the world.Combining exceptional 18th century opulence with contemporary chic, Le Meurice embodies the perfect French palace hotel. Located between Place de la Concorde and the Louvre, Le Meurice is ideally located for a range of activities, whether you’re after fine dining and shopping or simply want to explore the city of romance and culture.Le Meurice has been opened since 1815, so it has many stories hidden within its walls and has seen countless celebrities and notable figures come and go.",
    "brand": "Dorchester Collection",
    "blockQuote": "Le Meurice stands out among the Parisian palaces for its prime location and its regal decor.",
    "emailAddress": "info.lmp@dorchestercollection.com",
    "website": "dorchestercollection.com/en/paris/le-meurice",
    "bookingURL": "https://be.synxis.com/landing?_ga=2.104963584.2035934523.1625652785-593026414.1625652785&adult=2&agencyid=96027890&arrive=2021-07-07&chain=5310&child=0&currency=EUR&depart=2021-07-08&filter=&hotel=60013&level=chain&locale=en-US&rooms=1",
    "redirectNotes": "Le Meurice's reservations system",
    "phoneNumber": "+33 1 44 58 10 10",
    "addressOne": "228",
    "addressTwo": "Rue de Rivoli",
    "city": "Paris",
    "county": "",
    "region": "",
    "country": "France",
    "rooms": 160,
    "dollar": 3,
    "updated": "2021-07-07T10:22:49.757",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 22819,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a7ae9c3d-b099-4b0a-bcf6-e2d8f72dd11f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a7ae9c3d-b099-4b0a-bcf6-e2d8f72dd11f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a7ae9c3d-b099-4b0a-bcf6-e2d8f72dd11f/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 22813,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c698fe07-4d72-4314-b65a-5e4f434f5fef/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c698fe07-4d72-4314-b65a-5e4f434f5fef/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c698fe07-4d72-4314-b65a-5e4f434f5fef/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 22817,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/52e17344-87fb-4444-9290-b9c50d57b99e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/52e17344-87fb-4444-9290-b9c50d57b99e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/52e17344-87fb-4444-9290-b9c50d57b99e/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 22814,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b4e2b198-dcfc-47b9-958f-1861dbcc7b32/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b4e2b198-dcfc-47b9-958f-1861dbcc7b32/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b4e2b198-dcfc-47b9-958f-1861dbcc7b32/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 22812,
        "title": "Le Meurice - Paris - 5-Star Luxury Hotel | Dorchester Collection",
        "url": "https://pkslist.azureedge.net/media/images/1/5e76876f-d729-4ba3-aeb3-f59b069bb6b9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5e76876f-d729-4ba3-aeb3-f59b069bb6b9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5e76876f-d729-4ba3-aeb3-f59b069bb6b9/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 22815,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/71fd6518-36c1-4651-976b-ac6b32b61a7c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/71fd6518-36c1-4651-976b-ac6b32b61a7c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/71fd6518-36c1-4651-976b-ac6b32b61a7c/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 22816,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/84acf923-7dbd-47e7-afac-d56e9483ba2e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/84acf923-7dbd-47e7-afac-d56e9483ba2e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/84acf923-7dbd-47e7-afac-d56e9483ba2e/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 22821,
        "title": "VENUE SPOTLIGHT: LE MEURICE // PARIS, FRANCE Strawberry Milk Events",
        "url": "https://pkslist.azureedge.net/media/images/1/c3750768-0e70-4034-b3fc-32b5082b956d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c3750768-0e70-4034-b3fc-32b5082b956d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c3750768-0e70-4034-b3fc-32b5082b956d/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 116,
      "latitude": 48.8653685,
      "longitude": 2.3282179999999926,
      "parentGeographicAreaId": 0,
      "title": "228 Rue de Rivoli, 75001 Paris, France",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 42,
        "tagName": "Classic Traditionalist ",
        "tagType": 1
      },
      {
        "tagId": 44,
        "tagName": "Grand Dame ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 60,
        "tagName": "Gastronomic",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 155,
        "tagName": "Art",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 216,
        "tagName": "Museum",
        "tagType": 2
      },
      {
        "tagId": 222,
        "tagName": "Michelin Star dining",
        "tagType": 2
      },
      {
        "tagId": 255,
        "tagName": "Music",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 289,
        "tagName": "Antiques",
        "tagType": 2
      },
      {
        "tagId": 323,
        "tagName": "City Walk",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 27,
        "venueCategoryId": 22,
        "venueId": 247,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 22,
          "title": "Paris Top 10 ",
          "region": "Europe",
          "viewOrder": 9,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 245,
    "venueCategoryId": 22,
    "status": 1,
    "title": "Hotel Plaza Athenee",
    "description": "When it comes to luxury in the most romantic and stylish city in the world, look no further than the legendary Hôtel Plaza Athénée. Situated in the heart of Haute Couture, on the renowned avenue Montaigne, this Dorchester Collection hotel is in the ideal location for the finest shopping that Paris has to offer, and has some of the most magnificent views of the Eiffel Tower.\n\nEstablished in 1913, the hotel has frequently welcomed royalty, celebrities, and politicians through its doors, and has distinguished itself as one of the best hotels in Paris.",
    "brand": "Dorchester Collection",
    "blockQuote": "The dining room of Alain Ducasse au Plaza Athénée is one of the most beautiful anywhere and the food is exceptional.",
    "emailAddress": "reservations.hpa@dorchestercollection.com",
    "website": "dorchestercollection.com/en/paris/hotel-plaza-athenee",
    "bookingURL": "https://be.synxis.com/landing?_ga=2.43497638.2035934523.1625652785-593026414.1625652785&adult=2&agencyid=96027890&arrive=2021-07-07&chain=5310&child=0&currency=EUR&depart=2021-07-08&filter=&hotel=60022&level=chain&locale=en-US&rooms=1",
    "redirectNotes": "Hotel Plaza Athenee's reservations system",
    "phoneNumber": "+33 1 53 67 66 65",
    "addressOne": "25",
    "addressTwo": "Avenue Montaigne",
    "city": "Paris",
    "county": "",
    "region": "",
    "country": "France",
    "rooms": 208,
    "dollar": 3,
    "updated": "2021-07-07T10:21:12.64",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 36827,
        "title": "Hotel Plaza Athenee, Paris, France - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/632a22ca-0cf4-4ac1-bdb3-c10284eae2a4/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/632a22ca-0cf4-4ac1-bdb3-c10284eae2a4/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/632a22ca-0cf4-4ac1-bdb3-c10284eae2a4/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 22803,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/50ad1d61-4eba-4267-9a92-9cc407ab0eda/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/50ad1d61-4eba-4267-9a92-9cc407ab0eda/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/50ad1d61-4eba-4267-9a92-9cc407ab0eda/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 22800,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/12fb5af5-ff97-493a-ae26-043fa8627411/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/12fb5af5-ff97-493a-ae26-043fa8627411/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/12fb5af5-ff97-493a-ae26-043fa8627411/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 22804,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/854e9e4f-0683-42e0-bbe6-02b89047eaab/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/854e9e4f-0683-42e0-bbe6-02b89047eaab/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/854e9e4f-0683-42e0-bbe6-02b89047eaab/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 22801,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e95744d9-aca1-47f9-974d-a88b4196435e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e95744d9-aca1-47f9-974d-a88b4196435e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e95744d9-aca1-47f9-974d-a88b4196435e/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 22806,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/04ea31cd-4424-4b4a-88e2-8641d0fe8ead/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/04ea31cd-4424-4b4a-88e2-8641d0fe8ead/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/04ea31cd-4424-4b4a-88e2-8641d0fe8ead/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 22805,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/2380b01b-13a7-4e9d-ac41-c0225496fc98/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2380b01b-13a7-4e9d-ac41-c0225496fc98/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2380b01b-13a7-4e9d-ac41-c0225496fc98/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 22802,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/3c06187e-0ed3-4f0d-8651-4fe63edba323/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3c06187e-0ed3-4f0d-8651-4fe63edba323/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3c06187e-0ed3-4f0d-8651-4fe63edba323/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 22807,
        "title": "The Renovation of Paris: 5 Luxury Hotels",
        "url": "https://pkslist.azureedge.net/media/images/1/fa97d19b-7e2c-459a-b9bf-5a484835c8a8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fa97d19b-7e2c-459a-b9bf-5a484835c8a8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fa97d19b-7e2c-459a-b9bf-5a484835c8a8/256x192.jpg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 114,
      "latitude": 48.86619319999999,
      "longitude": 2.3042140999999674,
      "parentGeographicAreaId": 0,
      "title": "25 Avenue Montaigne, 75008 Paris, France",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 45,
        "tagName": "Historic Sense",
        "tagType": 1
      },
      {
        "tagId": 51,
        "tagName": "Style Icon",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 60,
        "tagName": "Gastronomic",
        "tagType": 2
      },
      {
        "tagId": 62,
        "tagName": "Nightlife",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 68,
        "tagName": "Business",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 216,
        "tagName": "Museum",
        "tagType": 2
      },
      {
        "tagId": 222,
        "tagName": "Michelin Star dining",
        "tagType": 2
      },
      {
        "tagId": 281,
        "tagName": "Ice Skating",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 25,
        "venueCategoryId": 22,
        "venueId": 245,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 22,
          "title": "Paris Top 10 ",
          "region": "Europe",
          "viewOrder": 9,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 240,
    "venueCategoryId": 22,
    "status": 1,
    "title": "Four Seasons Hotel George V",
    "description": "An art-deco landmark built in 1928, Four Seasons Hotel George V is nestled in the Golden Triangle of Paris, just off the historic Champs-Elysees. Oversized suites with Eiffel Tower views welcome you after a day of wandering the quaint, Parisian streets, and our three restaurants – with five Michelin stars among them – are home to some of the best food in France. A decadent new spa, an elegant swimming pool and a courtyard for whiling away the afternoons with a glass of wine from our cellar are just some of the pleasures unique to our historic destination.",
    "brand": "Four Seasons",
    "blockQuote": "The best place for people watching is La Galerie where the fashionable Parisian ladies come for a light bite.",
    "emailAddress": "res.paris@fourseasons.com",
    "website": "fourseasons.com/paris/",
    "bookingURL": "https://reservations.fourseasons.com/choose-your-room?hotelCode=PAR837&checkIn=2021-03-15&checkOut=2021-03-16&adults=2&children=0&iata=96027890",
    "redirectNotes": "Four Seasons Hotel George V's reservations system",
    "phoneNumber": "+33 1 49 52 70 00",
    "addressOne": "31",
    "addressTwo": "Avenue George V",
    "city": "Paris",
    "county": "",
    "region": "",
    "country": "France",
    "rooms": 244,
    "dollar": 3,
    "updated": "2021-03-15T15:43:30.25",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 46129,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/9f7cae73-4957-476b-aeb5-5f1d214e8d50/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9f7cae73-4957-476b-aeb5-5f1d214e8d50/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9f7cae73-4957-476b-aeb5-5f1d214e8d50/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 46130,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f69fc115-4c78-4564-90aa-e2b363eb6a19/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f69fc115-4c78-4564-90aa-e2b363eb6a19/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f69fc115-4c78-4564-90aa-e2b363eb6a19/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 46141,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/6900852c-f4dd-4a45-9a35-8f67f2708864/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6900852c-f4dd-4a45-9a35-8f67f2708864/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6900852c-f4dd-4a45-9a35-8f67f2708864/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 46131,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/7754a546-0c74-4460-a32e-c7bf578a3b62/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7754a546-0c74-4460-a32e-c7bf578a3b62/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7754a546-0c74-4460-a32e-c7bf578a3b62/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 46134,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/9c417cb0-ffee-4e47-8002-8687af1e72a1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9c417cb0-ffee-4e47-8002-8687af1e72a1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9c417cb0-ffee-4e47-8002-8687af1e72a1/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 46133,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/431e204c-aabc-4226-bffe-81e81a040c38/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/431e204c-aabc-4226-bffe-81e81a040c38/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/431e204c-aabc-4226-bffe-81e81a040c38/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 46132,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e93f6180-36b4-4da0-84a4-7e6cc0d10213/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e93f6180-36b4-4da0-84a4-7e6cc0d10213/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e93f6180-36b4-4da0-84a4-7e6cc0d10213/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 46138,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/33bea965-9f92-45cd-ae9d-68b19484303d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/33bea965-9f92-45cd-ae9d-68b19484303d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/33bea965-9f92-45cd-ae9d-68b19484303d/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 46140,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/13f4129d-7f8c-44e5-85e6-db3d2f119dec/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/13f4129d-7f8c-44e5-85e6-db3d2f119dec/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/13f4129d-7f8c-44e5-85e6-db3d2f119dec/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 46139,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/7891d567-8444-43a0-94bb-c448048a5878/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7891d567-8444-43a0-94bb-c448048a5878/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7891d567-8444-43a0-94bb-c448048a5878/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 46137,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e3bd67b8-3005-4302-a469-e7926a11be6a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e3bd67b8-3005-4302-a469-e7926a11be6a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e3bd67b8-3005-4302-a469-e7926a11be6a/256x192.jpg",
        "viewOrder": 12
      },
      {
        "mediaId": 46142,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b2ee17a7-d083-433b-8819-8173a9546e7d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b2ee17a7-d083-433b-8819-8173a9546e7d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b2ee17a7-d083-433b-8819-8173a9546e7d/256x192.jpg",
        "viewOrder": 13
      },
      {
        "mediaId": 46144,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d4177202-e578-4b7b-b482-22a397a1dd8c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d4177202-e578-4b7b-b482-22a397a1dd8c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d4177202-e578-4b7b-b482-22a397a1dd8c/256x192.jpg",
        "viewOrder": 14
      },
      {
        "mediaId": 46145,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a143ed65-7f7d-4be1-9d41-4ab99ca97693/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a143ed65-7f7d-4be1-9d41-4ab99ca97693/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a143ed65-7f7d-4be1-9d41-4ab99ca97693/256x192.jpg",
        "viewOrder": 15
      },
      {
        "mediaId": 46143,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/338b0047-bf97-44d0-b02f-f31314deca34/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/338b0047-bf97-44d0-b02f-f31314deca34/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/338b0047-bf97-44d0-b02f-f31314deca34/256x192.jpg",
        "viewOrder": 16
      },
      {
        "mediaId": 32796,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/478c70bb-3b56-44b6-966e-e4ad281212a3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/478c70bb-3b56-44b6-966e-e4ad281212a3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/478c70bb-3b56-44b6-966e-e4ad281212a3/256x192.jpg",
        "viewOrder": 17
      }
    ],
    "geographicArea": {
      "geographicAreaId": 110,
      "latitude": 48.8687512,
      "longitude": 2.3001692000000276,
      "parentGeographicAreaId": 0,
      "title": "31 Avenue George V, 75008 Paris, France",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 45,
        "tagName": "Historic Sense",
        "tagType": 1
      },
      {
        "tagId": 48,
        "tagName": "Iconic/Landmark ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 60,
        "tagName": "Gastronomic",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 68,
        "tagName": "Business",
        "tagType": 2
      },
      {
        "tagId": 197,
        "tagName": "Café",
        "tagType": 2
      },
      {
        "tagId": 202,
        "tagName": "Private Terraces",
        "tagType": 2
      },
      {
        "tagId": 204,
        "tagName": "Courtyard",
        "tagType": 2
      },
      {
        "tagId": 216,
        "tagName": "Museum",
        "tagType": 2
      },
      {
        "tagId": 222,
        "tagName": "Michelin Star dining",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 332,
        "tagName": "Fashion",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 23,
        "venueCategoryId": 22,
        "venueId": 240,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 22,
          "title": "Paris Top 10 ",
          "region": "Europe",
          "viewOrder": 9,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 223,
    "venueCategoryId": 22,
    "status": 1,
    "title": "La Reserve Paris",
    "description": "Nestled in a peaceful location, slightly set back from the world's most beautiful avenue, La Reserve Paris - Hotel and Spa overlooks the Grand Palais, the Eiffel Tower, the Pantheon and the Concorde obelisk. Just in front, a majestic curtain of trees preserves the intimacy of this place, like an echo of the Elysee gardens nearby. Michel Reybier's highly distinctive urban mansion has all the elegance of a 19th century private apartment, where ultra-personalized services can be enjoyed in complete simplicity and discretion.\n\nHere, you will find an intimate and infinitely pleasing home away from home, where excellence is both a vocation and a promise to those who share an appreciation for a different way of life.",
    "brand": null,
    "blockQuote": "The most discreet and elegant address in Paris.",
    "emailAddress": "hotel@lareserve-paris.com",
    "website": "lareserve-paris.com",
    "bookingURL": "https://www.myhotelreservation.net/b/hllw0645/HLLW0645/?i=96027890&_ga=2.121523151.650472423.1526049140-2126784661.1526049140",
    "redirectNotes": "La Reserve Paris' reservations system",
    "phoneNumber": "+33 1 58 36 60 60",
    "addressOne": "42",
    "addressTwo": "Avenue Gabriel",
    "city": "Paris",
    "county": "",
    "region": "",
    "country": "France",
    "rooms": 40,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 449,
        "title": "Habitually Chic® » La Reserve Paris",
        "url": "https://pkslist.azureedge.net/media/images/1/55a143df-9171-4527-8754-8e9cf160e66d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/55a143df-9171-4527-8754-8e9cf160e66d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/55a143df-9171-4527-8754-8e9cf160e66d/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 444,
        "title": "5 Star Luxury Hotels in Paris | La Reserve Paris Hotel & Spa",
        "url": "https://pkslist.azureedge.net/media/images/1/cdc28445-d853-4de8-84b8-945513bb89fa/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cdc28445-d853-4de8-84b8-945513bb89fa/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cdc28445-d853-4de8-84b8-945513bb89fa/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 20466,
        "title": "La Reserve Paris :: Hotel galerie",
        "url": "https://pkslist.azureedge.net/media/images/1/d118f265-0851-4ade-9434-427933864486/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d118f265-0851-4ade-9434-427933864486/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d118f265-0851-4ade-9434-427933864486/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 20467,
        "title": "La Réserve Paris Hotel and Spa - Traveldeals",
        "url": "https://pkslist.azureedge.net/media/images/1/317eb028-1f07-4d05-861d-c9275b6f5a25/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/317eb028-1f07-4d05-861d-c9275b6f5a25/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/317eb028-1f07-4d05-861d-c9275b6f5a25/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 20468,
        "title": "La Réserve Paris Hotel & Spa 2017 World Luxury Hotel AwardsWorld ...",
        "url": "https://pkslist.azureedge.net/media/images/1/ee2e96e4-ee56-4eea-96f7-3665e0573374/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ee2e96e4-ee56-4eea-96f7-3665e0573374/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ee2e96e4-ee56-4eea-96f7-3665e0573374/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 20469,
        "title": "La Réserve Paris Hotel & Spa 2017 World Luxury Hotel AwardsWorld ...",
        "url": "https://pkslist.azureedge.net/media/images/1/1ccca732-6b52-448b-ae01-e8d4950dd0dd/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1ccca732-6b52-448b-ae01-e8d4950dd0dd/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1ccca732-6b52-448b-ae01-e8d4950dd0dd/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 447,
        "title": "Habitually Chic® » La Reserve Paris",
        "url": "https://pkslist.azureedge.net/media/images/1/170d4744-3c13-4177-981f-a4747358920a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/170d4744-3c13-4177-981f-a4747358920a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/170d4744-3c13-4177-981f-a4747358920a/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 20471,
        "title": "La Reserve Paris. La Reserve Paris Apartments With La Reserve ...",
        "url": "https://pkslist.azureedge.net/media/images/1/56092475-65b1-47fa-9645-e220623a6873/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/56092475-65b1-47fa-9645-e220623a6873/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/56092475-65b1-47fa-9645-e220623a6873/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 446,
        "title": "La Reserve Paris (France) | Design Hotels™",
        "url": "https://pkslist.azureedge.net/media/images/1/e2039f9c-0ff9-43c4-bdbb-f271dacc87d7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e2039f9c-0ff9-43c4-bdbb-f271dacc87d7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e2039f9c-0ff9-43c4-bdbb-f271dacc87d7/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 448,
        "title": "La Reserve Paris - Hotel and Spa (France) - UPDATED 2016 Reviews ...",
        "url": "https://pkslist.azureedge.net/media/images/1/821d7442-b0af-418b-af24-eba33b523b20/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/821d7442-b0af-418b-af24-eba33b523b20/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/821d7442-b0af-418b-af24-eba33b523b20/256x192.jpg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 93,
      "latitude": 48.8697628,
      "longitude": 2.3132848000000195,
      "parentGeographicAreaId": 0,
      "title": "42 Avenue Gabriel, 75008 Paris, France",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 40,
        "tagName": "Boutique Bolthole ",
        "tagType": 1
      },
      {
        "tagId": 45,
        "tagName": "Historic Sense",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 60,
        "tagName": "Gastronomic",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 155,
        "tagName": "Art",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 216,
        "tagName": "Museum",
        "tagType": 2
      },
      {
        "tagId": 222,
        "tagName": "Michelin Star dining",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 323,
        "tagName": "City Walk",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 15,
        "venueCategoryId": 22,
        "venueId": 223,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 22,
          "title": "Paris Top 10 ",
          "region": "Europe",
          "viewOrder": 9,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  }
]
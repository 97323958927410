

export const placesStore20=[
    {
      "venueId": 3421,
      "venueCategoryId": 0,
      "status": 1,
      "title": "White Desert Echo",
      "description": "Echo is as close as you can get to feeling like you’re off the planet without leaving Earth.\n\nThe space-age design of the ‘Sky Pods’ makes them look like they’ve been beamed down from Mars. Futuristic and luxurious, the six bedrooms are created from composite fibreglass with floor to ceiling windows allowing guests to soak in the moon-like landscape beyond.\n\nOriginal photos taken from the International Space Station (ISS) by former Commander, White Desert guest and retired astronaut, colonel Terry Virts will be featured in each pod. Says Virts of the Echo landscape, “The mountains are the most beautiful I have seen across Earth, Venus and Mars.”\n\nPerfect for exclusive use groups and catering for up to 12 people, Echo allows guests to combine an ultra-luxury experience with a once in lifetime adventure on the 7th Continent. As with all White Desert’s camps, Echo is designed to be dismantled, leaving no trace on the Antarctic landscape.",
      "brand": "",
      "blockQuote": "You may never get to outer space but you can live like an astronaut.",
      "emailAddress": "info@white-desert.com",
      "website": "white-desert.com/echo/",
      "bookingURL": "https://white-desert.com/dates-and-rates/2022-2023/",
      "redirectNotes": "White Desert Echo's reservations system",
      "phoneNumber": "+1 815 534 4533",
      "addressOne": "",
      "addressTwo": "",
      "city": "Somewhere",
      "county": "",
      "region": "",
      "country": "Antarctica",
      "rooms": 6,
      "dollar": 3,
      "updated": "2022-07-19T11:01:09.663",
      "created": "2022-07-19T10:45:54.977",
      "medias": [
        {
          "mediaId": 53963,
          "title": "White Desert Echo Camp | Uncrate",
          "url": "https://pkslist.azureedge.net/media/images/1/0a295c1e-eaad-4b59-9b36-e2e0129386ee/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/0a295c1e-eaad-4b59-9b36-e2e0129386ee/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/0a295c1e-eaad-4b59-9b36-e2e0129386ee/256x192.jpg",
          "viewOrder": 0
        },
        {
          "mediaId": 53964,
          "title": "White Desert Antarctica launch 'Echo': a new camp inspired by ...",
          "url": "https://pkslist.azureedge.net/media/images/1/cc1bf930-fa0f-4ee1-bae6-a677f718228c/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/cc1bf930-fa0f-4ee1-bae6-a677f718228c/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/cc1bf930-fa0f-4ee1-bae6-a677f718228c/256x192.jpg",
          "viewOrder": 1
        },
        {
          "mediaId": 53965,
          "title": "White Desert's New Antarctica Camp : Echo | Luxsphere",
          "url": "https://pkslist.azureedge.net/media/images/1/9683e493-63fc-4977-bcbc-cf12589514f6/1200x900.png",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/9683e493-63fc-4977-bcbc-cf12589514f6/800x600.png",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/9683e493-63fc-4977-bcbc-cf12589514f6/256x192.png",
          "viewOrder": 2
        },
        {
          "mediaId": 53970,
          "title": "White Desert Echo Camp Antarctica | stupidDOPE.com",
          "url": "https://pkslist.azureedge.net/media/images/1/0d31d609-55e9-41c0-97b2-b5e70542077d/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/0d31d609-55e9-41c0-97b2-b5e70542077d/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/0d31d609-55e9-41c0-97b2-b5e70542077d/256x192.jpg",
          "viewOrder": 3
        },
        {
          "mediaId": 53967,
          "title": "Echo Camp from White Desert - UnnamedProject",
          "url": "https://pkslist.azureedge.net/media/images/1/7d1fc25b-21b8-4e7e-ac21-396c73dd0ca0/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/7d1fc25b-21b8-4e7e-ac21-396c73dd0ca0/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/7d1fc25b-21b8-4e7e-ac21-396c73dd0ca0/256x192.jpg",
          "viewOrder": 4
        },
        {
          "mediaId": 53962,
          "title": "First Look at Echo, White Desert's New Space-Inspired Antarctic Camp",
          "url": "https://pkslist.azureedge.net/media/images/1/f0d9eaca-d606-47bc-96b9-5096ef689e4b/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/f0d9eaca-d606-47bc-96b9-5096ef689e4b/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/f0d9eaca-d606-47bc-96b9-5096ef689e4b/256x192.jpg",
          "viewOrder": 5
        }
      ],
      "geographicArea": {
        "geographicAreaId": 3209,
        "latitude": -75.250973,
        "longitude": -0.071389,
        "parentGeographicAreaId": 0,
        "title": "Antarctica",
        "updated": null,
        "created": "2022-07-19T10:54:00"
      },
      "personalities": [
        {
          "tagId": 43,
          "tagName": "Design Slickster",
          "tagType": 1
        },
        {
          "tagId": 53,
          "tagName": "Cutting Edge",
          "tagType": 1
        }
      ],
      "tags": [
        {
          "tagId": 172,
          "tagName": "Biking",
          "tagType": 2
        },
        {
          "tagId": 207,
          "tagName": "Lounge/Bar",
          "tagType": 2
        },
        {
          "tagId": 243,
          "tagName": "Outdoor Activities",
          "tagType": 2
        },
        {
          "tagId": 287,
          "tagName": "Wildlife",
          "tagType": 2
        },
        {
          "tagId": 296,
          "tagName": "Guided Walk",
          "tagType": 2
        },
        {
          "tagId": 327,
          "tagName": "Snowmobiles",
          "tagType": 2
        },
        {
          "tagId": 338,
          "tagName": "Cross Country Skiing",
          "tagType": 2
        },
        {
          "tagId": 365,
          "tagName": "Private Chef",
          "tagType": 2
        },
        {
          "tagId": 453,
          "tagName": "Abseiling",
          "tagType": 2
        },
        {
          "tagId": 454,
          "tagName": "Ice Climbing",
          "tagType": 2
        }
      ],
      "venueCategoryAssigns": [
        {
          "venueCategoryAssignId": 757,
          "venueCategoryId": 34,
          "venueId": 3421,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 34,
            "title": "Extremely Remote",
            "region": "Global",
            "viewOrder": 13,
            "updated": "2020-09-04T09:11:00",
            "created": "2020-09-04T09:11:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 758,
          "venueCategoryId": 17,
          "venueId": 3421,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 17,
            "title": "Winter Wonderland",
            "region": "Global",
            "viewOrder": 12,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 759,
          "venueCategoryId": 13,
          "venueId": 3421,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 13,
            "title": "Wildlife Encounters",
            "region": "Global",
            "viewOrder": 4,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 760,
          "venueCategoryId": 20,
          "venueId": 3421,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 20,
            "title": "BucketList Hotels",
            "region": "Global",
            "viewOrder": 0,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 778,
          "venueCategoryId": 35,
          "venueId": 3421,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 35,
            "title": "New Openings",
            "region": "Global",
            "viewOrder": 1,
            "updated": "2022-07-26T08:22:00",
            "created": "2022-07-26T08:22:00",
            "venueCategoryAssign": [
              
            ]
          }
        }
      ]
    },
    {
      "venueId": 3385,
      "venueCategoryId": 0,
      "status": 1,
      "title": "Aman New York",
      "description": "Situated in the heart of Manhattan at the crossroads of Fifth Avenue and 57th Street, overlooking Central Park, Aman New York occupies the iconic Crown Building and comprises of guest rooms and suites, and also houses the first urban Aman Residences. \n\nThe Crown Building - formerly the first home of Museum of Modern Art - has undergone a monumental transformation, which has seen it fully restored to its original splendour and imbued with the spirit of Aman. Designed by Jean-Michel Gathy, Aman New York celebrates Aman's philosophy of creating sanctuaries beyond commonplace notions of luxury, whilst paying homage to the building's striking architecture and significant history.\n\n Aman New York will consist of 83 hotel rooms, as well as restaurants, a Garden Terrace & Bar with 7000 square feet of outdoor dining space, private members club, three-floor Aman Spa, Jazz Club and Wine Room.",
      "brand": "Aman",
      "blockQuote": "The subterranean Jazz Club is a must for a fun night in NYC.",
      "emailAddress": "reservations@aman.com",
      "website": "aman.com/hotels/aman-new-york",
      "bookingURL": "https://reservations.aman.com/?adult=2&arrive=2022-10-11&chain=16840&child=0&currency=USD&depart=2022-10-12&hotel=35050&level=hotel&locale=en-US&rooms=1&sbe_widget=search-bar&iata=96027890",
      "redirectNotes": "Aman New York's reservations system",
      "phoneNumber": "+1 212 970 2626",
      "addressOne": "730",
      "addressTwo": "5th Avenue",
      "city": "New York",
      "county": "",
      "region": "New York",
      "country": "United States",
      "rooms": 83,
      "dollar": 3,
      "updated": "2022-10-11T09:56:51.753",
      "created": "2022-03-15T12:54:30.013",
      "medias": [
        {
          "mediaId": 52981,
          "title": "New Aman Hotel Coming to NYC Is Home to a Jaw-dropping Pool With ...",
          "url": "https://pkslist.azureedge.net/media/images/1/a7a2c499-f3cc-4f0d-846d-fd3cc15d9485/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/a7a2c499-f3cc-4f0d-846d-fd3cc15d9485/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/a7a2c499-f3cc-4f0d-846d-fd3cc15d9485/256x192.jpg",
          "viewOrder": 0
        },
        {
          "mediaId": 55088,
          "title": "Denniston Architects converts 1920s skyscraper into Aman New York ...",
          "url": "https://pkslist.azureedge.net/media/images/1/b55b4c6f-79f1-4878-bd8b-db07a71cfbd0/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/b55b4c6f-79f1-4878-bd8b-db07a71cfbd0/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/b55b4c6f-79f1-4878-bd8b-db07a71cfbd0/256x192.jpg",
          "viewOrder": 1
        },
        {
          "mediaId": 52991,
          "title": "New Aman Hotel Coming to NYC Is Home to a Jaw-dropping Pool With ...",
          "url": "https://pkslist.azureedge.net/media/images/1/3273ffcb-d3fe-41c2-9b12-99e675500551/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/3273ffcb-d3fe-41c2-9b12-99e675500551/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/3273ffcb-d3fe-41c2-9b12-99e675500551/256x192.jpg",
          "viewOrder": 2
        },
        {
          "mediaId": 55081,
          "title": "Aman New York hotel review | CN Traveller",
          "url": "https://pkslist.azureedge.net/media/images/1/d96f67e5-8a7a-4ace-957f-6ea52a24984b/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/d96f67e5-8a7a-4ace-957f-6ea52a24984b/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/d96f67e5-8a7a-4ace-957f-6ea52a24984b/256x192.jpg",
          "viewOrder": 3
        },
        {
          "mediaId": 55083,
          "title": "Aman New York: First In | Condé Nast Traveler",
          "url": "https://pkslist.azureedge.net/media/images/1/31214da3-6911-4f36-bf03-97e37d5e7164/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/31214da3-6911-4f36-bf03-97e37d5e7164/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/31214da3-6911-4f36-bf03-97e37d5e7164/256x192.jpg",
          "viewOrder": 4
        },
        {
          "mediaId": 55080,
          "title": "NYC's Most Expensive Hotel: Aman Owner Explores Sale for $600 ...",
          "url": "https://pkslist.azureedge.net/media/images/1/f8a6bcf9-6a83-4a52-acbb-64b797d890b2/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/f8a6bcf9-6a83-4a52-acbb-64b797d890b2/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/f8a6bcf9-6a83-4a52-acbb-64b797d890b2/256x192.jpg",
          "viewOrder": 5
        },
        {
          "mediaId": 55087,
          "title": "AMAN NEW YORK - Updated 2022 Prices & Hotel Reviews (New York City)",
          "url": "https://pkslist.azureedge.net/media/images/1/de105805-4449-419e-b9b1-865d87134577/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/de105805-4449-419e-b9b1-865d87134577/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/de105805-4449-419e-b9b1-865d87134577/256x192.jpg",
          "viewOrder": 6
        },
        {
          "mediaId": 55084,
          "title": "Aman New York is here and it is astonishing | Condé Nast Traveller ...",
          "url": "https://pkslist.azureedge.net/media/images/1/7efe44ab-6b6c-4e84-ad0f-8d3a75d762fe/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/7efe44ab-6b6c-4e84-ad0f-8d3a75d762fe/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/7efe44ab-6b6c-4e84-ad0f-8d3a75d762fe/256x192.jpg",
          "viewOrder": 7
        },
        {
          "mediaId": 55086,
          "title": "Go Inside 5 of New York City's Most Exciting Hotel Openings - Galerie",
          "url": "https://pkslist.azureedge.net/media/images/1/91aeef73-4767-41c8-9af9-dfa20a08d3aa/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/91aeef73-4767-41c8-9af9-dfa20a08d3aa/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/91aeef73-4767-41c8-9af9-dfa20a08d3aa/256x192.jpg",
          "viewOrder": 8
        },
        {
          "mediaId": 55085,
          "title": "Aman Just Opened One of the World's Most Anticipated Hotels in NYC ...",
          "url": "https://pkslist.azureedge.net/media/images/1/d21804d0-0ccd-48db-bb3c-ce58cc7a9ae2/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/d21804d0-0ccd-48db-bb3c-ce58cc7a9ae2/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/d21804d0-0ccd-48db-bb3c-ce58cc7a9ae2/256x192.jpg",
          "viewOrder": 9
        },
        {
          "mediaId": 55079,
          "title": "Inside Aman New York, Where a Stay Can Cost $15,000 a Night ...",
          "url": "https://pkslist.azureedge.net/media/images/1/960a2798-1258-427f-aaa5-769cdfcb7293/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/960a2798-1258-427f-aaa5-769cdfcb7293/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/960a2798-1258-427f-aaa5-769cdfcb7293/256x192.jpg",
          "viewOrder": 10
        }
      ],
      "geographicArea": {
        "geographicAreaId": 3173,
        "latitude": 40.7628238,
        "longitude": -73.9745971,
        "parentGeographicAreaId": 0,
        "title": "The Crown Building, 730 5th Ave, New York, NY 10019, USA",
        "updated": null,
        "created": "2022-03-15T13:11:00"
      },
      "personalities": [
        {
          "tagId": 48,
          "tagName": "Iconic/Landmark ",
          "tagType": 1
        },
        {
          "tagId": 51,
          "tagName": "Style Icon",
          "tagType": 1
        }
      ],
      "tags": [
        {
          "tagId": 4,
          "tagName": "Spa",
          "tagType": 2
        },
        {
          "tagId": 62,
          "tagName": "Nightlife",
          "tagType": 2
        },
        {
          "tagId": 65,
          "tagName": "Shopping",
          "tagType": 2
        },
        {
          "tagId": 86,
          "tagName": "Indoor Pool",
          "tagType": 2
        },
        {
          "tagId": 202,
          "tagName": "Private Terraces",
          "tagType": 2
        },
        {
          "tagId": 216,
          "tagName": "Museum",
          "tagType": 2
        },
        {
          "tagId": 244,
          "tagName": "Members' Club",
          "tagType": 2
        },
        {
          "tagId": 253,
          "tagName": "Jazz Bar",
          "tagType": 2
        },
        {
          "tagId": 283,
          "tagName": "Restaurants",
          "tagType": 2
        },
        {
          "tagId": 301,
          "tagName": "Wine Bar",
          "tagType": 2
        },
        {
          "tagId": 323,
          "tagName": "City Walk",
          "tagType": 2
        }
      ],
      "venueCategoryAssigns": [
        {
          "venueCategoryAssignId": 763,
          "venueCategoryId": 35,
          "venueId": 3385,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 35,
            "title": "New Openings",
            "region": "Global",
            "viewOrder": 1,
            "updated": "2022-07-26T08:22:00",
            "created": "2022-07-26T08:22:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 800,
          "venueCategoryId": 20,
          "venueId": 3385,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 20,
            "title": "BucketList Hotels",
            "region": "Global",
            "viewOrder": 0,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        }
      ]
    },
    {
      "venueId": 3361,
      "venueCategoryId": 0,
      "status": 1,
      "title": "Kisawa",
      "description": "Kisawa means unbreakable, and this defines our commitment: to build a bond between people and place, life and land. Our mission is to bring wilderness and wellbeing together, carefully and comfortably. Our hospitality is generous and personalised, with a core sense of care at its heart. In all that we do, we want to support and enable our guests to do as they wish, when they wish.\n\nKisawa is a 300-hectare sanctuary of forest, beach and sand dunes, located on the southern tip of Benguerra Island. Benguerra Island is situated 14 kilometres off Mozambique, on the Eastern coast of Africa. Part of the Bazaruto Archipelago, the neighbouring WWF National Marine Park is home to some of the richest and least explored\nsubtropical ecosystems in the Indian Ocean.",
      "brand": "",
      "blockQuote": "Sail into the sunset on a traditional dhow for the most romantic evening.",
      "emailAddress": "reservations@kisawasanctuary.com",
      "website": "kisawasanctuary.com/",
      "bookingURL": "https://kisawasanctuary.com/booking/?startDate=2022-02-15&endDate=2022-02-18&adults=2&children=0&young=0&rooms=1&adults1=-1&children1=-1&young1=-1&iata=96027890",
      "redirectNotes": "Kisawa's reservations system",
      "phoneNumber": "+ 971 4 875 2323",
      "addressOne": "",
      "addressTwo": "",
      "city": "Benguerra Island",
      "county": "",
      "region": "",
      "country": "Mozambique",
      "rooms": 12,
      "dollar": 3,
      "updated": "2022-02-15T14:53:40.433",
      "created": "2022-02-15T14:09:30.427",
      "medias": [
        {
          "mediaId": 52470,
          "title": "Kisawa Residences - Kisawa Sanctuary",
          "url": "https://pkslist.azureedge.net/media/images/1/c920814e-28f6-4576-9ab7-efd6e98b96e8/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/c920814e-28f6-4576-9ab7-efd6e98b96e8/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/c920814e-28f6-4576-9ab7-efd6e98b96e8/256x192.jpg",
          "viewOrder": 0
        },
        {
          "mediaId": 52445,
          "title": "Luxury Mozambique Resort | Boutique Beach Escape | Kisawa Sanctuary",
          "url": "https://pkslist.azureedge.net/media/images/1/a4dbf99a-cdef-4b32-b1f0-2a813ab053d0/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/a4dbf99a-cdef-4b32-b1f0-2a813ab053d0/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/a4dbf99a-cdef-4b32-b1f0-2a813ab053d0/256x192.jpg",
          "viewOrder": 1
        },
        {
          "mediaId": 52457,
          "title": "An Insight Into The Incredible Kisawa Sanctuary On Benguerra ...",
          "url": "https://pkslist.azureedge.net/media/images/1/cf51cb9d-5031-40d3-a844-cae6a8a02f26/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/cf51cb9d-5031-40d3-a844-cae6a8a02f26/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/cf51cb9d-5031-40d3-a844-cae6a8a02f26/256x192.jpg",
          "viewOrder": 2
        },
        {
          "mediaId": 52442,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/26a43ad4-16ab-4104-90bc-9bfca5afe9c8/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/26a43ad4-16ab-4104-90bc-9bfca5afe9c8/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/26a43ad4-16ab-4104-90bc-9bfca5afe9c8/256x192.jpeg",
          "viewOrder": 3
        },
        {
          "mediaId": 52460,
          "title": "Kisawa Sanctuary - Plus Development | Plus Development",
          "url": "https://pkslist.azureedge.net/media/images/1/0cb0aee9-eab7-4649-ae3d-7118dac630f9/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/0cb0aee9-eab7-4649-ae3d-7118dac630f9/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/0cb0aee9-eab7-4649-ae3d-7118dac630f9/256x192.jpg",
          "viewOrder": 4
        },
        {
          "mediaId": 52464,
          "title": "Culinary - Kisawa Sanctuary",
          "url": "https://pkslist.azureedge.net/media/images/1/10ba0179-995b-4961-ab9d-b4999b890dad/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/10ba0179-995b-4961-ab9d-b4999b890dad/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/10ba0179-995b-4961-ab9d-b4999b890dad/256x192.jpg",
          "viewOrder": 5
        },
        {
          "mediaId": 52462,
          "title": "Activities & Adventures At Kisawa Sanctuary | Kisawa Sanctuary",
          "url": "https://pkslist.azureedge.net/media/images/1/43b0232a-600b-4790-907e-1ce83b135e13/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/43b0232a-600b-4790-907e-1ce83b135e13/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/43b0232a-600b-4790-907e-1ce83b135e13/256x192.jpg",
          "viewOrder": 6
        },
        {
          "mediaId": 52450,
          "title": "Kisawa Sanctuary, Mozambique: Barefoot on Benguerra | OutThere ...",
          "url": "https://pkslist.azureedge.net/media/images/1/aab5f95b-82a6-4b15-bc30-9392cfab7d6f/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/aab5f95b-82a6-4b15-bc30-9392cfab7d6f/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/aab5f95b-82a6-4b15-bc30-9392cfab7d6f/256x192.jpg",
          "viewOrder": 7
        },
        {
          "mediaId": 52439,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/2d9163fe-be7f-4ae7-91c1-97e6887c88c6/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/2d9163fe-be7f-4ae7-91c1-97e6887c88c6/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/2d9163fe-be7f-4ae7-91c1-97e6887c88c6/256x192.jpeg",
          "viewOrder": 8
        },
        {
          "mediaId": 52458,
          "title": "Kisawa Sanctuary will be a 740-acre resort 3D-printed with local ...",
          "url": "https://pkslist.azureedge.net/media/images/1/7827d019-1eda-41f8-99f2-ace342b8d087/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/7827d019-1eda-41f8-99f2-ace342b8d087/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/7827d019-1eda-41f8-99f2-ace342b8d087/256x192.jpg",
          "viewOrder": 9
        },
        {
          "mediaId": 52443,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/529d93c8-1f05-4d2e-a3df-5365d7e915c9/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/529d93c8-1f05-4d2e-a3df-5365d7e915c9/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/529d93c8-1f05-4d2e-a3df-5365d7e915c9/256x192.jpeg",
          "viewOrder": 10
        },
        {
          "mediaId": 52452,
          "title": "Kisawa Sanctuary - Plus Development | Plus Development",
          "url": "https://pkslist.azureedge.net/media/images/1/4c17fd9d-a646-4005-8dff-04aa0821951f/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/4c17fd9d-a646-4005-8dff-04aa0821951f/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/4c17fd9d-a646-4005-8dff-04aa0821951f/256x192.jpg",
          "viewOrder": 11
        },
        {
          "mediaId": 52441,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/2b455911-7cee-4692-8c44-79b632c9d4da/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/2b455911-7cee-4692-8c44-79b632c9d4da/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/2b455911-7cee-4692-8c44-79b632c9d4da/256x192.jpeg",
          "viewOrder": 12
        },
        {
          "mediaId": 52455,
          "title": "Out of Africa. Mozambique's Kisawa Sanctuary, Pure Island ...",
          "url": "https://pkslist.azureedge.net/media/images/1/2316241d-b00f-4a19-85bc-d37bbb70fb7e/1200x900.png",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/2316241d-b00f-4a19-85bc-d37bbb70fb7e/800x600.png",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/2316241d-b00f-4a19-85bc-d37bbb70fb7e/256x192.png",
          "viewOrder": 13
        }
      ],
      "geographicArea": {
        "geographicAreaId": 3149,
        "latitude": -21.9123046,
        "longitude": 35.445652,
        "parentGeographicAreaId": 0,
        "title": "Kisawa Sanctuary Benguerra Island, 1304, Mozambique",
        "updated": null,
        "created": "2022-02-15T14:20:00"
      },
      "personalities": [
        {
          "tagId": 50,
          "tagName": "Barefoot Luxury",
          "tagType": 1
        },
        {
          "tagId": 53,
          "tagName": "Cutting Edge",
          "tagType": 1
        }
      ],
      "tags": [
        {
          "tagId": 4,
          "tagName": "Spa",
          "tagType": 2
        },
        {
          "tagId": 58,
          "tagName": "Beach",
          "tagType": 2
        },
        {
          "tagId": 82,
          "tagName": "Horseback Riding",
          "tagType": 2
        },
        {
          "tagId": 90,
          "tagName": "Water Sports",
          "tagType": 2
        },
        {
          "tagId": 92,
          "tagName": "Infinity Pool",
          "tagType": 2
        },
        {
          "tagId": 109,
          "tagName": "Fishing",
          "tagType": 2
        },
        {
          "tagId": 116,
          "tagName": "Scuba Diving",
          "tagType": 2
        },
        {
          "tagId": 124,
          "tagName": "Helicopter",
          "tagType": 2
        },
        {
          "tagId": 183,
          "tagName": "Butler Service",
          "tagType": 2
        },
        {
          "tagId": 198,
          "tagName": "Boat Excursion",
          "tagType": 2
        },
        {
          "tagId": 283,
          "tagName": "Restaurants",
          "tagType": 2
        }
      ],
      "venueCategoryAssigns": [
        {
          "venueCategoryAssignId": 750,
          "venueCategoryId": 15,
          "venueId": 3361,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 15,
            "title": "Beach Paradise",
            "region": "Global",
            "viewOrder": 6,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 751,
          "venueCategoryId": 20,
          "venueId": 3361,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 20,
            "title": "BucketList Hotels",
            "region": "Global",
            "viewOrder": 0,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        }
      ]
    },
    {
      "venueId": 2712,
      "venueCategoryId": 0,
      "status": 1,
      "title": "Zannier Hotels Sonop",
      "description": "Set within our own 13,800 private acres of untouched Namibian desert wilderness, our lodge with 10 tented suites is constructed on top of granite boulders in authentic 1920’s British colonial style. Stretching from horizon to horizon, captivating beauty and boundless freedom surround you, with adventures from sunrise to sunset.\n\nOur guided excursions include horse-riding, electric fat bike or walking trails, hot air balloon safari and private sunrise or sunset safaris. After dark, the magic continues with an open-air cinema and stargazing under the endless African skies.",
      "brand": "",
      "blockQuote": "One of the most remote and exceptional landscapes I've ever stayed in.",
      "emailAddress": "reservations@sonop.com",
      "website": "https://www.zannierhotels.com/sonop/en/",
      "bookingURL": "https://be.synxis.com/?adult=1&agencyid=96027890&arrive=2023-12-28&chain=22139&child=0&config=EXTRA&currency=NAD&depart=2023-12-29&hotel=5971&level=hotel&locale=en-US&rooms=1",
      "redirectNotes": "Zannier Hotels Sonop's reservations system",
      "phoneNumber": "+264 81 125 4932",
      "addressOne": "",
      "addressTwo": "",
      "city": "Sonop",
      "county": "",
      "region": "",
      "country": "Namibia",
      "rooms": 10,
      "dollar": 3,
      "updated": "2023-12-28T12:29:13.207",
      "created": "2020-05-18T15:53:17.897",
      "medias": [
        {
          "mediaId": 63434,
          "title": "Review: Zannier Hotels Sonop (Namibia) - the Luxury Travel Expert",
          "url": "https://pkslist.azureedge.net/media/images/1/1e631782-8ada-4bd4-8495-703ed58115bc/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/1e631782-8ada-4bd4-8495-703ed58115bc/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/1e631782-8ada-4bd4-8495-703ed58115bc/256x192.jpg",
          "viewOrder": 0
        },
        {
          "mediaId": 36633,
          "title": "Sonop (Namib Desert, Karas) Verified Reviews | Tablet Hotels",
          "url": "https://pkslist.azureedge.net/media/images/1/445f8ce5-6d81-42c9-8319-82521ce1e3f0/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/445f8ce5-6d81-42c9-8319-82521ce1e3f0/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/445f8ce5-6d81-42c9-8319-82521ce1e3f0/256x192.jpg",
          "viewOrder": 6
        },
        {
          "mediaId": 63419,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/9059f33f-5187-4cf4-a8a0-6cc20369ac5f/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/9059f33f-5187-4cf4-a8a0-6cc20369ac5f/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/9059f33f-5187-4cf4-a8a0-6cc20369ac5f/256x192.jpeg",
          "viewOrder": 7
        },
        {
          "mediaId": 63420,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/524cc400-7363-4383-b1d2-c8becb76a1fc/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/524cc400-7363-4383-b1d2-c8becb76a1fc/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/524cc400-7363-4383-b1d2-c8becb76a1fc/256x192.jpeg",
          "viewOrder": 8
        },
        {
          "mediaId": 63421,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/d95ab006-79e0-454c-95e1-533ce1a1d52d/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/d95ab006-79e0-454c-95e1-533ce1a1d52d/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/d95ab006-79e0-454c-95e1-533ce1a1d52d/256x192.jpeg",
          "viewOrder": 9
        },
        {
          "mediaId": 63424,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/cce89346-0f45-40ac-ab63-afa6b3621b8b/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/cce89346-0f45-40ac-ab63-afa6b3621b8b/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/cce89346-0f45-40ac-ab63-afa6b3621b8b/256x192.jpeg",
          "viewOrder": 10
        },
        {
          "mediaId": 63423,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/f3875aad-5e75-4196-9794-882ccd82466d/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/f3875aad-5e75-4196-9794-882ccd82466d/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/f3875aad-5e75-4196-9794-882ccd82466d/256x192.jpeg",
          "viewOrder": 12
        },
        {
          "mediaId": 63425,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/c0ea4e30-df5b-40f4-b400-d23f79402c6e/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/c0ea4e30-df5b-40f4-b400-d23f79402c6e/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/c0ea4e30-df5b-40f4-b400-d23f79402c6e/256x192.jpeg",
          "viewOrder": 14
        },
        {
          "mediaId": 63418,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/ae9db308-9073-49cd-ba5a-bca301d576c5/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/ae9db308-9073-49cd-ba5a-bca301d576c5/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/ae9db308-9073-49cd-ba5a-bca301d576c5/256x192.jpeg",
          "viewOrder": 15
        },
        {
          "mediaId": 63422,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/e69f2d52-7776-4aa5-81d7-3c5b89000691/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/e69f2d52-7776-4aa5-81d7-3c5b89000691/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/e69f2d52-7776-4aa5-81d7-3c5b89000691/256x192.jpeg",
          "viewOrder": 16
        },
        {
          "mediaId": 36613,
          "title": "Zannier Sonop Lodge - wbpstars.com - World's Best Places",
          "url": "https://pkslist.azureedge.net/media/images/1/d832b04f-018b-463f-9428-7f2fdcf35deb/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/d832b04f-018b-463f-9428-7f2fdcf35deb/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/d832b04f-018b-463f-9428-7f2fdcf35deb/256x192.jpg",
          "viewOrder": 17
        },
        {
          "mediaId": 36629,
          "title": "Hotel Sonop - Zannier Hotels Group - Namibia",
          "url": "https://pkslist.azureedge.net/media/images/1/3457da08-de7f-4231-95c4-a68542efbad7/1200x900.png",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/3457da08-de7f-4231-95c4-a68542efbad7/800x600.png",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/3457da08-de7f-4231-95c4-a68542efbad7/256x192.png",
          "viewOrder": 18
        }
      ],
      "geographicArea": {
        "geographicAreaId": 2499,
        "latitude": -25.4977992,
        "longitude": 16.1818891,
        "parentGeographicAreaId": 0,
        "title": "Sonop, Namibia",
        "updated": null,
        "created": "2020-05-18T16:02:00"
      },
      "personalities": [
        {
          "tagId": 57,
          "tagName": "Romantic",
          "tagType": 1
        },
        {
          "tagId": 359,
          "tagName": "Colonial Chic",
          "tagType": 1
        }
      ],
      "tags": [
        {
          "tagId": 63,
          "tagName": "Safari",
          "tagType": 2
        },
        {
          "tagId": 82,
          "tagName": "Horseback Riding",
          "tagType": 2
        },
        {
          "tagId": 87,
          "tagName": "Outdoor Pool",
          "tagType": 2
        },
        {
          "tagId": 101,
          "tagName": "Cinema",
          "tagType": 2
        },
        {
          "tagId": 133,
          "tagName": "Glamping",
          "tagType": 2
        },
        {
          "tagId": 153,
          "tagName": "Cigar Lounge",
          "tagType": 2
        },
        {
          "tagId": 159,
          "tagName": "Mountain Biking",
          "tagType": 2
        },
        {
          "tagId": 207,
          "tagName": "Lounge/Bar",
          "tagType": 2
        },
        {
          "tagId": 263,
          "tagName": "Star Gazing",
          "tagType": 2
        },
        {
          "tagId": 265,
          "tagName": "Hot Air Balloon",
          "tagType": 2
        },
        {
          "tagId": 287,
          "tagName": "Wildlife",
          "tagType": 2
        }
      ],
      "venueCategoryAssigns": [
        {
          "venueCategoryAssignId": 566,
          "venueCategoryId": 20,
          "venueId": 2712,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 20,
            "title": "BucketList Hotels",
            "region": "Global",
            "viewOrder": 0,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 567,
          "venueCategoryId": 13,
          "venueId": 2712,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 13,
            "title": "Wildlife Encounters",
            "region": "Global",
            "viewOrder": 4,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        }
      ]
    },
    {
      "venueId": 1750,
      "venueCategoryId": 20,
      "status": 1,
      "title": "The Brando",
      "description": "The Brando is a unique luxury resort on French Polynesia’s breathtakingly beautiful private island of Tetiaroa – an atoll composed of a dozen small islands surrounding a sparkling lagoon 30 miles northeast of Tahiti. The Brando offers carefree luxury in the midst of pristine nature. With access to the island by private plane, the resort features 35 villas on white-sand beaches frequented by sea turtles, manta rays and exotic birds. The resort was designed to reflect Polynesian lifestyles and culture.",
      "brand": null,
      "blockQuote": "One of the world's most refined, barefoot luxury private island resorts.",
      "emailAddress": "reservation@thebrando.com",
      "website": "thebrando.com/",
      "bookingURL": "https://tainterwh.webhotel.microsapdc.com/bp/search_rooms.jsp",
      "redirectNotes": null,
      "phoneNumber": "+689 40 866 300",
      "addressOne": "",
      "addressTwo": "",
      "city": "Tetiaroa Private Island",
      "county": "",
      "region": "",
      "country": "French Polynesia",
      "rooms": 35,
      "dollar": 3,
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00",
      "medias": [
        {
          "mediaId": 20602,
          "title": "The Brando - luxury hotel French Polynesia - Original Travel",
          "url": "https://pkslist.azureedge.net/media/images/1/7434e98c-49f3-4478-93bf-d64a2e27d62b/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/7434e98c-49f3-4478-93bf-d64a2e27d62b/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/7434e98c-49f3-4478-93bf-d64a2e27d62b/256x192.jpg",
          "viewOrder": 0
        },
        {
          "mediaId": 20597,
          "title": "The Brando Tetiaroa, French Polynesia - Condé Nast Traveler",
          "url": "https://pkslist.azureedge.net/media/images/1/b982f53a-c3af-4390-afd9-aeec6d4f05bc/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/b982f53a-c3af-4390-afd9-aeec6d4f05bc/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/b982f53a-c3af-4390-afd9-aeec6d4f05bc/256x192.jpg",
          "viewOrder": 1
        },
        {
          "mediaId": 20870,
          "title": "The Brando | Tahiti | Luxury Holidays | Turquoise Holidays",
          "url": "https://pkslist.azureedge.net/media/images/1/378bd8b2-fed8-4a67-ad26-8ac4888e4afc/1200x900.jpg&w=0&h=0",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/378bd8b2-fed8-4a67-ad26-8ac4888e4afc/800x600.jpg&w=0&h=0",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/378bd8b2-fed8-4a67-ad26-8ac4888e4afc/256x192.jpg&w=0&h=0",
          "viewOrder": 2
        },
        {
          "mediaId": 20600,
          "title": "The Brando | Tahiti | Luxury Holidays | Turquoise Holidays",
          "url": "https://pkslist.azureedge.net/media/images/1/66bdb17b-f00b-4199-9fe8-88ad2f32c10d/1200x900.jpg&w=0&h=0",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/66bdb17b-f00b-4199-9fe8-88ad2f32c10d/800x600.jpg&w=0&h=0",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/66bdb17b-f00b-4199-9fe8-88ad2f32c10d/256x192.jpg&w=0&h=0",
          "viewOrder": 3
        },
        {
          "mediaId": 20869,
          "title": "The Brando Hotel Review, French Polynesia | Travel",
          "url": "https://pkslist.azureedge.net/media/images/1/65ab32d3-3355-4ebd-81b7-270cd5d9222d/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/65ab32d3-3355-4ebd-81b7-270cd5d9222d/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/65ab32d3-3355-4ebd-81b7-270cd5d9222d/256x192.jpg",
          "viewOrder": 4
        },
        {
          "mediaId": 21289,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/ea601f36-b1ed-4ec4-a6f6-e425e1bfae2c/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/ea601f36-b1ed-4ec4-a6f6-e425e1bfae2c/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/ea601f36-b1ed-4ec4-a6f6-e425e1bfae2c/256x192.jpg",
          "viewOrder": 5
        },
        {
          "mediaId": 20603,
          "title": "The Brando | Tahitian Resort | Andrew Harper Travel",
          "url": "https://pkslist.azureedge.net/media/images/1/d45c9465-ad61-4515-af6a-9971327be6ef/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/d45c9465-ad61-4515-af6a-9971327be6ef/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/d45c9465-ad61-4515-af6a-9971327be6ef/256x192.jpg",
          "viewOrder": 6
        },
        {
          "mediaId": 20601,
          "title": "The Brando, Eco-Resort, on Tetiaroa | Tahiti.com",
          "url": "https://pkslist.azureedge.net/media/images/1/b43818f4-ba97-45ec-93f2-d3296e449594/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/b43818f4-ba97-45ec-93f2-d3296e449594/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/b43818f4-ba97-45ec-93f2-d3296e449594/256x192.jpg",
          "viewOrder": 7
        },
        {
          "mediaId": 20606,
          "title": "The Brando, Eco-Resort, on Tetiaroa | Tahiti.com",
          "url": "https://pkslist.azureedge.net/media/images/1/2b5a40a5-974f-4309-b17e-e3f5e63a65d9/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/2b5a40a5-974f-4309-b17e-e3f5e63a65d9/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/2b5a40a5-974f-4309-b17e-e3f5e63a65d9/256x192.jpg",
          "viewOrder": 8
        },
        {
          "mediaId": 20598,
          "title": "The Brando, Eco-Resort, on Tetiaroa | Tahiti.com",
          "url": "https://pkslist.azureedge.net/media/images/1/b73fb5fa-e3e8-4026-8618-731da0122931/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/b73fb5fa-e3e8-4026-8618-731da0122931/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/b73fb5fa-e3e8-4026-8618-731da0122931/256x192.jpg",
          "viewOrder": 9
        }
      ],
      "geographicArea": {
        "geographicAreaId": 1566,
        "latitude": -17.0227731,
        "longitude": -149.5958988,
        "parentGeographicAreaId": 0,
        "title": "Onetahi, French Polynesia",
        "updated": "2020-05-06T12:20:00",
        "created": "2020-05-06T12:20:00"
      },
      "personalities": [
        {
          "tagId": 50,
          "tagName": "Barefoot Luxury",
          "tagType": 1
        },
        {
          "tagId": 157,
          "tagName": "Beach Escape",
          "tagType": 1
        }
      ],
      "tags": [
        {
          "tagId": 4,
          "tagName": "Spa",
          "tagType": 2
        },
        {
          "tagId": 87,
          "tagName": "Outdoor Pool",
          "tagType": 2
        },
        {
          "tagId": 88,
          "tagName": "Tennis",
          "tagType": 2
        },
        {
          "tagId": 90,
          "tagName": "Water Sports",
          "tagType": 2
        },
        {
          "tagId": 109,
          "tagName": "Fishing",
          "tagType": 2
        },
        {
          "tagId": 116,
          "tagName": "Scuba Diving",
          "tagType": 2
        },
        {
          "tagId": 135,
          "tagName": "Bird Watching",
          "tagType": 2
        },
        {
          "tagId": 172,
          "tagName": "Biking",
          "tagType": 2
        },
        {
          "tagId": 181,
          "tagName": "Private/Suite Pools",
          "tagType": 2
        },
        {
          "tagId": 193,
          "tagName": "Outdoor Dining",
          "tagType": 2
        },
        {
          "tagId": 198,
          "tagName": "Boat Excursion",
          "tagType": 2
        },
        {
          "tagId": 206,
          "tagName": "Private Beach",
          "tagType": 2
        },
        {
          "tagId": 318,
          "tagName": "Stand Up Paddle",
          "tagType": 2
        }
      ],
      "venueCategoryAssigns": [
        {
          "venueCategoryAssignId": 308,
          "venueCategoryId": 20,
          "venueId": 1750,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 20,
            "title": "BucketList Hotels",
            "region": "Global",
            "viewOrder": 0,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 358,
          "venueCategoryId": 19,
          "venueId": 1750,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 19,
            "title": "Private Islands",
            "region": "Global",
            "viewOrder": 15,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 359,
          "venueCategoryId": 15,
          "venueId": 1750,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 15,
            "title": "Beach Paradise",
            "region": "Global",
            "viewOrder": 6,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 360,
          "venueCategoryId": 5,
          "venueId": 1750,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 5,
            "title": "Romantic Getaways",
            "region": "Global",
            "viewOrder": 20,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        }
      ]
    },
    {
      "venueId": 1669,
      "venueCategoryId": 20,
      "status": 1,
      "title": "Amankora - Paro Lodge",
      "description": "At an elevation of 2,250 metres, Paro Valley is the entry point for most journeys through the Kingdom. Surrounded by pine forests, the 24-Suite retreat contrasts rustic elements with contemporary design. Guest Suites offer a combined lounge and bedroom furnished with a king-size bed, banquette window seat, reading chair and traditional bukhari wood-burning stove.",
      "brand": "Amanresorts",
      "blockQuote": "It's worth it to get up early and hike to Tiger's Nest Monastery to beat the crowds.",
      "emailAddress": "amankora@aman.com",
      "website": "aman.com/resorts/amankora/lodges/paro",
      "bookingURL": "https://gc.synxis.com/rez.aspx?Chain=16840&Hotel=62458&Lang=1&template=RBEKORA&shell=RBE2&iata=96027890",
      "redirectNotes": "Amankora's reservations system",
      "phoneNumber": "+975 2 331 333 ",
      "addressOne": "",
      "addressTwo": "",
      "city": "Paro",
      "county": "",
      "region": "",
      "country": "Bhutan",
      "rooms": 24,
      "dollar": 3,
      "updated": "2020-05-13T09:30:00",
      "created": "2020-05-06T12:20:00",
      "medias": [
        {
          "mediaId": 19140,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/a4adbfdd-9fd4-46d0-b8e8-a155b9e4b447/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/a4adbfdd-9fd4-46d0-b8e8-a155b9e4b447/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/a4adbfdd-9fd4-46d0-b8e8-a155b9e4b447/256x192.jpg",
          "viewOrder": 0
        },
        {
          "mediaId": 19133,
          "title": "Amankora Paro Hotel Review, Bhutan | Telegraph Travel",
          "url": "https://pkslist.azureedge.net/media/images/1/c0b7714a-d3b6-4884-9a49-1456e244a8f6/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/c0b7714a-d3b6-4884-9a49-1456e244a8f6/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/c0b7714a-d3b6-4884-9a49-1456e244a8f6/256x192.jpg",
          "viewOrder": 1
        },
        {
          "mediaId": 19131,
          "title": "Lodges at Amankora - Luxury Bhutan Accommodation - Aman",
          "url": "https://pkslist.azureedge.net/media/images/1/fdcf5e20-c5c9-4b80-9c61-6d73d95fc99e/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/fdcf5e20-c5c9-4b80-9c61-6d73d95fc99e/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/fdcf5e20-c5c9-4b80-9c61-6d73d95fc99e/256x192.jpg",
          "viewOrder": 2
        },
        {
          "mediaId": 19325,
          "title": "Amankora, Bhutan: hotel of the week - WordSmith: The Mr & Mrs ...",
          "url": "https://pkslist.azureedge.net/media/images/1/39f5c87d-5bd5-426c-a265-f40d549034aa/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/39f5c87d-5bd5-426c-a265-f40d549034aa/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/39f5c87d-5bd5-426c-a265-f40d549034aa/256x192.jpg",
          "viewOrder": 3
        },
        {
          "mediaId": 19137,
          "title": "Amankora Paro | Luxury Resort in Bumthang | Bhutan | andBeyond",
          "url": "https://pkslist.azureedge.net/media/images/1/40708f17-abaa-472b-b948-b71019a4f4fb/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/40708f17-abaa-472b-b948-b71019a4f4fb/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/40708f17-abaa-472b-b948-b71019a4f4fb/256x192.jpg",
          "viewOrder": 4
        },
        {
          "mediaId": 19139,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/cd8fd6c9-6dc8-490a-9a43-efd03b4ffc09/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/cd8fd6c9-6dc8-490a-9a43-efd03b4ffc09/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/cd8fd6c9-6dc8-490a-9a43-efd03b4ffc09/256x192.jpg",
          "viewOrder": 5
        },
        {
          "mediaId": 19136,
          "title": "Amankora Paro | Luxury Resort in Bumthang | Bhutan | andBeyond",
          "url": "https://pkslist.azureedge.net/media/images/1/b13cd4fe-3a80-413f-942d-29b37d4689fb/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/b13cd4fe-3a80-413f-942d-29b37d4689fb/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/b13cd4fe-3a80-413f-942d-29b37d4689fb/256x192.jpg",
          "viewOrder": 7
        }
      ],
      "geographicArea": {
        "geographicAreaId": 1483,
        "latitude": 27.4969234,
        "longitude": 89.32194389999995,
        "parentGeographicAreaId": 0,
        "title": "Bhutan",
        "updated": "2020-05-06T12:20:00",
        "created": "2020-05-06T12:20:00"
      },
      "personalities": [
        {
          "tagId": 47,
          "tagName": "Intimate Luxury ",
          "tagType": 1
        },
        {
          "tagId": 56,
          "tagName": "Quiet/Peaceful",
          "tagType": 1
        }
      ],
      "tags": [
        {
          "tagId": 4,
          "tagName": "Spa",
          "tagType": 2
        },
        {
          "tagId": 59,
          "tagName": "Cultural",
          "tagType": 2
        },
        {
          "tagId": 60,
          "tagName": "Gastronomic",
          "tagType": 2
        },
        {
          "tagId": 171,
          "tagName": "Trekking",
          "tagType": 2
        },
        {
          "tagId": 207,
          "tagName": "Lounge/Bar",
          "tagType": 2
        },
        {
          "tagId": 226,
          "tagName": "Outdoor Fireplace",
          "tagType": 2
        },
        {
          "tagId": 243,
          "tagName": "Outdoor Activities",
          "tagType": 2
        },
        {
          "tagId": 256,
          "tagName": "Boutique",
          "tagType": 2
        },
        {
          "tagId": 273,
          "tagName": "Archery",
          "tagType": 2
        },
        {
          "tagId": 296,
          "tagName": "Guided Walk",
          "tagType": 2
        },
        {
          "tagId": 319,
          "tagName": "Temples",
          "tagType": 2
        }
      ],
      "venueCategoryAssigns": [
        {
          "venueCategoryAssignId": 298,
          "venueCategoryId": 20,
          "venueId": 1669,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 20,
            "title": "BucketList Hotels",
            "region": "Global",
            "viewOrder": 0,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        }
      ]
    },
    {
      "venueId": 1648,
      "venueCategoryId": 0,
      "status": 1,
      "title": "Vik Chile",
      "description": "Vik Chile is comprised of 22 luxurious suites in a glass and golden titanium sculptural building and 7 remarkable glass bungalows called Puro Vik, all situated on a hilltop boasting breathtaking 360-degree views of the 11,000 acre Viña Vik Vineyard and surrounding nature. \n\nNestled in a magnificent vineyard in a terroir known by the natives as Millahue, the “place of gold”, so named for its nature, beauty, diversity and the gold found in the area... and now for the VIK wines. Located just two hours from Santiago in the central area of Chile, Vik Chile and Viña Vik Winery are the perfect place to relax in a tranquil atmosphere at the renowned Wine Spa. Enjoy the exceptional climate with warm sunny days and cool crisp evenings from September through May.",
      "brand": null,
      "blockQuote": "A horseback ride through the valleys and vineyard is breathtakingly beautiful.",
      "emailAddress": "info@vikchile.com",
      "website": "vikchile.com/",
      "bookingURL": "https://secure.guestcentric.net/api/bg/book.php?apikey=1bbbdecb2b8018f7356f18c37c6c9267&s=default&l=en_US",
      "redirectNotes": "Vik Chile's reservations system",
      "phoneNumber": "+56 9 6193 1754",
      "addressOne": "",
      "addressTwo": "",
      "city": "Millahue",
      "county": "",
      "region": "",
      "country": "Chile",
      "rooms": 29,
      "dollar": 3,
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00",
      "medias": [
        {
          "mediaId": 27938,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/8e049a7e-3013-449a-8029-0192f3f40b3b/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/8e049a7e-3013-449a-8029-0192f3f40b3b/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/8e049a7e-3013-449a-8029-0192f3f40b3b/256x192.jpg",
          "viewOrder": 0
        },
        {
          "mediaId": 18752,
          "title": "Vina VIK - Wine Tours in Chile - Visit Colchagua",
          "url": "https://pkslist.azureedge.net/media/images/1/22d491ea-e22e-4d13-abbb-de8fff755078/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/22d491ea-e22e-4d13-abbb-de8fff755078/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/22d491ea-e22e-4d13-abbb-de8fff755078/256x192.jpg",
          "viewOrder": 1
        },
        {
          "mediaId": 18754,
          "title": "Vik Chile, Palmilla Abajo, Chile (deals from $1,053 for 2018/19)",
          "url": "https://pkslist.azureedge.net/media/images/1/d4a071d5-7695-4bab-92d3-ce40e1610db3/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/d4a071d5-7695-4bab-92d3-ce40e1610db3/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/d4a071d5-7695-4bab-92d3-ce40e1610db3/256x192.jpg",
          "viewOrder": 2
        },
        {
          "mediaId": 27936,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/ea18ccf8-5948-4866-9916-239f941d0842/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/ea18ccf8-5948-4866-9916-239f941d0842/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/ea18ccf8-5948-4866-9916-239f941d0842/256x192.jpg",
          "viewOrder": 3
        },
        {
          "mediaId": 27937,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/e52b0b15-cd45-46f5-a15d-0115e74a017b/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/e52b0b15-cd45-46f5-a15d-0115e74a017b/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/e52b0b15-cd45-46f5-a15d-0115e74a017b/256x192.jpg",
          "viewOrder": 4
        },
        {
          "mediaId": 27934,
          "title": "Hotel Puro Vik – Chile | BWT Operadora",
          "url": "https://pkslist.azureedge.net/media/images/1/29e51b26-5f6d-4522-bcea-13eb0378d140/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/29e51b26-5f6d-4522-bcea-13eb0378d140/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/29e51b26-5f6d-4522-bcea-13eb0378d140/256x192.jpg",
          "viewOrder": 5
        },
        {
          "mediaId": 18751,
          "title": "Vik Chile, Palmilla Abajo, Chile (deals from $1,053 for 2018/19)",
          "url": "https://pkslist.azureedge.net/media/images/1/91dcbe5d-6fb6-4fb7-b9f2-78ad22ca73bc/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/91dcbe5d-6fb6-4fb7-b9f2-78ad22ca73bc/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/91dcbe5d-6fb6-4fb7-b9f2-78ad22ca73bc/256x192.jpg",
          "viewOrder": 6
        },
        {
          "mediaId": 18763,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/e720b4fd-5060-4bc9-88a4-188f4c5f1aab/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/e720b4fd-5060-4bc9-88a4-188f4c5f1aab/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/e720b4fd-5060-4bc9-88a4-188f4c5f1aab/256x192.jpg",
          "viewOrder": 9
        },
        {
          "mediaId": 18760,
          "title": "Vik Chile English",
          "url": "https://pkslist.azureedge.net/media/images/1/81804462-fa8b-49ce-88c4-40c7f112bdfc/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/81804462-fa8b-49ce-88c4-40c7f112bdfc/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/81804462-fa8b-49ce-88c4-40c7f112bdfc/256x192.jpg",
          "viewOrder": 10
        }
      ],
      "geographicArea": {
        "geographicAreaId": 1461,
        "latitude": -34.438713,
        "longitude": -71.08587999999997,
        "parentGeographicAreaId": 0,
        "title": "Valle de Millahue, San Vicente de Taguatagua, San Vicente, O'Higgins, Chile",
        "updated": "2020-05-06T12:20:00",
        "created": "2020-05-06T12:20:00"
      },
      "personalities": [
        {
          "tagId": 43,
          "tagName": "Design Slickster",
          "tagType": 1
        },
        {
          "tagId": 53,
          "tagName": "Cutting Edge",
          "tagType": 1
        }
      ],
      "tags": [
        {
          "tagId": 4,
          "tagName": "Spa",
          "tagType": 2
        },
        {
          "tagId": 61,
          "tagName": "Mountain",
          "tagType": 2
        },
        {
          "tagId": 82,
          "tagName": "Horseback Riding",
          "tagType": 2
        },
        {
          "tagId": 89,
          "tagName": "Vineyard",
          "tagType": 2
        },
        {
          "tagId": 92,
          "tagName": "Infinity Pool",
          "tagType": 2
        },
        {
          "tagId": 100,
          "tagName": "Wedding",
          "tagType": 2
        },
        {
          "tagId": 105,
          "tagName": "Afternoon Tea",
          "tagType": 2
        },
        {
          "tagId": 106,
          "tagName": "Nature",
          "tagType": 2
        },
        {
          "tagId": 125,
          "tagName": "Wineries",
          "tagType": 2
        },
        {
          "tagId": 221,
          "tagName": "Wine Tasting",
          "tagType": 2
        },
        {
          "tagId": 283,
          "tagName": "Restaurants",
          "tagType": 2
        },
        {
          "tagId": 303,
          "tagName": "Games Room",
          "tagType": 2
        }
      ],
      "venueCategoryAssigns": [
        {
          "venueCategoryAssignId": 436,
          "venueCategoryId": 20,
          "venueId": 1648,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 20,
            "title": "BucketList Hotels",
            "region": "Global",
            "viewOrder": 0,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 445,
          "venueCategoryId": 18,
          "venueId": 1648,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 18,
            "title": "Wine Country",
            "region": "Global",
            "viewOrder": 19,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 446,
          "venueCategoryId": 1,
          "venueId": 1648,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 1,
            "title": "Escape from the City",
            "region": "Global",
            "viewOrder": 14,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        }
      ]
    },
    {
      "venueId": 1385,
      "venueCategoryId": 20,
      "status": 1,
      "title": "Bisate Lodge",
      "description": "Bisate Lodge is located in the natural amphitheatre of an eroded volcanic cone – the word bisate means ‘pieces’ in Kinyarwanda, describing how the cone was once whole but worn away by natural erosion. The area has dramatic views of the peaks of the volcanoes Bisoke, Karisimbi and Mikeno rearing up through the Afro-alpine forests of nearby Volcanoes National Park. Six sumptuous en-suite rooms maximise comfort, warmth and views while retaining environmental principles and reflecting the culture of surrounding rural Rwanda.\n\nBisate is within easy driving distance of Park Headquarters, from where gorilla treks depart daily. Its vision of reforestation and rehabilitation means that each guest is invited to participate in our biodiversity conservation efforts, as well as engage and meet the local community – in addition to learning about and making a far-reaching positive impact on an iconic Critically Endangered species: the mountain gorilla.",
      "brand": null,
      "blockQuote": "You come here for the gorilla trekking which is amazing, but do try the Golden Monkey Trek to see these handsomely colored primates which are endemic to the area.",
      "emailAddress": "enquiry@wilderness.co.za",
      "website": "wilderness-safaris.com/camps/bisate-lodge",
      "bookingURL": "http://www.wilderness-safaris.com/camps/bisate-lodge/contact-us",
      "redirectNotes": null,
      "phoneNumber": "+27 11 257 5000",
      "addressOne": "",
      "addressTwo": "",
      "city": "Ruhengeri",
      "county": "",
      "region": "",
      "country": "Rwanda",
      "rooms": 6,
      "dollar": 3,
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00",
      "medias": [
        {
          "mediaId": 14893,
          "title": "Rwanda's eco-retreat Bisate Lodge is built into a volcanic cone",
          "url": "https://pkslist.azureedge.net/media/images/1/35479580-ea84-4c5e-9f15-2af28d9a64cb/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/35479580-ea84-4c5e-9f15-2af28d9a64cb/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/35479580-ea84-4c5e-9f15-2af28d9a64cb/256x192.jpg",
          "viewOrder": 0
        },
        {
          "mediaId": 14898,
          "title": "Rwanda's eco-retreat Bisate Lodge is built into a volcanic cone",
          "url": "https://pkslist.azureedge.net/media/images/1/bae36ba4-b13c-40de-8c6f-8eac8ebf5daa/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/bae36ba4-b13c-40de-8c6f-8eac8ebf5daa/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/bae36ba4-b13c-40de-8c6f-8eac8ebf5daa/256x192.jpg",
          "viewOrder": 1
        },
        {
          "mediaId": 14894,
          "title": "bisate-lodge-guest-area - Love Uganda Safaris - Gorilla Trekking ...",
          "url": "https://pkslist.azureedge.net/media/images/1/c3e09388-5a5e-4feb-a9ea-e70a6df538bb/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/c3e09388-5a5e-4feb-a9ea-e70a6df538bb/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/c3e09388-5a5e-4feb-a9ea-e70a6df538bb/256x192.jpg",
          "viewOrder": 2
        },
        {
          "mediaId": 14896,
          "title": "Rwanda's eco-retreat Bisate Lodge is built into a volcanic cone",
          "url": "https://pkslist.azureedge.net/media/images/1/c1d1db5f-22e3-4a4a-8a9e-a0a0cd1e45c8/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/c1d1db5f-22e3-4a4a-8a9e-a0a0cd1e45c8/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/c1d1db5f-22e3-4a4a-8a9e-a0a0cd1e45c8/256x192.jpg",
          "viewOrder": 3
        },
        {
          "mediaId": 14903,
          "title": "Bisate Lodge - Journeys by Design",
          "url": "https://pkslist.azureedge.net/media/images/1/871bfe86-22b6-423a-9b90-d6e7eda27a0e/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/871bfe86-22b6-423a-9b90-d6e7eda27a0e/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/871bfe86-22b6-423a-9b90-d6e7eda27a0e/256x192.jpg",
          "viewOrder": 4
        },
        {
          "mediaId": 14897,
          "title": "Bisate Lodge - Photos",
          "url": "https://pkslist.azureedge.net/media/images/1/54df3a76-33c9-4cd8-ba4e-af6bf5a91e50/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/54df3a76-33c9-4cd8-ba4e-af6bf5a91e50/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/54df3a76-33c9-4cd8-ba4e-af6bf5a91e50/256x192.jpg",
          "viewOrder": 5
        },
        {
          "mediaId": 14901,
          "title": "Discover the Beauty of Rwanda's National Parks at Bisate Lodge",
          "url": "https://pkslist.azureedge.net/media/images/1/c35ac925-fc65-46da-90b8-209d4edb2904/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/c35ac925-fc65-46da-90b8-209d4edb2904/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/c35ac925-fc65-46da-90b8-209d4edb2904/256x192.jpg",
          "viewOrder": 6
        },
        {
          "mediaId": 14902,
          "title": "Rwanda's eco-retreat Bisate Lodge is built into a volcanic cone",
          "url": "https://pkslist.azureedge.net/media/images/1/3f6d26e6-7eec-4c86-af18-7fefc00dc71b/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/3f6d26e6-7eec-4c86-af18-7fefc00dc71b/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/3f6d26e6-7eec-4c86-af18-7fefc00dc71b/256x192.jpg",
          "viewOrder": 7
        }
      ],
      "geographicArea": {
        "geographicAreaId": 1206,
        "latitude": -1.5064644,
        "longitude": 29.593360599999983,
        "parentGeographicAreaId": 0,
        "title": "Ruhengeri, Rwanda",
        "updated": "2020-05-06T12:20:00",
        "created": "2020-05-06T12:20:00"
      },
      "personalities": [
        {
          "tagId": 56,
          "tagName": "Quiet/Peaceful",
          "tagType": 1
        },
        {
          "tagId": 200,
          "tagName": "Rustic Elegance",
          "tagType": 1
        }
      ],
      "tags": [
        {
          "tagId": 63,
          "tagName": "Safari",
          "tagType": 2
        },
        {
          "tagId": 106,
          "tagName": "Nature",
          "tagType": 2
        },
        {
          "tagId": 135,
          "tagName": "Bird Watching",
          "tagType": 2
        },
        {
          "tagId": 171,
          "tagName": "Trekking",
          "tagType": 2
        },
        {
          "tagId": 269,
          "tagName": "Photography",
          "tagType": 2
        },
        {
          "tagId": 278,
          "tagName": "Conservation",
          "tagType": 2
        },
        {
          "tagId": 286,
          "tagName": "Volcano",
          "tagType": 2
        },
        {
          "tagId": 287,
          "tagName": "Wildlife",
          "tagType": 2
        },
        {
          "tagId": 296,
          "tagName": "Guided Walk",
          "tagType": 2
        },
        {
          "tagId": 324,
          "tagName": "Local Village",
          "tagType": 2
        }
      ],
      "venueCategoryAssigns": [
        {
          "venueCategoryAssignId": 256,
          "venueCategoryId": 20,
          "venueId": 1385,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 20,
            "title": "BucketList Hotels",
            "region": "Global",
            "viewOrder": 0,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 340,
          "venueCategoryId": 13,
          "venueId": 1385,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 13,
            "title": "Wildlife Encounters",
            "region": "Global",
            "viewOrder": 4,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        }
      ]
    },
    {
      "venueId": 1257,
      "venueCategoryId": 20,
      "status": 1,
      "title": "Saffire Freycinet",
      "description": "Saffire Freycinet is Australia’s luxury lodge on Tasmania’s East Coast adjacent to Coles Bay and overlooking the Freycinet Peninsula, famed for the iconic Wineglass Bay. Using natural building products such as timber and stone, the resort has been designed to blend organically into the surrounding landscape. Although the architecture is bold and modern, the real hero remains the stunning ocean and mountain views. Saffire is pitched at the top end of the market, with world-class cuisine, a luxurious modern spa and a high level of service. Each room enjoys unadulterated views of the bay and the mountains – and a high level of privacy.",
      "brand": null,
      "blockQuote": "The Freycinet Oyster Farm experience is so unique and allows you to taste prized Pacific oysters whilst wading in the water.",
      "emailAddress": "stay@saffire-freycinet.com.au",
      "website": "saffire-freycinet.com.au/",
      "bookingURL": "https://reservations.travelclick.com/75101?dateout=03/29/2018&hotelid=75101&adults=1&_ga=2.256458700.635482457.1522162255-26434832.1522162255&datein=03/28/2018&languageid=11&rooms=1#/datesofstay",
      "redirectNotes": "",
      "phoneNumber": "+61 3 6256 7888",
      "addressOne": "2352",
      "addressTwo": "Coles Bay Road",
      "city": "Coles Bay",
      "county": "",
      "region": "Tasmania",
      "country": "Australia",
      "rooms": 20,
      "dollar": 3,
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00",
      "medias": [
        {
          "mediaId": 19400,
          "title": "Saffire Freycinet (Coles Bay, AUS) | Expedia.com.au",
          "url": "https://pkslist.azureedge.net/media/images/1/4678628a-d656-40fa-8f2d-dea3c9ea4586/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/4678628a-d656-40fa-8f2d-dea3c9ea4586/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/4678628a-d656-40fa-8f2d-dea3c9ea4586/256x192.jpg",
          "viewOrder": 0
        },
        {
          "mediaId": 19406,
          "title": "Britton Timbers Australia | Saffire Freycinet",
          "url": "https://pkslist.azureedge.net/media/images/1/56bef7d9-3a74-4f1c-83ef-11b67fd58de2/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/56bef7d9-3a74-4f1c-83ef-11b67fd58de2/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/56bef7d9-3a74-4f1c-83ef-11b67fd58de2/256x192.jpg",
          "viewOrder": 1
        },
        {
          "mediaId": 19402,
          "title": "Saffire Freycinet | Audley Travel",
          "url": "https://pkslist.azureedge.net/media/images/1/faee9e91-00f5-4e35-be66-f8fe7814bfda/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/faee9e91-00f5-4e35-be66-f8fe7814bfda/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/faee9e91-00f5-4e35-be66-f8fe7814bfda/256x192.jpg",
          "viewOrder": 2
        },
        {
          "mediaId": 19403,
          "title": "Saffire Freycinet Deals & Reviews 2018 (Coles Bay, AUS) | Wotif",
          "url": "https://pkslist.azureedge.net/media/images/1/27db747d-165d-40fe-b881-603c113e4b13/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/27db747d-165d-40fe-b881-603c113e4b13/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/27db747d-165d-40fe-b881-603c113e4b13/256x192.jpg",
          "viewOrder": 3
        },
        {
          "mediaId": 19404,
          "title": "Saffire Freycinet Deals & Reviews 2018 (Coles Bay, AUS) | Wotif",
          "url": "https://pkslist.azureedge.net/media/images/1/ad9cbfbc-fd94-46d9-b311-5be0d03bc25f/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/ad9cbfbc-fd94-46d9-b311-5be0d03bc25f/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/ad9cbfbc-fd94-46d9-b311-5be0d03bc25f/256x192.jpg",
          "viewOrder": 4
        },
        {
          "mediaId": 13096,
          "title": "Saffire Freycinet | Suites Australia | Accommodation in Freycinet",
          "url": "https://pkslist.azureedge.net/media/images/1/c3811246-e10a-4cd7-9afb-c1675f14244c/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/c3811246-e10a-4cd7-9afb-c1675f14244c/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/c3811246-e10a-4cd7-9afb-c1675f14244c/256x192.jpg",
          "viewOrder": 5
        },
        {
          "mediaId": 13095,
          "title": "Tasmania Luxury Accommodation | Suites Australia | Saffire Freycinet",
          "url": "https://pkslist.azureedge.net/media/images/1/93399326-5fef-4dec-b08a-09e2205396a9/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/93399326-5fef-4dec-b08a-09e2205396a9/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/93399326-5fef-4dec-b08a-09e2205396a9/256x192.jpg",
          "viewOrder": 6
        },
        {
          "mediaId": 13089,
          "title": "Saffire Freycinet Deals & Reviews (Coles Bay, AUS) | Wotif",
          "url": "https://pkslist.azureedge.net/media/images/1/18db0f8d-595b-49c0-a6fd-ae20c522fbbe/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/18db0f8d-595b-49c0-a6fd-ae20c522fbbe/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/18db0f8d-595b-49c0-a6fd-ae20c522fbbe/256x192.jpg",
          "viewOrder": 7
        },
        {
          "mediaId": 19401,
          "title": "Saffire Freycinet Gallery | Tasmania Photos | Luxury Lodge Australia",
          "url": "https://pkslist.azureedge.net/media/images/1/3d492bcb-329c-497a-b927-15475466649b/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/3d492bcb-329c-497a-b927-15475466649b/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/3d492bcb-329c-497a-b927-15475466649b/256x192.jpg",
          "viewOrder": 8
        }
      ],
      "geographicArea": {
        "geographicAreaId": 1081,
        "latitude": -42.11002,
        "longitude": 148.26588990000005,
        "parentGeographicAreaId": 0,
        "title": "2352 Coles Bay Rd, Coles Bay TAS 7215, Australia",
        "updated": "2020-05-06T12:20:00",
        "created": "2020-05-06T12:20:00"
      },
      "personalities": [
        {
          "tagId": 43,
          "tagName": "Design Slickster",
          "tagType": 1
        },
        {
          "tagId": 56,
          "tagName": "Quiet/Peaceful",
          "tagType": 1
        }
      ],
      "tags": [
        {
          "tagId": 4,
          "tagName": "Spa",
          "tagType": 2
        },
        {
          "tagId": 60,
          "tagName": "Gastronomic",
          "tagType": 2
        },
        {
          "tagId": 103,
          "tagName": "Clay Pigeon Shooting",
          "tagType": 2
        },
        {
          "tagId": 120,
          "tagName": "Private Dining",
          "tagType": 2
        },
        {
          "tagId": 135,
          "tagName": "Bird Watching",
          "tagType": 2
        },
        {
          "tagId": 156,
          "tagName": "Fireplace",
          "tagType": 2
        },
        {
          "tagId": 164,
          "tagName": "Excursions",
          "tagType": 2
        },
        {
          "tagId": 198,
          "tagName": "Boat Excursion",
          "tagType": 2
        },
        {
          "tagId": 207,
          "tagName": "Lounge/Bar",
          "tagType": 2
        },
        {
          "tagId": 257,
          "tagName": "Canoeing",
          "tagType": 2
        },
        {
          "tagId": 264,
          "tagName": "Quad Bike",
          "tagType": 2
        },
        {
          "tagId": 273,
          "tagName": "Archery",
          "tagType": 2
        },
        {
          "tagId": 287,
          "tagName": "Wildlife",
          "tagType": 2
        }
      ],
      "venueCategoryAssigns": [
        {
          "venueCategoryAssignId": 229,
          "venueCategoryId": 20,
          "venueId": 1257,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 20,
            "title": "BucketList Hotels",
            "region": "Global",
            "viewOrder": 0,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 356,
          "venueCategoryId": 18,
          "venueId": 1257,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 18,
            "title": "Wine Country",
            "region": "Global",
            "viewOrder": 19,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 357,
          "venueCategoryId": 5,
          "venueId": 1257,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 5,
            "title": "Romantic Getaways",
            "region": "Global",
            "viewOrder": 20,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        }
      ]
    },
    {
      "venueId": 1253,
      "venueCategoryId": 20,
      "status": 1,
      "title": "Hotel de Crillon, A Rosewood Hotel",
      "description": "Hotel de Crillon, A Rosewood Hotel, is more than just one of the world’s most iconic hotels. It is a timeless Parisian landmark. Dating back to the 18th century, this historic treasure reopened in 2017 as a luxury palace hotel for 21st century travelers. Commissioned by King Louis XV in 1758 and built by Ange-Jacques Gabriel, the greatest architect of his time, it would later come to be the personal residence of the family of the Count of Crillon before becoming a palace hotel in 1909. Over the years, this legendary palace has captivated generations of guests including royalty, heads of state and luminaries drawn to the City of Light. Located at 10 Place de la Concorde in the heart of Paris, Hotel de Crillon’s past provides a rich backdrop to its modern-day story.",
      "brand": "Rosewood",
      "blockQuote": "For the ultimate Parisian experience, stay in the Karl Lagerfeld-designed Grand Apartments.",
      "emailAddress": "crillon@rosewoodhotels.com",
      "website": "rosewoodhotels.com/en/hotel-de-crillon",
      "bookingURL": "https://reservations.rosewoodhotels.com/?adult=1&agencyid=96027890&arrive=2021-06-08&chain=5159&child=0&config=gtm&currency=EUR&depart=2021-06-09&filter=&hotel=75077&level=hotel&locale=en-US&rooms=1",
      "redirectNotes": "Hotel de Crillon's reservations system",
      "phoneNumber": "+33 1 44 71 15 00",
      "addressOne": "10",
      "addressTwo": "Place de la Concorde",
      "city": "Paris",
      "county": "",
      "region": "",
      "country": "France",
      "rooms": 124,
      "dollar": 3,
      "updated": "2021-06-08T14:14:47.063",
      "created": "2020-05-06T12:20:00",
      "medias": [
        {
          "mediaId": 36123,
          "title": "Hotel Hôtel de Crillon, A Rosewood Hotel, Paris - trivago.com",
          "url": "https://pkslist.azureedge.net/media/images/1/7a61c179-90a2-4096-b2cd-7f828b01a80c/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/7a61c179-90a2-4096-b2cd-7f828b01a80c/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/7a61c179-90a2-4096-b2cd-7f828b01a80c/256x192.jpeg",
          "viewOrder": 0
        },
        {
          "mediaId": 36120,
          "title": "Hotel de Crillon, Paris, France - Booking.com",
          "url": "https://pkslist.azureedge.net/media/images/1/2db79148-675c-4cae-80d4-204102f899a2/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/2db79148-675c-4cae-80d4-204102f899a2/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/2db79148-675c-4cae-80d4-204102f899a2/256x192.jpg",
          "viewOrder": 1
        },
        {
          "mediaId": 13016,
          "title": "Hôtel de Crillon Paris: New Renovation Photos - Vogue",
          "url": "https://pkslist.azureedge.net/media/images/1/24915e31-86cc-4441-a128-9339c5506ec4/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/24915e31-86cc-4441-a128-9339c5506ec4/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/24915e31-86cc-4441-a128-9339c5506ec4/256x192.jpg",
          "viewOrder": 2
        },
        {
          "mediaId": 36119,
          "title": "Hôtel de Crillon A Rosewood Hotel in Paris | Hotel Rates & Reviews ...",
          "url": "https://pkslist.azureedge.net/media/images/1/64a7fcab-a800-4127-95fb-dcb6b1985dc9/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/64a7fcab-a800-4127-95fb-dcb6b1985dc9/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/64a7fcab-a800-4127-95fb-dcb6b1985dc9/256x192.jpg",
          "viewOrder": 3
        },
        {
          "mediaId": 36122,
          "title": "Hôtel de Crillon: Inside The Parisian Hotel's US$300 Million ...",
          "url": "https://pkslist.azureedge.net/media/images/1/11b7c600-99ac-4950-8da5-fe28f6a9a598/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/11b7c600-99ac-4950-8da5-fe28f6a9a598/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/11b7c600-99ac-4950-8da5-fe28f6a9a598/256x192.jpg",
          "viewOrder": 4
        },
        {
          "mediaId": 36118,
          "title": "Hôtel de Crillon A Rosewood Hotel (Paris, France) | Jetsetter",
          "url": "https://pkslist.azureedge.net/media/images/1/23a05cc3-06c3-48ce-b7c6-06c3ece4fda0/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/23a05cc3-06c3-48ce-b7c6-06c3ece4fda0/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/23a05cc3-06c3-48ce-b7c6-06c3ece4fda0/256x192.jpeg",
          "viewOrder": 5
        },
        {
          "mediaId": 36124,
          "title": "Hôtel De Crillon",
          "url": "https://pkslist.azureedge.net/media/images/1/61ecc260-ce96-4bb6-8204-34d975153db8/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/61ecc260-ce96-4bb6-8204-34d975153db8/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/61ecc260-ce96-4bb6-8204-34d975153db8/256x192.jpg",
          "viewOrder": 6
        },
        {
          "mediaId": 13035,
          "title": "Hotel de Crillon reopens in Paris after 200 million-dollars ...",
          "url": "https://pkslist.azureedge.net/media/images/1/946cff78-01ff-4b0a-b2bc-82969363554a/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/946cff78-01ff-4b0a-b2bc-82969363554a/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/946cff78-01ff-4b0a-b2bc-82969363554a/256x192.jpg",
          "viewOrder": 7
        },
        {
          "mediaId": 13037,
          "title": "Inside the Renovated Hôtel de Crillon - The basement-level pool ...",
          "url": "https://pkslist.azureedge.net/media/images/1/8658f867-70f0-47a3-b1ab-c33515fe8340/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/8658f867-70f0-47a3-b1ab-c33515fe8340/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/8658f867-70f0-47a3-b1ab-c33515fe8340/256x192.jpg",
          "viewOrder": 8
        }
      ],
      "geographicArea": {
        "geographicAreaId": 1077,
        "latitude": 48.8676155,
        "longitude": 2.321403700000019,
        "parentGeographicAreaId": 0,
        "title": "10 Place de la Concorde, 75008 Paris, France",
        "updated": "2020-05-06T12:20:00",
        "created": "2020-05-06T12:20:00"
      },
      "personalities": [
        {
          "tagId": 48,
          "tagName": "Iconic/Landmark ",
          "tagType": 1
        },
        {
          "tagId": 51,
          "tagName": "Style Icon",
          "tagType": 1
        }
      ],
      "tags": [
        {
          "tagId": 4,
          "tagName": "Spa",
          "tagType": 2
        },
        {
          "tagId": 60,
          "tagName": "Gastronomic",
          "tagType": 2
        },
        {
          "tagId": 65,
          "tagName": "Shopping",
          "tagType": 2
        },
        {
          "tagId": 79,
          "tagName": "Fitness",
          "tagType": 2
        },
        {
          "tagId": 86,
          "tagName": "Indoor Pool",
          "tagType": 2
        },
        {
          "tagId": 105,
          "tagName": "Afternoon Tea",
          "tagType": 2
        },
        {
          "tagId": 156,
          "tagName": "Fireplace",
          "tagType": 2
        },
        {
          "tagId": 199,
          "tagName": "Art Gallery",
          "tagType": 2
        },
        {
          "tagId": 207,
          "tagName": "Lounge/Bar",
          "tagType": 2
        },
        {
          "tagId": 216,
          "tagName": "Museum",
          "tagType": 2
        },
        {
          "tagId": 283,
          "tagName": "Restaurants",
          "tagType": 2
        },
        {
          "tagId": 323,
          "tagName": "City Walk",
          "tagType": 2
        },
        {
          "tagId": 332,
          "tagName": "Fashion",
          "tagType": 2
        }
      ],
      "venueCategoryAssigns": [
        {
          "venueCategoryAssignId": 228,
          "venueCategoryId": 20,
          "venueId": 1253,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 20,
            "title": "BucketList Hotels",
            "region": "Global",
            "viewOrder": 0,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 330,
          "venueCategoryId": 22,
          "venueId": 1253,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 22,
            "title": "Paris Top 10 ",
            "region": "Europe",
            "viewOrder": 9,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        }
      ]
    },
    {
      "venueId": 1207,
      "venueCategoryId": 0,
      "status": 1,
      "title": "andBeyond Sossusvlei Desert Lodge",
      "description": "The lodge is situated within &BEYOND Sossusvlei Private Desert Reserve, a vast 12 715 hectare (31 419 acre) expanse deep in the Namib, the world’s oldest living desert.\nSet amidst an ocean of dunes, rocky outcrops and gravel plains, the utter tranquility and extraordinary clarity of light makes this area one of Africa’s most compelling landscapes. The night skies are heavenly, with the concession bordering the International Dark Sky Reserve of the NamibRand Nature Reserve. A canvas of beauty that invites reconnection with yourself and nature.\n\nSustainability and uncompromised luxury go hand in hand at Sossusvlei. 10 beautifully appointed stone and glass suites, spread out along the curve of the escarpment, open onto ever-changing outlooks. Each spacious air conditioned suite offers a secluded, shaded veranda complete with private plunge pool, a living room with a fireplace, a large retractable skylight above the bed, and an ensuite bathroom with a glass-encased rain shower offering 180º desert views. The two-bedroomed Star Dune Suite is an ideal option for families or groups of friends.",
      "brand": "andBeyond",
      "blockQuote": "The hot air balloon adventure will allow for excellent photographic opportunities and lifelong memories.",
      "emailAddress": "safaris@andBeyond.com",
      "website": "https://www.andbeyond.com/our-lodges/africa/namibia/sossusvlei-desert/andbeyond-sossusvlei-desert-lodge/",
      "bookingURL": "https://www.andbeyond.com/our-lodges/africa/namibia/sossusvlei-desert/andbeyond-sossusvlei-desert-lodge/?utm_source=Organic&utm_medium=GMBSossusvlei&utm_campaign=GoogleMyBusiness",
      "redirectNotes": null,
      "phoneNumber": "+27 11 809 4300",
      "addressOne": "",
      "addressTwo": "",
      "city": "Sossusvlei",
      "county": "",
      "region": "",
      "country": "Namibia",
      "rooms": 10,
      "dollar": 3,
      "updated": "2020-05-18T15:27:01.993",
      "created": "2020-05-06T12:20:00",
      "medias": [
        {
          "mediaId": 36568,
          "title": "&Beyond Sossusvlei Desert Lodge | Namibia Safaris | Red Savannah",
          "url": "https://pkslist.azureedge.net/media/images/1/88984c17-f4f1-48b8-a8f0-3e4471cc6163/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/88984c17-f4f1-48b8-a8f0-3e4471cc6163/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/88984c17-f4f1-48b8-a8f0-3e4471cc6163/256x192.jpg",
          "viewOrder": 0
        },
        {
          "mediaId": 36560,
          "title": "andBeyond Sossusvlei Desert Lodge | Namib Desert | Namibia",
          "url": "https://pkslist.azureedge.net/media/images/1/856b502a-b8c9-4db2-94a8-62ecda7212cb/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/856b502a-b8c9-4db2-94a8-62ecda7212cb/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/856b502a-b8c9-4db2-94a8-62ecda7212cb/256x192.jpg",
          "viewOrder": 2
        },
        {
          "mediaId": 36559,
          "title": "AndBeyond Sossusvlei Desert Lodge Review: First In - Condé Nast ...",
          "url": "https://pkslist.azureedge.net/media/images/1/fa631ab9-a7cc-4ab8-97fb-a900162b7a5e/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/fa631ab9-a7cc-4ab8-97fb-a900162b7a5e/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/fa631ab9-a7cc-4ab8-97fb-a900162b7a5e/256x192.jpg",
          "viewOrder": 3
        },
        {
          "mediaId": 36558,
          "title": "andBeyond Sossusvlei Desert Lodge | Namib Desert | Namibia",
          "url": "https://pkslist.azureedge.net/media/images/1/91faeff3-30d3-4c93-bde7-51a081b5ff56/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/91faeff3-30d3-4c93-bde7-51a081b5ff56/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/91faeff3-30d3-4c93-bde7-51a081b5ff56/256x192.jpg",
          "viewOrder": 4
        },
        {
          "mediaId": 36562,
          "title": "andBeyond Sossusvlei Desert Lodge | Namib Desert | Namibia",
          "url": "https://pkslist.azureedge.net/media/images/1/689f6961-6f94-4ea3-a781-225a5aa8d230/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/689f6961-6f94-4ea3-a781-225a5aa8d230/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/689f6961-6f94-4ea3-a781-225a5aa8d230/256x192.jpg",
          "viewOrder": 5
        },
        {
          "mediaId": 36567,
          "title": "&Beyond Sossusvlei Desert Lodge Hotel Review, Namib-naukluft park ...",
          "url": "https://pkslist.azureedge.net/media/images/1/4a222773-f0ea-4c25-bc66-626461bcb69c/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/4a222773-f0ea-4c25-bc66-626461bcb69c/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/4a222773-f0ea-4c25-bc66-626461bcb69c/256x192.jpg",
          "viewOrder": 6
        },
        {
          "mediaId": 36565,
          "title": "andBeyond-Sossusvlei-Desert-Lodge-Suite-View-Render | Luxury ...",
          "url": "https://pkslist.azureedge.net/media/images/1/a46d413d-05c4-48ac-bf45-56133206799d/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/a46d413d-05c4-48ac-bf45-56133206799d/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/a46d413d-05c4-48ac-bf45-56133206799d/256x192.jpg",
          "viewOrder": 7
        },
        {
          "mediaId": 36572,
          "title": "A favourite destination is reborn in Namibia | How To Spend It",
          "url": "https://pkslist.azureedge.net/media/images/1/a76c58ef-47aa-469c-a353-d67b504a6f1a/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/a76c58ef-47aa-469c-a353-d67b504a6f1a/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/a76c58ef-47aa-469c-a353-d67b504a6f1a/256x192.jpg",
          "viewOrder": 8
        },
        {
          "mediaId": 36574,
          "title": "andBeyond Sossusvlei Desert Lodge in NamibRand Reserve, Namibia",
          "url": "https://pkslist.azureedge.net/media/images/1/34cad358-b7ce-4a36-8ad4-f3fcd42babb1/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/34cad358-b7ce-4a36-8ad4-f3fcd42babb1/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/34cad358-b7ce-4a36-8ad4-f3fcd42babb1/256x192.jpg",
          "viewOrder": 9
        },
        {
          "mediaId": 36575,
          "title": "andBeyond Sossusvlei Desert Lodge in NamibRand Reserve, Namibia",
          "url": "https://pkslist.azureedge.net/media/images/1/5d607e8a-16a6-4176-b55b-652fa801dfa7/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/5d607e8a-16a6-4176-b55b-652fa801dfa7/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/5d607e8a-16a6-4176-b55b-652fa801dfa7/256x192.jpg",
          "viewOrder": 10
        },
        {
          "mediaId": 36571,
          "title": "andBeyond Sossusvlei Desert Lodge | Namib Desert | Namibia",
          "url": "https://pkslist.azureedge.net/media/images/1/8dca9dd7-65a2-44fe-8c66-e4fc14a0425a/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/8dca9dd7-65a2-44fe-8c66-e4fc14a0425a/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/8dca9dd7-65a2-44fe-8c66-e4fc14a0425a/256x192.jpg",
          "viewOrder": 11
        }
      ],
      "geographicArea": {
        "geographicAreaId": 1032,
        "latitude": -24.779536,
        "longitude": 15.888596000000007,
        "parentGeographicAreaId": 0,
        "title": "Sossusvlei, Namib-Naukluft Park, Namibia, Namibia",
        "updated": "2020-05-06T12:20:00",
        "created": "2020-05-06T12:20:00"
      },
      "personalities": [
        {
          "tagId": 43,
          "tagName": "Design Slickster",
          "tagType": 1
        },
        {
          "tagId": 56,
          "tagName": "Quiet/Peaceful",
          "tagType": 1
        }
      ],
      "tags": [
        {
          "tagId": 59,
          "tagName": "Cultural",
          "tagType": 2
        },
        {
          "tagId": 61,
          "tagName": "Mountain",
          "tagType": 2
        },
        {
          "tagId": 63,
          "tagName": "Safari",
          "tagType": 2
        },
        {
          "tagId": 106,
          "tagName": "Nature",
          "tagType": 2
        },
        {
          "tagId": 110,
          "tagName": "Hiking",
          "tagType": 2
        },
        {
          "tagId": 134,
          "tagName": "Wilderness Walks",
          "tagType": 2
        },
        {
          "tagId": 164,
          "tagName": "Excursions",
          "tagType": 2
        },
        {
          "tagId": 193,
          "tagName": "Outdoor Dining",
          "tagType": 2
        },
        {
          "tagId": 219,
          "tagName": "Wine Cellar",
          "tagType": 2
        },
        {
          "tagId": 263,
          "tagName": "Star Gazing",
          "tagType": 2
        }
      ],
      "venueCategoryAssigns": [
        {
          "venueCategoryAssignId": 564,
          "venueCategoryId": 13,
          "venueId": 1207,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 13,
            "title": "Wildlife Encounters",
            "region": "Global",
            "viewOrder": 4,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 565,
          "venueCategoryId": 20,
          "venueId": 1207,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 20,
            "title": "BucketList Hotels",
            "region": "Global",
            "viewOrder": 0,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        }
      ]
    },
    {
      "venueId": 1136,
      "venueCategoryId": 20,
      "status": 1,
      "title": "Dunton Hot Springs",
      "description": "Dunton Hot Springs is a small and exclusive resort nestled deep in the San Juan Mountains of the Colorado Rockies. This perfectly restored ghost town thrives on contradictions; hand- hewn log cabins exquisitely furnished, a life-worn saloon serving food of startling quality, lung torturing trails followed by pampering massages, sensuous hot springs beneath shimmering snow banks. Free of cell phones, this romantic old mining town still provides high speed wireless internet access in every cabin and video conferencing in our dance hall to the comforting sound of a tumbling waterfall.\n\nThe resort offers a variety of day and overnight packages, and the entire town or ranch can be rented exclusively for corporate retreats, family reunions and weddings.",
      "brand": null,
      "blockQuote": "With such an unbelievably beautiful setting, you just have to get out and appreciate it.",
      "emailAddress": "reservations@duntonlife.com",
      "website": "duntonhotsprings.com/",
      "bookingURL": "http://reservation.worldweb.com/Bookings-nr105/activity-edit.html?table=hotels&listing_id=251&mode=command&command=bookingrequestform&hotel_id=251&location_id=497&flag_return_all=1%20&_ga=2.258778444.794350240.1502796405-965619294.1502796405",
      "redirectNotes": null,
      "phoneNumber": "+1 970 882 4800",
      "addressOne": "52068",
      "addressTwo": "County Road 38",
      "city": "Dolores",
      "county": "",
      "region": "Colorado",
      "country": "United States",
      "rooms": 13,
      "dollar": 3,
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00",
      "medias": [
        {
          "mediaId": 11422,
          "title": "Dolores, Colorado near Cortez Dunton Hot Springs, world class ...",
          "url": "https://pkslist.azureedge.net/media/images/1/63383574-28a7-48c8-a227-eb739211bd74/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/63383574-28a7-48c8-a227-eb739211bd74/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/63383574-28a7-48c8-a227-eb739211bd74/256x192.jpg",
          "viewOrder": 0
        },
        {
          "mediaId": 11424,
          "title": "Dunton Hot Springs | Uncrate",
          "url": "https://pkslist.azureedge.net/media/images/1/5ee5ad3c-3901-40d8-8248-260497a320f3/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/5ee5ad3c-3901-40d8-8248-260497a320f3/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/5ee5ad3c-3901-40d8-8248-260497a320f3/256x192.jpg",
          "viewOrder": 1
        },
        {
          "mediaId": 11426,
          "title": "Flashback Road Trip: Dunton Hot Springs Part 2 - Roadesque",
          "url": "https://pkslist.azureedge.net/media/images/1/374c79c5-ce67-45f7-9919-c7ca43a08e8c/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/374c79c5-ce67-45f7-9919-c7ca43a08e8c/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/374c79c5-ce67-45f7-9919-c7ca43a08e8c/256x192.jpg",
          "viewOrder": 2
        },
        {
          "mediaId": 11418,
          "title": "Dunton Hot Springs - Good ol' timey living with free wifi",
          "url": "https://pkslist.azureedge.net/media/images/1/72b7f0f6-d055-4cf4-84e0-e928209ef405/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/72b7f0f6-d055-4cf4-84e0-e928209ef405/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/72b7f0f6-d055-4cf4-84e0-e928209ef405/256x192.jpeg",
          "viewOrder": 3
        },
        {
          "mediaId": 11419,
          "title": "Dunton Hot Springs Resort in Colorado | HiConsumption",
          "url": "https://pkslist.azureedge.net/media/images/1/f0ee080a-abaf-4c05-8c08-7dc921cb25dd/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/f0ee080a-abaf-4c05-8c08-7dc921cb25dd/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/f0ee080a-abaf-4c05-8c08-7dc921cb25dd/256x192.jpg",
          "viewOrder": 4
        },
        {
          "mediaId": 11416,
          "title": "Dunton Hot Springs - Cabins & Rates",
          "url": "https://pkslist.azureedge.net/media/images/1/fb5f886d-1b7b-40fe-80ae-38c0f027eae7/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/fb5f886d-1b7b-40fe-80ae-38c0f027eae7/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/fb5f886d-1b7b-40fe-80ae-38c0f027eae7/256x192.jpg",
          "viewOrder": 5
        },
        {
          "mediaId": 11428,
          "title": "Dunton Hot Springs, Dolores, Colorado",
          "url": "https://pkslist.azureedge.net/media/images/1/706ef186-e64b-4d36-92d7-f10dccb42886/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/706ef186-e64b-4d36-92d7-f10dccb42886/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/706ef186-e64b-4d36-92d7-f10dccb42886/256x192.jpg",
          "viewOrder": 6
        },
        {
          "mediaId": 11429,
          "title": "Dunton Hot Springs - Original Travel - Luxury Hotel Colorado",
          "url": "https://pkslist.azureedge.net/media/images/1/d01fdb85-504f-4622-af26-b22b43f1af29/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/d01fdb85-504f-4622-af26-b22b43f1af29/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/d01fdb85-504f-4622-af26-b22b43f1af29/256x192.jpg",
          "viewOrder": 7
        },
        {
          "mediaId": 11417,
          "title": "Dunton Hot Springs - Cabins & Rates",
          "url": "https://pkslist.azureedge.net/media/images/1/e6382b4f-9cba-4032-b3e3-98893e86b2ed/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/e6382b4f-9cba-4032-b3e3-98893e86b2ed/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/e6382b4f-9cba-4032-b3e3-98893e86b2ed/256x192.jpg",
          "viewOrder": 8
        },
        {
          "mediaId": 11421,
          "title": "Dunton Hot Springs - Good ol' timey living with free wifi",
          "url": "https://pkslist.azureedge.net/media/images/1/24e8107c-3212-436c-98dc-8ca81b03e5ca/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/24e8107c-3212-436c-98dc-8ca81b03e5ca/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/24e8107c-3212-436c-98dc-8ca81b03e5ca/256x192.jpeg",
          "viewOrder": 9
        }
      ],
      "geographicArea": {
        "geographicAreaId": 961,
        "latitude": 37.7726733,
        "longitude": -108.09372580000002,
        "parentGeographicAreaId": 0,
        "title": "52068 Co Rd 38, Dolores, CO 81323, USA",
        "updated": "2020-05-06T12:20:00",
        "created": "2020-05-06T12:20:00"
      },
      "personalities": [
        {
          "tagId": 57,
          "tagName": "Romantic",
          "tagType": 1
        },
        {
          "tagId": 200,
          "tagName": "Rustic Elegance",
          "tagType": 1
        }
      ],
      "tags": [
        {
          "tagId": 4,
          "tagName": "Spa",
          "tagType": 2
        },
        {
          "tagId": 61,
          "tagName": "Mountain",
          "tagType": 2
        },
        {
          "tagId": 82,
          "tagName": "Horseback Riding",
          "tagType": 2
        },
        {
          "tagId": 86,
          "tagName": "Indoor Pool",
          "tagType": 2
        },
        {
          "tagId": 100,
          "tagName": "Wedding",
          "tagType": 2
        },
        {
          "tagId": 109,
          "tagName": "Fishing",
          "tagType": 2
        },
        {
          "tagId": 110,
          "tagName": "Hiking",
          "tagType": 2
        },
        {
          "tagId": 133,
          "tagName": "Glamping",
          "tagType": 2
        },
        {
          "tagId": 317,
          "tagName": "Gourmet",
          "tagType": 2
        }
      ],
      "venueCategoryAssigns": [
        {
          "venueCategoryAssignId": 218,
          "venueCategoryId": 20,
          "venueId": 1136,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 20,
            "title": "BucketList Hotels",
            "region": "Global",
            "viewOrder": 0,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 354,
          "venueCategoryId": 5,
          "venueId": 1136,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 5,
            "title": "Romantic Getaways",
            "region": "Global",
            "viewOrder": 20,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 355,
          "venueCategoryId": 1,
          "venueId": 1136,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 1,
            "title": "Escape from the City",
            "region": "Global",
            "viewOrder": 14,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 641,
          "venueCategoryId": 33,
          "venueId": 1136,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 33,
            "title": "Rustic Elegance",
            "region": "Global",
            "viewOrder": 11,
            "updated": "2020-09-04T09:04:00",
            "created": "2020-09-04T09:04:00",
            "venueCategoryAssign": [
              
            ]
          }
        }
      ]
    },
    {
      "venueId": 1135,
      "venueCategoryId": 19,
      "status": 1,
      "title": "COMO Laucala Island",
      "description": "COMO Laucala Island is located in the waters of Melanesia, in the Cakaudrove province of Fiji. The 25 Residences all come with private pools, spacious sundecks and design that blends modern luxury with Fiji’s natural beauty. They are located on beaches, above lagoons, in the jungle or perched on clifftops. Interiors are flooded with sunlight and styled with thatch roofs and local timber. For holistic wellness of body and mind, there is a COMO Shambhala Retreat to enjoy therapies and treatments made with ingredients from the Spa Garden. As much as possible, the restaurants and bars also use home-grown produce: fresh aromatics, nourishing greens and locally sourced meats and seafood.",
      "brand": "COMO Hotels",
      "blockQuote": "An extraordinary paradise with a panoply of activities to fill your days.",
      "emailAddress": "res.laucalaisland@comohotels.com",
      "website": "comohotels.com/en/laucalaisland",
      "bookingURL": "https://reservations.comohotels.com/?Start=AvailResults&_ga=2.95402334.395868165.1641741604-181164596.1641741604&adult=1&agencyid=96027890&arrive=2022-01-09&chain=10327&child=0&currency=USD&depart=2022-01-10&filter=&hotel=37374&lang=0&level=hotel&locale=en-US&rooms=1",
      "redirectNotes": "Como Laucala Island's reservations system",
      "phoneNumber": "+679 888 0077",
      "addressOne": "",
      "addressTwo": "",
      "city": "Laucala Island",
      "county": "",
      "region": "",
      "country": "Fiji",
      "rooms": 25,
      "dollar": 3,
      "updated": "2023-10-13T09:25:24.86",
      "created": "2020-05-06T12:20:00",
      "medias": [
        {
          "mediaId": 51447,
          "title": "https://www.comohotels.com/sites/default/files/COM...",
          "url": "https://pkslist.azureedge.net/media/images/1/f1c6fdca-0af1-4f20-b5b4-65adfc3a4a49/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/f1c6fdca-0af1-4f20-b5b4-65adfc3a4a49/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/f1c6fdca-0af1-4f20-b5b4-65adfc3a4a49/256x192.jpg",
          "viewOrder": 0
        },
        {
          "mediaId": 51438,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/8905cfb9-e40b-454c-b85e-bc8fc757515d/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/8905cfb9-e40b-454c-b85e-bc8fc757515d/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/8905cfb9-e40b-454c-b85e-bc8fc757515d/256x192.jpeg",
          "viewOrder": 1
        },
        {
          "mediaId": 51437,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/dee78427-5852-4e50-be88-fae255e9bd16/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/dee78427-5852-4e50-be88-fae255e9bd16/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/dee78427-5852-4e50-be88-fae255e9bd16/256x192.jpeg",
          "viewOrder": 2
        },
        {
          "mediaId": 51434,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/02fa3ee1-938c-422a-8227-590df240baf7/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/02fa3ee1-938c-422a-8227-590df240baf7/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/02fa3ee1-938c-422a-8227-590df240baf7/256x192.jpeg",
          "viewOrder": 3
        },
        {
          "mediaId": 51436,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/bbd3c50a-a4b6-426e-ae55-bca5761e0551/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/bbd3c50a-a4b6-426e-ae55-bca5761e0551/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/bbd3c50a-a4b6-426e-ae55-bca5761e0551/256x192.jpeg",
          "viewOrder": 4
        },
        {
          "mediaId": 51442,
          "title": "The Private Island Experience: A Look at COMO's New Luxury Resort ...",
          "url": "https://pkslist.azureedge.net/media/images/1/2a637979-78f5-4ee3-86a1-4b32ac749594/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/2a637979-78f5-4ee3-86a1-4b32ac749594/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/2a637979-78f5-4ee3-86a1-4b32ac749594/256x192.jpg",
          "viewOrder": 5
        },
        {
          "mediaId": 51435,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/21441780-e7d4-4c1f-8fc3-dc867fcd53a0/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/21441780-e7d4-4c1f-8fc3-dc867fcd53a0/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/21441780-e7d4-4c1f-8fc3-dc867fcd53a0/256x192.jpeg",
          "viewOrder": 6
        },
        {
          "mediaId": 51439,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/2c12765a-c558-4183-856c-a9a633768d2b/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/2c12765a-c558-4183-856c-a9a633768d2b/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/2c12765a-c558-4183-856c-a9a633768d2b/256x192.jpeg",
          "viewOrder": 7
        },
        {
          "mediaId": 51451,
          "title": "Laucala Island Fiji — Liz Rodgers, representation for Luxury ...",
          "url": "https://pkslist.azureedge.net/media/images/1/de2f3b92-1ac6-4e46-9a35-c8ab7bb1a066/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/de2f3b92-1ac6-4e46-9a35-c8ab7bb1a066/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/de2f3b92-1ac6-4e46-9a35-c8ab7bb1a066/256x192.jpg",
          "viewOrder": 8
        },
        {
          "mediaId": 51441,
          "title": "The Private Island Experience: A Look at COMO's New Luxury Resort ...",
          "url": "https://pkslist.azureedge.net/media/images/1/c02ab5c3-b1d5-467f-b2a3-1ca1b9e1c91f/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/c02ab5c3-b1d5-467f-b2a3-1ca1b9e1c91f/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/c02ab5c3-b1d5-467f-b2a3-1ca1b9e1c91f/256x192.jpg",
          "viewOrder": 9
        },
        {
          "mediaId": 51449,
          "title": "A look inside Laucala Island, Fiji - one of the worlds most ...",
          "url": "https://pkslist.azureedge.net/media/images/1/6cb28394-3ef9-4b76-bacc-cdab176890aa/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/6cb28394-3ef9-4b76-bacc-cdab176890aa/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/6cb28394-3ef9-4b76-bacc-cdab176890aa/256x192.jpg",
          "viewOrder": 10
        },
        {
          "mediaId": 51443,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/d23fe17e-dfb4-448a-9f53-0489b3835daa/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/d23fe17e-dfb4-448a-9f53-0489b3835daa/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/d23fe17e-dfb4-448a-9f53-0489b3835daa/256x192.jpeg",
          "viewOrder": 11
        }
      ],
      "geographicArea": {
        "geographicAreaId": 960,
        "latitude": -16.74433,
        "longitude": -179.69363999999996,
        "parentGeographicAreaId": 0,
        "title": "Laucala Island, Fiji",
        "updated": "2020-05-06T12:20:00",
        "created": "2020-05-06T12:20:00"
      },
      "personalities": [
        {
          "tagId": 57,
          "tagName": "Romantic",
          "tagType": 1
        },
        {
          "tagId": 157,
          "tagName": "Beach Escape",
          "tagType": 1
        }
      ],
      "tags": [
        {
          "tagId": 4,
          "tagName": "Spa",
          "tagType": 2
        },
        {
          "tagId": 58,
          "tagName": "Beach",
          "tagType": 2
        },
        {
          "tagId": 81,
          "tagName": "Golf",
          "tagType": 2
        },
        {
          "tagId": 82,
          "tagName": "Horseback Riding",
          "tagType": 2
        },
        {
          "tagId": 88,
          "tagName": "Tennis",
          "tagType": 2
        },
        {
          "tagId": 90,
          "tagName": "Water Sports",
          "tagType": 2
        },
        {
          "tagId": 92,
          "tagName": "Infinity Pool",
          "tagType": 2
        },
        {
          "tagId": 116,
          "tagName": "Scuba Diving",
          "tagType": 2
        },
        {
          "tagId": 120,
          "tagName": "Private Dining",
          "tagType": 2
        },
        {
          "tagId": 121,
          "tagName": "Yoga",
          "tagType": 2
        },
        {
          "tagId": 283,
          "tagName": "Restaurants",
          "tagType": 2
        },
        {
          "tagId": 398,
          "tagName": "Submarine",
          "tagType": 2
        }
      ],
      "venueCategoryAssigns": [
        {
          "venueCategoryAssignId": 217,
          "venueCategoryId": 20,
          "venueId": 1135,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 20,
            "title": "BucketList Hotels",
            "region": "Global",
            "viewOrder": 0,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 352,
          "venueCategoryId": 15,
          "venueId": 1135,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 15,
            "title": "Beach Paradise",
            "region": "Global",
            "viewOrder": 6,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 353,
          "venueCategoryId": 19,
          "venueId": 1135,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 19,
            "title": "Private Islands",
            "region": "Global",
            "viewOrder": 15,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        }
      ]
    },
    {
      "venueId": 1004,
      "venueCategoryId": 20,
      "status": 1,
      "title": "Icehotel Sweden",
      "description": "Icehotel is a world famous hotel and an art exhibition made of ice and snow. Founded in 1989, it is reborn in a new guise every winter, in the Swedish village of Jukkasjärvi – 200 km north of the Arctic Circle. The Torne River, the arts, and creating a setting for life-enriching moments are at the heart of it all.\n\nICEHOTEL 365 is a permanent structure that includes luxury suites, each with private relax and bathroom, and art suites, all sculptured by selected artists from all over the world. As well, there is a large ice bar that serves champagne and an ice gallery.  In addition, the complex includes ice church, ice bar, ice sculpting studio and pillar hall. The hotel also includes two heated restaurants, lounge, four meeting rooms and two wilderness camps. This ice experience can be visited year-round and is cooled by solar panels during the summer months.",
      "brand": "",
      "blockQuote": "Just thinking about it gives me chills, but you have to spend a night in the ICE room.",
      "emailAddress": "info@icehotel.com",
      "website": "icehotel.com/",
      "bookingURL": "https://www.icehotel.com/booking",
      "redirectNotes": "Icehotel Sweden's reservations system",
      "phoneNumber": "+46 7066 80263 ",
      "addressOne": "63",
      "addressTwo": "Marknadsvägen",
      "city": "Jukkasjarvi",
      "county": "",
      "region": "",
      "country": "Sweden",
      "rooms": 60,
      "dollar": 1,
      "updated": "2023-12-23T14:26:28.473",
      "created": "2020-05-06T12:20:00",
      "medias": [
        {
          "mediaId": 63025,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/01a61a7a-0795-466f-be9a-014be54587f9/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/01a61a7a-0795-466f-be9a-014be54587f9/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/01a61a7a-0795-466f-be9a-014be54587f9/256x192.jpeg",
          "viewOrder": 0
        },
        {
          "mediaId": 63026,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/a7c59726-7e44-480c-be32-85885efc5947/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/a7c59726-7e44-480c-be32-85885efc5947/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/a7c59726-7e44-480c-be32-85885efc5947/256x192.jpeg",
          "viewOrder": 1
        },
        {
          "mediaId": 10000,
          "title": "The World's First Ice Hotel – Jukkasjarvi (Sweden) | World for Travel",
          "url": "https://pkslist.azureedge.net/media/images/1/b7dd8b76-2476-4d46-9ed2-32ee96df24d6/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/b7dd8b76-2476-4d46-9ed2-32ee96df24d6/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/b7dd8b76-2476-4d46-9ed2-32ee96df24d6/256x192.jpg",
          "viewOrder": 2
        },
        {
          "mediaId": 19069,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/515f0cca-b431-4523-9a5f-ffe408bb6b3d/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/515f0cca-b431-4523-9a5f-ffe408bb6b3d/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/515f0cca-b431-4523-9a5f-ffe408bb6b3d/256x192.jpg",
          "viewOrder": 3
        },
        {
          "mediaId": 19068,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/cbb9ce6e-ce10-49a5-a47e-51c1fde338eb/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/cbb9ce6e-ce10-49a5-a47e-51c1fde338eb/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/cbb9ce6e-ce10-49a5-a47e-51c1fde338eb/256x192.jpg",
          "viewOrder": 4
        },
        {
          "mediaId": 63027,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/218f2dee-17ca-4fe5-9c54-c1a503dcbda8/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/218f2dee-17ca-4fe5-9c54-c1a503dcbda8/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/218f2dee-17ca-4fe5-9c54-c1a503dcbda8/256x192.jpeg",
          "viewOrder": 5
        },
        {
          "mediaId": 19065,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/73c5cfcf-0bdf-4989-af30-c8a00af9be5b/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/73c5cfcf-0bdf-4989-af30-c8a00af9be5b/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/73c5cfcf-0bdf-4989-af30-c8a00af9be5b/256x192.jpg",
          "viewOrder": 6
        },
        {
          "mediaId": 63024,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/827666f1-9c11-4b51-93b5-aba7ed2f09f2/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/827666f1-9c11-4b51-93b5-aba7ed2f09f2/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/827666f1-9c11-4b51-93b5-aba7ed2f09f2/256x192.jpeg",
          "viewOrder": 7
        },
        {
          "mediaId": 9994,
          "title": "Icehotel in Lapland, Sweden -- Travel 365 -- National Geographic",
          "url": "https://pkslist.azureedge.net/media/images/1/2e7edde6-18cd-4d6e-abd7-68c26d4d6e76/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/2e7edde6-18cd-4d6e-abd7-68c26d4d6e76/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/2e7edde6-18cd-4d6e-abd7-68c26d4d6e76/256x192.jpg",
          "viewOrder": 8
        },
        {
          "mediaId": 9991,
          "title": "World's first permanent ice hotel opens - CNN.com | CNN Travel",
          "url": "https://pkslist.azureedge.net/media/images/1/4fb2bca2-f812-463e-84c3-24345e58183c/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/4fb2bca2-f812-463e-84c3-24345e58183c/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/4fb2bca2-f812-463e-84c3-24345e58183c/256x192.jpg",
          "viewOrder": 9
        },
        {
          "mediaId": 10003,
          "title": "Get Your Chill On At An Ice Hotel | World Travel List",
          "url": "https://pkslist.azureedge.net/media/images/1/fadd99f1-8522-470d-9152-3d88b2fe7d74/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/fadd99f1-8522-470d-9152-3d88b2fe7d74/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/fadd99f1-8522-470d-9152-3d88b2fe7d74/256x192.jpg",
          "viewOrder": 10
        }
      ],
      "geographicArea": {
        "geographicAreaId": 833,
        "latitude": 67.85092709999999,
        "longitude": 20.59517059999996,
        "parentGeographicAreaId": 0,
        "title": "Marknadsvägen 63, 981 91 Jukkasjärvi, Sweden",
        "updated": "2020-05-06T12:20:00",
        "created": "2020-05-06T12:20:00"
      },
      "personalities": [
        {
          "tagId": 43,
          "tagName": "Design Slickster",
          "tagType": 1
        },
        {
          "tagId": 53,
          "tagName": "Cutting Edge",
          "tagType": 1
        }
      ],
      "tags": [
        {
          "tagId": 155,
          "tagName": "Art",
          "tagType": 2
        },
        {
          "tagId": 203,
          "tagName": "Sauna",
          "tagType": 2
        },
        {
          "tagId": 233,
          "tagName": "White Water Rafting",
          "tagType": 2
        },
        {
          "tagId": 236,
          "tagName": "Dog Sledding",
          "tagType": 2
        },
        {
          "tagId": 237,
          "tagName": "Northern Lights",
          "tagType": 2
        },
        {
          "tagId": 243,
          "tagName": "Outdoor Activities",
          "tagType": 2
        },
        {
          "tagId": 266,
          "tagName": "Nature Walk",
          "tagType": 2
        },
        {
          "tagId": 269,
          "tagName": "Photography",
          "tagType": 2
        },
        {
          "tagId": 317,
          "tagName": "Gourmet",
          "tagType": 2
        },
        {
          "tagId": 338,
          "tagName": "Cross Country Skiing",
          "tagType": 2
        }
      ],
      "venueCategoryAssigns": [
        {
          "venueCategoryAssignId": 188,
          "venueCategoryId": 20,
          "venueId": 1004,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 20,
            "title": "BucketList Hotels",
            "region": "Global",
            "viewOrder": 0,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 859,
          "venueCategoryId": 9,
          "venueId": 1004,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 9,
            "title": "Scandinavian Break",
            "region": "Northern Europe",
            "viewOrder": 21,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        }
      ]
    },
    {
      "venueId": 983,
      "venueCategoryId": 20,
      "status": 1,
      "title": "UXUA Casa Hotel & Spa",
      "description": "Authenticity is the greatest luxury a visit to Brazil's picturesque and historic Bahia state can offer. And no place delivers the experience like Trancoso's Quadrado, its car-free, UNESCO-protected town square. \n\nHalf UXUA's casas here date back 500-years to the village's founding. They were restored by designer Wilbert Das in collaboration with local artisans using traditional techniques and reclaimed materials.\nThese casas have a soul, each with legends and family history, every detail uniquely crafted by caring hands.\n\nUXUA's rustic beach lounge, Almescar Spa, Quadrado restaurant and 5-star service complete the perfect holiday.",
      "brand": null,
      "blockQuote": "Once you've slipped on your house Havaianas, they're never coming off!",
      "emailAddress": "info@uxua.com",
      "website": "uxua.com/",
      "bookingURL": "http://www.booking.com/Share-S8NH8G?aid=925669",
      "redirectNotes": null,
      "phoneNumber": "+55 73 3668 2277",
      "addressOne": "",
      "addressTwo": "",
      "city": "Trancoso",
      "county": "",
      "region": "",
      "country": "Brazil",
      "rooms": 12,
      "dollar": 3,
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00",
      "medias": [
        {
          "mediaId": 9688,
          "title": "Uxua Casa Hotel, Trancoso, Brazil | The Gold Standard 2013 | Award ...",
          "url": "https://pkslist.azureedge.net/media/images/1/2bf12a0e-0c67-4b89-b9a0-42fb1639efd6/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/2bf12a0e-0c67-4b89-b9a0-42fb1639efd6/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/2bf12a0e-0c67-4b89-b9a0-42fb1639efd6/256x192.jpg",
          "viewOrder": 0
        },
        {
          "mediaId": 26396,
          "title": "UXUA Casa Hotel & Spa, Trancoso, Bahia",
          "url": "https://pkslist.azureedge.net/media/images/1/e3927820-6b01-4ef6-bee3-0e4578ac0aae/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/e3927820-6b01-4ef6-bee3-0e4578ac0aae/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/e3927820-6b01-4ef6-bee3-0e4578ac0aae/256x192.jpg",
          "viewOrder": 1
        },
        {
          "mediaId": 26395,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/fc2d924d-876b-4575-9cb0-8a80cac2c744/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/fc2d924d-876b-4575-9cb0-8a80cac2c744/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/fc2d924d-876b-4575-9cb0-8a80cac2c744/256x192.jpg",
          "viewOrder": 2
        },
        {
          "mediaId": 26389,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/7d045d97-1e2e-4713-bb35-f92d24118671/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/7d045d97-1e2e-4713-bb35-f92d24118671/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/7d045d97-1e2e-4713-bb35-f92d24118671/256x192.jpg",
          "viewOrder": 3
        },
        {
          "mediaId": 26393,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/e31f42b7-bf96-4621-ad01-547158d87dad/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/e31f42b7-bf96-4621-ad01-547158d87dad/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/e31f42b7-bf96-4621-ad01-547158d87dad/256x192.jpg",
          "viewOrder": 4
        },
        {
          "mediaId": 26392,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/17ab5448-04f1-4f17-afd3-b56adae8e6e5/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/17ab5448-04f1-4f17-afd3-b56adae8e6e5/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/17ab5448-04f1-4f17-afd3-b56adae8e6e5/256x192.jpg",
          "viewOrder": 5
        },
        {
          "mediaId": 26391,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/9af5644d-3668-4a96-81ac-48df38a99be5/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/9af5644d-3668-4a96-81ac-48df38a99be5/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/9af5644d-3668-4a96-81ac-48df38a99be5/256x192.jpg",
          "viewOrder": 6
        },
        {
          "mediaId": 9702,
          "title": "Hotel Spotlight: Uxua Casa Hotel & Spa, Brazil",
          "url": "https://pkslist.azureedge.net/media/images/1/dcf1dcdf-0776-42d3-8b27-7b13781435db/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/dcf1dcdf-0776-42d3-8b27-7b13781435db/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/dcf1dcdf-0776-42d3-8b27-7b13781435db/256x192.jpg",
          "viewOrder": 8
        },
        {
          "mediaId": 9687,
          "title": "It's a jungle in here, at the Uxua Casa Hotel on Brazil's Bahian ...",
          "url": "https://pkslist.azureedge.net/media/images/1/d8a4ca27-51f2-4362-8ef8-461b59c90710/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/d8a4ca27-51f2-4362-8ef8-461b59c90710/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/d8a4ca27-51f2-4362-8ef8-461b59c90710/256x192.jpg",
          "viewOrder": 9
        },
        {
          "mediaId": 26399,
          "title": "Uxua Casa Hotel & Spa, Trancoso | A Kuoni Hotel in Salvador & Bahia",
          "url": "https://pkslist.azureedge.net/media/images/1/1bbeeba9-0f3a-4c56-8ac5-934c35573b3a/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/1bbeeba9-0f3a-4c56-8ac5-934c35573b3a/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/1bbeeba9-0f3a-4c56-8ac5-934c35573b3a/256x192.jpg",
          "viewOrder": 10
        },
        {
          "mediaId": 9701,
          "title": "Uxua Casa Hotel & Spa | Book online | Bed & Breakfast Europe",
          "url": "https://pkslist.azureedge.net/media/images/1/e84d2b92-bca3-4ecd-b89f-00eb695b8b6b/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/e84d2b92-bca3-4ecd-b89f-00eb695b8b6b/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/e84d2b92-bca3-4ecd-b89f-00eb695b8b6b/256x192.jpg",
          "viewOrder": 11
        }
      ],
      "geographicArea": {
        "geographicAreaId": 812,
        "latitude": -16.589496,
        "longitude": -39.094224,
        "parentGeographicAreaId": 0,
        "title": "Porto Seguro - State of Bahia, Brazil",
        "updated": "2020-05-06T12:20:00",
        "created": "2020-05-06T12:20:00"
      },
      "personalities": [
        {
          "tagId": 50,
          "tagName": "Barefoot Luxury",
          "tagType": 1
        },
        {
          "tagId": 200,
          "tagName": "Rustic Elegance",
          "tagType": 1
        }
      ],
      "tags": [
        {
          "tagId": 4,
          "tagName": "Spa",
          "tagType": 2
        },
        {
          "tagId": 32,
          "tagName": "Garden",
          "tagType": 2
        },
        {
          "tagId": 58,
          "tagName": "Beach",
          "tagType": 2
        },
        {
          "tagId": 65,
          "tagName": "Shopping",
          "tagType": 2
        },
        {
          "tagId": 87,
          "tagName": "Outdoor Pool",
          "tagType": 2
        },
        {
          "tagId": 106,
          "tagName": "Nature",
          "tagType": 2
        },
        {
          "tagId": 110,
          "tagName": "Hiking",
          "tagType": 2
        },
        {
          "tagId": 122,
          "tagName": "Surfing",
          "tagType": 2
        },
        {
          "tagId": 152,
          "tagName": "Kite Surfing",
          "tagType": 2
        },
        {
          "tagId": 170,
          "tagName": "Kayaking",
          "tagType": 2
        },
        {
          "tagId": 207,
          "tagName": "Lounge/Bar",
          "tagType": 2
        },
        {
          "tagId": 276,
          "tagName": "Gym",
          "tagType": 2
        },
        {
          "tagId": 283,
          "tagName": "Restaurants",
          "tagType": 2
        },
        {
          "tagId": 324,
          "tagName": "Local Village",
          "tagType": 2
        }
      ],
      "venueCategoryAssigns": [
        {
          "venueCategoryAssignId": 178,
          "venueCategoryId": 20,
          "venueId": 983,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 20,
            "title": "BucketList Hotels",
            "region": "Global",
            "viewOrder": 0,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 351,
          "venueCategoryId": 15,
          "venueId": 983,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 15,
            "title": "Beach Paradise",
            "region": "Global",
            "viewOrder": 6,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 642,
          "venueCategoryId": 33,
          "venueId": 983,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 33,
            "title": "Rustic Elegance",
            "region": "Global",
            "viewOrder": 11,
            "updated": "2020-09-04T09:04:00",
            "created": "2020-09-04T09:04:00",
            "venueCategoryAssign": [
              
            ]
          }
        }
      ]
    },
    {
      "venueId": 972,
      "venueCategoryId": 20,
      "status": 1,
      "title": "Le K2 Palace",
      "description": "A concept inspired by a land of legendary peaks where truth is found in wisdom, K2 originated in the desire to meet a challenge and realize a dream. Located close to the centre of the resort and the croisette, the hotel features 29 rooms and 5 suite-chalets of exceptional quality. Like a prestigious hamlet, K2 stretches along of the Cospillot slope, offering guests an incomparable panorama of the valley and mountain massifs. The individual ski lift in front of the hotel provides access to the world’s largest ski area.With spacious rooms and suites, exceptional chalets, a 550 m² spa, two restaurants, smoking room, lounge bar, two leisure areas for children and teenagers, cinema room, night club, skishop, gym and hairdressing salon, K2 offers innovative services with a personal touch.",
      "brand": "",
      "blockQuote": "A very unique venue; Le K'baret can be converted into a cinema, theatre or nightclub worth checking out for your next event.",
      "emailAddress": "welcome@lek2palace.com",
      "website": "lek2palace.com/en/",
      "bookingURL": "https://be.synxis.com/?_ga=2.87993691.2102037658.1691737629-1231300605.1691602480&adult=1&agencyid=96027890&arrive=2023-12-28&chain=24447&child=0&currency=EUR&depart=2023-12-29&hotel=6680&level=hotel&locale=en-US&rooms=1&src=30",
      "redirectNotes": "Le K2 Palace's reservations system",
      "phoneNumber": "+33 4 79 40 08 80",
      "addressOne": "",
      "addressTwo": "Rue des Clarines",
      "city": "Courchevel",
      "county": "",
      "region": "",
      "country": "France",
      "rooms": 34,
      "dollar": 3,
      "updated": "2023-12-28T06:28:46.1",
      "created": "2020-05-06T12:20:00",
      "medias": [
        {
          "mediaId": 63335,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/cfa795fc-d3d3-4277-ac55-1a2ce2294d5b/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/cfa795fc-d3d3-4277-ac55-1a2ce2294d5b/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/cfa795fc-d3d3-4277-ac55-1a2ce2294d5b/256x192.jpeg",
          "viewOrder": 0
        },
        {
          "mediaId": 9469,
          "title": "Hotel Le K2, Courchevel 1850 • Alpine Guru",
          "url": "https://pkslist.azureedge.net/media/images/1/9c928bc3-d232-4ad8-b589-c8d685bad8b9/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/9c928bc3-d232-4ad8-b589-c8d685bad8b9/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/9c928bc3-d232-4ad8-b589-c8d685bad8b9/256x192.jpg",
          "viewOrder": 1
        },
        {
          "mediaId": 63343,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/7f0e1efa-f735-4a4e-a1e7-42ba3825e9d5/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/7f0e1efa-f735-4a4e-a1e7-42ba3825e9d5/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/7f0e1efa-f735-4a4e-a1e7-42ba3825e9d5/256x192.jpeg",
          "viewOrder": 2
        },
        {
          "mediaId": 63341,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/4926c3f5-e2d4-4831-9277-19abca5cf476/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/4926c3f5-e2d4-4831-9277-19abca5cf476/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/4926c3f5-e2d4-4831-9277-19abca5cf476/256x192.jpeg",
          "viewOrder": 3
        },
        {
          "mediaId": 63350,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/77842645-a282-42bf-ad3a-a669993d6a4a/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/77842645-a282-42bf-ad3a-a669993d6a4a/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/77842645-a282-42bf-ad3a-a669993d6a4a/256x192.jpeg",
          "viewOrder": 4
        },
        {
          "mediaId": 63344,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/5bc285ea-89b2-446e-86b7-e9e30d6344d4/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/5bc285ea-89b2-446e-86b7-e9e30d6344d4/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/5bc285ea-89b2-446e-86b7-e9e30d6344d4/256x192.jpeg",
          "viewOrder": 5
        },
        {
          "mediaId": 63336,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/2899b385-dde8-477f-a1a8-6b0ef7f48836/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/2899b385-dde8-477f-a1a8-6b0ef7f48836/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/2899b385-dde8-477f-a1a8-6b0ef7f48836/256x192.jpeg",
          "viewOrder": 6
        },
        {
          "mediaId": 63352,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/c15d9d50-5ad3-4bfd-80dd-c8dfd215a63a/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/c15d9d50-5ad3-4bfd-80dd-c8dfd215a63a/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/c15d9d50-5ad3-4bfd-80dd-c8dfd215a63a/256x192.jpeg",
          "viewOrder": 7
        },
        {
          "mediaId": 9459,
          "title": "Hôtel Le K2***** hôtel de luxe Courchevel 1850 - Luxe & Passions",
          "url": "https://pkslist.azureedge.net/media/images/1/4d8416c7-b84e-42ea-81eb-d2f4d5a73b1f/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/4d8416c7-b84e-42ea-81eb-d2f4d5a73b1f/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/4d8416c7-b84e-42ea-81eb-d2f4d5a73b1f/256x192.jpg",
          "viewOrder": 8
        },
        {
          "mediaId": 63348,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/db342787-c9ed-426a-ae2c-899020693d97/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/db342787-c9ed-426a-ae2c-899020693d97/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/db342787-c9ed-426a-ae2c-899020693d97/256x192.jpeg",
          "viewOrder": 11
        },
        {
          "mediaId": 9462,
          "title": "Le K2 Palace Hotel, Courchevel | Courchnet.com",
          "url": "https://pkslist.azureedge.net/media/images/1/7dbfe7e6-75cf-4055-ab36-f1fdff77f5bd/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/7dbfe7e6-75cf-4055-ab36-f1fdff77f5bd/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/7dbfe7e6-75cf-4055-ab36-f1fdff77f5bd/256x192.jpg",
          "viewOrder": 12
        },
        {
          "mediaId": 63339,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/c8e5c55f-0151-4fe7-aec3-c9dd920c40db/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/c8e5c55f-0151-4fe7-aec3-c9dd920c40db/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/c8e5c55f-0151-4fe7-aec3-c9dd920c40db/256x192.jpeg",
          "viewOrder": 14
        },
        {
          "mediaId": 63346,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/7d0c547b-e311-4128-b5da-f4b2e99b2afb/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/7d0c547b-e311-4128-b5da-f4b2e99b2afb/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/7d0c547b-e311-4128-b5da-f4b2e99b2afb/256x192.jpeg",
          "viewOrder": 15
        }
      ],
      "geographicArea": {
        "geographicAreaId": 800,
        "latitude": 45.41219879999999,
        "longitude": 6.638829299999998,
        "parentGeographicAreaId": 0,
        "title": "Rue des Clarines, 73120 Saint-Bon-Tarentaise, France",
        "updated": "2020-05-06T12:20:00",
        "created": "2020-05-06T12:20:00"
      },
      "personalities": [
        {
          "tagId": 43,
          "tagName": "Design Slickster",
          "tagType": 1
        },
        {
          "tagId": 200,
          "tagName": "Rustic Elegance",
          "tagType": 1
        }
      ],
      "tags": [
        {
          "tagId": 4,
          "tagName": "Spa",
          "tagType": 2
        },
        {
          "tagId": 66,
          "tagName": "Skiing",
          "tagType": 2
        },
        {
          "tagId": 83,
          "tagName": "Kid's Club",
          "tagType": 2
        },
        {
          "tagId": 84,
          "tagName": "Nightclub",
          "tagType": 2
        },
        {
          "tagId": 86,
          "tagName": "Indoor Pool",
          "tagType": 2
        },
        {
          "tagId": 101,
          "tagName": "Cinema",
          "tagType": 2
        },
        {
          "tagId": 127,
          "tagName": "Hair Salon",
          "tagType": 2
        },
        {
          "tagId": 207,
          "tagName": "Lounge/Bar",
          "tagType": 2
        },
        {
          "tagId": 222,
          "tagName": "Michelin Star dining",
          "tagType": 2
        },
        {
          "tagId": 283,
          "tagName": "Restaurants",
          "tagType": 2
        },
        {
          "tagId": 294,
          "tagName": "Event Space",
          "tagType": 2
        }
      ],
      "venueCategoryAssigns": [
        {
          "venueCategoryAssignId": 175,
          "venueCategoryId": 20,
          "venueId": 972,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 20,
            "title": "BucketList Hotels",
            "region": "Global",
            "viewOrder": 0,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 336,
          "venueCategoryId": 17,
          "venueId": 972,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 17,
            "title": "Winter Wonderland",
            "region": "Global",
            "viewOrder": 12,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        }
      ]
    },
    {
      "venueId": 925,
      "venueCategoryId": 20,
      "status": 1,
      "title": "Villa d'Este",
      "description": "Experience the splendor, the grandeur, the intrigue and the magic of Villa d'Este- an opulent hotel overlooking glorious Lake Como. Hailed as one of the best hotels in the world by Travel & Leisure, Condé Nast Traveler and Forbes Traveler, this famous resort destination has been widely praised for its beautiful gardens, exquisite cuisine, gala celebrations, impeccable décor and extraordinary hospitality.\n\nSet on the banks of one of the most romantic lakes in the world and just north of Milan, Villa d'Este was built in 1568 as the summer residence of Cardinal Tolomeo Gallio. The property comprises 25 acres of gardens, whose statues and landscape have been photographed for centuries. The favourite playground of an Empress, an English queen and aristocrats,  it was transformed into a luxury hotel in 1873. Continuously renovated and updated, without sacrificing any of its old-world charm, Villa d'Este today offers 152 rooms, all different in decor but linked by the superior standards of hospitality that you expect from one of Europe's truly legendary resorts. There are also four beautiful private villas - Villa Malakoff, Villa Cima, Villa Garrovo and Mosaic House - located in the park of the Hotel: guests are able to use all of the facilities and amenities of the Villa d'Este while maintaining the privacy and exclusivity that a private villa can offer.",
      "brand": null,
      "blockQuote": "Villa d’Este is the art of fine living, beckoning you to leave behind the city and indulge in the dolce vita lifestyle.",
      "emailAddress": "reservations@villadeste.it",
      "website": "villadeste.com/",
      "bookingURL": "https://www.myhotelreservation.net/b/hllw2112/HLLW2112/?f=2018-09-04&t=2018-09-05&i=96027890",
      "redirectNotes": "Villa d'Este's reservations system",
      "phoneNumber": "+39 031 3481",
      "addressOne": "40",
      "addressTwo": "Via Regina",
      "city": "Lake Como",
      "county": "",
      "region": "",
      "country": "Italy",
      "rooms": 152,
      "dollar": 3,
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00",
      "medias": [
        {
          "mediaId": 24708,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/8b57c89e-88ab-4c4e-b328-551d8b675098/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/8b57c89e-88ab-4c4e-b328-551d8b675098/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/8b57c89e-88ab-4c4e-b328-551d8b675098/256x192.jpg",
          "viewOrder": 0
        },
        {
          "mediaId": 9424,
          "title": "Villa D'Este Via Regina, 40, 22012 Cernobbio Como, Italy +39 031 ...",
          "url": "https://pkslist.azureedge.net/media/images/1/8ed0faae-c401-48dc-a458-076e541e37e5/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/8ed0faae-c401-48dc-a458-076e541e37e5/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/8ed0faae-c401-48dc-a458-076e541e37e5/256x192.jpg",
          "viewOrder": 1
        },
        {
          "mediaId": 9431,
          "title": "Pools - Villa d'Este | Hotels in Lake Como | Villa d'Este",
          "url": "https://pkslist.azureedge.net/media/images/1/06e8e9e3-d76a-4a16-b2c0-353b3ba0931e/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/06e8e9e3-d76a-4a16-b2c0-353b3ba0931e/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/06e8e9e3-d76a-4a16-b2c0-353b3ba0931e/256x192.jpg",
          "viewOrder": 2
        },
        {
          "mediaId": 9423,
          "title": "Villa d'Este - Lake Como 5 star Hotels | Villa d'Este",
          "url": "https://pkslist.azureedge.net/media/images/1/30bc24c8-211f-4fb8-9119-3b7a17198dbd/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/30bc24c8-211f-4fb8-9119-3b7a17198dbd/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/30bc24c8-211f-4fb8-9119-3b7a17198dbd/256x192.jpg",
          "viewOrder": 3
        },
        {
          "mediaId": 8805,
          "title": "Villa d'Este - Wikipedia",
          "url": "https://pkslist.azureedge.net/media/images/1/f2681ceb-fcc9-4aad-9f66-6653546e3078/1200x900.JPG",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/f2681ceb-fcc9-4aad-9f66-6653546e3078/800x600.JPG",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/f2681ceb-fcc9-4aad-9f66-6653546e3078/256x192.JPG",
          "viewOrder": 4
        },
        {
          "mediaId": 8811,
          "title": "Villa d'Este | Lake Como, Italy",
          "url": "https://pkslist.azureedge.net/media/images/1/1353b20a-22d8-4c91-9db2-ec97e184d748/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/1353b20a-22d8-4c91-9db2-ec97e184d748/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/1353b20a-22d8-4c91-9db2-ec97e184d748/256x192.jpg",
          "viewOrder": 5
        },
        {
          "mediaId": 8813,
          "title": "Junior Suite Top - Lake Como Hotels 5 Star | Villa d'Este",
          "url": "https://pkslist.azureedge.net/media/images/1/60327f16-bd70-421e-b19f-87313d9485f7/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/60327f16-bd70-421e-b19f-87313d9485f7/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/60327f16-bd70-421e-b19f-87313d9485f7/256x192.jpg",
          "viewOrder": 6
        },
        {
          "mediaId": 8814,
          "title": "Villa D'Este & Lake Como - The Most Romantic Places In Italy - The ...",
          "url": "https://pkslist.azureedge.net/media/images/1/9a8002bf-1c55-4aad-aace-7de17eff3760/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/9a8002bf-1c55-4aad-aace-7de17eff3760/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/9a8002bf-1c55-4aad-aace-7de17eff3760/256x192.jpg",
          "viewOrder": 7
        },
        {
          "mediaId": 9425,
          "title": "Villa d'Este - OFFICIAL SITE - 5 Star Hotels Lake Como | Villa d'Este",
          "url": "https://pkslist.azureedge.net/media/images/1/db73cd31-2475-4add-8754-459de711ce4d/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/db73cd31-2475-4add-8754-459de711ce4d/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/db73cd31-2475-4add-8754-459de711ce4d/256x192.jpg",
          "viewOrder": 9
        }
      ],
      "geographicArea": {
        "geographicAreaId": 756,
        "latitude": 45.8449084,
        "longitude": 9.07961720000003,
        "parentGeographicAreaId": 0,
        "title": "Via Regina, 40, 22012 Cernobbio CO, Italy",
        "updated": "2020-05-06T12:20:00",
        "created": "2020-05-06T12:20:00"
      },
      "personalities": [
        {
          "tagId": 44,
          "tagName": "Grand Dame ",
          "tagType": 1
        },
        {
          "tagId": 48,
          "tagName": "Iconic/Landmark ",
          "tagType": 1
        }
      ],
      "tags": [
        {
          "tagId": 4,
          "tagName": "Spa",
          "tagType": 2
        },
        {
          "tagId": 32,
          "tagName": "Garden",
          "tagType": 2
        },
        {
          "tagId": 65,
          "tagName": "Shopping",
          "tagType": 2
        },
        {
          "tagId": 79,
          "tagName": "Fitness",
          "tagType": 2
        },
        {
          "tagId": 81,
          "tagName": "Golf",
          "tagType": 2
        },
        {
          "tagId": 87,
          "tagName": "Outdoor Pool",
          "tagType": 2
        },
        {
          "tagId": 155,
          "tagName": "Art",
          "tagType": 2
        },
        {
          "tagId": 160,
          "tagName": "Turkish Bath",
          "tagType": 2
        },
        {
          "tagId": 172,
          "tagName": "Biking",
          "tagType": 2
        },
        {
          "tagId": 198,
          "tagName": "Boat Excursion",
          "tagType": 2
        },
        {
          "tagId": 266,
          "tagName": "Nature Walk",
          "tagType": 2
        },
        {
          "tagId": 283,
          "tagName": "Restaurants",
          "tagType": 2
        },
        {
          "tagId": 284,
          "tagName": "Architecture",
          "tagType": 2
        },
        {
          "tagId": 289,
          "tagName": "Antiques",
          "tagType": 2
        }
      ],
      "venueCategoryAssigns": [
        {
          "venueCategoryAssignId": 165,
          "venueCategoryId": 20,
          "venueId": 925,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 20,
            "title": "BucketList Hotels",
            "region": "Global",
            "viewOrder": 0,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        }
      ]
    },
    {
      "venueId": 908,
      "venueCategoryId": 20,
      "status": 1,
      "title": "Villa TreVille",
      "description": "Villa TreVille provides a unique, boutique hotel experience within a historic, oceanfront estate of about two hectares (five acres) in one of the most fascinating and exclusive places in the world: Positano on Italy's storied Amalfi Coast. This private paradise has an accommodation complex of four independent houses: Villa Bianca, Villa Rosa, Villa Azzurra and Villa Tre Pini. The villas contain a total of 15 guest suites, two with private gardens. Each suite is named after a special guest who has left his or her mark in the annals of the estate and on the arts world, their achievements honoured and recalled in part through the furnishings and art objects, with atmospheres further enhanced by the particular spaces and settings and uses of light and colour. Guests have exclusive access to the seaside sundecks and the dock for the estate's private motor launch through elevators and stone stairways cut into the landscaped, rocky promontory.The villas and amenities are artfully in harmony with the cliffside gardens and pathways that invite exploratory strolls between pergolas laden with vines and cascades of bougainvillea, where every turn can reveal touches of traditional craftsmanship and creative imagination.There are the original Vietri antique ceramics, or exacting reproductions, and extensive handiworks of local artisans such as the hand-painted plates produced exclusively for Villa TreVille by Rosalinda di Sorrento or the glasses made for the house by Venini, the historic Venetian manufacturer of glassware in Murano.The refurbishment of the entire property initially was under the artistic direction of Franco Zeffirelli, the opera and movie maestro who lived on the estate for 35 years. The work subsequently was continued with meticulous attention to essential details by the former proprietor, Giovanni Russo, who was advised by architect Fausta Gaetani, a friend of Mr. Zeffirelli's and a frequent guest at Villa TreVille.",
      "brand": "",
      "blockQuote": "It's so beautiful the only problem you'll have is  never wanting to leave.",
      "emailAddress": "info@villatreville.com",
      "website": "villatreville.com/",
      "bookingURL": "https://www.simplebooking.it/ibe/search?hid=7066&lang=EN&cur=GBP#/q&guests=A,A&in=2021-9-9&out=2021-9-10&iata=96027890",
      "redirectNotes": "Villa TreVille's reservations system",
      "phoneNumber": "+39 0 89 8122411 ",
      "addressOne": "30",
      "addressTwo": "Via Arienzo",
      "city": "Positano",
      "county": "",
      "region": "Amalfi Coast",
      "country": "Italy",
      "rooms": 15,
      "dollar": 3,
      "updated": "2023-09-05T13:38:47.89",
      "created": "2020-05-06T12:20:00",
      "medias": [
        {
          "mediaId": 8590,
          "title": "Villa TreVille - Home",
          "url": "https://pkslist.azureedge.net/media/images/1/7477e753-ab4e-456b-a22d-76086353299c/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/7477e753-ab4e-456b-a22d-76086353299c/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/7477e753-ab4e-456b-a22d-76086353299c/256x192.jpg",
          "viewOrder": 0
        },
        {
          "mediaId": 49332,
          "title": "Romantic Wedding at Villa Treville in Positano",
          "url": "https://pkslist.azureedge.net/media/images/1/ceeda791-bbf9-4425-acb3-086258295cb1/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/ceeda791-bbf9-4425-acb3-086258295cb1/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/ceeda791-bbf9-4425-acb3-086258295cb1/256x192.jpg",
          "viewOrder": 1
        },
        {
          "mediaId": 49325,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/2ef3f05c-1dc2-492d-b274-425a88841cfb/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/2ef3f05c-1dc2-492d-b274-425a88841cfb/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/2ef3f05c-1dc2-492d-b274-425a88841cfb/256x192.jpeg",
          "viewOrder": 2
        },
        {
          "mediaId": 49324,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/d382c717-c4bd-48d0-b04a-271bdedea3df/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/d382c717-c4bd-48d0-b04a-271bdedea3df/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/d382c717-c4bd-48d0-b04a-271bdedea3df/256x192.jpeg",
          "viewOrder": 3
        },
        {
          "mediaId": 49335,
          "title": "Positano – Villa Treville | Amanda Tabberer",
          "url": "https://pkslist.azureedge.net/media/images/1/30df4ed7-a425-4367-a935-874182afccf4/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/30df4ed7-a425-4367-a935-874182afccf4/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/30df4ed7-a425-4367-a935-874182afccf4/256x192.jpg",
          "viewOrder": 4
        },
        {
          "mediaId": 49323,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/4fd7a1d0-45a8-482b-a0d6-795030c0b3b0/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/4fd7a1d0-45a8-482b-a0d6-795030c0b3b0/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/4fd7a1d0-45a8-482b-a0d6-795030c0b3b0/256x192.jpeg",
          "viewOrder": 5
        },
        {
          "mediaId": 49320,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/9a9d34da-cb48-402c-ae6e-5762dcde402a/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/9a9d34da-cb48-402c-ae6e-5762dcde402a/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/9a9d34da-cb48-402c-ae6e-5762dcde402a/256x192.jpeg",
          "viewOrder": 6
        },
        {
          "mediaId": 8598,
          "title": "Positano – Villa Treville | Amanda Tabberer",
          "url": "https://pkslist.azureedge.net/media/images/1/6e4033fb-96f0-45cb-9325-7954e8fb81ad/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/6e4033fb-96f0-45cb-9325-7954e8fb81ad/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/6e4033fb-96f0-45cb-9325-7954e8fb81ad/256x192.jpg",
          "viewOrder": 7
        },
        {
          "mediaId": 8591,
          "title": "Villa Treville - Positano - Prices and availability",
          "url": "https://pkslist.azureedge.net/media/images/1/ab70afbe-094e-4dbd-abb7-d58dfcd0538a/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/ab70afbe-094e-4dbd-abb7-d58dfcd0538a/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/ab70afbe-094e-4dbd-abb7-d58dfcd0538a/256x192.jpg",
          "viewOrder": 8
        },
        {
          "mediaId": 8592,
          "title": "Villa Tre Ville - Seafront Luxury Italian Villa For Rent In ...",
          "url": "https://pkslist.azureedge.net/media/images/1/837e7bd6-e668-48e6-8f27-a61e240eadf6/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/837e7bd6-e668-48e6-8f27-a61e240eadf6/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/837e7bd6-e668-48e6-8f27-a61e240eadf6/256x192.jpg",
          "viewOrder": 9
        }
      ],
      "geographicArea": {
        "geographicAreaId": 738,
        "latitude": 40.62647299999999,
        "longitude": 14.49692159999995,
        "parentGeographicAreaId": 0,
        "title": "Via Arienzo, 30, 84017 Positano SA, Italy",
        "updated": "2020-05-06T12:20:00",
        "created": "2020-05-06T12:20:00"
      },
      "personalities": [
        {
          "tagId": 51,
          "tagName": "Style Icon",
          "tagType": 1
        },
        {
          "tagId": 57,
          "tagName": "Romantic",
          "tagType": 1
        }
      ],
      "tags": [
        {
          "tagId": 32,
          "tagName": "Garden",
          "tagType": 2
        },
        {
          "tagId": 65,
          "tagName": "Shopping",
          "tagType": 2
        },
        {
          "tagId": 73,
          "tagName": "Outdoor Terraces",
          "tagType": 2
        },
        {
          "tagId": 107,
          "tagName": "Seaside",
          "tagType": 2
        },
        {
          "tagId": 155,
          "tagName": "Art",
          "tagType": 2
        },
        {
          "tagId": 198,
          "tagName": "Boat Excursion",
          "tagType": 2
        },
        {
          "tagId": 206,
          "tagName": "Private Beach",
          "tagType": 2
        },
        {
          "tagId": 207,
          "tagName": "Lounge/Bar",
          "tagType": 2
        },
        {
          "tagId": 270,
          "tagName": "Beach Club",
          "tagType": 2
        },
        {
          "tagId": 283,
          "tagName": "Restaurants",
          "tagType": 2
        }
      ],
      "venueCategoryAssigns": [
        {
          "venueCategoryAssignId": 162,
          "venueCategoryId": 20,
          "venueId": 908,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 20,
            "title": "BucketList Hotels",
            "region": "Global",
            "viewOrder": 0,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        }
      ]
    },
    {
      "venueId": 871,
      "venueCategoryId": 20,
      "status": 1,
      "title": "Post Ranch Inn",
      "description": "Embracing the dramatic beauty of the California coastline, Post Ranch Inn is a serene retreat where romance and connection thrive. The 39 guest rooms and one private home blend rustic elegance with luxury and comfort, offering stunning ocean or mountain views.\n\nPost Ranch is a destination in which to relax and reconnect with yourself, your partner and the environment. With this in mind, our guest rooms do not include televisions or alarm clocks. The minimum age for guests is 18 years old and pets are not allowed.\n\nPost Ranch Inn does not charge a resort fee. All reservations include a daily gourmet buffet breakfast, a mini-bar offering snacks and non-alcoholic beverages, wireless Internet access, valet parking, complimentary activities (including daily yoga, guided nature hikes, stargazing, garden tours), access to the infinity and swimming pools and more.",
      "brand": null,
      "blockQuote": "A very magical place with one of the most stunning views anywhere.",
      "emailAddress": "reservations@postranchinn.com",
      "website": "postranchinn.com",
      "bookingURL": "https://gc.synxis.com/rez.aspx?tps=fml&adult=1&step=1&hotel=27123&shell=fMRYPR&chain=10237&template=fMRYPR&avcurrency=USD&iata=96027890",
      "redirectNotes": "Post Ranch Inn's reservations system",
      "phoneNumber": " +1 831 667 2200",
      "addressOne": "47900",
      "addressTwo": "California 1",
      "city": "Big Sur",
      "county": "",
      "region": "California",
      "country": "United States",
      "rooms": 39,
      "dollar": 3,
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00",
      "medias": [
        {
          "mediaId": 8127,
          "title": "Post Ranch Inn Big Sur - Luxury Boutique Hotel in Big Sur CA",
          "url": "https://pkslist.azureedge.net/media/images/1/e8b2113d-c3b3-4228-9b40-15359e38855e/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/e8b2113d-c3b3-4228-9b40-15359e38855e/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/e8b2113d-c3b3-4228-9b40-15359e38855e/256x192.jpg",
          "viewOrder": 0
        },
        {
          "mediaId": 8119,
          "title": "Post Ranch Inn, Big Sur, CA - California Beaches",
          "url": "https://pkslist.azureedge.net/media/images/1/4ef9be1c-bd25-414c-8f02-83af6d96c672/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/4ef9be1c-bd25-414c-8f02-83af6d96c672/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/4ef9be1c-bd25-414c-8f02-83af6d96c672/256x192.jpg",
          "viewOrder": 1
        },
        {
          "mediaId": 8126,
          "title": "Post Ranch Inn - UPDATED 2017 Hotel Reviews & Price Comparison ...",
          "url": "https://pkslist.azureedge.net/media/images/1/9c39aceb-4d61-4f4f-b796-c44ab043a0d3/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/9c39aceb-4d61-4f4f-b796-c44ab043a0d3/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/9c39aceb-4d61-4f4f-b796-c44ab043a0d3/256x192.jpg",
          "viewOrder": 2
        },
        {
          "mediaId": 8120,
          "title": "Post Ranch Inn, Big Sur, California",
          "url": "https://pkslist.azureedge.net/media/images/1/420a40e6-f8df-4bdc-84b3-13715e35f9af/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/420a40e6-f8df-4bdc-84b3-13715e35f9af/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/420a40e6-f8df-4bdc-84b3-13715e35f9af/256x192.jpg",
          "viewOrder": 3
        },
        {
          "mediaId": 8123,
          "title": "Luxury Hotels in Big Sur CA | Post Ranch Inn - About Us | Monterey ...",
          "url": "https://pkslist.azureedge.net/media/images/1/edd6594e-d687-453e-b83f-9d1a4075958a/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/edd6594e-d687-453e-b83f-9d1a4075958a/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/edd6594e-d687-453e-b83f-9d1a4075958a/256x192.jpg",
          "viewOrder": 4
        },
        {
          "mediaId": 8128,
          "title": "Post Ranch Inn, Big Sur, CA - California Beaches",
          "url": "https://pkslist.azureedge.net/media/images/1/a97a0b18-ab1e-4523-b936-491b473b7a3c/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/a97a0b18-ab1e-4523-b936-491b473b7a3c/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/a97a0b18-ab1e-4523-b936-491b473b7a3c/256x192.jpg",
          "viewOrder": 5
        },
        {
          "mediaId": 8129,
          "title": "Post House – Post Ranch Inn, Big Sur – In Villas Veritas",
          "url": "https://pkslist.azureedge.net/media/images/1/88edd695-6384-474d-8f9b-f7bc554cc429/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/88edd695-6384-474d-8f9b-f7bc554cc429/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/88edd695-6384-474d-8f9b-f7bc554cc429/256x192.jpg",
          "viewOrder": 6
        }
      ],
      "geographicArea": {
        "geographicAreaId": 693,
        "latitude": 36.2302565,
        "longitude": -121.77181180000002,
        "parentGeographicAreaId": 0,
        "title": "47900 CA-1, Big Sur, CA 93920, USA",
        "updated": "2020-05-06T12:20:00",
        "created": "2020-05-06T12:20:00"
      },
      "personalities": [
        {
          "tagId": 53,
          "tagName": "Cutting Edge",
          "tagType": 1
        },
        {
          "tagId": 57,
          "tagName": "Romantic",
          "tagType": 1
        }
      ],
      "tags": [
        {
          "tagId": 60,
          "tagName": "Gastronomic",
          "tagType": 2
        },
        {
          "tagId": 92,
          "tagName": "Infinity Pool",
          "tagType": 2
        },
        {
          "tagId": 100,
          "tagName": "Wedding",
          "tagType": 2
        },
        {
          "tagId": 106,
          "tagName": "Nature",
          "tagType": 2
        },
        {
          "tagId": 121,
          "tagName": "Yoga",
          "tagType": 2
        },
        {
          "tagId": 155,
          "tagName": "Art",
          "tagType": 2
        },
        {
          "tagId": 199,
          "tagName": "Art Gallery",
          "tagType": 2
        },
        {
          "tagId": 207,
          "tagName": "Lounge/Bar",
          "tagType": 2
        },
        {
          "tagId": 266,
          "tagName": "Nature Walk",
          "tagType": 2
        },
        {
          "tagId": 293,
          "tagName": "Meeting Space",
          "tagType": 2
        },
        {
          "tagId": 320,
          "tagName": "Organic Garden",
          "tagType": 2
        }
      ],
      "venueCategoryAssigns": [
        {
          "venueCategoryAssignId": 156,
          "venueCategoryId": 20,
          "venueId": 871,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 20,
            "title": "BucketList Hotels",
            "region": "Global",
            "viewOrder": 0,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 349,
          "venueCategoryId": 5,
          "venueId": 871,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 5,
            "title": "Romantic Getaways",
            "region": "Global",
            "viewOrder": 20,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 350,
          "venueCategoryId": 1,
          "venueId": 871,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 1,
            "title": "Escape from the City",
            "region": "Global",
            "viewOrder": 14,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        }
      ]
    },
    {
      "venueId": 850,
      "venueCategoryId": 20,
      "status": 1,
      "title": "Eden Rock - St Barths",
      "description": "Eden Rock - St Barths is ‘beyond perfect’. Impeccable attention to detail, the hallmark of Oetker Collection hotels, creativity and sheer art de vivre, make it one of the most desirable resorts in the world for the travelling elite. And the location is stunning, perched on a rocky promontory that sheers the white sands of St Jean Bay in two; there is nowhere to match Eden Rock-St Barths for luxurious relaxation and play.",
      "brand": "",
      "blockQuote": "Masterminded by world-famous chef Jean-Georges Vongerichten, the Sand Bar is the heart of the property and where you'll indulge in delicious drinks and fresh dishes.",
      "emailAddress": "reservations.edenrock@oetkercollection.com",
      "website": "oetkercollection.com/destinations/eden-rock-st-barths/",
      "bookingURL": "https://gc.synxis.com/rez.aspx?Hotel=63102&Chain=13518&adult=2&Locale=en&_ga=2.266396046.1227213672.1535967993-942951975.1533504014&iata=96027890",
      "redirectNotes": "Eden Rock - St Barths' reservations system",
      "phoneNumber": "+ 590 590 29 79 99",
      "addressOne": "",
      "addressTwo": "",
      "city": "Baie de Saint Jean",
      "county": "",
      "region": "St Barths",
      "country": "Caribbean",
      "rooms": 37,
      "dollar": 3,
      "updated": "2023-06-13T07:48:40.843",
      "created": "2020-05-06T12:20:00",
      "medias": [
        {
          "mediaId": 7801,
          "title": "Eden Rock Hotel at St. Barths | Travel | Pinterest | St barths ...",
          "url": "https://pkslist.azureedge.net/media/images/1/c1571d47-fe1b-4252-83d7-3135436f4dc4/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/c1571d47-fe1b-4252-83d7-3135436f4dc4/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/c1571d47-fe1b-4252-83d7-3135436f4dc4/256x192.jpg",
          "viewOrder": 0
        },
        {
          "mediaId": 7804,
          "title": "The Eden Rock St. Barths: One of the World's top 5-star Hotels ...",
          "url": "https://pkslist.azureedge.net/media/images/1/16664b3e-3b97-423f-99d5-efb01ccf5927/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/16664b3e-3b97-423f-99d5-efb01ccf5927/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/16664b3e-3b97-423f-99d5-efb01ccf5927/256x192.jpg",
          "viewOrder": 1
        },
        {
          "mediaId": 7806,
          "title": "Hotels: Eden Rock - St Barths | Air Canada enRoute",
          "url": "https://pkslist.azureedge.net/media/images/1/e6ca38f8-bdc9-49f5-9ddf-3ff361b92e2a/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/e6ca38f8-bdc9-49f5-9ddf-3ff361b92e2a/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/e6ca38f8-bdc9-49f5-9ddf-3ff361b92e2a/256x192.jpg",
          "viewOrder": 2
        },
        {
          "mediaId": 7810,
          "title": "Eden Rock St. Barths - Heavenly Beach House - Orchid",
          "url": "https://pkslist.azureedge.net/media/images/1/3422d56a-a473-4dc1-b804-ebaeca1d8506/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/3422d56a-a473-4dc1-b804-ebaeca1d8506/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/3422d56a-a473-4dc1-b804-ebaeca1d8506/256x192.jpg",
          "viewOrder": 3
        },
        {
          "mediaId": 7808,
          "title": "Eden Rock St. Barths - Diamond Suite - Waterlily",
          "url": "https://pkslist.azureedge.net/media/images/1/ccc69536-f5a7-43ba-a52a-947229bd0de0/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/ccc69536-f5a7-43ba-a52a-947229bd0de0/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/ccc69536-f5a7-43ba-a52a-947229bd0de0/256x192.jpg",
          "viewOrder": 4
        },
        {
          "mediaId": 7805,
          "title": "Eden Rock St Barths | Luxury Hotel Saint Barths Caribbean",
          "url": "https://pkslist.azureedge.net/media/images/1/763a9583-9e0e-472f-894f-945d0d6c269d/1200x900.5630434782608695652173913043&slimmage=true&rnd=130978548420000000",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/763a9583-9e0e-472f-894f-945d0d6c269d/800x600.5630434782608695652173913043&slimmage=true&rnd=130978548420000000",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/763a9583-9e0e-472f-894f-945d0d6c269d/256x192.5630434782608695652173913043&slimmage=true&rnd=130978548420000000",
          "viewOrder": 5
        }
      ],
      "geographicArea": {
        "geographicAreaId": 673,
        "latitude": 17.9031705,
        "longitude": -62.83612579999999,
        "parentGeographicAreaId": 0,
        "title": "Baie de Saint Jean, Saint Barthélemy 97133, St Barthélemy",
        "updated": "2020-05-06T12:20:00",
        "created": "2020-05-06T12:20:00"
      },
      "personalities": [
        {
          "tagId": 48,
          "tagName": "Iconic/Landmark ",
          "tagType": 1
        },
        {
          "tagId": 54,
          "tagName": "Happening",
          "tagType": 1
        }
      ],
      "tags": [
        {
          "tagId": 4,
          "tagName": "Spa",
          "tagType": 2
        },
        {
          "tagId": 60,
          "tagName": "Gastronomic",
          "tagType": 2
        },
        {
          "tagId": 65,
          "tagName": "Shopping",
          "tagType": 2
        },
        {
          "tagId": 90,
          "tagName": "Water Sports",
          "tagType": 2
        },
        {
          "tagId": 105,
          "tagName": "Afternoon Tea",
          "tagType": 2
        },
        {
          "tagId": 155,
          "tagName": "Art",
          "tagType": 2
        },
        {
          "tagId": 181,
          "tagName": "Private/Suite Pools",
          "tagType": 2
        },
        {
          "tagId": 198,
          "tagName": "Boat Excursion",
          "tagType": 2
        },
        {
          "tagId": 206,
          "tagName": "Private Beach",
          "tagType": 2
        },
        {
          "tagId": 207,
          "tagName": "Lounge/Bar",
          "tagType": 2
        },
        {
          "tagId": 243,
          "tagName": "Outdoor Activities",
          "tagType": 2
        },
        {
          "tagId": 255,
          "tagName": "Music",
          "tagType": 2
        },
        {
          "tagId": 283,
          "tagName": "Restaurants",
          "tagType": 2
        }
      ],
      "venueCategoryAssigns": [
        {
          "venueCategoryAssignId": 152,
          "venueCategoryId": 20,
          "venueId": 850,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 20,
            "title": "BucketList Hotels",
            "region": "Global",
            "viewOrder": 0,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        }
      ]
    },
    {
      "venueId": 846,
      "venueCategoryId": 19,
      "status": 1,
      "title": "COMO Parrot Cay",
      "description": "COMO Parrot Cay is an award-winning Turks and Caicos luxury resort set on its own private island. With 1,000 unspoilt acres and mile-long beach, we deliver foot-in-the-sand luxury. All rooms, suites and beach villas have soothing interiors along with COMO’s service ethic, holistic therapies, yoga, diving and worldclass cuisine.",
      "brand": "COMO Hotels",
      "blockQuote": "Amazing villas as part of the room inventory makes this a perfect place for multi generational family trips.",
      "emailAddress": "parrotcay@comohotels.com",
      "website": "comohotels.com/parrotcay",
      "bookingURL": "https://gc.synxis.com/rez.aspx?Hotel=27586&Chain=10327&Adult=1&Child=0&Arrive=2018-09-04&Depart=2018-09-05&Start=AvailResults&lang=0&_ga=2.139026739.1223958135.1536067566-1513030496.1536067566&iata=96027890",
      "redirectNotes": "COMO Parrot Cay's reservations system",
      "phoneNumber": "+1 649 946 7788",
      "addressOne": "Parrot Cay",
      "addressTwo": "",
      "city": "Parrot Cay",
      "county": "",
      "region": "Turks and Caicos",
      "country": "Caribbean",
      "rooms": 75,
      "dollar": 3,
      "updated": "2020-05-21T12:13:16.18",
      "created": "2020-05-06T12:20:00",
      "medias": [
        {
          "mediaId": 17902,
          "title": "SI Swimsuit 2017: COMO Parrot Cay in Turks and Caicos",
          "url": "https://pkslist.azureedge.net/media/images/1/de8f698d-427a-4f5e-b53e-3f322d2efe45/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/de8f698d-427a-4f5e-b53e-3f322d2efe45/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/de8f698d-427a-4f5e-b53e-3f322d2efe45/256x192.jpg",
          "viewOrder": 0
        },
        {
          "mediaId": 31293,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/bb3d2795-962d-4ce0-b1dc-07aa76723e90/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/bb3d2795-962d-4ce0-b1dc-07aa76723e90/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/bb3d2795-962d-4ce0-b1dc-07aa76723e90/256x192.jpg",
          "viewOrder": 1
        },
        {
          "mediaId": 31304,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/ca279c8d-2c8f-4bf5-8261-03576741ecb7/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/ca279c8d-2c8f-4bf5-8261-03576741ecb7/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/ca279c8d-2c8f-4bf5-8261-03576741ecb7/256x192.jpg",
          "viewOrder": 2
        },
        {
          "mediaId": 31300,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/c2cde250-c059-4ded-9693-638f20dfbc1d/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/c2cde250-c059-4ded-9693-638f20dfbc1d/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/c2cde250-c059-4ded-9693-638f20dfbc1d/256x192.jpg",
          "viewOrder": 3
        },
        {
          "mediaId": 31302,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/465af76d-605c-4dd4-9383-51357e1d0a38/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/465af76d-605c-4dd4-9383-51357e1d0a38/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/465af76d-605c-4dd4-9383-51357e1d0a38/256x192.jpg",
          "viewOrder": 4
        },
        {
          "mediaId": 31298,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/2ea91779-8241-4656-86ee-a2884e3ae5dc/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/2ea91779-8241-4656-86ee-a2884e3ae5dc/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/2ea91779-8241-4656-86ee-a2884e3ae5dc/256x192.jpg",
          "viewOrder": 5
        },
        {
          "mediaId": 17905,
          "title": "Parrot Cay by COMO « Luxury Hotels TravelPlusStyle",
          "url": "https://pkslist.azureedge.net/media/images/1/4571d133-92ca-47c7-87b7-ba87b9f13693/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/4571d133-92ca-47c7-87b7-ba87b9f13693/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/4571d133-92ca-47c7-87b7-ba87b9f13693/256x192.jpg",
          "viewOrder": 6
        },
        {
          "mediaId": 31297,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/4dc4cbd0-3000-4023-8677-4b1a972351d4/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/4dc4cbd0-3000-4023-8677-4b1a972351d4/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/4dc4cbd0-3000-4023-8677-4b1a972351d4/256x192.jpg",
          "viewOrder": 7
        },
        {
          "mediaId": 31299,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/5d171b81-df97-4976-9e59-3dda38fbd4f4/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/5d171b81-df97-4976-9e59-3dda38fbd4f4/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/5d171b81-df97-4976-9e59-3dda38fbd4f4/256x192.jpg",
          "viewOrder": 8
        },
        {
          "mediaId": 9734,
          "title": "COMO Parrot Cay",
          "url": "https://pkslist.azureedge.net/media/images/1/00e72b56-7a58-480b-9f32-3c9d7bd53b9d/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/00e72b56-7a58-480b-9f32-3c9d7bd53b9d/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/00e72b56-7a58-480b-9f32-3c9d7bd53b9d/256x192.jpg",
          "viewOrder": 10
        }
      ],
      "geographicArea": {
        "geographicAreaId": 669,
        "latitude": 21.9287183,
        "longitude": -72.0599138,
        "parentGeographicAreaId": 0,
        "title": "Parrot Cay, Turks and Caicos Islands TKCA 1ZZ, Turks and Caicos Islands",
        "updated": "2020-05-06T12:20:00",
        "created": "2020-05-06T12:20:00"
      },
      "personalities": [
        {
          "tagId": 50,
          "tagName": "Barefoot Luxury",
          "tagType": 1
        },
        {
          "tagId": 157,
          "tagName": "Beach Escape",
          "tagType": 1
        }
      ],
      "tags": [
        {
          "tagId": 4,
          "tagName": "Spa",
          "tagType": 2
        },
        {
          "tagId": 60,
          "tagName": "Gastronomic",
          "tagType": 2
        },
        {
          "tagId": 90,
          "tagName": "Water Sports",
          "tagType": 2
        },
        {
          "tagId": 92,
          "tagName": "Infinity Pool",
          "tagType": 2
        },
        {
          "tagId": 116,
          "tagName": "Scuba Diving",
          "tagType": 2
        },
        {
          "tagId": 121,
          "tagName": "Yoga",
          "tagType": 2
        },
        {
          "tagId": 181,
          "tagName": "Private/Suite Pools",
          "tagType": 2
        },
        {
          "tagId": 198,
          "tagName": "Boat Excursion",
          "tagType": 2
        },
        {
          "tagId": 206,
          "tagName": "Private Beach",
          "tagType": 2
        },
        {
          "tagId": 235,
          "tagName": "Hot Tub",
          "tagType": 2
        }
      ],
      "venueCategoryAssigns": [
        {
          "venueCategoryAssignId": 148,
          "venueCategoryId": 19,
          "venueId": 846,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 19,
            "title": "Private Islands",
            "region": "Global",
            "viewOrder": 15,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 448,
          "venueCategoryId": 15,
          "venueId": 846,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 15,
            "title": "Beach Paradise",
            "region": "Global",
            "viewOrder": 6,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 449,
          "venueCategoryId": 20,
          "venueId": 846,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 20,
            "title": "BucketList Hotels",
            "region": "Global",
            "viewOrder": 0,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 450,
          "venueCategoryId": 5,
          "venueId": 846,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 5,
            "title": "Romantic Getaways",
            "region": "Global",
            "viewOrder": 20,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        }
      ]
    },
    {
      "venueId": 841,
      "venueCategoryId": 19,
      "status": 1,
      "title": "North Island",
      "description": "Set foot on your own private island in the Seychelles. Infinite, exclusive space where the embrace of lush tropical palms and giant granite peaks dwarf your presence with their drama. With just 11 villas, your footprints will be the only evidence on the Island’s vast, untouched beaches.\nNorth Island epitomises the freedom that complete privacy allows, extraordinary experiences, perfectly personally appropriate, and service that is generously big-hearted. The Island’s abundance naturally extends to the luxury of choice that a peerless all-inclusive offering provides. Experience a true barefoot-luxury escape.\n\nNorth Island eleven private guest villas offers each guest an unparalleled sense of exclusivity and luxury. All villas have been created from local materials harvested during the island rehabilitation process, balancing luxury and simplicity to form a signature architectural style called “barefoot luxury”. Your villa will become your own private beach home.",
      "brand": "",
      "blockQuote": "This is truly one of the most exclusive private islands in the world.",
      "emailAddress": "reservations@north-island.com",
      "website": "north-island.com/",
      "bookingURL": "http://www.booking.com/Share-GG8DA7?aid=925669",
      "redirectNotes": null,
      "phoneNumber": "+248 4293 110",
      "addressOne": "",
      "addressTwo": "",
      "city": "North Island",
      "county": "",
      "region": "",
      "country": "Seychelles",
      "rooms": 11,
      "dollar": 3,
      "updated": "2020-05-13T20:02:00",
      "created": "2020-05-06T12:20:00",
      "medias": [
        {
          "mediaId": 7814,
          "title": "North Island,seychelles",
          "url": "https://pkslist.azureedge.net/media/images/1/225e4158-c62a-489a-abb0-b0802bc996a6/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/225e4158-c62a-489a-abb0-b0802bc996a6/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/225e4158-c62a-489a-abb0-b0802bc996a6/256x192.jpg",
          "viewOrder": 0
        },
        {
          "mediaId": 7722,
          "title": "North Island Seychelles - North Island - justseychelles.com",
          "url": "https://pkslist.azureedge.net/media/images/1/2106189b-328b-40a1-bb0e-879a2aab8295/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/2106189b-328b-40a1-bb0e-879a2aab8295/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/2106189b-328b-40a1-bb0e-879a2aab8295/256x192.jpg",
          "viewOrder": 1
        },
        {
          "mediaId": 7724,
          "title": "North Island - #Seychelles - Connoisseur's | Private Islands ...",
          "url": "https://pkslist.azureedge.net/media/images/1/2d317f41-0340-438a-8631-4ec39e4e6c73/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/2d317f41-0340-438a-8631-4ec39e4e6c73/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/2d317f41-0340-438a-8631-4ec39e4e6c73/256x192.jpg",
          "viewOrder": 2
        },
        {
          "mediaId": 7830,
          "title": "The Entrancing North Island, Seychelles | The BackPackers",
          "url": "https://pkslist.azureedge.net/media/images/1/0620a85a-adff-42b9-bbb7-cc1936d63be0/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/0620a85a-adff-42b9-bbb7-cc1936d63be0/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/0620a85a-adff-42b9-bbb7-cc1936d63be0/256x192.jpg",
          "viewOrder": 3
        },
        {
          "mediaId": 7725,
          "title": "North Island, Seychelles – Cris & Kim",
          "url": "https://pkslist.azureedge.net/media/images/1/55b75aed-78a9-4ba7-86e0-b6939b2573b1/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/55b75aed-78a9-4ba7-86e0-b6939b2573b1/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/55b75aed-78a9-4ba7-86e0-b6939b2573b1/256x192.jpg",
          "viewOrder": 4
        },
        {
          "mediaId": 7821,
          "title": "Welcome to North Island, Seychelles - YouTube",
          "url": "https://pkslist.azureedge.net/media/images/1/5c9bd1e9-341f-4d99-95b2-1d64ccc90e07/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/5c9bd1e9-341f-4d99-95b2-1d64ccc90e07/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/5c9bd1e9-341f-4d99-95b2-1d64ccc90e07/256x192.jpg",
          "viewOrder": 5
        },
        {
          "mediaId": 7820,
          "title": "96 best images about North Island, Seychelles on Pinterest ...",
          "url": "https://pkslist.azureedge.net/media/images/1/54cdcd57-9aca-409d-a9b5-77cb046bb397/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/54cdcd57-9aca-409d-a9b5-77cb046bb397/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/54cdcd57-9aca-409d-a9b5-77cb046bb397/256x192.jpg",
          "viewOrder": 6
        },
        {
          "mediaId": 7826,
          "title": "North Island Lodge, Seychelles | Go2Africa.com",
          "url": "https://pkslist.azureedge.net/media/images/1/650d6f1f-134e-44a7-bbbd-57205aaf272a/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/650d6f1f-134e-44a7-bbbd-57205aaf272a/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/650d6f1f-134e-44a7-bbbd-57205aaf272a/256x192.jpg",
          "viewOrder": 7
        },
        {
          "mediaId": 7825,
          "title": "North Island",
          "url": "https://pkslist.azureedge.net/media/images/1/e95844cf-c04b-4ce8-ac13-0f42327f47a7/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/e95844cf-c04b-4ce8-ac13-0f42327f47a7/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/e95844cf-c04b-4ce8-ac13-0f42327f47a7/256x192.jpg",
          "viewOrder": 8
        },
        {
          "mediaId": 7833,
          "title": "North-Island-bath---credit-Dana-Allen | Hospitality | Pinterest ...",
          "url": "https://pkslist.azureedge.net/media/images/1/f3cba7c2-bec7-4afd-993b-e9c2e3db1b21/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/f3cba7c2-bec7-4afd-993b-e9c2e3db1b21/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/f3cba7c2-bec7-4afd-993b-e9c2e3db1b21/256x192.jpg",
          "viewOrder": 9
        },
        {
          "mediaId": 7832,
          "title": "North Island | Luxury Hotel in Seychelles Africa",
          "url": "https://pkslist.azureedge.net/media/images/1/3ac6469f-f715-4ebd-9d8f-5773c14a2a33/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/3ac6469f-f715-4ebd-9d8f-5773c14a2a33/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/3ac6469f-f715-4ebd-9d8f-5773c14a2a33/256x192.jpg",
          "viewOrder": 10
        },
        {
          "mediaId": 7827,
          "title": "Best Seychelles hotels and resorts (Condé Nast Traveller)",
          "url": "https://pkslist.azureedge.net/media/images/1/290597e5-03b2-46ea-93e8-7fca6570cafb/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/290597e5-03b2-46ea-93e8-7fca6570cafb/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/290597e5-03b2-46ea-93e8-7fca6570cafb/256x192.jpg",
          "viewOrder": 11
        }
      ],
      "geographicArea": {
        "geographicAreaId": 664,
        "latitude": -4.394964799999999,
        "longitude": 55.24525670000003,
        "parentGeographicAreaId": 0,
        "title": "Ile du Nord, Seychelles",
        "updated": "2020-05-06T12:20:00",
        "created": "2020-05-06T12:20:00"
      },
      "personalities": [
        {
          "tagId": 50,
          "tagName": "Barefoot Luxury",
          "tagType": 1
        },
        {
          "tagId": 200,
          "tagName": "Rustic Elegance",
          "tagType": 1
        }
      ],
      "tags": [
        {
          "tagId": 4,
          "tagName": "Spa",
          "tagType": 2
        },
        {
          "tagId": 33,
          "tagName": "Luxury",
          "tagType": 2
        },
        {
          "tagId": 58,
          "tagName": "Beach",
          "tagType": 2
        },
        {
          "tagId": 69,
          "tagName": "Couples",
          "tagType": 2
        },
        {
          "tagId": 88,
          "tagName": "Tennis",
          "tagType": 2
        },
        {
          "tagId": 90,
          "tagName": "Water Sports",
          "tagType": 2
        },
        {
          "tagId": 115,
          "tagName": "Snorkeling",
          "tagType": 2
        },
        {
          "tagId": 116,
          "tagName": "Scuba Diving",
          "tagType": 2
        },
        {
          "tagId": 121,
          "tagName": "Yoga",
          "tagType": 2
        },
        {
          "tagId": 362,
          "tagName": "Sustainable",
          "tagType": 2
        }
      ],
      "venueCategoryAssigns": [
        {
          "venueCategoryAssignId": 146,
          "venueCategoryId": 20,
          "venueId": 841,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 20,
            "title": "BucketList Hotels",
            "region": "Global",
            "viewOrder": 0,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 338,
          "venueCategoryId": 15,
          "venueId": 841,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 15,
            "title": "Beach Paradise",
            "region": "Global",
            "viewOrder": 6,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 339,
          "venueCategoryId": 19,
          "venueId": 841,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 19,
            "title": "Private Islands",
            "region": "Global",
            "viewOrder": 15,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 526,
          "venueCategoryId": 5,
          "venueId": 841,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 5,
            "title": "Romantic Getaways",
            "region": "Global",
            "viewOrder": 20,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        }
      ]
    },
    {
      "venueId": 828,
      "venueCategoryId": 20,
      "status": 1,
      "title": "Four Seasons Tented Camp Golden Triangle",
      "description": "Go on the adventure of a lifetime in the wondrous natural surroundings of Northern Thailand, bordering Burma and Laos. Tucked away in the historic Golden Triangle region, Four Seasons Tented Camp Golden Triangle is the perfect escape from the rest of the world. On our unique three- or four-night excursions, you will discover the joys of interacting with rescued elephants in their home habitat, explore spectacular mountain trails and bamboo jungles, and unwind in superb luxury tents. Let the 19th-century explorer’s spirit in you come to life – while enjoying our signature hospitality.",
      "brand": "Four Seasons",
      "blockQuote": "Be prepared to fall in love with the majestic elephants that reside at the camp.",
      "emailAddress": "reservations.thailand@fourseasons.com",
      "website": "fourseasons.com/goldentriangle/",
      "bookingURL": "https://reservations.fourseasons.com/?hotelCode=CEI788&checkIn=2021-03-19&checkOut=2021-03-20&adults=2&children=0&iata=96027890",
      "redirectNotes": "Four Seasons Tented Camp Golden Triangle's reservations system",
      "phoneNumber": "+66 53 910 200",
      "addressOne": "",
      "addressTwo": "",
      "city": "Chiang Rai",
      "county": "",
      "region": "",
      "country": "Thailand",
      "rooms": 15,
      "dollar": 3,
      "updated": "2021-03-19T14:02:11.07",
      "created": "2020-05-06T12:20:00",
      "medias": [
        {
          "mediaId": 18266,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/7cda7b11-1d74-423b-b827-e77dd539f1e4/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/7cda7b11-1d74-423b-b827-e77dd539f1e4/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/7cda7b11-1d74-423b-b827-e77dd539f1e4/256x192.jpg",
          "viewOrder": 0
        },
        {
          "mediaId": 18268,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/43313b3e-bafa-4979-96ca-a66f770a9c92/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/43313b3e-bafa-4979-96ca-a66f770a9c92/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/43313b3e-bafa-4979-96ca-a66f770a9c92/256x192.jpg",
          "viewOrder": 1
        },
        {
          "mediaId": 46396,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/f6b95a13-f85b-4841-831a-f5dee428dd88/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/f6b95a13-f85b-4841-831a-f5dee428dd88/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/f6b95a13-f85b-4841-831a-f5dee428dd88/256x192.jpeg",
          "viewOrder": 2
        },
        {
          "mediaId": 46391,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/8fee74bc-b66b-4c9e-99bb-fa49710d1e81/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/8fee74bc-b66b-4c9e-99bb-fa49710d1e81/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/8fee74bc-b66b-4c9e-99bb-fa49710d1e81/256x192.jpeg",
          "viewOrder": 3
        },
        {
          "mediaId": 46394,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/21077290-f763-4030-bdd9-2453c720ed3e/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/21077290-f763-4030-bdd9-2453c720ed3e/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/21077290-f763-4030-bdd9-2453c720ed3e/256x192.jpeg",
          "viewOrder": 5
        },
        {
          "mediaId": 46397,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/365ee520-2309-4f62-97f6-a77a6c87e920/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/365ee520-2309-4f62-97f6-a77a6c87e920/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/365ee520-2309-4f62-97f6-a77a6c87e920/256x192.jpeg",
          "viewOrder": 6
        },
        {
          "mediaId": 46401,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/08ab1fc3-126e-401d-a7d4-9f6f6b9509e1/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/08ab1fc3-126e-401d-a7d4-9f6f6b9509e1/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/08ab1fc3-126e-401d-a7d4-9f6f6b9509e1/256x192.jpeg",
          "viewOrder": 7
        },
        {
          "mediaId": 46398,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/8966669f-f89a-4a13-8775-7f5cda28900d/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/8966669f-f89a-4a13-8775-7f5cda28900d/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/8966669f-f89a-4a13-8775-7f5cda28900d/256x192.jpeg",
          "viewOrder": 8
        },
        {
          "mediaId": 46399,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/ecc1fefa-c219-4d54-89cd-c8d9b3f1f6d4/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/ecc1fefa-c219-4d54-89cd-c8d9b3f1f6d4/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/ecc1fefa-c219-4d54-89cd-c8d9b3f1f6d4/256x192.jpeg",
          "viewOrder": 9
        },
        {
          "mediaId": 46400,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/ad7f90ce-9fa8-4473-a2d9-c96d76c7d4f3/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/ad7f90ce-9fa8-4473-a2d9-c96d76c7d4f3/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/ad7f90ce-9fa8-4473-a2d9-c96d76c7d4f3/256x192.jpeg",
          "viewOrder": 10
        }
      ],
      "geographicArea": {
        "geographicAreaId": 651,
        "latitude": 20.313532,
        "longitude": 100.06936500000006,
        "parentGeographicAreaId": 0,
        "title": "Chiang Saen Post Office, ตำบล เวียง อำเภอ เชียงแสน เชียงราย 57150, Thailand",
        "updated": "2020-05-06T12:20:00",
        "created": "2020-05-06T12:20:00"
      },
      "personalities": [
        {
          "tagId": 56,
          "tagName": "Quiet/Peaceful",
          "tagType": 1
        },
        {
          "tagId": 200,
          "tagName": "Rustic Elegance",
          "tagType": 1
        }
      ],
      "tags": [
        {
          "tagId": 4,
          "tagName": "Spa",
          "tagType": 2
        },
        {
          "tagId": 77,
          "tagName": "Cooking Classes",
          "tagType": 2
        },
        {
          "tagId": 87,
          "tagName": "Outdoor Pool",
          "tagType": 2
        },
        {
          "tagId": 110,
          "tagName": "Hiking",
          "tagType": 2
        },
        {
          "tagId": 133,
          "tagName": "Glamping",
          "tagType": 2
        },
        {
          "tagId": 198,
          "tagName": "Boat Excursion",
          "tagType": 2
        },
        {
          "tagId": 219,
          "tagName": "Wine Cellar",
          "tagType": 2
        },
        {
          "tagId": 287,
          "tagName": "Wildlife",
          "tagType": 2
        },
        {
          "tagId": 317,
          "tagName": "Gourmet",
          "tagType": 2
        },
        {
          "tagId": 319,
          "tagName": "Temples",
          "tagType": 2
        }
      ],
      "venueCategoryAssigns": [
        {
          "venueCategoryAssignId": 142,
          "venueCategoryId": 20,
          "venueId": 828,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 20,
            "title": "BucketList Hotels",
            "region": "Global",
            "viewOrder": 0,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        }
      ]
    },
    {
      "venueId": 781,
      "venueCategoryId": 15,
      "status": 1,
      "title": "Morgan's Rock Hacienda & Ecolodge",
      "description": "Exotic rain forest surroundings, private sandy beach and bay, secluded location and large open spaces makes Morgan's Rock an exceptional place that brings guests peace, harmony and relaxation of the body and mind. Located on a 4,000 acre expanse of Nicaraguan jungle, nearly half of Morgan’s Rock is a private protected reserve. The Lodge’s concept and operation reflects this whimsical feeling.   \n\nThe ecolodge is a 15-bungalow and 3 Ocean View Two Bedroom Villas with Pool hideaway on a mile-long picturesque sandy beach, frequented year-round by nesting sea turtles. \n\nWith endless activities, tours, plenty of safe space to explore, and astounding wildlife, organic farm and a world class cullinary experience, Morgan’s Rock offers the ultimate family vacation. The restaurant features local food from their organic farm.",
      "brand": null,
      "blockQuote": "Horseback riding up to the lookout point and then down to the beach was a highlight.",
      "emailAddress": "reservations@morgansrock.com",
      "website": "morgansrock.com/",
      "bookingURL": "https://www.booking.com/hotel/ni/hacienda-amp-ecolodge-morgan-s-rock.en-gb.html?aid=925669;label=gog235jc-1DCAsoqAFCI2hhY2llbmRhLWFtcC1lY29sb2RnZS1tb3JnYW4tcy1yb2NrSDNYA2iwAYgBAZgBCbgBGMgBDNgBA-gBAYgCAagCBLgCyPr25QXAAgE;dist=0&group_adults=2&keep_landing=1&sb_price_type=total&type=total&",
      "redirectNotes": null,
      "phoneNumber": "+505 7833 7600",
      "addressOne": "",
      "addressTwo": "",
      "city": "San Juan del Sur",
      "county": "",
      "region": "",
      "country": "Nicaragua",
      "rooms": 18,
      "dollar": 2,
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00",
      "medias": [
        {
          "mediaId": 11944,
          "title": "The Ecolodge - Morgans Rock Ecolodge",
          "url": "https://pkslist.azureedge.net/media/images/1/b4d28ed2-f5da-4d60-8f87-dc7d6368e92b/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/b4d28ed2-f5da-4d60-8f87-dc7d6368e92b/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/b4d28ed2-f5da-4d60-8f87-dc7d6368e92b/256x192.jpg",
          "viewOrder": 0
        },
        {
          "mediaId": 29530,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/5e49c9f7-6621-456b-bc31-dc95c6d6db10/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/5e49c9f7-6621-456b-bc31-dc95c6d6db10/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/5e49c9f7-6621-456b-bc31-dc95c6d6db10/256x192.jpg",
          "viewOrder": 1
        },
        {
          "mediaId": 29532,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/cbd4758b-2b37-4509-9c9b-d8f2e80554ed/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/cbd4758b-2b37-4509-9c9b-d8f2e80554ed/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/cbd4758b-2b37-4509-9c9b-d8f2e80554ed/256x192.jpg",
          "viewOrder": 2
        },
        {
          "mediaId": 29531,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/2094da47-5422-4b36-93e2-c31bf2c20eaa/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/2094da47-5422-4b36-93e2-c31bf2c20eaa/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/2094da47-5422-4b36-93e2-c31bf2c20eaa/256x192.jpg",
          "viewOrder": 3
        },
        {
          "mediaId": 29533,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/5dbedc50-42f9-4e35-9e3a-b4ed260368ef/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/5dbedc50-42f9-4e35-9e3a-b4ed260368ef/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/5dbedc50-42f9-4e35-9e3a-b4ed260368ef/256x192.jpg",
          "viewOrder": 4
        },
        {
          "mediaId": 11947,
          "title": "Morgan's Rock Hacienda and Eco Lodge | Audley Travel",
          "url": "https://pkslist.azureedge.net/media/images/1/0a695b1c-35b8-477d-b5ab-22d2125da2b5/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/0a695b1c-35b8-477d-b5ab-22d2125da2b5/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/0a695b1c-35b8-477d-b5ab-22d2125da2b5/256x192.jpg",
          "viewOrder": 5
        },
        {
          "mediaId": 6753,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/d0e3fb0c-a24c-4350-988f-969c8c0d7e5a/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/d0e3fb0c-a24c-4350-988f-969c8c0d7e5a/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/d0e3fb0c-a24c-4350-988f-969c8c0d7e5a/256x192.jpg",
          "viewOrder": 6
        },
        {
          "mediaId": 6759,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/ff3ae736-2b7c-4a13-ba01-fd2d134695f5/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/ff3ae736-2b7c-4a13-ba01-fd2d134695f5/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/ff3ae736-2b7c-4a13-ba01-fd2d134695f5/256x192.jpg",
          "viewOrder": 7
        },
        {
          "mediaId": 29529,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/c9db279c-a310-4aa3-b127-3e12f3fc3fd1/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/c9db279c-a310-4aa3-b127-3e12f3fc3fd1/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/c9db279c-a310-4aa3-b127-3e12f3fc3fd1/256x192.jpg",
          "viewOrder": 8
        }
      ],
      "geographicArea": {
        "geographicAreaId": 604,
        "latitude": 11.306296,
        "longitude": -85.91931299999999,
        "parentGeographicAreaId": 0,
        "title": "San Juan del Sur, Nicaragua",
        "updated": "2020-05-06T12:20:00",
        "created": "2020-05-06T12:20:00"
      },
      "personalities": [
        {
          "tagId": 157,
          "tagName": "Beach Escape",
          "tagType": 1
        },
        {
          "tagId": 200,
          "tagName": "Rustic Elegance",
          "tagType": 1
        }
      ],
      "tags": [
        {
          "tagId": 70,
          "tagName": "Families",
          "tagType": 2
        },
        {
          "tagId": 82,
          "tagName": "Horseback Riding",
          "tagType": 2
        },
        {
          "tagId": 92,
          "tagName": "Infinity Pool",
          "tagType": 2
        },
        {
          "tagId": 102,
          "tagName": "Eco-friendly",
          "tagType": 2
        },
        {
          "tagId": 106,
          "tagName": "Nature",
          "tagType": 2
        },
        {
          "tagId": 121,
          "tagName": "Yoga",
          "tagType": 2
        },
        {
          "tagId": 134,
          "tagName": "Wilderness Walks",
          "tagType": 2
        },
        {
          "tagId": 164,
          "tagName": "Excursions",
          "tagType": 2
        },
        {
          "tagId": 181,
          "tagName": "Private/Suite Pools",
          "tagType": 2
        },
        {
          "tagId": 193,
          "tagName": "Outdoor Dining",
          "tagType": 2
        },
        {
          "tagId": 206,
          "tagName": "Private Beach",
          "tagType": 2
        }
      ],
      "venueCategoryAssigns": [
        {
          "venueCategoryAssignId": 134,
          "venueCategoryId": 15,
          "venueId": 781,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 15,
            "title": "Beach Paradise",
            "region": "Global",
            "viewOrder": 6,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 447,
          "venueCategoryId": 20,
          "venueId": 781,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 20,
            "title": "BucketList Hotels",
            "region": "Global",
            "viewOrder": 0,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 643,
          "venueCategoryId": 33,
          "venueId": 781,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 33,
            "title": "Rustic Elegance",
            "region": "Global",
            "viewOrder": 11,
            "updated": "2020-09-04T09:04:00",
            "created": "2020-09-04T09:04:00",
            "venueCategoryAssign": [
              
            ]
          }
        }
      ]
    },
    {
      "venueId": 742,
      "venueCategoryId": 1,
      "status": 1,
      "title": "Hotel du Cap Eden Roc",
      "description": "Hotel du Cap-Eden-Roc has historically been an inspirational retreat for writers and the creative spirited, indeed she was the elegant hotel in Scott Fitzgerald’s Tender is the night. Hotel du Cap-Eden-Roc presides over Antibes with an aura of magnificent glamour, with a distinguished guest list of royalty, dignitaries and Hollywood stars.\n\nHotel du Cap-Eden-Roc has always been renowned for the refined luxury of its rooms, suites and villas but especially for their glittering settings overlooking the azure sea and the private parkland, with its swaying pines and Riviera palms.",
      "brand": "",
      "blockQuote": "One of the most iconic hotels EVER, a must for your bucket list.",
      "emailAddress": "reservations.hdcer@oetkercollection.com",
      "website": "hotel-du-cap-eden-roc.com",
      "bookingURL": "https://be.synxis.com/?_ga=2.112819136.535158365.1556038969-1860822932.1556038969&adult=2&agencyid=96027890&arrive=2019-04-23&chain=13518&child=0&currency=EUR&depart=2019-04-24&hotel=55927&level=hotel&locale=en-GB&rooms=1&sbe_ri=0",
      "redirectNotes": "Hotel du Cap Eden Roc's reservations system",
      "phoneNumber": "+33 4 93 61 39 01",
      "addressOne": "Boulevard J. F. Kennedy",
      "addressTwo": "",
      "city": "Antibes",
      "county": "",
      "region": "Provence Cote d'Azur",
      "country": "France",
      "rooms": 130,
      "dollar": 3,
      "updated": "2020-05-14T20:06:02.52",
      "created": "2020-05-06T12:20:00",
      "medias": [
        {
          "mediaId": 6252,
          "title": "Hotel du Cap Eden Roc, France - | Amazing Places |",
          "url": "https://pkslist.azureedge.net/media/images/1/c8c620a6-33cd-48c4-a115-e4fb020e6f90/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/c8c620a6-33cd-48c4-a115-e4fb020e6f90/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/c8c620a6-33cd-48c4-a115-e4fb020e6f90/256x192.jpg",
          "viewOrder": 0
        },
        {
          "mediaId": 8577,
          "title": "Hotel du Cap-Eden-Roc | Luxury Hotel in Antibes, France",
          "url": "https://pkslist.azureedge.net/media/images/1/b04d9fa5-9586-459c-8227-23ffdb336078/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/b04d9fa5-9586-459c-8227-23ffdb336078/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/b04d9fa5-9586-459c-8227-23ffdb336078/256x192.jpg",
          "viewOrder": 1
        },
        {
          "mediaId": 6254,
          "title": "Hôtel du Cap-Eden-Roc | Bespoke Yacht Charter",
          "url": "https://pkslist.azureedge.net/media/images/1/c21c0e21-43d5-4f7a-8288-d499341e1b29/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/c21c0e21-43d5-4f7a-8288-d499341e1b29/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/c21c0e21-43d5-4f7a-8288-d499341e1b29/256x192.jpg",
          "viewOrder": 2
        },
        {
          "mediaId": 6260,
          "title": "Hôtel du Cap-Eden-Roc | Hotel, Eden-Roc Champagne Lounge ...",
          "url": "https://pkslist.azureedge.net/media/images/1/7e7b82f7-ed61-4161-ae5a-318b2e71dc10/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/7e7b82f7-ed61-4161-ae5a-318b2e71dc10/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/7e7b82f7-ed61-4161-ae5a-318b2e71dc10/256x192.jpg",
          "viewOrder": 3
        },
        {
          "mediaId": 6262,
          "title": "Gallery - Mediterranean Beach Hotel | Hotel du Cap-Eden-Roc",
          "url": "https://pkslist.azureedge.net/media/images/1/80ff53fb-d314-4aae-ac3e-c3c13b22c1fc/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/80ff53fb-d314-4aae-ac3e-c3c13b22c1fc/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/80ff53fb-d314-4aae-ac3e-c3c13b22c1fc/256x192.jpg",
          "viewOrder": 4
        },
        {
          "mediaId": 6258,
          "title": "Bars & Lounges Gallery - Luxury Hotels of the World | Oetker ...",
          "url": "https://pkslist.azureedge.net/media/images/1/8ef08509-fae9-42b9-a5d2-1c21b7657541/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/8ef08509-fae9-42b9-a5d2-1c21b7657541/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/8ef08509-fae9-42b9-a5d2-1c21b7657541/256x192.jpg",
          "viewOrder": 5
        },
        {
          "mediaId": 6256,
          "title": "Gallery - Mediterranean Beach Hotel | Hotel du Cap-Eden-Roc",
          "url": "https://pkslist.azureedge.net/media/images/1/26d1c262-fa66-4ed3-9182-3d94bdd2e6dc/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/26d1c262-fa66-4ed3-9182-3d94bdd2e6dc/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/26d1c262-fa66-4ed3-9182-3d94bdd2e6dc/256x192.jpg",
          "viewOrder": 9
        },
        {
          "mediaId": 8583,
          "title": "Du Cap Eden Roc (Antibes, France) – Hotelinstyle.com",
          "url": "https://pkslist.azureedge.net/media/images/1/9a5fb8ed-b290-410f-b2b6-d10c173b28f7/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/9a5fb8ed-b290-410f-b2b6-d10c173b28f7/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/9a5fb8ed-b290-410f-b2b6-d10c173b28f7/256x192.jpg",
          "viewOrder": 10
        },
        {
          "mediaId": 8584,
          "title": "The Lavish Hotel du Cap-Eden-Roc on the French Riviera",
          "url": "https://pkslist.azureedge.net/media/images/1/e2e71633-6d8d-4c9e-9563-d72645fb6e7c/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/e2e71633-6d8d-4c9e-9563-d72645fb6e7c/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/e2e71633-6d8d-4c9e-9563-d72645fb6e7c/256x192.jpeg",
          "viewOrder": 11
        },
        {
          "mediaId": 6261,
          "title": "The Amazing Hotel du Cap-Eden-Roc",
          "url": "https://pkslist.azureedge.net/media/images/1/539dbd3e-b5ce-4678-a493-49c536058796/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/539dbd3e-b5ce-4678-a493-49c536058796/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/539dbd3e-b5ce-4678-a493-49c536058796/256x192.jpg",
          "viewOrder": 12
        },
        {
          "mediaId": 6255,
          "title": "Accommodation - Hotel in South of France | Hotel du Cap-Eden-Roc",
          "url": "https://pkslist.azureedge.net/media/images/1/77ecc738-b06e-487f-a9fa-ed97769de3a3/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/77ecc738-b06e-487f-a9fa-ed97769de3a3/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/77ecc738-b06e-487f-a9fa-ed97769de3a3/256x192.jpg",
          "viewOrder": 13
        },
        {
          "mediaId": 6257,
          "title": "Hotel du Cap Eden Roc",
          "url": "https://pkslist.azureedge.net/media/images/1/66ee1397-df3f-4474-a21c-2e73194799ae/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/66ee1397-df3f-4474-a21c-2e73194799ae/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/66ee1397-df3f-4474-a21c-2e73194799ae/256x192.jpg",
          "viewOrder": 14
        }
      ],
      "geographicArea": {
        "geographicAreaId": 579,
        "latitude": 43.5483772,
        "longitude": 7.121576199999936,
        "parentGeographicAreaId": 0,
        "title": "Boulevard J. F. Kennedy, 06601 Antibes, France",
        "updated": "2020-05-06T12:20:00",
        "created": "2020-05-06T12:20:00"
      },
      "personalities": [
        {
          "tagId": 44,
          "tagName": "Grand Dame ",
          "tagType": 1
        },
        {
          "tagId": 48,
          "tagName": "Iconic/Landmark ",
          "tagType": 1
        }
      ],
      "tags": [
        {
          "tagId": 4,
          "tagName": "Spa",
          "tagType": 2
        },
        {
          "tagId": 33,
          "tagName": "Luxury",
          "tagType": 2
        },
        {
          "tagId": 58,
          "tagName": "Beach",
          "tagType": 2
        },
        {
          "tagId": 72,
          "tagName": "Bar(s)",
          "tagType": 2
        },
        {
          "tagId": 73,
          "tagName": "Outdoor Terraces",
          "tagType": 2
        },
        {
          "tagId": 78,
          "tagName": "Fine Dining",
          "tagType": 2
        },
        {
          "tagId": 83,
          "tagName": "Kid's Club",
          "tagType": 2
        },
        {
          "tagId": 87,
          "tagName": "Outdoor Pool",
          "tagType": 2
        },
        {
          "tagId": 88,
          "tagName": "Tennis",
          "tagType": 2
        },
        {
          "tagId": 90,
          "tagName": "Water Sports",
          "tagType": 2
        },
        {
          "tagId": 93,
          "tagName": "Private",
          "tagType": 2
        }
      ],
      "venueCategoryAssigns": [
        {
          "venueCategoryAssignId": 127,
          "venueCategoryId": 1,
          "venueId": 742,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 1,
            "title": "Escape from the City",
            "region": "Global",
            "viewOrder": 14,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 546,
          "venueCategoryId": 20,
          "venueId": 742,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 20,
            "title": "BucketList Hotels",
            "region": "Global",
            "viewOrder": 0,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        }
      ]
    },
    {
      "venueId": 740,
      "venueCategoryId": 19,
      "status": 1,
      "title": "Time + Tide Miavana",
      "description": "Time+Tide Miavana is a luxury lodge on the private island of Nosy Ankao off the northeast coast of Madagascar. With Madagascar's history created from a rich tapestry of diverse human immigrants and unique endemic animal and plant species, it is only fitting that the design is reflected in this island paradise. The natural elements of water, sand and air merge elegantly with luxurious interiors unique to Madagascar and local stone and palm branch bring texture to every corner of the villas. Strung along the island, every villa has direct access to the white sandy beaches, turquoise water and coral reefs. The colours of the sand, wood and water have been brought inside the villas and appear in hand dyed curtains, hand crafted chairs, and light fittings resembling old glass buoys.\n\nUse the villa's electric buggies to traverse the island and transport you to the Village Piazza, Aquatic Center and Beach Lounge.",
      "brand": "",
      "blockQuote": "Be a pioneer and scuba dive reefs with no name.",
      "emailAddress": "miavana@timeandtideafrica",
      "website": "timeandtideafrica.com/miavana",
      "bookingURL": "https://miavana.com/traveller-enquiry/",
      "redirectNotes": "Time + Tide Miavana's reservations system",
      "phoneNumber": "+260 216 246025",
      "addressOne": "",
      "addressTwo": "",
      "city": "Nosy Ankao",
      "county": "",
      "region": "",
      "country": "Madagascar",
      "rooms": 14,
      "dollar": 3,
      "updated": "2022-08-08T14:38:45.977",
      "created": "2020-05-06T12:20:00",
      "medias": [
        {
          "mediaId": 22993,
          "title": "Time + Tide Miavana, Madagascar | Traveller Made",
          "url": "https://pkslist.azureedge.net/media/images/1/8fd1216f-9682-4fa4-92e0-9e869c5842ff/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/8fd1216f-9682-4fa4-92e0-9e869c5842ff/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/8fd1216f-9682-4fa4-92e0-9e869c5842ff/256x192.jpg",
          "viewOrder": 0
        },
        {
          "mediaId": 6238,
          "title": "Enquire | Miavana, Madagascar - Africa Travel | Africa Travel",
          "url": "https://pkslist.azureedge.net/media/images/1/f4d2cad2-213c-401b-879f-e71566c1e5f9/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/f4d2cad2-213c-401b-879f-e71566c1e5f9/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/f4d2cad2-213c-401b-879f-e71566c1e5f9/256x192.jpg",
          "viewOrder": 1
        },
        {
          "mediaId": 22998,
          "title": "Miavana | Madagascar | Luxury Beach Lodges & Private Islands",
          "url": "https://pkslist.azureedge.net/media/images/1/193e12dd-ff56-4ccd-bf6d-e9682c7f720e/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/193e12dd-ff56-4ccd-bf6d-e9682c7f720e/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/193e12dd-ff56-4ccd-bf6d-e9682c7f720e/256x192.jpg",
          "viewOrder": 2
        },
        {
          "mediaId": 22989,
          "title": "Miavana, Madagascar - Natural World Safaris",
          "url": "https://pkslist.azureedge.net/media/images/1/10d65084-e0f8-47fc-b3a7-e595f8ca81bc/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/10d65084-e0f8-47fc-b3a7-e595f8ca81bc/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/10d65084-e0f8-47fc-b3a7-e595f8ca81bc/256x192.jpg",
          "viewOrder": 3
        },
        {
          "mediaId": 22992,
          "title": "Miavana, Madagascar - Natural World Safaris",
          "url": "https://pkslist.azureedge.net/media/images/1/41bcd49d-6901-4f07-ab49-b2654d292bf9/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/41bcd49d-6901-4f07-ab49-b2654d292bf9/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/41bcd49d-6901-4f07-ab49-b2654d292bf9/256x192.jpg",
          "viewOrder": 4
        },
        {
          "mediaId": 22991,
          "title": "Miavana, Madagascar - Natural World Safaris",
          "url": "https://pkslist.azureedge.net/media/images/1/90cecf52-7382-4762-bd64-ffae72dde71b/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/90cecf52-7382-4762-bd64-ffae72dde71b/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/90cecf52-7382-4762-bd64-ffae72dde71b/256x192.jpg",
          "viewOrder": 5
        },
        {
          "mediaId": 22990,
          "title": "Miavana | Miavana Madagascar at Nosy Ankao with Africa Odyssey",
          "url": "https://pkslist.azureedge.net/media/images/1/719d966a-9875-449d-9d67-aa79800f6e15/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/719d966a-9875-449d-9d67-aa79800f6e15/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/719d966a-9875-449d-9d67-aa79800f6e15/256x192.jpg",
          "viewOrder": 6
        },
        {
          "mediaId": 22996,
          "title": "Miavana, Madagascar, Africa| An exclusive review of Miavana | CN ...",
          "url": "https://pkslist.azureedge.net/media/images/1/eccf7772-5531-4765-add3-b1187a1af655/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/eccf7772-5531-4765-add3-b1187a1af655/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/eccf7772-5531-4765-add3-b1187a1af655/256x192.jpg",
          "viewOrder": 7
        },
        {
          "mediaId": 6240,
          "title": "Miavana.jpg",
          "url": "https://pkslist.azureedge.net/media/images/1/e07a05fd-7a07-43ce-893b-6262eabb4dd3/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/e07a05fd-7a07-43ce-893b-6262eabb4dd3/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/e07a05fd-7a07-43ce-893b-6262eabb4dd3/256x192.jpg",
          "viewOrder": 8
        },
        {
          "mediaId": 6239,
          "title": "Tailor Made Luxury Madagascar – Introducing Miavana – Equatours ...",
          "url": "https://pkslist.azureedge.net/media/images/1/b6344e38-65ca-4d42-8265-1f2f46ecb4ad/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/b6344e38-65ca-4d42-8265-1f2f46ecb4ad/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/b6344e38-65ca-4d42-8265-1f2f46ecb4ad/256x192.jpg",
          "viewOrder": 9
        }
      ],
      "geographicArea": {
        "geographicAreaId": 577,
        "latitude": -12.7916705,
        "longitude": 49.81819189999999,
        "parentGeographicAreaId": 0,
        "title": "Nosy Anko,, Madagascar",
        "updated": "2020-05-06T12:20:00",
        "created": "2020-05-06T12:20:00"
      },
      "personalities": [
        {
          "tagId": 43,
          "tagName": "Design Slickster",
          "tagType": 1
        },
        {
          "tagId": 157,
          "tagName": "Beach Escape",
          "tagType": 1
        }
      ],
      "tags": [
        {
          "tagId": 4,
          "tagName": "Spa",
          "tagType": 2
        },
        {
          "tagId": 33,
          "tagName": "Luxury",
          "tagType": 2
        },
        {
          "tagId": 88,
          "tagName": "Tennis",
          "tagType": 2
        },
        {
          "tagId": 90,
          "tagName": "Water Sports",
          "tagType": 2
        },
        {
          "tagId": 109,
          "tagName": "Fishing",
          "tagType": 2
        },
        {
          "tagId": 116,
          "tagName": "Scuba Diving",
          "tagType": 2
        },
        {
          "tagId": 124,
          "tagName": "Helicopter",
          "tagType": 2
        },
        {
          "tagId": 181,
          "tagName": "Private/Suite Pools",
          "tagType": 2
        },
        {
          "tagId": 206,
          "tagName": "Private Beach",
          "tagType": 2
        },
        {
          "tagId": 287,
          "tagName": "Wildlife",
          "tagType": 2
        }
      ],
      "venueCategoryAssigns": [
        {
          "venueCategoryAssignId": 126,
          "venueCategoryId": 20,
          "venueId": 740,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 20,
            "title": "BucketList Hotels",
            "region": "Global",
            "viewOrder": 0,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 347,
          "venueCategoryId": 15,
          "venueId": 740,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 15,
            "title": "Beach Paradise",
            "region": "Global",
            "viewOrder": 6,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 348,
          "venueCategoryId": 19,
          "venueId": 740,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 19,
            "title": "Private Islands",
            "region": "Global",
            "viewOrder": 15,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 656,
          "venueCategoryId": 34,
          "venueId": 740,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 34,
            "title": "Extremely Remote",
            "region": "Global",
            "viewOrder": 13,
            "updated": "2020-09-04T09:11:00",
            "created": "2020-09-04T09:11:00",
            "venueCategoryAssign": [
              
            ]
          }
        }
      ]
    },
    {
      "venueId": 680,
      "venueCategoryId": 20,
      "status": 1,
      "title": "The Dolder Grand",
      "description": "An exquisite setting in which to relax and enjoy yourself, with a wonderful view out over the city, lake and mountains. The Dolder Grand offers all of this and more – it is situated high above Zurich, not far from the city centre and yet in beautiful natural surroundings.\n\nThe Dolder Grand in Zurich has been the very epitome of hospitality since 1899. A complete renovation in 2008 created a new city resort with exquisite cuisine, a unique art collection and an unrivalled spa.",
      "brand": null,
      "blockQuote": "A storybook castle situated dramatically on a hill with spectacular views across the city and lake to the Alps Mountains.",
      "emailAddress": "info@thedoldergrand.com",
      "website": "thedoldergrand.com/en/",
      "bookingURL": "https://www.myhotelreservation.net/b/hllw1618/HLLW1618/?f=2018-09-03&t=2018-09-04&i=96027890&_ga=2.108660481.2061360871.1535967569-1672936498.1535967569",
      "redirectNotes": null,
      "phoneNumber": "+41 44 456 60 00",
      "addressOne": "65",
      "addressTwo": "Kurhausstrasse",
      "city": "Zurich",
      "county": "",
      "region": "",
      "country": "Switzerland",
      "rooms": 175,
      "dollar": 3,
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00",
      "medias": [
        {
          "mediaId": 6661,
          "title": "The Dolder Grand Hotel in Switzerland, Zurich | Newsnish",
          "url": "https://pkslist.azureedge.net/media/images/1/b72139fc-87c3-4fd8-b8eb-28d6d01f0235/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/b72139fc-87c3-4fd8-b8eb-28d6d01f0235/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/b72139fc-87c3-4fd8-b8eb-28d6d01f0235/256x192.jpg",
          "viewOrder": 0
        },
        {
          "mediaId": 6660,
          "title": "Dolder Grand Hotel | Foster + Partners",
          "url": "https://pkslist.azureedge.net/media/images/1/e2a46a1e-de29-4e55-b193-f36bec2cfaf9/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/e2a46a1e-de29-4e55-b193-f36bec2cfaf9/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/e2a46a1e-de29-4e55-b193-f36bec2cfaf9/256x192.jpg",
          "viewOrder": 2
        },
        {
          "mediaId": 6662,
          "title": "Dolder Grand Hotel Review | Fodor's Travel",
          "url": "https://pkslist.azureedge.net/media/images/1/6643e7f9-59af-439b-b5c8-1a4f406e7d2a/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/6643e7f9-59af-439b-b5c8-1a4f406e7d2a/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/6643e7f9-59af-439b-b5c8-1a4f406e7d2a/256x192.jpg",
          "viewOrder": 3
        },
        {
          "mediaId": 6111,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/f892c13f-9fe3-42b2-854f-b317b1441985/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/f892c13f-9fe3-42b2-854f-b317b1441985/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/f892c13f-9fe3-42b2-854f-b317b1441985/256x192.jpg",
          "viewOrder": 5
        },
        {
          "mediaId": 6112,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/384b4e13-86a7-4cf7-899c-4f16864e3df5/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/384b4e13-86a7-4cf7-899c-4f16864e3df5/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/384b4e13-86a7-4cf7-899c-4f16864e3df5/256x192.jpg",
          "viewOrder": 6
        },
        {
          "mediaId": 6109,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/207936e0-53d4-4961-844b-3877dc149c4e/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/207936e0-53d4-4961-844b-3877dc149c4e/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/207936e0-53d4-4961-844b-3877dc149c4e/256x192.jpg",
          "viewOrder": 7
        },
        {
          "mediaId": 6110,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/8be286ba-6273-4d60-97db-d4cb307ffecd/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/8be286ba-6273-4d60-97db-d4cb307ffecd/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/8be286ba-6273-4d60-97db-d4cb307ffecd/256x192.jpg",
          "viewOrder": 8
        },
        {
          "mediaId": 6108,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/61ab7555-ee45-4ff8-9bde-9755d68e1683/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/61ab7555-ee45-4ff8-9bde-9755d68e1683/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/61ab7555-ee45-4ff8-9bde-9755d68e1683/256x192.jpg",
          "viewOrder": 9
        },
        {
          "mediaId": 6105,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/b967b0a2-2e88-46ae-8e07-5bee0c8100e5/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/b967b0a2-2e88-46ae-8e07-5bee0c8100e5/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/b967b0a2-2e88-46ae-8e07-5bee0c8100e5/256x192.jpg",
          "viewOrder": 10
        }
      ],
      "geographicArea": {
        "geographicAreaId": 517,
        "latitude": 47.372676,
        "longitude": 8.573155000000042,
        "parentGeographicAreaId": 0,
        "title": "Kurhausstrasse 65, 8032 Zürich, Switzerland",
        "updated": "2020-05-06T12:20:00",
        "created": "2020-05-06T12:20:00"
      },
      "personalities": [
        {
          "tagId": 45,
          "tagName": "Historic Sense",
          "tagType": 1
        },
        {
          "tagId": 48,
          "tagName": "Iconic/Landmark ",
          "tagType": 1
        }
      ],
      "tags": [
        {
          "tagId": 4,
          "tagName": "Spa",
          "tagType": 2
        },
        {
          "tagId": 72,
          "tagName": "Bar(s)",
          "tagType": 2
        },
        {
          "tagId": 79,
          "tagName": "Fitness",
          "tagType": 2
        },
        {
          "tagId": 86,
          "tagName": "Indoor Pool",
          "tagType": 2
        },
        {
          "tagId": 100,
          "tagName": "Wedding",
          "tagType": 2
        },
        {
          "tagId": 216,
          "tagName": "Museum",
          "tagType": 2
        },
        {
          "tagId": 222,
          "tagName": "Michelin Star dining",
          "tagType": 2
        },
        {
          "tagId": 283,
          "tagName": "Restaurants",
          "tagType": 2
        },
        {
          "tagId": 299,
          "tagName": "Wellness Programs",
          "tagType": 2
        },
        {
          "tagId": 336,
          "tagName": "Ballroom",
          "tagType": 2
        }
      ],
      "venueCategoryAssigns": [
        {
          "venueCategoryAssignId": 118,
          "venueCategoryId": 20,
          "venueId": 680,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 20,
            "title": "BucketList Hotels",
            "region": "Global",
            "viewOrder": 0,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 605,
          "venueCategoryId": 32,
          "venueId": 680,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 32,
            "title": "Spa & Wellness ",
            "region": "Global",
            "viewOrder": 3,
            "updated": "2020-05-29T09:42:00",
            "created": "2020-05-29T09:42:00",
            "venueCategoryAssign": [
              
            ]
          }
        }
      ]
    },
    {
      "venueId": 552,
      "venueCategoryId": 20,
      "status": 1,
      "title": "Amanyangyun",
      "description": "Just outside downtown Shanghai, an ancient camphor forest sets the scene for Aman's fourth destination in China: Amanyangyun. Meaning ‘nourishing cloud’, yangyun is a reference to expanding the ‘cloud’ of the mind with knowledge – a philosophy borne by the story of the ancient trees and dwellings at Amanyangyun's heart. This introspective retreat belies a remarkable tale of a forest transported, and a history preserved. \n\nSaved from demolition, these Ming- and Qing-dynasty dwellings have been reassembled, brick by ancient brick, to create peaceful, refined spaces. Light-filled with forest views, interiors are finished in wood, stone and bamboo. Embracing a restful, earthy palette, Amanyangyun's 25 Club Suites and 13 Antique Villas feature tranquil courtyards.",
      "brand": "Amanresorts",
      "blockQuote": "Learn about Nanshufang and try a Chinese cultural activity such as ancient calligraphy, Chinese painting or a tea and incense ceremony while you're here.",
      "emailAddress": "reservations@aman.com",
      "website": "aman.com/resorts/amanyangyun",
      "bookingURL": "https://gc.synxis.com/rez.aspx?Chain=16840&Hotel=79166&Lang=1&template=RBERG&shell=RBE2&iata=96027890",
      "redirectNotes": "Amanyangyun's reservations system",
      "phoneNumber": "+65 6715 8855 ",
      "addressOne": "",
      "addressTwo": "",
      "city": "Shanghai",
      "county": "",
      "region": "",
      "country": "China",
      "rooms": 37,
      "dollar": 3,
      "updated": "2020-05-13T09:28:00",
      "created": "2020-05-06T12:20:00",
      "medias": [
        {
          "mediaId": 19925,
          "title": "New Resort Amanyangyun to Open in Autumn 2017",
          "url": "https://pkslist.azureedge.net/media/images/1/0575cf69-decc-4f33-9d4a-97693e7ca929/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/0575cf69-decc-4f33-9d4a-97693e7ca929/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/0575cf69-decc-4f33-9d4a-97693e7ca929/256x192.jpg",
          "viewOrder": 0
        },
        {
          "mediaId": 4264,
          "title": "Aman Resorts to open AmanYangyun in Shanghai in Autumn 2017 - CPP ...",
          "url": "https://pkslist.azureedge.net/media/images/1/892e95c0-7720-4008-a2e3-ed8c8b83da7c/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/892e95c0-7720-4008-a2e3-ed8c8b83da7c/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/892e95c0-7720-4008-a2e3-ed8c8b83da7c/256x192.jpg",
          "viewOrder": 1
        },
        {
          "mediaId": 4270,
          "title": "AmanYangyun Archives - Travel for Senses",
          "url": "https://pkslist.azureedge.net/media/images/1/6b1b45fb-b65c-42ac-8c7f-751e267fdb40/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/6b1b45fb-b65c-42ac-8c7f-751e267fdb40/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/6b1b45fb-b65c-42ac-8c7f-751e267fdb40/256x192.jpg",
          "viewOrder": 2
        },
        {
          "mediaId": 4265,
          "title": "Aman Resorts to open AmanYangyun in Shanghai in Autumn 2017 - CPP ...",
          "url": "https://pkslist.azureedge.net/media/images/1/290a1fa7-0a76-431d-8446-36c2a7f40e4a/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/290a1fa7-0a76-431d-8446-36c2a7f40e4a/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/290a1fa7-0a76-431d-8446-36c2a7f40e4a/256x192.jpg",
          "viewOrder": 3
        },
        {
          "mediaId": 19926,
          "title": "Amanyangyun (Shanghai, China): first impressions - FlyerTalk Forums",
          "url": "https://pkslist.azureedge.net/media/images/1/0e41f4aa-6a82-4ad1-9bfe-848c76b77dc5/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/0e41f4aa-6a82-4ad1-9bfe-848c76b77dc5/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/0e41f4aa-6a82-4ad1-9bfe-848c76b77dc5/256x192.jpg",
          "viewOrder": 4
        },
        {
          "mediaId": 19924,
          "title": "Amanyangyun set to open next week near Shanghai, China",
          "url": "https://pkslist.azureedge.net/media/images/1/01307900-f77b-4321-bda0-d0db03568620/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/01307900-f77b-4321-bda0-d0db03568620/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/01307900-f77b-4321-bda0-d0db03568620/256x192.jpg",
          "viewOrder": 5
        },
        {
          "mediaId": 19923,
          "title": "Amanyangyun set to open next week near Shanghai, China",
          "url": "https://pkslist.azureedge.net/media/images/1/589c945a-420d-4626-b000-f92d0a39b8f6/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/589c945a-420d-4626-b000-f92d0a39b8f6/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/589c945a-420d-4626-b000-f92d0a39b8f6/256x192.jpg",
          "viewOrder": 6
        },
        {
          "mediaId": 19922,
          "title": "Hotel Amanyangyun na Kitajskem vas bo pustil odprtih ust ...",
          "url": "https://pkslist.azureedge.net/media/images/1/bec3035f-29f6-4bd6-92b5-3880b7989b63/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/bec3035f-29f6-4bd6-92b5-3880b7989b63/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/bec3035f-29f6-4bd6-92b5-3880b7989b63/256x192.jpg",
          "viewOrder": 7
        },
        {
          "mediaId": 4262,
          "title": "Amanyangyun - Luxury Resort & Hotel in Shanghai, China - Aman",
          "url": "https://pkslist.azureedge.net/media/images/1/38046826-509c-4354-9a8f-16785e497d8c/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/38046826-509c-4354-9a8f-16785e497d8c/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/38046826-509c-4354-9a8f-16785e497d8c/256x192.jpg",
          "viewOrder": 9
        },
        {
          "mediaId": 4267,
          "title": "Introducing Amanyangyun: Opening Autumn 2017 - Hotelier Indonesia ...",
          "url": "https://pkslist.azureedge.net/media/images/1/dea1d2a2-23d6-49c2-8361-9381ae0e9603/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/dea1d2a2-23d6-49c2-8361-9381ae0e9603/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/dea1d2a2-23d6-49c2-8361-9381ae0e9603/256x192.jpg",
          "viewOrder": 10
        }
      ],
      "geographicArea": {
        "geographicAreaId": 389,
        "latitude": 31.230416,
        "longitude": 121.473701,
        "parentGeographicAreaId": 0,
        "title": "Shanghai, China",
        "updated": "2020-05-06T12:20:00",
        "created": "2020-05-06T12:20:00"
      },
      "personalities": [
        {
          "tagId": 45,
          "tagName": "Historic Sense",
          "tagType": 1
        },
        {
          "tagId": 53,
          "tagName": "Cutting Edge",
          "tagType": 1
        }
      ],
      "tags": [
        {
          "tagId": 4,
          "tagName": "Spa",
          "tagType": 2
        },
        {
          "tagId": 59,
          "tagName": "Cultural",
          "tagType": 2
        },
        {
          "tagId": 60,
          "tagName": "Gastronomic",
          "tagType": 2
        },
        {
          "tagId": 65,
          "tagName": "Shopping",
          "tagType": 2
        },
        {
          "tagId": 79,
          "tagName": "Fitness",
          "tagType": 2
        },
        {
          "tagId": 81,
          "tagName": "Golf",
          "tagType": 2
        },
        {
          "tagId": 86,
          "tagName": "Indoor Pool",
          "tagType": 2
        },
        {
          "tagId": 127,
          "tagName": "Hair Salon",
          "tagType": 2
        },
        {
          "tagId": 181,
          "tagName": "Private/Suite Pools",
          "tagType": 2
        },
        {
          "tagId": 204,
          "tagName": "Courtyard",
          "tagType": 2
        },
        {
          "tagId": 283,
          "tagName": "Restaurants",
          "tagType": 2
        },
        {
          "tagId": 319,
          "tagName": "Temples",
          "tagType": 2
        }
      ],
      "venueCategoryAssigns": [
        {
          "venueCategoryAssignId": 103,
          "venueCategoryId": 20,
          "venueId": 552,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 20,
            "title": "BucketList Hotels",
            "region": "Global",
            "viewOrder": 0,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        }
      ]
    },
    {
      "venueId": 545,
      "venueCategoryId": 20,
      "status": 1,
      "title": "Amangiri",
      "description": "Amangiri is a remote hideaway tucked within the luminous canyons of the American Southwest. Located in a protected valley with sweeping views, the resort offers both adrenaline-fuelled adventure and a peaceful retreat. Framing the surrounding dunes, plateaus and mountain ridges, the Suites and the Home embrace the area’s raw aesthetic.",
      "brand": "Amanresorts",
      "blockQuote": "A stunningly unique resort in the most spectacular setting imaginable.",
      "emailAddress": "amangiri@aman.com",
      "website": "aman.com/resorts/amangiri",
      "bookingURL": "https://gc.synxis.com/rez.aspx?Chain=16840&Hotel=62482&Lang=1&template=RBE3&shell=RBE2&iata=96027890",
      "redirectNotes": "Amangiri's reservations system",
      "phoneNumber": "+1 435 675 3999",
      "addressOne": "1",
      "addressTwo": "Kayenta Road",
      "city": "Canyon Point",
      "county": "",
      "region": "Utah",
      "country": "United States",
      "rooms": 34,
      "dollar": 3,
      "updated": "2020-05-13T09:27:00",
      "created": "2020-05-06T12:20:00",
      "medias": [
        {
          "mediaId": 4414,
          "title": "Amangiri - Luxury Hotel & Resort in Utah, USA - Aman",
          "url": "https://pkslist.azureedge.net/media/images/1/497c2f0f-d983-4897-b7f2-85d9a3cbbaa0/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/497c2f0f-d983-4897-b7f2-85d9a3cbbaa0/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/497c2f0f-d983-4897-b7f2-85d9a3cbbaa0/256x192.jpg",
          "viewOrder": 0
        },
        {
          "mediaId": 4198,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/c0d1244f-d8e6-4626-bacb-1c70a1b80d0d/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/c0d1244f-d8e6-4626-bacb-1c70a1b80d0d/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/c0d1244f-d8e6-4626-bacb-1c70a1b80d0d/256x192.jpg",
          "viewOrder": 1
        },
        {
          "mediaId": 4196,
          "title": "Amangiri Resort – Dramatic Escape In Utah | iDesignArch | Interior ...",
          "url": "https://pkslist.azureedge.net/media/images/1/8295cf74-ee66-4d6d-92ea-323776b28a31/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/8295cf74-ee66-4d6d-92ea-323776b28a31/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/8295cf74-ee66-4d6d-92ea-323776b28a31/256x192.jpg",
          "viewOrder": 2
        },
        {
          "mediaId": 4188,
          "title": "Amangiri Luxury Resort is an enclave of luxury and style in Utah.",
          "url": "https://pkslist.azureedge.net/media/images/1/87160cc9-fa34-4fee-bc28-27ffbade4eac/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/87160cc9-fa34-4fee-bc28-27ffbade4eac/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/87160cc9-fa34-4fee-bc28-27ffbade4eac/256x192.jpg",
          "viewOrder": 3
        },
        {
          "mediaId": 4193,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/2678a631-bd4e-4dac-a39c-f70d6c88b4e0/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/2678a631-bd4e-4dac-a39c-f70d6c88b4e0/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/2678a631-bd4e-4dac-a39c-f70d6c88b4e0/256x192.jpg",
          "viewOrder": 4
        },
        {
          "mediaId": 4185,
          "title": "Suites, Amangiri - Luxury Accommodation in Utah - Aman",
          "url": "https://pkslist.azureedge.net/media/images/1/c2b8fc52-b548-41d2-b5b3-a2040f874d84/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/c2b8fc52-b548-41d2-b5b3-a2040f874d84/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/c2b8fc52-b548-41d2-b5b3-a2040f874d84/256x192.jpg",
          "viewOrder": 5
        },
        {
          "mediaId": 4202,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/a1a71c93-65b5-43d0-a365-f011b2e3a0a2/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/a1a71c93-65b5-43d0-a365-f011b2e3a0a2/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/a1a71c93-65b5-43d0-a365-f011b2e3a0a2/256x192.jpg",
          "viewOrder": 6
        },
        {
          "mediaId": 4201,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/1d8a5c9b-b1f4-4abb-9549-b87d553ee60b/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/1d8a5c9b-b1f4-4abb-9549-b87d553ee60b/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/1d8a5c9b-b1f4-4abb-9549-b87d553ee60b/256x192.jpg",
          "viewOrder": 7
        },
        {
          "mediaId": 4187,
          "title": "Suites, Amangiri - Luxury Accommodation in Utah - Aman",
          "url": "https://pkslist.azureedge.net/media/images/1/8328343f-ed43-4d87-8dae-7eb3f222808e/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/8328343f-ed43-4d87-8dae-7eb3f222808e/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/8328343f-ed43-4d87-8dae-7eb3f222808e/256x192.jpg",
          "viewOrder": 8
        },
        {
          "mediaId": 4195,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/25d435c1-d12c-4983-981e-c4d9df2bebce/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/25d435c1-d12c-4983-981e-c4d9df2bebce/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/25d435c1-d12c-4983-981e-c4d9df2bebce/256x192.jpg",
          "viewOrder": 9
        },
        {
          "mediaId": 4192,
          "title": "Amangiri - UPDATED 2017 Resort Reviews & Price Comparison (Utah ...",
          "url": "https://pkslist.azureedge.net/media/images/1/0bfac041-94b8-4973-bf4e-0a1e01a5ca79/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/0bfac041-94b8-4973-bf4e-0a1e01a5ca79/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/0bfac041-94b8-4973-bf4e-0a1e01a5ca79/256x192.jpg",
          "viewOrder": 10
        }
      ],
      "geographicArea": {
        "geographicAreaId": 382,
        "latitude": 37.0145985,
        "longitude": -111.61129829999999,
        "parentGeographicAreaId": 0,
        "title": "1 Kayenta Rd, Canyon Point, UT 84741, USA",
        "updated": "2020-05-06T12:20:00",
        "created": "2020-05-06T12:20:00"
      },
      "personalities": [
        {
          "tagId": 43,
          "tagName": "Design Slickster",
          "tagType": 1
        },
        {
          "tagId": 53,
          "tagName": "Cutting Edge",
          "tagType": 1
        }
      ],
      "tags": [
        {
          "tagId": 4,
          "tagName": "Spa",
          "tagType": 2
        },
        {
          "tagId": 59,
          "tagName": "Cultural",
          "tagType": 2
        },
        {
          "tagId": 60,
          "tagName": "Gastronomic",
          "tagType": 2
        },
        {
          "tagId": 79,
          "tagName": "Fitness",
          "tagType": 2
        },
        {
          "tagId": 82,
          "tagName": "Horseback Riding",
          "tagType": 2
        },
        {
          "tagId": 87,
          "tagName": "Outdoor Pool",
          "tagType": 2
        },
        {
          "tagId": 110,
          "tagName": "Hiking",
          "tagType": 2
        },
        {
          "tagId": 121,
          "tagName": "Yoga",
          "tagType": 2
        },
        {
          "tagId": 155,
          "tagName": "Art",
          "tagType": 2
        },
        {
          "tagId": 170,
          "tagName": "Kayaking",
          "tagType": 2
        },
        {
          "tagId": 265,
          "tagName": "Hot Air Balloon",
          "tagType": 2
        }
      ],
      "venueCategoryAssigns": [
        {
          "venueCategoryAssignId": 101,
          "venueCategoryId": 20,
          "venueId": 545,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 20,
            "title": "BucketList Hotels",
            "region": "Global",
            "viewOrder": 0,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 345,
          "venueCategoryId": 5,
          "venueId": 545,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 5,
            "title": "Romantic Getaways",
            "region": "Global",
            "viewOrder": 20,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 608,
          "venueCategoryId": 32,
          "venueId": 545,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 32,
            "title": "Spa & Wellness ",
            "region": "Global",
            "viewOrder": 3,
            "updated": "2020-05-29T09:42:00",
            "created": "2020-05-29T09:42:00",
            "venueCategoryAssign": [
              
            ]
          }
        }
      ]
    },
    {
      "venueId": 532,
      "venueCategoryId": 20,
      "status": 1,
      "title": "Aman Tokyo",
      "description": "Aman Tokyo is a monument to the modern-day Japanese capital, a place where urban dynamism is tempered with serenity. Set in Otemachi, the hotel is within easy reach of Tokyo Station and the thriving Nihonbashi district. The large Rooms and Suites are outfitted with traditional Japanese furnishings, from washi paper sliding doors to a furo deep-soak bathtub",
      "brand": "Amanresorts",
      "blockQuote": "A holistic and urban sanctuary perched above the Tokyo skyline.",
      "emailAddress": "amantokyo@aman.com",
      "website": "aman.com/resorts/aman-tokyo",
      "bookingURL": "https://gc.synxis.com/rez.aspx?Chain=16840&Hotel=62649&Lang=2&template=RBERG&shell=RBE2&iata=96027890",
      "redirectNotes": "Aman Tokyo's reservations system",
      "phoneNumber": "+81 03 5224 3333",
      "addressOne": "大手町タワー",
      "addressTwo": "",
      "city": "Tokyo",
      "county": "",
      "region": "",
      "country": "Japan",
      "rooms": 84,
      "dollar": 3,
      "updated": "2020-05-13T09:24:00",
      "created": "2020-05-06T12:20:00",
      "medias": [
        {
          "mediaId": 4043,
          "title": "Aman Tokyo from $886 - UPDATED 2017 Hotel Reviews (Japan ...",
          "url": "https://pkslist.azureedge.net/media/images/1/2aed1a06-186c-4767-b762-f36b2931e12b/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/2aed1a06-186c-4767-b762-f36b2931e12b/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/2aed1a06-186c-4767-b762-f36b2931e12b/256x192.jpg",
          "viewOrder": 0
        },
        {
          "mediaId": 4350,
          "title": "Aman Tokyo | Traveller Made",
          "url": "https://pkslist.azureedge.net/media/images/1/4f3b7893-5ccf-4dfa-b664-ba7742c81d52/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/4f3b7893-5ccf-4dfa-b664-ba7742c81d52/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/4f3b7893-5ccf-4dfa-b664-ba7742c81d52/256x192.jpg",
          "viewOrder": 1
        },
        {
          "mediaId": 4349,
          "title": "Aman Tokyo from $886 - UPDATED 2017 Hotel Reviews (Japan ...",
          "url": "https://pkslist.azureedge.net/media/images/1/33494018-9aa3-479d-b474-49f23cf6a8c5/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/33494018-9aa3-479d-b474-49f23cf6a8c5/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/33494018-9aa3-479d-b474-49f23cf6a8c5/256x192.jpg",
          "viewOrder": 3
        },
        {
          "mediaId": 4048,
          "title": "Design Hotel Find: Aman Tokyo - Melting Butter : Melting Butter",
          "url": "https://pkslist.azureedge.net/media/images/1/a1d1af67-4a54-4d68-b9e1-272a03c2c2e6/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/a1d1af67-4a54-4d68-b9e1-272a03c2c2e6/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/a1d1af67-4a54-4d68-b9e1-272a03c2c2e6/256x192.jpg",
          "viewOrder": 4
        },
        {
          "mediaId": 4051,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/11c2fb26-b22d-47d9-9831-a3b3b70698dd/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/11c2fb26-b22d-47d9-9831-a3b3b70698dd/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/11c2fb26-b22d-47d9-9831-a3b3b70698dd/256x192.jpg",
          "viewOrder": 5
        },
        {
          "mediaId": 4044,
          "title": "Aman Tokyo: first look inside - Telegraph",
          "url": "https://pkslist.azureedge.net/media/images/1/c4b54a86-5f21-4cf7-9014-9a97bf8a5fcc/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/c4b54a86-5f21-4cf7-9014-9a97bf8a5fcc/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/c4b54a86-5f21-4cf7-9014-9a97bf8a5fcc/256x192.jpg",
          "viewOrder": 6
        },
        {
          "mediaId": 4049,
          "title": "Aman Tokyo | Traveller Made",
          "url": "https://pkslist.azureedge.net/media/images/1/fc4749de-724c-4af9-843f-e85eed971267/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/fc4749de-724c-4af9-843f-e85eed971267/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/fc4749de-724c-4af9-843f-e85eed971267/256x192.jpg",
          "viewOrder": 7
        },
        {
          "mediaId": 4047,
          "title": "BEST of the BEST 2015: Aman Tokyo | Luxury Travel",
          "url": "https://pkslist.azureedge.net/media/images/1/7773b46a-9686-4951-8d13-e87a4713199c/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/7773b46a-9686-4951-8d13-e87a4713199c/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/7773b46a-9686-4951-8d13-e87a4713199c/256x192.jpg",
          "viewOrder": 8
        }
      ],
      "geographicArea": {
        "geographicAreaId": 371,
        "latitude": 35.68541039999999,
        "longitude": 139.76527729999998,
        "parentGeographicAreaId": 0,
        "title": "Japan, 〒100-0004 Tōkyō-to, Chiyoda-ku, 千代田区Ōtemachi, 1 Chome−１−５−6 大手町タワー",
        "updated": "2020-05-06T12:20:00",
        "created": "2020-05-06T12:20:00"
      },
      "personalities": [
        {
          "tagId": 43,
          "tagName": "Design Slickster",
          "tagType": 1
        },
        {
          "tagId": 51,
          "tagName": "Style Icon",
          "tagType": 1
        }
      ],
      "tags": [
        {
          "tagId": 4,
          "tagName": "Spa",
          "tagType": 2
        },
        {
          "tagId": 65,
          "tagName": "Shopping",
          "tagType": 2
        },
        {
          "tagId": 68,
          "tagName": "Business",
          "tagType": 2
        },
        {
          "tagId": 78,
          "tagName": "Fine Dining",
          "tagType": 2
        },
        {
          "tagId": 79,
          "tagName": "Fitness",
          "tagType": 2
        },
        {
          "tagId": 86,
          "tagName": "Indoor Pool",
          "tagType": 2
        },
        {
          "tagId": 205,
          "tagName": "Design",
          "tagType": 2
        },
        {
          "tagId": 207,
          "tagName": "Lounge/Bar",
          "tagType": 2
        },
        {
          "tagId": 219,
          "tagName": "Wine Cellar",
          "tagType": 2
        },
        {
          "tagId": 283,
          "tagName": "Restaurants",
          "tagType": 2
        },
        {
          "tagId": 284,
          "tagName": "Architecture",
          "tagType": 2
        },
        {
          "tagId": 293,
          "tagName": "Meeting Space",
          "tagType": 2
        },
        {
          "tagId": 323,
          "tagName": "City Walk",
          "tagType": 2
        }
      ],
      "venueCategoryAssigns": [
        {
          "venueCategoryAssignId": 96,
          "venueCategoryId": 20,
          "venueId": 532,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 20,
            "title": "BucketList Hotels",
            "region": "Global",
            "viewOrder": 0,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        }
      ]
    },
    {
      "venueId": 530,
      "venueCategoryId": 20,
      "status": 1,
      "title": "Aman Venice",
      "description": "As sumptuous and romantic as the city itself, Aman Venice – set in a 16th-century palazzo on the Grand Canal – is home to museum-quality treasures including Tiepolo frescoes, gilded ceilings and centuries-old leather wall coverings. With views of the hotel’s private garden or the Grand Canal, many of the 24 Rooms and Signature Rooms feature protected frescos and reliefs.",
      "brand": "Amanresorts",
      "blockQuote": "It's the only palazzo hotel in Venice with two gardens, one overlooking the Grand Canal.",
      "emailAddress": "amanvenice.res@aman.com",
      "website": "aman.com/resorts/aman-venice",
      "bookingURL": "https://gc.synxis.com/rez.aspx?Chain=16840&Hotel=62478&Lang=1&template=RBE3&shell=RBE2&iata=96027890",
      "redirectNotes": "Aman Venice's reservations system",
      "phoneNumber": "+39 041 2707333",
      "addressOne": "",
      "addressTwo": "Sestiere San Polo",
      "city": "Venice",
      "county": "",
      "region": "",
      "country": "Italy",
      "rooms": 24,
      "dollar": 3,
      "updated": "2020-05-13T09:23:00",
      "created": "2020-05-06T12:20:00",
      "medias": [
        {
          "mediaId": 4017,
          "title": "Aman Venice - Grand Canal, Italy - Aman",
          "url": "https://pkslist.azureedge.net/media/images/1/00e60c28-28ed-4677-8850-a576313d27a3/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/00e60c28-28ed-4677-8850-a576313d27a3/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/00e60c28-28ed-4677-8850-a576313d27a3/256x192.jpg",
          "viewOrder": 0
        },
        {
          "mediaId": 4030,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/a66486a6-a083-470c-80b7-7906c68cc640/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/a66486a6-a083-470c-80b7-7906c68cc640/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/a66486a6-a083-470c-80b7-7906c68cc640/256x192.jpg",
          "viewOrder": 1
        },
        {
          "mediaId": 4352,
          "title": "Aman Canal Grande Venice Resort - UPDATED 2017 Hotel Reviews ...",
          "url": "https://pkslist.azureedge.net/media/images/1/00486092-5476-431c-ac51-af6f0e6aa298/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/00486092-5476-431c-ac51-af6f0e6aa298/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/00486092-5476-431c-ac51-af6f0e6aa298/256x192.jpg",
          "viewOrder": 2
        },
        {
          "mediaId": 4351,
          "title": "Aman Canal Grande Venice Resort - UPDATED 2017 Hotel Reviews ...",
          "url": "https://pkslist.azureedge.net/media/images/1/11b8774a-9899-4464-81ff-9fa31ade519d/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/11b8774a-9899-4464-81ff-9fa31ade519d/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/11b8774a-9899-4464-81ff-9fa31ade519d/256x192.jpg",
          "viewOrder": 3
        },
        {
          "mediaId": 4353,
          "title": "Discover Venetian Dining & Local Cuisine at Aman Venice - Aman",
          "url": "https://pkslist.azureedge.net/media/images/1/42a0aab0-a034-4bae-a014-b8e166c3b5fb/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/42a0aab0-a034-4bae-a014-b8e166c3b5fb/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/42a0aab0-a034-4bae-a014-b8e166c3b5fb/256x192.jpg",
          "viewOrder": 4
        },
        {
          "mediaId": 4031,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/199f096d-a87b-4fe4-9fb3-6fad11578a5c/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/199f096d-a87b-4fe4-9fb3-6fad11578a5c/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/199f096d-a87b-4fe4-9fb3-6fad11578a5c/256x192.jpg",
          "viewOrder": 5
        },
        {
          "mediaId": 4028,
          "title": "Aman Resorts opens Aman Canal Grande Venice « Luxury Hotels ...",
          "url": "https://pkslist.azureedge.net/media/images/1/77095873-40e1-4085-924c-0c3ed5da8c34/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/77095873-40e1-4085-924c-0c3ed5da8c34/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/77095873-40e1-4085-924c-0c3ed5da8c34/256x192.jpg",
          "viewOrder": 6
        },
        {
          "mediaId": 4029,
          "title": "Aman Canale Grande Hotel, Venice, Italy | Yatzer",
          "url": "https://pkslist.azureedge.net/media/images/1/768551b9-a49e-4bbe-890b-bea713789f1f/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/768551b9-a49e-4bbe-890b-bea713789f1f/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/768551b9-a49e-4bbe-890b-bea713789f1f/256x192.jpg",
          "viewOrder": 7
        },
        {
          "mediaId": 4023,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/06c11ce4-37f9-4e2e-bc5b-a215da6042ce/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/06c11ce4-37f9-4e2e-bc5b-a215da6042ce/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/06c11ce4-37f9-4e2e-bc5b-a215da6042ce/256x192.jpg",
          "viewOrder": 8
        }
      ],
      "geographicArea": {
        "geographicAreaId": 369,
        "latitude": 45.436847,
        "longitude": 12.331991000000016,
        "parentGeographicAreaId": 0,
        "title": "Calle Tiepolo Baiamonte, 1364, Palazzo Papadopoli, 30125 Sestiere San Polo, Venezia VE, Italy",
        "updated": "2020-05-06T12:20:00",
        "created": "2020-05-06T12:20:00"
      },
      "personalities": [
        {
          "tagId": 45,
          "tagName": "Historic Sense",
          "tagType": 1
        },
        {
          "tagId": 48,
          "tagName": "Iconic/Landmark ",
          "tagType": 1
        }
      ],
      "tags": [
        {
          "tagId": 4,
          "tagName": "Spa",
          "tagType": 2
        },
        {
          "tagId": 32,
          "tagName": "Garden",
          "tagType": 2
        },
        {
          "tagId": 59,
          "tagName": "Cultural",
          "tagType": 2
        },
        {
          "tagId": 72,
          "tagName": "Bar(s)",
          "tagType": 2
        },
        {
          "tagId": 155,
          "tagName": "Art",
          "tagType": 2
        },
        {
          "tagId": 216,
          "tagName": "Museum",
          "tagType": 2
        },
        {
          "tagId": 280,
          "tagName": "Piano",
          "tagType": 2
        },
        {
          "tagId": 283,
          "tagName": "Restaurants",
          "tagType": 2
        },
        {
          "tagId": 284,
          "tagName": "Architecture",
          "tagType": 2
        },
        {
          "tagId": 289,
          "tagName": "Antiques",
          "tagType": 2
        },
        {
          "tagId": 293,
          "tagName": "Meeting Space",
          "tagType": 2
        },
        {
          "tagId": 323,
          "tagName": "City Walk",
          "tagType": 2
        }
      ],
      "venueCategoryAssigns": [
        {
          "venueCategoryAssignId": 94,
          "venueCategoryId": 20,
          "venueId": 530,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 20,
            "title": "BucketList Hotels",
            "region": "Global",
            "viewOrder": 0,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        }
      ]
    },
    {
      "venueId": 529,
      "venueCategoryId": 20,
      "status": 1,
      "title": "Singita Pamushana Lodge",
      "description": "In 1994, the not-for-profit Malilangwe Trust was established as a result of the largest ever single donor investment in Zimbabwe, in order to purchase, protect, and manage the extraordinary wilderness area next to the Gonarezhou National Park in southeastern Zimbabwe.\n\nSingita Pamushana Lodge is the ecotourism arm of this 130,000 acre reserve, and its role is to help foster the sustainability of the wildlife and broader ecology, while enabling guests to share the magic of the lodge and the Malilangwe Wildlife Reserve. Singita manages the lodge on behalf of the Trust and all proceeds from the lodge benefit the numerous conservation and community outreach and development programmes underway.\n\nThe organic shaped buildings of Singita Pamushana Lodge are reminiscent of the ruins of Great Zimbabwe. Thick walls, meticulous thatching and incredibly detailed stonework lay the foundation for bold interiors that are in marked contrast to the surrounding landscape.\n\nThere are 6 palatial suites and a 5-bedroom villa, all with private plunge pools and panoramic views.",
      "brand": "Singita",
      "blockQuote": "If you've never experienced a guided safari walk, here's your chance to do it.",
      "emailAddress": "enquiries@singita.com",
      "website": "singita.com/lodge/singita-pamushana-lodge/",
      "bookingURL": "https://singita.com/lodge/singita-pamushana-lodge/",
      "redirectNotes": "",
      "phoneNumber": "+27 21 683 3424",
      "addressOne": "",
      "addressTwo": "",
      "city": "Malilangwe Reserve",
      "county": "",
      "region": "",
      "country": "Zimbabwe",
      "rooms": 9,
      "dollar": 3,
      "updated": "2020-05-17T09:02:47.687",
      "created": "2020-05-06T12:20:00",
      "medias": [
        {
          "mediaId": 23031,
          "title": "Pamushana Lodge - Pamushane Zimbabwe - Singita",
          "url": "https://pkslist.azureedge.net/media/images/1/2e1b18ae-ff2e-4600-914e-425c26e34042/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/2e1b18ae-ff2e-4600-914e-425c26e34042/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/2e1b18ae-ff2e-4600-914e-425c26e34042/256x192.jpg",
          "viewOrder": 0
        },
        {
          "mediaId": 33927,
          "title": "Pamushana Lodge - Pamushane Zimbabwe - Singita",
          "url": "https://pkslist.azureedge.net/media/images/1/00cf3282-dd65-44f0-b474-462eb460f3de/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/00cf3282-dd65-44f0-b474-462eb460f3de/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/00cf3282-dd65-44f0-b474-462eb460f3de/256x192.jpg",
          "viewOrder": 1
        },
        {
          "mediaId": 33937,
          "title": "Singita Pamushana Lodge | Singita Safari in Zimbabwe | Hideaway Report",
          "url": "https://pkslist.azureedge.net/media/images/1/1290f9d5-5391-4311-a8d4-9b19527ec451/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/1290f9d5-5391-4311-a8d4-9b19527ec451/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/1290f9d5-5391-4311-a8d4-9b19527ec451/256x192.jpg",
          "viewOrder": 2
        },
        {
          "mediaId": 33936,
          "title": "The New-Look Singita Pamushana Lodge is Revealed | Lodges and ...",
          "url": "https://pkslist.azureedge.net/media/images/1/1eae1a8e-3053-41dd-a568-403c2dd7c719/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/1eae1a8e-3053-41dd-a568-403c2dd7c719/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/1eae1a8e-3053-41dd-a568-403c2dd7c719/256x192.jpg",
          "viewOrder": 3
        },
        {
          "mediaId": 33929,
          "title": "The New-Look Singita Pamushana Lodge is Revealed | Lodges and ...",
          "url": "https://pkslist.azureedge.net/media/images/1/4d4d08d1-bc31-457e-9458-98e7f43b1479/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/4d4d08d1-bc31-457e-9458-98e7f43b1479/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/4d4d08d1-bc31-457e-9458-98e7f43b1479/256x192.jpg",
          "viewOrder": 4
        },
        {
          "mediaId": 33930,
          "title": "Singita Pamushana Lodge | Singita Safari in Zimbabwe | Hideaway Report",
          "url": "https://pkslist.azureedge.net/media/images/1/630f8f71-ff4e-47d5-bd27-e88f24c8605b/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/630f8f71-ff4e-47d5-bd27-e88f24c8605b/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/630f8f71-ff4e-47d5-bd27-e88f24c8605b/256x192.jpg",
          "viewOrder": 5
        },
        {
          "mediaId": 33935,
          "title": "Singita Pamushana Lodge | Singita Safari in Zimbabwe | Hideaway Report",
          "url": "https://pkslist.azureedge.net/media/images/1/3d381e1d-89eb-4b38-9c20-f441eb3ee4ac/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/3d381e1d-89eb-4b38-9c20-f441eb3ee4ac/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/3d381e1d-89eb-4b38-9c20-f441eb3ee4ac/256x192.jpg",
          "viewOrder": 6
        },
        {
          "mediaId": 33933,
          "title": "Singita Pamushana Lodge | Singita Safari in Zimbabwe | Hideaway Report",
          "url": "https://pkslist.azureedge.net/media/images/1/6f066f36-f71e-40aa-9f8c-5611d9cc0015/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/6f066f36-f71e-40aa-9f8c-5611d9cc0015/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/6f066f36-f71e-40aa-9f8c-5611d9cc0015/256x192.jpg",
          "viewOrder": 8
        },
        {
          "mediaId": 23030,
          "title": "Pamushana | Malilangwe Wildlife Reserve Zimbawe | Singita",
          "url": "https://pkslist.azureedge.net/media/images/1/d69a8b29-f8ff-4b24-9aac-db7022a3c29f/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/d69a8b29-f8ff-4b24-9aac-db7022a3c29f/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/d69a8b29-f8ff-4b24-9aac-db7022a3c29f/256x192.jpg",
          "viewOrder": 9
        }
      ],
      "geographicArea": {
        "geographicAreaId": 368,
        "latitude": -20.605872,
        "longitude": 31.566956900000037,
        "parentGeographicAreaId": 0,
        "title": "Unnamed Road, Zimbabwe",
        "updated": "2020-05-06T12:20:00",
        "created": "2020-05-06T12:20:00"
      },
      "personalities": [
        {
          "tagId": 43,
          "tagName": "Design Slickster",
          "tagType": 1
        },
        {
          "tagId": 200,
          "tagName": "Rustic Elegance",
          "tagType": 1
        }
      ],
      "tags": [
        {
          "tagId": 4,
          "tagName": "Spa",
          "tagType": 2
        },
        {
          "tagId": 60,
          "tagName": "Gastronomic",
          "tagType": 2
        },
        {
          "tagId": 63,
          "tagName": "Safari",
          "tagType": 2
        },
        {
          "tagId": 79,
          "tagName": "Fitness",
          "tagType": 2
        },
        {
          "tagId": 92,
          "tagName": "Infinity Pool",
          "tagType": 2
        },
        {
          "tagId": 109,
          "tagName": "Fishing",
          "tagType": 2
        },
        {
          "tagId": 135,
          "tagName": "Bird Watching",
          "tagType": 2
        },
        {
          "tagId": 164,
          "tagName": "Excursions",
          "tagType": 2
        },
        {
          "tagId": 207,
          "tagName": "Lounge/Bar",
          "tagType": 2
        },
        {
          "tagId": 263,
          "tagName": "Star Gazing",
          "tagType": 2
        },
        {
          "tagId": 269,
          "tagName": "Photography",
          "tagType": 2
        },
        {
          "tagId": 278,
          "tagName": "Conservation",
          "tagType": 2
        },
        {
          "tagId": 324,
          "tagName": "Local Village",
          "tagType": 2
        }
      ],
      "venueCategoryAssigns": [
        {
          "venueCategoryAssignId": 93,
          "venueCategoryId": 20,
          "venueId": 529,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 20,
            "title": "BucketList Hotels",
            "region": "Global",
            "viewOrder": 0,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 343,
          "venueCategoryId": 13,
          "venueId": 529,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 13,
            "title": "Wildlife Encounters",
            "region": "Global",
            "viewOrder": 4,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        }
      ]
    },
    {
      "venueId": 487,
      "venueCategoryId": 20,
      "status": 1,
      "title": "Nihi Sumba Island",
      "description": "This is not an escape from everyday life. It is the return to a life well lived. Where rugged luxury meets unregulated freedom. A place to connect with something larger than oneself. A haven for the adventurer, for the wanderer. The endlessly passionate and curious. Arrive with an open heart and leave changed forever. This is vacation with a purpose. A destination with meaning.\n\nNihi Sumba Island is an award-winning luxury resort on remote Sumba Island, Indonesia. A wide range of land and ocean-based experiences ensures all tastes are catered to. Attentive service and gourmet meals add to the refined and intimate ambience. A romantic getaway idyll with pristine private beach stretching in excess of 2.5km of length ideal for honeymooners and the unique tribal culture on the island offers guests a glimpse of a bygone era.",
      "brand": null,
      "blockQuote": "They are known for their extraordinary NihiOka spa safari which is a must-do while you're there.",
      "emailAddress": "info@nihi.com",
      "website": "nihi.com/",
      "bookingURL": "http://nihi.com/booking/",
      "redirectNotes": null,
      "phoneNumber": "+62 361 757149",
      "addressOne": "",
      "addressTwo": "",
      "city": "Sumba",
      "county": "",
      "region": "",
      "country": "Indonesia",
      "rooms": 33,
      "dollar": 3,
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00",
      "medias": [
        {
          "mediaId": 18968,
          "title": "Best hotel in the world is Nihi Sumba — built by fashion mogul ...",
          "url": "https://pkslist.azureedge.net/media/images/1/d66e5db3-94d7-4f0c-984d-43310621192f/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/d66e5db3-94d7-4f0c-984d-43310621192f/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/d66e5db3-94d7-4f0c-984d-43310621192f/256x192.jpg",
          "viewOrder": 0
        },
        {
          "mediaId": 18970,
          "title": "Nihiwatu Resort - Sumba Island - YouTube",
          "url": "https://pkslist.azureedge.net/media/images/1/abfa3d84-cf77-41f9-ac3e-ad81a093b6b2/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/abfa3d84-cf77-41f9-ac3e-ad81a093b6b2/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/abfa3d84-cf77-41f9-ac3e-ad81a093b6b2/256x192.jpg",
          "viewOrder": 1
        },
        {
          "mediaId": 18969,
          "title": "Nihi Sumba Island . Indonesia . | J.MAK Hospitality",
          "url": "https://pkslist.azureedge.net/media/images/1/9b927cef-cd93-4fd0-b112-65e03fbeed6e/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/9b927cef-cd93-4fd0-b112-65e03fbeed6e/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/9b927cef-cd93-4fd0-b112-65e03fbeed6e/256x192.jpg",
          "viewOrder": 2
        },
        {
          "mediaId": 18974,
          "title": "Best hotel in the world is Nihi Sumba — built by fashion mogul ...",
          "url": "https://pkslist.azureedge.net/media/images/1/7e7a7fad-0bb5-4dfd-b076-8886be4bb120/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/7e7a7fad-0bb5-4dfd-b076-8886be4bb120/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/7e7a7fad-0bb5-4dfd-b076-8886be4bb120/256x192.jpg",
          "viewOrder": 3
        },
        {
          "mediaId": 18973,
          "title": "Best hotel in the world is Nihi Sumba — built by fashion mogul ...",
          "url": "https://pkslist.azureedge.net/media/images/1/195176ee-d815-4f5f-b033-6e4ef709dabb/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/195176ee-d815-4f5f-b033-6e4ef709dabb/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/195176ee-d815-4f5f-b033-6e4ef709dabb/256x192.jpg",
          "viewOrder": 4
        },
        {
          "mediaId": 18972,
          "title": "Nihi Sumba Island | LuxuryHunt.com | Ultimate Collection",
          "url": "https://pkslist.azureedge.net/media/images/1/262675b4-8c31-460e-9577-8fa2975ac60c/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/262675b4-8c31-460e-9577-8fa2975ac60c/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/262675b4-8c31-460e-9577-8fa2975ac60c/256x192.jpg",
          "viewOrder": 5
        },
        {
          "mediaId": 3440,
          "title": "Olivia Palermo | Travel: Nihiwatu Beach - Sumba Island, Indonesia ...",
          "url": "https://pkslist.azureedge.net/media/images/1/483292e6-b16c-4f83-ae5c-09e21ddb31ee/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/483292e6-b16c-4f83-ae5c-09e21ddb31ee/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/483292e6-b16c-4f83-ae5c-09e21ddb31ee/256x192.jpg",
          "viewOrder": 6
        },
        {
          "mediaId": 3441,
          "title": "Nihiwatu Resort, Sumba Island, Indonesia: Sand and surf perfection ...",
          "url": "https://pkslist.azureedge.net/media/images/1/ea093a8c-7e2e-4930-8bd1-108c04a6bb2a/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/ea093a8c-7e2e-4930-8bd1-108c04a6bb2a/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/ea093a8c-7e2e-4930-8bd1-108c04a6bb2a/256x192.jpg",
          "viewOrder": 7
        },
        {
          "mediaId": 18976,
          "title": "Nihi Sumba Island Bali | Worldwide Vacation Spots | Pinterest ...",
          "url": "https://pkslist.azureedge.net/media/images/1/0f0a2b27-e105-46f6-9cd4-d27a3a960114/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/0f0a2b27-e105-46f6-9cd4-d27a3a960114/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/0f0a2b27-e105-46f6-9cd4-d27a3a960114/256x192.jpg",
          "viewOrder": 8
        },
        {
          "mediaId": 3436,
          "title": "Stay in a Treehouse with a Private Infinity Pool, at Nihiwatu ...",
          "url": "https://pkslist.azureedge.net/media/images/1/43dfe8b4-98da-4588-9d31-5d9ec00a9b7e/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/43dfe8b4-98da-4588-9d31-5d9ec00a9b7e/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/43dfe8b4-98da-4588-9d31-5d9ec00a9b7e/256x192.jpg",
          "viewOrder": 9
        },
        {
          "mediaId": 3435,
          "title": "Nihiwatu Sumba Island - The Bali Bible",
          "url": "https://pkslist.azureedge.net/media/images/1/26cd1670-8364-44d2-8a91-aec276a2d9f6/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/26cd1670-8364-44d2-8a91-aec276a2d9f6/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/26cd1670-8364-44d2-8a91-aec276a2d9f6/256x192.jpg",
          "viewOrder": 10
        }
      ],
      "geographicArea": {
        "geographicAreaId": 331,
        "latitude": -9.7776614,
        "longitude": 119.37614959999996,
        "parentGeographicAreaId": 0,
        "title": "Nusa Tenggara Tim, Hoba Wawi, Wanokaka, Hoba Wawi, Wanokaka, Kabupaten Sumba Barat, Nusa Tenggara Tim., Indonesia",
        "updated": "2020-05-06T12:20:00",
        "created": "2020-05-06T12:20:00"
      },
      "personalities": [
        {
          "tagId": 50,
          "tagName": "Barefoot Luxury",
          "tagType": 1
        },
        {
          "tagId": 200,
          "tagName": "Rustic Elegance",
          "tagType": 1
        }
      ],
      "tags": [
        {
          "tagId": 4,
          "tagName": "Spa",
          "tagType": 2
        },
        {
          "tagId": 59,
          "tagName": "Cultural",
          "tagType": 2
        },
        {
          "tagId": 82,
          "tagName": "Horseback Riding",
          "tagType": 2
        },
        {
          "tagId": 90,
          "tagName": "Water Sports",
          "tagType": 2
        },
        {
          "tagId": 92,
          "tagName": "Infinity Pool",
          "tagType": 2
        },
        {
          "tagId": 110,
          "tagName": "Hiking",
          "tagType": 2
        },
        {
          "tagId": 122,
          "tagName": "Surfing",
          "tagType": 2
        },
        {
          "tagId": 207,
          "tagName": "Lounge/Bar",
          "tagType": 2
        },
        {
          "tagId": 266,
          "tagName": "Nature Walk",
          "tagType": 2
        },
        {
          "tagId": 283,
          "tagName": "Restaurants",
          "tagType": 2
        },
        {
          "tagId": 324,
          "tagName": "Local Village",
          "tagType": 2
        }
      ],
      "venueCategoryAssigns": [
        {
          "venueCategoryAssignId": 84,
          "venueCategoryId": 20,
          "venueId": 487,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 20,
            "title": "BucketList Hotels",
            "region": "Global",
            "viewOrder": 0,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 342,
          "venueCategoryId": 5,
          "venueId": 487,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 5,
            "title": "Romantic Getaways",
            "region": "Global",
            "viewOrder": 20,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 622,
          "venueCategoryId": 32,
          "venueId": 487,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 32,
            "title": "Spa & Wellness ",
            "region": "Global",
            "viewOrder": 3,
            "updated": "2020-05-29T09:42:00",
            "created": "2020-05-29T09:42:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 645,
          "venueCategoryId": 34,
          "venueId": 487,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 34,
            "title": "Extremely Remote",
            "region": "Global",
            "viewOrder": 13,
            "updated": "2020-09-04T09:11:00",
            "created": "2020-09-04T09:11:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 677,
          "venueCategoryId": 33,
          "venueId": 487,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 33,
            "title": "Rustic Elegance",
            "region": "Global",
            "viewOrder": 11,
            "updated": "2020-09-04T09:04:00",
            "created": "2020-09-04T09:04:00",
            "venueCategoryAssign": [
              
            ]
          }
        }
      ]
    },
    {
      "venueId": 460,
      "venueCategoryId": 20,
      "status": 1,
      "title": "Explora Torres del Paine",
      "description": "Explora in Torres del Paine was designed as the perfect haven for exploring the region's wondrous geography and wildlife. Besides the favored location inside Torres del Paine National Park, this lodge's architecture was planned in a way that interferes the least possible with its natural surroundings. It is an extension of the territory.\n\nOur over 50 different guided hikes and horseback rides offer our travelers breathtaking views of glaciers, lakes and mountains in Torres del Paine National Park.",
      "brand": "",
      "blockQuote": "The only luxury hotel located within Torres de Paine National Park with by far, the best views of the iconic mountains.",
      "emailAddress": "reserve@explora.com",
      "website": "explora.com/torres-del-paine/",
      "bookingURL": "https://reservations.explora.com/ibe/paso1?language=EN",
      "redirectNotes": "Explora Torres del Paine's reservations system",
      "phoneNumber": "+56 2 23952800",
      "addressOne": "",
      "addressTwo": "",
      "city": "Torres del Paine",
      "county": "",
      "region": "Patagonia",
      "country": "Chile",
      "rooms": 50,
      "dollar": 3,
      "updated": "2022-01-10T15:08:57.667",
      "created": "2020-05-06T12:20:00",
      "medias": [
        {
          "mediaId": 18765,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/38b1c64e-6165-42c2-ae8f-d97c3aa1d214/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/38b1c64e-6165-42c2-ae8f-d97c3aa1d214/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/38b1c64e-6165-42c2-ae8f-d97c3aa1d214/256x192.jpg",
          "viewOrder": 0
        },
        {
          "mediaId": 51467,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/5126c83d-08e7-4537-aa1f-036ca200d010/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/5126c83d-08e7-4537-aa1f-036ca200d010/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/5126c83d-08e7-4537-aa1f-036ca200d010/256x192.jpeg",
          "viewOrder": 1
        },
        {
          "mediaId": 51465,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/a0b45ef8-b388-44b5-ae17-1dfeae93a70b/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/a0b45ef8-b388-44b5-ae17-1dfeae93a70b/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/a0b45ef8-b388-44b5-ae17-1dfeae93a70b/256x192.jpeg",
          "viewOrder": 2
        },
        {
          "mediaId": 51466,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/c38e72dc-5445-48ac-b66b-d6458ed5082a/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/c38e72dc-5445-48ac-b66b-d6458ed5082a/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/c38e72dc-5445-48ac-b66b-d6458ed5082a/256x192.jpeg",
          "viewOrder": 3
        },
        {
          "mediaId": 18767,
          "title": "Lodge Explora Patagonia, Torres del Paine, Chile - Booking.com",
          "url": "https://pkslist.azureedge.net/media/images/1/7c820d90-1c0f-4bd7-af41-64bd5dc44dab/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/7c820d90-1c0f-4bd7-af41-64bd5dc44dab/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/7c820d90-1c0f-4bd7-af41-64bd5dc44dab/256x192.jpg",
          "viewOrder": 4
        },
        {
          "mediaId": 3143,
          "title": "explora Patagonia - UPDATED 2017 Resort (All-Inclusive) Reviews ...",
          "url": "https://pkslist.azureedge.net/media/images/1/c8c89b2e-3a3d-497b-8c05-43035041d8b8/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/c8c89b2e-3a3d-497b-8c05-43035041d8b8/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/c8c89b2e-3a3d-497b-8c05-43035041d8b8/256x192.jpg",
          "viewOrder": 5
        },
        {
          "mediaId": 51463,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/d0674ec0-64b2-4fdf-861c-cdad3cd1b492/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/d0674ec0-64b2-4fdf-861c-cdad3cd1b492/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/d0674ec0-64b2-4fdf-861c-cdad3cd1b492/256x192.jpeg",
          "viewOrder": 6
        },
        {
          "mediaId": 18768,
          "title": "Explora Patagonia Hotel – Your New Bucket List Addition ...",
          "url": "https://pkslist.azureedge.net/media/images/1/7125ed25-da73-40ef-ae5f-0a7095c6a17c/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/7125ed25-da73-40ef-ae5f-0a7095c6a17c/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/7125ed25-da73-40ef-ae5f-0a7095c6a17c/256x192.jpg",
          "viewOrder": 7
        },
        {
          "mediaId": 51464,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/a0afa035-3dfa-4403-9058-35647ad4de35/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/a0afa035-3dfa-4403-9058-35647ad4de35/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/a0afa035-3dfa-4403-9058-35647ad4de35/256x192.jpeg",
          "viewOrder": 8
        },
        {
          "mediaId": 18769,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/abc41cc0-629e-4770-8931-0eae41aefa89/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/abc41cc0-629e-4770-8931-0eae41aefa89/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/abc41cc0-629e-4770-8931-0eae41aefa89/256x192.jpg",
          "viewOrder": 9
        }
      ],
      "geographicArea": {
        "geographicAreaId": 1462,
        "latitude": -51.11634,
        "longitude": -72.99182009999998,
        "parentGeographicAreaId": 0,
        "title": "Sector Salto Chico SN, Torres Del Paine, Torres de Paine, Región de Magallanes y de la Antártica Chilena, Chile",
        "updated": "2020-05-06T12:20:00",
        "created": "2020-05-06T12:20:00"
      },
      "personalities": [
        {
          "tagId": 43,
          "tagName": "Design Slickster",
          "tagType": 1
        },
        {
          "tagId": 56,
          "tagName": "Quiet/Peaceful",
          "tagType": 1
        }
      ],
      "tags": [
        {
          "tagId": 61,
          "tagName": "Mountain",
          "tagType": 2
        },
        {
          "tagId": 82,
          "tagName": "Horseback Riding",
          "tagType": 2
        },
        {
          "tagId": 110,
          "tagName": "Hiking",
          "tagType": 2
        },
        {
          "tagId": 164,
          "tagName": "Excursions",
          "tagType": 2
        },
        {
          "tagId": 235,
          "tagName": "Hot Tub",
          "tagType": 2
        },
        {
          "tagId": 243,
          "tagName": "Outdoor Activities",
          "tagType": 2
        },
        {
          "tagId": 252,
          "tagName": "National Park",
          "tagType": 2
        },
        {
          "tagId": 269,
          "tagName": "Photography",
          "tagType": 2
        },
        {
          "tagId": 317,
          "tagName": "Gourmet",
          "tagType": 2
        },
        {
          "tagId": 334,
          "tagName": "Heated Pool",
          "tagType": 2
        }
      ],
      "venueCategoryAssigns": [
        {
          "venueCategoryAssignId": 77,
          "venueCategoryId": 20,
          "venueId": 460,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 20,
            "title": "BucketList Hotels",
            "region": "Global",
            "viewOrder": 0,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        }
      ]
    },
    {
      "venueId": 444,
      "venueCategoryId": 20,
      "status": 1,
      "title": "The Farm at Cape Kidnappers",
      "description": "Set atop 6,000 rolling acres of stunning pasture-land in Hawke’s Bay, New Zealand. The Farm affords spectacular 180-degree views of the Pacific Ocean. The cluster of luxury lodge buildings suggest a working farm with the buildings combining rustic and textured materials with comfortable modern furniture.\n\nOffering 22 guest suites, and a four bedroom Owner's Cottage just a 60 minute flight from Auckland. Every suite offers its own private balcony and affords spectacular views of the property, golf course and Pacific Ocean. \n\nThe main lodge features lounge and dining spaces, outdoor rooms, roaring open fire places, a wine cellar complete with tasting room plus a fully-equipped gymnasium.",
      "brand": null,
      "blockQuote": "Pack a picnic and enjoy the day at one of the properties' wonderful picnic sites.",
      "emailAddress": "info@capekidnappers.com",
      "website": "capekidnappers.com/",
      "bookingURL": "https://gc.synxis.com/rez.aspx?Hotel=22966&Chain=8565&locale=en-US&adult=2&template=Responsive_CAPE&shell=RBE_CAPE&Arrive=2018-09-03&Nights=2&iata=96027890",
      "redirectNotes": "The Farm at Cape Kidnappers' reservations system",
      "phoneNumber": "+64 6 875 1900 ",
      "addressOne": "446",
      "addressTwo": "Clifton Road",
      "city": "Te Awanga",
      "county": "",
      "region": "Hawke's Bay",
      "country": "New Zealand",
      "rooms": 23,
      "dollar": 3,
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00",
      "medias": [
        {
          "mediaId": 2969,
          "title": "The Farm at Cape Kidnappers | Hawkes Bay | New Zealand | Exclusive ...",
          "url": "https://pkslist.azureedge.net/media/images/1/b8ae4092-93d2-4137-b01e-adcf0e6a7b6a/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/b8ae4092-93d2-4137-b01e-adcf0e6a7b6a/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/b8ae4092-93d2-4137-b01e-adcf0e6a7b6a/256x192.jpeg",
          "viewOrder": 0
        },
        {
          "mediaId": 2974,
          "title": "The Farm at Cape Kidnappers | Luxury Hotel in Hawke's Bay North Island",
          "url": "https://pkslist.azureedge.net/media/images/1/a63a9071-a693-4443-9d2d-20a5108647fe/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/a63a9071-a693-4443-9d2d-20a5108647fe/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/a63a9071-a693-4443-9d2d-20a5108647fe/256x192.jpg",
          "viewOrder": 1
        },
        {
          "mediaId": 4987,
          "title": "New Zealand's best lodge hotels - reviews by Condé Nast Traveller ...",
          "url": "https://pkslist.azureedge.net/media/images/1/a3dab6a4-7c36-4f42-8253-f2ccfe2911fb/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/a3dab6a4-7c36-4f42-8253-f2ccfe2911fb/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/a3dab6a4-7c36-4f42-8253-f2ccfe2911fb/256x192.jpg",
          "viewOrder": 2
        },
        {
          "mediaId": 2966,
          "title": "The Farm At Cape Kidnappers - Te Awanga, New Zealand | Classic Travel",
          "url": "https://pkslist.azureedge.net/media/images/1/efe80169-edff-405c-90ec-78c0f54e3e32/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/efe80169-edff-405c-90ec-78c0f54e3e32/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/efe80169-edff-405c-90ec-78c0f54e3e32/256x192.jpg",
          "viewOrder": 3
        },
        {
          "mediaId": 2972,
          "title": "Owners Cottage Gallery, Luxury Lodge Accommodation, Hawke's Bay ...",
          "url": "https://pkslist.azureedge.net/media/images/1/04d084ea-4f4a-4234-a6ae-e7934c0785a1/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/04d084ea-4f4a-4234-a6ae-e7934c0785a1/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/04d084ea-4f4a-4234-a6ae-e7934c0785a1/256x192.jpeg",
          "viewOrder": 4
        },
        {
          "mediaId": 2973,
          "title": "Suites Gallery, Luxury Lodge Accommodation, Hawke's Bay, New Zealand",
          "url": "https://pkslist.azureedge.net/media/images/1/d23bc0d1-5864-460b-9d35-85943fa9c257/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/d23bc0d1-5864-460b-9d35-85943fa9c257/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/d23bc0d1-5864-460b-9d35-85943fa9c257/256x192.jpeg",
          "viewOrder": 5
        },
        {
          "mediaId": 2965,
          "title": "The Farm at Cape Kidnappers | Hawkes Bay | New Zealand | Exclusive ...",
          "url": "https://pkslist.azureedge.net/media/images/1/93e3b505-e7b7-489e-ab52-28bb1d5e97fb/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/93e3b505-e7b7-489e-ab52-28bb1d5e97fb/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/93e3b505-e7b7-489e-ab52-28bb1d5e97fb/256x192.jpeg",
          "viewOrder": 6
        },
        {
          "mediaId": 4988,
          "title": "The Farm at Cape Kidnappers | Natural Habitats",
          "url": "https://pkslist.azureedge.net/media/images/1/48f507a6-87e3-4d57-8abe-facf48b581fe/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/48f507a6-87e3-4d57-8abe-facf48b581fe/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/48f507a6-87e3-4d57-8abe-facf48b581fe/256x192.jpg",
          "viewOrder": 7
        }
      ],
      "geographicArea": {
        "geographicAreaId": 291,
        "latitude": -39.6407993,
        "longitude": 176.9895295,
        "parentGeographicAreaId": 0,
        "title": "446 Clifton Rd, Clifton 4172, New Zealand",
        "updated": "2020-05-06T12:20:00",
        "created": "2020-05-06T12:20:00"
      },
      "personalities": [
        {
          "tagId": 200,
          "tagName": "Rustic Elegance",
          "tagType": 1
        },
        {
          "tagId": 239,
          "tagName": "Family Friendly",
          "tagType": 1
        }
      ],
      "tags": [
        {
          "tagId": 73,
          "tagName": "Outdoor Terraces",
          "tagType": 2
        },
        {
          "tagId": 81,
          "tagName": "Golf",
          "tagType": 2
        },
        {
          "tagId": 82,
          "tagName": "Horseback Riding",
          "tagType": 2
        },
        {
          "tagId": 92,
          "tagName": "Infinity Pool",
          "tagType": 2
        },
        {
          "tagId": 156,
          "tagName": "Fireplace",
          "tagType": 2
        },
        {
          "tagId": 219,
          "tagName": "Wine Cellar",
          "tagType": 2
        },
        {
          "tagId": 235,
          "tagName": "Hot Tub",
          "tagType": 2
        },
        {
          "tagId": 243,
          "tagName": "Outdoor Activities",
          "tagType": 2
        },
        {
          "tagId": 259,
          "tagName": "Picnic",
          "tagType": 2
        },
        {
          "tagId": 266,
          "tagName": "Nature Walk",
          "tagType": 2
        },
        {
          "tagId": 317,
          "tagName": "Gourmet",
          "tagType": 2
        },
        {
          "tagId": 339,
          "tagName": "Sheperding",
          "tagType": 2
        }
      ],
      "venueCategoryAssigns": [
        {
          "venueCategoryAssignId": 75,
          "venueCategoryId": 20,
          "venueId": 444,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 20,
            "title": "BucketList Hotels",
            "region": "Global",
            "viewOrder": 0,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 594,
          "venueCategoryId": 30,
          "venueId": 444,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 30,
            "title": "Fairway Dreams",
            "region": "Global",
            "viewOrder": 5,
            "updated": "2020-05-29T09:35:00",
            "created": "2020-05-29T09:35:00",
            "venueCategoryAssign": [
              
            ]
          }
        }
      ]
    },
    {
      "venueId": 421,
      "venueCategoryId": 0,
      "status": 1,
      "title": "Four Seasons Hotel Firenze",
      "description": "Four Seasons Hotel Firenze is an enchanted city sanctuary in the midst of Florence, where an art-filled Renaissance palazzo and conventino frame a centuries-old private park. Relax amidst original frescoes and sculptured reliefs, graced by sunlight and airy garden vistas. Sip espresso under majestic trees, lounge by the open-air pool or bask in Italian spa secrets. Wonders of the Uffizi and Duomo are a stroll away. Tuscan hill towns beckon.",
      "brand": "Four Seasons",
      "blockQuote": "Take a stroll in the hotel's 15th century private garden, the largest in Florence.",
      "emailAddress": "res.firenze@fourseasons.com",
      "website": "fourseasons.com/florence/",
      "bookingURL": "https://reservations.fourseasons.com/?hotelCode=FLR501&checkIn=2021-03-17&checkOut=2021-03-18&adults=2&children=0&iata=96027890",
      "redirectNotes": "Four Seasons Hotel Firenze's reservations system",
      "phoneNumber": "+39 055 26261",
      "addressOne": "99",
      "addressTwo": "Borgo Pinti",
      "city": "Florence",
      "county": "",
      "region": "Tuscany",
      "country": "Italy",
      "rooms": 117,
      "dollar": 3,
      "updated": "2021-03-17T10:13:41.67",
      "created": "2020-05-06T12:20:00",
      "medias": [
        {
          "mediaId": 2675,
          "title": "Four Seasons Hotel Firenze - UPDATED 2017 Reviews & Price ...",
          "url": "https://pkslist.azureedge.net/media/images/1/955ee422-b601-4688-adf9-e295b5c929fc/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/955ee422-b601-4688-adf9-e295b5c929fc/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/955ee422-b601-4688-adf9-e295b5c929fc/256x192.jpg",
          "viewOrder": 0
        },
        {
          "mediaId": 25350,
          "title": "Four Seasons Hotel Firenze, Florence – Updated 2018 Prices",
          "url": "https://pkslist.azureedge.net/media/images/1/d9b746d6-7cbb-4095-a4c7-1304cf821cc1/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/d9b746d6-7cbb-4095-a4c7-1304cf821cc1/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/d9b746d6-7cbb-4095-a4c7-1304cf821cc1/256x192.jpg",
          "viewOrder": 1
        },
        {
          "mediaId": 25353,
          "title": "Il Palagio - Four Seasons Hotel Firenze | Flawless Firenze",
          "url": "https://pkslist.azureedge.net/media/images/1/13b77206-c68b-470f-9b6a-81b91143bb5f/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/13b77206-c68b-470f-9b6a-81b91143bb5f/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/13b77206-c68b-470f-9b6a-81b91143bb5f/256x192.jpg",
          "viewOrder": 2
        },
        {
          "mediaId": 25352,
          "title": "Atrium Bar - Four Seasons Hotel Firenze | Flawless Firenze",
          "url": "https://pkslist.azureedge.net/media/images/1/6d5a7042-692f-4f62-b5fd-0aeeeccb253a/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/6d5a7042-692f-4f62-b5fd-0aeeeccb253a/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/6d5a7042-692f-4f62-b5fd-0aeeeccb253a/256x192.jpg",
          "viewOrder": 3
        },
        {
          "mediaId": 25358,
          "title": "Royal Suite (della Gherardesca) | Four Seasons Hotel Firenze",
          "url": "https://pkslist.azureedge.net/media/images/1/877e1af2-06c4-49c2-b301-9a0bca436f22/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/877e1af2-06c4-49c2-b301-9a0bca436f22/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/877e1af2-06c4-49c2-b301-9a0bca436f22/256x192.jpg",
          "viewOrder": 4
        },
        {
          "mediaId": 25356,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/354853ea-813a-4949-aebe-244e715e2118/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/354853ea-813a-4949-aebe-244e715e2118/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/354853ea-813a-4949-aebe-244e715e2118/256x192.jpg",
          "viewOrder": 5
        },
        {
          "mediaId": 25359,
          "title": "Royal Suite (della Gherardesca) | Four Seasons Hotel Firenze",
          "url": "https://pkslist.azureedge.net/media/images/1/d8528623-a2cf-44ee-ba27-17cdb1e71bb8/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/d8528623-a2cf-44ee-ba27-17cdb1e71bb8/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/d8528623-a2cf-44ee-ba27-17cdb1e71bb8/256x192.jpg",
          "viewOrder": 6
        },
        {
          "mediaId": 2679,
          "title": "10 hotel bathrooms around the world bigger than your condo | Daily ...",
          "url": "https://pkslist.azureedge.net/media/images/1/d52dfd10-a028-4302-bb1e-0499362f8dbe/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/d52dfd10-a028-4302-bb1e-0499362f8dbe/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/d52dfd10-a028-4302-bb1e-0499362f8dbe/256x192.jpg",
          "viewOrder": 7
        },
        {
          "mediaId": 25354,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/e7ff4ae5-ada3-406e-9848-841d9c241d56/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/e7ff4ae5-ada3-406e-9848-841d9c241d56/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/e7ff4ae5-ada3-406e-9848-841d9c241d56/256x192.jpg",
          "viewOrder": 8
        },
        {
          "mediaId": 25360,
          "title": "Four Seasons Hotel Firenze | Flawless Firenze",
          "url": "https://pkslist.azureedge.net/media/images/1/000951b7-6847-48f4-bc3e-0bf375eb57c6/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/000951b7-6847-48f4-bc3e-0bf375eb57c6/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/000951b7-6847-48f4-bc3e-0bf375eb57c6/256x192.jpg",
          "viewOrder": 9
        }
      ],
      "geographicArea": {
        "geographicAreaId": 269,
        "latitude": 43.7770311,
        "longitude": 11.266494800000032,
        "parentGeographicAreaId": 0,
        "title": "Borgo Pinti, 99, 50121 Firenze, Italy",
        "updated": "2020-05-06T12:20:00",
        "created": "2020-05-06T12:20:00"
      },
      "personalities": [
        {
          "tagId": 42,
          "tagName": "Classic Traditionalist ",
          "tagType": 1
        },
        {
          "tagId": 45,
          "tagName": "Historic Sense",
          "tagType": 1
        }
      ],
      "tags": [
        {
          "tagId": 4,
          "tagName": "Spa",
          "tagType": 2
        },
        {
          "tagId": 32,
          "tagName": "Garden",
          "tagType": 2
        },
        {
          "tagId": 39,
          "tagName": "City",
          "tagType": 2
        },
        {
          "tagId": 59,
          "tagName": "Cultural",
          "tagType": 2
        },
        {
          "tagId": 60,
          "tagName": "Gastronomic",
          "tagType": 2
        },
        {
          "tagId": 65,
          "tagName": "Shopping",
          "tagType": 2
        },
        {
          "tagId": 68,
          "tagName": "Business",
          "tagType": 2
        },
        {
          "tagId": 73,
          "tagName": "Outdoor Terraces",
          "tagType": 2
        },
        {
          "tagId": 85,
          "tagName": "Pet Friendly",
          "tagType": 2
        },
        {
          "tagId": 87,
          "tagName": "Outdoor Pool",
          "tagType": 2
        },
        {
          "tagId": 207,
          "tagName": "Lounge/Bar",
          "tagType": 2
        },
        {
          "tagId": 222,
          "tagName": "Michelin Star dining",
          "tagType": 2
        }
      ],
      "venueCategoryAssigns": [
        {
          "venueCategoryAssignId": 587,
          "venueCategoryId": 20,
          "venueId": 421,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 20,
            "title": "BucketList Hotels",
            "region": "Global",
            "viewOrder": 0,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 588,
          "venueCategoryId": 5,
          "venueId": 421,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 5,
            "title": "Romantic Getaways",
            "region": "Global",
            "viewOrder": 20,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        }
      ]
    },
    {
      "venueId": 414,
      "venueCategoryId": 20,
      "status": 1,
      "title": "Singita Lebombo Lodge",
      "description": "This dramatic lodge, comprises 13 suites and one private-use villa that give guests a front row seat to the Kruger National Park with bold, contemporary open plan suites suspended above the N’Wanetsi River. The exclusive concession is a richly diverse habitat, teeming with game, beneath endless African skies.\n\nOther highlights of the lodge include a roof terrace and wine studio (a multi-level retreat which boasts a library, multi-media room, wine studio and rooftop viewing area), an interactive kitchen, an espresso bar and help-yourself deli, and an additional 25-metre lap pool. All communal areas of the lodge have been pushed out to ensure that the outside bursts in, offering elegant, calm and meditative spaces immersed in a dramatic landscape of giant euphorbia trees.",
      "brand": "Singita",
      "blockQuote": "A 100% solar powered, incredibly chic and stylish safari experience.",
      "emailAddress": "enquiries@singita.com",
      "website": "singita.com/lodge/singita-lebombo-lodge/",
      "bookingURL": "https://availability.singita.com/singita/Trip-Planner/Lodging",
      "redirectNotes": "",
      "phoneNumber": "+27 13 735 5500",
      "addressOne": "",
      "addressTwo": "",
      "city": "Kruger Park",
      "county": "",
      "region": "Greater Kruger",
      "country": "South Africa",
      "rooms": 15,
      "dollar": 3,
      "updated": "2020-05-17T07:39:06.2",
      "created": "2020-05-06T12:20:00",
      "medias": [
        {
          "mediaId": 36184,
          "title": "Singita Lebombo's soul-restoring beauty | Lodges and Camps | Singita",
          "url": "https://pkslist.azureedge.net/media/images/1/b089edd3-ae2f-4915-8efd-59cd027fa422/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/b089edd3-ae2f-4915-8efd-59cd027fa422/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/b089edd3-ae2f-4915-8efd-59cd027fa422/256x192.jpg",
          "viewOrder": 0
        },
        {
          "mediaId": 36182,
          "title": "Singita Lebombo Lodge - Kruger National Park | Rhino Africa",
          "url": "https://pkslist.azureedge.net/media/images/1/fb954db8-cf91-4c94-b467-e75db1317ffa/1200x900.jpeg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/fb954db8-cf91-4c94-b467-e75db1317ffa/800x600.jpeg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/fb954db8-cf91-4c94-b467-e75db1317ffa/256x192.jpeg",
          "viewOrder": 1
        },
        {
          "mediaId": 2617,
          "title": "Enjoying Amazing Natural Landscape at Singita Lebombo Lodge, South ...",
          "url": "https://pkslist.azureedge.net/media/images/1/db4627b0-0edf-4c8c-bf67-06b5db04a6b2/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/db4627b0-0edf-4c8c-bf67-06b5db04a6b2/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/db4627b0-0edf-4c8c-bf67-06b5db04a6b2/256x192.jpg",
          "viewOrder": 2
        },
        {
          "mediaId": 2619,
          "title": "Singita Lebombo Lodge - XO Private",
          "url": "https://pkslist.azureedge.net/media/images/1/62a492de-1e9e-445e-8cd0-c1ba8ed50dcc/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/62a492de-1e9e-445e-8cd0-c1ba8ed50dcc/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/62a492de-1e9e-445e-8cd0-c1ba8ed50dcc/256x192.jpg",
          "viewOrder": 3
        },
        {
          "mediaId": 36180,
          "title": "Singita Lebombo Lodge - Evolved | Lodges and Camps | Singita",
          "url": "https://pkslist.azureedge.net/media/images/1/b0cc4daf-bf34-43cc-854b-eacb5da0eea5/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/b0cc4daf-bf34-43cc-854b-eacb5da0eea5/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/b0cc4daf-bf34-43cc-854b-eacb5da0eea5/256x192.jpg",
          "viewOrder": 4
        },
        {
          "mediaId": 36187,
          "title": "For The Soul-Restoring Place Look No Further Than Singita Lebombo ...",
          "url": "https://pkslist.azureedge.net/media/images/1/b76788e2-2961-4f7b-9fe7-37c7b3cf68ba/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/b76788e2-2961-4f7b-9fe7-37c7b3cf68ba/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/b76788e2-2961-4f7b-9fe7-37c7b3cf68ba/256x192.jpg",
          "viewOrder": 5
        },
        {
          "mediaId": 36181,
          "title": "A kaleidoscope of contrasts: Singita's Southern African journey ...",
          "url": "https://pkslist.azureedge.net/media/images/1/b99f7c26-20db-4921-8684-2b2a6ad3ceca/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/b99f7c26-20db-4921-8684-2b2a6ad3ceca/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/b99f7c26-20db-4921-8684-2b2a6ad3ceca/256x192.jpg",
          "viewOrder": 6
        },
        {
          "mediaId": 2620,
          "title": "Singita Lebombo Lodge - Haute Grandeur",
          "url": "https://pkslist.azureedge.net/media/images/1/cca611c2-6ed4-4787-89f2-ce3107c88b01/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/cca611c2-6ed4-4787-89f2-ce3107c88b01/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/cca611c2-6ed4-4787-89f2-ce3107c88b01/256x192.jpg",
          "viewOrder": 7
        },
        {
          "mediaId": 2616,
          "title": "Singita-Lebombo-Lodge-Nexus-Travel-Solutions-Luxury-Bespoke ...",
          "url": "https://pkslist.azureedge.net/media/images/1/f2b9a781-0b9b-4548-ba80-b238277e6af3/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/f2b9a781-0b9b-4548-ba80-b238277e6af3/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/f2b9a781-0b9b-4548-ba80-b238277e6af3/256x192.jpg",
          "viewOrder": 8
        }
      ],
      "geographicArea": {
        "geographicAreaId": 264,
        "latitude": -24.4520529,
        "longitude": 31.97922029999995,
        "parentGeographicAreaId": 0,
        "title": "N'wanetsi River, Kruger National Park, Mpumalanga, Kruger Park, 1350, South Africa",
        "updated": "2020-05-06T12:20:00",
        "created": "2020-05-06T12:20:00"
      },
      "personalities": [
        {
          "tagId": 43,
          "tagName": "Design Slickster",
          "tagType": 1
        },
        {
          "tagId": 47,
          "tagName": "Intimate Luxury ",
          "tagType": 1
        }
      ],
      "tags": [
        {
          "tagId": 4,
          "tagName": "Spa",
          "tagType": 2
        },
        {
          "tagId": 63,
          "tagName": "Safari",
          "tagType": 2
        },
        {
          "tagId": 106,
          "tagName": "Nature",
          "tagType": 2
        },
        {
          "tagId": 134,
          "tagName": "Wilderness Walks",
          "tagType": 2
        },
        {
          "tagId": 135,
          "tagName": "Bird Watching",
          "tagType": 2
        },
        {
          "tagId": 207,
          "tagName": "Lounge/Bar",
          "tagType": 2
        },
        {
          "tagId": 243,
          "tagName": "Outdoor Activities",
          "tagType": 2
        },
        {
          "tagId": 269,
          "tagName": "Photography",
          "tagType": 2
        },
        {
          "tagId": 287,
          "tagName": "Wildlife",
          "tagType": 2
        },
        {
          "tagId": 317,
          "tagName": "Gourmet",
          "tagType": 2
        }
      ],
      "venueCategoryAssigns": [
        {
          "venueCategoryAssignId": 65,
          "venueCategoryId": 20,
          "venueId": 414,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 20,
            "title": "BucketList Hotels",
            "region": "Global",
            "viewOrder": 0,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 341,
          "venueCategoryId": 13,
          "venueId": 414,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 13,
            "title": "Wildlife Encounters",
            "region": "Global",
            "viewOrder": 4,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        }
      ]
    },
    {
      "venueId": 377,
      "venueCategoryId": 20,
      "status": 1,
      "title": "Ett Hem",
      "description": "Ett Hem was a private residence built in 1910. The people who built it had a propensity for beauty, and they left their mark on this townhouse, situated in a peaceful, residential area of Stockholm. This Arts and Crafts building is a tranquil home away from home, steeped in the Scandinavian aesthetics where functionality meets beauty. \n\nTransforming the house into a hotel has been an incredibly delicate process: owner Jeanette Mix wanted to retain its personality but it also had to offer all the services required by modern travellers. The result is that Ett Hem is again a happy house filled with the best collection of furniture, Scandinavian antiques and design. \n\nWith twelve rooms, Ett Hem offers discerning guests comfortable interiors and bedrooms full of summery whiteness of light. Outdoors, the courtyard garden is a secret city wilderness that is the perfect place for summer breakfasts or winding down in the evening over a chilled bottle of wine.\n\nEtt Hem is not the usual hotel. Ett hem is a place where guests are treated as friends of the family, a place that allows guests to become part of it and feel at home.",
      "brand": "",
      "blockQuote": "Only one word to describe this hotel : PERFECT",
      "emailAddress": "info@etthem.se",
      "website": "etthem.se/",
      "bookingURL": "https://be.synxis.com/?adult=1&agencyid=96027890&arrive=2018-09-03&chain=22402&child=0&currency=SEK&depart=2018-09-04&filter=HOTEL&hotel=78802&level=hotel&locale=en-GB&rooms=1&sbe_ri=0&sbe_sid=AwwXBQkaj1v-T6VXB0v_Qzz6&src=24C",
      "redirectNotes": "Ett Hem's reservations system",
      "phoneNumber": "+46 8 20 05 90",
      "addressOne": "",
      "addressTwo": "Sköldungagatan",
      "city": "Stockholm",
      "county": "",
      "region": "",
      "country": "Sweden",
      "rooms": 22,
      "dollar": 3,
      "updated": "2022-08-22T07:06:16.617",
      "created": "2020-05-06T12:20:00",
      "medias": [
        {
          "mediaId": 35900,
          "title": "Ett Hem",
          "url": "https://pkslist.azureedge.net/media/images/1/daaf1a93-e5b7-4628-bb89-ae38a0645e8e/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/daaf1a93-e5b7-4628-bb89-ae38a0645e8e/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/daaf1a93-e5b7-4628-bb89-ae38a0645e8e/256x192.jpg",
          "viewOrder": 0
        },
        {
          "mediaId": 35765,
          "title": "This Converted Mansion in Östermalm Has Become a Cult Destination ...",
          "url": "https://pkslist.azureedge.net/media/images/1/70cbec69-ad50-4da9-a9f6-951abf1e176e/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/70cbec69-ad50-4da9-a9f6-951abf1e176e/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/70cbec69-ad50-4da9-a9f6-951abf1e176e/256x192.jpg",
          "viewOrder": 1
        },
        {
          "mediaId": 2080,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/babcd1a5-fe72-40ca-931b-490492bc4dbb/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/babcd1a5-fe72-40ca-931b-490492bc4dbb/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/babcd1a5-fe72-40ca-931b-490492bc4dbb/256x192.jpg",
          "viewOrder": 2
        },
        {
          "mediaId": 2077,
          "title": "Ett Hem, Stockholm / Sweden › Pretty Hotels",
          "url": "https://pkslist.azureedge.net/media/images/1/892b4997-8d1d-47c6-9539-08d2a2425d28/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/892b4997-8d1d-47c6-9539-08d2a2425d28/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/892b4997-8d1d-47c6-9539-08d2a2425d28/256x192.jpg",
          "viewOrder": 3
        },
        {
          "mediaId": 2055,
          "title": "Ett Hem",
          "url": "https://pkslist.azureedge.net/media/images/1/ea78e60b-b940-43c4-a082-b7893baf38f9/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/ea78e60b-b940-43c4-a082-b7893baf38f9/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/ea78e60b-b940-43c4-a082-b7893baf38f9/256x192.jpg",
          "viewOrder": 4
        },
        {
          "mediaId": 2063,
          "title": "Ett Hem Hotel Stockholm by Studio Ilse.",
          "url": "https://pkslist.azureedge.net/media/images/1/aeac027d-0c9a-4035-b194-06d41ac7b202/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/aeac027d-0c9a-4035-b194-06d41ac7b202/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/aeac027d-0c9a-4035-b194-06d41ac7b202/256x192.jpg",
          "viewOrder": 5
        },
        {
          "mediaId": 35766,
          "title": "A Visual Journey Through Stockholm's Hotel Ett Hem | Vardagsrum ...",
          "url": "https://pkslist.azureedge.net/media/images/1/293497cd-bd8f-4717-aabf-956313e32e14/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/293497cd-bd8f-4717-aabf-956313e32e14/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/293497cd-bd8f-4717-aabf-956313e32e14/256x192.jpg",
          "viewOrder": 6
        },
        {
          "mediaId": 2052,
          "title": "Ett Hem",
          "url": "https://pkslist.azureedge.net/media/images/1/57982577-8fee-41be-a2fa-b54e2049dc08/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/57982577-8fee-41be-a2fa-b54e2049dc08/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/57982577-8fee-41be-a2fa-b54e2049dc08/256x192.jpg",
          "viewOrder": 7
        }
      ],
      "geographicArea": {
        "geographicAreaId": 231,
        "latitude": 59.3460906,
        "longitude": 18.068265099999962,
        "parentGeographicAreaId": 0,
        "title": "Sköldungagatan, 114 27 Stockholm, Sweden",
        "updated": "2020-05-06T12:20:00",
        "created": "2020-05-06T12:20:00"
      },
      "personalities": [
        {
          "tagId": 40,
          "tagName": "Boutique Bolthole ",
          "tagType": 1
        },
        {
          "tagId": 43,
          "tagName": "Design Slickster",
          "tagType": 1
        }
      ],
      "tags": [
        {
          "tagId": 4,
          "tagName": "Spa",
          "tagType": 2
        },
        {
          "tagId": 34,
          "tagName": "Cosy",
          "tagType": 2
        },
        {
          "tagId": 73,
          "tagName": "Outdoor Terraces",
          "tagType": 2
        },
        {
          "tagId": 75,
          "tagName": "Casual Dining",
          "tagType": 2
        },
        {
          "tagId": 93,
          "tagName": "Private",
          "tagType": 2
        },
        {
          "tagId": 131,
          "tagName": "Library",
          "tagType": 2
        },
        {
          "tagId": 156,
          "tagName": "Fireplace",
          "tagType": 2
        },
        {
          "tagId": 205,
          "tagName": "Design",
          "tagType": 2
        },
        {
          "tagId": 276,
          "tagName": "Gym",
          "tagType": 2
        },
        {
          "tagId": 280,
          "tagName": "Piano",
          "tagType": 2
        },
        {
          "tagId": 317,
          "tagName": "Gourmet",
          "tagType": 2
        },
        {
          "tagId": 323,
          "tagName": "City Walk",
          "tagType": 2
        }
      ],
      "venueCategoryAssigns": [
        {
          "venueCategoryAssignId": 56,
          "venueCategoryId": 20,
          "venueId": 377,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 20,
            "title": "BucketList Hotels",
            "region": "Global",
            "viewOrder": 0,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 331,
          "venueCategoryId": 16,
          "venueId": 377,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 16,
            "title": "Boutique Boltholes",
            "region": "Global",
            "viewOrder": 18,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        }
      ]
    },
    {
      "venueId": 358,
      "venueCategoryId": 15,
      "status": 1,
      "title": "Belmond Cap Juluca",
      "description": "Belmond Cap Juluca is a dream-like escape, spread across two crescent-shaped coves. Feel the sea breeze blowing across your private terrace—every room is a beachfront haven. Be positioned to discover an island famed for pristine sands, broad coral reefs and an electrifying culinary scene.\n\nImagine enchanting ocean views alongside bright Caribbean accents. For those wondering where to stay in Anguilla, each room at Belmond Cap Juluca is an enticing haven to unwind in. Spread out on your plush king bed after a day spent on the beach, or relax with a cocktail on your private terrace. For true indulgence, pick a Private Pool Suite or a Villa for unparalleled privacy.",
      "brand": "Belmond",
      "blockQuote": "This resort has one of the most beautiful, powdery white sand beaches in the world.",
      "emailAddress": "capjuluca.cap@belmond.com",
      "website": "belmond.com/hotels/caribbean/anguilla/belmond-cap-juluca/",
      "bookingURL": "https://www.belmond.com/reservations/hotel-loading?productId=CAP&occupancy=2-0&pdadults=02&iatacode=96027890",
      "redirectNotes": "Belmond Cap Juluca's reservations system",
      "phoneNumber": "+1 264 497 6666",
      "addressOne": "",
      "addressTwo": "",
      "city": "Maundays Bay",
      "county": "",
      "region": "Anguilla",
      "country": "Caribbean",
      "rooms": 108,
      "dollar": 3,
      "updated": "2020-09-25T17:38:37.423",
      "created": "2020-05-06T12:20:00",
      "medias": [
        {
          "mediaId": 30798,
          "title": "Resort Belmond Cap Juluca, West End Village, Anguilla - Booking.com",
          "url": "https://pkslist.azureedge.net/media/images/1/69ee7902-8010-43d4-8083-9bbd4cf87e86/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/69ee7902-8010-43d4-8083-9bbd4cf87e86/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/69ee7902-8010-43d4-8083-9bbd4cf87e86/256x192.jpg",
          "viewOrder": 0
        },
        {
          "mediaId": 30806,
          "title": "Belmond Cap Juluca | Luxury Hotel in Anguilla | Red Savannah",
          "url": "https://pkslist.azureedge.net/media/images/1/1e5935c6-69d8-4618-82a3-7ae1d1d2c4ef/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/1e5935c6-69d8-4618-82a3-7ae1d1d2c4ef/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/1e5935c6-69d8-4618-82a3-7ae1d1d2c4ef/256x192.jpg",
          "viewOrder": 1
        },
        {
          "mediaId": 30814,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/8d610289-c0bd-485d-8d24-4cb84d725fc1/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/8d610289-c0bd-485d-8d24-4cb84d725fc1/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/8d610289-c0bd-485d-8d24-4cb84d725fc1/256x192.jpg",
          "viewOrder": 2
        },
        {
          "mediaId": 30813,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/aa693eb1-6012-48de-9011-87495e739ee6/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/aa693eb1-6012-48de-9011-87495e739ee6/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/aa693eb1-6012-48de-9011-87495e739ee6/256x192.jpg",
          "viewOrder": 3
        },
        {
          "mediaId": 30808,
          "title": "Belmond Acquires Iconic Caribbean Resort – Cap Juluca, Anguilla",
          "url": "https://pkslist.azureedge.net/media/images/1/df0d1a41-25c2-4e89-84f1-e7e3d984e3ce/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/df0d1a41-25c2-4e89-84f1-e7e3d984e3ce/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/df0d1a41-25c2-4e89-84f1-e7e3d984e3ce/256x192.jpg",
          "viewOrder": 4
        },
        {
          "mediaId": 30802,
          "title": "BELMOND CAP JULUCA - Updated 2019 Prices & Resort Reviews ...",
          "url": "https://pkslist.azureedge.net/media/images/1/3474a73e-9f5b-44c1-adc3-ca815209268d/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/3474a73e-9f5b-44c1-adc3-ca815209268d/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/3474a73e-9f5b-44c1-adc3-ca815209268d/256x192.jpg",
          "viewOrder": 6
        },
        {
          "mediaId": 30812,
          "title": "New Image",
          "url": "https://pkslist.azureedge.net/media/images/1/ae5cdc7c-fb8b-497c-8cdb-6bc9e51ce0d1/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/ae5cdc7c-fb8b-497c-8cdb-6bc9e51ce0d1/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/ae5cdc7c-fb8b-497c-8cdb-6bc9e51ce0d1/256x192.jpg",
          "viewOrder": 9
        },
        {
          "mediaId": 30804,
          "title": "Belmond's Legendary La Samanna and Cap Juluca Have Reopened After ...",
          "url": "https://pkslist.azureedge.net/media/images/1/8c3d65d0-e14b-44c3-a53a-1656e0a6eb94/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/8c3d65d0-e14b-44c3-a53a-1656e0a6eb94/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/8c3d65d0-e14b-44c3-a53a-1656e0a6eb94/256x192.jpg",
          "viewOrder": 10
        },
        {
          "mediaId": 30807,
          "title": "At Anguilla's Belmond Cap Juluca, A Timeless Kind of Beauty",
          "url": "https://pkslist.azureedge.net/media/images/1/5931e6bc-4c10-4366-ab8f-c0f91f13485a/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/5931e6bc-4c10-4366-ab8f-c0f91f13485a/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/5931e6bc-4c10-4366-ab8f-c0f91f13485a/256x192.jpg",
          "viewOrder": 11
        },
        {
          "mediaId": 30801,
          "title": "Belmond Cap Juluca, West End, Anguilla",
          "url": "https://pkslist.azureedge.net/media/images/1/9e5fc6c7-0e6f-43d4-ab5f-1eccb3350e75/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/9e5fc6c7-0e6f-43d4-ab5f-1eccb3350e75/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/9e5fc6c7-0e6f-43d4-ab5f-1eccb3350e75/256x192.jpg",
          "viewOrder": 12
        }
      ],
      "geographicArea": {
        "geographicAreaId": 213,
        "latitude": 18.1619637,
        "longitude": -63.145040800000004,
        "parentGeographicAreaId": 0,
        "title": "Maundays Bay, Anguilla",
        "updated": "2020-05-06T12:20:00",
        "created": "2020-05-06T12:20:00"
      },
      "personalities": [
        {
          "tagId": 57,
          "tagName": "Romantic",
          "tagType": 1
        },
        {
          "tagId": 157,
          "tagName": "Beach Escape",
          "tagType": 1
        }
      ],
      "tags": [
        {
          "tagId": 4,
          "tagName": "Spa",
          "tagType": 2
        },
        {
          "tagId": 32,
          "tagName": "Garden",
          "tagType": 2
        },
        {
          "tagId": 58,
          "tagName": "Beach",
          "tagType": 2
        },
        {
          "tagId": 60,
          "tagName": "Gastronomic",
          "tagType": 2
        },
        {
          "tagId": 72,
          "tagName": "Bar(s)",
          "tagType": 2
        },
        {
          "tagId": 73,
          "tagName": "Outdoor Terraces",
          "tagType": 2
        },
        {
          "tagId": 79,
          "tagName": "Fitness",
          "tagType": 2
        },
        {
          "tagId": 82,
          "tagName": "Horseback Riding",
          "tagType": 2
        },
        {
          "tagId": 87,
          "tagName": "Outdoor Pool",
          "tagType": 2
        },
        {
          "tagId": 115,
          "tagName": "Snorkeling",
          "tagType": 2
        },
        {
          "tagId": 116,
          "tagName": "Scuba Diving",
          "tagType": 2
        },
        {
          "tagId": 120,
          "tagName": "Private Dining",
          "tagType": 2
        },
        {
          "tagId": 152,
          "tagName": "Kite Surfing",
          "tagType": 2
        }
      ],
      "venueCategoryAssigns": [
        {
          "venueCategoryAssignId": 444,
          "venueCategoryId": 20,
          "venueId": 358,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 20,
            "title": "BucketList Hotels",
            "region": "Global",
            "viewOrder": 0,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        }
      ]
    },
    {
      "venueId": 354,
      "venueCategoryId": 20,
      "status": 1,
      "title": "Cap Estel",
      "description": "Situated on its own two-hectare peninsula jutting into the Mediterranean Sea, Cap Estel is set right on the sea in a location unique in France. \n\nSurrounded on three sides by water, with magnificent panoramic views, it was voted the Best Sea-front Hotel in France in 2011 by World Travel Awards.\n\nOnly 10 minutes away from Monaco, 10 minutes from Beaulieu-sur-Mer and St-Jean-Cap Ferrat, and 30 minutes from Nice, it is the ideal place from which to explore and enjoy the beauty of the Côte d’Azur.",
      "brand": "",
      "blockQuote": "The epitome of how the French Riviera should be.",
      "emailAddress": "contact@capestel.com",
      "website": "capestel.com/en/",
      "bookingURL": "http://www.booking.com/hotel/fr/cap-estel.html?aid=925669",
      "redirectNotes": "",
      "phoneNumber": " +33 493 762 929",
      "addressOne": "1312",
      "addressTwo": "Avenue Raymond Poincaré",
      "city": "Eze",
      "county": "",
      "region": "Provence Cote d'Azur",
      "country": "France",
      "rooms": 18,
      "dollar": 3,
      "updated": "2020-05-14T20:04:03.213",
      "created": "2020-05-06T12:20:00",
      "medias": [
        {
          "mediaId": 1692,
          "title": "Cap Estel Hotel (Eze, France) - UPDATED 2016 Reviews - TripAdvisor",
          "url": "https://pkslist.azureedge.net/media/images/1/128dea48-1ebe-48a5-aab2-9beaf6eeecaa/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/128dea48-1ebe-48a5-aab2-9beaf6eeecaa/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/128dea48-1ebe-48a5-aab2-9beaf6eeecaa/256x192.jpg",
          "viewOrder": 0
        },
        {
          "mediaId": 1680,
          "title": "Hotel Cap Estel | Hotel-R",
          "url": "https://pkslist.azureedge.net/media/images/1/d4b6304d-946b-493b-9da2-0e07077c3c10/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/d4b6304d-946b-493b-9da2-0e07077c3c10/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/d4b6304d-946b-493b-9da2-0e07077c3c10/256x192.jpg",
          "viewOrder": 1
        },
        {
          "mediaId": 1684,
          "title": "CAP ESTEL EZE SEA FRONT - LUXURY SPA HOTEL COTE D'AZUR - OFFICIAL ...",
          "url": "https://pkslist.azureedge.net/media/images/1/fdb21b8c-8e12-45c6-bc17-04663880ba19/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/fdb21b8c-8e12-45c6-bc17-04663880ba19/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/fdb21b8c-8e12-45c6-bc17-04663880ba19/256x192.jpg",
          "viewOrder": 2
        },
        {
          "mediaId": 1685,
          "title": "Hotel Cap Estel | Hotel-R",
          "url": "https://pkslist.azureedge.net/media/images/1/52083ba7-f464-4438-bec1-7e17941130cb/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/52083ba7-f464-4438-bec1-7e17941130cb/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/52083ba7-f464-4438-bec1-7e17941130cb/256x192.jpg",
          "viewOrder": 3
        },
        {
          "mediaId": 1686,
          "title": "Hotel Cap Estel | Hotel-R",
          "url": "https://pkslist.azureedge.net/media/images/1/a5b6f3ce-11e3-436e-a9e7-f180bf6a9273/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/a5b6f3ce-11e3-436e-a9e7-f180bf6a9273/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/a5b6f3ce-11e3-436e-a9e7-f180bf6a9273/256x192.jpg",
          "viewOrder": 4
        },
        {
          "mediaId": 1687,
          "title": "CAP ESTEL EZE SEA FRONT - LUXURY SPA HOTEL COTE D'AZUR - OFFICIAL ...",
          "url": "https://pkslist.azureedge.net/media/images/1/64cdc55f-2b81-48ba-8bc0-e6b71e0d6ce7/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/64cdc55f-2b81-48ba-8bc0-e6b71e0d6ce7/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/64cdc55f-2b81-48ba-8bc0-e6b71e0d6ce7/256x192.jpg",
          "viewOrder": 5
        },
        {
          "mediaId": 1690,
          "title": "Cap Estel Hotel, Nice, French Riviera - Five Star Alliance",
          "url": "https://pkslist.azureedge.net/media/images/1/38ab51ba-d419-4bbf-9802-c41e36ab5066/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/38ab51ba-d419-4bbf-9802-c41e36ab5066/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/38ab51ba-d419-4bbf-9802-c41e36ab5066/256x192.jpg",
          "viewOrder": 6
        },
        {
          "mediaId": 1688,
          "title": "A Day at Hotel Cap Estel, Èze - Corniche Watches",
          "url": "https://pkslist.azureedge.net/media/images/1/09b4cb84-f4b4-4013-86e8-6b2584e9e674/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/09b4cb84-f4b4-4013-86e8-6b2584e9e674/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/09b4cb84-f4b4-4013-86e8-6b2584e9e674/256x192.jpg",
          "viewOrder": 7
        },
        {
          "mediaId": 1689,
          "title": "Cap Estel, C te d'Azur | GP Associates",
          "url": "https://pkslist.azureedge.net/media/images/1/edf8432d-934e-4de9-a172-04f842629614/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/edf8432d-934e-4de9-a172-04f842629614/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/edf8432d-934e-4de9-a172-04f842629614/256x192.jpg",
          "viewOrder": 8
        }
      ],
      "geographicArea": {
        "geographicAreaId": 209,
        "latitude": 43.7186161,
        "longitude": 7.373898599999961,
        "parentGeographicAreaId": 0,
        "title": "1312 Avenue Raymond Poincaré, 06360 Èze, France",
        "updated": "2020-05-06T12:20:00",
        "created": "2020-05-06T12:20:00"
      },
      "personalities": [
        {
          "tagId": 47,
          "tagName": "Intimate Luxury ",
          "tagType": 1
        },
        {
          "tagId": 51,
          "tagName": "Style Icon",
          "tagType": 1
        }
      ],
      "tags": [
        {
          "tagId": 4,
          "tagName": "Spa",
          "tagType": 2
        },
        {
          "tagId": 32,
          "tagName": "Garden",
          "tagType": 2
        },
        {
          "tagId": 86,
          "tagName": "Indoor Pool",
          "tagType": 2
        },
        {
          "tagId": 87,
          "tagName": "Outdoor Pool",
          "tagType": 2
        },
        {
          "tagId": 107,
          "tagName": "Seaside",
          "tagType": 2
        },
        {
          "tagId": 193,
          "tagName": "Outdoor Dining",
          "tagType": 2
        },
        {
          "tagId": 206,
          "tagName": "Private Beach",
          "tagType": 2
        },
        {
          "tagId": 207,
          "tagName": "Lounge/Bar",
          "tagType": 2
        }
      ],
      "venueCategoryAssigns": [
        {
          "venueCategoryAssignId": 50,
          "venueCategoryId": 20,
          "venueId": 354,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 20,
            "title": "BucketList Hotels",
            "region": "Global",
            "viewOrder": 0,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 335,
          "venueCategoryId": 1,
          "venueId": 354,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 1,
            "title": "Escape from the City",
            "region": "Global",
            "viewOrder": 14,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        }
      ]
    },
    {
      "venueId": 345,
      "venueCategoryId": 20,
      "status": 1,
      "title": "Taj Lake Palace",
      "description": "Taj Lake Palace in Udaipur, is the most romantic hotel in the world.  It was built in 1746 as the pleasure palace of Maharana Jagat Singh II. Taj Lake Palace is one of the most recognisable residences in the world , since it has been featured in many films and TV serials, most notably, Octopussy and The Jewel in The Crown.\n\nRestored to its pristine glory, this heritage hotel has  66 luxurious rooms and 17 grand suites. Its location on an island in the midst of a lake affords every room breathtaking views of the neighbouring City Palace, Aravalli Hills, Machla Magra Hills and Jag Mandir.",
      "brand": "",
      "blockQuote": "The most romantic and only hotel that's actually on Lake Pichola and has 360 degree views.",
      "emailAddress": "lakepalace.udaipur@tajhotels.com",
      "website": "tajhotels.com/en-in/taj/taj-lake-palace-udaipur/",
      "bookingURL": "http://www.booking.com/Share-I9fMr1r?aid=925669",
      "redirectNotes": "",
      "phoneNumber": "+91 294 242 8800",
      "addressOne": "",
      "addressTwo": "",
      "city": "Udaipur",
      "county": "",
      "region": "Rajasthan",
      "country": "India",
      "rooms": 83,
      "dollar": 3,
      "updated": "2020-07-28T16:42:09.07",
      "created": "2020-05-06T12:20:00",
      "medias": [
        {
          "mediaId": 16834,
          "title": "5 Star Palace Hotel in Udaipur | Taj Lake Palace, Udaipur",
          "url": "https://pkslist.azureedge.net/media/images/1/3f52b308-b6c6-46a5-811e-d245e3516f56/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/3f52b308-b6c6-46a5-811e-d245e3516f56/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/3f52b308-b6c6-46a5-811e-d245e3516f56/256x192.jpg",
          "viewOrder": 0
        },
        {
          "mediaId": 16835,
          "title": "Resort Taj Lake Palace, Udaipur, India - Booking.com",
          "url": "https://pkslist.azureedge.net/media/images/1/db66d4a4-8b9f-4939-a11d-9b51213d1b1a/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/db66d4a4-8b9f-4939-a11d-9b51213d1b1a/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/db66d4a4-8b9f-4939-a11d-9b51213d1b1a/256x192.jpg",
          "viewOrder": 1
        },
        {
          "mediaId": 1575,
          "title": "Inside Garden - Picture of Taj Lake Palace Udaipur, Udaipur ...",
          "url": "https://pkslist.azureedge.net/media/images/1/17f0136a-ed07-4a11-9b41-0646e4feca74/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/17f0136a-ed07-4a11-9b41-0646e4feca74/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/17f0136a-ed07-4a11-9b41-0646e4feca74/256x192.jpg",
          "viewOrder": 2
        },
        {
          "mediaId": 16836,
          "title": "Resort Taj Lake Palace, Udaipur, India - Booking.com",
          "url": "https://pkslist.azureedge.net/media/images/1/77a694e5-1c40-43eb-a4b8-1cd5590821c6/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/77a694e5-1c40-43eb-a4b8-1cd5590821c6/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/77a694e5-1c40-43eb-a4b8-1cd5590821c6/256x192.jpg",
          "viewOrder": 3
        },
        {
          "mediaId": 16837,
          "title": "Taj Lake Palace, Udaipur | Holidays 2017/2018 | Luxury & Tailor ...",
          "url": "https://pkslist.azureedge.net/media/images/1/d283e5fa-e60b-4032-843b-b673a891c810/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/d283e5fa-e60b-4032-843b-b673a891c810/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/d283e5fa-e60b-4032-843b-b673a891c810/256x192.jpg",
          "viewOrder": 4
        },
        {
          "mediaId": 1579,
          "title": "Taj Lake Palace, Udaipur, India - Hotel Review & Photos",
          "url": "https://pkslist.azureedge.net/media/images/1/da8f0947-bcd7-4215-b9a9-cb262c05e2c3/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/da8f0947-bcd7-4215-b9a9-cb262c05e2c3/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/da8f0947-bcd7-4215-b9a9-cb262c05e2c3/256x192.jpg",
          "viewOrder": 5
        },
        {
          "mediaId": 1580,
          "title": "Resort Taj Lake Palace, Udaipur, India - Booking.com",
          "url": "https://pkslist.azureedge.net/media/images/1/14a1372d-262a-4266-86ea-9c9862995bee/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/14a1372d-262a-4266-86ea-9c9862995bee/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/14a1372d-262a-4266-86ea-9c9862995bee/256x192.jpg",
          "viewOrder": 6
        },
        {
          "mediaId": 1576,
          "title": "Taj Lake Palace, Udaipur, India - Hotel Review & Photos",
          "url": "https://pkslist.azureedge.net/media/images/1/c496e5b3-4884-49c2-a477-44411682afb7/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/c496e5b3-4884-49c2-a477-44411682afb7/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/c496e5b3-4884-49c2-a477-44411682afb7/256x192.jpg",
          "viewOrder": 7
        },
        {
          "mediaId": 1577,
          "title": "Taj Lake Palace Facilities - Information about the Taj Lake Palace",
          "url": "https://pkslist.azureedge.net/media/images/1/df7f47b6-6bd2-43b2-a67a-780d92351b21/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/df7f47b6-6bd2-43b2-a67a-780d92351b21/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/df7f47b6-6bd2-43b2-a67a-780d92351b21/256x192.jpg",
          "viewOrder": 8
        },
        {
          "mediaId": 1578,
          "title": "Taj Lake Palace: One of the World's most Famous Luxury Hotels ...",
          "url": "https://pkslist.azureedge.net/media/images/1/14d95e5f-82ac-489e-aaa4-cd9d49e4a94b/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/14d95e5f-82ac-489e-aaa4-cd9d49e4a94b/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/14d95e5f-82ac-489e-aaa4-cd9d49e4a94b/256x192.jpg",
          "viewOrder": 9
        }
      ],
      "geographicArea": {
        "geographicAreaId": 204,
        "latitude": 24.575351,
        "longitude": 73.68001600000002,
        "parentGeographicAreaId": 0,
        "title": "Pichola, Udaipur, Rajasthan 313001, India",
        "updated": "2020-05-06T12:20:00",
        "created": "2020-05-06T12:20:00"
      },
      "personalities": [
        {
          "tagId": 45,
          "tagName": "Historic Sense",
          "tagType": 1
        },
        {
          "tagId": 48,
          "tagName": "Iconic/Landmark ",
          "tagType": 1
        }
      ],
      "tags": [
        {
          "tagId": 4,
          "tagName": "Spa",
          "tagType": 2
        },
        {
          "tagId": 59,
          "tagName": "Cultural",
          "tagType": 2
        },
        {
          "tagId": 87,
          "tagName": "Outdoor Pool",
          "tagType": 2
        },
        {
          "tagId": 105,
          "tagName": "Afternoon Tea",
          "tagType": 2
        },
        {
          "tagId": 120,
          "tagName": "Private Dining",
          "tagType": 2
        },
        {
          "tagId": 121,
          "tagName": "Yoga",
          "tagType": 2
        },
        {
          "tagId": 155,
          "tagName": "Art",
          "tagType": 2
        },
        {
          "tagId": 187,
          "tagName": "Island",
          "tagType": 2
        },
        {
          "tagId": 198,
          "tagName": "Boat Excursion",
          "tagType": 2
        },
        {
          "tagId": 207,
          "tagName": "Lounge/Bar",
          "tagType": 2
        },
        {
          "tagId": 255,
          "tagName": "Music",
          "tagType": 2
        },
        {
          "tagId": 283,
          "tagName": "Restaurants",
          "tagType": 2
        },
        {
          "tagId": 284,
          "tagName": "Architecture",
          "tagType": 2
        },
        {
          "tagId": 295,
          "tagName": "Astrology Session",
          "tagType": 2
        }
      ],
      "venueCategoryAssigns": [
        {
          "venueCategoryAssignId": 48,
          "venueCategoryId": 20,
          "venueId": 345,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 20,
            "title": "BucketList Hotels",
            "region": "Global",
            "viewOrder": 0,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 337,
          "venueCategoryId": 5,
          "venueId": 345,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 5,
            "title": "Romantic Getaways",
            "region": "Global",
            "viewOrder": 20,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        }
      ]
    },
    {
      "venueId": 304,
      "venueCategoryId": 26,
      "status": 1,
      "title": "Cheval Blanc Randheli",
      "description": "Conceived by architect Jean-Michel Gathy, Cheval Blanc Randheli is an invitation to experience French “Art de Recevoir”. In a preserved environment, forty minutes north of Malé by seaplane, pristine waters and opulent vegetation offer Cheval Blanc Randheli an exceptional setting.\n\nThe Maison has been conceived in synergy with the island’s natural surroundings, lush vegetation and lagoon views. Contemporary yet indigenous, graceful and understated, it is the perfect balance of modern architecture and traditional inspirations. The graphic architecture is complemented by stylish interior design furnished with materials from the Indian Ocean and natural textures – rattan, bamboo, mother-of-pearl, coral or shagreen - enhanced by a vivid palette of colours with splashes of pop yellow and green.",
      "brand": "Cheval Blanc",
      "blockQuote": "The bar is set high with elegant and spacious private villas and service that goes above and beyond.",
      "emailAddress": "info.randheli@chevalblanc.com",
      "website": "randheli.chevalblanc.com",
      "bookingURL": "https://be.synxis.com/?adult=2&agencyid=96027890&arrive=2021-07-08&chain=23061&child=0&currency=USD&depart=2021-07-09&filter=&hotel=1344&level=hotel&locale=en-US&rooms=1",
      "redirectNotes": "Cheval Blanc Randheli's reservations system",
      "phoneNumber": "+960 656 1515",
      "addressOne": "",
      "addressTwo": "",
      "city": "Noonu Atoll",
      "county": "",
      "region": "",
      "country": "Maldives",
      "rooms": 45,
      "dollar": 3,
      "updated": "2021-07-08T11:31:43.71",
      "created": "2020-05-06T12:20:00",
      "medias": [
        {
          "mediaId": 43106,
          "title": "Cheval Blanc randheli maldives water villas aerial - The Luxe ...",
          "url": "https://pkslist.azureedge.net/media/images/1/fa27a54a-4e5f-47b8-8190-4a317e851871/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/fa27a54a-4e5f-47b8-8190-4a317e851871/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/fa27a54a-4e5f-47b8-8190-4a317e851871/256x192.jpg",
          "viewOrder": 0
        },
        {
          "mediaId": 1167,
          "title": "Cheval Blanc Randheli, Maldive | Traveller Made",
          "url": "https://pkslist.azureedge.net/media/images/1/599712cb-ca45-4e7f-9717-f219f8b5790f/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/599712cb-ca45-4e7f-9717-f219f8b5790f/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/599712cb-ca45-4e7f-9717-f219f8b5790f/256x192.jpg",
          "viewOrder": 2
        },
        {
          "mediaId": 43108,
          "title": "Cheval Blanc Randheli, Noonu Atoll, Maldives - Luxury Hotel ...",
          "url": "https://pkslist.azureedge.net/media/images/1/17e7ff61-315d-41ae-ad7c-2abe89742da8/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/17e7ff61-315d-41ae-ad7c-2abe89742da8/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/17e7ff61-315d-41ae-ad7c-2abe89742da8/256x192.jpg",
          "viewOrder": 3
        },
        {
          "mediaId": 43109,
          "title": "Cheval Blanc Randheli - Hotels in Heaven",
          "url": "https://pkslist.azureedge.net/media/images/1/c88e15ee-04fc-4ff6-86e6-c05510bd76d0/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/c88e15ee-04fc-4ff6-86e6-c05510bd76d0/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/c88e15ee-04fc-4ff6-86e6-c05510bd76d0/256x192.jpg",
          "viewOrder": 4
        },
        {
          "mediaId": 1169,
          "title": "Spa Cheval Blanc",
          "url": "https://pkslist.azureedge.net/media/images/1/6dcf639b-37c7-45fd-867d-db8e13d8123f/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/6dcf639b-37c7-45fd-867d-db8e13d8123f/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/6dcf639b-37c7-45fd-867d-db8e13d8123f/256x192.jpg",
          "viewOrder": 6
        },
        {
          "mediaId": 43102,
          "title": "Cheval Blanc Randheli, Noonu Atoll, Maldives | Elite Traveler ...",
          "url": "https://pkslist.azureedge.net/media/images/1/96ffaec9-14dc-4ad8-bf00-e388895becc7/1200x900.png",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/96ffaec9-14dc-4ad8-bf00-e388895becc7/800x600.png",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/96ffaec9-14dc-4ad8-bf00-e388895becc7/256x192.png",
          "viewOrder": 7
        },
        {
          "mediaId": 43101,
          "title": "Review: Cheval Blanc Randheli, Maldives",
          "url": "https://pkslist.azureedge.net/media/images/1/6542e845-2d81-4464-948c-218fa3791a91/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/6542e845-2d81-4464-948c-218fa3791a91/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/6542e845-2d81-4464-948c-218fa3791a91/256x192.jpg",
          "viewOrder": 8
        },
        {
          "mediaId": 43103,
          "title": "Cheval Blanc Randheli | apaiser bathware | Archello",
          "url": "https://pkslist.azureedge.net/media/images/1/9e901b38-f5d2-4858-b27b-6d545a9b041d/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/9e901b38-f5d2-4858-b27b-6d545a9b041d/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/9e901b38-f5d2-4858-b27b-6d545a9b041d/256x192.jpg",
          "viewOrder": 9
        },
        {
          "mediaId": 43104,
          "title": "Cheval Blanc Randheli | Best Hotels In Maldives",
          "url": "https://pkslist.azureedge.net/media/images/1/a35c4c35-a8c1-4864-b3e3-8987014d6b2c/1200x900.png",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/a35c4c35-a8c1-4864-b3e3-8987014d6b2c/800x600.png",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/a35c4c35-a8c1-4864-b3e3-8987014d6b2c/256x192.png",
          "viewOrder": 10
        },
        {
          "mediaId": 1168,
          "title": "water-villas-galerie-1-4-812a- ...",
          "url": "https://pkslist.azureedge.net/media/images/1/ae0b1b33-e07a-4a89-a1a6-ca0db16a48a3/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/ae0b1b33-e07a-4a89-a1a6-ca0db16a48a3/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/ae0b1b33-e07a-4a89-a1a6-ca0db16a48a3/256x192.jpg",
          "viewOrder": 12
        },
        {
          "mediaId": 1166,
          "title": "Cheval Blanc Randheli | Maldives",
          "url": "https://pkslist.azureedge.net/media/images/1/64a15e53-897f-4a9d-ba7f-614d9ba99969/1200x900.jpg",
          "url_Medium": "https://pkslist.azureedge.net/media/images/1/64a15e53-897f-4a9d-ba7f-614d9ba99969/800x600.jpg",
          "url_Low": "https://pkslist.azureedge.net/media/images/1/64a15e53-897f-4a9d-ba7f-614d9ba99969/256x192.jpg",
          "viewOrder": 13
        }
      ],
      "geographicArea": {
        "geographicAreaId": 165,
        "latitude": 5.691805,
        "longitude": 73.34106899999999,
        "parentGeographicAreaId": 0,
        "title": "Noonu Atoll Republic of Maldives, Maldives",
        "updated": "2020-05-06T12:20:00",
        "created": "2020-05-06T12:20:00"
      },
      "personalities": [
        {
          "tagId": 51,
          "tagName": "Style Icon",
          "tagType": 1
        },
        {
          "tagId": 157,
          "tagName": "Beach Escape",
          "tagType": 1
        }
      ],
      "tags": [
        {
          "tagId": 4,
          "tagName": "Spa",
          "tagType": 2
        },
        {
          "tagId": 33,
          "tagName": "Luxury",
          "tagType": 2
        },
        {
          "tagId": 58,
          "tagName": "Beach",
          "tagType": 2
        },
        {
          "tagId": 70,
          "tagName": "Families",
          "tagType": 2
        },
        {
          "tagId": 71,
          "tagName": "Group of Friends",
          "tagType": 2
        },
        {
          "tagId": 78,
          "tagName": "Fine Dining",
          "tagType": 2
        },
        {
          "tagId": 87,
          "tagName": "Outdoor Pool",
          "tagType": 2
        },
        {
          "tagId": 90,
          "tagName": "Water Sports",
          "tagType": 2
        },
        {
          "tagId": 115,
          "tagName": "Snorkeling",
          "tagType": 2
        },
        {
          "tagId": 116,
          "tagName": "Scuba Diving",
          "tagType": 2
        }
      ],
      "venueCategoryAssigns": [
        {
          "venueCategoryAssignId": 40,
          "venueCategoryId": 20,
          "venueId": 304,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 20,
            "title": "BucketList Hotels",
            "region": "Global",
            "viewOrder": 0,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 333,
          "venueCategoryId": 19,
          "venueId": 304,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 19,
            "title": "Private Islands",
            "region": "Global",
            "viewOrder": 15,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 334,
          "venueCategoryId": 15,
          "venueId": 304,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 15,
            "title": "Beach Paradise",
            "region": "Global",
            "viewOrder": 6,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        },
        {
          "venueCategoryAssignId": 475,
          "venueCategoryId": 26,
          "venueId": 304,
          "venue": null,
          "venueCategory": {
            "venueCategoryId": 26,
            "title": "Maldives Top 10",
            "region": "Indian Ocean",
            "viewOrder": 7,
            "updated": "2020-05-06T12:20:00",
            "created": "2020-05-06T12:20:00",
            "venueCategoryAssign": [
              
            ]
          }
        }
      ]
    }
  ]
// 20240123100509
// https://app.pks-list.com/api/Venue/v1/getlist/35

export const placesStore4=
[
  {
    "venueId": 3689,
    "venueCategoryId": 0,
    "status": 1,
    "title": "The Peninsula London",
    "description": "Superbly situated alongside Hyde Park Corner and Wellington Arch, The Peninsula London occupies one of the city’s most prestigious addresses. Newly built and impeccably designed to harmonise with the surrounding heritage buildings, the hotel is just steps away from Kensington Gardens, and other iconic attractions.",
    "brand": "Peninsula",
    "blockQuote": "Indulge with a traditional Peninsula Afternoon Tea accompanied by live music.",
    "emailAddress": "reservationpln@peninsula.com",
    "website": "peninsula.com/en/london/5-star-luxury-hotel-belgravia",
    "bookingURL": "https://secure.peninsula.com/?_ga=2.68933841.1590874037.1692773399-1821390654.1692773399&adult=2&agencyid=96027890&arrive=2023-09-12&chain=5440&child=0&currency=GBP&depart=2023-09-13&hotel=38446&level=hotel&locale=en-GB&rooms=1",
    "redirectNotes": "The Peninsula London's reservations system",
    "phoneNumber": "+44 20 3959 2888",
    "addressOne": "1",
    "addressTwo": "Grosvenor Place",
    "city": "London",
    "county": "",
    "region": "England",
    "country": "United Kingdom",
    "rooms": 190,
    "dollar": 3,
    "updated": "2023-08-23T09:14:48.85",
    "created": "2023-08-23T08:46:17.367",
    "medias": [
      {
        "mediaId": 59283,
        "title": "The Peninsula London Debuts, Bringing Spectacular New Style and ...",
        "url": "https://pkslist.azureedge.net/media/images/1/8b7e1ad3-2f08-487f-9f9b-d318450e7c73/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8b7e1ad3-2f08-487f-9f9b-d318450e7c73/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8b7e1ad3-2f08-487f-9f9b-d318450e7c73/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 59270,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b8fdd9a1-20d8-4cca-837d-8601ff16c0e9/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b8fdd9a1-20d8-4cca-837d-8601ff16c0e9/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b8fdd9a1-20d8-4cca-837d-8601ff16c0e9/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 59278,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/fe22ebd0-9e26-4dac-895c-6626e192b829/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fe22ebd0-9e26-4dac-895c-6626e192b829/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fe22ebd0-9e26-4dac-895c-6626e192b829/256x192.png",
        "viewOrder": 2
      },
      {
        "mediaId": 59286,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a0daad37-be51-4681-893e-dc92c24a3b63/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a0daad37-be51-4681-893e-dc92c24a3b63/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a0daad37-be51-4681-893e-dc92c24a3b63/256x192.png",
        "viewOrder": 3
      },
      {
        "mediaId": 59277,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f740ce39-358c-472f-8cd6-dfa991f578e1/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f740ce39-358c-472f-8cd6-dfa991f578e1/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f740ce39-358c-472f-8cd6-dfa991f578e1/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 59272,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d4cd9641-f1c4-4c80-82d8-2dc204870884/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d4cd9641-f1c4-4c80-82d8-2dc204870884/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d4cd9641-f1c4-4c80-82d8-2dc204870884/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 59267,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/88285e2e-5327-45e4-a362-4d8b84e31a01/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/88285e2e-5327-45e4-a362-4d8b84e31a01/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/88285e2e-5327-45e4-a362-4d8b84e31a01/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 59269,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/faba7365-86de-4563-ae83-041c5cce4b5f/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/faba7365-86de-4563-ae83-041c5cce4b5f/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/faba7365-86de-4563-ae83-041c5cce4b5f/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 59275,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/312ac237-2cb3-4c48-a3ce-b1178d17dc09/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/312ac237-2cb3-4c48-a3ce-b1178d17dc09/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/312ac237-2cb3-4c48-a3ce-b1178d17dc09/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 59284,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/356f71e3-89a7-4353-8274-112c583a65e2/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/356f71e3-89a7-4353-8274-112c583a65e2/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/356f71e3-89a7-4353-8274-112c583a65e2/256x192.png",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3470,
      "latitude": 51.5015896,
      "longitude": -0.1513984,
      "parentGeographicAreaId": 0,
      "title": "1 Grosvenor Pl, London SW1X 7HJ, UK",
      "updated": null,
      "created": "2023-08-23T08:53:00"
    },
    "personalities": [
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      },
      {
        "tagId": 55,
        "tagName": "Lively",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 105,
        "tagName": "Afternoon Tea",
        "tagType": 2
      },
      {
        "tagId": 222,
        "tagName": "Michelin Star dining",
        "tagType": 2
      },
      {
        "tagId": 297,
        "tagName": "Rooftop Restaurant",
        "tagType": 2
      },
      {
        "tagId": 323,
        "tagName": "City Walk",
        "tagType": 2
      },
      {
        "tagId": 336,
        "tagName": "Ballroom",
        "tagType": 2
      },
      {
        "tagId": 404,
        "tagName": "Sightseeing",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 826,
        "venueCategoryId": 35,
        "venueId": 3689,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 831,
        "venueCategoryId": 4,
        "venueId": 3689,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 4,
          "title": "London Top 10 ",
          "region": "Europe",
          "viewOrder": 10,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3371,
    "venueCategoryId": 0,
    "status": 1,
    "title": "The Carlton Tower Jumeirah",
    "description": "A beacon of British style and sophistication, The Carlton Tower Jumeirah is a renowned five star hotel in central London. Stay with us and enjoy stunning views of the London skyline, access to private gardens and tennis courts and one of London's most exclusive health clubs, The Peak Fitness Club & Spa. Located on Sloane Street in the heart of Knightsbridge, this luxury hotel is one of the best hotels in London and boasts 186 guest rooms including 88 suites. vvHome to The Peak Fitness Club & Spa, you can enjoy a relaxing spa treatment, invigorating workout in the gym or swim in our 20 metre indoor pool. Combined with a selection of restaurants and elegant bars, The Carlton Tower Jumeirah is the ideal destination for your next London city break.",
    "brand": "",
    "blockQuote": "Book the award-winning Chinoiserie Lounge for Afternoon Tea.",
    "emailAddress": "ctjinfo@jumeirah.com",
    "website": "jumeirah.com/en/stay/london/the-carlton-tower",
    "bookingURL": "https://www.jumeirah.com/en/booking/hotel-booking?hotelItemId=54293bc6-5263-48fb-bcc9-7e8f5b617104&rooms%5B0%5D%5Badults%5D=2&rooms%5B0%5D%5Bchildrens%5D=0&rooms%5B0%5D%5Binfants%5D=0&iata=96027890&bookingWithPoints=false",
    "redirectNotes": "The Carlton Tower Jumeirah's reservations system",
    "phoneNumber": "+44 20 7235 1234",
    "addressOne": "1",
    "addressTwo": "Cadogan Place",
    "city": "London",
    "county": "",
    "region": "England",
    "country": "United Kingdom",
    "rooms": 186,
    "dollar": 3,
    "updated": "2022-06-14T07:58:24.773",
    "created": "2022-02-22T15:59:30.413",
    "medias": [
      {
        "mediaId": 52716,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a4a33a63-fc5a-41d4-abc1-55da77d18736/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a4a33a63-fc5a-41d4-abc1-55da77d18736/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a4a33a63-fc5a-41d4-abc1-55da77d18736/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 52714,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/9678b255-c6ae-4132-aa96-f7e21a72afe8/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9678b255-c6ae-4132-aa96-f7e21a72afe8/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9678b255-c6ae-4132-aa96-f7e21a72afe8/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 52721,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/45ae008e-5cb4-4eb8-9356-206d7cbce674/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/45ae008e-5cb4-4eb8-9356-206d7cbce674/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/45ae008e-5cb4-4eb8-9356-206d7cbce674/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 52715,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c8047b97-83eb-4931-813b-dd651c723c1e/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c8047b97-83eb-4931-813b-dd651c723c1e/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c8047b97-83eb-4931-813b-dd651c723c1e/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 52718,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/43ca723e-15c1-4db5-97c5-fc65cfb4a9a8/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/43ca723e-15c1-4db5-97c5-fc65cfb4a9a8/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/43ca723e-15c1-4db5-97c5-fc65cfb4a9a8/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 52717,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/69a6fe10-829e-48c2-a23b-cb9ba10e38c4/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/69a6fe10-829e-48c2-a23b-cb9ba10e38c4/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/69a6fe10-829e-48c2-a23b-cb9ba10e38c4/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 53645,
        "title": "LONDON'S BEST HOTEL SUITES: Jumeirah Carlton Tower - Diplomat Magazine",
        "url": "https://pkslist.azureedge.net/media/images/1/846802f5-c119-41cb-abf9-899aa73bd6da/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/846802f5-c119-41cb-abf9-899aa73bd6da/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/846802f5-c119-41cb-abf9-899aa73bd6da/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 52713,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/106e7acd-5183-42ba-bc65-0e81d8d2f26c/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/106e7acd-5183-42ba-bc65-0e81d8d2f26c/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/106e7acd-5183-42ba-bc65-0e81d8d2f26c/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 52725,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/941726b3-a1ca-4ad0-bede-b7b1217331af/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/941726b3-a1ca-4ad0-bede-b7b1217331af/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/941726b3-a1ca-4ad0-bede-b7b1217331af/256x192.jpeg",
        "viewOrder": 9
      },
      {
        "mediaId": 52720,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b845e5b0-2f82-4044-8198-d5cdf646e811/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b845e5b0-2f82-4044-8198-d5cdf646e811/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b845e5b0-2f82-4044-8198-d5cdf646e811/256x192.jpeg",
        "viewOrder": 10
      },
      {
        "mediaId": 52722,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/391294d1-b80c-4848-863d-f5f68be04223/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/391294d1-b80c-4848-863d-f5f68be04223/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/391294d1-b80c-4848-863d-f5f68be04223/256x192.jpeg",
        "viewOrder": 11
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3159,
      "latitude": 51.4986384,
      "longitude": -0.1584378,
      "parentGeographicAreaId": 0,
      "title": "1 Cadogan Pl, London SW1X 9PY, UK",
      "updated": null,
      "created": "2022-02-22T16:03:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 62,
        "tagName": "Nightlife",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 68,
        "tagName": "Business",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 105,
        "tagName": "Afternoon Tea",
        "tagType": 2
      },
      {
        "tagId": 172,
        "tagName": "Biking",
        "tagType": 2
      },
      {
        "tagId": 216,
        "tagName": "Museum",
        "tagType": 2
      },
      {
        "tagId": 276,
        "tagName": "Gym",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 323,
        "tagName": "City Walk",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 829,
        "venueCategoryId": 4,
        "venueId": 3371,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 4,
          "title": "London Top 10 ",
          "region": "Europe",
          "viewOrder": 10,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2849,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Belmond Cadogan Hotel",
    "description": "If walls could talk, ours would have some captivating stories to tell. Our address has long been a playground for socialites and bohemians like Oscar Wilde and Lillie Langtry. Today we celebrate our past and heritage while offering modern, understated luxury. From the world-class restaurants and tranquil garden to quirky design details and in-room libraries, this is London living perfected.",
    "brand": "Belmond",
    "blockQuote": "Afternoon tea by Adam Handling is a true English delight.",
    "emailAddress": "reservations.cad@belmond.com",
    "website": "belmond.com/hotels/europe/uk/london/belmond-cadogan-hotel/",
    "bookingURL": "https://www.belmond.com/reservations/hotel-loading?productId=CAD&checkin=20201021&checkout=20201022&occupancy=2-0&pdadults=02&iatacode=96027890",
    "redirectNotes": "Belmond Cadogan Hotel's reservations system",
    "phoneNumber": "+44 20 7048 7141",
    "addressOne": "75",
    "addressTwo": "Sloane Street",
    "city": "London",
    "county": "",
    "region": "England",
    "country": "United Kingdom",
    "rooms": 54,
    "dollar": 3,
    "updated": "2020-10-19T23:29:57.537",
    "created": "2020-09-25T20:48:22.9",
    "medias": [
      {
        "mediaId": 39879,
        "title": "Made In Chelsea | PORTFOLIO Magazine",
        "url": "https://pkslist.azureedge.net/media/images/1/5a5981f5-ab6e-4281-8482-e86306737e42/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5a5981f5-ab6e-4281-8482-e86306737e42/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5a5981f5-ab6e-4281-8482-e86306737e42/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 39876,
        "title": "Belmond Cadogan Hotel, London, United Kingdonm - Hotel Review ...",
        "url": "https://pkslist.azureedge.net/media/images/1/575c0c2c-2aa2-48ca-8ad4-5cd98add69bd/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/575c0c2c-2aa2-48ca-8ad4-5cd98add69bd/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/575c0c2c-2aa2-48ca-8ad4-5cd98add69bd/256x192.png",
        "viewOrder": 1
      },
      {
        "mediaId": 39867,
        "title": "Belmond Cadogan Hotel Review: What To REALLY Expect If You Stay",
        "url": "https://pkslist.azureedge.net/media/images/1/30752a3b-332d-4210-a77b-14574f95f39d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/30752a3b-332d-4210-a77b-14574f95f39d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/30752a3b-332d-4210-a77b-14574f95f39d/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 39877,
        "title": "Belmond Cadogan Hotel, London, UK - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/3b528f91-5973-47db-91cc-9220609c645a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3b528f91-5973-47db-91cc-9220609c645a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3b528f91-5973-47db-91cc-9220609c645a/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 39870,
        "title": "Belmond Cadogan Hotel — London, UK | Wallpaper*",
        "url": "https://pkslist.azureedge.net/media/images/1/e2be4acb-c619-4f92-a7ca-690fdfbb6675/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e2be4acb-c619-4f92-a7ca-690fdfbb6675/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e2be4acb-c619-4f92-a7ca-690fdfbb6675/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 39881,
        "title": "The hottest new London hotel openings of 2019 | London Evening ...",
        "url": "https://pkslist.azureedge.net/media/images/1/e7f14f85-defa-4b60-9061-f978b1cdd77f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e7f14f85-defa-4b60-9061-f978b1cdd77f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e7f14f85-defa-4b60-9061-f978b1cdd77f/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 39872,
        "title": "New Belmond Cadogan Hotel London Opening Offer: Guaranteed Suite ...",
        "url": "https://pkslist.azureedge.net/media/images/1/a1cb2a25-9628-4071-9dd3-7ffe77f588ca/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a1cb2a25-9628-4071-9dd3-7ffe77f588ca/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a1cb2a25-9628-4071-9dd3-7ffe77f588ca/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 39871,
        "title": "Oscar Wilde's favourite London hotel renovated - TRAVELANDY NEWS",
        "url": "https://pkslist.azureedge.net/media/images/1/f9821af6-7544-442f-ad7a-d10326157a46/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f9821af6-7544-442f-ad7a-d10326157a46/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f9821af6-7544-442f-ad7a-d10326157a46/256x192.jpg",
        "viewOrder": 7
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2635,
      "latitude": 51.49696979999999,
      "longitude": -0.1592796,
      "parentGeographicAreaId": 0,
      "title": "75 Sloane St, Chelsea, London SW1X 9SG, UK",
      "updated": null,
      "created": "2020-09-25T21:05:00"
    },
    "personalities": [
      {
        "tagId": 40,
        "tagName": "Boutique Bolthole ",
        "tagType": 1
      },
      {
        "tagId": 47,
        "tagName": "Intimate Luxury ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 88,
        "tagName": "Tennis",
        "tagType": 2
      },
      {
        "tagId": 105,
        "tagName": "Afternoon Tea",
        "tagType": 2
      },
      {
        "tagId": 199,
        "tagName": "Art Gallery",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 259,
        "tagName": "Picnic",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 404,
        "tagName": "Sightseeing",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 830,
        "venueCategoryId": 4,
        "venueId": 2849,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 4,
          "title": "London Top 10 ",
          "region": "Europe",
          "viewOrder": 10,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1266,
    "venueCategoryId": 4,
    "status": 1,
    "title": "Mandarin Oriental Hyde Park",
    "description": "With bustling Knightsbridge on its doorstep and leafy Hyde Park at its rear, Mandarin Oriental Hyde Park is London's most prestigious address. Blending sumptuous guestrooms with a world-leading spa and a stylish bar, it is the definitive destination for a luxury escape. With two award-winning restaurants including Bar Boulud, London and Dinner by Heston Blumenthal, and a new Afternoon Tea salon, The Rosebery Lounge, Mandarin Oriental Hyde Park stands at the culinary centre of the capital.",
    "brand": "Mandarin Oriental",
    "blockQuote": "Excellently located for exploring many of London’s most famous attractions.",
    "emailAddress": "MOLON-RESERVATIONS@MOHG.COM",
    "website": "mandarinoriental.com/london/hyde-park/luxury-hotel/presentation?htl=MOLON&eng=google&src=local",
    "bookingURL": "http://www.booking.com/Share-Dm1BfD?aid=925669",
    "redirectNotes": null,
    "phoneNumber": "+44 020 7235 2000",
    "addressOne": "66",
    "addressTwo": "Knightsbridge",
    "city": "London",
    "county": "",
    "region": "England",
    "country": "United Kingdom",
    "rooms": 198,
    "dollar": 3,
    "updated": "2020-05-15T14:12:30.91",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 13176,
        "title": "THE ART OF LUXURY AT MANDARIN ORIENTAL HYDE PARK, LONDON - Travel ...",
        "url": "https://pkslist.azureedge.net/media/images/1/100fdda2-1ceb-43a5-9da0-c6fa296d75de/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/100fdda2-1ceb-43a5-9da0-c6fa296d75de/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/100fdda2-1ceb-43a5-9da0-c6fa296d75de/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 13178,
        "title": "Discovery: London Calling",
        "url": "https://pkslist.azureedge.net/media/images/1/c65b1638-6a4b-496c-9193-fc33d1b15e4a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c65b1638-6a4b-496c-9193-fc33d1b15e4a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c65b1638-6a4b-496c-9193-fc33d1b15e4a/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 13180,
        "title": "Mandarin Oriental Hyde Park, London unveils first phase of ...",
        "url": "https://pkslist.azureedge.net/media/images/1/25a449f4-f910-4139-8463-84787f98bd0d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/25a449f4-f910-4139-8463-84787f98bd0d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/25a449f4-f910-4139-8463-84787f98bd0d/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 13177,
        "title": "Mandarin Oriental Hyde Park, London unveils first phase of ...",
        "url": "https://pkslist.azureedge.net/media/images/1/a2b92311-3217-41f4-a31a-887dc2bb7d93/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a2b92311-3217-41f4-a31a-887dc2bb7d93/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a2b92311-3217-41f4-a31a-887dc2bb7d93/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 13182,
        "title": "Mandarin Oriental Hyde Park, London completes first phase of ...",
        "url": "https://pkslist.azureedge.net/media/images/1/0af0e4f0-c7ee-499c-80c6-edf602ba2645/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0af0e4f0-c7ee-499c-80c6-edf602ba2645/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0af0e4f0-c7ee-499c-80c6-edf602ba2645/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 13179,
        "title": "Mandarin Oriental Hyde Park, London unveils first phase of ...",
        "url": "https://pkslist.azureedge.net/media/images/1/c1eb24a6-d57c-4a36-b178-0c932a1a64ee/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c1eb24a6-d57c-4a36-b178-0c932a1a64ee/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c1eb24a6-d57c-4a36-b178-0c932a1a64ee/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 13181,
        "title": "Get fit at Mandarin Oriental - THE SPA MAN",
        "url": "https://pkslist.azureedge.net/media/images/1/cf93ddd0-6275-465a-b499-c31ef85809a5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cf93ddd0-6275-465a-b499-c31ef85809a5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cf93ddd0-6275-465a-b499-c31ef85809a5/256x192.jpg",
        "viewOrder": 6
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1092,
      "latitude": 51.502132,
      "longitude": -0.15999379999993835,
      "parentGeographicAreaId": 0,
      "title": "66 Knightsbridge, London SW1X 7LA, UK",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 48,
        "tagName": "Iconic/Landmark ",
        "tagType": 1
      },
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 60,
        "tagName": "Gastronomic",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 68,
        "tagName": "Business",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 78,
        "tagName": "Fine Dining",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 105,
        "tagName": "Afternoon Tea",
        "tagType": 2
      },
      {
        "tagId": 216,
        "tagName": "Museum",
        "tagType": 2
      },
      {
        "tagId": 235,
        "tagName": "Hot Tub",
        "tagType": 2
      },
      {
        "tagId": 276,
        "tagName": "Gym",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 232,
        "venueCategoryId": 4,
        "venueId": 1266,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 4,
          "title": "London Top 10 ",
          "region": "Europe",
          "viewOrder": 10,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 219,
    "venueCategoryId": 4,
    "status": 1,
    "title": "The Connaught",
    "description": "The Connaught, a luxury hotel in London’s chic Mayfair village, perfectly blends a discreet English ambience with the contemporary 5-star touches expected by the modern traveller.\n\nExpect breathtaking luxury hotel suites from some of the world’s best designers; Michelin-starred cuisine from Hélène Darroze; pampering and wellness at the luxury Aman Spa, and the perfect Martini, served in the restored splendour of the Connaught Bar.\n\nIt is all brought together by a team dedicated to ensuring a comfortable stay and anticipating your every whim as you relax in this sumptuous London residence. One of the most understated and highly personal 5-star hotels in London.",
    "brand": "",
    "blockQuote": "The award-winning Connaught Bar is the place to sip on cocktails created by some of the world’s greatest spirits alchemists.",
    "emailAddress": "info@the-connaught.co.uk",
    "website": "the-connaught.co.uk",
    "bookingURL": "http://www.booking.com/hotel/gb/the-connaught-mayfair.html?aid=925669",
    "redirectNotes": "",
    "phoneNumber": "+44 020 7499 7070",
    "addressOne": "",
    "addressTwo": "Carlos Place",
    "city": "London",
    "county": "",
    "region": "England",
    "country": "United Kingdom",
    "rooms": 121,
    "dollar": 3,
    "updated": "2020-05-21T13:29:52.4",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 25895,
        "title": "The Connaught | Mount Street Mayfair",
        "url": "https://pkslist.azureedge.net/media/images/1/6d9c129a-4a46-4da2-93c1-b7f82c4ce56c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6d9c129a-4a46-4da2-93c1-b7f82c4ce56c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6d9c129a-4a46-4da2-93c1-b7f82c4ce56c/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 18390,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/51285655-ebfa-41b4-981a-31946b571369/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/51285655-ebfa-41b4-981a-31946b571369/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/51285655-ebfa-41b4-981a-31946b571369/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 25901,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a98bc258-66ad-45c1-8092-1c22e84d487a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a98bc258-66ad-45c1-8092-1c22e84d487a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a98bc258-66ad-45c1-8092-1c22e84d487a/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 25897,
        "title": "Connaught, London | Traveller made",
        "url": "https://pkslist.azureedge.net/media/images/1/75a4fdd5-8e83-4af6-89c3-227dd628a779/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/75a4fdd5-8e83-4af6-89c3-227dd628a779/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/75a4fdd5-8e83-4af6-89c3-227dd628a779/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 25898,
        "title": "The Connaught, - Hotel Review - Condé Nast Traveler",
        "url": "https://pkslist.azureedge.net/media/images/1/3329fe9b-00ad-4096-95e9-5b6b52cae26b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3329fe9b-00ad-4096-95e9-5b6b52cae26b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3329fe9b-00ad-4096-95e9-5b6b52cae26b/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 25896,
        "title": "The Connaught Hotel Review, Mayfair, London | Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/78727f8d-6e91-4c27-ade2-9e959ada7d80/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/78727f8d-6e91-4c27-ade2-9e959ada7d80/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/78727f8d-6e91-4c27-ade2-9e959ada7d80/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 25902,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/1e2aa20c-9952-407a-97e5-eb5edc6a8848/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1e2aa20c-9952-407a-97e5-eb5edc6a8848/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1e2aa20c-9952-407a-97e5-eb5edc6a8848/256x192.jpg",
        "viewOrder": 7
      }
    ],
    "geographicArea": {
      "geographicAreaId": 215,
      "latitude": 51.5105345,
      "longitude": -0.1495542000000114,
      "parentGeographicAreaId": 0,
      "title": "Carlos Pl, Mayfair, London W1K, UK",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 42,
        "tagName": "Classic Traditionalist ",
        "tagType": 1
      },
      {
        "tagId": 46,
        "tagName": "Formal Luxury ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 105,
        "tagName": "Afternoon Tea",
        "tagType": 2
      },
      {
        "tagId": 183,
        "tagName": "Butler Service",
        "tagType": 2
      },
      {
        "tagId": 199,
        "tagName": "Art Gallery",
        "tagType": 2
      },
      {
        "tagId": 222,
        "tagName": "Michelin Star dining",
        "tagType": 2
      },
      {
        "tagId": 293,
        "tagName": "Meeting Space",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 14,
        "venueCategoryId": 4,
        "venueId": 219,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 4,
          "title": "London Top 10 ",
          "region": "Europe",
          "viewOrder": 10,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 218,
    "venueCategoryId": 4,
    "status": 1,
    "title": "The Berkeley",
    "description": "The Berkeley, in fashionable Knightsbridge, is one of the leading 5-star hotels in London: luxurious, welcoming, ready to meet the needs of the modern traveller. \n\nBreathtaking luxury hotel suites fuse comfort, stylish design and the latest innovations; Marcus Wareing offers two Michelin-starred dining; and a rooftop pool and luxury spa provide a retreat overlooking Hyde Park. The stars come out for award-winning cocktails at the Blue Bar, and fashionistas join us for Prêt-à-Portea, our catwalk-inspired London afternoon tea.\n\nThis relaxed, contemporary 5-star luxury is all within easy reach of Harrods, Harvey Nichols and the very best attractions that London has to offer.",
    "brand": "",
    "blockQuote": "A stay at the Berkeley wouldn't be complete without afternoon tea at the award-winning fashion themed  Collins Room.",
    "emailAddress": "info@the-berkeley.co.uk",
    "website": "the-berkeley.co.uk/",
    "bookingURL": "http://www.booking.com/hotel/gb/the-berkeley.html?aid=925669",
    "redirectNotes": "",
    "phoneNumber": "+44 020 7235 6000",
    "addressOne": "Wilton Place",
    "addressTwo": "SW1X 7RL",
    "city": "London",
    "county": "",
    "region": "England",
    "country": "United Kingdom",
    "rooms": 210,
    "dollar": 3,
    "updated": "2020-05-21T13:27:08.43",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 37167,
        "title": "André Fu's Pavilion Suites At The Berkeley Are The Biggest In ...",
        "url": "https://pkslist.azureedge.net/media/images/1/92227480-91a8-478c-bb92-82a3a3f903d8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/92227480-91a8-478c-bb92-82a3a3f903d8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/92227480-91a8-478c-bb92-82a3a3f903d8/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 37165,
        "title": "The Berkeley Hotel review: The creme-de-la-glam of Knightsbridge ...",
        "url": "https://pkslist.azureedge.net/media/images/1/f3b8b84c-cbe2-4e86-9cb4-227bc2a0d13c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f3b8b84c-cbe2-4e86-9cb4-227bc2a0d13c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f3b8b84c-cbe2-4e86-9cb4-227bc2a0d13c/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 21263,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c3d939fa-c266-45f5-92d0-6c3bb0a34e06/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c3d939fa-c266-45f5-92d0-6c3bb0a34e06/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c3d939fa-c266-45f5-92d0-6c3bb0a34e06/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 37164,
        "title": "The Berkeley Hotel Review, London | Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/806e0812-e352-4eff-888f-c3a20cf4f444/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/806e0812-e352-4eff-888f-c3a20cf4f444/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/806e0812-e352-4eff-888f-c3a20cf4f444/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 423,
        "title": "THE BERKELEY HOTEL LONDON 5* (United Kingdom) - from US$ 687 | BOOKED",
        "url": "https://pkslist.azureedge.net/media/images/1/106e3335-6ced-4a63-a0da-575be08bb0d0/1200x900.JPEG",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/106e3335-6ced-4a63-a0da-575be08bb0d0/800x600.JPEG",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/106e3335-6ced-4a63-a0da-575be08bb0d0/256x192.JPEG",
        "viewOrder": 5
      },
      {
        "mediaId": 37166,
        "title": "The Berkeley Hotel London, UK - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/e42e36d5-e2b8-4b3f-af3b-8f4980a1dcbc/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e42e36d5-e2b8-4b3f-af3b-8f4980a1dcbc/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e42e36d5-e2b8-4b3f-af3b-8f4980a1dcbc/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 21265,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/04634317-1785-44df-abef-58e6af6808d2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/04634317-1785-44df-abef-58e6af6808d2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/04634317-1785-44df-abef-58e6af6808d2/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 21269,
        "title": "The Berkeley, London | Traveller Made",
        "url": "https://pkslist.azureedge.net/media/images/1/998fd7fb-428c-4f89-be87-fb76b1abba6e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/998fd7fb-428c-4f89-be87-fb76b1abba6e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/998fd7fb-428c-4f89-be87-fb76b1abba6e/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 21270,
        "title": "The Berkeley London, England, United Kingdom - Condé Nast Traveler",
        "url": "https://pkslist.azureedge.net/media/images/1/19d2716b-e517-471c-9039-db05f54d07df/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/19d2716b-e517-471c-9039-db05f54d07df/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/19d2716b-e517-471c-9039-db05f54d07df/256x192.jpg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 89,
      "latitude": 51.5015562,
      "longitude": -0.15636719999997695,
      "parentGeographicAreaId": 0,
      "title": "Wilton Pl, London SW1X 8RL, UK",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 48,
        "tagName": "Iconic/Landmark ",
        "tagType": 1
      },
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 68,
        "tagName": "Business",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 100,
        "tagName": "Wedding",
        "tagType": 2
      },
      {
        "tagId": 105,
        "tagName": "Afternoon Tea",
        "tagType": 2
      },
      {
        "tagId": 154,
        "tagName": "Rooftop Pool",
        "tagType": 2
      },
      {
        "tagId": 197,
        "tagName": "Café",
        "tagType": 2
      },
      {
        "tagId": 222,
        "tagName": "Michelin Star dining",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 294,
        "tagName": "Event Space",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 13,
        "venueCategoryId": 4,
        "venueId": 218,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 4,
          "title": "London Top 10 ",
          "region": "Europe",
          "viewOrder": 10,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 214,
    "venueCategoryId": 4,
    "status": 1,
    "title": "Claridge's",
    "description": "Set in the heart of London’s Mayfair, Claridge’s is the epitome of timeless elegance, and considered to be one of the best 5-star luxury hotels in the world.\n\nClaridge’s is proud to have preserved both the physical and spiritual aspects of its unique heritage whilst adding all the modern luxury flourishes a guest could wish for, all bound together by impeccable service.\n\nExpect breathtaking luxury hotel suites from some of the world’s best designers; fabulous cuisine at Fera at Claridge’s; the best afternoon tea in London served in the glamorous Foyer & Reading Room; champagne and cocktails with style in Claridge’s Bar; and a nightcap as you sit comfortably ensconced in the soft velvet of the Fumoir bar.",
    "brand": "",
    "blockQuote": "Their decor is on point,  the food and service is exceptional and afternoon tea here is unforgettable.",
    "emailAddress": "reservations@claridges.co.uk",
    "website": "claridges.co.uk",
    "bookingURL": "http://www.booking.com/hotel/gb/claridge-s.html?aid=925669",
    "redirectNotes": "",
    "phoneNumber": "+44 020 7107 8862",
    "addressOne": "",
    "addressTwo": "Brook Street",
    "city": "London",
    "county": "",
    "region": "England",
    "country": "United Kingdom",
    "rooms": 203,
    "dollar": 3,
    "updated": "2020-05-21T12:36:36.61",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 37107,
        "title": "Claridges Hotel, London, UK - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/5cc7c3ea-fc24-4580-adaa-1d14972e679d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5cc7c3ea-fc24-4580-adaa-1d14972e679d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5cc7c3ea-fc24-4580-adaa-1d14972e679d/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 37114,
        "title": "Claridge's, London |Traveller Made",
        "url": "https://pkslist.azureedge.net/media/images/1/8144394b-2ab8-4046-8de2-8bba15b99596/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8144394b-2ab8-4046-8de2-8bba15b99596/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8144394b-2ab8-4046-8de2-8bba15b99596/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 37116,
        "title": "The Foyer At Claridge's, London - Mayfair - Restaurant Reviews ...",
        "url": "https://pkslist.azureedge.net/media/images/1/ffa6c129-5a81-42d4-bcfb-2cbbd1f5e36f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ffa6c129-5a81-42d4-bcfb-2cbbd1f5e36f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ffa6c129-5a81-42d4-bcfb-2cbbd1f5e36f/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 37112,
        "title": "FERA AT CLARIDGE'S, LONDON | Dernier & Hamlyn",
        "url": "https://pkslist.azureedge.net/media/images/1/06bedb34-aaf1-403a-9bc5-797e098ca735/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/06bedb34-aaf1-403a-9bc5-797e098ca735/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/06bedb34-aaf1-403a-9bc5-797e098ca735/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 37122,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ea10bf03-d590-461c-99e1-cd84f8657ebd/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ea10bf03-d590-461c-99e1-cd84f8657ebd/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ea10bf03-d590-461c-99e1-cd84f8657ebd/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 37113,
        "title": "Claridge's, London, United Kingdom - Hotel Review - Condé Nast ...",
        "url": "https://pkslist.azureedge.net/media/images/1/ab03e19a-0550-4321-8839-4e5b21502208/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ab03e19a-0550-4321-8839-4e5b21502208/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ab03e19a-0550-4321-8839-4e5b21502208/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 37119,
        "title": "Claridges Hotel, London, UK - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/d4854571-eaea-4abc-994c-741fd56fe688/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d4854571-eaea-4abc-994c-741fd56fe688/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d4854571-eaea-4abc-994c-741fd56fe688/256x192.jpg",
        "viewOrder": 6
      }
    ],
    "geographicArea": {
      "geographicAreaId": 85,
      "latitude": 51.5124781,
      "longitude": -0.14788909999992939,
      "parentGeographicAreaId": 0,
      "title": "Brook St, Mayfair, London W1K 4HR, UK",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 46,
        "tagName": "Formal Luxury ",
        "tagType": 1
      },
      {
        "tagId": 48,
        "tagName": "Iconic/Landmark ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 39,
        "tagName": "City",
        "tagType": 2
      },
      {
        "tagId": 59,
        "tagName": "Cultural",
        "tagType": 2
      },
      {
        "tagId": 62,
        "tagName": "Nightlife",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 68,
        "tagName": "Business",
        "tagType": 2
      },
      {
        "tagId": 69,
        "tagName": "Couples",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 78,
        "tagName": "Fine Dining",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 93,
        "tagName": "Private",
        "tagType": 2
      },
      {
        "tagId": 100,
        "tagName": "Wedding",
        "tagType": 2
      },
      {
        "tagId": 105,
        "tagName": "Afternoon Tea",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 12,
        "venueCategoryId": 4,
        "venueId": 214,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 4,
          "title": "London Top 10 ",
          "region": "Europe",
          "viewOrder": 10,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 212,
    "venueCategoryId": 4,
    "status": 1,
    "title": "Hotel Cafe Royal",
    "description": "This luxury hotel in London enjoys an unrivalled setting, where the elegance of Mayfair, energy of Soho and sophistication of St. James combine. . That's one reason why, for 150 years, people who make a difference have made their way through these doors. For Oscar Wilde and Muhammad Ali, Winston Churchill and David Bowie, David Chipperfield and Albert Adrià, Hotel Café Royal has always been the vibrant living room of London, the place to convene, converse and celebrate.",
    "brand": "",
    "blockQuote": "The stunning Oscar Wilde Bar featuring ornate Louis XVI decor is THE place for afternoon tea or a glass of champagne.",
    "emailAddress": "enquiries@hotelcaferoyal.com",
    "website": "hotelcaferoyal.com",
    "bookingURL": "https://be.synxis.com/?_ga=2.97995421.32280393.1598886315-1574477414.1598886315&adult=2&agencyid=96027890&arrive=2020-09-01&chain=24447&child=0&currency=GBP&depart=2020-09-02&hotel=6706&level=hotel&locale=en-US&rooms=1&src=30https://be.synxis.com/?_ga=2.97995421.32280393.1598886315-1574477414.1598886315&adult=2&agencyid=96027890&arrive=2020-09-01&chain=24447&child=0&currency=GBP&depart=2020-09-02&hotel=6706&level=hotel&locale=en-US&rooms=1&src=30",
    "redirectNotes": "Hotel Cafe Royal's reservations system",
    "phoneNumber": "+44 020 7406 3333",
    "addressOne": "68 Regent Street",
    "addressTwo": "Mayfair",
    "city": "London",
    "county": "",
    "region": "England",
    "country": "United Kingdom",
    "rooms": 160,
    "dollar": 3,
    "updated": "2020-09-01T09:01:08.837",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 37136,
        "title": "Hotel Hotel Cafe Royal, London - trivago.com",
        "url": "https://pkslist.azureedge.net/media/images/1/d9d15ee2-f40b-44c0-a0d2-aa5893f280d9/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d9d15ee2-f40b-44c0-a0d2-aa5893f280d9/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d9d15ee2-f40b-44c0-a0d2-aa5893f280d9/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 37135,
        "title": "Luxury 5-Star Hotel in Soho, London W1B | Hotel Café Royal",
        "url": "https://pkslist.azureedge.net/media/images/1/8e91607b-84d2-4a1e-8228-44b0d7343791/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8e91607b-84d2-4a1e-8228-44b0d7343791/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8e91607b-84d2-4a1e-8228-44b0d7343791/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 38910,
        "title": "Luxury 5-Star Hotel in Soho, London W1B | Hotel Café Royal",
        "url": "https://pkslist.azureedge.net/media/images/1/b99f23ac-99e6-41fa-8d39-63974632e84a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b99f23ac-99e6-41fa-8d39-63974632e84a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b99f23ac-99e6-41fa-8d39-63974632e84a/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 37137,
        "title": "Oscar Wilde Lounge | Hotel Café Royal",
        "url": "https://pkslist.azureedge.net/media/images/1/5d5eb0c4-a036-436a-9057-d7e6b59fc11f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5d5eb0c4-a036-436a-9057-d7e6b59fc11f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5d5eb0c4-a036-436a-9057-d7e6b59fc11f/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 37138,
        "title": "Penthouse London | Hotel Café Royal",
        "url": "https://pkslist.azureedge.net/media/images/1/7f67772b-8eda-4450-9179-1e500a9688ea/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7f67772b-8eda-4450-9179-1e500a9688ea/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7f67772b-8eda-4450-9179-1e500a9688ea/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 37141,
        "title": "Hotel Café Royal London Review | PORTER",
        "url": "https://pkslist.azureedge.net/media/images/1/bb1c45b8-581a-4f08-bf9a-683e9d9b0b9f/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bb1c45b8-581a-4f08-bf9a-683e9d9b0b9f/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bb1c45b8-581a-4f08-bf9a-683e9d9b0b9f/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 37151,
        "title": "London's Hotel Café Royal: Tradition Meets Modern — The Caviar ...",
        "url": "https://pkslist.azureedge.net/media/images/1/9a0ccd90-b6ce-4d7f-85bb-6e3c2f07da65/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9a0ccd90-b6ce-4d7f-85bb-6e3c2f07da65/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9a0ccd90-b6ce-4d7f-85bb-6e3c2f07da65/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 350,
        "title": "Hotel Cafe Royal London - Where To Go",
        "url": "https://pkslist.azureedge.net/media/images/1/9fc0f107-f8b8-4eed-a020-54cdc1bd1185/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9fc0f107-f8b8-4eed-a020-54cdc1bd1185/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9fc0f107-f8b8-4eed-a020-54cdc1bd1185/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 37149,
        "title": "Departures exclusive offer | Hotel Cafe Royal",
        "url": "https://pkslist.azureedge.net/media/images/1/d876a6b2-07ab-4ab8-85cb-ea510a616b80/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d876a6b2-07ab-4ab8-85cb-ea510a616b80/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d876a6b2-07ab-4ab8-85cb-ea510a616b80/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 83,
      "latitude": 51.5100916,
      "longitude": -0.13594309999996312,
      "parentGeographicAreaId": 0,
      "title": "68 Regent St, Soho, London W1B 4DY, UK",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 48,
        "tagName": "Iconic/Landmark ",
        "tagType": 1
      },
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 105,
        "tagName": "Afternoon Tea",
        "tagType": 2
      },
      {
        "tagId": 128,
        "tagName": "Club Lounge",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 212,
        "tagName": "Hammam",
        "tagType": 2
      },
      {
        "tagId": 216,
        "tagName": "Museum",
        "tagType": 2
      },
      {
        "tagId": 276,
        "tagName": "Gym",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 299,
        "tagName": "Wellness Programs",
        "tagType": 2
      },
      {
        "tagId": 406,
        "tagName": "Champagne Bar",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 11,
        "venueCategoryId": 4,
        "venueId": 212,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 4,
          "title": "London Top 10 ",
          "region": "Europe",
          "viewOrder": 10,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 211,
    "venueCategoryId": 4,
    "status": 1,
    "title": "Bulgari Hotel London",
    "description": "Located in Knightsbridge on the edge of Hyde Park, Bulgari Hotel London is both a haven of calm in the centre of the city and yet under a minute’s walk from such landmarks as the famous Harrods department store. Since opening in 2012, Bulgari has set new standards among the luxury hotels of the British capital. Elegant contemporary architecture and Bulgari’s legendary flair for design are matched by class-leading quality of service.\n\nDesign, service, and exceptionally generously proportioned rooms and suites combined with unrivalled facilities such as a full gymnasium and physical training centre with on-site Workshop personal training team; 11 single treatment rooms and 1 private spa suite Spa; 25 metres, three-lane swimming pool; 47 seat cinema; unique Cigar Shop and sampling room, a stunning Ballroom; to deliver a city hotel experience that is unique in the world.",
    "brand": "Bulgari",
    "blockQuote": "An uber luxurious hotel that enjoys a top notch location in the heart of Knightsbridge.",
    "emailAddress": "london@bulgarihotels.co.uk",
    "website": "bulgarihotels.com/en_US/london",
    "bookingURL": "http://www.booking.com/hotel/gb/bulgari-hotels.html?aid=925669",
    "redirectNotes": "",
    "phoneNumber": "+44 207 151 1010",
    "addressOne": "",
    "addressTwo": "171 Knightsbridge",
    "city": "London",
    "county": "",
    "region": "England",
    "country": "United Kingdom",
    "rooms": 85,
    "dollar": 3,
    "updated": "2020-05-21T07:18:30.583",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 37010,
        "title": "Bulgari London Hotel Review, Knightsbridge, London | Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/37683cce-70ea-4632-9180-fa0a6053c000/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/37683cce-70ea-4632-9180-fa0a6053c000/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/37683cce-70ea-4632-9180-fa0a6053c000/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 37009,
        "title": "Bulgari Hotel, London, London – Updated 2020 Prices",
        "url": "https://pkslist.azureedge.net/media/images/1/853b8246-7628-47fb-8483-f7fa99d9ae01/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/853b8246-7628-47fb-8483-f7fa99d9ae01/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/853b8246-7628-47fb-8483-f7fa99d9ae01/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 37013,
        "title": "Shopaholic in London: Bulgari hotel - bar and restaurant",
        "url": "https://pkslist.azureedge.net/media/images/1/44adc722-7cc4-4b6c-99d8-e84a91323bb0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/44adc722-7cc4-4b6c-99d8-e84a91323bb0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/44adc722-7cc4-4b6c-99d8-e84a91323bb0/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 37008,
        "title": "Bulgari Hotel, London, London – Updated 2020 Prices",
        "url": "https://pkslist.azureedge.net/media/images/1/ca173149-6deb-4f6d-b1c3-2445f737fe21/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ca173149-6deb-4f6d-b1c3-2445f737fe21/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ca173149-6deb-4f6d-b1c3-2445f737fe21/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 37014,
        "title": "Bulgari Hotel, London, London – Updated 2020 Prices",
        "url": "https://pkslist.azureedge.net/media/images/1/de7d6c1d-b0bb-419d-b24b-a61e9a052619/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/de7d6c1d-b0bb-419d-b24b-a61e9a052619/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/de7d6c1d-b0bb-419d-b24b-a61e9a052619/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 13481,
        "title": "Bulgari Hotel, London, UK - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/4de82000-c068-45b9-8107-52b9c42b4ffd/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4de82000-c068-45b9-8107-52b9c42b4ffd/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4de82000-c068-45b9-8107-52b9c42b4ffd/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 37016,
        "title": "Bulgari Hotel, London, UK - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/843e56b2-64a7-4394-bef6-06893088588a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/843e56b2-64a7-4394-bef6-06893088588a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/843e56b2-64a7-4394-bef6-06893088588a/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 37012,
        "title": "Is This The Most Exclusive Gym In London | The Gentleman's Journal",
        "url": "https://pkslist.azureedge.net/media/images/1/ee8c5359-682c-446d-bd7c-01584c401e44/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ee8c5359-682c-446d-bd7c-01584c401e44/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ee8c5359-682c-446d-bd7c-01584c401e44/256x192.jpg",
        "viewOrder": 12
      }
    ],
    "geographicArea": {
      "geographicAreaId": 82,
      "latitude": 51.5012897,
      "longitude": -0.16282830000000104,
      "parentGeographicAreaId": 0,
      "title": "Knightsbridge, London SW7 1DW, UK",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 46,
        "tagName": "Formal Luxury ",
        "tagType": 1
      },
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 68,
        "tagName": "Business",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 101,
        "tagName": "Cinema",
        "tagType": 2
      },
      {
        "tagId": 153,
        "tagName": "Cigar Lounge",
        "tagType": 2
      },
      {
        "tagId": 183,
        "tagName": "Butler Service",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 10,
        "venueCategoryId": 4,
        "venueId": 211,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 4,
          "title": "London Top 10 ",
          "region": "Europe",
          "viewOrder": 10,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 173,
    "venueCategoryId": 4,
    "status": 1,
    "title": "Rosewood London",
    "description": "Rosewood London is an ultra-luxury High Holborn hotel that combines English heritage with contemporary sophistication in a central location. Set in the heart of the city near Covent Garden, the City and West End theatres, it is an ideal base for business and sightseeing in London.\n\nRosewood London offers guests true urban sanctuaries, with 262 rooms and 44 suites that are among the best in London. Informed by the grand traditions of a British manor home, the five-star hotel’s stylish interiors –all dreamed up by designer Tony Chi and Associates– are crafted from the finest materials and appointed to the highest standard.",
    "brand": "Rosewood",
    "blockQuote": "Drinks in Scarfes Bar is a great spot for people watching.",
    "emailAddress": "london@rosewoodhotels.com",
    "website": "rosewoodhotels.com/en/london",
    "bookingURL": "https://be.synxis.com/?adult=1&agencyid=96027890&arrive=2021-06-08&chain=5159&child=0&currency=GBP&depart=2021-06-09&filter=&hotel=59618&iata=96027890&level=hotel&locale=en-US&rooms=1",
    "redirectNotes": "Rosewood London's reservations system",
    "phoneNumber": "+44 20 7781 8888",
    "addressOne": "252",
    "addressTwo": "High Holborn",
    "city": "London",
    "county": "",
    "region": "England",
    "country": "United Kingdom",
    "rooms": 306,
    "dollar": 3,
    "updated": "2021-06-08T13:44:01.357",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 36059,
        "title": "Rosewood London, London, United Kingdom - Hotel Review - Condé ...",
        "url": "https://pkslist.azureedge.net/media/images/1/fd47eb7f-688b-471d-a706-b4e449010e07/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fd47eb7f-688b-471d-a706-b4e449010e07/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fd47eb7f-688b-471d-a706-b4e449010e07/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 36060,
        "title": "Rosewood London, United Kingdom | Out There magazine | Luxury and ...",
        "url": "https://pkslist.azureedge.net/media/images/1/49a00883-7427-4194-8902-54870c20e018/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/49a00883-7427-4194-8902-54870c20e018/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/49a00883-7427-4194-8902-54870c20e018/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 34338,
        "title": "Rosewood, London: A new hotel that puts 'Midtown' on the map | The ...",
        "url": "https://pkslist.azureedge.net/media/images/1/e7543484-f05d-4dd0-b6b1-0128e1859b16/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e7543484-f05d-4dd0-b6b1-0128e1859b16/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e7543484-f05d-4dd0-b6b1-0128e1859b16/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 36063,
        "title": "Meetings and events at Rosewood London, London, GB1",
        "url": "https://pkslist.azureedge.net/media/images/1/e86c9bf6-1ee3-4e9e-9d84-4712ac522e64/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e86c9bf6-1ee3-4e9e-9d84-4712ac522e64/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e86c9bf6-1ee3-4e9e-9d84-4712ac522e64/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 6656,
        "title": "Hot Hotel: Rosewood, London | TheDesignAir",
        "url": "https://pkslist.azureedge.net/media/images/1/68e6ffc0-0db9-474d-a1a3-4a384044b4b1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/68e6ffc0-0db9-474d-a1a3-4a384044b4b1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/68e6ffc0-0db9-474d-a1a3-4a384044b4b1/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 6657,
        "title": "Rosewood London, London, United Kingdom - Hotel Review & Photos",
        "url": "https://pkslist.azureedge.net/media/images/1/8429fd25-fee4-4d17-90e8-f729a5683f41/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8429fd25-fee4-4d17-90e8-f729a5683f41/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8429fd25-fee4-4d17-90e8-f729a5683f41/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 36061,
        "title": "Rosewood Hotel London, UK - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/5734d0fd-dcba-4150-9532-eab097f6fb9e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5734d0fd-dcba-4150-9532-eab097f6fb9e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5734d0fd-dcba-4150-9532-eab097f6fb9e/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 36062,
        "title": "Rosewood London, London – Updated 2020 Prices",
        "url": "https://pkslist.azureedge.net/media/images/1/272e8e19-a144-45e7-a0ca-8a82403325b0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/272e8e19-a144-45e7-a0ca-8a82403325b0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/272e8e19-a144-45e7-a0ca-8a82403325b0/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 36066,
        "title": "Rosewood London – Robb Report",
        "url": "https://pkslist.azureedge.net/media/images/1/97fe6fbc-32a6-4224-98c4-c2cfbce2471a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/97fe6fbc-32a6-4224-98c4-c2cfbce2471a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/97fe6fbc-32a6-4224-98c4-c2cfbce2471a/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 58,
      "latitude": 51.5175931,
      "longitude": -0.11788269999999557,
      "parentGeographicAreaId": 0,
      "title": "252 High Holborn, London WC1V 7EN, UK",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 48,
        "tagName": "Iconic/Landmark ",
        "tagType": 1
      },
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 39,
        "tagName": "City",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 68,
        "tagName": "Business",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 78,
        "tagName": "Fine Dining",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 105,
        "tagName": "Afternoon Tea",
        "tagType": 2
      },
      {
        "tagId": 127,
        "tagName": "Hair Salon",
        "tagType": 2
      },
      {
        "tagId": 183,
        "tagName": "Butler Service",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 1,
        "venueCategoryId": 4,
        "venueId": 173,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 4,
          "title": "London Top 10 ",
          "region": "Europe",
          "viewOrder": 10,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 468,
        "venueCategoryId": 2,
        "venueId": 173,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 2,
          "title": "Quintessential British",
          "region": "United Kingdom",
          "viewOrder": 17,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 469,
        "venueCategoryId": 1,
        "venueId": 173,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 1,
          "title": "Escape from the City",
          "region": "Global",
          "viewOrder": 14,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  }
]
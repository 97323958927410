// 20240123100509
// https://app.pks-list.com/api/Venue/v1/getlist/35

export const placesStore26=[
  {
    "venueId": 3173,
    "venueCategoryId": 0,
    "status": 1,
    "title": "The Ritz-Carlton Maldives Fari Islands",
    "description": "The Ritz-Carlton Maldives, Fari Islands welcomes you to embrace island life.  A distinguished ‘Aris Meeha’ or butler refined by the Ritz-Carlton service tailors a journey of meaningful moments. Immerse into Maldivian stories as Sangu shells are blown, and Boduberu drums are crafted. Transition from a day full of discoveries in North Malé Atoll into an evening of indulgence in your overwater villa and the resort surroundings.\n\nSet above azure waters or nestled along beach coves, the villas at The Ritz-Carlton Maldives, Fari Islands blend island living with modern design. Blurring the line between indoors and out, each villa offers water views, an infinity pool, a sundeck and the attention of an Aris Meeha personal butler.",
    "brand": "Ritz Carlton",
    "blockQuote": "The resort is part of the integrated Fari Islands development which means guests will have access to on and off property experiences.",
    "emailAddress": "maldives.reservations@ritzcarlton.com",
    "website": "ritzcarlton.com/en/hotels/maldives-fari-islands",
    "bookingURL": "https://www.marriott.com/reservation/rateListMenu.mi",
    "redirectNotes": "The Ritz-Carlton Maldives Fari Islands' reservations system",
    "phoneNumber": "+960 400 0999",
    "addressOne": "",
    "addressTwo": "",
    "city": "North Male Atoll",
    "county": "",
    "region": "",
    "country": "Maldives",
    "rooms": 100,
    "dollar": 3,
    "updated": "2022-11-23T10:10:27.403",
    "created": "2021-06-28T07:36:49.553",
    "medias": [
      {
        "mediaId": 48157,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c3fcce30-2a46-42c7-b568-2a2b9aed4d6a/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c3fcce30-2a46-42c7-b568-2a2b9aed4d6a/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c3fcce30-2a46-42c7-b568-2a2b9aed4d6a/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 48159,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/5914e40f-f890-41b6-8a46-8337471a4afb/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5914e40f-f890-41b6-8a46-8337471a4afb/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5914e40f-f890-41b6-8a46-8337471a4afb/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 48158,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/1d450e0b-f91f-4663-b43e-81f05b68c0a8/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1d450e0b-f91f-4663-b43e-81f05b68c0a8/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1d450e0b-f91f-4663-b43e-81f05b68c0a8/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 48162,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b5739af4-4539-4214-90f2-aa522f4bf36c/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b5739af4-4539-4214-90f2-aa522f4bf36c/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b5739af4-4539-4214-90f2-aa522f4bf36c/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 48160,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ee9dff8e-1f37-450e-8b07-abff7fa3a2c1/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ee9dff8e-1f37-450e-8b07-abff7fa3a2c1/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ee9dff8e-1f37-450e-8b07-abff7fa3a2c1/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 48164,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/cff5b904-c682-47eb-b1b7-444e4084a59f/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cff5b904-c682-47eb-b1b7-444e4084a59f/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cff5b904-c682-47eb-b1b7-444e4084a59f/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 48161,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/2b79c23b-622c-4be3-a548-a506d3b2b6e1/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2b79c23b-622c-4be3-a548-a506d3b2b6e1/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2b79c23b-622c-4be3-a548-a506d3b2b6e1/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 48165,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/49b1d813-79d4-48c3-867e-77a0ba953e22/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/49b1d813-79d4-48c3-867e-77a0ba953e22/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/49b1d813-79d4-48c3-867e-77a0ba953e22/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 48163,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f75cc8fe-9feb-4680-a3c0-6ae22d86f378/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f75cc8fe-9feb-4680-a3c0-6ae22d86f378/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f75cc8fe-9feb-4680-a3c0-6ae22d86f378/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 48166,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/9df935b8-7c9c-427f-9251-5893e95922ad/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9df935b8-7c9c-427f-9251-5893e95922ad/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9df935b8-7c9c-427f-9251-5893e95922ad/256x192.jpeg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2960,
      "latitude": 4.6041761,
      "longitude": 73.58039579999999,
      "parentGeographicAreaId": 0,
      "title": "Fari Islands North Malé Atoll Male, 20013, Maldives",
      "updated": null,
      "created": "2021-06-28T07:46:00"
    },
    "personalities": [
      {
        "tagId": 157,
        "tagName": "Beach Escape",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 83,
        "tagName": "Kid's Club",
        "tagType": 2
      },
      {
        "tagId": 88,
        "tagName": "Tennis",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 181,
        "tagName": "Private/Suite Pools",
        "tagType": 2
      },
      {
        "tagId": 183,
        "tagName": "Butler Service",
        "tagType": 2
      },
      {
        "tagId": 206,
        "tagName": "Private Beach",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 731,
        "venueCategoryId": 15,
        "venueId": 3173,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 15,
          "title": "Beach Paradise",
          "region": "Global",
          "viewOrder": 6,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 833,
        "venueCategoryId": 26,
        "venueId": 3173,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 26,
          "title": "Maldives Top 10",
          "region": "Indian Ocean",
          "viewOrder": 7,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3147,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Patina Maldives Fari Islands",
    "description": "Patina Maldives is part of the Fari Islands with two neighbouring islands that share distinctly different energies to form a destination of deep cultural character. Here, socially and environmentally conscious travellers connect through a depth of beauty, creativity and discovery.\n\nThe position and views of our 90 villas and 20 studios are yours to choose. Studios sit on the edge of the vibrant Fari Marina Village while villas face the perfect sunrises or sunsets. All exude warm, rustic simplicity. And all celebrate the energy of human nature.",
    "brand": "",
    "blockQuote": "The hardest decision is where to eat since there are 12 dining concepts to choose from!",
    "emailAddress": "reservations.maldives@patinahotels.com",
    "website": "patinahotels.com/maldives-fari-islands/",
    "bookingURL": "https://be.synxis.com/?__utma=234240927.197196122.1621005036.1621005036.1621005036.1&__utmb=234240927.3.10.1621005036&__utmc=234240927&__utmk=133677261&__utmv=-&__utmx=-&__utmz=234240927.1621005036.1.1.utmcsr=google|utmccn=(organic)|utmcmd=organic|utmctr=(not%20provided)&_ga=2.127085070.264926635.1621005037-197196122.1621005036&adult=1&agencyid=96027890&arrive=2021-05-28&chain=5154&child=0&config=SBE_PMFI_V1&currency=USD&depart=2021-05-29&hotel=31794&level=hotel&locale=en-US&rooms=1&shell=RBE&start=searchres&theme=SBE_PMFI_V1",
    "redirectNotes": "Patina Maldives Fari Islands' reservations system",
    "phoneNumber": "+960 4000 555",
    "addressOne": "",
    "addressTwo": "",
    "city": "North Male Atoll",
    "county": "",
    "region": "",
    "country": "Maldives",
    "rooms": 110,
    "dollar": 3,
    "updated": "2021-06-16T07:59:53.87",
    "created": "2021-05-14T15:16:53.93",
    "medias": [
      {
        "mediaId": 47908,
        "title": "PATINA MALDIVES, FARI ISLANDS - Updated 2021 Prices, Hotel Reviews ...",
        "url": "https://pkslist.azureedge.net/media/images/1/7d92c9f2-bdcf-4437-a341-a79f4e430399/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7d92c9f2-bdcf-4437-a341-a79f4e430399/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7d92c9f2-bdcf-4437-a341-a79f4e430399/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 47562,
        "title": "Purposeful travel: Patina Maldives, Fari Islands is seeding next ...",
        "url": "https://pkslist.azureedge.net/media/images/1/47e78ff1-3f26-4bfe-b32f-27b3c111ab18/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/47e78ff1-3f26-4bfe-b32f-27b3c111ab18/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/47e78ff1-3f26-4bfe-b32f-27b3c111ab18/256x192.png",
        "viewOrder": 1
      },
      {
        "mediaId": 47906,
        "title": "J.MAK | Patina Maldives, Fari Islands",
        "url": "https://pkslist.azureedge.net/media/images/1/bc706f5e-7b83-4e33-8a03-2c344e3dc5ef/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bc706f5e-7b83-4e33-8a03-2c344e3dc5ef/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bc706f5e-7b83-4e33-8a03-2c344e3dc5ef/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 47580,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/4b0bf011-9c80-4a6f-a735-4dcf3c490dba/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4b0bf011-9c80-4a6f-a735-4dcf3c490dba/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4b0bf011-9c80-4a6f-a735-4dcf3c490dba/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 47578,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/af2cebb0-2bbb-4c0a-a591-964e5236b09e/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/af2cebb0-2bbb-4c0a-a591-964e5236b09e/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/af2cebb0-2bbb-4c0a-a591-964e5236b09e/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 47588,
        "title": "Patina Maldives: the NEXT-GEN SUSTAINABILITY — Meet The Leader",
        "url": "https://pkslist.azureedge.net/media/images/1/9a1aa60b-290d-4cfa-b7a6-c75736c8fec9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9a1aa60b-290d-4cfa-b7a6-c75736c8fec9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9a1aa60b-290d-4cfa-b7a6-c75736c8fec9/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 47589,
        "title": "Best Resort in Maldives | Patina Maldives, Fari Islands",
        "url": "https://pkslist.azureedge.net/media/images/1/bcfabb85-1948-43c9-a823-7f1beae23b6b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bcfabb85-1948-43c9-a823-7f1beae23b6b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bcfabb85-1948-43c9-a823-7f1beae23b6b/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 47577,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/193cfc9c-589e-4337-a396-5cec1080c26e/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/193cfc9c-589e-4337-a396-5cec1080c26e/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/193cfc9c-589e-4337-a396-5cec1080c26e/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 47581,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ecee079c-0377-49e2-83cc-018a37ef5e74/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ecee079c-0377-49e2-83cc-018a37ef5e74/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ecee079c-0377-49e2-83cc-018a37ef5e74/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 47582,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a25e673d-c7da-4426-bbb1-db87f28f0f66/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a25e673d-c7da-4426-bbb1-db87f28f0f66/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a25e673d-c7da-4426-bbb1-db87f28f0f66/256x192.jpeg",
        "viewOrder": 9
      },
      {
        "mediaId": 47586,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/7b8fb64c-3c9f-48b0-8221-6e034f6bb860/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7b8fb64c-3c9f-48b0-8221-6e034f6bb860/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7b8fb64c-3c9f-48b0-8221-6e034f6bb860/256x192.jpeg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2934,
      "latitude": 4.622835200000001,
      "longitude": 73.5689092,
      "parentGeographicAreaId": 0,
      "title": "Maldives",
      "updated": null,
      "created": "2021-05-14T15:20:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 157,
        "tagName": "Beach Escape",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 198,
        "tagName": "Boat Excursion",
        "tagType": 2
      },
      {
        "tagId": 199,
        "tagName": "Art Gallery",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 299,
        "tagName": "Wellness Programs",
        "tagType": 2
      },
      {
        "tagId": 410,
        "tagName": "Sundownders",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 727,
        "venueCategoryId": 15,
        "venueId": 3147,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 15,
          "title": "Beach Paradise",
          "region": "Global",
          "viewOrder": 6,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 771,
        "venueCategoryId": 35,
        "venueId": 3147,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 832,
        "venueCategoryId": 26,
        "venueId": 3147,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 26,
          "title": "Maldives Top 10",
          "region": "Indian Ocean",
          "viewOrder": 7,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2726,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Waldorf Astoria Maldives Ithaafushi",
    "description": "Escape to the unforgettable surrounded by astonishing natural beauty. Nestled among white sands and crystal blue waters, this elegant Waldorf Astoria Maldives Ithaafushi resort is a 40-minute yacht journey from Velana International Airport. Discover indulgent choices from 11 celebrated dining venues, a world-class lifestyle spa sanctuary, and a plethora of activities for all generations. Experience True Waldorf Astoria Service personalized to your every wish.\n\nFor those seeking the ultimate exclusivity, Stella Maris Private Ocean Villas are an intimate getaway, exquisite duplex villas with direct ocean access, accessible only by boat. Ithaafushi Private Island is a 32,000 sq. m. sanctuary encompassing private villas and amenities for the utmost luxury.",
    "brand": "Waldorf Astoria",
    "blockQuote": "With 11 unique dining venues and bars, plus a plethora of activities, you might have to extend your stay.",
    "emailAddress": "MLEON.Hotel@waldorfastoria.com",
    "website": "waldorfastoriamaldives.com/",
    "bookingURL": "https://www.hilton.com/en/book/reservation/rooms/?ctyhocn=MLEONWA&arrivalDate=2020-11-23&departureDate=2020-11-24&room1NumAdults=1&iata=96027890",
    "redirectNotes": "Waldorf Astoria Maldives Ithaafushi's reservations system",
    "phoneNumber": "+960 4 000300",
    "addressOne": "",
    "addressTwo": "",
    "city": "South Male Atoll",
    "county": "",
    "region": "",
    "country": "Maldives",
    "rooms": 119,
    "dollar": 3,
    "updated": "2024-01-18T08:58:11.28",
    "created": "2020-05-20T16:17:41.26",
    "medias": [
      {
        "mediaId": 36916,
        "title": "PAX - PHOTOS: Waldorf Astoria Maldives Ithaafushi opens this year",
        "url": "https://pkslist.azureedge.net/media/images/1/9ddb875f-3da3-46a3-8576-c9ee7acfd0ff/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9ddb875f-3da3-46a3-8576-c9ee7acfd0ff/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9ddb875f-3da3-46a3-8576-c9ee7acfd0ff/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 48528,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b8b19584-418f-4745-ba8f-3b6b3d2739a9/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b8b19584-418f-4745-ba8f-3b6b3d2739a9/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b8b19584-418f-4745-ba8f-3b6b3d2739a9/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 48527,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/9d9f369b-a194-4456-82f4-a2a94f0892d5/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9d9f369b-a194-4456-82f4-a2a94f0892d5/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9d9f369b-a194-4456-82f4-a2a94f0892d5/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 43255,
        "title": "Tatler Checks Into...Waldorf Astoria Maldives Ithaafushi | Tatler ...",
        "url": "https://pkslist.azureedge.net/media/images/1/13fb54a1-ab2f-4133-b9e8-dda4c8d083c4/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/13fb54a1-ab2f-4133-b9e8-dda4c8d083c4/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/13fb54a1-ab2f-4133-b9e8-dda4c8d083c4/256x192.png",
        "viewOrder": 6
      },
      {
        "mediaId": 36938,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/aa5d2e5d-fae9-466e-9506-f982cee1bd82/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/aa5d2e5d-fae9-466e-9506-f982cee1bd82/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/aa5d2e5d-fae9-466e-9506-f982cee1bd82/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 48531,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f90962aa-91df-48ca-ac77-bab0ad78b2ed/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f90962aa-91df-48ca-ac77-bab0ad78b2ed/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f90962aa-91df-48ca-ac77-bab0ad78b2ed/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 36913,
        "title": "Waldorf Astoria Maldives Ithaafushi",
        "url": "https://pkslist.azureedge.net/media/images/1/a435b822-fb1e-408c-852d-a1d59a8140df/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a435b822-fb1e-408c-852d-a1d59a8140df/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a435b822-fb1e-408c-852d-a1d59a8140df/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 48530,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/84d03ade-f8af-41e4-b0b0-621167ec0038/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/84d03ade-f8af-41e4-b0b0-621167ec0038/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/84d03ade-f8af-41e4-b0b0-621167ec0038/256x192.jpeg",
        "viewOrder": 10
      },
      {
        "mediaId": 48534,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c9c414a2-c1fa-4075-8d78-7c29dfef2144/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c9c414a2-c1fa-4075-8d78-7c29dfef2144/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c9c414a2-c1fa-4075-8d78-7c29dfef2144/256x192.jpeg",
        "viewOrder": 11
      },
      {
        "mediaId": 48537,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/0cab605e-3d41-4bde-9933-9b68594885d3/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0cab605e-3d41-4bde-9933-9b68594885d3/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0cab605e-3d41-4bde-9933-9b68594885d3/256x192.jpeg",
        "viewOrder": 12
      },
      {
        "mediaId": 36930,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/4efc5ce5-91f7-4065-9a0c-c403e7df2206/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4efc5ce5-91f7-4065-9a0c-c403e7df2206/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4efc5ce5-91f7-4065-9a0c-c403e7df2206/256x192.jpg",
        "viewOrder": 13
      },
      {
        "mediaId": 48539,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f2651553-4ede-49b2-a0fe-c487a8a04482/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f2651553-4ede-49b2-a0fe-c487a8a04482/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f2651553-4ede-49b2-a0fe-c487a8a04482/256x192.jpeg",
        "viewOrder": 14
      },
      {
        "mediaId": 48540,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d9ddddf2-25cf-4314-a533-492722a65e46/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d9ddddf2-25cf-4314-a533-492722a65e46/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d9ddddf2-25cf-4314-a533-492722a65e46/256x192.jpeg",
        "viewOrder": 15
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2512,
      "latitude": 4.013715400000001,
      "longitude": 73.3830321,
      "parentGeographicAreaId": 0,
      "title": "Ithaafushi Island South Malé Atoll Male, 20009, Maldives",
      "updated": null,
      "created": "2020-05-20T16:27:00"
    },
    "personalities": [
      {
        "tagId": 157,
        "tagName": "Beach Escape",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 83,
        "tagName": "Kid's Club",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 123,
        "tagName": "Over Water Bungalow",
        "tagType": 2
      },
      {
        "tagId": 181,
        "tagName": "Private/Suite Pools",
        "tagType": 2
      },
      {
        "tagId": 206,
        "tagName": "Private Beach",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 299,
        "tagName": "Wellness Programs",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 569,
        "venueCategoryId": 26,
        "venueId": 2726,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 26,
          "title": "Maldives Top 10",
          "region": "Indian Ocean",
          "viewOrder": 7,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 570,
        "venueCategoryId": 15,
        "venueId": 2726,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 15,
          "title": "Beach Paradise",
          "region": "Global",
          "viewOrder": 6,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2068,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Joali Maldives",
    "description": "Can you feel it? A heightened awareness, a shimmering sense of awakening. As the soft ocean breeze strokes your skin and ivory sands caress your toes, you feel more alive than ever. Incredible artworks unfold across the landscape, taking you on an inspiring journey of discovery through the island in Maldives. Seemingly ordinary moments set the stage for transformation, opening the door to new perspective and everlasting memories.  \n\nIn this paradise of abundance, amid lush coconut palms and sparkling waters, everything makes perfect sense. The joy of our island emerges from within from its inherent nature, spreading its wings each day. Let your joy too take flight in this magical realm.",
    "brand": "",
    "blockQuote": "Be sure to book sunrise or sunset side depending on your preference.",
    "emailAddress": "reservations.jomv@joali.com",
    "website": "joali.com/",
    "bookingURL": "https://reservations.joali.com/107147?languageid=1#/guestsandrooms&iata=96027890",
    "redirectNotes": "Joali Maldives' reservations system",
    "phoneNumber": "+960 658 44 00",
    "addressOne": "",
    "addressTwo": "",
    "city": "Raa Atoll",
    "county": "",
    "region": "",
    "country": "Maldives",
    "rooms": 73,
    "dollar": 3,
    "updated": "2021-09-13T13:00:52.527",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 49583,
        "title": "JOALI, Maldives resort [Hotel Review] - Maldives Magazine",
        "url": "https://pkslist.azureedge.net/media/images/1/01a62d09-397c-4dbf-ac10-2b0bf0bc7a18/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/01a62d09-397c-4dbf-ac10-2b0bf0bc7a18/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/01a62d09-397c-4dbf-ac10-2b0bf0bc7a18/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 49584,
        "title": "Hotel review: JOALI Maldives - the luxury resort putting ...",
        "url": "https://pkslist.azureedge.net/media/images/1/1edbfb1d-a220-41eb-9e8d-25b3ec7ffa11/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1edbfb1d-a220-41eb-9e8d-25b3ec7ffa11/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1edbfb1d-a220-41eb-9e8d-25b3ec7ffa11/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 49575,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ac852b8d-9e3f-4d8b-a753-3b3d1f280075/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ac852b8d-9e3f-4d8b-a753-3b3d1f280075/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ac852b8d-9e3f-4d8b-a753-3b3d1f280075/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 49569,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/84fbe967-5c00-4ec2-a96a-a22bd698a87e/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/84fbe967-5c00-4ec2-a96a-a22bd698a87e/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/84fbe967-5c00-4ec2-a96a-a22bd698a87e/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 49570,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/1952d0b5-45e5-4c97-9632-fc972c9017b1/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1952d0b5-45e5-4c97-9632-fc972c9017b1/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1952d0b5-45e5-4c97-9632-fc972c9017b1/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 49568,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e5583883-ed94-41f4-a970-4eb7636f0bd0/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e5583883-ed94-41f4-a970-4eb7636f0bd0/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e5583883-ed94-41f4-a970-4eb7636f0bd0/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 49567,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/5a5bf444-f211-4f49-b8de-cbe02aea7296/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5a5bf444-f211-4f49-b8de-cbe02aea7296/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5a5bf444-f211-4f49-b8de-cbe02aea7296/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 49572,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/40906d4b-1209-46d1-b3ac-3c2294dc2976/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/40906d4b-1209-46d1-b3ac-3c2294dc2976/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/40906d4b-1209-46d1-b3ac-3c2294dc2976/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 49581,
        "title": "JOALI Maldives, Maldives Islands | 2021 Updated Prices, Deals",
        "url": "https://pkslist.azureedge.net/media/images/1/f3459ec3-05dc-402a-a603-f4616e2da6bb/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f3459ec3-05dc-402a-a603-f4616e2da6bb/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f3459ec3-05dc-402a-a603-f4616e2da6bb/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 49566,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/58621bce-3380-4e10-ab4a-8961a74df493/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/58621bce-3380-4e10-ab4a-8961a74df493/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/58621bce-3380-4e10-ab4a-8961a74df493/256x192.jpeg",
        "viewOrder": 9
      },
      {
        "mediaId": 49574,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/0ba549fc-4c85-4416-9d87-2fb368658e54/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0ba549fc-4c85-4416-9d87-2fb368658e54/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0ba549fc-4c85-4416-9d87-2fb368658e54/256x192.jpeg",
        "viewOrder": 10
      },
      {
        "mediaId": 49576,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/80d6bf54-628a-4a1a-bb72-158773e29053/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/80d6bf54-628a-4a1a-bb72-158773e29053/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/80d6bf54-628a-4a1a-bb72-158773e29053/256x192.jpeg",
        "viewOrder": 11
      },
      {
        "mediaId": 49573,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/82464007-89a2-49c2-af4c-f394ceb4c8f0/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/82464007-89a2-49c2-af4c-f394ceb4c8f0/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/82464007-89a2-49c2-af4c-f394ceb4c8f0/256x192.jpeg",
        "viewOrder": 12
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1877,
      "latitude": 5.6507,
      "longitude": 72.88512000000003,
      "parentGeographicAreaId": 0,
      "title": "1 Raa Atoll, Maamigili Island, Maldives",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 157,
        "tagName": "Beach Escape",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 83,
        "tagName": "Kid's Club",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 116,
        "tagName": "Scuba Diving",
        "tagType": 2
      },
      {
        "tagId": 181,
        "tagName": "Private/Suite Pools",
        "tagType": 2
      },
      {
        "tagId": 198,
        "tagName": "Boat Excursion",
        "tagType": 2
      },
      {
        "tagId": 206,
        "tagName": "Private Beach",
        "tagType": 2
      },
      {
        "tagId": 219,
        "tagName": "Wine Cellar",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 541,
        "venueCategoryId": 26,
        "venueId": 2068,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 26,
          "title": "Maldives Top 10",
          "region": "Indian Ocean",
          "viewOrder": 7,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 542,
        "venueCategoryId": 15,
        "venueId": 2068,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 15,
          "title": "Beach Paradise",
          "region": "Global",
          "viewOrder": 6,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 543,
        "venueCategoryId": 5,
        "venueId": 2068,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 5,
          "title": "Romantic Getaways",
          "region": "Global",
          "viewOrder": 20,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1228,
    "venueCategoryId": 26,
    "status": 1,
    "title": "Velaa Private Island",
    "description": "Located north from Male, within the constellation of islands that form the Noonu Atoll, Velaa Private Island brings a new meaning to \"private moments\" in the Maldives. Here the essence of Maldivian culture, lifestyle and landscape meets limitless opportunities, where no request is too much, and each guest will ultimately feel that this is their own private island. \n\nComprising 43 private villas and 4 four-bedroom residences fusing local materials and contemporary flourishes, each retreat offers a generous outdoor space and private pool. While 18 villas are built over water, the Romantic Villa is the only exclusive villa that is reached by boat. Our Residences are the only 4-bedroom residences in Maldives, accommodating up to 10 people offering elegant contemporary design and 1,350 SQM of interior and exterior space, incl. 2 terraces, a pool and a courtyard.",
    "brand": "",
    "blockQuote": "This is one island you won't get bored on considering there's a golf course, tennis, yoga and even rock climbing.",
    "emailAddress": "info@velaaprivateisland.com",
    "website": "velaaprivateisland.com/",
    "bookingURL": "https://www.velaaprivateisland.com/",
    "redirectNotes": "Velaa Private Island's reservations system",
    "phoneNumber": "+960 6565 000",
    "addressOne": "",
    "addressTwo": "",
    "city": "Noonu Atoll",
    "county": "",
    "region": "",
    "country": "Maldives",
    "rooms": 47,
    "dollar": 3,
    "updated": "2020-11-23T17:11:21.067",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 43271,
        "title": "Velaa Private Island | Luxury Maldives Resort | Red Savannah",
        "url": "https://pkslist.azureedge.net/media/images/1/2685f411-0b28-4866-ab8f-79278289e606/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2685f411-0b28-4866-ab8f-79278289e606/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2685f411-0b28-4866-ab8f-79278289e606/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 43273,
        "title": "Why you should stay at this private island on your next Maldives ...",
        "url": "https://pkslist.azureedge.net/media/images/1/c0afa9ad-25cb-4ed1-a2d8-cace619a512e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c0afa9ad-25cb-4ed1-a2d8-cace619a512e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c0afa9ad-25cb-4ed1-a2d8-cace619a512e/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 43261,
        "title": "The Ultimate Romantic Getaway at Velaa Private Island | TravelmakerID",
        "url": "https://pkslist.azureedge.net/media/images/1/1e3d3ad1-8c87-44b6-bcf6-06c7999d63b5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1e3d3ad1-8c87-44b6-bcf6-06c7999d63b5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1e3d3ad1-8c87-44b6-bcf6-06c7999d63b5/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 43260,
        "title": "Velaa Private Island Resort Maldives | Stay at Best Resort Maldives",
        "url": "https://pkslist.azureedge.net/media/images/1/20c1ac15-6cc8-4cae-b824-81edb7840318/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/20c1ac15-6cc8-4cae-b824-81edb7840318/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/20c1ac15-6cc8-4cae-b824-81edb7840318/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 43266,
        "title": "Velaa Private Island",
        "url": "https://pkslist.azureedge.net/media/images/1/71a46f75-b74a-42bd-997a-425354207116/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/71a46f75-b74a-42bd-997a-425354207116/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/71a46f75-b74a-42bd-997a-425354207116/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 14807,
        "title": "Sunrise Water Pool Villa | Velaa Private Island",
        "url": "https://pkslist.azureedge.net/media/images/1/5adde006-8f3f-4313-9eb4-a082022ea56c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5adde006-8f3f-4313-9eb4-a082022ea56c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5adde006-8f3f-4313-9eb4-a082022ea56c/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 43259,
        "title": "Ultra-Luxury Private Residences on Velaa Private Island, Maldives",
        "url": "https://pkslist.azureedge.net/media/images/1/a336d6d4-a4fd-4700-b7de-ad7d1fafee08/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a336d6d4-a4fd-4700-b7de-ad7d1fafee08/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a336d6d4-a4fd-4700-b7de-ad7d1fafee08/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 14806,
        "title": "Velaa Private Island Maldives - Truly Classy",
        "url": "https://pkslist.azureedge.net/media/images/1/08761865-095a-4645-95c8-9ffb9400f90a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/08761865-095a-4645-95c8-9ffb9400f90a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/08761865-095a-4645-95c8-9ffb9400f90a/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 12726,
        "title": "New luxury resort Vela Private Island opens in the Maldives this ...",
        "url": "https://pkslist.azureedge.net/media/images/1/c4278db4-c258-4483-82c3-23ff56afd891/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c4278db4-c258-4483-82c3-23ff56afd891/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c4278db4-c258-4483-82c3-23ff56afd891/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 12733,
        "title": "Velaa Private Island, Maldives | Original Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/2aaf11a6-991d-404f-8135-5c27047d79de/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2aaf11a6-991d-404f-8135-5c27047d79de/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2aaf11a6-991d-404f-8135-5c27047d79de/256x192.jpg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1053,
      "latitude": 5.831370999999999,
      "longitude": 73.20945699999993,
      "parentGeographicAreaId": 0,
      "title": "Noonu Atoll, Republic of Maldives, Maldives",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 157,
        "tagName": "Beach Escape",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 67,
        "tagName": "Sporty",
        "tagType": 2
      },
      {
        "tagId": 78,
        "tagName": "Fine Dining",
        "tagType": 2
      },
      {
        "tagId": 81,
        "tagName": "Golf",
        "tagType": 2
      },
      {
        "tagId": 88,
        "tagName": "Tennis",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 116,
        "tagName": "Scuba Diving",
        "tagType": 2
      },
      {
        "tagId": 123,
        "tagName": "Over Water Bungalow",
        "tagType": 2
      },
      {
        "tagId": 306,
        "tagName": "Squash Court",
        "tagType": 2
      },
      {
        "tagId": 342,
        "tagName": "Water Skiing",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 224,
        "venueCategoryId": 19,
        "venueId": 1228,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 19,
          "title": "Private Islands",
          "region": "Global",
          "viewOrder": 15,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 478,
        "venueCategoryId": 26,
        "venueId": 1228,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 26,
          "title": "Maldives Top 10",
          "region": "Indian Ocean",
          "viewOrder": 7,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 518,
        "venueCategoryId": 27,
        "venueId": 1228,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 27,
          "title": "Family Fun",
          "region": "Global",
          "viewOrder": 16,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1107,
    "venueCategoryId": 26,
    "status": 1,
    "title": "Soneva Jani",
    "description": "An imaginative journey that began over two decades ago, the Soneva story continues with Soneva Jani, the brand’s newest luxury resort offering. Inspired by a word that means ‘wisdom’ in Sanskrit, Soneva Jani encompasses a collection of Water Villas and Island Sanctuaries set within a lagoon of crystal clear waters, fringed by pristine beaches and blanketed in lush tropical greenery.\n\nThe highlight of the islands that the resort calls home is the 5.6 kilometre private lagoon that surrounds it; an awe-inspiring azure spectacle that inspires a sense of calm.\n\nSoneva Jani, located in the Medhufaru lagoon in the Noonu Atoll, comprises five islands, the largest of which is Medhufaru, which is 150 acres in size. The resort can be reached by a scenic 40 minute seaplane flight direct from Malé International Airport, a 60 minute speedboat ride from Soneva Fushi, or by a leisurely halfday cruise from Soneva Fushi onboard Soneva in Aqua.",
    "brand": "Soneva",
    "blockQuote": "Visit the joining island where there is an over water cinema, tennis court and water sports area.",
    "emailAddress": "reservations@soneva.com",
    "website": "soneva.com/soneva-jani/",
    "bookingURL": "https://be.synxis.com/?_ga=2.6343344.306595346.1536085362-1204112873.1536085362&adult=1&agencyid=96027890&arrive=2021-07-23&chain=22104&child=0&currency=USD&depart=2021-07-24&filter=&hotel=71058&level=hotel&locale=en-US&rooms=1&shell=RBE&template=RBE",
    "redirectNotes": "Soneva Jani's reservations system",
    "phoneNumber": "+960 656 6666 ",
    "addressOne": "Medhufaru Island",
    "addressTwo": "",
    "city": "Noonu Atoll",
    "county": "",
    "region": "",
    "country": "Maldives",
    "rooms": 25,
    "dollar": 3,
    "updated": "2021-07-08T11:36:27.773",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 36048,
        "title": "Resort Soneva Jani, Noonu, Maldives - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/d4b2d4f0-ed1a-42e7-844a-032d500aea3f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d4b2d4f0-ed1a-42e7-844a-032d500aea3f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d4b2d4f0-ed1a-42e7-844a-032d500aea3f/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 36047,
        "title": "About Soneva Jani | Maldives Luxury Water Retreat Resort",
        "url": "https://pkslist.azureedge.net/media/images/1/3a530f22-d71c-47aa-9f1f-f726a5eadd7f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3a530f22-d71c-47aa-9f1f-f726a5eadd7f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3a530f22-d71c-47aa-9f1f-f726a5eadd7f/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 11217,
        "title": "Soneva Jani - the best new Maldives resort (Condé Nast Traveller)",
        "url": "https://pkslist.azureedge.net/media/images/1/7d611646-5329-4547-950c-ee23a07d2d84/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7d611646-5329-4547-950c-ee23a07d2d84/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7d611646-5329-4547-950c-ee23a07d2d84/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 11223,
        "title": "Soneva Jani Maldives - Private Residences | Maldives Beach Resort ...",
        "url": "https://pkslist.azureedge.net/media/images/1/f9afd03b-7fa9-4886-acc8-62120f6618c6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f9afd03b-7fa9-4886-acc8-62120f6618c6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f9afd03b-7fa9-4886-acc8-62120f6618c6/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 11224,
        "title": "3 Bedroom Water Reserve with slide at Soneva Jani Resort",
        "url": "https://pkslist.azureedge.net/media/images/1/8346b28c-c313-4de7-a829-4e27ef198d32/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8346b28c-c313-4de7-a829-4e27ef198d32/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8346b28c-c313-4de7-a829-4e27ef198d32/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 36053,
        "title": "A Picture Perfect Holiday In The Bluest Of Blue Lagoons",
        "url": "https://pkslist.azureedge.net/media/images/1/4b5f0897-9022-4a7a-af20-03cec8e75b00/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4b5f0897-9022-4a7a-af20-03cec8e75b00/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4b5f0897-9022-4a7a-af20-03cec8e75b00/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 36054,
        "title": "Soneva Jani Resort (Maldives Islands) - Deals, Photos & Reviews",
        "url": "https://pkslist.azureedge.net/media/images/1/3a80017f-1107-4226-9dd0-1e808675b952/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3a80017f-1107-4226-9dd0-1e808675b952/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3a80017f-1107-4226-9dd0-1e808675b952/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 36055,
        "title": "Villa 3 Soneva Jani - Lifestyle Capital Partners",
        "url": "https://pkslist.azureedge.net/media/images/1/7d427f72-e9cd-43f6-8165-96b87aff7f47/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7d427f72-e9cd-43f6-8165-96b87aff7f47/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7d427f72-e9cd-43f6-8165-96b87aff7f47/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 36050,
        "title": "Soneva Jani Unveils New Dining Concept-Petrie PR",
        "url": "https://pkslist.azureedge.net/media/images/1/667594d9-d56a-45ba-bcab-107c1854aceb/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/667594d9-d56a-45ba-bcab-107c1854aceb/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/667594d9-d56a-45ba-bcab-107c1854aceb/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 36057,
        "title": "Soneva Jani - Maldives - 2 Bedroom Water Retreat",
        "url": "https://pkslist.azureedge.net/media/images/1/5999e350-df72-460b-8e7b-f46b40cdca6d/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5999e350-df72-460b-8e7b-f46b40cdca6d/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5999e350-df72-460b-8e7b-f46b40cdca6d/256x192.jpeg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 934,
      "latitude": 5.712031199999999,
      "longitude": 73.41459269999996,
      "parentGeographicAreaId": 0,
      "title": "Medhufaru Island, Noonu Atoll, Maldives",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 50,
        "tagName": "Barefoot Luxury",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 116,
        "tagName": "Scuba Diving",
        "tagType": 2
      },
      {
        "tagId": 120,
        "tagName": "Private Dining",
        "tagType": 2
      },
      {
        "tagId": 123,
        "tagName": "Over Water Bungalow",
        "tagType": 2
      },
      {
        "tagId": 165,
        "tagName": "Observatory",
        "tagType": 2
      },
      {
        "tagId": 206,
        "tagName": "Private Beach",
        "tagType": 2
      },
      {
        "tagId": 259,
        "tagName": "Picnic",
        "tagType": 2
      },
      {
        "tagId": 263,
        "tagName": "Star Gazing",
        "tagType": 2
      },
      {
        "tagId": 408,
        "tagName": "Sunset Cruise",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 211,
        "venueCategoryId": 19,
        "venueId": 1107,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 19,
          "title": "Private Islands",
          "region": "Global",
          "viewOrder": 15,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 539,
        "venueCategoryId": 26,
        "venueId": 1107,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 26,
          "title": "Maldives Top 10",
          "region": "Indian Ocean",
          "viewOrder": 7,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 540,
        "venueCategoryId": 5,
        "venueId": 1107,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 5,
          "title": "Romantic Getaways",
          "region": "Global",
          "viewOrder": 20,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 770,
    "venueCategoryId": 15,
    "status": 1,
    "title": "The St. Regis Maldives Vommuli Resort",
    "description": "Nestled between verdant rainforest and white-sand beaches on a private island, The St. Regis Maldives Vommuli Resort overlooks the alluring waves of the Indian Ocean. Boasting refined furnishings and island-inspired design, each of the 33 on-land and 44 over-water villas promise picturesque ocean or garden views from private terraces and pools.\n\nSavor epicurean delights from all over the world in any of the six restaurants and bars—each inspired by authentic Italian, Middle Eastern and Asian flavors served in buffet, ala carte or tapas style, in addition to an extensive wine cellar. Restore your well-being with custom-made treatments at the over-water Iridium Spa, or maintain your workout regime at Vommuli House. At The St. Regis Maldives Vommuli 5 Star Resort, impeccable service meets effortless luxury.",
    "brand": "St. Regis",
    "blockQuote": "Inspired by the shape of a whale shark, the over-water Whale Bar serves delicious tapas with a stunning view.",
    "emailAddress": "stregis.maldivesvommuli@stregis.com",
    "website": "stregismaldives.com/",
    "bookingURL": "https://www.marriott.com/search/default.mi?roomCount=1&toDate=11%2F24%2F2020&numAdultsPerRoom=1&flexibleDateSearch=false&destinationAddress.city=Dhaalu+Atoll&destinationAddress.longitude=72.865534&fromDate=11%2F23%2F2020&unsuccessfulSell=true&propertyCode=mlexr&destinationAddress.location=Vommuli+Island%2C+Dhaalu+Atoll%2C+13080%2C+MV&singleSearch=true&singleSearchAutoSuggest=true&useRewardsPoints=false&countryName=MV&destinationAddress.latitude=2.910811&childrenCount=0&useRequestCriteria=true&showErrors=true&availabilityErrorCode=LAR-1002",
    "redirectNotes": "The St. Regis Maldives Vommuli Resort's reservations system",
    "phoneNumber": "+960 676 6333 ",
    "addressOne": "",
    "addressTwo": "",
    "city": "Dhaalu Atoll",
    "county": "",
    "region": "",
    "country": "Maldives",
    "rooms": 77,
    "dollar": 3,
    "updated": "2020-11-23T13:02:30.17",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 43238,
        "title": "The St. Regis Maldives Vommuli Resort, Dhaalu Atoll – Updated 2020 ...",
        "url": "https://pkslist.azureedge.net/media/images/1/9af83f0c-2f6b-46f1-bb58-77d940999744/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9af83f0c-2f6b-46f1-bb58-77d940999744/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9af83f0c-2f6b-46f1-bb58-77d940999744/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 43244,
        "title": "Here's What It's Like to Stay at a $25,000-per-night Overwater ...",
        "url": "https://pkslist.azureedge.net/media/images/1/48fceeb1-740e-4f7b-887f-6a27b3f24624/1200x900.jpg&q=85",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/48fceeb1-740e-4f7b-887f-6a27b3f24624/800x600.jpg&q=85",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/48fceeb1-740e-4f7b-887f-6a27b3f24624/256x192.jpg&q=85",
        "viewOrder": 1
      },
      {
        "mediaId": 14948,
        "title": "Maldives' Finest Luxury Resort | The St. Regis Maldives",
        "url": "https://pkslist.azureedge.net/media/images/1/5c5d38ee-0a7c-4728-8f9e-64537fb0f8a1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5c5d38ee-0a7c-4728-8f9e-64537fb0f8a1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5c5d38ee-0a7c-4728-8f9e-64537fb0f8a1/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 43243,
        "title": "The St Regis Maldives Vommuli Resort • Hotel Review by TravelPlusStyle",
        "url": "https://pkslist.azureedge.net/media/images/1/0bb525f2-f44c-4267-990a-04b263e1b9e1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0bb525f2-f44c-4267-990a-04b263e1b9e1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0bb525f2-f44c-4267-990a-04b263e1b9e1/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 43251,
        "title": "The St Regis Maldives Vommuli Resort • Hotel Review by TravelPlusStyle",
        "url": "https://pkslist.azureedge.net/media/images/1/af525118-764e-4aff-9fb7-84708ed7121f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/af525118-764e-4aff-9fb7-84708ed7121f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/af525118-764e-4aff-9fb7-84708ed7121f/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 43253,
        "title": "The St. Regis Maldives Vommuli Resort, Dhaalu Atoll – Updated 2020 ...",
        "url": "https://pkslist.azureedge.net/media/images/1/bbf476ef-3efd-46b1-aba9-1c3c597d2db3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bbf476ef-3efd-46b1-aba9-1c3c597d2db3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bbf476ef-3efd-46b1-aba9-1c3c597d2db3/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 6586,
        "title": "The St. Regis Maldives Vommuli Resort | Maldives.com",
        "url": "https://pkslist.azureedge.net/media/images/1/c2a1f3f5-37a7-48eb-8959-60fef912c90d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c2a1f3f5-37a7-48eb-8959-60fef912c90d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c2a1f3f5-37a7-48eb-8959-60fef912c90d/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 14953,
        "title": "The St. Regis Maldives Vommuli Resort, Maldives - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/a0d58eef-0ae0-48fb-af19-3e12d4bea357/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a0d58eef-0ae0-48fb-af19-3e12d4bea357/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a0d58eef-0ae0-48fb-af19-3e12d4bea357/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 43250,
        "title": "The St Regis Maldives Vommuli Resort • Hotel Review by TravelPlusStyle",
        "url": "https://pkslist.azureedge.net/media/images/1/7cbba288-cbdb-4eef-9837-5f9192ea465b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7cbba288-cbdb-4eef-9837-5f9192ea465b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7cbba288-cbdb-4eef-9837-5f9192ea465b/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 43247,
        "title": "St. Regis Maldives Vommuli Resort - World Luxury Spa Award Winner ...",
        "url": "https://pkslist.azureedge.net/media/images/1/eb83e3be-144d-43e7-8fe4-e9d20bc4b188/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/eb83e3be-144d-43e7-8fe4-e9d20bc4b188/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/eb83e3be-144d-43e7-8fe4-e9d20bc4b188/256x192.jpg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 591,
      "latitude": 2.911219999999999,
      "longitude": 72.86553900000001,
      "parentGeographicAreaId": 0,
      "title": "Vommuli Island, Dhaalu Atoll, Vommuli 13080, Maldives",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 157,
        "tagName": "Beach Escape",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 102,
        "tagName": "Eco-friendly",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 116,
        "tagName": "Scuba Diving",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 123,
        "tagName": "Over Water Bungalow",
        "tagType": 2
      },
      {
        "tagId": 181,
        "tagName": "Private/Suite Pools",
        "tagType": 2
      },
      {
        "tagId": 193,
        "tagName": "Outdoor Dining",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 132,
        "venueCategoryId": 15,
        "venueId": 770,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 15,
          "title": "Beach Paradise",
          "region": "Global",
          "viewOrder": 6,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 483,
        "venueCategoryId": 26,
        "venueId": 770,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 26,
          "title": "Maldives Top 10",
          "region": "Indian Ocean",
          "viewOrder": 7,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 484,
        "venueCategoryId": 19,
        "venueId": 770,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 19,
          "title": "Private Islands",
          "region": "Global",
          "viewOrder": 15,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 304,
    "venueCategoryId": 26,
    "status": 1,
    "title": "Cheval Blanc Randheli",
    "description": "Conceived by architect Jean-Michel Gathy, Cheval Blanc Randheli is an invitation to experience French “Art de Recevoir”. In a preserved environment, forty minutes north of Malé by seaplane, pristine waters and opulent vegetation offer Cheval Blanc Randheli an exceptional setting.\n\nThe Maison has been conceived in synergy with the island’s natural surroundings, lush vegetation and lagoon views. Contemporary yet indigenous, graceful and understated, it is the perfect balance of modern architecture and traditional inspirations. The graphic architecture is complemented by stylish interior design furnished with materials from the Indian Ocean and natural textures – rattan, bamboo, mother-of-pearl, coral or shagreen - enhanced by a vivid palette of colours with splashes of pop yellow and green.",
    "brand": "Cheval Blanc",
    "blockQuote": "The bar is set high with elegant and spacious private villas and service that goes above and beyond.",
    "emailAddress": "info.randheli@chevalblanc.com",
    "website": "randheli.chevalblanc.com",
    "bookingURL": "https://be.synxis.com/?adult=2&agencyid=96027890&arrive=2021-07-08&chain=23061&child=0&currency=USD&depart=2021-07-09&filter=&hotel=1344&level=hotel&locale=en-US&rooms=1",
    "redirectNotes": "Cheval Blanc Randheli's reservations system",
    "phoneNumber": "+960 656 1515",
    "addressOne": "",
    "addressTwo": "",
    "city": "Noonu Atoll",
    "county": "",
    "region": "",
    "country": "Maldives",
    "rooms": 45,
    "dollar": 3,
    "updated": "2021-07-08T11:31:43.71",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 43106,
        "title": "Cheval Blanc randheli maldives water villas aerial - The Luxe ...",
        "url": "https://pkslist.azureedge.net/media/images/1/fa27a54a-4e5f-47b8-8190-4a317e851871/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fa27a54a-4e5f-47b8-8190-4a317e851871/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fa27a54a-4e5f-47b8-8190-4a317e851871/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 1167,
        "title": "Cheval Blanc Randheli, Maldive | Traveller Made",
        "url": "https://pkslist.azureedge.net/media/images/1/599712cb-ca45-4e7f-9717-f219f8b5790f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/599712cb-ca45-4e7f-9717-f219f8b5790f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/599712cb-ca45-4e7f-9717-f219f8b5790f/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 43108,
        "title": "Cheval Blanc Randheli, Noonu Atoll, Maldives - Luxury Hotel ...",
        "url": "https://pkslist.azureedge.net/media/images/1/17e7ff61-315d-41ae-ad7c-2abe89742da8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/17e7ff61-315d-41ae-ad7c-2abe89742da8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/17e7ff61-315d-41ae-ad7c-2abe89742da8/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 43109,
        "title": "Cheval Blanc Randheli - Hotels in Heaven",
        "url": "https://pkslist.azureedge.net/media/images/1/c88e15ee-04fc-4ff6-86e6-c05510bd76d0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c88e15ee-04fc-4ff6-86e6-c05510bd76d0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c88e15ee-04fc-4ff6-86e6-c05510bd76d0/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 1169,
        "title": "Spa Cheval Blanc",
        "url": "https://pkslist.azureedge.net/media/images/1/6dcf639b-37c7-45fd-867d-db8e13d8123f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6dcf639b-37c7-45fd-867d-db8e13d8123f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6dcf639b-37c7-45fd-867d-db8e13d8123f/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 43102,
        "title": "Cheval Blanc Randheli, Noonu Atoll, Maldives | Elite Traveler ...",
        "url": "https://pkslist.azureedge.net/media/images/1/96ffaec9-14dc-4ad8-bf00-e388895becc7/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/96ffaec9-14dc-4ad8-bf00-e388895becc7/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/96ffaec9-14dc-4ad8-bf00-e388895becc7/256x192.png",
        "viewOrder": 7
      },
      {
        "mediaId": 43101,
        "title": "Review: Cheval Blanc Randheli, Maldives",
        "url": "https://pkslist.azureedge.net/media/images/1/6542e845-2d81-4464-948c-218fa3791a91/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6542e845-2d81-4464-948c-218fa3791a91/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6542e845-2d81-4464-948c-218fa3791a91/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 43103,
        "title": "Cheval Blanc Randheli | apaiser bathware | Archello",
        "url": "https://pkslist.azureedge.net/media/images/1/9e901b38-f5d2-4858-b27b-6d545a9b041d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9e901b38-f5d2-4858-b27b-6d545a9b041d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9e901b38-f5d2-4858-b27b-6d545a9b041d/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 43104,
        "title": "Cheval Blanc Randheli | Best Hotels In Maldives",
        "url": "https://pkslist.azureedge.net/media/images/1/a35c4c35-a8c1-4864-b3e3-8987014d6b2c/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a35c4c35-a8c1-4864-b3e3-8987014d6b2c/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a35c4c35-a8c1-4864-b3e3-8987014d6b2c/256x192.png",
        "viewOrder": 10
      },
      {
        "mediaId": 1168,
        "title": "water-villas-galerie-1-4-812a- ...",
        "url": "https://pkslist.azureedge.net/media/images/1/ae0b1b33-e07a-4a89-a1a6-ca0db16a48a3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ae0b1b33-e07a-4a89-a1a6-ca0db16a48a3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ae0b1b33-e07a-4a89-a1a6-ca0db16a48a3/256x192.jpg",
        "viewOrder": 12
      },
      {
        "mediaId": 1166,
        "title": "Cheval Blanc Randheli | Maldives",
        "url": "https://pkslist.azureedge.net/media/images/1/64a15e53-897f-4a9d-ba7f-614d9ba99969/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/64a15e53-897f-4a9d-ba7f-614d9ba99969/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/64a15e53-897f-4a9d-ba7f-614d9ba99969/256x192.jpg",
        "viewOrder": 13
      }
    ],
    "geographicArea": {
      "geographicAreaId": 165,
      "latitude": 5.691805,
      "longitude": 73.34106899999999,
      "parentGeographicAreaId": 0,
      "title": "Noonu Atoll Republic of Maldives, Maldives",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 51,
        "tagName": "Style Icon",
        "tagType": 1
      },
      {
        "tagId": 157,
        "tagName": "Beach Escape",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 58,
        "tagName": "Beach",
        "tagType": 2
      },
      {
        "tagId": 70,
        "tagName": "Families",
        "tagType": 2
      },
      {
        "tagId": 71,
        "tagName": "Group of Friends",
        "tagType": 2
      },
      {
        "tagId": 78,
        "tagName": "Fine Dining",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 115,
        "tagName": "Snorkeling",
        "tagType": 2
      },
      {
        "tagId": 116,
        "tagName": "Scuba Diving",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 40,
        "venueCategoryId": 20,
        "venueId": 304,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 20,
          "title": "BucketList Hotels",
          "region": "Global",
          "viewOrder": 0,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 333,
        "venueCategoryId": 19,
        "venueId": 304,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 19,
          "title": "Private Islands",
          "region": "Global",
          "viewOrder": 15,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 334,
        "venueCategoryId": 15,
        "venueId": 304,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 15,
          "title": "Beach Paradise",
          "region": "Global",
          "viewOrder": 6,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 475,
        "venueCategoryId": 26,
        "venueId": 304,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 26,
          "title": "Maldives Top 10",
          "region": "Indian Ocean",
          "viewOrder": 7,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 303,
    "venueCategoryId": 26,
    "status": 1,
    "title": "One&Only Reethi Rah",
    "description": "Welcome to one of the world’s most magical destinations. A place where turquoise clear waters meet cloudless azure skies. Where Indian Oceanbreezes sway lush palms. A tropical paradisefringed with 12 pristine beaches. Your very own private island escape. Explore on foot or by bike and feel blissfully alone. Or feel the buzz of beach\nclub, bar or crystalline pools. \n\nSurrounded by the wonders of the Indian Ocean, One&Only Reethi Rah makes its home on one of the largest islands in North Malé Atoll. A jewel among a string of coral atolls, lagoons and white sands, it is about 700 km (430 mi) southwest of Sri Lanka.\n\nOne&Only Reethi Rah offers 128 private resort villas well separated along the shore, either on the beach secluded by lush tropical greenery, or set above the clear, protected waters of the lagoon. Designed in an elegant contemporary style, these are among the largest resort villas in the world.",
    "brand": "One&Only",
    "blockQuote": "Totally unique and incredibly romantic, dining in the Tree House as the sun sets over the ocean is a moment you'll cherish.",
    "emailAddress": "reservations@oneandonlyreethirah.com",
    "website": "oneandonlyresorts.com/one-and-only-reethi-rah-maldives",
    "bookingURL": "https://be.synxis.com/?IATA=96027890&adult=1&agencyid=96027890&arrive=2021-07-07&chain=9020&child=0&currency=USD&depart=2021-07-08&filter=&hotel=23855&level=hotel&locale=en-US&rooms=1&shell=Flex&template=Flex2",
    "redirectNotes": "One&Only Reethi Rah's reservations system",
    "phoneNumber": "+960 664 8800",
    "addressOne": "",
    "addressTwo": "",
    "city": "North Male Atoll",
    "county": "",
    "region": "",
    "country": "Maldives",
    "rooms": 128,
    "dollar": 3,
    "updated": "2021-07-07T12:45:51.023",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 14877,
        "title": "One&Only Reethi Rah - UPDATED 2017 Prices & Resort Reviews ...",
        "url": "https://pkslist.azureedge.net/media/images/1/5b0f9ef2-040a-4ac1-9609-fe7bf8b6df3e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5b0f9ef2-040a-4ac1-9609-fe7bf8b6df3e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5b0f9ef2-040a-4ac1-9609-fe7bf8b6df3e/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 43217,
        "title": "One&Only Reethi Rah - The Maldives Expert",
        "url": "https://pkslist.azureedge.net/media/images/1/490a7fa7-3dbe-47cf-b575-f2ef2cf34480/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/490a7fa7-3dbe-47cf-b575-f2ef2cf34480/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/490a7fa7-3dbe-47cf-b575-f2ef2cf34480/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 43213,
        "title": "One&Only Reethi Rah Luxury Resort Maldives | Review by TravelPlusStyle",
        "url": "https://pkslist.azureedge.net/media/images/1/1aa7c78e-2843-4cf9-9035-feb65209602a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1aa7c78e-2843-4cf9-9035-feb65209602a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1aa7c78e-2843-4cf9-9035-feb65209602a/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 43212,
        "title": "One&Only Reethi Rah Luxury Resort Maldives | Review by TravelPlusStyle",
        "url": "https://pkslist.azureedge.net/media/images/1/9bbda750-2331-4377-913d-994b3e9e478a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9bbda750-2331-4377-913d-994b3e9e478a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9bbda750-2331-4377-913d-994b3e9e478a/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 43222,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b0bdd364-1d37-4390-bf0d-ae4057b4a20a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b0bdd364-1d37-4390-bf0d-ae4057b4a20a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b0bdd364-1d37-4390-bf0d-ae4057b4a20a/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 43223,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/239383f8-2b79-4553-900c-4d2082a98664/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/239383f8-2b79-4553-900c-4d2082a98664/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/239383f8-2b79-4553-900c-4d2082a98664/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 43236,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/05c80c70-c314-47a7-8456-d2d10641d362/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/05c80c70-c314-47a7-8456-d2d10641d362/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/05c80c70-c314-47a7-8456-d2d10641d362/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 43233,
        "title": "One&Only Reethi Rah | Elite Traveler : Elite Traveler",
        "url": "https://pkslist.azureedge.net/media/images/1/3ffd97fd-9ecf-403a-adc7-503c2c80c40c/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3ffd97fd-9ecf-403a-adc7-503c2c80c40c/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3ffd97fd-9ecf-403a-adc7-503c2c80c40c/256x192.png",
        "viewOrder": 7
      },
      {
        "mediaId": 43221,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/43da53cd-d308-4546-8db2-5937dc0a63e4/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/43da53cd-d308-4546-8db2-5937dc0a63e4/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/43da53cd-d308-4546-8db2-5937dc0a63e4/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 43218,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/163451dc-0499-4a07-b6d3-8f259016fdd9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/163451dc-0499-4a07-b6d3-8f259016fdd9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/163451dc-0499-4a07-b6d3-8f259016fdd9/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 43228,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/36c75c2b-9f1f-4ce9-ae8c-ffc7999581e2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/36c75c2b-9f1f-4ce9-ae8c-ffc7999581e2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/36c75c2b-9f1f-4ce9-ae8c-ffc7999581e2/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 43229,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/bdb4ed3b-7330-4cb2-84e2-b8a9b7a865bf/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bdb4ed3b-7330-4cb2-84e2-b8a9b7a865bf/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bdb4ed3b-7330-4cb2-84e2-b8a9b7a865bf/256x192.jpg",
        "viewOrder": 11
      }
    ],
    "geographicArea": {
      "geographicAreaId": 164,
      "latitude": 4.416666699999999,
      "longitude": 73.5,
      "parentGeographicAreaId": 0,
      "title": "Maale Atholhu Uthuruburi, Maldives",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 51,
        "tagName": "Style Icon",
        "tagType": 1
      },
      {
        "tagId": 157,
        "tagName": "Beach Escape",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 58,
        "tagName": "Beach",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 88,
        "tagName": "Tennis",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 109,
        "tagName": "Fishing",
        "tagType": 2
      },
      {
        "tagId": 115,
        "tagName": "Snorkeling",
        "tagType": 2
      },
      {
        "tagId": 116,
        "tagName": "Scuba Diving",
        "tagType": 2
      },
      {
        "tagId": 408,
        "tagName": "Sunset Cruise",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 39,
        "venueCategoryId": 19,
        "venueId": 303,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 19,
          "title": "Private Islands",
          "region": "Global",
          "viewOrder": 15,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 476,
        "venueCategoryId": 26,
        "venueId": 303,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 26,
          "title": "Maldives Top 10",
          "region": "Indian Ocean",
          "viewOrder": 7,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 477,
        "venueCategoryId": 15,
        "venueId": 303,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 15,
          "title": "Beach Paradise",
          "region": "Global",
          "viewOrder": 6,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 505,
        "venueCategoryId": 27,
        "venueId": 303,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 27,
          "title": "Family Fun",
          "region": "Global",
          "viewOrder": 16,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 299,
    "venueCategoryId": 26,
    "status": 1,
    "title": "Four Seasons Resort at Landaa Giraavaru",
    "description": "Immerse yourself in the pristine tropical beauty of this remote island hideaway at Four Seasons Resort Maldives at Landaa Giraavaru. Experience the natural world in a luxurious resort setting. Combining innovation, wellness and conservation, Landaa Giraavaru is a 44 acre, 103 villa wonderland in the Baa Atoll UNESCO Biosphere Reserve.\n\nLandaa Giraavaru’s vast natural wilderness incorporates 103 thatched bungalows and villas of contemporary Maldivian design. Hidden behind traditional turquoise gates, all land-based accommodations have their own beach access, pool and lush genuinely private gardens. Beach Villas and Family Beach Villas also have a 12-metre lap pool, sand-floor living area with sofas and toes-in-the-sand dining, and sea-gazing loft. Perched on stilts over the tranquil lagoon, the Water Villas – some with pools – offer panoramic Indian Ocean views through floor to ceiling windows, spacious sundecks, outdoor showers, and nets for over-water lazing. Land & Ocean Villas link land-based living quarters and over-water bedrooms across a vast pool deck, while the uber-luxe Three-bedroom Landaa Estate has its own 80-metre beach and 2,000 square feet of al fresco living space.",
    "brand": "Four Seasons",
    "blockQuote": "Don't miss a visit to the Marine Center and the anti-gravity yoga is a lot of fun.",
    "emailAddress": "res.maldives@fourseasons.com",
    "website": "fourseasons.com/maldiveslg/",
    "bookingURL": "https://reservations.fourseasons.com/?hotelCode=MLE313&checkIn=2021-03-15&checkOut=2021-03-16&adults=2&children=0&iata=96027890",
    "redirectNotes": "Four Seasons Resort at Landaa Giraavaru's reservations system",
    "phoneNumber": "+960 66 00 888",
    "addressOne": "",
    "addressTwo": "",
    "city": "Baa Atoll",
    "county": "",
    "region": "",
    "country": "Maldives",
    "rooms": 103,
    "dollar": 3,
    "updated": "2023-11-24T11:28:55.25",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 43100,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a894f1b5-14d8-42f2-ab28-cbe10417c6ca/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a894f1b5-14d8-42f2-ab28-cbe10417c6ca/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a894f1b5-14d8-42f2-ab28-cbe10417c6ca/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 43093,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/85dd3d5c-09df-4a77-9583-806e62857035/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/85dd3d5c-09df-4a77-9583-806e62857035/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/85dd3d5c-09df-4a77-9583-806e62857035/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 46149,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e7add46b-2e21-467e-b6b7-6762fcb73073/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e7add46b-2e21-467e-b6b7-6762fcb73073/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e7add46b-2e21-467e-b6b7-6762fcb73073/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 43089,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/516824cc-dde7-41b7-b348-561a99f57cb9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/516824cc-dde7-41b7-b348-561a99f57cb9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/516824cc-dde7-41b7-b348-561a99f57cb9/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 43085,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a6250588-5a17-42de-a7be-bbcb88e7a8be/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a6250588-5a17-42de-a7be-bbcb88e7a8be/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a6250588-5a17-42de-a7be-bbcb88e7a8be/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 43088,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b06943d8-5876-48e0-8fae-ee391a4333fe/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b06943d8-5876-48e0-8fae-ee391a4333fe/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b06943d8-5876-48e0-8fae-ee391a4333fe/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 46152,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ad80c72c-8ee2-4a6c-9842-1fdccf451eb7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ad80c72c-8ee2-4a6c-9842-1fdccf451eb7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ad80c72c-8ee2-4a6c-9842-1fdccf451eb7/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 46151,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d00cd099-5f34-47c6-99e3-d100110aa366/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d00cd099-5f34-47c6-99e3-d100110aa366/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d00cd099-5f34-47c6-99e3-d100110aa366/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 43094,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/58a64fe3-1cb2-4bbc-b8fc-ad1ce1307d24/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/58a64fe3-1cb2-4bbc-b8fc-ad1ce1307d24/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/58a64fe3-1cb2-4bbc-b8fc-ad1ce1307d24/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 43098,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/111aae65-3fa4-48b7-8c20-a59f0cc23866/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/111aae65-3fa4-48b7-8c20-a59f0cc23866/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/111aae65-3fa4-48b7-8c20-a59f0cc23866/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 43096,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d8fa25b4-e6a2-4ef7-803f-270e8defc14d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d8fa25b4-e6a2-4ef7-803f-270e8defc14d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d8fa25b4-e6a2-4ef7-803f-270e8defc14d/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 43092,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/35fa2988-0128-4db9-b690-a07d5e98b185/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/35fa2988-0128-4db9-b690-a07d5e98b185/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/35fa2988-0128-4db9-b690-a07d5e98b185/256x192.jpg",
        "viewOrder": 11
      },
      {
        "mediaId": 46148,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e70789fd-ba5e-4f34-88b9-e7349b9af422/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e70789fd-ba5e-4f34-88b9-e7349b9af422/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e70789fd-ba5e-4f34-88b9-e7349b9af422/256x192.jpg",
        "viewOrder": 12
      },
      {
        "mediaId": 43099,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/357a53ff-611c-492c-9cc6-d001226954a6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/357a53ff-611c-492c-9cc6-d001226954a6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/357a53ff-611c-492c-9cc6-d001226954a6/256x192.jpg",
        "viewOrder": 13
      },
      {
        "mediaId": 43090,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/11d0c926-da97-486e-8e47-0cf38b00ad70/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/11d0c926-da97-486e-8e47-0cf38b00ad70/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/11d0c926-da97-486e-8e47-0cf38b00ad70/256x192.jpg",
        "viewOrder": 14
      }
    ],
    "geographicArea": {
      "geographicAreaId": 160,
      "latitude": 4.8709599,
      "longitude": 72.99982120000004,
      "parentGeographicAreaId": 0,
      "title": "Baa Atoll, Maldives",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 157,
        "tagName": "Beach Escape",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 58,
        "tagName": "Beach",
        "tagType": 2
      },
      {
        "tagId": 70,
        "tagName": "Families",
        "tagType": 2
      },
      {
        "tagId": 83,
        "tagName": "Kid's Club",
        "tagType": 2
      },
      {
        "tagId": 88,
        "tagName": "Tennis",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 109,
        "tagName": "Fishing",
        "tagType": 2
      },
      {
        "tagId": 116,
        "tagName": "Scuba Diving",
        "tagType": 2
      },
      {
        "tagId": 276,
        "tagName": "Gym",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 38,
        "venueCategoryId": 15,
        "venueId": 299,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 15,
          "title": "Beach Paradise",
          "region": "Global",
          "viewOrder": 6,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 479,
        "venueCategoryId": 26,
        "venueId": 299,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 26,
          "title": "Maldives Top 10",
          "region": "Indian Ocean",
          "viewOrder": 7,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 504,
        "venueCategoryId": 27,
        "venueId": 299,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 27,
          "title": "Family Fun",
          "region": "Global",
          "viewOrder": 16,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 613,
        "venueCategoryId": 32,
        "venueId": 299,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  }
]
// 20240123100509
// https://app.pks-list.com/api/Venue/v1/getlist/35

export const placesStore35=[
  {
    "venueId": 3883,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Alila Kothaifaru Maldives",
    "description": "Surrounded by a stunning house reef in the scenic Raa Atoll, Alila Kothaifaru Maldives is a private island retreat set in an awe-inspiring destination embraced by nature’s wonders.\n\nUnwind in peaceful seclusion with awe-inspiring views at this all-pool-villa sanctuary. Our 11.2 hectare private island retreat lies at the northern edge of the Maldives in Raa Atoll, accessible via a scenic 45-minute seaplane journey from Malé. Lush vegetation, turquoise waters and abundant marine life set the scene for a relaxing stay interspersed with adventure.",
    "brand": "",
    "blockQuote": "Yakitori serves some of the best sake cocktails anywhere.",
    "emailAddress": "kothaifaru@alilahotels.com",
    "website": "alilahotels.com/kothaifaru-maldives/",
    "bookingURL": "https://www.hyatt.com/shop/rooms/MLDAL?adults=1&kids=0&rate=Standard&checkinDate=2023-12-29&checkoutDate=2024-01-02&rooms=1&accessibilityCheck=false&iata=96027890&rateFilter=",
    "redirectNotes": "Alila Kothaifaru Maldives' reservations sytem",
    "phoneNumber": "+96 0659 1234",
    "addressOne": "",
    "addressTwo": "",
    "city": "Raa Atoll",
    "county": "",
    "region": "",
    "country": "Maldives",
    "rooms": 80,
    "dollar": 3,
    "updated": "2023-12-29T07:27:10.957",
    "created": "2023-12-29T06:13:47.833",
    "medias": [
      {
        "mediaId": 63477,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/12cf3908-d5ea-47c7-8a50-0a7a3c853df5/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/12cf3908-d5ea-47c7-8a50-0a7a3c853df5/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/12cf3908-d5ea-47c7-8a50-0a7a3c853df5/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 63484,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/00674a18-4b12-4361-8300-d338026b321e/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/00674a18-4b12-4361-8300-d338026b321e/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/00674a18-4b12-4361-8300-d338026b321e/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 63478,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/4ca1d52a-19d7-46bd-be1f-7d21f0159531/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4ca1d52a-19d7-46bd-be1f-7d21f0159531/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4ca1d52a-19d7-46bd-be1f-7d21f0159531/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 63480,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/545ddc9a-7c52-4019-96ee-f0da93ee4f5c/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/545ddc9a-7c52-4019-96ee-f0da93ee4f5c/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/545ddc9a-7c52-4019-96ee-f0da93ee4f5c/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 63496,
        "title": "Beach Villa - Alila Kothaifaru Maldives | ADORE Maldives",
        "url": "https://pkslist.azureedge.net/media/images/1/9f600f05-de07-4457-8e0b-94a3042ac8e2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9f600f05-de07-4457-8e0b-94a3042ac8e2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9f600f05-de07-4457-8e0b-94a3042ac8e2/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 63486,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/0ee086fd-0166-4854-9ac3-f8a29bf01b6c/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0ee086fd-0166-4854-9ac3-f8a29bf01b6c/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0ee086fd-0166-4854-9ac3-f8a29bf01b6c/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 63475,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/8ba24041-740d-472c-902b-93f0cc7f63ea/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8ba24041-740d-472c-902b-93f0cc7f63ea/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8ba24041-740d-472c-902b-93f0cc7f63ea/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 63476,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/92e279d5-d892-4ec9-a397-3cf8a8ad8a50/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/92e279d5-d892-4ec9-a397-3cf8a8ad8a50/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/92e279d5-d892-4ec9-a397-3cf8a8ad8a50/256x192.jpeg",
        "viewOrder": 11
      },
      {
        "mediaId": 63488,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/7cfac8e1-c335-45f0-842f-2cc2916c0b67/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7cfac8e1-c335-45f0-842f-2cc2916c0b67/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7cfac8e1-c335-45f0-842f-2cc2916c0b67/256x192.jpeg",
        "viewOrder": 12
      },
      {
        "mediaId": 63479,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e713b8f7-07b8-4625-bbe0-9ae2cfc60394/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e713b8f7-07b8-4625-bbe0-9ae2cfc60394/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e713b8f7-07b8-4625-bbe0-9ae2cfc60394/256x192.jpeg",
        "viewOrder": 14
      },
      {
        "mediaId": 63489,
        "title": "Alila Kothaifaru Maldives — Hotel Review | Condé Nast Traveler",
        "url": "https://pkslist.azureedge.net/media/images/1/a4a1f91c-ad0e-49f7-af5a-393d5bb9fa95/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a4a1f91c-ad0e-49f7-af5a-393d5bb9fa95/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a4a1f91c-ad0e-49f7-af5a-393d5bb9fa95/256x192.jpg",
        "viewOrder": 15
      },
      {
        "mediaId": 63482,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/31f4207f-f243-4574-a45e-36e999718c18/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/31f4207f-f243-4574-a45e-36e999718c18/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/31f4207f-f243-4574-a45e-36e999718c18/256x192.jpeg",
        "viewOrder": 18
      },
      {
        "mediaId": 63485,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/8238b935-5d8f-4660-85ad-3eb5e5d72db7/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8238b935-5d8f-4660-85ad-3eb5e5d72db7/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8238b935-5d8f-4660-85ad-3eb5e5d72db7/256x192.jpeg",
        "viewOrder": 20
      },
      {
        "mediaId": 63487,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/168c62da-35af-4f9d-b803-a785248bb93e/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/168c62da-35af-4f9d-b803-a785248bb93e/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/168c62da-35af-4f9d-b803-a785248bb93e/256x192.jpeg",
        "viewOrder": 21
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3665,
      "latitude": 5.525348000000001,
      "longitude": 72.8430242,
      "parentGeographicAreaId": 0,
      "title": "Kothaifaru Island, Maldives",
      "updated": null,
      "created": "2023-12-29T06:25:00"
    },
    "personalities": [
      {
        "tagId": 157,
        "tagName": "Beach Escape",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 83,
        "tagName": "Kid's Club",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 116,
        "tagName": "Scuba Diving",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 206,
        "tagName": "Private Beach",
        "tagType": 2
      },
      {
        "tagId": 256,
        "tagName": "Boutique",
        "tagType": 2
      },
      {
        "tagId": 276,
        "tagName": "Gym",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 410,
        "tagName": "Sundownders",
        "tagType": 2
      },
      {
        "tagId": 448,
        "tagName": "Padel",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 863,
        "venueCategoryId": 35,
        "venueId": 3883,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 864,
        "venueCategoryId": 15,
        "venueId": 3883,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 15,
          "title": "Beach Paradise",
          "region": "Global",
          "viewOrder": 6,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3837,
    "venueCategoryId": 0,
    "status": 1,
    "title": "The St. Regis Red Sea Resort",
    "description": "Located on a pristine private island, The St. Regis Red Sea Resort presents a compelling vision of exquisite living in the Ummahat archipelago. Fashioned from one of the world’s little known natural treasures, the destination artfully blends the brand’s visionary spirit, avant-garde style and bespoke service with a progressive new benchmark for sustainable development. Accessed from the coast of Saudi Arabia via chartered boat or seaplane, the island occupies a secluded position in the pioneering The Red Sea project. A rich environment of timeless glamor, the collection of 90 overwater and beachfront villas, each have a spacious footprint and private pool. Creating a setting for immersive experiences, the resort includes outdoor pools, high tech fitness center, The St. Regis Spa and Children’s Club, alongside exceptional fine dining. Each stay is enhanced by a curated selection of signature rituals and the iconic St. Regis Butler service.",
    "brand": "St. Regis",
    "blockQuote": "A dream destination for lovers of wildlife, marine life, and otherworldly natural wonders.",
    "emailAddress": "reservations.redsea@stregis.com",
    "website": "marriott.com/en-us/hotels/ejhxr-the-st-regis-red-sea-resort/overview/",
    "bookingURL": "https://www.marriott.com/search/findHotels.mi?roomCount=1&toDate=12/20/2023&numAdultsPerRoom=1&flexibleDateSearch=false&destinationAddress.city=Red+Sea&destinationAddress.longitude=36.745282067805874&fromDate=12/19/2023&unsuccessfulSell=true&propertyCode=ejhxr&destinationAddress.location=Ummahat+Island+1,+Red+Sea,+SA&singleSearch=true&singleSearchAutoSuggest=true&useRewardsPoints=false&countryName=SA&destinationAddress.latitude=25.547434955386944&childrenCount=0&view=list#/0/",
    "redirectNotes": "The St. Regis Red Sea Resort's reservations system",
    "phoneNumber": "+966 1 4504 0000",
    "addressOne": "",
    "addressTwo": "",
    "city": "Ummahat Island",
    "county": "",
    "region": "",
    "country": "Saudi Arabia",
    "rooms": 90,
    "dollar": 3,
    "updated": "2023-12-21T15:03:24.533",
    "created": "2023-12-19T10:44:34.74",
    "medias": [
      {
        "mediaId": 62429,
        "title": "Marriott International Signs Agreement with The Red Sea ...",
        "url": "https://pkslist.azureedge.net/media/images/1/a0ddb604-da30-466e-8123-0be67c2ccf84/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a0ddb604-da30-466e-8123-0be67c2ccf84/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a0ddb604-da30-466e-8123-0be67c2ccf84/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 62416,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/6b46fee2-63cf-4206-a84e-e3b14ce8e0bd/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6b46fee2-63cf-4206-a84e-e3b14ce8e0bd/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6b46fee2-63cf-4206-a84e-e3b14ce8e0bd/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 62422,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/66dd73ff-2042-4005-86de-ee6a630b6030/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/66dd73ff-2042-4005-86de-ee6a630b6030/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/66dd73ff-2042-4005-86de-ee6a630b6030/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 62418,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d01d2f14-5d25-41d8-9152-584473e33f1f/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d01d2f14-5d25-41d8-9152-584473e33f1f/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d01d2f14-5d25-41d8-9152-584473e33f1f/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 62421,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/247b750b-1b77-4b66-bc74-3d5d19701b49/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/247b750b-1b77-4b66-bc74-3d5d19701b49/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/247b750b-1b77-4b66-bc74-3d5d19701b49/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 62425,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/3664b90f-e18e-4a8d-8b1d-313ace19bbea/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3664b90f-e18e-4a8d-8b1d-313ace19bbea/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3664b90f-e18e-4a8d-8b1d-313ace19bbea/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 62428,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/0f95ad42-2c42-44aa-a80b-7aaf59bfec75/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0f95ad42-2c42-44aa-a80b-7aaf59bfec75/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0f95ad42-2c42-44aa-a80b-7aaf59bfec75/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 62427,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/0a3d942c-bfc3-467b-b9a8-9357c77c468c/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0a3d942c-bfc3-467b-b9a8-9357c77c468c/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0a3d942c-bfc3-467b-b9a8-9357c77c468c/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 62423,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a44b64d6-ecf0-4ab6-9c0f-65bd7b2b0ba5/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a44b64d6-ecf0-4ab6-9c0f-65bd7b2b0ba5/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a44b64d6-ecf0-4ab6-9c0f-65bd7b2b0ba5/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 62419,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/5125e4d8-a312-49fc-96bb-cf317a2e48aa/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5125e4d8-a312-49fc-96bb-cf317a2e48aa/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5125e4d8-a312-49fc-96bb-cf317a2e48aa/256x192.jpeg",
        "viewOrder": 9
      },
      {
        "mediaId": 62420,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/8c8d980b-61f1-40b0-bcc5-3a17d987ace8/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8c8d980b-61f1-40b0-bcc5-3a17d987ace8/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8c8d980b-61f1-40b0-bcc5-3a17d987ace8/256x192.jpeg",
        "viewOrder": 10
      },
      {
        "mediaId": 62415,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a1d6c323-2019-4ff7-b615-75f5605d4fbe/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a1d6c323-2019-4ff7-b615-75f5605d4fbe/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a1d6c323-2019-4ff7-b615-75f5605d4fbe/256x192.jpeg",
        "viewOrder": 12
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3588,
      "latitude": 25.5470187,
      "longitude": 36.7454001,
      "parentGeographicAreaId": 0,
      "title": "7GQRGPWW+R5",
      "updated": null,
      "created": "2023-12-21T15:03:00"
    },
    "personalities": [
      {
        "tagId": 157,
        "tagName": "Beach Escape",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 78,
        "tagName": "Fine Dining",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 83,
        "tagName": "Kid's Club",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 181,
        "tagName": "Private/Suite Pools",
        "tagType": 2
      },
      {
        "tagId": 183,
        "tagName": "Butler Service",
        "tagType": 2
      },
      {
        "tagId": 198,
        "tagName": "Boat Excursion",
        "tagType": 2
      },
      {
        "tagId": 206,
        "tagName": "Private Beach",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 853,
        "venueCategoryId": 19,
        "venueId": 3837,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 19,
          "title": "Private Islands",
          "region": "Global",
          "viewOrder": 15,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 854,
        "venueCategoryId": 35,
        "venueId": 3837,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3836,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Six Senses Southern Dunes, The Red Sea",
    "description": "We invite you on an exciting journey into seldom-experienced nature, wildlife, and culture. Located along the historic incense trade route against the backdrop of desert plains and the Hijaz Mountains, Six Senses Southern Dunes, The Red Sea resort pays homage to Nabataean architectural heritage and majestic desert surroundings while introducing our trademark crafted experiences and wellness approach.",
    "brand": "Six Senses",
    "blockQuote": "A visit to the Six Senses Spa will be a highlight of your stay.",
    "emailAddress": "reservations-southerndunes@sixsenses.com",
    "website": "sixsenses.com/en/resorts/southern-dunes-the-red-sea",
    "bookingURL": "https://www.sixsenses.com/en/resorts/southern-dunes-the-red-sea/accomodation-results?newSearch=1&src=&Hotel=RSISD&Rooms=1&Adult=2&Child=0&iata=96027890",
    "redirectNotes": "Six Senses Southern Dunes, The Red Sea's reservations system",
    "phoneNumber": "+66 2631 9777",
    "addressOne": "",
    "addressTwo": "",
    "city": "Umluj",
    "county": "",
    "region": "",
    "country": "Saudi Arabia",
    "rooms": 76,
    "dollar": 3,
    "updated": "2023-12-21T15:05:10.03",
    "created": "2023-12-19T08:57:56.82",
    "medias": [
      {
        "mediaId": 62402,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/1edf03d5-5c74-4c31-b7d3-b1840be11fb0/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1edf03d5-5c74-4c31-b7d3-b1840be11fb0/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1edf03d5-5c74-4c31-b7d3-b1840be11fb0/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 62410,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a5540b2a-6023-4372-aac2-7d0257348c95/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a5540b2a-6023-4372-aac2-7d0257348c95/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a5540b2a-6023-4372-aac2-7d0257348c95/256x192.png",
        "viewOrder": 1
      },
      {
        "mediaId": 62412,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/497c9230-b757-4c90-9330-67e3c9bc83d6/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/497c9230-b757-4c90-9330-67e3c9bc83d6/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/497c9230-b757-4c90-9330-67e3c9bc83d6/256x192.png",
        "viewOrder": 2
      },
      {
        "mediaId": 62404,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/06c7ea58-08bd-4d3f-930b-4f9d159c0130/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/06c7ea58-08bd-4d3f-930b-4f9d159c0130/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/06c7ea58-08bd-4d3f-930b-4f9d159c0130/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 62406,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c5366922-80b3-44a1-8082-e54c90cb438b/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c5366922-80b3-44a1-8082-e54c90cb438b/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c5366922-80b3-44a1-8082-e54c90cb438b/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 62411,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/3169d002-bf3f-4900-b526-b5e80b6c77e4/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3169d002-bf3f-4900-b526-b5e80b6c77e4/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3169d002-bf3f-4900-b526-b5e80b6c77e4/256x192.png",
        "viewOrder": 7
      },
      {
        "mediaId": 62403,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/150ccf7b-acad-48d4-a97b-51c31859d03f/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/150ccf7b-acad-48d4-a97b-51c31859d03f/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/150ccf7b-acad-48d4-a97b-51c31859d03f/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 62407,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/4de7b9af-0e16-4385-b9c0-ec8d19676236/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4de7b9af-0e16-4385-b9c0-ec8d19676236/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4de7b9af-0e16-4385-b9c0-ec8d19676236/256x192.jpeg",
        "viewOrder": 9
      },
      {
        "mediaId": 62413,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d9de9664-8970-47d0-a3fb-ca353100b5a9/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d9de9664-8970-47d0-a3fb-ca353100b5a9/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d9de9664-8970-47d0-a3fb-ca353100b5a9/256x192.png",
        "viewOrder": 10
      },
      {
        "mediaId": 62408,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/305bf8e8-a783-4d86-ab99-9a70982f2420/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/305bf8e8-a783-4d86-ab99-9a70982f2420/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/305bf8e8-a783-4d86-ab99-9a70982f2420/256x192.png",
        "viewOrder": 11
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3590,
      "latitude": 25.3788588,
      "longitude": 37.330808,
      "parentGeographicAreaId": 0,
      "title": "The Red Sea Development, Umluj 48321, Saudi Arabia",
      "updated": null,
      "created": "2023-12-21T15:05:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 77,
        "tagName": "Cooking Classes",
        "tagType": 2
      },
      {
        "tagId": 162,
        "tagName": "Desert",
        "tagType": 2
      },
      {
        "tagId": 263,
        "tagName": "Star Gazing",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 299,
        "tagName": "Wellness Programs",
        "tagType": 2
      },
      {
        "tagId": 324,
        "tagName": "Local Village",
        "tagType": 2
      },
      {
        "tagId": 410,
        "tagName": "Sundownders",
        "tagType": 2
      },
      {
        "tagId": 457,
        "tagName": "Outdoor Cinema",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 851,
        "venueCategoryId": 35,
        "venueId": 3836,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 852,
        "venueCategoryId": 34,
        "venueId": 3836,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3835,
    "venueCategoryId": 0,
    "status": 1,
    "title": "The Fifth Avenue Hotel",
    "description": "Exquisitely and eclectically designed, The Fifth Avenue Hotel offers a realm of imagination to delight the senses in the energetic heart of Manhattan.\n\nA Gilded Age landmark reimagined as a luxury destination, The Fifth offers thoughtful service with the utmost comfort and care, with exceptional cuisine by Chef Andrew Carmellini. Welcome to a world of intimate ambience, elegance and charm.",
    "brand": "",
    "blockQuote": "Don't miss the nightly signature martini ritual when a butler makes your perfect martini right in your room.",
    "emailAddress": "reservations@thefifthavenuehotel.com",
    "website": "thefifthavenuehotel.com/",
    "bookingURL": "https://www.thefifthavenuehotel.com/contact/#/booking/step-2?data=('hAhd!'fifth-avenue-new'~ae!'12%2F19%2F2023'~dt!'01%2F04%2F2024'~fs.~rAat!2~cn!0~cg.~al7po5gp5rn.)8ry5rk5re.~rr*)8ax!0~cy5ds!('pe5ls5as5st*)~my7se5ce5ne*)*!null.6%5D5*~6!%5B7!false~8%5D~As6('%01A8765.*_",
    "redirectNotes": "The Fifth Avenue Hotel's reservations system",
    "phoneNumber": "+1 212 231 9400",
    "addressOne": "1",
    "addressTwo": "West 28th Street",
    "city": "NoMad",
    "county": "New York",
    "region": "New York",
    "country": "United States",
    "rooms": 153,
    "dollar": 3,
    "updated": "2023-12-21T15:05:50.837",
    "created": "2023-12-19T06:05:50.693",
    "medias": [
      {
        "mediaId": 62387,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/2cfaa416-6e61-496e-8854-17ad79a49a09/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2cfaa416-6e61-496e-8854-17ad79a49a09/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2cfaa416-6e61-496e-8854-17ad79a49a09/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 62398,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b8d4306a-8cc3-4b41-81ad-cf872df617f4/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b8d4306a-8cc3-4b41-81ad-cf872df617f4/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b8d4306a-8cc3-4b41-81ad-cf872df617f4/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 62386,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/196d21c2-7889-4c93-a8f6-5559f97c6abe/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/196d21c2-7889-4c93-a8f6-5559f97c6abe/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/196d21c2-7889-4c93-a8f6-5559f97c6abe/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 62390,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ce17e321-0d07-43f3-b7ba-122253f2c3be/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ce17e321-0d07-43f3-b7ba-122253f2c3be/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ce17e321-0d07-43f3-b7ba-122253f2c3be/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 62391,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/5003c29b-eb95-48ed-8628-739f6c7a3a0e/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5003c29b-eb95-48ed-8628-739f6c7a3a0e/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5003c29b-eb95-48ed-8628-739f6c7a3a0e/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 62388,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/df846579-cbf7-4704-9619-feb1f05fd70a/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/df846579-cbf7-4704-9619-feb1f05fd70a/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/df846579-cbf7-4704-9619-feb1f05fd70a/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 62392,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/293cedd9-6193-4a1a-9ff2-952335acf649/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/293cedd9-6193-4a1a-9ff2-952335acf649/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/293cedd9-6193-4a1a-9ff2-952335acf649/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 62389,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/93e344d9-73fc-443b-b725-ec42d1f7b99c/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/93e344d9-73fc-443b-b725-ec42d1f7b99c/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/93e344d9-73fc-443b-b725-ec42d1f7b99c/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 62399,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/4af43bca-54cc-4658-ba87-eec3ce604425/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4af43bca-54cc-4658-ba87-eec3ce604425/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4af43bca-54cc-4658-ba87-eec3ce604425/256x192.jpeg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3591,
      "latitude": 40.7450774,
      "longitude": -73.9877319,
      "parentGeographicAreaId": 0,
      "title": "1 W 28th St, New York, NY 10001, USA",
      "updated": null,
      "created": "2023-12-21T15:06:00"
    },
    "personalities": [
      {
        "tagId": 46,
        "tagName": "Formal Luxury ",
        "tagType": 1
      },
      {
        "tagId": 48,
        "tagName": "Iconic/Landmark ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 62,
        "tagName": "Nightlife",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 155,
        "tagName": "Art",
        "tagType": 2
      },
      {
        "tagId": 183,
        "tagName": "Butler Service",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 216,
        "tagName": "Museum",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 294,
        "tagName": "Event Space",
        "tagType": 2
      },
      {
        "tagId": 323,
        "tagName": "City Walk",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 850,
        "venueCategoryId": 35,
        "venueId": 3835,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3819,
    "venueCategoryId": 0,
    "status": 1,
    "title": "1 Place Vendome",
    "description": "15 suites and rooms from 30 to 130 m². Wellness area (treatment room, sauna and yoga room) A table d'hôtes: free access to hotel guests, a controlled culinary offer at the service of clients, A butler service: a person in regular contact with clients, dedicated for the duration of their stay. Facilitating exchanges and requests through the whatsapp messaging system, A concierge service, An in-room check-in service, 4 lounges on the same floor as real living spaces, A \"library\" lounge ideal for a quiet moment during the day or a drink in the evening, A lounge with a bar and a fireplace offering quality light meals, A \"winter garden\" lounge to enjoy a poetic decor bathed in daylight, A catering offer on demand: of the best possible quality, offering seasonal cuisine with the expected classics. Room service at any time of the day or night (24 hours a day).",
    "brand": "",
    "blockQuote": "Peek into the Chopard Store next door for a little gem.",
    "emailAddress": "concierge@1-placevendome.com",
    "website": "1-placevendome.com/en",
    "bookingURL": "https://be.synxis.com/?adult=1&agencyid=96027890&arrive=2023-12-15&chain=27613&child=0&currency=EUR&depart=2023-12-16&hotel=40900&level=hotel&locale=en-US&rooms=1",
    "redirectNotes": "1 Place Vendome's reservations system",
    "phoneNumber": "+33 1 55 04 55 00",
    "addressOne": "1",
    "addressTwo": "Place Vendôme",
    "city": "Paris",
    "county": "",
    "region": "",
    "country": "France",
    "rooms": 15,
    "dollar": 3,
    "updated": "2023-12-21T15:47:36.92",
    "created": "2023-12-15T09:36:01.783",
    "medias": [
      {
        "mediaId": 62107,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/cf43fc8e-d480-4fd3-9855-832f400c5cf1/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cf43fc8e-d480-4fd3-9855-832f400c5cf1/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cf43fc8e-d480-4fd3-9855-832f400c5cf1/256x192.png",
        "viewOrder": 0
      },
      {
        "mediaId": 62103,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/9a946c5c-3c6c-4bbb-9c77-a371893edb44/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9a946c5c-3c6c-4bbb-9c77-a371893edb44/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9a946c5c-3c6c-4bbb-9c77-a371893edb44/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 62112,
        "title": "1 Place Vendome is one of the best restaurants in Paris",
        "url": "https://pkslist.azureedge.net/media/images/1/670ece07-41f1-4d5b-815a-01a45097b3a8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/670ece07-41f1-4d5b-815a-01a45097b3a8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/670ece07-41f1-4d5b-815a-01a45097b3a8/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 62106,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/2fbcc012-0056-4536-b546-f97faaa30f30/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2fbcc012-0056-4536-b546-f97faaa30f30/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2fbcc012-0056-4536-b546-f97faaa30f30/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 62108,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/8f35c138-36d9-4724-91df-cbdc90f1ea4a/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8f35c138-36d9-4724-91df-cbdc90f1ea4a/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8f35c138-36d9-4724-91df-cbdc90f1ea4a/256x192.png",
        "viewOrder": 4
      },
      {
        "mediaId": 62110,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/1a228d0b-bfb7-432c-8b32-3d52d9341383/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1a228d0b-bfb7-432c-8b32-3d52d9341383/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1a228d0b-bfb7-432c-8b32-3d52d9341383/256x192.png",
        "viewOrder": 5
      },
      {
        "mediaId": 62104,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a9aaf45b-a8c9-47e3-8fee-51c8a280ed56/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a9aaf45b-a8c9-47e3-8fee-51c8a280ed56/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a9aaf45b-a8c9-47e3-8fee-51c8a280ed56/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 62109,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/53db1934-51a7-4171-bbff-de3c11359fe0/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/53db1934-51a7-4171-bbff-de3c11359fe0/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/53db1934-51a7-4171-bbff-de3c11359fe0/256x192.png",
        "viewOrder": 7
      },
      {
        "mediaId": 62111,
        "title": "Chopard has just inaugurated its first ever hotel, at Place ...",
        "url": "https://pkslist.azureedge.net/media/images/1/44413153-a18a-40da-a3e8-c5b2db1c6e9e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/44413153-a18a-40da-a3e8-c5b2db1c6e9e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/44413153-a18a-40da-a3e8-c5b2db1c6e9e/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3610,
      "latitude": 48.86685299999999,
      "longitude": 2.3284409,
      "parentGeographicAreaId": 0,
      "title": "1 Pl. Vendôme, 75001 Paris, France",
      "updated": null,
      "created": "2023-12-21T15:48:00"
    },
    "personalities": [
      {
        "tagId": 40,
        "tagName": "Boutique Bolthole ",
        "tagType": 1
      },
      {
        "tagId": 51,
        "tagName": "Style Icon",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 62,
        "tagName": "Nightlife",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 93,
        "tagName": "Private",
        "tagType": 2
      },
      {
        "tagId": 104,
        "tagName": "Cycling",
        "tagType": 2
      },
      {
        "tagId": 183,
        "tagName": "Butler Service",
        "tagType": 2
      },
      {
        "tagId": 216,
        "tagName": "Museum",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 332,
        "tagName": "Fashion",
        "tagType": 2
      },
      {
        "tagId": 404,
        "tagName": "Sightseeing",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 848,
        "venueCategoryId": 35,
        "venueId": 3819,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3815,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Habitas Santa Teresa",
    "description": "Nestled in the jungle in front of one of the region’s best surf breaks, our home in Santa Teresa, Costa Rica, is a beachfront sanctuary for those looking for an experience of adventure and discovery in a place where time is told in tides.",
    "brand": "",
    "blockQuote": "The best place for hydrofoiling and surfers and of all levels from sunrise to sunset.",
    "emailAddress": "reservations.santateresa@ourhabitas.com",
    "website": "ourhabitas.com/santa-teresa/",
    "bookingURL": "https://www.ourhabitas.com/santa-teresa/",
    "redirectNotes": "Habitas Santa Teresa's reservations system",
    "phoneNumber": "+1 845 318 9185",
    "addressOne": "",
    "addressTwo": "",
    "city": "Santa Teresa",
    "county": "",
    "region": "",
    "country": "Costa Rica",
    "rooms": 55,
    "dollar": 2,
    "updated": "2023-12-22T06:27:40.327",
    "created": "2023-12-13T12:57:48.983",
    "medias": [
      {
        "mediaId": 62005,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b76f7e8f-a28c-4f15-94d9-89b20b80470e/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b76f7e8f-a28c-4f15-94d9-89b20b80470e/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b76f7e8f-a28c-4f15-94d9-89b20b80470e/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 62006,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/1f301e90-2994-4a91-a012-27ed001fec13/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1f301e90-2994-4a91-a012-27ed001fec13/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1f301e90-2994-4a91-a012-27ed001fec13/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 62002,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/52a4368c-a93e-4b5f-9e29-c7d3107da83f/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/52a4368c-a93e-4b5f-9e29-c7d3107da83f/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/52a4368c-a93e-4b5f-9e29-c7d3107da83f/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 61997,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/6b450d7d-8a21-4884-86ee-5724047f3da3/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6b450d7d-8a21-4884-86ee-5724047f3da3/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6b450d7d-8a21-4884-86ee-5724047f3da3/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 62003,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/5cefe952-16c4-4954-931b-ea8d99b3fb0b/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5cefe952-16c4-4954-931b-ea8d99b3fb0b/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5cefe952-16c4-4954-931b-ea8d99b3fb0b/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 62001,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f4004a69-2a01-4418-8865-dcaba89836ba/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f4004a69-2a01-4418-8865-dcaba89836ba/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f4004a69-2a01-4418-8865-dcaba89836ba/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 62004,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/6472f398-8167-4eb3-93db-ef53d0ab0b51/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6472f398-8167-4eb3-93db-ef53d0ab0b51/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6472f398-8167-4eb3-93db-ef53d0ab0b51/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 62000,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/8adbeadb-f957-4431-accc-9fbe35fc10de/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8adbeadb-f957-4431-accc-9fbe35fc10de/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8adbeadb-f957-4431-accc-9fbe35fc10de/256x192.jpeg",
        "viewOrder": 9
      },
      {
        "mediaId": 62007,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/93aff0b9-1ecd-4e7b-a0c5-fca6e71d2547/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/93aff0b9-1ecd-4e7b-a0c5-fca6e71d2547/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/93aff0b9-1ecd-4e7b-a0c5-fca6e71d2547/256x192.jpeg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3613,
      "latitude": 9.628181399999999,
      "longitude": -85.1508617,
      "parentGeographicAreaId": 0,
      "title": "CRUZE PLAYA CARMEN CONDO PLAYA CARMEN LOCAL #4 PUNTARENAS, Provincia de Puntarenas, Puntarenas, Costa Rica",
      "updated": null,
      "created": "2023-12-22T06:28:00"
    },
    "personalities": [
      {
        "tagId": 50,
        "tagName": "Barefoot Luxury",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 58,
        "tagName": "Beach",
        "tagType": 2
      },
      {
        "tagId": 82,
        "tagName": "Horseback Riding",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 122,
        "tagName": "Surfing",
        "tagType": 2
      },
      {
        "tagId": 171,
        "tagName": "Trekking",
        "tagType": 2
      },
      {
        "tagId": 310,
        "tagName": "ATV Touring",
        "tagType": 2
      },
      {
        "tagId": 344,
        "tagName": "Waterfall",
        "tagType": 2
      },
      {
        "tagId": 362,
        "tagName": "Sustainable",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 847,
        "venueCategoryId": 35,
        "venueId": 3815,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3804,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Six Senses Kanuhura",
    "description": "An iconic Maldivian island, reborn. This laid-back retreat is known for being home to the finest beaches in the Maldives. Encompassing three private islands, Six Senses Kanuhura resort is a short seaplane ride from Malé International Airport or speedboat transfer from the pristine Lhaviyani Atoll. With 91 spacious beach, family, and overwater pool villas, the lagoon is at your feet, where the vibrant reef protects our resident turtles and marine life. Adventures abound in this paradise found with food, fun, family moments and new friends when you seek connection, and peaceful seclusion when you seek an island escape.",
    "brand": "Six Senses",
    "blockQuote": "The Sunken Table Dining Experience is sure to impress your loved one(s).",
    "emailAddress": "reservations-maldives@sixsenses.com",
    "website": "sixsenses.com/en/resorts/kanuhura/",
    "bookingURL": "https://www.sixsenses.com/en/resorts/kanuhura/accommodation-results?newSearch=1&src=&Hotel=DRVKH&Rooms=1&Adult=2&Child=0&_PMID=96027890",
    "redirectNotes": "Six Senses Kanuhura's reservations system",
    "phoneNumber": "+960 662 0044",
    "addressOne": "",
    "addressTwo": "",
    "city": "Lhaviyani Atoll",
    "county": "",
    "region": "",
    "country": "Maldives",
    "rooms": 91,
    "dollar": 3,
    "updated": "2023-12-22T06:36:17.34",
    "created": "2023-12-06T08:14:05.89",
    "medias": [
      {
        "mediaId": 61736,
        "title": "Aerial view - Picture of Six Senses Kanuhura - Tripadvisor",
        "url": "https://pkslist.azureedge.net/media/images/1/7a31427d-b3b1-48ac-a3c9-9b424709c3c7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7a31427d-b3b1-48ac-a3c9-9b424709c3c7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7a31427d-b3b1-48ac-a3c9-9b424709c3c7/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 61741,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/27f0de74-bdd8-40d3-bff5-b5f0481c88c2/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/27f0de74-bdd8-40d3-bff5-b5f0481c88c2/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/27f0de74-bdd8-40d3-bff5-b5f0481c88c2/256x192.png",
        "viewOrder": 1
      },
      {
        "mediaId": 61738,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/16ca90d7-42f3-4c76-9084-75fa84545a51/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/16ca90d7-42f3-4c76-9084-75fa84545a51/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/16ca90d7-42f3-4c76-9084-75fa84545a51/256x192.png",
        "viewOrder": 2
      },
      {
        "mediaId": 61740,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/54e2fc36-3946-4bc9-994f-d14529494236/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/54e2fc36-3946-4bc9-994f-d14529494236/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/54e2fc36-3946-4bc9-994f-d14529494236/256x192.png",
        "viewOrder": 3
      },
      {
        "mediaId": 61739,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e1d22b65-7b6e-46e5-9cfe-9bfb2ab71ff5/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e1d22b65-7b6e-46e5-9cfe-9bfb2ab71ff5/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e1d22b65-7b6e-46e5-9cfe-9bfb2ab71ff5/256x192.png",
        "viewOrder": 4
      },
      {
        "mediaId": 61731,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d4894746-3b5e-4e15-8209-c3a80f39a472/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d4894746-3b5e-4e15-8209-c3a80f39a472/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d4894746-3b5e-4e15-8209-c3a80f39a472/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 61733,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/3a6ed18e-35c0-4e14-ac9d-49d856aab40e/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3a6ed18e-35c0-4e14-ac9d-49d856aab40e/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3a6ed18e-35c0-4e14-ac9d-49d856aab40e/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 61742,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/eebd57b8-0230-4479-a3e8-ba560acb9e7a/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/eebd57b8-0230-4479-a3e8-ba560acb9e7a/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/eebd57b8-0230-4479-a3e8-ba560acb9e7a/256x192.png",
        "viewOrder": 7
      },
      {
        "mediaId": 61743,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b4d964cf-1485-49c6-a74c-6c658b47da4f/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b4d964cf-1485-49c6-a74c-6c658b47da4f/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b4d964cf-1485-49c6-a74c-6c658b47da4f/256x192.png",
        "viewOrder": 8
      },
      {
        "mediaId": 61744,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/4a4a6150-23e4-4619-b25f-033e2e382ad5/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4a4a6150-23e4-4619-b25f-033e2e382ad5/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4a4a6150-23e4-4619-b25f-033e2e382ad5/256x192.png",
        "viewOrder": 9
      },
      {
        "mediaId": 61745,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/59c50850-c87c-4664-b752-ba79db7999fe/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/59c50850-c87c-4664-b752-ba79db7999fe/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/59c50850-c87c-4664-b752-ba79db7999fe/256x192.png",
        "viewOrder": 10
      },
      {
        "mediaId": 61724,
        "title": "Six Senses Kanuhura hotel review | CN Traveller",
        "url": "https://pkslist.azureedge.net/media/images/1/0fb39ce3-3cdf-489c-96e0-4f4a052daa71/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0fb39ce3-3cdf-489c-96e0-4f4a052daa71/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0fb39ce3-3cdf-489c-96e0-4f4a052daa71/256x192.jpg",
        "viewOrder": 11
      },
      {
        "mediaId": 61737,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/bdb08cf0-4cad-4b24-9290-8120be0c1923/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bdb08cf0-4cad-4b24-9290-8120be0c1923/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bdb08cf0-4cad-4b24-9290-8120be0c1923/256x192.png",
        "viewOrder": 12
      },
      {
        "mediaId": 61747,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c1bb91d5-da37-4bd7-bc0a-f3e159115ade/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c1bb91d5-da37-4bd7-bc0a-f3e159115ade/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c1bb91d5-da37-4bd7-bc0a-f3e159115ade/256x192.png",
        "viewOrder": 13
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3622,
      "latitude": 5.533728200000001,
      "longitude": 73.5060357,
      "parentGeographicAreaId": 0,
      "title": "Kanuhura, Maldives",
      "updated": null,
      "created": "2023-12-22T06:36:00"
    },
    "personalities": [
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      },
      {
        "tagId": 157,
        "tagName": "Beach Escape",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 109,
        "tagName": "Fishing",
        "tagType": 2
      },
      {
        "tagId": 116,
        "tagName": "Scuba Diving",
        "tagType": 2
      },
      {
        "tagId": 198,
        "tagName": "Boat Excursion",
        "tagType": 2
      },
      {
        "tagId": 206,
        "tagName": "Private Beach",
        "tagType": 2
      },
      {
        "tagId": 259,
        "tagName": "Picnic",
        "tagType": 2
      },
      {
        "tagId": 320,
        "tagName": "Organic Garden",
        "tagType": 2
      },
      {
        "tagId": 362,
        "tagName": "Sustainable",
        "tagType": 2
      },
      {
        "tagId": 410,
        "tagName": "Sundownders",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 843,
        "venueCategoryId": 19,
        "venueId": 3804,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 19,
          "title": "Private Islands",
          "region": "Global",
          "viewOrder": 15,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 844,
        "venueCategoryId": 35,
        "venueId": 3804,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3747,
    "venueCategoryId": 0,
    "status": 1,
    "title": "One Sloane",
    "description": "Hidden behind a discreet 19th century façade, One Sloane reveals itself as the perfect blend of quiet British charm and curated French craftsmanship, conceived by renowned hotelier Costes.\n\nAssisted by his trusted circle of exceptional French artisans, recognised architect and interior designer François-Joseph Graf has breathed new life into this elegant Victorian mansion, elevating its feel of a quaint English house to a refined and discreet hotel.\n\nReminiscent of the eternal British charm and comfort, each of the 30 bespoke rooms and suites resonates with the emotional intimacy of a private residence, complete with restored antique furnishings, period wallpaper and playful trompe-l’oeil..",
    "brand": "",
    "blockQuote": "Sip on a seductive night cap at the lower-ground speakeasy.",
    "emailAddress": "contact@onesloane.co.uk",
    "website": "onesloane.co.uk/",
    "bookingURL": "https://be.synxis.com/?_gl=1*1axjy05*_ga*MTk3ODc1MTk1NC4xNjk3MjcwMzYz*_ga_XR1L98002Y*MTY5NzI3MDM2My4xLjEuMTY5NzI3MDk4NS4wLjAuMA..&adult=1&agencyid=96027890&arrive=2023-10-14&chain=21123&child=0&currency=GBP&depart=2023-10-15&hotel=42512&level=hotel&locale=en-GB&rooms=1",
    "redirectNotes": "One Sloane's reservation system",
    "phoneNumber": "+44 203 7500 750",
    "addressOne": "1",
    "addressTwo": "Sloane Gardens",
    "city": "London",
    "county": "",
    "region": "England",
    "country": "United Kingdom",
    "rooms": 30,
    "dollar": 3,
    "updated": "2023-10-14T08:25:21.143",
    "created": "2023-10-14T08:02:01.407",
    "medias": [
      {
        "mediaId": 60586,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/35c819f2-b30c-4d0d-a41c-1b1449152266/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/35c819f2-b30c-4d0d-a41c-1b1449152266/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/35c819f2-b30c-4d0d-a41c-1b1449152266/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 60584,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/62d3058d-5afc-4825-bf26-f94d18b73f23/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/62d3058d-5afc-4825-bf26-f94d18b73f23/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/62d3058d-5afc-4825-bf26-f94d18b73f23/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 60591,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/8afda115-3ba0-448a-9651-cf2e1bbf2dfa/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8afda115-3ba0-448a-9651-cf2e1bbf2dfa/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8afda115-3ba0-448a-9651-cf2e1bbf2dfa/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 60579,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/5592ab01-ca2b-4bd2-adb6-4ceeb1f033da/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5592ab01-ca2b-4bd2-adb6-4ceeb1f033da/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5592ab01-ca2b-4bd2-adb6-4ceeb1f033da/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 60581,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/3b635087-c29e-4717-91ce-45f27424b4e6/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3b635087-c29e-4717-91ce-45f27424b4e6/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3b635087-c29e-4717-91ce-45f27424b4e6/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 60582,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/87de1e8e-454f-400d-97e0-f5e055d43514/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/87de1e8e-454f-400d-97e0-f5e055d43514/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/87de1e8e-454f-400d-97e0-f5e055d43514/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 60589,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d648f706-06d1-4806-b157-b3814f997615/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d648f706-06d1-4806-b157-b3814f997615/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d648f706-06d1-4806-b157-b3814f997615/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 60588,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/13a1b53f-e231-43d8-bafe-a1220aad9612/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/13a1b53f-e231-43d8-bafe-a1220aad9612/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/13a1b53f-e231-43d8-bafe-a1220aad9612/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 60587,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a4383db3-847b-442a-bd54-e384932479b6/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a4383db3-847b-442a-bd54-e384932479b6/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a4383db3-847b-442a-bd54-e384932479b6/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 60585,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d5871389-26d5-43c6-aabb-4cbe0af988aa/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d5871389-26d5-43c6-aabb-4cbe0af988aa/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d5871389-26d5-43c6-aabb-4cbe0af988aa/256x192.jpeg",
        "viewOrder": 9
      },
      {
        "mediaId": 60590,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/2790035f-59ff-4193-9df1-2019b2b0eb7e/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2790035f-59ff-4193-9df1-2019b2b0eb7e/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2790035f-59ff-4193-9df1-2019b2b0eb7e/256x192.jpeg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3528,
      "latitude": 51.4919976,
      "longitude": -0.1562432,
      "parentGeographicAreaId": 0,
      "title": "1 Sloane Gardens, London SW1W 8EA, UK",
      "updated": null,
      "created": "2023-10-14T08:11:00"
    },
    "personalities": [
      {
        "tagId": 40,
        "tagName": "Boutique Bolthole ",
        "tagType": 1
      },
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 62,
        "tagName": "Nightlife",
        "tagType": 2
      },
      {
        "tagId": 64,
        "tagName": "Sceney",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 131,
        "tagName": "Library",
        "tagType": 2
      },
      {
        "tagId": 155,
        "tagName": "Art",
        "tagType": 2
      },
      {
        "tagId": 156,
        "tagName": "Fireplace",
        "tagType": 2
      },
      {
        "tagId": 172,
        "tagName": "Biking",
        "tagType": 2
      },
      {
        "tagId": 216,
        "tagName": "Museum",
        "tagType": 2
      },
      {
        "tagId": 255,
        "tagName": "Music",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 323,
        "tagName": "City Walk",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 841,
        "venueCategoryId": 35,
        "venueId": 3747,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3725,
    "venueCategoryId": 0,
    "status": 1,
    "title": "One&Only Aesthesis",
    "description": "One&Only Aesthesis is a captivating coastal hideaway that harks back to the golden days of the Athenian Riviera. Set within 21 hectares of protected forest reserve in Glyfada, the resort is just a short drive from the ancient wonders of the Greek capital. Drift into our Athenian Riviera retreat and experience a vibrant lifestyle that takes you back to the effortless sophistication of a bygone era.\n\nCarved out of native stone and seamlessly fusing indoor and outdoor spaces, the resort’s rooms, beachfront bungalows and villas capture the convivial spirit of the Hollywood Grecian era. This coastal-chic aesthetic elicits far more than memories alone – for many Greeks, it will transcend time and transport them to the paradise of their youth.",
    "brand": "",
    "blockQuote": "RESORT OPENING 2024. Take a romantic break at Mystiko, the secluded adults-only beach.",
    "emailAddress": "reservations@oneandonlyaesthesis.com",
    "website": "oneandonlyresorts.com/aesthesis",
    "bookingURL": "https://www.oneandonlyresorts.com/aesthesis/contact",
    "redirectNotes": "One&Only Aesthesis' reservations system",
    "phoneNumber": "+971 4 426 1099",
    "addressOne": "",
    "addressTwo": "Leoforos Poseidonos",
    "city": "Glyfada",
    "county": "",
    "region": "",
    "country": "Greece",
    "rooms": 127,
    "dollar": 3,
    "updated": "2023-10-03T16:35:47.193",
    "created": "2023-10-03T16:11:04.277",
    "medias": [
      {
        "mediaId": 60163,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b9d07b38-93ce-4f12-b1c6-1766b1f47361/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b9d07b38-93ce-4f12-b1c6-1766b1f47361/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b9d07b38-93ce-4f12-b1c6-1766b1f47361/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 60151,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/12b5f630-f659-4422-916d-9dc88e5a20e6/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/12b5f630-f659-4422-916d-9dc88e5a20e6/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/12b5f630-f659-4422-916d-9dc88e5a20e6/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 60155,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f4789e67-67ef-4446-acd3-5134c88ad682/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f4789e67-67ef-4446-acd3-5134c88ad682/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f4789e67-67ef-4446-acd3-5134c88ad682/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 60154,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/48fbf702-ecab-402e-b98b-cdab38407511/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/48fbf702-ecab-402e-b98b-cdab38407511/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/48fbf702-ecab-402e-b98b-cdab38407511/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 60160,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/5f592a26-c890-4fa8-ab33-accc7e19dc85/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5f592a26-c890-4fa8-ab33-accc7e19dc85/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5f592a26-c890-4fa8-ab33-accc7e19dc85/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 60153,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/0ee26485-5da9-4fd4-9442-4bdafa69419b/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0ee26485-5da9-4fd4-9442-4bdafa69419b/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0ee26485-5da9-4fd4-9442-4bdafa69419b/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 60161,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c1badbcf-5140-4ec1-82d6-7811bc8d5999/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c1badbcf-5140-4ec1-82d6-7811bc8d5999/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c1badbcf-5140-4ec1-82d6-7811bc8d5999/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 60152,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/2ced1a37-10a5-404f-953e-00b013833090/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2ced1a37-10a5-404f-953e-00b013833090/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2ced1a37-10a5-404f-953e-00b013833090/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 60162,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/64d8d28f-c469-4013-a30a-4db776f1941a/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/64d8d28f-c469-4013-a30a-4db776f1941a/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/64d8d28f-c469-4013-a30a-4db776f1941a/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 60159,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/48be6d3f-2c41-47ca-8efe-0c6629821161/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/48be6d3f-2c41-47ca-8efe-0c6629821161/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/48be6d3f-2c41-47ca-8efe-0c6629821161/256x192.jpeg",
        "viewOrder": 9
      },
      {
        "mediaId": 60165,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e06fc014-2a43-4586-a35a-8e03335ecff1/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e06fc014-2a43-4586-a35a-8e03335ecff1/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e06fc014-2a43-4586-a35a-8e03335ecff1/256x192.jpeg",
        "viewOrder": 10
      },
      {
        "mediaId": 60156,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f2cac3f9-78f6-40fc-8653-0e7a60b1a835/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f2cac3f9-78f6-40fc-8653-0e7a60b1a835/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f2cac3f9-78f6-40fc-8653-0e7a60b1a835/256x192.jpeg",
        "viewOrder": 11
      },
      {
        "mediaId": 60158,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/0ca6e598-52da-4b48-85bb-38230ec2bee3/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0ca6e598-52da-4b48-85bb-38230ec2bee3/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0ca6e598-52da-4b48-85bb-38230ec2bee3/256x192.jpeg",
        "viewOrder": 12
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3506,
      "latitude": 37.8570691,
      "longitude": 23.7502784,
      "parentGeographicAreaId": 0,
      "title": "Leof. Poseidonos, Glifada 166 74, Greece",
      "updated": null,
      "created": "2023-10-03T16:22:00"
    },
    "personalities": [
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      },
      {
        "tagId": 54,
        "tagName": "Happening",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 58,
        "tagName": "Beach",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 122,
        "tagName": "Surfing",
        "tagType": 2
      },
      {
        "tagId": 198,
        "tagName": "Boat Excursion",
        "tagType": 2
      },
      {
        "tagId": 245,
        "tagName": "Hot Springs",
        "tagType": 2
      },
      {
        "tagId": 270,
        "tagName": "Beach Club",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 404,
        "tagName": "Sightseeing",
        "tagType": 2
      },
      {
        "tagId": 429,
        "tagName": "Woodlands",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 838,
        "venueCategoryId": 35,
        "venueId": 3725,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3696,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Mandarin Oriental Costa Navarino",
    "description": "Nestled on the southwest coast of the Peloponnese, one of the most unspoiled and breathtaking landscapes in the Mediterranean, this beachfront resort will offer a unique experience of unparalleled Greek beauty along with the legendary service for which the brand is renowned.",
    "brand": "Mandarin Oriental",
    "blockQuote": "Sunset cocktails at Three Admirals Lounge with panoramic views over the Ionian Sea is a must.",
    "emailAddress": "mocna-info@mohg.com",
    "website": "mandarinoriental.com/en/costa-navarino/messenia/",
    "bookingURL": "https://www.mandarinoriental.com/en/reservation/#/?hotelCode=40953&roomCodes=%5B%5D&roomName&bedType&rateCode&adultGuestCount=2&childGuestCount=0&roomQuantity=1&aditionalGuests=%5B%5D&stayDateStart=2023-08-30&stayDateEnd=2023-09-02&iataNumber=96027890&groupCode&promotionCode",
    "redirectNotes": "Mandarin Oriental Costa Navarino's reservations system",
    "phoneNumber": "+30 272 309 9888",
    "addressOne": "",
    "addressTwo": "",
    "city": "Costa Navarino",
    "county": "",
    "region": "",
    "country": "Greece",
    "rooms": 99,
    "dollar": 3,
    "updated": "2023-08-30T12:26:14.53",
    "created": "2023-08-30T11:58:24.34",
    "medias": [
      {
        "mediaId": 59419,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/7a3eeb39-0e3d-4796-a241-a3f83226a8bb/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7a3eeb39-0e3d-4796-a241-a3f83226a8bb/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7a3eeb39-0e3d-4796-a241-a3f83226a8bb/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 59422,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/956580d7-3343-4e93-a87a-32eacedc7456/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/956580d7-3343-4e93-a87a-32eacedc7456/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/956580d7-3343-4e93-a87a-32eacedc7456/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 59423,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f685b794-14e4-4c81-a2bb-c4651e3ddb08/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f685b794-14e4-4c81-a2bb-c4651e3ddb08/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f685b794-14e4-4c81-a2bb-c4651e3ddb08/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 59424,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/1329eef9-75c3-4ecf-bc4d-89256fa0ad18/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1329eef9-75c3-4ecf-bc4d-89256fa0ad18/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1329eef9-75c3-4ecf-bc4d-89256fa0ad18/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 59421,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/9e3e6363-4596-4993-bd66-b7cd5d5916cd/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9e3e6363-4596-4993-bd66-b7cd5d5916cd/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9e3e6363-4596-4993-bd66-b7cd5d5916cd/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 59420,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f0002c49-590e-4588-9114-9c46c6557114/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f0002c49-590e-4588-9114-9c46c6557114/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f0002c49-590e-4588-9114-9c46c6557114/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 59425,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/48387a36-27f2-4a12-9b98-eaf131541e04/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/48387a36-27f2-4a12-9b98-eaf131541e04/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/48387a36-27f2-4a12-9b98-eaf131541e04/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 59426,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/db56ba2a-33e7-455c-b2cc-1d449a0a144b/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/db56ba2a-33e7-455c-b2cc-1d449a0a144b/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/db56ba2a-33e7-455c-b2cc-1d449a0a144b/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 59417,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/1ea03300-cd1c-4d8c-a2a4-d659bb2f8ca4/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1ea03300-cd1c-4d8c-a2a4-d659bb2f8ca4/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1ea03300-cd1c-4d8c-a2a4-d659bb2f8ca4/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 59416,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/44a57b96-f262-4bc8-9ded-9f6dd535d77e/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/44a57b96-f262-4bc8-9ded-9f6dd535d77e/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/44a57b96-f262-4bc8-9ded-9f6dd535d77e/256x192.jpeg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3477,
      "latitude": 36.9358938,
      "longitude": 21.7134519,
      "parentGeographicAreaId": 0,
      "title": "Bay, Costa, Pilos 240 01, Greece",
      "updated": null,
      "created": "2023-08-30T12:07:00"
    },
    "personalities": [
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      },
      {
        "tagId": 157,
        "tagName": "Beach Escape",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 58,
        "tagName": "Beach",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 81,
        "tagName": "Golf",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 88,
        "tagName": "Tennis",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 104,
        "tagName": "Cycling",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 410,
        "tagName": "Sundownders",
        "tagType": 2
      },
      {
        "tagId": 448,
        "tagName": "Padel",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 835,
        "venueCategoryId": 35,
        "venueId": 3696,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3694,
    "venueCategoryId": 0,
    "status": 1,
    "title": "The St. Regis Kanai Resort",
    "description": "Discover an exceptional address at The St. Regis Kanai Resort, Riviera Maya. We offer you a central location near Cancun and Playa Del Carmen. Find our vacation resort built on a natural mangrove reserve, with a setting inspired by the neighboring Sian Ka’an Reserve, a UNESCO World Heritage Site in Quintana Roo. Take in sweeping ocean views from a 620-acre reserve set on the shores of Mexico’s Yucatan Peninsula.",
    "brand": "St. Regis",
    "blockQuote": "Take a walk on the interconnected elevated walkways above the mangrove forest for some great pictures.",
    "emailAddress": "members@pks-list.com",
    "website": "marriott.com/en-us/hotels/cunxr-the-st-regis-kanai-resort-riviera-maya/overview/",
    "bookingURL": "https://www.marriott.com/reservation/rateListMenu.mi?defaultTab=standard",
    "redirectNotes": "The St Regis Kanai Resort's reservations system",
    "phoneNumber": "+52 984 1224800",
    "addressOne": "15",
    "addressTwo": "Paseo Kanai",
    "city": "Playa del Carmen",
    "county": "",
    "region": "Riviera Maya",
    "country": "Mexico",
    "rooms": 124,
    "dollar": 3,
    "updated": "2023-12-17T13:05:40",
    "created": "2023-08-30T08:48:28.49",
    "medias": [
      {
        "mediaId": 59380,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e88a484b-9577-47f1-a0a9-3abc101831f5/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e88a484b-9577-47f1-a0a9-3abc101831f5/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e88a484b-9577-47f1-a0a9-3abc101831f5/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 59385,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/8292ae21-2ac4-40f8-b976-559a17d1057f/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8292ae21-2ac4-40f8-b976-559a17d1057f/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8292ae21-2ac4-40f8-b976-559a17d1057f/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 59386,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/568a8a66-9ed1-447e-96f0-daef721f9edc/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/568a8a66-9ed1-447e-96f0-daef721f9edc/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/568a8a66-9ed1-447e-96f0-daef721f9edc/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 59388,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/53e91a6f-ccd2-4540-a743-4932c0c5d0ac/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/53e91a6f-ccd2-4540-a743-4932c0c5d0ac/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/53e91a6f-ccd2-4540-a743-4932c0c5d0ac/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 59395,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/04ac09aa-95b4-4f85-9198-95c2862213c1/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/04ac09aa-95b4-4f85-9198-95c2862213c1/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/04ac09aa-95b4-4f85-9198-95c2862213c1/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 59382,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d1c1ef82-90e5-4f61-80fd-c0391d824a8b/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d1c1ef82-90e5-4f61-80fd-c0391d824a8b/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d1c1ef82-90e5-4f61-80fd-c0391d824a8b/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 59384,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/3cf6f94b-9439-4cfe-8d26-8e12be7169eb/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3cf6f94b-9439-4cfe-8d26-8e12be7169eb/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3cf6f94b-9439-4cfe-8d26-8e12be7169eb/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 59401,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c4eae59a-cc59-4518-9d5b-bfae88746de9/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c4eae59a-cc59-4518-9d5b-bfae88746de9/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c4eae59a-cc59-4518-9d5b-bfae88746de9/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 59383,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/11164851-f08f-4e1b-86e6-d79ff89e598c/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/11164851-f08f-4e1b-86e6-d79ff89e598c/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/11164851-f08f-4e1b-86e6-d79ff89e598c/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 59392,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b97a72ac-3afa-4a37-bf78-da85ca65208d/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b97a72ac-3afa-4a37-bf78-da85ca65208d/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b97a72ac-3afa-4a37-bf78-da85ca65208d/256x192.jpeg",
        "viewOrder": 9
      },
      {
        "mediaId": 59391,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ee928087-374c-4457-b430-670665aabf43/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ee928087-374c-4457-b430-670665aabf43/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ee928087-374c-4457-b430-670665aabf43/256x192.jpeg",
        "viewOrder": 10
      },
      {
        "mediaId": 59393,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/9213f7f0-5e05-4398-9e3c-0d7487243eb7/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9213f7f0-5e05-4398-9e3c-0d7487243eb7/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9213f7f0-5e05-4398-9e3c-0d7487243eb7/256x192.jpeg",
        "viewOrder": 11
      },
      {
        "mediaId": 59397,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/79eeaf5b-9a6f-422a-b262-008c3662119e/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/79eeaf5b-9a6f-422a-b262-008c3662119e/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/79eeaf5b-9a6f-422a-b262-008c3662119e/256x192.jpeg",
        "viewOrder": 12
      },
      {
        "mediaId": 59399,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ddc53aa8-7a20-4bf6-ac6c-768c1551fa61/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ddc53aa8-7a20-4bf6-ac6c-768c1551fa61/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ddc53aa8-7a20-4bf6-ac6c-768c1551fa61/256x192.jpeg",
        "viewOrder": 13
      },
      {
        "mediaId": 59398,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/3b913304-c4fb-41ea-ba74-d435a02f2772/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3b913304-c4fb-41ea-ba74-d435a02f2772/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3b913304-c4fb-41ea-ba74-d435a02f2772/256x192.jpeg",
        "viewOrder": 14
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3475,
      "latitude": 20.7188711,
      "longitude": -86.98872949999999,
      "parentGeographicAreaId": 0,
      "title": "Paseo Kanai 15, Solidaridad, 77730 Playa del Carmen, Q.R., Mexico",
      "updated": null,
      "created": "2023-08-30T09:00:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 157,
        "tagName": "Beach Escape",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 58,
        "tagName": "Beach",
        "tagType": 2
      },
      {
        "tagId": 83,
        "tagName": "Kid's Club",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 183,
        "tagName": "Butler Service",
        "tagType": 2
      },
      {
        "tagId": 198,
        "tagName": "Boat Excursion",
        "tagType": 2
      },
      {
        "tagId": 220,
        "tagName": "Adult Pool",
        "tagType": 2
      },
      {
        "tagId": 276,
        "tagName": "Gym",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 284,
        "tagName": "Architecture",
        "tagType": 2
      },
      {
        "tagId": 410,
        "tagName": "Sundownders",
        "tagType": 2
      },
      {
        "tagId": 440,
        "tagName": "Unesco",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 834,
        "venueCategoryId": 35,
        "venueId": 3694,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3689,
    "venueCategoryId": 0,
    "status": 1,
    "title": "The Peninsula London",
    "description": "Superbly situated alongside Hyde Park Corner and Wellington Arch, The Peninsula London occupies one of the city’s most prestigious addresses. Newly built and impeccably designed to harmonise with the surrounding heritage buildings, the hotel is just steps away from Kensington Gardens, and other iconic attractions.",
    "brand": "Peninsula",
    "blockQuote": "Indulge with a traditional Peninsula Afternoon Tea accompanied by live music.",
    "emailAddress": "reservationpln@peninsula.com",
    "website": "peninsula.com/en/london/5-star-luxury-hotel-belgravia",
    "bookingURL": "https://secure.peninsula.com/?_ga=2.68933841.1590874037.1692773399-1821390654.1692773399&adult=2&agencyid=96027890&arrive=2023-09-12&chain=5440&child=0&currency=GBP&depart=2023-09-13&hotel=38446&level=hotel&locale=en-GB&rooms=1",
    "redirectNotes": "The Peninsula London's reservations system",
    "phoneNumber": "+44 20 3959 2888",
    "addressOne": "1",
    "addressTwo": "Grosvenor Place",
    "city": "London",
    "county": "",
    "region": "England",
    "country": "United Kingdom",
    "rooms": 190,
    "dollar": 3,
    "updated": "2023-08-23T09:14:48.85",
    "created": "2023-08-23T08:46:17.367",
    "medias": [
      {
        "mediaId": 59283,
        "title": "The Peninsula London Debuts, Bringing Spectacular New Style and ...",
        "url": "https://pkslist.azureedge.net/media/images/1/8b7e1ad3-2f08-487f-9f9b-d318450e7c73/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8b7e1ad3-2f08-487f-9f9b-d318450e7c73/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8b7e1ad3-2f08-487f-9f9b-d318450e7c73/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 59270,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b8fdd9a1-20d8-4cca-837d-8601ff16c0e9/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b8fdd9a1-20d8-4cca-837d-8601ff16c0e9/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b8fdd9a1-20d8-4cca-837d-8601ff16c0e9/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 59278,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/fe22ebd0-9e26-4dac-895c-6626e192b829/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fe22ebd0-9e26-4dac-895c-6626e192b829/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fe22ebd0-9e26-4dac-895c-6626e192b829/256x192.png",
        "viewOrder": 2
      },
      {
        "mediaId": 59286,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a0daad37-be51-4681-893e-dc92c24a3b63/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a0daad37-be51-4681-893e-dc92c24a3b63/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a0daad37-be51-4681-893e-dc92c24a3b63/256x192.png",
        "viewOrder": 3
      },
      {
        "mediaId": 59277,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f740ce39-358c-472f-8cd6-dfa991f578e1/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f740ce39-358c-472f-8cd6-dfa991f578e1/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f740ce39-358c-472f-8cd6-dfa991f578e1/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 59272,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d4cd9641-f1c4-4c80-82d8-2dc204870884/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d4cd9641-f1c4-4c80-82d8-2dc204870884/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d4cd9641-f1c4-4c80-82d8-2dc204870884/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 59267,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/88285e2e-5327-45e4-a362-4d8b84e31a01/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/88285e2e-5327-45e4-a362-4d8b84e31a01/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/88285e2e-5327-45e4-a362-4d8b84e31a01/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 59269,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/faba7365-86de-4563-ae83-041c5cce4b5f/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/faba7365-86de-4563-ae83-041c5cce4b5f/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/faba7365-86de-4563-ae83-041c5cce4b5f/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 59275,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/312ac237-2cb3-4c48-a3ce-b1178d17dc09/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/312ac237-2cb3-4c48-a3ce-b1178d17dc09/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/312ac237-2cb3-4c48-a3ce-b1178d17dc09/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 59284,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/356f71e3-89a7-4353-8274-112c583a65e2/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/356f71e3-89a7-4353-8274-112c583a65e2/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/356f71e3-89a7-4353-8274-112c583a65e2/256x192.png",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3470,
      "latitude": 51.5015896,
      "longitude": -0.1513984,
      "parentGeographicAreaId": 0,
      "title": "1 Grosvenor Pl, London SW1X 7HJ, UK",
      "updated": null,
      "created": "2023-08-23T08:53:00"
    },
    "personalities": [
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      },
      {
        "tagId": 55,
        "tagName": "Lively",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 105,
        "tagName": "Afternoon Tea",
        "tagType": 2
      },
      {
        "tagId": 222,
        "tagName": "Michelin Star dining",
        "tagType": 2
      },
      {
        "tagId": 297,
        "tagName": "Rooftop Restaurant",
        "tagType": 2
      },
      {
        "tagId": 323,
        "tagName": "City Walk",
        "tagType": 2
      },
      {
        "tagId": 336,
        "tagName": "Ballroom",
        "tagType": 2
      },
      {
        "tagId": 404,
        "tagName": "Sightseeing",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 826,
        "venueCategoryId": 35,
        "venueId": 3689,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 831,
        "venueCategoryId": 4,
        "venueId": 3689,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 4,
          "title": "London Top 10 ",
          "region": "Europe",
          "viewOrder": 10,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3688,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Raffles London at The OWO",
    "description": "Raffles London at The OWO on London's Whitehall unites the allure and heritage of Raffles with one of the world's most historic addresses.\n\nThis fine Grade II* Edwardian building has been transformed into a spectacular stage for dazzling celebrations; with fine dining, lively bars, the latest in wellness and beauty therapies, the most luxurious suites and rooms, versatile grand ballroom and event space.",
    "brand": "",
    "blockQuote": "Your culinary choices are endless with nine restaurants and bars headed by world-class chefs.",
    "emailAddress": "reservations.london@raffles.com",
    "website": "raffles.com/london/",
    "bookingURL": "https://all.accor.com/ssr/app/raffles/rates/B1C0/index.en.shtml?dateIn=2023-08-23&nights=1&compositions=2&stayplus=false&snu=false&accessibleRooms=false&otConsentString=,C0001,C0003,C0007,C0002,C0004,&partner_id=raffles&_ga=2.108295490.1992836539.1692773013-1062905038.1692773013&otConsentId=12b69edf-ed78-4fe8-a176-ab4ed7c4fcb2",
    "redirectNotes": "Raffles London at The OWO's reservations system",
    "phoneNumber": "+800 1723 3537",
    "addressOne": "57",
    "addressTwo": "Whitehall",
    "city": "London",
    "county": "",
    "region": "England",
    "country": "United Kingdom",
    "rooms": 120,
    "dollar": 3,
    "updated": "2023-08-23T08:45:23.047",
    "created": "2023-08-23T08:10:00.217",
    "medias": [
      {
        "mediaId": 59254,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/81618872-100a-4527-ae8f-34c2344b36f5/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/81618872-100a-4527-ae8f-34c2344b36f5/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/81618872-100a-4527-ae8f-34c2344b36f5/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 59259,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c0e0fb39-72e7-4926-887e-9e0546c5c0b8/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c0e0fb39-72e7-4926-887e-9e0546c5c0b8/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c0e0fb39-72e7-4926-887e-9e0546c5c0b8/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 59261,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f93b326d-3368-4252-b0cf-0f864e8fc384/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f93b326d-3368-4252-b0cf-0f864e8fc384/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f93b326d-3368-4252-b0cf-0f864e8fc384/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 59257,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d44716f5-9711-4c72-adab-a22e448c23c2/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d44716f5-9711-4c72-adab-a22e448c23c2/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d44716f5-9711-4c72-adab-a22e448c23c2/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 59255,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/5135864f-9de8-487c-83d0-47c259b5436c/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5135864f-9de8-487c-83d0-47c259b5436c/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5135864f-9de8-487c-83d0-47c259b5436c/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 59263,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ec0ea94b-0169-46b4-8e09-24332b4bb222/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ec0ea94b-0169-46b4-8e09-24332b4bb222/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ec0ea94b-0169-46b4-8e09-24332b4bb222/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 59256,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/48b76ec8-5ccc-436a-b89d-5ef5fc5eb2c3/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/48b76ec8-5ccc-436a-b89d-5ef5fc5eb2c3/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/48b76ec8-5ccc-436a-b89d-5ef5fc5eb2c3/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 59264,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/acfc0d23-4c35-4f0d-a462-3f8c5875f42e/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/acfc0d23-4c35-4f0d-a462-3f8c5875f42e/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/acfc0d23-4c35-4f0d-a462-3f8c5875f42e/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 59265,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/05395dc5-13dd-4521-a066-f3200a88aab8/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/05395dc5-13dd-4521-a066-f3200a88aab8/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/05395dc5-13dd-4521-a066-f3200a88aab8/256x192.jpeg",
        "viewOrder": 9
      },
      {
        "mediaId": 59260,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a5b42610-3fff-4881-be9a-ab4b3e43516a/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a5b42610-3fff-4881-be9a-ab4b3e43516a/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a5b42610-3fff-4881-be9a-ab4b3e43516a/256x192.jpeg",
        "viewOrder": 10
      },
      {
        "mediaId": 59258,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/17613e80-913e-4625-a31b-f7f75290bf6d/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/17613e80-913e-4625-a31b-f7f75290bf6d/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/17613e80-913e-4625-a31b-f7f75290bf6d/256x192.jpeg",
        "viewOrder": 11
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3469,
      "latitude": 51.5051333,
      "longitude": -0.1256631,
      "parentGeographicAreaId": 0,
      "title": "57 Whitehall, London SW1A 2BX, UK",
      "updated": null,
      "created": "2023-08-23T08:19:00"
    },
    "personalities": [
      {
        "tagId": 44,
        "tagName": "Grand Dame ",
        "tagType": 1
      },
      {
        "tagId": 45,
        "tagName": "Historic Sense",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 62,
        "tagName": "Nightlife",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 68,
        "tagName": "Business",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 172,
        "tagName": "Biking",
        "tagType": 2
      },
      {
        "tagId": 216,
        "tagName": "Museum",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 294,
        "tagName": "Event Space",
        "tagType": 2
      },
      {
        "tagId": 299,
        "tagName": "Wellness Programs",
        "tagType": 2
      },
      {
        "tagId": 404,
        "tagName": "Sightseeing",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 825,
        "venueCategoryId": 35,
        "venueId": 3688,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3675,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Maison Proust",
    "description": "In a world subjected to constant acceleration, Maison Proust celebrates taking time to let go, contemplate, and escape. In choosing this Belle Epoque theme, a period with a strong identity associated with its splendour and its love of the arts, its sole vocation is to offer guests timeless moments aligned with their desires.\n\nWith its understated luxury, intricate elegance and commitment to authenticity, Maison Proust invites you to come and experience the present moment.",
    "brand": "",
    "blockQuote": "Choose the room with your favourite artist to sleep with the original artwork.",
    "emailAddress": "contact@maison-proust.com",
    "website": "maison-proust.com/",
    "bookingURL": "https://www.secure-hotel-booking.com/smart/Maison-Proust/JSYD-27525/en/?_gl=1*x5z16v*_ga*MTUwMTgyNjI1Ny4xNjkxNTc0ODU2*_ga_2KWJ56W74E*MTY5MTU3NDg1Ni4xLjEuMTY5MTU3NTI3MC4yLjAuMA..#/RoomSelection",
    "redirectNotes": "Maison Proust's reservations system",
    "phoneNumber": "+33 1 86 54 55 55",
    "addressOne": "26",
    "addressTwo": "Rue de Picardie",
    "city": "Paris",
    "county": "",
    "region": "",
    "country": "France",
    "rooms": 23,
    "dollar": 3,
    "updated": "2023-08-09T10:17:08.46",
    "created": "2023-08-09T09:56:16.337",
    "medias": [
      {
        "mediaId": 58973,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/520d5d56-27a4-4032-9e9a-9d519714699a/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/520d5d56-27a4-4032-9e9a-9d519714699a/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/520d5d56-27a4-4032-9e9a-9d519714699a/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 58959,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/9e4e25bd-4713-48c2-bef6-d5d3200f3bd5/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9e4e25bd-4713-48c2-bef6-d5d3200f3bd5/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9e4e25bd-4713-48c2-bef6-d5d3200f3bd5/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 58960,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/538a9723-d50f-4788-bfb7-a6a7512072dd/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/538a9723-d50f-4788-bfb7-a6a7512072dd/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/538a9723-d50f-4788-bfb7-a6a7512072dd/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 58968,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ecfc722a-ae47-40c7-8d31-7568d0551272/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ecfc722a-ae47-40c7-8d31-7568d0551272/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ecfc722a-ae47-40c7-8d31-7568d0551272/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 58966,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c9d23c7f-2afa-4dbf-9d12-43334dd54efc/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c9d23c7f-2afa-4dbf-9d12-43334dd54efc/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c9d23c7f-2afa-4dbf-9d12-43334dd54efc/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 58964,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/45eff85f-6a17-4441-993c-76dc6cee1642/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/45eff85f-6a17-4441-993c-76dc6cee1642/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/45eff85f-6a17-4441-993c-76dc6cee1642/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 58965,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ebfe81af-5a0e-4760-8dc1-03052fe1aae7/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ebfe81af-5a0e-4760-8dc1-03052fe1aae7/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ebfe81af-5a0e-4760-8dc1-03052fe1aae7/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 58969,
        "title": "Maison Proust, Paris 3ème : -59% during the day - Dayuse.com",
        "url": "https://pkslist.azureedge.net/media/images/1/ca925b8c-365a-404b-89f0-5bad03e32c32/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ca925b8c-365a-404b-89f0-5bad03e32c32/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ca925b8c-365a-404b-89f0-5bad03e32c32/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 58970,
        "title": "Maison Proust - Hotel in Paris, France | The Vendry",
        "url": "https://pkslist.azureedge.net/media/images/1/d2ae9b7c-9537-46c3-a67e-9506cccaadcf/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d2ae9b7c-9537-46c3-a67e-9506cccaadcf/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d2ae9b7c-9537-46c3-a67e-9506cccaadcf/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 58971,
        "title": "Maison Proust — Bar Review | Condé Nast Traveler",
        "url": "https://pkslist.azureedge.net/media/images/1/d0e4076b-896f-4390-a37a-01daa4d2b300/1200x900.JPG",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d0e4076b-896f-4390-a37a-01daa4d2b300/800x600.JPG",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d0e4076b-896f-4390-a37a-01daa4d2b300/256x192.JPG",
        "viewOrder": 11
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3456,
      "latitude": 48.8640915,
      "longitude": 2.3632356,
      "parentGeographicAreaId": 0,
      "title": "26 Rue de Picardie, 75003 Paris, France",
      "updated": null,
      "created": "2023-08-09T10:03:00"
    },
    "personalities": [
      {
        "tagId": 40,
        "tagName": "Boutique Bolthole ",
        "tagType": 1
      },
      {
        "tagId": 57,
        "tagName": "Romantic",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 62,
        "tagName": "Nightlife",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 155,
        "tagName": "Art",
        "tagType": 2
      },
      {
        "tagId": 205,
        "tagName": "Design",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 216,
        "tagName": "Museum",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 294,
        "tagName": "Event Space",
        "tagType": 2
      },
      {
        "tagId": 332,
        "tagName": "Fashion",
        "tagType": 2
      },
      {
        "tagId": 404,
        "tagName": "Sightseeing",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 824,
        "venueCategoryId": 35,
        "venueId": 3675,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3668,
    "venueCategoryId": 0,
    "status": 1,
    "title": "The Peninsula Istanbul",
    "description": "Set along the Bosphorus waterfront in the historic district of Karaköy, The Peninsula Istanbul provides a showcase for both vibrant Turkish culture and superlative hospitality within Galataport, a new waterfront revitalisation project. The hotel is comprised of four exquisite buildings – three of them historic and beautifully restored – and welcomes guests arriving by land or sea.",
    "brand": "Peninsula",
    "blockQuote": "Go for a morning swim in the outdoor pool overlooking the Golden Horn.",
    "emailAddress": "pit@peninsula.com",
    "website": "peninsula.com/en/istanbul/5-star-luxury-hotel-bosphorus",
    "bookingURL": "https://secure.peninsula.com/?_ga=2.81553623.19801321.1691413353-1391920660.1691413353&adult=2&agencyid=96027890&arrive=2023-08-07&chain=5440&child=0&currency=EUR&depart=2023-08-08&hotel=38447&level=hotel&locale=en-GB&rooms=1",
    "redirectNotes": "The Peninsula Istanbul's reservations system",
    "phoneNumber": "+90 212 931 2888",
    "addressOne": "No:34",
    "addressTwo": "Kemankeş Caddesi",
    "city": "Istanbul",
    "county": "",
    "region": "",
    "country": "Turkey",
    "rooms": 177,
    "dollar": 3,
    "updated": "2023-08-07T14:16:53.983",
    "created": "2023-08-07T13:20:07.627",
    "medias": [
      {
        "mediaId": 58817,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/9f4987f6-7015-4b53-a8bd-4e537f0b5816/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9f4987f6-7015-4b53-a8bd-4e537f0b5816/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9f4987f6-7015-4b53-a8bd-4e537f0b5816/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 58826,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/7c25cb02-aa33-42d2-9366-ea02857fe8bd/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7c25cb02-aa33-42d2-9366-ea02857fe8bd/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7c25cb02-aa33-42d2-9366-ea02857fe8bd/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 58827,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/7079c399-e4ca-4c6e-82c1-83655aa676d9/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7079c399-e4ca-4c6e-82c1-83655aa676d9/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7079c399-e4ca-4c6e-82c1-83655aa676d9/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 58835,
        "title": "The Peninsula Istanbul hotel review | CN Traveller",
        "url": "https://pkslist.azureedge.net/media/images/1/241da7dc-8510-4e92-9e7f-8d46c48fc104/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/241da7dc-8510-4e92-9e7f-8d46c48fc104/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/241da7dc-8510-4e92-9e7f-8d46c48fc104/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 58824,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/8556f1f8-2ef2-4128-b6bd-ac0d52886281/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8556f1f8-2ef2-4128-b6bd-ac0d52886281/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8556f1f8-2ef2-4128-b6bd-ac0d52886281/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 58822,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a257a73e-7c75-459d-ac75-40bf2e5a8011/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a257a73e-7c75-459d-ac75-40bf2e5a8011/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a257a73e-7c75-459d-ac75-40bf2e5a8011/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 58830,
        "title": "Meet the local artisans behind Istanbul's hot new hotel launch",
        "url": "https://pkslist.azureedge.net/media/images/1/818d6514-c390-498c-8665-3b2980b5bb7e/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/818d6514-c390-498c-8665-3b2980b5bb7e/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/818d6514-c390-498c-8665-3b2980b5bb7e/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 58820,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/15768745-9bbc-4022-b4ab-a719ee2e87e4/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/15768745-9bbc-4022-b4ab-a719ee2e87e4/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/15768745-9bbc-4022-b4ab-a719ee2e87e4/256x192.jpeg",
        "viewOrder": 10
      },
      {
        "mediaId": 58823,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/4ad44085-c7e0-48cc-a169-f6cb01e1a148/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4ad44085-c7e0-48cc-a169-f6cb01e1a148/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4ad44085-c7e0-48cc-a169-f6cb01e1a148/256x192.jpeg",
        "viewOrder": 13
      },
      {
        "mediaId": 58825,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/78654a3f-8ffd-45e9-b897-b650cb93ea46/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/78654a3f-8ffd-45e9-b897-b650cb93ea46/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/78654a3f-8ffd-45e9-b897-b650cb93ea46/256x192.jpeg",
        "viewOrder": 14
      },
      {
        "mediaId": 58818,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/94b51ca3-90aa-4a26-b04e-5aa808055192/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/94b51ca3-90aa-4a26-b04e-5aa808055192/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/94b51ca3-90aa-4a26-b04e-5aa808055192/256x192.jpeg",
        "viewOrder": 17
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3450,
      "latitude": 41.02301249999999,
      "longitude": 28.97792739999999,
      "parentGeographicAreaId": 0,
      "title": "Karaköy, Kemankeş Karamustafapaşa Mahallesi, Kemankeş Karamustafa Paşa, Kemankeş Cd. No:34, 34425 Beyoğlu/İstanbul, Türkiye",
      "updated": null,
      "created": "2023-08-07T13:27:00"
    },
    "personalities": [
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 62,
        "tagName": "Nightlife",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 155,
        "tagName": "Art",
        "tagType": 2
      },
      {
        "tagId": 198,
        "tagName": "Boat Excursion",
        "tagType": 2
      },
      {
        "tagId": 215,
        "tagName": "Waterfront",
        "tagType": 2
      },
      {
        "tagId": 216,
        "tagName": "Museum",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 312,
        "tagName": "Outdoor Market",
        "tagType": 2
      },
      {
        "tagId": 323,
        "tagName": "City Walk",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 823,
        "venueCategoryId": 35,
        "venueId": 3668,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3648,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Bulgari Hotel Tokyo",
    "description": "Occupying the 40th to 45th floors of the Tokyo Midtown Yaesu, a ultra-skyscraper in the Yaesu 2-Chome North District, the hotel is located in a very central and convenient area at a walking distance to some of the best attractions in town such as the Imperial Palace and the vibrant shopping areas of both Nihombashi and Ginza.\n \nThe hotel offers 98 Rooms and Suites decorated with Italian luxury residential furniture brands like Maxalto, Flexform and B&B Italia, as well as an extraordinary Bulgari Suite.",
    "brand": "Bulgari",
    "blockQuote": "With only 8 seats, book early if you want to experience an extraordinary culinary journey at Sushi Hōseki.",
    "emailAddress": "tokyo.h@bulgarihotels.com",
    "website": "bulgarihotels.com/en_US/tokyo",
    "bookingURL": "https://reservations.bulgarihotels.com/reservation/rateListMenu.mi?defaultTab=standard",
    "redirectNotes": "Bulgari Hotel Tokyo's reservations system",
    "phoneNumber": "+81 3 6262 3333",
    "addressOne": "1",
    "addressTwo": "",
    "city": "Tokyo",
    "county": "",
    "region": "",
    "country": "Japan",
    "rooms": 98,
    "dollar": 3,
    "updated": "2023-08-05T11:04:02.743",
    "created": "2023-07-07T11:46:58.52",
    "medias": [
      {
        "mediaId": 58420,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/39c4dcde-39ac-4731-8fa2-4efa71933f3a/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/39c4dcde-39ac-4731-8fa2-4efa71933f3a/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/39c4dcde-39ac-4731-8fa2-4efa71933f3a/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 58437,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ca4ebaf1-c8b5-4ec8-9c0d-1ab080b9a9d6/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ca4ebaf1-c8b5-4ec8-9c0d-1ab080b9a9d6/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ca4ebaf1-c8b5-4ec8-9c0d-1ab080b9a9d6/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 58431,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e7904198-005f-442a-87d2-0d815211eb34/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e7904198-005f-442a-87d2-0d815211eb34/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e7904198-005f-442a-87d2-0d815211eb34/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 58421,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/fccf2bba-e286-4c2a-8210-318e14c7a788/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fccf2bba-e286-4c2a-8210-318e14c7a788/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fccf2bba-e286-4c2a-8210-318e14c7a788/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 58422,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/bf7e7432-1929-44be-b200-60b66587fdb2/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bf7e7432-1929-44be-b200-60b66587fdb2/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bf7e7432-1929-44be-b200-60b66587fdb2/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 58429,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a75fc69a-9696-4900-a94d-196ef8fcdcbc/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a75fc69a-9696-4900-a94d-196ef8fcdcbc/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a75fc69a-9696-4900-a94d-196ef8fcdcbc/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 58423,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/afcbd2db-dd9e-45b8-9430-a5e5036a1552/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/afcbd2db-dd9e-45b8-9430-a5e5036a1552/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/afcbd2db-dd9e-45b8-9430-a5e5036a1552/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 58426,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/5d1893b7-e96d-47f4-af34-81a4920741e2/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5d1893b7-e96d-47f4-af34-81a4920741e2/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5d1893b7-e96d-47f4-af34-81a4920741e2/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 58424,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/3f800b86-8dae-42dc-bfff-a64101b70e3c/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3f800b86-8dae-42dc-bfff-a64101b70e3c/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3f800b86-8dae-42dc-bfff-a64101b70e3c/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 58433,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/554bc002-5bea-47c9-b24e-5a19c40b2ee7/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/554bc002-5bea-47c9-b24e-5a19c40b2ee7/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/554bc002-5bea-47c9-b24e-5a19c40b2ee7/256x192.jpeg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3431,
      "latitude": 35.6796123,
      "longitude": 139.7692613,
      "parentGeographicAreaId": 0,
      "title": "2-chōme-2-1 Yaesu, Chuo City, Tokyo 104-0028, Japan",
      "updated": null,
      "created": "2023-08-05T11:04:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 100,
        "tagName": "Wedding",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 222,
        "tagName": "Michelin Star dining",
        "tagType": 2
      },
      {
        "tagId": 276,
        "tagName": "Gym",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 336,
        "tagName": "Ballroom",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 821,
        "venueCategoryId": 35,
        "venueId": 3648,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3643,
    "venueCategoryId": 0,
    "status": 1,
    "title": "COMO Le Montrachet",
    "description": "Our French hotel is a 19th-century 'place du village' inn at Puligny-Montrachet — one of the world's most renowned wine destinations. It encompasses the best of COMO: inimitable hospitality, excellent dining and discreet service.\n\nCOMO Le Montrachet faces a village square, just a few steps from the legendary 'Le Montrachet' vineyard in Burgundy where some of the world's finest French wines are produced.",
    "brand": "COMO Hotels",
    "blockQuote": "Do a biking tour to visit some of the most renowned vineyards in the world.",
    "emailAddress": "lemontrachet@comohotels.com",
    "website": "comohotels.com/burgundy/como-le-montrachet",
    "bookingURL": "https://reservations.comohotels.com/?Start=AvailResults&adult=2&agencyid=96027890&api_pass=U2FsdGVkX19DAERc2Ivy0n+NAmgskLYVBae7Igv0SxCtt5FhG13uBZEEMy2Gvxyb&api_primary_channel=WEB&api_secondary_channel=SYNXISWS_BE&api_user=CUAT_C1_COMOCBE&arrive=2023-07-06&chain=10327&child=0&currency=EUR&depart=2023-07-07&hotel=41466&level=hotel&locale=en-US&rooms=1",
    "redirectNotes": "COMO Le Montrachet's reservations system",
    "phoneNumber": "+33 3 80 21 30 06",
    "addressOne": "10",
    "addressTwo": "Place du Pasquier",
    "city": "Puligny-Montrachet",
    "county": "",
    "region": "",
    "country": "France",
    "rooms": 30,
    "dollar": 2,
    "updated": "2023-08-05T10:58:48.883",
    "created": "2023-07-06T08:39:28.767",
    "medias": [
      {
        "mediaId": 58350,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/59aa92f4-ca3a-4754-bd3f-91486a9e1f17/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/59aa92f4-ca3a-4754-bd3f-91486a9e1f17/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/59aa92f4-ca3a-4754-bd3f-91486a9e1f17/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 58362,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f40baab0-20b2-4be8-9704-706e1aa22d8e/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f40baab0-20b2-4be8-9704-706e1aa22d8e/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f40baab0-20b2-4be8-9704-706e1aa22d8e/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 58361,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f6ac3be3-ac8c-4a71-9dd2-63c7709b3b5a/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f6ac3be3-ac8c-4a71-9dd2-63c7709b3b5a/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f6ac3be3-ac8c-4a71-9dd2-63c7709b3b5a/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 58358,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/79ce0927-b8c6-41f2-8f36-a517e96d156a/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/79ce0927-b8c6-41f2-8f36-a517e96d156a/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/79ce0927-b8c6-41f2-8f36-a517e96d156a/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 58353,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/2c6e6048-8624-4721-8262-a119a3515bc2/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2c6e6048-8624-4721-8262-a119a3515bc2/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2c6e6048-8624-4721-8262-a119a3515bc2/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 58360,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e553fc04-63c2-4a0d-a678-ea5f06c1a2e3/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e553fc04-63c2-4a0d-a678-ea5f06c1a2e3/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e553fc04-63c2-4a0d-a678-ea5f06c1a2e3/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 58357,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/da451005-39bb-4743-82fa-71a4cc97c3a4/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/da451005-39bb-4743-82fa-71a4cc97c3a4/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/da451005-39bb-4743-82fa-71a4cc97c3a4/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 58352,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/cb6d2eb8-e5cf-4b08-80c0-90d883f554f5/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cb6d2eb8-e5cf-4b08-80c0-90d883f554f5/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cb6d2eb8-e5cf-4b08-80c0-90d883f554f5/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 58355,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/40266150-e788-4012-8d4a-cc030d8fa899/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/40266150-e788-4012-8d4a-cc030d8fa899/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/40266150-e788-4012-8d4a-cc030d8fa899/256x192.jpeg",
        "viewOrder": 9
      },
      {
        "mediaId": 58359,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/143e7162-2965-4f97-83f9-3b48267c1e7e/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/143e7162-2965-4f97-83f9-3b48267c1e7e/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/143e7162-2965-4f97-83f9-3b48267c1e7e/256x192.jpeg",
        "viewOrder": 10
      },
      {
        "mediaId": 58354,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/92f0a311-656e-418e-86ce-91f2320b9b12/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/92f0a311-656e-418e-86ce-91f2320b9b12/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/92f0a311-656e-418e-86ce-91f2320b9b12/256x192.jpeg",
        "viewOrder": 11
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3426,
      "latitude": 46.9447262,
      "longitude": 4.7539865,
      "parentGeographicAreaId": 0,
      "title": "de la Fontaine, 10 Place du Pasquier, 21190 Puligny-Montrachet, France",
      "updated": null,
      "created": "2023-08-05T10:59:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 57,
        "tagName": "Romantic",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 60,
        "tagName": "Gastronomic",
        "tagType": 2
      },
      {
        "tagId": 77,
        "tagName": "Cooking Classes",
        "tagType": 2
      },
      {
        "tagId": 89,
        "tagName": "Vineyard",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 172,
        "tagName": "Biking",
        "tagType": 2
      },
      {
        "tagId": 221,
        "tagName": "Wine Tasting",
        "tagType": 2
      },
      {
        "tagId": 265,
        "tagName": "Hot Air Balloon",
        "tagType": 2
      },
      {
        "tagId": 324,
        "tagName": "Local Village",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 816,
        "venueCategoryId": 35,
        "venueId": 3643,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3627,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Villa Bokeh",
    "description": "Villa Bokeh is a luxury hacienda in the heart of a 6-acre garden situated in the lush outskirts of Antigua Guatemala. The expansive villa sits on six acres of gardens, the largest of any hotel in Antigua. The manicured gardens are available for guests to leisurely explore and feature several tranquil fountains. Designed by Katina Jongenzoon in collaboration with Paliare Studio, guests will experience an alluring blend of sophisticated architecture, Guatemalan Colonial Design, and modern details.",
    "brand": "",
    "blockQuote": "Restaurant Bokéh 3.1 captures a traditional Guatemalan cuisine with a modern twist.",
    "emailAddress": "recepcion@villabokeh.com",
    "website": "villabokeh.com/",
    "bookingURL": "https://be.synxis.com/?_ga=2.200605867.408662680.1683110984-1704212657.1683110984&_gl=1*7dwhcs*_ga*MTcwNDIxMjY1Ny4xNjgzMTEwOTg0*_ga_2DC7XPX0XN*MTY4MzExMDk4My4xLjEuMTY4MzExMTQ0My4wLjAuMA..&adult=2&agencyid=96027890&arrive=2023-05-03&chain=8565&child=0&config=vbkconfig&configcode=vbkconfig&currency=USD&depart=2023-05-04&hotel=35143&level=hotel&locale=en-US&rooms=1&theme=vbktheme&themecode=vbktheme",
    "redirectNotes": "Villa Bokeh's reservations system",
    "phoneNumber": "+502 37605839",
    "addressOne": "",
    "addressTwo": "",
    "city": "Antigua",
    "county": "",
    "region": "",
    "country": "Guatemala",
    "rooms": 15,
    "dollar": 2,
    "updated": "2023-05-03T11:11:39.1",
    "created": "2023-05-03T10:53:52.41",
    "medias": [
      {
        "mediaId": 58046,
        "title": "A Review Of Villa Bokeh, A Luxury Hotel In Antigua Guatemala",
        "url": "https://pkslist.azureedge.net/media/images/1/ad0a22b5-b6fb-450c-b611-41b508177c00/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ad0a22b5-b6fb-450c-b611-41b508177c00/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ad0a22b5-b6fb-450c-b611-41b508177c00/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 58038,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/683f5e9f-b9f6-4ec3-88e0-2342d4c873fb/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/683f5e9f-b9f6-4ec3-88e0-2342d4c873fb/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/683f5e9f-b9f6-4ec3-88e0-2342d4c873fb/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 58039,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/8b6a28a6-55ea-48c8-adb6-f601462a7be9/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8b6a28a6-55ea-48c8-adb6-f601462a7be9/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8b6a28a6-55ea-48c8-adb6-f601462a7be9/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 58040,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/3439482a-78f0-47af-9d3e-ab245d6d1d8d/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3439482a-78f0-47af-9d3e-ab245d6d1d8d/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3439482a-78f0-47af-9d3e-ab245d6d1d8d/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 58041,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/0adf892e-28e6-48a9-a27c-89cff2f887f5/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0adf892e-28e6-48a9-a27c-89cff2f887f5/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0adf892e-28e6-48a9-a27c-89cff2f887f5/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 58043,
        "title": "Villa Bokéh Guatemala | OFFICIAL SITE | Luxury Boutique Hotel Antigua",
        "url": "https://pkslist.azureedge.net/media/images/1/e8e3ae6c-0f97-4cb8-b94d-305eb323f101/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e8e3ae6c-0f97-4cb8-b94d-305eb323f101/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e8e3ae6c-0f97-4cb8-b94d-305eb323f101/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 58047,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/da195807-f1a8-4fe6-8ecf-7cf1343c3a00/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/da195807-f1a8-4fe6-8ecf-7cf1343c3a00/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/da195807-f1a8-4fe6-8ecf-7cf1343c3a00/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 58049,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/bb0a07e5-3172-42d1-86cd-00b512aeccb9/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bb0a07e5-3172-42d1-86cd-00b512aeccb9/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bb0a07e5-3172-42d1-86cd-00b512aeccb9/256x192.jpeg",
        "viewOrder": 9
      },
      {
        "mediaId": 58042,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/bbae0f7e-2f1d-497c-aeb4-a575fda82c25/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bbae0f7e-2f1d-497c-aeb4-a575fda82c25/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bbae0f7e-2f1d-497c-aeb4-a575fda82c25/256x192.jpeg",
        "viewOrder": 10
      },
      {
        "mediaId": 58048,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/bdac9ded-3682-46c3-81ad-9c612e87ddc0/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bdac9ded-3682-46c3-81ad-9c612e87ddc0/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bdac9ded-3682-46c3-81ad-9c612e87ddc0/256x192.jpeg",
        "viewOrder": 11
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3414,
      "latitude": 14.5433523,
      "longitude": -90.7402357,
      "parentGeographicAreaId": 0,
      "title": "San Pedro Panorama, Sacatepequez. Lote C3, Entrada a Finca San Nicolás, Antigua Guatemala, Guatemala",
      "updated": null,
      "created": "2023-05-03T11:00:00"
    },
    "personalities": [
      {
        "tagId": 57,
        "tagName": "Romantic",
        "tagType": 1
      },
      {
        "tagId": 359,
        "tagName": "Colonial Chic",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 60,
        "tagName": "Gastronomic",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 100,
        "tagName": "Wedding",
        "tagType": 2
      },
      {
        "tagId": 108,
        "tagName": "Jacuzzi",
        "tagType": 2
      },
      {
        "tagId": 240,
        "tagName": "Painting Classes",
        "tagType": 2
      },
      {
        "tagId": 257,
        "tagName": "Canoeing",
        "tagType": 2
      },
      {
        "tagId": 259,
        "tagName": "Picnic",
        "tagType": 2
      },
      {
        "tagId": 266,
        "tagName": "Nature Walk",
        "tagType": 2
      },
      {
        "tagId": 440,
        "tagName": "Unesco",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 812,
        "venueCategoryId": 35,
        "venueId": 3627,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3625,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Roku Kyoto",
    "description": "Nestled deep in rolling mountain foothills sits ROKU KYOTO, a naturally secluded and intimate retreat in Kyoto. Enjoy peaceful moments at our wellness spa, seasonally inspired menus with locally sourced ingredients, and rich cultural experiences beneath the breathtaking panorama of the Takagamine mountains and Tenjin River.",
    "brand": "",
    "blockQuote": "For a modern twist on the traditional tea ceremony, try Roku's Tea House.",
    "emailAddress": "info@rokukyoto.com",
    "website": "hilton.com/en/hotels/itmolol-roku-kyoto/",
    "bookingURL": "https://www.hilton.com/en/book/reservation/rooms/?ctyhocn=ITMOLOL&arrivalDate=2023-04-30&departureDate=2023-05-01&room1NumAdults=1&displayCurrency=JPY&ownerVIPRate=false&awc=&cid=&brandCode=OL&dclid=&gclid=&inputModule=HOTEL_SEARCH&ohwDeepLinking=true&srpName=&WT.mc_id=",
    "redirectNotes": "Roku Kyoto's reservations system",
    "phoneNumber": "+81 75 320 0111",
    "addressOne": "44-1",
    "addressTwo": "",
    "city": "Kyoto",
    "county": "",
    "region": "",
    "country": "Japan",
    "rooms": 114,
    "dollar": 3,
    "updated": "2023-04-30T09:30:40.127",
    "created": "2023-04-30T08:59:05.4",
    "medias": [
      {
        "mediaId": 58003,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/bef8de6e-e878-4ac6-be04-2c5158c247f4/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bef8de6e-e878-4ac6-be04-2c5158c247f4/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bef8de6e-e878-4ac6-be04-2c5158c247f4/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 58004,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f205f8ca-2c27-4180-a82d-1187c8a1b718/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f205f8ca-2c27-4180-a82d-1187c8a1b718/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f205f8ca-2c27-4180-a82d-1187c8a1b718/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 58018,
        "title": "Roku Kyoto Embraces the Simplicity of Japanese Craftsmanship",
        "url": "https://pkslist.azureedge.net/media/images/1/eff1058b-8d80-4f4c-baf4-43cbd91270f6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/eff1058b-8d80-4f4c-baf4-43cbd91270f6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/eff1058b-8d80-4f4c-baf4-43cbd91270f6/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 58017,
        "title": "LXR Hotels & Resorts Debuts In Kyoto, Japan",
        "url": "https://pkslist.azureedge.net/media/images/1/a6ecc9c6-f5d6-4db3-93fc-e38f6008856e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a6ecc9c6-f5d6-4db3-93fc-e38f6008856e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a6ecc9c6-f5d6-4db3-93fc-e38f6008856e/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 58006,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/5ef700b3-6de9-457e-bad7-7adda2b298a6/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5ef700b3-6de9-457e-bad7-7adda2b298a6/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5ef700b3-6de9-457e-bad7-7adda2b298a6/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 58014,
        "title": "Roku Kyoto, New Hilton LXR Hotel In Japan - One Mile at a Time",
        "url": "https://pkslist.azureedge.net/media/images/1/373f3729-8e7b-4d77-844a-058ed61e6ff6/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/373f3729-8e7b-4d77-844a-058ed61e6ff6/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/373f3729-8e7b-4d77-844a-058ed61e6ff6/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 58021,
        "title": "Roku Kyoto, LXR Hotels & Resorts — Hotel Review | Condé Nast Traveler",
        "url": "https://pkslist.azureedge.net/media/images/1/5cad24cd-9546-4254-a958-5e3590f4e12b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5cad24cd-9546-4254-a958-5e3590f4e12b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5cad24cd-9546-4254-a958-5e3590f4e12b/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 58015,
        "title": "Roku Kyoto, LXR Hotels & Resorts, Kyoto – Updated 2023 Prices",
        "url": "https://pkslist.azureedge.net/media/images/1/7a685166-eec9-4172-856f-d91ff5f5b96c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7a685166-eec9-4172-856f-d91ff5f5b96c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7a685166-eec9-4172-856f-d91ff5f5b96c/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 58012,
        "title": "The New Roku Kyoto Hotel Is Reinventing Luxury in Japan",
        "url": "https://pkslist.azureedge.net/media/images/1/1b5e0093-6b07-4501-ae03-3fafc5482c18/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1b5e0093-6b07-4501-ae03-3fafc5482c18/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1b5e0093-6b07-4501-ae03-3fafc5482c18/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 58016,
        "title": "Roku Kyoto Embraces the Simplicity of Japanese Craftsmanship",
        "url": "https://pkslist.azureedge.net/media/images/1/4a13d3ea-02b0-4dc4-977f-cfdee9ddb144/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4a13d3ea-02b0-4dc4-977f-cfdee9ddb144/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4a13d3ea-02b0-4dc4-977f-cfdee9ddb144/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 58009,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/31a2b2a9-c5d3-4b55-89be-33d1c4693f47/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/31a2b2a9-c5d3-4b55-89be-33d1c4693f47/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/31a2b2a9-c5d3-4b55-89be-33d1c4693f47/256x192.jpeg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3412,
      "latitude": 35.0476204,
      "longitude": 135.734656,
      "parentGeographicAreaId": 0,
      "title": "44-1 Kinugasa Kagamiishichō, Kita Ward, Kyoto, 603-8451, Japan",
      "updated": null,
      "created": "2023-04-30T09:25:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 61,
        "tagName": "Mountain",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 155,
        "tagName": "Art",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 240,
        "tagName": "Painting Classes",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 459,
        "tagName": "Tea House",
        "tagType": 2
      },
      {
        "tagId": 460,
        "tagName": "Pottery",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 811,
        "venueCategoryId": 35,
        "venueId": 3625,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3608,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Bulgari Hotel Roma",
    "description": "The Hotel represents a new expression of the Bulgari spirit in the Eternal City. An instantly iconic destination given Rome’s role as Bulgari’s hometown and endless font of inspiration, the new property occupies an illustrious address at Piazza Augusto Imperatore in the heart of the Campo Marzio district. Bulgari Hotel Roma is home to 114 Rooms and Suites decorated in a colour palette inspired by the city, as well as Bulgari Hotels’ signature dining and lifestyle experiences, including Il Ristorante - Niko Romito, the Bulgari Bar, the Lobby Lounge, the Spa, and Italy’s first Bulgari Dolci chocolatier and patisserie.",
    "brand": "Bulgari",
    "blockQuote": "Being Italy's first, the Bulgari Dolci chocolatier is a must visit.",
    "emailAddress": "roma@bulgarihotels.com",
    "website": "bulgarihotels.com/en_US/rome",
    "bookingURL": "https://reservations.bulgarihotels.com/reservation/rateListMenu.mi?defaultTab=standard",
    "redirectNotes": "Bulgari Hotel Roma's reservations system",
    "phoneNumber": "+39 0 636 080 400",
    "addressOne": "17",
    "addressTwo": "Piazza Augusto Imperatore",
    "city": "Rome",
    "county": "",
    "region": "",
    "country": "Italy",
    "rooms": 114,
    "dollar": 3,
    "updated": "2023-12-26T07:33:40.83",
    "created": "2023-04-17T08:41:09.627",
    "medias": [
      {
        "mediaId": 57695,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ecde7759-d304-48e0-b31b-09f7905a253d/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ecde7759-d304-48e0-b31b-09f7905a253d/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ecde7759-d304-48e0-b31b-09f7905a253d/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 58438,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/32b1ff38-0f45-41d4-8dfa-04a22405b6d1/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/32b1ff38-0f45-41d4-8dfa-04a22405b6d1/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/32b1ff38-0f45-41d4-8dfa-04a22405b6d1/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 58439,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/89d1cd4e-21ab-4d44-9beb-d756fc96c038/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/89d1cd4e-21ab-4d44-9beb-d756fc96c038/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/89d1cd4e-21ab-4d44-9beb-d756fc96c038/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 58449,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/84002f70-c1ac-40dd-afc5-0ece8eb77909/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/84002f70-c1ac-40dd-afc5-0ece8eb77909/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/84002f70-c1ac-40dd-afc5-0ece8eb77909/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 58441,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/50c376e6-1125-4537-8d1f-e6a63c01cc8c/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/50c376e6-1125-4537-8d1f-e6a63c01cc8c/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/50c376e6-1125-4537-8d1f-e6a63c01cc8c/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 58440,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d514ce29-80a6-43f9-8b1f-11d22352e86e/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d514ce29-80a6-43f9-8b1f-11d22352e86e/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d514ce29-80a6-43f9-8b1f-11d22352e86e/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 58442,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/569d0704-5ce1-4386-a075-202864e53cfe/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/569d0704-5ce1-4386-a075-202864e53cfe/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/569d0704-5ce1-4386-a075-202864e53cfe/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 57694,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/45a8fcb4-7e0d-4658-a7b9-c87ed1e06204/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/45a8fcb4-7e0d-4658-a7b9-c87ed1e06204/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/45a8fcb4-7e0d-4658-a7b9-c87ed1e06204/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 58447,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/cbcc7a25-b14b-4aee-b713-830556e1ec3c/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cbcc7a25-b14b-4aee-b713-830556e1ec3c/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cbcc7a25-b14b-4aee-b713-830556e1ec3c/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 58446,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d6757431-bffe-45fa-a472-a3e22245df26/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d6757431-bffe-45fa-a472-a3e22245df26/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d6757431-bffe-45fa-a472-a3e22245df26/256x192.jpeg",
        "viewOrder": 9
      },
      {
        "mediaId": 58448,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/81f5fc32-08ab-47fe-b75e-04676b4ab67d/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/81f5fc32-08ab-47fe-b75e-04676b4ab67d/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/81f5fc32-08ab-47fe-b75e-04676b4ab67d/256x192.jpeg",
        "viewOrder": 10
      },
      {
        "mediaId": 58451,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/0f9d92cc-6bd8-4851-8fad-71fe28fcfb57/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0f9d92cc-6bd8-4851-8fad-71fe28fcfb57/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0f9d92cc-6bd8-4851-8fad-71fe28fcfb57/256x192.jpeg",
        "viewOrder": 11
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3396,
      "latitude": 41.90674239999999,
      "longitude": 12.4767033,
      "parentGeographicAreaId": 0,
      "title": "Piazza Augusto Imperatore, 17, 00186 Roma RM, Italy",
      "updated": null,
      "created": "2023-04-17T08:51:00"
    },
    "personalities": [
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      },
      {
        "tagId": 51,
        "tagName": "Style Icon",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 155,
        "tagName": "Art",
        "tagType": 2
      },
      {
        "tagId": 216,
        "tagName": "Museum",
        "tagType": 2
      },
      {
        "tagId": 276,
        "tagName": "Gym",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 332,
        "tagName": "Fashion",
        "tagType": 2
      },
      {
        "tagId": 400,
        "tagName": "Personal Training",
        "tagType": 2
      },
      {
        "tagId": 404,
        "tagName": "Sightseeing",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 808,
        "venueCategoryId": 35,
        "venueId": 3608,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3604,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Nolinski Venezia",
    "description": "A few canals over from the Piazza San Marco and La Fenice opera house stands the Nolinski Venezia. Its majestic façade, adorned with mythical creatures, the daughters of Poseidon, reminds us of the potency that La Serenissima draws from its waters. Here we enter Venetian history through the doors of the old Stock Exchange. Art Nouveau, Stile Liberty and Modernism are brought together in well-proportioned harmony, enhanced by the work of interior designers Le Coadic Scotto.",
    "brand": "",
    "blockQuote": "Venice’s 1929 former stock exchange is set to be the city's hottest hotel.",
    "emailAddress": "contact@nolinskivenezia.com",
    "website": "nolinskivenezia.com/en/",
    "bookingURL": "https://reservations.nolinskivenezia.com/114963#/guestsandrooms",
    "redirectNotes": "Nolinski Venezia's reservations system",
    "phoneNumber": "+39 041 406 2459",
    "addressOne": "2032",
    "addressTwo": "Calle Larga XXII Marzo",
    "city": "Venice",
    "county": "",
    "region": "",
    "country": "Italy",
    "rooms": 43,
    "dollar": 3,
    "updated": "2023-06-19T20:27:06.8",
    "created": "2023-03-09T08:02:31.68",
    "medias": [
      {
        "mediaId": 58265,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c967ad01-6684-412b-8a67-55c547bcf797/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c967ad01-6684-412b-8a67-55c547bcf797/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c967ad01-6684-412b-8a67-55c547bcf797/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 58253,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f2d1ecac-be0d-46f2-af5a-50c08e4a4eaf/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f2d1ecac-be0d-46f2-af5a-50c08e4a4eaf/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f2d1ecac-be0d-46f2-af5a-50c08e4a4eaf/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 58259,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a98ab45c-fcf2-4330-a876-7e2d83dcbe8a/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a98ab45c-fcf2-4330-a876-7e2d83dcbe8a/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a98ab45c-fcf2-4330-a876-7e2d83dcbe8a/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 58269,
        "title": "Nolinski Hotels - Evok Hôtels",
        "url": "https://pkslist.azureedge.net/media/images/1/a7845da3-6c02-4ccf-b1ae-f43118907b79/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a7845da3-6c02-4ccf-b1ae-f43118907b79/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a7845da3-6c02-4ccf-b1ae-f43118907b79/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 57547,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/eaf9f693-7388-499b-9756-794e02fb39f6/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/eaf9f693-7388-499b-9756-794e02fb39f6/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/eaf9f693-7388-499b-9756-794e02fb39f6/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 58257,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/3b9bde7b-b34d-4e3d-9e12-193b5fd75eb8/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3b9bde7b-b34d-4e3d-9e12-193b5fd75eb8/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3b9bde7b-b34d-4e3d-9e12-193b5fd75eb8/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 58266,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e99f6a81-c96b-4187-85d9-86f0196832e7/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e99f6a81-c96b-4187-85d9-86f0196832e7/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e99f6a81-c96b-4187-85d9-86f0196832e7/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 58267,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/fc16cd44-e637-446e-a8fd-74bc417cc469/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fc16cd44-e637-446e-a8fd-74bc417cc469/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fc16cd44-e637-446e-a8fd-74bc417cc469/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 57540,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c40f1917-8fe5-45c4-99b2-e055425cf188/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c40f1917-8fe5-45c4-99b2-e055425cf188/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c40f1917-8fe5-45c4-99b2-e055425cf188/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 57549,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/0b402667-0534-4fea-9d39-f641f066cc06/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0b402667-0534-4fea-9d39-f641f066cc06/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0b402667-0534-4fea-9d39-f641f066cc06/256x192.jpeg",
        "viewOrder": 9
      },
      {
        "mediaId": 57546,
        "title": "Nolinski Venezia – DUCO Galleria",
        "url": "https://pkslist.azureedge.net/media/images/1/1aa2db72-db91-45df-9584-f38dc09a9724/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1aa2db72-db91-45df-9584-f38dc09a9724/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1aa2db72-db91-45df-9584-f38dc09a9724/256x192.jpg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3392,
      "latitude": 45.43297099999999,
      "longitude": 12.3346522,
      "parentGeographicAreaId": 0,
      "title": "Calle Larga XXII Marzo, 2032, 30124 Venezia VE, Italy",
      "updated": null,
      "created": "2023-03-09T08:12:00"
    },
    "personalities": [
      {
        "tagId": 40,
        "tagName": "Boutique Bolthole ",
        "tagType": 1
      },
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 62,
        "tagName": "Nightlife",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 131,
        "tagName": "Library",
        "tagType": 2
      },
      {
        "tagId": 155,
        "tagName": "Art",
        "tagType": 2
      },
      {
        "tagId": 216,
        "tagName": "Museum",
        "tagType": 2
      },
      {
        "tagId": 222,
        "tagName": "Michelin Star dining",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 332,
        "tagName": "Fashion",
        "tagType": 2
      },
      {
        "tagId": 404,
        "tagName": "Sightseeing",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 806,
        "venueCategoryId": 35,
        "venueId": 3604,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3603,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Atlantis The Royal",
    "description": "The new iconic landmark of Dubai, Atlantis The Royal welcomes you to an experience that will completely redefine your perspective of luxury. Crafted by the world’s leading designers, architects and artists, this is a destination where everything has been designed to challenge the boundaries of imagination. Be taken on a journey of the impossible, with artful masterpieces, iconic entertainment and beautiful craftsmanship at every turn. Where the highest level of service sets a new standard in excellence.\nThis is the place where something incredible happens at every moment of your stay. This is Atlantis The Royal.",
    "brand": "",
    "blockQuote": "Go for sundowners at Cloud 22.",
    "emailAddress": "dxb-info@atlantisdubai.com",
    "website": "atlantis.com/atlantis-the-royal",
    "bookingURL": "https://www.atlantis.com/atlantis-the-royal/reservations/rooms-lo/book-now",
    "redirectNotes": "Atlantis The Royal's reservations system",
    "phoneNumber": "+971 4 426 0000",
    "addressOne": "",
    "addressTwo": "Crescent Road",
    "city": "Dubai",
    "county": "",
    "region": "",
    "country": "United Arab Emirates",
    "rooms": 795,
    "dollar": 3,
    "updated": "2023-03-09T07:40:41.97",
    "created": "2023-03-09T07:12:15.6",
    "medias": [
      {
        "mediaId": 57514,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d2d910ad-b33f-4ffc-8459-b74257caceb7/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d2d910ad-b33f-4ffc-8459-b74257caceb7/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d2d910ad-b33f-4ffc-8459-b74257caceb7/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 57537,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/34284d52-9eed-4fcd-a323-21d9656a57c3/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/34284d52-9eed-4fcd-a323-21d9656a57c3/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/34284d52-9eed-4fcd-a323-21d9656a57c3/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 57538,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/552933b3-28bc-44f3-ba30-34c8bb00d779/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/552933b3-28bc-44f3-ba30-34c8bb00d779/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/552933b3-28bc-44f3-ba30-34c8bb00d779/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 57516,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/16c7d0db-1af5-478c-88e6-72ccd2d9464c/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/16c7d0db-1af5-478c-88e6-72ccd2d9464c/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/16c7d0db-1af5-478c-88e6-72ccd2d9464c/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 57518,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/cada67e5-0670-4e01-a47d-d1ae4d60a87d/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cada67e5-0670-4e01-a47d-d1ae4d60a87d/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cada67e5-0670-4e01-a47d-d1ae4d60a87d/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 57530,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f1719d98-93b8-4259-ae36-9602d69bc375/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f1719d98-93b8-4259-ae36-9602d69bc375/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f1719d98-93b8-4259-ae36-9602d69bc375/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 57521,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/32c978df-4626-4fe1-8b54-e0a9aa6cd42d/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/32c978df-4626-4fe1-8b54-e0a9aa6cd42d/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/32c978df-4626-4fe1-8b54-e0a9aa6cd42d/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 57524,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/130d7c12-e658-407d-b45a-b98fd3c8afa8/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/130d7c12-e658-407d-b45a-b98fd3c8afa8/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/130d7c12-e658-407d-b45a-b98fd3c8afa8/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 57517,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/0278184e-ca4b-47c2-915d-f6dc9770e1e7/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0278184e-ca4b-47c2-915d-f6dc9770e1e7/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0278184e-ca4b-47c2-915d-f6dc9770e1e7/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 57536,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/1e499e42-eb59-4078-aa1c-22aaeb7d55d1/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1e499e42-eb59-4078-aa1c-22aaeb7d55d1/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1e499e42-eb59-4078-aa1c-22aaeb7d55d1/256x192.jpeg",
        "viewOrder": 9
      },
      {
        "mediaId": 57529,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/84d590ec-71e8-4775-b997-859811e292a2/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/84d590ec-71e8-4775-b997-859811e292a2/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/84d590ec-71e8-4775-b997-859811e292a2/256x192.jpeg",
        "viewOrder": 10
      },
      {
        "mediaId": 57532,
        "title": "The most beautiful hotels with an infinity pool in Dubai ...",
        "url": "https://pkslist.azureedge.net/media/images/1/79e5a12f-32c5-4b37-a218-c84eb37cab96/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/79e5a12f-32c5-4b37-a218-c84eb37cab96/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/79e5a12f-32c5-4b37-a218-c84eb37cab96/256x192.jpg",
        "viewOrder": 11
      },
      {
        "mediaId": 57519,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e64628a2-c02d-4194-83c9-43f96f19e2f5/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e64628a2-c02d-4194-83c9-43f96f19e2f5/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e64628a2-c02d-4194-83c9-43f96f19e2f5/256x192.jpeg",
        "viewOrder": 12
      },
      {
        "mediaId": 57527,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/3bc37ae7-28a4-4254-9756-83932511f761/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3bc37ae7-28a4-4254-9756-83932511f761/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3bc37ae7-28a4-4254-9756-83932511f761/256x192.jpeg",
        "viewOrder": 13
      },
      {
        "mediaId": 57528,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/fe8e6281-ae31-47c4-8817-53d79352d965/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fe8e6281-ae31-47c4-8817-53d79352d965/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fe8e6281-ae31-47c4-8817-53d79352d965/256x192.jpeg",
        "viewOrder": 14
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3391,
      "latitude": 25.1390557,
      "longitude": 55.1279662,
      "parentGeographicAreaId": 0,
      "title": "Palm Jumeirah - Crescent Rd - The Palm Jumeirah - Dubai - United Arab Emirates",
      "updated": null,
      "created": "2023-03-09T07:20:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 48,
        "tagName": "Iconic/Landmark ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 58,
        "tagName": "Beach",
        "tagType": 2
      },
      {
        "tagId": 62,
        "tagName": "Nightlife",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 178,
        "tagName": "Rooftop Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 181,
        "tagName": "Private/Suite Pools",
        "tagType": 2
      },
      {
        "tagId": 222,
        "tagName": "Michelin Star dining",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 284,
        "tagName": "Architecture",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 805,
        "venueCategoryId": 35,
        "venueId": 3603,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3598,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Six Senses Fort Barwara",
    "description": "A beautiful 14th-century fort sensitively converted to become a Six Senses sanctuary of well-being. Originally owned by a Rajasthani Royal Family, it faces the Chauth ka Barwara Mandir temple. The significant conservation effort incorporates two palaces and two temples within the walled fort. The design of Six Senses resort in Rajasthan reinterprets the gracious and regal ambiance of a bygone era dating back 700 years.",
    "brand": "Six Senses",
    "blockQuote": "For a magical evening, book the Aravali sunset experience.",
    "emailAddress": "reservations-fortbarwara@sixsenses.com",
    "website": "sixsenses.com/en/resorts/fort-barwara",
    "bookingURL": "https://www.sixsenses.com/en/resorts/fort-barwara/accomodation-results?newSearch=1&src=&Hotel=JAISS&Rooms=1&Adult=2&Child=0&_PMID=96027890",
    "redirectNotes": "Six Senses Fort Barwara's reservations system",
    "phoneNumber": "+91 7462 257777",
    "addressOne": "",
    "addressTwo": "",
    "city": "Chauth Ka Barwara",
    "county": "",
    "region": "Rajasthan",
    "country": "India",
    "rooms": 48,
    "dollar": 3,
    "updated": "2023-02-08T12:35:01.353",
    "created": "2023-02-08T12:08:45.253",
    "medias": [
      {
        "mediaId": 57426,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ac880113-eeda-4712-812e-1965c9489686/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ac880113-eeda-4712-812e-1965c9489686/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ac880113-eeda-4712-812e-1965c9489686/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 57429,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f0fa4062-3990-46d7-a768-8e13385a9d8b/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f0fa4062-3990-46d7-a768-8e13385a9d8b/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f0fa4062-3990-46d7-a768-8e13385a9d8b/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 57428,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e6f3f8b7-da72-4ce6-b79a-fe1ec143265c/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e6f3f8b7-da72-4ce6-b79a-fe1ec143265c/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e6f3f8b7-da72-4ce6-b79a-fe1ec143265c/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 57430,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/fc2e3cd7-a808-48b2-a146-5dec3a18d783/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fc2e3cd7-a808-48b2-a146-5dec3a18d783/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fc2e3cd7-a808-48b2-a146-5dec3a18d783/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 57432,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c831f129-d233-46ea-9b93-a2cc31461474/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c831f129-d233-46ea-9b93-a2cc31461474/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c831f129-d233-46ea-9b93-a2cc31461474/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 57441,
        "title": "The 17 Best Wellness Retreats in the World: Thermal Baths in Baden ...",
        "url": "https://pkslist.azureedge.net/media/images/1/f9881429-bbea-40ed-b7fa-bad867af948d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f9881429-bbea-40ed-b7fa-bad867af948d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f9881429-bbea-40ed-b7fa-bad867af948d/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 57436,
        "title": "Six Senses Fort Barwara — Hotel Review | Condé Nast Traveler",
        "url": "https://pkslist.azureedge.net/media/images/1/efa8b67c-7396-413f-9483-dc1e51012e1a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/efa8b67c-7396-413f-9483-dc1e51012e1a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/efa8b67c-7396-413f-9483-dc1e51012e1a/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 57427,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b6128994-702a-41f1-989a-9b31aed8b23c/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b6128994-702a-41f1-989a-9b31aed8b23c/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b6128994-702a-41f1-989a-9b31aed8b23c/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 57442,
        "title": "Indian palace dream hotel - THE Stylemate",
        "url": "https://pkslist.azureedge.net/media/images/1/2d5108ae-54be-4b79-a355-227df6309cd7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2d5108ae-54be-4b79-a355-227df6309cd7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2d5108ae-54be-4b79-a355-227df6309cd7/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 57439,
        "title": "This 14th century fort-turned-resort in Rajasthan offers luxury ...",
        "url": "https://pkslist.azureedge.net/media/images/1/66cb9229-74a3-4dea-9a7f-8661b59c6d8a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/66cb9229-74a3-4dea-9a7f-8661b59c6d8a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/66cb9229-74a3-4dea-9a7f-8661b59c6d8a/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 57433,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f450742a-a111-4547-bf8f-007b1ae958a5/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f450742a-a111-4547-bf8f-007b1ae958a5/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f450742a-a111-4547-bf8f-007b1ae958a5/256x192.jpeg",
        "viewOrder": 12
      },
      {
        "mediaId": 57444,
        "title": "Six Senses Fort Barwara – Time to Chill Out! | Wild Frontiers",
        "url": "https://pkslist.azureedge.net/media/images/1/e12d3b1e-92d9-42fe-bdec-61c98c43e83c/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e12d3b1e-92d9-42fe-bdec-61c98c43e83c/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e12d3b1e-92d9-42fe-bdec-61c98c43e83c/256x192.jpeg",
        "viewOrder": 13
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3386,
      "latitude": 26.0505702,
      "longitude": 76.1579884,
      "parentGeographicAreaId": 0,
      "title": "Six Senses Fort Barwara, Chauth Ka Barwara, Rajasthan 322702, India",
      "updated": null,
      "created": "2023-02-08T12:14:00"
    },
    "personalities": [
      {
        "tagId": 45,
        "tagName": "Historic Sense",
        "tagType": 1
      },
      {
        "tagId": 57,
        "tagName": "Romantic",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 252,
        "tagName": "National Park",
        "tagType": 2
      },
      {
        "tagId": 259,
        "tagName": "Picnic",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 296,
        "tagName": "Guided Walk",
        "tagType": 2
      },
      {
        "tagId": 299,
        "tagName": "Wellness Programs",
        "tagType": 2
      },
      {
        "tagId": 319,
        "tagName": "Temples",
        "tagType": 2
      },
      {
        "tagId": 410,
        "tagName": "Sundownders",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 804,
        "venueCategoryId": 35,
        "venueId": 3598,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3593,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Raffles Doha",
    "description": "Welcome to Raffles Doha, an ultra-luxury hotel in Qatar’s iconic Katara Towers with all-suite accommodation, personal butlers and family-friendly services.\n\nQatar is a rising star, the Middle Eastern pearl of art and culture. During your stay at Raffles Doha, make time to see, taste and experience the unique hallmarks of this storied yet forward-looking nation.",
    "brand": "",
    "blockQuote": "Head up to Acoustic Music Penthouse for cocktails, tapas and live music.",
    "emailAddress": "reservations.doha@raffles.com",
    "website": "raffles.com/doha/",
    "bookingURL": "https://all.accor.com/ssr/app/accor/rates/B662/index.en.shtml?dateIn=2023-02-06&nights=1&compositions=2&stayplus=false&snu=false&accessibleRooms=false&otConsentString=,C0001,C0003,C0007,C0002,C0004,&partner_id=raffles&_gl=1%2a17jb87c%2a_ga%2aMTUyMDM3OTU0OC4xNjc1Njg0MzYw%2a_ga_KS93RBNSHZ%2aMTY3NTY4NDM4MC4xLjEuMTY3NTY4NDc0Mi40Ni4wLjA.&_ga=2.182061857.764732041.1675684380-1520379548.1675684360&otConsentId=a67eaae3-7f00-4da0-a769-c05348d86857&iata=96027890",
    "redirectNotes": "Raffles Doha's reservations system",
    "phoneNumber": "+974 4030 7100",
    "addressOne": "",
    "addressTwo": "",
    "city": "Doha",
    "county": "",
    "region": "",
    "country": "Qatar",
    "rooms": 132,
    "dollar": 3,
    "updated": "2023-02-06T12:13:19.763",
    "created": "2023-02-06T11:54:26.69",
    "medias": [
      {
        "mediaId": 57334,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/86079e4e-a40a-453c-a0c6-b2e560d004c4/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/86079e4e-a40a-453c-a0c6-b2e560d004c4/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/86079e4e-a40a-453c-a0c6-b2e560d004c4/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 57343,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/253a7dc7-7b57-416b-8aeb-94787ee5e88d/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/253a7dc7-7b57-416b-8aeb-94787ee5e88d/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/253a7dc7-7b57-416b-8aeb-94787ee5e88d/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 57340,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/dd492a37-d533-4ce7-893c-37480f238688/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/dd492a37-d533-4ce7-893c-37480f238688/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/dd492a37-d533-4ce7-893c-37480f238688/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 57337,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/5f211580-2b16-4cb4-8d57-9f0b65df1ed7/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5f211580-2b16-4cb4-8d57-9f0b65df1ed7/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5f211580-2b16-4cb4-8d57-9f0b65df1ed7/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 57336,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/3902a01c-1af8-4a69-90c2-db828e43fea5/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3902a01c-1af8-4a69-90c2-db828e43fea5/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3902a01c-1af8-4a69-90c2-db828e43fea5/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 57338,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/94885ea8-3a16-4509-8944-5717f888566e/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/94885ea8-3a16-4509-8944-5717f888566e/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/94885ea8-3a16-4509-8944-5717f888566e/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 57339,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c870b68f-4a67-4e6f-8854-aec13d2f8856/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c870b68f-4a67-4e6f-8854-aec13d2f8856/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c870b68f-4a67-4e6f-8854-aec13d2f8856/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 57341,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/2dc337c3-64a4-401d-94fa-383f03cd2ad1/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2dc337c3-64a4-401d-94fa-383f03cd2ad1/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2dc337c3-64a4-401d-94fa-383f03cd2ad1/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 57342,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/0d7206ac-7803-4be3-b659-1a3020f1c2e9/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0d7206ac-7803-4be3-b659-1a3020f1c2e9/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0d7206ac-7803-4be3-b659-1a3020f1c2e9/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 57344,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/4b6381f2-4087-47f3-8779-7848ed439054/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4b6381f2-4087-47f3-8779-7848ed439054/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4b6381f2-4087-47f3-8779-7848ed439054/256x192.jpeg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3381,
      "latitude": 25.38990039999999,
      "longitude": 51.5314515,
      "parentGeographicAreaId": 0,
      "title": "Marina East Street Lusail, الدوحة، Qatar",
      "updated": null,
      "created": "2023-02-06T12:01:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 100,
        "tagName": "Wedding",
        "tagType": 2
      },
      {
        "tagId": 105,
        "tagName": "Afternoon Tea",
        "tagType": 2
      },
      {
        "tagId": 153,
        "tagName": "Cigar Lounge",
        "tagType": 2
      },
      {
        "tagId": 155,
        "tagName": "Art",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 284,
        "tagName": "Architecture",
        "tagType": 2
      },
      {
        "tagId": 299,
        "tagName": "Wellness Programs",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 802,
        "venueCategoryId": 35,
        "venueId": 3593,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3571,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Four Seasons Hotel Nashville",
    "description": "Let us show you the real Nashville, connecting you to the creative spirit of the city’s artisans. Four Seasons is the new social hub of downtown Nashville’s SoBro neighbourhood, just steps from music, sports and entertainment. Feel the rhythm of our vibrant restaurants and event spaces, and the harmony of our Spa and rooftop pool – celebrating nature with views of the Cumberland River and Riverfront Park. With unparalleled Four Seasons service and warm Southern hospitality, we’ll inspire a truly authentic experience of Music City.",
    "brand": "Four Seasons",
    "blockQuote": "Go for a swim in the rooftop pool followed by a soak in the huge hot tub.",
    "emailAddress": "members@pks-list.com",
    "website": "fourseasons.com/nashville/",
    "bookingURL": "https://www.sohohouse.com/bedrooms",
    "redirectNotes": "Four Seasons Hotel Nashville's reservations system",
    "phoneNumber": "+1 615 610 5001",
    "addressOne": "100",
    "addressTwo": "Demonbreun Street",
    "city": "Nashville",
    "county": "",
    "region": "Tennessee",
    "country": "United States",
    "rooms": 235,
    "dollar": 3,
    "updated": "2022-12-16T12:20:17.78",
    "created": "2022-12-16T09:51:45.9",
    "medias": [
      {
        "mediaId": 56931,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/9623e638-8f75-49bd-8a55-5394a545391b/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9623e638-8f75-49bd-8a55-5394a545391b/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9623e638-8f75-49bd-8a55-5394a545391b/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 56932,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/64c8d169-5320-4971-b796-8a23225314a4/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/64c8d169-5320-4971-b796-8a23225314a4/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/64c8d169-5320-4971-b796-8a23225314a4/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 56929,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/0517dbf0-ff70-4612-8546-d735a02c1d4d/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0517dbf0-ff70-4612-8546-d735a02c1d4d/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0517dbf0-ff70-4612-8546-d735a02c1d4d/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 56930,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/2bae0621-6c3f-4c62-bb8d-e02692781b7d/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2bae0621-6c3f-4c62-bb8d-e02692781b7d/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2bae0621-6c3f-4c62-bb8d-e02692781b7d/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 56933,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e97fcd07-f491-4be2-b5ff-0bcf2cb74cb7/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e97fcd07-f491-4be2-b5ff-0bcf2cb74cb7/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e97fcd07-f491-4be2-b5ff-0bcf2cb74cb7/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 56934,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/3ea53e0d-4eb5-43dc-9fa2-4677d3d5450b/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3ea53e0d-4eb5-43dc-9fa2-4677d3d5450b/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3ea53e0d-4eb5-43dc-9fa2-4677d3d5450b/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 56939,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/3f098bc1-8abe-412f-905d-5ad896a8b8ab/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3f098bc1-8abe-412f-905d-5ad896a8b8ab/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3f098bc1-8abe-412f-905d-5ad896a8b8ab/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 56935,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/8fc17603-8f5d-40df-ad23-b95a12b62640/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8fc17603-8f5d-40df-ad23-b95a12b62640/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8fc17603-8f5d-40df-ad23-b95a12b62640/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 56936,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/8199d8a3-9dcf-4373-bccd-c806b664485d/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8199d8a3-9dcf-4373-bccd-c806b664485d/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8199d8a3-9dcf-4373-bccd-c806b664485d/256x192.jpeg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3359,
      "latitude": 36.1600753,
      "longitude": -86.773679,
      "parentGeographicAreaId": 0,
      "title": "100 Demonbreun St, Nashville, TN 37201, USA",
      "updated": null,
      "created": "2022-12-16T10:01:00"
    },
    "personalities": [
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 127,
        "tagName": "Hair Salon",
        "tagType": 2
      },
      {
        "tagId": 154,
        "tagName": "Rooftop Pool",
        "tagType": 2
      },
      {
        "tagId": 197,
        "tagName": "Café",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 255,
        "tagName": "Music",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 294,
        "tagName": "Event Space",
        "tagType": 2
      },
      {
        "tagId": 323,
        "tagName": "City Walk",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 799,
        "venueCategoryId": 35,
        "venueId": 3571,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3546,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Four Seasons Resort Tamarindo",
    "description": "Escape to a secluded nature reserve in Mexico’s Tamarindo, the pristine coastline where verdant jungle meets the Pacific Ocean. With our unique location on a private peninsula, Four Seasons Resort Tamarindo, México, invites you to leave the world behind and explore this undiscovered region of Mexico – far from everything, yet remotely close. This particular topography, and the wildlife that calls it home, make the area a treasure to be protected. We provide a natural refuge for those who seek an authentic connection to Mexico, its biodiversity and culture.",
    "brand": "Four Seasons",
    "blockQuote": "For a romantic experience, book the Cliffside Suites as they are for adults only.",
    "emailAddress": "reservations.tamarindo@fourseasons.com",
    "website": "fourseasons.com/tamarindo/",
    "bookingURL": "https://reservations.fourseasons.com/?generalReservationForm.checkInDate=2022-11-17&generalReservationForm.checkOutDate=2022-11-18&generalReservationForm.guestCountPerRoom%5B0%5D.adultCount=2&generalReservationForm.guestCountPerRoom%5B0%5D.childCount=0&cawConfig.customBooker=true&generalReservationForm.locationId=ZLO908&generalReservationForm.iata=96027890&cawConfig.promoByGuest=true",
    "redirectNotes": "Four Seasons Resort Tamarindo's reservations system",
    "phoneNumber": "+52 357 689 0012",
    "addressOne": "",
    "addressTwo": "Carretera Federal 200",
    "city": "Barra de Navidad",
    "county": "",
    "region": "",
    "country": "Mexico",
    "rooms": 157,
    "dollar": 3,
    "updated": "2022-11-17T06:51:45.87",
    "created": "2022-11-17T06:24:34.15",
    "medias": [
      {
        "mediaId": 56408,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/100377e4-b57c-4700-ada7-162cf675ce20/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/100377e4-b57c-4700-ada7-162cf675ce20/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/100377e4-b57c-4700-ada7-162cf675ce20/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 56409,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/6f1f2408-e33c-4474-97a0-80b6bffc8f25/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6f1f2408-e33c-4474-97a0-80b6bffc8f25/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6f1f2408-e33c-4474-97a0-80b6bffc8f25/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 56411,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/8489b8cf-8d32-4128-b485-39f58ab1ee81/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8489b8cf-8d32-4128-b485-39f58ab1ee81/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8489b8cf-8d32-4128-b485-39f58ab1ee81/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 56413,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/55366af3-44a0-48ed-bd4a-2c7c8f14f41b/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/55366af3-44a0-48ed-bd4a-2c7c8f14f41b/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/55366af3-44a0-48ed-bd4a-2c7c8f14f41b/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 56412,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/391bf09b-5a69-415f-83cb-9b3b3d07bebc/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/391bf09b-5a69-415f-83cb-9b3b3d07bebc/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/391bf09b-5a69-415f-83cb-9b3b3d07bebc/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 56414,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/474c1d65-2a33-4b3e-a3be-02f6847f63bc/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/474c1d65-2a33-4b3e-a3be-02f6847f63bc/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/474c1d65-2a33-4b3e-a3be-02f6847f63bc/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 56419,
        "title": "Four Seasons Resort Tamarindo is Now Welcoming Guests - Vallarta ...",
        "url": "https://pkslist.azureedge.net/media/images/1/2bbf2027-a2c8-489b-9b38-f417d1a65498/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2bbf2027-a2c8-489b-9b38-f417d1a65498/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2bbf2027-a2c8-489b-9b38-f417d1a65498/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 56410,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/54f3f646-2128-42d4-a54c-db10ed0ed72e/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/54f3f646-2128-42d4-a54c-db10ed0ed72e/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/54f3f646-2128-42d4-a54c-db10ed0ed72e/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 56417,
        "title": "VIP arrivals: hottest hotel openings in March 2022 • Hotel Designs",
        "url": "https://pkslist.azureedge.net/media/images/1/a37c732e-8173-44c7-b0fa-b8b49ca49081/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a37c732e-8173-44c7-b0fa-b8b49ca49081/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a37c732e-8173-44c7-b0fa-b8b49ca49081/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 56423,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/1981b5f6-da8e-4aa4-9798-33c1f4debd43/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1981b5f6-da8e-4aa4-9798-33c1f4debd43/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1981b5f6-da8e-4aa4-9798-33c1f4debd43/256x192.jpeg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3334,
      "latitude": 19.2559434,
      "longitude": -104.8053523,
      "parentGeographicAreaId": 0,
      "title": "La Huerta, Jalisco, México Km. 7.5, México 200, 48970 Barra de Navidad, Jal., Mexico",
      "updated": null,
      "created": "2022-11-17T06:38:00"
    },
    "personalities": [
      {
        "tagId": 157,
        "tagName": "Beach Escape",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 58,
        "tagName": "Beach",
        "tagType": 2
      },
      {
        "tagId": 81,
        "tagName": "Golf",
        "tagType": 2
      },
      {
        "tagId": 83,
        "tagName": "Kid's Club",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 100,
        "tagName": "Wedding",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 155,
        "tagName": "Art",
        "tagType": 2
      },
      {
        "tagId": 276,
        "tagName": "Gym",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 320,
        "tagName": "Organic Garden",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 797,
        "venueCategoryId": 35,
        "venueId": 3546,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3534,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Six Senses Rome",
    "description": "No matter where we are, for us, la dolce vita is a life where you’re connected to what matters. Six Senses hotel in Rome translates this vision to offer both an escape and a sensory-rich journey for international guests and locals. Layered on to this are little rituals contrasting with the grandiose beauty of the Eternal City. From BIVIUM Restaurant-Café-Bar, the spot where all our paths cross, to a unique Rome-at-your-feet feeling on NOTOS Rooftop, and our take on the ancient art of bathing – all just steps away from historic landmarks and glorious works of art.",
    "brand": "",
    "blockQuote": "Visit the spa for a Roman Bath with the latest technology.",
    "emailAddress": "reservations-rome@sixsenses.com",
    "website": "sixsenses.com/en/hotels/rome",
    "bookingURL": "https://www.sixsenses.com/en/hotels/rome/accommodation-results?newSearch=1&src=&Hotel=ROMSS&Rooms=1&Adult=2&Child=0&_PMID=96027890",
    "redirectNotes": "Six Senses Rome's reservations system ",
    "phoneNumber": "+39 06 86814000",
    "addressOne": "",
    "addressTwo": "Piazza San Marcello",
    "city": "Rome",
    "county": "",
    "region": "",
    "country": "Italy",
    "rooms": 96,
    "dollar": 3,
    "updated": "2023-08-21T16:17:48.097",
    "created": "2022-11-14T12:38:38.567",
    "medias": [
      {
        "mediaId": 59145,
        "title": "Relax Italo-Style at Six Senses Rome, and Other News – SURFACE",
        "url": "https://pkslist.azureedge.net/media/images/1/f88b7534-f3aa-4bbb-acaa-0c262a157f1b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f88b7534-f3aa-4bbb-acaa-0c262a157f1b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f88b7534-f3aa-4bbb-acaa-0c262a157f1b/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 59142,
        "title": "Six Senses | IHG Development",
        "url": "https://pkslist.azureedge.net/media/images/1/dbdcc14e-4fa1-4d2a-b8bf-00107123ef39/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/dbdcc14e-4fa1-4d2a-b8bf-00107123ef39/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/dbdcc14e-4fa1-4d2a-b8bf-00107123ef39/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 59144,
        "title": "Hotelneueröffnung: Six Senses Rom eröffnet 2023 - Falstaff TRAVEL",
        "url": "https://pkslist.azureedge.net/media/images/1/79f9dab7-645d-4edc-89dd-8a1e338d6274/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/79f9dab7-645d-4edc-89dd-8a1e338d6274/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/79f9dab7-645d-4edc-89dd-8a1e338d6274/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 59129,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f59373dd-f5d7-4216-95b0-df5aeadda997/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f59373dd-f5d7-4216-95b0-df5aeadda997/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f59373dd-f5d7-4216-95b0-df5aeadda997/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 59131,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/54eaddc1-bc87-40d3-a669-cf074e0d466b/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/54eaddc1-bc87-40d3-a669-cf074e0d466b/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/54eaddc1-bc87-40d3-a669-cf074e0d466b/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 59132,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/3654d950-b3dd-4a85-be01-a1c7bb101d0a/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3654d950-b3dd-4a85-be01-a1c7bb101d0a/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3654d950-b3dd-4a85-be01-a1c7bb101d0a/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 59126,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/502420ad-91e9-4144-b34d-1e78bc182bf2/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/502420ad-91e9-4144-b34d-1e78bc182bf2/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/502420ad-91e9-4144-b34d-1e78bc182bf2/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 59127,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a2c7c851-ad77-4fd0-92f3-a699ce3be778/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a2c7c851-ad77-4fd0-92f3-a699ce3be778/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a2c7c851-ad77-4fd0-92f3-a699ce3be778/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 59143,
        "title": "Six Senses Rome, Rome : Five Star Alliance",
        "url": "https://pkslist.azureedge.net/media/images/1/420afe1c-518d-4029-9552-06bab3951d21/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/420afe1c-518d-4029-9552-06bab3951d21/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/420afe1c-518d-4029-9552-06bab3951d21/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 59139,
        "title": "Six Senses Rome — Hotel Review | Condé Nast Traveler",
        "url": "https://pkslist.azureedge.net/media/images/1/62aafcd3-cce1-4384-b6e7-c73d5734f17b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/62aafcd3-cce1-4384-b6e7-c73d5734f17b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/62aafcd3-cce1-4384-b6e7-c73d5734f17b/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 59135,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a2398356-f086-43b0-8e88-2bfa4517eec3/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a2398356-f086-43b0-8e88-2bfa4517eec3/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a2398356-f086-43b0-8e88-2bfa4517eec3/256x192.jpeg",
        "viewOrder": 10
      },
      {
        "mediaId": 59134,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/3e7f24bc-4c7f-4007-8e3b-50d6813afc93/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3e7f24bc-4c7f-4007-8e3b-50d6813afc93/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3e7f24bc-4c7f-4007-8e3b-50d6813afc93/256x192.jpeg",
        "viewOrder": 11
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3322,
      "latitude": 41.8986593,
      "longitude": 12.4814726,
      "parentGeographicAreaId": 0,
      "title": "P.za S. Marcello, 00187 Roma RM, Italy",
      "updated": null,
      "created": "2022-11-14T12:45:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 55,
        "tagName": "Lively",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 59,
        "tagName": "Cultural",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 155,
        "tagName": "Art",
        "tagType": 2
      },
      {
        "tagId": 213,
        "tagName": "Roof Terrace",
        "tagType": 2
      },
      {
        "tagId": 216,
        "tagName": "Museum",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 296,
        "tagName": "Guided Walk",
        "tagType": 2
      },
      {
        "tagId": 311,
        "tagName": "Cathedrals",
        "tagType": 2
      },
      {
        "tagId": 404,
        "tagName": "Sightseeing",
        "tagType": 2
      },
      {
        "tagId": 440,
        "tagName": "Unesco",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 795,
        "venueCategoryId": 35,
        "venueId": 3534,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3468,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Hotel Barriere Fouquet's New York",
    "description": "Welcome to 456 Greenwich Street. The newest New York address with an indefinable je-ne-sais-quoi. A hotel that elegantly whisks you from the charming streets of Tribeca to the heart of a Parisian maison. Designed by the Martin Brudnizki Design Studio, the hotel celebrates the art of living and receiving à la Française and features. 97 Art Deco-inspired Rooms and Suites, fine dining and vegetarian restaurants, a private spa and a movie theater.",
    "brand": "",
    "blockQuote": "Titsou is like stepping into Paris in the 20's.",
    "emailAddress": "members@pks-list.com",
    "website": "hotelsbarriere.com/en/new-york/fouquets-new-york.html",
    "bookingURL": "https://www.book-secure.com/index.php?s=results&property=usnew29097&arrival=2022-09-08&departure=2022-09-09&rate=BAR%2CFLEXBB%2CNFL%2CNFLBB%2CIB2%2CIB4%2CIB5%2CIB6&adults1=2&children1=0&locale=en_GB&currency=USD&stid=ti2p5lqar&redir=BIZ-so5523q0o4&Clusternames=USAGROHTLHotelBarrie&rt=1662647176&connectName=USAGROHTLHotelBarrie&cname=USAGROHTLHotelBarrie&Hotelnames=Usa-GRO-Hotel-Barriere-Le-Fouquet-S-New-York&hname=Usa-GRO-Hotel-Barriere-Le-Fouquet-S-New-York&cluster=USAGROHTLHotelBarrie",
    "redirectNotes": "Hotel Barriere Fouquet's New York's reservations system",
    "phoneNumber": "+1 917 672 0935",
    "addressOne": "456",
    "addressTwo": "Greenwich Street",
    "city": "Tribeca",
    "county": "New York",
    "region": "New York",
    "country": "United States",
    "rooms": 97,
    "dollar": 3,
    "updated": "2022-09-08T14:41:59.4",
    "created": "2022-09-08T14:22:48.83",
    "medias": [
      {
        "mediaId": 54946,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f17ff1b0-c453-4292-b7a1-42fcdf09376a/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f17ff1b0-c453-4292-b7a1-42fcdf09376a/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f17ff1b0-c453-4292-b7a1-42fcdf09376a/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 54949,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/10243c73-755a-4b2e-bd03-f11c7bfe87ea/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/10243c73-755a-4b2e-bd03-f11c7bfe87ea/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/10243c73-755a-4b2e-bd03-f11c7bfe87ea/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 54953,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e1220dc8-ad41-4c28-b2a2-fe908e25af75/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e1220dc8-ad41-4c28-b2a2-fe908e25af75/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e1220dc8-ad41-4c28-b2a2-fe908e25af75/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 54954,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/df9b6ca4-0429-47e5-a28c-05134fe80cc5/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/df9b6ca4-0429-47e5-a28c-05134fe80cc5/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/df9b6ca4-0429-47e5-a28c-05134fe80cc5/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 54947,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/2c645bb8-8a99-4fc8-bdcc-472b91e0a88c/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2c645bb8-8a99-4fc8-bdcc-472b91e0a88c/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2c645bb8-8a99-4fc8-bdcc-472b91e0a88c/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 54957,
        "title": "Le Fouquet's New York – Caspi Development",
        "url": "https://pkslist.azureedge.net/media/images/1/a0b10170-3d89-4a32-9b8c-981d28ad422a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a0b10170-3d89-4a32-9b8c-981d28ad422a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a0b10170-3d89-4a32-9b8c-981d28ad422a/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 54950,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/0be0eb23-3ff5-4d19-b1e4-298624900e4e/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0be0eb23-3ff5-4d19-b1e4-298624900e4e/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0be0eb23-3ff5-4d19-b1e4-298624900e4e/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 54952,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/3d363752-e221-4a1d-b2e0-24f7cf7fa895/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3d363752-e221-4a1d-b2e0-24f7cf7fa895/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3d363752-e221-4a1d-b2e0-24f7cf7fa895/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 54955,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ecba1f8e-d5e9-43e0-aae8-66022faa5008/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ecba1f8e-d5e9-43e0-aae8-66022faa5008/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ecba1f8e-d5e9-43e0-aae8-66022faa5008/256x192.jpeg",
        "viewOrder": 10
      },
      {
        "mediaId": 54956,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ff122fab-41cb-4d0b-bfc4-f575b8666d7a/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ff122fab-41cb-4d0b-bfc4-f575b8666d7a/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ff122fab-41cb-4d0b-bfc4-f575b8666d7a/256x192.jpeg",
        "viewOrder": 11
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3256,
      "latitude": 40.7236168,
      "longitude": -74.0097526,
      "parentGeographicAreaId": 0,
      "title": "456 Greenwich St, New York, NY 10013, USA",
      "updated": null,
      "created": "2022-09-08T14:34:00"
    },
    "personalities": [
      {
        "tagId": 51,
        "tagName": "Style Icon",
        "tagType": 1
      },
      {
        "tagId": 57,
        "tagName": "Romantic",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 62,
        "tagName": "Nightlife",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 78,
        "tagName": "Fine Dining",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 101,
        "tagName": "Cinema",
        "tagType": 2
      },
      {
        "tagId": 199,
        "tagName": "Art Gallery",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 323,
        "tagName": "City Walk",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 791,
        "venueCategoryId": 35,
        "venueId": 3468,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3431,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Sublime Lisboa",
    "description": "Located in the heart of the Amoreiras neighborhood next to one of Lisbon’s most privileged arteries, surrounded by historical buildings, charming gardens, museums, historical monuments and an Aqueduct, an elegant 20th century townhouse turned into a luxury boutique hotel opens its doors as Sublime Lisboa.\n\nSublime Lisboa has 15 rooms and suites which embody an expressly Portuguese residency from the 20th century turned into a sophisticated, yet relaxed, luxury boutique hotel. With carefully selected decor, each room is different providing guests an incomparably chic individually tailored atmosphere amidst one of Lisbon’s most centric neighborhoods.",
    "brand": "",
    "blockQuote": "Book the Sublime Suite with a private terrace for the best city views.",
    "emailAddress": "reservations@sublimelisboa.pt",
    "website": "sublimelisboa.pt/",
    "bookingURL": "https://secure.guestcentric.net/api/bg/book.php?apikey=7abafb15d2b40975462e1150ec60701b&url=https%3A%2F%2Fwww.sublimelisboa.pt%2F&channelKey=58e5de4e971fc00be29aa10492813ad4&_ga=2.160641721.509343355.1658508912-1311450075.1658508912&iata=96027890",
    "redirectNotes": "Sublime Lisboa's reservations system",
    "phoneNumber": "+351 21 846 2121",
    "addressOne": "10",
    "addressTwo": "Rua Marquês de Subserra",
    "city": "Lisbon",
    "county": "",
    "region": "",
    "country": "Portugal",
    "rooms": 15,
    "dollar": 2,
    "updated": "2022-07-25T13:09:59.867",
    "created": "2022-07-22T16:59:03.973",
    "medias": [
      {
        "mediaId": 54106,
        "title": "Sublime Lisboa - Querido",
        "url": "https://pkslist.azureedge.net/media/images/1/06ca1c97-77e5-48c9-8962-7b19a5ab9910/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/06ca1c97-77e5-48c9-8962-7b19a5ab9910/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/06ca1c97-77e5-48c9-8962-7b19a5ab9910/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 54105,
        "title": "Restaurant & Bar - Sublime Lisboa",
        "url": "https://pkslist.azureedge.net/media/images/1/daf1327b-9cd3-48b7-aa65-9fde1d704635/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/daf1327b-9cd3-48b7-aa65-9fde1d704635/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/daf1327b-9cd3-48b7-aa65-9fde1d704635/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 54108,
        "title": "Da Comporta para as Avenidas Novas, como o Sublime Lisboa ocupou ...",
        "url": "https://pkslist.azureedge.net/media/images/1/82b2f471-a100-446c-a6c8-970cc6bdf06f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/82b2f471-a100-446c-a6c8-970cc6bdf06f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/82b2f471-a100-446c-a6c8-970cc6bdf06f/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 54110,
        "title": "Europe: The Most Anticipated Hotels Opening of 2022 . ATD, a ...",
        "url": "https://pkslist.azureedge.net/media/images/1/4a640b2a-ff02-472d-9d40-de16f14d8bbc/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4a640b2a-ff02-472d-9d40-de16f14d8bbc/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4a640b2a-ff02-472d-9d40-de16f14d8bbc/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 54107,
        "title": "Home Page - Sublime Lisboa",
        "url": "https://pkslist.azureedge.net/media/images/1/889d7505-e14d-4782-bcbd-9233ba8e9c27/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/889d7505-e14d-4782-bcbd-9233ba8e9c27/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/889d7505-e14d-4782-bcbd-9233ba8e9c27/256x192.jpg",
        "viewOrder": 5
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3219,
      "latitude": 38.7256229,
      "longitude": -9.1570588,
      "parentGeographicAreaId": 0,
      "title": "R. Marquês de Subserra 10, 1070-012 Lisboa, Portugal",
      "updated": null,
      "created": "2022-07-22T17:02:00"
    },
    "personalities": [
      {
        "tagId": 40,
        "tagName": "Boutique Bolthole ",
        "tagType": 1
      },
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 172,
        "tagName": "Biking",
        "tagType": 2
      },
      {
        "tagId": 205,
        "tagName": "Design",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 216,
        "tagName": "Museum",
        "tagType": 2
      },
      {
        "tagId": 269,
        "tagName": "Photography",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 284,
        "tagName": "Architecture",
        "tagType": 2
      },
      {
        "tagId": 323,
        "tagName": "City Walk",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 776,
        "venueCategoryId": 35,
        "venueId": 3431,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3430,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Habitas Bacalar",
    "description": "Habitas Bacalar is our holistic oasis for recharging the body, mind and soul. Set between the tranquil lagoon and the Mayan jungle, we draw energy and inspiration from this unique natural power source, and give back to it in equal measure.\n\nAwake to a multihued sunrise over seven shades of blue or escape into a myriad of jungle palms. Our rooms provide comfort and tranquility as we reconnect with nature.",
    "brand": "",
    "blockQuote": "Book the E-foiling experience for an adventurous day of flying over water.",
    "emailAddress": "reservations@habitasbacalar.com",
    "website": "ourhabitas.com/bacalar/",
    "bookingURL": "https://sky-eu1.clock-software.com/86370/12365/wbe/en/products/new?_ga=2.100487838.1670009176.1658392392-866811074.1658392392",
    "redirectNotes": "Habitas Bacalar's reservations system",
    "phoneNumber": "+52 983 123 4141",
    "addressOne": "",
    "addressTwo": "",
    "city": "Bacalar",
    "county": "",
    "region": "",
    "country": "Mexico",
    "rooms": 35,
    "dollar": 2,
    "updated": "2022-07-22T16:18:20.493",
    "created": "2022-07-22T15:53:00.34",
    "medias": [
      {
        "mediaId": 54091,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/006db86a-1ec6-454e-b6f5-e7bcaf1a7147/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/006db86a-1ec6-454e-b6f5-e7bcaf1a7147/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/006db86a-1ec6-454e-b6f5-e7bcaf1a7147/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 54099,
        "title": "Habitas Bacalar - Bacalar, Mexico - Photos, Opinions, Booking",
        "url": "https://pkslist.azureedge.net/media/images/1/483dcb22-869f-4da5-a319-645d6bb8fc81/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/483dcb22-869f-4da5-a319-645d6bb8fc81/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/483dcb22-869f-4da5-a319-645d6bb8fc81/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 54101,
        "title": "Habitas Bacalar — Hôtel Weekend | Barefoot Luxury for the Modern Nomad",
        "url": "https://pkslist.azureedge.net/media/images/1/2247c985-fed1-4f97-aa0c-aeae92d5933d/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2247c985-fed1-4f97-aa0c-aeae92d5933d/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2247c985-fed1-4f97-aa0c-aeae92d5933d/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 54090,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/32c1e03f-0c78-47d0-a6f8-efa49d937021/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/32c1e03f-0c78-47d0-a6f8-efa49d937021/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/32c1e03f-0c78-47d0-a6f8-efa49d937021/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 54093,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/be2409ac-7fcb-4e47-942f-713988132fa4/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/be2409ac-7fcb-4e47-942f-713988132fa4/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/be2409ac-7fcb-4e47-942f-713988132fa4/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 54094,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d6d6ecd1-50aa-468a-812f-ce942ce3ffaf/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d6d6ecd1-50aa-468a-812f-ce942ce3ffaf/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d6d6ecd1-50aa-468a-812f-ce942ce3ffaf/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 54100,
        "title": "A review of Habitas Bacalar hotel in Bacalar, Mexico - Fathom",
        "url": "https://pkslist.azureedge.net/media/images/1/67b60002-93c9-4211-8cb4-b374cb9a2872/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/67b60002-93c9-4211-8cb4-b374cb9a2872/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/67b60002-93c9-4211-8cb4-b374cb9a2872/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 54103,
        "title": "Siete by Habitas Bacalar Restaurant - Bacalar, ROO | OpenTable",
        "url": "https://pkslist.azureedge.net/media/images/1/ca9cbbdd-c627-4b0c-9d98-6118b6be7b8a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ca9cbbdd-c627-4b0c-9d98-6118b6be7b8a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ca9cbbdd-c627-4b0c-9d98-6118b6be7b8a/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 54104,
        "title": "Holistic Destination Habitas Opens On Mexico's Lake Bacalar ...",
        "url": "https://pkslist.azureedge.net/media/images/1/e523306f-2d61-48b6-a7c4-91422b616e22/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e523306f-2d61-48b6-a7c4-91422b616e22/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e523306f-2d61-48b6-a7c4-91422b616e22/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3218,
      "latitude": 18.6164999,
      "longitude": -88.4324851,
      "parentGeographicAreaId": 0,
      "title": "Carretera Federal 307 Xul Ha, 77963 Bacalar, Q.R., Mexico",
      "updated": null,
      "created": "2022-07-22T15:57:00"
    },
    "personalities": [
      {
        "tagId": 50,
        "tagName": "Barefoot Luxury",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 133,
        "tagName": "Glamping",
        "tagType": 2
      },
      {
        "tagId": 166,
        "tagName": "Lakeside",
        "tagType": 2
      },
      {
        "tagId": 198,
        "tagName": "Boat Excursion",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 268,
        "tagName": "Massage",
        "tagType": 2
      },
      {
        "tagId": 291,
        "tagName": "Hollistic",
        "tagType": 2
      },
      {
        "tagId": 357,
        "tagName": "Sun Terrace",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 782,
        "venueCategoryId": 35,
        "venueId": 3430,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3424,
    "venueCategoryId": 0,
    "status": 1,
    "title": "The Madrid EDITION",
    "description": "The hotel showcases 200 beautifully appointed guest rooms and suites, some with terraces and five unique food and beverage outlets including a signature restaurant, cocktail bar, Pool Bar and rooftop terrace, together with an outdoor pool, state-of-the-art gym and spa. Flexible studios with over 350 sqm of dedicated space host creative meetings and events for large or small groups.\n\nSet in a tranquil square surrounded by historic buildings, The Madrid EDITION is near Puerta de Sol in the heart of the Spanish capital, one of the city’s most famous sites, and within walking distance to The Golden Triangle of Art – three of most important art museums in Madrid.",
    "brand": "EDITION",
    "blockQuote": "A rooftop oasis, Oroya is the place to go for fresh ceviches and Pisco-led cocktails.",
    "emailAddress": "reservations.mad@editionhotels.com",
    "website": "editionhotels.com/madrid/",
    "bookingURL": "https://www.marriott.com/reservation/rateListMenu.mi?scid=&defaultTab=standard&iata=96027890",
    "redirectNotes": "The Madrid EDITION's reservations system",
    "phoneNumber": "+34 919 54 54 20",
    "addressOne": "2",
    "addressTwo": "Plaza de Celenque",
    "city": "Madrid",
    "county": "",
    "region": "",
    "country": "Spain",
    "rooms": 200,
    "dollar": 2,
    "updated": "2022-07-26T09:23:35.177",
    "created": "2022-07-19T15:08:23.75",
    "medias": [
      {
        "mediaId": 54003,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/369d8850-14d5-41a8-8ac9-4be1cf864d81/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/369d8850-14d5-41a8-8ac9-4be1cf864d81/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/369d8850-14d5-41a8-8ac9-4be1cf864d81/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 54008,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/35ac7146-3014-4c80-933f-1522d7165487/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/35ac7146-3014-4c80-933f-1522d7165487/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/35ac7146-3014-4c80-933f-1522d7165487/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 54014,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/7e72c436-f405-49dd-9fd0-be9459d6abb1/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7e72c436-f405-49dd-9fd0-be9459d6abb1/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7e72c436-f405-49dd-9fd0-be9459d6abb1/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 54012,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/6a2e2098-7fda-4630-9a0e-1b1b800a8f2f/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6a2e2098-7fda-4630-9a0e-1b1b800a8f2f/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6a2e2098-7fda-4630-9a0e-1b1b800a8f2f/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 54005,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b99d8692-3e60-44d1-b94d-3a45a5f02ea8/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b99d8692-3e60-44d1-b94d-3a45a5f02ea8/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b99d8692-3e60-44d1-b94d-3a45a5f02ea8/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 54009,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b028dca8-d90f-458e-a516-f48510e5bf04/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b028dca8-d90f-458e-a516-f48510e5bf04/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b028dca8-d90f-458e-a516-f48510e5bf04/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 54007,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/126bb93d-8fe5-4df6-8afe-fb2e2851679a/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/126bb93d-8fe5-4df6-8afe-fb2e2851679a/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/126bb93d-8fe5-4df6-8afe-fb2e2851679a/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 54013,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/4c0bf2f5-7990-4b46-999c-f1febb6a196b/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4c0bf2f5-7990-4b46-999c-f1febb6a196b/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4c0bf2f5-7990-4b46-999c-f1febb6a196b/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 54015,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b5273145-537b-45b2-8c42-ec3823a10542/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b5273145-537b-45b2-8c42-ec3823a10542/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b5273145-537b-45b2-8c42-ec3823a10542/256x192.jpeg",
        "viewOrder": 9
      },
      {
        "mediaId": 54004,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/293154ce-8317-4cb3-8000-016def274200/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/293154ce-8317-4cb3-8000-016def274200/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/293154ce-8317-4cb3-8000-016def274200/256x192.jpeg",
        "viewOrder": 10
      },
      {
        "mediaId": 54006,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ba298b43-a081-4641-85a7-3916047d445a/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ba298b43-a081-4641-85a7-3916047d445a/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ba298b43-a081-4641-85a7-3916047d445a/256x192.jpeg",
        "viewOrder": 11
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3212,
      "latitude": 40.4175359,
      "longitude": -3.7060301,
      "parentGeographicAreaId": 0,
      "title": "Pl. de Celenque, 2, 28013 Madrid, Spain",
      "updated": null,
      "created": "2022-07-19T15:15:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 55,
        "tagName": "Lively",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 5,
        "tagName": "Billiards",
        "tagType": 2
      },
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 155,
        "tagName": "Art",
        "tagType": 2
      },
      {
        "tagId": 202,
        "tagName": "Private Terraces",
        "tagType": 2
      },
      {
        "tagId": 205,
        "tagName": "Design",
        "tagType": 2
      },
      {
        "tagId": 213,
        "tagName": "Roof Terrace",
        "tagType": 2
      },
      {
        "tagId": 216,
        "tagName": "Museum",
        "tagType": 2
      },
      {
        "tagId": 276,
        "tagName": "Gym",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 294,
        "tagName": "Event Space",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 777,
        "venueCategoryId": 35,
        "venueId": 3424,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3421,
    "venueCategoryId": 0,
    "status": 1,
    "title": "White Desert Echo",
    "description": "Echo is as close as you can get to feeling like you’re off the planet without leaving Earth.\n\nThe space-age design of the ‘Sky Pods’ makes them look like they’ve been beamed down from Mars. Futuristic and luxurious, the six bedrooms are created from composite fibreglass with floor to ceiling windows allowing guests to soak in the moon-like landscape beyond.\n\nOriginal photos taken from the International Space Station (ISS) by former Commander, White Desert guest and retired astronaut, colonel Terry Virts will be featured in each pod. Says Virts of the Echo landscape, “The mountains are the most beautiful I have seen across Earth, Venus and Mars.”\n\nPerfect for exclusive use groups and catering for up to 12 people, Echo allows guests to combine an ultra-luxury experience with a once in lifetime adventure on the 7th Continent. As with all White Desert’s camps, Echo is designed to be dismantled, leaving no trace on the Antarctic landscape.",
    "brand": "",
    "blockQuote": "You may never get to outer space but you can live like an astronaut.",
    "emailAddress": "info@white-desert.com",
    "website": "white-desert.com/echo/",
    "bookingURL": "https://white-desert.com/dates-and-rates/2022-2023/",
    "redirectNotes": "White Desert Echo's reservations system",
    "phoneNumber": "+1 815 534 4533",
    "addressOne": "",
    "addressTwo": "",
    "city": "Somewhere",
    "county": "",
    "region": "",
    "country": "Antarctica",
    "rooms": 6,
    "dollar": 3,
    "updated": "2022-07-19T11:01:09.663",
    "created": "2022-07-19T10:45:54.977",
    "medias": [
      {
        "mediaId": 53963,
        "title": "White Desert Echo Camp | Uncrate",
        "url": "https://pkslist.azureedge.net/media/images/1/0a295c1e-eaad-4b59-9b36-e2e0129386ee/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0a295c1e-eaad-4b59-9b36-e2e0129386ee/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0a295c1e-eaad-4b59-9b36-e2e0129386ee/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 53964,
        "title": "White Desert Antarctica launch 'Echo': a new camp inspired by ...",
        "url": "https://pkslist.azureedge.net/media/images/1/cc1bf930-fa0f-4ee1-bae6-a677f718228c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cc1bf930-fa0f-4ee1-bae6-a677f718228c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cc1bf930-fa0f-4ee1-bae6-a677f718228c/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 53965,
        "title": "White Desert's New Antarctica Camp : Echo | Luxsphere",
        "url": "https://pkslist.azureedge.net/media/images/1/9683e493-63fc-4977-bcbc-cf12589514f6/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9683e493-63fc-4977-bcbc-cf12589514f6/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9683e493-63fc-4977-bcbc-cf12589514f6/256x192.png",
        "viewOrder": 2
      },
      {
        "mediaId": 53970,
        "title": "White Desert Echo Camp Antarctica | stupidDOPE.com",
        "url": "https://pkslist.azureedge.net/media/images/1/0d31d609-55e9-41c0-97b2-b5e70542077d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0d31d609-55e9-41c0-97b2-b5e70542077d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0d31d609-55e9-41c0-97b2-b5e70542077d/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 53967,
        "title": "Echo Camp from White Desert - UnnamedProject",
        "url": "https://pkslist.azureedge.net/media/images/1/7d1fc25b-21b8-4e7e-ac21-396c73dd0ca0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7d1fc25b-21b8-4e7e-ac21-396c73dd0ca0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7d1fc25b-21b8-4e7e-ac21-396c73dd0ca0/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 53962,
        "title": "First Look at Echo, White Desert's New Space-Inspired Antarctic Camp",
        "url": "https://pkslist.azureedge.net/media/images/1/f0d9eaca-d606-47bc-96b9-5096ef689e4b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f0d9eaca-d606-47bc-96b9-5096ef689e4b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f0d9eaca-d606-47bc-96b9-5096ef689e4b/256x192.jpg",
        "viewOrder": 5
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3209,
      "latitude": -75.250973,
      "longitude": -0.071389,
      "parentGeographicAreaId": 0,
      "title": "Antarctica",
      "updated": null,
      "created": "2022-07-19T10:54:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 53,
        "tagName": "Cutting Edge",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 172,
        "tagName": "Biking",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 243,
        "tagName": "Outdoor Activities",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 296,
        "tagName": "Guided Walk",
        "tagType": 2
      },
      {
        "tagId": 327,
        "tagName": "Snowmobiles",
        "tagType": 2
      },
      {
        "tagId": 338,
        "tagName": "Cross Country Skiing",
        "tagType": 2
      },
      {
        "tagId": 365,
        "tagName": "Private Chef",
        "tagType": 2
      },
      {
        "tagId": 453,
        "tagName": "Abseiling",
        "tagType": 2
      },
      {
        "tagId": 454,
        "tagName": "Ice Climbing",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 757,
        "venueCategoryId": 34,
        "venueId": 3421,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 758,
        "venueCategoryId": 17,
        "venueId": 3421,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 759,
        "venueCategoryId": 13,
        "venueId": 3421,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 760,
        "venueCategoryId": 20,
        "venueId": 3421,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 20,
          "title": "BucketList Hotels",
          "region": "Global",
          "viewOrder": 0,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 778,
        "venueCategoryId": 35,
        "venueId": 3421,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3420,
    "venueCategoryId": 0,
    "status": 1,
    "title": "White Desert Wolf’s Fang",
    "description": "Offering laidback yet sophisticated interiors that evoke the bygone age of explorers, Wolf’s Fang features six heated bedroom tents that are equipped with en-suite washrooms, replete with shower rooms. The relaxed lounge area is a haven of comfort and warmth, no matter what the conditions outside. The inviting dining area is the ideal space to revisit the day’s exploration with fellow adventurers.\n\nAdrenalin seekers will enjoy a variety of exhilarating activities on this vast continent including abseiling, ice-climbing and rope-walks. Guests looking for peace and serenity will revel in gentle hikes, skidoo rides or simply taking in the dramatic vistas – guests can be as active or as relaxed as they wish during their stay.",
    "brand": "",
    "blockQuote": "You're going to love getting a drink at the world's most remote ice bar.",
    "emailAddress": "info@white-desert.com",
    "website": "white-desert.com/our-camps/wolfs-fang/",
    "bookingURL": "https://white-desert.com/dates-and-rates/2022-2023/",
    "redirectNotes": "White Desert Wolf’s Fang's reservations system",
    "phoneNumber": "+1 815 534 4533",
    "addressOne": "",
    "addressTwo": "",
    "city": "Queen Maud Land",
    "county": "",
    "region": "",
    "country": "Antarctica",
    "rooms": 6,
    "dollar": 3,
    "updated": "2022-07-19T11:05:15.973",
    "created": "2022-07-19T10:18:25.84",
    "medias": [
      {
        "mediaId": 53952,
        "title": "White Desert unveils new Antarctic camp | Aspire Travel Club",
        "url": "https://pkslist.azureedge.net/media/images/1/a5079944-3478-4380-bd78-c17d5f7b8c85/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a5079944-3478-4380-bd78-c17d5f7b8c85/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a5079944-3478-4380-bd78-c17d5f7b8c85/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 53945,
        "title": "How to Build a Hotel in Antarctica - Bloomberg",
        "url": "https://pkslist.azureedge.net/media/images/1/c5c81550-094c-4ad4-9562-dddf37abaed1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c5c81550-094c-4ad4-9562-dddf37abaed1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c5c81550-094c-4ad4-9562-dddf37abaed1/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 53960,
        "title": "How to Build a Hotel in Antarctica - Bloomberg",
        "url": "https://pkslist.azureedge.net/media/images/1/9a8f8fec-7d58-483c-b8ff-8adf30e4e8f0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9a8f8fec-7d58-483c-b8ff-8adf30e4e8f0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9a8f8fec-7d58-483c-b8ff-8adf30e4e8f0/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 53944,
        "title": "Tempus News Tags - Antarctica",
        "url": "https://pkslist.azureedge.net/media/images/1/a23ed882-caa3-45b8-b85f-177e34b96acf/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a23ed882-caa3-45b8-b85f-177e34b96acf/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a23ed882-caa3-45b8-b85f-177e34b96acf/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 53947,
        "title": "Experience Antarctica Without Getting Seasick - EliteVoyage",
        "url": "https://pkslist.azureedge.net/media/images/1/0986c4f6-7b32-4333-8935-6206dc634aa5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0986c4f6-7b32-4333-8935-6206dc634aa5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0986c4f6-7b32-4333-8935-6206dc634aa5/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 53943,
        "title": "Tempus News - White Desert launches Wolf's Fang, a new camp in ...",
        "url": "https://pkslist.azureedge.net/media/images/1/52f0eafd-cdec-41de-9949-d169ed75ecc7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/52f0eafd-cdec-41de-9949-d169ed75ecc7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/52f0eafd-cdec-41de-9949-d169ed75ecc7/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 53961,
        "title": "How to Build a Hotel in Antarctica - Bloomberg",
        "url": "https://pkslist.azureedge.net/media/images/1/59e72f7c-08a5-4db6-8b64-ecb7cfa97c22/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/59e72f7c-08a5-4db6-8b64-ecb7cfa97c22/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/59e72f7c-08a5-4db6-8b64-ecb7cfa97c22/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 53950,
        "title": "White Desert Tours Antarctica Opens Wolf's Fang Camp",
        "url": "https://pkslist.azureedge.net/media/images/1/e1859777-ac73-46dc-9955-00c647648f17/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e1859777-ac73-46dc-9955-00c647648f17/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e1859777-ac73-46dc-9955-00c647648f17/256x192.jpg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3208,
      "latitude": -86,
      "longitude": -160,
      "parentGeographicAreaId": 0,
      "title": "Queen Maud Mountains, Antarctica",
      "updated": null,
      "created": "2022-07-19T10:23:00"
    },
    "personalities": [
      {
        "tagId": 53,
        "tagName": "Cutting Edge",
        "tagType": 1
      },
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 133,
        "tagName": "Glamping",
        "tagType": 2
      },
      {
        "tagId": 172,
        "tagName": "Biking",
        "tagType": 2
      },
      {
        "tagId": 243,
        "tagName": "Outdoor Activities",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 296,
        "tagName": "Guided Walk",
        "tagType": 2
      },
      {
        "tagId": 317,
        "tagName": "Gourmet",
        "tagType": 2
      },
      {
        "tagId": 327,
        "tagName": "Snowmobiles",
        "tagType": 2
      },
      {
        "tagId": 453,
        "tagName": "Abseiling",
        "tagType": 2
      },
      {
        "tagId": 454,
        "tagName": "Ice Climbing",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 754,
        "venueCategoryId": 34,
        "venueId": 3420,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 755,
        "venueCategoryId": 17,
        "venueId": 3420,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 756,
        "venueCategoryId": 13,
        "venueId": 3420,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 779,
        "venueCategoryId": 35,
        "venueId": 3420,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3406,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Rosewood Vienna",
    "description": "Housed in an historic 19th century building, Rosewood Vienna is where the finest of European architecture and design meets with refined style, ultra-luxury amenities, and modern event spaces. Ideally situated on Petersplatz, one of the most famous squares in Vienna’s Old Town, Rosewood Vienna is located just steps away from the city’s most luxurious shopping and dining and its most famous historical sites.",
    "brand": "Rosewood",
    "blockQuote": "The hotel's location is excellent for exploring Vienna's rich history.",
    "emailAddress": "vienna.reservations@rosewoodhotels.com",
    "website": "rosewoodhotels.com/en/vienna",
    "bookingURL": "https://reservations.rosewoodhotels.com/?adult=1&agencyid=96027890&arrive=2022-08-04&chain=5159&child=0&config=gtm&currency=EUR&depart=2022-08-05&hotel=37577&level=hotel&locale=en-US&rooms=1",
    "redirectNotes": "Rosewood Vienna's reservations system",
    "phoneNumber": "+43 1 7999 888",
    "addressOne": "7",
    "addressTwo": "Petersplatz",
    "city": "Vienna",
    "county": "",
    "region": "",
    "country": "Austria",
    "rooms": 99,
    "dollar": 3,
    "updated": "2022-08-31T07:35:26.82",
    "created": "2022-06-14T07:26:12.167",
    "medias": [
      {
        "mediaId": 53662,
        "title": "Expansion: Rosewood comes to Vienna",
        "url": "https://pkslist.azureedge.net/media/images/1/3c56d78a-c5f4-4ea2-b4c4-ff15684c8605/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3c56d78a-c5f4-4ea2-b4c4-ff15684c8605/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3c56d78a-c5f4-4ea2-b4c4-ff15684c8605/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 54585,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/89b6633c-6ef0-4736-9001-077e5539db2c/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/89b6633c-6ef0-4736-9001-077e5539db2c/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/89b6633c-6ef0-4736-9001-077e5539db2c/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 54587,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/405791e4-8d02-4844-8f82-2e6427113e00/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/405791e4-8d02-4844-8f82-2e6427113e00/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/405791e4-8d02-4844-8f82-2e6427113e00/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 54586,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/48423271-fc60-4754-8171-c8ad7711fc62/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/48423271-fc60-4754-8171-c8ad7711fc62/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/48423271-fc60-4754-8171-c8ad7711fc62/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 53653,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/590b7666-1af3-46b9-85c4-229a2682b243/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/590b7666-1af3-46b9-85c4-229a2682b243/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/590b7666-1af3-46b9-85c4-229a2682b243/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 53651,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d5d31cda-9af8-4d82-a9d0-c9ea40292d0e/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d5d31cda-9af8-4d82-a9d0-c9ea40292d0e/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d5d31cda-9af8-4d82-a9d0-c9ea40292d0e/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 53652,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/de7c688a-6ec4-445e-9c1b-eae3f14e4a9d/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/de7c688a-6ec4-445e-9c1b-eae3f14e4a9d/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/de7c688a-6ec4-445e-9c1b-eae3f14e4a9d/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 54584,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/bf2e3dbf-d96b-487a-9db2-93b43dedb3a0/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bf2e3dbf-d96b-487a-9db2-93b43dedb3a0/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bf2e3dbf-d96b-487a-9db2-93b43dedb3a0/256x192.jpeg",
        "viewOrder": 9
      },
      {
        "mediaId": 53655,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b5042d95-17fb-4d75-848a-c8a885f70cbe/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b5042d95-17fb-4d75-848a-c8a885f70cbe/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b5042d95-17fb-4d75-848a-c8a885f70cbe/256x192.jpeg",
        "viewOrder": 10
      },
      {
        "mediaId": 53654,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f45e9e24-e8f4-4a27-89cd-e4ea0fa7aa12/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f45e9e24-e8f4-4a27-89cd-e4ea0fa7aa12/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f45e9e24-e8f4-4a27-89cd-e4ea0fa7aa12/256x192.jpeg",
        "viewOrder": 12
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3194,
      "latitude": 48.2096009,
      "longitude": 16.3696634,
      "parentGeographicAreaId": 0,
      "title": "Peterspl. 7, 1010 Wien, Austria",
      "updated": null,
      "created": "2022-06-14T07:34:00"
    },
    "personalities": [
      {
        "tagId": 48,
        "tagName": "Iconic/Landmark ",
        "tagType": 1
      },
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 155,
        "tagName": "Art",
        "tagType": 2
      },
      {
        "tagId": 183,
        "tagName": "Butler Service",
        "tagType": 2
      },
      {
        "tagId": 205,
        "tagName": "Design",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 216,
        "tagName": "Museum",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 294,
        "tagName": "Event Space",
        "tagType": 2
      },
      {
        "tagId": 323,
        "tagName": "City Walk",
        "tagType": 2
      },
      {
        "tagId": 404,
        "tagName": "Sightseeing",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 780,
        "venueCategoryId": 35,
        "venueId": 3406,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3385,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Aman New York",
    "description": "Situated in the heart of Manhattan at the crossroads of Fifth Avenue and 57th Street, overlooking Central Park, Aman New York occupies the iconic Crown Building and comprises of guest rooms and suites, and also houses the first urban Aman Residences. \n\nThe Crown Building - formerly the first home of Museum of Modern Art - has undergone a monumental transformation, which has seen it fully restored to its original splendour and imbued with the spirit of Aman. Designed by Jean-Michel Gathy, Aman New York celebrates Aman's philosophy of creating sanctuaries beyond commonplace notions of luxury, whilst paying homage to the building's striking architecture and significant history.\n\n Aman New York will consist of 83 hotel rooms, as well as restaurants, a Garden Terrace & Bar with 7000 square feet of outdoor dining space, private members club, three-floor Aman Spa, Jazz Club and Wine Room.",
    "brand": "Aman",
    "blockQuote": "The subterranean Jazz Club is a must for a fun night in NYC.",
    "emailAddress": "reservations@aman.com",
    "website": "aman.com/hotels/aman-new-york",
    "bookingURL": "https://reservations.aman.com/?adult=2&arrive=2022-10-11&chain=16840&child=0&currency=USD&depart=2022-10-12&hotel=35050&level=hotel&locale=en-US&rooms=1&sbe_widget=search-bar&iata=96027890",
    "redirectNotes": "Aman New York's reservations system",
    "phoneNumber": "+1 212 970 2626",
    "addressOne": "730",
    "addressTwo": "5th Avenue",
    "city": "New York",
    "county": "",
    "region": "New York",
    "country": "United States",
    "rooms": 83,
    "dollar": 3,
    "updated": "2022-10-11T09:56:51.753",
    "created": "2022-03-15T12:54:30.013",
    "medias": [
      {
        "mediaId": 52981,
        "title": "New Aman Hotel Coming to NYC Is Home to a Jaw-dropping Pool With ...",
        "url": "https://pkslist.azureedge.net/media/images/1/a7a2c499-f3cc-4f0d-846d-fd3cc15d9485/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a7a2c499-f3cc-4f0d-846d-fd3cc15d9485/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a7a2c499-f3cc-4f0d-846d-fd3cc15d9485/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 55088,
        "title": "Denniston Architects converts 1920s skyscraper into Aman New York ...",
        "url": "https://pkslist.azureedge.net/media/images/1/b55b4c6f-79f1-4878-bd8b-db07a71cfbd0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b55b4c6f-79f1-4878-bd8b-db07a71cfbd0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b55b4c6f-79f1-4878-bd8b-db07a71cfbd0/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 52991,
        "title": "New Aman Hotel Coming to NYC Is Home to a Jaw-dropping Pool With ...",
        "url": "https://pkslist.azureedge.net/media/images/1/3273ffcb-d3fe-41c2-9b12-99e675500551/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3273ffcb-d3fe-41c2-9b12-99e675500551/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3273ffcb-d3fe-41c2-9b12-99e675500551/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 55081,
        "title": "Aman New York hotel review | CN Traveller",
        "url": "https://pkslist.azureedge.net/media/images/1/d96f67e5-8a7a-4ace-957f-6ea52a24984b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d96f67e5-8a7a-4ace-957f-6ea52a24984b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d96f67e5-8a7a-4ace-957f-6ea52a24984b/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 55083,
        "title": "Aman New York: First In | Condé Nast Traveler",
        "url": "https://pkslist.azureedge.net/media/images/1/31214da3-6911-4f36-bf03-97e37d5e7164/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/31214da3-6911-4f36-bf03-97e37d5e7164/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/31214da3-6911-4f36-bf03-97e37d5e7164/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 55080,
        "title": "NYC's Most Expensive Hotel: Aman Owner Explores Sale for $600 ...",
        "url": "https://pkslist.azureedge.net/media/images/1/f8a6bcf9-6a83-4a52-acbb-64b797d890b2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f8a6bcf9-6a83-4a52-acbb-64b797d890b2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f8a6bcf9-6a83-4a52-acbb-64b797d890b2/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 55087,
        "title": "AMAN NEW YORK - Updated 2022 Prices & Hotel Reviews (New York City)",
        "url": "https://pkslist.azureedge.net/media/images/1/de105805-4449-419e-b9b1-865d87134577/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/de105805-4449-419e-b9b1-865d87134577/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/de105805-4449-419e-b9b1-865d87134577/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 55084,
        "title": "Aman New York is here and it is astonishing | Condé Nast Traveller ...",
        "url": "https://pkslist.azureedge.net/media/images/1/7efe44ab-6b6c-4e84-ad0f-8d3a75d762fe/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7efe44ab-6b6c-4e84-ad0f-8d3a75d762fe/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7efe44ab-6b6c-4e84-ad0f-8d3a75d762fe/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 55086,
        "title": "Go Inside 5 of New York City's Most Exciting Hotel Openings - Galerie",
        "url": "https://pkslist.azureedge.net/media/images/1/91aeef73-4767-41c8-9af9-dfa20a08d3aa/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/91aeef73-4767-41c8-9af9-dfa20a08d3aa/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/91aeef73-4767-41c8-9af9-dfa20a08d3aa/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 55085,
        "title": "Aman Just Opened One of the World's Most Anticipated Hotels in NYC ...",
        "url": "https://pkslist.azureedge.net/media/images/1/d21804d0-0ccd-48db-bb3c-ce58cc7a9ae2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d21804d0-0ccd-48db-bb3c-ce58cc7a9ae2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d21804d0-0ccd-48db-bb3c-ce58cc7a9ae2/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 55079,
        "title": "Inside Aman New York, Where a Stay Can Cost $15,000 a Night ...",
        "url": "https://pkslist.azureedge.net/media/images/1/960a2798-1258-427f-aaa5-769cdfcb7293/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/960a2798-1258-427f-aaa5-769cdfcb7293/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/960a2798-1258-427f-aaa5-769cdfcb7293/256x192.jpg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3173,
      "latitude": 40.7628238,
      "longitude": -73.9745971,
      "parentGeographicAreaId": 0,
      "title": "The Crown Building, 730 5th Ave, New York, NY 10019, USA",
      "updated": null,
      "created": "2022-03-15T13:11:00"
    },
    "personalities": [
      {
        "tagId": 48,
        "tagName": "Iconic/Landmark ",
        "tagType": 1
      },
      {
        "tagId": 51,
        "tagName": "Style Icon",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 62,
        "tagName": "Nightlife",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 202,
        "tagName": "Private Terraces",
        "tagType": 2
      },
      {
        "tagId": 216,
        "tagName": "Museum",
        "tagType": 2
      },
      {
        "tagId": 244,
        "tagName": "Members' Club",
        "tagType": 2
      },
      {
        "tagId": 253,
        "tagName": "Jazz Bar",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 301,
        "tagName": "Wine Bar",
        "tagType": 2
      },
      {
        "tagId": 323,
        "tagName": "City Walk",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 763,
        "venueCategoryId": 35,
        "venueId": 3385,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 800,
        "venueCategoryId": 20,
        "venueId": 3385,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 20,
          "title": "BucketList Hotels",
          "region": "Global",
          "viewOrder": 0,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3381,
    "venueCategoryId": 0,
    "status": 1,
    "title": "The Maybourne Riviera",
    "description": "The striking modernist architecture of The Maybourne Riviera emerges from the rocky peninsula of Roquebrune-Cap-Martin as if forging its way into the glistening Mediterranean Sea. Dramatic views of the coastline take in Italy to the east, Monte Carlo to the west, all bathed in brilliant light. The setting is unrivalled, the never-ending sea and sky viewed from every room, and as you step out into the warm, saltwater breeze, all it takes is a moment to softly slip into Riviera time.",
    "brand": "",
    "blockQuote": "Michelin-starred chef Mauro Colagreco serves the freshest seafood with stunning views at Riviera Playa.",
    "emailAddress": "reservations@maybourneriviera.com",
    "website": "maybourneriviera.com/",
    "bookingURL": "https://www.reservations-page.com/C00769/H13590/ov.aspx?lg=1&_gl=1*1mophec*_ga*MjAwMDAwNTUwNi4xNjQ3MTY0MDky*_ga_LLEH3XBNY9*MTY0NzI2NzI4MS4yLjEuMTY0NzI2NzU0NS4w&_ga=2.33094398.1975602811.1647267287-2000005506.1647164092#dates&iata=96027890",
    "redirectNotes": "The Maybourne Riviera's reservations system",
    "phoneNumber": "+33 4 93 37 22 40",
    "addressOne": "1551",
    "addressTwo": "Route de la Turbie",
    "city": "Roquebrune-Cap-Martin",
    "county": "",
    "region": "Provence Cote d'Azur",
    "country": "France",
    "rooms": 69,
    "dollar": 3,
    "updated": "2022-03-14T14:39:02.56",
    "created": "2022-03-14T14:16:40.123",
    "medias": [
      {
        "mediaId": 52887,
        "title": "THE MAYBOURNE RIVIERA - Hotel Reviews (Roquebrune-Cap-Martin ...",
        "url": "https://pkslist.azureedge.net/media/images/1/204d5819-22b5-4ea4-9064-4bf0115ba478/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/204d5819-22b5-4ea4-9064-4bf0115ba478/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/204d5819-22b5-4ea4-9064-4bf0115ba478/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 52889,
        "title": "The Maybourne Riviera: 5 Star Luxury Hotel & Resort in French Riviera",
        "url": "https://pkslist.azureedge.net/media/images/1/12e4b4ce-55f7-4ee3-9b3d-f9cebc3fa1a4/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/12e4b4ce-55f7-4ee3-9b3d-f9cebc3fa1a4/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/12e4b4ce-55f7-4ee3-9b3d-f9cebc3fa1a4/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 52907,
        "title": "Sun, scenery and French Riviera chic: Gaze at the Mediterranean ...",
        "url": "https://pkslist.azureedge.net/media/images/1/2ca04649-28e5-433d-b515-e6a515f14e23/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2ca04649-28e5-433d-b515-e6a515f14e23/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2ca04649-28e5-433d-b515-e6a515f14e23/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 52890,
        "title": "The Maybourne Riviera: Luxury Mediterranean Hotel",
        "url": "https://pkslist.azureedge.net/media/images/1/95c06620-fff8-44ff-931b-f011bc0f2eb6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/95c06620-fff8-44ff-931b-f011bc0f2eb6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/95c06620-fff8-44ff-931b-f011bc0f2eb6/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 52891,
        "title": "Riviera Restaurant: Mauro Colagreco - The Maybourne Riviera",
        "url": "https://pkslist.azureedge.net/media/images/1/2c296251-7dcf-4751-9181-3b520de69718/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2c296251-7dcf-4751-9181-3b520de69718/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2c296251-7dcf-4751-9181-3b520de69718/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 52914,
        "title": "Panoramic Suite: Sea View Suite - The Maybourne Riviera",
        "url": "https://pkslist.azureedge.net/media/images/1/f9c83a56-5dea-4f3c-8fbc-ca2312824e48/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f9c83a56-5dea-4f3c-8fbc-ca2312824e48/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f9c83a56-5dea-4f3c-8fbc-ca2312824e48/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 52913,
        "title": "Panoramic Suite: Sea View Suite - The Maybourne Riviera",
        "url": "https://pkslist.azureedge.net/media/images/1/7da616a7-a19c-4ed0-b239-e4a5b744518e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7da616a7-a19c-4ed0-b239-e4a5b744518e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7da616a7-a19c-4ed0-b239-e4a5b744518e/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 52910,
        "title": "The Maybourne Riviera: 5-Star Hotel on The French Riviera",
        "url": "https://pkslist.azureedge.net/media/images/1/699f4681-2b8b-4b7e-8972-7306607f6176/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/699f4681-2b8b-4b7e-8972-7306607f6176/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/699f4681-2b8b-4b7e-8972-7306607f6176/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 52912,
        "title": "Panoramic Suite: Sea View Suite - The Maybourne Riviera",
        "url": "https://pkslist.azureedge.net/media/images/1/02ac4490-9912-4d57-bb78-fcc3ab22d6e4/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/02ac4490-9912-4d57-bb78-fcc3ab22d6e4/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/02ac4490-9912-4d57-bb78-fcc3ab22d6e4/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 52893,
        "title": "Corniche Garden Room: Sea View Hotel Room - The Maybourne Riviera",
        "url": "https://pkslist.azureedge.net/media/images/1/9fd03d75-4ae0-4ad9-a4de-db6fc438fa92/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9fd03d75-4ae0-4ad9-a4de-db6fc438fa92/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9fd03d75-4ae0-4ad9-a4de-db6fc438fa92/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 52908,
        "title": "The Mediterranean's Most Dramatic New Pool - WSJ",
        "url": "https://pkslist.azureedge.net/media/images/1/6c67f014-9aa0-4b7a-9493-00ecd59ddb8d/1200x900.33333333",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6c67f014-9aa0-4b7a-9493-00ecd59ddb8d/800x600.33333333",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6c67f014-9aa0-4b7a-9493-00ecd59ddb8d/256x192.33333333",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3169,
      "latitude": 43.7567972,
      "longitude": 7.444317000000001,
      "parentGeographicAreaId": 0,
      "title": "1551 Rte de la Turbie, 06190 Roquebrune-Cap-Martin, France",
      "updated": null,
      "created": "2022-03-14T14:21:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 57,
        "tagName": "Romantic",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 155,
        "tagName": "Art",
        "tagType": 2
      },
      {
        "tagId": 198,
        "tagName": "Boat Excursion",
        "tagType": 2
      },
      {
        "tagId": 270,
        "tagName": "Beach Club",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 324,
        "tagName": "Local Village",
        "tagType": 2
      },
      {
        "tagId": 357,
        "tagName": "Sun Terrace",
        "tagType": 2
      },
      {
        "tagId": 404,
        "tagName": "Sightseeing",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 769,
        "venueCategoryId": 35,
        "venueId": 3381,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3358,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Sterrekopje Farm",
    "description": "A stay at Sterrekopje captures the senses, nurtures the soul, and invigorates the body. Scattered across Sterrekopje’s 50-hectare farm are 11 Sanctuaries, each a private retreat that’s rejuvenating yet calming, eclectic yet serene. Everything here has been carefully curated to foster deep restorative sleep and inspire gentle contemplation and creativity.\n \nYou’ll find beauty in every detail of our Sanctuaries, each of which is filled with a precious collection of one-of-a-kind art and furniture from some of our favourite destinations around the world. Four-poster beds from Kenya are topped with hypoallergenic mattresses and your choice of natural linens or Egyptian cotton. Hand-carved trunks have travelled the world from India, and elaborate murals have been masterfully painted by local artisans. Just like our guests, no two rooms are the same.",
    "brand": "",
    "blockQuote": "Borrow one of their traditional Dutch bicycles to explore Sterrekopje and the surrounding area.",
    "emailAddress": "welcome@sterrekopje.com",
    "website": "sterrekopje.com/",
    "bookingURL": "https://www.sterrekopje.com/stay",
    "redirectNotes": "Sterrekopje Farm's reservations system",
    "phoneNumber": "+27 21 007 0762",
    "addressOne": "",
    "addressTwo": "Excelsior Road",
    "city": "Franschhoek",
    "county": "",
    "region": "Cape Winelands",
    "country": "South Africa",
    "rooms": 11,
    "dollar": 1,
    "updated": "2022-06-14T07:18:01.457",
    "created": "2022-02-10T16:17:55.423",
    "medias": [
      {
        "mediaId": 53613,
        "title": "Sterrekopje Farm — Hotel Review | Condé Nast Traveler",
        "url": "https://pkslist.azureedge.net/media/images/1/ad9dbb87-e8ee-4136-a36a-e25a8f13944f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ad9dbb87-e8ee-4136-a36a-e25a8f13944f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ad9dbb87-e8ee-4136-a36a-e25a8f13944f/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 53643,
        "title": "Sterrekopje Farm — Hotel Review | Condé Nast Traveler",
        "url": "https://pkslist.azureedge.net/media/images/1/df34f371-4b5b-4ec1-9a90-3d3749eecb09/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/df34f371-4b5b-4ec1-9a90-3d3749eecb09/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/df34f371-4b5b-4ec1-9a90-3d3749eecb09/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 53640,
        "title": "STAY | Sterrekopje",
        "url": "https://pkslist.azureedge.net/media/images/1/bd451919-65df-4367-9aea-ef448c8f98fe/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bd451919-65df-4367-9aea-ef448c8f98fe/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bd451919-65df-4367-9aea-ef448c8f98fe/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 53618,
        "title": "Sterrekopje Farm — Hotel Review | Condé Nast Traveler",
        "url": "https://pkslist.azureedge.net/media/images/1/865f6418-978d-4707-ba04-377adc1a58b5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/865f6418-978d-4707-ba04-377adc1a58b5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/865f6418-978d-4707-ba04-377adc1a58b5/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 53624,
        "title": "Sterrekopje Farm — Hotel Review | Condé Nast Traveler",
        "url": "https://pkslist.azureedge.net/media/images/1/4ce3f3b2-7bb2-4ab5-905e-3148dbf63b8e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4ce3f3b2-7bb2-4ab5-905e-3148dbf63b8e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4ce3f3b2-7bb2-4ab5-905e-3148dbf63b8e/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 53630,
        "title": "Meet Sterrekopje, South Africa's Newest 5-Star Eco-Retreat – Robb ...",
        "url": "https://pkslist.azureedge.net/media/images/1/b0861daf-9094-4e67-b1e2-4a1802f8e169/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b0861daf-9094-4e67-b1e2-4a1802f8e169/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b0861daf-9094-4e67-b1e2-4a1802f8e169/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 53629,
        "title": "Sterrekopje Farm — Hotel Review | Condé Nast Traveler",
        "url": "https://pkslist.azureedge.net/media/images/1/1e40bcde-2feb-4f8a-b806-d31ea83b76d8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1e40bcde-2feb-4f8a-b806-d31ea83b76d8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1e40bcde-2feb-4f8a-b806-d31ea83b76d8/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 53621,
        "title": "Meet Sterrekopje, South Africa's Newest 5-Star Eco-Retreat – Robb ...",
        "url": "https://pkslist.azureedge.net/media/images/1/2657c932-51e1-4fd9-af54-2b2678254e89/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2657c932-51e1-4fd9-af54-2b2678254e89/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2657c932-51e1-4fd9-af54-2b2678254e89/256x192.jpg",
        "viewOrder": 7
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3145,
      "latitude": -33.9303032,
      "longitude": 19.1104875,
      "parentGeographicAreaId": 0,
      "title": "Excelsior Rd, Franschhoek, 7690, South Africa",
      "updated": null,
      "created": "2022-02-10T16:35:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 172,
        "tagName": "Biking",
        "tagType": 2
      },
      {
        "tagId": 181,
        "tagName": "Private/Suite Pools",
        "tagType": 2
      },
      {
        "tagId": 205,
        "tagName": "Design",
        "tagType": 2
      },
      {
        "tagId": 266,
        "tagName": "Nature Walk",
        "tagType": 2
      },
      {
        "tagId": 299,
        "tagName": "Wellness Programs",
        "tagType": 2
      },
      {
        "tagId": 320,
        "tagName": "Organic Garden",
        "tagType": 2
      },
      {
        "tagId": 351,
        "tagName": "Meditation",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 765,
        "venueCategoryId": 35,
        "venueId": 3358,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3340,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Bulgari Hotel Paris",
    "description": "Nested on Avenue George V, at the heart of the golden triangle, the Bvlgari Hotel Paris combines Bvlgari’s timeless glamour and heritage with a contemporary design and furniture by world-renowned Italian architects Antonio Citterio & Patricia Viel. Bvlgari Hotel Paris exclusive vibe sets the property apart with an understated yet uncompromising luxury residential feel offering ultimate comfort and coziness.\n\nAlongside the Bvlgari Penthouse of 1000m² with its own private roof garden, the latest Parisian gem comprises 76 luxurious residences infused with an instant Italian flair and breathtaking 360° views all over Paris. It is home to Il Ristorante – Niko Romito and an oversized Spa featuring a 25m pool.",
    "brand": "Bulgari",
    "blockQuote": "Aperitivo hour at the Bulgari Bar is a must.",
    "emailAddress": "paris@bulgarihotels.fr",
    "website": "bulgarihotels.com/paris",
    "bookingURL": "https://reservations.bulgarihotels.com/reservation/rateListMenu.mi",
    "redirectNotes": "Bulgari Hotel Paris' reservations system",
    "phoneNumber": "+33 1 81 72 10 00",
    "addressOne": "30",
    "addressTwo": "Avenue George V",
    "city": "Paris",
    "county": "",
    "region": "",
    "country": "France",
    "rooms": 76,
    "dollar": 3,
    "updated": "2023-04-17T06:16:26.513",
    "created": "2022-01-24T13:07:11.317",
    "medias": [
      {
        "mediaId": 51842,
        "title": "Bulgari Hotel Paris | Antonio Citterio Patricia Viel | Archello",
        "url": "https://pkslist.azureedge.net/media/images/1/bcfe453e-439c-48db-af15-cb25fa7ce089/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bcfe453e-439c-48db-af15-cb25fa7ce089/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bcfe453e-439c-48db-af15-cb25fa7ce089/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 51847,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/8c2f63c8-66a5-4b26-91c9-0e29cd5fdfe7/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8c2f63c8-66a5-4b26-91c9-0e29cd5fdfe7/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8c2f63c8-66a5-4b26-91c9-0e29cd5fdfe7/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 51834,
        "title": "Bulgari Paris hotel opens by the Champs-Elysées - Sortiraparis.com",
        "url": "https://pkslist.azureedge.net/media/images/1/e95ed05e-9304-462e-a925-3dec4bf59cbc/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e95ed05e-9304-462e-a925-3dec4bf59cbc/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e95ed05e-9304-462e-a925-3dec4bf59cbc/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 51827,
        "title": "Bulgari's New Hotel in Paris is a Luxury Lover's Dream—Take a Peek ...",
        "url": "https://pkslist.azureedge.net/media/images/1/708095ef-b29e-4949-9cee-4b4f45579c2d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/708095ef-b29e-4949-9cee-4b4f45579c2d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/708095ef-b29e-4949-9cee-4b4f45579c2d/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 51829,
        "title": "First Look Inside Bulgari Hotel Paris, An Escape In Paris",
        "url": "https://pkslist.azureedge.net/media/images/1/7c49cb33-46a3-4e69-9217-466aaa5e5903/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7c49cb33-46a3-4e69-9217-466aaa5e5903/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7c49cb33-46a3-4e69-9217-466aaa5e5903/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 51828,
        "title": "First Look Inside Bulgari Hotel Paris, An Escape In Paris",
        "url": "https://pkslist.azureedge.net/media/images/1/7df85ce5-fdf7-40e7-82cd-cc4978fedda6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7df85ce5-fdf7-40e7-82cd-cc4978fedda6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7df85ce5-fdf7-40e7-82cd-cc4978fedda6/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 51832,
        "title": "https://www.countryandtownhouse.co.uk/wp-content/u...",
        "url": "https://pkslist.azureedge.net/media/images/1/68de6952-d21d-439d-8242-6576a287fce3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/68de6952-d21d-439d-8242-6576a287fce3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/68de6952-d21d-439d-8242-6576a287fce3/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 51826,
        "title": "Bulgari Hotel Paris, an oasis of elegance and sophistication - LVMH",
        "url": "https://pkslist.azureedge.net/media/images/1/95a447c8-92ad-44bc-be84-da8956283a8e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/95a447c8-92ad-44bc-be84-da8956283a8e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/95a447c8-92ad-44bc-be84-da8956283a8e/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 51836,
        "title": "Bulgari Hotel Paris, an oasis of elegance and sophistication - LVMH",
        "url": "https://pkslist.azureedge.net/media/images/1/719ea4c9-91e7-454a-a6df-aed380568d1c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/719ea4c9-91e7-454a-a6df-aed380568d1c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/719ea4c9-91e7-454a-a6df-aed380568d1c/256x192.jpg",
        "viewOrder": 11
      },
      {
        "mediaId": 51839,
        "title": "Bulgari's New Hotel in Paris is a Luxury Lover's Dream—Take a Peek ...",
        "url": "https://pkslist.azureedge.net/media/images/1/6ac3ef05-7dad-4268-b902-b0768afa9d28/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6ac3ef05-7dad-4268-b902-b0768afa9d28/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6ac3ef05-7dad-4268-b902-b0768afa9d28/256x192.jpg",
        "viewOrder": 12
      },
      {
        "mediaId": 51846,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/268b23e0-3e81-42bf-bebc-77795bb3a289/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/268b23e0-3e81-42bf-bebc-77795bb3a289/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/268b23e0-3e81-42bf-bebc-77795bb3a289/256x192.jpeg",
        "viewOrder": 14
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3127,
      "latitude": 48.8683674,
      "longitude": 2.3014592,
      "parentGeographicAreaId": 0,
      "title": "30 Av. George V, 75008 Paris, France",
      "updated": null,
      "created": "2022-01-24T13:17:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 46,
        "tagName": "Formal Luxury ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 155,
        "tagName": "Art",
        "tagType": 2
      },
      {
        "tagId": 216,
        "tagName": "Museum",
        "tagType": 2
      },
      {
        "tagId": 222,
        "tagName": "Michelin Star dining",
        "tagType": 2
      },
      {
        "tagId": 276,
        "tagName": "Gym",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 332,
        "tagName": "Fashion",
        "tagType": 2
      },
      {
        "tagId": 404,
        "tagName": "Sightseeing",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 768,
        "venueCategoryId": 35,
        "venueId": 3340,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3320,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Explora El Chalten",
    "description": "With views to the Electric Valley and the Marconi Glacier, Explora in El Chaltén opened its doors on December 15, 2021. 17 km away from El Chaltén –in the Argentinean Patagonia– the lodge is a headquarter from where to explore a world-renowned destination by those who love nature and trekking. The Explora lodge in El Chaltén has been strategically built within the Los Huemules Conservation Reserve, our long-standing conservation partners in the region.\n\nPatagonia is not a country nor a place. It is a vast territory shared by Chile and Argentina, whose remarkable geography –shaped by mountains, glaciers, lakes, and rivers– offers countless exploration opportunities.",
    "brand": "",
    "blockQuote": "You'll enjoy the sleek yet cosy design immersed with impressive landscapes.",
    "emailAddress": "reserve@explora.com",
    "website": "explora.com/el-chalten-lodge/",
    "bookingURL": "https://reservations.explora.com/ibe/paso1?language=EN",
    "redirectNotes": "Explora El Chalten's reservations system",
    "phoneNumber": "+56 2 2395 2800",
    "addressOne": "",
    "addressTwo": "",
    "city": "El Chalten",
    "county": "",
    "region": "Patagonia",
    "country": "Argentina",
    "rooms": 20,
    "dollar": 3,
    "updated": "2022-07-19T09:46:19.08",
    "created": "2022-01-10T15:45:58.457",
    "medias": [
      {
        "mediaId": 53934,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a277183a-6ebe-4cfa-8924-2ff9fd17e73d/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a277183a-6ebe-4cfa-8924-2ff9fd17e73d/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a277183a-6ebe-4cfa-8924-2ff9fd17e73d/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 51484,
        "title": "Argentina's New Explora El Chaltén Has Some of the Best Views of ...",
        "url": "https://pkslist.azureedge.net/media/images/1/4f14a674-0243-4fc5-877d-b4b073f4fbe1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4f14a674-0243-4fc5-877d-b4b073f4fbe1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4f14a674-0243-4fc5-877d-b4b073f4fbe1/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 53930,
        "title": "Explora El Chalten, Argentina | Plan South America",
        "url": "https://pkslist.azureedge.net/media/images/1/132171ba-7a34-4b45-9925-7f1ffa95b5ea/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/132171ba-7a34-4b45-9925-7f1ffa95b5ea/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/132171ba-7a34-4b45-9925-7f1ffa95b5ea/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 53927,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/15171dd8-639e-4fc0-9406-29196ddcf90f/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/15171dd8-639e-4fc0-9406-29196ddcf90f/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/15171dd8-639e-4fc0-9406-29196ddcf90f/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 51487,
        "title": "Argentina's New Explora El Chaltén Has Some of the Best Views of ...",
        "url": "https://pkslist.azureedge.net/media/images/1/85fea868-8e17-4329-9f34-976a9a1f9571/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/85fea868-8e17-4329-9f34-976a9a1f9571/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/85fea868-8e17-4329-9f34-976a9a1f9571/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 51489,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a1d30e63-6475-4473-bca3-998f0b1caf7e/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a1d30e63-6475-4473-bca3-998f0b1caf7e/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a1d30e63-6475-4473-bca3-998f0b1caf7e/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 51490,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/3c2cb5bb-db1f-444d-9b26-809f0705388f/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3c2cb5bb-db1f-444d-9b26-809f0705388f/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3c2cb5bb-db1f-444d-9b26-809f0705388f/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 53935,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/317ae9c4-b987-48eb-b85c-f91db97d2ed7/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/317ae9c4-b987-48eb-b85c-f91db97d2ed7/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/317ae9c4-b987-48eb-b85c-f91db97d2ed7/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 51483,
        "title": "Welcome to the Family: Explora Lodge El Chaltén In Patagonia ...",
        "url": "https://pkslist.azureedge.net/media/images/1/47a377b2-fdba-4425-b954-5664f987b256/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/47a377b2-fdba-4425-b954-5664f987b256/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/47a377b2-fdba-4425-b954-5664f987b256/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3107,
      "latitude": -49.331769,
      "longitude": -72.885972,
      "parentGeographicAreaId": 0,
      "title": "Rita Provincial 41 Km.16 N, El Chalten, Santa Cruz, Argentina",
      "updated": null,
      "created": "2022-01-10T15:54:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 61,
        "tagName": "Mountain",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 164,
        "tagName": "Excursions",
        "tagType": 2
      },
      {
        "tagId": 171,
        "tagName": "Trekking",
        "tagType": 2
      },
      {
        "tagId": 172,
        "tagName": "Biking",
        "tagType": 2
      },
      {
        "tagId": 243,
        "tagName": "Outdoor Activities",
        "tagType": 2
      },
      {
        "tagId": 268,
        "tagName": "Massage",
        "tagType": 2
      },
      {
        "tagId": 296,
        "tagName": "Guided Walk",
        "tagType": 2
      },
      {
        "tagId": 317,
        "tagName": "Gourmet",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 772,
        "venueCategoryId": 35,
        "venueId": 3320,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3147,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Patina Maldives Fari Islands",
    "description": "Patina Maldives is part of the Fari Islands with two neighbouring islands that share distinctly different energies to form a destination of deep cultural character. Here, socially and environmentally conscious travellers connect through a depth of beauty, creativity and discovery.\n\nThe position and views of our 90 villas and 20 studios are yours to choose. Studios sit on the edge of the vibrant Fari Marina Village while villas face the perfect sunrises or sunsets. All exude warm, rustic simplicity. And all celebrate the energy of human nature.",
    "brand": "",
    "blockQuote": "The hardest decision is where to eat since there are 12 dining concepts to choose from!",
    "emailAddress": "reservations.maldives@patinahotels.com",
    "website": "patinahotels.com/maldives-fari-islands/",
    "bookingURL": "https://be.synxis.com/?__utma=234240927.197196122.1621005036.1621005036.1621005036.1&__utmb=234240927.3.10.1621005036&__utmc=234240927&__utmk=133677261&__utmv=-&__utmx=-&__utmz=234240927.1621005036.1.1.utmcsr=google|utmccn=(organic)|utmcmd=organic|utmctr=(not%20provided)&_ga=2.127085070.264926635.1621005037-197196122.1621005036&adult=1&agencyid=96027890&arrive=2021-05-28&chain=5154&child=0&config=SBE_PMFI_V1&currency=USD&depart=2021-05-29&hotel=31794&level=hotel&locale=en-US&rooms=1&shell=RBE&start=searchres&theme=SBE_PMFI_V1",
    "redirectNotes": "Patina Maldives Fari Islands' reservations system",
    "phoneNumber": "+960 4000 555",
    "addressOne": "",
    "addressTwo": "",
    "city": "North Male Atoll",
    "county": "",
    "region": "",
    "country": "Maldives",
    "rooms": 110,
    "dollar": 3,
    "updated": "2021-06-16T07:59:53.87",
    "created": "2021-05-14T15:16:53.93",
    "medias": [
      {
        "mediaId": 47908,
        "title": "PATINA MALDIVES, FARI ISLANDS - Updated 2021 Prices, Hotel Reviews ...",
        "url": "https://pkslist.azureedge.net/media/images/1/7d92c9f2-bdcf-4437-a341-a79f4e430399/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7d92c9f2-bdcf-4437-a341-a79f4e430399/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7d92c9f2-bdcf-4437-a341-a79f4e430399/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 47562,
        "title": "Purposeful travel: Patina Maldives, Fari Islands is seeding next ...",
        "url": "https://pkslist.azureedge.net/media/images/1/47e78ff1-3f26-4bfe-b32f-27b3c111ab18/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/47e78ff1-3f26-4bfe-b32f-27b3c111ab18/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/47e78ff1-3f26-4bfe-b32f-27b3c111ab18/256x192.png",
        "viewOrder": 1
      },
      {
        "mediaId": 47906,
        "title": "J.MAK | Patina Maldives, Fari Islands",
        "url": "https://pkslist.azureedge.net/media/images/1/bc706f5e-7b83-4e33-8a03-2c344e3dc5ef/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bc706f5e-7b83-4e33-8a03-2c344e3dc5ef/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bc706f5e-7b83-4e33-8a03-2c344e3dc5ef/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 47580,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/4b0bf011-9c80-4a6f-a735-4dcf3c490dba/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4b0bf011-9c80-4a6f-a735-4dcf3c490dba/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4b0bf011-9c80-4a6f-a735-4dcf3c490dba/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 47578,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/af2cebb0-2bbb-4c0a-a591-964e5236b09e/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/af2cebb0-2bbb-4c0a-a591-964e5236b09e/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/af2cebb0-2bbb-4c0a-a591-964e5236b09e/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 47588,
        "title": "Patina Maldives: the NEXT-GEN SUSTAINABILITY — Meet The Leader",
        "url": "https://pkslist.azureedge.net/media/images/1/9a1aa60b-290d-4cfa-b7a6-c75736c8fec9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9a1aa60b-290d-4cfa-b7a6-c75736c8fec9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9a1aa60b-290d-4cfa-b7a6-c75736c8fec9/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 47589,
        "title": "Best Resort in Maldives | Patina Maldives, Fari Islands",
        "url": "https://pkslist.azureedge.net/media/images/1/bcfabb85-1948-43c9-a823-7f1beae23b6b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bcfabb85-1948-43c9-a823-7f1beae23b6b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bcfabb85-1948-43c9-a823-7f1beae23b6b/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 47577,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/193cfc9c-589e-4337-a396-5cec1080c26e/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/193cfc9c-589e-4337-a396-5cec1080c26e/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/193cfc9c-589e-4337-a396-5cec1080c26e/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 47581,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ecee079c-0377-49e2-83cc-018a37ef5e74/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ecee079c-0377-49e2-83cc-018a37ef5e74/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ecee079c-0377-49e2-83cc-018a37ef5e74/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 47582,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a25e673d-c7da-4426-bbb1-db87f28f0f66/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a25e673d-c7da-4426-bbb1-db87f28f0f66/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a25e673d-c7da-4426-bbb1-db87f28f0f66/256x192.jpeg",
        "viewOrder": 9
      },
      {
        "mediaId": 47586,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/7b8fb64c-3c9f-48b0-8221-6e034f6bb860/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7b8fb64c-3c9f-48b0-8221-6e034f6bb860/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7b8fb64c-3c9f-48b0-8221-6e034f6bb860/256x192.jpeg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2934,
      "latitude": 4.622835200000001,
      "longitude": 73.5689092,
      "parentGeographicAreaId": 0,
      "title": "Maldives",
      "updated": null,
      "created": "2021-05-14T15:20:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 157,
        "tagName": "Beach Escape",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 198,
        "tagName": "Boat Excursion",
        "tagType": 2
      },
      {
        "tagId": 199,
        "tagName": "Art Gallery",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 299,
        "tagName": "Wellness Programs",
        "tagType": 2
      },
      {
        "tagId": 410,
        "tagName": "Sundownders",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 727,
        "venueCategoryId": 15,
        "venueId": 3147,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 15,
          "title": "Beach Paradise",
          "region": "Global",
          "viewOrder": 6,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 771,
        "venueCategoryId": 35,
        "venueId": 3147,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 832,
        "venueCategoryId": 26,
        "venueId": 3147,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 26,
          "title": "Maldives Top 10",
          "region": "Indian Ocean",
          "viewOrder": 7,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3045,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Sussurro",
    "description": "Seated on the secluded shores of a warm turquoise lagoon in Southern Mozambique, Sussurro is a thoughtfully designed space for you to align with nature and immerse yourself in a more meaningful travel experience.\n\nPrivate bungalows provide over a thousand square feet of personal space surrounded by nature. Each suite includes indoor and outdoor bathing facilities and generous water front verandahs. \n\nMozambican vernacular architecture weaves a natural canopy above billowing mosquito nets. Large apertures allow the colours of an ever changing landscape to permeate the space.\n\nRooms are dressed with furniture created and carved onsite and thoughtfully collected artisanal pieces from sub-saharan African.",
    "brand": "",
    "blockQuote": "Book a boat excursion on one of Sussurro’s traditional Mozambican dhows and drift by local fishing villages and through floating forests.",
    "emailAddress": "sarah@sussurro.co",
    "website": "sussurro.co/",
    "bookingURL": "https://hotels.cloudbeds.com/reservation/fiLdhQ#checkin=2021-02-01&checkout=2021-02-03&iata=96027890",
    "redirectNotes": "Sussurro's reservations system",
    "phoneNumber": "+258 84 677 2703",
    "addressOne": "",
    "addressTwo": "",
    "city": "Nhamabue",
    "county": "",
    "region": "",
    "country": "Mozambique",
    "rooms": 6,
    "dollar": 2,
    "updated": "2023-01-23T12:17:03.32",
    "created": "2021-01-07T15:53:23.303",
    "medias": [
      {
        "mediaId": 57039,
        "title": "The Best New Hotels in the World: 2022 It List",
        "url": "https://pkslist.azureedge.net/media/images/1/a677f0c4-7716-4568-b19d-cb7a2f1cce91/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a677f0c4-7716-4568-b19d-cb7a2f1cce91/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a677f0c4-7716-4568-b19d-cb7a2f1cce91/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 57034,
        "title": "Sussurro Review - Sussurro | A Hotel Life",
        "url": "https://pkslist.azureedge.net/media/images/1/aea98f54-5c45-4c09-b28b-1422e7940a3d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/aea98f54-5c45-4c09-b28b-1422e7940a3d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/aea98f54-5c45-4c09-b28b-1422e7940a3d/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 57036,
        "title": "Just in Time for Earth Day: 7 New Sustainable (and Stylish) Hotels ...",
        "url": "https://pkslist.azureedge.net/media/images/1/4905d9c9-a4f0-4c5f-a891-c9625b14f76f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4905d9c9-a4f0-4c5f-a891-c9625b14f76f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4905d9c9-a4f0-4c5f-a891-c9625b14f76f/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 57035,
        "title": "Sussurro — Hotel Review | Condé Nast Traveler",
        "url": "https://pkslist.azureedge.net/media/images/1/f9b407e0-9739-44f0-a6a3-33daefcd654e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f9b407e0-9739-44f0-a6a3-33daefcd654e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f9b407e0-9739-44f0-a6a3-33daefcd654e/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 44112,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/8126c8dd-df67-42bf-9c54-bd1a7a05fafe/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8126c8dd-df67-42bf-9c54-bd1a7a05fafe/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8126c8dd-df67-42bf-9c54-bd1a7a05fafe/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 44122,
        "title": "Dreaming of Mozambique | Meaningful travel, National parks, Travel ...",
        "url": "https://pkslist.azureedge.net/media/images/1/5909ca7c-477d-4ffc-9985-9868aea379d1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5909ca7c-477d-4ffc-9985-9868aea379d1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5909ca7c-477d-4ffc-9985-9868aea379d1/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 44110,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/2e6af210-9a7d-4200-a4e5-e7939661a463/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2e6af210-9a7d-4200-a4e5-e7939661a463/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2e6af210-9a7d-4200-a4e5-e7939661a463/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 44121,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/9a349c68-82c7-4e3f-9ebb-cbd52e74b76f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9a349c68-82c7-4e3f-9ebb-cbd52e74b76f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9a349c68-82c7-4e3f-9ebb-cbd52e74b76f/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 44120,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ff72ad22-140d-49fa-b6be-fd85ebcf326b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ff72ad22-140d-49fa-b6be-fd85ebcf326b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ff72ad22-140d-49fa-b6be-fd85ebcf326b/256x192.jpg",
        "viewOrder": 11
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2831,
      "latitude": -21.272443,
      "longitude": 35.1101073,
      "parentGeographicAreaId": 0,
      "title": "Govuro, Mozambique",
      "updated": null,
      "created": "2021-01-07T16:12:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 58,
        "tagName": "Beach",
        "tagType": 2
      },
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 109,
        "tagName": "Fishing",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 198,
        "tagName": "Boat Excursion",
        "tagType": 2
      },
      {
        "tagId": 252,
        "tagName": "National Park",
        "tagType": 2
      },
      {
        "tagId": 278,
        "tagName": "Conservation",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 766,
        "venueCategoryId": 35,
        "venueId": 3045,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 827,
        "venueCategoryId": 15,
        "venueId": 3045,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 15,
          "title": "Beach Paradise",
          "region": "Global",
          "viewOrder": 6,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3003,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Cheval Blanc Paris",
    "description": "Under the Pont-Neuf flows the Seine. In the heart of the City of Light, a few steps away from the Louvre and the Marais, in front of the Left Bank, rises Cheval Blanc Paris with majesty.\n\nWith only 72 rooms and suites, Cheval Blanc Paris is a confidential haven where lovers, families and friends meet. Seize the day overlooking the scenic panorama, bite in a warm croissant just out of the oven, sink into the foam of a delicately scented bath, capture the soul of Paris from your winter garden.\n\nA bustling place to live, an unmissable rendez-vous for Parisians, a haven of serenity where travellers and their families feel at home, Cheval Blanc holds the key to the heart of Paris.",
    "brand": "Cheval Blanc",
    "blockQuote": "The Dior Spa Cheval Blanc with its spectacular 30-metre swimming pool is a must visit.",
    "emailAddress": "info.paris@chevalblanc.com",
    "website": "chevalblanc.com/en/maison/paris/",
    "bookingURL": "https://reservation.chevalblanc.com/?_ga=2.36560608.1930030372.1662456799-589211743.1662456799&adult=2&agencyid=96027890&arrive=2022-09-06&chain=23061&child=0&currency=EUR&depart=2022-09-07&hotel=9732&level=hotel&locale=en-US&rooms=1",
    "redirectNotes": "Cheval Blanc Paris' reservations system",
    "phoneNumber": "+33 1 40 28 00 00",
    "addressOne": "8",
    "addressTwo": "Quai du Louvre",
    "city": "Paris",
    "county": "",
    "region": "",
    "country": "France",
    "rooms": 72,
    "dollar": 3,
    "updated": "2023-12-23T09:25:31.63",
    "created": "2020-11-23T17:16:15.673",
    "medias": [
      {
        "mediaId": 54771,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/3e67930e-a1f3-49d1-85d9-baccb1334265/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3e67930e-a1f3-49d1-85d9-baccb1334265/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3e67930e-a1f3-49d1-85d9-baccb1334265/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 57679,
        "title": "Cheval Blanc Paris Hotel Is LVMH's Newest Bauble at $1,500 a Night ...",
        "url": "https://pkslist.azureedge.net/media/images/1/b863af2a-fddf-45f5-9936-f0c7a5eac386/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b863af2a-fddf-45f5-9936-f0c7a5eac386/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b863af2a-fddf-45f5-9936-f0c7a5eac386/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 54780,
        "title": "CHEVAL BLANC PARIS - Updated 2022 Prices & Hotel Reviews (France)",
        "url": "https://pkslist.azureedge.net/media/images/1/790ea709-5448-49ce-994e-7bbc9f9cbc35/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/790ea709-5448-49ce-994e-7bbc9f9cbc35/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/790ea709-5448-49ce-994e-7bbc9f9cbc35/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 54782,
        "title": "Review: Cheval Blanc Paris",
        "url": "https://pkslist.azureedge.net/media/images/1/13dacb49-8db3-4f2a-aed2-7733c0a1d5a6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/13dacb49-8db3-4f2a-aed2-7733c0a1d5a6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/13dacb49-8db3-4f2a-aed2-7733c0a1d5a6/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 54775,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a447ffe9-9d2b-4212-8a34-142327d2eaf3/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a447ffe9-9d2b-4212-8a34-142327d2eaf3/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a447ffe9-9d2b-4212-8a34-142327d2eaf3/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 54773,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/4ec67976-2f13-4ee5-9558-8fcf9efb6e0b/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4ec67976-2f13-4ee5-9558-8fcf9efb6e0b/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4ec67976-2f13-4ee5-9558-8fcf9efb6e0b/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 57677,
        "title": "Review: Cheval Blanc Paris - One Mile at a Time",
        "url": "https://pkslist.azureedge.net/media/images/1/f8022664-8ea2-4bfc-8718-2a326718c4ca/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f8022664-8ea2-4bfc-8718-2a326718c4ca/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f8022664-8ea2-4bfc-8718-2a326718c4ca/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 54777,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/9cc7e1a6-3306-4919-8668-ef8910a95784/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9cc7e1a6-3306-4919-8668-ef8910a95784/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9cc7e1a6-3306-4919-8668-ef8910a95784/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 54776,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c406055b-7010-4453-b07d-3ede33c6f4ee/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c406055b-7010-4453-b07d-3ede33c6f4ee/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c406055b-7010-4453-b07d-3ede33c6f4ee/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 54781,
        "title": "Le Cheval Blanc Paris, La Samaritaine's luxury hotel opens ...",
        "url": "https://pkslist.azureedge.net/media/images/1/2a1c9769-abeb-498e-83fa-9b4b21d9e0fe/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2a1c9769-abeb-498e-83fa-9b4b21d9e0fe/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2a1c9769-abeb-498e-83fa-9b4b21d9e0fe/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 54783,
        "title": "Le Cheval Blanc Paris, La Samaritaine's luxury hotel opens ...",
        "url": "https://pkslist.azureedge.net/media/images/1/13688df1-33f6-45ae-8c9d-ef6791e1b9c6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/13688df1-33f6-45ae-8c9d-ef6791e1b9c6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/13688df1-33f6-45ae-8c9d-ef6791e1b9c6/256x192.jpg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2789,
      "latitude": 48.8586507,
      "longitude": 2.341837200000001,
      "parentGeographicAreaId": 0,
      "title": "8 Quai du Louvre, 75001 Paris, France",
      "updated": null,
      "created": "2020-11-23T17:24:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 51,
        "tagName": "Style Icon",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 155,
        "tagName": "Art",
        "tagType": 2
      },
      {
        "tagId": 216,
        "tagName": "Museum",
        "tagType": 2
      },
      {
        "tagId": 222,
        "tagName": "Michelin Star dining",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 332,
        "tagName": "Fashion",
        "tagType": 2
      },
      {
        "tagId": 404,
        "tagName": "Sightseeing",
        "tagType": 2
      },
      {
        "tagId": 466,
        "tagName": "Winter Garden",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 767,
        "venueCategoryId": 35,
        "venueId": 3003,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 828,
        "venueCategoryId": 22,
        "venueId": 3003,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 22,
          "title": "Paris Top 10 ",
          "region": "Europe",
          "viewOrder": 9,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2784,
    "venueCategoryId": 0,
    "status": 1,
    "title": "One&Only Mandarina",
    "description": "Be awed by Riviera Nayarit’s abundant landscape, alive with a palpable energy that ignites the senses. Step straight from tropical rainforest to white-sand beaches and swimmable seas. Play, relax, connect and adventure beneath intensely blue skies, which melt into pastel colours with the waning sun. Then unwind with ancient spa rituals and inspired dining from world-renowned chefs.",
    "brand": "One&Only",
    "blockQuote": "Hike up to the viewpoint past the 'La Abuela' tree for the most spectacular sunrises.",
    "emailAddress": "reservations@oneandonlymandarina.com",
    "website": "oneandonlyresorts.com/mandarina",
    "bookingURL": "https://www.oneandonlyresorts.com/mandarina/reservations/best-available-rate/search-results?w=1&agencyid=96027890",
    "redirectNotes": "One&Only Mandarina's reservations system",
    "phoneNumber": "+1 855 271 9494",
    "addressOne": "",
    "addressTwo": "",
    "city": "Lo de Marcos",
    "county": "",
    "region": "",
    "country": "Mexico",
    "rooms": 105,
    "dollar": 3,
    "updated": "2023-12-08T15:06:26.073",
    "created": "2020-07-31T06:50:32.173",
    "medias": [
      {
        "mediaId": 61890,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/1c72e31e-12b8-4e7e-b0b1-6af9951b7d15/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1c72e31e-12b8-4e7e-b0b1-6af9951b7d15/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1c72e31e-12b8-4e7e-b0b1-6af9951b7d15/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 61889,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/72f38a08-74a8-419e-8610-d63c3654bf4b/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/72f38a08-74a8-419e-8610-d63c3654bf4b/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/72f38a08-74a8-419e-8610-d63c3654bf4b/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 61892,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/481c1965-6405-4fb8-a9ed-246a1adc4df5/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/481c1965-6405-4fb8-a9ed-246a1adc4df5/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/481c1965-6405-4fb8-a9ed-246a1adc4df5/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 61891,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/60d4e452-42f3-4649-b504-167f92dbcc02/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/60d4e452-42f3-4649-b504-167f92dbcc02/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/60d4e452-42f3-4649-b504-167f92dbcc02/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 61897,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/cbea4b67-7772-4d2f-84c2-1bc9d8fe87e6/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cbea4b67-7772-4d2f-84c2-1bc9d8fe87e6/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cbea4b67-7772-4d2f-84c2-1bc9d8fe87e6/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 61887,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/48406834-425c-42d7-97e9-699c0b9d5ac3/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/48406834-425c-42d7-97e9-699c0b9d5ac3/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/48406834-425c-42d7-97e9-699c0b9d5ac3/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 61894,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b511177c-83fd-445c-ad23-178537e71e20/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b511177c-83fd-445c-ad23-178537e71e20/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b511177c-83fd-445c-ad23-178537e71e20/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 61907,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/7efc6e85-05b3-4b69-83bc-658b19a5659b/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7efc6e85-05b3-4b69-83bc-658b19a5659b/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7efc6e85-05b3-4b69-83bc-658b19a5659b/256x192.jpeg",
        "viewOrder": 10
      },
      {
        "mediaId": 61903,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/11b1b4fa-4989-4ede-8dff-f7060afbd237/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/11b1b4fa-4989-4ede-8dff-f7060afbd237/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/11b1b4fa-4989-4ede-8dff-f7060afbd237/256x192.jpeg",
        "viewOrder": 12
      },
      {
        "mediaId": 61893,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/67667bb4-0891-46cc-a3d7-cd9553508517/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/67667bb4-0891-46cc-a3d7-cd9553508517/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/67667bb4-0891-46cc-a3d7-cd9553508517/256x192.jpeg",
        "viewOrder": 14
      },
      {
        "mediaId": 61901,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e4b2ab53-edcc-43ca-978d-9334ef824a5a/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e4b2ab53-edcc-43ca-978d-9334ef824a5a/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e4b2ab53-edcc-43ca-978d-9334ef824a5a/256x192.jpeg",
        "viewOrder": 18
      },
      {
        "mediaId": 61902,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/44d911f4-f556-4f42-bea3-810b8690861b/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/44d911f4-f556-4f42-bea3-810b8690861b/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/44d911f4-f556-4f42-bea3-810b8690861b/256x192.jpeg",
        "viewOrder": 19
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2570,
      "latitude": 20.9779281,
      "longitude": -105.3290328,
      "parentGeographicAreaId": 0,
      "title": "Carretera Federal Libre, 200 Tepic-Puerto Vallarta, Municipio de Compostela El Monteón, 63779 Lo de Marcos, Nay., Mexico",
      "updated": null,
      "created": "2020-07-31T06:59:00"
    },
    "personalities": [
      {
        "tagId": 57,
        "tagName": "Romantic",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 82,
        "tagName": "Horseback Riding",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 115,
        "tagName": "Snorkeling",
        "tagType": 2
      },
      {
        "tagId": 172,
        "tagName": "Biking",
        "tagType": 2
      },
      {
        "tagId": 183,
        "tagName": "Butler Service",
        "tagType": 2
      },
      {
        "tagId": 206,
        "tagName": "Private Beach",
        "tagType": 2
      },
      {
        "tagId": 224,
        "tagName": "Villas",
        "tagType": 2
      },
      {
        "tagId": 235,
        "tagName": "Hot Tub",
        "tagType": 2
      },
      {
        "tagId": 250,
        "tagName": "Ziplining",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 845,
        "venueCategoryId": 35,
        "venueId": 2784,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  }
]
// 20240123100509
// https://app.pks-list.com/api/Venue/v1/getlist/35

export const placesStore21=[
  {
    "venueId": 1902,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Park Hyatt New York",
    "description": "Located in the heart of Midtown Manhattan, this New York luxury hotel is situated directly across the street from Carnegie Hall and one block from Central Park. Designed by world-renowned, Pritzker Prize-winning architect Christian de Portzamparc, Park Hyatt New York features 210 generously sized guest rooms, including 92 luxury suites, designed by Yabu Pushelberg. The stunning and state-of-the-art rooms are among the largest in New York City averaging 530 square feet and feature breathtaking views of the New York skyline and partial views of Central Park. Throughout the hotel, guests experience urbane spaces offering sophistication and elegance. Indulge in oversized accommodations that combine luxury furnishings with high-tech amenities. Discover intimate experiences tailored to your individual preferences, from delectable cuisine at The Back Room to soothing body treatment at Spa Nalai. Park Hyatt New York takes pleasure and pride in customizing each stay for traveling professionals and vacationing tourists, including being a pet friendly hotel.",
    "brand": "Hyatt",
    "blockQuote": "Go for a refreshing swim on the 25th floor with a curated Carnegie Hall soundtrack playing on underwater speakers.",
    "emailAddress": "nycph.guestrelations@hyatt.com",
    "website": "hyatt.com/en-US/hotel/new-york/park-hyatt-new-york/nycph",
    "bookingURL": "https://www.hyatt.com/shop/nycph?rooms=1&adults=1&location=Park%20Hyatt%20New%20York&checkinDate=2018-08-30&checkoutDate=2018-08-31&rate=Standard&kids=0",
    "redirectNotes": null,
    "phoneNumber": "+1 646 774 1234",
    "addressOne": "153",
    "addressTwo": "West 57th Street",
    "city": "Midtown",
    "county": "New York",
    "region": "New York",
    "country": "United States",
    "rooms": 210,
    "dollar": 3,
    "updated": "2020-05-17T09:31:57.267",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 22978,
        "title": "Luxury Midtown Hotel Near Central Park | Park Hyatt New York",
        "url": "https://pkslist.azureedge.net/media/images/1/89e5280f-a225-4920-baf9-954f9108e211/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/89e5280f-a225-4920-baf9-954f9108e211/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/89e5280f-a225-4920-baf9-954f9108e211/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 22979,
        "title": "Luxury Midtown Hotel Near Central Park | Park Hyatt New York",
        "url": "https://pkslist.azureedge.net/media/images/1/5cc0c580-0e64-4011-a590-d0bbf6f518a7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5cc0c580-0e64-4011-a590-d0bbf6f518a7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5cc0c580-0e64-4011-a590-d0bbf6f518a7/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 22987,
        "title": "Park Hyatt New York | Hipmunk",
        "url": "https://pkslist.azureedge.net/media/images/1/59162315-52ad-447c-b870-d72459d731c9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/59162315-52ad-447c-b870-d72459d731c9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/59162315-52ad-447c-b870-d72459d731c9/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 22988,
        "title": "Park Hyatt opens flagship hotel in New York [VIDEO]",
        "url": "https://pkslist.azureedge.net/media/images/1/e26bfbcc-7629-41fd-bf93-f1c92e85d6a8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e26bfbcc-7629-41fd-bf93-f1c92e85d6a8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e26bfbcc-7629-41fd-bf93-f1c92e85d6a8/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 22984,
        "title": "Park Hyatt New York Tour - Business Insider",
        "url": "https://pkslist.azureedge.net/media/images/1/691737bf-cb7e-43c8-bb1b-b3455d99b5a4/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/691737bf-cb7e-43c8-bb1b-b3455d99b5a4/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/691737bf-cb7e-43c8-bb1b-b3455d99b5a4/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 22983,
        "title": "Park Hyatt New York | Classic Vacations",
        "url": "https://pkslist.azureedge.net/media/images/1/8ff53dfe-6669-4aa9-9a77-d5da17287f02/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8ff53dfe-6669-4aa9-9a77-d5da17287f02/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8ff53dfe-6669-4aa9-9a77-d5da17287f02/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 22981,
        "title": "Park Hyatt New York Review | Andy's Travel Blog",
        "url": "https://pkslist.azureedge.net/media/images/1/58cad480-8699-42ab-8b53-ca31c4743fa4/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/58cad480-8699-42ab-8b53-ca31c4743fa4/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/58cad480-8699-42ab-8b53-ca31c4743fa4/256x192.jpg",
        "viewOrder": 6
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1715,
      "latitude": 40.76542739999999,
      "longitude": -73.97901730000001,
      "parentGeographicAreaId": 0,
      "title": "153 W 57th St, New York, NY 10019, USA",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      },
      {
        "tagId": 272,
        "tagName": "Business",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 68,
        "tagName": "Business",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 85,
        "tagName": "Pet Friendly",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 205,
        "tagName": "Design",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 255,
        "tagName": "Music",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 323,
        "tagName": "City Walk",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 553,
        "venueCategoryId": 21,
        "venueId": 1902,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 21,
          "title": "New York Top 10",
          "region": "North America",
          "viewOrder": 8,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1181,
    "venueCategoryId": 21,
    "status": 1,
    "title": "Baccarat Hotel New York",
    "description": "The Baccarat name has long been synonymous with royalty: kings and queens, sultans and tsars, moguls and modern-day stars. Its glittering objets d’art are defined by their elegance and authenticity, used throughout the most sumptuous repasts and celebrations of life.\n\nBaccarat is translating its essence into contemporary culture, still paying homage to its history.",
    "brand": null,
    "blockQuote": "Modern, vibrant with otherworldly interiors.",
    "emailAddress": "NYReservations@baccarathotels.com",
    "website": "baccarathotels.com",
    "bookingURL": "http://www.booking.com/Share-UC4stt?aid=925669",
    "redirectNotes": null,
    "phoneNumber": "+1 212 790 8800",
    "addressOne": "28",
    "addressTwo": "West 53rd Street",
    "city": "Midtown",
    "county": "New York",
    "region": "New York",
    "country": "United States",
    "rooms": 114,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 12029,
        "title": "Baccarat Hotel and Residences, New York will open in 2014 -",
        "url": "https://pkslist.azureedge.net/media/images/1/fefec4d0-2d99-4de2-bef1-5e4779a863ec/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fefec4d0-2d99-4de2-bef1-5e4779a863ec/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fefec4d0-2d99-4de2-bef1-5e4779a863ec/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 23039,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/0bcaaba4-d144-4701-bd11-9c08d43cbfea/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0bcaaba4-d144-4701-bd11-9c08d43cbfea/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0bcaaba4-d144-4701-bd11-9c08d43cbfea/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 12025,
        "title": "Baccarat Hotel New York | Traveller Made",
        "url": "https://pkslist.azureedge.net/media/images/1/ac08033a-1553-4ffd-ad59-fffbc58f7e6e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ac08033a-1553-4ffd-ad59-fffbc58f7e6e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ac08033a-1553-4ffd-ad59-fffbc58f7e6e/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 12024,
        "title": "Luxury Hotels in New York: Baccarat Hotels to Open soon | New York ...",
        "url": "https://pkslist.azureedge.net/media/images/1/05d602d7-71ee-4ba1-9494-23a183328ca2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/05d602d7-71ee-4ba1-9494-23a183328ca2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/05d602d7-71ee-4ba1-9494-23a183328ca2/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 12031,
        "title": "Baccarat Hotel & Residences New York, Manhattan, New York, United ...",
        "url": "https://pkslist.azureedge.net/media/images/1/b2ab078b-b7e4-47cb-8341-8e374a20f521/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b2ab078b-b7e4-47cb-8341-8e374a20f521/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b2ab078b-b7e4-47cb-8341-8e374a20f521/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 12030,
        "title": "Baccarat Hotel & Residences New York",
        "url": "https://pkslist.azureedge.net/media/images/1/78c94881-d65f-418f-811c-e49a69da5cdc/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/78c94881-d65f-418f-811c-e49a69da5cdc/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/78c94881-d65f-418f-811c-e49a69da5cdc/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 12019,
        "title": "Baccarat Hotel in NYC | The Blonde Salad",
        "url": "https://pkslist.azureedge.net/media/images/1/3398cae5-3940-45a4-b2cf-cff5a1e06a58/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3398cae5-3940-45a4-b2cf-cff5a1e06a58/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3398cae5-3940-45a4-b2cf-cff5a1e06a58/256x192.jpg",
        "viewOrder": 5
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1005,
      "latitude": 40.7608918,
      "longitude": -73.97740110000001,
      "parentGeographicAreaId": 0,
      "title": "28 W 53rd St, New York, NY 10019, USA",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 51,
        "tagName": "Style Icon",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 68,
        "tagName": "Business",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 100,
        "tagName": "Wedding",
        "tagType": 2
      },
      {
        "tagId": 105,
        "tagName": "Afternoon Tea",
        "tagType": 2
      },
      {
        "tagId": 199,
        "tagName": "Art Gallery",
        "tagType": 2
      },
      {
        "tagId": 205,
        "tagName": "Design",
        "tagType": 2
      },
      {
        "tagId": 216,
        "tagName": "Museum",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 284,
        "tagName": "Architecture",
        "tagType": 2
      },
      {
        "tagId": 311,
        "tagName": "Cathedrals",
        "tagType": 2
      },
      {
        "tagId": 323,
        "tagName": "City Walk",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 222,
        "venueCategoryId": 21,
        "venueId": 1181,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 21,
          "title": "New York Top 10",
          "region": "North America",
          "viewOrder": 8,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1140,
    "venueCategoryId": 21,
    "status": 1,
    "title": "The Bowery Hotel",
    "description": "With Art Deco details, dark wood pannels, faded oriental rugs, and shabby-chic vintage furniture, the Bowery Hotel is unlike any other in New York. The atmosphere is darkly brooding and richly nostalgic. Bright, comfortable, and ultra-luxurious rooms that bring Old New York to life. Located on the edge of the East Village, the Bowery is within easy walking distance of all the trendiest neighbourhoods- an ideal base for exploring Manhattan's vibrant downtown nightlife scene.",
    "brand": "",
    "blockQuote": "The super chic lobby bar is a favourite of trendy New Yorkers and celebrities.",
    "emailAddress": "reservations@bohonyc.com",
    "website": "theboweryhotel.com",
    "bookingURL": "https://res.windsurfercrs.com/ibe/index.aspx?propertyID=8932&brandID=4769&checkin=09/04/2018&checkout=09/05/2018&adults=1&iata=96027890",
    "redirectNotes": "The Bowery Hotel's reservations system",
    "phoneNumber": "+1 212 505 9100",
    "addressOne": "335",
    "addressTwo": "Bowery",
    "city": "East Village",
    "county": "New York",
    "region": "New York",
    "country": "United States",
    "rooms": 135,
    "dollar": 2,
    "updated": "2020-05-17T09:20:27.753",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 12301,
        "title": "NYC Boutique Hotel Find: The Bowery Hotel - Melting Butter ...",
        "url": "https://pkslist.azureedge.net/media/images/1/ae5b39b1-8381-4230-ae6e-9ec720e58067/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ae5b39b1-8381-4230-ae6e-9ec720e58067/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ae5b39b1-8381-4230-ae6e-9ec720e58067/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 12298,
        "title": "The Bowery Hotel - The Newton | Curated travel guides + interviews ...",
        "url": "https://pkslist.azureedge.net/media/images/1/b9d72693-bfbb-4ec8-9b8b-1e74f530579a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b9d72693-bfbb-4ec8-9b8b-1e74f530579a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b9d72693-bfbb-4ec8-9b8b-1e74f530579a/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 11710,
        "title": "The Bowery Hotel - Wikipedia",
        "url": "https://pkslist.azureedge.net/media/images/1/18af742c-cb67-4fe5-b4c5-0b3c458fd0fb/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/18af742c-cb67-4fe5-b4c5-0b3c458fd0fb/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/18af742c-cb67-4fe5-b4c5-0b3c458fd0fb/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 11714,
        "title": "The Bowery Hotel, New York, USA - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/81d8e148-1eea-48b2-ad26-b1c55cdce9b1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/81d8e148-1eea-48b2-ad26-b1c55cdce9b1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/81d8e148-1eea-48b2-ad26-b1c55cdce9b1/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 12300,
        "title": "The Bowery Hotel, New York, New York | Interior inspiration ...",
        "url": "https://pkslist.azureedge.net/media/images/1/b2d997ee-f142-4831-b077-beb1d44b0ba6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b2d997ee-f142-4831-b077-beb1d44b0ba6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b2d997ee-f142-4831-b077-beb1d44b0ba6/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 11709,
        "title": "The Bowery Hotel, New York, USA - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/f81d8fcc-2167-42a0-bb0c-5ac0bbe4ca35/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f81d8fcc-2167-42a0-bb0c-5ac0bbe4ca35/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f81d8fcc-2167-42a0-bb0c-5ac0bbe4ca35/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 11711,
        "title": "The Bowery Hotel New York City - Where To Go",
        "url": "https://pkslist.azureedge.net/media/images/1/e12c789b-ebf5-44bc-a383-a3e126f89ba3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e12c789b-ebf5-44bc-a383-a3e126f89ba3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e12c789b-ebf5-44bc-a383-a3e126f89ba3/256x192.jpg",
        "viewOrder": 6
      }
    ],
    "geographicArea": {
      "geographicAreaId": 965,
      "latitude": 40.72601699999999,
      "longitude": -73.99162899999999,
      "parentGeographicAreaId": 0,
      "title": "335 Bowery, New York, NY 10003, USA",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 55,
        "tagName": "Lively",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 34,
        "tagName": "Cosy",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 68,
        "tagName": "Business",
        "tagType": 2
      },
      {
        "tagId": 172,
        "tagName": "Biking",
        "tagType": 2
      },
      {
        "tagId": 199,
        "tagName": "Art Gallery",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 216,
        "tagName": "Museum",
        "tagType": 2
      },
      {
        "tagId": 276,
        "tagName": "Gym",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 323,
        "tagName": "City Walk",
        "tagType": 2
      },
      {
        "tagId": 336,
        "tagName": "Ballroom",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 219,
        "venueCategoryId": 21,
        "venueId": 1140,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 21,
          "title": "New York Top 10",
          "region": "North America",
          "viewOrder": 8,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 739,
    "venueCategoryId": 16,
    "status": 1,
    "title": "The Whitby Hotel",
    "description": "Situated in the heart of upper midtown Manhattan on West 56th Street near 5th Avenue, The Whitby is just two blocks from Central Park and on the doorstep of some of New York's leading restaurants, galleries and museums, including MoMA. It is surrounded by world-class department stores, luxury designer boutiques and Broadway is just a short walk away.\n\nThe Whitby Hotel is a celebration of contemporary art and design. There are 86 individually designed bedrooms and suites, each with floor to ceiling windows, many with a private terrace and stunning views of the Manhattan skyline. Interiors have been designed by Kit Kemp, acclaimed for her individual and unique way of arranging spaces with a colourful and carefree spirit. There is also The Whitby Bar and Restaurant, an Orangery, book-lined Drawing Room, private outdoor terrace as well as three beautifully designed private event rooms, a 130 seat state-of-the-art cinema and a fully equipped gym.",
    "brand": "",
    "blockQuote": "Check out the Whitby Experiences for fun and exciting things to do during your stay.",
    "emailAddress": "whitby@firmdale.com",
    "website": "firmdalehotels.com/hotels/new-york/the-whitby-hotel",
    "bookingURL": "http://www.booking.com/Share-oHeCAU?aid=925669",
    "redirectNotes": null,
    "phoneNumber": "+1 212 586 5656",
    "addressOne": "18",
    "addressTwo": "West 56th Street",
    "city": "Midtown",
    "county": "New York",
    "region": "New York",
    "country": "United States",
    "rooms": 86,
    "dollar": 3,
    "updated": "2020-11-21T08:26:06.173",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 42980,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/965dc355-f900-478f-be83-ab61f6b4dcfb/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/965dc355-f900-478f-be83-ab61f6b4dcfb/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/965dc355-f900-478f-be83-ab61f6b4dcfb/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 42982,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/10174421-cd27-4fd1-a7c8-10997b190d46/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/10174421-cd27-4fd1-a7c8-10997b190d46/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/10174421-cd27-4fd1-a7c8-10997b190d46/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 42991,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/50404c7c-c811-488e-ad90-cd49bc33b21a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/50404c7c-c811-488e-ad90-cd49bc33b21a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/50404c7c-c811-488e-ad90-cd49bc33b21a/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 42992,
        "title": "Firmdale Hotels - New York - The Whitby Hotel",
        "url": "https://pkslist.azureedge.net/media/images/1/c196ddae-d8c8-4c02-b914-ef0d7fb38737/1200x900.00972861113229513&cropmode=percentage&width=1596&height=892&bgcolor=fff&sig=455e724d9a7ecc6f063498990e2572df",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c196ddae-d8c8-4c02-b914-ef0d7fb38737/800x600.00972861113229513&cropmode=percentage&width=1596&height=892&bgcolor=fff&sig=455e724d9a7ecc6f063498990e2572df",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c196ddae-d8c8-4c02-b914-ef0d7fb38737/256x192.00972861113229513&cropmode=percentage&width=1596&height=892&bgcolor=fff&sig=455e724d9a7ecc6f063498990e2572df",
        "viewOrder": 3
      },
      {
        "mediaId": 42979,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/6d5c170b-5d6e-41fb-b46e-34d31e9dcf07/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6d5c170b-5d6e-41fb-b46e-34d31e9dcf07/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6d5c170b-5d6e-41fb-b46e-34d31e9dcf07/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 42988,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f433f353-44c0-4296-b023-dc6baa0e30f8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f433f353-44c0-4296-b023-dc6baa0e30f8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f433f353-44c0-4296-b023-dc6baa0e30f8/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 42987,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/548e1906-abdf-45d9-a921-6f6f50327777/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/548e1906-abdf-45d9-a921-6f6f50327777/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/548e1906-abdf-45d9-a921-6f6f50327777/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 42976,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/cfddf196-164d-438b-a638-6feec81afbb8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cfddf196-164d-438b-a638-6feec81afbb8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cfddf196-164d-438b-a638-6feec81afbb8/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 42977,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/66e5664a-2f16-432b-9433-bfc621f342e3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/66e5664a-2f16-432b-9433-bfc621f342e3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/66e5664a-2f16-432b-9433-bfc621f342e3/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 42986,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e5b2c064-6204-4ca1-a2d1-bf1a899f3590/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e5b2c064-6204-4ca1-a2d1-bf1a899f3590/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e5b2c064-6204-4ca1-a2d1-bf1a899f3590/256x192.jpg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 576,
      "latitude": 40.7626052,
      "longitude": -73.97573410000001,
      "parentGeographicAreaId": 0,
      "title": "18 W 56th St, New York, NY 10019, USA",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 62,
        "tagName": "Nightlife",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 68,
        "tagName": "Business",
        "tagType": 2
      },
      {
        "tagId": 70,
        "tagName": "Families",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 120,
        "tagName": "Private Dining",
        "tagType": 2
      },
      {
        "tagId": 155,
        "tagName": "Art",
        "tagType": 2
      },
      {
        "tagId": 216,
        "tagName": "Museum",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 323,
        "tagName": "City Walk",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 125,
        "venueCategoryId": 16,
        "venueId": 739,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 16,
          "title": "Boutique Boltholes",
          "region": "Global",
          "viewOrder": 18,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 554,
        "venueCategoryId": 21,
        "venueId": 739,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 21,
          "title": "New York Top 10",
          "region": "North America",
          "viewOrder": 8,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 505,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Four Seasons Hotel New York",
    "description": "Experience five-star, New York hotel living in our modern-deco landmark designed by famed architect I.M. Pei. Located on “Billionaires’ Row” at the city’s most prestigious address between Park Avenue and Madison Avenue, you’re just steps from Central Park and Madison Avenue shopping. Come and unwind in our sun-filled suites (some of the largest in Manhattan), take in the unparalleled city and park views, and experience serenity and luxury high above Manhattan’s exhilarating whirlwind.",
    "brand": "Four Seasons",
    "blockQuote": "The incredible Ty Warner Penthouse Suite comes with any car you want.",
    "emailAddress": "reservations.nyf@fourseasons.com",
    "website": "fourseasons.com/newyork/",
    "bookingURL": "https://reservations.fourseasons.com/?hotelCode=NYC633&checkIn=2021-03-17&checkOut=2021-03-18&adults=2&children=0&iata=96027890",
    "redirectNotes": "Four Seasons Hotel New York's reservations system",
    "phoneNumber": "+1 212 758 5700",
    "addressOne": "57",
    "addressTwo": "East 57th Street",
    "city": "Midtown",
    "county": "New York",
    "region": "New York",
    "country": "United States",
    "rooms": 368,
    "dollar": 3,
    "updated": "2021-03-17T16:17:31.387",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 46262,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e8d2a685-7599-4784-8239-aa8c08dc0c24/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e8d2a685-7599-4784-8239-aa8c08dc0c24/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e8d2a685-7599-4784-8239-aa8c08dc0c24/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 46263,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/bbb46dec-31a1-4d2b-a119-f38e336347bd/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bbb46dec-31a1-4d2b-a119-f38e336347bd/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bbb46dec-31a1-4d2b-a119-f38e336347bd/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 46261,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d61d3887-7da6-4d62-8216-f7577a88a92e/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d61d3887-7da6-4d62-8216-f7577a88a92e/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d61d3887-7da6-4d62-8216-f7577a88a92e/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 46264,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/62c79889-72dd-4131-88b9-f61c4652bc4c/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/62c79889-72dd-4131-88b9-f61c4652bc4c/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/62c79889-72dd-4131-88b9-f61c4652bc4c/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 46265,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/3550bcb8-2d3f-4d0c-bd94-a37da06793db/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3550bcb8-2d3f-4d0c-bd94-a37da06793db/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3550bcb8-2d3f-4d0c-bd94-a37da06793db/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 46259,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/4023e31e-8cdd-47b8-a321-f172d5469cdb/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4023e31e-8cdd-47b8-a321-f172d5469cdb/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4023e31e-8cdd-47b8-a321-f172d5469cdb/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 46266,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f37b0368-c50f-4f01-9f4c-0ebdb3a6840d/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f37b0368-c50f-4f01-9f4c-0ebdb3a6840d/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f37b0368-c50f-4f01-9f4c-0ebdb3a6840d/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 46268,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/eee617a5-0d43-48a7-a2d0-340ebfe2ec60/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/eee617a5-0d43-48a7-a2d0-340ebfe2ec60/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/eee617a5-0d43-48a7-a2d0-340ebfe2ec60/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 46267,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/85c48124-e384-4862-ae9b-539444eb081c/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/85c48124-e384-4862-ae9b-539444eb081c/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/85c48124-e384-4862-ae9b-539444eb081c/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 46260,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/59f8d480-5d75-41e9-b117-99006d4f0950/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/59f8d480-5d75-41e9-b117-99006d4f0950/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/59f8d480-5d75-41e9-b117-99006d4f0950/256x192.jpeg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 349,
      "latitude": 40.7621879,
      "longitude": -73.97160629999996,
      "parentGeographicAreaId": 0,
      "title": "57 E 57th St, New York, NY 10022, USA",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 48,
        "tagName": "Iconic/Landmark ",
        "tagType": 1
      },
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 62,
        "tagName": "Nightlife",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 68,
        "tagName": "Business",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 85,
        "tagName": "Pet Friendly",
        "tagType": 2
      },
      {
        "tagId": 105,
        "tagName": "Afternoon Tea",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 332,
        "tagName": "Fashion",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 555,
        "venueCategoryId": 21,
        "venueId": 505,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 21,
          "title": "New York Top 10",
          "region": "North America",
          "viewOrder": 8,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 319,
    "venueCategoryId": 21,
    "status": 1,
    "title": "The Mark",
    "description": "The Mark is housed, as it has always been, in the beautiful, 1927 landmark building at the corner of 77th Street and Madison Avenue. But, inside, the Mark has been completely reimagined, and given new life and identity for the 21st century. Newly reborn, The Mark combines old-world comfort, avant-garde design, up-to-the-minute technology and an unprecedented level of personal service to create the finest five-star hotel of the 21st century. Drawing on the expertise of an extraordinary group of talent – foremost, among them, the designer Jacques Grange, Chef Jean-Georges Vongerichten, hair stylist Frederic Fekkai and the hotel general manager Olivier Lordonnois – The Mark Hotel is now a beacon of high French style, and modern American technology and comfort.",
    "brand": "",
    "blockQuote": "The Mark's Bikes and Picnic by Jean-Georges is such fun way to explore Central Park and New York.",
    "emailAddress": "reservations@themarkhotel.com",
    "website": "themarkhotel.com/",
    "bookingURL": "https://gc.synxis.com/rez.aspx?tps=fml&adult=1&step=1&hotel=56217&shell=rNYCMK&chain=10237&template=rNYCMK&avcurrency=USD&_ga=2.6515826.1901435851.1536069326-636314049.1536069326&iata=96027890",
    "redirectNotes": "The Mark's reservations system",
    "phoneNumber": "+1 212 744 4300",
    "addressOne": "25 East 77th Street",
    "addressTwo": "Madison Avenue",
    "city": "Upper East Side",
    "county": "New York",
    "region": "New York",
    "country": "United States",
    "rooms": 150,
    "dollar": 2,
    "updated": "2020-05-17T09:04:15.76",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 1299,
        "title": "Hotels Manhattan New York | The Mark Hotel – Gallery | 5 Star ...",
        "url": "https://pkslist.azureedge.net/media/images/1/51f13854-56dc-49f5-8804-120deede8e75/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/51f13854-56dc-49f5-8804-120deede8e75/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/51f13854-56dc-49f5-8804-120deede8e75/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 1298,
        "title": "cn_image_1.size.the-mark-new-york-new-york-new-york-111420-2 ...",
        "url": "https://pkslist.azureedge.net/media/images/1/b24723ac-5142-400d-b0d1-3b4a77ddc588/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b24723ac-5142-400d-b0d1-3b4a77ddc588/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b24723ac-5142-400d-b0d1-3b4a77ddc588/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 19194,
        "title": "The Mark, New York | Traveller Made",
        "url": "https://pkslist.azureedge.net/media/images/1/c544a6a0-7af8-4fbd-ae38-26295d69db99/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c544a6a0-7af8-4fbd-ae38-26295d69db99/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c544a6a0-7af8-4fbd-ae38-26295d69db99/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 1300,
        "title": "The Mark, New York | Traveller Made",
        "url": "https://pkslist.azureedge.net/media/images/1/6fa174a4-ef34-4d1e-b3c6-ef1e2a24b074/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6fa174a4-ef34-4d1e-b3c6-ef1e2a24b074/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6fa174a4-ef34-4d1e-b3c6-ef1e2a24b074/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 1304,
        "title": "New York City Hotel Rooms | The Mark Hotel – Superior Courtyard ...",
        "url": "https://pkslist.azureedge.net/media/images/1/dee896c9-b327-48af-b81c-5e8532ea4acf/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/dee896c9-b327-48af-b81c-5e8532ea4acf/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/dee896c9-b327-48af-b81c-5e8532ea4acf/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 1302,
        "title": "The Mark, New York, US: each guest room features the BeoVision 8 ...",
        "url": "https://pkslist.azureedge.net/media/images/1/e9e5b23c-fdbc-4c0f-b56e-13f6868d584a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e9e5b23c-fdbc-4c0f-b56e-13f6868d584a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e9e5b23c-fdbc-4c0f-b56e-13f6868d584a/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 1306,
        "title": "The Mark, New York, New York - Hotel Review & Photos",
        "url": "https://pkslist.azureedge.net/media/images/1/8fce8eba-988f-41e8-a8b3-b3f0cf848b98/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8fce8eba-988f-41e8-a8b3-b3f0cf848b98/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8fce8eba-988f-41e8-a8b3-b3f0cf848b98/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 1297,
        "title": "Hotel The Mark New York, New York City, NY - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/19a50cb9-85ba-4544-83c0-49145f166192/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/19a50cb9-85ba-4544-83c0-49145f166192/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/19a50cb9-85ba-4544-83c0-49145f166192/256x192.jpg",
        "viewOrder": 7
      }
    ],
    "geographicArea": {
      "geographicAreaId": 179,
      "latitude": 40.7752711,
      "longitude": -73.9633994,
      "parentGeographicAreaId": 0,
      "title": "25 E 77th St, New York, NY 10075, USA",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 60,
        "tagName": "Gastronomic",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 68,
        "tagName": "Business",
        "tagType": 2
      },
      {
        "tagId": 127,
        "tagName": "Hair Salon",
        "tagType": 2
      },
      {
        "tagId": 172,
        "tagName": "Biking",
        "tagType": 2
      },
      {
        "tagId": 205,
        "tagName": "Design",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 216,
        "tagName": "Museum",
        "tagType": 2
      },
      {
        "tagId": 256,
        "tagName": "Boutique",
        "tagType": 2
      },
      {
        "tagId": 276,
        "tagName": "Gym",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 47,
        "venueCategoryId": 21,
        "venueId": 319,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 21,
          "title": "New York Top 10",
          "region": "North America",
          "viewOrder": 8,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 318,
    "venueCategoryId": 21,
    "status": 1,
    "title": "The Lowell Hotel",
    "description": "Located on a quiet, tree – lined street off Madison Avenue, The Lowell is in the heart of New York’s most exclusive and fashionable Upper East Side. A refined 74 room hotel, The Lowell welcomes guests with a serene residential atmosphere reminiscent of a private townhouse. With only 47 suites and 27 deluxe rooms, this landmark building prides itself on being one of the few luxury hotels that has accommodations with real wood – burning fireplaces, terraces and fully equipped kitchens. Michael Smith, a world renowned interior designer has further enhanced The Lowell’s residential ambiance with his distinctive style. Personalised service is inherent in a plush yet dignified manner. The way in which we welcome our guests, attend to them during their stay and remember them when they come back is the essence of The Lowell.",
    "brand": "",
    "blockQuote": "The epitome of Upper East Side elegance, it's perfectly placed to enjoy all the nightlife and exploring Manhattan has to offer.",
    "emailAddress": "reservations@lowellhotel.com",
    "website": "lowellhotel.com/",
    "bookingURL": "https://www.myhotelreservation.net/b/hllw1765/HLLW1765/?f=2018-03-16&t=2018-03-17&i=96027890&_ga=2.211185045.1684961024.1521204637-452220557.1521204637",
    "redirectNotes": "The Lowell Hotel's reservations system",
    "phoneNumber": "+1 212 838 1400",
    "addressOne": "28",
    "addressTwo": "East 63rd Street",
    "city": "Upper East Side",
    "county": "New York",
    "region": "New York",
    "country": "United States",
    "rooms": 74,
    "dollar": 3,
    "updated": "2020-05-17T09:02:36.833",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 1287,
        "title": "The Lowell - Hotels in New York , NY - Hotels.com",
        "url": "https://pkslist.azureedge.net/media/images/1/33c2f892-2711-4650-8070-fbb2cec5bf6e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/33c2f892-2711-4650-8070-fbb2cec5bf6e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/33c2f892-2711-4650-8070-fbb2cec5bf6e/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 1296,
        "title": "Hotels 5* | The Lowell Hotel | New York Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/76732a38-90d4-47f0-89ca-7c63a4a17f75/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/76732a38-90d4-47f0-89ca-7c63a4a17f75/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/76732a38-90d4-47f0-89ca-7c63a4a17f75/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 1285,
        "title": "Midtown Manhattan Hotels | Home | The Lowell Hotel",
        "url": "https://pkslist.azureedge.net/media/images/1/47dac9b5-5b5e-4b02-9328-171601c627e5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/47dac9b5-5b5e-4b02-9328-171601c627e5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/47dac9b5-5b5e-4b02-9328-171601c627e5/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 1288,
        "title": "New York City Luxury Hotels | Lowell Room | The Lowell Hotel",
        "url": "https://pkslist.azureedge.net/media/images/1/e0024c01-1426-48fd-bf7c-3a25c97ae599/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e0024c01-1426-48fd-bf7c-3a25c97ae599/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e0024c01-1426-48fd-bf7c-3a25c97ae599/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 1290,
        "title": "The Lowell, New York Deals - See Hotel Photos - Attractions Near ...",
        "url": "https://pkslist.azureedge.net/media/images/1/395fa380-4242-4d51-9c3c-4aff0c87257a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/395fa380-4242-4d51-9c3c-4aff0c87257a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/395fa380-4242-4d51-9c3c-4aff0c87257a/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 1294,
        "title": "Luxury New York Hotels | Penthouse Suite | The Lowell Hotel",
        "url": "https://pkslist.azureedge.net/media/images/1/c3084ff0-d512-48f6-90be-84f1704ccf8b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c3084ff0-d512-48f6-90be-84f1704ccf8b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c3084ff0-d512-48f6-90be-84f1704ccf8b/256x192.jpg",
        "viewOrder": 4
      }
    ],
    "geographicArea": {
      "geographicAreaId": 178,
      "latitude": 40.76571320000001,
      "longitude": -73.96921370000001,
      "parentGeographicAreaId": 0,
      "title": "28 E 63rd St, New York, NY 10065, USA",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 40,
        "tagName": "Boutique Bolthole ",
        "tagType": 1
      },
      {
        "tagId": 42,
        "tagName": "Classic Traditionalist ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 68,
        "tagName": "Business",
        "tagType": 2
      },
      {
        "tagId": 85,
        "tagName": "Pet Friendly",
        "tagType": 2
      },
      {
        "tagId": 199,
        "tagName": "Art Gallery",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 216,
        "tagName": "Museum",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 293,
        "tagName": "Meeting Space",
        "tagType": 2
      },
      {
        "tagId": 323,
        "tagName": "City Walk",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 46,
        "venueCategoryId": 21,
        "venueId": 318,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 21,
          "title": "New York Top 10",
          "region": "North America",
          "viewOrder": 8,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 317,
    "venueCategoryId": 21,
    "status": 1,
    "title": "The Carlyle, A Rosewood Hotel",
    "description": "Surround yourself with the elegance of the Upper East Side - from the vibrant culture and elite shopping to the oasis of understated luxury - The Carlyle has become a \"home away from home\" for sophisticated travelers. Offering a first-class combination of discretion and grace - the 190 rooms and suites feature elegant views of Central Park and Manhattan's skyline. Gracious and romantic formal dining awaits guests in The Carlyle Restaurant while legendary entertainers create the quintessential New York cabaret experience in the famed Café Carlyle.Step outside the elegant marble lobby and into a new level of sophistication. The Carlyle, A Rosewood Hotel is surrounded by galleries and designer boutiques on New York's posh Madison Avenue. Blocks from the Metropolitan Museum of Art, the Guggenheim, the Whitney and the Frick, the hotel is within easy reach of what is commonly referred to as \"Museum Mile.\"",
    "brand": "Rosewood",
    "blockQuote": "A sophisticated and elegant hotel, The Carlyle is a landmark of the Upper East Side.",
    "emailAddress": "thecarlyle@rosewoodhotels.com",
    "website": "rosewoodhotels.com/en/the-carlyle-new-york",
    "bookingURL": "https://reservations.rosewoodhotels.com/?adult=1&agencyid=96027890&arrive=2021-06-08&chain=5159&child=0&config=gtm&currency=USD&depart=2021-06-09&filter=&hotel=153&level=hotel&locale=en-US&rooms=1",
    "redirectNotes": "The Carlyle's reservations system",
    "phoneNumber": "+1 212 744 1600",
    "addressOne": "35",
    "addressTwo": "East 76th Street",
    "city": "Upper East Side",
    "county": "New York",
    "region": "New York",
    "country": "United States",
    "rooms": 190,
    "dollar": 2,
    "updated": "2021-06-08T14:03:08.743",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 36067,
        "title": "The Carlyle, A Rosewood Hotel Expert Review | Fodor's Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/35cec763-6f76-4413-b003-af9daf2a2294/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/35cec763-6f76-4413-b003-af9daf2a2294/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/35cec763-6f76-4413-b003-af9daf2a2294/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 1273,
        "title": "Bemelmans Bar at The Carlyle, A Rosewood Hotel | Design - F&B ...",
        "url": "https://pkslist.azureedge.net/media/images/1/517d8715-d1e8-42fe-9f01-3e5b08162d23/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/517d8715-d1e8-42fe-9f01-3e5b08162d23/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/517d8715-d1e8-42fe-9f01-3e5b08162d23/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 3889,
        "title": "The Carlyle (Rosewood), New York, New York - Hotel Review & Photos",
        "url": "https://pkslist.azureedge.net/media/images/1/125c8f5e-9237-4fe5-b14a-8a8db02e1c1b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/125c8f5e-9237-4fe5-b14a-8a8db02e1c1b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/125c8f5e-9237-4fe5-b14a-8a8db02e1c1b/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 3891,
        "title": "The Carlyle Hotel | The Carlyle, A Rosewood Hotel 35 East 76… | Flickr",
        "url": "https://pkslist.azureedge.net/media/images/1/d5e5f8bb-cdd8-4775-a75a-8df3637a7165/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d5e5f8bb-cdd8-4775-a75a-8df3637a7165/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d5e5f8bb-cdd8-4775-a75a-8df3637a7165/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 3896,
        "title": "The Carlyle, A Rosewood Hotel | Hipmunk",
        "url": "https://pkslist.azureedge.net/media/images/1/d9fe049a-915e-4b73-9967-ddea01b444e2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d9fe049a-915e-4b73-9967-ddea01b444e2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d9fe049a-915e-4b73-9967-ddea01b444e2/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 36069,
        "title": "The Carlyle, A Rosewood Hotel, Manhattan, New York, United States ...",
        "url": "https://pkslist.azureedge.net/media/images/1/bbb7ee64-728f-4d5a-9bf3-e919d5c12fcc/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bbb7ee64-728f-4d5a-9bf3-e919d5c12fcc/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bbb7ee64-728f-4d5a-9bf3-e919d5c12fcc/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 36068,
        "title": "The Carlyle, A Rosewood Hotel, - Hotel Review - Condé Nast Traveler",
        "url": "https://pkslist.azureedge.net/media/images/1/47c444e7-23fd-49ff-95da-b1dc990f2fab/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/47c444e7-23fd-49ff-95da-b1dc990f2fab/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/47c444e7-23fd-49ff-95da-b1dc990f2fab/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 1281,
        "title": "30th floor room! - Picture of The Carlyle, A Rosewood Hotel, New ...",
        "url": "https://pkslist.azureedge.net/media/images/1/36657b6d-950f-45d0-94da-3c17a2070ca9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/36657b6d-950f-45d0-94da-3c17a2070ca9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/36657b6d-950f-45d0-94da-3c17a2070ca9/256x192.jpg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 177,
      "latitude": 40.77431010000001,
      "longitude": -73.96307230000002,
      "parentGeographicAreaId": 0,
      "title": "35 E 76th St, New York, NY 10021, USA",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 46,
        "tagName": "Formal Luxury ",
        "tagType": 1
      },
      {
        "tagId": 48,
        "tagName": "Iconic/Landmark ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 68,
        "tagName": "Business",
        "tagType": 2
      },
      {
        "tagId": 78,
        "tagName": "Fine Dining",
        "tagType": 2
      },
      {
        "tagId": 127,
        "tagName": "Hair Salon",
        "tagType": 2
      },
      {
        "tagId": 167,
        "tagName": "Nanny/Babysitting Service",
        "tagType": 2
      },
      {
        "tagId": 199,
        "tagName": "Art Gallery",
        "tagType": 2
      },
      {
        "tagId": 216,
        "tagName": "Museum",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 323,
        "tagName": "City Walk",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 45,
        "venueCategoryId": 21,
        "venueId": 317,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 21,
          "title": "New York Top 10",
          "region": "North America",
          "viewOrder": 8,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 316,
    "venueCategoryId": 21,
    "status": 1,
    "title": "The Greenwich Hotel",
    "description": "The Greenwich Hotel mirrors the originality  of co-owner, Robert De Niro. Located in TriBeCa's cobblestone streets, The Greenwich offers intimacy, seclusion, and easy access to all New York has to offer.\n\nAccording to the hotel manual, \"There is no experience that the concierge can't arrange. A few ideas to get you started: a day trip to the North Fork vineyards in a vintage Aston Martin; a private cooking class on the finer points of Japanese cuisine.\" \n\nFurnishings draw from a wide range of cultural influences, from hand-loomed, Tibetan silk rugs to English leather settees. All beds are Duxiana from Sweden. Bathrooms are designed in unique patterns of hand laid Moroccan tile or Italian Carrara marble.\n\nDownstairs is Locanda Verde, a casual Italian spot with a zesty fare, and the Zen Shibui Spa, which has a shiatsu room, fitness centre and a one-of-a-kind lantern-lit pool housed under the roof of a 250-year-old bamboo farmhouse.",
    "brand": null,
    "blockQuote": "Expect luxurious service and gastronomic delights at the ultra-trendy and bohemian Greenwich Hotel, nestled in the vibrant Tribeca neighbourhood",
    "emailAddress": "frontdesk@thegreenwichhotel.com",
    "website": "thegreenwichhotel.com/",
    "bookingURL": "https://www.myhotelreservation.net/b/hllw2730/HLLW2730/?f=2018-09-05&t=2018-09-06&i=96027890",
    "redirectNotes": "The Greenwich Hotel's reservations system",
    "phoneNumber": "+1 212 941 8900",
    "addressOne": "377",
    "addressTwo": "Greenwich Street",
    "city": "TriBeCa",
    "county": "New York",
    "region": "New York",
    "country": "United States",
    "rooms": 88,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 1260,
        "title": "The Greenwich HotelTriBeCa - Compass + Twine",
        "url": "https://pkslist.azureedge.net/media/images/1/4ca7b86d-fdba-4fbc-94d8-a4a05d915b8a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4ca7b86d-fdba-4fbc-94d8-a4a05d915b8a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4ca7b86d-fdba-4fbc-94d8-a4a05d915b8a/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 1263,
        "title": "1000+ images about NYC - TriBeCa - The Greenwich Hotel on ...",
        "url": "https://pkslist.azureedge.net/media/images/1/322b5724-3767-43ed-8db7-96e98fbcdf03/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/322b5724-3767-43ed-8db7-96e98fbcdf03/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/322b5724-3767-43ed-8db7-96e98fbcdf03/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 1266,
        "title": "The Greenwich Hotel | Luxury Hotel in New York City New York",
        "url": "https://pkslist.azureedge.net/media/images/1/65549520-98b7-455a-8bcd-f4a89fb1d7cb/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/65549520-98b7-455a-8bcd-f4a89fb1d7cb/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/65549520-98b7-455a-8bcd-f4a89fb1d7cb/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 1264,
        "title": "Accommodations | NYC Hotel, TriBeCa Hotel | The Greenwich Hotel",
        "url": "https://pkslist.azureedge.net/media/images/1/21ff3ac3-d419-4d02-8337-e42a94c1c429/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/21ff3ac3-d419-4d02-8337-e42a94c1c429/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/21ff3ac3-d419-4d02-8337-e42a94c1c429/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 1267,
        "title": "Hotels with DUX Luxury Hotel Mattresses | The Greenwich Hotel",
        "url": "https://pkslist.azureedge.net/media/images/1/51688ef7-2fdd-4619-9220-d975996ef197/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/51688ef7-2fdd-4619-9220-d975996ef197/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/51688ef7-2fdd-4619-9220-d975996ef197/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 1262,
        "title": "We ask the questions: Philip Truelove of the Greenwich Hotel New ...",
        "url": "https://pkslist.azureedge.net/media/images/1/ac780e2b-6c6f-4e3d-92d3-7295ea21e7c6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ac780e2b-6c6f-4e3d-92d3-7295ea21e7c6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ac780e2b-6c6f-4e3d-92d3-7295ea21e7c6/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 1261,
        "title": "The Greenwich Hotel Review, New York | Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/c5b5dae9-3904-4074-bd0b-1cb5970058d9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c5b5dae9-3904-4074-bd0b-1cb5970058d9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c5b5dae9-3904-4074-bd0b-1cb5970058d9/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 1265,
        "title": "Insider's Look at The Greenwich Hotel: Where Tom Cruise and Suri ...",
        "url": "https://pkslist.azureedge.net/media/images/1/1a63eef3-1e5c-4ddf-87ab-9a6c968f0b19/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1a63eef3-1e5c-4ddf-87ab-9a6c968f0b19/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1a63eef3-1e5c-4ddf-87ab-9a6c968f0b19/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 1259,
        "title": "Greenwich Hotel (New York City) - UPDATED 2016 Reviews - TripAdvisor",
        "url": "https://pkslist.azureedge.net/media/images/1/5adf8595-b814-4093-8a5f-48b71d9dda28/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5adf8595-b814-4093-8a5f-48b71d9dda28/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5adf8595-b814-4093-8a5f-48b71d9dda28/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 176,
      "latitude": 40.7198912,
      "longitude": -74.00980119999997,
      "parentGeographicAreaId": 0,
      "title": "377 Greenwich St, New York, NY 10013, USA",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 40,
        "tagName": "Boutique Bolthole ",
        "tagType": 1
      },
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 64,
        "tagName": "Sceney",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 131,
        "tagName": "Library",
        "tagType": 2
      },
      {
        "tagId": 199,
        "tagName": "Art Gallery",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 323,
        "tagName": "City Walk",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 44,
        "venueCategoryId": 21,
        "venueId": 316,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 21,
          "title": "New York Top 10",
          "region": "North America",
          "viewOrder": 8,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 309,
    "venueCategoryId": 21,
    "status": 1,
    "title": "Mandarin Oriental New York",
    "description": "Offering a bird's eye view of the city, Mandarin Oriental, New York is a luxury hotel with a five-star difference. Enjoying a stunning setting and beautiful views of Manhattan, they offer the ultimate in service, comfort and style.\n\nMandarin Oriental, New York enjoys a prime position in the heart of this iconic city. Overlooking the Hudson River, Central Park and the famous skyline, their spacious accommodation and luxurious Five-Star facilities will take your breath away.",
    "brand": "Mandarin Oriental",
    "blockQuote": "A luxurious stay in the Big Apple thanks to its prime location, lavish rooms and award-winning cuisine.",
    "emailAddress": "monyc-reservations@mohg.com",
    "website": "mandarinoriental.com/newyork/",
    "bookingURL": "https://www.mandarinoriental.com/reservations/rooms?adults=2&children=0&rooms=1&arrive=2018-08-29&depart=2018-08-30&hotel=532&iata=96027890&language=en-gb",
    "redirectNotes": "Mandarin Oriental New York's reservations system",
    "phoneNumber": "+1 212 805 8800 ",
    "addressOne": "80",
    "addressTwo": "Columbus Circle",
    "city": "Upper West Side",
    "county": "New York",
    "region": "New York",
    "country": "United States",
    "rooms": 244,
    "dollar": 3,
    "updated": "2020-05-15T07:56:36.457",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 22862,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/5fc0edc4-525f-40ac-8a6b-4842cd278e8d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5fc0edc4-525f-40ac-8a6b-4842cd278e8d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5fc0edc4-525f-40ac-8a6b-4842cd278e8d/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 22864,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/5ecbc991-76c5-48f3-bf37-38adf71a08aa/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5ecbc991-76c5-48f3-bf37-38adf71a08aa/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5ecbc991-76c5-48f3-bf37-38adf71a08aa/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 18291,
        "title": "lux et veritas design | Mandarin Oriental Hotel - New York - lux ...",
        "url": "https://pkslist.azureedge.net/media/images/1/3f66c01c-b65e-4639-b91c-d0976af4d93d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3f66c01c-b65e-4639-b91c-d0976af4d93d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3f66c01c-b65e-4639-b91c-d0976af4d93d/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 22863,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a98b5830-39b4-43f5-8996-c3eb875852b9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a98b5830-39b4-43f5-8996-c3eb875852b9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a98b5830-39b4-43f5-8996-c3eb875852b9/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 22861,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/82c9d72e-8820-4ae6-abd8-cb95b60d15e3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/82c9d72e-8820-4ae6-abd8-cb95b60d15e3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/82c9d72e-8820-4ae6-abd8-cb95b60d15e3/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 22865,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d291a8be-67f7-41f1-87f2-9f7bf2778e00/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d291a8be-67f7-41f1-87f2-9f7bf2778e00/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d291a8be-67f7-41f1-87f2-9f7bf2778e00/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 18292,
        "title": "A Splashy, Secret New Place To Stay In New York: Suite 5000 At The ...",
        "url": "https://pkslist.azureedge.net/media/images/1/b7a1c054-1bb1-4cb2-9e39-42b2517e245b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b7a1c054-1bb1-4cb2-9e39-42b2517e245b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b7a1c054-1bb1-4cb2-9e39-42b2517e245b/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 1197,
        "title": "Mandarin Oriental, New York, New York, New York - Hotel Review ...",
        "url": "https://pkslist.azureedge.net/media/images/1/f083ac98-1d45-4985-964d-3ec2693352f0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f083ac98-1d45-4985-964d-3ec2693352f0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f083ac98-1d45-4985-964d-3ec2693352f0/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 18300,
        "title": "Mandarin Oriental Hotel New York - Hotel Gifts",
        "url": "https://pkslist.azureedge.net/media/images/1/4f7d2146-bd53-4e33-a18f-6d9b491fad06/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4f7d2146-bd53-4e33-a18f-6d9b491fad06/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4f7d2146-bd53-4e33-a18f-6d9b491fad06/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 1200,
        "title": "Mandarin Oriental, New York, New York, New York - Hotel Review ...",
        "url": "https://pkslist.azureedge.net/media/images/1/c20afce2-4f84-448b-9916-63e76db4f753/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c20afce2-4f84-448b-9916-63e76db4f753/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c20afce2-4f84-448b-9916-63e76db4f753/256x192.jpg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 170,
      "latitude": 40.7685526,
      "longitude": -73.98318660000001,
      "parentGeographicAreaId": 0,
      "title": "80 Columbus Cir, New York, NY 10023, USA",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 48,
        "tagName": "Iconic/Landmark ",
        "tagType": 1
      },
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 62,
        "tagName": "Nightlife",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 105,
        "tagName": "Afternoon Tea",
        "tagType": 2
      },
      {
        "tagId": 199,
        "tagName": "Art Gallery",
        "tagType": 2
      },
      {
        "tagId": 203,
        "tagName": "Sauna",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 216,
        "tagName": "Museum",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 315,
        "tagName": "Theatre",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 42,
        "venueCategoryId": 21,
        "venueId": 309,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 21,
          "title": "New York Top 10",
          "region": "North America",
          "viewOrder": 8,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  }
]
// 20240123100509
// https://app.pks-list.com/api/Venue/v1/getlist/35

export const placesStore30=
[
  {
    "venueId": 3641,
    "venueCategoryId": 0,
    "status": 1,
    "title": "SO Sotogrande Spa & Golf Resort",
    "description": "At the westernmost point of the Costa del Sol in sunny, southern Spain, where world-class golf courses roll down to the Mediterranean Sea, SO/ Sotogrande SPA & Golf Resort awaits your arrival. Hidden away in the Andalusian countryside, live life the Spanish way for a family holiday, golf weekend or spa break at this stylish 5-star lifestyle and wellness retreat.",
    "brand": "",
    "blockQuote": "For a taste of the finest Andalusian cuisine, book the gastronomic experience at Cortijo Santa María 1962 Restaurant.",
    "emailAddress": "sotogrande@sotogrande.com.",
    "website": "so-hotels.com/en/sotogrande/",
    "bookingURL": "https://all.accor.com/ssr/app/accor/rates/B6V6/index.en.shtml?dateIn=2023-06-27&nights=1&compositions=2&stayplus=false&snu=false&accessibleRooms=false&partner_id=sohotels&utm_campaign=hotel_website_search&_gl=1%2a1jmp34q%2a_ga%2aMTA4NTgyMTE1Ni4xNjg3ODQ5MzA0%2a_ga_1BN0BBK71F%2aMTY4Nzg0OTMwMy4xLjEuMTY4Nzg1MDcwMy4wLjAuMA..&utm_medium=accor_regional_websites&utm_source=hotelwebsite_B331",
    "redirectNotes": "SO Sotogrande Spa & Golf Resort's reservations system",
    "phoneNumber": "+34 956 58 20 00",
    "addressOne": "s/n",
    "addressTwo": "Avenida Almenara",
    "city": "Sotogrande",
    "county": "",
    "region": "",
    "country": "Spain",
    "rooms": 152,
    "dollar": 2,
    "updated": "2023-08-05T10:57:56.42",
    "created": "2023-06-27T07:03:34.523",
    "medias": [
      {
        "mediaId": 58331,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/08c38db2-35c7-45f9-9755-59f15d85d064/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/08c38db2-35c7-45f9-9755-59f15d85d064/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/08c38db2-35c7-45f9-9755-59f15d85d064/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 58326,
        "title": "SO/ Sotogrande︱5-star cortijo hotel in Andalusia, Spain︱Spa ...",
        "url": "https://pkslist.azureedge.net/media/images/1/6a0330fc-edfa-4480-8f81-df5dbab76889/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6a0330fc-edfa-4480-8f81-df5dbab76889/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6a0330fc-edfa-4480-8f81-df5dbab76889/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 58327,
        "title": "SO Sotogrande Luxury Hotel in Sotogrande - LCAH - ALL",
        "url": "https://pkslist.azureedge.net/media/images/1/dd5826c9-0932-4a52-8505-3298fcb97df0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/dd5826c9-0932-4a52-8505-3298fcb97df0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/dd5826c9-0932-4a52-8505-3298fcb97df0/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 58318,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/7c957126-12df-4a32-bd56-797fc6ed6a29/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7c957126-12df-4a32-bd56-797fc6ed6a29/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7c957126-12df-4a32-bd56-797fc6ed6a29/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 58323,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/5d890875-c28f-4800-9fc8-e2eecafd06fb/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5d890875-c28f-4800-9fc8-e2eecafd06fb/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5d890875-c28f-4800-9fc8-e2eecafd06fb/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 58319,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/dbbca4d7-ce31-4137-a7c6-b4dbe288a8fe/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/dbbca4d7-ce31-4137-a7c6-b4dbe288a8fe/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/dbbca4d7-ce31-4137-a7c6-b4dbe288a8fe/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 58321,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/6613a53e-51d6-49db-922b-6bd8c92a26d5/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6613a53e-51d6-49db-922b-6bd8c92a26d5/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6613a53e-51d6-49db-922b-6bd8c92a26d5/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 58324,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/9d2ac78f-5716-4283-ab54-bb8a0a276153/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9d2ac78f-5716-4283-ab54-bb8a0a276153/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9d2ac78f-5716-4283-ab54-bb8a0a276153/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 58320,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/1c5ba1a8-5f64-4322-8144-ec6b8e52c93c/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1c5ba1a8-5f64-4322-8144-ec6b8e52c93c/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1c5ba1a8-5f64-4322-8144-ec6b8e52c93c/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 58322,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ab730cda-56d3-4289-af3f-b08ac8ee11ce/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ab730cda-56d3-4289-af3f-b08ac8ee11ce/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ab730cda-56d3-4289-af3f-b08ac8ee11ce/256x192.jpeg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3425,
      "latitude": 36.275499,
      "longitude": -5.352199799999999,
      "parentGeographicAreaId": 0,
      "title": "Av. Almenara, s/n, 11360 Sotogrande, Cádiz, Spain",
      "updated": null,
      "created": "2023-08-05T10:58:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 58,
        "tagName": "Beach",
        "tagType": 2
      },
      {
        "tagId": 81,
        "tagName": "Golf",
        "tagType": 2
      },
      {
        "tagId": 82,
        "tagName": "Horseback Riding",
        "tagType": 2
      },
      {
        "tagId": 83,
        "tagName": "Kid's Club",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 276,
        "tagName": "Gym",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 424,
        "tagName": "Wellness Retreat",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 815,
        "venueCategoryId": 30,
        "venueId": 3641,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 30,
          "title": "Fairway Dreams",
          "region": "Global",
          "viewOrder": 5,
          "updated": "2020-05-29T09:35:00",
          "created": "2020-05-29T09:35:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3479,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Monte Rei Golf & Country Club",
    "description": "A choice of beautiful villas are available for short term rentals, making the ideal accommodation for families or friends who want to enjoy a world-class resort in a unique Algarvean setting. \n\nThe villas offer all the comforts of a private home, whilst still providing guests with the services expected within a luxury hotel. At the heart of the village is Veranda, offering a restaurant and bar, swimming, tennis, gymnasium and spa facilities. Guests staying at Monte Rei will enjoy personalised service and privileged access to all of the resort’s facilities and state-of-the-art amenities – including fine dining at Vistas Restaurant, and our Jack Nicklaus Signature Golf Course, recognised as the number one course in Portugal.",
    "brand": "",
    "blockQuote": "Boasting Portugal's top golf course plus luxurious accommodation and exceptional dining.",
    "emailAddress": "reservas@monterei.com",
    "website": "monte-rei.com/",
    "bookingURL": "https://secure.guestcentric.net/api/bg/book.php?apikey=5067febe37bfca7d209d24516d0aa600&s=default&l=en",
    "redirectNotes": "Monte Rei Golf & Country Club's reservations system",
    "phoneNumber": "+351 281 950 950",
    "addressOne": "",
    "addressTwo": "",
    "city": "Vila Nova de Cacela",
    "county": "",
    "region": "",
    "country": "Portugal",
    "rooms": 16,
    "dollar": 2,
    "updated": "2022-10-12T16:08:46.467",
    "created": "2022-10-12T15:50:39.91",
    "medias": [
      {
        "mediaId": 55152,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/6d7d9f0b-0bde-4e2c-ae2f-8aa6d93ff2f1/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6d7d9f0b-0bde-4e2c-ae2f-8aa6d93ff2f1/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6d7d9f0b-0bde-4e2c-ae2f-8aa6d93ff2f1/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 55147,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/71e22ba1-dd77-46db-8f1b-677f0d1b6b38/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/71e22ba1-dd77-46db-8f1b-677f0d1b6b38/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/71e22ba1-dd77-46db-8f1b-677f0d1b6b38/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 55153,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/6d5c551d-dda3-4c5a-aa1e-c01c82d66dbd/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6d5c551d-dda3-4c5a-aa1e-c01c82d66dbd/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6d5c551d-dda3-4c5a-aa1e-c01c82d66dbd/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 55151,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/2ebd1ecd-34b7-4f7c-af79-0eb140eb84bd/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2ebd1ecd-34b7-4f7c-af79-0eb140eb84bd/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2ebd1ecd-34b7-4f7c-af79-0eb140eb84bd/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 55148,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/1a53b9b8-4279-4a9e-aae4-777fd52d233f/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1a53b9b8-4279-4a9e-aae4-777fd52d233f/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1a53b9b8-4279-4a9e-aae4-777fd52d233f/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 55155,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/cf23380d-5dd9-4620-b3dd-d8780807ba0c/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cf23380d-5dd9-4620-b3dd-d8780807ba0c/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cf23380d-5dd9-4620-b3dd-d8780807ba0c/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 55149,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/7c1d8c4a-3d69-4181-b0d9-1d268768e901/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7c1d8c4a-3d69-4181-b0d9-1d268768e901/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7c1d8c4a-3d69-4181-b0d9-1d268768e901/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 55154,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ddbbf378-23d0-4585-a7c4-3a4bb7544ce8/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ddbbf378-23d0-4585-a7c4-3a4bb7544ce8/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ddbbf378-23d0-4585-a7c4-3a4bb7544ce8/256x192.jpeg",
        "viewOrder": 9
      },
      {
        "mediaId": 55156,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/69412f7c-bb46-4f77-8450-29542155150f/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/69412f7c-bb46-4f77-8450-29542155150f/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/69412f7c-bb46-4f77-8450-29542155150f/256x192.jpeg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3267,
      "latitude": 37.20854610000001,
      "longitude": -7.549181600000002,
      "parentGeographicAreaId": 0,
      "title": "Sitio Do Pocinho - Sesmarias, 8901-907 Vila Nova de Cacela, Portugal",
      "updated": null,
      "created": "2022-10-12T15:59:00"
    },
    "personalities": [
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 58,
        "tagName": "Beach",
        "tagType": 2
      },
      {
        "tagId": 81,
        "tagName": "Golf",
        "tagType": 2
      },
      {
        "tagId": 82,
        "tagName": "Horseback Riding",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 164,
        "tagName": "Excursions",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 222,
        "tagName": "Michelin Star dining",
        "tagType": 2
      },
      {
        "tagId": 266,
        "tagName": "Nature Walk",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 793,
        "venueCategoryId": 30,
        "venueId": 3479,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 30,
          "title": "Fairway Dreams",
          "region": "Global",
          "viewOrder": 5,
          "updated": "2020-05-29T09:35:00",
          "created": "2020-05-29T09:35:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3189,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Hotel Royal Evian Resort",
    "description": "The jewel of the Evian Resort, the Hôtel Royal is one of the most unique and luxurious hotels in the world. Nestling in 47-acre private wooded grounds, the 5-star Hotel, who got the Palace status in 2016, overlooks Evian-les-Bains, affording breathtaking views of Lake Geneva and the French and Swiss Alps in the centre of Europe. The Hôtel Royal is a rare example of French-style luxury offering 150 rooms including 32 exceptional suites.\n\nGuest accommodation features lithographs, family photographs and a period desk. Over 1,500 carefully selected works of graphic and photographic art by leading artists make up the Hôtel Royal’s collection. From the 6th floor suites, the panoramic views of Lake Geneva and the French and Swiss Alps are breathtaking. Attention to detail and the ultimate in refinement are present throughout.",
    "brand": "",
    "blockQuote": "Evian Resort Golf Club has a Champions Course with 18 holes and The Lake Course with 6 holes.",
    "emailAddress": "reservation@evianresort.com",
    "website": "hotel-royal-evian.com/en/home/",
    "bookingURL": "https://www.hotel-royal-evian.com/en/booking/",
    "redirectNotes": "Hotel Royal Evian Resort's reservations system",
    "phoneNumber": "+33 4 50 26 50 50",
    "addressOne": "960",
    "addressTwo": "Avenue du Léman",
    "city": "Neuvecelle",
    "county": "",
    "region": "",
    "country": "France",
    "rooms": 150,
    "dollar": 2,
    "updated": "2021-07-08T14:43:04.263",
    "created": "2021-07-08T14:17:39.027",
    "medias": [
      {
        "mediaId": 48568,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c06c0cc8-f717-4b5b-99ea-3a4a7a549dd9/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c06c0cc8-f717-4b5b-99ea-3a4a7a549dd9/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c06c0cc8-f717-4b5b-99ea-3a4a7a549dd9/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 48575,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/7e812a5f-21bf-48d4-8296-8fcd62bcfe13/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7e812a5f-21bf-48d4-8296-8fcd62bcfe13/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7e812a5f-21bf-48d4-8296-8fcd62bcfe13/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 48574,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/0306c56f-87c8-456b-8ec8-ae19cf0fc867/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0306c56f-87c8-456b-8ec8-ae19cf0fc867/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0306c56f-87c8-456b-8ec8-ae19cf0fc867/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 48571,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/830bf1f3-7972-4101-9851-015e2031e57f/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/830bf1f3-7972-4101-9851-015e2031e57f/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/830bf1f3-7972-4101-9851-015e2031e57f/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 48570,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d049f1b1-9bc0-45a5-962a-3963298539a4/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d049f1b1-9bc0-45a5-962a-3963298539a4/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d049f1b1-9bc0-45a5-962a-3963298539a4/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 48572,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/2bb6e8d7-49bd-4276-b10f-913b54d4eaaa/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2bb6e8d7-49bd-4276-b10f-913b54d4eaaa/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2bb6e8d7-49bd-4276-b10f-913b54d4eaaa/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 48579,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ee14fa55-6ae4-4e6d-93d5-6f267cda3fc1/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ee14fa55-6ae4-4e6d-93d5-6f267cda3fc1/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ee14fa55-6ae4-4e6d-93d5-6f267cda3fc1/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 48582,
        "title": "→ Hotel Royal Evian Resort | Photo gallery | 5-star hotel Evian Leman",
        "url": "https://pkslist.azureedge.net/media/images/1/53ad7b82-18d0-4811-bd8a-30e52aef6360/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/53ad7b82-18d0-4811-bd8a-30e52aef6360/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/53ad7b82-18d0-4811-bd8a-30e52aef6360/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 48577,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/77f03544-b6d8-4573-b559-0ba644cc9589/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/77f03544-b6d8-4573-b559-0ba644cc9589/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/77f03544-b6d8-4573-b559-0ba644cc9589/256x192.jpeg",
        "viewOrder": 11
      },
      {
        "mediaId": 48578,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/05cb07c1-5b70-49a1-8ab8-7b4b2d15dea0/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/05cb07c1-5b70-49a1-8ab8-7b4b2d15dea0/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/05cb07c1-5b70-49a1-8ab8-7b4b2d15dea0/256x192.jpeg",
        "viewOrder": 12
      },
      {
        "mediaId": 48580,
        "title": "5-star Hotel ROYAL - Luxury and Palace in EVIAN (France, Europe)",
        "url": "https://pkslist.azureedge.net/media/images/1/93a75ab3-cd4e-495e-a337-195393ff5dc0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/93a75ab3-cd4e-495e-a337-195393ff5dc0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/93a75ab3-cd4e-495e-a337-195393ff5dc0/256x192.jpg",
        "viewOrder": 13
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2977,
      "latitude": 46.39680509999999,
      "longitude": 6.594026099999999,
      "parentGeographicAreaId": 0,
      "title": "960 Avenue du Léman, 74500 Neuvecelle, France",
      "updated": null,
      "created": "2021-07-08T14:33:00"
    },
    "personalities": [
      {
        "tagId": 44,
        "tagName": "Grand Dame ",
        "tagType": 1
      },
      {
        "tagId": 48,
        "tagName": "Iconic/Landmark ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 74,
        "tagName": "Casino",
        "tagType": 2
      },
      {
        "tagId": 81,
        "tagName": "Golf",
        "tagType": 2
      },
      {
        "tagId": 83,
        "tagName": "Kid's Club",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 88,
        "tagName": "Tennis",
        "tagType": 2
      },
      {
        "tagId": 127,
        "tagName": "Hair Salon",
        "tagType": 2
      },
      {
        "tagId": 222,
        "tagName": "Michelin Star dining",
        "tagType": 2
      },
      {
        "tagId": 231,
        "tagName": "Concerts",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 735,
        "venueCategoryId": 30,
        "venueId": 3189,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 30,
          "title": "Fairway Dreams",
          "region": "Global",
          "viewOrder": 5,
          "updated": "2020-05-29T09:35:00",
          "created": "2020-05-29T09:35:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2757,
    "venueCategoryId": 0,
    "status": 1,
    "title": "The Inn at Spanish Bay",
    "description": "Nestled among majestic Monterey pines—and fronted by a Scottish-style links golf course that touches some of the most breathtaking coastline in the world—The Inn at Spanish Bay is an idyllic retreat.\n\nAll guest rooms feature stylish and comfortable furnishings and a warm, cozy fireplace. Most rooms include a private patio or balcony, providing magnificent views of landscaped grounds, pine forests, rolling fairways or the Pacific Ocean. Each room also features complimentary Wi-Fi, flat-screen TVs with cable and lavish modern bathrooms loaded with signature amenities to enhance your stay.\n\nWhile at The Inn, dine at your choice of fabulous restaurants onsite. Stroll along our seaside boardwalk and discover hidden coves and sandy beaches. Play at any of our renowned golf courses, pamper yourself at our resort’s award-winning Spa and enjoy access to our private members-only Spanish Bay Club.",
    "brand": "",
    "blockQuote": "Make use of your complimentary access to the members-only Spanish Bay Club, Beach Club and Tennis Club.",
    "emailAddress": "email@pebblebeach.com",
    "website": "https://www.pebblebeach.com/accommodations/the-inn-at-spanish-bay/",
    "bookingURL": "https://www.pebblebeach.com/plan-my-trip/select-accommodations/task/step1/fromdate/2020-06-02/todate/2020-06-04/norooms/1/nogolfers/0/",
    "redirectNotes": null,
    "phoneNumber": "+1 866 681 8171",
    "addressOne": "2700",
    "addressTwo": "17 Mile Drive",
    "city": "Pebble Beach",
    "county": "",
    "region": "California",
    "country": "United States",
    "rooms": 269,
    "dollar": 3,
    "updated": "2020-05-31T15:21:51.187",
    "created": "2020-05-29T13:37:44.007",
    "medias": [
      {
        "mediaId": 37963,
        "title": "The Inn at Spanish Bay LEGEND in Pebble Beach, Del Monte Forest ...",
        "url": "https://pkslist.azureedge.net/media/images/1/d714df12-266d-48da-a6c1-c1b5943f0e43/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d714df12-266d-48da-a6c1-c1b5943f0e43/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d714df12-266d-48da-a6c1-c1b5943f0e43/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 37962,
        "title": "The Inn at Spanish Bay | Associated Luxury Hotels International",
        "url": "https://pkslist.azureedge.net/media/images/1/92156eb6-9b60-4f6b-81b0-ee46eaa32b77/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/92156eb6-9b60-4f6b-81b0-ee46eaa32b77/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/92156eb6-9b60-4f6b-81b0-ee46eaa32b77/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 37961,
        "title": "The Inn at Spanish Bay | Associated Luxury Hotels International",
        "url": "https://pkslist.azureedge.net/media/images/1/03c2db0f-da45-48bf-a55a-425fbc808144/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/03c2db0f-da45-48bf-a55a-425fbc808144/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/03c2db0f-da45-48bf-a55a-425fbc808144/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 37957,
        "title": "Spanish Bay",
        "url": "https://pkslist.azureedge.net/media/images/1/9dccbb44-8ad6-4c4f-bc6e-0fb730bcfbcd/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9dccbb44-8ad6-4c4f-bc6e-0fb730bcfbcd/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9dccbb44-8ad6-4c4f-bc6e-0fb730bcfbcd/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 37970,
        "title": "Accommodations | AT&T Pebble Beach Pro-Am",
        "url": "https://pkslist.azureedge.net/media/images/1/8f1779ce-d96d-4fc3-bbf4-7214f2a96f5c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8f1779ce-d96d-4fc3-bbf4-7214f2a96f5c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8f1779ce-d96d-4fc3-bbf4-7214f2a96f5c/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 37972,
        "title": "The Inn at Spanish Bay | Associated Luxury Hotels International",
        "url": "https://pkslist.azureedge.net/media/images/1/cf1c0924-0211-43fa-b305-e6ba42587220/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cf1c0924-0211-43fa-b305-e6ba42587220/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cf1c0924-0211-43fa-b305-e6ba42587220/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 37964,
        "title": "The Inn at Spanish Bay | Pebble Beach Resorts",
        "url": "https://pkslist.azureedge.net/media/images/1/fd0e402e-e5f3-412d-b307-bd007896ee90/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fd0e402e-e5f3-412d-b307-bd007896ee90/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fd0e402e-e5f3-412d-b307-bd007896ee90/256x192.jpg",
        "viewOrder": 6
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2543,
      "latitude": 36.6121628,
      "longitude": -121.9424654,
      "parentGeographicAreaId": 0,
      "title": "2700 17 Mile Dr, Pebble Beach, CA 93953, USA",
      "updated": null,
      "created": "2020-05-29T13:52:00"
    },
    "personalities": [
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      },
      {
        "tagId": 57,
        "tagName": "Romantic",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 73,
        "tagName": "Outdoor Terraces",
        "tagType": 2
      },
      {
        "tagId": 81,
        "tagName": "Golf",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 88,
        "tagName": "Tennis",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 156,
        "tagName": "Fireplace",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 219,
        "tagName": "Wine Cellar",
        "tagType": 2
      },
      {
        "tagId": 270,
        "tagName": "Beach Club",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 632,
        "venueCategoryId": 30,
        "venueId": 2757,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 30,
          "title": "Fairway Dreams",
          "region": "Global",
          "viewOrder": 5,
          "updated": "2020-05-29T09:35:00",
          "created": "2020-05-29T09:35:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2529,
    "venueCategoryId": 0,
    "status": 1,
    "title": "The K Club",
    "description": "The K Club Hotel & Resort in Kildare is Ireland’s first ever AA 5 Red Star Hotel. Our luxury resort hotel is located only 30 minutes from Dublin in the beautiful County of Kildare where the River Liffey meanders through the soft green countryside.\n\nSteeped in history, the 5 Star K Club hotel and resort combines old world elegance with indulgent luxury so our guests are treated to luxurious rooms and incredible dining experiences. Our guests can also opt to unwind in our oasis of calm at the internationally acclaimed K Spa or experience playing golf on a Ryder Cup course. Over the past 25 years we’ve firmly established our resort as the ultimate country escape for discerning guests and their families.",
    "brand": null,
    "blockQuote": "Attention Golf Lovers: where else can you play on two Arnold Palmer designed championship courses? - the Ryder Cup Course and the Smurfit Course.",
    "emailAddress": "sales@kclub.ie",
    "website": "kclub.ie/",
    "bookingURL": "https://be.synxis.com/?adult=2&agencyid=96027890&arrive=2019-08-23&chain=10237&child=0&currency=EUR&depart=2019-08-24&hotel=27233&level=hotel&locale=en-US&rooms=1",
    "redirectNotes": "The K Club's reservations system",
    "phoneNumber": "+353 1 6017 200",
    "addressOne": "",
    "addressTwo": "",
    "city": "Straffan",
    "county": "",
    "region": "",
    "country": "Ireland",
    "rooms": 134,
    "dollar": 1,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 33218,
        "title": "Prestigious K Club Hotel And Golf Resort On The Market For €80 Million",
        "url": "https://pkslist.azureedge.net/media/images/1/f052fcac-24c8-4466-9018-5be19d1d11d0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f052fcac-24c8-4466-9018-5be19d1d11d0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f052fcac-24c8-4466-9018-5be19d1d11d0/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 33232,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/960ed0b5-bbf9-416f-9222-6925c9bfc4ff/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/960ed0b5-bbf9-416f-9222-6925c9bfc4ff/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/960ed0b5-bbf9-416f-9222-6925c9bfc4ff/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 33228,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c98aa321-4986-4954-83c6-8d6dc7f1a6e9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c98aa321-4986-4954-83c6-8d6dc7f1a6e9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c98aa321-4986-4954-83c6-8d6dc7f1a6e9/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 33231,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ddc6f205-8a57-4b54-bb1d-310bfd38f2c5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ddc6f205-8a57-4b54-bb1d-310bfd38f2c5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ddc6f205-8a57-4b54-bb1d-310bfd38f2c5/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 33227,
        "title": "Kildare Hotel at the K Club Ireland",
        "url": "https://pkslist.azureedge.net/media/images/1/2b59bb29-4da4-48bb-957f-8c55c5d5e3d7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2b59bb29-4da4-48bb-957f-8c55c5d5e3d7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2b59bb29-4da4-48bb-957f-8c55c5d5e3d7/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 33234,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c3397717-5396-46b6-8f31-d929a352f422/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c3397717-5396-46b6-8f31-d929a352f422/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c3397717-5396-46b6-8f31-d929a352f422/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 33233,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/8fda961c-f32a-4740-8763-462f75f3eb96/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8fda961c-f32a-4740-8763-462f75f3eb96/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8fda961c-f32a-4740-8763-462f75f3eb96/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 33229,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/82e042a3-4973-42f5-b873-443b51234b72/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/82e042a3-4973-42f5-b873-443b51234b72/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/82e042a3-4973-42f5-b873-443b51234b72/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 33225,
        "title": "The K Club Hotel & Resort, book the best golf break in Southern ...",
        "url": "https://pkslist.azureedge.net/media/images/1/11bede38-baf2-4570-bf3f-52d3abba6c24/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/11bede38-baf2-4570-bf3f-52d3abba6c24/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/11bede38-baf2-4570-bf3f-52d3abba6c24/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 33223,
        "title": "The-K-Club | Epic Golf Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/ae242d83-3bed-494b-ab3c-f01e5081181a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ae242d83-3bed-494b-ab3c-f01e5081181a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ae242d83-3bed-494b-ab3c-f01e5081181a/256x192.jpg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2314,
      "latitude": 53.3065509,
      "longitude": -6.625254700000028,
      "parentGeographicAreaId": 0,
      "title": "Straffan, Co. Kildare, Ireland",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 42,
        "tagName": "Classic Traditionalist ",
        "tagType": 1
      },
      {
        "tagId": 45,
        "tagName": "Historic Sense",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 31,
        "tagName": "Countryside",
        "tagType": 2
      },
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 81,
        "tagName": "Golf",
        "tagType": 2
      },
      {
        "tagId": 82,
        "tagName": "Horseback Riding",
        "tagType": 2
      },
      {
        "tagId": 83,
        "tagName": "Kid's Club",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 88,
        "tagName": "Tennis",
        "tagType": 2
      },
      {
        "tagId": 103,
        "tagName": "Clay Pigeon Shooting",
        "tagType": 2
      },
      {
        "tagId": 109,
        "tagName": "Fishing",
        "tagType": 2
      },
      {
        "tagId": 127,
        "tagName": "Hair Salon",
        "tagType": 2
      },
      {
        "tagId": 235,
        "tagName": "Hot Tub",
        "tagType": 2
      },
      {
        "tagId": 273,
        "tagName": "Archery",
        "tagType": 2
      },
      {
        "tagId": 276,
        "tagName": "Gym",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 595,
        "venueCategoryId": 30,
        "venueId": 2529,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 30,
          "title": "Fairway Dreams",
          "region": "Global",
          "viewOrder": 5,
          "updated": "2020-05-29T09:35:00",
          "created": "2020-05-29T09:35:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2451,
    "venueCategoryId": 0,
    "status": 1,
    "title": "The Lodge at Sea Island Golf Club",
    "description": "From your balcony at The Lodge, you’ll overlook the outstretched sea, oceanfront pool, savor the smell of s’mores around a crackling bonfire, and delight in milk and cookies served right before bed. Enjoy 24-hour butler service and even a luxurious rose-petal bath, drawn just to your liking. And access to all that Sea Island has to offer, all within arm’s reach of championship golf courses and our many other activities. Against the sun setting over the water, a bagpiper puts the courses to sleep each night. A cherished signal of the end of another Sea Island day and the beginning of a fun night ahead.",
    "brand": null,
    "blockQuote": "An English-style country manor with 3 championship golf courses and plenty to do for the whole family.",
    "emailAddress": "info@seaisland.com",
    "website": "seaisland.com/accommodations/the-lodge/?utm_source=google&utm_medium=organic&utm_campaign=GMB",
    "bookingURL": "http://www.booking.com/Share-1OzHmV?aid=925669",
    "redirectNotes": null,
    "phoneNumber": "+1 855 572 4975",
    "addressOne": "100",
    "addressTwo": "Retreat Avenue",
    "city": "St. Simons Island",
    "county": "",
    "region": "Georgia",
    "country": "United States",
    "rooms": 49,
    "dollar": 2,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 31737,
        "title": "The Lodge | Forbes Five-Star Georgia Luxury Resort | Sea Island",
        "url": "https://pkslist.azureedge.net/media/images/1/44698000-722a-44bd-8090-087ab254f4c5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/44698000-722a-44bd-8090-087ab254f4c5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/44698000-722a-44bd-8090-087ab254f4c5/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 31746,
        "title": "Lodge at Sea Island Golf Club | Travel + Leisure",
        "url": "https://pkslist.azureedge.net/media/images/1/b606a061-c9f6-4129-981b-1e1c0f7c7420/1200x900.jpg?itok=dL9Gl-IT",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b606a061-c9f6-4129-981b-1e1c0f7c7420/800x600.jpg?itok=dL9Gl-IT",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b606a061-c9f6-4129-981b-1e1c0f7c7420/256x192.jpg?itok=dL9Gl-IT",
        "viewOrder": 1
      },
      {
        "mediaId": 31741,
        "title": "The Lodge at Sea Island | Luxury Hotel in Golden Isles Georgia",
        "url": "https://pkslist.azureedge.net/media/images/1/972df11e-c518-491f-a8fd-f479a58863e0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/972df11e-c518-491f-a8fd-f479a58863e0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/972df11e-c518-491f-a8fd-f479a58863e0/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 31745,
        "title": "The Lodge at Sea Island | Luxury Hotel in Golden Isles Georgia",
        "url": "https://pkslist.azureedge.net/media/images/1/5af4b9e9-43c5-4667-abed-12fdfb0415a8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5af4b9e9-43c5-4667-abed-12fdfb0415a8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5af4b9e9-43c5-4667-abed-12fdfb0415a8/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 31742,
        "title": "The Lodge at Sea Island | Luxury Hotel in Golden Isles Georgia",
        "url": "https://pkslist.azureedge.net/media/images/1/e03ca4ef-3978-4f67-a41d-e61d4989b71c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e03ca4ef-3978-4f67-a41d-e61d4989b71c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e03ca4ef-3978-4f67-a41d-e61d4989b71c/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 31747,
        "title": "The Lodge at Sea Island | Architectural Design & Planning Group",
        "url": "https://pkslist.azureedge.net/media/images/1/8e9e35fa-75bf-4af3-b36c-789978a3a387/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8e9e35fa-75bf-4af3-b36c-789978a3a387/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8e9e35fa-75bf-4af3-b36c-789978a3a387/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 31740,
        "title": "The Lodge at Sea Island, Saint Simons Island, GA - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/f90c7d9f-fab8-4871-ae00-e8863498912e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f90c7d9f-fab8-4871-ae00-e8863498912e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f90c7d9f-fab8-4871-ae00-e8863498912e/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 31749,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/1c329dbf-ec93-42c0-80e7-f26b8ae04408/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1c329dbf-ec93-42c0-80e7-f26b8ae04408/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1c329dbf-ec93-42c0-80e7-f26b8ae04408/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 31744,
        "title": "The Lodge at Sea Island Golf Club, St Simons Island, Georgia - For...",
        "url": "https://pkslist.azureedge.net/media/images/1/cd5326c3-a36a-4cc2-83bb-a688ff637fd7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cd5326c3-a36a-4cc2-83bb-a688ff637fd7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cd5326c3-a36a-4cc2-83bb-a688ff637fd7/256x192.jpg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2234,
      "latitude": 31.138482,
      "longitude": -81.40575899999999,
      "parentGeographicAreaId": 0,
      "title": "100 Retreat Ave, St Simons, GA 31522, USA",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 81,
        "tagName": "Golf",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 88,
        "tagName": "Tennis",
        "tagType": 2
      },
      {
        "tagId": 100,
        "tagName": "Wedding",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 219,
        "tagName": "Wine Cellar",
        "tagType": 2
      },
      {
        "tagId": 235,
        "tagName": "Hot Tub",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 590,
        "venueCategoryId": 30,
        "venueId": 2451,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 30,
          "title": "Fairway Dreams",
          "region": "Global",
          "viewOrder": 5,
          "updated": "2020-05-29T09:35:00",
          "created": "2020-05-29T09:35:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1974,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Sandy Lane",
    "description": "Elegant and exclusive, Sandy Lane is set in an ancient mahogany grove overlooking a 1000 foot crescent of gorgeous white sand beach. Accommodations include 112 rooms and suites as well as a stunning 5-bedroom villa. With a magnificent 47,000-square-foot spa, four restaurants, six bars, nine floodlit tennis courses, beachside service, 45 holes of championship golf and The Treehouse Club facility for kids and teens, Sandy Lane will cater to your every need.",
    "brand": "",
    "blockQuote": "With three golf courses; The Old Nine, The Country Club and the world-renowned Green Monkey, it's a golf and beach lover's dream.",
    "emailAddress": "reservations@sandylane.com",
    "website": "sandylane.com/",
    "bookingURL": "https://be.synxis.com/?adult=1&agencyid=96027890&arrive=2020-10-12&chain=24447&child=0&currency=USD&depart=2020-10-14&hotel=6856&level=hotel&locale=en-US&rooms=1&src=30",
    "redirectNotes": "Sandy Lane's reservations system",
    "phoneNumber": "+1 246 444 2000 ",
    "addressOne": "",
    "addressTwo": "",
    "city": "St. James",
    "county": "",
    "region": "Barbados",
    "country": "Caribbean",
    "rooms": 113,
    "dollar": 3,
    "updated": "2020-10-12T16:52:48.167",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 41095,
        "title": "Barbados' Sandy Lane Resort to Reopen in November",
        "url": "https://pkslist.azureedge.net/media/images/1/5e6850dd-f0b5-42a2-a775-8c005d3f051e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5e6850dd-f0b5-42a2-a775-8c005d3f051e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5e6850dd-f0b5-42a2-a775-8c005d3f051e/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 41081,
        "title": "Sandy Lane Hotel Review, Barbados | Telegraph Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/5ffb91c5-fff9-4377-ae44-1dacd9c946af/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5ffb91c5-fff9-4377-ae44-1dacd9c946af/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5ffb91c5-fff9-4377-ae44-1dacd9c946af/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 41083,
        "title": "Wedding & Event Venue | Photo Gallery | Sandy Lane Barbados",
        "url": "https://pkslist.azureedge.net/media/images/1/cc378ce8-81d0-4484-8bfa-b01c56496145/1200x900.jpg&maxsidesize=1600",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cc378ce8-81d0-4484-8bfa-b01c56496145/800x600.jpg&maxsidesize=1600",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cc378ce8-81d0-4484-8bfa-b01c56496145/256x192.jpg&maxsidesize=1600",
        "viewOrder": 2
      },
      {
        "mediaId": 41085,
        "title": "Sandy Lane | Luxury Barbados Resorts | Caribbean Holidays",
        "url": "https://pkslist.azureedge.net/media/images/1/c087e565-5478-4fd8-a466-a623c0f7ce1a/1200x900.jpg&maxsidesize=1600",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c087e565-5478-4fd8-a466-a623c0f7ce1a/800x600.jpg&maxsidesize=1600",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c087e565-5478-4fd8-a466-a623c0f7ce1a/256x192.jpg&maxsidesize=1600",
        "viewOrder": 3
      },
      {
        "mediaId": 41093,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/58927eef-924c-4709-8f05-a99882781934/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/58927eef-924c-4709-8f05-a99882781934/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/58927eef-924c-4709-8f05-a99882781934/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 41096,
        "title": "Windward - Sandy Lane | Barbados Villa Rental | WhereToStay.com",
        "url": "https://pkslist.azureedge.net/media/images/1/4f6dfa75-b3c3-498b-ba9a-7ed76066de9d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4f6dfa75-b3c3-498b-ba9a-7ed76066de9d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4f6dfa75-b3c3-498b-ba9a-7ed76066de9d/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 24235,
        "title": "Sandy Lane Hotel Review, Barbados | Telegraph Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/cd0180e5-3f97-4a0e-8a74-2be6a6c7a691/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cd0180e5-3f97-4a0e-8a74-2be6a6c7a691/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cd0180e5-3f97-4a0e-8a74-2be6a6c7a691/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 41084,
        "title": "Sandy Lane | Luxury Barbados Resorts | Caribbean Holidays",
        "url": "https://pkslist.azureedge.net/media/images/1/0336b4c8-284c-437b-b0cc-c8206735df5c/1200x900.jpg&maxsidesize=1600",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0336b4c8-284c-437b-b0cc-c8206735df5c/800x600.jpg&maxsidesize=1600",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0336b4c8-284c-437b-b0cc-c8206735df5c/256x192.jpg&maxsidesize=1600",
        "viewOrder": 7
      },
      {
        "mediaId": 24238,
        "title": "5 Star Luxury Caribbean Accommodations | Photo Gallery | Sandy Lane",
        "url": "https://pkslist.azureedge.net/media/images/1/5a39f19f-bab1-4c82-bf13-43cdd08a2b25/1200x900.jpg&maxsidesize=1600",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5a39f19f-bab1-4c82-bf13-43cdd08a2b25/800x600.jpg&maxsidesize=1600",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5a39f19f-bab1-4c82-bf13-43cdd08a2b25/256x192.jpg&maxsidesize=1600",
        "viewOrder": 8
      },
      {
        "mediaId": 41094,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b36d6515-3f32-436e-8761-4f7b2454a034/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b36d6515-3f32-436e-8761-4f7b2454a034/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b36d6515-3f32-436e-8761-4f7b2454a034/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 41086,
        "title": "One Sandy Lane – Chestertons Barbados",
        "url": "https://pkslist.azureedge.net/media/images/1/888d8e4e-b126-4c7b-a2d2-9c73e99e7d83/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/888d8e4e-b126-4c7b-a2d2-9c73e99e7d83/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/888d8e4e-b126-4c7b-a2d2-9c73e99e7d83/256x192.jpg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1784,
      "latitude": 13.173994,
      "longitude": -59.637013000000024,
      "parentGeographicAreaId": 0,
      "title": "Sandy Lane Hotel, Holetown BB24024, Barbados",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 157,
        "tagName": "Beach Escape",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 58,
        "tagName": "Beach",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 81,
        "tagName": "Golf",
        "tagType": 2
      },
      {
        "tagId": 83,
        "tagName": "Kid's Club",
        "tagType": 2
      },
      {
        "tagId": 88,
        "tagName": "Tennis",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 100,
        "tagName": "Wedding",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 334,
        "tagName": "Heated Pool",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 690,
        "venueCategoryId": 30,
        "venueId": 1974,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 30,
          "title": "Fairway Dreams",
          "region": "Global",
          "viewOrder": 5,
          "updated": "2020-05-29T09:35:00",
          "created": "2020-05-29T09:35:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1150,
    "venueCategoryId": 0,
    "status": 1,
    "title": "The Sanctuary at Kiawah Island Golf Resort",
    "description": "Located along the pristine shores of Kiawah Island, South Carolina, this exquisitely designed oceanfront hotel, just 21 miles from downtown Charleston, captures the spirit, history and charm of the beautiful south. Come walk through the front doors and experience a seaside mansion, where you will be surrounded by the grandeur of the architecture and hospitality at its finest. This beautiful seaside mansion is waiting to envelop you in its service and southern hospitality.\n\nThe Sanctuary offers 255 spacious rooms and suites, including the 3000 square foot Presidential Suite. Their king rooms are among the largest on the East Coast with the smallest measuring 520 square feet. All hotel rooms are uniquely designed to include such exquisite amenities as a handcrafted desk and armoire, an elegantly appointed sofa and chair, and custom made beds.",
    "brand": null,
    "blockQuote": "A seaside resort with Southern hospitality great for a multi-generation holiday.",
    "emailAddress": "reservations@kiawahresort.com",
    "website": "kiawahresort.com",
    "bookingURL": "https://kiawahresort.com/reservations/",
    "redirectNotes": null,
    "phoneNumber": "+1 843 768 2121",
    "addressOne": "1",
    "addressTwo": "Sanctuary Beach Drive",
    "city": "Kiawah Island",
    "county": "",
    "region": "South Carolina",
    "country": "United States",
    "rooms": 255,
    "dollar": 2,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 12248,
        "title": "No. 19 The Sanctuary at Kiawah Island Golf Resort Kiawah Island ...",
        "url": "https://pkslist.azureedge.net/media/images/1/51d8489e-02f8-4c85-9ed3-269fc0c78482/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/51d8489e-02f8-4c85-9ed3-269fc0c78482/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/51d8489e-02f8-4c85-9ed3-269fc0c78482/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 12237,
        "title": "South Carolina Golf Resorts | The Sanctuary at Kiawah Island Golf ...",
        "url": "https://pkslist.azureedge.net/media/images/1/a1b4b8dc-4b2b-458c-a310-3aef59fe296b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a1b4b8dc-4b2b-458c-a310-3aef59fe296b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a1b4b8dc-4b2b-458c-a310-3aef59fe296b/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 11691,
        "title": "The Sanctuary at Kiawah Island Golf Resort, Charleston, South Carolina",
        "url": "https://pkslist.azureedge.net/media/images/1/e2cd8c6a-5100-4c85-9ac1-eba511fce524/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e2cd8c6a-5100-4c85-9ac1-eba511fce524/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e2cd8c6a-5100-4c85-9ac1-eba511fce524/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 12240,
        "title": "The Sanctuary At Kiawah Island Golf Resort : Nrys.info",
        "url": "https://pkslist.azureedge.net/media/images/1/6cb6bd3d-66e1-463e-9963-c8a67da4e722/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6cb6bd3d-66e1-463e-9963-c8a67da4e722/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6cb6bd3d-66e1-463e-9963-c8a67da4e722/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 11693,
        "title": "The Sanctuary at Kiawah Island Golf Resort | Search Hotel discount ...",
        "url": "https://pkslist.azureedge.net/media/images/1/4d858a0f-2ff8-49e3-8d2d-151030a89041/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4d858a0f-2ff8-49e3-8d2d-151030a89041/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4d858a0f-2ff8-49e3-8d2d-151030a89041/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 12243,
        "title": "The Sanctuary at Kiawah Island Golf Resort | Charleston Area CVB",
        "url": "https://pkslist.azureedge.net/media/images/1/7993824a-a469-4c05-a806-026093835f84/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7993824a-a469-4c05-a806-026093835f84/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7993824a-a469-4c05-a806-026093835f84/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 12238,
        "title": "Tennis Resorts Online: Kiawah Island Golf Resort",
        "url": "https://pkslist.azureedge.net/media/images/1/c295816c-90af-4e17-b14b-041cc4a1a183/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c295816c-90af-4e17-b14b-041cc4a1a183/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c295816c-90af-4e17-b14b-041cc4a1a183/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 12245,
        "title": "Kiawah Island Golf Resort - Coastal Virginia Magazine - April 2015 ...",
        "url": "https://pkslist.azureedge.net/media/images/1/5aeac43f-8f82-4f4c-a99e-058d744b2947/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5aeac43f-8f82-4f4c-a99e-058d744b2947/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5aeac43f-8f82-4f4c-a99e-058d744b2947/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 11692,
        "title": "The Sanctuary at Kiawah Island Golf Resort | Search Hotel discount ...",
        "url": "https://pkslist.azureedge.net/media/images/1/aecc8d6f-d53d-4745-b974-cadb11abc426/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/aecc8d6f-d53d-4745-b974-cadb11abc426/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/aecc8d6f-d53d-4745-b974-cadb11abc426/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 12244,
        "title": "The Sanctuary at Kiawah Island Golf Resort | Charleston Area CVB",
        "url": "https://pkslist.azureedge.net/media/images/1/5a0e71f9-f0eb-496e-9105-ec05a3de3665/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5a0e71f9-f0eb-496e-9105-ec05a3de3665/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5a0e71f9-f0eb-496e-9105-ec05a3de3665/256x192.jpg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 974,
      "latitude": 32.603865,
      "longitude": -80.09462099999996,
      "parentGeographicAreaId": 0,
      "title": "1 Sanctuary Beach Dr, Kiawah Island, SC 29455, United States",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 42,
        "tagName": "Classic Traditionalist ",
        "tagType": 1
      },
      {
        "tagId": 55,
        "tagName": "Lively",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 58,
        "tagName": "Beach",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 81,
        "tagName": "Golf",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 88,
        "tagName": "Tennis",
        "tagType": 2
      },
      {
        "tagId": 102,
        "tagName": "Eco-friendly",
        "tagType": 2
      },
      {
        "tagId": 120,
        "tagName": "Private Dining",
        "tagType": 2
      },
      {
        "tagId": 128,
        "tagName": "Club Lounge",
        "tagType": 2
      },
      {
        "tagId": 169,
        "tagName": "Resort",
        "tagType": 2
      },
      {
        "tagId": 193,
        "tagName": "Outdoor Dining",
        "tagType": 2
      },
      {
        "tagId": 220,
        "tagName": "Adult Pool",
        "tagType": 2
      },
      {
        "tagId": 228,
        "tagName": "Courtesy Car",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 591,
        "venueCategoryId": 30,
        "venueId": 1150,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 30,
          "title": "Fairway Dreams",
          "region": "Global",
          "viewOrder": 5,
          "updated": "2020-05-29T09:35:00",
          "created": "2020-05-29T09:35:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1123,
    "venueCategoryId": 0,
    "status": 1,
    "title": "The Broadmoor",
    "description": "The Broadmoor welcomes guests to a legendary Forbes Five-Star and AAA Five-Diamond resort with impeccable service and distinctive amenities. Here, the spirit of the West inspires the adventurer within, providing endless opportunities to explore the picturesque mountains, streams, and canyons that have inspired generations. Whether you are interested in zip lining adventures and horseback riding, Colorado fly fishing, or an indulgent day at the spa, you’ll find endless activities for every age in every season. Experience an iconic Colorado Springs resort where personalized luxury offers an escape from the ordinary and modern details blend seamlessly with the timeless European elegance of this historic hotel.",
    "brand": null,
    "blockQuote": "There are 18 on-site dining options, 3 championship golf courses and a plethora of activities for everyone imaginable.",
    "emailAddress": "reservations@broadmoor.com",
    "website": "broadmoor.com",
    "bookingURL": "http://www.booking.com/Share-xNQhNt?aid=925669",
    "redirectNotes": null,
    "phoneNumber": "+1 719 623 5112",
    "addressOne": "1",
    "addressTwo": "Lake Avenue",
    "city": "Colorado Springs",
    "county": "",
    "region": "Colorado",
    "country": "United States",
    "rooms": 269,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 18301,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/2c932dcb-45d6-479a-8802-7c2519e44a88/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2c932dcb-45d6-479a-8802-7c2519e44a88/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2c932dcb-45d6-479a-8802-7c2519e44a88/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 18302,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/03f1c91c-004b-43ee-81ba-bf38d1a12aac/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/03f1c91c-004b-43ee-81ba-bf38d1a12aac/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/03f1c91c-004b-43ee-81ba-bf38d1a12aac/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 11305,
        "title": "The Broadmoor | A Luxury Colorado Springs Hotel",
        "url": "https://pkslist.azureedge.net/media/images/1/03443e53-1741-4bf6-93be-d11a76562cef/1200x900.jpg&maxsidesize=768",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/03443e53-1741-4bf6-93be-d11a76562cef/800x600.jpg&maxsidesize=768",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/03443e53-1741-4bf6-93be-d11a76562cef/256x192.jpg&maxsidesize=768",
        "viewOrder": 2
      },
      {
        "mediaId": 11310,
        "title": "Colorado Springs Luxury Resort - The Broadmoor",
        "url": "https://pkslist.azureedge.net/media/images/1/234f85ec-4014-4a57-a7c1-02552683a82a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/234f85ec-4014-4a57-a7c1-02552683a82a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/234f85ec-4014-4a57-a7c1-02552683a82a/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 11316,
        "title": "The Broadmoor Room Prices & Rates - Family Vacation Critic",
        "url": "https://pkslist.azureedge.net/media/images/1/09196a62-3eb6-4cd7-8ed7-974a80ddff4d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/09196a62-3eb6-4cd7-8ed7-974a80ddff4d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/09196a62-3eb6-4cd7-8ed7-974a80ddff4d/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 11313,
        "title": "Venue Spotlight: The Broadmoor Resort // Colorado Springs ...",
        "url": "https://pkslist.azureedge.net/media/images/1/ec880059-9b9e-4428-a08e-9c83c04b7ec2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ec880059-9b9e-4428-a08e-9c83c04b7ec2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ec880059-9b9e-4428-a08e-9c83c04b7ec2/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 18303,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/7d465a92-2fce-4a2e-b56e-b16a132224fb/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7d465a92-2fce-4a2e-b56e-b16a132224fb/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7d465a92-2fce-4a2e-b56e-b16a132224fb/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 18304,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f3382497-2fee-49ea-b9bd-329115ae3301/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f3382497-2fee-49ea-b9bd-329115ae3301/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f3382497-2fee-49ea-b9bd-329115ae3301/256x192.jpg",
        "viewOrder": 7
      }
    ],
    "geographicArea": {
      "geographicAreaId": 948,
      "latitude": 38.79104230000001,
      "longitude": -104.85044579999999,
      "parentGeographicAreaId": 0,
      "title": "1 Lake Ave, Colorado Springs, CO 80906, USA",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 44,
        "tagName": "Grand Dame ",
        "tagType": 1
      },
      {
        "tagId": 45,
        "tagName": "Historic Sense",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 68,
        "tagName": "Business",
        "tagType": 2
      },
      {
        "tagId": 70,
        "tagName": "Families",
        "tagType": 2
      },
      {
        "tagId": 75,
        "tagName": "Casual Dining",
        "tagType": 2
      },
      {
        "tagId": 78,
        "tagName": "Fine Dining",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 88,
        "tagName": "Tennis",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 100,
        "tagName": "Wedding",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 109,
        "tagName": "Fishing",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 215,
        "tagName": "Waterfront",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 596,
        "venueCategoryId": 30,
        "venueId": 1123,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 30,
          "title": "Fairway Dreams",
          "region": "Global",
          "viewOrder": 5,
          "updated": "2020-05-29T09:35:00",
          "created": "2020-05-29T09:35:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1121,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Wequassett Resort and Golf Club",
    "description": "Nestled on the elbow of Cape Cod, this meticulously landscaped hideaway features 120 lavish guest rooms and suites, four acclaimed restaurants, two magnificent pools, two extraordinary beaches, boating and water sports, four Har Tru tennis courts and eighteen holes of exclusive championship golf.",
    "brand": null,
    "blockQuote": "Spoil yourself and get a room with view of the Bay!",
    "emailAddress": "info@wequassett.com",
    "website": "wequassett.com",
    "bookingURL": "https://gc.synxis.com/rez.aspx?tps=fml&adult=1&step=1&hotel=27328&shell=rHYAWI&chain=10237&template=rHYAWI&avcurrency=USD&_ga=2.27955388.1053606302.1547590012-675198581.1547590012&iata=96027890",
    "redirectNotes": "Wequassett Resort and Golf Club's reservations system",
    "phoneNumber": "+1 508 319 9901",
    "addressOne": "2173",
    "addressTwo": "Massachusetts 28",
    "city": "Harwich",
    "county": "",
    "region": "Massachusetts",
    "country": "United States",
    "rooms": 120,
    "dollar": 2,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 26843,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/177cc4f5-7112-44c8-a7f0-8d0954490859/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/177cc4f5-7112-44c8-a7f0-8d0954490859/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/177cc4f5-7112-44c8-a7f0-8d0954490859/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 11293,
        "title": "Wequassett Resort & Golf Club | Zonas",
        "url": "https://pkslist.azureedge.net/media/images/1/49b15a52-9de5-49ac-81bb-da8b8be2ea05/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/49b15a52-9de5-49ac-81bb-da8b8be2ea05/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/49b15a52-9de5-49ac-81bb-da8b8be2ea05/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 11290,
        "title": "Wequassett Resort and Golf Club",
        "url": "https://pkslist.azureedge.net/media/images/1/e8a5946a-539b-4566-a133-965a3d0ebb2f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e8a5946a-539b-4566-a133-965a3d0ebb2f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e8a5946a-539b-4566-a133-965a3d0ebb2f/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 26846,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c0888126-5eab-41ff-8429-1912df61acb6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c0888126-5eab-41ff-8429-1912df61acb6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c0888126-5eab-41ff-8429-1912df61acb6/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 11291,
        "title": "Wequassett Resort and Golf Club, Chatham, Massachusetts",
        "url": "https://pkslist.azureedge.net/media/images/1/5dbf0b19-8954-45aa-be42-1d21c4c56aae/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5dbf0b19-8954-45aa-be42-1d21c4c56aae/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5dbf0b19-8954-45aa-be42-1d21c4c56aae/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 26844,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/4013b623-095a-4f33-97b4-f32eb794ca49/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4013b623-095a-4f33-97b4-f32eb794ca49/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4013b623-095a-4f33-97b4-f32eb794ca49/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 26845,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f383dd64-2a1e-4690-a956-c19a9ac5a71c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f383dd64-2a1e-4690-a956-c19a9ac5a71c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f383dd64-2a1e-4690-a956-c19a9ac5a71c/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 26842,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/79d1f345-e9ff-4898-9f43-1b9d82290075/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/79d1f345-e9ff-4898-9f43-1b9d82290075/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/79d1f345-e9ff-4898-9f43-1b9d82290075/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 946,
      "latitude": 41.7222453,
      "longitude": -69.9966043,
      "parentGeographicAreaId": 0,
      "title": "2173 MA-28, Harwich, MA 02645, USA",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 58,
        "tagName": "Beach",
        "tagType": 2
      },
      {
        "tagId": 68,
        "tagName": "Business",
        "tagType": 2
      },
      {
        "tagId": 75,
        "tagName": "Casual Dining",
        "tagType": 2
      },
      {
        "tagId": 78,
        "tagName": "Fine Dining",
        "tagType": 2
      },
      {
        "tagId": 81,
        "tagName": "Golf",
        "tagType": 2
      },
      {
        "tagId": 83,
        "tagName": "Kid's Club",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 88,
        "tagName": "Tennis",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 100,
        "tagName": "Wedding",
        "tagType": 2
      },
      {
        "tagId": 172,
        "tagName": "Biking",
        "tagType": 2
      },
      {
        "tagId": 202,
        "tagName": "Private Terraces",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 592,
        "venueCategoryId": 30,
        "venueId": 1121,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 30,
          "title": "Fairway Dreams",
          "region": "Global",
          "viewOrder": 5,
          "updated": "2020-05-29T09:35:00",
          "created": "2020-05-29T09:35:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 899,
    "venueCategoryId": 0,
    "status": 1,
    "title": "The Lodge at Pebble Beach",
    "description": "Since 1919, The Lodge at Pebble Beach has been a landmark destination, the spectacular intersection of luxury and breathtaking beauty. This is the trip you will measure all other trips against.\n\nAs a golfer, the tradition of staying at The Lodge while overlooking the most famous finishing hole in the game is a proud rite of passage. And as a relaxed guest, the striking scenery is only rivaled by our sincere dedication to service, and our commitment to making your trip a once-in-a-lifetime experience",
    "brand": null,
    "blockQuote": "Your stay at this legendary lodge includes complimentary access to The Beach & Tennis Club so don't worry if you're not a golfer.",
    "emailAddress": "email@pebblebeach.com",
    "website": "pebblebeach.com/accommodations/the-lodge-at-pebble-beach/",
    "bookingURL": "https://www.pebblebeach.com/plan-my-trip/",
    "redirectNotes": "",
    "phoneNumber": "+1 831 625 8598",
    "addressOne": "",
    "addressTwo": "",
    "city": "Del Monte Forest",
    "county": "",
    "region": "California",
    "country": "United States",
    "rooms": 161,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 22940,
        "title": "Resort Activities in Monterey | Lodge at Pebble Beach | Golf ...",
        "url": "https://pkslist.azureedge.net/media/images/1/3cc88e8d-0a22-42e0-afd7-fe8324e5a018/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3cc88e8d-0a22-42e0-afd7-fe8324e5a018/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3cc88e8d-0a22-42e0-afd7-fe8324e5a018/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 22946,
        "title": "Lodge at Pebble Beach | Golf Resort | Andrew Harper Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/4d395b23-435a-4fc6-a5eb-bd3ccb445707/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4d395b23-435a-4fc6-a5eb-bd3ccb445707/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4d395b23-435a-4fc6-a5eb-bd3ccb445707/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 22948,
        "title": "Pebble Beach | Premier Luxury Accommodations & Hotels",
        "url": "https://pkslist.azureedge.net/media/images/1/3fca4eaa-b74a-47f6-a4a8-adbda035054d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3fca4eaa-b74a-47f6-a4a8-adbda035054d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3fca4eaa-b74a-47f6-a4a8-adbda035054d/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 22945,
        "title": "Lodge at Pebble Beach | Golf Resort | Andrew Harper Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/7d48c8e9-e2e6-4492-a1d5-654237e3c286/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7d48c8e9-e2e6-4492-a1d5-654237e3c286/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7d48c8e9-e2e6-4492-a1d5-654237e3c286/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 8494,
        "title": "Lodge at Pebble Beach | Golf Resort | Andrew Harper Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/25674ba7-35d1-4104-a0f8-181de1caa173/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/25674ba7-35d1-4104-a0f8-181de1caa173/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/25674ba7-35d1-4104-a0f8-181de1caa173/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 8496,
        "title": "Luxury Pebble Beach Resort | The Lodge at Pebble Beach | Five Star ...",
        "url": "https://pkslist.azureedge.net/media/images/1/119df5cd-6a81-4d3c-b043-3da786c3bf29/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/119df5cd-6a81-4d3c-b043-3da786c3bf29/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/119df5cd-6a81-4d3c-b043-3da786c3bf29/256x192.jpg",
        "viewOrder": 6
      }
    ],
    "geographicArea": {
      "geographicAreaId": 730,
      "latitude": 36.5688064,
      "longitude": -121.95062410000003,
      "parentGeographicAreaId": 0,
      "title": "1700 17-Mile Drive, Pebble Beach, CA 93953, USA",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 48,
        "tagName": "Iconic/Landmark ",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 58,
        "tagName": "Beach",
        "tagType": 2
      },
      {
        "tagId": 60,
        "tagName": "Gastronomic",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 68,
        "tagName": "Business",
        "tagType": 2
      },
      {
        "tagId": 70,
        "tagName": "Families",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 81,
        "tagName": "Golf",
        "tagType": 2
      },
      {
        "tagId": 82,
        "tagName": "Horseback Riding",
        "tagType": 2
      },
      {
        "tagId": 85,
        "tagName": "Pet Friendly",
        "tagType": 2
      },
      {
        "tagId": 88,
        "tagName": "Tennis",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 169,
        "tagName": "Resort",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 597,
        "venueCategoryId": 30,
        "venueId": 899,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 30,
          "title": "Fairway Dreams",
          "region": "Global",
          "viewOrder": 5,
          "updated": "2020-05-29T09:35:00",
          "created": "2020-05-29T09:35:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 882,
    "venueCategoryId": 0,
    "status": 1,
    "title": "The Ritz-Carlton Half Moon Bay",
    "description": "When the surrounding area is rich with natural resources and beauty, luxury comes with a distinctly local touch. At The Ritz-Carlton, Half Moon Bay, hotel guests can savor the Northern California sunset on a beautiful evening; sample fine wines from vintners along the coast; craft s’mores over an open fire on their private terrace; discover the nourishing benefits of pumpkin at the spa or tee off on two championship golf courses that are as beautiful as they are well-designed. Holding court on a bluff overlooking the ocean, just 20 miles from San Francisco International Airport, this secluded, luxury hotel in California celebrates its surroundings with an experience rooted in embracing elements of the coast.",
    "brand": "Ritz Carlton",
    "blockQuote": "Cork Wine Bar houses over 5000 bottles of wine and offers an upscale wine bar experience.",
    "emailAddress": "RC-HalfMoonBayleads@ritzcarlton.com",
    "website": "ritzcarlton.com/en/hotels/california/half-moon-bay",
    "bookingURL": "http://www.booking.com/Share-eFgBH4?aid=925669",
    "redirectNotes": null,
    "phoneNumber": "+1 650 712 7000",
    "addressOne": "1",
    "addressTwo": "Miramontes Point Road",
    "city": "Half Moon Bay",
    "county": "",
    "region": "California",
    "country": "United States",
    "rooms": 261,
    "dollar": 3,
    "updated": "2020-12-18T16:15:09.007",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 33316,
        "title": "The Ritz-Carlton, Half Moon Bay, Half Moon Bay, California, United ...",
        "url": "https://pkslist.azureedge.net/media/images/1/0d1a84aa-4a8f-40a6-b638-9d118830e973/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0d1a84aa-4a8f-40a6-b638-9d118830e973/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0d1a84aa-4a8f-40a6-b638-9d118830e973/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 33326,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/9bc4dca2-53b8-4121-8420-632f3a05c5ac/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9bc4dca2-53b8-4121-8420-632f3a05c5ac/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9bc4dca2-53b8-4121-8420-632f3a05c5ac/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 33321,
        "title": "The Ritz-Carlton, Half Moon Bay, California, United States ...",
        "url": "https://pkslist.azureedge.net/media/images/1/618c2ac6-6780-4e57-876a-4cb389c0526f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/618c2ac6-6780-4e57-876a-4cb389c0526f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/618c2ac6-6780-4e57-876a-4cb389c0526f/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 33329,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f663c8f6-6473-4d9e-8356-7f1bce3907ec/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f663c8f6-6473-4d9e-8356-7f1bce3907ec/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f663c8f6-6473-4d9e-8356-7f1bce3907ec/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 33330,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/6da85faf-7290-4092-8087-5cea2b8b65c1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6da85faf-7290-4092-8087-5cea2b8b65c1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6da85faf-7290-4092-8087-5cea2b8b65c1/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 33333,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f38e5bd2-b2ba-4e21-918a-5bddb5c8ed77/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f38e5bd2-b2ba-4e21-918a-5bddb5c8ed77/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f38e5bd2-b2ba-4e21-918a-5bddb5c8ed77/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 33327,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b4fb9d0d-12fd-470f-b555-26d2d1fac613/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b4fb9d0d-12fd-470f-b555-26d2d1fac613/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b4fb9d0d-12fd-470f-b555-26d2d1fac613/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 33315,
        "title": "Review: Ritz Carlton Half Moon Bay",
        "url": "https://pkslist.azureedge.net/media/images/1/edf403a9-d21d-46cb-b68f-4dc24c7c893c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/edf403a9-d21d-46cb-b68f-4dc24c7c893c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/edf403a9-d21d-46cb-b68f-4dc24c7c893c/256x192.jpg",
        "viewOrder": 11
      }
    ],
    "geographicArea": {
      "geographicAreaId": 714,
      "latitude": 37.4337889,
      "longitude": -122.44150869999999,
      "parentGeographicAreaId": 0,
      "title": "1 Miramontes Point Rd, Half Moon Bay, CA 94019, USA",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      },
      {
        "tagId": 57,
        "tagName": "Romantic",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 58,
        "tagName": "Beach",
        "tagType": 2
      },
      {
        "tagId": 70,
        "tagName": "Families",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 81,
        "tagName": "Golf",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 88,
        "tagName": "Tennis",
        "tagType": 2
      },
      {
        "tagId": 100,
        "tagName": "Wedding",
        "tagType": 2
      },
      {
        "tagId": 169,
        "tagName": "Resort",
        "tagType": 2
      },
      {
        "tagId": 170,
        "tagName": "Kayaking",
        "tagType": 2
      },
      {
        "tagId": 185,
        "tagName": "Coastal",
        "tagType": 2
      },
      {
        "tagId": 193,
        "tagName": "Outdoor Dining",
        "tagType": 2
      },
      {
        "tagId": 275,
        "tagName": "Conservatory",
        "tagType": 2
      },
      {
        "tagId": 301,
        "tagName": "Wine Bar",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 628,
        "venueCategoryId": 30,
        "venueId": 882,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 30,
          "title": "Fairway Dreams",
          "region": "Global",
          "viewOrder": 5,
          "updated": "2020-05-29T09:35:00",
          "created": "2020-05-29T09:35:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 744,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Finca Cortesin",
    "description": "The hotel’s avant-garde yet majestic design is inspired by traditional Andalusian architecture. Exclusive and luxurious, a world set apart, where traditional Mediterranean lifestyle is seamlessly combined with the latest in contemporary living: superb cuisine, impeccable service and an unrivaled ambiance of peace and tranquility.",
    "brand": null,
    "blockQuote": "Golf, beach, gastronomy and relaxing all wrapped up in a stunning setting.",
    "emailAddress": "reservas@hotelcortesin.com",
    "website": "fincacortesin.com/",
    "bookingURL": "http://www.booking.com/Share-mudBiq?aid=925669",
    "redirectNotes": null,
    "phoneNumber": "+34 952 93 78 00",
    "addressOne": "s/n",
    "addressTwo": "Carretera de Casares",
    "city": "Marbella",
    "county": "",
    "region": "",
    "country": "Spain",
    "rooms": 67,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 32884,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/92b5a42f-2661-4498-8702-ff2e38bca942/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/92b5a42f-2661-4498-8702-ff2e38bca942/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/92b5a42f-2661-4498-8702-ff2e38bca942/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 32893,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/71087335-1b3b-4ef5-9a23-680f6891a41b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/71087335-1b3b-4ef5-9a23-680f6891a41b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/71087335-1b3b-4ef5-9a23-680f6891a41b/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 32888,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/55dca5d4-36c0-4b61-9ec7-b16e0a849122/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/55dca5d4-36c0-4b61-9ec7-b16e0a849122/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/55dca5d4-36c0-4b61-9ec7-b16e0a849122/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 32889,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/2c7aaf98-6e1e-4ada-a15c-1033c6287993/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2c7aaf98-6e1e-4ada-a15c-1033c6287993/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2c7aaf98-6e1e-4ada-a15c-1033c6287993/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 32886,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/27bf77cb-8527-4aac-8342-521ab05cd01c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/27bf77cb-8527-4aac-8342-521ab05cd01c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/27bf77cb-8527-4aac-8342-521ab05cd01c/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 6430,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/9c0c24b3-3b54-4c23-836e-958cf72c6f02/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9c0c24b3-3b54-4c23-836e-958cf72c6f02/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9c0c24b3-3b54-4c23-836e-958cf72c6f02/256x192.jpeg",
        "viewOrder": 10
      },
      {
        "mediaId": 32890,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/140362f3-465d-4d1c-82e5-ed512acf64b2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/140362f3-465d-4d1c-82e5-ed512acf64b2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/140362f3-465d-4d1c-82e5-ed512acf64b2/256x192.jpg",
        "viewOrder": 11
      },
      {
        "mediaId": 32885,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c848121e-4b6c-420e-be1d-bafe530a7f66/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c848121e-4b6c-420e-be1d-bafe530a7f66/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c848121e-4b6c-420e-be1d-bafe530a7f66/256x192.jpg",
        "viewOrder": 14
      },
      {
        "mediaId": 6436,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c12c3415-bd24-47f7-8eb1-a0e13ae24fe8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c12c3415-bd24-47f7-8eb1-a0e13ae24fe8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c12c3415-bd24-47f7-8eb1-a0e13ae24fe8/256x192.jpg",
        "viewOrder": 15
      }
    ],
    "geographicArea": {
      "geographicAreaId": 581,
      "latitude": 36.3971344,
      "longitude": -5.2241933000000245,
      "parentGeographicAreaId": 0,
      "title": "Carretera de Casares, s/n, 29690 Casares, Málaga, Spain",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 70,
        "tagName": "Families",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 73,
        "tagName": "Outdoor Terraces",
        "tagType": 2
      },
      {
        "tagId": 81,
        "tagName": "Golf",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 167,
        "tagName": "Nanny/Babysitting Service",
        "tagType": 2
      },
      {
        "tagId": 180,
        "tagName": "Health Management Programs",
        "tagType": 2
      },
      {
        "tagId": 185,
        "tagName": "Coastal",
        "tagType": 2
      },
      {
        "tagId": 206,
        "tagName": "Private Beach",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 627,
        "venueCategoryId": 30,
        "venueId": 744,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 30,
          "title": "Fairway Dreams",
          "region": "Global",
          "viewOrder": 5,
          "updated": "2020-05-29T09:35:00",
          "created": "2020-05-29T09:35:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 710,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Four Seasons Resort Hualalai",
    "description": "Set on Hawaii Island’s exclusive Kona-Kohala Coast, this showpiece oceanfront luxury resort boasts a dramatic mix of white-sand beaches, dazzling oceanfront and black-lava landscapes. Housed in intimate two-storey bungalows, the 243 guest rooms and suites at Four Seasons Resort Hualalai exude authentic Hawaiian luxury. A peerless collection of native Hawaiian art complements expansive, open-air living spaces and island-inspired décor at this Kona, Hawaii luxury resort on the Big Island.",
    "brand": "Four Seasons",
    "blockQuote": "Enjoy championship golf, island-fresh dining, and a genuine aloha spirit during your stay.",
    "emailAddress": "res.hualalai@fourseasons.com",
    "website": "fourseasons.com/hualalai/",
    "bookingURL": "https://reservations.fourseasons.com/?hotelCode=KOA872&checkIn=2021-03-18&checkOut=2021-03-19&adults=2&children=0&iata=96027890",
    "redirectNotes": "Four Seasons Resort Hualalai's reservations system",
    "phoneNumber": "+1 808 325 8000",
    "addressOne": "72-100",
    "addressTwo": "Ka'upulehu Drive",
    "city": "Kailua Kona",
    "county": "Big Island",
    "region": "Hawaii",
    "country": "United States",
    "rooms": 243,
    "dollar": 3,
    "updated": "2022-11-23T10:29:11.897",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 46304,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d7f654af-0d3e-42ea-8615-36287ffc327a/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d7f654af-0d3e-42ea-8615-36287ffc327a/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d7f654af-0d3e-42ea-8615-36287ffc327a/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 46302,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b3e16f08-cdce-4dfd-8a78-9a1d2be93a89/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b3e16f08-cdce-4dfd-8a78-9a1d2be93a89/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b3e16f08-cdce-4dfd-8a78-9a1d2be93a89/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 46298,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/08872159-2ee1-40cf-97be-0eda12ea7eb8/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/08872159-2ee1-40cf-97be-0eda12ea7eb8/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/08872159-2ee1-40cf-97be-0eda12ea7eb8/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 46303,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/866cb1fa-9d11-4901-b590-34895a075f2c/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/866cb1fa-9d11-4901-b590-34895a075f2c/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/866cb1fa-9d11-4901-b590-34895a075f2c/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 46299,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/48e6a8ea-3f21-4615-aee3-ad6e9998b090/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/48e6a8ea-3f21-4615-aee3-ad6e9998b090/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/48e6a8ea-3f21-4615-aee3-ad6e9998b090/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 46310,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/2966c8ef-2490-433c-a359-31355b240974/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2966c8ef-2490-433c-a359-31355b240974/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2966c8ef-2490-433c-a359-31355b240974/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 46305,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/54f6e940-986b-421b-bde8-93b859eeafa9/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/54f6e940-986b-421b-bde8-93b859eeafa9/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/54f6e940-986b-421b-bde8-93b859eeafa9/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 46297,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f6e8d6f8-07e6-4f61-a112-b6f2d481d4de/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f6e8d6f8-07e6-4f61-a112-b6f2d481d4de/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f6e8d6f8-07e6-4f61-a112-b6f2d481d4de/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 46309,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/491fdb63-c61f-4d98-80ba-384c06828bc0/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/491fdb63-c61f-4d98-80ba-384c06828bc0/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/491fdb63-c61f-4d98-80ba-384c06828bc0/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 5903,
        "title": "Four Seasons Resort Hualalai - Haute Grandeur",
        "url": "https://pkslist.azureedge.net/media/images/1/7fadf5e8-a405-4391-bf41-da53847f39c9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7fadf5e8-a405-4391-bf41-da53847f39c9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7fadf5e8-a405-4391-bf41-da53847f39c9/256x192.jpg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 546,
      "latitude": 19.8276739,
      "longitude": -155.9916716,
      "parentGeographicAreaId": 0,
      "title": "72-100 Ka'upulehu Drive, Kailua-Kona, HI 96740, USA",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      },
      {
        "tagId": 157,
        "tagName": "Beach Escape",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 58,
        "tagName": "Beach",
        "tagType": 2
      },
      {
        "tagId": 78,
        "tagName": "Fine Dining",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 83,
        "tagName": "Kid's Club",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 100,
        "tagName": "Wedding",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 108,
        "tagName": "Jacuzzi",
        "tagType": 2
      },
      {
        "tagId": 193,
        "tagName": "Outdoor Dining",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 600,
        "venueCategoryId": 30,
        "venueId": 710,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 30,
          "title": "Fairway Dreams",
          "region": "Global",
          "viewOrder": 5,
          "updated": "2020-05-29T09:35:00",
          "created": "2020-05-29T09:35:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 725,
        "venueCategoryId": 15,
        "venueId": 710,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 15,
          "title": "Beach Paradise",
          "region": "Global",
          "viewOrder": 6,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 631,
    "venueCategoryId": 0,
    "status": 1,
    "title": "The Romanos, a Luxury Collection Resort",
    "description": "The Romanos, a Luxury Collection Resort, is an award-winning property stretching along the prime Mediterranean destination of Navarino Dunes at Costa Navarino. This is the perfect location for unrivaled relaxation within the 321 incomparable rooms, suites and villas of The Romanos Resort, offering exceptional garden, golf and sea views, and the iconic sunset backdrops of the Ionian Sea for an unforgettable experience..\n\nIncluding two signature golf courses – The Dunes Course and The Bay Course – Costa Navarino has been established as the new luxury golf destination in Greece, offering extensive golf facilities nestled in a wonderful sea and olive grove landscape.",
    "brand": "",
    "blockQuote": "With 2 great golf courses and a plethora of activities, you'll certainly be entertained.",
    "emailAddress": "info.navarino@luxurycollection.com",
    "website": "romanoscostanavarino.com/",
    "bookingURL": "https://www.marriott.com/reservation/rateListMenu.mi?defaultTab=standard",
    "redirectNotes": "The Romanos, a Luxury Collection Resort's reservations system",
    "phoneNumber": "+30 272 309 6000",
    "addressOne": "",
    "addressTwo": "",
    "city": "Costa Navarino",
    "county": "",
    "region": "",
    "country": "Greece",
    "rooms": 321,
    "dollar": 2,
    "updated": "2023-08-30T15:49:50.637",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 48170,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/476d1cb1-1cdd-4251-934e-84727dcc1a8d/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/476d1cb1-1cdd-4251-934e-84727dcc1a8d/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/476d1cb1-1cdd-4251-934e-84727dcc1a8d/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 5242,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/0dacdbb7-9227-4bb8-a7cb-770ea740cd4a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0dacdbb7-9227-4bb8-a7cb-770ea740cd4a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0dacdbb7-9227-4bb8-a7cb-770ea740cd4a/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 48178,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/9d86466f-de99-4b90-aa42-696119906da3/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9d86466f-de99-4b90-aa42-696119906da3/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9d86466f-de99-4b90-aa42-696119906da3/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 48175,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/61f7982f-3e8d-4c12-9ed3-9374094f7e91/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/61f7982f-3e8d-4c12-9ed3-9374094f7e91/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/61f7982f-3e8d-4c12-9ed3-9374094f7e91/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 48176,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/465b04e7-8606-486f-b0ad-528e20d79406/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/465b04e7-8606-486f-b0ad-528e20d79406/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/465b04e7-8606-486f-b0ad-528e20d79406/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 48171,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/cee0d457-9b0e-4f4e-ae9f-fa8cb10f94e7/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cee0d457-9b0e-4f4e-ae9f-fa8cb10f94e7/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cee0d457-9b0e-4f4e-ae9f-fa8cb10f94e7/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 48174,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/842736c6-667f-45fd-8a7a-ca325a339b02/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/842736c6-667f-45fd-8a7a-ca325a339b02/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/842736c6-667f-45fd-8a7a-ca325a339b02/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 48173,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/267288d6-e04b-43d1-bea7-3a0417209e91/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/267288d6-e04b-43d1-bea7-3a0417209e91/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/267288d6-e04b-43d1-bea7-3a0417209e91/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 15216,
        "title": "Luxury Resort in Greece | Romanos Costa Navarino",
        "url": "https://pkslist.azureedge.net/media/images/1/502e94e8-0d7d-4162-8ced-3eba73e968e4/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/502e94e8-0d7d-4162-8ced-3eba73e968e4/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/502e94e8-0d7d-4162-8ced-3eba73e968e4/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 48177,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/80ba91db-60a3-4faf-81ad-958c6c1a8826/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/80ba91db-60a3-4faf-81ad-958c6c1a8826/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/80ba91db-60a3-4faf-81ad-958c6c1a8826/256x192.jpeg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 469,
      "latitude": 36.99739,
      "longitude": 21.64996999999994,
      "parentGeographicAreaId": 0,
      "title": "Navarino Dunes, Costa Navarino, Messinia, Costa Navarino 240 01, Greece",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 81,
        "tagName": "Golf",
        "tagType": 2
      },
      {
        "tagId": 83,
        "tagName": "Kid's Club",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 122,
        "tagName": "Surfing",
        "tagType": 2
      },
      {
        "tagId": 161,
        "tagName": "Bowling Alley",
        "tagType": 2
      },
      {
        "tagId": 172,
        "tagName": "Biking",
        "tagType": 2
      },
      {
        "tagId": 183,
        "tagName": "Butler Service",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 732,
        "venueCategoryId": 30,
        "venueId": 631,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 30,
          "title": "Fairway Dreams",
          "region": "Global",
          "viewOrder": 5,
          "updated": "2020-05-29T09:35:00",
          "created": "2020-05-29T09:35:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 623,
    "venueCategoryId": 0,
    "status": 1,
    "title": "The Oitavos",
    "description": "The Oitavos is a family owned and managed grand hotel that represents the pinnacle of contemporary design and innovation. Offering a modern taste of Portugal, which is simultaneously rooted in the country’s national heritage, The Oitavos has achieved new standards of excellence and added the “luxury” of informality to its guests’ experience, providing quality service, style and fun to a global, sophisticated audience.\nLocated on the coastline, overlooking the Atlantic Ocean, and on the fringe of the Sintra National Park, only 5 minutes from the town of Cascais and the famous Guincho beach, The Oitavos in Portugal is the ideal luxury hotel from which to enjoy your authentic Portuguese adventure.",
    "brand": null,
    "blockQuote": "Take the short 3-minute walk to the hotel's other restaurant, Verbasco which specializes in Portuguese dishes and tapas with a more relaxed atmosphere.",
    "emailAddress": "reservations@theoitavos.com",
    "website": "theoitavos.com/",
    "bookingURL": "https://gc.synxis.com/rez.aspx?Hotel=28160&Chain=10655&template=RBE&arrive=Arrival&depart=Departure&adult=&child=&age1=undefined&age2=undefined&_ga=2&iata=96027890",
    "redirectNotes": "The Oitavos' reservations system",
    "phoneNumber": "+351 214 860 020 ",
    "addressOne": "",
    "addressTwo": "Rua de Oitavos",
    "city": "Cascais",
    "county": "",
    "region": "Lisbon",
    "country": "Portugal",
    "rooms": 143,
    "dollar": 2,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 5172,
        "title": "The Oitavos Hotel Lisbon - Golf Breaks & Holiday offers -",
        "url": "https://pkslist.azureedge.net/media/images/1/09d22466-1091-459b-9bc6-c79a827f6f61/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/09d22466-1091-459b-9bc6-c79a827f6f61/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/09d22466-1091-459b-9bc6-c79a827f6f61/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 5176,
        "title": "Hotel The Oitavos - Cascais - Costa de Estoril",
        "url": "https://pkslist.azureedge.net/media/images/1/df30a27e-aff6-4913-8058-a48df3b48365/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/df30a27e-aff6-4913-8058-a48df3b48365/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/df30a27e-aff6-4913-8058-a48df3b48365/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 21516,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/7400168b-c961-442e-819f-7a0bbf47fe75/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7400168b-c961-442e-819f-7a0bbf47fe75/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7400168b-c961-442e-819f-7a0bbf47fe75/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 21514,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/82e282b0-8a6e-4ac3-8fa5-478b1c8ce64f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/82e282b0-8a6e-4ac3-8fa5-478b1c8ce64f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/82e282b0-8a6e-4ac3-8fa5-478b1c8ce64f/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 5170,
        "title": "The Oitavos | Luxury Hotel in Cascais, in the Lisbon Coast, Portugal",
        "url": "https://pkslist.azureedge.net/media/images/1/7c027fc7-54d9-4981-9818-0f44987134ed/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7c027fc7-54d9-4981-9818-0f44987134ed/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7c027fc7-54d9-4981-9818-0f44987134ed/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 5179,
        "title": "Oitavos Suite: Hotel bedroom suite with Golf & Sea views in ...",
        "url": "https://pkslist.azureedge.net/media/images/1/dadf487b-2a5c-4a70-9438-8c6b45112789/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/dadf487b-2a5c-4a70-9438-8c6b45112789/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/dadf487b-2a5c-4a70-9438-8c6b45112789/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 5175,
        "title": "Hotel The Oitavos - Water Evolution",
        "url": "https://pkslist.azureedge.net/media/images/1/4ea639b7-bb86-45af-a290-9acf16724784/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4ea639b7-bb86-45af-a290-9acf16724784/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4ea639b7-bb86-45af-a290-9acf16724784/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 5178,
        "title": "The Oitavos Hotel in Cascais Portugal, Cascais Hotel Deals ...",
        "url": "https://pkslist.azureedge.net/media/images/1/d61fb7a4-4d30-4e34-b018-2a17ef12f7cd/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d61fb7a4-4d30-4e34-b018-2a17ef12f7cd/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d61fb7a4-4d30-4e34-b018-2a17ef12f7cd/256x192.jpg",
        "viewOrder": 7
      }
    ],
    "geographicArea": {
      "geographicAreaId": 461,
      "latitude": 38.7036785,
      "longitude": -9.467299099999991,
      "parentGeographicAreaId": 0,
      "title": "R. de Oitavos, 2750-374 Cascais, Portugal",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 67,
        "tagName": "Sporty",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 81,
        "tagName": "Golf",
        "tagType": 2
      },
      {
        "tagId": 82,
        "tagName": "Horseback Riding",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 100,
        "tagName": "Wedding",
        "tagType": 2
      },
      {
        "tagId": 108,
        "tagName": "Jacuzzi",
        "tagType": 2
      },
      {
        "tagId": 124,
        "tagName": "Helicopter",
        "tagType": 2
      },
      {
        "tagId": 151,
        "tagName": "Salt water pool",
        "tagType": 2
      },
      {
        "tagId": 185,
        "tagName": "Coastal",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 599,
        "venueCategoryId": 30,
        "venueId": 623,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 30,
          "title": "Fairway Dreams",
          "region": "Global",
          "viewOrder": 5,
          "updated": "2020-05-29T09:35:00",
          "created": "2020-05-29T09:35:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 570,
    "venueCategoryId": 0,
    "status": 1,
    "title": "CordeValle",
    "description": "Tucked away in the foothills of the Santa Cruz Mountains, CordeValle is a hidden gem that combines the extraordinary beauty of an awe-inspiring natural landscape with the convenience and hospitality of a luxury resort. Sprawling over nearly 1,700 acres and cradled by rolling Northern California countryside, the resort is a sanctuary of gently undulating meadows, tree-dotted hills, seasonal creeks and waterfalls. The centerpiece of the resort is a 7,360-yard championship golf course with unobstructed views. The understated luxury of the clubhouse encompasses over 4,100 square feet of private, state-of-the-art meeting rooms and special event dining for up to 120 guests, as well as three distinct dining venues. The resort also features a wellness spa, fitness facilities, an outdoor pool, a winery and a tennis center with tournament courts.",
    "brand": "",
    "blockQuote": "The golfing is top notch, but there is plenty to do for those who don't golf.",
    "emailAddress": "reservations@cordevallecom",
    "website": "cordevalle.com/",
    "bookingURL": "https://be.synxis.com/?_ga=2.188935211.1267892671.1623161168-1661267178.1623161168&adult=1&agencyid=96027890&arrive=2021-06-09&chain=27719&child=0&currency=USD&depart=2021-06-10&filter=&hotel=12943&level=hotel&locale=en-US&rooms=1",
    "redirectNotes": "CordeValle's reservations system",
    "phoneNumber": "+1 408 695 4500",
    "addressOne": "1",
    "addressTwo": "Cordevalle Club Drive",
    "city": "San Martin",
    "county": "",
    "region": "California",
    "country": "United States",
    "rooms": 45,
    "dollar": 2,
    "updated": "2021-06-09T09:42:20.37",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 47875,
        "title": "Indulge in a Pre-Wedding Getaway at Cordevalle, A Rosewood Resort",
        "url": "https://pkslist.azureedge.net/media/images/1/d6d13a36-c5df-4792-a153-1ebe56a6fa0f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d6d13a36-c5df-4792-a153-1ebe56a6fa0f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d6d13a36-c5df-4792-a153-1ebe56a6fa0f/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 9185,
        "title": "Rosewood CordeValle, San Martin, California - Resort Review & Photos",
        "url": "https://pkslist.azureedge.net/media/images/1/f8f0bce4-2b9a-460a-b1f7-eafacf5a29fc/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f8f0bce4-2b9a-460a-b1f7-eafacf5a29fc/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f8f0bce4-2b9a-460a-b1f7-eafacf5a29fc/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 47877,
        "title": "Dip Back Into Travel with a Visit to CordeValle - Haute Living San ...",
        "url": "https://pkslist.azureedge.net/media/images/1/9850ff35-2d12-463a-bd32-8a7ab576c3cd/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9850ff35-2d12-463a-bd32-8a7ab576c3cd/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9850ff35-2d12-463a-bd32-8a7ab576c3cd/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 47872,
        "title": "CordeValle golf resort hopes for upswing in COVID era",
        "url": "https://pkslist.azureedge.net/media/images/1/f7c86d46-7208-4e5e-9425-714eebccfb10/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f7c86d46-7208-4e5e-9425-714eebccfb10/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f7c86d46-7208-4e5e-9425-714eebccfb10/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 4518,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/70fe4a09-1f4d-4f1b-89c0-c3543a4757f9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/70fe4a09-1f4d-4f1b-89c0-c3543a4757f9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/70fe4a09-1f4d-4f1b-89c0-c3543a4757f9/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 4514,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/8cdfdc60-9d3d-466f-a980-f2e5bf65eb3c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8cdfdc60-9d3d-466f-a980-f2e5bf65eb3c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8cdfdc60-9d3d-466f-a980-f2e5bf65eb3c/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 47881,
        "title": "Child-friendly accommodation: Rosewood CordeValle",
        "url": "https://pkslist.azureedge.net/media/images/1/b001bd73-e8af-470c-ad54-949759af1694/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b001bd73-e8af-470c-ad54-949759af1694/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b001bd73-e8af-470c-ad54-949759af1694/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 4520,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/dc2a49bb-3dce-4627-a0ef-11245c5a24d5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/dc2a49bb-3dce-4627-a0ef-11245c5a24d5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/dc2a49bb-3dce-4627-a0ef-11245c5a24d5/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 4517,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c3470d4f-b911-4566-a679-5ba062b2989d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c3470d4f-b911-4566-a679-5ba062b2989d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c3470d4f-b911-4566-a679-5ba062b2989d/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 47878,
        "title": "Hotel Rosewood CordeValle in San Martin California - 12 Photos ...",
        "url": "https://pkslist.azureedge.net/media/images/1/9550a672-d9f3-4f97-a6b1-304df86386e1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9550a672-d9f3-4f97-a6b1-304df86386e1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9550a672-d9f3-4f97-a6b1-304df86386e1/256x192.jpg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 410,
      "latitude": 37.067571,
      "longitude": -121.63289120000002,
      "parentGeographicAreaId": 0,
      "title": "1 Cordevalle Club Drive, San Martin, CA 95046, USA",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 42,
        "tagName": "Classic Traditionalist ",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 31,
        "tagName": "Countryside",
        "tagType": 2
      },
      {
        "tagId": 61,
        "tagName": "Mountain",
        "tagType": 2
      },
      {
        "tagId": 68,
        "tagName": "Business",
        "tagType": 2
      },
      {
        "tagId": 81,
        "tagName": "Golf",
        "tagType": 2
      },
      {
        "tagId": 83,
        "tagName": "Kid's Club",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 88,
        "tagName": "Tennis",
        "tagType": 2
      },
      {
        "tagId": 89,
        "tagName": "Vineyard",
        "tagType": 2
      },
      {
        "tagId": 153,
        "tagName": "Cigar Lounge",
        "tagType": 2
      },
      {
        "tagId": 219,
        "tagName": "Wine Cellar",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 598,
        "venueCategoryId": 30,
        "venueId": 570,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 30,
          "title": "Fairway Dreams",
          "region": "Global",
          "viewOrder": 5,
          "updated": "2020-05-29T09:35:00",
          "created": "2020-05-29T09:35:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 565,
    "venueCategoryId": 0,
    "status": 1,
    "title": "One&Only Palmilla",
    "description": "Exceptional luxury and blissful serenity, with breathtaking sea views and all the gracious hospitality of Mexico. \n\nWake each morning to the frothy trajectory of waves along the shore. Play in crystal waters that Jacques Cousteau call the ‘world’s aquarium’. Follow the sculpted contours of fairways and greens considered among the finest worldwide. As the sun descends, cool air sails in, carrying with it the scent of mimosa, wild fig and mesquite. Begin the evening with the icy thrill of a freshly shaken margarita. Linger over just-caught fish dressed with Jean-Georges’ fusion of flavours. Let every care fall away as you surrender to total happiness.",
    "brand": "One&Only",
    "blockQuote": "An exclusive oasis where golden desert sands meet breathtaking turquoise waters.",
    "emailAddress": "reservations@oneandonlypalmilla.com",
    "website": "oneandonlyresorts.com/one-and-only-palmilla-los-cabos",
    "bookingURL": "https://be.synxis.com/?IATA=96027890&adult=1&agencyid=96027890&arrive=2021-07-07&chain=9020&child=0&currency=USD&depart=2021-07-08&filter=&hotel=23856&level=hotel&locale=en-US&rooms=1&shell=Flex",
    "redirectNotes": "One&Only Palmilla's reservations system",
    "phoneNumber": " +52 624 146 7000",
    "addressOne": "",
    "addressTwo": "Carr Transpeninsular",
    "city": "Los Cabos",
    "county": "",
    "region": "",
    "country": "Mexico",
    "rooms": 174,
    "dollar": 2,
    "updated": "2021-07-07T12:49:59.807",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 4446,
        "title": "One & Only Palmilla | Ann Flower Communications",
        "url": "https://pkslist.azureedge.net/media/images/1/4cd9472b-5506-48ea-ba03-4c926f384c9c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4cd9472b-5506-48ea-ba03-4c926f384c9c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4cd9472b-5506-48ea-ba03-4c926f384c9c/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 4452,
        "title": "Celeb Digs - Serenity By The Sea At The One & Only Palmilla In Los ...",
        "url": "https://pkslist.azureedge.net/media/images/1/7f5be6ac-e2e1-4160-b8dd-f6e93f9353c5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7f5be6ac-e2e1-4160-b8dd-f6e93f9353c5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7f5be6ac-e2e1-4160-b8dd-f6e93f9353c5/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 4458,
        "title": "Cabo's One&Only Palmilla Resort Has an Amazing Villa ...",
        "url": "https://pkslist.azureedge.net/media/images/1/1f100508-41c5-4d4e-a83c-ff9623c9bf33/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1f100508-41c5-4d4e-a83c-ff9623c9bf33/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1f100508-41c5-4d4e-a83c-ff9623c9bf33/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 4457,
        "title": "One & Only Palmilla from $758 - UPDATED 2017 Resort Reviews (Los ...",
        "url": "https://pkslist.azureedge.net/media/images/1/7a434d43-6492-4f17-8df2-449a967d8e0f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7a434d43-6492-4f17-8df2-449a967d8e0f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7a434d43-6492-4f17-8df2-449a967d8e0f/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 4451,
        "title": "One & Only Palmilla from $758 - UPDATED 2017 Resort Reviews (Los ...",
        "url": "https://pkslist.azureedge.net/media/images/1/85a3ccb8-50be-4994-9203-ec7b11070baf/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/85a3ccb8-50be-4994-9203-ec7b11070baf/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/85a3ccb8-50be-4994-9203-ec7b11070baf/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 4447,
        "title": "One & Only Palmilla (San Jose Del Cabo) 2017 Hotel Review - Family ...",
        "url": "https://pkslist.azureedge.net/media/images/1/6245e6bb-762f-4876-aa40-b9d7d341698b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6245e6bb-762f-4876-aa40-b9d7d341698b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6245e6bb-762f-4876-aa40-b9d7d341698b/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 4456,
        "title": "One Only Palmilla | Hotel-R",
        "url": "https://pkslist.azureedge.net/media/images/1/83c2c937-7d97-4d08-bc3a-a4212e64a35c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/83c2c937-7d97-4d08-bc3a-a4212e64a35c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/83c2c937-7d97-4d08-bc3a-a4212e64a35c/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 4449,
        "title": "One-And-Only-Palmilla-Luxury-Hotel-and-Resort-Cabo-0511 - Vantage ...",
        "url": "https://pkslist.azureedge.net/media/images/1/8f3db4eb-6736-4ede-8d4b-c6036232f15d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8f3db4eb-6736-4ede-8d4b-c6036232f15d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8f3db4eb-6736-4ede-8d4b-c6036232f15d/256x192.jpg",
        "viewOrder": 7
      }
    ],
    "geographicArea": {
      "geographicAreaId": 405,
      "latitude": 23.00682,
      "longitude": -109.71428300000002,
      "parentGeographicAreaId": 0,
      "title": "Carr Transpeninsular, 23400 San José del Cabo, B.C.S., Mexico",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      },
      {
        "tagId": 157,
        "tagName": "Beach Escape",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 73,
        "tagName": "Outdoor Terraces",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 81,
        "tagName": "Golf",
        "tagType": 2
      },
      {
        "tagId": 83,
        "tagName": "Kid's Club",
        "tagType": 2
      },
      {
        "tagId": 88,
        "tagName": "Tennis",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 164,
        "tagName": "Excursions",
        "tagType": 2
      },
      {
        "tagId": 169,
        "tagName": "Resort",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 593,
        "venueCategoryId": 30,
        "venueId": 565,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 30,
          "title": "Fairway Dreams",
          "region": "Global",
          "viewOrder": 5,
          "updated": "2020-05-29T09:35:00",
          "created": "2020-05-29T09:35:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 444,
    "venueCategoryId": 20,
    "status": 1,
    "title": "The Farm at Cape Kidnappers",
    "description": "Set atop 6,000 rolling acres of stunning pasture-land in Hawke’s Bay, New Zealand. The Farm affords spectacular 180-degree views of the Pacific Ocean. The cluster of luxury lodge buildings suggest a working farm with the buildings combining rustic and textured materials with comfortable modern furniture.\n\nOffering 22 guest suites, and a four bedroom Owner's Cottage just a 60 minute flight from Auckland. Every suite offers its own private balcony and affords spectacular views of the property, golf course and Pacific Ocean. \n\nThe main lodge features lounge and dining spaces, outdoor rooms, roaring open fire places, a wine cellar complete with tasting room plus a fully-equipped gymnasium.",
    "brand": null,
    "blockQuote": "Pack a picnic and enjoy the day at one of the properties' wonderful picnic sites.",
    "emailAddress": "info@capekidnappers.com",
    "website": "capekidnappers.com/",
    "bookingURL": "https://gc.synxis.com/rez.aspx?Hotel=22966&Chain=8565&locale=en-US&adult=2&template=Responsive_CAPE&shell=RBE_CAPE&Arrive=2018-09-03&Nights=2&iata=96027890",
    "redirectNotes": "The Farm at Cape Kidnappers' reservations system",
    "phoneNumber": "+64 6 875 1900 ",
    "addressOne": "446",
    "addressTwo": "Clifton Road",
    "city": "Te Awanga",
    "county": "",
    "region": "Hawke's Bay",
    "country": "New Zealand",
    "rooms": 23,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 2969,
        "title": "The Farm at Cape Kidnappers | Hawkes Bay | New Zealand | Exclusive ...",
        "url": "https://pkslist.azureedge.net/media/images/1/b8ae4092-93d2-4137-b01e-adcf0e6a7b6a/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b8ae4092-93d2-4137-b01e-adcf0e6a7b6a/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b8ae4092-93d2-4137-b01e-adcf0e6a7b6a/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 2974,
        "title": "The Farm at Cape Kidnappers | Luxury Hotel in Hawke's Bay North Island",
        "url": "https://pkslist.azureedge.net/media/images/1/a63a9071-a693-4443-9d2d-20a5108647fe/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a63a9071-a693-4443-9d2d-20a5108647fe/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a63a9071-a693-4443-9d2d-20a5108647fe/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 4987,
        "title": "New Zealand's best lodge hotels - reviews by Condé Nast Traveller ...",
        "url": "https://pkslist.azureedge.net/media/images/1/a3dab6a4-7c36-4f42-8253-f2ccfe2911fb/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a3dab6a4-7c36-4f42-8253-f2ccfe2911fb/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a3dab6a4-7c36-4f42-8253-f2ccfe2911fb/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 2966,
        "title": "The Farm At Cape Kidnappers - Te Awanga, New Zealand | Classic Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/efe80169-edff-405c-90ec-78c0f54e3e32/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/efe80169-edff-405c-90ec-78c0f54e3e32/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/efe80169-edff-405c-90ec-78c0f54e3e32/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 2972,
        "title": "Owners Cottage Gallery, Luxury Lodge Accommodation, Hawke's Bay ...",
        "url": "https://pkslist.azureedge.net/media/images/1/04d084ea-4f4a-4234-a6ae-e7934c0785a1/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/04d084ea-4f4a-4234-a6ae-e7934c0785a1/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/04d084ea-4f4a-4234-a6ae-e7934c0785a1/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 2973,
        "title": "Suites Gallery, Luxury Lodge Accommodation, Hawke's Bay, New Zealand",
        "url": "https://pkslist.azureedge.net/media/images/1/d23bc0d1-5864-460b-9d35-85943fa9c257/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d23bc0d1-5864-460b-9d35-85943fa9c257/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d23bc0d1-5864-460b-9d35-85943fa9c257/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 2965,
        "title": "The Farm at Cape Kidnappers | Hawkes Bay | New Zealand | Exclusive ...",
        "url": "https://pkslist.azureedge.net/media/images/1/93e3b505-e7b7-489e-ab52-28bb1d5e97fb/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/93e3b505-e7b7-489e-ab52-28bb1d5e97fb/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/93e3b505-e7b7-489e-ab52-28bb1d5e97fb/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 4988,
        "title": "The Farm at Cape Kidnappers | Natural Habitats",
        "url": "https://pkslist.azureedge.net/media/images/1/48f507a6-87e3-4d57-8abe-facf48b581fe/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/48f507a6-87e3-4d57-8abe-facf48b581fe/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/48f507a6-87e3-4d57-8abe-facf48b581fe/256x192.jpg",
        "viewOrder": 7
      }
    ],
    "geographicArea": {
      "geographicAreaId": 291,
      "latitude": -39.6407993,
      "longitude": 176.9895295,
      "parentGeographicAreaId": 0,
      "title": "446 Clifton Rd, Clifton 4172, New Zealand",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 73,
        "tagName": "Outdoor Terraces",
        "tagType": 2
      },
      {
        "tagId": 81,
        "tagName": "Golf",
        "tagType": 2
      },
      {
        "tagId": 82,
        "tagName": "Horseback Riding",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 156,
        "tagName": "Fireplace",
        "tagType": 2
      },
      {
        "tagId": 219,
        "tagName": "Wine Cellar",
        "tagType": 2
      },
      {
        "tagId": 235,
        "tagName": "Hot Tub",
        "tagType": 2
      },
      {
        "tagId": 243,
        "tagName": "Outdoor Activities",
        "tagType": 2
      },
      {
        "tagId": 259,
        "tagName": "Picnic",
        "tagType": 2
      },
      {
        "tagId": 266,
        "tagName": "Nature Walk",
        "tagType": 2
      },
      {
        "tagId": 317,
        "tagName": "Gourmet",
        "tagType": 2
      },
      {
        "tagId": 339,
        "tagName": "Sheperding",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 75,
        "venueCategoryId": 20,
        "venueId": 444,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 20,
          "title": "BucketList Hotels",
          "region": "Global",
          "viewOrder": 0,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 594,
        "venueCategoryId": 30,
        "venueId": 444,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 30,
          "title": "Fairway Dreams",
          "region": "Global",
          "viewOrder": 5,
          "updated": "2020-05-29T09:35:00",
          "created": "2020-05-29T09:35:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 268,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Old Course Hotel, Golf Resort & Spa",
    "description": "Bordering the renowned 17th Road Hole of the Old Course, the Old Course Hotel overlooks the famous links courses, the West Sands Beach and the beautiful Scottish coastline. This prestigious AA Five Star-awarded hotel has 144 rooms including 35 suites and is recognised as one of Europe’s leading resorts. The Old Course Hotel features a combination of classically elegant and contemporary interiors, and French designer Jacques Garcia designed many of the hotel’s suites. Located just a short stroll from the historic university town of St Andrews, it’s a great vacation spot for golfers and non-golfers alike.",
    "brand": "",
    "blockQuote": "Every golfer's dream;  five-star quality service and rooms with 30 golf courses and panoramic views over to the sea beyond",
    "emailAddress": "reservations@oldcoursehotel.co.uk",
    "website": "oldcoursehotel.co.uk",
    "bookingURL": "http://www.booking.com/hotel/gb/oldcourse.html?aid=925669",
    "redirectNotes": "",
    "phoneNumber": "+44 1334 474371",
    "addressOne": "",
    "addressTwo": "Old Station Road",
    "city": "Saint Andrews",
    "county": "",
    "region": "Scotland",
    "country": "United Kingdom",
    "rooms": 144,
    "dollar": 2,
    "updated": "2020-09-29T09:56:19.73",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 834,
        "title": "The Old Course Hotel in St Andrews | Luxury Scotland",
        "url": "https://pkslist.azureedge.net/media/images/1/7fb442cc-349d-4bed-8a63-d5c14b3c569e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7fb442cc-349d-4bed-8a63-d5c14b3c569e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7fb442cc-349d-4bed-8a63-d5c14b3c569e/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 831,
        "title": "Old Course Hotel, Golf Resort & Spa (St. Andrews, Scotland ...",
        "url": "https://pkslist.azureedge.net/media/images/1/48a7e36c-2c62-4f9b-a75a-3395faeb24d4/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/48a7e36c-2c62-4f9b-a75a-3395faeb24d4/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/48a7e36c-2c62-4f9b-a75a-3395faeb24d4/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 830,
        "title": "Golf Planet Holidays.com The Old Course Hotel Golf Resort & Spa Hotel",
        "url": "https://pkslist.azureedge.net/media/images/1/f2f01b3b-9a8a-4b52-8f96-f15ceeb3ce90/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f2f01b3b-9a8a-4b52-8f96-f15ceeb3ce90/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f2f01b3b-9a8a-4b52-8f96-f15ceeb3ce90/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 833,
        "title": "Old Course Hotel, Golf Resort & Spa - St andrews - The AA",
        "url": "https://pkslist.azureedge.net/media/images/1/82c465e8-755b-4a25-ba1b-9c1201690286/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/82c465e8-755b-4a25-ba1b-9c1201690286/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/82c465e8-755b-4a25-ba1b-9c1201690286/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 835,
        "title": "SCOTLAND – St. Andrews | Best Golf And Diving",
        "url": "https://pkslist.azureedge.net/media/images/1/3566853c-8b32-4ca3-a482-04f3a8d82351/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3566853c-8b32-4ca3-a482-04f3a8d82351/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3566853c-8b32-4ca3-a482-04f3a8d82351/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 837,
        "title": "Old Course Hotel St Andrews - hotelroomsearch.net",
        "url": "https://pkslist.azureedge.net/media/images/1/46d009c2-0105-4baa-b73f-398a73937184/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/46d009c2-0105-4baa-b73f-398a73937184/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/46d009c2-0105-4baa-b73f-398a73937184/256x192.jpg",
        "viewOrder": 5
      }
    ],
    "geographicArea": {
      "geographicAreaId": 135,
      "latitude": 56.3435266,
      "longitude": -2.8104170000000295,
      "parentGeographicAreaId": 0,
      "title": "Old Station Rd, St Andrews KY16, UK",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 42,
        "tagName": "Classic Traditionalist ",
        "tagType": 1
      },
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 59,
        "tagName": "Cultural",
        "tagType": 2
      },
      {
        "tagId": 68,
        "tagName": "Business",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 81,
        "tagName": "Golf",
        "tagType": 2
      },
      {
        "tagId": 83,
        "tagName": "Kid's Club",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 100,
        "tagName": "Wedding",
        "tagType": 2
      },
      {
        "tagId": 105,
        "tagName": "Afternoon Tea",
        "tagType": 2
      },
      {
        "tagId": 108,
        "tagName": "Jacuzzi",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 589,
        "venueCategoryId": 30,
        "venueId": 268,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 30,
          "title": "Fairway Dreams",
          "region": "Global",
          "viewOrder": 5,
          "updated": "2020-05-29T09:35:00",
          "created": "2020-05-29T09:35:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 190,
    "venueCategoryId": 0,
    "status": 1,
    "title": "The Gleneagles Hotel",
    "description": "Set within the gentle beauty of 850 acres of Perthshire countryside, this five star Leading Hotel of the World is home to three championship golf courses, a sparkling, award-winning spa and an exhilarating array of outdoor activities.\n  \nJust one hour from the international airports at Edinburgh and Glasgow, Gleneagles is rich with promise, as suited to romance as quality family time, group celebrations and corporate events.\n\nNo two bedrooms or suites are alike, with a choice of traditional or modern interiors. Elegant restaurants, bars, designer boutiques and a beautiful leisure club complete the picture, but perhaps not the experience. That comes with the exceptional service, as thorough and discreet as the historic setting itself.",
    "brand": null,
    "blockQuote": "A visit to the Health Club and Spa to relax and rejuvenate after your sporty day is a must.",
    "emailAddress": "amanda.ellis@gleneagles.com",
    "website": "gleneagles.com",
    "bookingURL": "https://booking.gleneagles.com/book/room",
    "redirectNotes": "",
    "phoneNumber": "+44 1764 662231",
    "addressOne": "",
    "addressTwo": "",
    "city": "Auchterarder",
    "county": "",
    "region": "Scotland",
    "country": "United Kingdom",
    "rooms": 232,
    "dollar": 2,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 194,
        "title": "Inside Scotland's most famous hotel, Gleneagles | Daily Mail Online",
        "url": "https://pkslist.azureedge.net/media/images/1/fbf890d8-7548-4a80-9197-9b9c23a5e3f3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fbf890d8-7548-4a80-9197-9b9c23a5e3f3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fbf890d8-7548-4a80-9197-9b9c23a5e3f3/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 24174,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b1a81dae-cf14-44d0-8d89-b05e75b69dde/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b1a81dae-cf14-44d0-8d89-b05e75b69dde/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b1a81dae-cf14-44d0-8d89-b05e75b69dde/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 24172,
        "title": "The Gleneagles Hotel, Auchterarder, UK - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/60d88bd8-8f9b-411c-8d65-9c9c51fdb511/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/60d88bd8-8f9b-411c-8d65-9c9c51fdb511/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/60d88bd8-8f9b-411c-8d65-9c9c51fdb511/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 24173,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b98b963d-57eb-43af-a6bb-efe65d4ef2bd/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b98b963d-57eb-43af-a6bb-efe65d4ef2bd/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b98b963d-57eb-43af-a6bb-efe65d4ef2bd/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 24177,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/9535115a-f1b4-4eac-83fb-5d98bbe1ff6e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9535115a-f1b4-4eac-83fb-5d98bbe1ff6e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9535115a-f1b4-4eac-83fb-5d98bbe1ff6e/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 24175,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/2b304e2b-de6a-4b73-94ce-70456956a822/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2b304e2b-de6a-4b73-94ce-70456956a822/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2b304e2b-de6a-4b73-94ce-70456956a822/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 24176,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c072753d-3a03-425f-b917-80ecb62ec1d8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c072753d-3a03-425f-b917-80ecb62ec1d8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c072753d-3a03-425f-b917-80ecb62ec1d8/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 197,
        "title": "Blue Tower Suite & Luxury Hotel Suites at Gleneagles - Gleneagles",
        "url": "https://pkslist.azureedge.net/media/images/1/4ccbde11-c2ff-449f-89e9-1618088bb119/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4ccbde11-c2ff-449f-89e9-1618088bb119/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4ccbde11-c2ff-449f-89e9-1618088bb119/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 66,
      "latitude": 56.28338549999999,
      "longitude": -3.7521833000000697,
      "parentGeographicAreaId": 0,
      "title": "Auchterarder PH3 1NF, UK",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 57,
        "tagName": "Romantic",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 31,
        "tagName": "Countryside",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 60,
        "tagName": "Gastronomic",
        "tagType": 2
      },
      {
        "tagId": 69,
        "tagName": "Couples",
        "tagType": 2
      },
      {
        "tagId": 70,
        "tagName": "Families",
        "tagType": 2
      },
      {
        "tagId": 81,
        "tagName": "Golf",
        "tagType": 2
      },
      {
        "tagId": 82,
        "tagName": "Horseback Riding",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 88,
        "tagName": "Tennis",
        "tagType": 2
      },
      {
        "tagId": 109,
        "tagName": "Fishing",
        "tagType": 2
      },
      {
        "tagId": 127,
        "tagName": "Hair Salon",
        "tagType": 2
      },
      {
        "tagId": 314,
        "tagName": "Falconry",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 602,
        "venueCategoryId": 30,
        "venueId": 190,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 30,
          "title": "Fairway Dreams",
          "region": "Global",
          "viewOrder": 5,
          "updated": "2020-05-29T09:35:00",
          "created": "2020-05-29T09:35:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  }
]
// 20240123100509
// https://app.pks-list.com/api/Venue/v1/getlist/35

export const placesStore32=[
  {
    "venueId": 3950,
    "venueCategoryId": 0,
    "status": 1,
    "title": "ADLER Spa Resort THERMAE",
    "description": "Where gentle hills dot the landscape, the scent of cypresses fills the air and steaming thermal water relieves all tension, coming to rest is easy. Nestling in a unique location in the hilly Tuscan landscape, the ADLER Spa Resort THERMAE is not just a place for personal relaxation, it is also the ideal starting point for truly getting to know the region. Immerse yourself in our soothing spa and an environment of enchanting beauty.",
    "brand": "",
    "blockQuote": "Hop and a bike and cycle through the gorgeous Val d’Orcia with its historic village.",
    "emailAddress": "info@adler-thermae.com",
    "website": "adler-resorts.com/en/adler-spa-resort-thermae/25-0.html",
    "bookingURL": "https://booking.adler-resorts.com/be/en/adler-spa-resort-thermae-bagno-vignoni/?_ga=2.209348050.1729634196.1705908175-814124361.1705908175&iata=96027890",
    "redirectNotes": "ADLER Spa Resort THERMAE's reservations system",
    "phoneNumber": "+39 0577 889001",
    "addressOne": "1",
    "addressTwo": "Strada di Bagno Vignoni",
    "city": "Bagno Vignoni",
    "county": "",
    "region": "Tuscany",
    "country": "Italy",
    "rooms": 0,
    "dollar": 0,
    "updated": "2024-01-22T11:46:50.43",
    "created": "2024-01-22T11:27:27.753",
    "medias": [
      {
        "mediaId": 65142,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ac985882-e716-4706-b5e2-386b82442ad9/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ac985882-e716-4706-b5e2-386b82442ad9/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ac985882-e716-4706-b5e2-386b82442ad9/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 65154,
        "title": "Press - ADLER Spa Resort THERMAE",
        "url": "https://pkslist.azureedge.net/media/images/1/9d7a7b5e-07dd-4280-ba49-52b172ff4847/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9d7a7b5e-07dd-4280-ba49-52b172ff4847/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9d7a7b5e-07dd-4280-ba49-52b172ff4847/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 65143,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/9e25fe45-5fbd-4d41-b369-5df1e2c15cc9/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9e25fe45-5fbd-4d41-b369-5df1e2c15cc9/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9e25fe45-5fbd-4d41-b369-5df1e2c15cc9/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 65146,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/8fc2b177-841d-4afb-b4c1-fb386b6c8c5b/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8fc2b177-841d-4afb-b4c1-fb386b6c8c5b/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8fc2b177-841d-4afb-b4c1-fb386b6c8c5b/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 65147,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f1f60595-b4a9-4acb-8c42-96f53cf3e33d/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f1f60595-b4a9-4acb-8c42-96f53cf3e33d/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f1f60595-b4a9-4acb-8c42-96f53cf3e33d/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 65149,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/efae889a-b491-409f-b194-0f22ad29e668/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/efae889a-b491-409f-b194-0f22ad29e668/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/efae889a-b491-409f-b194-0f22ad29e668/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 65152,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/df8cda55-6af2-4c10-be8d-b7a755f23cb7/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/df8cda55-6af2-4c10-be8d-b7a755f23cb7/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/df8cda55-6af2-4c10-be8d-b7a755f23cb7/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 65145,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/aa59f6a2-b6cb-401d-9841-515063bc7852/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/aa59f6a2-b6cb-401d-9841-515063bc7852/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/aa59f6a2-b6cb-401d-9841-515063bc7852/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 65144,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/9e928996-ab60-45ff-86e4-d47eb5446038/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9e928996-ab60-45ff-86e4-d47eb5446038/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9e928996-ab60-45ff-86e4-d47eb5446038/256x192.jpeg",
        "viewOrder": 9
      },
      {
        "mediaId": 65148,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/5dd8af9a-f328-45c4-8150-1ad37ae2945b/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5dd8af9a-f328-45c4-8150-1ad37ae2945b/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5dd8af9a-f328-45c4-8150-1ad37ae2945b/256x192.jpeg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3731,
      "latitude": 43.0301882,
      "longitude": 11.6226294,
      "parentGeographicAreaId": 0,
      "title": "Str. di Bagno Vignoni, 1, 53027 San Quirico D'orcia SI, Italy",
      "updated": null,
      "created": "2024-01-22T11:33:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 57,
        "tagName": "Romantic",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 31,
        "tagName": "Countryside",
        "tagType": 2
      },
      {
        "tagId": 77,
        "tagName": "Cooking Classes",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 104,
        "tagName": "Cycling",
        "tagType": 2
      },
      {
        "tagId": 221,
        "tagName": "Wine Tasting",
        "tagType": 2
      },
      {
        "tagId": 245,
        "tagName": "Hot Springs",
        "tagType": 2
      },
      {
        "tagId": 266,
        "tagName": "Nature Walk",
        "tagType": 2
      },
      {
        "tagId": 324,
        "tagName": "Local Village",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 871,
        "venueCategoryId": 32,
        "venueId": 3950,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3949,
    "venueCategoryId": 0,
    "status": 1,
    "title": "ADLER Spa Resort BALANCE",
    "description": "The ADLER Spa Resort BALANCE nestles in the \"most beautiful mountains in the world\". The peaceful location and an ambience of pure lightness not only create a holiday mood, they also ensure sustainable regeneration. The Spa Resort in the Dolomites is all about your health and the unity of body, mind and soul. Be the centre of attention for once and enjoy nature, gentle exercise and a multi-faceted range of spa packages.",
    "brand": "",
    "blockQuote": "There's a team of doctors dedicated to your health and wellbeing.",
    "emailAddress": "info@adler-balance.com",
    "website": "adler-resorts.com/en/adler-spa-resort-balance/58-0.html",
    "bookingURL": "https://booking.adler-resorts.com/be/en/adler-spa-resort-balance-ortisei/?Canale=&_ga=2.264455375.1729634196.1705908175-814124361.1705908175&iata=96027890",
    "redirectNotes": "ADLER Spa Resort BALANCE's reservations system",
    "phoneNumber": "+39 0471 775002 ",
    "addressOne": "5",
    "addressTwo": "Via Stufan",
    "city": "Ortisei",
    "county": "",
    "region": "Dolomites",
    "country": "Italy",
    "rooms": 30,
    "dollar": 2,
    "updated": "2024-01-22T11:21:14.507",
    "created": "2024-01-22T09:43:23.337",
    "medias": [
      {
        "mediaId": 65139,
        "title": "ADLER Spa Resort BALANCE - Spa - Condé Nast Johansens",
        "url": "https://pkslist.azureedge.net/media/images/1/f0ff5df8-5a59-46ad-95f8-05a8bf8f0808/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f0ff5df8-5a59-46ad-95f8-05a8bf8f0808/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f0ff5df8-5a59-46ad-95f8-05a8bf8f0808/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 65132,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/986920db-9927-45d8-9fad-19c75f074f16/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/986920db-9927-45d8-9fad-19c75f074f16/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/986920db-9927-45d8-9fad-19c75f074f16/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 65134,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a25e3c74-9775-46c4-8803-70b28be408b4/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a25e3c74-9775-46c4-8803-70b28be408b4/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a25e3c74-9775-46c4-8803-70b28be408b4/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 65133,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/762dd663-09db-4476-99b3-b4758e99db8d/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/762dd663-09db-4476-99b3-b4758e99db8d/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/762dd663-09db-4476-99b3-b4758e99db8d/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 65131,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/2d5d3274-ead3-4433-9882-43c8a1536669/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2d5d3274-ead3-4433-9882-43c8a1536669/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2d5d3274-ead3-4433-9882-43c8a1536669/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 65135,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/4c87b23f-423a-4e9f-b494-d97a361cb3a1/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4c87b23f-423a-4e9f-b494-d97a361cb3a1/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4c87b23f-423a-4e9f-b494-d97a361cb3a1/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 65136,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d93678dc-21d0-4986-a3b7-211f6350ac9c/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d93678dc-21d0-4986-a3b7-211f6350ac9c/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d93678dc-21d0-4986-a3b7-211f6350ac9c/256x192.jpeg",
        "viewOrder": 9
      },
      {
        "mediaId": 65137,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/5d759ea4-f762-4f94-b32c-6a5e172b15d8/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5d759ea4-f762-4f94-b32c-6a5e172b15d8/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5d759ea4-f762-4f94-b32c-6a5e172b15d8/256x192.jpeg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3730,
      "latitude": 46.5753143,
      "longitude": 11.6730217,
      "parentGeographicAreaId": 0,
      "title": "Via Stufan, 5, 39046 Ortisei BZ, Italy",
      "updated": null,
      "created": "2024-01-22T11:08:00"
    },
    "personalities": [
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 66,
        "tagName": "Skiing",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 127,
        "tagName": "Hair Salon",
        "tagType": 2
      },
      {
        "tagId": 159,
        "tagName": "Mountain Biking",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 219,
        "tagName": "Wine Cellar",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 299,
        "tagName": "Wellness Programs",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 870,
        "venueCategoryId": 32,
        "venueId": 3949,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3948,
    "venueCategoryId": 0,
    "status": 1,
    "title": "ADLER Spa Resort DOLOMITI",
    "description": "Between majestic peaks and lush vegetation, the ADLER spa resort Dolomiti nestles picturesquely into its surroundings. But it is not only its location in the Val Gardena holiday region - which has always drawn nature lovers - that makes it an extraordinary place of relaxation and power. It is also the expansive spa area and the feel-good ambiance that give you exactly what you need to lead you back to lightness during your time-out.",
    "brand": "",
    "blockQuote": "The must-visit spa boasts the largest water-based facilities in the Dolomites.",
    "emailAddress": "info@adler-dolomiti.com",
    "website": "adler-resorts.com/en/adler-spa-resort-dolomiti/43-0.html",
    "bookingURL": "https://booking.adler-resorts.com/be/en/adler-spa-resort-dolomiti-ortisei/?Canale=&_ga=2.7447922.1729634196.1705908175-814124361.1705908175&iata=96027890",
    "redirectNotes": "ADLER Spa Resort DOLOMITI's reservations system",
    "phoneNumber": "+39 0471 775 000",
    "addressOne": "7",
    "addressTwo": "Strada Rezia",
    "city": "Ortisei",
    "county": "",
    "region": "Dolomites",
    "country": "Italy",
    "rooms": 120,
    "dollar": 2,
    "updated": "2024-01-22T09:02:06.983",
    "created": "2024-01-22T08:21:44.11",
    "medias": [
      {
        "mediaId": 65112,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/94acc4c7-f6eb-4ccd-9161-8ab75c94bd1c/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/94acc4c7-f6eb-4ccd-9161-8ab75c94bd1c/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/94acc4c7-f6eb-4ccd-9161-8ab75c94bd1c/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 65122,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/93dd5dfe-a5db-4e74-9de3-b6741493bf89/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/93dd5dfe-a5db-4e74-9de3-b6741493bf89/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/93dd5dfe-a5db-4e74-9de3-b6741493bf89/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 65113,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/7ad4c447-5512-45ec-9fec-cdd60e360e28/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7ad4c447-5512-45ec-9fec-cdd60e360e28/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7ad4c447-5512-45ec-9fec-cdd60e360e28/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 65116,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c0698d7e-114c-475e-ac4c-0d6c85222d8b/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c0698d7e-114c-475e-ac4c-0d6c85222d8b/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c0698d7e-114c-475e-ac4c-0d6c85222d8b/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 65115,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/eb075877-7d2e-448d-9604-4f39eefcd76e/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/eb075877-7d2e-448d-9604-4f39eefcd76e/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/eb075877-7d2e-448d-9604-4f39eefcd76e/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 65118,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/3c45dc1a-907c-4a15-ab31-e19a8f5d42cf/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3c45dc1a-907c-4a15-ab31-e19a8f5d42cf/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3c45dc1a-907c-4a15-ab31-e19a8f5d42cf/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 65117,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/342e27e5-bd3b-4d5d-88f7-eca1927d731b/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/342e27e5-bd3b-4d5d-88f7-eca1927d731b/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/342e27e5-bd3b-4d5d-88f7-eca1927d731b/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 65127,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/20d0996c-910d-44b4-9274-b069f6dc1c8d/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/20d0996c-910d-44b4-9274-b069f6dc1c8d/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/20d0996c-910d-44b4-9274-b069f6dc1c8d/256x192.jpeg",
        "viewOrder": 10
      },
      {
        "mediaId": 65119,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/1ef219a3-e789-4d76-b602-0f78fbcf688f/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1ef219a3-e789-4d76-b602-0f78fbcf688f/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1ef219a3-e789-4d76-b602-0f78fbcf688f/256x192.jpeg",
        "viewOrder": 13
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3729,
      "latitude": 46.5744796,
      "longitude": 11.6716462,
      "parentGeographicAreaId": 0,
      "title": "Strada Rezia, 7, 39046 Ortisei BZ, Italy",
      "updated": null,
      "created": "2024-01-22T08:26:00"
    },
    "personalities": [
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 159,
        "tagName": "Mountain Biking",
        "tagType": 2
      },
      {
        "tagId": 276,
        "tagName": "Gym",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 299,
        "tagName": "Wellness Programs",
        "tagType": 2
      },
      {
        "tagId": 440,
        "tagName": "Unesco",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 869,
        "venueCategoryId": 32,
        "venueId": 3948,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3814,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Six Senses Vana",
    "description": "Immerse in a wellness retreat that enables you to feel, think, and be better. Through connecting to yourself, others, and nature. Moving your body, energy, and thoughts. Healing from what pains you. Accepting what is, to rebalance, rewild, and rechild.\n\nNamed after the forest we take refuge in, Six Senses Vana aims to protect and nurture, providing space to discover and flourish. Ayurveda, Yoga, Tibetan Medicine, and Natural Healing Therapies combine with a kind-hearted team and personalized support.",
    "brand": "",
    "blockQuote": "The excursion to the arti ceremony at Rishikesh is not to be missed.",
    "emailAddress": "reservations-vana@sixsenses.com",
    "website": "sixsenses.com/en/resorts/vana",
    "bookingURL": "https://www.sixsenses.com/en/resorts/vana/accomodation-results?newSearch=1&src=&Hotel=AMDVW&Rooms=1&Adult=2&Child=0&_PMID=96027890",
    "redirectNotes": "Six Senses Vana's reservations system",
    "phoneNumber": "+91 135 351 1111",
    "addressOne": "",
    "addressTwo": "Mussoorie Road",
    "city": "Uttarakhand",
    "county": "",
    "region": "",
    "country": "India",
    "rooms": 82,
    "dollar": 3,
    "updated": "2023-12-21T15:42:44.493",
    "created": "2023-12-12T06:34:17.28",
    "medias": [
      {
        "mediaId": 61957,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/fbaf92f3-ac6a-4ac3-8874-ee3c47cfcf9b/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fbaf92f3-ac6a-4ac3-8874-ee3c47cfcf9b/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fbaf92f3-ac6a-4ac3-8874-ee3c47cfcf9b/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 61956,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/7f24b7a8-5c19-45e4-8c9b-584310521a5a/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7f24b7a8-5c19-45e4-8c9b-584310521a5a/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7f24b7a8-5c19-45e4-8c9b-584310521a5a/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 61955,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/99ea6d94-0229-4380-bf52-0352a4e70407/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/99ea6d94-0229-4380-bf52-0352a4e70407/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/99ea6d94-0229-4380-bf52-0352a4e70407/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 61961,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d914878c-0fe9-47d5-ad2f-be98f522f9d8/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d914878c-0fe9-47d5-ad2f-be98f522f9d8/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d914878c-0fe9-47d5-ad2f-be98f522f9d8/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 61959,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ba5a321a-30fa-4bcb-8f08-019668ab8a2b/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ba5a321a-30fa-4bcb-8f08-019668ab8a2b/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ba5a321a-30fa-4bcb-8f08-019668ab8a2b/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 61960,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/bf37881a-3128-40df-9e21-e1085b711d7c/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bf37881a-3128-40df-9e21-e1085b711d7c/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bf37881a-3128-40df-9e21-e1085b711d7c/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 61963,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/66b0f4e1-d8cb-4f14-8ed1-c05aa77d9e16/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/66b0f4e1-d8cb-4f14-8ed1-c05aa77d9e16/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/66b0f4e1-d8cb-4f14-8ed1-c05aa77d9e16/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 61962,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/7eb9946d-d359-4a61-8f54-7811a67c23fa/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7eb9946d-d359-4a61-8f54-7811a67c23fa/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7eb9946d-d359-4a61-8f54-7811a67c23fa/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 61964,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d22b0dea-9af5-4a81-bbf7-355e0c625683/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d22b0dea-9af5-4a81-bbf7-355e0c625683/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d22b0dea-9af5-4a81-bbf7-355e0c625683/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 61966,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/fb657582-6587-4473-bc6c-0648c7ffc71f/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fb657582-6587-4473-bc6c-0648c7ffc71f/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fb657582-6587-4473-bc6c-0648c7ffc71f/256x192.jpeg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3604,
      "latitude": 30.3738886,
      "longitude": 78.07684619999999,
      "parentGeographicAreaId": 0,
      "title": "Mussoorie Rd, Malsi, Dehradun, Uttarakhand 248001, India",
      "updated": null,
      "created": "2023-12-21T15:43:00"
    },
    "personalities": [
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      },
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 276,
        "tagName": "Gym",
        "tagType": 2
      },
      {
        "tagId": 299,
        "tagName": "Wellness Programs",
        "tagType": 2
      },
      {
        "tagId": 320,
        "tagName": "Organic Garden",
        "tagType": 2
      },
      {
        "tagId": 351,
        "tagName": "Meditation",
        "tagType": 2
      },
      {
        "tagId": 400,
        "tagName": "Personal Training",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 846,
        "venueCategoryId": 32,
        "venueId": 3814,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3772,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Carillon Miami Wellness Resort",
    "description": "Welcome to Carillon Miami Wellness Resort, where our unique approach to wellness prioritizes indulgence and pleasure at every turn.\n\nCome for a nourishing journey for the body and soul. Align your physical and mental health at our cutting-edge wellness center. Dine on vibrantly fresh, organic cuisine at the ocean’s edge. And savor a stay at one of our modern luxury apartments, all situated on one of the longest beachfront stretches in the city.",
    "brand": "",
    "blockQuote": "It'll get your vote as the best spa in South Florida.",
    "emailAddress": "info@carillonhotel.com",
    "website": "carillonhotel.com/en/",
    "bookingURL": "https://reservations.carillonhotel.com/?_ga=2.119109504.829457020.1698053425-143141040.1698053425&_gl=1*1j6hipc*_ga*MTQzMTQxMDQwLjE2OTgwNTM0MjU.*_ga_X5TY2EMNF9*MTY5ODEyNzEzMS4yLjEuMTY5ODEyNzY4MC41OC4wLjA.&adult=1&arrive=2023-10-24&chain=24447&child=0&currency=USD&depart=2023-10-25&hotel=6973&level=hotel&locale=en-US&rooms=1&src=30",
    "redirectNotes": "Carillon Miami Wellness Resort's reservations system",
    "phoneNumber": "+1 786 319 9563",
    "addressOne": "6801",
    "addressTwo": "Collins Avenue",
    "city": "Miami",
    "county": "Miami Beach",
    "region": "Florida",
    "country": "United States",
    "rooms": 150,
    "dollar": 2,
    "updated": "2023-10-24T06:19:34.973",
    "created": "2023-10-24T05:59:19.58",
    "medias": [
      {
        "mediaId": 61114,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/48b6d4ba-e02c-4903-a0e8-6e6865957d5c/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/48b6d4ba-e02c-4903-a0e8-6e6865957d5c/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/48b6d4ba-e02c-4903-a0e8-6e6865957d5c/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 61112,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/6195fad8-255d-48cc-9a22-9cc6616e97b8/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6195fad8-255d-48cc-9a22-9cc6616e97b8/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6195fad8-255d-48cc-9a22-9cc6616e97b8/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 61115,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e58329f3-75a5-4cbc-add2-985d3827f9dc/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e58329f3-75a5-4cbc-add2-985d3827f9dc/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e58329f3-75a5-4cbc-add2-985d3827f9dc/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 61118,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/72b09d18-aa96-4190-8bfb-96c07df021fc/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/72b09d18-aa96-4190-8bfb-96c07df021fc/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/72b09d18-aa96-4190-8bfb-96c07df021fc/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 61119,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d002b2e3-2bb4-483a-9a82-13ab73f6a16c/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d002b2e3-2bb4-483a-9a82-13ab73f6a16c/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d002b2e3-2bb4-483a-9a82-13ab73f6a16c/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 61122,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a1cde541-33f5-4240-a85d-318e58678d73/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a1cde541-33f5-4240-a85d-318e58678d73/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a1cde541-33f5-4240-a85d-318e58678d73/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 61116,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/6989ea4a-a0da-4172-bd89-27983ef3db37/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6989ea4a-a0da-4172-bd89-27983ef3db37/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6989ea4a-a0da-4172-bd89-27983ef3db37/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 61111,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/445bc9ed-f9de-4232-8b5c-7d2ed309201e/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/445bc9ed-f9de-4232-8b5c-7d2ed309201e/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/445bc9ed-f9de-4232-8b5c-7d2ed309201e/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 61117,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d398ab62-e82d-48ee-9a2e-9391c3170bee/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d398ab62-e82d-48ee-9a2e-9391c3170bee/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d398ab62-e82d-48ee-9a2e-9391c3170bee/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 61120,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/3ef873a7-12a0-48b4-9cae-93358f307ade/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3ef873a7-12a0-48b4-9cae-93358f307ade/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3ef873a7-12a0-48b4-9cae-93358f307ade/256x192.jpeg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3553,
      "latitude": 25.8529812,
      "longitude": -80.1201794,
      "parentGeographicAreaId": 0,
      "title": "6801 Collins Ave, Miami Beach, FL 33141, USA",
      "updated": null,
      "created": "2023-10-24T06:07:00"
    },
    "personalities": [
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      },
      {
        "tagId": 53,
        "tagName": "Cutting Edge",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 58,
        "tagName": "Beach",
        "tagType": 2
      },
      {
        "tagId": 81,
        "tagName": "Golf",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 109,
        "tagName": "Fishing",
        "tagType": 2
      },
      {
        "tagId": 243,
        "tagName": "Outdoor Activities",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 299,
        "tagName": "Wellness Programs",
        "tagType": 2
      },
      {
        "tagId": 400,
        "tagName": "Personal Training",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 842,
        "venueCategoryId": 32,
        "venueId": 3772,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3710,
    "venueCategoryId": 0,
    "status": 1,
    "title": "The Original FX Mayr",
    "description": "The Original FX Mayr is located in Austria, on a hill overlooking the southern shore of Lake Wörthersee.\n\nTHE ORIGINAL – Our house was the first centre for modern Mayr medicine and is one of the world’s leading resort for enhanced medical diagnostics and holistic health care.\n\nIt is ideal place to detox, strengthen intestinal health and the immune system – and to find new balance and inner peace.",
    "brand": "",
    "blockQuote": "This legendary cleansing clinic is where you'll dramatically cleanse the digestive system and achieve satisfying results on health and weight loss.",
    "emailAddress": "info@original-mayr.com",
    "website": "original-mayr.com/en/",
    "bookingURL": "https://www.original-mayr.com/en/reservation-request/",
    "redirectNotes": "The Original FX Mayr's reservations system",
    "phoneNumber": "+43 4273 25110",
    "addressOne": "2",
    "addressTwo": "Golfstraße",
    "city": "Maria Worth",
    "county": "",
    "region": "",
    "country": "Austria",
    "rooms": 47,
    "dollar": 3,
    "updated": "2023-09-04T09:18:47.91",
    "created": "2023-09-04T08:40:44.023",
    "medias": [
      {
        "mediaId": 59703,
        "title": "Review: The Original FX Mayr -",
        "url": "https://pkslist.azureedge.net/media/images/1/39c456fe-dda5-41a8-a605-b9b7cdc53328/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/39c456fe-dda5-41a8-a605-b9b7cdc53328/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/39c456fe-dda5-41a8-a605-b9b7cdc53328/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 59706,
        "title": "Home | The Original FX Mayr",
        "url": "https://pkslist.azureedge.net/media/images/1/fe997608-2df1-422f-88c6-2a18524ef9e7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fe997608-2df1-422f-88c6-2a18524ef9e7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fe997608-2df1-422f-88c6-2a18524ef9e7/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 59704,
        "title": "THE BEST Maria Worth Beach Suite Hotels of 2023 (with Prices ...",
        "url": "https://pkslist.azureedge.net/media/images/1/f947290e-79e6-404c-95f7-5b883a18d9b5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f947290e-79e6-404c-95f7-5b883a18d9b5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f947290e-79e6-404c-95f7-5b883a18d9b5/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 59689,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/091187ac-8b89-4f58-beda-871517d60951/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/091187ac-8b89-4f58-beda-871517d60951/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/091187ac-8b89-4f58-beda-871517d60951/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 59708,
        "title": "Review: The Original FX Mayr -",
        "url": "https://pkslist.azureedge.net/media/images/1/521a53fd-86e0-43bb-8023-d96eacf8fefe/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/521a53fd-86e0-43bb-8023-d96eacf8fefe/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/521a53fd-86e0-43bb-8023-d96eacf8fefe/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 59693,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/7ac7a1af-80b8-43b5-b087-a47e2847994e/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7ac7a1af-80b8-43b5-b087-a47e2847994e/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7ac7a1af-80b8-43b5-b087-a47e2847994e/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 59694,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d687f384-be0c-44c9-b097-a9a2a8c3f3b9/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d687f384-be0c-44c9-b097-a9a2a8c3f3b9/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d687f384-be0c-44c9-b097-a9a2a8c3f3b9/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 59705,
        "title": "Mayr Resort | Gallery | The Original FX Mayr",
        "url": "https://pkslist.azureedge.net/media/images/1/ba67430c-08d8-4e6d-944f-57bbeee7300e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ba67430c-08d8-4e6d-944f-57bbeee7300e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ba67430c-08d8-4e6d-944f-57bbeee7300e/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 59698,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/fe46fe4c-7a5e-4a5c-ad01-ce68f8f93b11/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fe46fe4c-7a5e-4a5c-ad01-ce68f8f93b11/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fe46fe4c-7a5e-4a5c-ad01-ce68f8f93b11/256x192.jpeg",
        "viewOrder": 10
      },
      {
        "mediaId": 59700,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/3dd271c2-1ac6-4984-b0c9-c3f29bc4c6c8/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3dd271c2-1ac6-4984-b0c9-c3f29bc4c6c8/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3dd271c2-1ac6-4984-b0c9-c3f29bc4c6c8/256x192.jpeg",
        "viewOrder": 11
      },
      {
        "mediaId": 59699,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/43a4fd5a-32e7-4319-8acc-5f152eff1a88/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/43a4fd5a-32e7-4319-8acc-5f152eff1a88/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/43a4fd5a-32e7-4319-8acc-5f152eff1a88/256x192.jpeg",
        "viewOrder": 12
      },
      {
        "mediaId": 59710,
        "title": "Home | The Original FX Mayr",
        "url": "https://pkslist.azureedge.net/media/images/1/eda3c300-fcc2-4360-8f25-7bd617186c4f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/eda3c300-fcc2-4360-8f25-7bd617186c4f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/eda3c300-fcc2-4360-8f25-7bd617186c4f/256x192.jpg",
        "viewOrder": 13
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3491,
      "latitude": 46.6234139,
      "longitude": 14.1220885,
      "parentGeographicAreaId": 0,
      "title": "Golfstraße 2, 9082 Oberdellach, Austria",
      "updated": null,
      "created": "2023-09-04T08:54:00"
    },
    "personalities": [
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      },
      {
        "tagId": 53,
        "tagName": "Cutting Edge",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 77,
        "tagName": "Cooking Classes",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 299,
        "tagName": "Wellness Programs",
        "tagType": 2
      },
      {
        "tagId": 300,
        "tagName": "Pilates",
        "tagType": 2
      },
      {
        "tagId": 317,
        "tagName": "Gourmet",
        "tagType": 2
      },
      {
        "tagId": 351,
        "tagName": "Meditation",
        "tagType": 2
      },
      {
        "tagId": 357,
        "tagName": "Sun Terrace",
        "tagType": 2
      },
      {
        "tagId": 400,
        "tagName": "Personal Training",
        "tagType": 2
      },
      {
        "tagId": 441,
        "tagName": "E-Bike",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 837,
        "venueCategoryId": 32,
        "venueId": 3710,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3640,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Cap Vermell Grand Hotel",
    "description": "Cap Vermell Grand Hotel is a luxury retreat that reflects the style of a traditional Mallorquin hilltop village with references of local art and cultural heritage. Situated in a tranquil valley surrounded by natural beauty, the resort offers a place of escapism and promises to create unique and mindful experiences for all guests.",
    "brand": "",
    "blockQuote": "Dine at VORO, the 2-Star Michelin rated restaurant for a delightful gastronomic experience.",
    "emailAddress": "reservations.grandhotel@capvermell.com",
    "website": "capvermellgrandhotel.com/",
    "bookingURL": "https://be.synxis.com/?adult=1&agencyid=96027890&arrive=2023-06-26&chain=24447&child=0&currency=EUR&depart=2023-06-30&hotel=32300&level=hotel&locale=en-US&rooms=1&src=30",
    "redirectNotes": "Cap Vermell Grand Hotel's reservations system",
    "phoneNumber": "+34 871 811 222",
    "addressOne": "12",
    "addressTwo": "Vial A",
    "city": "Canyamel",
    "county": "",
    "region": "Mallorca",
    "country": "Spain",
    "rooms": 142,
    "dollar": 2,
    "updated": "2023-08-05T10:57:26.533",
    "created": "2023-06-26T21:02:02.423",
    "medias": [
      {
        "mediaId": 58297,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/4cdeae84-4164-4d8e-a79f-702d69616657/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4cdeae84-4164-4d8e-a79f-702d69616657/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4cdeae84-4164-4d8e-a79f-702d69616657/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 58287,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/0656f7f3-bfcd-414b-be8f-62326b483055/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0656f7f3-bfcd-414b-be8f-62326b483055/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0656f7f3-bfcd-414b-be8f-62326b483055/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 58302,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a460a872-fca1-4886-97e9-c4055b6a37b8/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a460a872-fca1-4886-97e9-c4055b6a37b8/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a460a872-fca1-4886-97e9-c4055b6a37b8/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 58290,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ed10274d-8e7a-4dc3-a8a1-7e2acb9baa9e/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ed10274d-8e7a-4dc3-a8a1-7e2acb9baa9e/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ed10274d-8e7a-4dc3-a8a1-7e2acb9baa9e/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 58301,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/3f5dcafd-d04f-4a9e-aaf1-1fdf3c9a9767/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3f5dcafd-d04f-4a9e-aaf1-1fdf3c9a9767/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3f5dcafd-d04f-4a9e-aaf1-1fdf3c9a9767/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 58296,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/94ac8239-c266-439a-bd48-f66688c76eec/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/94ac8239-c266-439a-bd48-f66688c76eec/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/94ac8239-c266-439a-bd48-f66688c76eec/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 58292,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/707d5724-2161-4ce6-b717-1e8fe1ef9b7c/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/707d5724-2161-4ce6-b717-1e8fe1ef9b7c/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/707d5724-2161-4ce6-b717-1e8fe1ef9b7c/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 58294,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/bc5bfdd3-9a05-4202-a290-56a26ffea2a3/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bc5bfdd3-9a05-4202-a290-56a26ffea2a3/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bc5bfdd3-9a05-4202-a290-56a26ffea2a3/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 58305,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/733991a4-f209-4f4b-9e61-466950dbfb1a/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/733991a4-f209-4f4b-9e61-466950dbfb1a/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/733991a4-f209-4f4b-9e61-466950dbfb1a/256x192.jpeg",
        "viewOrder": 9
      },
      {
        "mediaId": 58300,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a6167c56-ca36-42e6-92d5-8a3fc46349f1/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a6167c56-ca36-42e6-92d5-8a3fc46349f1/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a6167c56-ca36-42e6-92d5-8a3fc46349f1/256x192.jpeg",
        "viewOrder": 10
      },
      {
        "mediaId": 58304,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/2bc12c14-521c-4bed-86fe-7cc6a4fdf043/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2bc12c14-521c-4bed-86fe-7cc6a4fdf043/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2bc12c14-521c-4bed-86fe-7cc6a4fdf043/256x192.jpeg",
        "viewOrder": 11
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3424,
      "latitude": 39.6689517,
      "longitude": 3.4395414,
      "parentGeographicAreaId": 0,
      "title": "Urb. Atalaya de, Mallorca, Vial A, 12, 07589 Canyamel, Islas Baleares, Spain",
      "updated": null,
      "created": "2023-08-05T10:57:00"
    },
    "personalities": [
      {
        "tagId": 57,
        "tagName": "Romantic",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 58,
        "tagName": "Beach",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 222,
        "tagName": "Michelin Star dining",
        "tagType": 2
      },
      {
        "tagId": 276,
        "tagName": "Gym",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 424,
        "tagName": "Wellness Retreat",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 814,
        "venueCategoryId": 32,
        "venueId": 3640,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3589,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Zulal Wellness Resort by Chiva-Som",
    "description": "Zulal Wellness Resort by Chiva-Som is a pioneering destination in the Middle East that blends Traditional Arabic & Islamic Medicine with a holistic health and well-being philosophy, established by Chiva-Som, the internationally acclaimed wellness resort in Thailand. We welcome guests from all over the world to our coastal home on the northern tip of Qatar, where we offer two distinct yet interconnecting experiences. Zulal Serenity is dedicated to adult guests seeking an immersive health and lifestyle reset, while Zulal Discovery invites families to connect and embark on a wellness journey together. We aim to inspire you with positive and sustainable lifestyle change.",
    "brand": "",
    "blockQuote": "Zulal Discovery is for families with kids of all ages and Zulal Serenity is perfect if your children are 16 years plus.",
    "emailAddress": "reservations@zulal.com",
    "website": "zulal.com/",
    "bookingURL": "https://be.synxis.com/?adult=1&agencyid=96027890&arrive=2023-02-02&chain=21936&child=0&currency=QAR&depart=2023-02-03&hotel=30328&level=hotel&locale=en-US&rooms=1",
    "redirectNotes": "Zulal Wellness Resort by Chiva-Som's reservations system",
    "phoneNumber": "+974 44 77 6500",
    "addressOne": "",
    "addressTwo": "",
    "city": "Al Ruwais",
    "county": "",
    "region": "",
    "country": "Qatar",
    "rooms": 180,
    "dollar": 2,
    "updated": "2023-02-01T13:27:04.02",
    "created": "2023-02-01T12:46:07.483",
    "medias": [
      {
        "mediaId": 57278,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/904dd2fb-d66a-4fe4-b4c2-ebd02b1a02be/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/904dd2fb-d66a-4fe4-b4c2-ebd02b1a02be/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/904dd2fb-d66a-4fe4-b4c2-ebd02b1a02be/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 57281,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/1abba779-513a-4814-adb6-1ef682c000a2/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1abba779-513a-4814-adb6-1ef682c000a2/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1abba779-513a-4814-adb6-1ef682c000a2/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 57288,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a3c450fe-82f9-4bf2-8f84-d87cd16012f0/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a3c450fe-82f9-4bf2-8f84-d87cd16012f0/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a3c450fe-82f9-4bf2-8f84-d87cd16012f0/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 57287,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/7b49bf52-5c93-4934-9b65-5eb0c980b6a9/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7b49bf52-5c93-4934-9b65-5eb0c980b6a9/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7b49bf52-5c93-4934-9b65-5eb0c980b6a9/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 57290,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/bab6203a-af19-4879-ad5f-400daf22ce66/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bab6203a-af19-4879-ad5f-400daf22ce66/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bab6203a-af19-4879-ad5f-400daf22ce66/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 57283,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/4af4785d-73ea-4c8b-a5b7-f24840f34974/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4af4785d-73ea-4c8b-a5b7-f24840f34974/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4af4785d-73ea-4c8b-a5b7-f24840f34974/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 57277,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/52098407-dd79-4c40-b4eb-380e14e1ce86/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/52098407-dd79-4c40-b4eb-380e14e1ce86/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/52098407-dd79-4c40-b4eb-380e14e1ce86/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 57284,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/03ed7690-d379-4b46-8318-1549e11ef372/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/03ed7690-d379-4b46-8318-1549e11ef372/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/03ed7690-d379-4b46-8318-1549e11ef372/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 57285,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/66404983-2b49-4ff1-a0c1-68834969598e/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/66404983-2b49-4ff1-a0c1-68834969598e/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/66404983-2b49-4ff1-a0c1-68834969598e/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 57289,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/995ff31f-d24d-4aaf-b6ef-0ebffaabb1d3/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/995ff31f-d24d-4aaf-b6ef-0ebffaabb1d3/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/995ff31f-d24d-4aaf-b6ef-0ebffaabb1d3/256x192.jpeg",
        "viewOrder": 9
      },
      {
        "mediaId": 57292,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/12a21f82-b29b-4d4a-a339-ed4ec0ddb4ba/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/12a21f82-b29b-4d4a-a339-ed4ec0ddb4ba/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/12a21f82-b29b-4d4a-a339-ed4ec0ddb4ba/256x192.jpeg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3377,
      "latitude": 26.1551154,
      "longitude": 51.241833,
      "parentGeographicAreaId": 0,
      "title": "Madīnat ash Shamāl، Al Ruwais, Qatar",
      "updated": null,
      "created": "2023-02-01T12:56:00"
    },
    "personalities": [
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 206,
        "tagName": "Private Beach",
        "tagType": 2
      },
      {
        "tagId": 357,
        "tagName": "Sun Terrace",
        "tagType": 2
      },
      {
        "tagId": 424,
        "tagName": "Wellness Retreat",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 801,
        "venueCategoryId": 32,
        "venueId": 3589,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3565,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Fairmont Taghazout Bay",
    "description": "Fairmont Taghazout Bay is a unique resort overlooking the Atlantic Ocean. It is where memories are created. A luxury five-star wellness resort nestled at the heart of Taghazout Bay with exceptional leisure facilities, invites you to sit back and enjoy every moment in an idyllic piece of heaven. \n\nWe offer a seductive blend of style and serenity, with a range of gourmet restaurants and bars, a rejuvenating Fairmont Spa, a fitness centre, a kids club and a teens club, along with choices of rooms, suites and villas all with an ocean view.",
    "brand": "",
    "blockQuote": "For a unique experience, why not try your skills at sand boarding?",
    "emailAddress": "Taghazout.Reservations@fairmont.com",
    "website": "fairmont.com/taghazout/",
    "bookingURL": "https://all.accor.com/ssr/app/accor/rates/A7Z3/index.en.shtml?dateIn=2022-12-07&nights=1&compositions=2&stayplus=false&snu=false&accessibleRooms=false&otConsentString=,C0001,C0003,C0007,C0002,C0004,&partner_id=fairmont&_gl=1%2a141ndsp%2a_ga%2aMjA5NDY0OTIyMC4xNjY5MTk3Nzgx%2a_ga_WPLEEQEV59%2aMTY3MDQxMzIyNC4yLjEuMTY3MDQxMzQyNy4xOS4wLjA.&_ga=2.170665058.398503273.1670413223-2094649220.1669197781&otConsentId=b82fcfbf-e87c-4e89-9278-9e60876a0cdb&iata=96027890",
    "redirectNotes": "Fairmont Taghazout Bay's reservations system",
    "phoneNumber": "+212 5282 82828",
    "addressOne": "Km 17",
    "addressTwo": "Route D'Essaouira",
    "city": "Taghazout",
    "county": "",
    "region": "",
    "country": "Morocco",
    "rooms": 146,
    "dollar": 1,
    "updated": "2022-12-07T12:04:09.177",
    "created": "2022-12-07T11:41:45.68",
    "medias": [
      {
        "mediaId": 56807,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d4518313-68c8-4ba6-a0d8-b4af4a85748e/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d4518313-68c8-4ba6-a0d8-b4af4a85748e/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d4518313-68c8-4ba6-a0d8-b4af4a85748e/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 56810,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/0ee964b6-3861-4c28-b9e4-36e0c97fd598/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0ee964b6-3861-4c28-b9e4-36e0c97fd598/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0ee964b6-3861-4c28-b9e4-36e0c97fd598/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 56805,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/97c02b41-8d5a-42d4-88b8-a2adeb3fc7ac/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/97c02b41-8d5a-42d4-88b8-a2adeb3fc7ac/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/97c02b41-8d5a-42d4-88b8-a2adeb3fc7ac/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 56806,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/2918f6fd-0c9a-4cb7-8f54-b55210c03a38/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2918f6fd-0c9a-4cb7-8f54-b55210c03a38/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2918f6fd-0c9a-4cb7-8f54-b55210c03a38/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 56814,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a6aff2b6-cf7e-456c-8fc4-44b90f3ebacb/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a6aff2b6-cf7e-456c-8fc4-44b90f3ebacb/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a6aff2b6-cf7e-456c-8fc4-44b90f3ebacb/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 56813,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/820029d7-385d-46d7-991e-4f7339017424/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/820029d7-385d-46d7-991e-4f7339017424/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/820029d7-385d-46d7-991e-4f7339017424/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 56812,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/7a22c53f-c9e9-409b-924a-633ac4eaec73/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7a22c53f-c9e9-409b-924a-633ac4eaec73/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7a22c53f-c9e9-409b-924a-633ac4eaec73/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 56815,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b6bae6e1-ec35-4556-83ff-91d7453dd0ea/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b6bae6e1-ec35-4556-83ff-91d7453dd0ea/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b6bae6e1-ec35-4556-83ff-91d7453dd0ea/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 56816,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/025c2891-6c4c-463e-b54e-6693b9dfcbe5/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/025c2891-6c4c-463e-b54e-6693b9dfcbe5/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/025c2891-6c4c-463e-b54e-6693b9dfcbe5/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 56817,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f240df91-3687-40ca-b98e-292657589ff3/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f240df91-3687-40ca-b98e-292657589ff3/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f240df91-3687-40ca-b98e-292657589ff3/256x192.jpeg",
        "viewOrder": 9
      },
      {
        "mediaId": 56818,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/253001dc-b911-4d45-bbde-0e7a54ba53cd/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/253001dc-b911-4d45-bbde-0e7a54ba53cd/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/253001dc-b911-4d45-bbde-0e7a54ba53cd/256x192.jpeg",
        "viewOrder": 10
      },
      {
        "mediaId": 56820,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/20685e62-c30b-4d1f-b4a0-96a6927f1fbb/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/20685e62-c30b-4d1f-b4a0-96a6927f1fbb/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/20685e62-c30b-4d1f-b4a0-96a6927f1fbb/256x192.jpeg",
        "viewOrder": 12
      },
      {
        "mediaId": 56821,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ab5192fe-bf05-41ad-84ff-9355eff3d147/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ab5192fe-bf05-41ad-84ff-9355eff3d147/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ab5192fe-bf05-41ad-84ff-9355eff3d147/256x192.jpeg",
        "viewOrder": 13
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3353,
      "latitude": 30.5170267,
      "longitude": -9.6861698,
      "parentGeographicAreaId": 0,
      "title": "Station Touristique, Km 17 Route D'Essaouira, Taghazout 80750, Morocco",
      "updated": null,
      "created": "2022-12-07T11:47:00"
    },
    "personalities": [
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 81,
        "tagName": "Golf",
        "tagType": 2
      },
      {
        "tagId": 83,
        "tagName": "Kid's Club",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 172,
        "tagName": "Biking",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 424,
        "tagName": "Wellness Retreat",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 798,
        "venueCategoryId": 32,
        "venueId": 3565,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3494,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Althoff Seehotel Uberfahrt",
    "description": "e welcome you to the Elegant Nature Resort at the Tegernsee. Only 45 minutes by car from Munich in Rottach-Egern, we offer you everything and more for a perfect time here. Among other things: one of the most beautiful spas in Europe and a multiple award-winning star cuisine. Enjoy exceptional and multiple award-winning culinary delights in our five restaurants.",
    "brand": "",
    "blockQuote": "A day of luxurious pampering in the 3000m² spa is a must.",
    "emailAddress": "info@seehotel-ueberfahrt.com",
    "website": "althoffcollection.com/en/althoff-seehotel-ueberfahrt",
    "bookingURL": "https://www.althoffcollection.com/althoff-seehotel-ueberfahrt/booking/?lang=en",
    "redirectNotes": "Althoff Seehotel Uberfahrt's reservations system",
    "phoneNumber": "+49 8022 6690",
    "addressOne": "10",
    "addressTwo": "Überfahrtstraße",
    "city": "Rottach-Egern",
    "county": "",
    "region": "",
    "country": "Germany",
    "rooms": 176,
    "dollar": 2,
    "updated": "2022-10-24T14:44:17.543",
    "created": "2022-10-24T14:17:05.413",
    "medias": [
      {
        "mediaId": 55370,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/3b3de0a4-8e5a-45d1-988c-5f0bbc29f284/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3b3de0a4-8e5a-45d1-988c-5f0bbc29f284/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3b3de0a4-8e5a-45d1-988c-5f0bbc29f284/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 55372,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d8150c01-f4a4-4d45-aefa-cddb676f7b8b/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d8150c01-f4a4-4d45-aefa-cddb676f7b8b/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d8150c01-f4a4-4d45-aefa-cddb676f7b8b/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 55380,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b05e6fd2-c436-432f-9c5d-ddb5eb1b9282/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b05e6fd2-c436-432f-9c5d-ddb5eb1b9282/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b05e6fd2-c436-432f-9c5d-ddb5eb1b9282/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 55374,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/8b6c7ec7-c836-4442-99ec-e78a4fc10acc/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8b6c7ec7-c836-4442-99ec-e78a4fc10acc/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8b6c7ec7-c836-4442-99ec-e78a4fc10acc/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 55375,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e0bfd40b-ca59-4979-9676-eee7a3417aef/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e0bfd40b-ca59-4979-9676-eee7a3417aef/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e0bfd40b-ca59-4979-9676-eee7a3417aef/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 55376,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/4ac8795a-ad27-4081-9e03-d5d81b561127/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4ac8795a-ad27-4081-9e03-d5d81b561127/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4ac8795a-ad27-4081-9e03-d5d81b561127/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 55373,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e86f5037-9a7a-4e5f-9c48-85ed0f592440/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e86f5037-9a7a-4e5f-9c48-85ed0f592440/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e86f5037-9a7a-4e5f-9c48-85ed0f592440/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 55377,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/05d86734-35f0-44b9-a854-9a070cf2b7aa/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/05d86734-35f0-44b9-a854-9a070cf2b7aa/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/05d86734-35f0-44b9-a854-9a070cf2b7aa/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 55379,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/12a9c36f-2846-48e2-9dcc-73567bf79d4d/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/12a9c36f-2846-48e2-9dcc-73567bf79d4d/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/12a9c36f-2846-48e2-9dcc-73567bf79d4d/256x192.jpeg",
        "viewOrder": 9
      },
      {
        "mediaId": 55378,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/23dc238d-88a5-4aae-89c4-2b5885e14635/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/23dc238d-88a5-4aae-89c4-2b5885e14635/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/23dc238d-88a5-4aae-89c4-2b5885e14635/256x192.jpeg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3282,
      "latitude": 47.696585,
      "longitude": 11.7584318,
      "parentGeographicAreaId": 0,
      "title": "Überfahrtstraße 10, 83700 Rottach-Egern, Germany",
      "updated": null,
      "created": "2022-10-24T14:24:00"
    },
    "personalities": [
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      },
      {
        "tagId": 57,
        "tagName": "Romantic",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 166,
        "tagName": "Lakeside",
        "tagType": 2
      },
      {
        "tagId": 206,
        "tagName": "Private Beach",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 222,
        "tagName": "Michelin Star dining",
        "tagType": 2
      },
      {
        "tagId": 234,
        "tagName": "Sailing",
        "tagType": 2
      },
      {
        "tagId": 266,
        "tagName": "Nature Walk",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 794,
        "venueCategoryId": 32,
        "venueId": 3494,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3454,
    "venueCategoryId": 0,
    "status": 1,
    "title": "The Ranch at Palazzo Fiuggi",
    "description": "Experience The Ranch’s transformational weekly wellness program in a historic property, nestled in one of Italy’s richest naturalistic areas, just 50 minutes from Rome. Offered 52 weeks/year, The Ranch Italy at Palazzo Fiuggi combines an award-winning, results-oriented fitness program with the services and expertise of the esteemed team at this renowned medical wellness retreat. The result is an immersive and transformative program that gives guests a more complete picture of their overall health and longevity. You’ll leave with a greater sense of self, feeling rested and rejuvenated while having lost weight and gaining muscle.\n\nGuests of The Ranch program can also enjoy the Palazzo Fiuggi’s panoramic indoor and outdoor pools, that feature views of Fiuggi “old town.” A highlight of the experience is Fiuggi’s world-class spa, or Roman Thermae which includes the use of hydrotherapy and Thalasso pools featuring the ancient healing waters of the area.  Additional medical services or spa treatments can be experienced during the week at an additional rate.\n\nLimited to 25 guests each week, this 7-night, 8-day experience features a daily schedule that mirrors The Ranch Malibu’s signature no-options program.",
    "brand": "",
    "blockQuote": "A must is a day at Palazzo Fiuggi’s world-class spa and Roman Thermae.",
    "emailAddress": "inquiry@theranchmalibu.com",
    "website": "theranchmalibu.com/health-fitness-programs/the-ranch-italy/",
    "bookingURL": "https://theranchmalibu.com/retreat-reservations/reserve-italy/",
    "redirectNotes": "The Ranch at Palazzo Fiuggi's reservations system",
    "phoneNumber": "+39 0775 7661",
    "addressOne": "34",
    "addressTwo": "Via dei Villini",
    "city": "Fiuggi",
    "county": "",
    "region": "",
    "country": "Italy",
    "rooms": 25,
    "dollar": 3,
    "updated": "2023-10-19T08:40:00.543",
    "created": "2022-08-30T09:48:54.15",
    "medias": [
      {
        "mediaId": 54570,
        "title": "Palazzo Fiuggi for a longer life, better lived",
        "url": "https://pkslist.azureedge.net/media/images/1/0b3586e5-133c-47a1-8534-92c591ad27ac/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0b3586e5-133c-47a1-8534-92c591ad27ac/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0b3586e5-133c-47a1-8534-92c591ad27ac/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 54565,
        "title": "Spa Of The Month: The Ranch Italy At Palazzo Fiuggi | Citizen Femme",
        "url": "https://pkslist.azureedge.net/media/images/1/679aea96-0018-4690-83d0-5676e7521915/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/679aea96-0018-4690-83d0-5676e7521915/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/679aea96-0018-4690-83d0-5676e7521915/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 54576,
        "title": "The Ranch Italy | Fitness, Wellness & Health Retreat Programs ...",
        "url": "https://pkslist.azureedge.net/media/images/1/2502e3ce-bec4-49c4-a581-690e52de3ab3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2502e3ce-bec4-49c4-a581-690e52de3ab3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2502e3ce-bec4-49c4-a581-690e52de3ab3/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 54560,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ea16a11d-2e43-4f08-81c7-948c2a17c914/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ea16a11d-2e43-4f08-81c7-948c2a17c914/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ea16a11d-2e43-4f08-81c7-948c2a17c914/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 54562,
        "title": "The Ranch Italy | Fitness, Wellness & Health Retreat Programs ...",
        "url": "https://pkslist.azureedge.net/media/images/1/bcb2cb09-4263-4f76-8136-a58661d9e1bc/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bcb2cb09-4263-4f76-8136-a58661d9e1bc/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bcb2cb09-4263-4f76-8136-a58661d9e1bc/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 54571,
        "title": "The Ranch Malibu to Debut Celebrity Favorite Program in Italy ...",
        "url": "https://pkslist.azureedge.net/media/images/1/f835bc00-8d3e-4966-a801-aa937e8353cd/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f835bc00-8d3e-4966-a801-aa937e8353cd/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f835bc00-8d3e-4966-a801-aa937e8353cd/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 54567,
        "title": "Introducing The Ranch Italy - Hidden Doorways | Hidden Doorways",
        "url": "https://pkslist.azureedge.net/media/images/1/0c0a066f-d595-46d4-9a8f-4b5e2536c30a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0c0a066f-d595-46d4-9a8f-4b5e2536c30a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0c0a066f-d595-46d4-9a8f-4b5e2536c30a/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 54569,
        "title": "The Ranch Italy | Fitness, Wellness & Health Retreat Programs ...",
        "url": "https://pkslist.azureedge.net/media/images/1/825ad08c-58b9-4722-80c9-036ea0387a32/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/825ad08c-58b9-4722-80c9-036ea0387a32/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/825ad08c-58b9-4722-80c9-036ea0387a32/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 54572,
        "title": "The Ranch Malibu Is Opening a Location Near Rome, Italy – Robb Report",
        "url": "https://pkslist.azureedge.net/media/images/1/88ffae10-4845-4b84-a0e8-7c81dde61312/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/88ffae10-4845-4b84-a0e8-7c81dde61312/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/88ffae10-4845-4b84-a0e8-7c81dde61312/256x192.jpg",
        "viewOrder": 11
      },
      {
        "mediaId": 54573,
        "title": "Spa Of The Month: The Ranch Italy At Palazzo Fiuggi | Citizen Femme",
        "url": "https://pkslist.azureedge.net/media/images/1/a19e80b8-8e3b-469c-ba4b-817dc4e0fa3c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a19e80b8-8e3b-469c-ba4b-817dc4e0fa3c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a19e80b8-8e3b-469c-ba4b-817dc4e0fa3c/256x192.jpg",
        "viewOrder": 12
      },
      {
        "mediaId": 54568,
        "title": "5 Luxury Health & Wellness Medical Travel Retreats",
        "url": "https://pkslist.azureedge.net/media/images/1/13e07367-11e6-4826-b71b-3d8c4cc6b395/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/13e07367-11e6-4826-b71b-3d8c4cc6b395/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/13e07367-11e6-4826-b71b-3d8c4cc6b395/256x192.jpg",
        "viewOrder": 13
      },
      {
        "mediaId": 54579,
        "title": "Erstmals international: The Ranch kommt ab Mai ins Palazzo Fiuggi",
        "url": "https://pkslist.azureedge.net/media/images/1/b1d8765c-276d-4119-9a7c-4488fd6a8e87/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b1d8765c-276d-4119-9a7c-4488fd6a8e87/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b1d8765c-276d-4119-9a7c-4488fd6a8e87/256x192.jpg",
        "viewOrder": 15
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3241,
      "latitude": 41.7957382,
      "longitude": 13.2194871,
      "parentGeographicAreaId": 0,
      "title": "Via dei Villini, 34, 03014 Fiuggi FR, Italy",
      "updated": null,
      "created": "2022-08-30T10:11:00"
    },
    "personalities": [
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      },
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 243,
        "tagName": "Outdoor Activities",
        "tagType": 2
      },
      {
        "tagId": 268,
        "tagName": "Massage",
        "tagType": 2
      },
      {
        "tagId": 299,
        "tagName": "Wellness Programs",
        "tagType": 2
      },
      {
        "tagId": 317,
        "tagName": "Gourmet",
        "tagType": 2
      },
      {
        "tagId": 351,
        "tagName": "Meditation",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 789,
        "venueCategoryId": 32,
        "venueId": 3454,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3453,
    "venueCategoryId": 0,
    "status": 1,
    "title": "The Ranch Malibu",
    "description": "Nestled in nature with no distractions, The Ranch Malibu spans 200 acres in the Santa Monica Mountains, and provides the perfect setting to calm the mind, return to nature and focus on your mental and physical health goals during the weeklong program. Set on a historic working ranch three miles above the Pacific Ocean, the sprawling grounds are designed to be rustic, yet refined and feature an open-air kitchen and great room, certified organic garden, pool and jacuzzi, Buddha Barn and massage “village.” There are also more unique health services and treatments offered including IV Therapy, cholesterol testing, a cold plunge pool and cryotherapy saunas. Guests are in a sequestered environment with no televisions, no cell and limited wi-fi service available to further aid in mental detoxification.\n\nFollowing the invigorating daily fitness schedule, retreat to one of 21 private cottages, each beautifully decorated with reclaimed wood floors, limestone bathrooms, and linen-covered queen and king-sized beds. The calming colors and beautiful aesthetic of your own private room will not only add to your relaxation and transformation but also support a restful night’s sleep.",
    "brand": "",
    "blockQuote": "A luxury boot camp with a focus on nature and wellness to calm the mind and body.",
    "emailAddress": "inquiry@theranchmalibu.com",
    "website": "https://theranchmalibu.com/",
    "bookingURL": "https://theranchmalibu.com/retreat-reservations/",
    "redirectNotes": "The Ranch Malibu's reservations system",
    "phoneNumber": "+1 310 457 8700  ",
    "addressOne": "12220",
    "addressTwo": "Cotharin Road",
    "city": "Malibu",
    "county": "",
    "region": "California",
    "country": "United States",
    "rooms": 21,
    "dollar": 3,
    "updated": "2022-08-30T09:35:11.2",
    "created": "2022-08-30T09:12:49.597",
    "medias": [
      {
        "mediaId": 54548,
        "title": "The Ranch Malibu – Hotel Review | Condé Nast Traveler",
        "url": "https://pkslist.azureedge.net/media/images/1/67ff782b-36eb-4e83-99c5-a9338e0919b8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/67ff782b-36eb-4e83-99c5-a9338e0919b8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/67ff782b-36eb-4e83-99c5-a9338e0919b8/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 54547,
        "title": "The Ranch at Live Oak | USA – Healing Hotels of the World",
        "url": "https://pkslist.azureedge.net/media/images/1/0e45e0b5-8cf1-4781-b288-076e826c7808/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0e45e0b5-8cf1-4781-b288-076e826c7808/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0e45e0b5-8cf1-4781-b288-076e826c7808/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 54558,
        "title": "The Ranch Malibu Debuts At-Home Programs | American Spa",
        "url": "https://pkslist.azureedge.net/media/images/1/0e97fcbb-58a3-4658-badc-33e9a9aaf2e2/1200x900",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0e97fcbb-58a3-4658-badc-33e9a9aaf2e2/800x600",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0e97fcbb-58a3-4658-badc-33e9a9aaf2e2/256x192",
        "viewOrder": 2
      },
      {
        "mediaId": 54540,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/8cc0e33b-eb88-4d7d-bc14-3296781f61eb/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8cc0e33b-eb88-4d7d-bc14-3296781f61eb/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8cc0e33b-eb88-4d7d-bc14-3296781f61eb/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 54539,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/79dd68d3-824e-44e5-9742-7c3a10b914f5/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/79dd68d3-824e-44e5-9742-7c3a10b914f5/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/79dd68d3-824e-44e5-9742-7c3a10b914f5/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 54533,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c4c5e242-570e-420b-a40d-f2c3936fa3e7/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c4c5e242-570e-420b-a40d-f2c3936fa3e7/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c4c5e242-570e-420b-a40d-f2c3936fa3e7/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 54543,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a5064382-2c6f-4cc5-9de1-507f14a89546/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a5064382-2c6f-4cc5-9de1-507f14a89546/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a5064382-2c6f-4cc5-9de1-507f14a89546/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 54537,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a3bb1e04-3b09-497b-bb17-539be8d76814/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a3bb1e04-3b09-497b-bb17-539be8d76814/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a3bb1e04-3b09-497b-bb17-539be8d76814/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 54555,
        "title": "The Ranch Malibu – Hotel Review | Condé Nast Traveler",
        "url": "https://pkslist.azureedge.net/media/images/1/7b92b672-b653-432f-82cd-787f258222b9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7b92b672-b653-432f-82cd-787f258222b9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7b92b672-b653-432f-82cd-787f258222b9/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 54541,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/1de52452-82a0-4fde-a46c-05592ae59e22/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1de52452-82a0-4fde-a46c-05592ae59e22/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1de52452-82a0-4fde-a46c-05592ae59e22/256x192.jpeg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3240,
      "latitude": 34.091735,
      "longitude": -118.951392,
      "parentGeographicAreaId": 0,
      "title": "12220 Cotharin Rd, Malibu, CA 90265, USA",
      "updated": null,
      "created": "2022-08-30T09:26:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 58,
        "tagName": "Beach",
        "tagType": 2
      },
      {
        "tagId": 77,
        "tagName": "Cooking Classes",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 268,
        "tagName": "Massage",
        "tagType": 2
      },
      {
        "tagId": 296,
        "tagName": "Guided Walk",
        "tagType": 2
      },
      {
        "tagId": 320,
        "tagName": "Organic Garden",
        "tagType": 2
      },
      {
        "tagId": 351,
        "tagName": "Meditation",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 788,
        "venueCategoryId": 32,
        "venueId": 3453,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3452,
    "venueCategoryId": 0,
    "status": 1,
    "title": "AlpenGold Hotel",
    "description": "In the middle of the natural metropolis of Davos, on the edge of the forest, slightly elevated above Lake Davos, the AlpenGold Hotel surprises with alpine and cosmopolitan values – a home away from home, with time and space for new perspectives.\n\nHard to overlook in the landscape, the pine cone is located in the forest above Lake Davos, just outside the vibrant centre, and offers an unexpected view over the Davos mountains and the Landwasser valley. On the inside, alpine materials of stone and wood create a warm and modern atmosphere. Ingenuousness creates the opportunity to discover many details, for example the play of light and space, structures create new spaces and connect different areas…",
    "brand": "",
    "blockQuote": "Book a table at the Cheese Factory to try an Alpine specialty like fondue or raclette.",
    "emailAddress": "info@alpengoldhotel.com",
    "website": "alpengoldhotel.com/en/",
    "bookingURL": "https://www.ihg.com/hotels/us/en/find-hotels/hotel/rooms?qDest=Baslerstrasse%209,%20Davos%20Dorf.,%20CH&qCiMy=72022&qCiD=29&qCoMy=72022&qCoD=30&qAdlt=1&qChld=0&qRms=1&qRtP=6CBARC&qIta=99502222&qSlH=ZDVDA&qAkamaiCC=SE&qSrt=sAV&qBrs=re.ic.in.vn.cp.vx.hi.ex.rs.cv.sb.cw.ma.ul.ki.va.ii.sp.nd.ct.sx.we.lx&qAAR=6CBARC&qWch=0&qSmP=1&setPMCookies=true&qRad=30&qRdU=mi&srb_u=1&qSHBrC=SP&icdv=99502222",
    "redirectNotes": "AlpenGold Hotel's reservations system",
    "phoneNumber": "+41 81 414 04 00",
    "addressOne": "9",
    "addressTwo": "Baslerstrasse",
    "city": "Davos",
    "county": "",
    "region": "",
    "country": "Switzerland",
    "rooms": 216,
    "dollar": 2,
    "updated": "2022-08-29T12:06:13.79",
    "created": "2022-08-29T11:44:04.017",
    "medias": [
      {
        "mediaId": 54525,
        "title": "InterContinental Davos World Luxury Hotel Awards",
        "url": "https://pkslist.azureedge.net/media/images/1/513e7300-ed9f-4429-8c55-374998eb755f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/513e7300-ed9f-4429-8c55-374998eb755f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/513e7300-ed9f-4429-8c55-374998eb755f/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 54528,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/3c8375a3-bf5a-454f-9184-e1227dbfe718/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3c8375a3-bf5a-454f-9184-e1227dbfe718/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3c8375a3-bf5a-454f-9184-e1227dbfe718/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 54514,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/74f7df22-b8e8-492e-b8d4-4fb831f2612e/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/74f7df22-b8e8-492e-b8d4-4fb831f2612e/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/74f7df22-b8e8-492e-b8d4-4fb831f2612e/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 54515,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/9e7256b2-801a-48a0-a0ff-fc359a033aee/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9e7256b2-801a-48a0-a0ff-fc359a033aee/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9e7256b2-801a-48a0-a0ff-fc359a033aee/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 54516,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/27d807c8-ffdc-44fb-b272-d09f2ea13d24/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/27d807c8-ffdc-44fb-b272-d09f2ea13d24/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/27d807c8-ffdc-44fb-b272-d09f2ea13d24/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 54518,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/2cf6c94e-af27-4474-9309-483e2d404014/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2cf6c94e-af27-4474-9309-483e2d404014/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2cf6c94e-af27-4474-9309-483e2d404014/256x192.jpeg",
        "viewOrder": 9
      },
      {
        "mediaId": 54513,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/131867fd-0852-401f-8bca-652e59641425/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/131867fd-0852-401f-8bca-652e59641425/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/131867fd-0852-401f-8bca-652e59641425/256x192.jpeg",
        "viewOrder": 10
      },
      {
        "mediaId": 54530,
        "title": "AlpenGold Hotel Davos, Davos - Reserving.com",
        "url": "https://pkslist.azureedge.net/media/images/1/dd8555a9-3337-41c1-b433-f8d056996f19/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/dd8555a9-3337-41c1-b433-f8d056996f19/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/dd8555a9-3337-41c1-b433-f8d056996f19/256x192.jpg",
        "viewOrder": 11
      },
      {
        "mediaId": 54520,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/0876487a-aab6-408f-a63c-e58d5ab331aa/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0876487a-aab6-408f-a63c-e58d5ab331aa/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0876487a-aab6-408f-a63c-e58d5ab331aa/256x192.jpeg",
        "viewOrder": 13
      },
      {
        "mediaId": 54517,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b5ebd033-c9f6-4d2b-a8c5-24f76c9750de/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b5ebd033-c9f6-4d2b-a8c5-24f76c9750de/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b5ebd033-c9f6-4d2b-a8c5-24f76c9750de/256x192.jpeg",
        "viewOrder": 15
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3239,
      "latitude": 46.8133583,
      "longitude": 9.853643400000001,
      "parentGeographicAreaId": 0,
      "title": "Baslerstrasse 9, 7260 Davos, Switzerland",
      "updated": null,
      "created": "2022-08-29T11:49:00"
    },
    "personalities": [
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 66,
        "tagName": "Skiing",
        "tagType": 2
      },
      {
        "tagId": 81,
        "tagName": "Golf",
        "tagType": 2
      },
      {
        "tagId": 83,
        "tagName": "Kid's Club",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 159,
        "tagName": "Mountain Biking",
        "tagType": 2
      },
      {
        "tagId": 234,
        "tagName": "Sailing",
        "tagType": 2
      },
      {
        "tagId": 284,
        "tagName": "Architecture",
        "tagType": 2
      },
      {
        "tagId": 356,
        "tagName": "Paragliding",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 787,
        "venueCategoryId": 32,
        "venueId": 3452,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3291,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Joali Being",
    "description": "Nestled on the secluded island of Bodufushi in Raa Atoll, amid crystalline lagoons, pristine beaches and lush coconut palms, JOALI BEING is a 40-minute seaplane journey from Malé International Airport.. Join us as we script an extraordinary tale together, one where integrity and passion illuminate each word, chapter and verse. Here, wellbeing is so much more than just an expression – it is a way of life, of being. We believe in savouring the joy of here and now. In setting our intention and nurturing with empathy. In co-creating moments of wonder and transcendence.\n\nEach of our 68 thoughtful well living spaces come with private pools and special inclusions, such as customised mini-bar setups, meditative musical instruments and wellbeing games.\n\nAll food and drink options at JOALI BEING have been expertly curated with the help of the retreat’s nutritionists, offering a selection of flavours and cuisines with healthy and indulging dining options. Personalised nutrition sessions and co-created menu recommendations are available.",
    "brand": "",
    "blockQuote": "The Aktar, a herbology center combining traditional Anatolian remedies and Maldivian healing arts is a must-visit.",
    "emailAddress": "info.being@joali.com",
    "website": "joali.com/joalibeing/",
    "bookingURL": "https://reservations.travelclick.com/114092#/guestsandrooms&agencyid=96027890",
    "redirectNotes": "Joali Being's reservations sytem",
    "phoneNumber": "+960 658 31 00",
    "addressOne": "",
    "addressTwo": "",
    "city": "Raa Atoll",
    "county": "",
    "region": "",
    "country": "Maldives",
    "rooms": 68,
    "dollar": 3,
    "updated": "2022-04-04T14:50:03.777",
    "created": "2021-11-18T15:47:03.427",
    "medias": [
      {
        "mediaId": 50862,
        "title": "Joali Being: a new wellbeing concept in the Maldives",
        "url": "https://pkslist.azureedge.net/media/images/1/73f54207-334b-4d09-9a60-61eaecb07530/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/73f54207-334b-4d09-9a60-61eaecb07530/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/73f54207-334b-4d09-9a60-61eaecb07530/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 53465,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/469ff6c5-51b0-40b9-a6d8-39def7b0dd45/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/469ff6c5-51b0-40b9-a6d8-39def7b0dd45/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/469ff6c5-51b0-40b9-a6d8-39def7b0dd45/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 53451,
        "title": "JOALI BEING, Bodufushi Island - Mason Rose",
        "url": "https://pkslist.azureedge.net/media/images/1/733def5f-9962-4db3-af71-3367fd9b6f68/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/733def5f-9962-4db3-af71-3367fd9b6f68/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/733def5f-9962-4db3-af71-3367fd9b6f68/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 53469,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/2a8936e3-41ff-4902-abbc-91098c31be23/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2a8936e3-41ff-4902-abbc-91098c31be23/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2a8936e3-41ff-4902-abbc-91098c31be23/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 53454,
        "title": "Joali Being Opens as the Maldives Latest Private Island Wellness ...",
        "url": "https://pkslist.azureedge.net/media/images/1/de4c6b0f-36b2-428f-806d-32d0cdf03b54/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/de4c6b0f-36b2-428f-806d-32d0cdf03b54/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/de4c6b0f-36b2-428f-806d-32d0cdf03b54/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 53467,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/57d805de-9813-4a26-8085-3852e816a5a4/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/57d805de-9813-4a26-8085-3852e816a5a4/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/57d805de-9813-4a26-8085-3852e816a5a4/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 53452,
        "title": "JOALI BEING, Bodufushi Island - Mason Rose",
        "url": "https://pkslist.azureedge.net/media/images/1/63d3bed9-519a-4946-a8a2-65c3c0a0efa1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/63d3bed9-519a-4946-a8a2-65c3c0a0efa1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/63d3bed9-519a-4946-a8a2-65c3c0a0efa1/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 53461,
        "title": "Joali Being: Review | Vanity Fair",
        "url": "https://pkslist.azureedge.net/media/images/1/961f0746-007f-44b9-9947-d4fd3e1c3378/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/961f0746-007f-44b9-9947-d4fd3e1c3378/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/961f0746-007f-44b9-9947-d4fd3e1c3378/256x192.png",
        "viewOrder": 7
      },
      {
        "mediaId": 53464,
        "title": "JOALI Being Bodufushi | Luxury Resort | Foray Travels",
        "url": "https://pkslist.azureedge.net/media/images/1/34db1949-793c-4795-b1e2-d137b02702bf/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/34db1949-793c-4795-b1e2-d137b02702bf/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/34db1949-793c-4795-b1e2-d137b02702bf/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 53468,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c17c1b57-c65c-465b-8d48-25a37ba593be/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c17c1b57-c65c-465b-8d48-25a37ba593be/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c17c1b57-c65c-465b-8d48-25a37ba593be/256x192.jpeg",
        "viewOrder": 12
      },
      {
        "mediaId": 50877,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/49cbae86-692b-427c-b45a-9e6ef204c487/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/49cbae86-692b-427c-b45a-9e6ef204c487/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/49cbae86-692b-427c-b45a-9e6ef204c487/256x192.jpeg",
        "viewOrder": 13
      },
      {
        "mediaId": 53466,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/996f2a7a-35b8-4d7b-9e48-b67a1f934f21/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/996f2a7a-35b8-4d7b-9e48-b67a1f934f21/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/996f2a7a-35b8-4d7b-9e48-b67a1f934f21/256x192.jpeg",
        "viewOrder": 14
      },
      {
        "mediaId": 53459,
        "title": "Joali Being: Review | Vanity Fair",
        "url": "https://pkslist.azureedge.net/media/images/1/672807b0-448e-441e-a371-e0d69bc1c4d1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/672807b0-448e-441e-a371-e0d69bc1c4d1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/672807b0-448e-441e-a371-e0d69bc1c4d1/256x192.jpg",
        "viewOrder": 15
      },
      {
        "mediaId": 53449,
        "title": "Joali Being Opens as the Maldives Latest Private Island Wellness ...",
        "url": "https://pkslist.azureedge.net/media/images/1/a8028008-b80a-4e59-b74c-e89118a88b68/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a8028008-b80a-4e59-b74c-e89118a88b68/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a8028008-b80a-4e59-b74c-e89118a88b68/256x192.jpg",
        "viewOrder": 16
      },
      {
        "mediaId": 53470,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d3143ee5-e174-4275-b933-a4f494daa711/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d3143ee5-e174-4275-b933-a4f494daa711/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d3143ee5-e174-4275-b933-a4f494daa711/256x192.jpeg",
        "viewOrder": 17
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3078,
      "latitude": 5.507354900000001,
      "longitude": 72.9689398,
      "parentGeographicAreaId": 0,
      "title": "Bodufushi Island, Maldives",
      "updated": null,
      "created": "2021-11-18T15:56:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 157,
        "tagName": "Beach Escape",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 181,
        "tagName": "Private/Suite Pools",
        "tagType": 2
      },
      {
        "tagId": 206,
        "tagName": "Private Beach",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 299,
        "tagName": "Wellness Programs",
        "tagType": 2
      },
      {
        "tagId": 351,
        "tagName": "Meditation",
        "tagType": 2
      },
      {
        "tagId": 400,
        "tagName": "Personal Training",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 743,
        "venueCategoryId": 32,
        "venueId": 3291,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 744,
        "venueCategoryId": 15,
        "venueId": 3291,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 15,
          "title": "Beach Paradise",
          "region": "Global",
          "viewOrder": 6,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3170,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Palazzo Fiuggi",
    "description": "We take a 360-degree approach to your health. Our diagnostic assessments and consultations allow us to carefully craft a bespoke portfolio of medical services, wellness treatments and holistic therapies designed exclusively for you. We invite you to experience the Palazzo Fiuggi Medical Concept at its birthplace. Here, amid a breath-taking natural setting long renowned for its healing waters, guests can enjoy privileged access to world-class spa and medical facilities.\n\nAt Palazzo Fiuggi, guest rooms are beyond luxurious. Flooded by natural light and offering views across the park, towards the old town or the pool, each room features smart contemporary styling that complements the Liberty-period provenance of the historical building. Adorned with objets d’art, rooms exude character and charm and create a comfortable and relaxing backdrop to every visit.",
    "brand": "",
    "blockQuote": "Incredibly impressive, the 6,000sqm Medical Wellness Retreat is a must visit.",
    "emailAddress": "reservations@palazzofiuggi.com",
    "website": "palazzofiuggi.com/",
    "bookingURL": "https://www.palazzofiuggi.com/health-related-holidays/suites-for-luxury-wellness-retreats",
    "redirectNotes": "Palazzo Fiuggi's reservations system",
    "phoneNumber": "+39 0 775 7661",
    "addressOne": "34",
    "addressTwo": "Via dei Villini",
    "city": "Fiuggi",
    "county": "",
    "region": "",
    "country": "Italy",
    "rooms": 150,
    "dollar": 3,
    "updated": "2022-08-29T11:38:27.56",
    "created": "2021-06-24T16:24:43.367",
    "medias": [
      {
        "mediaId": 48117,
        "title": "Palazzo Fiuggi Wellness Medical Retreat | XO Private",
        "url": "https://pkslist.azureedge.net/media/images/1/f1acd069-3f6e-471a-97af-22f9fa947f55/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f1acd069-3f6e-471a-97af-22f9fa947f55/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f1acd069-3f6e-471a-97af-22f9fa947f55/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 48104,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/83399b46-25a3-4910-a638-279cae27f027/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/83399b46-25a3-4910-a638-279cae27f027/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/83399b46-25a3-4910-a638-279cae27f027/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 48105,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/4b9d6070-3503-4757-88d5-a7367c3b2f75/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4b9d6070-3503-4757-88d5-a7367c3b2f75/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4b9d6070-3503-4757-88d5-a7367c3b2f75/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 48106,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/7c0514fd-857a-4738-a7b1-0e5eb74b3eef/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7c0514fd-857a-4738-a7b1-0e5eb74b3eef/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7c0514fd-857a-4738-a7b1-0e5eb74b3eef/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 48108,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d1acc0a7-8d73-44c6-9c65-214754fcd308/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d1acc0a7-8d73-44c6-9c65-214754fcd308/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d1acc0a7-8d73-44c6-9c65-214754fcd308/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 48107,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/08835aef-8061-48bc-97a9-91c17e95b543/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/08835aef-8061-48bc-97a9-91c17e95b543/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/08835aef-8061-48bc-97a9-91c17e95b543/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 48114,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a449df7d-9ee6-4076-aa5b-7c4173b7d56b/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a449df7d-9ee6-4076-aa5b-7c4173b7d56b/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a449df7d-9ee6-4076-aa5b-7c4173b7d56b/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 48109,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/20ffa824-3075-493f-85b1-1f92c87b37ca/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/20ffa824-3075-493f-85b1-1f92c87b37ca/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/20ffa824-3075-493f-85b1-1f92c87b37ca/256x192.jpeg",
        "viewOrder": 10
      },
      {
        "mediaId": 48110,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e5fff2fc-70e5-40b4-92ba-4778217ac49d/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e5fff2fc-70e5-40b4-92ba-4778217ac49d/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e5fff2fc-70e5-40b4-92ba-4778217ac49d/256x192.jpeg",
        "viewOrder": 11
      },
      {
        "mediaId": 48120,
        "title": "THE BEST 5 Star Hotels in Fiuggi of 2021 (with Prices) - Tripadvisor",
        "url": "https://pkslist.azureedge.net/media/images/1/3b9b04c1-5b17-471d-b3cd-36887ccbbe4f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3b9b04c1-5b17-471d-b3cd-36887ccbbe4f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3b9b04c1-5b17-471d-b3cd-36887ccbbe4f/256x192.jpg",
        "viewOrder": 14
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2957,
      "latitude": 41.79575370000001,
      "longitude": 13.219572,
      "parentGeographicAreaId": 0,
      "title": "Via dei Villini, 34, 03014 Fiuggi FR, Italy",
      "updated": null,
      "created": "2021-06-24T16:53:00"
    },
    "personalities": [
      {
        "tagId": 48,
        "tagName": "Iconic/Landmark ",
        "tagType": 1
      },
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 180,
        "tagName": "Health Management Programs",
        "tagType": 2
      },
      {
        "tagId": 222,
        "tagName": "Michelin Star dining",
        "tagType": 2
      },
      {
        "tagId": 266,
        "tagName": "Nature Walk",
        "tagType": 2
      },
      {
        "tagId": 400,
        "tagName": "Personal Training",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 730,
        "venueCategoryId": 32,
        "venueId": 3170,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3073,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Chenot Palace Weggis",
    "description": "Introducing Chenot Palace Weggis - the leading health wellness retreat of the well-known Chenot brand. Chenot Palace Weggis is located at the foot of the Swiss Alps and is surrounded by picturesque Lake Lucerne.\n\nThis flagship property is designed and equipped to offer the unique 7-nights Chenot wellness programmes and treatments. It features a medical spa facility of 5 000 sqm with state-of-the-art diagnostics and cutting-edge medical screening tests.\n\nThe charming turn-of-the-century Palace and new contemporary building host 97 elegantly furnished rooms. Match your programme with beautiful room or suite, that look out onto lake Lucerne or the panorama mountain landscape.",
    "brand": "",
    "blockQuote": "I loved the new sleeping technology, peaceful zen gardens, modern architecture art gallery.",
    "emailAddress": "reservation@chenotpalaceweggis.com",
    "website": "chenotpalaceweggis.com/web/",
    "bookingURL": "https://www.chenotpalaceweggis.com/web/booking",
    "redirectNotes": "Chenot Palace Weggis' reservations system",
    "phoneNumber": "+41 41 255 20 00",
    "addressOne": "34",
    "addressTwo": "Hertensteinstrasse",
    "city": "Weggis",
    "county": "",
    "region": "",
    "country": "Switzerland",
    "rooms": 97,
    "dollar": 3,
    "updated": "2021-01-22T09:10:55.353",
    "created": "2021-01-22T08:51:04.507",
    "medias": [
      {
        "mediaId": 44720,
        "title": "Chenot Palace Weggis : Destination Santé en Suisse - Luxe Wellness ...",
        "url": "https://pkslist.azureedge.net/media/images/1/240dcf23-6a5c-40a8-b4dc-55dac2631b08/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/240dcf23-6a5c-40a8-b4dc-55dac2631b08/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/240dcf23-6a5c-40a8-b4dc-55dac2631b08/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 44715,
        "title": "Chenot Palace Weggis - about Weggis",
        "url": "https://pkslist.azureedge.net/media/images/1/e35e69b9-8f02-4a1b-95ee-defe99137037/1200x900.JPG",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e35e69b9-8f02-4a1b-95ee-defe99137037/800x600.JPG",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e35e69b9-8f02-4a1b-95ee-defe99137037/256x192.JPG",
        "viewOrder": 1
      },
      {
        "mediaId": 44724,
        "title": "Chenot Palace Weggis | SwissGlam.ch | Switzerland hotels, Weggis ...",
        "url": "https://pkslist.azureedge.net/media/images/1/2246bf25-b285-4f23-bbda-2130582ee0c2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2246bf25-b285-4f23-bbda-2130582ee0c2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2246bf25-b285-4f23-bbda-2130582ee0c2/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 44717,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/2714105c-0cc0-4622-9618-4d2303d2bfa0/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2714105c-0cc0-4622-9618-4d2303d2bfa0/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2714105c-0cc0-4622-9618-4d2303d2bfa0/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 44727,
        "title": "Chenot Palace Weggis to Open in June 2020 | FOUR Magazine : FOUR ...",
        "url": "https://pkslist.azureedge.net/media/images/1/92b3f896-af3b-4f94-9001-7814c0758a4b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/92b3f896-af3b-4f94-9001-7814c0758a4b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/92b3f896-af3b-4f94-9001-7814c0758a4b/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 44721,
        "title": "Chenot Palace Weggis Health Wellness Hotel - e-architect",
        "url": "https://pkslist.azureedge.net/media/images/1/7c193a72-4fc3-4d23-b956-39c26db39b95/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7c193a72-4fc3-4d23-b956-39c26db39b95/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7c193a72-4fc3-4d23-b956-39c26db39b95/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 44729,
        "title": "Single Deluxe",
        "url": "https://pkslist.azureedge.net/media/images/1/e88f591d-b487-449d-96fa-4dd330dd1c08/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e88f591d-b487-449d-96fa-4dd330dd1c08/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e88f591d-b487-449d-96fa-4dd330dd1c08/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 44725,
        "title": "Rooms - Chenot Palace Weggis",
        "url": "https://pkslist.azureedge.net/media/images/1/c410fe3e-b38d-47a6-a805-6f7b515a2225/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c410fe3e-b38d-47a6-a805-6f7b515a2225/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c410fe3e-b38d-47a6-a805-6f7b515a2225/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 44733,
        "title": "Rooms - Chenot Palace Weggis",
        "url": "https://pkslist.azureedge.net/media/images/1/4eb85e34-c2d1-4030-b0a1-9622e11dd704/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4eb85e34-c2d1-4030-b0a1-9622e11dd704/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4eb85e34-c2d1-4030-b0a1-9622e11dd704/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 44712,
        "title": "Inside the Brand New Chenot Palace Weggis in Switzerland | Elite ...",
        "url": "https://pkslist.azureedge.net/media/images/1/e889221d-1271-4111-97d7-cbbdd2278033/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e889221d-1271-4111-97d7-cbbdd2278033/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e889221d-1271-4111-97d7-cbbdd2278033/256x192.jpg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2859,
      "latitude": 47.02950149999999,
      "longitude": 8.421162899999999,
      "parentGeographicAreaId": 0,
      "title": "Hertensteinstrasse 34, 6353 Weggis, Switzerland",
      "updated": null,
      "created": "2021-01-22T09:07:00"
    },
    "personalities": [
      {
        "tagId": 53,
        "tagName": "Cutting Edge",
        "tagType": 1
      },
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 105,
        "tagName": "Afternoon Tea",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 159,
        "tagName": "Mountain Biking",
        "tagType": 2
      },
      {
        "tagId": 202,
        "tagName": "Private Terraces",
        "tagType": 2
      },
      {
        "tagId": 299,
        "tagName": "Wellness Programs",
        "tagType": 2
      },
      {
        "tagId": 317,
        "tagName": "Gourmet",
        "tagType": 2
      },
      {
        "tagId": 318,
        "tagName": "Stand Up Paddle",
        "tagType": 2
      },
      {
        "tagId": 363,
        "tagName": "Beauty Salon",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 715,
        "venueCategoryId": 32,
        "venueId": 3073,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2756,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Chiva Som",
    "description": "When you visit Chiva-Som, you will be welcomed into a stunning resort of natural beauty beside the Gulf of Thailand in Hua Hin. You will enjoy being able to choose one of twelve luxury room and suite types as your accommodations during your stay. For the energetic, Chiva-Som offers a range of activities and facilities geared towards getting you in the best shape of your life. The  gymnasium features the latest technology and equipment to meet your fitness needs.\n\nChiva-Som also has a number of studios and salas that allow you to concentrate on your health and fitness while pursuing various activities like Yoga, Pilates, Tai Chi, Watsu® and aerobic dance. These facilities are all surrounded by the natural and sustainable, tropical beauty of the incomparable Chiva-Som resort.",
    "brand": "",
    "blockQuote": "Whatever your health issue, Chiva Som has a program for you.",
    "emailAddress": "reservation@chivasom.com",
    "website": "https://www.chivasom.com/",
    "bookingURL": "https://gc.synxis.com/rez.aspx?Chain=21936&Hotel=77348&Lang=1&template=RBE&shell=RBE&locale=en-GB&arrive=2020/5/29&adult=1&rooms=1&depart=2020/6/5&_ga=2.93123549.1798232629.1590757340-298722767.1590757340&iata=96027890",
    "redirectNotes": "Chiva-Som's reservations system",
    "phoneNumber": "+66 3253 6536",
    "addressOne": "73 / 4",
    "addressTwo": "Petchkasem Road",
    "city": "Hua Hin",
    "county": "",
    "region": "",
    "country": "Thailand",
    "rooms": 12,
    "dollar": 3,
    "updated": "2020-07-15T10:05:11.7",
    "created": "2020-05-29T13:04:23.827",
    "medias": [
      {
        "mediaId": 37938,
        "title": "Resort Gallery | Chiva-Som International Health Resorts",
        "url": "https://pkslist.azureedge.net/media/images/1/a501b423-8476-4da7-88a4-8f007516f2e5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a501b423-8476-4da7-88a4-8f007516f2e5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a501b423-8476-4da7-88a4-8f007516f2e5/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 37947,
        "title": "Chiva-Som marks 25th birthday with year-long celebrations ...",
        "url": "https://pkslist.azureedge.net/media/images/1/e176edbd-3c38-4711-a3fe-452e1bdb8263/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e176edbd-3c38-4711-a3fe-452e1bdb8263/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e176edbd-3c38-4711-a3fe-452e1bdb8263/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 37942,
        "title": "Chiva-Som – Hotel Review | Travel Insider",
        "url": "https://pkslist.azureedge.net/media/images/1/96ff5e65-10bc-4c98-ac1c-2329638da09d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/96ff5e65-10bc-4c98-ac1c-2329638da09d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/96ff5e65-10bc-4c98-ac1c-2329638da09d/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 37939,
        "title": "Resort Gallery | Chiva-Som International Health Resorts",
        "url": "https://pkslist.azureedge.net/media/images/1/b11f86dc-0925-45e1-af73-86c1f1391aa4/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b11f86dc-0925-45e1-af73-86c1f1391aa4/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b11f86dc-0925-45e1-af73-86c1f1391aa4/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 37944,
        "title": "Top Wellness Retreats in Thailand: Chiva Som - Luxury Travel Magazine",
        "url": "https://pkslist.azureedge.net/media/images/1/5923aef0-7f01-4233-a68d-8b6dba08c3bc/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5923aef0-7f01-4233-a68d-8b6dba08c3bc/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5923aef0-7f01-4233-a68d-8b6dba08c3bc/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 37952,
        "title": "Chiva-Som-Anchan Bedroom - HOT Hua Hin Property",
        "url": "https://pkslist.azureedge.net/media/images/1/39d2d068-c4c6-4af9-bb80-154d90cb1e2a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/39d2d068-c4c6-4af9-bb80-154d90cb1e2a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/39d2d068-c4c6-4af9-bb80-154d90cb1e2a/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 37956,
        "title": "Chiva – Som Destination Spa Hua Hin, Thailand Award winning and ...",
        "url": "https://pkslist.azureedge.net/media/images/1/61a907eb-c22b-486c-ab6a-a2867b14a48e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/61a907eb-c22b-486c-ab6a-a2867b14a48e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/61a907eb-c22b-486c-ab6a-a2867b14a48e/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 37950,
        "title": "Meetings and events at Chiva-Som Hua Hin, Hua Hin, TH",
        "url": "https://pkslist.azureedge.net/media/images/1/1fc5399c-2767-43d8-960b-7129f32cbd6e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1fc5399c-2767-43d8-960b-7129f32cbd6e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1fc5399c-2767-43d8-960b-7129f32cbd6e/256x192.jpg",
        "viewOrder": 7
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2542,
      "latitude": 12.5935054,
      "longitude": 99.94984339999999,
      "parentGeographicAreaId": 0,
      "title": "73 / 4 Petchkasem Rd, Tambon Hua Hin, Amphoe Hua Hin, Chang Wat Prachuap Khiri Khan 77110, Thailand",
      "updated": null,
      "created": "2020-05-29T13:09:00"
    },
    "personalities": [
      {
        "tagId": 42,
        "tagName": "Classic Traditionalist ",
        "tagType": 1
      },
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 58,
        "tagName": "Beach",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 88,
        "tagName": "Tennis",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 276,
        "tagName": "Gym",
        "tagType": 2
      },
      {
        "tagId": 299,
        "tagName": "Wellness Programs",
        "tagType": 2
      },
      {
        "tagId": 300,
        "tagName": "Pilates",
        "tagType": 2
      },
      {
        "tagId": 328,
        "tagName": "Tai Chi",
        "tagType": 2
      },
      {
        "tagId": 351,
        "tagName": "Meditation",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 630,
        "venueCategoryId": 32,
        "venueId": 2756,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2755,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Mii amo",
    "description": "Mii amo is a world-class destination spa in Sedona, Arizona that defies the imagination. Set within the soaring red rock backdrop of Boynton Canyon, within steps of Enchantment Resort, Mii amo is surrounded by awe-inspiring, natural beauty and endless spiritual healing. Mii amo welcomes guests ages 16 and older to embark on all-inclusive 3, 4 or 7-night Journeys of discovery and inner peace.\n\nArtfully arranged in a private enclave of casitas shaded by cottonwood trees, our 16 casitas provide the ultimate serenity. Bordered by landscaped courtyards, each casita features a private patio or balcony, in-room coffee makers, wood-paneled closets with built-ins and a spa-amenity bathroom complete with a deep soaking tub. All casitas include a beehive fireplace and whirlpool, and premium casitas also include an outdoor shower and private massage area for your comfort.",
    "brand": "",
    "blockQuote": "The healing sessions are renowned since many of the best alternative therapy practitioners flock to Boynton Canyon, known to have a vortex (energy field).",
    "emailAddress": "resdesk@enchantmentgroup.com",
    "website": "https://www.miiamo.com/",
    "bookingURL": "https://be.synxis.com/?_ga=2.85432337.983699552.1590753957-1967105126.1590753957&adult=2&agencyid=96027890&arrive=2020-06-04&chain=23554&child=0&currency=USD&depart=2020-06-07&hotel=3347&level=hotel&locale=en-US&rooms=1",
    "redirectNotes": "Mii amo's reservations system",
    "phoneNumber": "+1 844 241 7498",
    "addressOne": "525",
    "addressTwo": "Boynton Canyon Road",
    "city": "Sedona",
    "county": "",
    "region": "Arizona",
    "country": "United States",
    "rooms": 16,
    "dollar": 3,
    "updated": "2020-05-29T12:53:12.163",
    "created": "2020-05-29T12:32:09.473",
    "medias": [
      {
        "mediaId": 37921,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/18db4eb0-6075-413c-8c6b-7c4196cc4725/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/18db4eb0-6075-413c-8c6b-7c4196cc4725/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/18db4eb0-6075-413c-8c6b-7c4196cc4725/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 37909,
        "title": "Mii Amo, Sedona, Arizona, United States - Destination Spa Review ...",
        "url": "https://pkslist.azureedge.net/media/images/1/466ada0e-e13e-4a0e-a80c-94755ea8b151/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/466ada0e-e13e-4a0e-a80c-94755ea8b151/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/466ada0e-e13e-4a0e-a80c-94755ea8b151/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 37917,
        "title": "Photo Gallery | Mii amo",
        "url": "https://pkslist.azureedge.net/media/images/1/1e615c83-f408-407f-afcb-20dcd78c47b0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1e615c83-f408-407f-afcb-20dcd78c47b0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1e615c83-f408-407f-afcb-20dcd78c47b0/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 37910,
        "title": "Photo Gallery | Mii amo",
        "url": "https://pkslist.azureedge.net/media/images/1/d06e2be7-bf77-430c-adba-c4281e3817c9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d06e2be7-bf77-430c-adba-c4281e3817c9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d06e2be7-bf77-430c-adba-c4281e3817c9/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 37911,
        "title": "Photo Gallery | Mii amo",
        "url": "https://pkslist.azureedge.net/media/images/1/4eaad715-f532-4283-8d6d-c4b5f92b4156/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4eaad715-f532-4283-8d6d-c4b5f92b4156/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4eaad715-f532-4283-8d6d-c4b5f92b4156/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 37916,
        "title": "9 Ways to Have a Spiritual Awakening at Mii amo",
        "url": "https://pkslist.azureedge.net/media/images/1/18beb972-c97a-4881-adca-788e4b6c4d94/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/18beb972-c97a-4881-adca-788e4b6c4d94/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/18beb972-c97a-4881-adca-788e4b6c4d94/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 37919,
        "title": "Photo Gallery | Mii amo",
        "url": "https://pkslist.azureedge.net/media/images/1/496d8330-e9ab-4d03-92ed-23cdc12414f9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/496d8330-e9ab-4d03-92ed-23cdc12414f9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/496d8330-e9ab-4d03-92ed-23cdc12414f9/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 37922,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/79ebdabc-8c21-4843-95d5-7a424a1184e3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/79ebdabc-8c21-4843-95d5-7a424a1184e3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/79ebdabc-8c21-4843-95d5-7a424a1184e3/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 37923,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/0d9dbb85-4c79-4624-97ac-9981f95186f5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0d9dbb85-4c79-4624-97ac-9981f95186f5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0d9dbb85-4c79-4624-97ac-9981f95186f5/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 37920,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/8d4c9a49-75f9-4015-8ddf-e7b1519d7018/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8d4c9a49-75f9-4015-8ddf-e7b1519d7018/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8d4c9a49-75f9-4015-8ddf-e7b1519d7018/256x192.jpg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2541,
      "latitude": 34.9136294,
      "longitude": -111.8512252,
      "parentGeographicAreaId": 0,
      "title": "525 Boynton Canyon Rd, Sedona, AZ 86336, USA",
      "updated": null,
      "created": "2020-05-29T12:44:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 5,
        "tagName": "Billiards",
        "tagType": 2
      },
      {
        "tagId": 77,
        "tagName": "Cooking Classes",
        "tagType": 2
      },
      {
        "tagId": 88,
        "tagName": "Tennis",
        "tagType": 2
      },
      {
        "tagId": 108,
        "tagName": "Jacuzzi",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 156,
        "tagName": "Fireplace",
        "tagType": 2
      },
      {
        "tagId": 209,
        "tagName": "Adults Only",
        "tagType": 2
      },
      {
        "tagId": 229,
        "tagName": "Private Balconies",
        "tagType": 2
      },
      {
        "tagId": 299,
        "tagName": "Wellness Programs",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 629,
        "venueCategoryId": 32,
        "venueId": 2755,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2706,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Alpin Panorama Hotel Hubertus",
    "description": "Between heaven and earth, in a picturesque place amidst the Dolomites, lies our unique hotel in Olang. A place of peace. A place of joy. A place of inspiration. In front of our hotel in Olang, the Alpin Panorama Hotel Hubertus: the imposing Dolomite peaks with their impressive rock faces, who are silent witnesses to the forces of nature that shaped this special region. Borders disappear against this backdrop; borders between architecture and landscape, heaven and earth, man and nature. Take a break in our hotel in Olang and become one with yourself and with the surrounding nature.",
    "brand": "",
    "blockQuote": "The sky infinity pool is a hydrophile's heaven.",
    "emailAddress": "info@hotel-hubertus.com",
    "website": "hotel-hubertus.com/en/",
    "bookingURL": "https://www.hotel-hubertus.com/en/living/current-rates?fromdate=14.11.2021&todate=21.11.2021",
    "redirectNotes": "Alpin Panorama Hotel Hubertus' reservations system",
    "phoneNumber": "+39 0474 59 21 04",
    "addressOne": "5",
    "addressTwo": "Furkelstraße",
    "city": "Sorafurcia",
    "county": "",
    "region": "",
    "country": "Italy",
    "rooms": 64,
    "dollar": 2,
    "updated": "2022-07-28T10:50:18.657",
    "created": "2020-05-15T12:54:27.6",
    "medias": [
      {
        "mediaId": 54219,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/77848b12-7525-4849-af92-e87c75b8314d/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/77848b12-7525-4849-af92-e87c75b8314d/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/77848b12-7525-4849-af92-e87c75b8314d/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 54227,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/935ba471-55d7-48e2-aa8f-1004c27cb627/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/935ba471-55d7-48e2-aa8f-1004c27cb627/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/935ba471-55d7-48e2-aa8f-1004c27cb627/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 54225,
        "title": "Alpin Panorama Hotel Hubertus in Olang - 4S stars Hotel - south ...",
        "url": "https://pkslist.azureedge.net/media/images/1/07751081-33bf-407b-9431-4bf45c3e7e57/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/07751081-33bf-407b-9431-4bf45c3e7e57/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/07751081-33bf-407b-9431-4bf45c3e7e57/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 54221,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/34cb3d7d-6f54-4d56-af70-f61e1f5959c6/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/34cb3d7d-6f54-4d56-af70-f61e1f5959c6/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/34cb3d7d-6f54-4d56-af70-f61e1f5959c6/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 54226,
        "title": "Alpin Panorama Hotel Hubertus in Olang - 4S stars Hotel - south ...",
        "url": "https://pkslist.azureedge.net/media/images/1/03415d66-f517-40a9-b4c2-3e2875322d21/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/03415d66-f517-40a9-b4c2-3e2875322d21/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/03415d66-f517-40a9-b4c2-3e2875322d21/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 54218,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ed2ca8d3-9ae9-4d8b-aff1-a4a2dfc4c18f/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ed2ca8d3-9ae9-4d8b-aff1-a4a2dfc4c18f/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ed2ca8d3-9ae9-4d8b-aff1-a4a2dfc4c18f/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 54223,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/58456ab7-6fef-443b-aa73-bf9b94ddd9ac/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/58456ab7-6fef-443b-aa73-bf9b94ddd9ac/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/58456ab7-6fef-443b-aa73-bf9b94ddd9ac/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 50715,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c5d12ad5-83de-4cbe-a4f7-eecf55d47db8/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c5d12ad5-83de-4cbe-a4f7-eecf55d47db8/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c5d12ad5-83de-4cbe-a4f7-eecf55d47db8/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 36010,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c5d0dce9-95f4-4860-9978-39933c5cd295/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c5d0dce9-95f4-4860-9978-39933c5cd295/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c5d0dce9-95f4-4860-9978-39933c5cd295/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 50714,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/92477db2-3bb5-4923-b98c-244c042feff2/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/92477db2-3bb5-4923-b98c-244c042feff2/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/92477db2-3bb5-4923-b98c-244c042feff2/256x192.jpeg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2493,
      "latitude": 46.7537444,
      "longitude": 12.0099917,
      "parentGeographicAreaId": 0,
      "title": "Furkelstraße, 5, 39030 Geiselsberg, Südtirol, Italy",
      "updated": null,
      "created": "2020-05-15T13:10:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 53,
        "tagName": "Cutting Edge",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 66,
        "tagName": "Skiing",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 151,
        "tagName": "Salt water pool",
        "tagType": 2
      },
      {
        "tagId": 203,
        "tagName": "Sauna",
        "tagType": 2
      },
      {
        "tagId": 219,
        "tagName": "Wine Cellar",
        "tagType": 2
      },
      {
        "tagId": 243,
        "tagName": "Outdoor Activities",
        "tagType": 2
      },
      {
        "tagId": 284,
        "tagName": "Architecture",
        "tagType": 2
      },
      {
        "tagId": 299,
        "tagName": "Wellness Programs",
        "tagType": 2
      },
      {
        "tagId": 348,
        "tagName": "Unesco World Heritage",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 550,
        "venueCategoryId": 10,
        "venueId": 2706,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 10,
          "title": "Infinity Pools",
          "region": "Global",
          "viewOrder": 2,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 741,
        "venueCategoryId": 32,
        "venueId": 2706,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2525,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Lefay Resort & Spa Lago di Garda",
    "description": "Lefay Resort & SPA Lago di Garda is located in Gargnano, one of the most picturesque villages on the lake. The Resort, the first to be awarded the prestigious 5-star superior rating on Lake Garda, is situated inside an 11 hectares natural park in the heart of the spectacular and renowned \"Riviera dei Limoni\", surrounded by gentle hills and natural terraces rich in olive trees and woods overlooking the lake.",
    "brand": null,
    "blockQuote": "Their spa, aka as the 'temple of wellness', is 3,800 square metres of heavenly bliss.",
    "emailAddress": "res.garda@lefayresorts.com",
    "website": "lagodigarda.lefayresorts.com/en",
    "bookingURL": "http://www.booking.com/Share-4HyGCC?aid=925669",
    "redirectNotes": null,
    "phoneNumber": "+39 0365 241800",
    "addressOne": "136",
    "addressTwo": "Via Angelo Feltrinelli",
    "city": "Gargnano",
    "county": "",
    "region": "",
    "country": "Italy",
    "rooms": 93,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 33143,
        "title": "Lefay Resort Lago Garda, Gargnano, Italy - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/08ce905d-9e2b-49ce-8af0-d603758652b2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/08ce905d-9e2b-49ce-8af0-d603758652b2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/08ce905d-9e2b-49ce-8af0-d603758652b2/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 33159,
        "title": "Lefay Resort and SPA Lago di Garda, Gargnano, Italy",
        "url": "https://pkslist.azureedge.net/media/images/1/3b7b10cc-bdb5-454a-9c55-946aa57769d2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3b7b10cc-bdb5-454a-9c55-946aa57769d2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3b7b10cc-bdb5-454a-9c55-946aa57769d2/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 33158,
        "title": "Lefay Resort & SPA Lago Di Garda | A Kuoni Hotel in Lake Garda",
        "url": "https://pkslist.azureedge.net/media/images/1/1b16aba6-d6e5-48ee-8e62-61b509638c69/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1b16aba6-d6e5-48ee-8e62-61b509638c69/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1b16aba6-d6e5-48ee-8e62-61b509638c69/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 33142,
        "title": "Lefay Resort Lago Garda, Gargnano, Italy - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/daa2934d-a041-4e09-8a8c-c03aa899d031/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/daa2934d-a041-4e09-8a8c-c03aa899d031/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/daa2934d-a041-4e09-8a8c-c03aa899d031/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 33152,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/954ec19a-0fe8-4723-9716-dbeacb7986f6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/954ec19a-0fe8-4723-9716-dbeacb7986f6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/954ec19a-0fe8-4723-9716-dbeacb7986f6/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 33153,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/3e27791f-98ac-42a0-8e0c-7d3e670c5732/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3e27791f-98ac-42a0-8e0c-7d3e670c5732/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3e27791f-98ac-42a0-8e0c-7d3e670c5732/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 33149,
        "title": "Lefay Resort Lago Garda, Gargnano, Italy - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/4d0e3e60-f3a5-4ec0-b4b0-a7ae4eba7025/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4d0e3e60-f3a5-4ec0-b4b0-a7ae4eba7025/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4d0e3e60-f3a5-4ec0-b4b0-a7ae4eba7025/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 33155,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/bf987a18-e1cb-418b-8459-4e8c2d1e6e81/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bf987a18-e1cb-418b-8459-4e8c2d1e6e81/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bf987a18-e1cb-418b-8459-4e8c2d1e6e81/256x192.jpg",
        "viewOrder": 7
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2310,
      "latitude": 45.6869301,
      "longitude": 10.645623699999987,
      "parentGeographicAreaId": 0,
      "title": "Via Angelo Feltrinelli, 136, 25084 Gargnano BS, Italy",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      },
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 60,
        "tagName": "Gastronomic",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 198,
        "tagName": "Boat Excursion",
        "tagType": 2
      },
      {
        "tagId": 229,
        "tagName": "Private Balconies",
        "tagType": 2
      },
      {
        "tagId": 299,
        "tagName": "Wellness Programs",
        "tagType": 2
      },
      {
        "tagId": 362,
        "tagName": "Sustainable",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 603,
        "venueCategoryId": 32,
        "venueId": 2525,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1990,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Corinthia Hotel London",
    "description": "As London's elegant, uplifting, grand hotel, intelligently designed for modern life, Corinthia Hotel London combines grandeur, heritage and a contemporary style with a modern touch. In close proximity to the city's major attractions, features include 283 rooms of with 51 are suites and 7 penthouses. A Mediterranean Restaurant, the new Kerridge's Bar & Grill, a jazz influenced bar and award-wining flagship ESPA Life at Corinthia, with BodySPace gym and hair salon by Daniel Galvin, make the hotel a destination in itself. Perfectly positioned in the heart of London, and minutes from the West End and River Thames, the hotel is within easy walking distance to many top attractions.",
    "brand": null,
    "blockQuote": "It's award-winning and London's largest spa and it's a must visit.",
    "emailAddress": "london@corinthia.com",
    "website": "corinthia.com/en/hotels/london",
    "bookingURL": "http://www.booking.com/Share-3Dectq?aid=925669",
    "redirectNotes": null,
    "phoneNumber": " +44 020 7930 8181",
    "addressOne": "",
    "addressTwo": "Whitehall Place",
    "city": "London",
    "county": "",
    "region": "England",
    "country": "United Kingdom",
    "rooms": 283,
    "dollar": 2,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 24497,
        "title": "Corinthia Hotel London | Luxury Hotels in London | Corinthia Hotels",
        "url": "https://pkslist.azureedge.net/media/images/1/2ac54612-4ac7-43a9-a802-0bec5e747509/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2ac54612-4ac7-43a9-a802-0bec5e747509/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2ac54612-4ac7-43a9-a802-0bec5e747509/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 24508,
        "title": "Corinthia Hotel London Exterior - Ruritaniahotel.com",
        "url": "https://pkslist.azureedge.net/media/images/1/e93b7915-0ec0-4747-b27f-7ffdd6990d88/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e93b7915-0ec0-4747-b27f-7ffdd6990d88/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e93b7915-0ec0-4747-b27f-7ffdd6990d88/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 24504,
        "title": "Globe chandelier light at the Corinthia, London #chandelier ...",
        "url": "https://pkslist.azureedge.net/media/images/1/c453374e-039a-4aa4-9c01-071ddf50b832/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c453374e-039a-4aa4-9c01-071ddf50b832/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c453374e-039a-4aa4-9c01-071ddf50b832/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 24502,
        "title": "Corinthia Hotel London - review | London Evening Standard",
        "url": "https://pkslist.azureedge.net/media/images/1/e1dc82f5-3617-4352-9a8c-6e4e19b365c8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e1dc82f5-3617-4352-9a8c-6e4e19b365c8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e1dc82f5-3617-4352-9a8c-6e4e19b365c8/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 24500,
        "title": "Corinthia Hotel London - review | London Evening Standard",
        "url": "https://pkslist.azureedge.net/media/images/1/ea400cc2-de85-40d5-952e-8244c89db680/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ea400cc2-de85-40d5-952e-8244c89db680/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ea400cc2-de85-40d5-952e-8244c89db680/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 24499,
        "title": "Corinthia Hotel London Is Offering Guests A Look Into The Future",
        "url": "https://pkslist.azureedge.net/media/images/1/93f4fe2b-11cf-4d4f-b627-5eb3afd939fb/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/93f4fe2b-11cf-4d4f-b627-5eb3afd939fb/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/93f4fe2b-11cf-4d4f-b627-5eb3afd939fb/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 24498,
        "title": "Corinthia Hotel London | Luxury Hotels in London | Corinthia Hotels",
        "url": "https://pkslist.azureedge.net/media/images/1/9bda3479-8952-47ec-aa7c-6297b5129f44/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9bda3479-8952-47ec-aa7c-6297b5129f44/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9bda3479-8952-47ec-aa7c-6297b5129f44/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 24501,
        "title": "Superior King Room | Luxury Hotel Rooms London | Corinthia Hotel ...",
        "url": "https://pkslist.azureedge.net/media/images/1/e42cbf01-7468-4ac0-8d84-60ee914e28ad/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e42cbf01-7468-4ac0-8d84-60ee914e28ad/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e42cbf01-7468-4ac0-8d84-60ee914e28ad/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 24503,
        "title": "Book Corinthia Hotel London in London | Hotels.com",
        "url": "https://pkslist.azureedge.net/media/images/1/a640ee23-5376-487b-b941-57c55869ab17/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a640ee23-5376-487b-b941-57c55869ab17/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a640ee23-5376-487b-b941-57c55869ab17/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1800,
      "latitude": 51.506547,
      "longitude": -0.12423300000000381,
      "parentGeographicAreaId": 0,
      "title": "Whitehall Pl, Westminster, London SW1A 2BD, UK",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 44,
        "tagName": "Grand Dame ",
        "tagType": 1
      },
      {
        "tagId": 45,
        "tagName": "Historic Sense",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 68,
        "tagName": "Business",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 108,
        "tagName": "Jacuzzi",
        "tagType": 2
      },
      {
        "tagId": 127,
        "tagName": "Hair Salon",
        "tagType": 2
      },
      {
        "tagId": 183,
        "tagName": "Butler Service",
        "tagType": 2
      },
      {
        "tagId": 216,
        "tagName": "Museum",
        "tagType": 2
      },
      {
        "tagId": 276,
        "tagName": "Gym",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 293,
        "tagName": "Meeting Space",
        "tagType": 2
      },
      {
        "tagId": 323,
        "tagName": "City Walk",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 615,
        "venueCategoryId": 32,
        "venueId": 1990,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1831,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Hotel Villa Honegg",
    "description": "Hotel Villa Honegg is a 5-star Superior Hotel with 23 rooms furnished to excellent standards. High above Lake Lucerne you will find relaxation and privacy in a unique setting.\n\nThe Hotel Villa Honegg has 23 comfortable rooms and suites to suit a discerning clientele. Featuring a pleasant blend of natural materials and elegance, the decor and furnishings create an inviting ambience fostering relaxation and a sense of well-being.",
    "brand": "",
    "blockQuote": "Take in views of the mountains and lake from the 34ºC heated outdoor infinity pool.",
    "emailAddress": "info@villa-honegg.ch",
    "website": "villa-honegg.ch/en/",
    "bookingURL": "https://villa-honegg.ch/en/online-booking/",
    "redirectNotes": null,
    "phoneNumber": "+41 41 618 32 00",
    "addressOne": "",
    "addressTwo": "",
    "city": "Ennetburgen",
    "county": "",
    "region": "",
    "country": "Switzerland",
    "rooms": 23,
    "dollar": 2,
    "updated": "2020-05-16T13:23:10.167",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 21773,
        "title": "Hotel Villa Honegg - Destination Deluxe",
        "url": "https://pkslist.azureedge.net/media/images/1/a95c008b-65f9-4484-9e14-8f34927e69a9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a95c008b-65f9-4484-9e14-8f34927e69a9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a95c008b-65f9-4484-9e14-8f34927e69a9/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 21776,
        "title": "The boutique hotel in the heart of Switzerland | Hotel Villa ...",
        "url": "https://pkslist.azureedge.net/media/images/1/71d554a2-e07a-49dc-af15-9d6d911b5ac2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/71d554a2-e07a-49dc-af15-9d6d911b5ac2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/71d554a2-e07a-49dc-af15-9d6d911b5ac2/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 21778,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f06efcc7-64e9-4b66-a79b-4b96f33f29ed/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f06efcc7-64e9-4b66-a79b-4b96f33f29ed/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f06efcc7-64e9-4b66-a79b-4b96f33f29ed/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 21768,
        "title": "Villa Honegg, Ennetbürgen, Switzerland - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/da42c500-0bb5-4032-908c-41f789bfa9dc/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/da42c500-0bb5-4032-908c-41f789bfa9dc/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/da42c500-0bb5-4032-908c-41f789bfa9dc/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 21775,
        "title": "Restaurant and terrace lounge | Hotel Villa Honegg Bürgenstock",
        "url": "https://pkslist.azureedge.net/media/images/1/bb5222c5-5591-4744-a2cc-7c3d9fa6e747/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bb5222c5-5591-4744-a2cc-7c3d9fa6e747/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bb5222c5-5591-4744-a2cc-7c3d9fa6e747/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 21779,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/bcea6f86-4860-495a-8719-f6f581855b1f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bcea6f86-4860-495a-8719-f6f581855b1f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bcea6f86-4860-495a-8719-f6f581855b1f/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 21766,
        "title": "Villa Honegg, Ennetbürgen, Switzerland - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/31072737-b63e-4dee-8076-cf2c1bd381b2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/31072737-b63e-4dee-8076-cf2c1bd381b2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/31072737-b63e-4dee-8076-cf2c1bd381b2/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 21777,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b85bd194-c76c-47c9-84cf-b506fdbba526/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b85bd194-c76c-47c9-84cf-b506fdbba526/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b85bd194-c76c-47c9-84cf-b506fdbba526/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 21770,
        "title": "Other ski resorts will pale in comparison after seeing this place ...",
        "url": "https://pkslist.azureedge.net/media/images/1/459944c6-8d94-480a-bf52-0cfda9213bac/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/459944c6-8d94-480a-bf52-0cfda9213bac/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/459944c6-8d94-480a-bf52-0cfda9213bac/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1646,
      "latitude": 46.99481489999999,
      "longitude": 8.403009500000053,
      "parentGeographicAreaId": 0,
      "title": "6373 Ennetbürgen, Switzerland",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 40,
        "tagName": "Boutique Bolthole ",
        "tagType": 1
      },
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 75,
        "tagName": "Casual Dining",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 101,
        "tagName": "Cinema",
        "tagType": 2
      },
      {
        "tagId": 156,
        "tagName": "Fireplace",
        "tagType": 2
      },
      {
        "tagId": 191,
        "tagName": "Cigar Bar",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 212,
        "tagName": "Hammam",
        "tagType": 2
      },
      {
        "tagId": 276,
        "tagName": "Gym",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 552,
        "venueCategoryId": 10,
        "venueId": 1831,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 10,
          "title": "Infinity Pools",
          "region": "Global",
          "viewOrder": 2,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 606,
        "venueCategoryId": 32,
        "venueId": 1831,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1803,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Burgenstock Hotel & Alpine Spa",
    "description": "Experience the breathtaking beauty of Lake Lucerne and warm Swiss hospitality at the Bürgenstock Hotel, one of the most elegant hotels in the region.\n\nThe newly built Bürgenstock Hotel & Alpine Spa features modern architecture and contemporary design in the 102 rooms and suites with incredible views far above Lake Lucerne. The spacious rooms ranging from 42 to 678 sqm are all equipped with exquisite materials and stylish designer furniture.",
    "brand": "",
    "blockQuote": "Book a visit to the Alpine Spa just so you can admire the panorama view from 500 metres above Lake Lucerne.",
    "emailAddress": "reservations@buergenstock.ch",
    "website": "buergenstock.ch/en/hotels/buergenstockhotel",
    "bookingURL": "https://www.myhotelreservation.net/b/hllw1630/HLLW1630/?f=2018-06-14&t=2018-06-15&i=96027890",
    "redirectNotes": "Burgenstock Hotel's reservations system",
    "phoneNumber": "+41 41 612 60 00",
    "addressOne": "17",
    "addressTwo": "Bürgenstock",
    "city": "Burgenstock",
    "county": "",
    "region": "",
    "country": "Switzerland",
    "rooms": 102,
    "dollar": 3,
    "updated": "2020-05-24T17:47:30.66",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 37391,
        "title": "swisshoteldata.ch - Swiss hotel directory",
        "url": "https://pkslist.azureedge.net/media/images/1/4c55f9ba-673d-492d-916d-0ba8d081a9bb/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4c55f9ba-673d-492d-916d-0ba8d081a9bb/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4c55f9ba-673d-492d-916d-0ba8d081a9bb/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 37393,
        "title": "Bürgenstock Hotel, Switzerland - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/e9546c02-6d63-4aae-afea-c0cef709da70/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e9546c02-6d63-4aae-afea-c0cef709da70/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e9546c02-6d63-4aae-afea-c0cef709da70/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 21308,
        "title": "Grand Hotel Burgenstock Hotel & SPA | La Suisse/Switzerland My ...",
        "url": "https://pkslist.azureedge.net/media/images/1/9460d421-2678-4fcb-871a-7343b65d74a8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9460d421-2678-4fcb-871a-7343b65d74a8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9460d421-2678-4fcb-871a-7343b65d74a8/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 21310,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c74a9a3a-2b8b-48fd-981c-86f4e0c0745c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c74a9a3a-2b8b-48fd-981c-86f4e0c0745c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c74a9a3a-2b8b-48fd-981c-86f4e0c0745c/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 21424,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/530a5b2d-d269-4616-bb66-6e1d3492ba83/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/530a5b2d-d269-4616-bb66-6e1d3492ba83/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/530a5b2d-d269-4616-bb66-6e1d3492ba83/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 21425,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/7df38270-92dd-427a-b504-799fb2b59add/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7df38270-92dd-427a-b504-799fb2b59add/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7df38270-92dd-427a-b504-799fb2b59add/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 21306,
        "title": "Hotels & Residences - Bürgenstock Resort Luzern",
        "url": "https://pkslist.azureedge.net/media/images/1/c49442df-0ffb-4d26-99fd-f288036082b8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c49442df-0ffb-4d26-99fd-f288036082b8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c49442df-0ffb-4d26-99fd-f288036082b8/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 21305,
        "title": "Bürgenstock Hotels & Resort - Palace Hotel, Waldhotel, Taverne ...",
        "url": "https://pkslist.azureedge.net/media/images/1/9b153857-97d5-42d7-86f1-3747e0813a78/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9b153857-97d5-42d7-86f1-3747e0813a78/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9b153857-97d5-42d7-86f1-3747e0813a78/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 21304,
        "title": "Bürgenstock Hotels & Resort Lake Lucerne - Bürgenstock Resort Luzern",
        "url": "https://pkslist.azureedge.net/media/images/1/f73ea697-99f4-480e-ac25-41d3d08c097d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f73ea697-99f4-480e-ac25-41d3d08c097d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f73ea697-99f4-480e-ac25-41d3d08c097d/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1618,
      "latitude": 46.9967744,
      "longitude": 8.379383599999983,
      "parentGeographicAreaId": 0,
      "title": "Bürgenstock 17, 6363 Bürgenstock, Switzerland",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 55,
        "tagName": "Lively",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 61,
        "tagName": "Mountain",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 66,
        "tagName": "Skiing",
        "tagType": 2
      },
      {
        "tagId": 68,
        "tagName": "Business",
        "tagType": 2
      },
      {
        "tagId": 81,
        "tagName": "Golf",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 88,
        "tagName": "Tennis",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 101,
        "tagName": "Cinema",
        "tagType": 2
      },
      {
        "tagId": 153,
        "tagName": "Cigar Lounge",
        "tagType": 2
      },
      {
        "tagId": 244,
        "tagName": "Members' Club",
        "tagType": 2
      },
      {
        "tagId": 281,
        "tagName": "Ice Skating",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 551,
        "venueCategoryId": 10,
        "venueId": 1803,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 10,
          "title": "Infinity Pools",
          "region": "Global",
          "viewOrder": 2,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 607,
        "venueCategoryId": 32,
        "venueId": 1803,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1636,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Schloss Elmau",
    "description": "Discerning globetrotters not only enjoy the magic location of Schloss Elmau in probably the most beautiful sanctuary of the Bavarian Alps, spectacular Spas and a liberating sense of perfect calm, hospitality without limitations & cosmopolitan restaurants, Yoga or Tai Chi Chuan with excellent teachers, phantastic sports as well as edutainment for their Kids, but also inspiring encounters with some of the greatest artists and authors of our time. The new RETREAT has been the Residence for the Heads of State & Governement during the G7 Summit 2015. 47 Suites, 2 Restaurants, Lounges, Library, Yoga Pavillon & Spa connect a wholly new dimension of space & casual elegance with breathtaking views of the monumental Wetterstein Mountain & roaring Ferchenbach Creek.",
    "brand": null,
    "blockQuote": "Whether it's snowing or the sun is shining, you will feel at peace in this remote sanctuary.",
    "emailAddress": "schloss@elmau.de",
    "website": "schloss-elmau.de/en/",
    "bookingURL": "https://reservations.schloss-elmau.de/Inquiry.aspx",
    "redirectNotes": null,
    "phoneNumber": "+49 8823 18 0",
    "addressOne": "2",
    "addressTwo": "Elmau",
    "city": "Elmau",
    "county": "",
    "region": "",
    "country": "Germany",
    "rooms": 47,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 18594,
        "title": "Schloss Elmau - Wikipedia",
        "url": "https://pkslist.azureedge.net/media/images/1/a4fd91e7-d52a-4522-824d-b72f0f5af730/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a4fd91e7-d52a-4522-824d-b72f0f5af730/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a4fd91e7-d52a-4522-824d-b72f0f5af730/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 18595,
        "title": "Hotel Schloss Elmau | Luxury German Hotels - Autobahn Adventures ...",
        "url": "https://pkslist.azureedge.net/media/images/1/f21c6924-0c94-4ef5-9c53-4b7a9cbd04e2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f21c6924-0c94-4ef5-9c53-4b7a9cbd04e2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f21c6924-0c94-4ef5-9c53-4b7a9cbd04e2/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 18596,
        "title": "Luxury Spa Break in the Alps | SCHLOSS ELMAU",
        "url": "https://pkslist.azureedge.net/media/images/1/ca577992-fb60-41f3-b133-c62d9edbe649/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ca577992-fb60-41f3-b133-c62d9edbe649/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ca577992-fb60-41f3-b133-c62d9edbe649/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 18600,
        "title": "Schloss Elmau Luxury Spa Retreat and Cultural Hideaway | Luxury ...",
        "url": "https://pkslist.azureedge.net/media/images/1/0857be7c-7805-48b0-8d50-4a7cd691fddf/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0857be7c-7805-48b0-8d50-4a7cd691fddf/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0857be7c-7805-48b0-8d50-4a7cd691fddf/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 18598,
        "title": "Schloss Elmau Hotel Review, Bavaria, Germany | Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/d8004d43-5703-4db8-89d8-fc900f51c464/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d8004d43-5703-4db8-89d8-fc900f51c464/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d8004d43-5703-4db8-89d8-fc900f51c464/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 18597,
        "title": "auf What I Love",
        "url": "https://pkslist.azureedge.net/media/images/1/dd8748da-008c-4581-b800-3bbaeae68d40/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/dd8748da-008c-4581-b800-3bbaeae68d40/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/dd8748da-008c-4581-b800-3bbaeae68d40/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 18593,
        "title": "Schloss Elmau Hotel Review, Bavaria, Germany | Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/be697e61-2a52-42b4-bdcd-6207847e8a04/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/be697e61-2a52-42b4-bdcd-6207847e8a04/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/be697e61-2a52-42b4-bdcd-6207847e8a04/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 18599,
        "title": "Schloss Elmau Luxury Spa Retreat and Cultural Hideaway | Luxury ...",
        "url": "https://pkslist.azureedge.net/media/images/1/dca3a035-7b25-4982-88f5-fac07ba1a7e0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/dca3a035-7b25-4982-88f5-fac07ba1a7e0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/dca3a035-7b25-4982-88f5-fac07ba1a7e0/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 18601,
        "title": "Endloser Badespaß: Die schönsten Infinity-Hotelpools in ...",
        "url": "https://pkslist.azureedge.net/media/images/1/9d46b2de-fb77-4d0c-9190-5ca5059b7968/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9d46b2de-fb77-4d0c-9190-5ca5059b7968/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9d46b2de-fb77-4d0c-9190-5ca5059b7968/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1450,
      "latitude": 47.462005,
      "longitude": 11.186613999999963,
      "parentGeographicAreaId": 0,
      "title": "In Elmau 2, 82493 Krün, Germany",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 45,
        "tagName": "Historic Sense",
        "tagType": 1
      },
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 66,
        "tagName": "Skiing",
        "tagType": 2
      },
      {
        "tagId": 67,
        "tagName": "Sporty",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 88,
        "tagName": "Tennis",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 131,
        "tagName": "Library",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 212,
        "tagName": "Hammam",
        "tagType": 2
      },
      {
        "tagId": 256,
        "tagName": "Boutique",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 620,
        "venueCategoryId": 32,
        "venueId": 1636,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1601,
    "venueCategoryId": 0,
    "status": 1,
    "title": "La Reserve Geneva Hotel & Spa",
    "description": "La Réserve Genève - Hotel and Spa is ideally located on the shores of Lake Geneva in a residential suburb just over three miles from the town center and two miles from the international airport. A cleverly concealed reserve of happiness available only to those in the know...\n\nThis 5 star hotel-spa was designed as a lodge in a world beyond time, reminiscent of a lost paradise on the outer edges of a legendary Africa. A spa, five restaurants, 73 rooms and 29 suites that tell a story of a wonderful journey. A warm, relaxed atmosphere, with a magical view over the waters of the lake Léman beyond the park. \n\nSome places just exude a mysterious power, as if the waves of positive harmony created a feeling of plenitude, of a natural connection with the inner self. La Réserve Genève - Hotel and Spa is one of these. Whether for a night or a few days, it procures a delicious sensation of feeling at home right from the first moment. Nestled in a tawny-colored leather club armchair in front of a monumental fireplace in winter; or lazing in the sun beside the pool in summer.",
    "brand": null,
    "blockQuote": "A secret luxury hotel far from prying eyes.",
    "emailAddress": "reservation@lareserve-geneve.com",
    "website": "lareserve.ch/en/geneva-luxury-hotel",
    "bookingURL": "https://www.myhotelreservation.net/b/hllw1634/HLLW1634/?f=2018-09-12&t=2018-09-13&i=96027890&_ga=2.255457096.1199442046.1536757563-681396870.1536757563",
    "redirectNotes": "La Reserve Geneva Hotel & Spa's reservations system",
    "phoneNumber": "+41 22 959 59 59 ",
    "addressOne": "301",
    "addressTwo": "Route de Lausanne",
    "city": "Geneva",
    "county": "",
    "region": "",
    "country": "Switzerland",
    "rooms": 102,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 17874,
        "title": "Hotel La Réserve Genève & Spa, Bellevue, Switzerland - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/5a5ffc4d-1c99-490d-b236-44600c7e5712/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5a5ffc4d-1c99-490d-b236-44600c7e5712/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5a5ffc4d-1c99-490d-b236-44600c7e5712/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 17876,
        "title": "La Reserve Geneve Hotel & Spa - Luxury spa hotel in Geneva ...",
        "url": "https://pkslist.azureedge.net/media/images/1/91a0a805-488f-4acb-885a-a812ea9a4df7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/91a0a805-488f-4acb-885a-a812ea9a4df7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/91a0a805-488f-4acb-885a-a812ea9a4df7/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 17877,
        "title": "La Réserve Genève - Hotel, Spa & Villas | Traveller Made",
        "url": "https://pkslist.azureedge.net/media/images/1/bf4b0b00-d446-4fb4-b4d8-e308fbfca4b7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bf4b0b00-d446-4fb4-b4d8-e308fbfca4b7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bf4b0b00-d446-4fb4-b4d8-e308fbfca4b7/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 17875,
        "title": "La Reserve Geneve :: Valentine's offer",
        "url": "https://pkslist.azureedge.net/media/images/1/49f91092-bab1-471a-b999-34f0e3ff209a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/49f91092-bab1-471a-b999-34f0e3ff209a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/49f91092-bab1-471a-b999-34f0e3ff209a/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 17879,
        "title": "Hotel La Réserve Genève & Spa, Bellevue, Switzerland - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/cbe60656-b41e-4845-a274-d789560d1c54/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cbe60656-b41e-4845-a274-d789560d1c54/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cbe60656-b41e-4845-a274-d789560d1c54/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 17873,
        "title": "Hotel La Réserve Genève & Spa, Bellevue, Switzerland - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/33d80f3f-1a1f-4b6e-a8f7-2866b8ceb2c1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/33d80f3f-1a1f-4b6e-a8f7-2866b8ceb2c1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/33d80f3f-1a1f-4b6e-a8f7-2866b8ceb2c1/256x192.jpg",
        "viewOrder": 5
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1415,
      "latitude": 46.25059439999999,
      "longitude": 6.149497999999994,
      "parentGeographicAreaId": 0,
      "title": "Route de Lausanne 301, 1293 Bellevue, Switzerland",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      },
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 69,
        "tagName": "Couples",
        "tagType": 2
      },
      {
        "tagId": 70,
        "tagName": "Families",
        "tagType": 2
      },
      {
        "tagId": 73,
        "tagName": "Outdoor Terraces",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 212,
        "tagName": "Hammam",
        "tagType": 2
      },
      {
        "tagId": 224,
        "tagName": "Villas",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 291,
        "venueCategoryId": 1,
        "venueId": 1601,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 1,
          "title": "Escape from the City",
          "region": "Global",
          "viewOrder": 14,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 621,
        "venueCategoryId": 32,
        "venueId": 1601,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1537,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Six Senses Kyoto",
    "description": "Historically the capital and still thought to be the cultural and historical heart of Japan, Kyoto is famed for its Buddhist temples, Shinto shrines, palaces, traditional Japanese architecture, beautiful cherry blossoms, and mountains covered in centuries-old cedar trees surrounding the city.\n\nYou’ll find us in Higashiyama, a prime location to enjoy an urban sanctuary and welcome recharge ready to discover many delights on the doorstep. We’re an easy walk from the Myoho-in Temple, Kyoto National Museum and Sanjusangendo Buddhist Temple. Choose your view in one of our 81 rooms and suites – either our courtyard garden, the city, or the garden of the famous 16th-century Toyokuni Shrine opposite us – with the interior design inspired by folklore, The Moon Rabbit, and the Tale of Genji.",
    "brand": "",
    "blockQuote": "Book a Japanese zen spa treatment for some much deserved pampering.",
    "emailAddress": "reservations-kyoto@sixsenses.com",
    "website": "sixsenses.com/en/hotels/kyoto",
    "bookingURL": "https://www.sixsenses.com/en/hotels/kyoto/accomodation-results?newSearch=1&src=&Hotel=UKYSS&Rooms=1&Adult=2&Child=0&_PMID=96027890",
    "redirectNotes": "Six Senses Kyoto's reservations system",
    "phoneNumber": "+81 75 606 4341",
    "addressOne": "431",
    "addressTwo": "",
    "city": "Kyoto",
    "county": "",
    "region": "",
    "country": "Japan",
    "rooms": 81,
    "dollar": 3,
    "updated": "2023-12-21T15:41:02.013",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 61967,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/11b87010-ef26-4bab-aba8-64fd51f6452c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/11b87010-ef26-4bab-aba8-64fd51f6452c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/11b87010-ef26-4bab-aba8-64fd51f6452c/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 61968,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/23cab0e4-8ba3-41e3-a1d0-3f54859a7c87/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/23cab0e4-8ba3-41e3-a1d0-3f54859a7c87/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/23cab0e4-8ba3-41e3-a1d0-3f54859a7c87/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 61971,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/feb24ed3-5a61-478e-863c-860f8f29999b/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/feb24ed3-5a61-478e-863c-860f8f29999b/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/feb24ed3-5a61-478e-863c-860f8f29999b/256x192.png",
        "viewOrder": 2
      },
      {
        "mediaId": 61980,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/41b5bab3-4651-445c-b0bb-e83f00ab9b9f/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/41b5bab3-4651-445c-b0bb-e83f00ab9b9f/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/41b5bab3-4651-445c-b0bb-e83f00ab9b9f/256x192.png",
        "viewOrder": 3
      },
      {
        "mediaId": 61970,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/239dd6b0-c141-4f18-b878-c493e75a1e97/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/239dd6b0-c141-4f18-b878-c493e75a1e97/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/239dd6b0-c141-4f18-b878-c493e75a1e97/256x192.png",
        "viewOrder": 4
      },
      {
        "mediaId": 61976,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c8f0b0bb-a6fc-43f5-96a7-35d79f1f4732/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c8f0b0bb-a6fc-43f5-96a7-35d79f1f4732/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c8f0b0bb-a6fc-43f5-96a7-35d79f1f4732/256x192.png",
        "viewOrder": 5
      },
      {
        "mediaId": 61973,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/886c0373-6cf5-4777-9540-e172fb29a648/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/886c0373-6cf5-4777-9540-e172fb29a648/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/886c0373-6cf5-4777-9540-e172fb29a648/256x192.png",
        "viewOrder": 6
      },
      {
        "mediaId": 61979,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/acbb776f-cfc9-4368-87d3-30ab79745cd3/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/acbb776f-cfc9-4368-87d3-30ab79745cd3/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/acbb776f-cfc9-4368-87d3-30ab79745cd3/256x192.png",
        "viewOrder": 7
      },
      {
        "mediaId": 61974,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/705871e4-d099-4e28-8a77-72e583ed08d3/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/705871e4-d099-4e28-8a77-72e583ed08d3/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/705871e4-d099-4e28-8a77-72e583ed08d3/256x192.png",
        "viewOrder": 8
      },
      {
        "mediaId": 61977,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/60f9c17e-1390-4d15-a312-595930d0b8f5/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/60f9c17e-1390-4d15-a312-595930d0b8f5/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/60f9c17e-1390-4d15-a312-595930d0b8f5/256x192.png",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1351,
      "latitude": 34.9911642,
      "longitude": 135.7742085,
      "parentGeographicAreaId": 0,
      "title": "431 Myōhōin Maekawachō, Higashiyama Ward, Kyoto, 605-0932, Japan",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 59,
        "tagName": "Cultural",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 216,
        "tagName": "Museum",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 299,
        "tagName": "Wellness Programs",
        "tagType": 2
      },
      {
        "tagId": 319,
        "tagName": "Temples",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 626,
        "venueCategoryId": 32,
        "venueId": 1537,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1458,
    "venueCategoryId": 1,
    "status": 1,
    "title": "Chable Resort & Spa",
    "description": "Chable Resort is a stupendously chic five-star resort deep in the Mayan forest. Think striking white cube buildings, rectangular clear blue pools amid lush palms and crumbling ruins; mysticism meets modernism.\n\nChable Resort offers 38 Casitas and 2 expansive Villas. Each casita is purposely situated within the lush gardens where nature can be enjoyed to its fullest. The original 19th century walls of the working hacienda serve as the framework for every casita, and incorporates modern touches, warm woods and traditional Mayan architecture to make Chablé a true work of art. Every room has a view and every view is a living part of the Hacienda. The villas are your home away from home, offering jungle views, Hacienda murals and glass walls bringing together the colonial heritage and modern design and creating a secluded hideaway.",
    "brand": null,
    "blockQuote": "A visit to the hotel's unique spa to see the cenote, a sacred body of water, is a must.",
    "emailAddress": "reservations@chableresort.com",
    "website": "chableresort.com/yucatan/",
    "bookingURL": "https://reservations.travelclick.com/107754?&iata=96027890&languageid=1#/guestsandrooms",
    "redirectNotes": "Chable Resort & Spa's reservations system",
    "phoneNumber": "+52 55 4161 3085",
    "addressOne": "",
    "addressTwo": "",
    "city": "Chochola",
    "county": "",
    "region": "Yucatan",
    "country": "Mexico",
    "rooms": 40,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 16051,
        "title": "Chablé Resort & Spa, Mérida, Yucatan",
        "url": "https://pkslist.azureedge.net/media/images/1/059ff86b-1cfa-4b3d-a028-47e04edb18a8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/059ff86b-1cfa-4b3d-a028-47e04edb18a8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/059ff86b-1cfa-4b3d-a028-47e04edb18a8/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 29884,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/58f31be6-353f-4447-99f3-dd34b1e3aa47/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/58f31be6-353f-4447-99f3-dd34b1e3aa47/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/58f31be6-353f-4447-99f3-dd34b1e3aa47/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 29890,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c509068e-bb47-4f4e-ae0c-33265f93f872/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c509068e-bb47-4f4e-ae0c-33265f93f872/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c509068e-bb47-4f4e-ae0c-33265f93f872/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 29887,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/69cc8e3e-ae0a-4135-bb8e-b529dc5768be/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/69cc8e3e-ae0a-4135-bb8e-b529dc5768be/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/69cc8e3e-ae0a-4135-bb8e-b529dc5768be/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 29889,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/4a1dca34-572f-479c-8999-aa95b2d62902/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4a1dca34-572f-479c-8999-aa95b2d62902/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4a1dca34-572f-479c-8999-aa95b2d62902/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 16044,
        "title": "Unique in the World: Chablé Resort Is Opening Fascinating ...",
        "url": "https://pkslist.azureedge.net/media/images/1/9ff34b0e-848a-4691-b507-853e7a8e60b6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9ff34b0e-848a-4691-b507-853e7a8e60b6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9ff34b0e-848a-4691-b507-853e7a8e60b6/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 29886,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/702b25bf-61bc-47b8-88cc-d5d060c8cd79/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/702b25bf-61bc-47b8-88cc-d5d060c8cd79/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/702b25bf-61bc-47b8-88cc-d5d060c8cd79/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 29883,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d9bba6c5-e9dd-40af-bb79-2335a6739469/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d9bba6c5-e9dd-40af-bb79-2335a6739469/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d9bba6c5-e9dd-40af-bb79-2335a6739469/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 29892,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/3d00a282-669f-40a0-8ca7-37f3207730ed/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3d00a282-669f-40a0-8ca7-37f3207730ed/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3d00a282-669f-40a0-8ca7-37f3207730ed/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 29888,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/df5f4a28-19f8-482d-8e36-6e004e41a77c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/df5f4a28-19f8-482d-8e36-6e004e41a77c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/df5f4a28-19f8-482d-8e36-6e004e41a77c/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 16047,
        "title": "Book Chable Resort and Spa in Chochola | Hotels.com",
        "url": "https://pkslist.azureedge.net/media/images/1/87b31904-ff38-44b0-8eb7-de162174fd59/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/87b31904-ff38-44b0-8eb7-de162174fd59/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/87b31904-ff38-44b0-8eb7-de162174fd59/256x192.jpg",
        "viewOrder": 11
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1275,
      "latitude": 20.754219,
      "longitude": -89.8614384,
      "parentGeographicAreaId": 0,
      "title": "Tablaje 642 San Antonio Chablé, 97816 Chocholá, Yuc., Mexico",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 57,
        "tagName": "Romantic",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 59,
        "tagName": "Cultural",
        "tagType": 2
      },
      {
        "tagId": 60,
        "tagName": "Gastronomic",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 164,
        "tagName": "Excursions",
        "tagType": 2
      },
      {
        "tagId": 172,
        "tagName": "Biking",
        "tagType": 2
      },
      {
        "tagId": 181,
        "tagName": "Private/Suite Pools",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 266,
        "venueCategoryId": 1,
        "venueId": 1458,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 1,
          "title": "Escape from the City",
          "region": "Global",
          "viewOrder": 14,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 624,
        "venueCategoryId": 32,
        "venueId": 1458,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1328,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Santani",
    "description": "Lasting wellness is achieved by bringing oneself into balance, with nature as well as through the nurture we give ourselves and receive from others.\n\nMeaning “in harmony with,” Santani offers a uniquely restorative sanctuary where this belief guides everything we do. Wellness programmes in Ayurveda, detox and cleansing, yoga and relaxation combine on site pampering and rejuvenation with enlightened learning for healthy living long after you leave our invigorating surroundings.\n\nDesigned to free the mind while treading as lightly as possible on the earth, these private sanctuaries blur the distinction between indoors and out, to encourage you to rest, relax and meditate while inhaling the purest, freshest air and living within in this pristine, truly inspiring scenery that surrounds us.\n\nChoice and freedom are essential to sustainable wellness. Whether you like juicing, vegetarian, vegan and raw foods or you prefer heartier wholesome fare, Santani is about taking pleasure in the locally sourced, organic food you eat here. Our healthy, nutritious and naturally delicious personalised dishes are thoughtfully prepared by Santani’s experienced, internationally trained chefs.\n\nHarmonising health science innovations from around the world with the most effective treatments of Sri Lankan Ayurveda, Santani offers a thoughtful, sustainable approach to wellness: we pamper your body to revitalize you here and we educate your mind so that you leave with healthy habits and practices which allow you to enjoy greater mental, spiritual and physical well-being for years to come.",
    "brand": null,
    "blockQuote": "There are no menus here, the chefs make recommendations based on your dietary requirements using organic fruits and vegetables locally grown.",
    "emailAddress": "reservations@santani.lk",
    "website": "santani.lk/",
    "bookingURL": "http://www.booking.com/Share-XMDiew?aid=925669",
    "redirectNotes": null,
    "phoneNumber": "+94 76 399 1919",
    "addressOne": "",
    "addressTwo": "",
    "city": "Werapitiya",
    "county": "Kandy",
    "region": "",
    "country": "Sri Lanka",
    "rooms": 20,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 14072,
        "title": "Santani Wellness Resort & Spa - UPDATED 2017 Prices & Resort (All ...",
        "url": "https://pkslist.azureedge.net/media/images/1/123576ca-cd75-410c-bf7f-ce553a3c8069/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/123576ca-cd75-410c-bf7f-ce553a3c8069/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/123576ca-cd75-410c-bf7f-ce553a3c8069/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 14063,
        "title": "Santani Resort & Spa, Kandy, Sri Lanka - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/983c21db-9b35-4857-81f4-a699f5649211/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/983c21db-9b35-4857-81f4-a699f5649211/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/983c21db-9b35-4857-81f4-a699f5649211/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 15768,
        "title": "Santani Wellness Resort & Spa",
        "url": "https://pkslist.azureedge.net/media/images/1/d6df08fa-ee2b-4b3f-944b-02f9dd81f417/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d6df08fa-ee2b-4b3f-944b-02f9dd81f417/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d6df08fa-ee2b-4b3f-944b-02f9dd81f417/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 14064,
        "title": "The Sri Lankan Escape on a Former Tea Estate - SURFACE",
        "url": "https://pkslist.azureedge.net/media/images/1/69455d31-8217-4a5b-a552-38ab959d508f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/69455d31-8217-4a5b-a552-38ab959d508f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/69455d31-8217-4a5b-a552-38ab959d508f/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 14066,
        "title": "Santani - Wellness Resort, Sri Lanka | Travel | Pinterest ...",
        "url": "https://pkslist.azureedge.net/media/images/1/1b91b83b-4899-4533-a514-bf0f6cc04b4d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1b91b83b-4899-4533-a514-bf0f6cc04b4d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1b91b83b-4899-4533-a514-bf0f6cc04b4d/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 15769,
        "title": "Santani Resort & Spa (Srí Lanka Kandy) - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/12596c2d-3b07-4f4b-a34e-443c00bb4300/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/12596c2d-3b07-4f4b-a34e-443c00bb4300/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/12596c2d-3b07-4f4b-a34e-443c00bb4300/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 14068,
        "title": "Fall in love, Never Leave – Santani, Sri Lanka | Must See Places",
        "url": "https://pkslist.azureedge.net/media/images/1/ffcacdd7-05fe-4d9a-a13e-b8b9b7f406dc/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ffcacdd7-05fe-4d9a-a13e-b8b9b7f406dc/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ffcacdd7-05fe-4d9a-a13e-b8b9b7f406dc/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 15765,
        "title": "5 healthy holidays for winter sun - Healthista",
        "url": "https://pkslist.azureedge.net/media/images/1/17b453ea-d836-4805-a098-b9222c91b0d8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/17b453ea-d836-4805-a098-b9222c91b0d8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/17b453ea-d836-4805-a098-b9222c91b0d8/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 14070,
        "title": "Fall in love, Never Leave – Santani, Sri Lanka | Must See Places",
        "url": "https://pkslist.azureedge.net/media/images/1/7b598e63-d5a2-406f-b44b-37f7d8070414/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7b598e63-d5a2-406f-b44b-37f7d8070414/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7b598e63-d5a2-406f-b44b-37f7d8070414/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 14071,
        "title": "Santani Wellness Resort & Spa - PlushEscapes",
        "url": "https://pkslist.azureedge.net/media/images/1/c1339b86-c38e-4ba8-a1af-309e63a922d6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c1339b86-c38e-4ba8-a1af-309e63a922d6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c1339b86-c38e-4ba8-a1af-309e63a922d6/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 14065,
        "title": "Santani Resort & Spa, Kandy, Sri Lanka - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/1c7ea2e1-0bce-4d82-96d6-248499c57beb/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1c7ea2e1-0bce-4d82-96d6-248499c57beb/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1c7ea2e1-0bce-4d82-96d6-248499c57beb/256x192.jpg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1149,
      "latitude": 7.356067999999998,
      "longitude": 80.74248599999999,
      "parentGeographicAreaId": 0,
      "title": "Aratenna Estate, 00013, Sri Lanka",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 53,
        "tagName": "Cutting Edge",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 61,
        "tagName": "Mountain",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 203,
        "tagName": "Sauna",
        "tagType": 2
      },
      {
        "tagId": 205,
        "tagName": "Design",
        "tagType": 2
      },
      {
        "tagId": 266,
        "tagName": "Nature Walk",
        "tagType": 2
      },
      {
        "tagId": 285,
        "tagName": "Steam Room",
        "tagType": 2
      },
      {
        "tagId": 299,
        "tagName": "Wellness Programs",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 625,
        "venueCategoryId": 32,
        "venueId": 1328,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1200,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Borgo Egnazia",
    "description": "Borgo is the Italian word for village. Borgo Egnazia, a stroll from the Adriatic Sea on the fertile plains of Puglia, is a village of dreams hewn in the beautiful white Tufo stone of the region. If it had existed in ancient times it would have been the eighth wonder of the world! But although it looks as if time, sun, footsteps and sea breezes have weathered its facades, Borgo Egnazia is a 21st century creation that sparks the imagination with its characterful buttresses and the higgledy-piggledy layout of the casette around the central Piazza. You arrive at La Corte (the court), which sits like a manor house or Moorish Castle to the side of the village. It's a castle of troubadours and sanctuary though, not of battlements. Here are rooms where you can take your sojourn with us, descending the sweeping stairway to enjoy the bars, restaurants, terraces, spa and pools as well as the many secreted, gently lit bowers in a labyrinth of corridors that make it seem as if you had entered the Temple of Aphrodite! Alternatively you can choose to stay in one of the Borgo's casette, a deux or as a family of four, which nestle around two piazzas down bougainvillea- scented alleyways that you would swear had been there since the days of carts and horses. Larger families might prefer to take one of our 29 villas that fringe the village with their own plunge pools and shaded gardens, lush with oleander and rosemary. \n\nBorgo Egnazia is hard to describe. It's a place like nowhere else, with so much thought having gone into every detail. You have to come and experience it for yourself.",
    "brand": null,
    "blockQuote": "All rooms have a private balcony facing the hills, the sea, or the golf course but I like sea view best.",
    "emailAddress": "info@borgoegnazia.com",
    "website": "borgoegnazia.com/",
    "bookingURL": "http://www.booking.com/Share-nvo5of?aid=925669",
    "redirectNotes": null,
    "phoneNumber": "+39 080 225 5000",
    "addressOne": "",
    "addressTwo": "Strada Comunale Egnazia",
    "city": "Fasano",
    "county": "",
    "region": "Puglia",
    "country": "Italy",
    "rooms": 183,
    "dollar": 2,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 12413,
        "title": "Borgo Egnazia: 2017 Room Prices, Deals & Reviews | Expedia",
        "url": "https://pkslist.azureedge.net/media/images/1/3a8e0c1f-387c-410d-80c3-368f58738d09/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3a8e0c1f-387c-410d-80c3-368f58738d09/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3a8e0c1f-387c-410d-80c3-368f58738d09/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 12405,
        "title": "Home |",
        "url": "https://pkslist.azureedge.net/media/images/1/7c82d81d-3d96-4120-a6e8-292a97b9bc67/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7c82d81d-3d96-4120-a6e8-292a97b9bc67/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7c82d81d-3d96-4120-a6e8-292a97b9bc67/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 12403,
        "title": "Hotel Borgo Egnazia, Savelletri, Italy - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/ffde64d2-adfe-4a38-b5bf-3f3862bec60f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ffde64d2-adfe-4a38-b5bf-3f3862bec60f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ffde64d2-adfe-4a38-b5bf-3f3862bec60f/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 12407,
        "title": "Borgo Egnazia - 5 Star Hotel in Puglia - Official website",
        "url": "https://pkslist.azureedge.net/media/images/1/0a5f6092-d6d3-4f94-a43c-e146c4892bc3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0a5f6092-d6d3-4f94-a43c-e146c4892bc3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0a5f6092-d6d3-4f94-a43c-e146c4892bc3/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 12409,
        "title": "Videos and Tours | Five-star hotel in Puglia",
        "url": "https://pkslist.azureedge.net/media/images/1/af6240a6-4d76-4b7e-a953-eef0605ccab5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/af6240a6-4d76-4b7e-a953-eef0605ccab5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/af6240a6-4d76-4b7e-a953-eef0605ccab5/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 12414,
        "title": "Borgo Egnazia Hotel Review, Puglia, Italy | Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/e79b8631-d51e-427f-a2f0-b0ae78ddcc13/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e79b8631-d51e-427f-a2f0-b0ae78ddcc13/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e79b8631-d51e-427f-a2f0-b0ae78ddcc13/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 12404,
        "title": "Five-star hotel room by the sea in Puglia",
        "url": "https://pkslist.azureedge.net/media/images/1/d67ae07b-6f40-4d99-96a2-8abebc1615d8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d67ae07b-6f40-4d99-96a2-8abebc1615d8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d67ae07b-6f40-4d99-96a2-8abebc1615d8/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 12406,
        "title": "Five-star hotel suite by the sea in Puglia",
        "url": "https://pkslist.azureedge.net/media/images/1/9fe9816a-915d-4763-bf64-edc7f05e5eb4/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9fe9816a-915d-4763-bf64-edc7f05e5eb4/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9fe9816a-915d-4763-bf64-edc7f05e5eb4/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 12410,
        "title": "Five-star hotel room by the sea in Puglia",
        "url": "https://pkslist.azureedge.net/media/images/1/5a56cb5f-c05d-4edf-8978-ddf36d71b310/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5a56cb5f-c05d-4edf-8978-ddf36d71b310/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5a56cb5f-c05d-4edf-8978-ddf36d71b310/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 12408,
        "title": "Borgo Egnazia - 5 Star Hotel in Puglia - Official website",
        "url": "https://pkslist.azureedge.net/media/images/1/164ec1db-d685-4443-9e59-585bbf7e5db2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/164ec1db-d685-4443-9e59-585bbf7e5db2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/164ec1db-d685-4443-9e59-585bbf7e5db2/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 12412,
        "title": "Hotel Borgo Egnazia, Savelletri, Italy - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/0b5f6cb1-042c-4a65-a292-b2e71fc9a845/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0b5f6cb1-042c-4a65-a292-b2e71fc9a845/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0b5f6cb1-042c-4a65-a292-b2e71fc9a845/256x192.jpg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1025,
      "latitude": 40.8752544,
      "longitude": 17.39635910000004,
      "parentGeographicAreaId": 0,
      "title": "Strada Comunale Egnazia, 72015 Savelletri, Fasano BR, Italy",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 45,
        "tagName": "Historic Sense",
        "tagType": 1
      },
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 60,
        "tagName": "Gastronomic",
        "tagType": 2
      },
      {
        "tagId": 67,
        "tagName": "Sporty",
        "tagType": 2
      },
      {
        "tagId": 70,
        "tagName": "Families",
        "tagType": 2
      },
      {
        "tagId": 81,
        "tagName": "Golf",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 88,
        "tagName": "Tennis",
        "tagType": 2
      },
      {
        "tagId": 172,
        "tagName": "Biking",
        "tagType": 2
      },
      {
        "tagId": 205,
        "tagName": "Design",
        "tagType": 2
      },
      {
        "tagId": 206,
        "tagName": "Private Beach",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 213,
        "tagName": "Roof Terrace",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 623,
        "venueCategoryId": 32,
        "venueId": 1200,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1194,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Sha Wellness Clinic",
    "description": "SHA is a wellness clinic dedicated to improving people’s health and well-being through a fusion of ancient oriental disciplines and revolutionary western techniques. There are three main areas at SHA: The SHA Method, anti-aging and aesthetics medicine. The aim of these areas is to ensure that all guests enjoy a pleasant stay and leave looking and feeling younger and healthier, revitalised and renewed both inside and out. Guests will experience a fundamental change in their bodies, improving their quality of life, slowing down the aging process and preventing illness. SHA has always ensured that everything is exquisitely planned right down to the finest detail, even before the first stone was laid. Located in a magical setting where peace is the essence, blessed with a warm climate and stunning views that never fail to move even its most distinguished clients. The results speak for themselves: paradise on earth, spread throughout five buildings that are in perfect harmony with the natural surroundings, set atop the beautiful Sierra Helada del Albir, overlooking the Mediterranean Sea and close to the peaceful town of Altea, all at the service of your well-being, health and beauty.",
    "brand": null,
    "blockQuote": "If you are committed to making your body the best it can be, this is the perfect place to do it.",
    "emailAddress": "reservations@shawellnessclinic.com",
    "website": "shawellnessclinic.com/en/",
    "bookingURL": "https://shawellnessclinic.com/en/online-reservations/",
    "redirectNotes": null,
    "phoneNumber": "+34 966 81 11 99",
    "addressOne": "5",
    "addressTwo": "Carrer del Verderol",
    "city": "L'Albir",
    "county": "",
    "region": "",
    "country": "Spain",
    "rooms": 93,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 12192,
        "title": "SHA Wellness Clinic (Alicante, Spain) - New You & Me",
        "url": "https://pkslist.azureedge.net/media/images/1/1168bace-c5d1-4e79-bf32-1ce9cc98f74a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1168bace-c5d1-4e79-bf32-1ce9cc98f74a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1168bace-c5d1-4e79-bf32-1ce9cc98f74a/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 12186,
        "title": "SHA Wellness Clinic | Health and Fitness Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/5302522b-8d8b-4854-8cb1-3402814ad6b1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5302522b-8d8b-4854-8cb1-3402814ad6b1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5302522b-8d8b-4854-8cb1-3402814ad6b1/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 12187,
        "title": "SHA Wellness Clinic – Alicante Spain | Luxury Travelers Guide",
        "url": "https://pkslist.azureedge.net/media/images/1/fa9bf11d-c4c3-4adf-9435-ecff345b8773/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fa9bf11d-c4c3-4adf-9435-ecff345b8773/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fa9bf11d-c4c3-4adf-9435-ecff345b8773/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 12190,
        "title": "Sleep Recovery Programme at SHA Wellness Clinic - DOSE",
        "url": "https://pkslist.azureedge.net/media/images/1/5a0f8628-a306-489f-995d-9e8f8edb66ec/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5a0f8628-a306-489f-995d-9e8f8edb66ec/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5a0f8628-a306-489f-995d-9e8f8edb66ec/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 12194,
        "title": "Sha Wellness Clinic - hotelroomsearch.net",
        "url": "https://pkslist.azureedge.net/media/images/1/bbca2fc9-893d-4695-a3f5-806882ce0844/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bbca2fc9-893d-4695-a3f5-806882ce0844/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bbca2fc9-893d-4695-a3f5-806882ce0844/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 12189,
        "title": "sha wellness clinic | the healthy holiday company",
        "url": "https://pkslist.azureedge.net/media/images/1/01bc9f95-9b07-4093-9b30-3c8f9a589834/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/01bc9f95-9b07-4093-9b30-3c8f9a589834/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/01bc9f95-9b07-4093-9b30-3c8f9a589834/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 12191,
        "title": "Resort Sha Wellness Clinic, Albir, Spain - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/26a6af37-061d-428b-b7d7-322e8ceff563/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/26a6af37-061d-428b-b7d7-322e8ceff563/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/26a6af37-061d-428b-b7d7-322e8ceff563/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 12193,
        "title": "SHA Wellness Clinic – Alicante Spain | Luxury Travelers Guide",
        "url": "https://pkslist.azureedge.net/media/images/1/03c91a71-54d2-4b4a-aba4-110bf62b72b5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/03c91a71-54d2-4b4a-aba4-110bf62b72b5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/03c91a71-54d2-4b4a-aba4-110bf62b72b5/256x192.jpg",
        "viewOrder": 7
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1018,
      "latitude": 38.55982799999999,
      "longitude": -0.07375899999999547,
      "parentGeographicAreaId": 0,
      "title": "Carrer del Verderol, 5, 03581 L'Albir, Alicante, Spain",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 53,
        "tagName": "Cutting Edge",
        "tagType": 1
      },
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 67,
        "tagName": "Sporty",
        "tagType": 2
      },
      {
        "tagId": 77,
        "tagName": "Cooking Classes",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 180,
        "tagName": "Health Management Programs",
        "tagType": 2
      },
      {
        "tagId": 205,
        "tagName": "Design",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 604,
        "venueCategoryId": 32,
        "venueId": 1194,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 860,
    "venueCategoryId": 1,
    "status": 1,
    "title": "Cal Reiet Holistic Retreat",
    "description": "Cal Reiet is a unique country hideaway with the authenticity of a home away from home atmosphere. Set in beautifully lush Mediterranean gardens, an oasis ripe with scents of the earth, sea and sun and an ideal setting for the communion of mind, body and spirit. This is Cal Reiet, a holistic wellbeing retreat and boutique lifestyle hotel featuring 15 beautifully designed rooms and suites.",
    "brand": null,
    "blockQuote": "This is a place of lifelong learning, a place to nourish your individuality and to connect with your mind, body and spirit.",
    "emailAddress": "enquiries@calreiet.com",
    "website": "calreiet.com/",
    "bookingURL": "http://www.booking.com/Share-5EBDbE?aid=925669",
    "redirectNotes": null,
    "phoneNumber": "+34 971 947 047",
    "addressOne": "80",
    "addressTwo": "Carrer de C'al Reiet",
    "city": "Santanyi",
    "county": "",
    "region": "Mallorca",
    "country": "Spain",
    "rooms": 15,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 18143,
        "title": "Holistic retreats in Mallorca | Boutique hotel with organic dining ...",
        "url": "https://pkslist.azureedge.net/media/images/1/512a32eb-464f-4107-a355-980d15b9d4f3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/512a32eb-464f-4107-a355-980d15b9d4f3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/512a32eb-464f-4107-a355-980d15b9d4f3/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 18141,
        "title": "Cal Reiet Holistic Retreat, Santanyi, Spain | VeganWelcome",
        "url": "https://pkslist.azureedge.net/media/images/1/ad1d0962-79ee-4b31-af1f-accb464a1a9b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ad1d0962-79ee-4b31-af1f-accb464a1a9b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ad1d0962-79ee-4b31-af1f-accb464a1a9b/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 18139,
        "title": "Book Cal Reiet Holistic Retreat in Santanyi | Hotels.com",
        "url": "https://pkslist.azureedge.net/media/images/1/e4ea1c89-c660-41c9-899d-e1bd51b7642c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e4ea1c89-c660-41c9-899d-e1bd51b7642c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e4ea1c89-c660-41c9-899d-e1bd51b7642c/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 18140,
        "title": "Hotel Cal Reiet Holistic Retreat, Santanyi, Spain - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/c0e7191b-ba66-4615-9410-899e27d00b7b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c0e7191b-ba66-4615-9410-899e27d00b7b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c0e7191b-ba66-4615-9410-899e27d00b7b/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 8008,
        "title": "Cal Reiet Holistic Retreat, Mallorca (Majorca) Hotel Review",
        "url": "https://pkslist.azureedge.net/media/images/1/fb820d56-6ad4-4861-a0de-64dfb4d8b7fe/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fb820d56-6ad4-4861-a0de-64dfb4d8b7fe/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fb820d56-6ad4-4861-a0de-64dfb4d8b7fe/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 18142,
        "title": "Cal Reiet Holistic Retreat, a Design Boutique Hotel Santanyi, Spain",
        "url": "https://pkslist.azureedge.net/media/images/1/60424973-327f-4a45-ae93-af1416daebd0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/60424973-327f-4a45-ae93-af1416daebd0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/60424973-327f-4a45-ae93-af1416daebd0/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 8004,
        "title": "Interior design by BLOOMINT DESIGN - Hotel Cal Reiet Holistic ...",
        "url": "https://pkslist.azureedge.net/media/images/1/8a3709f8-da31-40d9-9a08-6713318159b1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8a3709f8-da31-40d9-9a08-6713318159b1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8a3709f8-da31-40d9-9a08-6713318159b1/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 8007,
        "title": "Chic suite terrace - Picture of Cal Reiet Holistic Retreat ...",
        "url": "https://pkslist.azureedge.net/media/images/1/c4fd6d9d-2b63-49ca-98cc-017b7ba6df2a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c4fd6d9d-2b63-49ca-98cc-017b7ba6df2a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c4fd6d9d-2b63-49ca-98cc-017b7ba6df2a/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 8012,
        "title": "Cal Reiet Holistic Retreat - Yoga Retreat in Santanyi",
        "url": "https://pkslist.azureedge.net/media/images/1/9b2ffff1-c6cd-4838-af7e-0375665963b3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9b2ffff1-c6cd-4838-af7e-0375665963b3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9b2ffff1-c6cd-4838-af7e-0375665963b3/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 681,
      "latitude": 39.35585899999999,
      "longitude": 3.134875999999963,
      "parentGeographicAreaId": 0,
      "title": "Carrer de C'al Reiet, 80, 07650 Santanyí, Illes Balears, Spain",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 47,
        "tagName": "Intimate Luxury ",
        "tagType": 1
      },
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 81,
        "tagName": "Golf",
        "tagType": 2
      },
      {
        "tagId": 82,
        "tagName": "Horseback Riding",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 104,
        "tagName": "Cycling",
        "tagType": 2
      },
      {
        "tagId": 115,
        "tagName": "Snorkeling",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 171,
        "tagName": "Trekking",
        "tagType": 2
      },
      {
        "tagId": 193,
        "tagName": "Outdoor Dining",
        "tagType": 2
      },
      {
        "tagId": 299,
        "tagName": "Wellness Programs",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 155,
        "venueCategoryId": 1,
        "venueId": 860,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 1,
          "title": "Escape from the City",
          "region": "Global",
          "viewOrder": 14,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 614,
        "venueCategoryId": 32,
        "venueId": 860,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 680,
    "venueCategoryId": 20,
    "status": 1,
    "title": "The Dolder Grand",
    "description": "An exquisite setting in which to relax and enjoy yourself, with a wonderful view out over the city, lake and mountains. The Dolder Grand offers all of this and more – it is situated high above Zurich, not far from the city centre and yet in beautiful natural surroundings.\n\nThe Dolder Grand in Zurich has been the very epitome of hospitality since 1899. A complete renovation in 2008 created a new city resort with exquisite cuisine, a unique art collection and an unrivalled spa.",
    "brand": null,
    "blockQuote": "A storybook castle situated dramatically on a hill with spectacular views across the city and lake to the Alps Mountains.",
    "emailAddress": "info@thedoldergrand.com",
    "website": "thedoldergrand.com/en/",
    "bookingURL": "https://www.myhotelreservation.net/b/hllw1618/HLLW1618/?f=2018-09-03&t=2018-09-04&i=96027890&_ga=2.108660481.2061360871.1535967569-1672936498.1535967569",
    "redirectNotes": null,
    "phoneNumber": "+41 44 456 60 00",
    "addressOne": "65",
    "addressTwo": "Kurhausstrasse",
    "city": "Zurich",
    "county": "",
    "region": "",
    "country": "Switzerland",
    "rooms": 175,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 6661,
        "title": "The Dolder Grand Hotel in Switzerland, Zurich | Newsnish",
        "url": "https://pkslist.azureedge.net/media/images/1/b72139fc-87c3-4fd8-b8eb-28d6d01f0235/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b72139fc-87c3-4fd8-b8eb-28d6d01f0235/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b72139fc-87c3-4fd8-b8eb-28d6d01f0235/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 6660,
        "title": "Dolder Grand Hotel | Foster + Partners",
        "url": "https://pkslist.azureedge.net/media/images/1/e2a46a1e-de29-4e55-b193-f36bec2cfaf9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e2a46a1e-de29-4e55-b193-f36bec2cfaf9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e2a46a1e-de29-4e55-b193-f36bec2cfaf9/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 6662,
        "title": "Dolder Grand Hotel Review | Fodor's Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/6643e7f9-59af-439b-b5c8-1a4f406e7d2a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6643e7f9-59af-439b-b5c8-1a4f406e7d2a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6643e7f9-59af-439b-b5c8-1a4f406e7d2a/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 6111,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f892c13f-9fe3-42b2-854f-b317b1441985/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f892c13f-9fe3-42b2-854f-b317b1441985/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f892c13f-9fe3-42b2-854f-b317b1441985/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 6112,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/384b4e13-86a7-4cf7-899c-4f16864e3df5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/384b4e13-86a7-4cf7-899c-4f16864e3df5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/384b4e13-86a7-4cf7-899c-4f16864e3df5/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 6109,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/207936e0-53d4-4961-844b-3877dc149c4e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/207936e0-53d4-4961-844b-3877dc149c4e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/207936e0-53d4-4961-844b-3877dc149c4e/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 6110,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/8be286ba-6273-4d60-97db-d4cb307ffecd/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8be286ba-6273-4d60-97db-d4cb307ffecd/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8be286ba-6273-4d60-97db-d4cb307ffecd/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 6108,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/61ab7555-ee45-4ff8-9bde-9755d68e1683/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/61ab7555-ee45-4ff8-9bde-9755d68e1683/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/61ab7555-ee45-4ff8-9bde-9755d68e1683/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 6105,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b967b0a2-2e88-46ae-8e07-5bee0c8100e5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b967b0a2-2e88-46ae-8e07-5bee0c8100e5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b967b0a2-2e88-46ae-8e07-5bee0c8100e5/256x192.jpg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 517,
      "latitude": 47.372676,
      "longitude": 8.573155000000042,
      "parentGeographicAreaId": 0,
      "title": "Kurhausstrasse 65, 8032 Zürich, Switzerland",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 45,
        "tagName": "Historic Sense",
        "tagType": 1
      },
      {
        "tagId": 48,
        "tagName": "Iconic/Landmark ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 100,
        "tagName": "Wedding",
        "tagType": 2
      },
      {
        "tagId": 216,
        "tagName": "Museum",
        "tagType": 2
      },
      {
        "tagId": 222,
        "tagName": "Michelin Star dining",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 299,
        "tagName": "Wellness Programs",
        "tagType": 2
      },
      {
        "tagId": 336,
        "tagName": "Ballroom",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 118,
        "venueCategoryId": 20,
        "venueId": 680,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 20,
          "title": "BucketList Hotels",
          "region": "Global",
          "viewOrder": 0,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 605,
        "venueCategoryId": 32,
        "venueId": 680,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 575,
    "venueCategoryId": 10,
    "status": 1,
    "title": "Las Ventanas al Paraiso, A Rosewood Resort",
    "description": "Las Ventanas al Paraíso, a Rosewood Resort, has redefined pampering and luxury in Los Cabos with unparalleled service and unwavering attention to detail.\n\nLas Ventanas al Paraíso offers a choice of exquisitely appointed accommodations. The 84 enchanting suites and villas feature the finest craftsmanship, Mexican motifs and artworks, and accessories reflective of the vibrant hues of Los Cabos.\nThe expansive villas feature hand-carved furniture and accessories reflective of the vibrant hues of Los Cabos. Each villa boasts a private infinity-edge pool as well as awe-inspiring views of the Sea of Cortez. The Ty Warner Mansion is an exclusive 28,000-square-foot beachfront mansion poised directly on the water's edge with panoramic views over the Sea of Cortez. For longer stays in Los Cabos, private residences offer resort living convenience.",
    "brand": "Rosewood",
    "blockQuote": "The pristine white sand beach is a magical place for a sunset dinner.",
    "emailAddress": "lasventanas@rosewoodhotels.com",
    "website": "rosewoodhotels.com/en/las-ventanas-los-cabos",
    "bookingURL": "https://reservations.rosewoodhotels.com/?adult=1&agencyid=96027890&arrive=2021-06-08&chain=5159&child=0&config=gtm&currency=USD&depart=2021-06-09&filter=&hotel=160&level=hotel&locale=en-US&rooms=1",
    "redirectNotes": "Rosewood Las Ventanas al Paraiso's reservations system",
    "phoneNumber": "+52 624 144 2800",
    "addressOne": "",
    "addressTwo": "Km 19.5, Carr. Transpeninsular",
    "city": "Los Cabos",
    "county": "",
    "region": "",
    "country": "Mexico",
    "rooms": 84,
    "dollar": 3,
    "updated": "2021-06-08T14:13:00.223",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 36143,
        "title": "Review: Las Ventanas al Paraiso",
        "url": "https://pkslist.azureedge.net/media/images/1/886f9fa6-4dff-46e5-ad9a-bbf09a3e70b1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/886f9fa6-4dff-46e5-ad9a-bbf09a3e70b1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/886f9fa6-4dff-46e5-ad9a-bbf09a3e70b1/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 36139,
        "title": "Resort Las Ventanas al Paraiso, A Rosewood Resort, San Jose del ...",
        "url": "https://pkslist.azureedge.net/media/images/1/067ac9d5-4e17-4d8d-977a-f25df51257f9/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/067ac9d5-4e17-4d8d-977a-f25df51257f9/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/067ac9d5-4e17-4d8d-977a-f25df51257f9/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 36140,
        "title": "Las Ventanas Al Paraiso, A Rosewood Resort | HKS Architects",
        "url": "https://pkslist.azureedge.net/media/images/1/12e90aa2-e54d-4685-bc04-452dd4f669c0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/12e90aa2-e54d-4685-bc04-452dd4f669c0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/12e90aa2-e54d-4685-bc04-452dd4f669c0/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 36146,
        "title": "Las Ventanas al Paraiso, A Rosewood Resort - Cabo San Lucas - One ...",
        "url": "https://pkslist.azureedge.net/media/images/1/57996a6b-e591-473f-b5c1-f1c1ee5a8185/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/57996a6b-e591-473f-b5c1-f1c1ee5a8185/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/57996a6b-e591-473f-b5c1-f1c1ee5a8185/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 36148,
        "title": "Hotels - Andrew Harper Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/ec7e06fc-b749-49af-939c-5eb4bbfa1311/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ec7e06fc-b749-49af-939c-5eb4bbfa1311/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ec7e06fc-b749-49af-939c-5eb4bbfa1311/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 36145,
        "title": "Las Ventanas al Paraiso, A Rosewood Resort - Cabo San Lucas - One ...",
        "url": "https://pkslist.azureedge.net/media/images/1/71633761-6dce-4703-a640-d7f304c6a911/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/71633761-6dce-4703-a640-d7f304c6a911/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/71633761-6dce-4703-a640-d7f304c6a911/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 36144,
        "title": "Ultimate Jet Vacations",
        "url": "https://pkslist.azureedge.net/media/images/1/baa70e13-dc0d-4ba8-ba9e-c5ff09cf3f28/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/baa70e13-dc0d-4ba8-ba9e-c5ff09cf3f28/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/baa70e13-dc0d-4ba8-ba9e-c5ff09cf3f28/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 36138,
        "title": "Las Ventanas al Paraiso, A Rosewood Resort - Los Cabos, Mexico ...",
        "url": "https://pkslist.azureedge.net/media/images/1/3f5a5585-65fe-419f-8610-f726aba74317/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3f5a5585-65fe-419f-8610-f726aba74317/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3f5a5585-65fe-419f-8610-f726aba74317/256x192.jpg",
        "viewOrder": 12
      }
    ],
    "geographicArea": {
      "geographicAreaId": 415,
      "latitude": 22.97736,
      "longitude": -109.76867300000004,
      "parentGeographicAreaId": 0,
      "title": "Carretera Transpeninsular Km 19.5, Cabo Real, 23400 San Jose del Cabo, B.C.S., Mexico",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 57,
        "tagName": "Romantic",
        "tagType": 1
      },
      {
        "tagId": 157,
        "tagName": "Beach Escape",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 81,
        "tagName": "Golf",
        "tagType": 2
      },
      {
        "tagId": 88,
        "tagName": "Tennis",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 181,
        "tagName": "Private/Suite Pools",
        "tagType": 2
      },
      {
        "tagId": 219,
        "tagName": "Wine Cellar",
        "tagType": 2
      },
      {
        "tagId": 235,
        "tagName": "Hot Tub",
        "tagType": 2
      },
      {
        "tagId": 270,
        "tagName": "Beach Club",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 109,
        "venueCategoryId": 10,
        "venueId": 575,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 10,
          "title": "Infinity Pools",
          "region": "Global",
          "viewOrder": 2,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 619,
        "venueCategoryId": 32,
        "venueId": 575,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 573,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Rosewood Mayakoba",
    "description": "Surrounded by emerald jungle and pristine white beaches, Rosewood Mayakoba is a Riviera Maya resort that defines a private world of rare luxury and refined beauty.\n\nWith 130 exclusive suites built along serene lagoons and the Caribbean Sea, Rosewood Mayakoba is one of the best Riviera Maya resorts for comfort, luxury and tranquility. Each suite is crafted from indigenous materials such as limestone, wood, and water to enhance harmony with natural surroundings. Guests discover open spaces, contemporary furnishings and sliding-glass doors with wooden lattices for fresh air in their private retreats. Accommodations at this luxurious Riviera Maya resort are complemented with rooftop sundecks, over-sized terraces, spacious bathrooms (some with outdoor garden showers), plunge pools and boat docks.",
    "brand": "Rosewood",
    "blockQuote": "Riviera Maya is the perfect destination for art lovers and culture buffs alike.",
    "emailAddress": "mayakoba@rosewoodhotels.com",
    "website": "rosewoodhotels.com/en/mayakoba-riviera-maya",
    "bookingURL": "https://reservations.rosewoodhotels.com/?adult=1&agencyid=96027890&arrive=2021-06-08&chain=5159&child=0&config=gtm&currency=USD&depart=2021-06-09&filter=&hotel=18150&level=hotel&locale=en-US&rooms=1",
    "redirectNotes": "Rosewood Mayakoba's reservations system",
    "phoneNumber": "+52 984 875 8000",
    "addressOne": "",
    "addressTwo": "",
    "city": "Playa del Carmen",
    "county": "",
    "region": "Riviera Maya",
    "country": "Mexico",
    "rooms": 130,
    "dollar": 3,
    "updated": "2023-08-30T09:17:47.017",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 4544,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/5e539657-1429-42c5-8f2c-d87bbdac9c8f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5e539657-1429-42c5-8f2c-d87bbdac9c8f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5e539657-1429-42c5-8f2c-d87bbdac9c8f/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 36083,
        "title": "ROSEWOOD MAYAKOBA - Updated 2020 Prices & Resort Reviews (Riviera ...",
        "url": "https://pkslist.azureedge.net/media/images/1/00789ae3-dfab-476c-ac9f-783575339240/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/00789ae3-dfab-476c-ac9f-783575339240/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/00789ae3-dfab-476c-ac9f-783575339240/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 4547,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d2a5cf65-ca82-4b0f-8faf-a2d1325d5268/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d2a5cf65-ca82-4b0f-8faf-a2d1325d5268/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d2a5cf65-ca82-4b0f-8faf-a2d1325d5268/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 36084,
        "title": "Rosewood Mayakoba | Traveller Made Hotel Partner",
        "url": "https://pkslist.azureedge.net/media/images/1/72723400-9fd0-4a2a-a46f-fbaed99d96f9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/72723400-9fd0-4a2a-a46f-fbaed99d96f9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/72723400-9fd0-4a2a-a46f-fbaed99d96f9/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 36085,
        "title": "Rosewood Mayakoba Shows Off Fab New Look - Recommend",
        "url": "https://pkslist.azureedge.net/media/images/1/dbf9c3e7-7cd9-446c-a17e-b6d3468b180a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/dbf9c3e7-7cd9-446c-a17e-b6d3468b180a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/dbf9c3e7-7cd9-446c-a17e-b6d3468b180a/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 36088,
        "title": "Rosewood Mayakoba | IAB Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/edb4e273-7023-4fe4-9fae-acafb067fafe/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/edb4e273-7023-4fe4-9fae-acafb067fafe/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/edb4e273-7023-4fe4-9fae-acafb067fafe/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 4549,
        "title": "Rosewood Mayakoba (Playa del Carmen, MEX) | Expedia",
        "url": "https://pkslist.azureedge.net/media/images/1/64b6fe95-10d0-4b38-af67-9329026db1cd/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/64b6fe95-10d0-4b38-af67-9329026db1cd/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/64b6fe95-10d0-4b38-af67-9329026db1cd/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 36086,
        "title": "Rosewood Mayakoba, Riviera Maya, Mexico | Elite Traveler",
        "url": "https://pkslist.azureedge.net/media/images/1/f3bb7961-4634-4f67-bbef-7c5d5b788bb9/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f3bb7961-4634-4f67-bbef-7c5d5b788bb9/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f3bb7961-4634-4f67-bbef-7c5d5b788bb9/256x192.png",
        "viewOrder": 7
      },
      {
        "mediaId": 4550,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/6d1445c0-2487-443b-881d-f49481873113/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6d1445c0-2487-443b-881d-f49481873113/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6d1445c0-2487-443b-881d-f49481873113/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 413,
      "latitude": 20.692011,
      "longitude": -87.02879819999998,
      "parentGeographicAreaId": 0,
      "title": "Carretera Federal Cancún-Playa del Carmen Km. 298, 77710 Q.R., Mexico",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      },
      {
        "tagId": 157,
        "tagName": "Beach Escape",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 60,
        "tagName": "Gastronomic",
        "tagType": 2
      },
      {
        "tagId": 73,
        "tagName": "Outdoor Terraces",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 81,
        "tagName": "Golf",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 100,
        "tagName": "Wedding",
        "tagType": 2
      },
      {
        "tagId": 164,
        "tagName": "Excursions",
        "tagType": 2
      },
      {
        "tagId": 167,
        "tagName": "Nanny/Babysitting Service",
        "tagType": 2
      },
      {
        "tagId": 169,
        "tagName": "Resort",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 513,
        "venueCategoryId": 27,
        "venueId": 573,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 27,
          "title": "Family Fun",
          "region": "Global",
          "viewOrder": 16,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 617,
        "venueCategoryId": 32,
        "venueId": 573,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 560,
    "venueCategoryId": 10,
    "status": 1,
    "title": "Six Senses Yao Noi",
    "description": "Six Senses Yao Noi sets the highest benchmark for island resort living in Asia. The iconic limestone pinnacles rising from the waters of Phang Nga Bay create a surreal backdrop and a sense of privacy and adventure. Pool Villa accommodations provide generous personal space, a variety of views and present an uncompromised lifestyle.\n\nNatural materials used in the modern architecture, together with attention to detail and focus on the unique destination reinforce the Six Senses philosophy of sustainability and creating exceptional experiences.",
    "brand": "Six Senses",
    "blockQuote": "Peaceful living environment with a focus on personal space and privacy.",
    "emailAddress": "reservations-yaonoi@sixsenses.com",
    "website": "https://www.sixsenses.com/en/resorts/yao-noi",
    "bookingURL": "https://www.sixsenses.com/en/resorts/yao-noi/accomodation-results?newSearch=1&src=&Hotel=HDYYN&Rooms=1&Adult=2&Child=0&_PMID=96027890",
    "redirectNotes": "Six Senses Yao Noi's reservations system",
    "phoneNumber": "+66 7641 8500",
    "addressOne": "",
    "addressTwo": "",
    "city": "Koh Yao Noi",
    "county": "",
    "region": "Phuket",
    "country": "Thailand",
    "rooms": 56,
    "dollar": 2,
    "updated": "2020-10-12T17:26:40.47",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 17870,
        "title": "Six Senses Yao Noi « Luxury Hotels TravelPlusStyle",
        "url": "https://pkslist.azureedge.net/media/images/1/6d11dcdd-b82e-4ed8-947f-e91b6c6cd085/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6d11dcdd-b82e-4ed8-947f-e91b6c6cd085/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6d11dcdd-b82e-4ed8-947f-e91b6c6cd085/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 17872,
        "title": "Six Senses Yao Noi: 2018 Room Prices, Deals & Reviews | Expedia",
        "url": "https://pkslist.azureedge.net/media/images/1/bb6aabaa-05ba-44ff-b8f3-d3fb6d42ef2a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bb6aabaa-05ba-44ff-b8f3-d3fb6d42ef2a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bb6aabaa-05ba-44ff-b8f3-d3fb6d42ef2a/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 38157,
        "title": "Six Senses Yao Noi, Thailand • Luxury Hotel Review by TravelPlusStyle",
        "url": "https://pkslist.azureedge.net/media/images/1/9c996136-c56b-4ad1-ba6d-800d74e0b81b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9c996136-c56b-4ad1-ba6d-800d74e0b81b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9c996136-c56b-4ad1-ba6d-800d74e0b81b/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 4371,
        "title": "Thailand Pool Villa, Yao Noi Luxury Villas & Suites - Six Senses",
        "url": "https://pkslist.azureedge.net/media/images/1/36d97351-51a6-4226-9fa3-a74fa031b024/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/36d97351-51a6-4226-9fa3-a74fa031b024/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/36d97351-51a6-4226-9fa3-a74fa031b024/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 17871,
        "title": "Six Senses Yao Noi in Phuket - Asia Green Resorts - Asia Tour ...",
        "url": "https://pkslist.azureedge.net/media/images/1/37863300-4eb8-4171-9c7a-d0f54deadb06/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/37863300-4eb8-4171-9c7a-d0f54deadb06/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/37863300-4eb8-4171-9c7a-d0f54deadb06/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 38155,
        "title": "Meetings and events at Six Senses Hideaway Yao Noi, Ko Tao, TH",
        "url": "https://pkslist.azureedge.net/media/images/1/55671cef-7fbe-47a8-90d2-ce85576ede03/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/55671cef-7fbe-47a8-90d2-ce85576ede03/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/55671cef-7fbe-47a8-90d2-ce85576ede03/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 38163,
        "title": "The Retreat at Six Senses Yao Noi – Robb Report",
        "url": "https://pkslist.azureedge.net/media/images/1/3a029d72-f304-4d5c-8b2f-380598c1a054/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3a029d72-f304-4d5c-8b2f-380598c1a054/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3a029d72-f304-4d5c-8b2f-380598c1a054/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 38164,
        "title": "Sis Senses Yao Noi | Vexplore Tours",
        "url": "https://pkslist.azureedge.net/media/images/1/b444389c-ee9f-40e9-bb7d-9602b26e16b6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b444389c-ee9f-40e9-bb7d-9602b26e16b6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b444389c-ee9f-40e9-bb7d-9602b26e16b6/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 38166,
        "title": "Six Senses Yao Noi, Thailand • Luxury Hotel Review by TravelPlusStyle",
        "url": "https://pkslist.azureedge.net/media/images/1/555037e2-657f-49f2-9a8c-272a4800abff/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/555037e2-657f-49f2-9a8c-272a4800abff/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/555037e2-657f-49f2-9a8c-272a4800abff/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 38167,
        "title": "Six Senses Yao Noi",
        "url": "https://pkslist.azureedge.net/media/images/1/be0cad4f-240f-4a62-9db7-65474221d30e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/be0cad4f-240f-4a62-9db7-65474221d30e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/be0cad4f-240f-4a62-9db7-65474221d30e/256x192.jpg",
        "viewOrder": 11
      },
      {
        "mediaId": 38160,
        "title": "Six Senses Yao Noi: A Thai resort with a conscience | CNN Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/4f1c687a-bc5d-4701-a7f2-5a0bd17c1b42/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4f1c687a-bc5d-4701-a7f2-5a0bd17c1b42/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4f1c687a-bc5d-4701-a7f2-5a0bd17c1b42/256x192.jpg",
        "viewOrder": 12
      },
      {
        "mediaId": 17799,
        "title": "Six Senses Yao Noi | Exklusive Luxushotels in Thailand mit BAWA ...",
        "url": "https://pkslist.azureedge.net/media/images/1/5d37ba4a-e7a7-4607-932b-e29220f4da8d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5d37ba4a-e7a7-4607-932b-e29220f4da8d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5d37ba4a-e7a7-4607-932b-e29220f4da8d/256x192.jpg",
        "viewOrder": 14
      }
    ],
    "geographicArea": {
      "geographicAreaId": 398,
      "latitude": 8.119752,
      "longitude": 98.62516099999993,
      "parentGeographicAreaId": 0,
      "title": "56 Moo 5, Koh Yao Noi, อำเภอ เกาะยาว พังงา 82160, Thailand",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 50,
        "tagName": "Barefoot Luxury",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 77,
        "tagName": "Cooking Classes",
        "tagType": 2
      },
      {
        "tagId": 83,
        "tagName": "Kid's Club",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 101,
        "tagName": "Cinema",
        "tagType": 2
      },
      {
        "tagId": 116,
        "tagName": "Scuba Diving",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 181,
        "tagName": "Private/Suite Pools",
        "tagType": 2
      },
      {
        "tagId": 183,
        "tagName": "Butler Service",
        "tagType": 2
      },
      {
        "tagId": 193,
        "tagName": "Outdoor Dining",
        "tagType": 2
      },
      {
        "tagId": 198,
        "tagName": "Boat Excursion",
        "tagType": 2
      },
      {
        "tagId": 206,
        "tagName": "Private Beach",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 106,
        "venueCategoryId": 10,
        "venueId": 560,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 10,
          "title": "Infinity Pools",
          "region": "Global",
          "viewOrder": 2,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 609,
        "venueCategoryId": 32,
        "venueId": 560,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 661,
        "venueCategoryId": 33,
        "venueId": 560,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 33,
          "title": "Rustic Elegance",
          "region": "Global",
          "viewOrder": 11,
          "updated": "2020-09-04T09:04:00",
          "created": "2020-09-04T09:04:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 557,
    "venueCategoryId": 19,
    "status": 1,
    "title": "Six Senses Zil Pasyon",
    "description": "Six Senses Zil Pasyon is located on Félicité, a private island where massive granite rocks and white sand beaches create an air of drama and beauty. With perpetual summer, the island is ideally located outside the cyclone belt just 30 nautical miles northeast of the Seychelles International Airport.\nCreated with the renowned Six Senses flair, the resort in Seychelles is a very personal destination that embraces nature while offering every creature comfort. Its 30 pool villas offer an oasis of serenity encircled by the Indian Ocean.",
    "brand": "Six Senses",
    "blockQuote": "The spa is exceptional with an infinity pool that looks out to infinity and beyond so make sure you book your appointment.",
    "emailAddress": "reservations-zilpasyon@sixsenses.com",
    "website": "sixsenses.com/resorts/zilpasyon",
    "bookingURL": "https://www.sixsenses.com/en/resorts/zil-pasyon/accomodation-results?newSearch=1&src=&Hotel=69636&Rooms=1&Adult=2&Child=0&_PMID=96027890",
    "redirectNotes": "Six Senses Zil Pasyon's reservations system",
    "phoneNumber": "+248 467 1000",
    "addressOne": "",
    "addressTwo": "",
    "city": "Felicite",
    "county": "",
    "region": "",
    "country": "Seychelles",
    "rooms": 30,
    "dollar": 3,
    "updated": "2020-10-12T17:24:25.243",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 7388,
        "title": "Spa Opportunities: - Six Senses Zil Pasyon Spa to be spread over ...",
        "url": "https://pkslist.azureedge.net/media/images/1/b464ee63-3857-46f5-8150-ea1e225dad35/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b464ee63-3857-46f5-8150-ea1e225dad35/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b464ee63-3857-46f5-8150-ea1e225dad35/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 7384,
        "title": "Seychelles Beach Resort, Luxury Resort in Seychelles - Six Senses",
        "url": "https://pkslist.azureedge.net/media/images/1/b849ee3e-4055-4c13-bfef-3830f9f6e59a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b849ee3e-4055-4c13-bfef-3830f9f6e59a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b849ee3e-4055-4c13-bfef-3830f9f6e59a/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 7389,
        "title": "Six Senses Zil Pasyon « Luxury Hotels TravelPlusStyle",
        "url": "https://pkslist.azureedge.net/media/images/1/e3f938e7-8f98-400f-84d3-41c77b5823a3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e3f938e7-8f98-400f-84d3-41c77b5823a3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e3f938e7-8f98-400f-84d3-41c77b5823a3/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 4322,
        "title": "Seychelles Beach Resort, Luxury Resort in Seychelles - Six Senses",
        "url": "https://pkslist.azureedge.net/media/images/1/a8a8b81f-8e84-4228-bbe6-8f4f1b2c88e6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a8a8b81f-8e84-4228-bbe6-8f4f1b2c88e6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a8a8b81f-8e84-4228-bbe6-8f4f1b2c88e6/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 4323,
        "title": "Six Senses Zil Pasyon opens in the Seychelles - CPP-LUXURY",
        "url": "https://pkslist.azureedge.net/media/images/1/015244b2-8863-4c03-8c2f-febb58f4fce6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/015244b2-8863-4c03-8c2f-febb58f4fce6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/015244b2-8863-4c03-8c2f-febb58f4fce6/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 4326,
        "title": "Seychelles Accommodation, Seychelles Villas - Six Senses",
        "url": "https://pkslist.azureedge.net/media/images/1/96a04bc8-4d2f-43e1-a8ca-93c7144f3732/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/96a04bc8-4d2f-43e1-a8ca-93c7144f3732/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/96a04bc8-4d2f-43e1-a8ca-93c7144f3732/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 7385,
        "title": "Zil Pasyon Residences Seychelles",
        "url": "https://pkslist.azureedge.net/media/images/1/1f7a42d9-918b-4d38-948b-f4ff0842f29e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1f7a42d9-918b-4d38-948b-f4ff0842f29e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1f7a42d9-918b-4d38-948b-f4ff0842f29e/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 4320,
        "title": "Six Senses Zil Pasyon opens in the Seychelles - CPP-LUXURY",
        "url": "https://pkslist.azureedge.net/media/images/1/c979f437-1b3c-4321-b12a-42dfafe7c993/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c979f437-1b3c-4321-b12a-42dfafe7c993/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c979f437-1b3c-4321-b12a-42dfafe7c993/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 4327,
        "title": "Six Senses Zil Pasyon - New Luxury Beach Resort To Open In The ...",
        "url": "https://pkslist.azureedge.net/media/images/1/b57c3a70-05bd-4ba0-866b-5d1dbaf2e314/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b57c3a70-05bd-4ba0-866b-5d1dbaf2e314/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b57c3a70-05bd-4ba0-866b-5d1dbaf2e314/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 394,
      "latitude": -4.317484700000001,
      "longitude": 55.868409199999974,
      "parentGeographicAreaId": 0,
      "title": "Unnamed Road, Seychelles",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 50,
        "tagName": "Barefoot Luxury",
        "tagType": 1
      },
      {
        "tagId": 157,
        "tagName": "Beach Escape",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 109,
        "tagName": "Fishing",
        "tagType": 2
      },
      {
        "tagId": 116,
        "tagName": "Scuba Diving",
        "tagType": 2
      },
      {
        "tagId": 198,
        "tagName": "Boat Excursion",
        "tagType": 2
      },
      {
        "tagId": 205,
        "tagName": "Design",
        "tagType": 2
      },
      {
        "tagId": 206,
        "tagName": "Private Beach",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 105,
        "venueCategoryId": 19,
        "venueId": 557,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 19,
          "title": "Private Islands",
          "region": "Global",
          "viewOrder": 15,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 611,
        "venueCategoryId": 32,
        "venueId": 557,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 556,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Six Senses Douru Valley",
    "description": "Located in the beautiful UNESCO World Heritage-listed Douro Valley, Six Senses Douro Valley is touched with the romance of 19th century architecture wedded to contemporary interiors reflecting the inimitable Six Senses style. With the River Douro winding its lazy way through the oldest wine-producing region in the world,this serene setting is 88 miles (142 kilometers) from Oporto International Airport.The resort’s estate covers 19 acres (8 hectares) with 57 guest accommodations, the Vale de Abraao restaurant with three different atmospheres: Open Kitchen, Terrace and Dining Room; a Wine Library featuring vintages from the valley and a stunning Six Senses Spa occupying 23,700 square feet (2,200 square meters).",
    "brand": "Six Senses",
    "blockQuote": "The wine and dine Douro River cruise is a wonderful way to discover some of the fascinating wines of the region.",
    "emailAddress": "reservations-dourovalley@sixsenses.com",
    "website": "sixsenses.com/resorts/douro-valley",
    "bookingURL": "https://www.sixsenses.com/en/resorts/douro-valley/accomodation-results?newSearch=1&src=&Hotel=64540&Rooms=1&Adult=2&Child=0&_PMID=96027890",
    "redirectNotes": "Six Senses Douru Valley's reservations system",
    "phoneNumber": "+351 254 660 600",
    "addressOne": "",
    "addressTwo": "Quinta Vale de Abrão",
    "city": "Lamego",
    "county": "",
    "region": "",
    "country": "Portugal",
    "rooms": 57,
    "dollar": 2,
    "updated": "2020-10-12T17:22:59.177",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 37924,
        "title": "Hotel Six Senses Douro Valley, Lamego, Portugal - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/a0060bc0-c6a5-45d5-927a-6d0523bbcfab/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a0060bc0-c6a5-45d5-927a-6d0523bbcfab/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a0060bc0-c6a5-45d5-927a-6d0523bbcfab/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 37925,
        "title": "Six Senses Douro Valley to add guestrooms, suites and event space ...",
        "url": "https://pkslist.azureedge.net/media/images/1/5a6650f0-5703-44b0-9e0c-2593063a56b1/1200x900.0N83LMlg6pat5QeZi",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5a6650f0-5703-44b0-9e0c-2593063a56b1/800x600.0N83LMlg6pat5QeZi",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5a6650f0-5703-44b0-9e0c-2593063a56b1/256x192.0N83LMlg6pat5QeZi",
        "viewOrder": 2
      },
      {
        "mediaId": 37927,
        "title": "Six Senses Douro Valley",
        "url": "https://pkslist.azureedge.net/media/images/1/8dc950aa-16cc-4fd8-bd60-a7f756d679f0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8dc950aa-16cc-4fd8-bd60-a7f756d679f0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8dc950aa-16cc-4fd8-bd60-a7f756d679f0/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 37933,
        "title": "Hotel Six Senses Douro Valley, Lamego, Portugal - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/616a1d15-db7e-45dc-a3e7-bfc76c666d47/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/616a1d15-db7e-45dc-a3e7-bfc76c666d47/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/616a1d15-db7e-45dc-a3e7-bfc76c666d47/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 37928,
        "title": "Best Hotels in Northern Portugal",
        "url": "https://pkslist.azureedge.net/media/images/1/541395e4-7b38-4e2a-8406-92311bd350eb/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/541395e4-7b38-4e2a-8406-92311bd350eb/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/541395e4-7b38-4e2a-8406-92311bd350eb/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 37934,
        "title": "Six Senses Douro Valley Hotel (Lamego) - Deals, Photos & Reviews",
        "url": "https://pkslist.azureedge.net/media/images/1/97d4ea72-da94-4504-8c3e-ee11592e9bde/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/97d4ea72-da94-4504-8c3e-ee11592e9bde/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/97d4ea72-da94-4504-8c3e-ee11592e9bde/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 37929,
        "title": "Six Senses Douro Valley (Lamego, Portugal) | Jetsetter",
        "url": "https://pkslist.azureedge.net/media/images/1/c74660a1-f4d9-4f66-adf4-beed9581b250/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c74660a1-f4d9-4f66-adf4-beed9581b250/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c74660a1-f4d9-4f66-adf4-beed9581b250/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 9887,
        "title": "Hotel Six Senses Douro Valley, Lamego, Portugal - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/2e73ae45-196f-4d80-bf10-e4b285be75d9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2e73ae45-196f-4d80-bf10-e4b285be75d9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2e73ae45-196f-4d80-bf10-e4b285be75d9/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 37926,
        "title": "Rebelo de Andrade · Six Senses - Douro Valley Hotel · Divisare",
        "url": "https://pkslist.azureedge.net/media/images/1/7e15b1d5-2880-4915-a65b-e4ee9e583b93/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7e15b1d5-2880-4915-a65b-e4ee9e583b93/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7e15b1d5-2880-4915-a65b-e4ee9e583b93/256x192.jpg",
        "viewOrder": 11
      },
      {
        "mediaId": 4312,
        "title": "Wine and wellness at the Six Senses Douro Valley, Portugal ...",
        "url": "https://pkslist.azureedge.net/media/images/1/edeae34a-80d8-4ca7-91a0-0856e52671c7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/edeae34a-80d8-4ca7-91a0-0856e52671c7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/edeae34a-80d8-4ca7-91a0-0856e52671c7/256x192.jpg",
        "viewOrder": 13
      }
    ],
    "geographicArea": {
      "geographicAreaId": 393,
      "latitude": 41.144198,
      "longitude": -7.812249999999949,
      "parentGeographicAreaId": 0,
      "title": "Quinta Vale de Abrão, 5100-758 Samodães,Lamego, Portugal",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 45,
        "tagName": "Historic Sense",
        "tagType": 1
      },
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 31,
        "tagName": "Countryside",
        "tagType": 2
      },
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 73,
        "tagName": "Outdoor Terraces",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 108,
        "tagName": "Jacuzzi",
        "tagType": 2
      },
      {
        "tagId": 131,
        "tagName": "Library",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 610,
        "venueCategoryId": 32,
        "venueId": 556,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 555,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Six Senses Zighy Bay",
    "description": "An oasis built of natural stone and warm woods, your Omani enclave invites unwinding in the cool indoors under high indigenous-style ceilings, in the colorfully cushioned living area and on your cloud-like bed made with unbleached cotton sheets topped with your choice of ergonomic pillows. Refresh outside within the privacy of high cobblestone walls, on your plumped up sun loungers, shaded living and dining areas that surround the swimming pool, and the traditional Omani summer house.",
    "brand": "Six Senses",
    "blockQuote": "Catch a beautiful sunrise on the beach, it's worth the early wake up call.",
    "emailAddress": "reservations-zighy@sixsenses.com",
    "website": "sixsenses.com/resorts/zighy-bay",
    "bookingURL": "https://www.sixsenses.com/en/resorts/zighy-bay/accomodation-results?newSearch=1&src=&Hotel=61669&Rooms=1&Adult=2&Child=0&_PMID=96027890",
    "redirectNotes": "Six Senses Zighy Bay's reservations system",
    "phoneNumber": "+968 2673 5555",
    "addressOne": "",
    "addressTwo": "",
    "city": "Dibba Musandam",
    "county": "",
    "region": "",
    "country": "Oman",
    "rooms": 82,
    "dollar": 3,
    "updated": "2020-10-12T17:22:04.88",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 4301,
        "title": "Oman Luxury Resorts, Zighy Bay Luxury Villas & Suites - Six Senses",
        "url": "https://pkslist.azureedge.net/media/images/1/f535064f-ba0b-4eb8-9f9b-55c4722a8891/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f535064f-ba0b-4eb8-9f9b-55c4722a8891/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f535064f-ba0b-4eb8-9f9b-55c4722a8891/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 4303,
        "title": "Six Senses Zighy Bay « Luxury Hotels TravelPlusStyle",
        "url": "https://pkslist.azureedge.net/media/images/1/0e619577-e344-419e-8d9f-3c538182b8af/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0e619577-e344-419e-8d9f-3c538182b8af/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0e619577-e344-419e-8d9f-3c538182b8af/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 4306,
        "title": "Six Senses Zighy Bay, Muscat - AsanteHolidays.com",
        "url": "https://pkslist.azureedge.net/media/images/1/aacb0465-99b9-4d09-93c3-e9f768e4c452/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/aacb0465-99b9-4d09-93c3-e9f768e4c452/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/aacb0465-99b9-4d09-93c3-e9f768e4c452/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 4307,
        "title": "Oman Holistic Spa - Six Senses at Zighy Bay",
        "url": "https://pkslist.azureedge.net/media/images/1/32c66d7e-8c90-48a6-8dbd-bdc3fe77627d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/32c66d7e-8c90-48a6-8dbd-bdc3fe77627d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/32c66d7e-8c90-48a6-8dbd-bdc3fe77627d/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 4305,
        "title": "Six Senses Zighy Bay (Zighy Bay, OMN) | Expedia",
        "url": "https://pkslist.azureedge.net/media/images/1/16e4beed-e406-43d6-b3f4-4d15f74ba54a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/16e4beed-e406-43d6-b3f4-4d15f74ba54a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/16e4beed-e406-43d6-b3f4-4d15f74ba54a/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 4304,
        "title": "Six Senses Zighy Bay « Luxury Hotels TravelPlusStyle",
        "url": "https://pkslist.azureedge.net/media/images/1/a3690405-42d6-4f69-be9b-ee5267028094/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a3690405-42d6-4f69-be9b-ee5267028094/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a3690405-42d6-4f69-be9b-ee5267028094/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 4302,
        "title": "Oman Villas, Suites in Oman - Six Senses Zighy Bay",
        "url": "https://pkslist.azureedge.net/media/images/1/c1625b93-18f5-493f-b6d4-4b29f9b54dad/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c1625b93-18f5-493f-b6d4-4b29f9b54dad/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c1625b93-18f5-493f-b6d4-4b29f9b54dad/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 4308,
        "title": "Six Senses Zighy Bay Oman - Compass + Twine",
        "url": "https://pkslist.azureedge.net/media/images/1/fad85264-2bf4-4254-8fb0-470f332d1414/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fad85264-2bf4-4254-8fb0-470f332d1414/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fad85264-2bf4-4254-8fb0-470f332d1414/256x192.jpg",
        "viewOrder": 7
      }
    ],
    "geographicArea": {
      "geographicAreaId": 392,
      "latitude": 25.708671,
      "longitude": 56.273173999999926,
      "parentGeographicAreaId": 0,
      "title": "Musandam Peninsula, PO Box 212, Dibba-Musandam 800, Oman",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 47,
        "tagName": "Intimate Luxury ",
        "tagType": 1
      },
      {
        "tagId": 50,
        "tagName": "Barefoot Luxury",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 58,
        "tagName": "Beach",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 73,
        "tagName": "Outdoor Terraces",
        "tagType": 2
      },
      {
        "tagId": 77,
        "tagName": "Cooking Classes",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 83,
        "tagName": "Kid's Club",
        "tagType": 2
      },
      {
        "tagId": 88,
        "tagName": "Tennis",
        "tagType": 2
      },
      {
        "tagId": 108,
        "tagName": "Jacuzzi",
        "tagType": 2
      },
      {
        "tagId": 172,
        "tagName": "Biking",
        "tagType": 2
      },
      {
        "tagId": 181,
        "tagName": "Private/Suite Pools",
        "tagType": 2
      },
      {
        "tagId": 183,
        "tagName": "Butler Service",
        "tagType": 2
      },
      {
        "tagId": 212,
        "tagName": "Hammam",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 618,
        "venueCategoryId": 32,
        "venueId": 555,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 545,
    "venueCategoryId": 20,
    "status": 1,
    "title": "Amangiri",
    "description": "Amangiri is a remote hideaway tucked within the luminous canyons of the American Southwest. Located in a protected valley with sweeping views, the resort offers both adrenaline-fuelled adventure and a peaceful retreat. Framing the surrounding dunes, plateaus and mountain ridges, the Suites and the Home embrace the area’s raw aesthetic.",
    "brand": "Amanresorts",
    "blockQuote": "A stunningly unique resort in the most spectacular setting imaginable.",
    "emailAddress": "amangiri@aman.com",
    "website": "aman.com/resorts/amangiri",
    "bookingURL": "https://gc.synxis.com/rez.aspx?Chain=16840&Hotel=62482&Lang=1&template=RBE3&shell=RBE2&iata=96027890",
    "redirectNotes": "Amangiri's reservations system",
    "phoneNumber": "+1 435 675 3999",
    "addressOne": "1",
    "addressTwo": "Kayenta Road",
    "city": "Canyon Point",
    "county": "",
    "region": "Utah",
    "country": "United States",
    "rooms": 34,
    "dollar": 3,
    "updated": "2020-05-13T09:27:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 4414,
        "title": "Amangiri - Luxury Hotel & Resort in Utah, USA - Aman",
        "url": "https://pkslist.azureedge.net/media/images/1/497c2f0f-d983-4897-b7f2-85d9a3cbbaa0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/497c2f0f-d983-4897-b7f2-85d9a3cbbaa0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/497c2f0f-d983-4897-b7f2-85d9a3cbbaa0/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 4198,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c0d1244f-d8e6-4626-bacb-1c70a1b80d0d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c0d1244f-d8e6-4626-bacb-1c70a1b80d0d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c0d1244f-d8e6-4626-bacb-1c70a1b80d0d/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 4196,
        "title": "Amangiri Resort – Dramatic Escape In Utah | iDesignArch | Interior ...",
        "url": "https://pkslist.azureedge.net/media/images/1/8295cf74-ee66-4d6d-92ea-323776b28a31/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8295cf74-ee66-4d6d-92ea-323776b28a31/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8295cf74-ee66-4d6d-92ea-323776b28a31/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 4188,
        "title": "Amangiri Luxury Resort is an enclave of luxury and style in Utah.",
        "url": "https://pkslist.azureedge.net/media/images/1/87160cc9-fa34-4fee-bc28-27ffbade4eac/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/87160cc9-fa34-4fee-bc28-27ffbade4eac/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/87160cc9-fa34-4fee-bc28-27ffbade4eac/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 4193,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/2678a631-bd4e-4dac-a39c-f70d6c88b4e0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2678a631-bd4e-4dac-a39c-f70d6c88b4e0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2678a631-bd4e-4dac-a39c-f70d6c88b4e0/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 4185,
        "title": "Suites, Amangiri - Luxury Accommodation in Utah - Aman",
        "url": "https://pkslist.azureedge.net/media/images/1/c2b8fc52-b548-41d2-b5b3-a2040f874d84/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c2b8fc52-b548-41d2-b5b3-a2040f874d84/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c2b8fc52-b548-41d2-b5b3-a2040f874d84/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 4202,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a1a71c93-65b5-43d0-a365-f011b2e3a0a2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a1a71c93-65b5-43d0-a365-f011b2e3a0a2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a1a71c93-65b5-43d0-a365-f011b2e3a0a2/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 4201,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/1d8a5c9b-b1f4-4abb-9549-b87d553ee60b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1d8a5c9b-b1f4-4abb-9549-b87d553ee60b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1d8a5c9b-b1f4-4abb-9549-b87d553ee60b/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 4187,
        "title": "Suites, Amangiri - Luxury Accommodation in Utah - Aman",
        "url": "https://pkslist.azureedge.net/media/images/1/8328343f-ed43-4d87-8dae-7eb3f222808e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8328343f-ed43-4d87-8dae-7eb3f222808e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8328343f-ed43-4d87-8dae-7eb3f222808e/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 4195,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/25d435c1-d12c-4983-981e-c4d9df2bebce/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/25d435c1-d12c-4983-981e-c4d9df2bebce/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/25d435c1-d12c-4983-981e-c4d9df2bebce/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 4192,
        "title": "Amangiri - UPDATED 2017 Resort Reviews & Price Comparison (Utah ...",
        "url": "https://pkslist.azureedge.net/media/images/1/0bfac041-94b8-4973-bf4e-0a1e01a5ca79/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0bfac041-94b8-4973-bf4e-0a1e01a5ca79/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0bfac041-94b8-4973-bf4e-0a1e01a5ca79/256x192.jpg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 382,
      "latitude": 37.0145985,
      "longitude": -111.61129829999999,
      "parentGeographicAreaId": 0,
      "title": "1 Kayenta Rd, Canyon Point, UT 84741, USA",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 53,
        "tagName": "Cutting Edge",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 59,
        "tagName": "Cultural",
        "tagType": 2
      },
      {
        "tagId": 60,
        "tagName": "Gastronomic",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 82,
        "tagName": "Horseback Riding",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 155,
        "tagName": "Art",
        "tagType": 2
      },
      {
        "tagId": 170,
        "tagName": "Kayaking",
        "tagType": 2
      },
      {
        "tagId": 265,
        "tagName": "Hot Air Balloon",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 101,
        "venueCategoryId": 20,
        "venueId": 545,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 20,
          "title": "BucketList Hotels",
          "region": "Global",
          "viewOrder": 0,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 345,
        "venueCategoryId": 5,
        "venueId": 545,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 5,
          "title": "Romantic Getaways",
          "region": "Global",
          "viewOrder": 20,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 608,
        "venueCategoryId": 32,
        "venueId": 545,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 541,
    "venueCategoryId": 15,
    "status": 1,
    "title": "Amanpuri",
    "description": "This timeless retreat on the west coast of Phuket is in harmony with its idyllic setting. Ancient Ayutthayan architecture informs the design of its pavilions and villas, which overlook lush vegetation and the pristine Andaman Sea. Interspersed throughout a mature coconut plantation, the Thai-style Pavilions and Villas open onto outdoor sun decks and private dining terraces.",
    "brand": "Amanresorts",
    "blockQuote": "Located on a strip of pristine beach, Amanpuri has the best beach in Phuket.",
    "emailAddress": "amanpuri@aman.com",
    "website": "aman.com/resorts/amanpuri",
    "bookingURL": "https://gc.synxis.com/rez.aspx?Chain=16840&Hotel=62467&Lang=1&template=RBE3&shell=RBE2&iata=96027890",
    "redirectNotes": "Amanpuri's reservations system",
    "phoneNumber": "+66 76 324 333",
    "addressOne": "",
    "addressTwo": "",
    "city": "Phuket",
    "county": "",
    "region": "",
    "country": "Thailand",
    "rooms": 81,
    "dollar": 3,
    "updated": "2020-05-29T13:36:08.033",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 4370,
        "title": "Amanpuri - Luxury Resort in Phuket, Thailand - Aman",
        "url": "https://pkslist.azureedge.net/media/images/1/6265c501-afd5-4692-93af-e75f22485847/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6265c501-afd5-4692-93af-e75f22485847/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6265c501-afd5-4692-93af-e75f22485847/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 4146,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/dac03b3a-df31-4e9d-91e1-a7aa963fa608/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/dac03b3a-df31-4e9d-91e1-a7aa963fa608/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/dac03b3a-df31-4e9d-91e1-a7aa963fa608/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 4144,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d51585fb-c8bc-43e9-9002-dacc528cf214/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d51585fb-c8bc-43e9-9002-dacc528cf214/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d51585fb-c8bc-43e9-9002-dacc528cf214/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 4142,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/218fb074-675a-4758-bae7-ff3f991823df/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/218fb074-675a-4758-bae7-ff3f991823df/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/218fb074-675a-4758-bae7-ff3f991823df/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 4140,
        "title": "Amanpuri - UPDATED 2017 Resort Reviews & Price Comparison (Phuket ...",
        "url": "https://pkslist.azureedge.net/media/images/1/a3f4d5f9-8a68-4ac5-ad47-05fc8f62bace/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a3f4d5f9-8a68-4ac5-ad47-05fc8f62bace/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a3f4d5f9-8a68-4ac5-ad47-05fc8f62bace/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 4141,
        "title": "Amanpuri, Phuket | Traveller Made",
        "url": "https://pkslist.azureedge.net/media/images/1/19b998f7-d1eb-4ac4-89e0-dc444c46c7cc/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/19b998f7-d1eb-4ac4-89e0-dc444c46c7cc/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/19b998f7-d1eb-4ac4-89e0-dc444c46c7cc/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 4143,
        "title": "Amanpuri - Indagare",
        "url": "https://pkslist.azureedge.net/media/images/1/5fda9511-6c2f-4fd2-b681-58064d3d5e16/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5fda9511-6c2f-4fd2-b681-58064d3d5e16/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5fda9511-6c2f-4fd2-b681-58064d3d5e16/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 4145,
        "title": "Amanpuri Resort Phuket - Hotel Exterior | Travel | Pinterest ...",
        "url": "https://pkslist.azureedge.net/media/images/1/d27de058-7254-4197-b295-48d35db0ac6b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d27de058-7254-4197-b295-48d35db0ac6b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d27de058-7254-4197-b295-48d35db0ac6b/256x192.jpg",
        "viewOrder": 7
      }
    ],
    "geographicArea": {
      "geographicAreaId": 379,
      "latitude": 7.9842032,
      "longitude": 98.27645059999998,
      "parentGeographicAreaId": 0,
      "title": "118 Moo 3 Srissunthorn, Phuket, อำเภอ ถลาง ภูเก็ต 83110, Thailand",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 157,
        "tagName": "Beach Escape",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 58,
        "tagName": "Beach",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 73,
        "tagName": "Outdoor Terraces",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 88,
        "tagName": "Tennis",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 256,
        "tagName": "Boutique",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 300,
        "tagName": "Pilates",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 98,
        "venueCategoryId": 15,
        "venueId": 541,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 15,
          "title": "Beach Paradise",
          "region": "Global",
          "viewOrder": 6,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 631,
        "venueCategoryId": 32,
        "venueId": 541,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 493,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Bulgari Resort Bali",
    "description": "The Bvlgari Resort Bali is located in one of the most exclusive destinations in the world, a veritable emblem of the tropical exoticism of the Orient, combining the breathtaking beauty of unspoiled nature of Bali beach resorts with a sophisticated contemporary design born from the encounter between traditional Balinese forms and high Italian style. The quality of the services along with the views of the Indian Ocean offered by its magnificent position 150 meters over the sea are the perfect introduction to the second luxury hotels from Bvlgari Hotels & Resorts.",
    "brand": "Bulgari",
    "blockQuote": "Be sure to book at award winning restaurant Il Restorante, creative Italian cuisine showcasing quality ingredients from Italy combined with local organic produce.",
    "emailAddress": "infobali@bulgarihotels.com",
    "website": "https://www.bulgarihotels.com/en_US/bali/the-resort/overview",
    "bookingURL": "http://booking.com/db648b08033d1b?aid=925669",
    "redirectNotes": null,
    "phoneNumber": "+62 361 847 100 0",
    "addressOne": "",
    "addressTwo": "",
    "city": "Uluwatu",
    "county": "",
    "region": "Bali",
    "country": "Indonesia",
    "rooms": 64,
    "dollar": 3,
    "updated": "2020-05-21T09:48:32.193",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 37046,
        "title": "Review: Il Ristorante Restaurant, Bali - International Traveller",
        "url": "https://pkslist.azureedge.net/media/images/1/cae5eaad-208e-4b47-a52e-90e80cee3bf2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cae5eaad-208e-4b47-a52e-90e80cee3bf2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cae5eaad-208e-4b47-a52e-90e80cee3bf2/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 37036,
        "title": "Sangkar Restaurant at Bulgari Resort Bali - Fine-Dining Restaurant ...",
        "url": "https://pkslist.azureedge.net/media/images/1/df69afd7-4040-4295-8631-844ffe32ce81/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/df69afd7-4040-4295-8631-844ffe32ce81/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/df69afd7-4040-4295-8631-844ffe32ce81/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 37033,
        "title": "Bulgari Resort Bali, Uluwatu, Indonesia - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/8b963f05-e159-41c8-9886-1d23805aa97c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8b963f05-e159-41c8-9886-1d23805aa97c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8b963f05-e159-41c8-9886-1d23805aa97c/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 37044,
        "title": "Bulgari Resort Bali, Indonesia • Luxury Hotel Review by ...",
        "url": "https://pkslist.azureedge.net/media/images/1/6c84696f-9107-42a2-bf65-a85dc1825b0c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6c84696f-9107-42a2-bf65-a85dc1825b0c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6c84696f-9107-42a2-bf65-a85dc1825b0c/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 37039,
        "title": "Hotel room The Bulgari Two Bedroom Villa , Bulgari Resort Bali ...",
        "url": "https://pkslist.azureedge.net/media/images/1/b4739999-af84-43e0-a8dd-67a40807c738/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b4739999-af84-43e0-a8dd-67a40807c738/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b4739999-af84-43e0-a8dd-67a40807c738/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 37038,
        "title": "Bulgari Resort Bali, Uluwatu, Indonesia. © Bulgari Hotels ...",
        "url": "https://pkslist.azureedge.net/media/images/1/e9a29f7e-000c-43e3-8da9-2be2ec45930a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e9a29f7e-000c-43e3-8da9-2be2ec45930a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e9a29f7e-000c-43e3-8da9-2be2ec45930a/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 37041,
        "title": "Bulgari Hotels Bali | 2018 World's Best Hotels",
        "url": "https://pkslist.azureedge.net/media/images/1/d6d12a45-2893-4244-be83-af63d4aa4519/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d6d12a45-2893-4244-be83-af63d4aa4519/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d6d12a45-2893-4244-be83-af63d4aa4519/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 37043,
        "title": "Bulgari Resort Bali, Indonesia • Luxury Hotel Review by ...",
        "url": "https://pkslist.azureedge.net/media/images/1/36766cf3-6f49-4e2d-b21d-90e51261593d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/36766cf3-6f49-4e2d-b21d-90e51261593d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/36766cf3-6f49-4e2d-b21d-90e51261593d/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 37045,
        "title": "Bulgari Resort Bali, Indonesia • Luxury Hotel Review by ...",
        "url": "https://pkslist.azureedge.net/media/images/1/578a54e2-4398-45e9-b587-a401507742aa/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/578a54e2-4398-45e9-b587-a401507742aa/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/578a54e2-4398-45e9-b587-a401507742aa/256x192.jpg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 337,
      "latitude": -8.843816,
      "longitude": 115.12069099999997,
      "parentGeographicAreaId": 0,
      "title": "Jalan Goa Lempeh, Banjar Dinas Kangin, Uluwatu, Pecatu, Kuta Sel., Kabupaten Badung, Bali 80361, Indonesia",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 57,
        "tagName": "Romantic",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 58,
        "tagName": "Beach",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 100,
        "tagName": "Wedding",
        "tagType": 2
      },
      {
        "tagId": 108,
        "tagName": "Jacuzzi",
        "tagType": 2
      },
      {
        "tagId": 116,
        "tagName": "Scuba Diving",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 616,
        "venueCategoryId": 32,
        "venueId": 493,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 490,
    "venueCategoryId": 0,
    "status": 1,
    "title": "COMO Shambhala Estate",
    "description": "COMO Shambhala Estate is located near Ubud, Bali—a true ‘Retreat for Change’ with resident experts including a yoga teacher, Ayurvedic doctor and resident dietician. The holistic, 360-degree approach offers signature massage therapies and beauty treatments. A state-of-the-art gym and outdoor activities such as hiking and climbing make the most of the Estate’s unique location. Cuisine is always nutritionally balanced while the residences, suites and villas suit independent guests, couples or families.",
    "brand": "COMO Hotels",
    "blockQuote": "Book the Principal Suite in Tirta-Ening residence for the waterfall pool and six-ton carved stone bath.",
    "emailAddress": "CSestate@comohotels.com",
    "website": "comohotels.com/comoshambhalaestate",
    "bookingURL": "https://gc.synxis.com/rez.aspx?Hotel=27732&Chain=10327&Adult=1&Child=0&Arrive=2018-09-05&Depart=2018-09-08&Start=AvailResults&lang=0&_ga=2.218834329.1223958135.1536067566-1513030496.1536067566&iata=96027890",
    "redirectNotes": "COMO Shambhala Estate's reservations system",
    "phoneNumber": "+62 361 978 888",
    "addressOne": "",
    "addressTwo": "Desa",
    "city": "Ubud",
    "county": "",
    "region": "Bali",
    "country": "Indonesia",
    "rooms": 30,
    "dollar": 2,
    "updated": "2020-05-21T12:11:56.013",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 3466,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/46db25e4-6bba-4d46-ab34-15a6522e66db/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/46db25e4-6bba-4d46-ab34-15a6522e66db/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/46db25e4-6bba-4d46-ab34-15a6522e66db/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 4578,
        "title": "COMO Shambhala Estate Bali - COMO Hotels and Resorts | Resort ...",
        "url": "https://pkslist.azureedge.net/media/images/1/f7c0ea81-18cf-4b3c-8d47-f3860a76f80e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f7c0ea81-18cf-4b3c-8d47-f3860a76f80e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f7c0ea81-18cf-4b3c-8d47-f3860a76f80e/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 3467,
        "title": "Book COMO Shambhala Estate, Payangan, Indonesia - Hotels.com",
        "url": "https://pkslist.azureedge.net/media/images/1/9e293b89-5d0d-4580-8765-b5fb7c5346c6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9e293b89-5d0d-4580-8765-b5fb7c5346c6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9e293b89-5d0d-4580-8765-b5fb7c5346c6/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 4575,
        "title": "COMO Shambhala Estate Spa | Healing Hotels of the World",
        "url": "https://pkslist.azureedge.net/media/images/1/e670af0a-b60a-4195-9bf2-fc36d27d8417/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e670af0a-b60a-4195-9bf2-fc36d27d8417/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e670af0a-b60a-4195-9bf2-fc36d27d8417/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 3461,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/04bcde85-fe70-479d-bede-fdca62ad5935/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/04bcde85-fe70-479d-bede-fdca62ad5935/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/04bcde85-fe70-479d-bede-fdca62ad5935/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 3462,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/2717d8e7-48c2-4baf-8101-d7d6437b9e85/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2717d8e7-48c2-4baf-8101-d7d6437b9e85/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2717d8e7-48c2-4baf-8101-d7d6437b9e85/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 3463,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f877a846-ddbc-42e4-83d7-1e318060299a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f877a846-ddbc-42e4-83d7-1e318060299a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f877a846-ddbc-42e4-83d7-1e318060299a/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 3465,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/2bd3f461-9d3c-46b9-bcb9-379eaacc4a5a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2bd3f461-9d3c-46b9-bcb9-379eaacc4a5a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2bd3f461-9d3c-46b9-bcb9-379eaacc4a5a/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 3464,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/7dd16880-da89-4dad-bf40-408a28fcdfc6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7dd16880-da89-4dad-bf40-408a28fcdfc6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7dd16880-da89-4dad-bf40-408a28fcdfc6/256x192.jpg",
        "viewOrder": 7
      }
    ],
    "geographicArea": {
      "geographicAreaId": 334,
      "latitude": -8.450783200000002,
      "longitude": 115.2354507,
      "parentGeographicAreaId": 0,
      "title": "Banjar Begawan, Desa, Melinggih Kelod, Payangan, Kabupaten Gianyar, Bali 80571, Indonesia",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 47,
        "tagName": "Intimate Luxury ",
        "tagType": 1
      },
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 61,
        "tagName": "Mountain",
        "tagType": 2
      },
      {
        "tagId": 73,
        "tagName": "Outdoor Terraces",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 88,
        "tagName": "Tennis",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 263,
        "tagName": "Star Gazing",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 612,
        "venueCategoryId": 32,
        "venueId": 490,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 487,
    "venueCategoryId": 20,
    "status": 1,
    "title": "Nihi Sumba Island",
    "description": "This is not an escape from everyday life. It is the return to a life well lived. Where rugged luxury meets unregulated freedom. A place to connect with something larger than oneself. A haven for the adventurer, for the wanderer. The endlessly passionate and curious. Arrive with an open heart and leave changed forever. This is vacation with a purpose. A destination with meaning.\n\nNihi Sumba Island is an award-winning luxury resort on remote Sumba Island, Indonesia. A wide range of land and ocean-based experiences ensures all tastes are catered to. Attentive service and gourmet meals add to the refined and intimate ambience. A romantic getaway idyll with pristine private beach stretching in excess of 2.5km of length ideal for honeymooners and the unique tribal culture on the island offers guests a glimpse of a bygone era.",
    "brand": null,
    "blockQuote": "They are known for their extraordinary NihiOka spa safari which is a must-do while you're there.",
    "emailAddress": "info@nihi.com",
    "website": "nihi.com/",
    "bookingURL": "http://nihi.com/booking/",
    "redirectNotes": null,
    "phoneNumber": "+62 361 757149",
    "addressOne": "",
    "addressTwo": "",
    "city": "Sumba",
    "county": "",
    "region": "",
    "country": "Indonesia",
    "rooms": 33,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 18968,
        "title": "Best hotel in the world is Nihi Sumba — built by fashion mogul ...",
        "url": "https://pkslist.azureedge.net/media/images/1/d66e5db3-94d7-4f0c-984d-43310621192f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d66e5db3-94d7-4f0c-984d-43310621192f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d66e5db3-94d7-4f0c-984d-43310621192f/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 18970,
        "title": "Nihiwatu Resort - Sumba Island - YouTube",
        "url": "https://pkslist.azureedge.net/media/images/1/abfa3d84-cf77-41f9-ac3e-ad81a093b6b2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/abfa3d84-cf77-41f9-ac3e-ad81a093b6b2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/abfa3d84-cf77-41f9-ac3e-ad81a093b6b2/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 18969,
        "title": "Nihi Sumba Island . Indonesia . | J.MAK Hospitality",
        "url": "https://pkslist.azureedge.net/media/images/1/9b927cef-cd93-4fd0-b112-65e03fbeed6e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9b927cef-cd93-4fd0-b112-65e03fbeed6e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9b927cef-cd93-4fd0-b112-65e03fbeed6e/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 18974,
        "title": "Best hotel in the world is Nihi Sumba — built by fashion mogul ...",
        "url": "https://pkslist.azureedge.net/media/images/1/7e7a7fad-0bb5-4dfd-b076-8886be4bb120/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7e7a7fad-0bb5-4dfd-b076-8886be4bb120/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7e7a7fad-0bb5-4dfd-b076-8886be4bb120/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 18973,
        "title": "Best hotel in the world is Nihi Sumba — built by fashion mogul ...",
        "url": "https://pkslist.azureedge.net/media/images/1/195176ee-d815-4f5f-b033-6e4ef709dabb/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/195176ee-d815-4f5f-b033-6e4ef709dabb/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/195176ee-d815-4f5f-b033-6e4ef709dabb/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 18972,
        "title": "Nihi Sumba Island | LuxuryHunt.com | Ultimate Collection",
        "url": "https://pkslist.azureedge.net/media/images/1/262675b4-8c31-460e-9577-8fa2975ac60c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/262675b4-8c31-460e-9577-8fa2975ac60c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/262675b4-8c31-460e-9577-8fa2975ac60c/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 3440,
        "title": "Olivia Palermo | Travel: Nihiwatu Beach - Sumba Island, Indonesia ...",
        "url": "https://pkslist.azureedge.net/media/images/1/483292e6-b16c-4f83-ae5c-09e21ddb31ee/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/483292e6-b16c-4f83-ae5c-09e21ddb31ee/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/483292e6-b16c-4f83-ae5c-09e21ddb31ee/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 3441,
        "title": "Nihiwatu Resort, Sumba Island, Indonesia: Sand and surf perfection ...",
        "url": "https://pkslist.azureedge.net/media/images/1/ea093a8c-7e2e-4930-8bd1-108c04a6bb2a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ea093a8c-7e2e-4930-8bd1-108c04a6bb2a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ea093a8c-7e2e-4930-8bd1-108c04a6bb2a/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 18976,
        "title": "Nihi Sumba Island Bali | Worldwide Vacation Spots | Pinterest ...",
        "url": "https://pkslist.azureedge.net/media/images/1/0f0a2b27-e105-46f6-9cd4-d27a3a960114/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0f0a2b27-e105-46f6-9cd4-d27a3a960114/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0f0a2b27-e105-46f6-9cd4-d27a3a960114/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 3436,
        "title": "Stay in a Treehouse with a Private Infinity Pool, at Nihiwatu ...",
        "url": "https://pkslist.azureedge.net/media/images/1/43dfe8b4-98da-4588-9d31-5d9ec00a9b7e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/43dfe8b4-98da-4588-9d31-5d9ec00a9b7e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/43dfe8b4-98da-4588-9d31-5d9ec00a9b7e/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 3435,
        "title": "Nihiwatu Sumba Island - The Bali Bible",
        "url": "https://pkslist.azureedge.net/media/images/1/26cd1670-8364-44d2-8a91-aec276a2d9f6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/26cd1670-8364-44d2-8a91-aec276a2d9f6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/26cd1670-8364-44d2-8a91-aec276a2d9f6/256x192.jpg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 331,
      "latitude": -9.7776614,
      "longitude": 119.37614959999996,
      "parentGeographicAreaId": 0,
      "title": "Nusa Tenggara Tim, Hoba Wawi, Wanokaka, Hoba Wawi, Wanokaka, Kabupaten Sumba Barat, Nusa Tenggara Tim., Indonesia",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 50,
        "tagName": "Barefoot Luxury",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 59,
        "tagName": "Cultural",
        "tagType": 2
      },
      {
        "tagId": 82,
        "tagName": "Horseback Riding",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 122,
        "tagName": "Surfing",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 266,
        "tagName": "Nature Walk",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 324,
        "tagName": "Local Village",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 84,
        "venueCategoryId": 20,
        "venueId": 487,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 20,
          "title": "BucketList Hotels",
          "region": "Global",
          "viewOrder": 0,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 342,
        "venueCategoryId": 5,
        "venueId": 487,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 5,
          "title": "Romantic Getaways",
          "region": "Global",
          "viewOrder": 20,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 622,
        "venueCategoryId": 32,
        "venueId": 487,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 645,
        "venueCategoryId": 34,
        "venueId": 487,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 677,
        "venueCategoryId": 33,
        "venueId": 487,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 33,
          "title": "Rustic Elegance",
          "region": "Global",
          "viewOrder": 11,
          "updated": "2020-09-04T09:04:00",
          "created": "2020-09-04T09:04:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 299,
    "venueCategoryId": 26,
    "status": 1,
    "title": "Four Seasons Resort at Landaa Giraavaru",
    "description": "Immerse yourself in the pristine tropical beauty of this remote island hideaway at Four Seasons Resort Maldives at Landaa Giraavaru. Experience the natural world in a luxurious resort setting. Combining innovation, wellness and conservation, Landaa Giraavaru is a 44 acre, 103 villa wonderland in the Baa Atoll UNESCO Biosphere Reserve.\n\nLandaa Giraavaru’s vast natural wilderness incorporates 103 thatched bungalows and villas of contemporary Maldivian design. Hidden behind traditional turquoise gates, all land-based accommodations have their own beach access, pool and lush genuinely private gardens. Beach Villas and Family Beach Villas also have a 12-metre lap pool, sand-floor living area with sofas and toes-in-the-sand dining, and sea-gazing loft. Perched on stilts over the tranquil lagoon, the Water Villas – some with pools – offer panoramic Indian Ocean views through floor to ceiling windows, spacious sundecks, outdoor showers, and nets for over-water lazing. Land & Ocean Villas link land-based living quarters and over-water bedrooms across a vast pool deck, while the uber-luxe Three-bedroom Landaa Estate has its own 80-metre beach and 2,000 square feet of al fresco living space.",
    "brand": "Four Seasons",
    "blockQuote": "Don't miss a visit to the Marine Center and the anti-gravity yoga is a lot of fun.",
    "emailAddress": "res.maldives@fourseasons.com",
    "website": "fourseasons.com/maldiveslg/",
    "bookingURL": "https://reservations.fourseasons.com/?hotelCode=MLE313&checkIn=2021-03-15&checkOut=2021-03-16&adults=2&children=0&iata=96027890",
    "redirectNotes": "Four Seasons Resort at Landaa Giraavaru's reservations system",
    "phoneNumber": "+960 66 00 888",
    "addressOne": "",
    "addressTwo": "",
    "city": "Baa Atoll",
    "county": "",
    "region": "",
    "country": "Maldives",
    "rooms": 103,
    "dollar": 3,
    "updated": "2023-11-24T11:28:55.25",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 43100,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a894f1b5-14d8-42f2-ab28-cbe10417c6ca/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a894f1b5-14d8-42f2-ab28-cbe10417c6ca/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a894f1b5-14d8-42f2-ab28-cbe10417c6ca/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 43093,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/85dd3d5c-09df-4a77-9583-806e62857035/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/85dd3d5c-09df-4a77-9583-806e62857035/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/85dd3d5c-09df-4a77-9583-806e62857035/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 46149,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e7add46b-2e21-467e-b6b7-6762fcb73073/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e7add46b-2e21-467e-b6b7-6762fcb73073/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e7add46b-2e21-467e-b6b7-6762fcb73073/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 43089,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/516824cc-dde7-41b7-b348-561a99f57cb9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/516824cc-dde7-41b7-b348-561a99f57cb9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/516824cc-dde7-41b7-b348-561a99f57cb9/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 43085,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a6250588-5a17-42de-a7be-bbcb88e7a8be/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a6250588-5a17-42de-a7be-bbcb88e7a8be/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a6250588-5a17-42de-a7be-bbcb88e7a8be/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 43088,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b06943d8-5876-48e0-8fae-ee391a4333fe/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b06943d8-5876-48e0-8fae-ee391a4333fe/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b06943d8-5876-48e0-8fae-ee391a4333fe/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 46152,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ad80c72c-8ee2-4a6c-9842-1fdccf451eb7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ad80c72c-8ee2-4a6c-9842-1fdccf451eb7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ad80c72c-8ee2-4a6c-9842-1fdccf451eb7/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 46151,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d00cd099-5f34-47c6-99e3-d100110aa366/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d00cd099-5f34-47c6-99e3-d100110aa366/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d00cd099-5f34-47c6-99e3-d100110aa366/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 43094,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/58a64fe3-1cb2-4bbc-b8fc-ad1ce1307d24/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/58a64fe3-1cb2-4bbc-b8fc-ad1ce1307d24/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/58a64fe3-1cb2-4bbc-b8fc-ad1ce1307d24/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 43098,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/111aae65-3fa4-48b7-8c20-a59f0cc23866/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/111aae65-3fa4-48b7-8c20-a59f0cc23866/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/111aae65-3fa4-48b7-8c20-a59f0cc23866/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 43096,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d8fa25b4-e6a2-4ef7-803f-270e8defc14d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d8fa25b4-e6a2-4ef7-803f-270e8defc14d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d8fa25b4-e6a2-4ef7-803f-270e8defc14d/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 43092,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/35fa2988-0128-4db9-b690-a07d5e98b185/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/35fa2988-0128-4db9-b690-a07d5e98b185/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/35fa2988-0128-4db9-b690-a07d5e98b185/256x192.jpg",
        "viewOrder": 11
      },
      {
        "mediaId": 46148,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e70789fd-ba5e-4f34-88b9-e7349b9af422/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e70789fd-ba5e-4f34-88b9-e7349b9af422/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e70789fd-ba5e-4f34-88b9-e7349b9af422/256x192.jpg",
        "viewOrder": 12
      },
      {
        "mediaId": 43099,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/357a53ff-611c-492c-9cc6-d001226954a6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/357a53ff-611c-492c-9cc6-d001226954a6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/357a53ff-611c-492c-9cc6-d001226954a6/256x192.jpg",
        "viewOrder": 13
      },
      {
        "mediaId": 43090,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/11d0c926-da97-486e-8e47-0cf38b00ad70/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/11d0c926-da97-486e-8e47-0cf38b00ad70/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/11d0c926-da97-486e-8e47-0cf38b00ad70/256x192.jpg",
        "viewOrder": 14
      }
    ],
    "geographicArea": {
      "geographicAreaId": 160,
      "latitude": 4.8709599,
      "longitude": 72.99982120000004,
      "parentGeographicAreaId": 0,
      "title": "Baa Atoll, Maldives",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 157,
        "tagName": "Beach Escape",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 58,
        "tagName": "Beach",
        "tagType": 2
      },
      {
        "tagId": 70,
        "tagName": "Families",
        "tagType": 2
      },
      {
        "tagId": 83,
        "tagName": "Kid's Club",
        "tagType": 2
      },
      {
        "tagId": 88,
        "tagName": "Tennis",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 109,
        "tagName": "Fishing",
        "tagType": 2
      },
      {
        "tagId": 116,
        "tagName": "Scuba Diving",
        "tagType": 2
      },
      {
        "tagId": 276,
        "tagName": "Gym",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 38,
        "venueCategoryId": 15,
        "venueId": 299,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 15,
          "title": "Beach Paradise",
          "region": "Global",
          "viewOrder": 6,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 479,
        "venueCategoryId": 26,
        "venueId": 299,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 26,
          "title": "Maldives Top 10",
          "region": "Indian Ocean",
          "viewOrder": 7,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 504,
        "venueCategoryId": 27,
        "venueId": 299,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 27,
          "title": "Family Fun",
          "region": "Global",
          "viewOrder": 16,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 613,
        "venueCategoryId": 32,
        "venueId": 299,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  }
]
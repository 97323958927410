import React from 'react'
import "../styles/Home.css";
import "react-alice-carousel/lib/alice-carousel.css";
import { useState } from 'react';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { AiOutlineSearch } from "react-icons/ai"
import { Link } from 'react-router-dom';
import CalendarNavBar from './CalendarNavBar';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { counterActions } from '../redux/State';
import { AiOutlinePlusCircle } from "react-icons/ai"
import { AiOutlineMinusCircle } from "react-icons/ai"
import Select from 'react-select';
import { IoLocationOutline } from "react-icons/io5";

const Home = ({ toggle, setToggle }) => {

  const [inputValue, setInputeValue] = useState("");

  const [linkClickedVlaue, setLinkClickedValue] = useState("");

  const [selectedOption, setSelectedOption] = useState(null);

  let [children, setChildren] = useState(0);

  let [infants, setInfants] = useState(0);


  const adultsQuantity = useSelector((state) => state.counter.value);

  const dispacth = useDispatch();

  const increaeHandler = () => {
    dispacth(counterActions.increaseCount());
  }

  const decreaseHandler = () => {
    dispacth(counterActions.decrementCount());
  }

  const inputValueHandler = (e) => {
    setInputeValue(e.target.value);
  }

  const options = [
    { value: 'yes', label: 'yes' },
    { value: 'no', label: 'no' },
  ]

  const childrenAdd = () => {
    setChildren(children + 1)
  }

  const childrenLess = () => {
    if (children <= 0) {
      return
    }
    else {
      setChildren(children - 1)
    }
  }

  const infantsAdd = () => {
    setInfants(infants + 1)
  }

  const infantsLess = () => {
    if (infants <= 0) {
      return
    }
    else {
      setInfants(infants - 1)
    }
  }


  const placesArray = [
    {
      id: "1",
      place: "london"
    },
    {
      id: "2",
      place: "russia"
    },
    {
      id: "3",
      place: "spain"
    },
    {
      id: "4",
      place: "brazil"
    },
    {
      id: "5",
      place: "india"
    },
    {
      id: "6",
      place: "france"
    },

    {
      id: "9",
      place: "italy"
    },
    {
      id: "10",
      place: "australia"
    },
    {
      id: "11",
      place: "canada"
    },
    {
      id: "12",
      place: "spain"
    },
    {
      id: "13",
      place: "usa"
    },
    {
      id: "14",
      place: "kyiv"
    },
    {
      id: "15",
      place: "ukraine"
    },
    {
      id: "16",
      place: "newyork"
    },
    {
      id: "17",
      place: "europe"
    },

  ]

  return (

    <div>
      <nav className=" bg-white w-full navBar flex relative justify-between items-center mx-auto px-8 h-20">
        {/* logo */}
        <div className="inline-flex">
          <Link className="_o6689fn" to={"/"}>
            <div className="hidden md:block">
              <img className='md:w-64 w-32' src='/images/TSPlus.png' alt='TravelStart+' />

            </div>
            <div className="block md:hidden">
              <img className='md:w-64 w-32' src='/images/TSPlus.png' alt='TravelStart+' />

            </div>
          </Link>
        </div>

        <div className="inline-flex">
          <Link className="_o6689fn" to={"/"}>
            <div className="hidden md:block">
              <img className='md:w-48 w-32' src='/images/logo-teal.png' alt='PksList logo' />

            </div>
            <div className="block md:hidden">
              <img className='md:w-48 w-32' src='/images/logo-teal.png' alt='PksList logo' />

            </div>
          </Link>
        </div>

        <div className="hidden sm:block flex-shrink flex-grow-0 justify-start px-2">
          <div className="inline-block">
            <div className="inline-flex items-center max-w-full">


              <label htmlFor='my-modal-4' className='navbarAnyHold flex inpWidth2 cursor-pointer'>
                <p className=' inline-block'> Anywhere <p className=' inline-block ml-1'> | </p> </p>
                <p className=' inline-block'> Any Week <p className=' inline-block ml-1'> | </p> </p>
                <p className=' text-gray-400 whitespace-nowrap '> Add guests <AiOutlineSearch className=' whitespace-nowrap inline-block' /> </p>
              </label>

              <input type="checkbox" id="my-modal-4" className="modal-toggle" />
              <label htmlFor="my-modal-4" className="modal cursor-pointer">
                <label className="modal-box relative" htmlFor="">
                  <input placeholder="Search Destinations" onChange={inputValueHandler} onClick={() => setToggle(!toggle)} value={inputValue} type="text" className="flex outline-none items-center flex-grow-0 flex-shrink pl-2 relative inputBox  border  px-1  py-1" />



                  <div className='searchTabsHold relative flex top-28 right-1'>
                    <Tabs variant='soft-rounded' colorScheme='red'>
                      <TabList>
                        <Tab>PLACE</Tab>
                        <Tab>DATES</Tab>
                        <Tab>GUESTS</Tab>
                      </TabList>

                      <TabPanels>

                        <TabPanel>

                          <div className='absolute z-50 tabContentPlaces'>
                            {inputValue === "" ? <> <div className='tabContent1Hold cursor-pointer'>
                              <Link to={`/location/usa`}>
                                <img src='https://a0.muscache.com/pictures/f9ec8a23-ed44-420b-83e5-10ff1f071a13.jpg' className='tabContent1Image' alt="USA"/>
                              </Link>
                              <Link to={`/location/europe`}>
                                <img src='https://a0.muscache.com/im/pictures/7b5cf816-6c16-49f8-99e5-cbc4adfd97e2.jpg?im_w=320' className='tabContent1Image' alt="Europe"/>
                              </Link>

                              <Link to={`/location/london`}>
                                <img src='https://a0.muscache.com/im/pictures/dbb2b5ef-2efe-4099-81ac-c7b957f384ed.jpg?im_w=320' className='tabContent1Image' alt="UK"/>
                              </Link>

                            </div>

                              <div className='secImages'>
                                <Link to={`/location/canada`}>
                                  <img src='https://a0.muscache.com/im/pictures/d77de9f5-5318-4571-88c7-e97d2355d20a.jpg?im_w=320' className='tabContent1Image'  alt="USA"/>
                                </Link>
                                <Link to={`/location/italy`}>
                                  <img src='https://a0.muscache.com/im/pictures/ebc5a343-8b76-4ae5-8700-eb5e9cec9243.jpg?im_w=320' className='tabContent1Image mr-1'  alt="USA"/>
                                </Link>
                                <Link to={`/location/kyiv`}>
                                  <img src='https://a0.muscache.com/im/pictures/66355b01-4695-4db9-b292-c149c46fb1ca.jpg?im_w=320' className='tabContent1Image'  alt="USA"/>
                                </Link>
                              </div>

                              <div className='secText flex  top-24 relative left-16'>
                                <p className='fs'> SouthEast Asia </p>
                                <p className='fs'> Indonesia </p>
                                <p className='fs'> MiddleEast </p>
                              </div>

                              <div className='imgCaptionHold flex flex-wrap'>
                                <p className='fs'> I'm flexible  </p>
                                <p className='fs'> Europe</p>
                                <p className='fs'> United Kingdom </p>

                              </div>  </> : <>
                              <div className='serachResultsHold'>
                                <p className=' fof text-xl text-center mt-3'> MOST TRAVELLED PLACES </p>
                                <div className=' flex flex-row flex-wrap searchDisplayParent'>
                                  {placesArray.filter((item) => {

                                    if (inputValue === "") {
                                      return ""
                                    }
                                    else if (item.place.toLocaleLowerCase().includes(inputValue)) {
                                      return item
                                    }
                                  }).map((item) => {
                                    return (
                                      <div className='searcdisplayIndivitual'>
                                        <div>
                                          <Link to={`/location/${linkClickedVlaue}`}>
                                            <IoLocationOutline className=' inline-block mb-2 text-2xl' />
                                            <p onMouseOver={(e) => setLinkClickedValue(e.target.innerText)} className="capitalize fof inline-block">  {item.place} </p>
                                          </Link>
                                        </div>
                                      </div>
                                    )
                                  })}
                                </div>
                              </div>
                            </>}

                          </div>


                        </TabPanel>
                        <TabPanel>
                          <CalendarNavBar />
                        </TabPanel>

                        <TabPanel>
                          <div className='tab3MainHold flex flex-col'>
                            <div className='adultsKidsHold flex-col flex text-lg font-medium fof'>
                              <p> Adults  </p>
                              <p>Children</p>
                              <p>Infants  </p>
                              <p> Pets </p>
                            </div>

                            <div className='adultCriteriaHold flex flex-col relative'>
                              <p className=' text-gray-400'> Ages 13 or above </p>
                              <p className=' text-gray-400'> Ages 2-12 </p>
                              <p className=' text-gray-400'> Under 2 </p>
                              <p className=' text-gray-400'> Select pet friendly </p>
                            </div>

                            <div className='divideLineHold flex flex-col'>
                              <p className='navLine'>  </p>
                              <p className='navLine'>  </p>
                              <p className='navLine'>  </p>
                            </div>

                            <div className='btnControlsPlus flex flex-col relative'>
                              <AiOutlinePlusCircle className='fss' onClick={increaeHandler} />
                              <AiOutlinePlusCircle className='fss' onClick={childrenAdd} />
                              <AiOutlinePlusCircle className='fss' onClick={infantsAdd} />
                            </div>

                            <div className='btnControlMinus flex flex-col relative cursor-pointer' id='btnmin'>
                              <AiOutlineMinusCircle className='fss' onClick={decreaseHandler} />
                              <AiOutlineMinusCircle className='fss' onClick={childrenLess} />
                              <AiOutlineMinusCircle className='fss' onClick={infantsLess} />

                            </div>

                            <div className='navQtyHold relative'>
                              <p className=' w-3'> {adultsQuantity} </p>
                              <p className=' w-3'> {children}</p>
                              <p className=' w-3'>{infants} </p>
                            </div>

                            <div className='selectWrapper'>
                              <Select
                                defaultValue={selectedOption}
                                onChange={setSelectedOption}
                                options={options}
                                className="outline-none"
                              />
                            </div>


                          </div>
                        </TabPanel>

                      </TabPanels>
                    </Tabs>

                  </div>

                </label>
              </label>

            </div>
          </div>
        </div>
        {/* end search bar */}
      </nav >

    </div >
  )
}

export default Home

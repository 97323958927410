// 20240123100509
// https://app.pks-list.com/api/Venue/v1/getlist/35

export const placesStore33=
[
  {
    "venueId": 2657,
    "venueCategoryId": 2,
    "status": 1,
    "title": "The Newt in Somerset",
    "description": "The Newt in Somerset is a country estate with splendid woodland, farmland and gardens. The core is Georgian, with limestone buildings the colour of burnt orange, the seat of the Hobhouse family for more than two centuries. Innovative design is paired with the freshest produce, beautiful country walks and a state-of-the-art spa – wrapping you in a sense of wellbeing.\n\nHadspen House, built around 1690, captures the essence of a Georgian country home. A place to delight, its elegant rooms have been restored and reimagined, while its unique setting inspires our garden-to-table dining and garden-scented Spa.\n\nChoose a book from the Library, sampling our selection of teas in the Drawing Room. Sip a cocktail while peering across the Croquet Lawn at the Somerset landscape. Feast on homegrown fare in The Botanical Rooms. Get together in the Bar or Croquet Room, choosing from the collection of wines in our vaulted cellars below, as well as our very own cyder.",
    "brand": "",
    "blockQuote": "The garden is unbelievably beautiful and will only get better with time.",
    "emailAddress": "reservations@thenewtinsomerset.com",
    "website": "thenewtinsomerset.com/",
    "bookingURL": "https://bookings.thenewtinsomerset.com/",
    "redirectNotes": null,
    "phoneNumber": "+44 1963 577777",
    "addressOne": "Hadspen, Bruton",
    "addressTwo": "BA7 7NG",
    "city": "Castle Cary",
    "county": "Somerset",
    "region": "England",
    "country": "United Kingdom",
    "rooms": 23,
    "dollar": 2,
    "updated": "2020-09-29T11:38:31.067",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 35307,
        "title": "The Newt in Somerset on Instagram: “We are almost ready to welcome ...",
        "url": "https://pkslist.azureedge.net/media/images/1/9293813b-5c5b-4f4d-a9ae-93b6f1b369bf/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9293813b-5c5b-4f4d-a9ae-93b6f1b369bf/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9293813b-5c5b-4f4d-a9ae-93b6f1b369bf/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 35299,
        "title": "The Newt hotel in Somerset | House & Garden",
        "url": "https://pkslist.azureedge.net/media/images/1/ee183411-54f8-4861-9c37-fa82d3d0182c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ee183411-54f8-4861-9c37-fa82d3d0182c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ee183411-54f8-4861-9c37-fa82d3d0182c/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 35294,
        "title": "The Newt in Somerset (@thenewtsomerset) | Twitter",
        "url": "https://pkslist.azureedge.net/media/images/1/c60567e2-8934-4fe2-8dad-b0e85756e80b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c60567e2-8934-4fe2-8dad-b0e85756e80b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c60567e2-8934-4fe2-8dad-b0e85756e80b/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 35302,
        "title": "The Newt in Somerset Hotel Review, England | Telegraph Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/8c346087-479d-4a0d-9726-b0783d70f2d4/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8c346087-479d-4a0d-9726-b0783d70f2d4/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8c346087-479d-4a0d-9726-b0783d70f2d4/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 35297,
        "title": "New Hotel Opening: The Newt in Somerset | FOUR Magazine : FOUR ...",
        "url": "https://pkslist.azureedge.net/media/images/1/866a9b7a-8522-4cb6-b214-f3ea5719e98e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/866a9b7a-8522-4cb6-b214-f3ea5719e98e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/866a9b7a-8522-4cb6-b214-f3ea5719e98e/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 35305,
        "title": "The Newt, Somerset: an exclusive first review | Financial Times",
        "url": "https://pkslist.azureedge.net/media/images/1/3e27a529-91e4-4628-806c-f26b04e85fad/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3e27a529-91e4-4628-806c-f26b04e85fad/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3e27a529-91e4-4628-806c-f26b04e85fad/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 35303,
        "title": "The Newt in Somerset | Loft spaces, House rooms, Hotel kitchen",
        "url": "https://pkslist.azureedge.net/media/images/1/c99da51a-3dc0-4a9f-b2d6-cc9fa3e79d2f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c99da51a-3dc0-4a9f-b2d6-cc9fa3e79d2f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c99da51a-3dc0-4a9f-b2d6-cc9fa3e79d2f/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 35301,
        "title": "Rooms & suites at The Newt - Somerset",
        "url": "https://pkslist.azureedge.net/media/images/1/257fa942-e35e-4d14-9c8b-94347e7503bb/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/257fa942-e35e-4d14-9c8b-94347e7503bb/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/257fa942-e35e-4d14-9c8b-94347e7503bb/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 35304,
        "title": "The Newt in Somerset – Hotel Review | Tatler",
        "url": "https://pkslist.azureedge.net/media/images/1/fb7eab94-09d2-40ef-b5a6-6a574e45b132/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fb7eab94-09d2-40ef-b5a6-6a574e45b132/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fb7eab94-09d2-40ef-b5a6-6a574e45b132/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 35293,
        "title": "New Hotel Opening: The Newt in Somerset | FOUR Magazine : FOUR ...",
        "url": "https://pkslist.azureedge.net/media/images/1/9417cfdf-a3a8-4178-bbf9-5cf4b4c79025/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9417cfdf-a3a8-4178-bbf9-5cf4b4c79025/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9417cfdf-a3a8-4178-bbf9-5cf4b4c79025/256x192.jpg",
        "viewOrder": 11
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2435,
      "latitude": 51.08029790000001,
      "longitude": -2.4878027,
      "parentGeographicAreaId": 0,
      "title": "A359, Hadspen, Bruton, Castle Cary BA7 7NG, UK",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 31,
        "tagName": "Countryside",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 120,
        "tagName": "Private Dining",
        "tagType": 2
      },
      {
        "tagId": 131,
        "tagName": "Library",
        "tagType": 2
      },
      {
        "tagId": 172,
        "tagName": "Biking",
        "tagType": 2
      },
      {
        "tagId": 221,
        "tagName": "Wine Tasting",
        "tagType": 2
      },
      {
        "tagId": 256,
        "tagName": "Boutique",
        "tagType": 2
      },
      {
        "tagId": 320,
        "tagName": "Organic Garden",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 687,
        "venueCategoryId": 33,
        "venueId": 2657,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 33,
          "title": "Rustic Elegance",
          "region": "Global",
          "viewOrder": 11,
          "updated": "2020-09-04T09:04:00",
          "created": "2020-09-04T09:04:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 688,
        "venueCategoryId": 1,
        "venueId": 2657,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 1,
          "title": "Escape from the City",
          "region": "Global",
          "viewOrder": 14,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2315,
    "venueCategoryId": 0,
    "status": 1,
    "title": "La Lancha",
    "description": "Set in the rainforest high above the shores of Lago Petén Itzá is La Lancha, a quaint, 10-room lodge decorated with furniture, fabrics, and carvings from Guatemala personally selected by the Coppola family. Nearby is Tikal, the epicenter of Maya spirituality. Buried deep within the rainforest, the ruins include more than 3,000 structures where moss clings to the steps of ancient temples and plazas.\n\nWith its beautiful lakeshore setting, relaxed ambiance, and close proximity to the lost world of Maya, La Lancha is an ideal destination to both enjoy a languid pace and explore the many adventures of the Petén region.",
    "brand": null,
    "blockQuote": "Lake View Suites are my favorite since look out over the gorgeous Lago Petén Itzá.",
    "emailAddress": "info@thefamilycoppolahideaways.com",
    "website": "thefamilycoppolahideaways.com/en/la-lancha",
    "bookingURL": "https://www.booking.com/hotel/gt/la-lancha.en-gb.html?aid=925669;label=gog235jc-1DCAsoXkIJbGEtbGFuY2hhSDNYA2iwAYgBAZgBCbgBGMgBDNgBA-gBAYgCAagCBLgC3sSH5gXAAgE;sid=6b10f0abd2fe50a3592a2bb374b7ab7f;dist=0&keep_landing=1&sb_price_type=total&type=total&",
    "redirectNotes": null,
    "phoneNumber": "+502 3045 0817",
    "addressOne": "",
    "addressTwo": "",
    "city": "Lake Peten Itza",
    "county": "",
    "region": "",
    "country": "Guatemala",
    "rooms": 10,
    "dollar": 1,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 29627,
        "title": "Guatemala Luxury Resort, Boutique Guatemala Hotel Amenities ...",
        "url": "https://pkslist.azureedge.net/media/images/1/55828ecd-bd57-49e2-b6b3-6c45c36fad94/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/55828ecd-bd57-49e2-b6b3-6c45c36fad94/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/55828ecd-bd57-49e2-b6b3-6c45c36fad94/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 29626,
        "title": "Lodge La Lancha, El Remate, Guatemala - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/e52ae9bf-4761-4e0e-a271-355c70070d2a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e52ae9bf-4761-4e0e-a271-355c70070d2a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e52ae9bf-4761-4e0e-a271-355c70070d2a/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 29630,
        "title": "Boutique Hotels Guatemala, Guatemala Resort - Coppola ...",
        "url": "https://pkslist.azureedge.net/media/images/1/be9a4901-efd0-4d5a-a08f-5b4e8bce60e4/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/be9a4901-efd0-4d5a-a08f-5b4e8bce60e4/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/be9a4901-efd0-4d5a-a08f-5b4e8bce60e4/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 29635,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/61afa601-feb7-44c8-a860-5b16d1ee49a8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/61afa601-feb7-44c8-a860-5b16d1ee49a8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/61afa601-feb7-44c8-a860-5b16d1ee49a8/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 29634,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c68cab2c-5589-4415-8957-5b2b379ddd0e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c68cab2c-5589-4415-8957-5b2b379ddd0e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c68cab2c-5589-4415-8957-5b2b379ddd0e/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 29640,
        "title": "La Lancha | Luxury Hotel in Guatemala Central America",
        "url": "https://pkslist.azureedge.net/media/images/1/4236eb31-e599-4e91-9851-e22129a660cd/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4236eb31-e599-4e91-9851-e22129a660cd/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4236eb31-e599-4e91-9851-e22129a660cd/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 29639,
        "title": "La Lancha, Guatemala, The Family Coppola Resorts – Belize Travel ...",
        "url": "https://pkslist.azureedge.net/media/images/1/69e79043-029e-462d-995e-3ffaa385b540/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/69e79043-029e-462d-995e-3ffaa385b540/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/69e79043-029e-462d-995e-3ffaa385b540/256x192.jpg",
        "viewOrder": 6
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2115,
      "latitude": 16.99932,
      "longitude": -89.70032000000003,
      "parentGeographicAreaId": 0,
      "title": "Lake Peten Itza, El Remate, Guatemala",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 47,
        "tagName": "Intimate Luxury ",
        "tagType": 1
      },
      {
        "tagId": 57,
        "tagName": "Romantic",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 75,
        "tagName": "Casual Dining",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 164,
        "tagName": "Excursions",
        "tagType": 2
      },
      {
        "tagId": 171,
        "tagName": "Trekking",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 229,
        "tagName": "Private Balconies",
        "tagType": 2
      },
      {
        "tagId": 235,
        "tagName": "Hot Tub",
        "tagType": 2
      },
      {
        "tagId": 257,
        "tagName": "Canoeing",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 650,
        "venueCategoryId": 34,
        "venueId": 2315,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 676,
        "venueCategoryId": 33,
        "venueId": 2315,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 33,
          "title": "Rustic Elegance",
          "region": "Global",
          "viewOrder": 11,
          "updated": "2020-09-04T09:04:00",
          "created": "2020-09-04T09:04:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2164,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Rancho do Peixe",
    "description": "Your house on the beach is here, with everything that you can imagine and nothing less!\n\nRancho do Peixe is a charming and peaceful refuge situated in a 200 thousand m2 (49 acre) coconut plantation that has sand dunes, native vegetation, and faces the sea. Each of the 26 thatched-roof bungalows is made of wood and designed in harmony with the coconut trees and beach. The bungalows afford privileged lighting and ventilation so one can chat underneath the moonlight, take in the sunset, and enjoy the refreshing sea breeze. There's a restaurant serving Brazilian specialties and regional fare, a lounge with TV and games, a 25m pool surrounded by deck chairs, an area for relaxation, a kite surfing school and storage, and the famous beach bar.",
    "brand": null,
    "blockQuote": "Kitesurfing and windsurfing are the things to do here and conveniently, there's Rancho do Kite school to help you get started.",
    "emailAddress": "reservas@ranchodopeixe.com.br",
    "website": "ranchodopeixe.com.br/en/",
    "bookingURL": "http://www.booking.com/Share-Vsrcei?aid=925669",
    "redirectNotes": null,
    "phoneNumber": "+55 88 3660 3118",
    "addressOne": "",
    "addressTwo": "",
    "city": "Jericoacoara",
    "county": "",
    "region": "",
    "country": "Brazil",
    "rooms": 26,
    "dollar": 1,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 27025,
        "title": "Rancho do Peixe in Cruz - Book on Hotels.com",
        "url": "https://pkslist.azureedge.net/media/images/1/b1826286-5ab0-4cdf-a820-f3f1df90fc79/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b1826286-5ab0-4cdf-a820-f3f1df90fc79/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b1826286-5ab0-4cdf-a820-f3f1df90fc79/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 27030,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ee8bc39a-c0a3-4383-86fa-52fa4189a2ce/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ee8bc39a-c0a3-4383-86fa-52fa4189a2ce/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ee8bc39a-c0a3-4383-86fa-52fa4189a2ce/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 27021,
        "title": "Guesthouse Rancho Do Peixe, Prea, Brazil - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/f395c00d-b8a4-43c9-8e42-775d5ddd556f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f395c00d-b8a4-43c9-8e42-775d5ddd556f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f395c00d-b8a4-43c9-8e42-775d5ddd556f/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 27032,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e01dc012-e697-4a80-be95-b5ef717e7656/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e01dc012-e697-4a80-be95-b5ef717e7656/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e01dc012-e697-4a80-be95-b5ef717e7656/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 27028,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/40f4fdd0-598f-43bb-9558-01c1b1e62c1d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/40f4fdd0-598f-43bb-9558-01c1b1e62c1d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/40f4fdd0-598f-43bb-9558-01c1b1e62c1d/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 27029,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/6901f321-7a60-4a01-992a-07e8b5f89b25/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6901f321-7a60-4a01-992a-07e8b5f89b25/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6901f321-7a60-4a01-992a-07e8b5f89b25/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 27022,
        "title": "Home - Rancho do Peixe",
        "url": "https://pkslist.azureedge.net/media/images/1/6c834b96-198c-4ec9-9696-1ba7ec105a5b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6c834b96-198c-4ec9-9696-1ba7ec105a5b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6c834b96-198c-4ec9-9696-1ba7ec105a5b/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 27024,
        "title": "Home - Rancho do Peixe",
        "url": "https://pkslist.azureedge.net/media/images/1/572a4f6b-4574-4c3d-9921-d76453fe2c48/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/572a4f6b-4574-4c3d-9921-d76453fe2c48/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/572a4f6b-4574-4c3d-9921-d76453fe2c48/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1970,
      "latitude": -2.8164317,
      "longitude": -40.40808229999999,
      "parentGeographicAreaId": 0,
      "title": "Rua da Praia, s/n, Cruz - CE, 62595-000, Brazil",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 50,
        "tagName": "Barefoot Luxury",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 58,
        "tagName": "Beach",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 102,
        "tagName": "Eco-friendly",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 152,
        "tagName": "Kite Surfing",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 230,
        "tagName": "Hammocks",
        "tagType": 2
      },
      {
        "tagId": 261,
        "tagName": "Wind Surfing",
        "tagType": 2
      },
      {
        "tagId": 263,
        "tagName": "Star Gazing",
        "tagType": 2
      },
      {
        "tagId": 303,
        "tagName": "Games Room",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 668,
        "venueCategoryId": 33,
        "venueId": 2164,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 33,
          "title": "Rustic Elegance",
          "region": "Global",
          "viewOrder": 11,
          "updated": "2020-09-04T09:04:00",
          "created": "2020-09-04T09:04:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2142,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Anavilhanas Jungle Lodge",
    "description": "Anavilhanas Jungle Lodge is a cozy and exclusive jungle hotel located in front of Parque Nacional de Anavilhanas (Anavilhanas National Park), in the margins of Rio Negro (Negro River), 180km from Manaus, in the heart of the Brazilian Amazon.\n\nThe hotel has 16 Standard Cottages, 4 Superior Bungalows and 2 Panoramic Bungalows with stunning surrounding views over the forest.",
    "brand": null,
    "blockQuote": "The Amazon experiences will blow your mind, but so will the food at this lodge.",
    "emailAddress": "reservas@anavilhanaslodge.com",
    "website": "anavilhanaslodge.com/wordpress/en/",
    "bookingURL": "http://www.anavilhanaslodge.com/wordpress/en/contact-us/reservation/",
    "redirectNotes": null,
    "phoneNumber": "+55 92 3622 8996",
    "addressOne": "",
    "addressTwo": "",
    "city": "Novo Airao",
    "county": "",
    "region": "Amazon",
    "country": "Brazil",
    "rooms": 22,
    "dollar": 2,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 26629,
        "title": "The Amazon, Rio de Janeiro, and Iguazu Falls - Explore captivating ...",
        "url": "https://pkslist.azureedge.net/media/images/1/50e48353-8ac5-4db7-b7ba-35156488ec8f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/50e48353-8ac5-4db7-b7ba-35156488ec8f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/50e48353-8ac5-4db7-b7ba-35156488ec8f/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 26623,
        "title": "The Lodge | Anavilhanas Jungle Lodge",
        "url": "https://pkslist.azureedge.net/media/images/1/9fdd74b2-ef71-4eaf-967a-98cb3033b423/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9fdd74b2-ef71-4eaf-967a-98cb3033b423/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9fdd74b2-ef71-4eaf-967a-98cb3033b423/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 26625,
        "title": "Anavilhanas Jungle Lodge - Vaya Adventures",
        "url": "https://pkslist.azureedge.net/media/images/1/83fc67b1-e1d4-4a28-93fb-1dce4be83f8b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/83fc67b1-e1d4-4a28-93fb-1dce4be83f8b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/83fc67b1-e1d4-4a28-93fb-1dce4be83f8b/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 26631,
        "title": "Anavilhanas Jungle Lodge | get lost Magazine",
        "url": "https://pkslist.azureedge.net/media/images/1/1f96e8b1-f70e-4919-ae27-5b53afd1f377/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1f96e8b1-f70e-4919-ae27-5b53afd1f377/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1f96e8b1-f70e-4919-ae27-5b53afd1f377/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 26621,
        "title": "Anavilhanas Jungle Lodge",
        "url": "https://pkslist.azureedge.net/media/images/1/d0a19a31-347b-4496-8efb-8752acf68fff/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d0a19a31-347b-4496-8efb-8752acf68fff/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d0a19a31-347b-4496-8efb-8752acf68fff/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 26627,
        "title": "Anavilhanas Jungle Lodge - Vaya Adventures",
        "url": "https://pkslist.azureedge.net/media/images/1/60baff2c-ada8-4304-b71c-2d6da8759aa9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/60baff2c-ada8-4304-b71c-2d6da8759aa9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/60baff2c-ada8-4304-b71c-2d6da8759aa9/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 26626,
        "title": "Anavilhanas Jungle Lodge, Amazon | A Kuoni Hotel in The Amazon In ...",
        "url": "https://pkslist.azureedge.net/media/images/1/ca1e0b25-fc42-4e52-802c-19e1d22fa088/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ca1e0b25-fc42-4e52-802c-19e1d22fa088/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ca1e0b25-fc42-4e52-802c-19e1d22fa088/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 26628,
        "title": "Anavilhanas Jungle Lodge, Brazil - Natural World Safaris",
        "url": "https://pkslist.azureedge.net/media/images/1/c580486b-7af8-41b1-b8bb-f30b14cbfe6b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c580486b-7af8-41b1-b8bb-f30b14cbfe6b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c580486b-7af8-41b1-b8bb-f30b14cbfe6b/256x192.jpg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1948,
      "latitude": -2.637716,
      "longitude": -60.92417369999998,
      "parentGeographicAreaId": 0,
      "title": "Novo Airão - State of Amazonas, 69730-000, Brazil",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 75,
        "tagName": "Casual Dining",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 135,
        "tagName": "Bird Watching",
        "tagType": 2
      },
      {
        "tagId": 198,
        "tagName": "Boat Excursion",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 251,
        "tagName": "Rainforest",
        "tagType": 2
      },
      {
        "tagId": 252,
        "tagName": "National Park",
        "tagType": 2
      },
      {
        "tagId": 256,
        "tagName": "Boutique",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 667,
        "venueCategoryId": 33,
        "venueId": 2142,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 33,
          "title": "Rustic Elegance",
          "region": "Global",
          "viewOrder": 11,
          "updated": "2020-09-04T09:04:00",
          "created": "2020-09-04T09:04:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2108,
    "venueCategoryId": 0,
    "status": 1,
    "title": "The Daylesford Cottages",
    "description": "The cottages are beautifully converted farmhouses, restored to retain their original features. Four of the self-catered cottages are just a few steps away from the organic farmshop and one is in the village of Daylesford. Just 90 minutes from London, all five cottages provide guests with the opportunity to reconnect with nature, retreat from the everyday stresses of bustling urban life, and experience the beauty of the Cotswolds countryside.\n\n Travelling and holidays are a time to indulge, so each cottage comes with a relaxing one hour treatment in the Bamford Haybarn Spa for every adult guest. You can enhance your stay with further holistic treatments and classes, a course at the Cookery School, a floristry or craft workshop and by dining in the farmshop restaurants and at the sister pub, The Wild Rabbit in the nearby village of Kingham.",
    "brand": "",
    "blockQuote": "Try one of the cooking courses, they're fun and will inspire you to create innovative meals at home.",
    "emailAddress": "daylesfordcottages@daylesford.com",
    "website": "daylesford.com/",
    "bookingURL": "https://www.daylesford.com/daylesford-cottages/",
    "redirectNotes": null,
    "phoneNumber": "+44 01608 731 717",
    "addressOne": "GL56 0YG",
    "addressTwo": "",
    "city": "Daylesford",
    "county": "Cotswolds",
    "region": "England",
    "country": "United Kingdom",
    "rooms": 5,
    "dollar": 3,
    "updated": "2020-09-29T11:29:21.433",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 40308,
        "title": "Daylesford Cottages, Moreton in Marsh, UK - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/348c51b4-a21d-4912-aeca-570e47b82903/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/348c51b4-a21d-4912-aeca-570e47b82903/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/348c51b4-a21d-4912-aeca-570e47b82903/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 40309,
        "title": "Inside the luxury Daylesford Cottages - perfect for a Cotswolds ...",
        "url": "https://pkslist.azureedge.net/media/images/1/a9c49eed-9cab-4974-9ea7-f3a81ce3f4bd/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a9c49eed-9cab-4974-9ea7-f3a81ce3f4bd/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a9c49eed-9cab-4974-9ea7-f3a81ce3f4bd/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 40312,
        "title": "Daylesford Organic Farm in the Cotswolds, England | a conversation",
        "url": "https://pkslist.azureedge.net/media/images/1/775ee0c8-bebf-46ff-81da-31b37e69bff7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/775ee0c8-bebf-46ff-81da-31b37e69bff7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/775ee0c8-bebf-46ff-81da-31b37e69bff7/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 40310,
        "title": "The best hotels in the Cotswolds | CN Traveller",
        "url": "https://pkslist.azureedge.net/media/images/1/3b9730ef-73c7-4322-97b1-e6f7974d975d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3b9730ef-73c7-4322-97b1-e6f7974d975d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3b9730ef-73c7-4322-97b1-e6f7974d975d/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 26138,
        "title": "The Egg Store | Daylesford",
        "url": "https://pkslist.azureedge.net/media/images/1/3bc3c58e-7fd5-44c3-9a4b-5182ddd5df65/1200x900.jpg?fit=2000%2C1334&ssl=1",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3bc3c58e-7fd5-44c3-9a4b-5182ddd5df65/800x600.jpg?fit=2000%2C1334&ssl=1",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3bc3c58e-7fd5-44c3-9a4b-5182ddd5df65/256x192.jpg?fit=2000%2C1334&ssl=1",
        "viewOrder": 4
      },
      {
        "mediaId": 26142,
        "title": "resize woodstore bedroom 2 | Daylesford",
        "url": "https://pkslist.azureedge.net/media/images/1/5a556acc-119c-4be7-8c05-5347fc5922c3/1200x900.jpg?fit=1024%2C651&ssl=1",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5a556acc-119c-4be7-8c05-5347fc5922c3/800x600.jpg?fit=1024%2C651&ssl=1",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5a556acc-119c-4be7-8c05-5347fc5922c3/256x192.jpg?fit=1024%2C651&ssl=1",
        "viewOrder": 5
      },
      {
        "mediaId": 40313,
        "title": "Win a Place on the Daylesford Spring Wellness Retreat in the ...",
        "url": "https://pkslist.azureedge.net/media/images/1/66a8d1df-1d08-431e-a7ef-8afda079a0d1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/66a8d1df-1d08-431e-a7ef-8afda079a0d1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/66a8d1df-1d08-431e-a7ef-8afda079a0d1/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 26139,
        "title": "Stay With Us | Stone exterior, Sitting rooms and Room",
        "url": "https://pkslist.azureedge.net/media/images/1/9160d02b-b76e-48f9-887b-e6c3cbd9d666/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9160d02b-b76e-48f9-887b-e6c3cbd9d666/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9160d02b-b76e-48f9-887b-e6c3cbd9d666/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 26134,
        "title": "Wake Up Here: The Daylesford Cottages, Gloucestershire | SUITCASE ...",
        "url": "https://pkslist.azureedge.net/media/images/1/790b3637-3a55-47dd-95a1-ed3010d4eec7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/790b3637-3a55-47dd-95a1-ed3010d4eec7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/790b3637-3a55-47dd-95a1-ed3010d4eec7/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1918,
      "latitude": 51.9267177,
      "longitude": -1.6446005999999898,
      "parentGeographicAreaId": 0,
      "title": "Daylesford, Moreton-in-Marsh GL56 0YG, UK",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 40,
        "tagName": "Boutique Bolthole ",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 31,
        "tagName": "Countryside",
        "tagType": 2
      },
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 34,
        "tagName": "Cosy",
        "tagType": 2
      },
      {
        "tagId": 75,
        "tagName": "Casual Dining",
        "tagType": 2
      },
      {
        "tagId": 77,
        "tagName": "Cooking Classes",
        "tagType": 2
      },
      {
        "tagId": 114,
        "tagName": "Pub",
        "tagType": 2
      },
      {
        "tagId": 256,
        "tagName": "Boutique",
        "tagType": 2
      },
      {
        "tagId": 266,
        "tagName": "Nature Walk",
        "tagType": 2
      },
      {
        "tagId": 320,
        "tagName": "Organic Garden",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 685,
        "venueCategoryId": 1,
        "venueId": 2108,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 1,
          "title": "Escape from the City",
          "region": "Global",
          "viewOrder": 14,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 686,
        "venueCategoryId": 33,
        "venueId": 2108,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 33,
          "title": "Rustic Elegance",
          "region": "Global",
          "viewOrder": 11,
          "updated": "2020-09-04T09:04:00",
          "created": "2020-09-04T09:04:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1935,
    "venueCategoryId": 19,
    "status": 1,
    "title": "Bawah Reserve",
    "description": "Enveloped by lush tropical greenery and azure blue ocean, Bawah Reserve rests in sheltered carefree seclusion 160 nautical miles (300km) northeast of Singapore in Indonesia’s remote Anambas Archipelago.\n\nSurrounded by clear turquoise waters, blue lagoons and coral reefs, this pristine and previously uninhabited marine conservation area caters to outdoor adventurers with an appetite for indulgence as well as paradise seekers looking for relaxation. Bawah offers the true meaning of all-inclusive luxury, by being the only retreat in Asia to include all spa treatments, guest experiences and transport via its new amphibious seaplane.",
    "brand": "",
    "blockQuote": "At least go for the bamboo tented beach suite but better yet, upgrade yourself to an Overwater Bungalow for an even more spectacular view.",
    "emailAddress": "reservations@bawahreserve.com",
    "website": "bawahreserve.com/",
    "bookingURL": "https://reservations.verticalbooking.com/reservations/index.html?lingua_int=eng&id_stile=13302&id_albergo=17659&dc=5240",
    "redirectNotes": null,
    "phoneNumber": "+33 6 52 93 07 85",
    "addressOne": "",
    "addressTwo": "",
    "city": "Anambas",
    "county": "",
    "region": "Riau Islands",
    "country": "Indonesia",
    "rooms": 35,
    "dollar": 3,
    "updated": "2020-05-13T20:13:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 23539,
        "title": "Bawah Reserve opens in Indonesia's Riau Islands",
        "url": "https://pkslist.azureedge.net/media/images/1/0484b812-38d0-4806-b97a-c714670e8285/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0484b812-38d0-4806-b97a-c714670e8285/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0484b812-38d0-4806-b97a-c714670e8285/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 23537,
        "title": "Bawah Reserve: Digital Detox In The Middle Of Glorious Nowhere ...",
        "url": "https://pkslist.azureedge.net/media/images/1/c2f218fa-587a-4857-a34a-c8163b410d3e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c2f218fa-587a-4857-a34a-c8163b410d3e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c2f218fa-587a-4857-a34a-c8163b410d3e/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 23530,
        "title": "Bawah Reserve: Digital Detox In The Middle Of Glorious Nowhere ...",
        "url": "https://pkslist.azureedge.net/media/images/1/ef78fe74-11f2-4102-aed5-09ef1026861a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ef78fe74-11f2-4102-aed5-09ef1026861a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ef78fe74-11f2-4102-aed5-09ef1026861a/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 23534,
        "title": "Bawah Reserve, Indonesia - review | London Evening Standard",
        "url": "https://pkslist.azureedge.net/media/images/1/62506ea4-3a1b-4094-b0ae-db20d9bb91a9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/62506ea4-3a1b-4094-b0ae-db20d9bb91a9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/62506ea4-3a1b-4094-b0ae-db20d9bb91a9/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 23535,
        "title": "Bawah Reserve, Indonesia - review | London Evening Standard",
        "url": "https://pkslist.azureedge.net/media/images/1/5d81655f-f9f4-4d64-80ac-5a043e3d284d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5d81655f-f9f4-4d64-80ac-5a043e3d284d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5d81655f-f9f4-4d64-80ac-5a043e3d284d/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 23538,
        "title": "Bawah Reserve: Digital Detox In The Middle Of Glorious Nowhere ...",
        "url": "https://pkslist.azureedge.net/media/images/1/e485d283-f31d-4079-af19-5f9c8a42ae1f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e485d283-f31d-4079-af19-5f9c8a42ae1f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e485d283-f31d-4079-af19-5f9c8a42ae1f/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 23532,
        "title": "WRD welcomes Bawah Reserve into portfolio ·ETB Travel News Asia",
        "url": "https://pkslist.azureedge.net/media/images/1/71d98365-f75b-4a78-81a6-e7d1c0db36c0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/71d98365-f75b-4a78-81a6-e7d1c0db36c0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/71d98365-f75b-4a78-81a6-e7d1c0db36c0/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 23533,
        "title": "Experiences - Bawah Reserve",
        "url": "https://pkslist.azureedge.net/media/images/1/0871c08c-92d5-46a9-99a5-cd82bdb5e918/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0871c08c-92d5-46a9-99a5-cd82bdb5e918/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0871c08c-92d5-46a9-99a5-cd82bdb5e918/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 23536,
        "title": "Bawah Island Reserve, Indonesia | Book with The Asia Collective",
        "url": "https://pkslist.azureedge.net/media/images/1/6507bae4-2bdf-4ec8-a7c8-410e41d9decb/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6507bae4-2bdf-4ec8-a7c8-410e41d9decb/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6507bae4-2bdf-4ec8-a7c8-410e41d9decb/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1745,
      "latitude": 2.513008,
      "longitude": 106.04848100000004,
      "parentGeographicAreaId": 0,
      "title": "Bawah Reserve, Anambas, Kiabu, Siantan Sel., Pulau Bawah, Riau 29791, Indonesia",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 50,
        "tagName": "Barefoot Luxury",
        "tagType": 1
      },
      {
        "tagId": 157,
        "tagName": "Beach Escape",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 58,
        "tagName": "Beach",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 116,
        "tagName": "Scuba Diving",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 187,
        "tagName": "Island",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 368,
        "venueCategoryId": 15,
        "venueId": 1935,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 15,
          "title": "Beach Paradise",
          "region": "Global",
          "viewOrder": 6,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 530,
        "venueCategoryId": 19,
        "venueId": 1935,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 19,
          "title": "Private Islands",
          "region": "Global",
          "viewOrder": 15,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 531,
        "venueCategoryId": 5,
        "venueId": 1935,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 5,
          "title": "Romantic Getaways",
          "region": "Global",
          "viewOrder": 20,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 678,
        "venueCategoryId": 33,
        "venueId": 1935,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 33,
          "title": "Rustic Elegance",
          "region": "Global",
          "viewOrder": 11,
          "updated": "2020-09-04T09:04:00",
          "created": "2020-09-04T09:04:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1873,
    "venueCategoryId": 0,
    "status": 1,
    "title": "La Ferme de Georges",
    "description": "Neighboring the coastal dunes of northeastern Brazil, La Ferme de Georges beckons those who crave slowed down culinary indulgence, locally inspired design intertwined with modern luxury and stunning natural landscapes.\n\nLa Ferme de Georges covers several acres midway between the sea and the river, at the very edge of the natural park of Lençóis Maranhenses. The 7 chalets can accommodate up to 4 guests (5 in the Park Chalet). La Ferme de Georges can also be fully privatized or in a series of three chalets at a time. It's a great place to escape and to live an exceptional moment \"à la carte\", with a personalized service.",
    "brand": null,
    "blockQuote": "Trekking through the sand dunes is a wonderful experience, but it's even more exciting on horseback or ATV.",
    "emailAddress": "hello@georges.life",
    "website": "georges.life/en/atins/",
    "bookingURL": "http://www.booking.com/Share-6FKjz7t?aid=925669",
    "redirectNotes": null,
    "phoneNumber": "+55 98 91138196",
    "addressOne": "",
    "addressTwo": "",
    "city": "Atins",
    "county": "",
    "region": "",
    "country": "Brazil",
    "rooms": 12,
    "dollar": 2,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 22474,
        "title": "Hotel La Ferme De Georges, Atins, Brazil - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/2daa595d-ed30-4f01-840e-0bd58c74a621/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2daa595d-ed30-4f01-840e-0bd58c74a621/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2daa595d-ed30-4f01-840e-0bd58c74a621/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 22473,
        "title": "La Ferme de Georges | Atins, Lençóis Maranhenses, Brazil",
        "url": "https://pkslist.azureedge.net/media/images/1/09120016-9968-45c6-9de7-b4928cd6bec1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/09120016-9968-45c6-9de7-b4928cd6bec1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/09120016-9968-45c6-9de7-b4928cd6bec1/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 27042,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/0d91b32c-a91c-4634-b7dc-c54f1e0f4fbc/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0d91b32c-a91c-4634-b7dc-c54f1e0f4fbc/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0d91b32c-a91c-4634-b7dc-c54f1e0f4fbc/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 22480,
        "title": "La Ferme de Georges (Atins, Brazil) | Design Hotels™",
        "url": "https://pkslist.azureedge.net/media/images/1/ee45e6c7-b8bc-4adf-8df1-86d7464dc6f2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ee45e6c7-b8bc-4adf-8df1-86d7464dc6f2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ee45e6c7-b8bc-4adf-8df1-86d7464dc6f2/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 22478,
        "title": "La Ferme de Georges (Atins, Brazil) | Design Hotels™",
        "url": "https://pkslist.azureedge.net/media/images/1/07a56b04-599f-49f6-ae03-5acff2f9a527/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/07a56b04-599f-49f6-ae03-5acff2f9a527/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/07a56b04-599f-49f6-ae03-5acff2f9a527/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 22479,
        "title": "La Ferme de Georges (Atins, Brazil) | Design Hotels™",
        "url": "https://pkslist.azureedge.net/media/images/1/d4136ff5-5f37-42bc-a383-05fbc64d3e0c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d4136ff5-5f37-42bc-a383-05fbc64d3e0c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d4136ff5-5f37-42bc-a383-05fbc64d3e0c/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 22482,
        "title": "La Ferme de Georges (Atins, Brazil) | Design Hotels™",
        "url": "https://pkslist.azureedge.net/media/images/1/202d7bb0-1554-49f6-abfa-8e9c5f40fa5c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/202d7bb0-1554-49f6-abfa-8e9c5f40fa5c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/202d7bb0-1554-49f6-abfa-8e9c5f40fa5c/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 22476,
        "title": "Hotel La Ferme De Georges, Atins, Brazil - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/932551ad-9896-405c-acdf-19424f5724d2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/932551ad-9896-405c-acdf-19424f5724d2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/932551ad-9896-405c-acdf-19424f5724d2/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1688,
      "latitude": -2.5722892,
      "longitude": -42.752486999999974,
      "parentGeographicAreaId": 0,
      "title": "La Ferme de Georges, Parque Nacional dos Lençóis Maranhenses, CP 185, Barreirinhas - MA, 65590-000, Brazil",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 75,
        "tagName": "Casual Dining",
        "tagType": 2
      },
      {
        "tagId": 82,
        "tagName": "Horseback Riding",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 152,
        "tagName": "Kite Surfing",
        "tagType": 2
      },
      {
        "tagId": 229,
        "tagName": "Private Balconies",
        "tagType": 2
      },
      {
        "tagId": 252,
        "tagName": "National Park",
        "tagType": 2
      },
      {
        "tagId": 310,
        "tagName": "ATV Touring",
        "tagType": 2
      },
      {
        "tagId": 320,
        "tagName": "Organic Garden",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 649,
        "venueCategoryId": 34,
        "venueId": 1873,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 666,
        "venueCategoryId": 33,
        "venueId": 1873,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 33,
          "title": "Rustic Elegance",
          "region": "Global",
          "viewOrder": 11,
          "updated": "2020-09-04T09:04:00",
          "created": "2020-09-04T09:04:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1136,
    "venueCategoryId": 20,
    "status": 1,
    "title": "Dunton Hot Springs",
    "description": "Dunton Hot Springs is a small and exclusive resort nestled deep in the San Juan Mountains of the Colorado Rockies. This perfectly restored ghost town thrives on contradictions; hand- hewn log cabins exquisitely furnished, a life-worn saloon serving food of startling quality, lung torturing trails followed by pampering massages, sensuous hot springs beneath shimmering snow banks. Free of cell phones, this romantic old mining town still provides high speed wireless internet access in every cabin and video conferencing in our dance hall to the comforting sound of a tumbling waterfall.\n\nThe resort offers a variety of day and overnight packages, and the entire town or ranch can be rented exclusively for corporate retreats, family reunions and weddings.",
    "brand": null,
    "blockQuote": "With such an unbelievably beautiful setting, you just have to get out and appreciate it.",
    "emailAddress": "reservations@duntonlife.com",
    "website": "duntonhotsprings.com/",
    "bookingURL": "http://reservation.worldweb.com/Bookings-nr105/activity-edit.html?table=hotels&listing_id=251&mode=command&command=bookingrequestform&hotel_id=251&location_id=497&flag_return_all=1%20&_ga=2.258778444.794350240.1502796405-965619294.1502796405",
    "redirectNotes": null,
    "phoneNumber": "+1 970 882 4800",
    "addressOne": "52068",
    "addressTwo": "County Road 38",
    "city": "Dolores",
    "county": "",
    "region": "Colorado",
    "country": "United States",
    "rooms": 13,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 11422,
        "title": "Dolores, Colorado near Cortez Dunton Hot Springs, world class ...",
        "url": "https://pkslist.azureedge.net/media/images/1/63383574-28a7-48c8-a227-eb739211bd74/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/63383574-28a7-48c8-a227-eb739211bd74/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/63383574-28a7-48c8-a227-eb739211bd74/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 11424,
        "title": "Dunton Hot Springs | Uncrate",
        "url": "https://pkslist.azureedge.net/media/images/1/5ee5ad3c-3901-40d8-8248-260497a320f3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5ee5ad3c-3901-40d8-8248-260497a320f3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5ee5ad3c-3901-40d8-8248-260497a320f3/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 11426,
        "title": "Flashback Road Trip: Dunton Hot Springs Part 2 - Roadesque",
        "url": "https://pkslist.azureedge.net/media/images/1/374c79c5-ce67-45f7-9919-c7ca43a08e8c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/374c79c5-ce67-45f7-9919-c7ca43a08e8c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/374c79c5-ce67-45f7-9919-c7ca43a08e8c/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 11418,
        "title": "Dunton Hot Springs - Good ol' timey living with free wifi",
        "url": "https://pkslist.azureedge.net/media/images/1/72b7f0f6-d055-4cf4-84e0-e928209ef405/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/72b7f0f6-d055-4cf4-84e0-e928209ef405/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/72b7f0f6-d055-4cf4-84e0-e928209ef405/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 11419,
        "title": "Dunton Hot Springs Resort in Colorado | HiConsumption",
        "url": "https://pkslist.azureedge.net/media/images/1/f0ee080a-abaf-4c05-8c08-7dc921cb25dd/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f0ee080a-abaf-4c05-8c08-7dc921cb25dd/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f0ee080a-abaf-4c05-8c08-7dc921cb25dd/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 11416,
        "title": "Dunton Hot Springs - Cabins & Rates",
        "url": "https://pkslist.azureedge.net/media/images/1/fb5f886d-1b7b-40fe-80ae-38c0f027eae7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fb5f886d-1b7b-40fe-80ae-38c0f027eae7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fb5f886d-1b7b-40fe-80ae-38c0f027eae7/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 11428,
        "title": "Dunton Hot Springs, Dolores, Colorado",
        "url": "https://pkslist.azureedge.net/media/images/1/706ef186-e64b-4d36-92d7-f10dccb42886/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/706ef186-e64b-4d36-92d7-f10dccb42886/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/706ef186-e64b-4d36-92d7-f10dccb42886/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 11429,
        "title": "Dunton Hot Springs - Original Travel - Luxury Hotel Colorado",
        "url": "https://pkslist.azureedge.net/media/images/1/d01fdb85-504f-4622-af26-b22b43f1af29/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d01fdb85-504f-4622-af26-b22b43f1af29/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d01fdb85-504f-4622-af26-b22b43f1af29/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 11417,
        "title": "Dunton Hot Springs - Cabins & Rates",
        "url": "https://pkslist.azureedge.net/media/images/1/e6382b4f-9cba-4032-b3e3-98893e86b2ed/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e6382b4f-9cba-4032-b3e3-98893e86b2ed/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e6382b4f-9cba-4032-b3e3-98893e86b2ed/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 11421,
        "title": "Dunton Hot Springs - Good ol' timey living with free wifi",
        "url": "https://pkslist.azureedge.net/media/images/1/24e8107c-3212-436c-98dc-8ca81b03e5ca/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/24e8107c-3212-436c-98dc-8ca81b03e5ca/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/24e8107c-3212-436c-98dc-8ca81b03e5ca/256x192.jpeg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 961,
      "latitude": 37.7726733,
      "longitude": -108.09372580000002,
      "parentGeographicAreaId": 0,
      "title": "52068 Co Rd 38, Dolores, CO 81323, USA",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 57,
        "tagName": "Romantic",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 61,
        "tagName": "Mountain",
        "tagType": 2
      },
      {
        "tagId": 82,
        "tagName": "Horseback Riding",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 100,
        "tagName": "Wedding",
        "tagType": 2
      },
      {
        "tagId": 109,
        "tagName": "Fishing",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 133,
        "tagName": "Glamping",
        "tagType": 2
      },
      {
        "tagId": 317,
        "tagName": "Gourmet",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 218,
        "venueCategoryId": 20,
        "venueId": 1136,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 20,
          "title": "BucketList Hotels",
          "region": "Global",
          "viewOrder": 0,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 354,
        "venueCategoryId": 5,
        "venueId": 1136,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 5,
          "title": "Romantic Getaways",
          "region": "Global",
          "viewOrder": 20,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 355,
        "venueCategoryId": 1,
        "venueId": 1136,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 1,
          "title": "Escape from the City",
          "region": "Global",
          "viewOrder": 14,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 641,
        "venueCategoryId": 33,
        "venueId": 1136,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 33,
          "title": "Rustic Elegance",
          "region": "Global",
          "viewOrder": 11,
          "updated": "2020-09-04T09:04:00",
          "created": "2020-09-04T09:04:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1017,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Legado Mitico Buenos Aires",
    "description": "If you are looking for a hotel in Buenos Aires, you will find our hotel at the very heart of the mythical neighborhood of Palermo, right in the center of Buenos Aires, and will be instantly inspired with love. Palermo Soho, a perfect blend of bohemia and modernity, was once known as “Palermo Viejo”. Enjoy a strategically placed neighborhood, with its picturesque stone paved streets, which vital force is its avant-garde and eclectic spirit. Visit Legado Mítico and forever stay in Buenos Aires.",
    "brand": null,
    "blockQuote": "This hotel enjoys a great location in Palermo Soho, a state-of-the-art area known for trendy coffee shops and unique designer boutiques.",
    "emailAddress": "reservasbsas@legadomitico.com",
    "website": "legadomitico.com/bsas/index.php",
    "bookingURL": "http://www.booking.com/Share-MpDPuQ?aid=925669",
    "redirectNotes": null,
    "phoneNumber": "+54 11 4833 1300",
    "addressOne": "1848",
    "addressTwo": "Gurruchaga",
    "city": "Buenos Aires",
    "county": "",
    "region": "",
    "country": "Argentina",
    "rooms": 11,
    "dollar": 2,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 26348,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/3f5c0dd8-d2de-4872-a5d7-aee34654d993/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3f5c0dd8-d2de-4872-a5d7-aee34654d993/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3f5c0dd8-d2de-4872-a5d7-aee34654d993/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 26346,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/6011a7ab-0a7f-431d-a00f-2ec47525f27a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6011a7ab-0a7f-431d-a00f-2ec47525f27a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6011a7ab-0a7f-431d-a00f-2ec47525f27a/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 26345,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/fa113f1c-423d-4491-a88f-3c85f6b179ff/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fa113f1c-423d-4491-a88f-3c85f6b179ff/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fa113f1c-423d-4491-a88f-3c85f6b179ff/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 26350,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c670fd9b-38a7-4d67-a0e7-f190d06cb01a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c670fd9b-38a7-4d67-a0e7-f190d06cb01a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c670fd9b-38a7-4d67-a0e7-f190d06cb01a/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 26347,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/9ee87471-fd61-452c-b4e9-e70b0591026f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9ee87471-fd61-452c-b4e9-e70b0591026f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9ee87471-fd61-452c-b4e9-e70b0591026f/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 10076,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/bc85ebc6-1cee-4a40-8e02-8d76f95daa30/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bc85ebc6-1cee-4a40-8e02-8d76f95daa30/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bc85ebc6-1cee-4a40-8e02-8d76f95daa30/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 26349,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/bc827fdc-0485-49f5-96f8-0aa0e56ae00f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bc827fdc-0485-49f5-96f8-0aa0e56ae00f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bc827fdc-0485-49f5-96f8-0aa0e56ae00f/256x192.jpg",
        "viewOrder": 6
      }
    ],
    "geographicArea": {
      "geographicAreaId": 846,
      "latitude": -34.5877264,
      "longitude": -58.426764599999956,
      "parentGeographicAreaId": 0,
      "title": "C1414DIL Soho, Cdad. Autónoma de, Gurruchaga 1848, C1416 CABA, Argentina",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 40,
        "tagName": "Boutique Bolthole ",
        "tagType": 1
      },
      {
        "tagId": 47,
        "tagName": "Intimate Luxury ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 59,
        "tagName": "Cultural",
        "tagType": 2
      },
      {
        "tagId": 62,
        "tagName": "Nightlife",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 68,
        "tagName": "Business",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 131,
        "tagName": "Library",
        "tagType": 2
      },
      {
        "tagId": 172,
        "tagName": "Biking",
        "tagType": 2
      },
      {
        "tagId": 235,
        "tagName": "Hot Tub",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 323,
        "tagName": "City Walk",
        "tagType": 2
      },
      {
        "tagId": 357,
        "tagName": "Sun Terrace",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 670,
        "venueCategoryId": 33,
        "venueId": 1017,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 33,
          "title": "Rustic Elegance",
          "region": "Global",
          "viewOrder": 11,
          "updated": "2020-09-04T09:04:00",
          "created": "2020-09-04T09:04:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1003,
    "venueCategoryId": 9,
    "status": 1,
    "title": "Island Lodge",
    "description": "ISLAND LODGE offers corporate & leisure groups a highly exclusive archipelago experience, a concept designed to be in harmony with the surrounding environment, a mix of genuine ”hardship” but still benefitting from a luxurious and comfortable habitat. The hotel offers you a high quality, sustainable, all-inclusive concept based on an outdoor tented accommodation  - leaving ”green footprints” and memories for a lifetime.\n\nIsland Lodge accommodation is a chapter in itself - all of their 7 special high -tech designed twin bed tents combines modern Scandinavian interior design with nature. All 7 tents are located on selected premium spots on the island with breath-taking views over the archipelago. \n\nThe twin bed tents are spacious (18 m2) and equipped with comfoftable beds and bedding made from the finest luxury linen from Mille Notti.  A wood fired stove keep you warm during chilly days, kerosene lamps and candles  lights up your tent after sunset .\n\nAfter a walk in the forest or a delicious meal you will enjoy relaxing in your tent and look forward to waking up after a good night’s sleep.",
    "brand": null,
    "blockQuote": "Star gaze in your private wood fired hot tub with your loved one, or loved ones since it does fit 10 people.",
    "emailAddress": "info@islandlodge.se",
    "website": "islandlodge.se/Island_Lodge/HOME.html",
    "bookingURL": "http://www.islandlodge.se/Island_Lodge/ACCOMMODATION.html",
    "redirectNotes": null,
    "phoneNumber": "+46 735168090",
    "addressOne": "",
    "addressTwo": "Bergholmen",
    "city": "Stockholm",
    "county": "",
    "region": "",
    "country": "Sweden",
    "rooms": 7,
    "dollar": 2,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 20723,
        "title": "13 private islands you can actually afford to stay on ...",
        "url": "https://pkslist.azureedge.net/media/images/1/25aecf00-7116-44be-8bab-7ef5d7246f5e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/25aecf00-7116-44be-8bab-7ef5d7246f5e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/25aecf00-7116-44be-8bab-7ef5d7246f5e/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 20720,
        "title": "Island Lodge - The Seattle Lesbian",
        "url": "https://pkslist.azureedge.net/media/images/1/4960932d-339a-4740-9212-43d390641142/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4960932d-339a-4740-9212-43d390641142/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4960932d-339a-4740-9212-43d390641142/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 9950,
        "title": "Glamping\" at Island Lodge in the Stockholm Archipelago - Garden ...",
        "url": "https://pkslist.azureedge.net/media/images/1/d14f434a-4c67-4cc2-9dda-4cfb0fea0a7e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d14f434a-4c67-4cc2-9dda-4cfb0fea0a7e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d14f434a-4c67-4cc2-9dda-4cfb0fea0a7e/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 20722,
        "title": "ISLAND LODGE | Easy Travel: Holidays in Finland, Scandinavia and ...",
        "url": "https://pkslist.azureedge.net/media/images/1/c804edbf-8a43-4cf6-b069-0e04809d007d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c804edbf-8a43-4cf6-b069-0e04809d007d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c804edbf-8a43-4cf6-b069-0e04809d007d/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 9954,
        "title": "HOME",
        "url": "https://pkslist.azureedge.net/media/images/1/51c125eb-68cc-420d-aa68-22b880eddfe6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/51c125eb-68cc-420d-aa68-22b880eddfe6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/51c125eb-68cc-420d-aa68-22b880eddfe6/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 9951,
        "title": "Island Lodge - Sweden Holidays 2014 – Tatler Travel Guide 2014 ...",
        "url": "https://pkslist.azureedge.net/media/images/1/e3d4e86d-1cfd-4f4f-8269-a7f7615902c9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e3d4e86d-1cfd-4f4f-8269-a7f7615902c9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e3d4e86d-1cfd-4f4f-8269-a7f7615902c9/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 20721,
        "title": "Glamping\" at Island Lodge in the Stockholm Archipelago",
        "url": "https://pkslist.azureedge.net/media/images/1/5ee7ecdb-f98d-4c0b-a010-91f730f106c8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5ee7ecdb-f98d-4c0b-a010-91f730f106c8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5ee7ecdb-f98d-4c0b-a010-91f730f106c8/256x192.jpg",
        "viewOrder": 6
      }
    ],
    "geographicArea": {
      "geographicAreaId": 832,
      "latitude": 59.36990909999999,
      "longitude": 18.37915399999997,
      "parentGeographicAreaId": 0,
      "title": "Bergholmen, 185 92 Vaxholm, Sweden",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 50,
        "tagName": "Barefoot Luxury",
        "tagType": 1
      },
      {
        "tagId": 57,
        "tagName": "Romantic",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 59,
        "tagName": "Cultural",
        "tagType": 2
      },
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 75,
        "tagName": "Casual Dining",
        "tagType": 2
      },
      {
        "tagId": 102,
        "tagName": "Eco-friendly",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 133,
        "tagName": "Glamping",
        "tagType": 2
      },
      {
        "tagId": 134,
        "tagName": "Wilderness Walks",
        "tagType": 2
      },
      {
        "tagId": 170,
        "tagName": "Kayaking",
        "tagType": 2
      },
      {
        "tagId": 171,
        "tagName": "Trekking",
        "tagType": 2
      },
      {
        "tagId": 198,
        "tagName": "Boat Excursion",
        "tagType": 2
      },
      {
        "tagId": 206,
        "tagName": "Private Beach",
        "tagType": 2
      },
      {
        "tagId": 226,
        "tagName": "Outdoor Fireplace",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 187,
        "venueCategoryId": 9,
        "venueId": 1003,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 9,
          "title": "Scandinavian Break",
          "region": "Northern Europe",
          "viewOrder": 21,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 674,
        "venueCategoryId": 33,
        "venueId": 1003,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 33,
          "title": "Rustic Elegance",
          "region": "Global",
          "viewOrder": 11,
          "updated": "2020-09-04T09:04:00",
          "created": "2020-09-04T09:04:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 985,
    "venueCategoryId": 9,
    "status": 1,
    "title": "Treehotel",
    "description": "Even though you might be a world away from home, at the Treehotel we welcome you like family. Let us take you away from the stresses and demands of everyday life.\n\nLiving up among the tall trees in the pine forest of Northern Sweden has never been so easy. Gaze out at Sweden’s spectacular nature from up in the Mirrorcube or the UFO. Watch the Northern Lights illuminate the sky above you and your snowmobile, or take a family fishing trip and learn all about the berries you pick up along the way. One thing’s for sure at the Treehotel, you’ll be inspired by your surroundings and experiences.\n\nWhether you’re looking for an adventure, a relaxing break or simply to wind down in nature, Treehotel invites you to experience nature, and our restaurant and surroundings, in the most unique and memorable way you can imagine.",
    "brand": "",
    "blockQuote": "For an experience that is totally unique and out of this world!",
    "emailAddress": "booking@treehotel.se",
    "website": "treehotel.se/en/",
    "bookingURL": "http://www.booking.com/Share-cHXhaJ?aid=925669",
    "redirectNotes": null,
    "phoneNumber": "+46 928 103 00",
    "addressOne": "2A",
    "addressTwo": "Edeforsvägen",
    "city": "Harads",
    "county": "",
    "region": "",
    "country": "Sweden",
    "rooms": 13,
    "dollar": 2,
    "updated": "2020-09-29T09:05:54.617",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 40197,
        "title": "Treehotel, Harads, Sweden - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/07961a21-8f8f-4a8c-acff-0a7607ccfc52/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/07961a21-8f8f-4a8c-acff-0a7607ccfc52/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/07961a21-8f8f-4a8c-acff-0a7607ccfc52/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 40194,
        "title": "Treehotel, Swedish Lapland | Visit Sweden",
        "url": "https://pkslist.azureedge.net/media/images/1/af8957eb-41ab-4e1a-8fba-7cdbb46df7dd/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/af8957eb-41ab-4e1a-8fba-7cdbb46df7dd/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/af8957eb-41ab-4e1a-8fba-7cdbb46df7dd/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 40202,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a6d7e2cb-ab1f-4085-94cf-2f1161b2213f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a6d7e2cb-ab1f-4085-94cf-2f1161b2213f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a6d7e2cb-ab1f-4085-94cf-2f1161b2213f/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 40210,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/38a5187a-8649-4d20-8bc8-b7523030ca33/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/38a5187a-8649-4d20-8bc8-b7523030ca33/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/38a5187a-8649-4d20-8bc8-b7523030ca33/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 40207,
        "title": "Treehotel, Swedish Lapland | Visit Sweden",
        "url": "https://pkslist.azureedge.net/media/images/1/028176c2-b32b-419a-ab57-707c53b0620a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/028176c2-b32b-419a-ab57-707c53b0620a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/028176c2-b32b-419a-ab57-707c53b0620a/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 9984,
        "title": "The 7th Room: The Newest Part of Sweden's Treehotel",
        "url": "https://pkslist.azureedge.net/media/images/1/4bac8e10-1ce6-4e8b-9215-f0355a08e2cd/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4bac8e10-1ce6-4e8b-9215-f0355a08e2cd/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4bac8e10-1ce6-4e8b-9215-f0355a08e2cd/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 40198,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/6ce271f1-9e5d-4b15-afec-52eb7414bbbf/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6ce271f1-9e5d-4b15-afec-52eb7414bbbf/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6ce271f1-9e5d-4b15-afec-52eb7414bbbf/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 40195,
        "title": "Treehotel – A night among the trees",
        "url": "https://pkslist.azureedge.net/media/images/1/2835d9c2-b13c-4103-993d-c57cca7b69dd/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2835d9c2-b13c-4103-993d-c57cca7b69dd/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2835d9c2-b13c-4103-993d-c57cca7b69dd/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 40199,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/0412a1e9-0553-444d-b830-a3896a192bb3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0412a1e9-0553-444d-b830-a3896a192bb3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0412a1e9-0553-444d-b830-a3896a192bb3/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 40200,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/755f35e7-bc11-4fa1-a76d-e76489ca0dac/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/755f35e7-bc11-4fa1-a76d-e76489ca0dac/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/755f35e7-bc11-4fa1-a76d-e76489ca0dac/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 9983,
        "title": "TreeHotel – HipExplore Inc.®",
        "url": "https://pkslist.azureedge.net/media/images/1/f0cf8ee1-04d4-4631-8187-3b5c71a018f1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f0cf8ee1-04d4-4631-8187-3b5c71a018f1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f0cf8ee1-04d4-4631-8187-3b5c71a018f1/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 40203,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f35c5d7b-e9ea-4493-88ec-a10cedc06ef6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f35c5d7b-e9ea-4493-88ec-a10cedc06ef6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f35c5d7b-e9ea-4493-88ec-a10cedc06ef6/256x192.jpg",
        "viewOrder": 11
      },
      {
        "mediaId": 9968,
        "title": "Treehotel.se - The Cabin",
        "url": "https://pkslist.azureedge.net/media/images/1/1ba1c8c3-9232-4cab-b3dc-3b6aac7e2ac1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1ba1c8c3-9232-4cab-b3dc-3b6aac7e2ac1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1ba1c8c3-9232-4cab-b3dc-3b6aac7e2ac1/256x192.jpg",
        "viewOrder": 12
      }
    ],
    "geographicArea": {
      "geographicAreaId": 814,
      "latitude": 66.0728651,
      "longitude": 20.98163569999997,
      "parentGeographicAreaId": 0,
      "title": "Edeforsvägen 2A, 960 24 Harads, Sweden",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 53,
        "tagName": "Cutting Edge",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 66,
        "tagName": "Skiing",
        "tagType": 2
      },
      {
        "tagId": 82,
        "tagName": "Horseback Riding",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 134,
        "tagName": "Wilderness Walks",
        "tagType": 2
      },
      {
        "tagId": 159,
        "tagName": "Mountain Biking",
        "tagType": 2
      },
      {
        "tagId": 170,
        "tagName": "Kayaking",
        "tagType": 2
      },
      {
        "tagId": 203,
        "tagName": "Sauna",
        "tagType": 2
      },
      {
        "tagId": 233,
        "tagName": "White Water Rafting",
        "tagType": 2
      },
      {
        "tagId": 327,
        "tagName": "Snowmobiles",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 180,
        "venueCategoryId": 9,
        "venueId": 985,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 9,
          "title": "Scandinavian Break",
          "region": "Northern Europe",
          "viewOrder": 21,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 673,
        "venueCategoryId": 33,
        "venueId": 985,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 33,
          "title": "Rustic Elegance",
          "region": "Global",
          "viewOrder": 11,
          "updated": "2020-09-04T09:04:00",
          "created": "2020-09-04T09:04:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 983,
    "venueCategoryId": 20,
    "status": 1,
    "title": "UXUA Casa Hotel & Spa",
    "description": "Authenticity is the greatest luxury a visit to Brazil's picturesque and historic Bahia state can offer. And no place delivers the experience like Trancoso's Quadrado, its car-free, UNESCO-protected town square. \n\nHalf UXUA's casas here date back 500-years to the village's founding. They were restored by designer Wilbert Das in collaboration with local artisans using traditional techniques and reclaimed materials.\nThese casas have a soul, each with legends and family history, every detail uniquely crafted by caring hands.\n\nUXUA's rustic beach lounge, Almescar Spa, Quadrado restaurant and 5-star service complete the perfect holiday.",
    "brand": null,
    "blockQuote": "Once you've slipped on your house Havaianas, they're never coming off!",
    "emailAddress": "info@uxua.com",
    "website": "uxua.com/",
    "bookingURL": "http://www.booking.com/Share-S8NH8G?aid=925669",
    "redirectNotes": null,
    "phoneNumber": "+55 73 3668 2277",
    "addressOne": "",
    "addressTwo": "",
    "city": "Trancoso",
    "county": "",
    "region": "",
    "country": "Brazil",
    "rooms": 12,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 9688,
        "title": "Uxua Casa Hotel, Trancoso, Brazil | The Gold Standard 2013 | Award ...",
        "url": "https://pkslist.azureedge.net/media/images/1/2bf12a0e-0c67-4b89-b9a0-42fb1639efd6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2bf12a0e-0c67-4b89-b9a0-42fb1639efd6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2bf12a0e-0c67-4b89-b9a0-42fb1639efd6/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 26396,
        "title": "UXUA Casa Hotel & Spa, Trancoso, Bahia",
        "url": "https://pkslist.azureedge.net/media/images/1/e3927820-6b01-4ef6-bee3-0e4578ac0aae/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e3927820-6b01-4ef6-bee3-0e4578ac0aae/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e3927820-6b01-4ef6-bee3-0e4578ac0aae/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 26395,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/fc2d924d-876b-4575-9cb0-8a80cac2c744/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fc2d924d-876b-4575-9cb0-8a80cac2c744/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fc2d924d-876b-4575-9cb0-8a80cac2c744/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 26389,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/7d045d97-1e2e-4713-bb35-f92d24118671/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7d045d97-1e2e-4713-bb35-f92d24118671/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7d045d97-1e2e-4713-bb35-f92d24118671/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 26393,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e31f42b7-bf96-4621-ad01-547158d87dad/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e31f42b7-bf96-4621-ad01-547158d87dad/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e31f42b7-bf96-4621-ad01-547158d87dad/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 26392,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/17ab5448-04f1-4f17-afd3-b56adae8e6e5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/17ab5448-04f1-4f17-afd3-b56adae8e6e5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/17ab5448-04f1-4f17-afd3-b56adae8e6e5/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 26391,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/9af5644d-3668-4a96-81ac-48df38a99be5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9af5644d-3668-4a96-81ac-48df38a99be5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9af5644d-3668-4a96-81ac-48df38a99be5/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 9702,
        "title": "Hotel Spotlight: Uxua Casa Hotel & Spa, Brazil",
        "url": "https://pkslist.azureedge.net/media/images/1/dcf1dcdf-0776-42d3-8b27-7b13781435db/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/dcf1dcdf-0776-42d3-8b27-7b13781435db/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/dcf1dcdf-0776-42d3-8b27-7b13781435db/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 9687,
        "title": "It's a jungle in here, at the Uxua Casa Hotel on Brazil's Bahian ...",
        "url": "https://pkslist.azureedge.net/media/images/1/d8a4ca27-51f2-4362-8ef8-461b59c90710/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d8a4ca27-51f2-4362-8ef8-461b59c90710/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d8a4ca27-51f2-4362-8ef8-461b59c90710/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 26399,
        "title": "Uxua Casa Hotel & Spa, Trancoso | A Kuoni Hotel in Salvador & Bahia",
        "url": "https://pkslist.azureedge.net/media/images/1/1bbeeba9-0f3a-4c56-8ac5-934c35573b3a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1bbeeba9-0f3a-4c56-8ac5-934c35573b3a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1bbeeba9-0f3a-4c56-8ac5-934c35573b3a/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 9701,
        "title": "Uxua Casa Hotel & Spa | Book online | Bed & Breakfast Europe",
        "url": "https://pkslist.azureedge.net/media/images/1/e84d2b92-bca3-4ecd-b89f-00eb695b8b6b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e84d2b92-bca3-4ecd-b89f-00eb695b8b6b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e84d2b92-bca3-4ecd-b89f-00eb695b8b6b/256x192.jpg",
        "viewOrder": 11
      }
    ],
    "geographicArea": {
      "geographicAreaId": 812,
      "latitude": -16.589496,
      "longitude": -39.094224,
      "parentGeographicAreaId": 0,
      "title": "Porto Seguro - State of Bahia, Brazil",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 50,
        "tagName": "Barefoot Luxury",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 58,
        "tagName": "Beach",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 122,
        "tagName": "Surfing",
        "tagType": 2
      },
      {
        "tagId": 152,
        "tagName": "Kite Surfing",
        "tagType": 2
      },
      {
        "tagId": 170,
        "tagName": "Kayaking",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 276,
        "tagName": "Gym",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 324,
        "tagName": "Local Village",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 178,
        "venueCategoryId": 20,
        "venueId": 983,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 20,
          "title": "BucketList Hotels",
          "region": "Global",
          "viewOrder": 0,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 351,
        "venueCategoryId": 15,
        "venueId": 983,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 15,
          "title": "Beach Paradise",
          "region": "Global",
          "viewOrder": 6,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 642,
        "venueCategoryId": 33,
        "venueId": 983,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 33,
          "title": "Rustic Elegance",
          "region": "Global",
          "viewOrder": 11,
          "updated": "2020-09-04T09:04:00",
          "created": "2020-09-04T09:04:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 956,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Domaine de Murtoli",
    "description": "Located in the South of Corsica, between Sartene and Bonifacio, lays a territory of 2500 hectares wide, with a preserved coastline of 8 kms long, uniting the sea, a river, beaches, dunes, maquis, plains, hills, forests, and mountains. The Domaine de Murtoli is a unique holiday destination offering 19 authentic sheepfolds from the 17th century within wide-open spaces, breathtaking landscapes, and access to all of your favorite sports and hobbies. In this spectacular site, it is possible in one day to fish in the sea and in a river, go hunting, water fowling, horse-riding, play golf and even having a meeting in our seminar room. Everything that you've dreamed of is here. You'll discover pristine nature, the heady scent of paradise. Blissful moments that will last an eternity begin here.",
    "brand": "",
    "blockQuote": "Dinner in the grotto is essential but you have to book it in advance.",
    "emailAddress": "villas@murtoli.com",
    "website": "murtoli.com",
    "bookingURL": "contact@murtoli.com",
    "redirectNotes": "Domaine de Murtoli's reservations system",
    "phoneNumber": "+33 4 95 71 69 24",
    "addressOne": "",
    "addressTwo": "Vallée de l'Ortolo",
    "city": "Sartene",
    "county": "",
    "region": "Corsica",
    "country": "France",
    "rooms": 19,
    "dollar": 3,
    "updated": "2021-08-10T06:48:24.377",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 9193,
        "title": "Luxury hotel, Domaine de Murtoli, Corsica , France - Luxury Dream ...",
        "url": "https://pkslist.azureedge.net/media/images/1/53e0e1d6-2934-4fe1-8ecf-9e88347f8eef/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/53e0e1d6-2934-4fe1-8ecf-9e88347f8eef/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/53e0e1d6-2934-4fe1-8ecf-9e88347f8eef/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 9192,
        "title": "Domaine de Murtoli | Traveller Made",
        "url": "https://pkslist.azureedge.net/media/images/1/8d9834ad-8edd-4d5a-ae91-edb7e5c2fcf0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8d9834ad-8edd-4d5a-ae91-edb7e5c2fcf0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8d9834ad-8edd-4d5a-ae91-edb7e5c2fcf0/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 9205,
        "title": "Domaine de Murtoli (Corsica, France)",
        "url": "https://pkslist.azureedge.net/media/images/1/fd1bdfff-3dbe-4fb8-a7ca-f0f36bd428b7/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fd1bdfff-3dbe-4fb8-a7ca-f0f36bd428b7/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fd1bdfff-3dbe-4fb8-a7ca-f0f36bd428b7/256x192.png",
        "viewOrder": 2
      },
      {
        "mediaId": 9198,
        "title": "Domaine de Murtoli | Traveller Made",
        "url": "https://pkslist.azureedge.net/media/images/1/cc392170-ea03-4eed-8612-36f623e2ccb6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cc392170-ea03-4eed-8612-36f623e2ccb6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cc392170-ea03-4eed-8612-36f623e2ccb6/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 9206,
        "title": "la plage - Picture of Domaine de Murtoli, Sartene - TripAdvisor",
        "url": "https://pkslist.azureedge.net/media/images/1/774a13ef-4c20-441c-951e-24c62baeecf9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/774a13ef-4c20-441c-951e-24c62baeecf9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/774a13ef-4c20-441c-951e-24c62baeecf9/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 9194,
        "title": "Domaine de Murtoli | Traveller Made",
        "url": "https://pkslist.azureedge.net/media/images/1/cff760fc-32a8-438d-8e1d-e8b8eb3bada4/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cff760fc-32a8-438d-8e1d-e8b8eb3bada4/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cff760fc-32a8-438d-8e1d-e8b8eb3bada4/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 9199,
        "title": "Domaine de Murtoli | Luxury Hotel in Corsica France",
        "url": "https://pkslist.azureedge.net/media/images/1/a3a5f51a-f77c-4b6f-8ecf-92695d9d8f28/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a3a5f51a-f77c-4b6f-8ecf-92695d9d8f28/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a3a5f51a-f77c-4b6f-8ecf-92695d9d8f28/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 9202,
        "title": "Domaine de Murtoli | Traveller Made",
        "url": "https://pkslist.azureedge.net/media/images/1/a389eb1c-3d1e-4dcf-985a-122c866a8278/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a389eb1c-3d1e-4dcf-985a-122c866a8278/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a389eb1c-3d1e-4dcf-985a-122c866a8278/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 9203,
        "title": "Eddera - Picture of Domaine de Murtoli, Sartene - TripAdvisor",
        "url": "https://pkslist.azureedge.net/media/images/1/64a0cf20-0faf-4d27-a609-fa8e308e615b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/64a0cf20-0faf-4d27-a609-fa8e308e615b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/64a0cf20-0faf-4d27-a609-fa8e308e615b/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 9208,
        "title": "la chambre - Picture of Domaine de Murtoli, Sartene - TripAdvisor",
        "url": "https://pkslist.azureedge.net/media/images/1/0751b6df-a1c8-49f7-bb1c-6e7b57cf77f5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0751b6df-a1c8-49f7-bb1c-6e7b57cf77f5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0751b6df-a1c8-49f7-bb1c-6e7b57cf77f5/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 9209,
        "title": "Domaine de Murtoli | Luxury Hotel in Corsica France",
        "url": "https://pkslist.azureedge.net/media/images/1/8822e1ad-adfc-4922-8c07-900267fd73be/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8822e1ad-adfc-4922-8c07-900267fd73be/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8822e1ad-adfc-4922-8c07-900267fd73be/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 9207,
        "title": "La plage - Picture of Domaine de Murtoli, Sartene - TripAdvisor",
        "url": "https://pkslist.azureedge.net/media/images/1/43016ec0-97f5-4bc6-a928-37e393b936dc/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/43016ec0-97f5-4bc6-a928-37e393b936dc/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/43016ec0-97f5-4bc6-a928-37e393b936dc/256x192.jpg",
        "viewOrder": 11
      },
      {
        "mediaId": 9196,
        "title": "Luxury Hotel Sartene, Corsica : Domaine de Murtoli",
        "url": "https://pkslist.azureedge.net/media/images/1/b67d71f6-78be-4862-99da-40e3b0d6f490/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b67d71f6-78be-4862-99da-40e3b0d6f490/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b67d71f6-78be-4862-99da-40e3b0d6f490/256x192.jpg",
        "viewOrder": 12
      }
    ],
    "geographicArea": {
      "geographicAreaId": 783,
      "latitude": 41.51613699999999,
      "longitude": 8.948873999999932,
      "parentGeographicAreaId": 0,
      "title": "Vallée de l'Ortolo, 20100 Sartène, France",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 45,
        "tagName": "Historic Sense",
        "tagType": 1
      },
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 58,
        "tagName": "Beach",
        "tagType": 2
      },
      {
        "tagId": 61,
        "tagName": "Mountain",
        "tagType": 2
      },
      {
        "tagId": 81,
        "tagName": "Golf",
        "tagType": 2
      },
      {
        "tagId": 82,
        "tagName": "Horseback Riding",
        "tagType": 2
      },
      {
        "tagId": 109,
        "tagName": "Fishing",
        "tagType": 2
      },
      {
        "tagId": 116,
        "tagName": "Scuba Diving",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 134,
        "tagName": "Wilderness Walks",
        "tagType": 2
      },
      {
        "tagId": 172,
        "tagName": "Biking",
        "tagType": 2
      },
      {
        "tagId": 221,
        "tagName": "Wine Tasting",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 675,
        "venueCategoryId": 33,
        "venueId": 956,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 33,
          "title": "Rustic Elegance",
          "region": "Global",
          "viewOrder": 11,
          "updated": "2020-09-04T09:04:00",
          "created": "2020-09-04T09:04:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 811,
    "venueCategoryId": 5,
    "status": 1,
    "title": "Song Saa Private Island",
    "description": "Created with sustainability in mind, the resort reflects the beauty of the Cambodian coast. It’s a place that breathes in time with nature, built from local materials, by local hands, and infused with local art and soul. Welcome to a luxury island experience with everything you need right at your fingertips. Spacious private pool villas, gourmet dining, snorkelling and diving, spa therapies, wellness activities, nature tours, cultural trips…and so much more.\n\nSong Saa Private Island offers 24 spacious luxury villas, each featuring a private pool and spectacular island and ocean views. Each villa was constructed from recycled and reclaimed timber and decorated with repurposed items and local materials in keeping with our philosophy of sustainability.",
    "brand": null,
    "blockQuote": "To be here is to detach from the world and tune into a time that is true and simple.",
    "emailAddress": "info@songsaa.com",
    "website": "songsaa.com",
    "bookingURL": "https://reservations.travelclick.com/96839?locale=EN&iata=96027890&Adults=2&languageid=1&HotelID=96839&Children=0&Rooms=1&Length=1#/datesofstay",
    "redirectNotes": "Song Saa Private Island's reservations system",
    "phoneNumber": "+852 2343 2388",
    "addressOne": "",
    "addressTwo": "",
    "city": "Koh Rong Archipelago",
    "county": "",
    "region": "",
    "country": "Cambodia",
    "rooms": 24,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 17770,
        "title": "Ejendomme til salg - 2 Bedroom Jungle Villa on Song Saa Private ...",
        "url": "https://pkslist.azureedge.net/media/images/1/d8a5e256-9dd6-4263-b909-26ae233d8dc9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d8a5e256-9dd6-4263-b909-26ae233d8dc9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d8a5e256-9dd6-4263-b909-26ae233d8dc9/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 7301,
        "title": "Song Saa Private Island | Sihanoukville | Audley Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/3eff91a0-2303-4ccf-adb7-26171a0041b9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3eff91a0-2303-4ccf-adb7-26171a0041b9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3eff91a0-2303-4ccf-adb7-26171a0041b9/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 17771,
        "title": "Song Saa Private Island | Luxury Hotel in Coastal Resorts Cambodia",
        "url": "https://pkslist.azureedge.net/media/images/1/0b7275c5-a72d-4d77-b744-f9efd59c4dcd/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0b7275c5-a72d-4d77-b744-f9efd59c4dcd/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0b7275c5-a72d-4d77-b744-f9efd59c4dcd/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 7303,
        "title": "Luxury and tailor-made holidays to Song Saa Private Island ...",
        "url": "https://pkslist.azureedge.net/media/images/1/29a3d48c-c6c8-454d-9772-cd5d97970c83/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/29a3d48c-c6c8-454d-9772-cd5d97970c83/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/29a3d48c-c6c8-454d-9772-cd5d97970c83/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 7307,
        "title": "Welcome to Cambodia – Holiday in a private Paradise | It's Kat!",
        "url": "https://pkslist.azureedge.net/media/images/1/33ef56dc-0c80-4ddf-b800-09c776dd55f7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/33ef56dc-0c80-4ddf-b800-09c776dd55f7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/33ef56dc-0c80-4ddf-b800-09c776dd55f7/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 7305,
        "title": "Song Saa Private Island in Koh Ouen - Hotels.com",
        "url": "https://pkslist.azureedge.net/media/images/1/914365c6-2c09-4f0d-b65f-867b04c24f9b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/914365c6-2c09-4f0d-b65f-867b04c24f9b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/914365c6-2c09-4f0d-b65f-867b04c24f9b/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 7306,
        "title": "Resort Song Saa Private Island, Cambodia - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/29f11521-c56d-4029-8563-682af0479bfc/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/29f11521-c56d-4029-8563-682af0479bfc/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/29f11521-c56d-4029-8563-682af0479bfc/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 7300,
        "title": "The story behind Song Saa, Cambodia's first private island resort ...",
        "url": "https://pkslist.azureedge.net/media/images/1/3193ae87-c4ee-4cb8-8fa0-b1a94b43bb46/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3193ae87-c4ee-4cb8-8fa0-b1a94b43bb46/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3193ae87-c4ee-4cb8-8fa0-b1a94b43bb46/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 18706,
        "title": "Private Islands for rent - Song Saa - Cambodia - Pacific Ocean",
        "url": "https://pkslist.azureedge.net/media/images/1/22683bb5-3d7f-42ed-992a-d5deea737dbb/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/22683bb5-3d7f-42ed-992a-d5deea737dbb/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/22683bb5-3d7f-42ed-992a-d5deea737dbb/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 634,
      "latitude": 10.755927,
      "longitude": 103.26222200000007,
      "parentGeographicAreaId": 0,
      "title": "Koh Ouen Private Island, Koh Rong Archipelago, Near Sihanoukville, Cambodia",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 50,
        "tagName": "Barefoot Luxury",
        "tagType": 1
      },
      {
        "tagId": 157,
        "tagName": "Beach Escape",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 181,
        "tagName": "Private/Suite Pools",
        "tagType": 2
      },
      {
        "tagId": 187,
        "tagName": "Island",
        "tagType": 2
      },
      {
        "tagId": 193,
        "tagName": "Outdoor Dining",
        "tagType": 2
      },
      {
        "tagId": 206,
        "tagName": "Private Beach",
        "tagType": 2
      },
      {
        "tagId": 234,
        "tagName": "Sailing",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 139,
        "venueCategoryId": 5,
        "venueId": 811,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 5,
          "title": "Romantic Getaways",
          "region": "Global",
          "viewOrder": 20,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 664,
        "venueCategoryId": 33,
        "venueId": 811,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 33,
          "title": "Rustic Elegance",
          "region": "Global",
          "viewOrder": 11,
          "updated": "2020-09-04T09:04:00",
          "created": "2020-09-04T09:04:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 781,
    "venueCategoryId": 15,
    "status": 1,
    "title": "Morgan's Rock Hacienda & Ecolodge",
    "description": "Exotic rain forest surroundings, private sandy beach and bay, secluded location and large open spaces makes Morgan's Rock an exceptional place that brings guests peace, harmony and relaxation of the body and mind. Located on a 4,000 acre expanse of Nicaraguan jungle, nearly half of Morgan’s Rock is a private protected reserve. The Lodge’s concept and operation reflects this whimsical feeling.   \n\nThe ecolodge is a 15-bungalow and 3 Ocean View Two Bedroom Villas with Pool hideaway on a mile-long picturesque sandy beach, frequented year-round by nesting sea turtles. \n\nWith endless activities, tours, plenty of safe space to explore, and astounding wildlife, organic farm and a world class cullinary experience, Morgan’s Rock offers the ultimate family vacation. The restaurant features local food from their organic farm.",
    "brand": null,
    "blockQuote": "Horseback riding up to the lookout point and then down to the beach was a highlight.",
    "emailAddress": "reservations@morgansrock.com",
    "website": "morgansrock.com/",
    "bookingURL": "https://www.booking.com/hotel/ni/hacienda-amp-ecolodge-morgan-s-rock.en-gb.html?aid=925669;label=gog235jc-1DCAsoqAFCI2hhY2llbmRhLWFtcC1lY29sb2RnZS1tb3JnYW4tcy1yb2NrSDNYA2iwAYgBAZgBCbgBGMgBDNgBA-gBAYgCAagCBLgCyPr25QXAAgE;dist=0&group_adults=2&keep_landing=1&sb_price_type=total&type=total&",
    "redirectNotes": null,
    "phoneNumber": "+505 7833 7600",
    "addressOne": "",
    "addressTwo": "",
    "city": "San Juan del Sur",
    "county": "",
    "region": "",
    "country": "Nicaragua",
    "rooms": 18,
    "dollar": 2,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 11944,
        "title": "The Ecolodge - Morgans Rock Ecolodge",
        "url": "https://pkslist.azureedge.net/media/images/1/b4d28ed2-f5da-4d60-8f87-dc7d6368e92b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b4d28ed2-f5da-4d60-8f87-dc7d6368e92b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b4d28ed2-f5da-4d60-8f87-dc7d6368e92b/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 29530,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/5e49c9f7-6621-456b-bc31-dc95c6d6db10/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5e49c9f7-6621-456b-bc31-dc95c6d6db10/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5e49c9f7-6621-456b-bc31-dc95c6d6db10/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 29532,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/cbd4758b-2b37-4509-9c9b-d8f2e80554ed/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cbd4758b-2b37-4509-9c9b-d8f2e80554ed/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cbd4758b-2b37-4509-9c9b-d8f2e80554ed/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 29531,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/2094da47-5422-4b36-93e2-c31bf2c20eaa/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2094da47-5422-4b36-93e2-c31bf2c20eaa/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2094da47-5422-4b36-93e2-c31bf2c20eaa/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 29533,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/5dbedc50-42f9-4e35-9e3a-b4ed260368ef/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5dbedc50-42f9-4e35-9e3a-b4ed260368ef/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5dbedc50-42f9-4e35-9e3a-b4ed260368ef/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 11947,
        "title": "Morgan's Rock Hacienda and Eco Lodge | Audley Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/0a695b1c-35b8-477d-b5ab-22d2125da2b5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0a695b1c-35b8-477d-b5ab-22d2125da2b5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0a695b1c-35b8-477d-b5ab-22d2125da2b5/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 6753,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d0e3fb0c-a24c-4350-988f-969c8c0d7e5a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d0e3fb0c-a24c-4350-988f-969c8c0d7e5a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d0e3fb0c-a24c-4350-988f-969c8c0d7e5a/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 6759,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ff3ae736-2b7c-4a13-ba01-fd2d134695f5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ff3ae736-2b7c-4a13-ba01-fd2d134695f5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ff3ae736-2b7c-4a13-ba01-fd2d134695f5/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 29529,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c9db279c-a310-4aa3-b127-3e12f3fc3fd1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c9db279c-a310-4aa3-b127-3e12f3fc3fd1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c9db279c-a310-4aa3-b127-3e12f3fc3fd1/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 604,
      "latitude": 11.306296,
      "longitude": -85.91931299999999,
      "parentGeographicAreaId": 0,
      "title": "San Juan del Sur, Nicaragua",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 157,
        "tagName": "Beach Escape",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 70,
        "tagName": "Families",
        "tagType": 2
      },
      {
        "tagId": 82,
        "tagName": "Horseback Riding",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 102,
        "tagName": "Eco-friendly",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 134,
        "tagName": "Wilderness Walks",
        "tagType": 2
      },
      {
        "tagId": 164,
        "tagName": "Excursions",
        "tagType": 2
      },
      {
        "tagId": 181,
        "tagName": "Private/Suite Pools",
        "tagType": 2
      },
      {
        "tagId": 193,
        "tagName": "Outdoor Dining",
        "tagType": 2
      },
      {
        "tagId": 206,
        "tagName": "Private Beach",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 134,
        "venueCategoryId": 15,
        "venueId": 781,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 15,
          "title": "Beach Paradise",
          "region": "Global",
          "viewOrder": 6,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 447,
        "venueCategoryId": 20,
        "venueId": 781,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 20,
          "title": "BucketList Hotels",
          "region": "Global",
          "viewOrder": 0,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 643,
        "venueCategoryId": 33,
        "venueId": 781,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 33,
          "title": "Rustic Elegance",
          "region": "Global",
          "viewOrder": 11,
          "updated": "2020-09-04T09:04:00",
          "created": "2020-09-04T09:04:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 611,
    "venueCategoryId": 1,
    "status": 1,
    "title": "Soho Farmhouse",
    "description": "A member’s club in 100 acres of Oxfordshire countryside with 40 cabins, a four-bedroom cottage and a seven-bedroom farm house. Club spaces include the Main Barn and Mill Room for eating and drinking, a Boathouse with outdoor and indoor heated pools, Cowshed Relax spa with Steam and Sauna Island, and Cowshed Active with gym and studio. There is also an Electric Barn cinema, tennis courts, a five-a-side football pitch, horse stables, and a Teeny Barn and Camp.",
    "brand": "Soho House",
    "blockQuote": "Book the man in a van to come cook your full English breakfast right outside your front door.",
    "emailAddress": "sohofarmhouse.enquiries@sohohouse.com",
    "website": "sohofarmhouse.com/",
    "bookingURL": "https://www.sohofarmhouse.com/reservation-enquiries?arrive=&depart=&jump=1&adult=2&locale=en-GB&start=calendar&flexible=on",
    "redirectNotes": null,
    "phoneNumber": "+44 1608 691000",
    "addressOne": "1",
    "addressTwo": "Tracey Farm Cottages",
    "city": "Great Tew",
    "county": "Cotswolds",
    "region": "England",
    "country": "United Kingdom",
    "rooms": 40,
    "dollar": 2,
    "updated": "2020-05-26T10:40:00.153",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 5022,
        "title": "The Soho Farmhouse | Serendipia",
        "url": "https://pkslist.azureedge.net/media/images/1/0457fcdb-ad36-49a5-be05-f1cf23d01bde/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0457fcdb-ad36-49a5-be05-f1cf23d01bde/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0457fcdb-ad36-49a5-be05-f1cf23d01bde/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 5039,
        "title": "Travel to: Soho Farmhouse | Olivia Palermo",
        "url": "https://pkslist.azureedge.net/media/images/1/57d7f2d9-4050-4bac-9b37-be261d4a5353/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/57d7f2d9-4050-4bac-9b37-be261d4a5353/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/57d7f2d9-4050-4bac-9b37-be261d4a5353/256x192.png",
        "viewOrder": 1
      },
      {
        "mediaId": 5023,
        "title": "A Look Inside the Soho Farmhouse in Oxfordshire — urdesignmag",
        "url": "https://pkslist.azureedge.net/media/images/1/c6c21e9d-b29e-4734-af96-05c9dc6b13b8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c6c21e9d-b29e-4734-af96-05c9dc6b13b8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c6c21e9d-b29e-4734-af96-05c9dc6b13b8/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 5029,
        "title": "Soho Farmhouse: A Proper English Rural Retreat - WSJ",
        "url": "https://pkslist.azureedge.net/media/images/1/fa0706df-973c-4836-8114-043a9ca98090/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fa0706df-973c-4836-8114-043a9ca98090/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fa0706df-973c-4836-8114-043a9ca98090/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 5033,
        "title": "Soho Farmhouse - Gessato Blog",
        "url": "https://pkslist.azureedge.net/media/images/1/d3a2a38e-e2a3-4c29-ae40-2071855b2aeb/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d3a2a38e-e2a3-4c29-ae40-2071855b2aeb/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d3a2a38e-e2a3-4c29-ae40-2071855b2aeb/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 5018,
        "title": "Inside Soho House's New Game-Changing Farmhouse - Condé Nast Traveler",
        "url": "https://pkslist.azureedge.net/media/images/1/d1535fef-fcb2-4e5c-a620-ac350df4e74d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d1535fef-fcb2-4e5c-a620-ac350df4e74d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d1535fef-fcb2-4e5c-a620-ac350df4e74d/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 5035,
        "title": "oxfordshire farmhouse retreat by michaelis boyd + soho house",
        "url": "https://pkslist.azureedge.net/media/images/1/12d98b97-7057-4eeb-960e-b49972d9fcef/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/12d98b97-7057-4eeb-960e-b49972d9fcef/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/12d98b97-7057-4eeb-960e-b49972d9fcef/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 5026,
        "title": "Inside Soho House's New Game-Changing Farmhouse - Condé Nast Traveler",
        "url": "https://pkslist.azureedge.net/media/images/1/3177194d-4eb9-45b7-8ece-a2d25d186573/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3177194d-4eb9-45b7-8ece-a2d25d186573/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3177194d-4eb9-45b7-8ece-a2d25d186573/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 5014,
        "title": "Soho Farmhouse, Chipping Norton, Cotswolds | Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/76cfb082-2b4e-42d0-b455-547a5547fab4/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/76cfb082-2b4e-42d0-b455-547a5547fab4/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/76cfb082-2b4e-42d0-b455-547a5547fab4/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 5028,
        "title": "Soho Farmhouse, the 'farm' where the trendy elite play at being ...",
        "url": "https://pkslist.azureedge.net/media/images/1/b121b036-a4b1-4bcd-916c-aa1dd1799be0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b121b036-a4b1-4bcd-916c-aa1dd1799be0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b121b036-a4b1-4bcd-916c-aa1dd1799be0/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 5031,
        "title": "Soho Farmhouse Oxfordshire: An Exclusive Retreat In The English ...",
        "url": "https://pkslist.azureedge.net/media/images/1/a01a8fbe-ae5b-423e-b16f-99c519092dfa/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a01a8fbe-ae5b-423e-b16f-99c519092dfa/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a01a8fbe-ae5b-423e-b16f-99c519092dfa/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 5042,
        "title": "Soho Farmhouse Cotswolds GB | Reise Reise | Pinterest | Travel ...",
        "url": "https://pkslist.azureedge.net/media/images/1/5494088c-6e0f-4c9a-adc7-6404d1006aea/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5494088c-6e0f-4c9a-adc7-6404d1006aea/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5494088c-6e0f-4c9a-adc7-6404d1006aea/256x192.jpg",
        "viewOrder": 11
      },
      {
        "mediaId": 5038,
        "title": "Sneak Peek: Soho Farmhouse, Oxfordshire, United Kingdom | Luxury ...",
        "url": "https://pkslist.azureedge.net/media/images/1/3f0e665a-1caa-4f65-b710-41eed6afc724/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3f0e665a-1caa-4f65-b710-41eed6afc724/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3f0e665a-1caa-4f65-b710-41eed6afc724/256x192.jpg",
        "viewOrder": 12
      },
      {
        "mediaId": 5045,
        "title": "17 best ideas about Soho Farmhouse on Pinterest | Soho house, Cafe ...",
        "url": "https://pkslist.azureedge.net/media/images/1/b2d21eb4-6175-4232-814d-12f28a587a1a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b2d21eb4-6175-4232-814d-12f28a587a1a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b2d21eb4-6175-4232-814d-12f28a587a1a/256x192.jpg",
        "viewOrder": 13
      }
    ],
    "geographicArea": {
      "geographicAreaId": 451,
      "latitude": 51.9403066,
      "longitude": -1.4214412000000038,
      "parentGeographicAreaId": 0,
      "title": "1 Tracey Farm Cottages, Great Tew, Chipping Norton OX7 4JS, UK",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 40,
        "tagName": "Boutique Bolthole ",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 5,
        "tagName": "Billiards",
        "tagType": 2
      },
      {
        "tagId": 31,
        "tagName": "Countryside",
        "tagType": 2
      },
      {
        "tagId": 34,
        "tagName": "Cosy",
        "tagType": 2
      },
      {
        "tagId": 64,
        "tagName": "Sceney",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 73,
        "tagName": "Outdoor Terraces",
        "tagType": 2
      },
      {
        "tagId": 88,
        "tagName": "Tennis",
        "tagType": 2
      },
      {
        "tagId": 105,
        "tagName": "Afternoon Tea",
        "tagType": 2
      },
      {
        "tagId": 127,
        "tagName": "Hair Salon",
        "tagType": 2
      },
      {
        "tagId": 172,
        "tagName": "Biking",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 115,
        "venueCategoryId": 1,
        "venueId": 611,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 1,
          "title": "Escape from the City",
          "region": "Global",
          "viewOrder": 14,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 644,
        "venueCategoryId": 33,
        "venueId": 611,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 33,
          "title": "Rustic Elegance",
          "region": "Global",
          "viewOrder": 11,
          "updated": "2020-09-04T09:04:00",
          "created": "2020-09-04T09:04:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 591,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Les Fermes de Marie",
    "description": "In this five-star luxury hotel in Megève, authenticity meets with the exceptional, offering elegance, calm and distinction. Les Fermes de Marie is made up of authentic alpine farmhouses, rescued from ruin high up in the hills of the French Alps. These buildings were taken apart beam by beam, numbered and put back together piece by piece in a 3 acre park in the heart of Megève. Enjoy a getaway in a place full of history where every stone, every beam, every object tells a story...\n\nIn the winter at Les Fermes de Marie you will experience the magic of snow in the mountains during a bespoke luxury getaway in a place that is intimate, cozy and calm. When spring arrives, it's all the sweetness of the beautiful days you always find. As the climate warms, this authentic luxury hotel becomes your summer quarters.",
    "brand": null,
    "blockQuote": "The bar is a really cozy place to hang out after a day on the slopes.",
    "emailAddress": "contact@fermesdemarie.com",
    "website": "fermesdemarie.com",
    "bookingURL": "http://booking.com/fbb54a2e27e292b2?aid=925669",
    "redirectNotes": "",
    "phoneNumber": "+33 4 50 93 03 10",
    "addressOne": "163",
    "addressTwo": "Chemin de la Riante Colline",
    "city": "Megeve",
    "county": "",
    "region": "",
    "country": "France",
    "rooms": 70,
    "dollar": 2,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 22468,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/82ead82b-1f98-4212-8e2f-185a0a0775b2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/82ead82b-1f98-4212-8e2f-185a0a0775b2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/82ead82b-1f98-4212-8e2f-185a0a0775b2/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 4776,
        "title": "Les Fermes de Marie | Hotel et Spa de Luxe à Megeve",
        "url": "https://pkslist.azureedge.net/media/images/1/18ff5b92-2898-4bb0-8bfe-118d2576b65a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/18ff5b92-2898-4bb0-8bfe-118d2576b65a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/18ff5b92-2898-4bb0-8bfe-118d2576b65a/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 4775,
        "title": "Les Fermes de Marie from $270 - UPDATED 2017 Hotel Reviews (Megeve ...",
        "url": "https://pkslist.azureedge.net/media/images/1/4ae0520e-eac9-4f2c-bc21-c24c5ade69e4/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4ae0520e-eac9-4f2c-bc21-c24c5ade69e4/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4ae0520e-eac9-4f2c-bc21-c24c5ade69e4/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 22469,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ad5848cf-cb88-4df7-8d86-e3cebcfb0b2c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ad5848cf-cb88-4df7-8d86-e3cebcfb0b2c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ad5848cf-cb88-4df7-8d86-e3cebcfb0b2c/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 4777,
        "title": "Places We Love: Les Fermes de Marie – Megève, France - Jean Marc Fray",
        "url": "https://pkslist.azureedge.net/media/images/1/b4f7571b-13e7-409f-8407-8aa158f9667f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b4f7571b-13e7-409f-8407-8aa158f9667f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b4f7571b-13e7-409f-8407-8aa158f9667f/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 22471,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/2c36ad7d-fdd5-4ebd-a4d1-f3061f4d4ccd/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2c36ad7d-fdd5-4ebd-a4d1-f3061f4d4ccd/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2c36ad7d-fdd5-4ebd-a4d1-f3061f4d4ccd/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 4778,
        "title": "Les Fermes de Marie | Hotel et Spa de Luxe à Megeve",
        "url": "https://pkslist.azureedge.net/media/images/1/88aa8947-d7aa-4e9b-b663-6a80f0bb6204/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/88aa8947-d7aa-4e9b-b663-6a80f0bb6204/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/88aa8947-d7aa-4e9b-b663-6a80f0bb6204/256x192.jpg",
        "viewOrder": 7
      }
    ],
    "geographicArea": {
      "geographicAreaId": 431,
      "latitude": 45.8561901,
      "longitude": 6.61298510000006,
      "parentGeographicAreaId": 0,
      "title": "163 Chemin de la Riante Colline, 74120 Megève, France",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 47,
        "tagName": "Intimate Luxury ",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 34,
        "tagName": "Cosy",
        "tagType": 2
      },
      {
        "tagId": 60,
        "tagName": "Gastronomic",
        "tagType": 2
      },
      {
        "tagId": 61,
        "tagName": "Mountain",
        "tagType": 2
      },
      {
        "tagId": 66,
        "tagName": "Skiing",
        "tagType": 2
      },
      {
        "tagId": 83,
        "tagName": "Kid's Club",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 100,
        "tagName": "Wedding",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 108,
        "tagName": "Jacuzzi",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 672,
        "venueCategoryId": 33,
        "venueId": 591,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 33,
          "title": "Rustic Elegance",
          "region": "Global",
          "viewOrder": 11,
          "updated": "2020-09-04T09:04:00",
          "created": "2020-09-04T09:04:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 582,
    "venueCategoryId": 0,
    "status": 1,
    "title": "La Signoria",
    "description": "At the gates of Calvi, overlooking the Bonifato valley and the blues of the Mediterranean Sea, the luxury hotel Corsica la Signoria is the former Genoese estate of La Paratella, granted by King Louis XV to Murat de la Serprière, who kept the charm of a mansion. All the elegance of this luxury Corsican hotel, without ostentation of the 18th century still reigns today in the charming rooms and personalized salons.\n\nDesigned as suites and casettas for travelers seeking privacy. From one building to another, all periods, styles and influences enhance the simplicity of historic architectural elements. All rooms have a \nterrace or balcony and are facing south/south west, with views of the park and/or the mountains. Decorated with antique furniture, they sing a hymn to the color and the art of patina. Marie Ceccaldi has been actively involved in the decoration of charm, carefully chinning furniture and objects with talent.",
    "brand": null,
    "blockQuote": "An exceptional upscale hotel that manages to make you feel relaxed and at home.",
    "emailAddress": "direction@hotel-la-signoria.com",
    "website": "hotel-la-signoria.com/",
    "bookingURL": "http://booking.com/0db567680ba8ab?aid=925669",
    "redirectNotes": null,
    "phoneNumber": "+33 4 95 65 93 00",
    "addressOne": "",
    "addressTwo": "Route De La Foret De Bonifato",
    "city": "Calvi",
    "county": "",
    "region": "Corsica",
    "country": "France",
    "rooms": 29,
    "dollar": 2,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 9210,
        "title": "La Signoria, a boutique hotel in Corsica",
        "url": "https://pkslist.azureedge.net/media/images/1/b2306066-46ad-4547-97c7-c83c210521be/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b2306066-46ad-4547-97c7-c83c210521be/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b2306066-46ad-4547-97c7-c83c210521be/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 9216,
        "title": "Hôtel la Signoria | Calvi Town",
        "url": "https://pkslist.azureedge.net/media/images/1/cfc0e84f-b9ef-4c4f-80e0-0fab4fd1266a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cfc0e84f-b9ef-4c4f-80e0-0fab4fd1266a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cfc0e84f-b9ef-4c4f-80e0-0fab4fd1266a/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 4668,
        "title": "Hotel La Signoria, Northern Corsica, France | HotelSearch.com",
        "url": "https://pkslist.azureedge.net/media/images/1/3e0c4dea-55c4-41e3-a143-76bb0980fd68/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3e0c4dea-55c4-41e3-a143-76bb0980fd68/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3e0c4dea-55c4-41e3-a143-76bb0980fd68/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 4670,
        "title": "List of hotels in Corsica, France - Boutique & Luxury Hotels ...",
        "url": "https://pkslist.azureedge.net/media/images/1/96156158-2179-41fc-91d1-ba163fa0ea26/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/96156158-2179-41fc-91d1-ba163fa0ea26/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/96156158-2179-41fc-91d1-ba163fa0ea26/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 9215,
        "title": "corsica - - tourism corsica",
        "url": "https://pkslist.azureedge.net/media/images/1/7a5a3b74-f5eb-4765-8883-12c29a99db92/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7a5a3b74-f5eb-4765-8883-12c29a99db92/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7a5a3b74-f5eb-4765-8883-12c29a99db92/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 4669,
        "title": "La Signoria hotel - Corsica, France - Smith & Family",
        "url": "https://pkslist.azureedge.net/media/images/1/673e6740-9ca4-4939-8c25-74a77851ca8e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/673e6740-9ca4-4939-8c25-74a77851ca8e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/673e6740-9ca4-4939-8c25-74a77851ca8e/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 4671,
        "title": "La Signoria 1212. Il Piccolo B&B In Monteriggioni | Book online ...",
        "url": "https://pkslist.azureedge.net/media/images/1/62009a61-4a18-464a-992d-81bad2496a6c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/62009a61-4a18-464a-992d-81bad2496a6c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/62009a61-4a18-464a-992d-81bad2496a6c/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 4667,
        "title": "La Signoria Chateaux & Hotels De France in Calvi, starting at $139 ...",
        "url": "https://pkslist.azureedge.net/media/images/1/4aba0a5a-ac88-47f7-b521-143c449eda18/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4aba0a5a-ac88-47f7-b521-143c449eda18/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4aba0a5a-ac88-47f7-b521-143c449eda18/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 9213,
        "title": "LA SIGNORIA",
        "url": "https://pkslist.azureedge.net/media/images/1/9549113b-6f1d-45e2-b2f4-6c54087ce55c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9549113b-6f1d-45e2-b2f4-6c54087ce55c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9549113b-6f1d-45e2-b2f4-6c54087ce55c/256x192.jpg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 422,
      "latitude": 42.5372,
      "longitude": 8.785338000000024,
      "parentGeographicAreaId": 0,
      "title": "Route De La Foret De Bonifato, 20260 Calvi, France",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 45,
        "tagName": "Historic Sense",
        "tagType": 1
      },
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 31,
        "tagName": "Countryside",
        "tagType": 2
      },
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 73,
        "tagName": "Outdoor Terraces",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 116,
        "tagName": "Scuba Diving",
        "tagType": 2
      },
      {
        "tagId": 155,
        "tagName": "Art",
        "tagType": 2
      },
      {
        "tagId": 206,
        "tagName": "Private Beach",
        "tagType": 2
      },
      {
        "tagId": 221,
        "tagName": "Wine Tasting",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 671,
        "venueCategoryId": 33,
        "venueId": 582,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 33,
          "title": "Rustic Elegance",
          "region": "Global",
          "viewOrder": 11,
          "updated": "2020-09-04T09:04:00",
          "created": "2020-09-04T09:04:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 562,
    "venueCategoryId": 15,
    "status": 1,
    "title": "Six Senses Ninh Van Bay",
    "description": "Six Senses Ninh Van Bay sits on a dramatic bay, with impressive rock formations. Golden sand beach and towering mountains add to the sense of being at one with nature. The family friendly resort’s architectural style incorporates Vietnamese traditions, with all 58 accommodations being personal pool villas.The restaurants at Six Senses Ninh Van Bay serve international and local favourites complemented by the best local seafood, including lobsters and king prawns. There are wonderful spaces for lounging with drinks from the wine cave, or healthy fresh juices and favourite libations from the bar. Six Senses Spa at Ninh Van Bay is a sanctuary for all the senses, with traditional Vietnamese treatments and Six Senses Signatures to suit every individual guest. In addition to the breath-taking treatment rooms, the spa is equipped with yoga pavilion, a gymnasium, hair salon and spa retail gallery. In addition to blissful relaxation, guests can also enjoy many activities and excursions on water, under water at the coral reef and on land by joining a cultural or wildlife adventure",
    "brand": "Six Senses",
    "blockQuote": "Experience the natural beauty of Vietnam's most untouched beaches on your own private tropical island.",
    "emailAddress": "reservations-ninhvan@sixsenses.com",
    "website": "sixsenses.com/resorts/ninh-van-bay",
    "bookingURL": "https://www.sixsenses.com/en/resorts/ninh-van-bay/accomodation-results?newSearch=1&src=&Hotel=61671&Rooms=1&Adult=2&Child=0&_PMID=96027890",
    "redirectNotes": "Six Senses Ninh Van Bay's reservations system",
    "phoneNumber": "+84 58 3524 268",
    "addressOne": "",
    "addressTwo": "",
    "city": "Ninh Hoa",
    "county": "",
    "region": "",
    "country": "Vietnam",
    "rooms": 58,
    "dollar": 2,
    "updated": "2020-10-12T18:51:34.903",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 41123,
        "title": "Resort Six Senses Ninh Van Bay, Vietnam - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/494950d9-1412-45de-b3db-fdf3db601cee/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/494950d9-1412-45de-b3db-fdf3db601cee/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/494950d9-1412-45de-b3db-fdf3db601cee/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 41115,
        "title": "Six Senses Ninh Van Bay: An Eco Resort to Feed Your Senses",
        "url": "https://pkslist.azureedge.net/media/images/1/62ac0382-71c5-49d2-8df6-3594c96c0c13/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/62ac0382-71c5-49d2-8df6-3594c96c0c13/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/62ac0382-71c5-49d2-8df6-3594c96c0c13/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 41109,
        "title": "Resort Six Senses Ninh Van Bay, Vietnam - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/90e5ce81-d4e2-49a8-8db3-685343285661/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/90e5ce81-d4e2-49a8-8db3-685343285661/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/90e5ce81-d4e2-49a8-8db3-685343285661/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 41113,
        "title": "Six Senses Ninh Van Bay, Vietnam • Hotel Review by TravelPlusStyle",
        "url": "https://pkslist.azureedge.net/media/images/1/09b90c80-1b43-45c9-ab91-cfc73a14569b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/09b90c80-1b43-45c9-ab91-cfc73a14569b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/09b90c80-1b43-45c9-ab91-cfc73a14569b/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 41120,
        "title": "Resort Six Senses Ninh Van Bay, Vietnam - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/4aeb4f07-430d-4c5e-9faf-7f2368a71e73/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4aeb4f07-430d-4c5e-9faf-7f2368a71e73/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4aeb4f07-430d-4c5e-9faf-7f2368a71e73/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 41118,
        "title": "Six Senses Ninh Van Bay Resort villa (Nha Trang) - Deals, Photos ...",
        "url": "https://pkslist.azureedge.net/media/images/1/fcd956f2-9786-42bd-8515-72515e47e8f6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fcd956f2-9786-42bd-8515-72515e47e8f6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fcd956f2-9786-42bd-8515-72515e47e8f6/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 4400,
        "title": "Six Senses Ninh Van Bay | Vietnam",
        "url": "https://pkslist.azureedge.net/media/images/1/ca14c902-f50b-4b9f-964b-0c66d24211e3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ca14c902-f50b-4b9f-964b-0c66d24211e3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ca14c902-f50b-4b9f-964b-0c66d24211e3/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 4401,
        "title": "Relaxing Time @ Six Senses Ninh Van Bay (Vietnam) | vinaday.com",
        "url": "https://pkslist.azureedge.net/media/images/1/22e0b690-4980-43ec-936c-ef02daed3f97/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/22e0b690-4980-43ec-936c-ef02daed3f97/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/22e0b690-4980-43ec-936c-ef02daed3f97/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 402,
      "latitude": 12.358594,
      "longitude": 109.27772299999992,
      "parentGeographicAreaId": 0,
      "title": "Ninh Vân, Ninh Hòa, Ninh Vân, Vietnam",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 50,
        "tagName": "Barefoot Luxury",
        "tagType": 1
      },
      {
        "tagId": 157,
        "tagName": "Beach Escape",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 77,
        "tagName": "Cooking Classes",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 83,
        "tagName": "Kid's Club",
        "tagType": 2
      },
      {
        "tagId": 85,
        "tagName": "Pet Friendly",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 88,
        "tagName": "Tennis",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 108,
        "tagName": "Jacuzzi",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 164,
        "tagName": "Excursions",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 107,
        "venueCategoryId": 15,
        "venueId": 562,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 15,
          "title": "Beach Paradise",
          "region": "Global",
          "viewOrder": 6,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 662,
        "venueCategoryId": 33,
        "venueId": 562,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 33,
          "title": "Rustic Elegance",
          "region": "Global",
          "viewOrder": 11,
          "updated": "2020-09-04T09:04:00",
          "created": "2020-09-04T09:04:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 560,
    "venueCategoryId": 10,
    "status": 1,
    "title": "Six Senses Yao Noi",
    "description": "Six Senses Yao Noi sets the highest benchmark for island resort living in Asia. The iconic limestone pinnacles rising from the waters of Phang Nga Bay create a surreal backdrop and a sense of privacy and adventure. Pool Villa accommodations provide generous personal space, a variety of views and present an uncompromised lifestyle.\n\nNatural materials used in the modern architecture, together with attention to detail and focus on the unique destination reinforce the Six Senses philosophy of sustainability and creating exceptional experiences.",
    "brand": "Six Senses",
    "blockQuote": "Peaceful living environment with a focus on personal space and privacy.",
    "emailAddress": "reservations-yaonoi@sixsenses.com",
    "website": "https://www.sixsenses.com/en/resorts/yao-noi",
    "bookingURL": "https://www.sixsenses.com/en/resorts/yao-noi/accomodation-results?newSearch=1&src=&Hotel=HDYYN&Rooms=1&Adult=2&Child=0&_PMID=96027890",
    "redirectNotes": "Six Senses Yao Noi's reservations system",
    "phoneNumber": "+66 7641 8500",
    "addressOne": "",
    "addressTwo": "",
    "city": "Koh Yao Noi",
    "county": "",
    "region": "Phuket",
    "country": "Thailand",
    "rooms": 56,
    "dollar": 2,
    "updated": "2020-10-12T17:26:40.47",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 17870,
        "title": "Six Senses Yao Noi « Luxury Hotels TravelPlusStyle",
        "url": "https://pkslist.azureedge.net/media/images/1/6d11dcdd-b82e-4ed8-947f-e91b6c6cd085/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6d11dcdd-b82e-4ed8-947f-e91b6c6cd085/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6d11dcdd-b82e-4ed8-947f-e91b6c6cd085/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 17872,
        "title": "Six Senses Yao Noi: 2018 Room Prices, Deals & Reviews | Expedia",
        "url": "https://pkslist.azureedge.net/media/images/1/bb6aabaa-05ba-44ff-b8f3-d3fb6d42ef2a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bb6aabaa-05ba-44ff-b8f3-d3fb6d42ef2a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bb6aabaa-05ba-44ff-b8f3-d3fb6d42ef2a/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 38157,
        "title": "Six Senses Yao Noi, Thailand • Luxury Hotel Review by TravelPlusStyle",
        "url": "https://pkslist.azureedge.net/media/images/1/9c996136-c56b-4ad1-ba6d-800d74e0b81b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9c996136-c56b-4ad1-ba6d-800d74e0b81b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9c996136-c56b-4ad1-ba6d-800d74e0b81b/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 4371,
        "title": "Thailand Pool Villa, Yao Noi Luxury Villas & Suites - Six Senses",
        "url": "https://pkslist.azureedge.net/media/images/1/36d97351-51a6-4226-9fa3-a74fa031b024/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/36d97351-51a6-4226-9fa3-a74fa031b024/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/36d97351-51a6-4226-9fa3-a74fa031b024/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 17871,
        "title": "Six Senses Yao Noi in Phuket - Asia Green Resorts - Asia Tour ...",
        "url": "https://pkslist.azureedge.net/media/images/1/37863300-4eb8-4171-9c7a-d0f54deadb06/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/37863300-4eb8-4171-9c7a-d0f54deadb06/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/37863300-4eb8-4171-9c7a-d0f54deadb06/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 38155,
        "title": "Meetings and events at Six Senses Hideaway Yao Noi, Ko Tao, TH",
        "url": "https://pkslist.azureedge.net/media/images/1/55671cef-7fbe-47a8-90d2-ce85576ede03/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/55671cef-7fbe-47a8-90d2-ce85576ede03/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/55671cef-7fbe-47a8-90d2-ce85576ede03/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 38163,
        "title": "The Retreat at Six Senses Yao Noi – Robb Report",
        "url": "https://pkslist.azureedge.net/media/images/1/3a029d72-f304-4d5c-8b2f-380598c1a054/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3a029d72-f304-4d5c-8b2f-380598c1a054/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3a029d72-f304-4d5c-8b2f-380598c1a054/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 38164,
        "title": "Sis Senses Yao Noi | Vexplore Tours",
        "url": "https://pkslist.azureedge.net/media/images/1/b444389c-ee9f-40e9-bb7d-9602b26e16b6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b444389c-ee9f-40e9-bb7d-9602b26e16b6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b444389c-ee9f-40e9-bb7d-9602b26e16b6/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 38166,
        "title": "Six Senses Yao Noi, Thailand • Luxury Hotel Review by TravelPlusStyle",
        "url": "https://pkslist.azureedge.net/media/images/1/555037e2-657f-49f2-9a8c-272a4800abff/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/555037e2-657f-49f2-9a8c-272a4800abff/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/555037e2-657f-49f2-9a8c-272a4800abff/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 38167,
        "title": "Six Senses Yao Noi",
        "url": "https://pkslist.azureedge.net/media/images/1/be0cad4f-240f-4a62-9db7-65474221d30e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/be0cad4f-240f-4a62-9db7-65474221d30e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/be0cad4f-240f-4a62-9db7-65474221d30e/256x192.jpg",
        "viewOrder": 11
      },
      {
        "mediaId": 38160,
        "title": "Six Senses Yao Noi: A Thai resort with a conscience | CNN Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/4f1c687a-bc5d-4701-a7f2-5a0bd17c1b42/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4f1c687a-bc5d-4701-a7f2-5a0bd17c1b42/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4f1c687a-bc5d-4701-a7f2-5a0bd17c1b42/256x192.jpg",
        "viewOrder": 12
      },
      {
        "mediaId": 17799,
        "title": "Six Senses Yao Noi | Exklusive Luxushotels in Thailand mit BAWA ...",
        "url": "https://pkslist.azureedge.net/media/images/1/5d37ba4a-e7a7-4607-932b-e29220f4da8d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5d37ba4a-e7a7-4607-932b-e29220f4da8d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5d37ba4a-e7a7-4607-932b-e29220f4da8d/256x192.jpg",
        "viewOrder": 14
      }
    ],
    "geographicArea": {
      "geographicAreaId": 398,
      "latitude": 8.119752,
      "longitude": 98.62516099999993,
      "parentGeographicAreaId": 0,
      "title": "56 Moo 5, Koh Yao Noi, อำเภอ เกาะยาว พังงา 82160, Thailand",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 50,
        "tagName": "Barefoot Luxury",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 77,
        "tagName": "Cooking Classes",
        "tagType": 2
      },
      {
        "tagId": 83,
        "tagName": "Kid's Club",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 101,
        "tagName": "Cinema",
        "tagType": 2
      },
      {
        "tagId": 116,
        "tagName": "Scuba Diving",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 181,
        "tagName": "Private/Suite Pools",
        "tagType": 2
      },
      {
        "tagId": 183,
        "tagName": "Butler Service",
        "tagType": 2
      },
      {
        "tagId": 193,
        "tagName": "Outdoor Dining",
        "tagType": 2
      },
      {
        "tagId": 198,
        "tagName": "Boat Excursion",
        "tagType": 2
      },
      {
        "tagId": 206,
        "tagName": "Private Beach",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 106,
        "venueCategoryId": 10,
        "venueId": 560,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 10,
          "title": "Infinity Pools",
          "region": "Global",
          "viewOrder": 2,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 609,
        "venueCategoryId": 32,
        "venueId": 560,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 661,
        "venueCategoryId": 33,
        "venueId": 560,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 33,
          "title": "Rustic Elegance",
          "region": "Global",
          "viewOrder": 11,
          "updated": "2020-09-04T09:04:00",
          "created": "2020-09-04T09:04:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 559,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Six Senses Samui",
    "description": "Six Senses Samui is set on a gently sloping headland at the northern tip of Samui Island. The resort is made up of just 66 private villas, many with personal pools, which are landscaped within natural vegetation, and with glorious, sweeping panoramas out to the Gulf of Thailand and the surrounding islands.\n\nLocated just six kilometers from Samui Airport, the 20-acre (9-hectare) estate is a 45-minute flight south of the Thai capital of Bangkok.",
    "brand": "Six Senses",
    "blockQuote": "Be sure to visit Six Senses Samui’s Farm on the Hill and Earth Lab project that has won the Green Excellence Awards UK 2017.",
    "emailAddress": "reservations-samui@sixsenses.com",
    "website": "sixsenses.com/resorts/samui",
    "bookingURL": "https://www.sixsenses.com/en/resorts/samui/accomodation-results?newSearch=1&src=&Hotel=61666&Rooms=1&Adult=2&Child=0&_PMID=96027890",
    "redirectNotes": "Six Senses Samui's reservations system",
    "phoneNumber": "+66 7724 5678",
    "addressOne": "",
    "addressTwo": "",
    "city": "Koh Samui",
    "county": "",
    "region": "",
    "country": "Thailand",
    "rooms": 66,
    "dollar": 2,
    "updated": "2020-10-12T17:25:24.163",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 4360,
        "title": "Resort Thailand, Samui Villas, Pool Villa Samui - Six Senses",
        "url": "https://pkslist.azureedge.net/media/images/1/66baa933-b910-4720-85f0-669880521a32/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/66baa933-b910-4720-85f0-669880521a32/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/66baa933-b910-4720-85f0-669880521a32/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 4366,
        "title": "Six Senses Samui",
        "url": "https://pkslist.azureedge.net/media/images/1/0483ff95-56e0-444e-bbac-546328d1efaf/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0483ff95-56e0-444e-bbac-546328d1efaf/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0483ff95-56e0-444e-bbac-546328d1efaf/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 4369,
        "title": "Six Senses Samui (Ko Samui/Bophut) - Hotel Reviews, Photos & Price ...",
        "url": "https://pkslist.azureedge.net/media/images/1/a31a8983-341a-4e25-bb9d-d9e99116c027/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a31a8983-341a-4e25-bb9d-d9e99116c027/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a31a8983-341a-4e25-bb9d-d9e99116c027/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 17784,
        "title": "Six Senses Samui « Luxury Hotels TravelPlusStyle",
        "url": "https://pkslist.azureedge.net/media/images/1/203b173a-8921-49d4-be04-61094e8cd23e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/203b173a-8921-49d4-be04-61094e8cd23e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/203b173a-8921-49d4-be04-61094e8cd23e/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 4362,
        "title": "Six Senses Samui Reopens on July 1 with Beautiful Refreshed Interiors",
        "url": "https://pkslist.azureedge.net/media/images/1/0093057e-b9c0-4ea9-83f2-bca9cae2d178/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0093057e-b9c0-4ea9-83f2-bca9cae2d178/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0093057e-b9c0-4ea9-83f2-bca9cae2d178/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 4367,
        "title": "Six Senses Samui - Luxury Ocean Front Villa Resort in Choeng Mon",
        "url": "https://pkslist.azureedge.net/media/images/1/5b6b9ff6-8823-4fcd-ba89-b68a0453229b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5b6b9ff6-8823-4fcd-ba89-b68a0453229b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5b6b9ff6-8823-4fcd-ba89-b68a0453229b/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 4357,
        "title": "Resort Thailand, Samui Villas, Pool Villa Samui - Six Senses",
        "url": "https://pkslist.azureedge.net/media/images/1/e0d20f7f-03d1-4084-9ddf-78c48867659f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e0d20f7f-03d1-4084-9ddf-78c48867659f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e0d20f7f-03d1-4084-9ddf-78c48867659f/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 4363,
        "title": "Six Senses Samui | Fabulous 100",
        "url": "https://pkslist.azureedge.net/media/images/1/56ccb6a6-7fdc-43b6-825b-e416bb698bc4/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/56ccb6a6-7fdc-43b6-825b-e416bb698bc4/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/56ccb6a6-7fdc-43b6-825b-e416bb698bc4/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 396,
      "latitude": 9.593005999999999,
      "longitude": 100.06848600000001,
      "parentGeographicAreaId": 0,
      "title": "9/10 Moo 5, Baan Plai Laem, Bophut, Koh Samui, อำเภอ เกาะสมุย สุราษฎร์ธานี 84320, Thailand",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 50,
        "tagName": "Barefoot Luxury",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 58,
        "tagName": "Beach",
        "tagType": 2
      },
      {
        "tagId": 73,
        "tagName": "Outdoor Terraces",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 169,
        "tagName": "Resort",
        "tagType": 2
      },
      {
        "tagId": 170,
        "tagName": "Kayaking",
        "tagType": 2
      },
      {
        "tagId": 181,
        "tagName": "Private/Suite Pools",
        "tagType": 2
      },
      {
        "tagId": 183,
        "tagName": "Butler Service",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 660,
        "venueCategoryId": 33,
        "venueId": 559,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 33,
          "title": "Rustic Elegance",
          "region": "Global",
          "viewOrder": 11,
          "updated": "2020-09-04T09:04:00",
          "created": "2020-09-04T09:04:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 487,
    "venueCategoryId": 20,
    "status": 1,
    "title": "Nihi Sumba Island",
    "description": "This is not an escape from everyday life. It is the return to a life well lived. Where rugged luxury meets unregulated freedom. A place to connect with something larger than oneself. A haven for the adventurer, for the wanderer. The endlessly passionate and curious. Arrive with an open heart and leave changed forever. This is vacation with a purpose. A destination with meaning.\n\nNihi Sumba Island is an award-winning luxury resort on remote Sumba Island, Indonesia. A wide range of land and ocean-based experiences ensures all tastes are catered to. Attentive service and gourmet meals add to the refined and intimate ambience. A romantic getaway idyll with pristine private beach stretching in excess of 2.5km of length ideal for honeymooners and the unique tribal culture on the island offers guests a glimpse of a bygone era.",
    "brand": null,
    "blockQuote": "They are known for their extraordinary NihiOka spa safari which is a must-do while you're there.",
    "emailAddress": "info@nihi.com",
    "website": "nihi.com/",
    "bookingURL": "http://nihi.com/booking/",
    "redirectNotes": null,
    "phoneNumber": "+62 361 757149",
    "addressOne": "",
    "addressTwo": "",
    "city": "Sumba",
    "county": "",
    "region": "",
    "country": "Indonesia",
    "rooms": 33,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 18968,
        "title": "Best hotel in the world is Nihi Sumba — built by fashion mogul ...",
        "url": "https://pkslist.azureedge.net/media/images/1/d66e5db3-94d7-4f0c-984d-43310621192f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d66e5db3-94d7-4f0c-984d-43310621192f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d66e5db3-94d7-4f0c-984d-43310621192f/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 18970,
        "title": "Nihiwatu Resort - Sumba Island - YouTube",
        "url": "https://pkslist.azureedge.net/media/images/1/abfa3d84-cf77-41f9-ac3e-ad81a093b6b2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/abfa3d84-cf77-41f9-ac3e-ad81a093b6b2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/abfa3d84-cf77-41f9-ac3e-ad81a093b6b2/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 18969,
        "title": "Nihi Sumba Island . Indonesia . | J.MAK Hospitality",
        "url": "https://pkslist.azureedge.net/media/images/1/9b927cef-cd93-4fd0-b112-65e03fbeed6e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9b927cef-cd93-4fd0-b112-65e03fbeed6e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9b927cef-cd93-4fd0-b112-65e03fbeed6e/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 18974,
        "title": "Best hotel in the world is Nihi Sumba — built by fashion mogul ...",
        "url": "https://pkslist.azureedge.net/media/images/1/7e7a7fad-0bb5-4dfd-b076-8886be4bb120/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7e7a7fad-0bb5-4dfd-b076-8886be4bb120/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7e7a7fad-0bb5-4dfd-b076-8886be4bb120/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 18973,
        "title": "Best hotel in the world is Nihi Sumba — built by fashion mogul ...",
        "url": "https://pkslist.azureedge.net/media/images/1/195176ee-d815-4f5f-b033-6e4ef709dabb/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/195176ee-d815-4f5f-b033-6e4ef709dabb/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/195176ee-d815-4f5f-b033-6e4ef709dabb/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 18972,
        "title": "Nihi Sumba Island | LuxuryHunt.com | Ultimate Collection",
        "url": "https://pkslist.azureedge.net/media/images/1/262675b4-8c31-460e-9577-8fa2975ac60c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/262675b4-8c31-460e-9577-8fa2975ac60c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/262675b4-8c31-460e-9577-8fa2975ac60c/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 3440,
        "title": "Olivia Palermo | Travel: Nihiwatu Beach - Sumba Island, Indonesia ...",
        "url": "https://pkslist.azureedge.net/media/images/1/483292e6-b16c-4f83-ae5c-09e21ddb31ee/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/483292e6-b16c-4f83-ae5c-09e21ddb31ee/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/483292e6-b16c-4f83-ae5c-09e21ddb31ee/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 3441,
        "title": "Nihiwatu Resort, Sumba Island, Indonesia: Sand and surf perfection ...",
        "url": "https://pkslist.azureedge.net/media/images/1/ea093a8c-7e2e-4930-8bd1-108c04a6bb2a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ea093a8c-7e2e-4930-8bd1-108c04a6bb2a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ea093a8c-7e2e-4930-8bd1-108c04a6bb2a/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 18976,
        "title": "Nihi Sumba Island Bali | Worldwide Vacation Spots | Pinterest ...",
        "url": "https://pkslist.azureedge.net/media/images/1/0f0a2b27-e105-46f6-9cd4-d27a3a960114/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0f0a2b27-e105-46f6-9cd4-d27a3a960114/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0f0a2b27-e105-46f6-9cd4-d27a3a960114/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 3436,
        "title": "Stay in a Treehouse with a Private Infinity Pool, at Nihiwatu ...",
        "url": "https://pkslist.azureedge.net/media/images/1/43dfe8b4-98da-4588-9d31-5d9ec00a9b7e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/43dfe8b4-98da-4588-9d31-5d9ec00a9b7e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/43dfe8b4-98da-4588-9d31-5d9ec00a9b7e/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 3435,
        "title": "Nihiwatu Sumba Island - The Bali Bible",
        "url": "https://pkslist.azureedge.net/media/images/1/26cd1670-8364-44d2-8a91-aec276a2d9f6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/26cd1670-8364-44d2-8a91-aec276a2d9f6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/26cd1670-8364-44d2-8a91-aec276a2d9f6/256x192.jpg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 331,
      "latitude": -9.7776614,
      "longitude": 119.37614959999996,
      "parentGeographicAreaId": 0,
      "title": "Nusa Tenggara Tim, Hoba Wawi, Wanokaka, Hoba Wawi, Wanokaka, Kabupaten Sumba Barat, Nusa Tenggara Tim., Indonesia",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 50,
        "tagName": "Barefoot Luxury",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 59,
        "tagName": "Cultural",
        "tagType": 2
      },
      {
        "tagId": 82,
        "tagName": "Horseback Riding",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 122,
        "tagName": "Surfing",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 266,
        "tagName": "Nature Walk",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 324,
        "tagName": "Local Village",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 84,
        "venueCategoryId": 20,
        "venueId": 487,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 20,
          "title": "BucketList Hotels",
          "region": "Global",
          "viewOrder": 0,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 342,
        "venueCategoryId": 5,
        "venueId": 487,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 5,
          "title": "Romantic Getaways",
          "region": "Global",
          "viewOrder": 20,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 622,
        "venueCategoryId": 32,
        "venueId": 487,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 645,
        "venueCategoryId": 34,
        "venueId": 487,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 677,
        "venueCategoryId": 33,
        "venueId": 487,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 33,
          "title": "Rustic Elegance",
          "region": "Global",
          "viewOrder": 11,
          "updated": "2020-09-04T09:04:00",
          "created": "2020-09-04T09:04:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 367,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Legado Mitico Salta",
    "description": "On the historic quarter of the city of Salta, on Mitre Street, this mansion, which once belonged to a patrician Salteñan family, has regained the charm of its original spirit to accommodate Legado Mítico Salta, a hotel where tradition combines with today’s contemporary lifestyle.Inspired by history and culture, warm and moving, these hotels evoke those characters who led the way with their luminous performances, as well as the silent ones who wove their individual existence in the weft of our collective identity.In their design, refinement and classicism gracefully combine with the simplicity of current trends.",
    "brand": null,
    "blockQuote": "Boutique gem for those wanting to immerse in some local flair.",
    "emailAddress": "reservassalta@legadomitico.com",
    "website": "legadomitico.com/",
    "bookingURL": "http://www.booking.com/hotel/ar/legado-mitico-salta.html?aid=925669",
    "redirectNotes": "",
    "phoneNumber": "+54387 422 8786",
    "addressOne": "647",
    "addressTwo": "Bartolomé Mitre",
    "city": "Salta",
    "county": "",
    "region": "",
    "country": "Argentina",
    "rooms": 11,
    "dollar": 1,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 1902,
        "title": "Hotel Legado Mitico Boutique, Salta, Argentina - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/8099fd01-6400-429e-9159-dbb3f8a6205b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8099fd01-6400-429e-9159-dbb3f8a6205b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8099fd01-6400-429e-9159-dbb3f8a6205b/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 1917,
        "title": "LEGADO MÍTICO. EMBLEMATIC BOUTIQUE HOTELS. BUENOS AIRES – SALTA.",
        "url": "https://pkslist.azureedge.net/media/images/1/cf6a938b-e4ed-4bb4-9cc4-07eb14e5f77c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cf6a938b-e4ed-4bb4-9cc4-07eb14e5f77c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cf6a938b-e4ed-4bb4-9cc4-07eb14e5f77c/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 1907,
        "title": "Legado Mítico | Taste of Argentina",
        "url": "https://pkslist.azureedge.net/media/images/1/d1ce90d5-bc50-48d1-9e3b-3e754aa024e0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d1ce90d5-bc50-48d1-9e3b-3e754aa024e0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d1ce90d5-bc50-48d1-9e3b-3e754aa024e0/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 1906,
        "title": "Legadoi Mitico Salta - Picture of Legado Mitico Salta, Salta ...",
        "url": "https://pkslist.azureedge.net/media/images/1/aa3d8a92-517e-41b8-aa9e-0d7edb958329/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/aa3d8a92-517e-41b8-aa9e-0d7edb958329/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/aa3d8a92-517e-41b8-aa9e-0d7edb958329/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 1908,
        "title": "Legado Mitico Salta, North West Argentina | Original Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/6ba1ead6-5d30-4bf9-a4ef-db94f1f91666/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6ba1ead6-5d30-4bf9-a4ef-db94f1f91666/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6ba1ead6-5d30-4bf9-a4ef-db94f1f91666/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 1904,
        "title": "Hotel Boutique Legado Mitico Photos | Salta Hotels |Argentina For Less",
        "url": "https://pkslist.azureedge.net/media/images/1/b218bb8b-fc53-4e4a-83c0-be9d01f8eb8d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b218bb8b-fc53-4e4a-83c0-be9d01f8eb8d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b218bb8b-fc53-4e4a-83c0-be9d01f8eb8d/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 1911,
        "title": "Legado Mitico Salta | Audley Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/c4f29249-8505-4208-aa62-f47a60276d8a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c4f29249-8505-4208-aa62-f47a60276d8a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c4f29249-8505-4208-aa62-f47a60276d8a/256x192.jpg",
        "viewOrder": 7
      }
    ],
    "geographicArea": {
      "geographicAreaId": 223,
      "latitude": -24.7820449,
      "longitude": -65.41035119999998,
      "parentGeographicAreaId": 0,
      "title": "Bartolomé Mitre 647, Salta, Argentina",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 40,
        "tagName": "Boutique Bolthole ",
        "tagType": 1
      },
      {
        "tagId": 45,
        "tagName": "Historic Sense",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 34,
        "tagName": "Cosy",
        "tagType": 2
      },
      {
        "tagId": 59,
        "tagName": "Cultural",
        "tagType": 2
      },
      {
        "tagId": 69,
        "tagName": "Couples",
        "tagType": 2
      },
      {
        "tagId": 73,
        "tagName": "Outdoor Terraces",
        "tagType": 2
      },
      {
        "tagId": 75,
        "tagName": "Casual Dining",
        "tagType": 2
      },
      {
        "tagId": 131,
        "tagName": "Library",
        "tagType": 2
      },
      {
        "tagId": 156,
        "tagName": "Fireplace",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 669,
        "venueCategoryId": 33,
        "venueId": 367,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 33,
          "title": "Rustic Elegance",
          "region": "Global",
          "viewOrder": 11,
          "updated": "2020-09-04T09:04:00",
          "created": "2020-09-04T09:04:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 302,
    "venueCategoryId": 26,
    "status": 1,
    "title": "Six Senses Laamu",
    "description": "Six Senses Laamu is the only resort in the Laamu Atoll, deep in the Indian Ocean. Just an inter-island plane trip from Malé International Airport and a short boat ride brings you to a place where dolphins swim. The luxury Maldives resort is a combination of on-land and over-water villas constructed of sustainable materials and set around the palm-fringed paradise. At several lounges and restaurants our chefs from East and West prepare mouthwatering dishes, many created from produce grown in the island's organic gardens.",
    "brand": "Six Senses",
    "blockQuote": "Want to be like Robinson Crusoe? Book the Deserted Island Picnic experience and enjoy and entire island to yourselves for a day.",
    "emailAddress": "reservations-laamu@sixsenses.com",
    "website": "sixsenses.com/resorts/laamu/destination",
    "bookingURL": "https://www.sixsenses.com/en/resorts/laamu/accomodation-results?newSearch=1&src=&Hotel=KDOLM&Rooms=1&Adult=2&Child=0&_PMID=96027890",
    "redirectNotes": "Six Senses Laamu's reservations system",
    "phoneNumber": "+960 680 0800  ",
    "addressOne": "",
    "addressTwo": "",
    "city": "Laamu Atoll",
    "county": "",
    "region": "",
    "country": "Maldives",
    "rooms": 97,
    "dollar": 3,
    "updated": "2020-11-23T11:34:40.107",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 1142,
        "title": "Six Senses Laamu | Luxury Hotels TravelPlusStyle",
        "url": "https://pkslist.azureedge.net/media/images/1/a6971939-6d83-40b6-af70-dff0304aaf5d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a6971939-6d83-40b6-af70-dff0304aaf5d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a6971939-6d83-40b6-af70-dff0304aaf5d/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 43200,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/30356544-64ac-4769-b204-618a6bf090f7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/30356544-64ac-4769-b204-618a6bf090f7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/30356544-64ac-4769-b204-618a6bf090f7/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 43202,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a9a2a587-13e3-452a-a05b-e12b9e3c3a51/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a9a2a587-13e3-452a-a05b-e12b9e3c3a51/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a9a2a587-13e3-452a-a05b-e12b9e3c3a51/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 43204,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/803ff387-1081-419e-a62c-bd1a06ae0a80/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/803ff387-1081-419e-a62c-bd1a06ae0a80/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/803ff387-1081-419e-a62c-bd1a06ae0a80/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 1146,
        "title": "Six Senses Gallery",
        "url": "https://pkslist.azureedge.net/media/images/1/2d75a10b-5c66-4f5d-bbfd-b8521099406b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2d75a10b-5c66-4f5d-bbfd-b8521099406b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2d75a10b-5c66-4f5d-bbfd-b8521099406b/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 1149,
        "title": "Resort Six Senses Laamu, Olhuveli , Maldives - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/b4e97606-0b0d-4015-a812-0714f8ffe745/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b4e97606-0b0d-4015-a812-0714f8ffe745/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b4e97606-0b0d-4015-a812-0714f8ffe745/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 43211,
        "title": "Dolphins, Six Senses Laamu & epic Sunset - Maldives Travel Diary ...",
        "url": "https://pkslist.azureedge.net/media/images/1/2bf7c7b8-64d0-44f0-887a-f9f7c0beb3df/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2bf7c7b8-64d0-44f0-887a-f9f7c0beb3df/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2bf7c7b8-64d0-44f0-887a-f9f7c0beb3df/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 43208,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/cf8062c3-71aa-4f76-ad89-0844bd13e610/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cf8062c3-71aa-4f76-ad89-0844bd13e610/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cf8062c3-71aa-4f76-ad89-0844bd13e610/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 43209,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d9f2ee07-d51c-422b-8dc7-14669e939572/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d9f2ee07-d51c-422b-8dc7-14669e939572/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d9f2ee07-d51c-422b-8dc7-14669e939572/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 43205,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f485ea49-e2b4-45c5-8251-479a131947fe/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f485ea49-e2b4-45c5-8251-479a131947fe/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f485ea49-e2b4-45c5-8251-479a131947fe/256x192.jpg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 163,
      "latitude": 1.9772276,
      "longitude": 73.53610100000003,
      "parentGeographicAreaId": 0,
      "title": "Laamu Atoll, Maldives",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 50,
        "tagName": "Barefoot Luxury",
        "tagType": 1
      },
      {
        "tagId": 157,
        "tagName": "Beach Escape",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 101,
        "tagName": "Cinema",
        "tagType": 2
      },
      {
        "tagId": 109,
        "tagName": "Fishing",
        "tagType": 2
      },
      {
        "tagId": 116,
        "tagName": "Scuba Diving",
        "tagType": 2
      },
      {
        "tagId": 198,
        "tagName": "Boat Excursion",
        "tagType": 2
      },
      {
        "tagId": 206,
        "tagName": "Private Beach",
        "tagType": 2
      },
      {
        "tagId": 320,
        "tagName": "Organic Garden",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 537,
        "venueCategoryId": 5,
        "venueId": 302,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 5,
          "title": "Romantic Getaways",
          "region": "Global",
          "viewOrder": 20,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 538,
        "venueCategoryId": 15,
        "venueId": 302,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 15,
          "title": "Beach Paradise",
          "region": "Global",
          "viewOrder": 6,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 663,
        "venueCategoryId": 33,
        "venueId": 302,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 33,
          "title": "Rustic Elegance",
          "region": "Global",
          "viewOrder": 11,
          "updated": "2020-09-04T09:04:00",
          "created": "2020-09-04T09:04:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 300,
    "venueCategoryId": 26,
    "status": 1,
    "title": "Gili Lankanfushi",
    "description": "They have but one philosophy here. Leave your worries at home. This is a place of positive energy, and nothing is ever going to change that. So kick back, relax, and forget about everything but the gentle, warm breeze embracing you.\n\nGili Lankanfushi Maldives is a short 20 minute boat ride from Male, the capital of the Maldives. It is a tiny coral island in a sparkling lagoon, with jetties threading across the water out to spacious villas. The forty four stilted villas plus the vast Private Reserve are crafted in a traditional style from natural materials and offer rooftop and over-water sundecks, featuring open air bathrooms, sumptuous daybeds and a personal entertainment center. For ultimate privacy, they have seven Crusoe Residences that are only accessible by personal boat. Come ashore for some great dining and equally great wines or perhaps a romantic moonlit dinner delivered right to the villa. The possibilities are endless. The interior decoration has been devised to recreate a natural habitat, using sustainable woods, natural materials and traditional fabrics, whilst still offering all the modern day amenities and creature comforts of true luxury. At Gili Lankanfushi, Maldives we are very sensitive to the environment. The utmost care has been taken in developing the island with respect to its ecological balance. The Maldives is a paradise for snorkeling and diving and the resort has its own fully equipped PADI diving school plus a variety of water sports. This place tops the list of must-do destinations for those seeking the aquatic paradise.",
    "brand": "",
    "blockQuote": "All villas have great views, but villas 1-4 have the very best views.",
    "emailAddress": "mr.friday@gili-lankanfushi.com",
    "website": "gili-lankanfushi.com/",
    "bookingURL": "https://www.book-secure.com/index.php?s=results&property=mvnor24661&arrival=2020-11-22&departure=2020-11-23&adults1=2&children1=0&locale=en_GB&currency=USD&stid=281jn8292&_ga=2.21645883.184934632.1606026606-1271403926.1606026606&redir=BIZ-so5523q0o4&Clusternames=ASIAHPLHTLTheGili&rt=1606026677&connectName=ASIAHPLHTLTheGili&cname=ASIAHPLHTLTheGili&Hotelnames=Asia-HPL-Mv-Gili-Maldives&hname=Asia-HPL-Mv-Gili-Maldives&cluster=ASIAHPLHTLTheGili",
    "redirectNotes": "Gili Lankanfushi's reservations system",
    "phoneNumber": "+960 6640304",
    "addressOne": "",
    "addressTwo": "",
    "city": "North Male Atoll",
    "county": "",
    "region": "",
    "country": "Maldives",
    "rooms": 45,
    "dollar": 3,
    "updated": "2020-11-22T08:11:10.073",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 43113,
        "title": "Why you should visit Gilli Lankanfushi, Maldives",
        "url": "https://pkslist.azureedge.net/media/images/1/69a32404-d8ea-45be-82f8-94a085619d50/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/69a32404-d8ea-45be-82f8-94a085619d50/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/69a32404-d8ea-45be-82f8-94a085619d50/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 43124,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/3a7ca7bf-bf97-42bb-958b-4e64eff40169/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3a7ca7bf-bf97-42bb-958b-4e64eff40169/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3a7ca7bf-bf97-42bb-958b-4e64eff40169/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 43116,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/04d3bdfe-9984-4d8d-92b7-935b2fe18775/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/04d3bdfe-9984-4d8d-92b7-935b2fe18775/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/04d3bdfe-9984-4d8d-92b7-935b2fe18775/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 43118,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/54892798-2800-4c7f-8331-4dca9e1ba744/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/54892798-2800-4c7f-8331-4dca9e1ba744/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/54892798-2800-4c7f-8331-4dca9e1ba744/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 43126,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c2626899-7745-46b0-b029-5dae40c02790/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c2626899-7745-46b0-b029-5dae40c02790/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c2626899-7745-46b0-b029-5dae40c02790/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 43112,
        "title": "Is Gili Lankanfushi the perfect Maldivian resort? Review by ...",
        "url": "https://pkslist.azureedge.net/media/images/1/37ff16ec-d521-4906-a12c-2ecaf9e53499/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/37ff16ec-d521-4906-a12c-2ecaf9e53499/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/37ff16ec-d521-4906-a12c-2ecaf9e53499/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 43117,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/7cd40763-ab81-4cc2-bdec-3db545c1f7c3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7cd40763-ab81-4cc2-bdec-3db545c1f7c3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7cd40763-ab81-4cc2-bdec-3db545c1f7c3/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 43123,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ae077732-ca82-485a-9dde-e2166f83749e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ae077732-ca82-485a-9dde-e2166f83749e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ae077732-ca82-485a-9dde-e2166f83749e/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 43127,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/46bc1c68-ab9a-4122-bb74-9c0b913ba375/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/46bc1c68-ab9a-4122-bb74-9c0b913ba375/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/46bc1c68-ab9a-4122-bb74-9c0b913ba375/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 43129,
        "title": "Gili Lankanfushi Maldives | Top Luxury Resort in Maldives",
        "url": "https://pkslist.azureedge.net/media/images/1/95ab3387-6d7a-4f33-aa90-a7190fc306a9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/95ab3387-6d7a-4f33-aa90-a7190fc306a9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/95ab3387-6d7a-4f33-aa90-a7190fc306a9/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 43125,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/29c5f231-6774-48c0-9958-f62c625dd18c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/29c5f231-6774-48c0-9958-f62c625dd18c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/29c5f231-6774-48c0-9958-f62c625dd18c/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 43119,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/bacbc946-0da3-4f38-af6e-eb7d46b25a5c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bacbc946-0da3-4f38-af6e-eb7d46b25a5c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bacbc946-0da3-4f38-af6e-eb7d46b25a5c/256x192.jpg",
        "viewOrder": 11
      },
      {
        "mediaId": 43121,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/3a474beb-6c29-4944-9de3-e07456124522/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3a474beb-6c29-4944-9de3-e07456124522/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3a474beb-6c29-4944-9de3-e07456124522/256x192.jpg",
        "viewOrder": 12
      }
    ],
    "geographicArea": {
      "geographicAreaId": 161,
      "latitude": 4.295320799999999,
      "longitude": 73.55830860000003,
      "parentGeographicAreaId": 0,
      "title": "Lankanfushi, Maldives",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 50,
        "tagName": "Barefoot Luxury",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 58,
        "tagName": "Beach",
        "tagType": 2
      },
      {
        "tagId": 69,
        "tagName": "Couples",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 109,
        "tagName": "Fishing",
        "tagType": 2
      },
      {
        "tagId": 116,
        "tagName": "Scuba Diving",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 193,
        "tagName": "Outdoor Dining",
        "tagType": 2
      },
      {
        "tagId": 198,
        "tagName": "Boat Excursion",
        "tagType": 2
      },
      {
        "tagId": 410,
        "tagName": "Sundownders",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 533,
        "venueCategoryId": 5,
        "venueId": 300,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 5,
          "title": "Romantic Getaways",
          "region": "Global",
          "viewOrder": 20,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 535,
        "venueCategoryId": 15,
        "venueId": 300,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 15,
          "title": "Beach Paradise",
          "region": "Global",
          "viewOrder": 6,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 659,
        "venueCategoryId": 33,
        "venueId": 300,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 33,
          "title": "Rustic Elegance",
          "region": "Global",
          "viewOrder": 11,
          "updated": "2020-09-04T09:04:00",
          "created": "2020-09-04T09:04:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 228,
    "venueCategoryId": 2,
    "status": 1,
    "title": "The Wild Rabbit",
    "description": "The Wild Rabbit is a re-invention of the traditional English inn – a place to eat, drink and sleep. Set in the heart of the Cotswolds, serving locally sourced, seasonal food in a relaxed and welcoming setting.\n\nThe rooms combine pared back style with honest creature comforts: excellent mattresses, luxurious bathrooms and fine linen sheets. Each room is different, taking their names from the British countryside, and illustrated with whimsical linocuts by Hugo Guinness.\n\nThe beautiful Cotswold cottages are located a short walk away from The Wild Rabbit, in the quiet village of Kingham, and are all individually furnished as a home-away-from-home. Each cottage has two double or twin bedrooms with en-suite bathrooms, a fully equipped kitchen, eating area and sitting rooms with open fires or log burning stoves.",
    "brand": "",
    "blockQuote": "A haven of eco-elegance, which might just be the poshest pub in Britain.",
    "emailAddress": "theteam@thewildrabbit.co.uk",
    "website": "thewildrabbit.co.uk",
    "bookingURL": "https://thewildrabbit.co.uk/room-search/",
    "redirectNotes": "",
    "phoneNumber": "+44 01608 658389",
    "addressOne": "",
    "addressTwo": "Church Street",
    "city": "Kingham",
    "county": "Cotswolds",
    "region": "England",
    "country": "United Kingdom",
    "rooms": 17,
    "dollar": 1,
    "updated": "2020-09-29T11:48:54.863",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 483,
        "title": "Best new hotels and restaurants in the Cotswolds (Condé Nast ...",
        "url": "https://pkslist.azureedge.net/media/images/1/281651e5-cd72-4864-8dac-e7ecb18efeb9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/281651e5-cd72-4864-8dac-e7ecb18efeb9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/281651e5-cd72-4864-8dac-e7ecb18efeb9/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 40320,
        "title": "The Wild Rabbit, Kingham, Cotswolds | Wild rabbit, Cotswolds ...",
        "url": "https://pkslist.azureedge.net/media/images/1/1870629b-db26-4224-b6ee-61f6a332c8fc/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1870629b-db26-4224-b6ee-61f6a332c8fc/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1870629b-db26-4224-b6ee-61f6a332c8fc/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 40321,
        "title": "The Wild Rabbit | SPENCER FUNG ARCHITECTS",
        "url": "https://pkslist.azureedge.net/media/images/1/b2125260-6125-4a22-b3dd-0a8d371f79a3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b2125260-6125-4a22-b3dd-0a8d371f79a3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b2125260-6125-4a22-b3dd-0a8d371f79a3/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 40314,
        "title": "The Wild Rabbit Hotel Review, Cotswolds, Oxfordshire | Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/8cb960ed-3c39-4677-a9f2-0f2b88ebb41b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8cb960ed-3c39-4677-a9f2-0f2b88ebb41b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8cb960ed-3c39-4677-a9f2-0f2b88ebb41b/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 25736,
        "title": "The Beehive - The Wild Rabbit",
        "url": "https://pkslist.azureedge.net/media/images/1/15c249ac-a82a-4e29-9253-5f03bb8c3420/1200x900.jpg?resize=1600%2C850&ssl=1",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/15c249ac-a82a-4e29-9253-5f03bb8c3420/800x600.jpg?resize=1600%2C850&ssl=1",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/15c249ac-a82a-4e29-9253-5f03bb8c3420/256x192.jpg?resize=1600%2C850&ssl=1",
        "viewOrder": 3
      },
      {
        "mediaId": 25732,
        "title": "The Wild Rabbit Hotel Review, Cotswolds, Oxfordshire | Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/41b80f10-4d33-4a30-a7ae-e46ad79701eb/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/41b80f10-4d33-4a30-a7ae-e46ad79701eb/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/41b80f10-4d33-4a30-a7ae-e46ad79701eb/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 25734,
        "title": "The Wild Rabbit in Kingham « Luxury Hotels TravelPlusStyle",
        "url": "https://pkslist.azureedge.net/media/images/1/638fa8ab-86d9-4d8a-bc1d-91280542aaef/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/638fa8ab-86d9-4d8a-bc1d-91280542aaef/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/638fa8ab-86d9-4d8a-bc1d-91280542aaef/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 25735,
        "title": "The Wild Rabbit | Restaurants & Hotels | Pinterest | Cereal ...",
        "url": "https://pkslist.azureedge.net/media/images/1/0245a01d-3dde-4792-a99a-f26fb79d6bdf/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0245a01d-3dde-4792-a99a-f26fb79d6bdf/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0245a01d-3dde-4792-a99a-f26fb79d6bdf/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 40315,
        "title": "New Cottages At The Wild Rabbit - The Wild Rabbit",
        "url": "https://pkslist.azureedge.net/media/images/1/a01b5055-d52c-4ee6-bef0-d069e51c7281/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a01b5055-d52c-4ee6-bef0-d069e51c7281/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a01b5055-d52c-4ee6-bef0-d069e51c7281/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 98,
      "latitude": 51.9128766,
      "longitude": -1.6237487999999303,
      "parentGeographicAreaId": 0,
      "title": "Church St, Kingham, Chipping Norton OX7 6YA, UK",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 47,
        "tagName": "Intimate Luxury ",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 31,
        "tagName": "Countryside",
        "tagType": 2
      },
      {
        "tagId": 34,
        "tagName": "Cosy",
        "tagType": 2
      },
      {
        "tagId": 77,
        "tagName": "Cooking Classes",
        "tagType": 2
      },
      {
        "tagId": 85,
        "tagName": "Pet Friendly",
        "tagType": 2
      },
      {
        "tagId": 114,
        "tagName": "Pub",
        "tagType": 2
      },
      {
        "tagId": 120,
        "tagName": "Private Dining",
        "tagType": 2
      },
      {
        "tagId": 156,
        "tagName": "Fireplace",
        "tagType": 2
      },
      {
        "tagId": 172,
        "tagName": "Biking",
        "tagType": 2
      },
      {
        "tagId": 252,
        "tagName": "National Park",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 689,
        "venueCategoryId": 33,
        "venueId": 228,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 33,
          "title": "Rustic Elegance",
          "region": "Global",
          "viewOrder": 11,
          "updated": "2020-09-04T09:04:00",
          "created": "2020-09-04T09:04:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  }
]
// 20240123100509
// https://app.pks-list.com/api/Venue/v1/getlist/35

export const placesStore10=
[
  {
    "venueId": 3383,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Marina Bay Sands",
    "description": "Indulge in the best experiences at Singapore’s most iconic hotel, Marina Bay Sands home to the world’s largest Infinity Pool. Located along Marina Bay waterfront with 3 cascading hotel towers, 2,560 rooms, extraordinary Sands Sky Park, floating crystal pavilions, lotus-inspired ArtScience Museum, The Shoppes, Celebrity Chef Restaurants, Theatres and hottest nightclubs.",
    "brand": "",
    "blockQuote": "Go for a swim with a dazzling view in the world's largest rooftop pool.",
    "emailAddress": "room.reservations@marinabaysands.com",
    "website": "marinabaysands.com/hotel.html",
    "bookingURL": "https://www.marinabaysands.com/booking/rooms.html?CheckinDate=2022-03-15&LOS=1&Rooms=1&Adults_1=1&Children_1=0&locale=en&offerCode=&flow=tf&Currency=SGD&multi=false&iata=96027890",
    "redirectNotes": "Marina Bay Sands' reservations system",
    "phoneNumber": "+65 6688 8888",
    "addressOne": "10",
    "addressTwo": "Bayfront Avenue",
    "city": "Singapore",
    "county": "",
    "region": "",
    "country": "Singapore",
    "rooms": 2560,
    "dollar": 2,
    "updated": "2022-03-15T08:13:15.907",
    "created": "2022-03-15T07:48:37.073",
    "medias": [
      {
        "mediaId": 52961,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/20b0f76c-9ebb-447d-a996-d7adfee4997a/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/20b0f76c-9ebb-447d-a996-d7adfee4997a/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/20b0f76c-9ebb-447d-a996-d7adfee4997a/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 52963,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/140d8bea-5506-44fc-aaf7-54366599dd5a/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/140d8bea-5506-44fc-aaf7-54366599dd5a/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/140d8bea-5506-44fc-aaf7-54366599dd5a/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 52953,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/6f15092b-997a-441b-a799-b71bdce1d8d9/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6f15092b-997a-441b-a799-b71bdce1d8d9/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6f15092b-997a-441b-a799-b71bdce1d8d9/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 52956,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a7a8c272-ac93-4e1e-80da-07711a51f151/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a7a8c272-ac93-4e1e-80da-07711a51f151/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a7a8c272-ac93-4e1e-80da-07711a51f151/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 52954,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/42f60c58-b68f-4158-8ec3-9dc1bd6771c5/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/42f60c58-b68f-4158-8ec3-9dc1bd6771c5/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/42f60c58-b68f-4158-8ec3-9dc1bd6771c5/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 52955,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/db1729a7-bdec-4762-8704-84f51b8546af/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/db1729a7-bdec-4762-8704-84f51b8546af/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/db1729a7-bdec-4762-8704-84f51b8546af/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 52959,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/09299246-140e-40ef-b520-bf9eeed870d3/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/09299246-140e-40ef-b520-bf9eeed870d3/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/09299246-140e-40ef-b520-bf9eeed870d3/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 52952,
        "title": "Orchid Suite - Singapore Hotel | Singapore Luxury Hotel | Marina ...",
        "url": "https://pkslist.azureedge.net/media/images/1/7a1d7387-b202-4da6-9605-8d6f59609e40/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7a1d7387-b202-4da6-9605-8d6f59609e40/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7a1d7387-b202-4da6-9605-8d6f59609e40/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 52962,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a52a7bfe-3939-4f84-a47c-755607f21232/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a52a7bfe-3939-4f84-a47c-755607f21232/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a52a7bfe-3939-4f84-a47c-755607f21232/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 52964,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/6dd409ff-e410-4702-b37e-c57c63495074/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6dd409ff-e410-4702-b37e-c57c63495074/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6dd409ff-e410-4702-b37e-c57c63495074/256x192.jpeg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3171,
      "latitude": 1.2846547,
      "longitude": 103.8609937,
      "parentGeographicAreaId": 0,
      "title": "10 Bayfront Ave, Singapore 018956",
      "updated": null,
      "created": "2022-03-15T07:56:00"
    },
    "personalities": [
      {
        "tagId": 48,
        "tagName": "Iconic/Landmark ",
        "tagType": 1
      },
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 62,
        "tagName": "Nightlife",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 68,
        "tagName": "Business",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 74,
        "tagName": "Casino",
        "tagType": 2
      },
      {
        "tagId": 84,
        "tagName": "Nightclub",
        "tagType": 2
      },
      {
        "tagId": 154,
        "tagName": "Rooftop Pool",
        "tagType": 2
      },
      {
        "tagId": 216,
        "tagName": "Museum",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 312,
        "tagName": "Outdoor Market",
        "tagType": 2
      },
      {
        "tagId": 323,
        "tagName": "City Walk",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 752,
        "venueCategoryId": 10,
        "venueId": 3383,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 10,
          "title": "Infinity Pools",
          "region": "Global",
          "viewOrder": 2,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2947,
    "venueCategoryId": 0,
    "status": 1,
    "title": "The Beige",
    "description": "The Beige is a luxury tent resort located in the lush environs of Angkor. Ensconced in a region replete with UNESCO World Heritage Sites like Angkor Wat, this resort evokes a feel for the finest of Khmer history. The space on a sprawling expanse totalling ten hectares, there are only ten guest rooms. Our tent fabric resembles the sailcloth of a yacht – unyielding in wind, though lightweight and durable. Breezes pass softly through the open interior, as relaxing reminders of nature all around.",
    "brand": "",
    "blockQuote": "Feels like you're flying instead of swimming when you're in the hotel's unique floating forest pool.",
    "emailAddress": "info@the-beige.com",
    "website": "the-beige.com/",
    "bookingURL": "http://www.booking.com/Share-zTe6EB?aid=925669",
    "redirectNotes": "",
    "phoneNumber": "+855 99 297 298",
    "addressOne": "",
    "addressTwo": "",
    "city": "Siem Reap",
    "county": "",
    "region": "",
    "country": "Cambodia",
    "rooms": 10,
    "dollar": 2,
    "updated": "2020-11-08T13:30:41.19",
    "created": "2020-11-08T12:09:20.037",
    "medias": [
      {
        "mediaId": 41956,
        "title": "THE BEIGE Luxury Tent Resort – in Siem Reap , Cambodia",
        "url": "https://pkslist.azureedge.net/media/images/1/e6938635-9f94-429e-aa65-e7d11ba14bdc/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e6938635-9f94-429e-aa65-e7d11ba14bdc/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e6938635-9f94-429e-aa65-e7d11ba14bdc/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 41966,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/87b35c97-0bc6-43b3-b3cb-3d7f1cac50f9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/87b35c97-0bc6-43b3-b3cb-3d7f1cac50f9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/87b35c97-0bc6-43b3-b3cb-3d7f1cac50f9/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 41957,
        "title": "THE BEIGE Luxury Tent Resort – in Siem Reap , Cambodia",
        "url": "https://pkslist.azureedge.net/media/images/1/8ce60839-fcb8-4851-994a-1452323405c5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8ce60839-fcb8-4851-994a-1452323405c5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8ce60839-fcb8-4851-994a-1452323405c5/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 41968,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/6c9bc5a9-36c4-42fb-9d2b-49e1d4152186/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6c9bc5a9-36c4-42fb-9d2b-49e1d4152186/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6c9bc5a9-36c4-42fb-9d2b-49e1d4152186/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 41967,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/5a13f632-14cc-4efc-8fcc-8015de6c0d7a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5a13f632-14cc-4efc-8fcc-8015de6c0d7a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5a13f632-14cc-4efc-8fcc-8015de6c0d7a/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 41955,
        "title": "THE BEIGE Luxury Tent Resort – in Siem Reap , Cambodia",
        "url": "https://pkslist.azureedge.net/media/images/1/f3b4645d-e12c-4e9b-89bc-48497d8d2ac7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f3b4645d-e12c-4e9b-89bc-48497d8d2ac7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f3b4645d-e12c-4e9b-89bc-48497d8d2ac7/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 41962,
        "title": "The Beige - Siem Reap - Kobu",
        "url": "https://pkslist.azureedge.net/media/images/1/a74c1163-000f-4a02-bb0c-2add33b4835e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a74c1163-000f-4a02-bb0c-2add33b4835e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a74c1163-000f-4a02-bb0c-2add33b4835e/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 41964,
        "title": "THE BEIGE Luxury Tent Resort – in Siem Reap , Cambodia",
        "url": "https://pkslist.azureedge.net/media/images/1/d6d655ac-f1e9-4d94-a713-da95c30b24ff/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d6d655ac-f1e9-4d94-a713-da95c30b24ff/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d6d655ac-f1e9-4d94-a713-da95c30b24ff/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 41965,
        "title": "Pin on Casas",
        "url": "https://pkslist.azureedge.net/media/images/1/01ea78f5-20e7-449d-b790-5e1b68ddd538/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/01ea78f5-20e7-449d-b790-5e1b68ddd538/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/01ea78f5-20e7-449d-b790-5e1b68ddd538/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 41963,
        "title": "Pin on Casas",
        "url": "https://pkslist.azureedge.net/media/images/1/6c35a499-eda6-4e14-9470-81d3bf52e61d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6c35a499-eda6-4e14-9470-81d3bf52e61d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6c35a499-eda6-4e14-9470-81d3bf52e61d/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 41960,
        "title": "THE BEIGE Luxury Tent Resort – in Siem Reap , Cambodia ในปี 2020",
        "url": "https://pkslist.azureedge.net/media/images/1/630bd4e4-49e7-481a-a7f6-915b975e794e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/630bd4e4-49e7-481a-a7f6-915b975e794e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/630bd4e4-49e7-481a-a7f6-915b975e794e/256x192.jpg",
        "viewOrder": 11
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2732,
      "latitude": 13.503674,
      "longitude": 103.827665,
      "parentGeographicAreaId": 0,
      "title": "13°30'14.6\"N 103°49'39., PREAK KO TMEY VILLAGE,, SVAY CHEK Cominue,, Angkor Thom District,, Krong Siem Reap 17604, Cambodia",
      "updated": null,
      "created": "2020-11-08T12:25:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 359,
        "tagName": "Colonial Chic",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 59,
        "tagName": "Cultural",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 133,
        "tagName": "Glamping",
        "tagType": 2
      },
      {
        "tagId": 172,
        "tagName": "Biking",
        "tagType": 2
      },
      {
        "tagId": 181,
        "tagName": "Private/Suite Pools",
        "tagType": 2
      },
      {
        "tagId": 259,
        "tagName": "Picnic",
        "tagType": 2
      },
      {
        "tagId": 348,
        "tagName": "Unesco World Heritage",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 696,
        "venueCategoryId": 10,
        "venueId": 2947,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 10,
          "title": "Infinity Pools",
          "region": "Global",
          "viewOrder": 2,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2706,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Alpin Panorama Hotel Hubertus",
    "description": "Between heaven and earth, in a picturesque place amidst the Dolomites, lies our unique hotel in Olang. A place of peace. A place of joy. A place of inspiration. In front of our hotel in Olang, the Alpin Panorama Hotel Hubertus: the imposing Dolomite peaks with their impressive rock faces, who are silent witnesses to the forces of nature that shaped this special region. Borders disappear against this backdrop; borders between architecture and landscape, heaven and earth, man and nature. Take a break in our hotel in Olang and become one with yourself and with the surrounding nature.",
    "brand": "",
    "blockQuote": "The sky infinity pool is a hydrophile's heaven.",
    "emailAddress": "info@hotel-hubertus.com",
    "website": "hotel-hubertus.com/en/",
    "bookingURL": "https://www.hotel-hubertus.com/en/living/current-rates?fromdate=14.11.2021&todate=21.11.2021",
    "redirectNotes": "Alpin Panorama Hotel Hubertus' reservations system",
    "phoneNumber": "+39 0474 59 21 04",
    "addressOne": "5",
    "addressTwo": "Furkelstraße",
    "city": "Sorafurcia",
    "county": "",
    "region": "",
    "country": "Italy",
    "rooms": 64,
    "dollar": 2,
    "updated": "2022-07-28T10:50:18.657",
    "created": "2020-05-15T12:54:27.6",
    "medias": [
      {
        "mediaId": 54219,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/77848b12-7525-4849-af92-e87c75b8314d/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/77848b12-7525-4849-af92-e87c75b8314d/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/77848b12-7525-4849-af92-e87c75b8314d/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 54227,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/935ba471-55d7-48e2-aa8f-1004c27cb627/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/935ba471-55d7-48e2-aa8f-1004c27cb627/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/935ba471-55d7-48e2-aa8f-1004c27cb627/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 54225,
        "title": "Alpin Panorama Hotel Hubertus in Olang - 4S stars Hotel - south ...",
        "url": "https://pkslist.azureedge.net/media/images/1/07751081-33bf-407b-9431-4bf45c3e7e57/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/07751081-33bf-407b-9431-4bf45c3e7e57/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/07751081-33bf-407b-9431-4bf45c3e7e57/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 54221,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/34cb3d7d-6f54-4d56-af70-f61e1f5959c6/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/34cb3d7d-6f54-4d56-af70-f61e1f5959c6/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/34cb3d7d-6f54-4d56-af70-f61e1f5959c6/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 54226,
        "title": "Alpin Panorama Hotel Hubertus in Olang - 4S stars Hotel - south ...",
        "url": "https://pkslist.azureedge.net/media/images/1/03415d66-f517-40a9-b4c2-3e2875322d21/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/03415d66-f517-40a9-b4c2-3e2875322d21/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/03415d66-f517-40a9-b4c2-3e2875322d21/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 54218,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ed2ca8d3-9ae9-4d8b-aff1-a4a2dfc4c18f/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ed2ca8d3-9ae9-4d8b-aff1-a4a2dfc4c18f/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ed2ca8d3-9ae9-4d8b-aff1-a4a2dfc4c18f/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 54223,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/58456ab7-6fef-443b-aa73-bf9b94ddd9ac/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/58456ab7-6fef-443b-aa73-bf9b94ddd9ac/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/58456ab7-6fef-443b-aa73-bf9b94ddd9ac/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 50715,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c5d12ad5-83de-4cbe-a4f7-eecf55d47db8/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c5d12ad5-83de-4cbe-a4f7-eecf55d47db8/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c5d12ad5-83de-4cbe-a4f7-eecf55d47db8/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 36010,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c5d0dce9-95f4-4860-9978-39933c5cd295/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c5d0dce9-95f4-4860-9978-39933c5cd295/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c5d0dce9-95f4-4860-9978-39933c5cd295/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 50714,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/92477db2-3bb5-4923-b98c-244c042feff2/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/92477db2-3bb5-4923-b98c-244c042feff2/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/92477db2-3bb5-4923-b98c-244c042feff2/256x192.jpeg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2493,
      "latitude": 46.7537444,
      "longitude": 12.0099917,
      "parentGeographicAreaId": 0,
      "title": "Furkelstraße, 5, 39030 Geiselsberg, Südtirol, Italy",
      "updated": null,
      "created": "2020-05-15T13:10:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 53,
        "tagName": "Cutting Edge",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 66,
        "tagName": "Skiing",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 151,
        "tagName": "Salt water pool",
        "tagType": 2
      },
      {
        "tagId": 203,
        "tagName": "Sauna",
        "tagType": 2
      },
      {
        "tagId": 219,
        "tagName": "Wine Cellar",
        "tagType": 2
      },
      {
        "tagId": 243,
        "tagName": "Outdoor Activities",
        "tagType": 2
      },
      {
        "tagId": 284,
        "tagName": "Architecture",
        "tagType": 2
      },
      {
        "tagId": 299,
        "tagName": "Wellness Programs",
        "tagType": 2
      },
      {
        "tagId": 348,
        "tagName": "Unesco World Heritage",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 550,
        "venueCategoryId": 10,
        "venueId": 2706,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 10,
          "title": "Infinity Pools",
          "region": "Global",
          "viewOrder": 2,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 741,
        "venueCategoryId": 32,
        "venueId": 2706,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1831,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Hotel Villa Honegg",
    "description": "Hotel Villa Honegg is a 5-star Superior Hotel with 23 rooms furnished to excellent standards. High above Lake Lucerne you will find relaxation and privacy in a unique setting.\n\nThe Hotel Villa Honegg has 23 comfortable rooms and suites to suit a discerning clientele. Featuring a pleasant blend of natural materials and elegance, the decor and furnishings create an inviting ambience fostering relaxation and a sense of well-being.",
    "brand": "",
    "blockQuote": "Take in views of the mountains and lake from the 34ºC heated outdoor infinity pool.",
    "emailAddress": "info@villa-honegg.ch",
    "website": "villa-honegg.ch/en/",
    "bookingURL": "https://villa-honegg.ch/en/online-booking/",
    "redirectNotes": null,
    "phoneNumber": "+41 41 618 32 00",
    "addressOne": "",
    "addressTwo": "",
    "city": "Ennetburgen",
    "county": "",
    "region": "",
    "country": "Switzerland",
    "rooms": 23,
    "dollar": 2,
    "updated": "2020-05-16T13:23:10.167",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 21773,
        "title": "Hotel Villa Honegg - Destination Deluxe",
        "url": "https://pkslist.azureedge.net/media/images/1/a95c008b-65f9-4484-9e14-8f34927e69a9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a95c008b-65f9-4484-9e14-8f34927e69a9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a95c008b-65f9-4484-9e14-8f34927e69a9/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 21776,
        "title": "The boutique hotel in the heart of Switzerland | Hotel Villa ...",
        "url": "https://pkslist.azureedge.net/media/images/1/71d554a2-e07a-49dc-af15-9d6d911b5ac2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/71d554a2-e07a-49dc-af15-9d6d911b5ac2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/71d554a2-e07a-49dc-af15-9d6d911b5ac2/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 21778,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f06efcc7-64e9-4b66-a79b-4b96f33f29ed/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f06efcc7-64e9-4b66-a79b-4b96f33f29ed/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f06efcc7-64e9-4b66-a79b-4b96f33f29ed/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 21768,
        "title": "Villa Honegg, Ennetbürgen, Switzerland - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/da42c500-0bb5-4032-908c-41f789bfa9dc/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/da42c500-0bb5-4032-908c-41f789bfa9dc/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/da42c500-0bb5-4032-908c-41f789bfa9dc/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 21775,
        "title": "Restaurant and terrace lounge | Hotel Villa Honegg Bürgenstock",
        "url": "https://pkslist.azureedge.net/media/images/1/bb5222c5-5591-4744-a2cc-7c3d9fa6e747/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bb5222c5-5591-4744-a2cc-7c3d9fa6e747/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bb5222c5-5591-4744-a2cc-7c3d9fa6e747/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 21779,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/bcea6f86-4860-495a-8719-f6f581855b1f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bcea6f86-4860-495a-8719-f6f581855b1f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bcea6f86-4860-495a-8719-f6f581855b1f/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 21766,
        "title": "Villa Honegg, Ennetbürgen, Switzerland - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/31072737-b63e-4dee-8076-cf2c1bd381b2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/31072737-b63e-4dee-8076-cf2c1bd381b2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/31072737-b63e-4dee-8076-cf2c1bd381b2/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 21777,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b85bd194-c76c-47c9-84cf-b506fdbba526/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b85bd194-c76c-47c9-84cf-b506fdbba526/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b85bd194-c76c-47c9-84cf-b506fdbba526/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 21770,
        "title": "Other ski resorts will pale in comparison after seeing this place ...",
        "url": "https://pkslist.azureedge.net/media/images/1/459944c6-8d94-480a-bf52-0cfda9213bac/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/459944c6-8d94-480a-bf52-0cfda9213bac/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/459944c6-8d94-480a-bf52-0cfda9213bac/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1646,
      "latitude": 46.99481489999999,
      "longitude": 8.403009500000053,
      "parentGeographicAreaId": 0,
      "title": "6373 Ennetbürgen, Switzerland",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 40,
        "tagName": "Boutique Bolthole ",
        "tagType": 1
      },
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 75,
        "tagName": "Casual Dining",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 101,
        "tagName": "Cinema",
        "tagType": 2
      },
      {
        "tagId": 156,
        "tagName": "Fireplace",
        "tagType": 2
      },
      {
        "tagId": 191,
        "tagName": "Cigar Bar",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 212,
        "tagName": "Hammam",
        "tagType": 2
      },
      {
        "tagId": 276,
        "tagName": "Gym",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 552,
        "venueCategoryId": 10,
        "venueId": 1831,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 10,
          "title": "Infinity Pools",
          "region": "Global",
          "viewOrder": 2,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 606,
        "venueCategoryId": 32,
        "venueId": 1831,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1803,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Burgenstock Hotel & Alpine Spa",
    "description": "Experience the breathtaking beauty of Lake Lucerne and warm Swiss hospitality at the Bürgenstock Hotel, one of the most elegant hotels in the region.\n\nThe newly built Bürgenstock Hotel & Alpine Spa features modern architecture and contemporary design in the 102 rooms and suites with incredible views far above Lake Lucerne. The spacious rooms ranging from 42 to 678 sqm are all equipped with exquisite materials and stylish designer furniture.",
    "brand": "",
    "blockQuote": "Book a visit to the Alpine Spa just so you can admire the panorama view from 500 metres above Lake Lucerne.",
    "emailAddress": "reservations@buergenstock.ch",
    "website": "buergenstock.ch/en/hotels/buergenstockhotel",
    "bookingURL": "https://www.myhotelreservation.net/b/hllw1630/HLLW1630/?f=2018-06-14&t=2018-06-15&i=96027890",
    "redirectNotes": "Burgenstock Hotel's reservations system",
    "phoneNumber": "+41 41 612 60 00",
    "addressOne": "17",
    "addressTwo": "Bürgenstock",
    "city": "Burgenstock",
    "county": "",
    "region": "",
    "country": "Switzerland",
    "rooms": 102,
    "dollar": 3,
    "updated": "2020-05-24T17:47:30.66",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 37391,
        "title": "swisshoteldata.ch - Swiss hotel directory",
        "url": "https://pkslist.azureedge.net/media/images/1/4c55f9ba-673d-492d-916d-0ba8d081a9bb/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4c55f9ba-673d-492d-916d-0ba8d081a9bb/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4c55f9ba-673d-492d-916d-0ba8d081a9bb/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 37393,
        "title": "Bürgenstock Hotel, Switzerland - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/e9546c02-6d63-4aae-afea-c0cef709da70/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e9546c02-6d63-4aae-afea-c0cef709da70/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e9546c02-6d63-4aae-afea-c0cef709da70/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 21308,
        "title": "Grand Hotel Burgenstock Hotel & SPA | La Suisse/Switzerland My ...",
        "url": "https://pkslist.azureedge.net/media/images/1/9460d421-2678-4fcb-871a-7343b65d74a8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9460d421-2678-4fcb-871a-7343b65d74a8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9460d421-2678-4fcb-871a-7343b65d74a8/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 21310,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c74a9a3a-2b8b-48fd-981c-86f4e0c0745c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c74a9a3a-2b8b-48fd-981c-86f4e0c0745c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c74a9a3a-2b8b-48fd-981c-86f4e0c0745c/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 21424,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/530a5b2d-d269-4616-bb66-6e1d3492ba83/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/530a5b2d-d269-4616-bb66-6e1d3492ba83/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/530a5b2d-d269-4616-bb66-6e1d3492ba83/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 21425,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/7df38270-92dd-427a-b504-799fb2b59add/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7df38270-92dd-427a-b504-799fb2b59add/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7df38270-92dd-427a-b504-799fb2b59add/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 21306,
        "title": "Hotels & Residences - Bürgenstock Resort Luzern",
        "url": "https://pkslist.azureedge.net/media/images/1/c49442df-0ffb-4d26-99fd-f288036082b8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c49442df-0ffb-4d26-99fd-f288036082b8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c49442df-0ffb-4d26-99fd-f288036082b8/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 21305,
        "title": "Bürgenstock Hotels & Resort - Palace Hotel, Waldhotel, Taverne ...",
        "url": "https://pkslist.azureedge.net/media/images/1/9b153857-97d5-42d7-86f1-3747e0813a78/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9b153857-97d5-42d7-86f1-3747e0813a78/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9b153857-97d5-42d7-86f1-3747e0813a78/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 21304,
        "title": "Bürgenstock Hotels & Resort Lake Lucerne - Bürgenstock Resort Luzern",
        "url": "https://pkslist.azureedge.net/media/images/1/f73ea697-99f4-480e-ac25-41d3d08c097d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f73ea697-99f4-480e-ac25-41d3d08c097d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f73ea697-99f4-480e-ac25-41d3d08c097d/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1618,
      "latitude": 46.9967744,
      "longitude": 8.379383599999983,
      "parentGeographicAreaId": 0,
      "title": "Bürgenstock 17, 6363 Bürgenstock, Switzerland",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 55,
        "tagName": "Lively",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 61,
        "tagName": "Mountain",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 66,
        "tagName": "Skiing",
        "tagType": 2
      },
      {
        "tagId": 68,
        "tagName": "Business",
        "tagType": 2
      },
      {
        "tagId": 81,
        "tagName": "Golf",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 88,
        "tagName": "Tennis",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 101,
        "tagName": "Cinema",
        "tagType": 2
      },
      {
        "tagId": 153,
        "tagName": "Cigar Lounge",
        "tagType": 2
      },
      {
        "tagId": 244,
        "tagName": "Members' Club",
        "tagType": 2
      },
      {
        "tagId": 281,
        "tagName": "Ice Skating",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 551,
        "venueCategoryId": 10,
        "venueId": 1803,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 10,
          "title": "Infinity Pools",
          "region": "Global",
          "viewOrder": 2,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 607,
        "venueCategoryId": 32,
        "venueId": 1803,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1608,
    "venueCategoryId": 10,
    "status": 1,
    "title": "Park Hyatt Bangkok",
    "description": "Embracing its enviable location in the world’s most popular tourist destination (according to the MasterCard Global Destination Cities Index 2016), Park Hyatt Bangkok promises to offer a refined and uniquely Thai experience, reflecting the country’s rich culture and legendary gracious hospitality. A devoted team of hosts dedicates themselves to providing Park Hyatt’s world-class discreet and sincere service. Iconic architecture, sophisticated interiors, spacious guestrooms, and exceptional drinking and dining venues all provide a level of service and hospitality that is unparalleled in the market.",
    "brand": "Hyatt",
    "blockQuote": "The Penthouse Bar + Grill is spread over three-floors with a rooftop terrace that has sweeping views of the city.",
    "emailAddress": "bangkok.park@hyatt.com",
    "website": "hyatt.com/en-US/hotel/thailand/park-hyatt-bangkok/bkkph",
    "bookingURL": "http://www.booking.com/Share-E6nWqU?aid=925669",
    "redirectNotes": null,
    "phoneNumber": "+66 2 012 1234",
    "addressOne": "",
    "addressTwo": "",
    "city": "Bangkok",
    "county": "",
    "region": "",
    "country": "Thailand",
    "rooms": 222,
    "dollar": 2,
    "updated": "2020-05-29T11:53:28.337",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 18049,
        "title": "Michael Kors Jing An | cor 4 | Pinterest | Michael kors, Luxury ...",
        "url": "https://pkslist.azureedge.net/media/images/1/add5cbb0-1dd3-4893-86ce-0e859071045e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/add5cbb0-1dd3-4893-86ce-0e859071045e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/add5cbb0-1dd3-4893-86ce-0e859071045e/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 18045,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/1685f6f2-6f09-45b2-90fa-b4989142c703/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1685f6f2-6f09-45b2-90fa-b4989142c703/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1685f6f2-6f09-45b2-90fa-b4989142c703/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 18042,
        "title": "Park Hyatt Bangkok, Thailand | Traveller Made",
        "url": "https://pkslist.azureedge.net/media/images/1/1f32442e-8deb-4968-b54e-fe4bb49d7ab7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1f32442e-8deb-4968-b54e-fe4bb49d7ab7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1f32442e-8deb-4968-b54e-fe4bb49d7ab7/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 18043,
        "title": "LuxExpose Park-Hyatt-Bangkok-8 - Lux Exposé",
        "url": "https://pkslist.azureedge.net/media/images/1/1454d839-c66c-4a97-b668-51f5f47bf03a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1454d839-c66c-4a97-b668-51f5f47bf03a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1454d839-c66c-4a97-b668-51f5f47bf03a/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 18037,
        "title": "Hotel Park Hyatt Bangkok, Thailand - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/f7fd7225-8928-48b9-a4df-6b60a008c212/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f7fd7225-8928-48b9-a4df-6b60a008c212/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f7fd7225-8928-48b9-a4df-6b60a008c212/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 18047,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e83716ae-38f8-403e-9dbf-019b37a53ad4/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e83716ae-38f8-403e-9dbf-019b37a53ad4/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e83716ae-38f8-403e-9dbf-019b37a53ad4/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 18044,
        "title": "Park Hyatt Bangkok, Thailand | Traveller Made",
        "url": "https://pkslist.azureedge.net/media/images/1/4fef5214-13d8-49ec-b5e9-f5b4e8a2228a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4fef5214-13d8-49ec-b5e9-f5b4e8a2228a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4fef5214-13d8-49ec-b5e9-f5b4e8a2228a/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 18040,
        "title": "Hotel Park Hyatt Bangkok, Thailand - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/b1d54f36-8474-4898-9a48-44e0db865240/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b1d54f36-8474-4898-9a48-44e0db865240/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b1d54f36-8474-4898-9a48-44e0db865240/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 18046,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/9f8c2864-2fe6-40d8-a46f-a4f73968d636/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9f8c2864-2fe6-40d8-a46f-a4f73968d636/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9f8c2864-2fe6-40d8-a46f-a4f73968d636/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1422,
      "latitude": 13.743729,
      "longitude": 100.54749590000006,
      "parentGeographicAreaId": 0,
      "title": "Central Embassy, 88 Thanon Witthayu, แขวง ลุมพินี เขต ปทุมวัน Bangkok 10330, Thailand",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 62,
        "tagName": "Nightlife",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 68,
        "tagName": "Business",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 105,
        "tagName": "Afternoon Tea",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 216,
        "tagName": "Museum",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 294,
        "venueCategoryId": 10,
        "venueId": 1608,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 10,
          "title": "Infinity Pools",
          "region": "Global",
          "viewOrder": 2,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1588,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Playa Vik Jose Ignacio",
    "description": "Playa Vik Jose Ignacio is a 2 minute walk from the beach. There is a  gym, sauna and spacious deck area with open sea views. The hotel has a chic contemporary design in the José Ignacio area. There is free WiFi and guests can relax in the swimming pool.\n\nPlaya Vik Jose Ignacio has spacious suites and apartments with plenty of light. They are equipped with air conditioning, and are styled with pieces of modern artwork.",
    "brand": "",
    "blockQuote": "An architectural dream with the coolest anvil-shaped infinity pool that was made for watching sunsets.",
    "emailAddress": "info@playavik.com",
    "website": "playavik.com/",
    "bookingURL": "https://www.booking.com/hotel/uy/playa-vik-jose-ignacio.en-gb.html?aid=925669;label=gog235jc-1DCAso7QFCFnBsYXlhLXZpay1qb3NlLWlnbmFjaW9IM1gDaPsBiAEBmAEJuAEHyAEM2AED6AEBiAIBqAIDuAKFtd32BcACAdICJGUwOTlkYTkzLWUzMmUtNGJmYi1hMDNkLWRlOTIzMzBhYzJlZdgCBOACAQ;sid=6b10f0abd2fe50a3592a2bb374b7ab7f;dist=0&keep_landing=1&sb_price_type=total&type=total&",
    "redirectNotes": "Playa Vik Jose Ignacio's reservations system",
    "phoneNumber": "+598 93 704 866",
    "addressOne": "",
    "addressTwo": "Calle de Los Cisnes",
    "city": "Jose Ignacio",
    "county": "",
    "region": "",
    "country": "Uruguay",
    "rooms": 22,
    "dollar": 3,
    "updated": "2020-06-03T08:18:46.913",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 17686,
        "title": "Lodge Playa Vik Jose Ignacio, José Ignacio, Uruguay - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/c55f92e3-c38d-48b3-ab2f-452557cb00b6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c55f92e3-c38d-48b3-ab2f-452557cb00b6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c55f92e3-c38d-48b3-ab2f-452557cb00b6/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 17687,
        "title": "Playa Vik Hotel Jose Ignacio, Uruguay – Precious Pieces ...",
        "url": "https://pkslist.azureedge.net/media/images/1/a46df2a0-2fd3-4308-8605-c889ac608b18/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a46df2a0-2fd3-4308-8605-c889ac608b18/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a46df2a0-2fd3-4308-8605-c889ac608b18/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 38179,
        "title": "Playa Vik José Ignacio, Maldonado, Uruguay - Resort Review - Condé ...",
        "url": "https://pkslist.azureedge.net/media/images/1/57010fc5-0607-48ed-9397-e7c89e2c69a7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/57010fc5-0607-48ed-9397-e7c89e2c69a7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/57010fc5-0607-48ed-9397-e7c89e2c69a7/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 38169,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/5f5396b8-3bb2-4a4a-bb36-a00a1b2565a6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5f5396b8-3bb2-4a4a-bb36-a00a1b2565a6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5f5396b8-3bb2-4a4a-bb36-a00a1b2565a6/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 38172,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e34b8913-5782-4a11-b478-94758bc6edd5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e34b8913-5782-4a11-b478-94758bc6edd5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e34b8913-5782-4a11-b478-94758bc6edd5/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 38178,
        "title": "The Viks of Uruguay | Jose Ignacio - Plan South America",
        "url": "https://pkslist.azureedge.net/media/images/1/7a23ac5f-5552-4e65-840e-5b27fe5f47f3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7a23ac5f-5552-4e65-840e-5b27fe5f47f3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7a23ac5f-5552-4e65-840e-5b27fe5f47f3/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 17688,
        "title": "Playa Vik Jose Ignacio: 2018 Room Prices, Deals & Reviews | Expedia",
        "url": "https://pkslist.azureedge.net/media/images/1/ca37a091-c652-42d4-aa4d-b46b2ae145f7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ca37a091-c652-42d4-aa4d-b46b2ae145f7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ca37a091-c652-42d4-aa4d-b46b2ae145f7/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 17689,
        "title": "Playa Vik José Ignacio in Uruguay - International Traveller",
        "url": "https://pkslist.azureedge.net/media/images/1/706b01e7-2e0d-4219-bede-914cff4942b4/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/706b01e7-2e0d-4219-bede-914cff4942b4/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/706b01e7-2e0d-4219-bede-914cff4942b4/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 38171,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b47f654e-45eb-4626-b7a6-e470b9633d19/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b47f654e-45eb-4626-b7a6-e470b9633d19/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b47f654e-45eb-4626-b7a6-e470b9633d19/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 38175,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/0da76d16-de0f-4b5c-a558-ff6348bc721c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0da76d16-de0f-4b5c-a558-ff6348bc721c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0da76d16-de0f-4b5c-a558-ff6348bc721c/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 17684,
        "title": "Lodge Playa Vik Jose Ignacio, José Ignacio, Uruguay - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/82cbc770-7b04-41ab-a409-fe0aa22e7c33/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/82cbc770-7b04-41ab-a409-fe0aa22e7c33/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/82cbc770-7b04-41ab-a409-fe0aa22e7c33/256x192.jpg",
        "viewOrder": 11
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1402,
      "latitude": -34.8419549,
      "longitude": -54.64032589999999,
      "parentGeographicAreaId": 0,
      "title": "esq. s/n, Calle de Los Cisnes & Calle Los Horneros, 20402 José Ignacio, Departamento de Maldonado, Uruguay",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 53,
        "tagName": "Cutting Edge",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 58,
        "tagName": "Beach",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 155,
        "tagName": "Art",
        "tagType": 2
      },
      {
        "tagId": 170,
        "tagName": "Kayaking",
        "tagType": 2
      },
      {
        "tagId": 172,
        "tagName": "Biking",
        "tagType": 2
      },
      {
        "tagId": 203,
        "tagName": "Sauna",
        "tagType": 2
      },
      {
        "tagId": 205,
        "tagName": "Design",
        "tagType": 2
      },
      {
        "tagId": 226,
        "tagName": "Outdoor Fireplace",
        "tagType": 2
      },
      {
        "tagId": 276,
        "tagName": "Gym",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 284,
        "tagName": "Architecture",
        "tagType": 2
      },
      {
        "tagId": 318,
        "tagName": "Stand Up Paddle",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 432,
        "venueCategoryId": 10,
        "venueId": 1588,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 10,
          "title": "Infinity Pools",
          "region": "Global",
          "viewOrder": 2,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1435,
    "venueCategoryId": 10,
    "status": 1,
    "title": "Anantara Al Jabal Al Akhdar Resort",
    "description": "A rare jewel in the rocky contours of the Saiq Plateau on Oman’s fabled Green Mountain, this unique retreat perches 2,000 metres above sea level on the curving rim of a great canyon. Elevated in both location and authentic luxury, Anantara Al Jabal Al Akhdar is the highest five star resort in the Middle East and amongst the highest in the world.  \n\nElegant architecture reflects the mountainous surrounds and Oman’s artistic culture. Contemporary design motifs depict the region’s ancient forts. Experience how indigenous elements blend seamlessly with world class accommodation and facilities at this distinctive Oman resort. Hide away in a private villa with a temperature-controlled pool, or the prestigious three bedroom villa - the most luxurious in the country. A striking infinity pool with a belvedere offers an illustrious setting for relaxation. Anantara Spa’s luxuriant hammam pampers with Arabian cleansing rituals, and helps you find your natural flow in hot yoga.\n\nAnantara Al Jabal Al Akhdar Resort promises an inspirational mountain experience, immersing you into the unique mountainside culture.",
    "brand": "Anantara",
    "blockQuote": "An intimate escape overlooking dramatic canyon views.",
    "emailAddress": "aljabalalakhdar@anantara.com",
    "website": "jabal-akhdar.anantara.com",
    "bookingURL": "https://secure.minorhotels.com/rooms.aspx?bc=AN&hc=AJAA&checkin=22/01/2021&nights=2&adults=2&rooms=1&children=0&infants=0&roomcode=&language=en&tacode=96027890&_ga=2.105231882.823312751.1611301430-1968482771.1604913518&currency=USD",
    "redirectNotes": "Anantara Al Jabal Al Akhdar Resort's reservations system",
    "phoneNumber": "+968 2521 8000",
    "addressOne": "",
    "addressTwo": "",
    "city": "Nizwa",
    "county": "",
    "region": "",
    "country": "Oman",
    "rooms": 115,
    "dollar": 2,
    "updated": "2021-01-22T08:26:13.697",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 15658,
        "title": "Highest 5* resort in the Gulf set to open in Oman - OmanGB News",
        "url": "https://pkslist.azureedge.net/media/images/1/0aacca58-46d5-4c4a-9b2a-a75858f6c4af/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0aacca58-46d5-4c4a-9b2a-a75858f6c4af/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0aacca58-46d5-4c4a-9b2a-a75858f6c4af/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 15663,
        "title": "Anantara Al Jabal Al Akhdar Resort, Sultanate of Oman | Traveller Made",
        "url": "https://pkslist.azureedge.net/media/images/1/7de7716d-b2c5-45b0-a5e7-116cc89fedfa/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7de7716d-b2c5-45b0-a5e7-116cc89fedfa/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7de7716d-b2c5-45b0-a5e7-116cc89fedfa/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 15667,
        "title": "Anantara Al Jabal Al Akhdar Resort, Sultanate of Oman | Traveller Made",
        "url": "https://pkslist.azureedge.net/media/images/1/1d5d88ae-6a35-4b98-aa60-f8d02d07719d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1d5d88ae-6a35-4b98-aa60-f8d02d07719d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1d5d88ae-6a35-4b98-aa60-f8d02d07719d/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 15657,
        "title": "Anantara, Oman, Al Jabal Al Akhdar | Oman | Pinterest",
        "url": "https://pkslist.azureedge.net/media/images/1/69bac345-2116-4635-8e74-f6e09abacd5f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/69bac345-2116-4635-8e74-f6e09abacd5f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/69bac345-2116-4635-8e74-f6e09abacd5f/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 15662,
        "title": "Anantara, Oman, Al Jabal Al Akhdar | Atelier Pod | Pinterest",
        "url": "https://pkslist.azureedge.net/media/images/1/5b91eed6-8f8b-4f55-bf41-e07da3dca6b4/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5b91eed6-8f8b-4f55-bf41-e07da3dca6b4/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5b91eed6-8f8b-4f55-bf41-e07da3dca6b4/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 15665,
        "title": "Anantara Al Jabal Al Akhdar Resort 5* Voyage Privé : up to -70%",
        "url": "https://pkslist.azureedge.net/media/images/1/5a8d3d93-bc0d-4f8c-9b48-54089dd6c7ca/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5a8d3d93-bc0d-4f8c-9b48-54089dd6c7ca/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5a8d3d93-bc0d-4f8c-9b48-54089dd6c7ca/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 15661,
        "title": "Anantara Al Jabal Al Akhdar Resort, Al 'Aqar, Oman - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/6ba9a2a7-6686-47bd-a679-9add04a49055/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6ba9a2a7-6686-47bd-a679-9add04a49055/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6ba9a2a7-6686-47bd-a679-9add04a49055/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 15664,
        "title": "Book Anantara Al Jabal Al Akhdar Resort in Sayq | Hotels.com",
        "url": "https://pkslist.azureedge.net/media/images/1/4d91c3ec-ea59-41b3-9dc7-3e9b4985d3db/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4d91c3ec-ea59-41b3-9dc7-3e9b4985d3db/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4d91c3ec-ea59-41b3-9dc7-3e9b4985d3db/256x192.jpg",
        "viewOrder": 7
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1254,
      "latitude": 23.067768,
      "longitude": 57.67009499999995,
      "parentGeographicAreaId": 0,
      "title": "No 110, Al Jabal Al Akhdar, Nizwa 621, Oman",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 59,
        "tagName": "Cultural",
        "tagType": 2
      },
      {
        "tagId": 68,
        "tagName": "Business",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 83,
        "tagName": "Kid's Club",
        "tagType": 2
      },
      {
        "tagId": 88,
        "tagName": "Tennis",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 100,
        "tagName": "Wedding",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 108,
        "tagName": "Jacuzzi",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 131,
        "tagName": "Library",
        "tagType": 2
      },
      {
        "tagId": 164,
        "tagName": "Excursions",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 264,
        "venueCategoryId": 10,
        "venueId": 1435,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 10,
          "title": "Infinity Pools",
          "region": "Global",
          "viewOrder": 2,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1426,
    "venueCategoryId": 10,
    "status": 1,
    "title": "Thotalagala",
    "description": "Welcome to Thotalagala, a remarkable feat of restoration. The planters bungalow has been revived to its former glory, with seven themed suites based on personalities that shaped the history of the province of Uva, one of the foremost tea growing regions in Sri Lanka, the finest tea growing region in the world.\n\nThotalagala bungalow is surrounded by 20 acres of lawn and patana grass. From the edge of the lawn a sweeping vista greets the eye. Behind the bungalow and covering the Haputale hills lies 4000 hectares of tea stretching unto Lipton’s seat, 1500 feet above,and over onto the Bandarawela side of the mountain. The seven suites represent the epitome of colonial luxury. The teak panelled smoking room is the ideal place to retreat to for an after dinner cognac. The dining room itself is with its twenty seater table, a place to savour traditional hearty plantation fare. This is also a working estate and guest will be able to immerse themselves into the traditional lifestyle of a tea planter.\n\nCome and explore true working tea country, walk through endless tea gardens. You are invited to stay awhile, to travel back in time and walk in the footsteps of Sir Thomas Lipton.",
    "brand": null,
    "blockQuote": "Take along the Guest Relations Manager, Leila the resident pooch for a walk along the tea trails and you'll become an instant VIP.",
    "emailAddress": "reservations@thotalagala.com",
    "website": "thotalagala.com/",
    "bookingURL": "http://www.booking.com/Share-NzTEFs?aid=925669",
    "redirectNotes": null,
    "phoneNumber": "+94 76 762 7433",
    "addressOne": "",
    "addressTwo": "",
    "city": "Haputale",
    "county": "",
    "region": "",
    "country": "Sri Lanka",
    "rooms": 7,
    "dollar": 1,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 15772,
        "title": "Infinity pool",
        "url": "https://pkslist.azureedge.net/media/images/1/a2d50ab8-1f17-4df0-be39-34dc2e40b181/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a2d50ab8-1f17-4df0-be39-34dc2e40b181/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a2d50ab8-1f17-4df0-be39-34dc2e40b181/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 15777,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f0234929-9008-4727-be1a-d4bb836978f2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f0234929-9008-4727-be1a-d4bb836978f2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f0234929-9008-4727-be1a-d4bb836978f2/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 15773,
        "title": "Bathroom",
        "url": "https://pkslist.azureedge.net/media/images/1/25dde379-165d-4583-b32e-00d5bb0ea162/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/25dde379-165d-4583-b32e-00d5bb0ea162/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/25dde379-165d-4583-b32e-00d5bb0ea162/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 15774,
        "title": "Lipton Suite",
        "url": "https://pkslist.azureedge.net/media/images/1/fa70650e-dbd9-4b03-8871-6717eea992e2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fa70650e-dbd9-4b03-8871-6717eea992e2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fa70650e-dbd9-4b03-8871-6717eea992e2/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 15345,
        "title": "Thotalagala | Condé Nast Johansens",
        "url": "https://pkslist.azureedge.net/media/images/1/7c41e114-b345-44e5-80d0-5fdaa8600853/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7c41e114-b345-44e5-80d0-5fdaa8600853/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7c41e114-b345-44e5-80d0-5fdaa8600853/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 15342,
        "title": "Thotalagala Bungalow, Haputale | Pan Lanka",
        "url": "https://pkslist.azureedge.net/media/images/1/fbdac017-a6ba-494a-9cc9-19809705fc2a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fbdac017-a6ba-494a-9cc9-19809705fc2a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fbdac017-a6ba-494a-9cc9-19809705fc2a/256x192.jpg",
        "viewOrder": 5
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1245,
      "latitude": 6.7716128,
      "longitude": 80.98104050000006,
      "parentGeographicAreaId": 0,
      "title": "Thotalagala Plantation House, Dambatenne Road, Thotalagala,, Haputale, Sri Lanka",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 57,
        "tagName": "Romantic",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 75,
        "tagName": "Casual Dining",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 105,
        "tagName": "Afternoon Tea",
        "tagType": 2
      },
      {
        "tagId": 153,
        "tagName": "Cigar Lounge",
        "tagType": 2
      },
      {
        "tagId": 164,
        "tagName": "Excursions",
        "tagType": 2
      },
      {
        "tagId": 193,
        "tagName": "Outdoor Dining",
        "tagType": 2
      },
      {
        "tagId": 259,
        "tagName": "Picnic",
        "tagType": 2
      },
      {
        "tagId": 266,
        "tagName": "Nature Walk",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 263,
        "venueCategoryId": 10,
        "venueId": 1426,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 10,
          "title": "Infinity Pools",
          "region": "Global",
          "viewOrder": 2,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1392,
    "venueCategoryId": 10,
    "status": 1,
    "title": "Casa Bonita Tropical Lodge",
    "description": "Perched between tropical blues and luscious greens, Casa Bonita Tropical Lodge is the former Schiffino family summer home, graced with lush tropical foliage, coconut, palm and mahogany. The site offers the most unforgettable views of the Caribbean Sea. Today, the third generation of his family still offers its passion for hospitality, encouraging visitors to discover gastronomy based on organic resources, to experience wellbeing and to enjoy the biodiversity of this unique region.\nCasa Bonita offers 3 room categories: The Deluxe Rooms are simple and refreshing, and are built in a way so that each has views of both the Caribbean Sea and The Sierra Barahuco Mountains that you can enjoy from your private balcony.  The Ocean Suites have the best view of the Caribbean sea in a private area of 116.6m2, including your very own dining area and infinity pool. Next, the private villa in paradise! A home away from home in the surrounded by the beautiful foliage that decorates the Southern coast. The villa has two bedrooms, one with a king bed and one with two full beds, a living room, dining room, fully equipped kitchen, private pool and barbecue.",
    "brand": null,
    "blockQuote": "Casa Bonita is a hidden gem perfect for both adventure and relaxation.",
    "emailAddress": "info@casabonitadr.com",
    "website": "casabonitadr.com",
    "bookingURL": "http://www.booking.com/Share-BhKGwN?aid=925669",
    "redirectNotes": null,
    "phoneNumber": "+1 809 540 5908",
    "addressOne": "",
    "addressTwo": "",
    "city": "Barahona",
    "county": "",
    "region": "Dominican Republic",
    "country": "Caribbean",
    "rooms": 12,
    "dollar": 1,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 15028,
        "title": "Bonita Tropical Lodge, Santa Cruz de Barahona, Dominican Republic ...",
        "url": "https://pkslist.azureedge.net/media/images/1/4e0b272b-b63a-4549-808e-babfa4e408e6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4e0b272b-b63a-4549-808e-babfa4e408e6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4e0b272b-b63a-4549-808e-babfa4e408e6/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 17970,
        "title": "Casa Bonita Tropical Lodge, Bahoruco, Baraona",
        "url": "https://pkslist.azureedge.net/media/images/1/76030421-265b-44e1-8e37-9a7f8f1ff7fb/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/76030421-265b-44e1-8e37-9a7f8f1ff7fb/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/76030421-265b-44e1-8e37-9a7f8f1ff7fb/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 15029,
        "title": "Bonita Tropical Lodge, Santa Cruz de Barahona, Dominican Republic ...",
        "url": "https://pkslist.azureedge.net/media/images/1/cfba9243-0b4d-4151-8642-48aa423138b1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cfba9243-0b4d-4151-8642-48aa423138b1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cfba9243-0b4d-4151-8642-48aa423138b1/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 15030,
        "title": "Casa Bonita Tropical Lodge, Santa Cruz de Barahona, Dominican ...",
        "url": "https://pkslist.azureedge.net/media/images/1/229e81a5-c84d-4f46-b09d-e5a2a8f52526/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/229e81a5-c84d-4f46-b09d-e5a2a8f52526/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/229e81a5-c84d-4f46-b09d-e5a2a8f52526/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 17973,
        "title": "Hotel Review: Casa Bonita Tropical Lodge | Air Canada enRoute",
        "url": "https://pkslist.azureedge.net/media/images/1/02cde184-c026-4521-bda9-32b4a38cd71a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/02cde184-c026-4521-bda9-32b4a38cd71a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/02cde184-c026-4521-bda9-32b4a38cd71a/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 15031,
        "title": "Casa Bonita Tropical Lodge in the Dominican Republic ...",
        "url": "https://pkslist.azureedge.net/media/images/1/0f9db283-5af0-4988-ade4-4b6fd9790681/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0f9db283-5af0-4988-ade4-4b6fd9790681/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0f9db283-5af0-4988-ade4-4b6fd9790681/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 15032,
        "title": "Casa Bonita Tropical Lodge - hotelroomsearch.net",
        "url": "https://pkslist.azureedge.net/media/images/1/4aead360-290c-4d6c-98c5-d3555cbbf6b5/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4aead360-290c-4d6c-98c5-d3555cbbf6b5/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4aead360-290c-4d6c-98c5-d3555cbbf6b5/256x192.jpeg",
        "viewOrder": 6
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1212,
      "latitude": 18.0801508,
      "longitude": -71.09295680000002,
      "parentGeographicAreaId": 0,
      "title": "Km. 17 Carretera de la Costa, Bahoruco, Barahona, Barahona 81000, Dominican Republic",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 57,
        "tagName": "Romantic",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 60,
        "tagName": "Gastronomic",
        "tagType": 2
      },
      {
        "tagId": 82,
        "tagName": "Horseback Riding",
        "tagType": 2
      },
      {
        "tagId": 88,
        "tagName": "Tennis",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 109,
        "tagName": "Fishing",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 115,
        "tagName": "Snorkeling",
        "tagType": 2
      },
      {
        "tagId": 116,
        "tagName": "Scuba Diving",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 122,
        "tagName": "Surfing",
        "tagType": 2
      },
      {
        "tagId": 135,
        "tagName": "Bird Watching",
        "tagType": 2
      },
      {
        "tagId": 159,
        "tagName": "Mountain Biking",
        "tagType": 2
      },
      {
        "tagId": 252,
        "tagName": "National Park",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 257,
        "venueCategoryId": 10,
        "venueId": 1392,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 10,
          "title": "Infinity Pools",
          "region": "Global",
          "viewOrder": 2,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1359,
    "venueCategoryId": 10,
    "status": 1,
    "title": "Raffles Seychelles",
    "description": "One of the world’s most far flung destinations, nestled in the Indian Ocean, the island of Praslin is the launch point for a myriad of activities and home to natural wonders such as the UNESCO World Heritage Site of Vallée de Mai, and Anse Lazio. Rated as one of the best beaches in the world, Anze Lazio is located just minutes from Raffles Seychelles.\nRaffles Seychelles instills a sense of wellbeing and creates an atmosphere of blissful relaxation, ideal for the paradise seeker. Featuring eighty-six villas, some of the most spacious in the Seychelles. Each villa offers a private plunge pool and outdoor pavilion to soak up breathtaking views of the opal-hued ocean, white sandy beach and lush green hills. This is the perfect destination for couples to celebrate their one-of-a-kind moment, be it an oceanfront wedding or an anniversary celebration.",
    "brand": null,
    "blockQuote": "An oasis of laidback luxury.",
    "emailAddress": "praslin@raffles.com",
    "website": "raffles.com/praslin",
    "bookingURL": "http://www.booking.com/Share-4azpxw?aid=925669",
    "redirectNotes": null,
    "phoneNumber": "+248 429 6000",
    "addressOne": "",
    "addressTwo": "",
    "city": "Praslin",
    "county": "",
    "region": "",
    "country": "Seychelles",
    "rooms": 86,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 14554,
        "title": "Raffles Seychelles Hotel Review, Praslin Seychelles | Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/a12d1f3a-01bb-47a7-aa3f-e0efe2847ad9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a12d1f3a-01bb-47a7-aa3f-e0efe2847ad9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a12d1f3a-01bb-47a7-aa3f-e0efe2847ad9/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 14558,
        "title": "Raffles Seychelles, Anse Takamaka, Praslin",
        "url": "https://pkslist.azureedge.net/media/images/1/7f2ff833-a439-4235-8351-72806e336e68/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7f2ff833-a439-4235-8351-72806e336e68/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7f2ff833-a439-4235-8351-72806e336e68/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 14556,
        "title": "Raffles Seychelles, Anse Takamaka, Praslin",
        "url": "https://pkslist.azureedge.net/media/images/1/35333111-0f2a-4f25-9367-efe6b977cda4/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/35333111-0f2a-4f25-9367-efe6b977cda4/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/35333111-0f2a-4f25-9367-efe6b977cda4/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 23084,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/8d8433bb-bc8c-4b49-a0f2-c449da700154/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8d8433bb-bc8c-4b49-a0f2-c449da700154/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8d8433bb-bc8c-4b49-a0f2-c449da700154/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 14560,
        "title": "Raffles Seychelles (formerly Raffles Praslin Seychelles) Anse ...",
        "url": "https://pkslist.azureedge.net/media/images/1/4133f500-e210-4ffc-9050-ce5769efbf98/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4133f500-e210-4ffc-9050-ce5769efbf98/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4133f500-e210-4ffc-9050-ce5769efbf98/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 14561,
        "title": "Raffles Seychelles, Holiday houses Baie Sainte Anne",
        "url": "https://pkslist.azureedge.net/media/images/1/8364800a-a92f-4838-b514-12c7bf96fe53/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8364800a-a92f-4838-b514-12c7bf96fe53/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8364800a-a92f-4838-b514-12c7bf96fe53/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 14557,
        "title": "Raffles Praslin, Praslin, Seychelles - Just Luxury Travel 4 U",
        "url": "https://pkslist.azureedge.net/media/images/1/5d754d82-6a1d-470a-8daf-a06a7ca60cdd/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5d754d82-6a1d-470a-8daf-a06a7ca60cdd/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5d754d82-6a1d-470a-8daf-a06a7ca60cdd/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 14549,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/25ee33d2-2dc5-4095-9f9f-7abfb71f0627/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/25ee33d2-2dc5-4095-9f9f-7abfb71f0627/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/25ee33d2-2dc5-4095-9f9f-7abfb71f0627/256x192.jpg",
        "viewOrder": 7
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1181,
      "latitude": -4.308307,
      "longitude": 55.71670700000004,
      "parentGeographicAreaId": 0,
      "title": "Anse Takamaka, PRASLIN, Seychelles",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 50,
        "tagName": "Barefoot Luxury",
        "tagType": 1
      },
      {
        "tagId": 157,
        "tagName": "Beach Escape",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 83,
        "tagName": "Kid's Club",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 115,
        "tagName": "Snorkeling",
        "tagType": 2
      },
      {
        "tagId": 181,
        "tagName": "Private/Suite Pools",
        "tagType": 2
      },
      {
        "tagId": 183,
        "tagName": "Butler Service",
        "tagType": 2
      },
      {
        "tagId": 193,
        "tagName": "Outdoor Dining",
        "tagType": 2
      },
      {
        "tagId": 198,
        "tagName": "Boat Excursion",
        "tagType": 2
      },
      {
        "tagId": 202,
        "tagName": "Private Terraces",
        "tagType": 2
      },
      {
        "tagId": 224,
        "tagName": "Villas",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 252,
        "venueCategoryId": 10,
        "venueId": 1359,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 10,
          "title": "Infinity Pools",
          "region": "Global",
          "viewOrder": 2,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1334,
    "venueCategoryId": 10,
    "status": 1,
    "title": "MGM Macau",
    "description": "MGM MACAU is the ultimate style statement. A shimmering, three-tiered gold exterior reflects the hues of the South China Sea and the swell of the ocean surf. Inside, every detail of the Forbes Five-Star luxury integrated resort tells a story of passion and artistry, unified to create a truly inspiring experience. At the heart runs the Grande Praça, a bright, bustling hub that connects guests with the very best of MGM MACAU: fine dining, entertainment, events, meetings, shopping and the elegant comfort of a Forbes Five-Star luxury integrated resort. Magnificent façades and sweeping staircases transport visitors to old Europe, while the main façade pays homage to Macau’s Portuguese heritage, as it is crafted in the image of Lisbon’s Central Train Station. Remarkable contemporary design permeates each of the 6 categories from Grand Rooms and Suites, to Studios and Villas, all designed to meet your high standards and ensure that every aspect of your stay is up to the level you expect, providing you with the perfect mix of wonder and luxury.",
    "brand": null,
    "blockQuote": "An elegant and grand resort in the best location in Macau.",
    "emailAddress": "hotelenquiry@mgmmacau.com",
    "website": "mgm.mo/en/macau",
    "bookingURL": "http://www.booking.com/Share-XMKF1n?aid=925669",
    "redirectNotes": null,
    "phoneNumber": "+853 8802 8888",
    "addressOne": "",
    "addressTwo": "孫逸仙 大 馬路",
    "city": "Macau",
    "county": "",
    "region": "",
    "country": "China",
    "rooms": 600,
    "dollar": 1,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 14178,
        "title": "The MGM Macau",
        "url": "https://pkslist.azureedge.net/media/images/1/966d4a1b-7896-4bc1-8342-7a56ee0e94ea/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/966d4a1b-7896-4bc1-8342-7a56ee0e94ea/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/966d4a1b-7896-4bc1-8342-7a56ee0e94ea/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 14179,
        "title": "North by Square Eight Restaurant, MGM Macau - Tommy Ooi Travel Guide",
        "url": "https://pkslist.azureedge.net/media/images/1/40f49147-ff5f-4b82-87ba-e777882b6f63/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/40f49147-ff5f-4b82-87ba-e777882b6f63/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/40f49147-ff5f-4b82-87ba-e777882b6f63/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 14181,
        "title": "MGM MACAU « TTGmice – Asia-Pacific's Leading Meetings, Incentives ...",
        "url": "https://pkslist.azureedge.net/media/images/1/ccdccd09-f290-49b6-bd24-c8e674ee390b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ccdccd09-f290-49b6-bd24-c8e674ee390b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ccdccd09-f290-49b6-bd24-c8e674ee390b/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 14182,
        "title": "MGM Macau - Macau - book your hotel with ViaMichelin",
        "url": "https://pkslist.azureedge.net/media/images/1/9a96f8d7-e36c-4e49-adb2-2c814a157ee5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9a96f8d7-e36c-4e49-adb2-2c814a157ee5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9a96f8d7-e36c-4e49-adb2-2c814a157ee5/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 14180,
        "title": "Hotel MGM Macau, Macau - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/4932d21d-4f24-41af-9004-4ae2d8cd5af6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4932d21d-4f24-41af-9004-4ae2d8cd5af6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4932d21d-4f24-41af-9004-4ae2d8cd5af6/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 14183,
        "title": "The Mansion at MGM Grand | Wilson Associates",
        "url": "https://pkslist.azureedge.net/media/images/1/90e3fe69-a00c-4acf-a01b-89c2b6eb6cbf/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/90e3fe69-a00c-4acf-a01b-89c2b6eb6cbf/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/90e3fe69-a00c-4acf-a01b-89c2b6eb6cbf/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 14185,
        "title": "MGM Macau, Macau City, Macau",
        "url": "https://pkslist.azureedge.net/media/images/1/2cd48059-2e78-4f7f-b845-def44a42b2f0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2cd48059-2e78-4f7f-b845-def44a42b2f0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2cd48059-2e78-4f7f-b845-def44a42b2f0/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 14172,
        "title": "Hotel MGM Macau, Macau - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/199e7f66-cfd7-4e26-b8d1-13a08f23014a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/199e7f66-cfd7-4e26-b8d1-13a08f23014a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/199e7f66-cfd7-4e26-b8d1-13a08f23014a/256x192.jpg",
        "viewOrder": 7
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1156,
      "latitude": 22.18555719999999,
      "longitude": 113.54799939999998,
      "parentGeographicAreaId": 0,
      "title": "Macau孫逸仙 大 馬路",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      },
      {
        "tagId": 55,
        "tagName": "Lively",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 39,
        "tagName": "City",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 68,
        "tagName": "Business",
        "tagType": 2
      },
      {
        "tagId": 70,
        "tagName": "Families",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 74,
        "tagName": "Casino",
        "tagType": 2
      },
      {
        "tagId": 78,
        "tagName": "Fine Dining",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 100,
        "tagName": "Wedding",
        "tagType": 2
      },
      {
        "tagId": 108,
        "tagName": "Jacuzzi",
        "tagType": 2
      },
      {
        "tagId": 169,
        "tagName": "Resort",
        "tagType": 2
      },
      {
        "tagId": 199,
        "tagName": "Art Gallery",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 243,
        "venueCategoryId": 10,
        "venueId": 1334,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 10,
          "title": "Infinity Pools",
          "region": "Global",
          "viewOrder": 2,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1317,
    "venueCategoryId": 10,
    "status": 1,
    "title": "Chileno Bay Resort & Residences",
    "description": "Nestled along a protected cove, the most desirable beach in Los Cabos has long been known for its tranquil, swimmable waters and pristine coral reefs, where a short venture out to sea unveils a hidden underwater world of tropical fish and sea turtles. Chileno Bay was created with a distinct vision to bring a contemporary take on the classic Baja escape with an airy, open aesthetic that blurs the line between indoor and outdoor with private terraces in each guest room.",
    "brand": "Auberge Resorts Collection",
    "blockQuote": "You'll appreciate being able to swim year-round in the waters right outside the hotel.",
    "emailAddress": "cbr.info@aubergeresorts.com",
    "website": "aubergeresorts.com/chilenobay/",
    "bookingURL": "https://be.synxis.com/?_ga=2.257487747.967439844.1591288211-1313543455.1591288211&adult=2&agencyid=96027890&arrive=2020-06-04&arrive_date=Jun%2004&chain=16237&child=0&currency=USD&depart=2020-06-08&depart_date=Jun%2008&hotel=70874&level=chain&locale=en-US&rooms=1",
    "redirectNotes": "Chileno Bay Resort & Residences' reservations system",
    "phoneNumber": "+52 624 104 96 00",
    "addressOne": "",
    "addressTwo": "",
    "city": "Los Cabos",
    "county": "",
    "region": "",
    "country": "Mexico",
    "rooms": 92,
    "dollar": 2,
    "updated": "2022-01-30T08:18:21.15",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 38240,
        "title": "About | Chileno Bay Golf and Beach Club",
        "url": "https://pkslist.azureedge.net/media/images/1/527e722d-4064-46e9-a19a-aa5339032e70/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/527e722d-4064-46e9-a19a-aa5339032e70/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/527e722d-4064-46e9-a19a-aa5339032e70/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 52194,
        "title": "Chileno Bay Resort 4 Bedroom Oceanfront Villa - Cabo San Lucas, Mexico",
        "url": "https://pkslist.azureedge.net/media/images/1/16a5b8d5-8dab-4ead-9e4a-145d79375f1c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/16a5b8d5-8dab-4ead-9e4a-145d79375f1c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/16a5b8d5-8dab-4ead-9e4a-145d79375f1c/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 52205,
        "title": "Chileno Bay Resort & Residences - Cabo San Lucas - Three Bedroom ...",
        "url": "https://pkslist.azureedge.net/media/images/1/c7d8c5a5-d6b3-4223-b283-26fe3b65a0df/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c7d8c5a5-d6b3-4223-b283-26fe3b65a0df/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c7d8c5a5-d6b3-4223-b283-26fe3b65a0df/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 52207,
        "title": "Chileno Bay, an Auberge Resort « BAMO",
        "url": "https://pkslist.azureedge.net/media/images/1/b155e7fd-288d-4457-8cb5-301c79a2fc7f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b155e7fd-288d-4457-8cb5-301c79a2fc7f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b155e7fd-288d-4457-8cb5-301c79a2fc7f/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 13932,
        "title": "New Resort in Cabo San Lucas, New Resort in Mexico - Auberge Resort",
        "url": "https://pkslist.azureedge.net/media/images/1/4e9f191a-c9f2-4c2d-9752-d895047e488e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4e9f191a-c9f2-4c2d-9752-d895047e488e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4e9f191a-c9f2-4c2d-9752-d895047e488e/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 52190,
        "title": "Chileno Bay Resort & Residences - Cabo San Lucas, Mexico",
        "url": "https://pkslist.azureedge.net/media/images/1/f06657e3-5aa2-4092-8384-9921ef40f587/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f06657e3-5aa2-4092-8384-9921ef40f587/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f06657e3-5aa2-4092-8384-9921ef40f587/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 13940,
        "title": "Chileno Bay Resort & Residences - Cabo San Lucas- Luxuria Vacations",
        "url": "https://pkslist.azureedge.net/media/images/1/ccde28ee-5279-4c55-bf7e-ecad1a167649/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ccde28ee-5279-4c55-bf7e-ecad1a167649/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ccde28ee-5279-4c55-bf7e-ecad1a167649/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 52187,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e9e7bd45-b992-46e5-8999-ca87f4b42c04/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e9e7bd45-b992-46e5-8999-ca87f4b42c04/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e9e7bd45-b992-46e5-8999-ca87f4b42c04/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 52193,
        "title": "Homes for Sale in Chileno Bay - Montage - El Dorado - Maravilla ...",
        "url": "https://pkslist.azureedge.net/media/images/1/945403fb-d668-4e6b-a9c7-021a2947b6ec/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/945403fb-d668-4e6b-a9c7-021a2947b6ec/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/945403fb-d668-4e6b-a9c7-021a2947b6ec/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1139,
      "latitude": 22.948361,
      "longitude": -109.80758200000002,
      "parentGeographicAreaId": 0,
      "title": "Carretera Transpeninsular San Jose-San Lucas Km. 15, Playa Chileno Bay, 23410 Cabo San Lucas, B.C.S., Mexico",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 157,
        "tagName": "Beach Escape",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 58,
        "tagName": "Beach",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 81,
        "tagName": "Golf",
        "tagType": 2
      },
      {
        "tagId": 83,
        "tagName": "Kid's Club",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 100,
        "tagName": "Wedding",
        "tagType": 2
      },
      {
        "tagId": 181,
        "tagName": "Private/Suite Pools",
        "tagType": 2
      },
      {
        "tagId": 193,
        "tagName": "Outdoor Dining",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 241,
        "venueCategoryId": 10,
        "venueId": 1317,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 10,
          "title": "Infinity Pools",
          "region": "Global",
          "viewOrder": 2,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1289,
    "venueCategoryId": 10,
    "status": 1,
    "title": "Jackalope",
    "description": "Set on a pristine property amid the world-renowned Mornington Peninsula wine region, an intoxicating vineyard acreage frames a luxury hotel, unique in both destination and design. \n\nJackalope’s daring emprise, a delicate interplay between the ideal and surreal, is delivered through boldly curated installations and collaborations with some of the world’s principal designers. The 46 designer dens deliver absolute in-room reverie. Interiors are lavished with custom-made comforts, while floor-to-ceiling windows and private terraces connect the room’s contemporary design with its country entwine.",
    "brand": "",
    "blockQuote": "A creative and architectural dream set amidst an elegant vineyard.",
    "emailAddress": "hello@jackalopehotels.com",
    "website": "jackalopehotels.com",
    "bookingURL": "http://www.booking.com/Share-oZE6ZK?aid=925669",
    "redirectNotes": null,
    "phoneNumber": "+61 3 5931 2500",
    "addressOne": "166",
    "addressTwo": "Balnarring Road",
    "city": "Merricks North",
    "county": "",
    "region": "Victoria",
    "country": "Australia",
    "rooms": 46,
    "dollar": 3,
    "updated": "2020-06-04T16:27:43.073",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 38237,
        "title": "Vacay: Jackalope Hotel, Mornington Peninsula - Summersite - Medium",
        "url": "https://pkslist.azureedge.net/media/images/1/82f8e616-64dc-4266-b891-e63c1faf06d8/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/82f8e616-64dc-4266-b891-e63c1faf06d8/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/82f8e616-64dc-4266-b891-e63c1faf06d8/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 13534,
        "title": "Carr creates moody interiors for Australia's art-filled Jackalope ...",
        "url": "https://pkslist.azureedge.net/media/images/1/081d6968-5c8f-4e55-a6ec-1df434d3497a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/081d6968-5c8f-4e55-a6ec-1df434d3497a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/081d6968-5c8f-4e55-a6ec-1df434d3497a/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 13539,
        "title": "JACKALOPE HOTEL - LUXURY AMONGST THE MORNINGTON PENINSULA - HUNTER LAB",
        "url": "https://pkslist.azureedge.net/media/images/1/48a43147-cced-4246-b8b2-eb12dc68c326/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/48a43147-cced-4246-b8b2-eb12dc68c326/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/48a43147-cced-4246-b8b2-eb12dc68c326/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 13540,
        "title": "Jackalope Hotel, Merricks North, Australia - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/7a5d37b9-5d5d-49b7-8a2c-2e1a39adeb00/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7a5d37b9-5d5d-49b7-8a2c-2e1a39adeb00/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7a5d37b9-5d5d-49b7-8a2c-2e1a39adeb00/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 13538,
        "title": "Jackalope hotel opens on the Mornington Peninsula :: Gourmet Traveller",
        "url": "https://pkslist.azureedge.net/media/images/1/c9c923a9-020f-405b-a002-e91f9ba45820/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c9c923a9-020f-405b-a002-e91f9ba45820/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c9c923a9-020f-405b-a002-e91f9ba45820/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 13535,
        "title": "Jackalope Hotel, Merricks North, Australia - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/7f15b10e-2709-408b-a072-e6f5d4c7dd4e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7f15b10e-2709-408b-a072-e6f5d4c7dd4e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7f15b10e-2709-408b-a072-e6f5d4c7dd4e/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 13536,
        "title": "Carr creates moody interiors for Australia's art-filled Jackalope ...",
        "url": "https://pkslist.azureedge.net/media/images/1/bfaf0445-bbc4-443a-8d50-f72454f02df9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bfaf0445-bbc4-443a-8d50-f72454f02df9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bfaf0445-bbc4-443a-8d50-f72454f02df9/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 13533,
        "title": "Luxury Hotel Mornington Peninsula | Jackalope Hotels",
        "url": "https://pkslist.azureedge.net/media/images/1/7f361504-9df0-424b-9536-b081bb962e3b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7f361504-9df0-424b-9536-b081bb962e3b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7f361504-9df0-424b-9536-b081bb962e3b/256x192.jpg",
        "viewOrder": 7
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1113,
      "latitude": -38.344479,
      "longitude": 145.100645,
      "parentGeographicAreaId": 0,
      "title": "166 Balnarring Rd, Merricks North VIC 3926, Australia",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 53,
        "tagName": "Cutting Edge",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 31,
        "tagName": "Countryside",
        "tagType": 2
      },
      {
        "tagId": 68,
        "tagName": "Business",
        "tagType": 2
      },
      {
        "tagId": 78,
        "tagName": "Fine Dining",
        "tagType": 2
      },
      {
        "tagId": 89,
        "tagName": "Vineyard",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 155,
        "tagName": "Art",
        "tagType": 2
      },
      {
        "tagId": 202,
        "tagName": "Private Terraces",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 221,
        "tagName": "Wine Tasting",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 235,
        "venueCategoryId": 10,
        "venueId": 1289,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 10,
          "title": "Infinity Pools",
          "region": "Global",
          "viewOrder": 2,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1247,
    "venueCategoryId": 10,
    "status": 1,
    "title": "Miramonti Boutique Hotel",
    "description": "Latched to a sunny plateau in Hafling (Avelengo) in the Dolomites, the boutique Miramonti Spa & Hotel sits within a carpet of hillside forest splendour overlooking Merano, with spectacular bird’s eye vistas of captivating Alto Adige - this is South Tyrol from the top down, as seen from this luxury style pad. Although this classical Alpine retreat has been cleverly spliced with modernity, the roots of the region are sensed through vintage pieces dotted throughout, adding a subtle nostalgic charm to the mountainous culture of the place.",
    "brand": null,
    "blockQuote": "The Forest Sauna is 20 metres above ground, hidden in the trees with mind seducing views; go and be seduced.",
    "emailAddress": "info@hotel-miramonti.com",
    "website": "hotel-miramonti.com/en/home.html",
    "bookingURL": "http://www.booking.com/Share-nqPiOv?aid=925669",
    "redirectNotes": null,
    "phoneNumber": "+39 0473 27 93 35",
    "addressOne": "",
    "addressTwo": "Sankt Kathreinstraße",
    "city": "St. Kathreinstrabe",
    "county": "",
    "region": "",
    "country": "Italy",
    "rooms": 36,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 12987,
        "title": "Hotel GranPanorama Miramonti, Avelengo, Italy - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/ed57b92c-e5dc-425a-a43d-6ad2af838ab9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ed57b92c-e5dc-425a-a43d-6ad2af838ab9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ed57b92c-e5dc-425a-a43d-6ad2af838ab9/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 23077,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ed4d3e0c-30f6-4f7d-96c0-501712aa19b2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ed4d3e0c-30f6-4f7d-96c0-501712aa19b2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ed4d3e0c-30f6-4f7d-96c0-501712aa19b2/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 23073,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e1af8639-40e0-4c94-a735-3983b161bec2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e1af8639-40e0-4c94-a735-3983b161bec2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e1af8639-40e0-4c94-a735-3983b161bec2/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 23076,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/5bbf8d60-b35c-47c3-9973-68d891bcd075/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5bbf8d60-b35c-47c3-9973-68d891bcd075/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5bbf8d60-b35c-47c3-9973-68d891bcd075/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 23071,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/5218c5c9-8915-4e62-94f7-69cffff6de76/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5218c5c9-8915-4e62-94f7-69cffff6de76/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5218c5c9-8915-4e62-94f7-69cffff6de76/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 12988,
        "title": "Miramonti Boutique Hotel - Avelengo Merano and 36 handpicked ...",
        "url": "https://pkslist.azureedge.net/media/images/1/baaaa846-931b-43cf-9dcf-44a1a74fc292/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/baaaa846-931b-43cf-9dcf-44a1a74fc292/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/baaaa846-931b-43cf-9dcf-44a1a74fc292/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 23075,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/9f579f06-cf7e-4464-aa54-1e8da34ecccd/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9f579f06-cf7e-4464-aa54-1e8da34ecccd/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9f579f06-cf7e-4464-aa54-1e8da34ecccd/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 12990,
        "title": "The MiraMonti Boutique Hotel - OPUMO",
        "url": "https://pkslist.azureedge.net/media/images/1/aa43bfb4-e52a-4500-9de7-bbd481f66842/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/aa43bfb4-e52a-4500-9de7-bbd481f66842/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/aa43bfb4-e52a-4500-9de7-bbd481f66842/256x192.jpg",
        "viewOrder": 7
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1071,
      "latitude": 46.64994309999999,
      "longitude": 11.208947899999998,
      "parentGeographicAreaId": 0,
      "title": "St. Kathreinstraße, 39010 Hafling, Bozen, Italy",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 40,
        "tagName": "Boutique Bolthole ",
        "tagType": 1
      },
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 34,
        "tagName": "Cosy",
        "tagType": 2
      },
      {
        "tagId": 60,
        "tagName": "Gastronomic",
        "tagType": 2
      },
      {
        "tagId": 61,
        "tagName": "Mountain",
        "tagType": 2
      },
      {
        "tagId": 69,
        "tagName": "Couples",
        "tagType": 2
      },
      {
        "tagId": 70,
        "tagName": "Families",
        "tagType": 2
      },
      {
        "tagId": 73,
        "tagName": "Outdoor Terraces",
        "tagType": 2
      },
      {
        "tagId": 85,
        "tagName": "Pet Friendly",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 108,
        "tagName": "Jacuzzi",
        "tagType": 2
      },
      {
        "tagId": 167,
        "tagName": "Nanny/Babysitting Service",
        "tagType": 2
      },
      {
        "tagId": 226,
        "tagName": "Outdoor Fireplace",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 226,
        "venueCategoryId": 10,
        "venueId": 1247,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 10,
          "title": "Infinity Pools",
          "region": "Global",
          "viewOrder": 2,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 926,
    "venueCategoryId": 10,
    "status": 1,
    "title": "Monastero Santa Rosa Hotel & Spa",
    "description": "Striking the perfect balance of seclusion without solitude, Monastero Santa Rosa allows guests to determine the tone of their stay, whether they’re looking to disappear for an intimate getaway or gather with the group and make new acquaintances. It’s hard to imagine that the generous grounds, filled with four levels of gardens, lush landscaping, plentiful lounge chairs, and canopied daybeds, serve just 20 rooms and suites. Where most boutique properties might only offer in-room massages, Monastero Santa Rosa hosts a fully equipped spa to rival any major city spa, offering not just treatment rooms, but large-scale amenities that include a sauna, steam, mosaic-tiled experience shower, hydro pool, and tepidarium.\n\nGuests are welcome to feel a part of the scene at the library and bar. Engage in a game of chess or cards or read while sipping a martini in the modern cocktail area. Relax down by the pool or discover the resort’s secret gardens, quiet sun decks, and indoor relaxation areas. Get the best of both worlds by making your stay as energizing or relaxed as you wish, by selecting different spaces to suit your mood at any time.\n\nChildren 16 years plus are welcome.",
    "brand": "",
    "blockQuote": "The backdrop of the infinity pool is one of the most stunning I've ever seen.",
    "emailAddress": "info@monasterosantarosa.com",
    "website": "monasterosantarosa.com/",
    "bookingURL": "https://gc.synxis.com/rez.aspx?Hotel=58884&Chain=10205&template=RESP&shell=RESPEN&locale=en-US&iata=96027890",
    "redirectNotes": "Monastero Santa Rosa Hotel & Spa's reservations system",
    "phoneNumber": "+39 0898321199",
    "addressOne": "2",
    "addressTwo": "Via Roma",
    "city": "Conca dei Marini",
    "county": "",
    "region": "Amalfi Coast",
    "country": "Italy",
    "rooms": 20,
    "dollar": 3,
    "updated": "2023-09-05T13:38:09",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 38236,
        "title": "Review: A Stay at Monastero Santa Rosa, Amalfi Coast",
        "url": "https://pkslist.azureedge.net/media/images/1/bbb81524-1219-4613-bb07-d9f5fb01b634/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bbb81524-1219-4613-bb07-d9f5fb01b634/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bbb81524-1219-4613-bb07-d9f5fb01b634/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 38219,
        "title": "A Monastery on the Amalfi Coast is Reborn | NUVO",
        "url": "https://pkslist.azureedge.net/media/images/1/1618d15e-e68c-459f-849e-8204bc59294a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1618d15e-e68c-459f-849e-8204bc59294a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1618d15e-e68c-459f-849e-8204bc59294a/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 38227,
        "title": "Monastero Santa Rosa Hotel, a new standard for breathtaking panoramas",
        "url": "https://pkslist.azureedge.net/media/images/1/54a1394b-0aa3-4231-9172-50b7ff0af930/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/54a1394b-0aa3-4231-9172-50b7ff0af930/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/54a1394b-0aa3-4231-9172-50b7ff0af930/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 38235,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e36e120c-32e2-4a2c-b85b-0d9ebad2087a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e36e120c-32e2-4a2c-b85b-0d9ebad2087a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e36e120c-32e2-4a2c-b85b-0d9ebad2087a/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 38230,
        "title": "Monastero Santa Rosa",
        "url": "https://pkslist.azureedge.net/media/images/1/2e17410f-104f-4a2f-9599-b267cc60d6ea/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2e17410f-104f-4a2f-9599-b267cc60d6ea/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2e17410f-104f-4a2f-9599-b267cc60d6ea/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 38231,
        "title": "Monastero Santa Rosa Hotel, a new standard for breathtaking panoramas",
        "url": "https://pkslist.azureedge.net/media/images/1/85be0dc2-047d-48d8-8cb7-a35f8533baeb/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/85be0dc2-047d-48d8-8cb7-a35f8533baeb/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/85be0dc2-047d-48d8-8cb7-a35f8533baeb/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 9419,
        "title": "Monastero Santa Rosa Hotel & Spa",
        "url": "https://pkslist.azureedge.net/media/images/1/78ceed6c-7109-4af2-a073-569416269d02/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/78ceed6c-7109-4af2-a073-569416269d02/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/78ceed6c-7109-4af2-a073-569416269d02/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 38228,
        "title": "Monastero Santa Rosa Hotel & Spa - The Luxury Spa Edit",
        "url": "https://pkslist.azureedge.net/media/images/1/343c605f-0f62-462b-a23d-d93be60ecd2b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/343c605f-0f62-462b-a23d-d93be60ecd2b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/343c605f-0f62-462b-a23d-d93be60ecd2b/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 38222,
        "title": "Monastero Santa Rosa Hotel & Spa, Italy, Campania, Salerno ...",
        "url": "https://pkslist.azureedge.net/media/images/1/9d5bc1e9-9749-4c48-b6f8-9ed3b5b7d36f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9d5bc1e9-9749-4c48-b6f8-9ed3b5b7d36f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9d5bc1e9-9749-4c48-b6f8-9ed3b5b7d36f/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 9408,
        "title": "MONASTERO SANTA ROSA HOTEL | Tops, Amalfi and Luxury",
        "url": "https://pkslist.azureedge.net/media/images/1/bd1f6104-88b7-45f8-968f-cefd350bcdad/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bd1f6104-88b7-45f8-968f-cefd350bcdad/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bd1f6104-88b7-45f8-968f-cefd350bcdad/256x192.jpg",
        "viewOrder": 11
      }
    ],
    "geographicArea": {
      "geographicAreaId": 757,
      "latitude": 40.620582,
      "longitude": 14.57735809999997,
      "parentGeographicAreaId": 0,
      "title": "Via Roma, 2, 84010 Conca dei Marini SA, Italy",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 45,
        "tagName": "Historic Sense",
        "tagType": 1
      },
      {
        "tagId": 57,
        "tagName": "Romantic",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 60,
        "tagName": "Gastronomic",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 131,
        "tagName": "Library",
        "tagType": 2
      },
      {
        "tagId": 185,
        "tagName": "Coastal",
        "tagType": 2
      },
      {
        "tagId": 193,
        "tagName": "Outdoor Dining",
        "tagType": 2
      },
      {
        "tagId": 222,
        "tagName": "Michelin Star dining",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 166,
        "venueCategoryId": 10,
        "venueId": 926,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 10,
          "title": "Infinity Pools",
          "region": "Global",
          "viewOrder": 2,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 800,
    "venueCategoryId": 10,
    "status": 1,
    "title": "Four Seasons Resort Mauritius at Anahita",
    "description": "Everything natural, modern, rustic and luxurious – you’ll find it at Four Seasons Resort Mauritius at Anahita, a secluded and spacious luxury resort where you can spend all day coasting across the water in your own sailing boat, tour a former sugar plantation, make friends with a giant tortoise, and afterwards fall asleep under the stars. \n\nIn the heart of the Indian Ocean, the hotel welcomes guests to a luxurious tropical sanctuary, where greetings of warm smiles and gentle lagoon breezes fill each day. Whether you're playing a complimentary round on the thrilling oceanfront finish of our Ernie Els-designed golf course or splashing with the family by the lagoon, our stylish open-air luxury resort invites relaxation, romance and endless possibilities for adventure amid the dramatic landscape and the vibrant colours of Mauritius.",
    "brand": "Four Seasons",
    "blockQuote": "Ideal choice for honeymooners and privacy lovers are the ocean view villas.",
    "emailAddress": "res.mauritius@fourseasons.com",
    "website": "fourseasons.com/mauritius/",
    "bookingURL": "https://reservations.fourseasons.com/?hotelCode=MRU183&checkIn=2021-03-19&checkOut=2021-03-20&adults=2&children=0&iata=96027890",
    "redirectNotes": "Four Seasons Resort Mauritius at Anahita's reservations system",
    "phoneNumber": "+1 230 402 3100",
    "addressOne": "",
    "addressTwo": "",
    "city": "Beau Champ",
    "county": "",
    "region": "",
    "country": "Mauritius",
    "rooms": 136,
    "dollar": 3,
    "updated": "2021-03-19T09:06:31.48",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 46343,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/9ff49f03-1565-4f94-a9b5-af6486a656ea/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9ff49f03-1565-4f94-a9b5-af6486a656ea/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9ff49f03-1565-4f94-a9b5-af6486a656ea/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 23078,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/4a8073b8-9ac2-43c2-94d7-e0763b5cc656/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4a8073b8-9ac2-43c2-94d7-e0763b5cc656/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4a8073b8-9ac2-43c2-94d7-e0763b5cc656/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 46357,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a3853712-b8cf-4b94-90b9-3f9e1114fd41/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a3853712-b8cf-4b94-90b9-3f9e1114fd41/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a3853712-b8cf-4b94-90b9-3f9e1114fd41/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 23079,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/22b1e27d-685b-49d1-ace7-e70b32458125/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/22b1e27d-685b-49d1-ace7-e70b32458125/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/22b1e27d-685b-49d1-ace7-e70b32458125/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 46351,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/94170780-9adb-4286-9e0b-e38fdc37cf10/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/94170780-9adb-4286-9e0b-e38fdc37cf10/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/94170780-9adb-4286-9e0b-e38fdc37cf10/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 46352,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f5652d2a-f44e-4400-8eac-1935f4f721ba/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f5652d2a-f44e-4400-8eac-1935f4f721ba/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f5652d2a-f44e-4400-8eac-1935f4f721ba/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 46344,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/5002f6cf-4842-478b-905f-008a200613dd/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5002f6cf-4842-478b-905f-008a200613dd/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5002f6cf-4842-478b-905f-008a200613dd/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 23081,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/04739920-cc64-4836-a4c3-2d2d57bde6e6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/04739920-cc64-4836-a4c3-2d2d57bde6e6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/04739920-cc64-4836-a4c3-2d2d57bde6e6/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 23082,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/046f3cb0-3bc3-4745-b4c0-5547699e9dd5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/046f3cb0-3bc3-4745-b4c0-5547699e9dd5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/046f3cb0-3bc3-4745-b4c0-5547699e9dd5/256x192.jpg",
        "viewOrder": 12
      },
      {
        "mediaId": 46354,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/932d6e3a-f72b-4148-b882-1190e82ff990/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/932d6e3a-f72b-4148-b882-1190e82ff990/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/932d6e3a-f72b-4148-b882-1190e82ff990/256x192.jpeg",
        "viewOrder": 14
      },
      {
        "mediaId": 46356,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/25da718f-dbbe-43d3-ae97-5ac7aa2db4e2/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/25da718f-dbbe-43d3-ae97-5ac7aa2db4e2/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/25da718f-dbbe-43d3-ae97-5ac7aa2db4e2/256x192.jpeg",
        "viewOrder": 15
      },
      {
        "mediaId": 46348,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/beef99de-0755-4084-a014-6d63bcc8ca01/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/beef99de-0755-4084-a014-6d63bcc8ca01/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/beef99de-0755-4084-a014-6d63bcc8ca01/256x192.jpeg",
        "viewOrder": 17
      }
    ],
    "geographicArea": {
      "geographicAreaId": 623,
      "latitude": -20.283863,
      "longitude": 57.79366200000004,
      "parentGeographicAreaId": 0,
      "title": "Coastal Road, Beau Champ, Mauritius",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 157,
        "tagName": "Beach Escape",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 58,
        "tagName": "Beach",
        "tagType": 2
      },
      {
        "tagId": 77,
        "tagName": "Cooking Classes",
        "tagType": 2
      },
      {
        "tagId": 81,
        "tagName": "Golf",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 115,
        "tagName": "Snorkeling",
        "tagType": 2
      },
      {
        "tagId": 116,
        "tagName": "Scuba Diving",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 137,
        "venueCategoryId": 10,
        "venueId": 800,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 10,
          "title": "Infinity Pools",
          "region": "Global",
          "viewOrder": 2,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 509,
        "venueCategoryId": 27,
        "venueId": 800,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 27,
          "title": "Family Fun",
          "region": "Global",
          "viewOrder": 16,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 575,
    "venueCategoryId": 10,
    "status": 1,
    "title": "Las Ventanas al Paraiso, A Rosewood Resort",
    "description": "Las Ventanas al Paraíso, a Rosewood Resort, has redefined pampering and luxury in Los Cabos with unparalleled service and unwavering attention to detail.\n\nLas Ventanas al Paraíso offers a choice of exquisitely appointed accommodations. The 84 enchanting suites and villas feature the finest craftsmanship, Mexican motifs and artworks, and accessories reflective of the vibrant hues of Los Cabos.\nThe expansive villas feature hand-carved furniture and accessories reflective of the vibrant hues of Los Cabos. Each villa boasts a private infinity-edge pool as well as awe-inspiring views of the Sea of Cortez. The Ty Warner Mansion is an exclusive 28,000-square-foot beachfront mansion poised directly on the water's edge with panoramic views over the Sea of Cortez. For longer stays in Los Cabos, private residences offer resort living convenience.",
    "brand": "Rosewood",
    "blockQuote": "The pristine white sand beach is a magical place for a sunset dinner.",
    "emailAddress": "lasventanas@rosewoodhotels.com",
    "website": "rosewoodhotels.com/en/las-ventanas-los-cabos",
    "bookingURL": "https://reservations.rosewoodhotels.com/?adult=1&agencyid=96027890&arrive=2021-06-08&chain=5159&child=0&config=gtm&currency=USD&depart=2021-06-09&filter=&hotel=160&level=hotel&locale=en-US&rooms=1",
    "redirectNotes": "Rosewood Las Ventanas al Paraiso's reservations system",
    "phoneNumber": "+52 624 144 2800",
    "addressOne": "",
    "addressTwo": "Km 19.5, Carr. Transpeninsular",
    "city": "Los Cabos",
    "county": "",
    "region": "",
    "country": "Mexico",
    "rooms": 84,
    "dollar": 3,
    "updated": "2021-06-08T14:13:00.223",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 36143,
        "title": "Review: Las Ventanas al Paraiso",
        "url": "https://pkslist.azureedge.net/media/images/1/886f9fa6-4dff-46e5-ad9a-bbf09a3e70b1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/886f9fa6-4dff-46e5-ad9a-bbf09a3e70b1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/886f9fa6-4dff-46e5-ad9a-bbf09a3e70b1/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 36139,
        "title": "Resort Las Ventanas al Paraiso, A Rosewood Resort, San Jose del ...",
        "url": "https://pkslist.azureedge.net/media/images/1/067ac9d5-4e17-4d8d-977a-f25df51257f9/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/067ac9d5-4e17-4d8d-977a-f25df51257f9/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/067ac9d5-4e17-4d8d-977a-f25df51257f9/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 36140,
        "title": "Las Ventanas Al Paraiso, A Rosewood Resort | HKS Architects",
        "url": "https://pkslist.azureedge.net/media/images/1/12e90aa2-e54d-4685-bc04-452dd4f669c0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/12e90aa2-e54d-4685-bc04-452dd4f669c0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/12e90aa2-e54d-4685-bc04-452dd4f669c0/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 36146,
        "title": "Las Ventanas al Paraiso, A Rosewood Resort - Cabo San Lucas - One ...",
        "url": "https://pkslist.azureedge.net/media/images/1/57996a6b-e591-473f-b5c1-f1c1ee5a8185/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/57996a6b-e591-473f-b5c1-f1c1ee5a8185/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/57996a6b-e591-473f-b5c1-f1c1ee5a8185/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 36148,
        "title": "Hotels - Andrew Harper Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/ec7e06fc-b749-49af-939c-5eb4bbfa1311/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ec7e06fc-b749-49af-939c-5eb4bbfa1311/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ec7e06fc-b749-49af-939c-5eb4bbfa1311/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 36145,
        "title": "Las Ventanas al Paraiso, A Rosewood Resort - Cabo San Lucas - One ...",
        "url": "https://pkslist.azureedge.net/media/images/1/71633761-6dce-4703-a640-d7f304c6a911/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/71633761-6dce-4703-a640-d7f304c6a911/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/71633761-6dce-4703-a640-d7f304c6a911/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 36144,
        "title": "Ultimate Jet Vacations",
        "url": "https://pkslist.azureedge.net/media/images/1/baa70e13-dc0d-4ba8-ba9e-c5ff09cf3f28/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/baa70e13-dc0d-4ba8-ba9e-c5ff09cf3f28/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/baa70e13-dc0d-4ba8-ba9e-c5ff09cf3f28/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 36138,
        "title": "Las Ventanas al Paraiso, A Rosewood Resort - Los Cabos, Mexico ...",
        "url": "https://pkslist.azureedge.net/media/images/1/3f5a5585-65fe-419f-8610-f726aba74317/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3f5a5585-65fe-419f-8610-f726aba74317/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3f5a5585-65fe-419f-8610-f726aba74317/256x192.jpg",
        "viewOrder": 12
      }
    ],
    "geographicArea": {
      "geographicAreaId": 415,
      "latitude": 22.97736,
      "longitude": -109.76867300000004,
      "parentGeographicAreaId": 0,
      "title": "Carretera Transpeninsular Km 19.5, Cabo Real, 23400 San Jose del Cabo, B.C.S., Mexico",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 57,
        "tagName": "Romantic",
        "tagType": 1
      },
      {
        "tagId": 157,
        "tagName": "Beach Escape",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 81,
        "tagName": "Golf",
        "tagType": 2
      },
      {
        "tagId": 88,
        "tagName": "Tennis",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 181,
        "tagName": "Private/Suite Pools",
        "tagType": 2
      },
      {
        "tagId": 219,
        "tagName": "Wine Cellar",
        "tagType": 2
      },
      {
        "tagId": 235,
        "tagName": "Hot Tub",
        "tagType": 2
      },
      {
        "tagId": 270,
        "tagName": "Beach Club",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 109,
        "venueCategoryId": 10,
        "venueId": 575,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 10,
          "title": "Infinity Pools",
          "region": "Global",
          "viewOrder": 2,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 619,
        "venueCategoryId": 32,
        "venueId": 575,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 560,
    "venueCategoryId": 10,
    "status": 1,
    "title": "Six Senses Yao Noi",
    "description": "Six Senses Yao Noi sets the highest benchmark for island resort living in Asia. The iconic limestone pinnacles rising from the waters of Phang Nga Bay create a surreal backdrop and a sense of privacy and adventure. Pool Villa accommodations provide generous personal space, a variety of views and present an uncompromised lifestyle.\n\nNatural materials used in the modern architecture, together with attention to detail and focus on the unique destination reinforce the Six Senses philosophy of sustainability and creating exceptional experiences.",
    "brand": "Six Senses",
    "blockQuote": "Peaceful living environment with a focus on personal space and privacy.",
    "emailAddress": "reservations-yaonoi@sixsenses.com",
    "website": "https://www.sixsenses.com/en/resorts/yao-noi",
    "bookingURL": "https://www.sixsenses.com/en/resorts/yao-noi/accomodation-results?newSearch=1&src=&Hotel=HDYYN&Rooms=1&Adult=2&Child=0&_PMID=96027890",
    "redirectNotes": "Six Senses Yao Noi's reservations system",
    "phoneNumber": "+66 7641 8500",
    "addressOne": "",
    "addressTwo": "",
    "city": "Koh Yao Noi",
    "county": "",
    "region": "Phuket",
    "country": "Thailand",
    "rooms": 56,
    "dollar": 2,
    "updated": "2020-10-12T17:26:40.47",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 17870,
        "title": "Six Senses Yao Noi « Luxury Hotels TravelPlusStyle",
        "url": "https://pkslist.azureedge.net/media/images/1/6d11dcdd-b82e-4ed8-947f-e91b6c6cd085/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6d11dcdd-b82e-4ed8-947f-e91b6c6cd085/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6d11dcdd-b82e-4ed8-947f-e91b6c6cd085/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 17872,
        "title": "Six Senses Yao Noi: 2018 Room Prices, Deals & Reviews | Expedia",
        "url": "https://pkslist.azureedge.net/media/images/1/bb6aabaa-05ba-44ff-b8f3-d3fb6d42ef2a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bb6aabaa-05ba-44ff-b8f3-d3fb6d42ef2a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bb6aabaa-05ba-44ff-b8f3-d3fb6d42ef2a/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 38157,
        "title": "Six Senses Yao Noi, Thailand • Luxury Hotel Review by TravelPlusStyle",
        "url": "https://pkslist.azureedge.net/media/images/1/9c996136-c56b-4ad1-ba6d-800d74e0b81b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9c996136-c56b-4ad1-ba6d-800d74e0b81b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9c996136-c56b-4ad1-ba6d-800d74e0b81b/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 4371,
        "title": "Thailand Pool Villa, Yao Noi Luxury Villas & Suites - Six Senses",
        "url": "https://pkslist.azureedge.net/media/images/1/36d97351-51a6-4226-9fa3-a74fa031b024/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/36d97351-51a6-4226-9fa3-a74fa031b024/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/36d97351-51a6-4226-9fa3-a74fa031b024/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 17871,
        "title": "Six Senses Yao Noi in Phuket - Asia Green Resorts - Asia Tour ...",
        "url": "https://pkslist.azureedge.net/media/images/1/37863300-4eb8-4171-9c7a-d0f54deadb06/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/37863300-4eb8-4171-9c7a-d0f54deadb06/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/37863300-4eb8-4171-9c7a-d0f54deadb06/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 38155,
        "title": "Meetings and events at Six Senses Hideaway Yao Noi, Ko Tao, TH",
        "url": "https://pkslist.azureedge.net/media/images/1/55671cef-7fbe-47a8-90d2-ce85576ede03/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/55671cef-7fbe-47a8-90d2-ce85576ede03/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/55671cef-7fbe-47a8-90d2-ce85576ede03/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 38163,
        "title": "The Retreat at Six Senses Yao Noi – Robb Report",
        "url": "https://pkslist.azureedge.net/media/images/1/3a029d72-f304-4d5c-8b2f-380598c1a054/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3a029d72-f304-4d5c-8b2f-380598c1a054/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3a029d72-f304-4d5c-8b2f-380598c1a054/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 38164,
        "title": "Sis Senses Yao Noi | Vexplore Tours",
        "url": "https://pkslist.azureedge.net/media/images/1/b444389c-ee9f-40e9-bb7d-9602b26e16b6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b444389c-ee9f-40e9-bb7d-9602b26e16b6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b444389c-ee9f-40e9-bb7d-9602b26e16b6/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 38166,
        "title": "Six Senses Yao Noi, Thailand • Luxury Hotel Review by TravelPlusStyle",
        "url": "https://pkslist.azureedge.net/media/images/1/555037e2-657f-49f2-9a8c-272a4800abff/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/555037e2-657f-49f2-9a8c-272a4800abff/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/555037e2-657f-49f2-9a8c-272a4800abff/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 38167,
        "title": "Six Senses Yao Noi",
        "url": "https://pkslist.azureedge.net/media/images/1/be0cad4f-240f-4a62-9db7-65474221d30e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/be0cad4f-240f-4a62-9db7-65474221d30e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/be0cad4f-240f-4a62-9db7-65474221d30e/256x192.jpg",
        "viewOrder": 11
      },
      {
        "mediaId": 38160,
        "title": "Six Senses Yao Noi: A Thai resort with a conscience | CNN Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/4f1c687a-bc5d-4701-a7f2-5a0bd17c1b42/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4f1c687a-bc5d-4701-a7f2-5a0bd17c1b42/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4f1c687a-bc5d-4701-a7f2-5a0bd17c1b42/256x192.jpg",
        "viewOrder": 12
      },
      {
        "mediaId": 17799,
        "title": "Six Senses Yao Noi | Exklusive Luxushotels in Thailand mit BAWA ...",
        "url": "https://pkslist.azureedge.net/media/images/1/5d37ba4a-e7a7-4607-932b-e29220f4da8d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5d37ba4a-e7a7-4607-932b-e29220f4da8d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5d37ba4a-e7a7-4607-932b-e29220f4da8d/256x192.jpg",
        "viewOrder": 14
      }
    ],
    "geographicArea": {
      "geographicAreaId": 398,
      "latitude": 8.119752,
      "longitude": 98.62516099999993,
      "parentGeographicAreaId": 0,
      "title": "56 Moo 5, Koh Yao Noi, อำเภอ เกาะยาว พังงา 82160, Thailand",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 50,
        "tagName": "Barefoot Luxury",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 77,
        "tagName": "Cooking Classes",
        "tagType": 2
      },
      {
        "tagId": 83,
        "tagName": "Kid's Club",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 101,
        "tagName": "Cinema",
        "tagType": 2
      },
      {
        "tagId": 116,
        "tagName": "Scuba Diving",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 181,
        "tagName": "Private/Suite Pools",
        "tagType": 2
      },
      {
        "tagId": 183,
        "tagName": "Butler Service",
        "tagType": 2
      },
      {
        "tagId": 193,
        "tagName": "Outdoor Dining",
        "tagType": 2
      },
      {
        "tagId": 198,
        "tagName": "Boat Excursion",
        "tagType": 2
      },
      {
        "tagId": 206,
        "tagName": "Private Beach",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 106,
        "venueCategoryId": 10,
        "venueId": 560,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 10,
          "title": "Infinity Pools",
          "region": "Global",
          "viewOrder": 2,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 609,
        "venueCategoryId": 32,
        "venueId": 560,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 661,
        "venueCategoryId": 33,
        "venueId": 560,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 33,
          "title": "Rustic Elegance",
          "region": "Global",
          "viewOrder": 11,
          "updated": "2020-09-04T09:04:00",
          "created": "2020-09-04T09:04:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 547,
    "venueCategoryId": 10,
    "status": 1,
    "title": "Amanoi",
    "description": "Amanoi’s two Spa Houses are the first of their kind in Asia. The luxury resort features the serene lakeside Thuy Lien Spa House and the equally secluded An Son Spa House providing guests with completely private and comprehensive treatment facilities, including Banya and Hammam. Fusing contemporary elegance with traditional Vietnamese architectural styles, Amanoi's luxury resort Pavilions, Spa Houses and Residences are perched along a coastal ridge with views of the sea or tucked into a valley overlooking the National Park",
    "brand": "Amanresorts",
    "blockQuote": "Explore the national park with an Amanoi guide to learn about Vietnam's wilderness and get the most out of your experience.",
    "emailAddress": "amanoi@aman.com",
    "website": "aman.com/resorts/amanoi",
    "bookingURL": "https://gc.synxis.com/rez.aspx?Chain=16840&Hotel=62469&Lang=1&template=RBE3&shell=RBE2&iata=96027890",
    "redirectNotes": "Amanoi's reservations system",
    "phoneNumber": "+84 68 377 0777  ",
    "addressOne": "",
    "addressTwo": "Road to Ba Dien Beach",
    "city": "Vinh Hy",
    "county": "",
    "region": "",
    "country": "Vietnam",
    "rooms": 31,
    "dollar": 3,
    "updated": "2020-06-03T08:28:13.38",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 38191,
        "title": "Amanoi, Nha Trang, Vietnam • Luxury Hotel Review by TravelPlusStyle",
        "url": "https://pkslist.azureedge.net/media/images/1/01ba0f8b-c6fa-4151-b3b4-c43fda19d9bb/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/01ba0f8b-c6fa-4151-b3b4-c43fda19d9bb/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/01ba0f8b-c6fa-4151-b3b4-c43fda19d9bb/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 38182,
        "title": "4 Bedroom Residence at Amanoi Resort Vietnam | By UniqueVillas",
        "url": "https://pkslist.azureedge.net/media/images/1/a0d79bb5-171d-4d85-94ca-a307bbecca6e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a0d79bb5-171d-4d85-94ca-a307bbecca6e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a0d79bb5-171d-4d85-94ca-a307bbecca6e/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 38190,
        "title": "Amanoi to offer Holistic Detox Retreat - Vietnam Discovery Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/d6586690-dc52-4f67-8b56-a54356d4e88e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d6586690-dc52-4f67-8b56-a54356d4e88e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d6586690-dc52-4f67-8b56-a54356d4e88e/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 38185,
        "title": "Ocean Pool Villa at Amanoi Resort Vietnam | By UniqueVillas",
        "url": "https://pkslist.azureedge.net/media/images/1/e3470037-968b-4f34-9c88-c95c4034c8b4/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e3470037-968b-4f34-9c88-c95c4034c8b4/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e3470037-968b-4f34-9c88-c95c4034c8b4/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 38188,
        "title": "Amanoi - Ninh Thuan, Vietnam - Black Platinum Gold",
        "url": "https://pkslist.azureedge.net/media/images/1/ca358849-4dc8-4f6d-8653-f023becdce81/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ca358849-4dc8-4f6d-8653-f023becdce81/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ca358849-4dc8-4f6d-8653-f023becdce81/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 38192,
        "title": "Amanoi 5 Bedroom Residence | Luxury Villa in Vinh Hy Bay | Edge ...",
        "url": "https://pkslist.azureedge.net/media/images/1/984efe7e-f861-4c2d-8fd1-eb5a04984ec1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/984efe7e-f861-4c2d-8fd1-eb5a04984ec1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/984efe7e-f861-4c2d-8fd1-eb5a04984ec1/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 38193,
        "title": "5 Bedroom Bay Residence at Amanoi Resort Vietnam | By UniqueVillas",
        "url": "https://pkslist.azureedge.net/media/images/1/97c7f450-2fca-4771-865a-483517ae6b5a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/97c7f450-2fca-4771-865a-483517ae6b5a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/97c7f450-2fca-4771-865a-483517ae6b5a/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 38196,
        "title": "Book Amanoi Resort, Luxury Vacation Rentals by ZEKKEI",
        "url": "https://pkslist.azureedge.net/media/images/1/314a6517-faa4-439c-9b78-48a91310e335/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/314a6517-faa4-439c-9b78-48a91310e335/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/314a6517-faa4-439c-9b78-48a91310e335/256x192.jpg",
        "viewOrder": 11
      },
      {
        "mediaId": 38197,
        "title": "Rooms, villas & suites at Amanoi - Ninh Thuan Province",
        "url": "https://pkslist.azureedge.net/media/images/1/d9f4ec5e-e8b0-4388-9a62-a7a0ee9b93b2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d9f4ec5e-e8b0-4388-9a62-a7a0ee9b93b2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d9f4ec5e-e8b0-4388-9a62-a7a0ee9b93b2/256x192.jpg",
        "viewOrder": 12
      },
      {
        "mediaId": 38183,
        "title": "Amanoi, Nha Trang, Vietnam • Luxury Hotel Review by TravelPlusStyle",
        "url": "https://pkslist.azureedge.net/media/images/1/26db00ac-9173-4332-a4f7-3aafbea722e0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/26db00ac-9173-4332-a4f7-3aafbea722e0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/26db00ac-9173-4332-a4f7-3aafbea722e0/256x192.jpg",
        "viewOrder": 13
      }
    ],
    "geographicArea": {
      "geographicAreaId": 384,
      "latitude": 11.710085,
      "longitude": 109.19304999999997,
      "parentGeographicAreaId": 0,
      "title": "Road to Ba Dien beach, Vĩnh Hải, Ninh Hải, Ninh Thuận, Vietnam",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 47,
        "tagName": "Intimate Luxury ",
        "tagType": 1
      },
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 61,
        "tagName": "Mountain",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 109,
        "tagName": "Fishing",
        "tagType": 2
      },
      {
        "tagId": 171,
        "tagName": "Trekking",
        "tagType": 2
      },
      {
        "tagId": 206,
        "tagName": "Private Beach",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 252,
        "tagName": "National Park",
        "tagType": 2
      },
      {
        "tagId": 266,
        "tagName": "Nature Walk",
        "tagType": 2
      },
      {
        "tagId": 276,
        "tagName": "Gym",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 102,
        "venueCategoryId": 10,
        "venueId": 547,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 10,
          "title": "Infinity Pools",
          "region": "Global",
          "viewOrder": 2,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 528,
    "venueCategoryId": 10,
    "status": 1,
    "title": "Amanzoe",
    "description": "Situated on a gentle rise surrounded by undulating olive groves, Amanzoe’s 360-degree views take in the Peloponnese countryside and rocky coves. The resort is within easy reach of a wealth of archaeological sites and bustling Porto Heli.\n\nDotted across the hillside, Amanzoe’s Pavilions and Villas are classically inspired and contemporary in attitude.",
    "brand": "Amanresorts",
    "blockQuote": "Sundowners on the terrace offering panoramic views of the Peloponnese coastline is an absolute must.",
    "emailAddress": "amanzoe@aman.com",
    "website": "aman.com/resorts/amanzoe",
    "bookingURL": "https://gc.synxis.com/rez.aspx?Chain=16840&Hotel=62477&Lang=1&template=RBE3&shell=RBE2&iata=96027890",
    "redirectNotes": "Amanzoe's reservations system",
    "phoneNumber": "+30 275 4772 888",
    "addressOne": "",
    "addressTwo": "",
    "city": "Kranidi",
    "county": "",
    "region": "",
    "country": "Greece",
    "rooms": 38,
    "dollar": 3,
    "updated": "2020-05-13T09:21:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 4000,
        "title": "Amanzoe - Luxury Hotel & Resort in Porto Heli, Greece - Aman",
        "url": "https://pkslist.azureedge.net/media/images/1/9b75f136-6abc-4a09-9bb2-697421e2a559/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9b75f136-6abc-4a09-9bb2-697421e2a559/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9b75f136-6abc-4a09-9bb2-697421e2a559/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 4001,
        "title": "Villas To Own - Buy Villas in Porto Heli - Aman Real Estate",
        "url": "https://pkslist.azureedge.net/media/images/1/f550074c-607e-4eee-8a7c-d25d79cf0e21/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f550074c-607e-4eee-8a7c-d25d79cf0e21/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f550074c-607e-4eee-8a7c-d25d79cf0e21/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 4008,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/6ee96189-49e3-47a6-aea6-1f67ff7304c7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6ee96189-49e3-47a6-aea6-1f67ff7304c7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6ee96189-49e3-47a6-aea6-1f67ff7304c7/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 4002,
        "title": "Villas To Own - Buy Villas in Porto Heli - Aman Real Estate",
        "url": "https://pkslist.azureedge.net/media/images/1/037a99f3-b506-4858-a8cb-7dd54172daf2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/037a99f3-b506-4858-a8cb-7dd54172daf2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/037a99f3-b506-4858-a8cb-7dd54172daf2/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 4004,
        "title": "Amanzoe-Amanresorts-Greece - CPP-LUXURY",
        "url": "https://pkslist.azureedge.net/media/images/1/48a499dc-4aae-4bbc-8441-a488288bb528/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/48a499dc-4aae-4bbc-8441-a488288bb528/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/48a499dc-4aae-4bbc-8441-a488288bb528/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 4005,
        "title": "Amanzoe - UPDATED 2017 Hotel Reviews & Price Comparison (Kranidi ...",
        "url": "https://pkslist.azureedge.net/media/images/1/b45869dc-cb94-4a58-b28f-a29a0a0de1d6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b45869dc-cb94-4a58-b28f-a29a0a0de1d6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b45869dc-cb94-4a58-b28f-a29a0a0de1d6/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 4007,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/8b766b13-0899-42af-8257-aa1c63b3e7fd/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8b766b13-0899-42af-8257-aa1c63b3e7fd/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8b766b13-0899-42af-8257-aa1c63b3e7fd/256x192.jpg",
        "viewOrder": 6
      }
    ],
    "geographicArea": {
      "geographicAreaId": 367,
      "latitude": 37.37054699999999,
      "longitude": 23.117470000000026,
      "parentGeographicAreaId": 0,
      "title": "Agios Panteleimonas site, Kranidi 213 00, Greece",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 40,
        "tagName": "Boutique Bolthole ",
        "tagType": 1
      },
      {
        "tagId": 47,
        "tagName": "Intimate Luxury ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 5,
        "tagName": "Billiards",
        "tagType": 2
      },
      {
        "tagId": 31,
        "tagName": "Countryside",
        "tagType": 2
      },
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 73,
        "tagName": "Outdoor Terraces",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 88,
        "tagName": "Tennis",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 108,
        "tagName": "Jacuzzi",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 131,
        "tagName": "Library",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 92,
        "venueCategoryId": 10,
        "venueId": 528,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 10,
          "title": "Infinity Pools",
          "region": "Global",
          "viewOrder": 2,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 482,
    "venueCategoryId": 10,
    "status": 1,
    "title": "Amankila",
    "description": "Set atop stilts amid the treetops, the 34 free-standing Suites have been fashioned as Balinese beach houses with private outdoor terraces.\n\nThe panoramic views from Amankila, perched on a hillside above the Lombok Strait, are the first step to discovering east Bali’s mix of spiritual and regal heritage. Sacred mountains tower over villages tucked between emerald-green rice paddies, where life has changed little over the centuries. Watersports such as scuba diving, sailing and paddle boarding can be enjoyed in the pristine waters",
    "brand": "Amanresorts",
    "blockQuote": "A water sports lover's tropical hideaway.",
    "emailAddress": "amankila@aman.com",
    "website": "aman.com/resorts/amankila",
    "bookingURL": "https://gc.synxis.com/rez.aspx?hotel=62472&arrive=02/07/2018&depart=02/08/2018&adult=1&Chain=16840&template=RBE3&shell=RBE2&iata=96027890",
    "redirectNotes": "Amankila's reservations system",
    "phoneNumber": "+62 363 41333 ",
    "addressOne": "Amankila",
    "addressTwo": "",
    "city": "Manggis",
    "county": "",
    "region": "Bali",
    "country": "Indonesia",
    "rooms": 34,
    "dollar": 3,
    "updated": "2020-05-21T12:08:26.663",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 3379,
        "title": "The Amankila resort on Bali's coastline in Indonesia",
        "url": "https://pkslist.azureedge.net/media/images/1/9dd12b8b-91f5-47c8-9370-c5deb0b6c2f9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9dd12b8b-91f5-47c8-9370-c5deb0b6c2f9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9dd12b8b-91f5-47c8-9370-c5deb0b6c2f9/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 4572,
        "title": "Review: Amankila Bali - One Mile at a Time",
        "url": "https://pkslist.azureedge.net/media/images/1/b4b8a742-2ba7-4cd4-b850-3d4775ec0f30/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b4b8a742-2ba7-4cd4-b850-3d4775ec0f30/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b4b8a742-2ba7-4cd4-b850-3d4775ec0f30/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 3384,
        "title": "Discover Adventure Experiences in Bali, Amankila - Aman",
        "url": "https://pkslist.azureedge.net/media/images/1/ab96bbe8-84cc-4b10-8cff-254f8bfa1c1f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ab96bbe8-84cc-4b10-8cff-254f8bfa1c1f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ab96bbe8-84cc-4b10-8cff-254f8bfa1c1f/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 3385,
        "title": "Rooms & Suites - Amankila Resort",
        "url": "https://pkslist.azureedge.net/media/images/1/47fb019b-2f85-487e-b5b3-8c1800c3c311/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/47fb019b-2f85-487e-b5b3-8c1800c3c311/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/47fb019b-2f85-487e-b5b3-8c1800c3c311/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 4571,
        "title": "Amankila - Hotels & Style",
        "url": "https://pkslist.azureedge.net/media/images/1/df3d3812-79e0-427f-be63-e54617b56e60/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/df3d3812-79e0-427f-be63-e54617b56e60/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/df3d3812-79e0-427f-be63-e54617b56e60/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 4574,
        "title": "Amankila | Ultimate Collection | LuxuryHunt.com",
        "url": "https://pkslist.azureedge.net/media/images/1/1ca589b5-0fc9-4dd1-8d11-56dff7d0f550/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1ca589b5-0fc9-4dd1-8d11-56dff7d0f550/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1ca589b5-0fc9-4dd1-8d11-56dff7d0f550/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 3383,
        "title": "Amankila, Bali, Indonesia",
        "url": "https://pkslist.azureedge.net/media/images/1/94d943e9-57d8-45ff-80f8-aab1123ccc0e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/94d943e9-57d8-45ff-80f8-aab1123ccc0e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/94d943e9-57d8-45ff-80f8-aab1123ccc0e/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 3387,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/dda577e7-5943-4c62-a25d-36e0ef90eda0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/dda577e7-5943-4c62-a25d-36e0ef90eda0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/dda577e7-5943-4c62-a25d-36e0ef90eda0/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 3380,
        "title": "Short stay: Amankila, Bali, Indonesia - FlyerTalk Forums",
        "url": "https://pkslist.azureedge.net/media/images/1/a2870369-f809-4877-8a49-0bb23792ad8e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a2870369-f809-4877-8a49-0bb23792ad8e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a2870369-f809-4877-8a49-0bb23792ad8e/256x192.jpg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 326,
      "latitude": -8.500832999999998,
      "longitude": 115.52750000000003,
      "parentGeographicAreaId": 0,
      "title": "Amankila, Manggis, Kabupaten Karangasem, Bali, Indonesia",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 47,
        "tagName": "Intimate Luxury ",
        "tagType": 1
      },
      {
        "tagId": 157,
        "tagName": "Beach Escape",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 58,
        "tagName": "Beach",
        "tagType": 2
      },
      {
        "tagId": 59,
        "tagName": "Cultural",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 115,
        "tagName": "Snorkeling",
        "tagType": 2
      },
      {
        "tagId": 116,
        "tagName": "Scuba Diving",
        "tagType": 2
      },
      {
        "tagId": 122,
        "tagName": "Surfing",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 83,
        "venueCategoryId": 10,
        "venueId": 482,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 10,
          "title": "Infinity Pools",
          "region": "Global",
          "viewOrder": 2,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 305,
    "venueCategoryId": 26,
    "status": 1,
    "title": "COMO Maalifushi",
    "description": "COMO Maalifushi is the first luxury resort in the Maldives to open in the pristine Thaa Atoll in the archipelago’s southwest. With overwater villas and beach suites, COMO Maalifushi is therefore the ultimate away-from-it-all destination for families, couples, surfers and divers. There are some excellent breaks nearby as well as a string of uninhabited private islands, where there are seasonal hammerhead and manta ray sightings.",
    "brand": "COMO Hotels",
    "blockQuote": "DO NOT miss the Night Whale Shark Excursions, you can snorkel up close with whale sharks at night, the largest fish in the sea!",
    "emailAddress": "maalifushi@comohotels.com",
    "website": "comohotels.com/maalifushi",
    "bookingURL": "https://be.synxis.com/?Start=AvailResults&_ga=2.186191273.1223958135.1536067566-1513030496.1536067566&adult=1&agencyid=96027890&arrive=2021-07-09&chain=10327&child=0&currency=USD&depart=2021-07-10&filter=&hotel=59844&lang=0&level=hotel&locale=en-US&rooms=1",
    "redirectNotes": "COMO Maalifushi's reservations system",
    "phoneNumber": "+96 678 0008",
    "addressOne": "Guraidhoo",
    "addressTwo": "",
    "city": "Thaa Atoll",
    "county": "",
    "region": "",
    "country": "Maldives",
    "rooms": 65,
    "dollar": 3,
    "updated": "2021-07-08T11:32:40.33",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 2042,
        "title": "Maalifushi by COMO | Luxury Hotels TravelPlusStyle",
        "url": "https://pkslist.azureedge.net/media/images/1/eb4d4b3b-b6c6-48fc-8243-762f903ad471/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/eb4d4b3b-b6c6-48fc-8243-762f903ad471/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/eb4d4b3b-b6c6-48fc-8243-762f903ad471/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 1173,
        "title": "Review: Maalifushi by COMO | Luxury Insider",
        "url": "https://pkslist.azureedge.net/media/images/1/38c9cd2c-c90a-4cf5-90fa-f217d46cb1ab/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/38c9cd2c-c90a-4cf5-90fa-f217d46cb1ab/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/38c9cd2c-c90a-4cf5-90fa-f217d46cb1ab/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 1174,
        "title": "Review: Maalifushi by COMO | Luxury Insider",
        "url": "https://pkslist.azureedge.net/media/images/1/84d5930d-7ce3-4fdc-a6a6-d9c47e998d26/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/84d5930d-7ce3-4fdc-a6a6-d9c47e998d26/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/84d5930d-7ce3-4fdc-a6a6-d9c47e998d26/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 6055,
        "title": "From where you'd rather be: Maalifushi by COMO, Buro 24/7",
        "url": "https://pkslist.azureedge.net/media/images/1/aebbee6f-4c3d-4ab1-9b24-99ba877e0caf/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/aebbee6f-4c3d-4ab1-9b24-99ba877e0caf/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/aebbee6f-4c3d-4ab1-9b24-99ba877e0caf/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 1171,
        "title": "Review: Maalifushi by COMO | Luxury Insider",
        "url": "https://pkslist.azureedge.net/media/images/1/0d00eb46-9836-498a-89e8-1d628abd8037/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0d00eb46-9836-498a-89e8-1d628abd8037/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0d00eb46-9836-498a-89e8-1d628abd8037/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 2043,
        "title": "Maalifushi by COMO to open in the Maldives | Luxury Hotels ...",
        "url": "https://pkslist.azureedge.net/media/images/1/f889d642-c41b-4af7-b268-aad9373c91ca/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f889d642-c41b-4af7-b268-aad9373c91ca/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f889d642-c41b-4af7-b268-aad9373c91ca/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 1170,
        "title": "Review: Maalifushi by COMO | Luxury Insider",
        "url": "https://pkslist.azureedge.net/media/images/1/2890a29d-9af9-4ed7-9736-9f2fceee7f25/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2890a29d-9af9-4ed7-9736-9f2fceee7f25/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2890a29d-9af9-4ed7-9736-9f2fceee7f25/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 6056,
        "title": "COMO Maalifushi - Two Bedroom Beach Suite",
        "url": "https://pkslist.azureedge.net/media/images/1/070f9cc2-9cd4-4266-90e4-2b25f65e6718/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/070f9cc2-9cd4-4266-90e4-2b25f65e6718/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/070f9cc2-9cd4-4266-90e4-2b25f65e6718/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 6053,
        "title": "COMO Maalifushi",
        "url": "https://pkslist.azureedge.net/media/images/1/91393c08-b6b4-4760-b225-1b5538a7a9fb/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/91393c08-b6b4-4760-b225-1b5538a7a9fb/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/91393c08-b6b4-4760-b225-1b5538a7a9fb/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 6057,
        "title": "COMO Maalifushi, The Maldives (Maalefushi) - Resort Reviews ...",
        "url": "https://pkslist.azureedge.net/media/images/1/2f2a13c4-02d8-48bd-8280-bdad37bb9082/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2f2a13c4-02d8-48bd-8280-bdad37bb9082/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2f2a13c4-02d8-48bd-8280-bdad37bb9082/256x192.jpg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 166,
      "latitude": 2.209209,
      "longitude": 73.14946170000007,
      "parentGeographicAreaId": 0,
      "title": "Thaa Atoll, Maldives",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 50,
        "tagName": "Barefoot Luxury",
        "tagType": 1
      },
      {
        "tagId": 157,
        "tagName": "Beach Escape",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 58,
        "tagName": "Beach",
        "tagType": 2
      },
      {
        "tagId": 78,
        "tagName": "Fine Dining",
        "tagType": 2
      },
      {
        "tagId": 102,
        "tagName": "Eco-friendly",
        "tagType": 2
      },
      {
        "tagId": 115,
        "tagName": "Snorkeling",
        "tagType": 2
      },
      {
        "tagId": 116,
        "tagName": "Scuba Diving",
        "tagType": 2
      },
      {
        "tagId": 120,
        "tagName": "Private Dining",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 41,
        "venueCategoryId": 10,
        "venueId": 305,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 10,
          "title": "Infinity Pools",
          "region": "Global",
          "viewOrder": 2,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 481,
        "venueCategoryId": 15,
        "venueId": 305,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 15,
          "title": "Beach Paradise",
          "region": "Global",
          "viewOrder": 6,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 482,
        "venueCategoryId": 19,
        "venueId": 305,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 19,
          "title": "Private Islands",
          "region": "Global",
          "viewOrder": 15,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  }
]
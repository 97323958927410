// 20240123100509
// https://app.pks-list.com/api/Venue/v1/getlist/35

export const placesStore17=
[
  {
    "venueId": 3421,
    "venueCategoryId": 0,
    "status": 1,
    "title": "White Desert Echo",
    "description": "Echo is as close as you can get to feeling like you’re off the planet without leaving Earth.\n\nThe space-age design of the ‘Sky Pods’ makes them look like they’ve been beamed down from Mars. Futuristic and luxurious, the six bedrooms are created from composite fibreglass with floor to ceiling windows allowing guests to soak in the moon-like landscape beyond.\n\nOriginal photos taken from the International Space Station (ISS) by former Commander, White Desert guest and retired astronaut, colonel Terry Virts will be featured in each pod. Says Virts of the Echo landscape, “The mountains are the most beautiful I have seen across Earth, Venus and Mars.”\n\nPerfect for exclusive use groups and catering for up to 12 people, Echo allows guests to combine an ultra-luxury experience with a once in lifetime adventure on the 7th Continent. As with all White Desert’s camps, Echo is designed to be dismantled, leaving no trace on the Antarctic landscape.",
    "brand": "",
    "blockQuote": "You may never get to outer space but you can live like an astronaut.",
    "emailAddress": "info@white-desert.com",
    "website": "white-desert.com/echo/",
    "bookingURL": "https://white-desert.com/dates-and-rates/2022-2023/",
    "redirectNotes": "White Desert Echo's reservations system",
    "phoneNumber": "+1 815 534 4533",
    "addressOne": "",
    "addressTwo": "",
    "city": "Somewhere",
    "county": "",
    "region": "",
    "country": "Antarctica",
    "rooms": 6,
    "dollar": 3,
    "updated": "2022-07-19T11:01:09.663",
    "created": "2022-07-19T10:45:54.977",
    "medias": [
      {
        "mediaId": 53963,
        "title": "White Desert Echo Camp | Uncrate",
        "url": "https://pkslist.azureedge.net/media/images/1/0a295c1e-eaad-4b59-9b36-e2e0129386ee/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0a295c1e-eaad-4b59-9b36-e2e0129386ee/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0a295c1e-eaad-4b59-9b36-e2e0129386ee/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 53964,
        "title": "White Desert Antarctica launch 'Echo': a new camp inspired by ...",
        "url": "https://pkslist.azureedge.net/media/images/1/cc1bf930-fa0f-4ee1-bae6-a677f718228c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cc1bf930-fa0f-4ee1-bae6-a677f718228c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cc1bf930-fa0f-4ee1-bae6-a677f718228c/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 53965,
        "title": "White Desert's New Antarctica Camp : Echo | Luxsphere",
        "url": "https://pkslist.azureedge.net/media/images/1/9683e493-63fc-4977-bcbc-cf12589514f6/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9683e493-63fc-4977-bcbc-cf12589514f6/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9683e493-63fc-4977-bcbc-cf12589514f6/256x192.png",
        "viewOrder": 2
      },
      {
        "mediaId": 53970,
        "title": "White Desert Echo Camp Antarctica | stupidDOPE.com",
        "url": "https://pkslist.azureedge.net/media/images/1/0d31d609-55e9-41c0-97b2-b5e70542077d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0d31d609-55e9-41c0-97b2-b5e70542077d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0d31d609-55e9-41c0-97b2-b5e70542077d/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 53967,
        "title": "Echo Camp from White Desert - UnnamedProject",
        "url": "https://pkslist.azureedge.net/media/images/1/7d1fc25b-21b8-4e7e-ac21-396c73dd0ca0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7d1fc25b-21b8-4e7e-ac21-396c73dd0ca0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7d1fc25b-21b8-4e7e-ac21-396c73dd0ca0/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 53962,
        "title": "First Look at Echo, White Desert's New Space-Inspired Antarctic Camp",
        "url": "https://pkslist.azureedge.net/media/images/1/f0d9eaca-d606-47bc-96b9-5096ef689e4b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f0d9eaca-d606-47bc-96b9-5096ef689e4b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f0d9eaca-d606-47bc-96b9-5096ef689e4b/256x192.jpg",
        "viewOrder": 5
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3209,
      "latitude": -75.250973,
      "longitude": -0.071389,
      "parentGeographicAreaId": 0,
      "title": "Antarctica",
      "updated": null,
      "created": "2022-07-19T10:54:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 53,
        "tagName": "Cutting Edge",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 172,
        "tagName": "Biking",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 243,
        "tagName": "Outdoor Activities",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 296,
        "tagName": "Guided Walk",
        "tagType": 2
      },
      {
        "tagId": 327,
        "tagName": "Snowmobiles",
        "tagType": 2
      },
      {
        "tagId": 338,
        "tagName": "Cross Country Skiing",
        "tagType": 2
      },
      {
        "tagId": 365,
        "tagName": "Private Chef",
        "tagType": 2
      },
      {
        "tagId": 453,
        "tagName": "Abseiling",
        "tagType": 2
      },
      {
        "tagId": 454,
        "tagName": "Ice Climbing",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 757,
        "venueCategoryId": 34,
        "venueId": 3421,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 758,
        "venueCategoryId": 17,
        "venueId": 3421,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 759,
        "venueCategoryId": 13,
        "venueId": 3421,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 760,
        "venueCategoryId": 20,
        "venueId": 3421,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 20,
          "title": "BucketList Hotels",
          "region": "Global",
          "viewOrder": 0,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 778,
        "venueCategoryId": 35,
        "venueId": 3421,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3420,
    "venueCategoryId": 0,
    "status": 1,
    "title": "White Desert Wolf’s Fang",
    "description": "Offering laidback yet sophisticated interiors that evoke the bygone age of explorers, Wolf’s Fang features six heated bedroom tents that are equipped with en-suite washrooms, replete with shower rooms. The relaxed lounge area is a haven of comfort and warmth, no matter what the conditions outside. The inviting dining area is the ideal space to revisit the day’s exploration with fellow adventurers.\n\nAdrenalin seekers will enjoy a variety of exhilarating activities on this vast continent including abseiling, ice-climbing and rope-walks. Guests looking for peace and serenity will revel in gentle hikes, skidoo rides or simply taking in the dramatic vistas – guests can be as active or as relaxed as they wish during their stay.",
    "brand": "",
    "blockQuote": "You're going to love getting a drink at the world's most remote ice bar.",
    "emailAddress": "info@white-desert.com",
    "website": "white-desert.com/our-camps/wolfs-fang/",
    "bookingURL": "https://white-desert.com/dates-and-rates/2022-2023/",
    "redirectNotes": "White Desert Wolf’s Fang's reservations system",
    "phoneNumber": "+1 815 534 4533",
    "addressOne": "",
    "addressTwo": "",
    "city": "Queen Maud Land",
    "county": "",
    "region": "",
    "country": "Antarctica",
    "rooms": 6,
    "dollar": 3,
    "updated": "2022-07-19T11:05:15.973",
    "created": "2022-07-19T10:18:25.84",
    "medias": [
      {
        "mediaId": 53952,
        "title": "White Desert unveils new Antarctic camp | Aspire Travel Club",
        "url": "https://pkslist.azureedge.net/media/images/1/a5079944-3478-4380-bd78-c17d5f7b8c85/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a5079944-3478-4380-bd78-c17d5f7b8c85/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a5079944-3478-4380-bd78-c17d5f7b8c85/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 53945,
        "title": "How to Build a Hotel in Antarctica - Bloomberg",
        "url": "https://pkslist.azureedge.net/media/images/1/c5c81550-094c-4ad4-9562-dddf37abaed1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c5c81550-094c-4ad4-9562-dddf37abaed1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c5c81550-094c-4ad4-9562-dddf37abaed1/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 53960,
        "title": "How to Build a Hotel in Antarctica - Bloomberg",
        "url": "https://pkslist.azureedge.net/media/images/1/9a8f8fec-7d58-483c-b8ff-8adf30e4e8f0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9a8f8fec-7d58-483c-b8ff-8adf30e4e8f0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9a8f8fec-7d58-483c-b8ff-8adf30e4e8f0/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 53944,
        "title": "Tempus News Tags - Antarctica",
        "url": "https://pkslist.azureedge.net/media/images/1/a23ed882-caa3-45b8-b85f-177e34b96acf/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a23ed882-caa3-45b8-b85f-177e34b96acf/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a23ed882-caa3-45b8-b85f-177e34b96acf/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 53947,
        "title": "Experience Antarctica Without Getting Seasick - EliteVoyage",
        "url": "https://pkslist.azureedge.net/media/images/1/0986c4f6-7b32-4333-8935-6206dc634aa5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0986c4f6-7b32-4333-8935-6206dc634aa5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0986c4f6-7b32-4333-8935-6206dc634aa5/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 53943,
        "title": "Tempus News - White Desert launches Wolf's Fang, a new camp in ...",
        "url": "https://pkslist.azureedge.net/media/images/1/52f0eafd-cdec-41de-9949-d169ed75ecc7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/52f0eafd-cdec-41de-9949-d169ed75ecc7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/52f0eafd-cdec-41de-9949-d169ed75ecc7/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 53961,
        "title": "How to Build a Hotel in Antarctica - Bloomberg",
        "url": "https://pkslist.azureedge.net/media/images/1/59e72f7c-08a5-4db6-8b64-ecb7cfa97c22/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/59e72f7c-08a5-4db6-8b64-ecb7cfa97c22/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/59e72f7c-08a5-4db6-8b64-ecb7cfa97c22/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 53950,
        "title": "White Desert Tours Antarctica Opens Wolf's Fang Camp",
        "url": "https://pkslist.azureedge.net/media/images/1/e1859777-ac73-46dc-9955-00c647648f17/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e1859777-ac73-46dc-9955-00c647648f17/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e1859777-ac73-46dc-9955-00c647648f17/256x192.jpg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3208,
      "latitude": -86,
      "longitude": -160,
      "parentGeographicAreaId": 0,
      "title": "Queen Maud Mountains, Antarctica",
      "updated": null,
      "created": "2022-07-19T10:23:00"
    },
    "personalities": [
      {
        "tagId": 53,
        "tagName": "Cutting Edge",
        "tagType": 1
      },
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 133,
        "tagName": "Glamping",
        "tagType": 2
      },
      {
        "tagId": 172,
        "tagName": "Biking",
        "tagType": 2
      },
      {
        "tagId": 243,
        "tagName": "Outdoor Activities",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 296,
        "tagName": "Guided Walk",
        "tagType": 2
      },
      {
        "tagId": 317,
        "tagName": "Gourmet",
        "tagType": 2
      },
      {
        "tagId": 327,
        "tagName": "Snowmobiles",
        "tagType": 2
      },
      {
        "tagId": 453,
        "tagName": "Abseiling",
        "tagType": 2
      },
      {
        "tagId": 454,
        "tagName": "Ice Climbing",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 754,
        "venueCategoryId": 34,
        "venueId": 3420,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 755,
        "venueCategoryId": 17,
        "venueId": 3420,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 756,
        "venueCategoryId": 13,
        "venueId": 3420,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 779,
        "venueCategoryId": 35,
        "venueId": 3420,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3180,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Grand Hotel Kronenhof",
    "description": "The architecture of Grand Hotel Kronenhof makes it one of the most splendid hotels in the Alps. This heritage-protected luxury-class establishment is located in the heart of the idyllic Engadine village of Pontresina, only six kilometres from St. Moritz. The only 5-star superior hotel in the village, it boasts some 112 spacious rooms and suites as well as the large and well-equipped Kronenhof Spa and several very notable restaurants.",
    "brand": "",
    "blockQuote": "Ice-skating is romantic as a couple and fun as a family.",
    "emailAddress": "info@kronenhof.com",
    "website": "kronenhof.com/en/",
    "bookingURL": "https://be.synxis.com/?_ga=2.104969795.1359158448.1625127152-477122420.1625127152&adult=2&agencyid=96027890&arrive=2021-07-01&chain=10205&child=0&config=FLEXTEST&configcode=Kronenhof&currency=CHF&depart=2021-07-02&filter=&hotel=6058&level=hotel&linking=FLEX_CHAIN&locale=en-US&nocache=true&rooms=1&sbe_sid=els_hLtMlANJwHDptbh3htAf&theme=NETTOBOOK&themecode=Positioner",
    "redirectNotes": "Grand Hotel Kronenhof's reservations system",
    "phoneNumber": "+41 81 830 30 30",
    "addressOne": "130",
    "addressTwo": "Via Maistra",
    "city": "Pontresina",
    "county": "",
    "region": "",
    "country": "Switzerland",
    "rooms": 112,
    "dollar": 2,
    "updated": "2021-07-01T08:40:12.767",
    "created": "2021-07-01T08:14:24.073",
    "medias": [
      {
        "mediaId": 48378,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/44c61502-fc54-478c-8f14-58cc00164b89/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/44c61502-fc54-478c-8f14-58cc00164b89/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/44c61502-fc54-478c-8f14-58cc00164b89/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 48380,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/1f93d111-e659-4b39-887c-4944e0a69ea1/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1f93d111-e659-4b39-887c-4944e0a69ea1/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1f93d111-e659-4b39-887c-4944e0a69ea1/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 48381,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f291e817-3e0a-49e0-a634-8a45b7d87a1c/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f291e817-3e0a-49e0-a634-8a45b7d87a1c/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f291e817-3e0a-49e0-a634-8a45b7d87a1c/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 48383,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/2d652b1a-98ea-40e6-9814-038fd12b4ef1/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2d652b1a-98ea-40e6-9814-038fd12b4ef1/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2d652b1a-98ea-40e6-9814-038fd12b4ef1/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 48394,
        "title": "GRAND HOTEL KRONENHOF - Updated 2021 Prices & Reviews (Pontresina ...",
        "url": "https://pkslist.azureedge.net/media/images/1/c30f3ac9-e1e6-4e9f-b159-c1cdf70d21f0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c30f3ac9-e1e6-4e9f-b159-c1cdf70d21f0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c30f3ac9-e1e6-4e9f-b159-c1cdf70d21f0/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 48386,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/88462c32-bdff-452d-9927-72333ba7b1bf/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/88462c32-bdff-452d-9927-72333ba7b1bf/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/88462c32-bdff-452d-9927-72333ba7b1bf/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 48387,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c0fe4f11-c467-4d90-b3e0-fe9c38599f14/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c0fe4f11-c467-4d90-b3e0-fe9c38599f14/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c0fe4f11-c467-4d90-b3e0-fe9c38599f14/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 48388,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a89473fb-51fd-4b10-9c28-1247932f92df/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a89473fb-51fd-4b10-9c28-1247932f92df/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a89473fb-51fd-4b10-9c28-1247932f92df/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 48384,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/4965e856-0429-4221-bebc-ae273cb0bf08/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4965e856-0429-4221-bebc-ae273cb0bf08/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4965e856-0429-4221-bebc-ae273cb0bf08/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 48379,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d902525b-1572-450a-9c94-2a13ad69685b/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d902525b-1572-450a-9c94-2a13ad69685b/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d902525b-1572-450a-9c94-2a13ad69685b/256x192.jpeg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2967,
      "latitude": 46.493499,
      "longitude": 9.90105,
      "parentGeographicAreaId": 0,
      "title": "Via Maistra 130, 7504 Pontresina, Switzerland",
      "updated": null,
      "created": "2021-07-01T08:20:00"
    },
    "personalities": [
      {
        "tagId": 44,
        "tagName": "Grand Dame ",
        "tagType": 1
      },
      {
        "tagId": 45,
        "tagName": "Historic Sense",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 66,
        "tagName": "Skiing",
        "tagType": 2
      },
      {
        "tagId": 81,
        "tagName": "Golf",
        "tagType": 2
      },
      {
        "tagId": 83,
        "tagName": "Kid's Club",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 159,
        "tagName": "Mountain Biking",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 299,
        "tagName": "Wellness Programs",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 734,
        "venueCategoryId": 17,
        "venueId": 3180,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3097,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Experimental Chalet",
    "description": "Experimental Chalet is a stylish Alpine retreat in the heart of Verbier with a a signature cocktail bar, a restaurant by the renowned Parisian chef Gregory Marchand (Frenchie), the legendary Farm Club and a spa by Biologique Recherche.\n\nPerched among the snow-capped summits of Verbier, the chalet offers a selection of 39 rooms and suites, some with terrace, jacuzzi, or panoramic views of the surrounding Alpine peaks and Mont Combin. With open spaces and natural light, the comfortable, minimalist design by Milanese architect Fabrizio Casiraghi is inspired by mid-century resorts, with deep jewel and sorbet tones, and lacquered wood.",
    "brand": "",
    "blockQuote": "Après-ski aficionados can hit the cocktail bar and continue into the night at the famous Farm Club.",
    "emailAddress": "info@experimentalchalet.com",
    "website": "experimentalchalet.com/",
    "bookingURL": "https://be.synxis.com/?adult=1&agencyid=96027890&arrive=2021-02-09&chain=21856&child=0&currency=CHF&depart=2021-02-10&hotel=5061&level=hotel&locale=en-US&rooms=1",
    "redirectNotes": "Experimental Chalet's reservations system",
    "phoneNumber": "+41 27 775 40 00",
    "addressOne": "55",
    "addressTwo": "Route de Verbier Station",
    "city": "Verbier",
    "county": "",
    "region": "",
    "country": "Switzerland",
    "rooms": 39,
    "dollar": 2,
    "updated": "2021-02-09T09:17:47.063",
    "created": "2021-02-09T09:01:05.21",
    "medias": [
      {
        "mediaId": 45293,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/fce602bf-c3d1-4270-967e-06d72a50d6a6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fce602bf-c3d1-4270-967e-06d72a50d6a6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fce602bf-c3d1-4270-967e-06d72a50d6a6/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 45291,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/7b1ce3df-c5ac-4d38-94bb-7caaa8876f32/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7b1ce3df-c5ac-4d38-94bb-7caaa8876f32/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7b1ce3df-c5ac-4d38-94bb-7caaa8876f32/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 45295,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/1b8eafd9-7c31-4150-889c-6b43a4da3629/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1b8eafd9-7c31-4150-889c-6b43a4da3629/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1b8eafd9-7c31-4150-889c-6b43a4da3629/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 45296,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/413fa0e5-dd72-47ac-b8ba-b6e7e20940df/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/413fa0e5-dd72-47ac-b8ba-b6e7e20940df/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/413fa0e5-dd72-47ac-b8ba-b6e7e20940df/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 45301,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e4aa1e20-1637-4524-a406-bdb9fe74c353/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e4aa1e20-1637-4524-a406-bdb9fe74c353/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e4aa1e20-1637-4524-a406-bdb9fe74c353/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 45303,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/8d91f7c4-55fa-48f9-8743-0b405b2c8207/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8d91f7c4-55fa-48f9-8743-0b405b2c8207/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8d91f7c4-55fa-48f9-8743-0b405b2c8207/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 45309,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/fc19db77-395b-4056-961f-4398f7c7829f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fc19db77-395b-4056-961f-4398f7c7829f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fc19db77-395b-4056-961f-4398f7c7829f/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 45299,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/cceaf581-d088-4b9d-9408-d2960fb72969/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cceaf581-d088-4b9d-9408-d2960fb72969/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cceaf581-d088-4b9d-9408-d2960fb72969/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 45300,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/29b04d07-08a4-453f-94fe-d6442f8ff61f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/29b04d07-08a4-453f-94fe-d6442f8ff61f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/29b04d07-08a4-453f-94fe-d6442f8ff61f/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 45294,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/49783662-6cc6-41ed-b29a-3f0a9e9f1ad2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/49783662-6cc6-41ed-b29a-3f0a9e9f1ad2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/49783662-6cc6-41ed-b29a-3f0a9e9f1ad2/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 45310,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e16e063f-03d3-47fe-835c-7e60f0628171/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e16e063f-03d3-47fe-835c-7e60f0628171/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e16e063f-03d3-47fe-835c-7e60f0628171/256x192.jpg",
        "viewOrder": 13
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2884,
      "latitude": 46.096231,
      "longitude": 7.2260847,
      "parentGeographicAreaId": 0,
      "title": "Route de Verbier Station 55, 1936 Verbier, Switzerland",
      "updated": null,
      "created": "2021-02-09T09:09:00"
    },
    "personalities": [
      {
        "tagId": 40,
        "tagName": "Boutique Bolthole ",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 66,
        "tagName": "Skiing",
        "tagType": 2
      },
      {
        "tagId": 84,
        "tagName": "Nightclub",
        "tagType": 2
      },
      {
        "tagId": 124,
        "tagName": "Helicopter",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 235,
        "tagName": "Hot Tub",
        "tagType": 2
      },
      {
        "tagId": 243,
        "tagName": "Outdoor Activities",
        "tagType": 2
      },
      {
        "tagId": 250,
        "tagName": "Ziplining",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 340,
        "tagName": "Snow Shoeing",
        "tagType": 2
      },
      {
        "tagId": 443,
        "tagName": "Skydiving",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 723,
        "venueCategoryId": 17,
        "venueId": 3097,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3071,
    "venueCategoryId": 0,
    "status": 1,
    "title": "White Desert Whichaway",
    "description": "Whichaway is our flagship camp and sits on the shores of one of the freshwater lakes of the ice-free Schirmacher Oasis.\n\nWe’ve designed 6 heated ‘polar pods’ with cutting-edge exteriors and old-world interiors that conjure up images of Antarctica’s rich heritage of exploration. Our guests really love to sit back and chat in the communal lounge, library and dining area which is a wonderful space to relax with amazing views.\n\nSavour gourmet meals prepared by your private chef, enjoy a sauna with view of the glacier, sip cocktails by the fire, and cross off bucket list adventures like visiting the South Pole, ice climbing, hiking over ice waves, and coming face-to-face with 28,000 emperor penguins.",
    "brand": "",
    "blockQuote": "You can be David Attenborough for a day when you visit the Atka Bay Emperor Penguin Colony.",
    "emailAddress": "info@white-desert.com",
    "website": "white-desert.com/our-camps/whichaway/",
    "bookingURL": "https://white-desert.com/dates-and-rates/2022-2023/",
    "redirectNotes": "White Desert Whichaway's reservations system",
    "phoneNumber": "+1 815 534 4533",
    "addressOne": "",
    "addressTwo": "",
    "city": "Schirmacher Oasis",
    "county": "",
    "region": "",
    "country": "Antarctica",
    "rooms": 6,
    "dollar": 3,
    "updated": "2022-07-19T11:05:06.367",
    "created": "2021-01-21T17:13:19.397",
    "medias": [
      {
        "mediaId": 53936,
        "title": "White Desert Antarctic Adventures | Uncrate",
        "url": "https://pkslist.azureedge.net/media/images/1/0e478a51-9a93-4c0d-a822-337c1794df9e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0e478a51-9a93-4c0d-a822-337c1794df9e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0e478a51-9a93-4c0d-a822-337c1794df9e/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 44673,
        "title": "Ice ice baby: White Desert",
        "url": "https://pkslist.azureedge.net/media/images/1/d2642b2b-b3e7-46a8-adea-db953c1aa7ec/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d2642b2b-b3e7-46a8-adea-db953c1aa7ec/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d2642b2b-b3e7-46a8-adea-db953c1aa7ec/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 44674,
        "title": "This Luxury, One-day Trip to Antarctica Will Cost You a Mere ...",
        "url": "https://pkslist.azureedge.net/media/images/1/db56d0d5-b4b4-48a3-a8fc-5d53c30f5b28/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/db56d0d5-b4b4-48a3-a8fc-5d53c30f5b28/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/db56d0d5-b4b4-48a3-a8fc-5d53c30f5b28/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 44688,
        "title": "Antarctica: Luxury Cruises & Activities | Extraordinary Journeys",
        "url": "https://pkslist.azureedge.net/media/images/1/5835c4e6-3f71-483d-8ded-28b674c4dfbd/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5835c4e6-3f71-483d-8ded-28b674c4dfbd/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5835c4e6-3f71-483d-8ded-28b674c4dfbd/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 44684,
        "title": "Luxury campsite in Antarctica offers tiny domed pods",
        "url": "https://pkslist.azureedge.net/media/images/1/56cc4684-8e91-44d9-86a1-61e4cebbdb07/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/56cc4684-8e91-44d9-86a1-61e4cebbdb07/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/56cc4684-8e91-44d9-86a1-61e4cebbdb07/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 53940,
        "title": "White Desert Antarctica - World of Glamping - Luxury glamping escapes",
        "url": "https://pkslist.azureedge.net/media/images/1/8bc7bc9c-4bff-4e76-ba50-b78039160095/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8bc7bc9c-4bff-4e76-ba50-b78039160095/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8bc7bc9c-4bff-4e76-ba50-b78039160095/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 53941,
        "title": "Whichaway Oasis – Safari Architects",
        "url": "https://pkslist.azureedge.net/media/images/1/e5ac3b5d-d3d8-45db-8c26-d4d7464664d6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e5ac3b5d-d3d8-45db-8c26-d4d7464664d6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e5ac3b5d-d3d8-45db-8c26-d4d7464664d6/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 53938,
        "title": "White Desert Antarctica - World of Glamping - Luxury glamping escapes",
        "url": "https://pkslist.azureedge.net/media/images/1/8508eb52-7b4e-407a-a04d-c02349759f6b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8508eb52-7b4e-407a-a04d-c02349759f6b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8508eb52-7b4e-407a-a04d-c02349759f6b/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 44671,
        "title": "White Desert Antarctic Adventures | HiConsumption",
        "url": "https://pkslist.azureedge.net/media/images/1/69dd104f-669f-4901-85d0-0d05fd3a0c1c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/69dd104f-669f-4901-85d0-0d05fd3a0c1c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/69dd104f-669f-4901-85d0-0d05fd3a0c1c/256x192.jpg",
        "viewOrder": 12
      },
      {
        "mediaId": 44677,
        "title": "Flying the last frontier - Skies Mag",
        "url": "https://pkslist.azureedge.net/media/images/1/0cc4c21f-06b2-4f9a-a0d1-f73b9f645e2a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0cc4c21f-06b2-4f9a-a0d1-f73b9f645e2a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0cc4c21f-06b2-4f9a-a0d1-f73b9f645e2a/256x192.jpg",
        "viewOrder": 13
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2857,
      "latitude": -70.75,
      "longitude": 11.666667,
      "parentGeographicAreaId": 0,
      "title": "Schirmacher Ponds, Antarctica",
      "updated": null,
      "created": "2021-01-21T17:52:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 53,
        "tagName": "Cutting Edge",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 102,
        "tagName": "Eco-friendly",
        "tagType": 2
      },
      {
        "tagId": 131,
        "tagName": "Library",
        "tagType": 2
      },
      {
        "tagId": 134,
        "tagName": "Wilderness Walks",
        "tagType": 2
      },
      {
        "tagId": 164,
        "tagName": "Excursions",
        "tagType": 2
      },
      {
        "tagId": 171,
        "tagName": "Trekking",
        "tagType": 2
      },
      {
        "tagId": 186,
        "tagName": "Rock Climbing",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 259,
        "tagName": "Picnic",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 317,
        "tagName": "Gourmet",
        "tagType": 2
      },
      {
        "tagId": 338,
        "tagName": "Cross Country Skiing",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 712,
        "venueCategoryId": 17,
        "venueId": 3071,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 713,
        "venueCategoryId": 34,
        "venueId": 3071,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 714,
        "venueCategoryId": 13,
        "venueId": 3071,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2963,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Six Senses Residences Courchevel",
    "description": "Set at the heart of Courchevel 1850 in the Three Valleys area of the French Alps, Six Senses Residences Courchevel brings together the traditional and contemporary. Fine features and furnishings sourced from top designers, a concierge to assist with all aspects of your stay including private catering, Six Senses Spa and Japanese restaurant Sumosan bringing the wow-factor to every ski holiday.\n\nMeticulously designed interiors are a hallmark of the 25 apartments, featuring and Alpine setting with a blend of contemporary style with traditional mountain influences. All deliver unobstructed and panoramic views and up to four bedrooms, plus balconies and terraces in close proximity to the village and slopes.",
    "brand": "Six Senses",
    "blockQuote": "Relax in the spa's outdoor hot tub after an exciting day of skiing.",
    "emailAddress": "reservations-courchevel@sixsenses.com",
    "website": "sixsenses.com/en/resorts/residences-courchevel",
    "bookingURL": "https://www.sixsenses.com/en/resorts/residences-courchevel/accomodation-results?newSearch=1&src=&Hotel=LYSSF&Rooms=1&Adult=2&Child=0&_PMID=96027890",
    "redirectNotes": "Six Senses Residences Courchevel's reservations system",
    "phoneNumber": "+33 479 069 363",
    "addressOne": "291",
    "addressTwo": "Rue des Tovets",
    "city": "Courchevel",
    "county": "",
    "region": "",
    "country": "France",
    "rooms": 25,
    "dollar": 3,
    "updated": "2022-07-19T09:05:19.777",
    "created": "2020-11-11T16:23:31.103",
    "medias": [
      {
        "mediaId": 42267,
        "title": "Apartment Six Senses 4, Courchevel 1850 • Leo Trippi",
        "url": "https://pkslist.azureedge.net/media/images/1/74cd4872-e33a-4286-a05f-636d6a171ebb/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/74cd4872-e33a-4286-a05f-636d6a171ebb/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/74cd4872-e33a-4286-a05f-636d6a171ebb/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 42252,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e468267e-7274-497e-b9e1-1d53d83e9857/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e468267e-7274-497e-b9e1-1d53d83e9857/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e468267e-7274-497e-b9e1-1d53d83e9857/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 42269,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/2bafe4be-3df4-416f-8d23-90def5bc1d2d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2bafe4be-3df4-416f-8d23-90def5bc1d2d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2bafe4be-3df4-416f-8d23-90def5bc1d2d/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 42258,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/2c9be15a-8861-4ac9-8b50-7ffba75d786c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2c9be15a-8861-4ac9-8b50-7ffba75d786c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2c9be15a-8861-4ac9-8b50-7ffba75d786c/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 42268,
        "title": "The stunning Spa at Six Senses Residences Courchevel, luxury ski ...",
        "url": "https://pkslist.azureedge.net/media/images/1/572c5844-dc9f-41c9-86f2-f49bddb7ce4f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/572c5844-dc9f-41c9-86f2-f49bddb7ce4f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/572c5844-dc9f-41c9-86f2-f49bddb7ce4f/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 42261,
        "title": "Six Senses Residences, Courchevel 1850, France - Capital and Provident",
        "url": "https://pkslist.azureedge.net/media/images/1/5b00305f-5444-4280-8478-3aea94d02efa/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5b00305f-5444-4280-8478-3aea94d02efa/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5b00305f-5444-4280-8478-3aea94d02efa/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 42265,
        "title": "Five Star Hotels, Luxury Spa Resorts - Six Senses Hotels Resorts Spas",
        "url": "https://pkslist.azureedge.net/media/images/1/02f31ea2-a3a7-4ba7-811d-3bdaf5f21acc/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/02f31ea2-a3a7-4ba7-811d-3bdaf5f21acc/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/02f31ea2-a3a7-4ba7-811d-3bdaf5f21acc/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 42257,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/602f7709-5f77-4016-9600-9ce7a4caf3f8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/602f7709-5f77-4016-9600-9ce7a4caf3f8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/602f7709-5f77-4016-9600-9ce7a4caf3f8/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 42271,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/11a1d16c-f0a1-444e-9e19-a06b6f9bb065/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/11a1d16c-f0a1-444e-9e19-a06b6f9bb065/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/11a1d16c-f0a1-444e-9e19-a06b6f9bb065/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 42255,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/386cc406-5a57-4f18-9618-44029a24bc22/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/386cc406-5a57-4f18-9618-44029a24bc22/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/386cc406-5a57-4f18-9618-44029a24bc22/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 42256,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/5503e28d-8a6c-45d9-b41d-4956b91776ce/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5503e28d-8a6c-45d9-b41d-4956b91776ce/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5503e28d-8a6c-45d9-b41d-4956b91776ce/256x192.jpg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2748,
      "latitude": 45.4154791,
      "longitude": 6.6366241,
      "parentGeographicAreaId": 0,
      "title": "291 Rue des Tovets, 73120 Saint-Bon-Tarentaise, France",
      "updated": null,
      "created": "2020-11-11T16:36:00"
    },
    "personalities": [
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 66,
        "tagName": "Skiing",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 124,
        "tagName": "Helicopter",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 219,
        "tagName": "Wine Cellar",
        "tagType": 2
      },
      {
        "tagId": 235,
        "tagName": "Hot Tub",
        "tagType": 2
      },
      {
        "tagId": 236,
        "tagName": "Dog Sledding",
        "tagType": 2
      },
      {
        "tagId": 265,
        "tagName": "Hot Air Balloon",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 327,
        "tagName": "Snowmobiles",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 698,
        "venueCategoryId": 17,
        "venueId": 2963,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2571,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Grand Hotel Zermatterhof",
    "description": "A grand hotel is built for living, not just sleeping. Today the Grand Hotel Zermatterhof welcomes illustrious guests into its five-star surroundings in exactly the same way as it has done ever since 1879. Enjoying a central but peaceful location, this traditional luxury establishment offers classic elegance and all the comforts of modernity. The hotel has 77 individually furnished rooms and suites with magnificent views of the surrounding mountains, promising both peace and space in an exclusive setting. And for the ultimate in luxury, guests can avail themselves of the hotel's wellness zone, Vita borni. Here, you can experience an atmosphere of wellbeing with a spacious pool, saunascape, treatment rooms and a private spa for couples.",
    "brand": null,
    "blockQuote": "Book a room with views of the Matterhorn, you deserve it!",
    "emailAddress": "info@zermatterhof.ch",
    "website": "zermatterhof.ch/en/",
    "bookingURL": "https://be.synxis.com/?adult=2&agencyid=96027890&arrive=2019-09-04&chain=10237&child=0&currency=CHF&depart=2019-09-06&hotel=26938&level=hotel&locale=en-US&rooms=1",
    "redirectNotes": "Grand Hotel Zermatterhof's reservations system",
    "phoneNumber": "+41 27 966 66 00",
    "addressOne": "55",
    "addressTwo": "Bahnhofstrasse",
    "city": "Zermatt",
    "county": "",
    "region": "",
    "country": "Switzerland",
    "rooms": 77,
    "dollar": 2,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 34056,
        "title": "Grand Hotel Zermatterhof Zermatt, Switzerland | Out There magazine ...",
        "url": "https://pkslist.azureedge.net/media/images/1/8037d932-4a9c-41e2-a596-7f5086c49c39/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8037d932-4a9c-41e2-a596-7f5086c49c39/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8037d932-4a9c-41e2-a596-7f5086c49c39/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 34054,
        "title": "The 5-star hotel in Zermatt",
        "url": "https://pkslist.azureedge.net/media/images/1/926f6588-2abd-451e-81d2-d7a976019879/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/926f6588-2abd-451e-81d2-d7a976019879/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/926f6588-2abd-451e-81d2-d7a976019879/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 34062,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f2a0bed4-d531-4884-b199-49b410f5d297/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f2a0bed4-d531-4884-b199-49b410f5d297/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f2a0bed4-d531-4884-b199-49b410f5d297/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 34061,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/667e27f1-fa49-48c2-88c1-8349f5ddb8e0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/667e27f1-fa49-48c2-88c1-8349f5ddb8e0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/667e27f1-fa49-48c2-88c1-8349f5ddb8e0/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 34060,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ac2084f1-e30b-416c-bb3d-a52e5295c4d6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ac2084f1-e30b-416c-bb3d-a52e5295c4d6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ac2084f1-e30b-416c-bb3d-a52e5295c4d6/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 34055,
        "title": "swisshoteldata.ch - Swiss hotel directory",
        "url": "https://pkslist.azureedge.net/media/images/1/e5f02d39-640b-4001-a68a-c52943817452/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e5f02d39-640b-4001-a68a-c52943817452/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e5f02d39-640b-4001-a68a-c52943817452/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 34058,
        "title": "Grand Hotel Zermatterhof - Ski Europe - winter ski vacation deals ...",
        "url": "https://pkslist.azureedge.net/media/images/1/f15a085e-ee73-4ddb-aba9-5b89903e53c5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f15a085e-ee73-4ddb-aba9-5b89903e53c5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f15a085e-ee73-4ddb-aba9-5b89903e53c5/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 34063,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ad086f56-ddda-4bb0-8a2e-f7c613c58fe2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ad086f56-ddda-4bb0-8a2e-f7c613c58fe2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ad086f56-ddda-4bb0-8a2e-f7c613c58fe2/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 34057,
        "title": "Grand Hotel Zermatterhof - Booking & Info",
        "url": "https://pkslist.azureedge.net/media/images/1/a0b8986c-57da-4228-9be4-e64385e18ea7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a0b8986c-57da-4228-9be4-e64385e18ea7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a0b8986c-57da-4228-9be4-e64385e18ea7/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2355,
      "latitude": 46.02007820000001,
      "longitude": 7.746948100000054,
      "parentGeographicAreaId": 0,
      "title": "Bahnhofstrasse 55, 3920 Zermatt, Switzerland",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 45,
        "tagName": "Historic Sense",
        "tagType": 1
      },
      {
        "tagId": 48,
        "tagName": "Iconic/Landmark ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 66,
        "tagName": "Skiing",
        "tagType": 2
      },
      {
        "tagId": 68,
        "tagName": "Business",
        "tagType": 2
      },
      {
        "tagId": 105,
        "tagName": "Afternoon Tea",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 153,
        "tagName": "Cigar Lounge",
        "tagType": 2
      },
      {
        "tagId": 159,
        "tagName": "Mountain Biking",
        "tagType": 2
      },
      {
        "tagId": 222,
        "tagName": "Michelin Star dining",
        "tagType": 2
      },
      {
        "tagId": 280,
        "tagName": "Piano",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 294,
        "tagName": "Event Space",
        "tagType": 2
      },
      {
        "tagId": 324,
        "tagName": "Local Village",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 458,
        "venueCategoryId": 17,
        "venueId": 2571,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2533,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Nira Alpina",
    "description": "Nira Alpina provides an ideal base for both summer and winter holidays and each season brings a myriad of breathtaking natural sights. With fast access to the pistes and paths, our location puts you in the heart of the mountains – in winter, ski-in and ski-out, and in summer, start your hiking or biking at 1870 metres. We have direct covered access to Corvatsch cable car and with the slope just 5 metres from the hotel, everything you need is within walking distance.\n\nOur luxurious rooms are spacious yet cosy and offer large windows looking out to snowy mountains or perfectly manicured gardens. Experience an adventure a world away, an escape from the everyday, a retreat to the extraordinary. And with our rooftop bar and Stars Restaurant, you can discover a selection of delicious culinary experiences that will leave you wanting more.",
    "brand": "",
    "blockQuote": "Head to Pichalain for apres-ski in the cosiest of settings for cheese fondue and raclette.",
    "emailAddress": "info@niraalpina.com",
    "website": "niraalpina.com/",
    "bookingURL": "https://be.synxis.com/?_ga=2.95061340.1361633181.1566566192-1648391523.1566566192&adult=2&agencyid=96027890&arrive=2024-01-04&chain=5154&child=0&currency=CHF&depart=2024-01-05&hotel=53708&level=hotel&locale=en-US&rooms=1&shell=RES_NIRA_ALPINA_V1&template=RES_NIRA_ALPINA_V1",
    "redirectNotes": "Nira Alpina's reservations system",
    "phoneNumber": "+41 81 838 69 69",
    "addressOne": "76",
    "addressTwo": "Via dal Corvatsch",
    "city": "Silvaplana",
    "county": "",
    "region": "St Moritz",
    "country": "Switzerland",
    "rooms": 70,
    "dollar": 1,
    "updated": "2024-01-04T13:30:42.817",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 63741,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e9ceea62-e2de-4ed1-af3a-1757e3f6f994/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e9ceea62-e2de-4ed1-af3a-1757e3f6f994/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e9ceea62-e2de-4ed1-af3a-1757e3f6f994/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 63742,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b25b5cb1-5590-4037-aeda-d072eb8a1baf/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b25b5cb1-5590-4037-aeda-d072eb8a1baf/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b25b5cb1-5590-4037-aeda-d072eb8a1baf/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 63746,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/06c5e596-450e-432c-9b51-61d886e501bf/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/06c5e596-450e-432c-9b51-61d886e501bf/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/06c5e596-450e-432c-9b51-61d886e501bf/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 63744,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/5450765c-008f-413b-a046-78d1c8465ad2/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5450765c-008f-413b-a046-78d1c8465ad2/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5450765c-008f-413b-a046-78d1c8465ad2/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 63748,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/93674b43-f6aa-474c-9758-efd0d4bedc0f/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/93674b43-f6aa-474c-9758-efd0d4bedc0f/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/93674b43-f6aa-474c-9758-efd0d4bedc0f/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 33287,
        "title": "NIRA ALPINA - Updated 2019 Prices & Hotel Reviews (Silvaplana ...",
        "url": "https://pkslist.azureedge.net/media/images/1/69e1bd2d-4dc5-4b34-a3ba-2e1d9087af97/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/69e1bd2d-4dc5-4b34-a3ba-2e1d9087af97/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/69e1bd2d-4dc5-4b34-a3ba-2e1d9087af97/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 33285,
        "title": "NIRA ALPINA - Updated 2019 Prices & Hotel Reviews (Silvaplana ...",
        "url": "https://pkslist.azureedge.net/media/images/1/31ff1749-28fe-4bd7-9d04-f0854915f802/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/31ff1749-28fe-4bd7-9d04-f0854915f802/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/31ff1749-28fe-4bd7-9d04-f0854915f802/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 63745,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/5b3608f5-0ad8-484d-9d6f-fbe43cf7cc5a/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5b3608f5-0ad8-484d-9d6f-fbe43cf7cc5a/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5b3608f5-0ad8-484d-9d6f-fbe43cf7cc5a/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 63747,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c32af87b-ba59-4e35-96a3-3548e1f188b1/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c32af87b-ba59-4e35-96a3-3548e1f188b1/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c32af87b-ba59-4e35-96a3-3548e1f188b1/256x192.jpeg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2318,
      "latitude": 46.4552309,
      "longitude": 9.813583300000005,
      "parentGeographicAreaId": 0,
      "title": "Via dal Corvatsch 76, 7513 Silvaplana, Switzerland",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 66,
        "tagName": "Skiing",
        "tagType": 2
      },
      {
        "tagId": 83,
        "tagName": "Kid's Club",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 159,
        "tagName": "Mountain Biking",
        "tagType": 2
      },
      {
        "tagId": 178,
        "tagName": "Rooftop Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 276,
        "tagName": "Gym",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 285,
        "tagName": "Steam Room",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 453,
        "venueCategoryId": 17,
        "venueId": 2533,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2236,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Ski Portillo",
    "description": "The iconic Hotel Portillo has 123 comfortable rooms, all with views of the spectacular Inca lagoon or the alley where the resort is located.\n\nAlmost all the activities take place within the resort and most of the services as well. There is no city, shopping centers or nearby cafes. They offer double rooms, suites and family apartments, in which there is no television as they encourage guests to participate in extra activities and take advantage of all their services.\n\nAll rooms in the hotel have a telephone, minibar, safe, hair dryer and Wi-Fi internet connection.",
    "brand": null,
    "blockQuote": "The outdoor pool and jacuzzi are waiting for you after a day on the slopes.",
    "emailAddress": "reservas@skiportillo.com",
    "website": "skiportillo.com/en/",
    "bookingURL": "https://reservas.skiportillo.com/cgi-bin/reservas_portillo.cgi?LANGUAGE=EN",
    "redirectNotes": null,
    "phoneNumber": "+56 223 617 000",
    "addressOne": "420",
    "addressTwo": "Ruta 60",
    "city": "Andes Mountain Range",
    "county": "",
    "region": "",
    "country": "Chile",
    "rooms": 123,
    "dollar": 2,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 28180,
        "title": "Private Tour Inca Lagoon & Portillo | Guided Bike Tours & Private ...",
        "url": "https://pkslist.azureedge.net/media/images/1/e312622b-5879-4954-8f78-957708069a28/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e312622b-5879-4954-8f78-957708069a28/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e312622b-5879-4954-8f78-957708069a28/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 28175,
        "title": "Mountain Guide: Ski Portillo – Orsden",
        "url": "https://pkslist.azureedge.net/media/images/1/943cd9bd-ced1-4003-8511-6deae6fb8e81/1200x900.jpg?v=1509372043",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/943cd9bd-ced1-4003-8511-6deae6fb8e81/800x600.jpg?v=1509372043",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/943cd9bd-ced1-4003-8511-6deae6fb8e81/256x192.jpg?v=1509372043",
        "viewOrder": 1
      },
      {
        "mediaId": 28182,
        "title": "Portillo Chile Hotel Lodging Rooms Information",
        "url": "https://pkslist.azureedge.net/media/images/1/36b40009-a1d0-4226-b55d-91a3eabd46fe/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/36b40009-a1d0-4226-b55d-91a3eabd46fe/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/36b40009-a1d0-4226-b55d-91a3eabd46fe/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 28181,
        "title": "Portillo Hotel Chile - Ski Resort",
        "url": "https://pkslist.azureedge.net/media/images/1/8be97594-983a-40ee-ae44-d5a685b95170/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8be97594-983a-40ee-ae44-d5a685b95170/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8be97594-983a-40ee-ae44-d5a685b95170/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 28178,
        "title": "Ski Chile: Skiing in the Andes at Portillo & Atacama Desert Tours ...",
        "url": "https://pkslist.azureedge.net/media/images/1/c64c08d2-6735-4627-bef0-ba03babc8d09/1200x900.jpg?1435153337",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c64c08d2-6735-4627-bef0-ba03babc8d09/800x600.jpg?1435153337",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c64c08d2-6735-4627-bef0-ba03babc8d09/256x192.jpg?1435153337",
        "viewOrder": 5
      },
      {
        "mediaId": 28179,
        "title": "Ski Portillo: Best Chile Ski Resort",
        "url": "https://pkslist.azureedge.net/media/images/1/88d1b2a1-183e-4b46-9641-103d99584460/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/88d1b2a1-183e-4b46-9641-103d99584460/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/88d1b2a1-183e-4b46-9641-103d99584460/256x192.jpg",
        "viewOrder": 6
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2038,
      "latitude": -32.8356074,
      "longitude": -70.12928929999998,
      "parentGeographicAreaId": 0,
      "title": "Ruta 60 420, Los Andes, Región de Valparaíso, Chile",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 61,
        "tagName": "Mountain",
        "tagType": 2
      },
      {
        "tagId": 66,
        "tagName": "Skiing",
        "tagType": 2
      },
      {
        "tagId": 75,
        "tagName": "Casual Dining",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 101,
        "tagName": "Cinema",
        "tagType": 2
      },
      {
        "tagId": 108,
        "tagName": "Jacuzzi",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 124,
        "tagName": "Helicopter",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 303,
        "tagName": "Games Room",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 437,
        "venueCategoryId": 17,
        "venueId": 2236,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2137,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Alpina Gstaad",
    "description": "A new icon in the exclusive, discrete and cosmopolitan Swiss resort of Gstaad, The Alpina Gstaad five-star superior hotel has single-handedly redefined luxury and Alpine chic. Find serenity in the Six Senses Spa - a holistic destination spa informed by Asian wellness traditions that draws upon the energy of the Alps and of the Bernese Oberland's life-giving peaks and pastures. This is no ordinary hotel, it is a unique establishment where authenticity combines with Swiss refinement to create an Alpine experience like no other.\n\nAt The Alpina Gstaad, each of the 56 rooms and suites uniquely embody Swiss authenticity and sophistication. The expertise of artisans from the region has created inspiring spaces, where original and contemporary local materials are subtly united with genuine period woodwork.\n\nDefined as “the birth of a new classic”, The Alpina Gstaad has already come to represent the ultimate standard in the hotel business for the most exacting international clientele.",
    "brand": null,
    "blockQuote": "The world-renown Six Senses Spa is a must visit offering unique treatments to indulge the senses.",
    "emailAddress": "reservations@thealpinagstaad.ch",
    "website": "thealpinagstaad.ch/",
    "bookingURL": "https://gc.synxis.com/rez.aspx?Hotel=56809&Chain=10237&template=rSIRTA&shell=rSIRTA&locale=en&iata=96027890",
    "redirectNotes": "Alpina Gstaad's reservations system",
    "phoneNumber": "+41 33 888 9888",
    "addressOne": "23",
    "addressTwo": "Alpinastrasse",
    "city": "Gstaad",
    "county": "",
    "region": "",
    "country": "Switzerland",
    "rooms": 56,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 26550,
        "title": "Hotel The Alpina Gstaad in Switzerland - Homepage",
        "url": "https://pkslist.azureedge.net/media/images/1/34af7832-9339-4965-9f59-a7461afd41c7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/34af7832-9339-4965-9f59-a7461afd41c7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/34af7832-9339-4965-9f59-a7461afd41c7/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 26545,
        "title": "The Alpina Gstaad - One of a kind hotels",
        "url": "https://pkslist.azureedge.net/media/images/1/3f1ff2e4-0a86-4340-a909-13124d8237e4/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3f1ff2e4-0a86-4340-a909-13124d8237e4/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3f1ff2e4-0a86-4340-a909-13124d8237e4/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 26560,
        "title": "Hotel The Alpina Gstaad in Switzerland - Gastronomy",
        "url": "https://pkslist.azureedge.net/media/images/1/6ee26a19-ebad-48e0-a8fb-9a96cd54a5a3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6ee26a19-ebad-48e0-a8fb-9a96cd54a5a3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6ee26a19-ebad-48e0-a8fb-9a96cd54a5a3/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 26553,
        "title": "Hotel The Alpina Gstaad in Switzerland - Panorama Suite",
        "url": "https://pkslist.azureedge.net/media/images/1/6c3e4fbe-9b01-4440-b3a3-737afc371069/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6c3e4fbe-9b01-4440-b3a3-737afc371069/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6c3e4fbe-9b01-4440-b3a3-737afc371069/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 26561,
        "title": "Hotel The Alpina Gstaad in Switzerland - Главная страница",
        "url": "https://pkslist.azureedge.net/media/images/1/c1744d68-8eea-4954-b310-04516315031e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c1744d68-8eea-4954-b310-04516315031e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c1744d68-8eea-4954-b310-04516315031e/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 26555,
        "title": "Hotel The Alpina Gstaad in Switzerland - Panorama Suite",
        "url": "https://pkslist.azureedge.net/media/images/1/d2faa357-1585-477f-b8de-3382d9b48b15/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d2faa357-1585-477f-b8de-3382d9b48b15/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d2faa357-1585-477f-b8de-3382d9b48b15/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 26554,
        "title": "Hotel The Alpina Gstaad in Switzerland - Panorama Suite",
        "url": "https://pkslist.azureedge.net/media/images/1/526dcec3-45c5-40e0-b68c-b35292b0fa0a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/526dcec3-45c5-40e0-b68c-b35292b0fa0a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/526dcec3-45c5-40e0-b68c-b35292b0fa0a/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 26565,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/64ed943a-be7f-4b96-8a8e-eb44d88a44ec/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/64ed943a-be7f-4b96-8a8e-eb44d88a44ec/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/64ed943a-be7f-4b96-8a8e-eb44d88a44ec/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 26558,
        "title": "Hotel The Alpina Gstaad in Switzerland - Accommodation",
        "url": "https://pkslist.azureedge.net/media/images/1/2a5a4e9e-23b8-40a2-9238-77e860d7541c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2a5a4e9e-23b8-40a2-9238-77e860d7541c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2a5a4e9e-23b8-40a2-9238-77e860d7541c/256x192.jpg",
        "viewOrder": 12
      },
      {
        "mediaId": 26562,
        "title": "Hotel The Alpina Gstaad in Switzerland - Six Senses Spa",
        "url": "https://pkslist.azureedge.net/media/images/1/66655502-b8a3-41fc-88d8-4492cb54d4d1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/66655502-b8a3-41fc-88d8-4492cb54d4d1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/66655502-b8a3-41fc-88d8-4492cb54d4d1/256x192.jpg",
        "viewOrder": 13
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1943,
      "latitude": 46.4758876,
      "longitude": 7.288395700000024,
      "parentGeographicAreaId": 0,
      "title": "Alpinastrasse 23, 3780 Gstaad, Switzerland",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 66,
        "tagName": "Skiing",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 85,
        "tagName": "Pet Friendly",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 101,
        "tagName": "Cinema",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 155,
        "tagName": "Art",
        "tagType": 2
      },
      {
        "tagId": 156,
        "tagName": "Fireplace",
        "tagType": 2
      },
      {
        "tagId": 191,
        "tagName": "Cigar Bar",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 301,
        "tagName": "Wine Bar",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 428,
        "venueCategoryId": 17,
        "venueId": 2137,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2136,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Le Grand Bellevue",
    "description": "Located in the center of Gstaad, the luxurious 5-star Hotel Le Grand Bellevue houses the elegant two Michelin-star restaurant LEONARD’s. \n\nStylish, secluded hideaways and turreted tower rooms. Cosy chalet-style suites with rustic wooden beams. Lavish split-level spaces with floor-to-ceiling windows, where you can gaze out over Gstaad and the staggering beauty of the Alpine region to the sweeping majesty of the mountains beyond. The light-filled luxury rooms and suites are yours to enjoy—places where you can be entirely at home. Slip into your sumptuously soft bathrobe and slippers and pour yourself a drink from your complimentary minibar. You have arrived.",
    "brand": null,
    "blockQuote": "A visit to the 3000 sqm Le Grand Spa is a must to enjoy the thermal oasis, herbal sauna, Himalayan salt room, ice grotto or stargaze in the outdoor relaxation pool.",
    "emailAddress": "info@bellevue-gstaad.ch",
    "website": "bellevue-gstaad.ch/",
    "bookingURL": "http://www.booking.com/Share-bQK4FE0?aid=925669",
    "redirectNotes": null,
    "phoneNumber": "+41 33 748 00 00",
    "addressOne": "17",
    "addressTwo": "Untergstaadstrasse",
    "city": "Gstaad",
    "county": "",
    "region": "",
    "country": "Switzerland",
    "rooms": 57,
    "dollar": 1,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 26523,
        "title": "Le Grand Bellevue",
        "url": "https://pkslist.azureedge.net/media/images/1/3452d0ee-1474-4610-98b9-232cda822f86/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3452d0ee-1474-4610-98b9-232cda822f86/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3452d0ee-1474-4610-98b9-232cda822f86/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 26536,
        "title": "Hotel Le Grand Bellevue - Gstaad luxury boutique hotel | The Style ...",
        "url": "https://pkslist.azureedge.net/media/images/1/2fb23f12-e294-4644-9418-67cdb1136284/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2fb23f12-e294-4644-9418-67cdb1136284/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2fb23f12-e294-4644-9418-67cdb1136284/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 26527,
        "title": "The Bar, Le Grand Bellevue",
        "url": "https://pkslist.azureedge.net/media/images/1/2a4acdd3-a74a-4bc9-8d1e-291344dcd87c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2a4acdd3-a74a-4bc9-8d1e-291344dcd87c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2a4acdd3-a74a-4bc9-8d1e-291344dcd87c/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 26525,
        "title": "Le Grand Bellevue, Gstaad: A new kind of luxury | The Independent",
        "url": "https://pkslist.azureedge.net/media/images/1/b08b3d7a-86c0-4c30-96a7-744dc582be46/1200x900.jpg?w968h681",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b08b3d7a-86c0-4c30-96a7-744dc582be46/800x600.jpg?w968h681",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b08b3d7a-86c0-4c30-96a7-744dc582be46/256x192.jpg?w968h681",
        "viewOrder": 3
      },
      {
        "mediaId": 26524,
        "title": "Le Grand Spa at Le Grand Bellevue, Gstaad - Haute Grandeur",
        "url": "https://pkslist.azureedge.net/media/images/1/e2f7258e-ef67-487d-b022-c576b21b6fbf/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e2f7258e-ef67-487d-b022-c576b21b6fbf/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e2f7258e-ef67-487d-b022-c576b21b6fbf/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 26534,
        "title": "Home – Le Grand Bellevue Gstaad",
        "url": "https://pkslist.azureedge.net/media/images/1/17239cdb-1f4e-43d6-93c6-07b983c0c1dc/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/17239cdb-1f4e-43d6-93c6-07b983c0c1dc/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/17239cdb-1f4e-43d6-93c6-07b983c0c1dc/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 26522,
        "title": "Hotel Le Grand Bellevue, Gstaad, Switzerland - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/2f0346be-2063-4627-aff6-458133aae3d4/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2f0346be-2063-4627-aff6-458133aae3d4/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2f0346be-2063-4627-aff6-458133aae3d4/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 26529,
        "title": "Suite Panorama | Le Grand Bellevue Gstaad",
        "url": "https://pkslist.azureedge.net/media/images/1/446a96f0-e884-4940-aad3-4bcc3f61f8be/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/446a96f0-e884-4940-aad3-4bcc3f61f8be/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/446a96f0-e884-4940-aad3-4bcc3f61f8be/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 26530,
        "title": "Hotel Le Grand Bellevue - Gstaad luxury boutique hotel | The Style ...",
        "url": "https://pkslist.azureedge.net/media/images/1/4f764c3d-e74d-4150-b6ea-c44692685cc6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4f764c3d-e74d-4150-b6ea-c44692685cc6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4f764c3d-e74d-4150-b6ea-c44692685cc6/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 26538,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b5108ad0-32f6-48d2-bb4b-a361985a385e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b5108ad0-32f6-48d2-bb4b-a361985a385e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b5108ad0-32f6-48d2-bb4b-a361985a385e/256x192.jpg",
        "viewOrder": 13
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1942,
      "latitude": 46.47792409999999,
      "longitude": 7.283451099999979,
      "parentGeographicAreaId": 0,
      "title": "Untergstaadstrasse 17, 3780 Gstaad, Switzerland",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 40,
        "tagName": "Boutique Bolthole ",
        "tagType": 1
      },
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 66,
        "tagName": "Skiing",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 101,
        "tagName": "Cinema",
        "tagType": 2
      },
      {
        "tagId": 105,
        "tagName": "Afternoon Tea",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 159,
        "tagName": "Mountain Biking",
        "tagType": 2
      },
      {
        "tagId": 160,
        "tagName": "Turkish Bath",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 219,
        "tagName": "Wine Cellar",
        "tagType": 2
      },
      {
        "tagId": 222,
        "tagName": "Michelin Star dining",
        "tagType": 2
      },
      {
        "tagId": 265,
        "tagName": "Hot Air Balloon",
        "tagType": 2
      },
      {
        "tagId": 276,
        "tagName": "Gym",
        "tagType": 2
      },
      {
        "tagId": 280,
        "tagName": "Piano",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 427,
        "venueCategoryId": 17,
        "venueId": 2136,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2105,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Hotel Le Portetta",
    "description": "In the best position at the foot of the French Alps, this Courchevel Moriond hotel is totally re-built and upgraded with ski in/out access and is still as relaxed and family friendly as ever.\n\nEnjoy the best sun terrace, lounge, bar and restaurant in the French Alps resort, Courchevel. Guests can also experience spacious accommodation and sleep in the best rooms and hotel suites.",
    "brand": "",
    "blockQuote": "For the best views in Courchevel - looking out onto Belvedere, book the Piste Rooms.",
    "emailAddress": "info@portetta.com",
    "website": "portetta.com/en/",
    "bookingURL": "https://www.portetta.com/en/book/book-stay/guests/",
    "redirectNotes": "Le Portetta's reservations system",
    "phoneNumber": "+33 4 79 08 01 47",
    "addressOne": "252",
    "addressTwo": "Rue du Marquis",
    "city": "Courchevel",
    "county": "",
    "region": "",
    "country": "France",
    "rooms": 44,
    "dollar": 2,
    "updated": "2023-12-26T07:16:51.22",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 26066,
        "title": "Hotel Le Portetta - Courchevel 1650 luxury ski hotel - France ...",
        "url": "https://pkslist.azureedge.net/media/images/1/bd0aa01a-7842-449b-82e5-c661ba3ff3d0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bd0aa01a-7842-449b-82e5-c661ba3ff3d0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bd0aa01a-7842-449b-82e5-c661ba3ff3d0/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 26064,
        "title": "Hotel Le Portetta - Courchevel 1650 luxury ski hotel - France ...",
        "url": "https://pkslist.azureedge.net/media/images/1/10e6afd8-0296-43d1-a723-1eafbb7e86fd/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/10e6afd8-0296-43d1-a723-1eafbb7e86fd/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/10e6afd8-0296-43d1-a723-1eafbb7e86fd/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 26063,
        "title": "Hotel Le Portetta, Courchevel 1650 • Alpine Guru",
        "url": "https://pkslist.azureedge.net/media/images/1/db2f8078-a14e-45a7-92f6-31aa230f5ec7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/db2f8078-a14e-45a7-92f6-31aa230f5ec7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/db2f8078-a14e-45a7-92f6-31aa230f5ec7/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 26068,
        "title": "Hotel Le Portetta, Courchevel 1650 • Alpine Guru",
        "url": "https://pkslist.azureedge.net/media/images/1/e1b07139-f48c-4502-ac4e-60460066c4f9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e1b07139-f48c-4502-ac4e-60460066c4f9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e1b07139-f48c-4502-ac4e-60460066c4f9/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 26065,
        "title": "Hotel Le Portetta, Courchevel 1650 • Alpine Guru",
        "url": "https://pkslist.azureedge.net/media/images/1/93ad29da-adf3-4f17-b751-0206ac88036d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/93ad29da-adf3-4f17-b751-0206ac88036d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/93ad29da-adf3-4f17-b751-0206ac88036d/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 26067,
        "title": "Hotel Portetta Courchevel 1650 | Luxury Ski Hotel | Kaluma Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/c039a472-9383-4995-85e7-45fd48a9a690/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c039a472-9383-4995-85e7-45fd48a9a690/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c039a472-9383-4995-85e7-45fd48a9a690/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 26070,
        "title": "Hotel Le Portetta in Courchevel 1650, France - White Blancmange",
        "url": "https://pkslist.azureedge.net/media/images/1/ed6f59eb-6bc1-4adf-8be8-e2f614151f16/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ed6f59eb-6bc1-4adf-8be8-e2f614151f16/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ed6f59eb-6bc1-4adf-8be8-e2f614151f16/256x192.jpg",
        "viewOrder": 7
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1914,
      "latitude": 45.41745160000001,
      "longitude": 6.6540773,
      "parentGeographicAreaId": 0,
      "title": "COURCHEVEL MORIOND, 252 Rue du Marquis, 73120 Courchevel, France",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 47,
        "tagName": "Intimate Luxury ",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 61,
        "tagName": "Mountain",
        "tagType": 2
      },
      {
        "tagId": 66,
        "tagName": "Skiing",
        "tagType": 2
      },
      {
        "tagId": 161,
        "tagName": "Bowling Alley",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 212,
        "tagName": "Hammam",
        "tagType": 2
      },
      {
        "tagId": 276,
        "tagName": "Gym",
        "tagType": 2
      },
      {
        "tagId": 281,
        "tagName": "Ice Skating",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 326,
        "tagName": "Sledding",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 421,
        "venueCategoryId": 17,
        "venueId": 2105,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2100,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Altapura",
    "description": "Amateur of a certain art of living, sensitive to beautiful things, you will be charmed by Altapura, 5-star hotel. Generous volumes and elegant reminiscent of the panache of the grand palaces, a chic Nordic atmosphere, work around the nobility of wood and stone, Altapura displays its design style, playing contrasts between cozy atmosphere and festive atmosphere and cosmopolitan. From the sweet serenity of your room or the spa to the warm exchanges in restaurants, lounges or on the south-facing terrace, you live fully every moment.",
    "brand": null,
    "blockQuote": "Book a mountain facing room and wake up to breathtaking views.",
    "emailAddress": "contact@altapura.fr",
    "website": "altapura.fr/#!hotel-val-thorens/sejour-montagne",
    "bookingURL": "http://www.booking.com/Share-r0aYKH?aid=925669",
    "redirectNotes": null,
    "phoneNumber": "+33 4 80 36 80 36",
    "addressOne": "",
    "addressTwo": "",
    "city": "Val Thorens",
    "county": "",
    "region": "",
    "country": "France",
    "rooms": 88,
    "dollar": 2,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 26003,
        "title": "Altapura | Hôtel 5 étoiles à Val Thorens | Les 3 Vallées",
        "url": "https://pkslist.azureedge.net/media/images/1/6067236d-0825-4991-9610-5498424f399f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6067236d-0825-4991-9610-5498424f399f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6067236d-0825-4991-9610-5498424f399f/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 26008,
        "title": "Hotel Altapura | Reynaers Inc",
        "url": "https://pkslist.azureedge.net/media/images/1/9af1a745-4d6a-43db-b23d-101bcfedbebe/1200x900.jpg?itok=Fcf2Nams",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9af1a745-4d6a-43db-b23d-101bcfedbebe/800x600.jpg?itok=Fcf2Nams",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9af1a745-4d6a-43db-b23d-101bcfedbebe/256x192.jpg?itok=Fcf2Nams",
        "viewOrder": 1
      },
      {
        "mediaId": 26002,
        "title": "Altapura | Hôtel 5 étoiles à Val Thorens | Les 3 Vallées",
        "url": "https://pkslist.azureedge.net/media/images/1/4661ffb1-2e5f-4aee-9bcf-d433480889a8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4661ffb1-2e5f-4aee-9bcf-d433480889a8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4661ffb1-2e5f-4aee-9bcf-d433480889a8/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 26005,
        "title": "Altapura | Hôtel 5 étoiles à Val Thorens | Les 3 Vallées",
        "url": "https://pkslist.azureedge.net/media/images/1/efea8ec8-875d-4e23-a051-642a09ab9289/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/efea8ec8-875d-4e23-a051-642a09ab9289/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/efea8ec8-875d-4e23-a051-642a09ab9289/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 26007,
        "title": "Altapura | Hôtel 5 étoiles à Val Thorens | Les 3 Vallées",
        "url": "https://pkslist.azureedge.net/media/images/1/1c6b9857-03b5-4d93-bb4c-427a69d60683/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1c6b9857-03b5-4d93-bb4c-427a69d60683/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1c6b9857-03b5-4d93-bb4c-427a69d60683/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 26013,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e32b0210-407b-4eb3-bb67-fe8985153478/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e32b0210-407b-4eb3-bb67-fe8985153478/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e32b0210-407b-4eb3-bb67-fe8985153478/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 26004,
        "title": "Altapura | Hôtel 5 étoiles à Val Thorens | Les 3 Vallées",
        "url": "https://pkslist.azureedge.net/media/images/1/222f31fa-8fda-44ca-ad6b-9839458372c7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/222f31fa-8fda-44ca-ad6b-9839458372c7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/222f31fa-8fda-44ca-ad6b-9839458372c7/256x192.jpg",
        "viewOrder": 6
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1909,
      "latitude": 45.35740859813945,
      "longitude": 6.51229890705406,
      "parentGeographicAreaId": 0,
      "title": "Val Thorens, France",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 61,
        "tagName": "Mountain",
        "tagType": 2
      },
      {
        "tagId": 66,
        "tagName": "Skiing",
        "tagType": 2
      },
      {
        "tagId": 73,
        "tagName": "Outdoor Terraces",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 235,
        "tagName": "Hot Tub",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 420,
        "venueCategoryId": 17,
        "venueId": 2100,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2098,
    "venueCategoryId": 0,
    "status": 1,
    "title": "El Lodge",
    "description": "El Lodge is housed in a charming Alpine-style building, surrounded by majestic mountains and ski slopes in the heart of the Sierra Nevada Ski Resort, the most southerly Ski Resort in Europe. Its location gives guests direct access to the Maribel and Aguila slopes, so you can step off the terrace, click on your skis and hit the pistes.",
    "brand": null,
    "blockQuote": "Rooms with mountain view so you can look out to the pistes is top choice.",
    "emailAddress": "reservas@ellodge.com",
    "website": "ellodge.com/",
    "bookingURL": "http://www.booking.com/Share-Sj8wkMV?aid=925669",
    "redirectNotes": "",
    "phoneNumber": "+34 958 480 600",
    "addressOne": "8",
    "addressTwo": "Calle Maribel",
    "city": "Sierra Nevada",
    "county": "",
    "region": "Andalucia",
    "country": "Spain",
    "rooms": 20,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 25980,
        "title": "El Lodge, Ski & Spa, Sierra Nevada, Spain - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/c8318ea7-2811-45ae-89f1-1514e7717d16/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c8318ea7-2811-45ae-89f1-1514e7717d16/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c8318ea7-2811-45ae-89f1-1514e7717d16/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 25984,
        "title": "El Lodge, Ski & Spa, Sierra Nevada, Spain - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/8a052aa7-fc5c-4a1c-aaaf-b3cadb8abd75/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8a052aa7-fc5c-4a1c-aaaf-b3cadb8abd75/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8a052aa7-fc5c-4a1c-aaaf-b3cadb8abd75/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 25989,
        "title": "El Lodge, our cosy chalet in the Sierra Nevada mountains ...",
        "url": "https://pkslist.azureedge.net/media/images/1/57b60fc1-3c0c-4d34-9eba-69c4219dfbdb/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/57b60fc1-3c0c-4d34-9eba-69c4219dfbdb/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/57b60fc1-3c0c-4d34-9eba-69c4219dfbdb/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 25991,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/7c14606d-d85d-424f-9ab5-67a26276f21a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7c14606d-d85d-424f-9ab5-67a26276f21a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7c14606d-d85d-424f-9ab5-67a26276f21a/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 25988,
        "title": "EL LODGE SKI & SPA, THE MOST EXCLUSIVE SKI RESORT IN EUROPE ...",
        "url": "https://pkslist.azureedge.net/media/images/1/3b693d80-1268-4b2d-b54c-606e73e4fb6e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3b693d80-1268-4b2d-b54c-606e73e4fb6e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3b693d80-1268-4b2d-b54c-606e73e4fb6e/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 25990,
        "title": "El Lodge Ski and Spa, Luxury Hotel in Sierra Nevada, Spain | SLH",
        "url": "https://pkslist.azureedge.net/media/images/1/4866a760-4bcb-4cc6-9e76-73dea8df8813/1200x900.jpg?format=jpg&width=450&quality=75",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4866a760-4bcb-4cc6-9e76-73dea8df8813/800x600.jpg?format=jpg&width=450&quality=75",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4866a760-4bcb-4cc6-9e76-73dea8df8813/256x192.jpg?format=jpg&width=450&quality=75",
        "viewOrder": 5
      },
      {
        "mediaId": 25992,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/925bcaa7-c058-48c6-bcae-20216e5f7f99/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/925bcaa7-c058-48c6-bcae-20216e5f7f99/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/925bcaa7-c058-48c6-bcae-20216e5f7f99/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 25985,
        "title": "Hotel El Lodge, Sierra Nevada - Reserving.com",
        "url": "https://pkslist.azureedge.net/media/images/1/e20d1cbe-8b9e-446f-bafd-65e914d4c231/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e20d1cbe-8b9e-446f-bafd-65e914d4c231/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e20d1cbe-8b9e-446f-bafd-65e914d4c231/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 25981,
        "title": "Hotel El Lodge, Sierra Nevada - Reserving.com",
        "url": "https://pkslist.azureedge.net/media/images/1/d063d610-1557-4ba2-a015-58ade074aed3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d063d610-1557-4ba2-a015-58ade074aed3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d063d610-1557-4ba2-a015-58ade074aed3/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1907,
      "latitude": 37.09204020000001,
      "longitude": -3.3959670000000415,
      "parentGeographicAreaId": 0,
      "title": "Calle Maribel, 8, 18196 Sierra Nevada, Granada, Spain",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 40,
        "tagName": "Boutique Bolthole ",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 61,
        "tagName": "Mountain",
        "tagType": 2
      },
      {
        "tagId": 66,
        "tagName": "Skiing",
        "tagType": 2
      },
      {
        "tagId": 68,
        "tagName": "Business",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 83,
        "tagName": "Kid's Club",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 235,
        "tagName": "Hot Tub",
        "tagType": 2
      },
      {
        "tagId": 276,
        "tagName": "Gym",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 325,
        "tagName": "Shoe Shine",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 418,
        "venueCategoryId": 17,
        "venueId": 2098,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2033,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Four Seasons Resort and Residences Vail",
    "description": "When the mountains are calling, Vail is like nothing on earth. Our Resort is situated at the front door of this history-rich village, steeped in pioneering spirit and untamed refinement, with legendary Vail Mountain just steps away. Come and celebrate the great outdoors with us – best experienced through our team’s passion and excitement for unparalleled adventure – then recharge in our award-winning Spa and cap off your days with an al-fresco swim under the stars.",
    "brand": "Four Seasons",
    "blockQuote": "Book a room/suite looking out to Vail mountain so you can wake up to a majestic view.",
    "emailAddress": "res.vail@fourseasons.com",
    "website": "fourseasons.com/vail/",
    "bookingURL": "https://reservations.fourseasons.com/?hotelCode=EGE729&checkIn=2021-04-02&checkOut=2021-04-03&adults=2&children=0&iata=96027890",
    "redirectNotes": "Four Seasons Resort and Residences Vail's reservations system",
    "phoneNumber": "+1 970 477 8600",
    "addressOne": "One",
    "addressTwo": "Vail Road",
    "city": "Vail",
    "county": "",
    "region": "Colorado",
    "country": "United States",
    "rooms": 121,
    "dollar": 2,
    "updated": "2021-04-02T13:08:22.07",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 46868,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/85c0f644-53ac-42e9-b491-77b5c9e19814/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/85c0f644-53ac-42e9-b491-77b5c9e19814/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/85c0f644-53ac-42e9-b491-77b5c9e19814/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 46878,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f6ffe779-e706-477e-8b2c-cac08b5c22ca/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f6ffe779-e706-477e-8b2c-cac08b5c22ca/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f6ffe779-e706-477e-8b2c-cac08b5c22ca/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 46870,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d6b2efab-420c-4e8c-9fb0-caaefdb5b3f6/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d6b2efab-420c-4e8c-9fb0-caaefdb5b3f6/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d6b2efab-420c-4e8c-9fb0-caaefdb5b3f6/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 46873,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e8fdc777-de3c-474d-b12e-0f3118cade8d/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e8fdc777-de3c-474d-b12e-0f3118cade8d/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e8fdc777-de3c-474d-b12e-0f3118cade8d/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 46872,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/030aaeab-aa65-4026-9b7a-916254e3a572/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/030aaeab-aa65-4026-9b7a-916254e3a572/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/030aaeab-aa65-4026-9b7a-916254e3a572/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 46871,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/af4d5997-1785-4c62-bde2-6d31fa796993/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/af4d5997-1785-4c62-bde2-6d31fa796993/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/af4d5997-1785-4c62-bde2-6d31fa796993/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 25064,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d0a77d36-097a-4d29-92c6-e066877fd64d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d0a77d36-097a-4d29-92c6-e066877fd64d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d0a77d36-097a-4d29-92c6-e066877fd64d/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 46869,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/2afb45ed-f579-4146-950e-adaa3aae7244/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2afb45ed-f579-4146-950e-adaa3aae7244/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2afb45ed-f579-4146-950e-adaa3aae7244/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 46876,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b461db83-ba15-46bf-ab39-142d4c6dd570/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b461db83-ba15-46bf-ab39-142d4c6dd570/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b461db83-ba15-46bf-ab39-142d4c6dd570/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 46877,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/20a957cd-1d19-4f88-948f-7c117967f833/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/20a957cd-1d19-4f88-948f-7c117967f833/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/20a957cd-1d19-4f88-948f-7c117967f833/256x192.jpeg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1843,
      "latitude": 39.6430777,
      "longitude": -106.3790376,
      "parentGeographicAreaId": 0,
      "title": "One, Vail Rd, Vail, CO 81657, USA",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 34,
        "tagName": "Cosy",
        "tagType": 2
      },
      {
        "tagId": 61,
        "tagName": "Mountain",
        "tagType": 2
      },
      {
        "tagId": 66,
        "tagName": "Skiing",
        "tagType": 2
      },
      {
        "tagId": 68,
        "tagName": "Business",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 156,
        "tagName": "Fireplace",
        "tagType": 2
      },
      {
        "tagId": 193,
        "tagName": "Outdoor Dining",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 578,
        "venueCategoryId": 17,
        "venueId": 2033,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1970,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Chalet Edelweiss - Courchevel Prestige Chalets",
    "description": "Chalet Edelweiss is the largest and most luxurious rental chalet in the Alps, completely ski in/ski out, located right on the Bellecote piste!  Completed in September 2012, Edelweiss is found next door to Les Gentianes and benefits from same location - ski-in ski-out, an intensely private residential area, yet less than 5 minutes' walk to the village of Courchevel.\n\nChalet Edelweiss brings a new standard of luxury to Courchevel, the infusion of the Savoir touch completes the sensational elegance and the ambiance of the chalet, 3000m² over seven floors with a giant feature staircase, with eight bedrooms suites sleeping 16, two being master suites of 100m², a 50m² ski room, a state of the art cinema room, library and games room and a nightclub with bar and DJ booth, pool table and dance floor accommodating 100 people.",
    "brand": null,
    "blockQuote": "Get on the slopes and meet some people so you can invite them to your private nightclub!",
    "emailAddress": "info@courchevelprestigechalets.com",
    "website": "courchevelprestigechalets.com/chalet-edelweiss/",
    "bookingURL": "http://courchevelprestigechalets.com/contact-us/",
    "redirectNotes": null,
    "phoneNumber": "+33 6 62 99 80 29",
    "addressOne": "3",
    "addressTwo": "Rue de Bellecôte",
    "city": "Courchevel",
    "county": "",
    "region": "",
    "country": "France",
    "rooms": 8,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 24159,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/4a679b06-bda6-4337-9e73-c01984b969e8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4a679b06-bda6-4337-9e73-c01984b969e8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4a679b06-bda6-4337-9e73-c01984b969e8/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 24160,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/103b41f2-6175-44ef-b53f-2cd6422b55cd/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/103b41f2-6175-44ef-b53f-2cd6422b55cd/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/103b41f2-6175-44ef-b53f-2cd6422b55cd/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 24164,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ac694349-d5e9-41c4-9498-b51399392bd7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ac694349-d5e9-41c4-9498-b51399392bd7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ac694349-d5e9-41c4-9498-b51399392bd7/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 24162,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a2164d73-2730-4113-a203-2af0b478bde5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a2164d73-2730-4113-a203-2af0b478bde5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a2164d73-2730-4113-a203-2af0b478bde5/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 24165,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/6bed7ac8-23e8-4c3f-b8ba-109942820ed0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6bed7ac8-23e8-4c3f-b8ba-109942820ed0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6bed7ac8-23e8-4c3f-b8ba-109942820ed0/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 24166,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b5dbbb3d-2181-419b-b6a8-0f0943235050/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b5dbbb3d-2181-419b-b6a8-0f0943235050/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b5dbbb3d-2181-419b-b6a8-0f0943235050/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 24168,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ef8e1715-eea0-45f4-9731-fa5e0a3f820d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ef8e1715-eea0-45f4-9731-fa5e0a3f820d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ef8e1715-eea0-45f4-9731-fa5e0a3f820d/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 24170,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a9e769a8-c9d5-4cc2-8d91-25ecc581ec75/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a9e769a8-c9d5-4cc2-8d91-25ecc581ec75/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a9e769a8-c9d5-4cc2-8d91-25ecc581ec75/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 24169,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/dde12026-e086-49b7-a5b8-a6b70a99a466/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/dde12026-e086-49b7-a5b8-a6b70a99a466/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/dde12026-e086-49b7-a5b8-a6b70a99a466/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 24171,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/50238115-8cde-47e0-b6b9-cf3c29e68695/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/50238115-8cde-47e0-b6b9-cf3c29e68695/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/50238115-8cde-47e0-b6b9-cf3c29e68695/256x192.jpg",
        "viewOrder": 11
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1780,
      "latitude": 45.4127315,
      "longitude": 6.636577399999965,
      "parentGeographicAreaId": 0,
      "title": "3 Rue de Bellecôte, 73120 Saint-Bon-Tarentaise, France",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 189,
        "tagName": "Exclusively Yours",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 66,
        "tagName": "Skiing",
        "tagType": 2
      },
      {
        "tagId": 70,
        "tagName": "Families",
        "tagType": 2
      },
      {
        "tagId": 71,
        "tagName": "Group of Friends",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 84,
        "tagName": "Nightclub",
        "tagType": 2
      },
      {
        "tagId": 101,
        "tagName": "Cinema",
        "tagType": 2
      },
      {
        "tagId": 131,
        "tagName": "Library",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 303,
        "tagName": "Games Room",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 400,
        "venueCategoryId": 17,
        "venueId": 1970,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1585,
    "venueCategoryId": 17,
    "status": 1,
    "title": "Cheval Blanc Courchevel",
    "description": "Cheval Blanc Courchevel is a contemporary ski-in ski-out Maison, set in the prestigious Jardin Alpin of Courchevel 1850, with direct access to the world's largest ski domain, Les Trois Vallees. With just 36 elegant rooms and suites, Cheval Blanc Courchevel is an intimate and inviting haven where impeccable, personalised service creates an easy going and friendly atmosphere. It is home to the Cheval Blanc Spa, with signature treatments by Guerlain, Le 1947 - Courchevel's only three Michelin-starred restaurant by Yannick Alleno, Le Triptyque for all-day dining, Le Bar, a cigar lounge and Le Carrousel and Paddock for younger guests.",
    "brand": "Cheval Blanc",
    "blockQuote": "Once the founder of LVMH's private home turned hotel, this is where the Cheval Blanc brand all started.",
    "emailAddress": "info.courchevel@chevalblanc.com",
    "website": "chevalblanc.com/courchevel/en",
    "bookingURL": "http://www.booking.com/Share-o9Jq0q?aid=925669",
    "redirectNotes": "",
    "phoneNumber": "+33 4 79 00 50 50",
    "addressOne": "",
    "addressTwo": "",
    "city": "Courchevel",
    "county": "",
    "region": "",
    "country": "France",
    "rooms": 36,
    "dollar": 3,
    "updated": "2020-05-14T14:44:35.427",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 17657,
        "title": "Courchevel Cheval Blanc | EVNEO.INFO (10-Jan-18 15:58:26)",
        "url": "https://pkslist.azureedge.net/media/images/1/bd1fb01c-b9bd-43bd-b9c5-f93e8c60f726/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bd1fb01c-b9bd-43bd-b9c5-f93e8c60f726/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bd1fb01c-b9bd-43bd-b9c5-f93e8c60f726/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 17647,
        "title": "Hotel CHEVAL BLANC COURCHEVEL - Courchevel hotel, accommodation in ...",
        "url": "https://pkslist.azureedge.net/media/images/1/d47c9164-3c5a-47d9-a611-56a5a2f6d8f3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d47c9164-3c5a-47d9-a611-56a5a2f6d8f3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d47c9164-3c5a-47d9-a611-56a5a2f6d8f3/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 17650,
        "title": "Blanc Courchevel 1850",
        "url": "https://pkslist.azureedge.net/media/images/1/71024b81-cb1a-4d58-9256-72317e612b44/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/71024b81-cb1a-4d58-9256-72317e612b44/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/71024b81-cb1a-4d58-9256-72317e612b44/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 17655,
        "title": "Cheval Blanc Courchevel***** Palace | Site officiel du tourisme en ...",
        "url": "https://pkslist.azureedge.net/media/images/1/6419ea04-1fd4-4d65-80a6-5fc95247bd6c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6419ea04-1fd4-4d65-80a6-5fc95247bd6c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6419ea04-1fd4-4d65-80a6-5fc95247bd6c/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 17646,
        "title": "Blanc Courchevel 1850",
        "url": "https://pkslist.azureedge.net/media/images/1/c4429cac-5d48-46dd-ac7e-f6301ba0dfcf/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c4429cac-5d48-46dd-ac7e-f6301ba0dfcf/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c4429cac-5d48-46dd-ac7e-f6301ba0dfcf/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 17651,
        "title": "Cheval Blanc hotel review, French Alps | Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/71c1b154-67fa-487a-b8e4-a55a53d12b3e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/71c1b154-67fa-487a-b8e4-a55a53d12b3e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/71c1b154-67fa-487a-b8e4-a55a53d12b3e/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 17652,
        "title": "Cheval Blanc hotel review, French Alps | Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/f0454224-352e-48ec-a138-10e270480d51/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f0454224-352e-48ec-a138-10e270480d51/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f0454224-352e-48ec-a138-10e270480d51/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 17653,
        "title": "Rooms | Cheval Blanc Courchevel",
        "url": "https://pkslist.azureedge.net/media/images/1/6eeb765c-89d7-484b-a008-2e8319cd42e6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6eeb765c-89d7-484b-a008-2e8319cd42e6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6eeb765c-89d7-484b-a008-2e8319cd42e6/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 17654,
        "title": "Cheval Blanc Spa | Cheval Blanc Courchevel",
        "url": "https://pkslist.azureedge.net/media/images/1/fc739d6a-2703-4357-9e61-1d76e35439f5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fc739d6a-2703-4357-9e61-1d76e35439f5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fc739d6a-2703-4357-9e61-1d76e35439f5/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1399,
      "latitude": 45.40938,
      "longitude": 6.633653999999979,
      "parentGeographicAreaId": 0,
      "title": "Rue du Jardin Alpin, 73120 Saint-Bon-Tarentaise, France",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 47,
        "tagName": "Intimate Luxury ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 66,
        "tagName": "Skiing",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 83,
        "tagName": "Kid's Club",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 127,
        "tagName": "Hair Salon",
        "tagType": 2
      },
      {
        "tagId": 153,
        "tagName": "Cigar Lounge",
        "tagType": 2
      },
      {
        "tagId": 222,
        "tagName": "Michelin Star dining",
        "tagType": 2
      },
      {
        "tagId": 236,
        "tagName": "Dog Sledding",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 288,
        "venueCategoryId": 17,
        "venueId": 1585,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1512,
    "venueCategoryId": 17,
    "status": 1,
    "title": "Les Airelles Courchevel",
    "description": "There is a perfect spot just outside of Courchevel – where the magnificent ski slopes meet the Jardin Alpin – where Airelles is situated. From the outside, this sumptuous hotel looks like an austro-hungarian castle in a fairy tale.\n\nInside, the magic remains unbroken, thanks to the elegant materials, otherworldly murals and stained-glass windows that fill the property. It is a wonderland that comes alive each winter. Airelles believes that modern luxury means giving guests a personalised experience that is discreet, subtle and all-encompassing. And whether your mouth is watering for a specific dish, or you want to explore a specific slope, there is a team of 180 employees on hand day and night to anticipate and meet every need.",
    "brand": "",
    "blockQuote": "The Spa La Mer is a gorgeous mountain retreat not to be missed.",
    "emailAddress": "reservation.lesairelles@airelles.com",
    "website": "airelles.com/en/destination/courchevel-hotel",
    "bookingURL": "https://be.synxis.com/?_ga=2.204824720.1200757409.1624885184-514453306.1624885184&adult=2&agencyid=96027890&arrive=2021-12-10&chain=24447&child=0&currency=EUR&depart=2021-12-11&filter=&hotel=6668&level=hotel&locale=en-US&rooms=1&src=30",
    "redirectNotes": "Les Airelles' reservations system",
    "phoneNumber": "+33 4 79 00 38 38",
    "addressOne": "",
    "addressTwo": "",
    "city": "Courchevel",
    "county": "",
    "region": "",
    "country": "France",
    "rooms": 51,
    "dollar": 3,
    "updated": "2022-03-24T14:19:17.26",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 16483,
        "title": "Courchevel, France | Alpine World",
        "url": "https://pkslist.azureedge.net/media/images/1/6d2878d3-7dad-4063-a416-9580111a3a37/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6d2878d3-7dad-4063-a416-9580111a3a37/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6d2878d3-7dad-4063-a416-9580111a3a37/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 48268,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/3bd824bc-df03-4dfe-a4af-6215250a4566/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3bd824bc-df03-4dfe-a4af-6215250a4566/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3bd824bc-df03-4dfe-a4af-6215250a4566/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 48269,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/31564231-fa4c-447c-ae2b-637bc6cce6f7/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/31564231-fa4c-447c-ae2b-637bc6cce6f7/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/31564231-fa4c-447c-ae2b-637bc6cce6f7/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 48271,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d3586d16-e6f7-48d1-a91c-3610d5fe12d9/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d3586d16-e6f7-48d1-a91c-3610d5fe12d9/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d3586d16-e6f7-48d1-a91c-3610d5fe12d9/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 48267,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/72fdfe86-61ac-4b98-99fd-a7f2c77966e4/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/72fdfe86-61ac-4b98-99fd-a7f2c77966e4/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/72fdfe86-61ac-4b98-99fd-a7f2c77966e4/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 48270,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e939b05b-5e94-4b27-9f0b-afc1aa654fe9/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e939b05b-5e94-4b27-9f0b-afc1aa654fe9/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e939b05b-5e94-4b27-9f0b-afc1aa654fe9/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 16466,
        "title": "Les Airelles Courchevel - Luxury Resorts | Berkeley Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/80e85fc6-0938-4e2c-939e-5baf9f987eb2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/80e85fc6-0938-4e2c-939e-5baf9f987eb2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/80e85fc6-0938-4e2c-939e-5baf9f987eb2/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 48272,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b49fc981-b5da-4b4d-8778-ef2452ddb8ec/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b49fc981-b5da-4b4d-8778-ef2452ddb8ec/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b49fc981-b5da-4b4d-8778-ef2452ddb8ec/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 48274,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/186ab067-ad72-46b3-af3c-f58d1cbbbd8b/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/186ab067-ad72-46b3-af3c-f58d1cbbbd8b/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/186ab067-ad72-46b3-af3c-f58d1cbbbd8b/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 48273,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/6613ed6e-f4cb-45ac-827b-2f6ecf55dcb8/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6613ed6e-f4cb-45ac-827b-2f6ecf55dcb8/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6613ed6e-f4cb-45ac-827b-2f6ecf55dcb8/256x192.jpeg",
        "viewOrder": 9
      },
      {
        "mediaId": 16459,
        "title": "Hotel LES AIRELLES - Courchevel hotel, accommodation in Courchevel ...",
        "url": "https://pkslist.azureedge.net/media/images/1/aea89ce2-1a89-4b51-b234-a9888ca5490d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/aea89ce2-1a89-4b51-b234-a9888ca5490d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/aea89ce2-1a89-4b51-b234-a9888ca5490d/256x192.jpg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1327,
      "latitude": 45.4100464,
      "longitude": 6.6341973,
      "parentGeographicAreaId": 0,
      "title": "Rue du Jardin Alpin, 73120 Courchevel, France",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 66,
        "tagName": "Skiing",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 127,
        "tagName": "Hair Salon",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 236,
        "tagName": "Dog Sledding",
        "tagType": 2
      },
      {
        "tagId": 243,
        "tagName": "Outdoor Activities",
        "tagType": 2
      },
      {
        "tagId": 317,
        "tagName": "Gourmet",
        "tagType": 2
      },
      {
        "tagId": 444,
        "tagName": "Heli Skiing",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 281,
        "venueCategoryId": 17,
        "venueId": 1512,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1511,
    "venueCategoryId": 17,
    "status": 1,
    "title": "Hotel Annapurna",
    "description": "The luxury hotel Annapurna benefits from an exceptional location in Courchevel, facing south on the Pralong slope. Being the highest hotel in Courchevel gives magnificent views from the rooms and great natural light with their large panoramic windows. \n\nHotel Annapurna has a contemporary and cosy decor. Guestrooms are spacious and stylishly decorated, most with their own sunny balcony and individually-designed suites all have south-facing balconies providing the sun from dawn to dusk and amazing with great views.",
    "brand": null,
    "blockQuote": "Book a room with a fabulous sunny terrace overlooking the slopes.",
    "emailAddress": "info@annapurna-courchevel.com",
    "website": "annapurna-courchevel.com",
    "bookingURL": "http://www.annapurna-courchevel.com/en/rates-booking/rates-booking.htm",
    "redirectNotes": "",
    "phoneNumber": "+33 4 79 08 04 60",
    "addressOne": "",
    "addressTwo": "",
    "city": "Saint Bon Tarentaise",
    "county": "",
    "region": "",
    "country": "France",
    "rooms": 70,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 29581,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/5386b94f-4265-4a67-8f6a-b9fbc62ce223/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5386b94f-4265-4a67-8f6a-b9fbc62ce223/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5386b94f-4265-4a67-8f6a-b9fbc62ce223/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 29586,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/6ac92e42-042f-4f8b-a514-4f0adece98fb/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6ac92e42-042f-4f8b-a514-4f0adece98fb/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6ac92e42-042f-4f8b-a514-4f0adece98fb/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 29585,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/38b95788-8683-4b7b-85b0-d4899bc41800/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/38b95788-8683-4b7b-85b0-d4899bc41800/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/38b95788-8683-4b7b-85b0-d4899bc41800/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 29584,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/788fe3d2-582a-4c73-aa47-040d53937a39/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/788fe3d2-582a-4c73-aa47-040d53937a39/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/788fe3d2-582a-4c73-aa47-040d53937a39/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 29583,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/21a9d074-0be5-4c78-9e7a-42f8e985e85b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/21a9d074-0be5-4c78-9e7a-42f8e985e85b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/21a9d074-0be5-4c78-9e7a-42f8e985e85b/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 29589,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c522a2db-9ffe-4b59-9bd0-6f98cd9465ea/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c522a2db-9ffe-4b59-9bd0-6f98cd9465ea/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c522a2db-9ffe-4b59-9bd0-6f98cd9465ea/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 16457,
        "title": "HÔTEL ANNAPURNA, COURCHEVEL 1850 | 22 392 60 19 | Specjalista ...",
        "url": "https://pkslist.azureedge.net/media/images/1/ed224990-81fc-49d2-bcfe-ff00852f2518/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ed224990-81fc-49d2-bcfe-ff00852f2518/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ed224990-81fc-49d2-bcfe-ff00852f2518/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 16450,
        "title": "Hotel ANNAPURNA - Courchevel hotel, accommodation in Courchevel ...",
        "url": "https://pkslist.azureedge.net/media/images/1/dada1f1f-923b-4e25-b2b6-794cf8f1fec5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/dada1f1f-923b-4e25-b2b6-794cf8f1fec5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/dada1f1f-923b-4e25-b2b6-794cf8f1fec5/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 29587,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/4a2242d7-b776-4226-aa3f-caaabc8b8902/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4a2242d7-b776-4226-aa3f-caaabc8b8902/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4a2242d7-b776-4226-aa3f-caaabc8b8902/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 29588,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/78b8c47b-932e-43a3-bf95-1c7d35b08366/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/78b8c47b-932e-43a3-bf95-1c7d35b08366/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/78b8c47b-932e-43a3-bf95-1c7d35b08366/256x192.jpg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1326,
      "latitude": 45.4013256,
      "longitude": 6.636890900000026,
      "parentGeographicAreaId": 0,
      "title": "73120 Saint-Bon-Tarentaise, France",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 61,
        "tagName": "Mountain",
        "tagType": 2
      },
      {
        "tagId": 66,
        "tagName": "Skiing",
        "tagType": 2
      },
      {
        "tagId": 70,
        "tagName": "Families",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 127,
        "tagName": "Hair Salon",
        "tagType": 2
      },
      {
        "tagId": 167,
        "tagName": "Nanny/Babysitting Service",
        "tagType": 2
      },
      {
        "tagId": 193,
        "tagName": "Outdoor Dining",
        "tagType": 2
      },
      {
        "tagId": 229,
        "tagName": "Private Balconies",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 293,
        "tagName": "Meeting Space",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 280,
        "venueCategoryId": 17,
        "venueId": 1511,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1509,
    "venueCategoryId": 17,
    "status": 1,
    "title": "Hotel Saint Roch",
    "description": "Le Saint Roch elevates hospitality into an art form. The French art of living is taken to its extreme, successfully combining modernity and warmth, luxury and relaxation, in a superb setting with an Alpine atmosphere.\n\nThe hotel contains 14 suites, 5 guestrooms and 2 apartments. The decoration, which is different for every room, guarantees a cosy atmosphere. Vintage parquet flooring, velvet, leather and fur rub shoulders with more modern elements. The bathrooms are resplendent in crocodile skin, mother of pearl and metal and feature all the essential facilities for comfort and a little pampering: rainfall shower and hamam.\n\nAt the Saint Roch, the word service really comes into its own. Efficient, smiling and personalised, it guarantees that every hotel guest will feel at home.",
    "brand": "",
    "blockQuote": "A true Alpine ambiance in its most genuine and luxurious incarnation.",
    "emailAddress": "reservation@lesaintroch.com",
    "website": "lesaintroch.com",
    "bookingURL": "https://secure.reservit.com/fo/booking/2/360738/availability?nbroom=1&specialMode=default&redirectHOST=hotel.reservit.com&begindate=2023-12-26&partid=0&_gl=1*13lwu9q*_ga*R0ExLjIuMTE2NjYzMTA4NS4xNzAzNTc0MDYw*_ga_79JGJ7EPPX*R1MxLjIuMTcwMzU3NDA2MC4xLjEuMTcwMzU3NDE5MS4wLjAuMA..&tmonth=12&tyear=2023&hotelid=360738&numnight=1&m=booking&roomAge1=40,40&langcode=FR&enddate=2023-12-27&fyear=2023&custid=2&fmonth=12&fday=26&tday=27",
    "redirectNotes": "Le Saint Roch's reservations system",
    "phoneNumber": "+33 4 79 08 02 66",
    "addressOne": "",
    "addressTwo": "",
    "city": "Courchevel",
    "county": "",
    "region": "",
    "country": "France",
    "rooms": 26,
    "dollar": 3,
    "updated": "2023-12-26T07:04:13.463",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 16427,
        "title": "SPA LE SAINT ROCH Courchevel – Spa, relaxation and wellness Courchevel",
        "url": "https://pkslist.azureedge.net/media/images/1/9675f95f-90de-48a2-832d-34ae4dd6852b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9675f95f-90de-48a2-832d-34ae4dd6852b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9675f95f-90de-48a2-832d-34ae4dd6852b/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 16431,
        "title": "LE SAINT ROCH - Courchevel bar night club - Courchevel ski resort ...",
        "url": "https://pkslist.azureedge.net/media/images/1/ee012a58-0ed9-43db-89d8-aa5d48cd7675/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ee012a58-0ed9-43db-89d8-aa5d48cd7675/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ee012a58-0ed9-43db-89d8-aa5d48cd7675/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 16437,
        "title": null,
        "url": "https://pkslist.azureedge.net/media/images/1/0fb760c7-4c5f-4edd-a35f-dea965f0bd80/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0fb760c7-4c5f-4edd-a35f-dea965f0bd80/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0fb760c7-4c5f-4edd-a35f-dea965f0bd80/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 16428,
        "title": "Hotel le Saint Roch, Courchevel, France",
        "url": "https://pkslist.azureedge.net/media/images/1/f06c6a4f-6e38-402d-ab00-18d1698c47bb/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f06c6a4f-6e38-402d-ab00-18d1698c47bb/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f06c6a4f-6e38-402d-ab00-18d1698c47bb/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 16436,
        "title": "Le Saint-Roch – Courchevel | LSD MAGAZINE",
        "url": "https://pkslist.azureedge.net/media/images/1/799d2b95-7566-4e49-8723-c4c5bf01e137/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/799d2b95-7566-4e49-8723-c4c5bf01e137/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/799d2b95-7566-4e49-8723-c4c5bf01e137/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 16429,
        "title": "Saint Roch, Courchevel 1850 (5*) - Kaluma Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/423349a8-016f-4c5b-83d6-34fed172d5e6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/423349a8-016f-4c5b-83d6-34fed172d5e6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/423349a8-016f-4c5b-83d6-34fed172d5e6/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 16433,
        "title": null,
        "url": "https://pkslist.azureedge.net/media/images/1/e4c5e20c-b44c-48a7-bed8-e041b86d820a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e4c5e20c-b44c-48a7-bed8-e041b86d820a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e4c5e20c-b44c-48a7-bed8-e041b86d820a/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 16434,
        "title": null,
        "url": "https://pkslist.azureedge.net/media/images/1/416509a0-a161-4582-8b84-360aa7a98f18/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/416509a0-a161-4582-8b84-360aa7a98f18/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/416509a0-a161-4582-8b84-360aa7a98f18/256x192.png",
        "viewOrder": 7
      },
      {
        "mediaId": 16430,
        "title": "Hotel LE SAINT ROCH - Courchevel hotel, accommodation in ...",
        "url": "https://pkslist.azureedge.net/media/images/1/1c65aa2d-f973-482a-bf2b-c2abf767ce30/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1c65aa2d-f973-482a-bf2b-c2abf767ce30/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1c65aa2d-f973-482a-bf2b-c2abf767ce30/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 16426,
        "title": "CONTACT - hotel saint roch courchevel",
        "url": "https://pkslist.azureedge.net/media/images/1/8e65fd16-6ef9-4613-b667-29b24ad32fa8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8e65fd16-6ef9-4613-b667-29b24ad32fa8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8e65fd16-6ef9-4613-b667-29b24ad32fa8/256x192.jpg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2474,
      "latitude": 45.41381959999999,
      "longitude": 6.6355075,
      "parentGeographicAreaId": 0,
      "title": "Piste de bellecote, 73121 Courchevel, France",
      "updated": null,
      "created": "2020-05-13T17:42:00"
    },
    "personalities": [
      {
        "tagId": 40,
        "tagName": "Boutique Bolthole ",
        "tagType": 1
      },
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 34,
        "tagName": "Cosy",
        "tagType": 2
      },
      {
        "tagId": 61,
        "tagName": "Mountain",
        "tagType": 2
      },
      {
        "tagId": 66,
        "tagName": "Skiing",
        "tagType": 2
      },
      {
        "tagId": 69,
        "tagName": "Couples",
        "tagType": 2
      },
      {
        "tagId": 71,
        "tagName": "Group of Friends",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 156,
        "tagName": "Fireplace",
        "tagType": 2
      },
      {
        "tagId": 212,
        "tagName": "Hammam",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 279,
        "venueCategoryId": 17,
        "venueId": 1509,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1507,
    "venueCategoryId": 17,
    "status": 1,
    "title": "White 1921 Courchevel",
    "description": "Ideally located on the Croisette, White 1921 Courchevel is the perfect place to indulge in a day out on the slopes and enjoy the festive atmosphere of Courchevel 1850 and its numerous boutiques, bars and restaurants.\n\nDesigned by renowned architect Jean-Michel Wilmotte, White 1921 Courchevel offers a cosy yet contemporary stay. Each room with a mountain flair, some feature balconies or open terraces with beautiful views over the slopes or the legendary village, but every one offers a spacious and homely world to enjoy every aspect of a mountain getaway.",
    "brand": null,
    "blockQuote": "A cosy retreat for a stay with friends or family.",
    "emailAddress": "info.courchevel@white1921.com",
    "website": "white1921.com/courchevel/en",
    "bookingURL": "https://gc.synxis.com/rez.aspx?Hotel=6233&Chain=23061&locale=en-GB&adult=2&iata=96027890",
    "redirectNotes": "White 1921 Courchevel's reservations system",
    "phoneNumber": "+33 4 79 00 27 00",
    "addressOne": "",
    "addressTwo": "",
    "city": "Courchevel",
    "county": "",
    "region": "",
    "country": "France",
    "rooms": 26,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 16404,
        "title": "Hotel WHITE 1921 COURCHEVEL - Courchevel hotel, accommodation in ...",
        "url": "https://pkslist.azureedge.net/media/images/1/5e3d457f-1847-44c0-a609-57d1cebccd5d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5e3d457f-1847-44c0-a609-57d1cebccd5d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5e3d457f-1847-44c0-a609-57d1cebccd5d/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 29596,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d4738624-956c-48e6-950a-d8ee2d027188/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d4738624-956c-48e6-950a-d8ee2d027188/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d4738624-956c-48e6-950a-d8ee2d027188/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 16406,
        "title": "Hotel WHITE 1921 COURCHEVEL - Courchevel hotel, accommodation in ...",
        "url": "https://pkslist.azureedge.net/media/images/1/eb902828-cc18-4f92-b921-fb994d5920ea/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/eb902828-cc18-4f92-b921-fb994d5920ea/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/eb902828-cc18-4f92-b921-fb994d5920ea/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 29593,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f55be866-4012-42e6-9dde-92b575298f33/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f55be866-4012-42e6-9dde-92b575298f33/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f55be866-4012-42e6-9dde-92b575298f33/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 29595,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/7a4bb176-a500-4b85-b596-d1dd2fa7ee0b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7a4bb176-a500-4b85-b596-d1dd2fa7ee0b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7a4bb176-a500-4b85-b596-d1dd2fa7ee0b/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 29592,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c5e99f06-bbfd-4571-aa49-e034b9caa58a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c5e99f06-bbfd-4571-aa49-e034b9caa58a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c5e99f06-bbfd-4571-aa49-e034b9caa58a/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 29597,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/02583061-0412-4b39-9180-40951f7012dd/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/02583061-0412-4b39-9180-40951f7012dd/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/02583061-0412-4b39-9180-40951f7012dd/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 16410,
        "title": null,
        "url": "https://pkslist.azureedge.net/media/images/1/86d0ede2-f99f-494b-817d-cd2a1ce7761b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/86d0ede2-f99f-494b-817d-cd2a1ce7761b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/86d0ede2-f99f-494b-817d-cd2a1ce7761b/256x192.jpg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1323,
      "latitude": 45.41489600000001,
      "longitude": 6.634700000000066,
      "parentGeographicAreaId": 0,
      "title": "80 rue du Rocher, 73120 Courchevel 1850, France",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 61,
        "tagName": "Mountain",
        "tagType": 2
      },
      {
        "tagId": 66,
        "tagName": "Skiing",
        "tagType": 2
      },
      {
        "tagId": 71,
        "tagName": "Group of Friends",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 75,
        "tagName": "Casual Dining",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 199,
        "tagName": "Art Gallery",
        "tagType": 2
      },
      {
        "tagId": 203,
        "tagName": "Sauna",
        "tagType": 2
      },
      {
        "tagId": 212,
        "tagName": "Hammam",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 277,
        "venueCategoryId": 17,
        "venueId": 1507,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1502,
    "venueCategoryId": 26,
    "status": 1,
    "title": "Vigilius Mountain Resort",
    "description": "Vigilius Mountain Resort is 1,500 meters above everyday life. This boutique luxury resort is a member of Design Hotels and can be reached exclusively by cable car, the panoramic windows in the 41 rooms furbished with natural materials like wood, clay and linen offer inspirational views of the Dolomite Mountains and the surrounding woods. Here it is possible to open up, and draw new vigour and energy from the placid wisdom of nature, while trifles are left behind in the valley.In the spa, the infinity pool, whirlpool, sauna and steambath offer relaxation and regeneration as well as carefully selected massages and treatments, using pure and natural care products.Move & explore is the name of the sports and relaxation programme, including outdoor and indoor activities: mountain biking, hiking, nordic walking as well as yoga, 5 Tibetans(R), archery and much more.Blessed with its location in South Tyrol, the cuisine is indicative of the area with two cultures: Alpine and Italian. Restaurant 1500 and Stube Ida invite to experience two different gastronomic concepts, creative international cuisine or traditional local specialties.",
    "brand": "",
    "blockQuote": "You are surrounded by nature, this is a place to appreciate life.",
    "emailAddress": "info@vigilius.it",
    "website": "vigilius.it/de",
    "bookingURL": "http://www.booking.com/Share-M9jvtN?aid=925669",
    "redirectNotes": null,
    "phoneNumber": "+390473 556 600",
    "addressOne": "3",
    "addressTwo": "Via Villa",
    "city": "Lana",
    "county": "",
    "region": "",
    "country": "Italy",
    "rooms": 41,
    "dollar": 2,
    "updated": "2020-05-13T17:34:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 16332,
        "title": "Vigilius Mountain Resort ( Lana, South Tyrol, Italy ) | Design Hotels™",
        "url": "https://pkslist.azureedge.net/media/images/1/6d2d6c83-3c8e-4984-8b38-877ebdd33844/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6d2d6c83-3c8e-4984-8b38-877ebdd33844/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6d2d6c83-3c8e-4984-8b38-877ebdd33844/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 35760,
        "title": "Vigilius Mountain Resort ( Lana, South Tyrol, Italy ) | Design Hotels™",
        "url": "https://pkslist.azureedge.net/media/images/1/22da0ebe-c087-4e9f-8f10-c74a7e385466/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/22da0ebe-c087-4e9f-8f10-c74a7e385466/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/22da0ebe-c087-4e9f-8f10-c74a7e385466/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 16334,
        "title": "Vigilius Mountain Resort ( Lana, South Tyrol, Italy ) | Design Hotels™",
        "url": "https://pkslist.azureedge.net/media/images/1/57c91db6-8eaf-4f7e-9b88-781791dfeda3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/57c91db6-8eaf-4f7e-9b88-781791dfeda3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/57c91db6-8eaf-4f7e-9b88-781791dfeda3/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 16331,
        "title": "Vigilius Mountain Resort, Lana, Italy - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/d8a07b30-35fa-47c9-921b-f9ef6e8a9d34/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d8a07b30-35fa-47c9-921b-f9ef6e8a9d34/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d8a07b30-35fa-47c9-921b-f9ef6e8a9d34/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 16337,
        "title": "5 Star Vigilius Mountain Resort - Atzwanger AG",
        "url": "https://pkslist.azureedge.net/media/images/1/24de6f4d-3c74-4e72-8108-51e3ebb32773/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/24de6f4d-3c74-4e72-8108-51e3ebb32773/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/24de6f4d-3c74-4e72-8108-51e3ebb32773/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 16336,
        "title": "Vigilius Mountain Resort, Matteo Thun | Lana | Italy | MIMOA",
        "url": "https://pkslist.azureedge.net/media/images/1/b7fd8ad6-d68c-4685-b67c-9b471c05ba8c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b7fd8ad6-d68c-4685-b67c-9b471c05ba8c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b7fd8ad6-d68c-4685-b67c-9b471c05ba8c/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 16341,
        "title": "Vigilius Mountain Resort Spa – South Tyrol, Italy | Art of the Spa",
        "url": "https://pkslist.azureedge.net/media/images/1/13372c90-462a-4cd9-975e-05d123f079c7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/13372c90-462a-4cd9-975e-05d123f079c7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/13372c90-462a-4cd9-975e-05d123f079c7/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 16342,
        "title": "vigilius mountain resort",
        "url": "https://pkslist.azureedge.net/media/images/1/0ab5b149-458d-4e29-9917-f2b6e1957b92/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0ab5b149-458d-4e29-9917-f2b6e1957b92/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0ab5b149-458d-4e29-9917-f2b6e1957b92/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 16339,
        "title": "Vigilius Mountain Resort, South Tyrol, Italy",
        "url": "https://pkslist.azureedge.net/media/images/1/4b7dfc75-e40a-47e4-9979-dad800a8327f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4b7dfc75-e40a-47e4-9979-dad800a8327f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4b7dfc75-e40a-47e4-9979-dad800a8327f/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2471,
      "latitude": 46.62056740000001,
      "longitude": 11.139939,
      "parentGeographicAreaId": 0,
      "title": "Via Villa, 3, 39011 Lana BZ, Italy",
      "updated": null,
      "created": "2020-05-13T17:34:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 5,
        "tagName": "Billiards",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 61,
        "tagName": "Mountain",
        "tagType": 2
      },
      {
        "tagId": 66,
        "tagName": "Skiing",
        "tagType": 2
      },
      {
        "tagId": 67,
        "tagName": "Sporty",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 497,
        "venueCategoryId": 17,
        "venueId": 1502,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 498,
        "venueCategoryId": 27,
        "venueId": 1502,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 27,
          "title": "Family Fun",
          "region": "Global",
          "viewOrder": 16,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1497,
    "venueCategoryId": 17,
    "status": 1,
    "title": "La Sivoliere",
    "description": "La Sivolière, The best kept secret of Courchevel, an ambiance, a way of life… More than a hotel, it is a home where guests are welcomed in a relaxed and elegant atmosphere. Enjoy a ski-in, ski-out hotel, after being outfitted from head to toe in the ski lounge, warm up to the fireplace, taste the gourmet cuisine of the restaurant Le 1850, rest in the well being area, appreciate your home away from home…\n\nEach room and suite offers the perfect combination of sophistication and authenticity, the warm tones, the sandblasted pine wood and the silky velvet fabrics create a cozy and intimate ambiance. Mixed tones and materials immerse guests in a harmonious and elegant atmosphere, in harmony with nature, with extensive view of the slopes and forest or of the village of Courchevel 1850.",
    "brand": null,
    "blockQuote": "The perfect blend of contemporary and traditional decor, using only the finest materials.",
    "emailAddress": "lasivoliere@sivoliere.fr",
    "website": "hotel-la-sivoliere.com",
    "bookingURL": "https://www.book-secure.com/index.php?s=results&property=frcou25587&arrival=2018-09-04&departure=2018-09-05&code=96027890&adults1=2&children1=0&rooms=1&locale=en_GB&currency=EUR&stid=bxxkfn41b&showPromotions=1&Clusternames=FRCOULASIVOLIERE&cluster=FRCOULASIVOLIERE&Hotelnames=FRCOULASIVOLIERE&hname=FRCOULASIVOLIERE&arrivalDateValue=2018-09-04&frommonth=09&fromday=04&fromyear=2018&departureDateValue=2018-09-05&tomonth=09&today=05&toyear=2018&AccessCode=96027890&accessCode=96027890&__utma=1.1753840001.1536057223.1536057223.1536057223.1&__utmb=1.10.8.1536057960890&__utmc=1&__utmx=-&__utmz=1.1536057223.1.1.utmcsr=google|utmccn=(organic)|utmcmd=organic|utmctr=(not%20provided)&__utmv=-&__utmk=13319991&redir=BIZ-so5523q0o4&rt=1536058180",
    "redirectNotes": "La Sivoliere's reservations system",
    "phoneNumber": "+33 4 79 08 08 33 ",
    "addressOne": "",
    "addressTwo": "Rue des Chenus",
    "city": "Courchevel",
    "county": "",
    "region": "",
    "country": "France",
    "rooms": 36,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 16288,
        "title": "Rooms & Suites - Authentic Room Courchevel Hotel - La Sivolière ...",
        "url": "https://pkslist.azureedge.net/media/images/1/7d8c03e0-1b33-4e1c-b04d-7bfa36b97bbd/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7d8c03e0-1b33-4e1c-b04d-7bfa36b97bbd/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7d8c03e0-1b33-4e1c-b04d-7bfa36b97bbd/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 23033,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/20bfd45d-45db-4e68-8dff-72536ce492ee/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/20bfd45d-45db-4e68-8dff-72536ce492ee/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/20bfd45d-45db-4e68-8dff-72536ce492ee/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 16286,
        "title": "Discover Hotel La Sivoliere in Courchevel, France | Voyage Privé ...",
        "url": "https://pkslist.azureedge.net/media/images/1/4695b1d6-a95a-40c0-922f-c1f9b23c3a22/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4695b1d6-a95a-40c0-922f-c1f9b23c3a22/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4695b1d6-a95a-40c0-922f-c1f9b23c3a22/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 16287,
        "title": "Rooms & Suites - Duplex Apartment Courchevel Hotel - La Sivolière ...",
        "url": "https://pkslist.azureedge.net/media/images/1/f90ed8ce-ffb8-4920-893c-c43ecf9729e9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f90ed8ce-ffb8-4920-893c-c43ecf9729e9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f90ed8ce-ffb8-4920-893c-c43ecf9729e9/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 16285,
        "title": "La Sivolière in Courchevel - Book a deluxe hotel close to downtown ...",
        "url": "https://pkslist.azureedge.net/media/images/1/1b2776c6-5be7-4557-9535-2cd6a33b662e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1b2776c6-5be7-4557-9535-2cd6a33b662e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1b2776c6-5be7-4557-9535-2cd6a33b662e/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 16297,
        "title": "Hôtel La Sivolière, a Design Boutique Hotel Courchevel, France",
        "url": "https://pkslist.azureedge.net/media/images/1/7f6c232a-73c1-4a2b-9c95-7a62d3725af4/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7f6c232a-73c1-4a2b-9c95-7a62d3725af4/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7f6c232a-73c1-4a2b-9c95-7a62d3725af4/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 16292,
        "title": null,
        "url": "https://pkslist.azureedge.net/media/images/1/3a0d17af-0203-4f24-a003-c3ee5c1c92bd/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3a0d17af-0203-4f24-a003-c3ee5c1c92bd/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3a0d17af-0203-4f24-a003-c3ee5c1c92bd/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 16295,
        "title": "Hôtel La Sivolière, a Design Boutique Hotel Courchevel, France",
        "url": "https://pkslist.azureedge.net/media/images/1/42f8229f-a008-4488-8c93-b293f7ea33ad/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/42f8229f-a008-4488-8c93-b293f7ea33ad/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/42f8229f-a008-4488-8c93-b293f7ea33ad/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1314,
      "latitude": 45.41672049999999,
      "longitude": 6.627876300000025,
      "parentGeographicAreaId": 0,
      "title": "Rue des Chenus, 73120 Courchevel, France",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 34,
        "tagName": "Cosy",
        "tagType": 2
      },
      {
        "tagId": 60,
        "tagName": "Gastronomic",
        "tagType": 2
      },
      {
        "tagId": 61,
        "tagName": "Mountain",
        "tagType": 2
      },
      {
        "tagId": 66,
        "tagName": "Skiing",
        "tagType": 2
      },
      {
        "tagId": 69,
        "tagName": "Couples",
        "tagType": 2
      },
      {
        "tagId": 71,
        "tagName": "Group of Friends",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 85,
        "tagName": "Pet Friendly",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 156,
        "tagName": "Fireplace",
        "tagType": 2
      },
      {
        "tagId": 203,
        "tagName": "Sauna",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 276,
        "venueCategoryId": 17,
        "venueId": 1497,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1496,
    "venueCategoryId": 17,
    "status": 1,
    "title": "L'Apogee Courchevel",
    "description": "L’Apogée Courchevel is an Alpine retreat where guests can relax in effortless style. Situated in the French Alps in the exclusive Jardin Alpin in Courchevel 1850, the five-star hotel enjoys an unbeatable location in this top ski resort, and offers a unique family atmosphere.\n\nThe hotel is a cocoon of luxurious warmth immersed in a pristine snowy panorama, a deeply comfortable home for guests to return to after a day in the brilliant white snow; somewhere authentic with an easy sophistication. Rooms and suites are remarkably spacious and designed with chic individualism, offering cosiness and warmth with furs, soft wool plaid and supple leather caressing the senses. The penthouse and chalets are designed to a level beyond luxury, with rooftop Jacuzzi and private spas.",
    "brand": null,
    "blockQuote": "Subtle alpine charm with a smooth contemporary style.",
    "emailAddress": "reservations.apg@oetkercollection.com",
    "website": "oetkercollection.com/destinations/lapogee-courchevel",
    "bookingURL": "https://gc.synxis.com/rez.aspx?Hotel=59311&Chain=13518&arrive=12/15/2017&adult=2&Locale=en&_ga=2.183189797.2061156101.1536058337-942951975.1533504014&iata=96027890",
    "redirectNotes": "L'Apogee Courchevel's reservations system",
    "phoneNumber": "+33 4 79 04 01 04",
    "addressOne": "5",
    "addressTwo": "Rue Emile Allais",
    "city": "Courchevel",
    "county": "",
    "region": "",
    "country": "France",
    "rooms": 53,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 16283,
        "title": "Hotel L'APOGÉE COURCHEVEL - Courchevel hotel, accommodation in ...",
        "url": "https://pkslist.azureedge.net/media/images/1/e570ca37-885e-41d4-96c3-821cf33d1cbf/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e570ca37-885e-41d4-96c3-821cf33d1cbf/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e570ca37-885e-41d4-96c3-821cf33d1cbf/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 16280,
        "title": "Apogée Courchevel | Hg2 Courchevel",
        "url": "https://pkslist.azureedge.net/media/images/1/11654b25-3627-432e-9753-61891d111ab0/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/11654b25-3627-432e-9753-61891d111ab0/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/11654b25-3627-432e-9753-61891d111ab0/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 16284,
        "title": "Courchevel - L'Apogée | HOTEL, MÔN AMOUR | Pinterest",
        "url": "https://pkslist.azureedge.net/media/images/1/f7b3aadd-2d3e-403c-9e86-f51538fb9c4f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f7b3aadd-2d3e-403c-9e86-f51538fb9c4f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f7b3aadd-2d3e-403c-9e86-f51538fb9c4f/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 16279,
        "title": "A personal review of L'Apogée Courchevel",
        "url": "https://pkslist.azureedge.net/media/images/1/e26d0059-3703-47f3-8498-af2e6dd32a84/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e26d0059-3703-47f3-8498-af2e6dd32a84/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e26d0059-3703-47f3-8498-af2e6dd32a84/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 16282,
        "title": "L'Apogée Courchevel, Courchevel 1650 (Moriond), 2013 - India ...",
        "url": "https://pkslist.azureedge.net/media/images/1/05fe0d91-4947-43e2-bb75-e3d0ea45b7d8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/05fe0d91-4947-43e2-bb75-e3d0ea45b7d8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/05fe0d91-4947-43e2-bb75-e3d0ea45b7d8/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 16281,
        "title": "L'Apogée Courchevel - Courchevel luxury ski hotel - 3 Vallees ...",
        "url": "https://pkslist.azureedge.net/media/images/1/2baa9ac8-21f2-4059-b858-c4c13cbb4c6f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2baa9ac8-21f2-4059-b858-c4c13cbb4c6f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2baa9ac8-21f2-4059-b858-c4c13cbb4c6f/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 16275,
        "title": "Hotel L'APOGÉE COURCHEVEL - Courchevel hotel, accommodation in ...",
        "url": "https://pkslist.azureedge.net/media/images/1/af7d3d0b-3bf0-4a05-b598-cbaf731b3d4d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/af7d3d0b-3bf0-4a05-b598-cbaf731b3d4d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/af7d3d0b-3bf0-4a05-b598-cbaf731b3d4d/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 16278,
        "title": "Hotel L'APOGÉE COURCHEVEL - Courchevel hotel, accommodation in ...",
        "url": "https://pkslist.azureedge.net/media/images/1/7ef0dcea-81d0-4ed3-9c20-c3927bfe646b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7ef0dcea-81d0-4ed3-9c20-c3927bfe646b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7ef0dcea-81d0-4ed3-9c20-c3927bfe646b/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 16277,
        "title": "Hotel L'Apogée Courchevel, France - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/9285fe33-6b74-495b-865c-93ff7f9613ff/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9285fe33-6b74-495b-865c-93ff7f9613ff/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9285fe33-6b74-495b-865c-93ff7f9613ff/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1313,
      "latitude": 45.4119657,
      "longitude": 6.633069400000068,
      "parentGeographicAreaId": 0,
      "title": "5 Rue Emile Allais, 73120 Saint-Bon-Tarentaise, France",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 34,
        "tagName": "Cosy",
        "tagType": 2
      },
      {
        "tagId": 60,
        "tagName": "Gastronomic",
        "tagType": 2
      },
      {
        "tagId": 61,
        "tagName": "Mountain",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 66,
        "tagName": "Skiing",
        "tagType": 2
      },
      {
        "tagId": 69,
        "tagName": "Couples",
        "tagType": 2
      },
      {
        "tagId": 71,
        "tagName": "Group of Friends",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 83,
        "tagName": "Kid's Club",
        "tagType": 2
      },
      {
        "tagId": 153,
        "tagName": "Cigar Lounge",
        "tagType": 2
      },
      {
        "tagId": 183,
        "tagName": "Butler Service",
        "tagType": 2
      },
      {
        "tagId": 202,
        "tagName": "Private Terraces",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 275,
        "venueCategoryId": 17,
        "venueId": 1496,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1489,
    "venueCategoryId": 17,
    "status": 1,
    "title": "CERVO Mountain Boutique Resort",
    "description": "CERVO guests can choose from six chalets with a total of 36 rooms and suites. Classy materials, natural colours and tasteful design form the setting for unforgettable holiday moments with a view of the Matterhorn- with 270° panoramic views and all-round comfort, CERVO style. As each house has its own spa, you can enjoy the beauty of the Alpine world here in an intimate atmosphere.\n\nCERVO Mountain Boutique Resort is a private retreat where you can recharge body, soul and spirit.",
    "brand": null,
    "blockQuote": "Each house has its own spa where you can completely relax and take in the beauty of the alpine world.",
    "emailAddress": "info@cervo.ch",
    "website": "cervo.ch/en/",
    "bookingURL": "http://www.booking.com/Share-T5aQnB?aid=925669",
    "redirectNotes": null,
    "phoneNumber": "+41 27 968 12 12 ",
    "addressOne": "156",
    "addressTwo": "Riedweg",
    "city": "Zermatt",
    "county": "",
    "region": "",
    "country": "Switzerland",
    "rooms": 36,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 23035,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/2568a3dc-2270-48ff-a3e4-e7df1e1eb920/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2568a3dc-2270-48ff-a3e4-e7df1e1eb920/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2568a3dc-2270-48ff-a3e4-e7df1e1eb920/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 23034,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/418230c0-9b03-4b2b-8371-e02df0184cba/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/418230c0-9b03-4b2b-8371-e02df0184cba/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/418230c0-9b03-4b2b-8371-e02df0184cba/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 16183,
        "title": "Cervo Mountain Boutique Resort - EIGHTY FOUR ROOMS",
        "url": "https://pkslist.azureedge.net/media/images/1/af537f48-28e3-45e6-a1ef-65b8010a81b9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/af537f48-28e3-45e6-a1ef-65b8010a81b9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/af537f48-28e3-45e6-a1ef-65b8010a81b9/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 23037,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b4619f1e-da74-43fd-809f-9e3a240af2e0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b4619f1e-da74-43fd-809f-9e3a240af2e0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b4619f1e-da74-43fd-809f-9e3a240af2e0/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 23038,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c21d2cae-ab76-4d0c-8d5a-a3753c01a285/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c21d2cae-ab76-4d0c-8d5a-a3753c01a285/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c21d2cae-ab76-4d0c-8d5a-a3753c01a285/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 16186,
        "title": "Welcome | CERVO Mountain Boutique Resort",
        "url": "https://pkslist.azureedge.net/media/images/1/be19c325-548f-4f8b-aa6c-f779627a84ef/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/be19c325-548f-4f8b-aa6c-f779627a84ef/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/be19c325-548f-4f8b-aa6c-f779627a84ef/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 16184,
        "title": "CERVO Mountain Boutique Resort - Hotels in Heaven - The most ...",
        "url": "https://pkslist.azureedge.net/media/images/1/69b716ec-ec31-4b43-af08-65e8b84e7627/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/69b716ec-ec31-4b43-af08-65e8b84e7627/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/69b716ec-ec31-4b43-af08-65e8b84e7627/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 23036,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ec78bc73-73c8-4b56-b179-a528d2d8bc53/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ec78bc73-73c8-4b56-b179-a528d2d8bc53/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ec78bc73-73c8-4b56-b179-a528d2d8bc53/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 16185,
        "title": "Cervo Mountain Boutique Resort (Zermatt, Switzerland) | Design Hotels™",
        "url": "https://pkslist.azureedge.net/media/images/1/40dd2895-efe6-495b-aafe-1115be511126/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/40dd2895-efe6-495b-aafe-1115be511126/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/40dd2895-efe6-495b-aafe-1115be511126/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 16189,
        "title": "CERVO Mountain Boutique Resort - Zermatt - HIP Hotels",
        "url": "https://pkslist.azureedge.net/media/images/1/29e43770-5390-42b8-abad-6fda598a0532/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/29e43770-5390-42b8-abad-6fda598a0532/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/29e43770-5390-42b8-abad-6fda598a0532/256x192.jpg",
        "viewOrder": 12
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1306,
      "latitude": 46.021937,
      "longitude": 7.754372999999987,
      "parentGeographicAreaId": 0,
      "title": "Riedweg 156, 3920 Zermatt, Switzerland",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      },
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 34,
        "tagName": "Cosy",
        "tagType": 2
      },
      {
        "tagId": 61,
        "tagName": "Mountain",
        "tagType": 2
      },
      {
        "tagId": 66,
        "tagName": "Skiing",
        "tagType": 2
      },
      {
        "tagId": 69,
        "tagName": "Couples",
        "tagType": 2
      },
      {
        "tagId": 70,
        "tagName": "Families",
        "tagType": 2
      },
      {
        "tagId": 71,
        "tagName": "Group of Friends",
        "tagType": 2
      },
      {
        "tagId": 73,
        "tagName": "Outdoor Terraces",
        "tagType": 2
      },
      {
        "tagId": 75,
        "tagName": "Casual Dining",
        "tagType": 2
      },
      {
        "tagId": 85,
        "tagName": "Pet Friendly",
        "tagType": 2
      },
      {
        "tagId": 93,
        "tagName": "Private",
        "tagType": 2
      },
      {
        "tagId": 100,
        "tagName": "Wedding",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 243,
        "tagName": "Outdoor Activities",
        "tagType": 2
      },
      {
        "tagId": 293,
        "tagName": "Meeting Space",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 271,
        "venueCategoryId": 17,
        "venueId": 1489,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1488,
    "venueCategoryId": 17,
    "status": 1,
    "title": "Hotel Firefly",
    "description": "The family owned luxury suite hotel is so much more than a roof over your head. This is the kind of hotel where you'd be pleased to spend the day thanks to the unusual concept of this four-star superior hotel. Your choice to relax and enjoy extraordinary service through out the day is paramount here. \n\nFire, Water, Earth, Air – The interior of the FIREFLY follows the principle of these four elements. They run through the entire hotel, from floor to floor. Each of the suites is assigned to an element by a clear colour scheme that goes to the last detail, and creates a distinctive mood. Each suite also offers a high-end kitchen.\n\nAll that matters is your own rhythm.  Hotel Firefly provides you with the time and space to enjoy life.",
    "brand": null,
    "blockQuote": "Visit the spa for a full body pampering or a full body work out.",
    "emailAddress": "info@firefly-zermatt.ch",
    "website": "firefly-zermatt.ch",
    "bookingURL": "https://www.booking.com/hotel/ch/firefly.en-gb.html?aid=925669;label=gog235jc-1DCAsoLEIHZmlyZWZseUgzWANoqAGIAQGYAQm4ARjIAQzYAQPoAQGIAgGoAgS4AtHhnOYFwAIB;sid=6b10f0abd2fe50a3592a2bb374b7ab7f;dist=0&group_adults=2&keep_landing=1&sb_price_type=total&type=total&",
    "redirectNotes": "",
    "phoneNumber": "+41 27 967 76 76",
    "addressOne": "55",
    "addressTwo": "Schluhmattstrasse",
    "city": "Zermatt",
    "county": "",
    "region": "",
    "country": "Switzerland",
    "rooms": 16,
    "dollar": 2,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 16170,
        "title": "4 Stars Luxury Suite Hotel Firefly, Zermatt, Switzerland to see ...",
        "url": "https://pkslist.azureedge.net/media/images/1/d13c86a3-aa2f-40cd-8291-392190a5ea4b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d13c86a3-aa2f-40cd-8291-392190a5ea4b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d13c86a3-aa2f-40cd-8291-392190a5ea4b/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 16167,
        "title": "4 Stars Luxury Suite Hotel Firefly, Zermatt, Switzerland | Luxe ...",
        "url": "https://pkslist.azureedge.net/media/images/1/5cb530b1-032d-4226-9c20-d1a165507dc3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5cb530b1-032d-4226-9c20-d1a165507dc3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5cb530b1-032d-4226-9c20-d1a165507dc3/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 16177,
        "title": "Book Rooms in the Hotel Firefly, Zermatt Switzerland - Powder White",
        "url": "https://pkslist.azureedge.net/media/images/1/a49a40eb-d96b-401b-b1ad-93cee456eda1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a49a40eb-d96b-401b-b1ad-93cee456eda1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a49a40eb-d96b-401b-b1ad-93cee456eda1/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 16169,
        "title": "Hotel Zermatt Switserland",
        "url": "https://pkslist.azureedge.net/media/images/1/45bbedd6-a33d-4188-bccc-03b3ff24c2d7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/45bbedd6-a33d-4188-bccc-03b3ff24c2d7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/45bbedd6-a33d-4188-bccc-03b3ff24c2d7/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 16174,
        "title": "Hotel Firefly, Zermatt: the best offers with Destinia",
        "url": "https://pkslist.azureedge.net/media/images/1/33a67640-c7b5-408a-9094-c02a02d3c694/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/33a67640-c7b5-408a-9094-c02a02d3c694/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/33a67640-c7b5-408a-9094-c02a02d3c694/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 16171,
        "title": "Hotel Firefly Luxury Suites, Zermatt, Switzerland - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/5d0230d7-b5ce-4d1d-a9b8-269e32d786fa/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5d0230d7-b5ce-4d1d-a9b8-269e32d786fa/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5d0230d7-b5ce-4d1d-a9b8-269e32d786fa/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 16168,
        "title": "Firefly Hotel - hotelroomsearch.net",
        "url": "https://pkslist.azureedge.net/media/images/1/8835d859-6d8f-4129-a0d7-79a5761fdac5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8835d859-6d8f-4129-a0d7-79a5761fdac5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8835d859-6d8f-4129-a0d7-79a5761fdac5/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 16175,
        "title": "Firefly Hotel - hotelroomsearch.net",
        "url": "https://pkslist.azureedge.net/media/images/1/1e8d678a-f7d7-4aeb-9ca3-9f58758ecdd0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1e8d678a-f7d7-4aeb-9ca3-9f58758ecdd0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1e8d678a-f7d7-4aeb-9ca3-9f58758ecdd0/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 16166,
        "title": "4 Stars Luxury Suite Hotel Firefly, Zermatt, Switzerland",
        "url": "https://pkslist.azureedge.net/media/images/1/ed5a8f7c-a2d4-4d65-a1a6-ec751b7a2961/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ed5a8f7c-a2d4-4d65-a1a6-ec751b7a2961/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ed5a8f7c-a2d4-4d65-a1a6-ec751b7a2961/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1305,
      "latitude": 46.0169866,
      "longitude": 7.745612400000027,
      "parentGeographicAreaId": 0,
      "title": "Schluhmattstrasse 55, 3920 Zermatt, Switzerland",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 47,
        "tagName": "Intimate Luxury ",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 34,
        "tagName": "Cosy",
        "tagType": 2
      },
      {
        "tagId": 61,
        "tagName": "Mountain",
        "tagType": 2
      },
      {
        "tagId": 66,
        "tagName": "Skiing",
        "tagType": 2
      },
      {
        "tagId": 69,
        "tagName": "Couples",
        "tagType": 2
      },
      {
        "tagId": 70,
        "tagName": "Families",
        "tagType": 2
      },
      {
        "tagId": 71,
        "tagName": "Group of Friends",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 156,
        "tagName": "Fireplace",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 243,
        "tagName": "Outdoor Activities",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 270,
        "venueCategoryId": 17,
        "venueId": 1488,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1486,
    "venueCategoryId": 17,
    "status": 1,
    "title": "Almhof Schneider",
    "description": "For many the Almhof Schneider is more than just a hotel. It has been home to the family since 1451, since 1929 the goal is to make their guests feel at home. Its convenient position in the village, somewhat off the main road and at the foot of the Schlegelkopf mountain allows you to spend your vacation in peace, without a car, walk into the centre, put on your skis outside the front door and enjoy all sport facilities within close range.\n\nIn the 18 rooms and 35 suites you will experience a quality of living enhanced by the the timber and stones of the region and the simple and functional forms of alpine architecture. Panelling of alpine fir, oiled walnut, Salzburg limestone, open fire places, antique alpine furniture and handmade beds: designed with a great passion for detail and hand-crafted. It is a blend of traditional and contemporary styles but always with the emphasis on the perfection of workmanship and the use of genuine and superior traditional materials.",
    "brand": null,
    "blockQuote": "Indulging its guests is a traditional Austrian hospitality",
    "emailAddress": "info@almhof.at",
    "website": "almhof.at",
    "bookingURL": "http://www.booking.com/Share-4PicAB?aid=925669",
    "redirectNotes": null,
    "phoneNumber": "+43 5583 3500 0",
    "addressOne": "59",
    "addressTwo": "Tannberg",
    "city": "Lech",
    "county": "",
    "region": "",
    "country": "Austria",
    "rooms": 53,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 16150,
        "title": "Austria's Best Ski Hotel 2014 « World Ski Awards",
        "url": "https://pkslist.azureedge.net/media/images/1/76790ce7-75e0-428e-a816-ac8626fbd926/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/76790ce7-75e0-428e-a816-ac8626fbd926/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/76790ce7-75e0-428e-a816-ac8626fbd926/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 16147,
        "title": "Hotel Almhof Schneider - Mittelberg - book your hotel with ViaMichelin",
        "url": "https://pkslist.azureedge.net/media/images/1/4c7ac4d5-d1db-4ed4-94a3-a6a0e2082f86/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4c7ac4d5-d1db-4ed4-94a3-a6a0e2082f86/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4c7ac4d5-d1db-4ed4-94a3-a6a0e2082f86/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 16152,
        "title": "Almhof Schneider - The Hotel Specialist",
        "url": "https://pkslist.azureedge.net/media/images/1/9beec228-db3e-4e97-bb49-5abeeefaa968/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9beec228-db3e-4e97-bb49-5abeeefaa968/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9beec228-db3e-4e97-bb49-5abeeefaa968/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 16140,
        "title": "Hotel Almhof Schneider, Lech am Arlberg, Austria - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/cc7f9180-b5ad-48b3-a499-05fb604d9fd4/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cc7f9180-b5ad-48b3-a499-05fb604d9fd4/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cc7f9180-b5ad-48b3-a499-05fb604d9fd4/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 16146,
        "title": "Hotel Almhof Schneider, 5 Star Hotel, GBP 651 | Lech am Arlberg ...",
        "url": "https://pkslist.azureedge.net/media/images/1/fb07b46e-5aa1-49e0-9001-faf425eb4f70/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fb07b46e-5aa1-49e0-9001-faf425eb4f70/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fb07b46e-5aa1-49e0-9001-faf425eb4f70/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 16145,
        "title": "Hotel Almhof Schneider, Lech am Arlberg, Austria - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/36e5a218-cf62-406d-a845-cd71a483ee28/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/36e5a218-cf62-406d-a845-cd71a483ee28/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/36e5a218-cf62-406d-a845-cd71a483ee28/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 16148,
        "title": "Hotel Almhof Schneider - Hotels & Style",
        "url": "https://pkslist.azureedge.net/media/images/1/1dbf4c4a-3064-4049-8e04-368a81f03277/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1dbf4c4a-3064-4049-8e04-368a81f03277/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1dbf4c4a-3064-4049-8e04-368a81f03277/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 16141,
        "title": "Hotel Almhof Schneider - Hotels & Style",
        "url": "https://pkslist.azureedge.net/media/images/1/1a62f697-e4fe-4d4c-a007-88204015951a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1a62f697-e4fe-4d4c-a007-88204015951a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1a62f697-e4fe-4d4c-a007-88204015951a/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 16151,
        "title": "Hotel Almhof Schneider – Best of Golf and Ski",
        "url": "https://pkslist.azureedge.net/media/images/1/d2ba863d-1ac1-4b5c-a0bb-2626733a45ec/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d2ba863d-1ac1-4b5c-a0bb-2626733a45ec/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d2ba863d-1ac1-4b5c-a0bb-2626733a45ec/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1303,
      "latitude": 47.208485,
      "longitude": 10.139858000000004,
      "parentGeographicAreaId": 0,
      "title": "Tannberg 59, 6764 Lech, Austria",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 47,
        "tagName": "Intimate Luxury ",
        "tagType": 1
      },
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 34,
        "tagName": "Cosy",
        "tagType": 2
      },
      {
        "tagId": 60,
        "tagName": "Gastronomic",
        "tagType": 2
      },
      {
        "tagId": 66,
        "tagName": "Skiing",
        "tagType": 2
      },
      {
        "tagId": 69,
        "tagName": "Couples",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 101,
        "tagName": "Cinema",
        "tagType": 2
      },
      {
        "tagId": 131,
        "tagName": "Library",
        "tagType": 2
      },
      {
        "tagId": 156,
        "tagName": "Fireplace",
        "tagType": 2
      },
      {
        "tagId": 167,
        "tagName": "Nanny/Babysitting Service",
        "tagType": 2
      },
      {
        "tagId": 243,
        "tagName": "Outdoor Activities",
        "tagType": 2
      },
      {
        "tagId": 293,
        "tagName": "Meeting Space",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 269,
        "venueCategoryId": 17,
        "venueId": 1486,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1461,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Four Seasons Resort and Residences Whistler",
    "description": "Be ready for a warm Canadian welcome! At Four Seasons, we’ll fill your days with exhilarating adventures on Whistler and Blackcomb mountains. When it’s time to relax, return to our comfortable, intimate lodge, featuring Whistler’s largest guest rooms, suites and residences – all with fireplace and balcony. Friendly Four Seasons charm makes you feel right at home.\n\nMonumental in scale, Whistler dazzles with its vast acreage, summit glaciers and a towering vertical rise. It also charms, with a lively European-esque village and alpine meadows. Year round, Whistler is an inexhaustible source of adventure, shopping and unforgettable memories.",
    "brand": "Four Seasons",
    "blockQuote": "Heat up in the sauna followed by a dip in the outdoor pool.",
    "emailAddress": "res.whistler@fourseasons.com",
    "website": "fourseasons.com/whistler",
    "bookingURL": "https://reservations.fourseasons.com/?hotelCode=YWS199&checkIn=2021-03-30&checkOut=2021-03-31&adults=2&children=0&iata=96027890",
    "redirectNotes": "Four Seasons Resort and Residences Whistler's reservations system",
    "phoneNumber": "+1 604 935 3400",
    "addressOne": "4591",
    "addressTwo": "Blackcomb Way",
    "city": "Whistler",
    "county": "",
    "region": "British Columbia",
    "country": "Canada",
    "rooms": 291,
    "dollar": 3,
    "updated": "2021-03-31T06:02:44.377",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 46772,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/432e8261-3420-4114-9c9c-9dec19908281/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/432e8261-3420-4114-9c9c-9dec19908281/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/432e8261-3420-4114-9c9c-9dec19908281/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 46769,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/693d0c76-492b-4bb5-a78e-08b3840b82ea/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/693d0c76-492b-4bb5-a78e-08b3840b82ea/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/693d0c76-492b-4bb5-a78e-08b3840b82ea/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 46762,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/6f523a44-30fa-4433-b017-dba36ca29be0/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6f523a44-30fa-4433-b017-dba36ca29be0/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6f523a44-30fa-4433-b017-dba36ca29be0/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 46764,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/bc71985c-2816-476d-bf5d-80ab9e5ebc12/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bc71985c-2816-476d-bf5d-80ab9e5ebc12/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bc71985c-2816-476d-bf5d-80ab9e5ebc12/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 46765,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/dee996dc-a665-4c98-82c6-0392e1a202d2/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/dee996dc-a665-4c98-82c6-0392e1a202d2/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/dee996dc-a665-4c98-82c6-0392e1a202d2/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 46763,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/2c17c951-2ff1-45aa-8eba-c18a8b11f67e/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2c17c951-2ff1-45aa-8eba-c18a8b11f67e/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2c17c951-2ff1-45aa-8eba-c18a8b11f67e/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 46766,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/384f00a4-02c2-4dde-9e34-364687469883/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/384f00a4-02c2-4dde-9e34-364687469883/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/384f00a4-02c2-4dde-9e34-364687469883/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 46768,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b30d9a81-cf77-46fe-97e7-d1be80e0d479/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b30d9a81-cf77-46fe-97e7-d1be80e0d479/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b30d9a81-cf77-46fe-97e7-d1be80e0d479/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 46775,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/bea9bbdd-55f2-4246-b67b-9400fb4f8118/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bea9bbdd-55f2-4246-b67b-9400fb4f8118/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bea9bbdd-55f2-4246-b67b-9400fb4f8118/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 46776,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/9d5b6b4b-9823-4541-8e5a-1b2e07b1250b/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9d5b6b4b-9823-4541-8e5a-1b2e07b1250b/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9d5b6b4b-9823-4541-8e5a-1b2e07b1250b/256x192.jpeg",
        "viewOrder": 9
      },
      {
        "mediaId": 46771,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/0bb00c71-a4da-45ce-871d-f40d9f202830/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0bb00c71-a4da-45ce-871d-f40d9f202830/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0bb00c71-a4da-45ce-871d-f40d9f202830/256x192.jpeg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1278,
      "latitude": 50.1189723,
      "longitude": -122.94472510000003,
      "parentGeographicAreaId": 0,
      "title": "4591 Blackcomb Way, Whistler, BC V0N 1B4, Canada",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 60,
        "tagName": "Gastronomic",
        "tagType": 2
      },
      {
        "tagId": 66,
        "tagName": "Skiing",
        "tagType": 2
      },
      {
        "tagId": 81,
        "tagName": "Golf",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 156,
        "tagName": "Fireplace",
        "tagType": 2
      },
      {
        "tagId": 159,
        "tagName": "Mountain Biking",
        "tagType": 2
      },
      {
        "tagId": 243,
        "tagName": "Outdoor Activities",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 575,
        "venueCategoryId": 17,
        "venueId": 1461,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1419,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Four Seasons Hotel Megeve",
    "description": "Meet us in Megève, in the heart of the French Alps, just a few minutes away from the village centre. This modern chalet builds on the legacy of the Edmond de Rothschild family who set out to create an unrivaled resort experience nearly a century ago. Distinct French character, pure Alpine tradition, and an intimate and inviting setting in both summer and winter define this mountain retreat. Play one of the world’s finest Alpine golf courses, relax in the region’s largest spa, and dine at La Dame de Pic – Le 1920.",
    "brand": "Four Seasons",
    "blockQuote": "The only hotel directly on the Mont d’Arbois slopes, you'll be in the heart of the French Alps.",
    "emailAddress": "members@pks-list.com",
    "website": "fourseasons.com/megeve/",
    "bookingURL": "https://reservations.fourseasons.com/?hotelCode=MVV605&checkIn=2021-03-30&checkOut=2021-03-31&adults=2&children=0&iata=96027890",
    "redirectNotes": "Four Seasons Hotel Megeve's reservations system",
    "phoneNumber": "+33 4 50 211 211",
    "addressOne": "",
    "addressTwo": "",
    "city": "Megeve",
    "county": "",
    "region": "",
    "country": "France",
    "rooms": 55,
    "dollar": 3,
    "updated": "2021-03-30T07:05:37.537",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 46722,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/df4b606a-d821-4029-bbd0-036de60a7ed1/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/df4b606a-d821-4029-bbd0-036de60a7ed1/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/df4b606a-d821-4029-bbd0-036de60a7ed1/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 46725,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b50ee199-56af-445e-9a03-9c0219219999/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b50ee199-56af-445e-9a03-9c0219219999/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b50ee199-56af-445e-9a03-9c0219219999/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 46744,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e0618c70-03ea-4d2e-b8bc-5670e48c686b/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e0618c70-03ea-4d2e-b8bc-5670e48c686b/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e0618c70-03ea-4d2e-b8bc-5670e48c686b/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 46735,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/eeb12a97-d35a-41f5-85f1-93d3cd980855/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/eeb12a97-d35a-41f5-85f1-93d3cd980855/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/eeb12a97-d35a-41f5-85f1-93d3cd980855/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 46726,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/36c00b6c-af52-4d82-9143-c3c2e3fda7fd/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/36c00b6c-af52-4d82-9143-c3c2e3fda7fd/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/36c00b6c-af52-4d82-9143-c3c2e3fda7fd/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 15224,
        "title": "Four Seasons Hotel Megeve, Megève, France - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/037adb1a-ccd7-4f16-9ab0-e94f19030043/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/037adb1a-ccd7-4f16-9ab0-e94f19030043/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/037adb1a-ccd7-4f16-9ab0-e94f19030043/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 46732,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f6b891b9-fe9d-4071-aba3-28d53200e403/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f6b891b9-fe9d-4071-aba3-28d53200e403/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f6b891b9-fe9d-4071-aba3-28d53200e403/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 46731,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/783d4f64-4760-4aee-9f84-044fa2c6fbf6/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/783d4f64-4760-4aee-9f84-044fa2c6fbf6/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/783d4f64-4760-4aee-9f84-044fa2c6fbf6/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 46739,
        "title": "Les Chalets du Mont d'Arbois Megeve, a Four Seasons Hotel, Megève ...",
        "url": "https://pkslist.azureedge.net/media/images/1/e0e2b5d3-6592-4002-b88b-fe1370c29b57/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e0e2b5d3-6592-4002-b88b-fe1370c29b57/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e0e2b5d3-6592-4002-b88b-fe1370c29b57/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 46736,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/354773a2-6ddb-4c13-b5b2-21588f6dcb0b/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/354773a2-6ddb-4c13-b5b2-21588f6dcb0b/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/354773a2-6ddb-4c13-b5b2-21588f6dcb0b/256x192.jpeg",
        "viewOrder": 13
      },
      {
        "mediaId": 46733,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a6e1cba2-49d2-45cb-aea5-bd3d601723ad/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a6e1cba2-49d2-45cb-aea5-bd3d601723ad/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a6e1cba2-49d2-45cb-aea5-bd3d601723ad/256x192.jpeg",
        "viewOrder": 14
      },
      {
        "mediaId": 15220,
        "title": "Four Seasons Hotel To Launch New Resort In Medieval French Alps ...",
        "url": "https://pkslist.azureedge.net/media/images/1/5d87f6ef-e724-479f-b12f-0b836c3c5e8d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5d87f6ef-e724-479f-b12f-0b836c3c5e8d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5d87f6ef-e724-479f-b12f-0b836c3c5e8d/256x192.jpg",
        "viewOrder": 20
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1238,
      "latitude": 45.847148,
      "longitude": 6.642925600000012,
      "parentGeographicAreaId": 0,
      "title": "373 Chemin des Follières, 74120 Megéve, France",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 66,
        "tagName": "Skiing",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 81,
        "tagName": "Golf",
        "tagType": 2
      },
      {
        "tagId": 83,
        "tagName": "Kid's Club",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 159,
        "tagName": "Mountain Biking",
        "tagType": 2
      },
      {
        "tagId": 212,
        "tagName": "Hammam",
        "tagType": 2
      },
      {
        "tagId": 222,
        "tagName": "Michelin Star dining",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 573,
        "venueCategoryId": 17,
        "venueId": 1419,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 574,
        "venueCategoryId": 27,
        "venueId": 1419,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 27,
          "title": "Family Fun",
          "region": "Global",
          "viewOrder": 16,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1282,
    "venueCategoryId": 17,
    "status": 1,
    "title": "Hotel Jerome, Auberge Resorts Collection",
    "description": "The newly refurbished Hotel Jerome, An Auberge Resort recently received the \"Hot New Hotel\" from Travel & Leisure for its renovation. The Hotel Jerome's 93 guest rooms and suites have been beautifully redesigned incorporating the charm of the destination with modern amenities for a luxury traveler. Aspen's best new restaurant \"Prospect\" offers regional cuisine, the iconic J-Bar is a local watering hole serving lunch and dinner daily with classic American cuisine. The new Living Room lobby has been energized with a bar and stylish seating areas to relax and socialize. A new Auberge Spa offers treatments for guests that will soothe and pamper after a day on the slopes or a mountain adventure. Services include twice-daily maid service, nightly turn-down. Convenient local airport and downtown transportation is provided via chauffer-driven luxury SUV. Nestled in the heart of Aspen, this landmark hotel is just a ten-minute drive from the Aspen Airport, a 75-minute drive from Eagle County Airport and less than four hours from Denver International Airport.",
    "brand": "Auberge Resorts Collection",
    "blockQuote": "J-Bar is the town's hotspot and great for a little people watching along with a good ol' beer or vintage-inspired cocktail.",
    "emailAddress": "Jerome.reservations@aubergeresorts.com",
    "website": "hoteljerome.aubergeresorts.com/",
    "bookingURL": "https://gc.synxis.com/rez.aspx?Hotel=60896&Chain=16237&_ga=2&template=RBE3&shell=RBE2&iata=96027890",
    "redirectNotes": "Hotel Jerome's reservations system",
    "phoneNumber": "+1 970 429 5028",
    "addressOne": "330",
    "addressTwo": "East Main Street",
    "city": "Aspen",
    "county": "",
    "region": "Colorado",
    "country": "United States",
    "rooms": 99,
    "dollar": 2,
    "updated": "2022-01-24T14:05:55.05",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 13425,
        "title": "Hotel Jerome, Aspen, CO - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/c9006fde-ca15-4019-a03c-c53a0bfba93a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c9006fde-ca15-4019-a03c-c53a0bfba93a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c9006fde-ca15-4019-a03c-c53a0bfba93a/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 13427,
        "title": "hotel jerome - rowland+broughton",
        "url": "https://pkslist.azureedge.net/media/images/1/6fb403d2-39cb-44bc-a1c4-027ae8000668/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6fb403d2-39cb-44bc-a1c4-027ae8000668/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6fb403d2-39cb-44bc-a1c4-027ae8000668/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 13428,
        "title": "Discover the Spooky Past of the Hotel Jerome",
        "url": "https://pkslist.azureedge.net/media/images/1/bc31cabd-f76e-485a-88c7-0ce624dad224/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bc31cabd-f76e-485a-88c7-0ce624dad224/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bc31cabd-f76e-485a-88c7-0ce624dad224/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 13429,
        "title": "Hotel Jerome | Stay Aspen Snowmass",
        "url": "https://pkslist.azureedge.net/media/images/1/ee4787ae-98c5-49b2-a45d-1cfac61bc471/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ee4787ae-98c5-49b2-a45d-1cfac61bc471/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ee4787ae-98c5-49b2-a45d-1cfac61bc471/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 13430,
        "title": "Sunday Night: The Tycoon Saloon at the Hotel Jerome – ADSUM",
        "url": "https://pkslist.azureedge.net/media/images/1/ed8241ec-2a1f-4249-8aa0-f03cdd4d5731/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ed8241ec-2a1f-4249-8aa0-f03cdd4d5731/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ed8241ec-2a1f-4249-8aa0-f03cdd4d5731/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 13423,
        "title": "Aspen Boutique Hotels, Aspen Lodging - Hotel Jerome Gallery",
        "url": "https://pkslist.azureedge.net/media/images/1/ea3ca753-6e39-4209-84e8-108309d31164/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ea3ca753-6e39-4209-84e8-108309d31164/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ea3ca753-6e39-4209-84e8-108309d31164/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 13426,
        "title": "Hotel Jerome, An Auberge Resort: 2017 Room Prices, Deals & Reviews ...",
        "url": "https://pkslist.azureedge.net/media/images/1/bd10beca-eebe-4a18-9993-34bcd4084be5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bd10beca-eebe-4a18-9993-34bcd4084be5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bd10beca-eebe-4a18-9993-34bcd4084be5/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 13431,
        "title": "Hotel Jerome | Stay Aspen Snowmass",
        "url": "https://pkslist.azureedge.net/media/images/1/feb12909-c685-4d78-84ed-eebdc692b229/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/feb12909-c685-4d78-84ed-eebdc692b229/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/feb12909-c685-4d78-84ed-eebdc692b229/256x192.jpg",
        "viewOrder": 7
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1106,
      "latitude": 39.19087040000001,
      "longitude": -106.81950979999999,
      "parentGeographicAreaId": 0,
      "title": "330 E Main St, Aspen, CO 81611, USA",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 45,
        "tagName": "Historic Sense",
        "tagType": 1
      },
      {
        "tagId": 48,
        "tagName": "Iconic/Landmark ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 60,
        "tagName": "Gastronomic",
        "tagType": 2
      },
      {
        "tagId": 65,
        "tagName": "Shopping",
        "tagType": 2
      },
      {
        "tagId": 66,
        "tagName": "Skiing",
        "tagType": 2
      },
      {
        "tagId": 75,
        "tagName": "Casual Dining",
        "tagType": 2
      },
      {
        "tagId": 81,
        "tagName": "Golf",
        "tagType": 2
      },
      {
        "tagId": 104,
        "tagName": "Cycling",
        "tagType": 2
      },
      {
        "tagId": 109,
        "tagName": "Fishing",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 236,
        "tagName": "Dog Sledding",
        "tagType": 2
      },
      {
        "tagId": 259,
        "tagName": "Picnic",
        "tagType": 2
      },
      {
        "tagId": 281,
        "tagName": "Ice Skating",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 233,
        "venueCategoryId": 17,
        "venueId": 1282,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1030,
    "venueCategoryId": 17,
    "status": 1,
    "title": "Albreda Lodge",
    "description": "Nestled in the heart of the Monashee and Cariboo mountains - and with more than 10 metres of snowfall each year, Mike Wiegele Helicopter Skiing is renowned for unlimited vertical, pristine powder and exceptional heliski terrain and service.\n\nAs the jewel of Wiegele heliskiing terrain, Albreda Lodge complements its majestic mountain setting with sophisticated style and exceptional service. \n\nPersonal guides and exclusive helicopter service allow you to enjoy the exceptional comfort, attentive service and full amenities you have come to expect from our Blue River location – in a more exclusive and intimate setting.\nPrivate Heliski Albreda Lodge - Mike Wiegele Heliskiing Located 45 km north of Blue River, the handcrafted wooden heli-ski lodge offers stunning views of Mount Albreda.",
    "brand": null,
    "blockQuote": "It's known for offering the finest heli-skiing and heli-boarding experience but there is plenty to do in the summer too.",
    "emailAddress": "reservations@wiegele.com",
    "website": "wiegele.com/albreda-heliski-lodge.htm",
    "bookingURL": "http://reservations@wiegele.com ",
    "redirectNotes": null,
    "phoneNumber": "+1 250 673 8381",
    "addressOne": "972",
    "addressTwo": "Harrwood Drive",
    "city": "Blue River",
    "county": "",
    "region": "British Columbia",
    "country": "Canada",
    "rooms": 18,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 10628,
        "title": "Albreda Lodge | Heliskiing | British Columbia, Canada | Perfect ...",
        "url": "https://pkslist.azureedge.net/media/images/1/a72bae07-abba-4184-9417-95d6f56a21db/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a72bae07-abba-4184-9417-95d6f56a21db/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a72bae07-abba-4184-9417-95d6f56a21db/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 10302,
        "title": "Albreda | Private Heli-Skiing Lodge : - Wiegele Heliskiing",
        "url": "https://pkslist.azureedge.net/media/images/1/72b35a6d-637d-438a-b20b-659d05984e64/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/72b35a6d-637d-438a-b20b-659d05984e64/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/72b35a6d-637d-438a-b20b-659d05984e64/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 10305,
        "title": "Albreda Lodge",
        "url": "https://pkslist.azureedge.net/media/images/1/7d28078a-d8e2-42e9-876c-54095ee6b34a/1200x900.JPG",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7d28078a-d8e2-42e9-876c-54095ee6b34a/800x600.JPG",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7d28078a-d8e2-42e9-876c-54095ee6b34a/256x192.JPG",
        "viewOrder": 2
      },
      {
        "mediaId": 10624,
        "title": "Albreda Lodge | Heliskiing | British Columbia, Canada | Perfect ...",
        "url": "https://pkslist.azureedge.net/media/images/1/bcbf7bc6-2e80-4bab-93ce-c410ad0751ba/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bcbf7bc6-2e80-4bab-93ce-c410ad0751ba/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bcbf7bc6-2e80-4bab-93ce-c410ad0751ba/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 10625,
        "title": "Mike Wiegele Heliskiing Resort, Heli Boarding, Heli-Skiing, BC ...",
        "url": "https://pkslist.azureedge.net/media/images/1/454bac54-969b-4196-a623-2be0ebabb3e7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/454bac54-969b-4196-a623-2be0ebabb3e7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/454bac54-969b-4196-a623-2be0ebabb3e7/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 10307,
        "title": "Albreda Lodge",
        "url": "https://pkslist.azureedge.net/media/images/1/54001a11-da3f-4b7b-9154-88bb31b87d4c/1200x900.JPG",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/54001a11-da3f-4b7b-9154-88bb31b87d4c/800x600.JPG",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/54001a11-da3f-4b7b-9154-88bb31b87d4c/256x192.JPG",
        "viewOrder": 5
      },
      {
        "mediaId": 10626,
        "title": "5 Spectacular Alternatives to the Classic Alpine Ski Break, from ...",
        "url": "https://pkslist.azureedge.net/media/images/1/ded47a8a-d8cc-4fea-8a76-b4f400a2f459/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ded47a8a-d8cc-4fea-8a76-b4f400a2f459/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ded47a8a-d8cc-4fea-8a76-b4f400a2f459/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 10627,
        "title": "5 Spectacular Alternatives to the Classic Alpine Ski Break, from ...",
        "url": "https://pkslist.azureedge.net/media/images/1/362b1250-18f0-4bd6-b842-ddd8f584275b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/362b1250-18f0-4bd6-b842-ddd8f584275b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/362b1250-18f0-4bd6-b842-ddd8f584275b/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 10301,
        "title": "Albreda Heli-Ski Lodge Photo & Video Gallery | BC | Canada ...",
        "url": "https://pkslist.azureedge.net/media/images/1/096b73fd-39ab-408c-8a0c-4b328dacc9cf/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/096b73fd-39ab-408c-8a0c-4b328dacc9cf/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/096b73fd-39ab-408c-8a0c-4b328dacc9cf/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 859,
      "latitude": 52.11423769999999,
      "longitude": -119.30021690000001,
      "parentGeographicAreaId": 0,
      "title": "972 Harrwood Dr, Blue River, BC V0E 1J0, Canada",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 47,
        "tagName": "Intimate Luxury ",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 34,
        "tagName": "Cosy",
        "tagType": 2
      },
      {
        "tagId": 61,
        "tagName": "Mountain",
        "tagType": 2
      },
      {
        "tagId": 66,
        "tagName": "Skiing",
        "tagType": 2
      },
      {
        "tagId": 67,
        "tagName": "Sporty",
        "tagType": 2
      },
      {
        "tagId": 68,
        "tagName": "Business",
        "tagType": 2
      },
      {
        "tagId": 75,
        "tagName": "Casual Dining",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 108,
        "tagName": "Jacuzzi",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 134,
        "tagName": "Wilderness Walks",
        "tagType": 2
      },
      {
        "tagId": 156,
        "tagName": "Fireplace",
        "tagType": 2
      },
      {
        "tagId": 172,
        "tagName": "Biking",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 219,
        "tagName": "Wine Cellar",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 192,
        "venueCategoryId": 17,
        "venueId": 1030,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 973,
    "venueCategoryId": 17,
    "status": 1,
    "title": "Le K2 Altitude",
    "description": "At the heart of the Savoie Region and within the world's largest skiable area - Les 3 Vallées, with 600km of marked runs, it is the kingdom of skiing and the mountain lifestyle. Courchevel is one of the most famous and prestigious resorts in the World. Thanks to the airport located at an altitude 2016 meters, with its permanently snow-cleared surface runway and heliport, Courchevel is very easily accessible. The resort has top quality infrastructure that perfectly meets the needs of an international clientele.\n\nAt dusk when the snow-capped peaks take on magical tints and hues, discover the generous comforts of the rooms and suites, all with latest technology, spread throughout 8 chalets for the well-being and intimacy of every guest. Priceless evenings spent in generous space, with great attention to detail and in subtle shades. From the comfort of your bedroom, Room service will cater to your every desire for the finer things in life...  With spacious volumes and every attention to detail, evenings are marked by elegance in an assortment of soft shades.",
    "brand": "",
    "blockQuote": "Le 1856 bar is a great place to hang for those who want a ski break.",
    "emailAddress": "welcome@lek2altitude.com",
    "website": "lek2altitude.com/en/",
    "bookingURL": "https://www.myhotelreservation.net/b/hllw0648/HLLW0648/?f=2018-12-18&t=2018-12-19&i=96027890",
    "redirectNotes": "Le K2 Altitude's reservations system",
    "phoneNumber": "+33 4 7901 4646",
    "addressOne": "",
    "addressTwo": "",
    "city": "Courchevel",
    "county": "",
    "region": "",
    "country": "France",
    "rooms": 33,
    "dollar": 3,
    "updated": "2023-12-27T18:16:09.953",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 63317,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ee0e9e26-0eb2-4084-9993-b70de1e48293/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ee0e9e26-0eb2-4084-9993-b70de1e48293/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ee0e9e26-0eb2-4084-9993-b70de1e48293/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 63319,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ac2e6e2e-e9f8-492a-9d55-574bf3f15159/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ac2e6e2e-e9f8-492a-9d55-574bf3f15159/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ac2e6e2e-e9f8-492a-9d55-574bf3f15159/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 63325,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c06ce0c5-8407-4018-b609-0de5e22ae587/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c06ce0c5-8407-4018-b609-0de5e22ae587/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c06ce0c5-8407-4018-b609-0de5e22ae587/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 63329,
        "title": "LE K2 ALTITUDE | Luxury Courchevel Hotels, Courchevel Chalets ...",
        "url": "https://pkslist.azureedge.net/media/images/1/97132a18-c545-4980-be87-a768ba785acd/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/97132a18-c545-4980-be87-a768ba785acd/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/97132a18-c545-4980-be87-a768ba785acd/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 63330,
        "title": "Hotel Le K2 Altitude | Luxury Hotel in Courchevel 1850",
        "url": "https://pkslist.azureedge.net/media/images/1/1c57ce67-5778-46d7-a5a5-bd1e21a3caea/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1c57ce67-5778-46d7-a5a5-bd1e21a3caea/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1c57ce67-5778-46d7-a5a5-bd1e21a3caea/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 63331,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/4867837d-de07-4bf5-970e-84886ed76154/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4867837d-de07-4bf5-970e-84886ed76154/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4867837d-de07-4bf5-970e-84886ed76154/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 63321,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/dd9b817d-357b-4488-947c-f2f8a082003b/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/dd9b817d-357b-4488-947c-f2f8a082003b/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/dd9b817d-357b-4488-947c-f2f8a082003b/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 63318,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ce0c2f55-94bf-4dc2-ac0d-77fcbc95708e/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ce0c2f55-94bf-4dc2-ac0d-77fcbc95708e/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ce0c2f55-94bf-4dc2-ac0d-77fcbc95708e/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 63326,
        "title": "Hotel Le K2 Altitude | Luxury Hotel in Courchevel 1850",
        "url": "https://pkslist.azureedge.net/media/images/1/93760bbe-b6b0-4705-97d4-561810c1010d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/93760bbe-b6b0-4705-97d4-561810c1010d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/93760bbe-b6b0-4705-97d4-561810c1010d/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 801,
      "latitude": 45.402805,
      "longitude": 6.637962000000016,
      "parentGeographicAreaId": 0,
      "title": "356 Route de l'Altiport, 73120 Courchevel, France",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 66,
        "tagName": "Skiing",
        "tagType": 2
      },
      {
        "tagId": 83,
        "tagName": "Kid's Club",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 101,
        "tagName": "Cinema",
        "tagType": 2
      },
      {
        "tagId": 127,
        "tagName": "Hair Salon",
        "tagType": 2
      },
      {
        "tagId": 222,
        "tagName": "Michelin Star dining",
        "tagType": 2
      },
      {
        "tagId": 281,
        "tagName": "Ice Skating",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 326,
        "tagName": "Sledding",
        "tagType": 2
      },
      {
        "tagId": 340,
        "tagName": "Snow Shoeing",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 176,
        "venueCategoryId": 17,
        "venueId": 973,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 972,
    "venueCategoryId": 20,
    "status": 1,
    "title": "Le K2 Palace",
    "description": "A concept inspired by a land of legendary peaks where truth is found in wisdom, K2 originated in the desire to meet a challenge and realize a dream. Located close to the centre of the resort and the croisette, the hotel features 29 rooms and 5 suite-chalets of exceptional quality. Like a prestigious hamlet, K2 stretches along of the Cospillot slope, offering guests an incomparable panorama of the valley and mountain massifs. The individual ski lift in front of the hotel provides access to the world’s largest ski area.With spacious rooms and suites, exceptional chalets, a 550 m² spa, two restaurants, smoking room, lounge bar, two leisure areas for children and teenagers, cinema room, night club, skishop, gym and hairdressing salon, K2 offers innovative services with a personal touch.",
    "brand": "",
    "blockQuote": "A very unique venue; Le K'baret can be converted into a cinema, theatre or nightclub worth checking out for your next event.",
    "emailAddress": "welcome@lek2palace.com",
    "website": "lek2palace.com/en/",
    "bookingURL": "https://be.synxis.com/?_ga=2.87993691.2102037658.1691737629-1231300605.1691602480&adult=1&agencyid=96027890&arrive=2023-12-28&chain=24447&child=0&currency=EUR&depart=2023-12-29&hotel=6680&level=hotel&locale=en-US&rooms=1&src=30",
    "redirectNotes": "Le K2 Palace's reservations system",
    "phoneNumber": "+33 4 79 40 08 80",
    "addressOne": "",
    "addressTwo": "Rue des Clarines",
    "city": "Courchevel",
    "county": "",
    "region": "",
    "country": "France",
    "rooms": 34,
    "dollar": 3,
    "updated": "2023-12-28T06:28:46.1",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 63335,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/cfa795fc-d3d3-4277-ac55-1a2ce2294d5b/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cfa795fc-d3d3-4277-ac55-1a2ce2294d5b/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cfa795fc-d3d3-4277-ac55-1a2ce2294d5b/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 9469,
        "title": "Hotel Le K2, Courchevel 1850 • Alpine Guru",
        "url": "https://pkslist.azureedge.net/media/images/1/9c928bc3-d232-4ad8-b589-c8d685bad8b9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9c928bc3-d232-4ad8-b589-c8d685bad8b9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9c928bc3-d232-4ad8-b589-c8d685bad8b9/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 63343,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/7f0e1efa-f735-4a4e-a1e7-42ba3825e9d5/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7f0e1efa-f735-4a4e-a1e7-42ba3825e9d5/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7f0e1efa-f735-4a4e-a1e7-42ba3825e9d5/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 63341,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/4926c3f5-e2d4-4831-9277-19abca5cf476/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4926c3f5-e2d4-4831-9277-19abca5cf476/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4926c3f5-e2d4-4831-9277-19abca5cf476/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 63350,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/77842645-a282-42bf-ad3a-a669993d6a4a/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/77842645-a282-42bf-ad3a-a669993d6a4a/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/77842645-a282-42bf-ad3a-a669993d6a4a/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 63344,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/5bc285ea-89b2-446e-86b7-e9e30d6344d4/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5bc285ea-89b2-446e-86b7-e9e30d6344d4/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5bc285ea-89b2-446e-86b7-e9e30d6344d4/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 63336,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/2899b385-dde8-477f-a1a8-6b0ef7f48836/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2899b385-dde8-477f-a1a8-6b0ef7f48836/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2899b385-dde8-477f-a1a8-6b0ef7f48836/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 63352,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c15d9d50-5ad3-4bfd-80dd-c8dfd215a63a/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c15d9d50-5ad3-4bfd-80dd-c8dfd215a63a/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c15d9d50-5ad3-4bfd-80dd-c8dfd215a63a/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 9459,
        "title": "Hôtel Le K2***** hôtel de luxe Courchevel 1850 - Luxe & Passions",
        "url": "https://pkslist.azureedge.net/media/images/1/4d8416c7-b84e-42ea-81eb-d2f4d5a73b1f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4d8416c7-b84e-42ea-81eb-d2f4d5a73b1f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4d8416c7-b84e-42ea-81eb-d2f4d5a73b1f/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 63348,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/db342787-c9ed-426a-ae2c-899020693d97/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/db342787-c9ed-426a-ae2c-899020693d97/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/db342787-c9ed-426a-ae2c-899020693d97/256x192.jpeg",
        "viewOrder": 11
      },
      {
        "mediaId": 9462,
        "title": "Le K2 Palace Hotel, Courchevel | Courchnet.com",
        "url": "https://pkslist.azureedge.net/media/images/1/7dbfe7e6-75cf-4055-ab36-f1fdff77f5bd/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7dbfe7e6-75cf-4055-ab36-f1fdff77f5bd/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7dbfe7e6-75cf-4055-ab36-f1fdff77f5bd/256x192.jpg",
        "viewOrder": 12
      },
      {
        "mediaId": 63339,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c8e5c55f-0151-4fe7-aec3-c9dd920c40db/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c8e5c55f-0151-4fe7-aec3-c9dd920c40db/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c8e5c55f-0151-4fe7-aec3-c9dd920c40db/256x192.jpeg",
        "viewOrder": 14
      },
      {
        "mediaId": 63346,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/7d0c547b-e311-4128-b5da-f4b2e99b2afb/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7d0c547b-e311-4128-b5da-f4b2e99b2afb/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7d0c547b-e311-4128-b5da-f4b2e99b2afb/256x192.jpeg",
        "viewOrder": 15
      }
    ],
    "geographicArea": {
      "geographicAreaId": 800,
      "latitude": 45.41219879999999,
      "longitude": 6.638829299999998,
      "parentGeographicAreaId": 0,
      "title": "Rue des Clarines, 73120 Saint-Bon-Tarentaise, France",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 66,
        "tagName": "Skiing",
        "tagType": 2
      },
      {
        "tagId": 83,
        "tagName": "Kid's Club",
        "tagType": 2
      },
      {
        "tagId": 84,
        "tagName": "Nightclub",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 101,
        "tagName": "Cinema",
        "tagType": 2
      },
      {
        "tagId": 127,
        "tagName": "Hair Salon",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 222,
        "tagName": "Michelin Star dining",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 294,
        "tagName": "Event Space",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 175,
        "venueCategoryId": 20,
        "venueId": 972,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 20,
          "title": "BucketList Hotels",
          "region": "Global",
          "viewOrder": 0,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 336,
        "venueCategoryId": 17,
        "venueId": 972,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 967,
    "venueCategoryId": 17,
    "status": 1,
    "title": "Terminal Neige Refuge du Montenvers",
    "description": "Perched on the Mont-Blanc to 6725 feet above sea level, with a view overlooking the Mer de Glace and mythical Aiguille des Drus, the Montenvers Refuge is a place of history and authenticity. In the evening, after the departure of the last train and the final day time visitors, the setting comes alive with the colours of the evening sunset in a delightful atmosphere of calm and serenity. You are alone in the world taking in this unique panorama!",
    "brand": null,
    "blockQuote": "A high mountain refuge with historical charm and breathtaking views.",
    "emailAddress": "contact@tn-refuge.com",
    "website": "montenvers.terminal-neige.com/en/#!chamonix-hotel/mont-blanc-montenvers",
    "bookingURL": "http://www.booking.com/Share-FPv2wr?aid=925669",
    "redirectNotes": null,
    "phoneNumber": "+33 4 50 53 87 70",
    "addressOne": "",
    "addressTwo": "Le Montenvers",
    "city": "Chamonix",
    "county": "",
    "region": "",
    "country": "France",
    "rooms": 20,
    "dollar": 1,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 9495,
        "title": "Terminal Neige Refuge du Montenvers | La Mer de Glace | Refuge d ...",
        "url": "https://pkslist.azureedge.net/media/images/1/7602f194-db6f-4025-af2f-b0b3761a9cf4/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7602f194-db6f-4025-af2f-b0b3761a9cf4/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7602f194-db6f-4025-af2f-b0b3761a9cf4/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 26754,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e5704068-e427-41b1-b2ae-89ffdb2e6912/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e5704068-e427-41b1-b2ae-89ffdb2e6912/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e5704068-e427-41b1-b2ae-89ffdb2e6912/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 26755,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/3afc6b27-e5bd-48bf-bbd7-a4665ada9167/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3afc6b27-e5bd-48bf-bbd7-a4665ada9167/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3afc6b27-e5bd-48bf-bbd7-a4665ada9167/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 26757,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/16239b04-27c6-4e6f-8648-300e8c483cf6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/16239b04-27c6-4e6f-8648-300e8c483cf6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/16239b04-27c6-4e6f-8648-300e8c483cf6/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 26756,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ecd2f109-18d2-40e8-b429-27080bfc7e77/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ecd2f109-18d2-40e8-b429-27080bfc7e77/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ecd2f109-18d2-40e8-b429-27080bfc7e77/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 26753,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/db3b4ae3-749d-4c3a-ba62-82b27632dc13/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/db3b4ae3-749d-4c3a-ba62-82b27632dc13/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/db3b4ae3-749d-4c3a-ba62-82b27632dc13/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 9499,
        "title": "Le Terminal Neige ou la renaissance du site du Montenvers ...",
        "url": "https://pkslist.azureedge.net/media/images/1/86fc6426-69bc-4c63-98a9-face03ce05e2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/86fc6426-69bc-4c63-98a9-face03ce05e2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/86fc6426-69bc-4c63-98a9-face03ce05e2/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 9496,
        "title": "Terminal Neige Refuge du Montenvers | La Mer de Glace | Refuge d ...",
        "url": "https://pkslist.azureedge.net/media/images/1/b126e236-977e-4bd3-ba99-cd4948649a88/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b126e236-977e-4bd3-ba99-cd4948649a88/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b126e236-977e-4bd3-ba99-cd4948649a88/256x192.jpg",
        "viewOrder": 7
      }
    ],
    "geographicArea": {
      "geographicAreaId": 794,
      "latitude": 45.929731,
      "longitude": 6.918393000000037,
      "parentGeographicAreaId": 0,
      "title": "Le Montenvers, 74400 Chamonix-Mont-Blanc, France",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 61,
        "tagName": "Mountain",
        "tagType": 2
      },
      {
        "tagId": 69,
        "tagName": "Couples",
        "tagType": 2
      },
      {
        "tagId": 70,
        "tagName": "Families",
        "tagType": 2
      },
      {
        "tagId": 75,
        "tagName": "Casual Dining",
        "tagType": 2
      },
      {
        "tagId": 85,
        "tagName": "Pet Friendly",
        "tagType": 2
      },
      {
        "tagId": 100,
        "tagName": "Wedding",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 156,
        "tagName": "Fireplace",
        "tagType": 2
      },
      {
        "tagId": 193,
        "tagName": "Outdoor Dining",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 174,
        "venueCategoryId": 17,
        "venueId": 967,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 953,
    "venueCategoryId": 17,
    "status": 1,
    "title": "The Little Nell",
    "description": "Aspen at our front door, Aspen Mountain at our back. We’re proud to be Aspen’s only luxury boutique hotel, with 5 Stars and 5 Diamonds, and exclusive ski-in, ski-out access to Aspen Mountain. From our kitchens to our cellars, with their 20,000 bottles of wine, from the Concierges in our lobby to the Concierges on our mountains, and from family adventure to après ski, this is, guests have said, “the Aspen of Aspen.”\n\nChicago designer and Aspen resident Holly Hunt styled our 52 guest rooms, 26 premium guest rooms, 8 suites and 6 signature suites. Contemporary works of art offset her “Aspen Modern” plush, textured fabrics and palettes of cream, blue and chocolate. All accommodations feature a unique floor plan and most have private balconies.",
    "brand": null,
    "blockQuote": "The IT place to see and be seen for Apres Ski in Aspen, this is where it all happens.",
    "emailAddress": "insiderinfo@thelittlenell.com",
    "website": "thelittlenell.com",
    "bookingURL": "http://www.booking.com/Share-edWez2?aid=925669",
    "redirectNotes": null,
    "phoneNumber": "+1 855 920 4600",
    "addressOne": "675",
    "addressTwo": "East Durant Avenue",
    "city": "Aspen",
    "county": "",
    "region": "Colorado",
    "country": "United States",
    "rooms": 92,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 31468,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/59a50ca6-029e-4b31-9dd6-b78dc5f71098/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/59a50ca6-029e-4b31-9dd6-b78dc5f71098/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/59a50ca6-029e-4b31-9dd6-b78dc5f71098/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 31469,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/4ef3c1bc-5e5a-4e45-afcd-9a4df1cf99b2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4ef3c1bc-5e5a-4e45-afcd-9a4df1cf99b2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4ef3c1bc-5e5a-4e45-afcd-9a4df1cf99b2/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 9737,
        "title": "The Little Nell, Aspen, Colorado - Hotel Review & Photos",
        "url": "https://pkslist.azureedge.net/media/images/1/8b48a33f-f93c-4dc7-82f9-a816db60a015/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8b48a33f-f93c-4dc7-82f9-a816db60a015/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8b48a33f-f93c-4dc7-82f9-a816db60a015/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 9745,
        "title": "The Little Nell | Luxury Hotel in Aspen Colorado",
        "url": "https://pkslist.azureedge.net/media/images/1/75c1d649-1450-4290-b3a8-ae2cc95ff6aa/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/75c1d649-1450-4290-b3a8-ae2cc95ff6aa/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/75c1d649-1450-4290-b3a8-ae2cc95ff6aa/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 9746,
        "title": "The Little Nell | Luxury Hotel in Aspen Colorado",
        "url": "https://pkslist.azureedge.net/media/images/1/4f7d1ce3-31de-4703-b93e-5d269cd9c572/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4f7d1ce3-31de-4703-b93e-5d269cd9c572/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4f7d1ce3-31de-4703-b93e-5d269cd9c572/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 31467,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b0c756ea-8008-4cca-a1fd-e5f53a45cc7d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b0c756ea-8008-4cca-a1fd-e5f53a45cc7d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b0c756ea-8008-4cca-a1fd-e5f53a45cc7d/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 31466,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/27a5590b-8504-484a-a9e3-f191e30aa75d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/27a5590b-8504-484a-a9e3-f191e30aa75d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/27a5590b-8504-484a-a9e3-f191e30aa75d/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 9116,
        "title": "The Little Nell, Aspen, Colorado - Hotel Review & Photos",
        "url": "https://pkslist.azureedge.net/media/images/1/828156f5-dfc7-49ae-8460-71a6685d48ea/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/828156f5-dfc7-49ae-8460-71a6685d48ea/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/828156f5-dfc7-49ae-8460-71a6685d48ea/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 9739,
        "title": "The Little Nell - Aspen, CO, USA The only... | Luxury Accommodations",
        "url": "https://pkslist.azureedge.net/media/images/1/b1af1ab6-8796-495f-822a-bc66d80d32b6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b1af1ab6-8796-495f-822a-bc66d80d32b6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b1af1ab6-8796-495f-822a-bc66d80d32b6/256x192.jpg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 780,
      "latitude": 39.1863817,
      "longitude": -106.8174229,
      "parentGeographicAreaId": 0,
      "title": "675 E Durant Ave, Aspen, CO 81611, USA",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 47,
        "tagName": "Intimate Luxury ",
        "tagType": 1
      },
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 61,
        "tagName": "Mountain",
        "tagType": 2
      },
      {
        "tagId": 66,
        "tagName": "Skiing",
        "tagType": 2
      },
      {
        "tagId": 70,
        "tagName": "Families",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 85,
        "tagName": "Pet Friendly",
        "tagType": 2
      },
      {
        "tagId": 100,
        "tagName": "Wedding",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 109,
        "tagName": "Fishing",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 156,
        "tagName": "Fireplace",
        "tagType": 2
      },
      {
        "tagId": 193,
        "tagName": "Outdoor Dining",
        "tagType": 2
      },
      {
        "tagId": 198,
        "tagName": "Boat Excursion",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 169,
        "venueCategoryId": 17,
        "venueId": 953,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 682,
    "venueCategoryId": 17,
    "status": 1,
    "title": "Gstaad Palace",
    "description": "Over the years the Gstaad Palace has earned an unrivaled reputation for discreet, refined hospitality and has always welcomed the most discerning international clientele. Set on a hill overlooking Gstaad, the resort is privileged to offer a breathtaking view of the majestic Swiss Alps. \n\n100 stylishly decorated rooms and suites await you at the hotel. Whether it’s a single room or the spectacular Penthouse suite with three bedrooms – only superb quality materials have been used everywhere. They are all equipped with the latest technical requirements too. Enjoy the breathtaking view over the Swiss Alps. From the Walig Hut, which is situated 1,700 metres above Gsteig, you have an impressive view over Gstaad and the Saanenland region.",
    "brand": null,
    "blockQuote": "There's so much to do, come summer or winter, by yourself or with family, just come.",
    "emailAddress": "info@palace.ch",
    "website": "palace.ch/en/",
    "bookingURL": "https://www.myhotelreservation.net/b/hllw1606/HLLW1606/?f=2019-01-09&t=2019-01-10&lang=en_US&vri=-1&_ga=2.48117159.1692983844.1546910144-679816191.1546910144&i=96027890",
    "redirectNotes": "Gstaad Palace's reservations system",
    "phoneNumber": "+41 33 748 50 00",
    "addressOne": "28",
    "addressTwo": "Palacestrasse",
    "city": "Gstaad",
    "county": "",
    "region": "",
    "country": "Switzerland",
    "rooms": 100,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 7110,
        "title": "Why Hollywood Stars Have Flocked to Switzerland's Gstaad Palace ...",
        "url": "https://pkslist.azureedge.net/media/images/1/aba0898c-13a1-4f4d-826e-0d158764b785/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/aba0898c-13a1-4f4d-826e-0d158764b785/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/aba0898c-13a1-4f4d-826e-0d158764b785/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 6124,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/4a1cc5c3-d707-4497-9ed4-20c2027114ec/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4a1cc5c3-d707-4497-9ed4-20c2027114ec/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4a1cc5c3-d707-4497-9ed4-20c2027114ec/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 6128,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ebe27d93-131c-4b8c-8f5c-48784330efc3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ebe27d93-131c-4b8c-8f5c-48784330efc3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ebe27d93-131c-4b8c-8f5c-48784330efc3/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 6125,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/7d20dca8-0cac-4085-aa88-8b4218ae19e4/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7d20dca8-0cac-4085-aa88-8b4218ae19e4/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7d20dca8-0cac-4085-aa88-8b4218ae19e4/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 6126,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/0efc85ae-fa00-49f2-b178-89de76a6cb93/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0efc85ae-fa00-49f2-b178-89de76a6cb93/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0efc85ae-fa00-49f2-b178-89de76a6cb93/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 6127,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f9e65656-8979-4ffc-b086-8505de408e2d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f9e65656-8979-4ffc-b086-8505de408e2d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f9e65656-8979-4ffc-b086-8505de408e2d/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 6131,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/352a26df-3dcc-4bc8-b302-0c9ec788376e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/352a26df-3dcc-4bc8-b302-0c9ec788376e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/352a26df-3dcc-4bc8-b302-0c9ec788376e/256x192.jpg",
        "viewOrder": 7
      }
    ],
    "geographicArea": {
      "geographicAreaId": 519,
      "latitude": 46.4731083,
      "longitude": 7.2894615999999814,
      "parentGeographicAreaId": 0,
      "title": "Palacestrasse 28, 3780 Gstaad, Switzerland",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 45,
        "tagName": "Historic Sense",
        "tagType": 1
      },
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 31,
        "tagName": "Countryside",
        "tagType": 2
      },
      {
        "tagId": 61,
        "tagName": "Mountain",
        "tagType": 2
      },
      {
        "tagId": 66,
        "tagName": "Skiing",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 88,
        "tagName": "Tennis",
        "tagType": 2
      },
      {
        "tagId": 108,
        "tagName": "Jacuzzi",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 159,
        "tagName": "Mountain Biking",
        "tagType": 2
      },
      {
        "tagId": 169,
        "tagName": "Resort",
        "tagType": 2
      },
      {
        "tagId": 203,
        "tagName": "Sauna",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 119,
        "venueCategoryId": 17,
        "venueId": 682,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 554,
    "venueCategoryId": 17,
    "status": 1,
    "title": "Aman Le Melezin",
    "description": "Aman Le Mélézin is set directly on the Bellecôte piste, perched 1,850 metres above sea level in Courchevel 1850, in the heart of Les Trois Vallées. Ski beneath snow-dusted fir trees and enjoy the village’s convivial après-ski. Topped by a slate roof and amid a wonderland of groomed pistes, Aman Le Mélézin’s 31 Chambres and Suites are decorated in an Alps-inspired palette",
    "brand": "Amanresorts",
    "blockQuote": "Get cozy by the library’s inviting fireplace, the perfect spot for a pre-dinner drink.",
    "emailAddress": "lemelezin@aman.com",
    "website": "aman.com/resorts/aman-le-melezin",
    "bookingURL": "http://gc.synxis.com/rez.aspx?Chain=16840&Hotel=62479&Lang=1&template=RBE&shell=RBE2&iata=96027890",
    "redirectNotes": "Aman Le Melezin's reservations system",
    "phoneNumber": "+33 4 7908 0133 ",
    "addressOne": "",
    "addressTwo": "Rue de Bellecôte",
    "city": "Courchevel",
    "county": "",
    "region": "",
    "country": "France",
    "rooms": 31,
    "dollar": 3,
    "updated": "2020-05-13T09:29:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 4296,
        "title": "Aman Le Mélézin, Rhône-Alpes, France",
        "url": "https://pkslist.azureedge.net/media/images/1/a4dffc14-179f-4bc5-9404-9534e07f9218/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a4dffc14-179f-4bc5-9404-9534e07f9218/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a4dffc14-179f-4bc5-9404-9534e07f9218/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 4291,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a25ec3e2-25ad-4c20-a13a-d65a64736762/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a25ec3e2-25ad-4c20-a13a-d65a64736762/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a25ec3e2-25ad-4c20-a13a-d65a64736762/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 4293,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/743567c7-0bdc-4761-9c8f-3cd52a130fa6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/743567c7-0bdc-4761-9c8f-3cd52a130fa6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/743567c7-0bdc-4761-9c8f-3cd52a130fa6/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 4299,
        "title": "Bathroom at Aman Le Melezin - Picture of Aman Le Melezin ...",
        "url": "https://pkslist.azureedge.net/media/images/1/60f34249-3842-432e-a06d-076aff8fe8bd/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/60f34249-3842-432e-a06d-076aff8fe8bd/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/60f34249-3842-432e-a06d-076aff8fe8bd/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 4288,
        "title": "Guest room with a view at at Aman Le Melezin, a ski-in/ski-out ...",
        "url": "https://pkslist.azureedge.net/media/images/1/5063f09a-ef53-41e1-976e-52001434f533/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5063f09a-ef53-41e1-976e-52001434f533/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5063f09a-ef53-41e1-976e-52001434f533/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 4294,
        "title": "Aman Le Melezin, Courchevel : Five Star Alliance",
        "url": "https://pkslist.azureedge.net/media/images/1/f9c63db8-9ce5-4441-8317-99fe27a55a1b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f9c63db8-9ce5-4441-8317-99fe27a55a1b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f9c63db8-9ce5-4441-8317-99fe27a55a1b/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 4295,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/4cab2a37-cb02-4b0b-9a97-71d26a7545ec/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4cab2a37-cb02-4b0b-9a97-71d26a7545ec/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4cab2a37-cb02-4b0b-9a97-71d26a7545ec/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 4297,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c39fe1b5-38b1-40ac-b97d-256f9742cdec/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c39fe1b5-38b1-40ac-b97d-256f9742cdec/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c39fe1b5-38b1-40ac-b97d-256f9742cdec/256x192.jpg",
        "viewOrder": 7
      }
    ],
    "geographicArea": {
      "geographicAreaId": 391,
      "latitude": 45.412978,
      "longitude": 6.636103999999932,
      "parentGeographicAreaId": 0,
      "title": "Rue de Bellecôte, 73120 Saint-Bon-Tarentaise, France",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 47,
        "tagName": "Intimate Luxury ",
        "tagType": 1
      },
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 34,
        "tagName": "Cosy",
        "tagType": 2
      },
      {
        "tagId": 61,
        "tagName": "Mountain",
        "tagType": 2
      },
      {
        "tagId": 66,
        "tagName": "Skiing",
        "tagType": 2
      },
      {
        "tagId": 67,
        "tagName": "Sporty",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 108,
        "tagName": "Jacuzzi",
        "tagType": 2
      },
      {
        "tagId": 131,
        "tagName": "Library",
        "tagType": 2
      },
      {
        "tagId": 156,
        "tagName": "Fireplace",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 104,
        "venueCategoryId": 17,
        "venueId": 554,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  }
]
// 20240123100509
// https://app.pks-list.com/api/Venue/v1/getlist/35

export const placesStore34=
[
  {
    "venueId": 3920,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Shinta Mani Mustang - A Bensely Collection",
    "description": "An unforgettable journey of cultural adventure and spiritual growth with a breathtaking backdrop. Our 29 suites have floor to ceiling windows with staggering views of the Nilgiri range opposite. Impeccably decorated by Bill Bensley, with all the modern comforts you’d expect from Shinta Mani. The aesthetic draws from local Tibetan art and imagery with flashes of bold colour. Designer blankets, Tibetan rugs handmade in Nepal and stylish wooden furnishings create a combination of timeless design and luxuriant comfort.",
    "brand": "",
    "blockQuote": "For those who want to experience the unique landscape and culture of Mustang, known as The Last Forbidden Kingdom in the Himalayas.",
    "emailAddress": "info@shintamanimustang.com",
    "website": "shintamani.com/mustang/",
    "bookingURL": "https://shintamani.com/mustang/book-now/",
    "redirectNotes": "Shinta Mani Mustang's reservations system",
    "phoneNumber": "+977 980 233 63 86",
    "addressOne": "",
    "addressTwo": "",
    "city": "Jomsom",
    "county": "",
    "region": "",
    "country": "Nepal",
    "rooms": 29,
    "dollar": 3,
    "updated": "2024-01-16T13:53:48.41",
    "created": "2024-01-16T12:49:58.98",
    "medias": [
      {
        "mediaId": 64449,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f1470d1e-c627-48c7-9ad4-966e6aeb406b/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f1470d1e-c627-48c7-9ad4-966e6aeb406b/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f1470d1e-c627-48c7-9ad4-966e6aeb406b/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 64446,
        "title": "In-Depth Review of Shinta Mani Mustang in Nepal - AFAR",
        "url": "https://pkslist.azureedge.net/media/images/1/a0d482fd-c7d5-4918-b924-42b8ade247bc/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a0d482fd-c7d5-4918-b924-42b8ade247bc/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a0d482fd-c7d5-4918-b924-42b8ade247bc/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 64434,
        "title": "202211281817365nc12ifz.y03.jpg",
        "url": "https://pkslist.azureedge.net/media/images/1/738deb26-1d82-49e7-a9fb-a4602c0bba78/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/738deb26-1d82-49e7-a9fb-a4602c0bba78/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/738deb26-1d82-49e7-a9fb-a4602c0bba78/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 64426,
        "title": "Shinta Mani Mustang – a Bensley Collection hotel review | CN Traveller",
        "url": "https://pkslist.azureedge.net/media/images/1/af2a65a7-5069-488e-8e12-36b70fbc0400/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/af2a65a7-5069-488e-8e12-36b70fbc0400/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/af2a65a7-5069-488e-8e12-36b70fbc0400/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 64425,
        "title": "Shinta Mani Mustang – A Bensley Collection to open in 2023 - Sleeper",
        "url": "https://pkslist.azureedge.net/media/images/1/3f279523-40b1-47b3-92bc-031df5764039/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3f279523-40b1-47b3-92bc-031df5764039/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3f279523-40b1-47b3-92bc-031df5764039/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 64428,
        "title": "Shinta Mani Mustang – A Bensley Collection – BENSLEY",
        "url": "https://pkslist.azureedge.net/media/images/1/8eb9d458-7345-42c0-8bb4-2c822dc63ca6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8eb9d458-7345-42c0-8bb4-2c822dc63ca6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8eb9d458-7345-42c0-8bb4-2c822dc63ca6/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 64436,
        "title": "Mustang | Shinta Mani Mustang a Bensley Collection",
        "url": "https://pkslist.azureedge.net/media/images/1/8680db06-1a1c-41b1-bebe-98a85669d6f8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8680db06-1a1c-41b1-bebe-98a85669d6f8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8680db06-1a1c-41b1-bebe-98a85669d6f8/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 64435,
        "title": "BENSLEY – Architecture . Interior Design . Landscape",
        "url": "https://pkslist.azureedge.net/media/images/1/a0c50599-fd5d-4bc3-959c-bbe0df6d87dc/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a0c50599-fd5d-4bc3-959c-bbe0df6d87dc/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a0c50599-fd5d-4bc3-959c-bbe0df6d87dc/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 64438,
        "title": "Bill Bensley designs Himalayan retreat - Sleeper",
        "url": "https://pkslist.azureedge.net/media/images/1/35ab41fd-3be6-422b-ad29-76f16e22147b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/35ab41fd-3be6-422b-ad29-76f16e22147b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/35ab41fd-3be6-422b-ad29-76f16e22147b/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3702,
      "latitude": 28.78122879999999,
      "longitude": 83.71717489999999,
      "parentGeographicAreaId": 0,
      "title": "Marpha-5, जोमसोम 33100, Nepal",
      "updated": null,
      "created": "2024-01-16T13:36:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 59,
        "tagName": "Cultural",
        "tagType": 2
      },
      {
        "tagId": 82,
        "tagName": "Horseback Riding",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 124,
        "tagName": "Helicopter",
        "tagType": 2
      },
      {
        "tagId": 159,
        "tagName": "Mountain Biking",
        "tagType": 2
      },
      {
        "tagId": 273,
        "tagName": "Archery",
        "tagType": 2
      },
      {
        "tagId": 296,
        "tagName": "Guided Walk",
        "tagType": 2
      },
      {
        "tagId": 299,
        "tagName": "Wellness Programs",
        "tagType": 2
      },
      {
        "tagId": 319,
        "tagName": "Temples",
        "tagType": 2
      },
      {
        "tagId": 324,
        "tagName": "Local Village",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 865,
        "venueCategoryId": 34,
        "venueId": 3920,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3836,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Six Senses Southern Dunes, The Red Sea",
    "description": "We invite you on an exciting journey into seldom-experienced nature, wildlife, and culture. Located along the historic incense trade route against the backdrop of desert plains and the Hijaz Mountains, Six Senses Southern Dunes, The Red Sea resort pays homage to Nabataean architectural heritage and majestic desert surroundings while introducing our trademark crafted experiences and wellness approach.",
    "brand": "Six Senses",
    "blockQuote": "A visit to the Six Senses Spa will be a highlight of your stay.",
    "emailAddress": "reservations-southerndunes@sixsenses.com",
    "website": "sixsenses.com/en/resorts/southern-dunes-the-red-sea",
    "bookingURL": "https://www.sixsenses.com/en/resorts/southern-dunes-the-red-sea/accomodation-results?newSearch=1&src=&Hotel=RSISD&Rooms=1&Adult=2&Child=0&iata=96027890",
    "redirectNotes": "Six Senses Southern Dunes, The Red Sea's reservations system",
    "phoneNumber": "+66 2631 9777",
    "addressOne": "",
    "addressTwo": "",
    "city": "Umluj",
    "county": "",
    "region": "",
    "country": "Saudi Arabia",
    "rooms": 76,
    "dollar": 3,
    "updated": "2023-12-21T15:05:10.03",
    "created": "2023-12-19T08:57:56.82",
    "medias": [
      {
        "mediaId": 62402,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/1edf03d5-5c74-4c31-b7d3-b1840be11fb0/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1edf03d5-5c74-4c31-b7d3-b1840be11fb0/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1edf03d5-5c74-4c31-b7d3-b1840be11fb0/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 62410,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a5540b2a-6023-4372-aac2-7d0257348c95/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a5540b2a-6023-4372-aac2-7d0257348c95/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a5540b2a-6023-4372-aac2-7d0257348c95/256x192.png",
        "viewOrder": 1
      },
      {
        "mediaId": 62412,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/497c9230-b757-4c90-9330-67e3c9bc83d6/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/497c9230-b757-4c90-9330-67e3c9bc83d6/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/497c9230-b757-4c90-9330-67e3c9bc83d6/256x192.png",
        "viewOrder": 2
      },
      {
        "mediaId": 62404,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/06c7ea58-08bd-4d3f-930b-4f9d159c0130/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/06c7ea58-08bd-4d3f-930b-4f9d159c0130/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/06c7ea58-08bd-4d3f-930b-4f9d159c0130/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 62406,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c5366922-80b3-44a1-8082-e54c90cb438b/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c5366922-80b3-44a1-8082-e54c90cb438b/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c5366922-80b3-44a1-8082-e54c90cb438b/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 62411,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/3169d002-bf3f-4900-b526-b5e80b6c77e4/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3169d002-bf3f-4900-b526-b5e80b6c77e4/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3169d002-bf3f-4900-b526-b5e80b6c77e4/256x192.png",
        "viewOrder": 7
      },
      {
        "mediaId": 62403,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/150ccf7b-acad-48d4-a97b-51c31859d03f/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/150ccf7b-acad-48d4-a97b-51c31859d03f/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/150ccf7b-acad-48d4-a97b-51c31859d03f/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 62407,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/4de7b9af-0e16-4385-b9c0-ec8d19676236/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4de7b9af-0e16-4385-b9c0-ec8d19676236/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4de7b9af-0e16-4385-b9c0-ec8d19676236/256x192.jpeg",
        "viewOrder": 9
      },
      {
        "mediaId": 62413,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d9de9664-8970-47d0-a3fb-ca353100b5a9/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d9de9664-8970-47d0-a3fb-ca353100b5a9/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d9de9664-8970-47d0-a3fb-ca353100b5a9/256x192.png",
        "viewOrder": 10
      },
      {
        "mediaId": 62408,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/305bf8e8-a783-4d86-ab99-9a70982f2420/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/305bf8e8-a783-4d86-ab99-9a70982f2420/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/305bf8e8-a783-4d86-ab99-9a70982f2420/256x192.png",
        "viewOrder": 11
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3590,
      "latitude": 25.3788588,
      "longitude": 37.330808,
      "parentGeographicAreaId": 0,
      "title": "The Red Sea Development, Umluj 48321, Saudi Arabia",
      "updated": null,
      "created": "2023-12-21T15:05:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 77,
        "tagName": "Cooking Classes",
        "tagType": 2
      },
      {
        "tagId": 162,
        "tagName": "Desert",
        "tagType": 2
      },
      {
        "tagId": 263,
        "tagName": "Star Gazing",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 299,
        "tagName": "Wellness Programs",
        "tagType": 2
      },
      {
        "tagId": 324,
        "tagName": "Local Village",
        "tagType": 2
      },
      {
        "tagId": 410,
        "tagName": "Sundownders",
        "tagType": 2
      },
      {
        "tagId": 457,
        "tagName": "Outdoor Cinema",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 851,
        "venueCategoryId": 35,
        "venueId": 3836,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 852,
        "venueCategoryId": 34,
        "venueId": 3836,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3744,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Royal Davui Island Resort Fiji",
    "description": "Royal Davui Island Resort Fiji is an exclusive, adults-only island sanctuary situated in Fiji’s breathtaking Beqa Lagoon. Here, we offer a level of Fijian barefoot luxury, elegance, and privacy that cannot be found in larger resorts.\n\nWhite sand beaches, turquoise waters, and tall palms silhouetted against the setting sun – above all, you’ll feel a million miles away from the hustle of modern living in this unhurried and stress-free environment.\n\nAs the premier adults-only island in Fiji, we are the ideal tropical island escape for an island vacation, honeymoon, or destination wedding.",
    "brand": "",
    "blockQuote": "Book the Sunset Private Plunge Pool Villa to catch the last rays.",
    "emailAddress": "res@royaldavuifiji.com",
    "website": "royaldavuifiji.com/",
    "bookingURL": "https://book-directonline.com/properties/royaldavuidirect?locale=en&items[0][adults]=2&items[0][children]=0&items[0][infants]=0&currency=FJD&checkInDate=2023-10-13&checkOutDate=2023-10-16&trackPage=yes",
    "redirectNotes": "Royal Davui Island Resort Fiji's reservations system",
    "phoneNumber": "+679 7738990",
    "addressOne": "",
    "addressTwo": "",
    "city": "Beqa Island",
    "county": "",
    "region": "",
    "country": "Fiji",
    "rooms": 16,
    "dollar": 3,
    "updated": "2023-10-12T13:57:00.25",
    "created": "2023-10-12T12:44:40.517",
    "medias": [
      {
        "mediaId": 60527,
        "title": "Private Islands for rent - Royal Davui - Fiji - Pacific Ocean",
        "url": "https://pkslist.azureedge.net/media/images/1/2bb67339-50af-4a88-b77b-7727f8b61078/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2bb67339-50af-4a88-b77b-7727f8b61078/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2bb67339-50af-4a88-b77b-7727f8b61078/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 60525,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/211bc711-5cfd-479e-8242-c6ec0de18db1/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/211bc711-5cfd-479e-8242-c6ec0de18db1/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/211bc711-5cfd-479e-8242-c6ec0de18db1/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 60530,
        "title": "Condé Nast Traveler",
        "url": "https://pkslist.azureedge.net/media/images/1/05e38b81-d533-4529-8591-d6564935e3e8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/05e38b81-d533-4529-8591-d6564935e3e8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/05e38b81-d533-4529-8591-d6564935e3e8/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 60534,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/3e13a941-0369-4c5a-a9e2-a0468856f61b/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3e13a941-0369-4c5a-a9e2-a0468856f61b/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3e13a941-0369-4c5a-a9e2-a0468856f61b/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 60531,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/97228ae7-598d-4fc8-9d07-e3e0ed972b48/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/97228ae7-598d-4fc8-9d07-e3e0ed972b48/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/97228ae7-598d-4fc8-9d07-e3e0ed972b48/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 60538,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/fdedd705-07dd-45b5-8f97-87a7febb9926/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fdedd705-07dd-45b5-8f97-87a7febb9926/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fdedd705-07dd-45b5-8f97-87a7febb9926/256x192.jpeg",
        "viewOrder": 9
      },
      {
        "mediaId": 60539,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e64b8e9c-1afd-4d1d-a72a-451ae08555ce/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e64b8e9c-1afd-4d1d-a72a-451ae08555ce/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e64b8e9c-1afd-4d1d-a72a-451ae08555ce/256x192.jpeg",
        "viewOrder": 10
      },
      {
        "mediaId": 60536,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a252b1bc-bf9b-4fe0-a1a1-647050d9d52a/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a252b1bc-bf9b-4fe0-a1a1-647050d9d52a/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a252b1bc-bf9b-4fe0-a1a1-647050d9d52a/256x192.jpeg",
        "viewOrder": 11
      },
      {
        "mediaId": 60528,
        "title": "Review: Royal Davui Island Resort, Fiji - AGLAIA",
        "url": "https://pkslist.azureedge.net/media/images/1/a0635f55-4c04-4732-8332-bf905ea08176/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a0635f55-4c04-4732-8332-bf905ea08176/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a0635f55-4c04-4732-8332-bf905ea08176/256x192.jpg",
        "viewOrder": 13
      },
      {
        "mediaId": 60543,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/25b60d82-2cce-4c05-9d9b-5d433d4aa00a/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/25b60d82-2cce-4c05-9d9b-5d433d4aa00a/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/25b60d82-2cce-4c05-9d9b-5d433d4aa00a/256x192.jpeg",
        "viewOrder": 16
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3525,
      "latitude": -18.4334767,
      "longitude": 178.0748079,
      "parentGeographicAreaId": 0,
      "title": "Royal Davui Island Resort, Fiji",
      "updated": null,
      "created": "2023-10-12T13:37:00"
    },
    "personalities": [
      {
        "tagId": 50,
        "tagName": "Barefoot Luxury",
        "tagType": 1
      },
      {
        "tagId": 157,
        "tagName": "Beach Escape",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 116,
        "tagName": "Scuba Diving",
        "tagType": 2
      },
      {
        "tagId": 124,
        "tagName": "Helicopter",
        "tagType": 2
      },
      {
        "tagId": 198,
        "tagName": "Boat Excursion",
        "tagType": 2
      },
      {
        "tagId": 206,
        "tagName": "Private Beach",
        "tagType": 2
      },
      {
        "tagId": 209,
        "tagName": "Adults Only",
        "tagType": 2
      },
      {
        "tagId": 410,
        "tagName": "Sundownders",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 839,
        "venueCategoryId": 34,
        "venueId": 3744,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 840,
        "venueCategoryId": 19,
        "venueId": 3744,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 19,
          "title": "Private Islands",
          "region": "Global",
          "viewOrder": 15,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3421,
    "venueCategoryId": 0,
    "status": 1,
    "title": "White Desert Echo",
    "description": "Echo is as close as you can get to feeling like you’re off the planet without leaving Earth.\n\nThe space-age design of the ‘Sky Pods’ makes them look like they’ve been beamed down from Mars. Futuristic and luxurious, the six bedrooms are created from composite fibreglass with floor to ceiling windows allowing guests to soak in the moon-like landscape beyond.\n\nOriginal photos taken from the International Space Station (ISS) by former Commander, White Desert guest and retired astronaut, colonel Terry Virts will be featured in each pod. Says Virts of the Echo landscape, “The mountains are the most beautiful I have seen across Earth, Venus and Mars.”\n\nPerfect for exclusive use groups and catering for up to 12 people, Echo allows guests to combine an ultra-luxury experience with a once in lifetime adventure on the 7th Continent. As with all White Desert’s camps, Echo is designed to be dismantled, leaving no trace on the Antarctic landscape.",
    "brand": "",
    "blockQuote": "You may never get to outer space but you can live like an astronaut.",
    "emailAddress": "info@white-desert.com",
    "website": "white-desert.com/echo/",
    "bookingURL": "https://white-desert.com/dates-and-rates/2022-2023/",
    "redirectNotes": "White Desert Echo's reservations system",
    "phoneNumber": "+1 815 534 4533",
    "addressOne": "",
    "addressTwo": "",
    "city": "Somewhere",
    "county": "",
    "region": "",
    "country": "Antarctica",
    "rooms": 6,
    "dollar": 3,
    "updated": "2022-07-19T11:01:09.663",
    "created": "2022-07-19T10:45:54.977",
    "medias": [
      {
        "mediaId": 53963,
        "title": "White Desert Echo Camp | Uncrate",
        "url": "https://pkslist.azureedge.net/media/images/1/0a295c1e-eaad-4b59-9b36-e2e0129386ee/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0a295c1e-eaad-4b59-9b36-e2e0129386ee/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0a295c1e-eaad-4b59-9b36-e2e0129386ee/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 53964,
        "title": "White Desert Antarctica launch 'Echo': a new camp inspired by ...",
        "url": "https://pkslist.azureedge.net/media/images/1/cc1bf930-fa0f-4ee1-bae6-a677f718228c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cc1bf930-fa0f-4ee1-bae6-a677f718228c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cc1bf930-fa0f-4ee1-bae6-a677f718228c/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 53965,
        "title": "White Desert's New Antarctica Camp : Echo | Luxsphere",
        "url": "https://pkslist.azureedge.net/media/images/1/9683e493-63fc-4977-bcbc-cf12589514f6/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9683e493-63fc-4977-bcbc-cf12589514f6/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9683e493-63fc-4977-bcbc-cf12589514f6/256x192.png",
        "viewOrder": 2
      },
      {
        "mediaId": 53970,
        "title": "White Desert Echo Camp Antarctica | stupidDOPE.com",
        "url": "https://pkslist.azureedge.net/media/images/1/0d31d609-55e9-41c0-97b2-b5e70542077d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0d31d609-55e9-41c0-97b2-b5e70542077d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0d31d609-55e9-41c0-97b2-b5e70542077d/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 53967,
        "title": "Echo Camp from White Desert - UnnamedProject",
        "url": "https://pkslist.azureedge.net/media/images/1/7d1fc25b-21b8-4e7e-ac21-396c73dd0ca0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7d1fc25b-21b8-4e7e-ac21-396c73dd0ca0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7d1fc25b-21b8-4e7e-ac21-396c73dd0ca0/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 53962,
        "title": "First Look at Echo, White Desert's New Space-Inspired Antarctic Camp",
        "url": "https://pkslist.azureedge.net/media/images/1/f0d9eaca-d606-47bc-96b9-5096ef689e4b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f0d9eaca-d606-47bc-96b9-5096ef689e4b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f0d9eaca-d606-47bc-96b9-5096ef689e4b/256x192.jpg",
        "viewOrder": 5
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3209,
      "latitude": -75.250973,
      "longitude": -0.071389,
      "parentGeographicAreaId": 0,
      "title": "Antarctica",
      "updated": null,
      "created": "2022-07-19T10:54:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 53,
        "tagName": "Cutting Edge",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 172,
        "tagName": "Biking",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 243,
        "tagName": "Outdoor Activities",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 296,
        "tagName": "Guided Walk",
        "tagType": 2
      },
      {
        "tagId": 327,
        "tagName": "Snowmobiles",
        "tagType": 2
      },
      {
        "tagId": 338,
        "tagName": "Cross Country Skiing",
        "tagType": 2
      },
      {
        "tagId": 365,
        "tagName": "Private Chef",
        "tagType": 2
      },
      {
        "tagId": 453,
        "tagName": "Abseiling",
        "tagType": 2
      },
      {
        "tagId": 454,
        "tagName": "Ice Climbing",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 757,
        "venueCategoryId": 34,
        "venueId": 3421,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 758,
        "venueCategoryId": 17,
        "venueId": 3421,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 759,
        "venueCategoryId": 13,
        "venueId": 3421,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 760,
        "venueCategoryId": 20,
        "venueId": 3421,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 20,
          "title": "BucketList Hotels",
          "region": "Global",
          "viewOrder": 0,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 778,
        "venueCategoryId": 35,
        "venueId": 3421,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3420,
    "venueCategoryId": 0,
    "status": 1,
    "title": "White Desert Wolf’s Fang",
    "description": "Offering laidback yet sophisticated interiors that evoke the bygone age of explorers, Wolf’s Fang features six heated bedroom tents that are equipped with en-suite washrooms, replete with shower rooms. The relaxed lounge area is a haven of comfort and warmth, no matter what the conditions outside. The inviting dining area is the ideal space to revisit the day’s exploration with fellow adventurers.\n\nAdrenalin seekers will enjoy a variety of exhilarating activities on this vast continent including abseiling, ice-climbing and rope-walks. Guests looking for peace and serenity will revel in gentle hikes, skidoo rides or simply taking in the dramatic vistas – guests can be as active or as relaxed as they wish during their stay.",
    "brand": "",
    "blockQuote": "You're going to love getting a drink at the world's most remote ice bar.",
    "emailAddress": "info@white-desert.com",
    "website": "white-desert.com/our-camps/wolfs-fang/",
    "bookingURL": "https://white-desert.com/dates-and-rates/2022-2023/",
    "redirectNotes": "White Desert Wolf’s Fang's reservations system",
    "phoneNumber": "+1 815 534 4533",
    "addressOne": "",
    "addressTwo": "",
    "city": "Queen Maud Land",
    "county": "",
    "region": "",
    "country": "Antarctica",
    "rooms": 6,
    "dollar": 3,
    "updated": "2022-07-19T11:05:15.973",
    "created": "2022-07-19T10:18:25.84",
    "medias": [
      {
        "mediaId": 53952,
        "title": "White Desert unveils new Antarctic camp | Aspire Travel Club",
        "url": "https://pkslist.azureedge.net/media/images/1/a5079944-3478-4380-bd78-c17d5f7b8c85/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a5079944-3478-4380-bd78-c17d5f7b8c85/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a5079944-3478-4380-bd78-c17d5f7b8c85/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 53945,
        "title": "How to Build a Hotel in Antarctica - Bloomberg",
        "url": "https://pkslist.azureedge.net/media/images/1/c5c81550-094c-4ad4-9562-dddf37abaed1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c5c81550-094c-4ad4-9562-dddf37abaed1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c5c81550-094c-4ad4-9562-dddf37abaed1/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 53960,
        "title": "How to Build a Hotel in Antarctica - Bloomberg",
        "url": "https://pkslist.azureedge.net/media/images/1/9a8f8fec-7d58-483c-b8ff-8adf30e4e8f0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9a8f8fec-7d58-483c-b8ff-8adf30e4e8f0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9a8f8fec-7d58-483c-b8ff-8adf30e4e8f0/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 53944,
        "title": "Tempus News Tags - Antarctica",
        "url": "https://pkslist.azureedge.net/media/images/1/a23ed882-caa3-45b8-b85f-177e34b96acf/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a23ed882-caa3-45b8-b85f-177e34b96acf/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a23ed882-caa3-45b8-b85f-177e34b96acf/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 53947,
        "title": "Experience Antarctica Without Getting Seasick - EliteVoyage",
        "url": "https://pkslist.azureedge.net/media/images/1/0986c4f6-7b32-4333-8935-6206dc634aa5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0986c4f6-7b32-4333-8935-6206dc634aa5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0986c4f6-7b32-4333-8935-6206dc634aa5/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 53943,
        "title": "Tempus News - White Desert launches Wolf's Fang, a new camp in ...",
        "url": "https://pkslist.azureedge.net/media/images/1/52f0eafd-cdec-41de-9949-d169ed75ecc7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/52f0eafd-cdec-41de-9949-d169ed75ecc7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/52f0eafd-cdec-41de-9949-d169ed75ecc7/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 53961,
        "title": "How to Build a Hotel in Antarctica - Bloomberg",
        "url": "https://pkslist.azureedge.net/media/images/1/59e72f7c-08a5-4db6-8b64-ecb7cfa97c22/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/59e72f7c-08a5-4db6-8b64-ecb7cfa97c22/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/59e72f7c-08a5-4db6-8b64-ecb7cfa97c22/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 53950,
        "title": "White Desert Tours Antarctica Opens Wolf's Fang Camp",
        "url": "https://pkslist.azureedge.net/media/images/1/e1859777-ac73-46dc-9955-00c647648f17/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e1859777-ac73-46dc-9955-00c647648f17/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e1859777-ac73-46dc-9955-00c647648f17/256x192.jpg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3208,
      "latitude": -86,
      "longitude": -160,
      "parentGeographicAreaId": 0,
      "title": "Queen Maud Mountains, Antarctica",
      "updated": null,
      "created": "2022-07-19T10:23:00"
    },
    "personalities": [
      {
        "tagId": 53,
        "tagName": "Cutting Edge",
        "tagType": 1
      },
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 133,
        "tagName": "Glamping",
        "tagType": 2
      },
      {
        "tagId": 172,
        "tagName": "Biking",
        "tagType": 2
      },
      {
        "tagId": 243,
        "tagName": "Outdoor Activities",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 296,
        "tagName": "Guided Walk",
        "tagType": 2
      },
      {
        "tagId": 317,
        "tagName": "Gourmet",
        "tagType": 2
      },
      {
        "tagId": 327,
        "tagName": "Snowmobiles",
        "tagType": 2
      },
      {
        "tagId": 453,
        "tagName": "Abseiling",
        "tagType": 2
      },
      {
        "tagId": 454,
        "tagName": "Ice Climbing",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 754,
        "venueCategoryId": 34,
        "venueId": 3420,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 755,
        "venueCategoryId": 17,
        "venueId": 3420,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 756,
        "venueCategoryId": 13,
        "venueId": 3420,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 779,
        "venueCategoryId": 35,
        "venueId": 3420,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3251,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Kitich Forest Camp",
    "description": "From the dry, yellow savannah that epitomises northern Kenya’s expansive plains rises 900 square kilometres of lush mountain forest like no other in East Africa.\nThis is a place where elephants forge trails amongst ancient hardwood trees and endemic cycads, where crystal clear streams form natural swimming pools, and where rare and endangered monkeys thrive under the protection of local Samburu communities.\n\nNestled in one of the valleys – overlooking a river frequented by African painted dogs, colourful birds, leopards and elephants - are six tents. Kitich Forest Camp offers traditional safari charm in a wilderness quite unlike any other in Africa, and delivers an experience as equally unforgettable for those discovering Africa for the first time, as it does for those lucky enough to have spent many years exploring it.",
    "brand": "",
    "blockQuote": "Take a quad bike to the Reteti Elephant Orphanage and singing wells.",
    "emailAddress": "bookings@africanterritories.co.ke",
    "website": "kitichforestcamp.com/",
    "bookingURL": "https://kitichforestcamp.com/book",
    "redirectNotes": "Kitich Forest Camp's reservations system",
    "phoneNumber": "+254 717 468049",
    "addressOne": "",
    "addressTwo": "",
    "city": "Mathews Mountain Range",
    "county": "",
    "region": "Laikipia",
    "country": "Kenya",
    "rooms": 6,
    "dollar": 3,
    "updated": "2021-09-23T12:45:26.14",
    "created": "2021-09-23T12:13:55.467",
    "medias": [
      {
        "mediaId": 50039,
        "title": "Kitich Forest Camp | Northern Kenya | Kenya | Expert Africa",
        "url": "https://pkslist.azureedge.net/media/images/1/73dcd19a-d154-439b-aaaf-38c10e049b5b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/73dcd19a-d154-439b-aaaf-38c10e049b5b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/73dcd19a-d154-439b-aaaf-38c10e049b5b/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 50031,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/4ec91de1-c8e5-40de-9f9e-af93d33abeb9/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4ec91de1-c8e5-40de-9f9e-af93d33abeb9/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4ec91de1-c8e5-40de-9f9e-af93d33abeb9/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 50022,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/baa83e59-3264-446c-9dcb-ec66b030e4a3/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/baa83e59-3264-446c-9dcb-ec66b030e4a3/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/baa83e59-3264-446c-9dcb-ec66b030e4a3/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 50025,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f31644f0-3867-4764-a1ef-ca6a580ff596/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f31644f0-3867-4764-a1ef-ca6a580ff596/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f31644f0-3867-4764-a1ef-ca6a580ff596/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 50027,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d64c8426-4150-4ce5-8c63-b4bec28da9cb/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d64c8426-4150-4ce5-8c63-b4bec28da9cb/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d64c8426-4150-4ce5-8c63-b4bec28da9cb/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 50028,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/cd0b496f-68fd-42e1-b421-647d1b962d29/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cd0b496f-68fd-42e1-b421-647d1b962d29/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cd0b496f-68fd-42e1-b421-647d1b962d29/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 50032,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/651bd033-1626-4d25-9a2f-7ab04623743a/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/651bd033-1626-4d25-9a2f-7ab04623743a/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/651bd033-1626-4d25-9a2f-7ab04623743a/256x192.jpeg",
        "viewOrder": 10
      },
      {
        "mediaId": 50035,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/fee1aa28-df96-4d40-aacd-321826c22b36/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fee1aa28-df96-4d40-aacd-321826c22b36/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fee1aa28-df96-4d40-aacd-321826c22b36/256x192.jpeg",
        "viewOrder": 11
      },
      {
        "mediaId": 50034,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/701672a0-2d09-4c47-b160-487af695cd27/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/701672a0-2d09-4c47-b160-487af695cd27/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/701672a0-2d09-4c47-b160-487af695cd27/256x192.jpeg",
        "viewOrder": 12
      },
      {
        "mediaId": 50037,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/0e429008-1116-4af1-942a-a2c73755fe10/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0e429008-1116-4af1-942a-a2c73755fe10/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0e429008-1116-4af1-942a-a2c73755fe10/256x192.jpeg",
        "viewOrder": 15
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3038,
      "latitude": 1.2221209,
      "longitude": 37.30294659999999,
      "parentGeographicAreaId": 0,
      "title": "Mathews Mountain Range Matthews Range, Kenya",
      "updated": null,
      "created": "2021-09-23T12:36:00"
    },
    "personalities": [
      {
        "tagId": 50,
        "tagName": "Barefoot Luxury",
        "tagType": 1
      },
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 135,
        "tagName": "Bird Watching",
        "tagType": 2
      },
      {
        "tagId": 259,
        "tagName": "Picnic",
        "tagType": 2
      },
      {
        "tagId": 264,
        "tagName": "Quad Bike",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 296,
        "tagName": "Guided Walk",
        "tagType": 2
      },
      {
        "tagId": 330,
        "tagName": "Swimming",
        "tagType": 2
      },
      {
        "tagId": 350,
        "tagName": "Campfire",
        "tagType": 2
      },
      {
        "tagId": 410,
        "tagName": "Sundownders",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 738,
        "venueCategoryId": 34,
        "venueId": 3251,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3241,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Desert Rose Lodge",
    "description": "Desert Rose, a unique eco-friendly lodge located at 5,500 feet (1676 meters) above sea level on the verdant cedar forest slopes of Mt Nyiru which towers majestically above the Elbarta plains, between Karisia Mountains and Lake Turkana in North Kenya. It consists of five unique houses accommodating up to twelve people.\n\nThe remote location of the lodge, itscloseness to nature, and surreality amid the wilderness creates a unique atmosphere of spirituality and attracts not only people but also wild animals, like the friendly leopard which lives nearby and can be heard from a distance in the early morning and at dusk. There is no network at the lodge. However, there is a place we normally refer to as the “Safaricom rock”, where if the weather permits, you can get some network, hence the name.",
    "brand": "",
    "blockQuote": "A boat trip to discover the magical shores of Lake Turkana is a highlight.",
    "emailAddress": "lodge@desertrosekenya.com",
    "website": "desertrosekenya.com/",
    "bookingURL": "https://desertrosekenya.com/make-a-reservation/",
    "redirectNotes": "Desert Rose Lodge's reservations system",
    "phoneNumber": "+254 791 471 194",
    "addressOne": "",
    "addressTwo": "",
    "city": "Lake Turkana",
    "county": "",
    "region": "",
    "country": "Kenya",
    "rooms": 5,
    "dollar": 2,
    "updated": "2021-09-19T14:55:55.39",
    "created": "2021-09-19T14:31:43.413",
    "medias": [
      {
        "mediaId": 49805,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/51044c24-83cd-4f93-b337-762f7d56aa6d/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/51044c24-83cd-4f93-b337-762f7d56aa6d/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/51044c24-83cd-4f93-b337-762f7d56aa6d/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 49814,
        "title": "Desert Rose Lodge, Samburu - Travel Discover Kenya",
        "url": "https://pkslist.azureedge.net/media/images/1/6473528f-533d-4f00-a1bd-a48e32d72c90/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6473528f-533d-4f00-a1bd-a48e32d72c90/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6473528f-533d-4f00-a1bd-a48e32d72c90/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 49817,
        "title": "Safari to Desert Rose Lodge with Africa Travel Resource",
        "url": "https://pkslist.azureedge.net/media/images/1/f76d241c-5fcb-462e-96cf-4ef7089432ee/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f76d241c-5fcb-462e-96cf-4ef7089432ee/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f76d241c-5fcb-462e-96cf-4ef7089432ee/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 49804,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/735590d2-8686-4baa-a666-ef2ab51b9449/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/735590d2-8686-4baa-a666-ef2ab51b9449/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/735590d2-8686-4baa-a666-ef2ab51b9449/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 49801,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e4c88858-953a-430d-999f-6752ff6750fc/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e4c88858-953a-430d-999f-6752ff6750fc/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e4c88858-953a-430d-999f-6752ff6750fc/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 49812,
        "title": "Safari to Desert Rose Lodge with Africa Travel Resource",
        "url": "https://pkslist.azureedge.net/media/images/1/205719a1-d865-45d5-a21c-2a9cba20ec37/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/205719a1-d865-45d5-a21c-2a9cba20ec37/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/205719a1-d865-45d5-a21c-2a9cba20ec37/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 49806,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/6428a1a1-b96d-45e4-a17e-fcdd759c76f8/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6428a1a1-b96d-45e4-a17e-fcdd759c76f8/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6428a1a1-b96d-45e4-a17e-fcdd759c76f8/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 49803,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/bbb079f1-3033-43b8-996d-b656bc878c1a/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bbb079f1-3033-43b8-996d-b656bc878c1a/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bbb079f1-3033-43b8-996d-b656bc878c1a/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 49802,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/40ca8283-6d7d-4c58-97ee-f48ba0850581/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/40ca8283-6d7d-4c58-97ee-f48ba0850581/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/40ca8283-6d7d-4c58-97ee-f48ba0850581/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 49813,
        "title": "Desert Rose Lodge in the Northern Frontier District, Kenya ...",
        "url": "https://pkslist.azureedge.net/media/images/1/e2d465c0-fb1c-42b0-bdae-d9df1f48b0b1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e2d465c0-fb1c-42b0-bdae-d9df1f48b0b1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e2d465c0-fb1c-42b0-bdae-d9df1f48b0b1/256x192.jpg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3029,
      "latitude": 2.0573982,
      "longitude": 36.8441114,
      "parentGeographicAreaId": 0,
      "title": "Desert rose, Samburu District, Kenya",
      "updated": null,
      "created": "2021-09-19T14:40:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 59,
        "tagName": "Cultural",
        "tagType": 2
      },
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 109,
        "tagName": "Fishing",
        "tagType": 2
      },
      {
        "tagId": 186,
        "tagName": "Rock Climbing",
        "tagType": 2
      },
      {
        "tagId": 243,
        "tagName": "Outdoor Activities",
        "tagType": 2
      },
      {
        "tagId": 266,
        "tagName": "Nature Walk",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 737,
        "venueCategoryId": 34,
        "venueId": 3241,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3149,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Pater Noster Lighthouse",
    "description": "Here, in the outpost of the archipelago, on one of Sweden’s most windblown, barren and exposed places, Pater Noster – the king of lighthouses, was built. This state-of-the-art masterpiece gave hope and guided seafarers safely for over a century. Next to it, the lighthouse keepers and their families built their home, a small-scale community on an island which had always been perceived as uninhabitable. For more than one hundred years they lived out here, on the terms dictated by the island and the sea.\n\nNow, we are once again opening the door to the lighthouse master’s home. A place for seafarers from near and far, for nature lovers and pleasure seekers, for those who are seeking tranquility or good company. Here, where the light is brighter and the wind is wilder, the ocean saltier and the seafood fresher.",
    "brand": "",
    "blockQuote": "A soak in the hot tub with spanning views over the sea is in the cards.",
    "emailAddress": "mirja@paternoster.se",
    "website": "paternoster.se/en/",
    "bookingURL": "https://book.paternoster.se/?language=en",
    "redirectNotes": "Pater Noster Lighthouse's reservations system",
    "phoneNumber": "+46 73 324 77 04",
    "addressOne": "",
    "addressTwo": "",
    "city": "Marstrand",
    "county": "",
    "region": "",
    "country": "Sweden",
    "rooms": 9,
    "dollar": 2,
    "updated": "2023-12-23T14:07:46.63",
    "created": "2021-05-18T14:16:16.557",
    "medias": [
      {
        "mediaId": 47633,
        "title": "Pater Noster lighthouse is a remote destination hotel in Sweden ...",
        "url": "https://pkslist.azureedge.net/media/images/1/582f2889-9aa4-4053-859b-8a25a3b76af4/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/582f2889-9aa4-4053-859b-8a25a3b76af4/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/582f2889-9aa4-4053-859b-8a25a3b76af4/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 47634,
        "title": "Pater Noster Lighthouse - Wikipedia",
        "url": "https://pkslist.azureedge.net/media/images/1/01b36a7a-8b53-41d9-b6b1-1c65107b786e/1200x900.JPG",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/01b36a7a-8b53-41d9-b6b1-1c65107b786e/800x600.JPG",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/01b36a7a-8b53-41d9-b6b1-1c65107b786e/256x192.JPG",
        "viewOrder": 1
      },
      {
        "mediaId": 47637,
        "title": "Stay at this Remote Swedish Island Hotel with its own Spectacular ...",
        "url": "https://pkslist.azureedge.net/media/images/1/88f23606-4ae8-40b0-a141-60eb9cc0df58/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/88f23606-4ae8-40b0-a141-60eb9cc0df58/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/88f23606-4ae8-40b0-a141-60eb9cc0df58/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 47635,
        "title": "Pater Noster lighthouse is a remote destination hotel in Sweden ...",
        "url": "https://pkslist.azureedge.net/media/images/1/825981c2-f941-4a0b-b76c-0abbcf347cf0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/825981c2-f941-4a0b-b76c-0abbcf347cf0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/825981c2-f941-4a0b-b76c-0abbcf347cf0/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 47652,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b4798da5-c01e-4790-b743-a7fce8d412b1/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b4798da5-c01e-4790-b743-a7fce8d412b1/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b4798da5-c01e-4790-b743-a7fce8d412b1/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 47636,
        "title": "Pater Noster lighthouse is a remote destination hotel in Sweden ...",
        "url": "https://pkslist.azureedge.net/media/images/1/95e48181-b093-4bf1-8074-8b1751e3ec57/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/95e48181-b093-4bf1-8074-8b1751e3ec57/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/95e48181-b093-4bf1-8074-8b1751e3ec57/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 47653,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a9200246-aff8-489d-b868-3984c2af6985/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a9200246-aff8-489d-b868-3984c2af6985/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a9200246-aff8-489d-b868-3984c2af6985/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 47654,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e6fafc8a-30f8-4200-aa4e-441444d34a32/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e6fafc8a-30f8-4200-aa4e-441444d34a32/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e6fafc8a-30f8-4200-aa4e-441444d34a32/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 47641,
        "title": "Pater Noster lighthouse is a remote destination hotel in Sweden ...",
        "url": "https://pkslist.azureedge.net/media/images/1/da91ca3e-71a2-4c0d-b43f-9108acbc34d2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/da91ca3e-71a2-4c0d-b43f-9108acbc34d2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/da91ca3e-71a2-4c0d-b43f-9108acbc34d2/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 47648,
        "title": "Pater Noster | Hamneskär, Sweden - in 2021 | Cottage living, Home ...",
        "url": "https://pkslist.azureedge.net/media/images/1/d81d4b50-d427-4a5a-a89a-1fa208a3d721/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d81d4b50-d427-4a5a-a89a-1fa208a3d721/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d81d4b50-d427-4a5a-a89a-1fa208a3d721/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 47645,
        "title": "Pater Noster Lighthouse: The World's Remotest Cinema - Happy Ali",
        "url": "https://pkslist.azureedge.net/media/images/1/1d719105-abe4-46cb-8d48-e3d3c5e70fb3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1d719105-abe4-46cb-8d48-e3d3c5e70fb3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1d719105-abe4-46cb-8d48-e3d3c5e70fb3/256x192.jpg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2936,
      "latitude": 57.89570419999999,
      "longitude": 11.4662986,
      "parentGeographicAreaId": 0,
      "title": "Hamneskär, 471 51 Hamneskär, Sweden",
      "updated": null,
      "created": "2021-05-18T14:25:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 75,
        "tagName": "Casual Dining",
        "tagType": 2
      },
      {
        "tagId": 109,
        "tagName": "Fishing",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 124,
        "tagName": "Helicopter",
        "tagType": 2
      },
      {
        "tagId": 135,
        "tagName": "Bird Watching",
        "tagType": 2
      },
      {
        "tagId": 205,
        "tagName": "Design",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 235,
        "tagName": "Hot Tub",
        "tagType": 2
      },
      {
        "tagId": 268,
        "tagName": "Massage",
        "tagType": 2
      },
      {
        "tagId": 330,
        "tagName": "Swimming",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 774,
        "venueCategoryId": 9,
        "venueId": 3149,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 9,
          "title": "Scandinavian Break",
          "region": "Northern Europe",
          "viewOrder": 21,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 860,
        "venueCategoryId": 34,
        "venueId": 3149,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3080,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Futangue Hotel & Spa",
    "description": "The perfect place to relax, hidden away in a little corner of paradise in Northern Patagonia. Futangue Hotel & Spa stands out for its native wood interiors and incredible views across to mountain ranges and waterfalls. This is the point from which to set out and explore the wilderness of the Patagonian Andes and to where you can return again, to unwind and enjoy the Spa, after a day of adventure.\n\nThe accommodation includes two charming and comfortable buildings: Futangue Hotel & Spa and the Explorer’s House and, with 16 and 10 spacious rooms, respectively. The constructions are only 120 meters apart and share the same dining room in the restaurant at the Hotel. Futangue Hotel & Spa invites guests to discover this stunning, wild, private park, while still being able to enjoy pleasant and exclusive accommodation.",
    "brand": "",
    "blockQuote": "It's fun to horseback ride through the park's stunning landscape.",
    "emailAddress": "reservations@futangue.com",
    "website": "parquefutangue.com/en/hotel/",
    "bookingURL": "https://reservations.travelclick.com/112511?LanguageID=1#/guestsandrooms&iata=96027890",
    "redirectNotes": "Futangue Hotel & Spa's reservations system",
    "phoneNumber": "+56 9 8819 5842",
    "addressOne": "",
    "addressTwo": "",
    "city": "Lago Ranco",
    "county": "",
    "region": "Patagonia",
    "country": "Chile",
    "rooms": 26,
    "dollar": 2,
    "updated": "2021-01-27T12:49:24.107",
    "created": "2021-01-27T12:22:55.6",
    "medias": [
      {
        "mediaId": 44922,
        "title": "Futangue Hotel & Spa - Community Members | REMOTE Latin America",
        "url": "https://pkslist.azureedge.net/media/images/1/3f7f7e6b-f0a0-4531-af3d-c5e695e6cd6a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3f7f7e6b-f0a0-4531-af3d-c5e695e6cd6a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3f7f7e6b-f0a0-4531-af3d-c5e695e6cd6a/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 44908,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f7661c35-903b-494c-9a7a-7912c3893fc8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f7661c35-903b-494c-9a7a-7912c3893fc8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f7661c35-903b-494c-9a7a-7912c3893fc8/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 44913,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/02831219-69ef-4d9c-aafb-5613e4f1dc64/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/02831219-69ef-4d9c-aafb-5613e4f1dc64/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/02831219-69ef-4d9c-aafb-5613e4f1dc64/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 44904,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/fd27e9e3-d846-435d-b8b8-c9875352f4d2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fd27e9e3-d846-435d-b8b8-c9875352f4d2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fd27e9e3-d846-435d-b8b8-c9875352f4d2/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 44906,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/4eae9dad-ac9b-4506-b56e-5e2a491f4b70/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4eae9dad-ac9b-4506-b56e-5e2a491f4b70/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4eae9dad-ac9b-4506-b56e-5e2a491f4b70/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 44905,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/89977bea-d7ae-41d1-84bf-dee21cb1207c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/89977bea-d7ae-41d1-84bf-dee21cb1207c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/89977bea-d7ae-41d1-84bf-dee21cb1207c/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 44915,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/3afe584a-c4fa-4def-b814-c903ed487bd6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3afe584a-c4fa-4def-b814-c903ed487bd6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3afe584a-c4fa-4def-b814-c903ed487bd6/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 44917,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/dae1d2c8-8847-4a8a-9011-01a8e3f8b323/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/dae1d2c8-8847-4a8a-9011-01a8e3f8b323/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/dae1d2c8-8847-4a8a-9011-01a8e3f8b323/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 44916,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e1b1f674-255e-454d-b413-c9a34fe4ab9d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e1b1f674-255e-454d-b413-c9a34fe4ab9d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e1b1f674-255e-454d-b413-c9a34fe4ab9d/256x192.jpg",
        "viewOrder": 13
      },
      {
        "mediaId": 44907,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/4d5544e1-7cc2-4234-86c3-9395135b12eb/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4d5544e1-7cc2-4234-86c3-9395135b12eb/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4d5544e1-7cc2-4234-86c3-9395135b12eb/256x192.jpg",
        "viewOrder": 15
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2866,
      "latitude": -40.3383316,
      "longitude": -72.2121748,
      "parentGeographicAreaId": 0,
      "title": "Ruta T-85, KM 22, Lago Ranco, Los Ríos, Chile",
      "updated": null,
      "created": "2021-01-27T12:45:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 82,
        "tagName": "Horseback Riding",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 104,
        "tagName": "Cycling",
        "tagType": 2
      },
      {
        "tagId": 109,
        "tagName": "Fishing",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 170,
        "tagName": "Kayaking",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 340,
        "tagName": "Snow Shoeing",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 719,
        "venueCategoryId": 34,
        "venueId": 3080,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3076,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Ol Jogi",
    "description": "Nestled into a protective rocky outcrop that is surrounded by lush greenery, the manor house contains a dining room, games room, bar/lounge, and a wonderful collection of the owner's private art.  There is also an large pool complex, complete with a waterfall, that is also home to the spa and fitness center.\n\nSurrounding the main house,  a collection of quaint cottages are set under grand shady trees. Ol Jogi sits on a family-operated wildlife conservancy on the famous Laikipia Plateau in Kenya. The reserve is home to roughly 7000 large mammals including the Big 5, the worlds largest population of endangered Grevy's zebra, and significant numbers of other endangered animals. The core focus of the conservancy is wildlife conservation - there is a vetinary clinic, wildlife sanctuary and wildlife rehabilitation centre. During your stay you can walk with rescued elephants, and may even be invited on a vetinary intervention.",
    "brand": "",
    "blockQuote": "Do ask to see the 'top secret' James Bond-worthy wine cellar.",
    "emailAddress": "reservations@oljogi.org",
    "website": "oljogihome.com/sliding-home2/#/home",
    "bookingURL": "http://oljogihome.com/sliding-home2/#/contact-us",
    "redirectNotes": "Ol Jogi's reservations system",
    "phoneNumber": "+254 62 203 1650",
    "addressOne": "",
    "addressTwo": "",
    "city": "Nanyuki",
    "county": "",
    "region": "Laikipia",
    "country": "Kenya",
    "rooms": 13,
    "dollar": 3,
    "updated": "2021-09-23T12:06:21.53",
    "created": "2021-01-24T14:57:12.3",
    "medias": [
      {
        "mediaId": 44802,
        "title": "Spotlight on Ol Jogi",
        "url": "https://pkslist.azureedge.net/media/images/1/73eebf78-df24-47f8-8d94-94f3c08a668b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/73eebf78-df24-47f8-8d94-94f3c08a668b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/73eebf78-df24-47f8-8d94-94f3c08a668b/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 50017,
        "title": "Ol Jogi – Hotel Review | Condé Nast Traveler",
        "url": "https://pkslist.azureedge.net/media/images/1/3b2fa7f9-5631-4121-a84b-ae8ecaeb2cc5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3b2fa7f9-5631-4121-a84b-ae8ecaeb2cc5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3b2fa7f9-5631-4121-a84b-ae8ecaeb2cc5/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 44795,
        "title": "Ol Jogi Ranch - Travel Discover Kenya",
        "url": "https://pkslist.azureedge.net/media/images/1/69e7ae7b-7ebb-4bcf-acee-3b585866157a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/69e7ae7b-7ebb-4bcf-acee-3b585866157a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/69e7ae7b-7ebb-4bcf-acee-3b585866157a/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 44812,
        "title": "Ol Jogi Ranch - Micato Luxury Safaris and Journeys",
        "url": "https://pkslist.azureedge.net/media/images/1/ddb2e2aa-40b5-4311-bdfd-722d4ea3756b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ddb2e2aa-40b5-4311-bdfd-722d4ea3756b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ddb2e2aa-40b5-4311-bdfd-722d4ea3756b/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 44814,
        "title": "Ol Jogi Ranch - Micato Luxury Safaris and Journeys",
        "url": "https://pkslist.azureedge.net/media/images/1/c334928b-9e0c-4127-bd0d-4ed9b1d4738e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c334928b-9e0c-4127-bd0d-4ed9b1d4738e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c334928b-9e0c-4127-bd0d-4ed9b1d4738e/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 44797,
        "title": "Ol Jogi Home",
        "url": "https://pkslist.azureedge.net/media/images/1/a2d8f159-a496-445a-9388-bccff9c13b08/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a2d8f159-a496-445a-9388-bccff9c13b08/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a2d8f159-a496-445a-9388-bccff9c13b08/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 50016,
        "title": "Ol Jogi Ranch, Kenya-Luxury Africa Camps & Lodges-Micato Safaris",
        "url": "https://pkslist.azureedge.net/media/images/1/782daf57-c68a-4abc-b677-a2cc331294cd/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/782daf57-c68a-4abc-b677-a2cc331294cd/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/782daf57-c68a-4abc-b677-a2cc331294cd/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 44811,
        "title": "Ol Jogi Wildlife Conservancy, Kenya - Pelorus",
        "url": "https://pkslist.azureedge.net/media/images/1/83b71470-47a4-4c3a-826f-51ce925fa6da/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/83b71470-47a4-4c3a-826f-51ce925fa6da/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/83b71470-47a4-4c3a-826f-51ce925fa6da/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 44813,
        "title": "Ol Jogi Home Kenya|Laikipia Luxury Safari Homes",
        "url": "https://pkslist.azureedge.net/media/images/1/451fee06-51de-4eae-9f8e-5bc8bb1fac36/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/451fee06-51de-4eae-9f8e-5bc8bb1fac36/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/451fee06-51de-4eae-9f8e-5bc8bb1fac36/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 50018,
        "title": "SPECIAL: OL JOGI RANCH | Especially Africa",
        "url": "https://pkslist.azureedge.net/media/images/1/bb29ebe6-1ec5-4262-b665-4962b5f1be4f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bb29ebe6-1ec5-4262-b665-4962b5f1be4f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bb29ebe6-1ec5-4262-b665-4962b5f1be4f/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 44806,
        "title": "Pin on Ol jogi",
        "url": "https://pkslist.azureedge.net/media/images/1/d28f75d0-23f8-45cc-94c3-bb34415d02aa/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d28f75d0-23f8-45cc-94c3-bb34415d02aa/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d28f75d0-23f8-45cc-94c3-bb34415d02aa/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 44796,
        "title": "Ol Jogi Home",
        "url": "https://pkslist.azureedge.net/media/images/1/c609fd70-0a03-41ea-ac7e-93eea06246d4/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c609fd70-0a03-41ea-ac7e-93eea06246d4/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c609fd70-0a03-41ea-ac7e-93eea06246d4/256x192.jpg",
        "viewOrder": 11
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2862,
      "latitude": 0.3145033,
      "longitude": 36.9755982,
      "parentGeographicAreaId": 0,
      "title": "Kenya",
      "updated": null,
      "created": "2021-01-24T15:28:00"
    },
    "personalities": [
      {
        "tagId": 189,
        "tagName": "Exclusively Yours",
        "tagType": 1
      },
      {
        "tagId": 359,
        "tagName": "Colonial Chic",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 82,
        "tagName": "Horseback Riding",
        "tagType": 2
      },
      {
        "tagId": 131,
        "tagName": "Library",
        "tagType": 2
      },
      {
        "tagId": 135,
        "tagName": "Bird Watching",
        "tagType": 2
      },
      {
        "tagId": 266,
        "tagName": "Nature Walk",
        "tagType": 2
      },
      {
        "tagId": 276,
        "tagName": "Gym",
        "tagType": 2
      },
      {
        "tagId": 278,
        "tagName": "Conservation",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 334,
        "tagName": "Heated Pool",
        "tagType": 2
      },
      {
        "tagId": 351,
        "tagName": "Meditation",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 716,
        "venueCategoryId": 13,
        "venueId": 3076,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 717,
        "venueCategoryId": 34,
        "venueId": 3076,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3071,
    "venueCategoryId": 0,
    "status": 1,
    "title": "White Desert Whichaway",
    "description": "Whichaway is our flagship camp and sits on the shores of one of the freshwater lakes of the ice-free Schirmacher Oasis.\n\nWe’ve designed 6 heated ‘polar pods’ with cutting-edge exteriors and old-world interiors that conjure up images of Antarctica’s rich heritage of exploration. Our guests really love to sit back and chat in the communal lounge, library and dining area which is a wonderful space to relax with amazing views.\n\nSavour gourmet meals prepared by your private chef, enjoy a sauna with view of the glacier, sip cocktails by the fire, and cross off bucket list adventures like visiting the South Pole, ice climbing, hiking over ice waves, and coming face-to-face with 28,000 emperor penguins.",
    "brand": "",
    "blockQuote": "You can be David Attenborough for a day when you visit the Atka Bay Emperor Penguin Colony.",
    "emailAddress": "info@white-desert.com",
    "website": "white-desert.com/our-camps/whichaway/",
    "bookingURL": "https://white-desert.com/dates-and-rates/2022-2023/",
    "redirectNotes": "White Desert Whichaway's reservations system",
    "phoneNumber": "+1 815 534 4533",
    "addressOne": "",
    "addressTwo": "",
    "city": "Schirmacher Oasis",
    "county": "",
    "region": "",
    "country": "Antarctica",
    "rooms": 6,
    "dollar": 3,
    "updated": "2022-07-19T11:05:06.367",
    "created": "2021-01-21T17:13:19.397",
    "medias": [
      {
        "mediaId": 53936,
        "title": "White Desert Antarctic Adventures | Uncrate",
        "url": "https://pkslist.azureedge.net/media/images/1/0e478a51-9a93-4c0d-a822-337c1794df9e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0e478a51-9a93-4c0d-a822-337c1794df9e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0e478a51-9a93-4c0d-a822-337c1794df9e/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 44673,
        "title": "Ice ice baby: White Desert",
        "url": "https://pkslist.azureedge.net/media/images/1/d2642b2b-b3e7-46a8-adea-db953c1aa7ec/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d2642b2b-b3e7-46a8-adea-db953c1aa7ec/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d2642b2b-b3e7-46a8-adea-db953c1aa7ec/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 44674,
        "title": "This Luxury, One-day Trip to Antarctica Will Cost You a Mere ...",
        "url": "https://pkslist.azureedge.net/media/images/1/db56d0d5-b4b4-48a3-a8fc-5d53c30f5b28/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/db56d0d5-b4b4-48a3-a8fc-5d53c30f5b28/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/db56d0d5-b4b4-48a3-a8fc-5d53c30f5b28/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 44688,
        "title": "Antarctica: Luxury Cruises & Activities | Extraordinary Journeys",
        "url": "https://pkslist.azureedge.net/media/images/1/5835c4e6-3f71-483d-8ded-28b674c4dfbd/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5835c4e6-3f71-483d-8ded-28b674c4dfbd/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5835c4e6-3f71-483d-8ded-28b674c4dfbd/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 44684,
        "title": "Luxury campsite in Antarctica offers tiny domed pods",
        "url": "https://pkslist.azureedge.net/media/images/1/56cc4684-8e91-44d9-86a1-61e4cebbdb07/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/56cc4684-8e91-44d9-86a1-61e4cebbdb07/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/56cc4684-8e91-44d9-86a1-61e4cebbdb07/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 53940,
        "title": "White Desert Antarctica - World of Glamping - Luxury glamping escapes",
        "url": "https://pkslist.azureedge.net/media/images/1/8bc7bc9c-4bff-4e76-ba50-b78039160095/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8bc7bc9c-4bff-4e76-ba50-b78039160095/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8bc7bc9c-4bff-4e76-ba50-b78039160095/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 53941,
        "title": "Whichaway Oasis – Safari Architects",
        "url": "https://pkslist.azureedge.net/media/images/1/e5ac3b5d-d3d8-45db-8c26-d4d7464664d6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e5ac3b5d-d3d8-45db-8c26-d4d7464664d6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e5ac3b5d-d3d8-45db-8c26-d4d7464664d6/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 53938,
        "title": "White Desert Antarctica - World of Glamping - Luxury glamping escapes",
        "url": "https://pkslist.azureedge.net/media/images/1/8508eb52-7b4e-407a-a04d-c02349759f6b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8508eb52-7b4e-407a-a04d-c02349759f6b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8508eb52-7b4e-407a-a04d-c02349759f6b/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 44671,
        "title": "White Desert Antarctic Adventures | HiConsumption",
        "url": "https://pkslist.azureedge.net/media/images/1/69dd104f-669f-4901-85d0-0d05fd3a0c1c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/69dd104f-669f-4901-85d0-0d05fd3a0c1c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/69dd104f-669f-4901-85d0-0d05fd3a0c1c/256x192.jpg",
        "viewOrder": 12
      },
      {
        "mediaId": 44677,
        "title": "Flying the last frontier - Skies Mag",
        "url": "https://pkslist.azureedge.net/media/images/1/0cc4c21f-06b2-4f9a-a0d1-f73b9f645e2a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0cc4c21f-06b2-4f9a-a0d1-f73b9f645e2a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0cc4c21f-06b2-4f9a-a0d1-f73b9f645e2a/256x192.jpg",
        "viewOrder": 13
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2857,
      "latitude": -70.75,
      "longitude": 11.666667,
      "parentGeographicAreaId": 0,
      "title": "Schirmacher Ponds, Antarctica",
      "updated": null,
      "created": "2021-01-21T17:52:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 53,
        "tagName": "Cutting Edge",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 102,
        "tagName": "Eco-friendly",
        "tagType": 2
      },
      {
        "tagId": 131,
        "tagName": "Library",
        "tagType": 2
      },
      {
        "tagId": 134,
        "tagName": "Wilderness Walks",
        "tagType": 2
      },
      {
        "tagId": 164,
        "tagName": "Excursions",
        "tagType": 2
      },
      {
        "tagId": 171,
        "tagName": "Trekking",
        "tagType": 2
      },
      {
        "tagId": 186,
        "tagName": "Rock Climbing",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 259,
        "tagName": "Picnic",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 317,
        "tagName": "Gourmet",
        "tagType": 2
      },
      {
        "tagId": 338,
        "tagName": "Cross Country Skiing",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 712,
        "venueCategoryId": 17,
        "venueId": 3071,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 713,
        "venueCategoryId": 34,
        "venueId": 3071,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 714,
        "venueCategoryId": 13,
        "venueId": 3071,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3061,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Banyan Tree Ringha",
    "description": "Nestled in the beautiful Shangrila highlands in Tibet, Banyan Tree Ringha unravels the stunning beauty of the Himalayas. The resort is one of the most secluded hideaways in the world. Be touched by this ancient land and the majestic mountains that surround our Shangrila resort. Built from traditional Tibetan farmhouses, the resort itself is a picturesque experience that allows you to relax, unwind and find the inner peace you desire.\n\nBanyan Tree Ringha offers large farmhouses and lodges for a genuine Tibetan experience. With the largest rooms in the area, the resort provides sanctuary and an opportunity to retreat into your own mineral rich hot spring pool. The rooms and villas offer a choice of mountain view or garden view, or, for some villas, both.",
    "brand": "",
    "blockQuote": "Book the most romantic dinner along the Shu Du Guang river for you and your loved one.",
    "emailAddress": "ringha@banyantree.com",
    "website": "banyantree.com/en/china/ringha",
    "bookingURL": "https://be.synxis.com/?_ga=2.218360287.75457596.1611057630-535579609.1611057630&adult=2&agencyid=96027890&arrive=2021-01-19&chain=17604&child=0&configcode=BTSBE&currency=CNY&depart=2021-01-20&hotel=64249&level=hotel&locale=en-US&rooms=1&themecode=BTSBE",
    "redirectNotes": "Banyan Tree Ringha's reservations system",
    "phoneNumber": "+86 887 828 8822",
    "addressOne": "",
    "addressTwo": "",
    "city": "Shangrila City",
    "county": "",
    "region": "Tibet",
    "country": "China",
    "rooms": 32,
    "dollar": 1,
    "updated": "2021-05-11T16:12:14.623",
    "created": "2021-01-19T12:04:55.217",
    "medias": [
      {
        "mediaId": 44470,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/574f1c46-1d6b-4644-9e9d-fd073db30b98/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/574f1c46-1d6b-4644-9e9d-fd073db30b98/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/574f1c46-1d6b-4644-9e9d-fd073db30b98/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 44474,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/6f81ae53-22cf-4c1a-81ae-ecd4c2b95f12/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6f81ae53-22cf-4c1a-81ae-ecd4c2b95f12/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6f81ae53-22cf-4c1a-81ae-ecd4c2b95f12/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 44479,
        "title": "Banyan Tree Ringha | Banyan tree, Conde nast traveler, Hotel reviews",
        "url": "https://pkslist.azureedge.net/media/images/1/79b61f46-5af5-4e0b-9568-476fb6bc03bf/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/79b61f46-5af5-4e0b-9568-476fb6bc03bf/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/79b61f46-5af5-4e0b-9568-476fb6bc03bf/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 44480,
        "title": "Banyan Tree Ringha | XO Private",
        "url": "https://pkslist.azureedge.net/media/images/1/812d9ca1-6dbe-46f7-a491-081b77e312e2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/812d9ca1-6dbe-46f7-a491-081b77e312e2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/812d9ca1-6dbe-46f7-a491-081b77e312e2/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 44478,
        "title": "Banyan Tree Ringha in Deqin | Hotel Rates & Reviews on Orbitz",
        "url": "https://pkslist.azureedge.net/media/images/1/4b5b19e0-2b20-45c4-a279-5657b2e7ee86/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4b5b19e0-2b20-45c4-a279-5657b2e7ee86/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4b5b19e0-2b20-45c4-a279-5657b2e7ee86/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 44471,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/661ff154-d8be-4f6e-9e0a-3999681dc7d2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/661ff154-d8be-4f6e-9e0a-3999681dc7d2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/661ff154-d8be-4f6e-9e0a-3999681dc7d2/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 44482,
        "title": "Banyan Tree Ringha - Tibetan Spa Suite - China | Banyan tree ...",
        "url": "https://pkslist.azureedge.net/media/images/1/8a6c2345-5494-4e28-a78a-279fc0545de5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8a6c2345-5494-4e28-a78a-279fc0545de5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8a6c2345-5494-4e28-a78a-279fc0545de5/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 44481,
        "title": "Extraordinary Eco Retreats and the Books To Read While You're ...",
        "url": "https://pkslist.azureedge.net/media/images/1/bd127051-ffe5-411d-ad56-8c1bc975bc6b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bd127051-ffe5-411d-ad56-8c1bc975bc6b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bd127051-ffe5-411d-ad56-8c1bc975bc6b/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 44472,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ab18840c-5d9a-45db-a136-d88e68ae3a14/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ab18840c-5d9a-45db-a136-d88e68ae3a14/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ab18840c-5d9a-45db-a136-d88e68ae3a14/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2847,
      "latitude": 27.762947,
      "longitude": 99.76812299999999,
      "parentGeographicAreaId": 0,
      "title": "China, Yunnan Sheng, Diqing Zangzuzizhizhou, Xianggelila Shi, 建塘镇红坡村",
      "updated": null,
      "created": "2021-01-19T12:19:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 61,
        "tagName": "Mountain",
        "tagType": 2
      },
      {
        "tagId": 156,
        "tagName": "Fireplace",
        "tagType": 2
      },
      {
        "tagId": 202,
        "tagName": "Private Terraces",
        "tagType": 2
      },
      {
        "tagId": 266,
        "tagName": "Nature Walk",
        "tagType": 2
      },
      {
        "tagId": 293,
        "tagName": "Meeting Space",
        "tagType": 2
      },
      {
        "tagId": 324,
        "tagName": "Local Village",
        "tagType": 2
      },
      {
        "tagId": 362,
        "tagName": "Sustainable",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 711,
        "venueCategoryId": 34,
        "venueId": 3061,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2994,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Lundies House",
    "description": "Inspired by a deep sense of place, community and by the beauty of its location, Lundies is a guesthouse of a very special kind. Here refined interiors and a profound sense of comfort combine to create a house of simple beauty and stillness to which you arrive to relax, explore and enjoy the magnificent surrounding countryside and landscape. Nestled between some of the most famous bens in Scotland, Lundies House looks over stone walls towards the ruins of Castle Bharrich as the landscape opens into Kyle of Tongue and Tongue Bay.\n\n\nThe main house, a converted B-listed manse will provide accommodation for up to 16 people in 3 double bedrooms and 1 top floor apartment. In addition, the house's steadings have been converted and remodelled to provide a further 3 contemporary courtyard studios, a semi self-contained cosy apartment, outdoor summer eating area and motorbike parking.",
    "brand": "",
    "blockQuote": "You will go on a gastronomic adventure with Traditional Highland meets Contemporary Nordic cuisine.",
    "emailAddress": "hello@lundies.scot",
    "website": "lundies.scot/",
    "bookingURL": "hello@wildland.scot",
    "redirectNotes": null,
    "phoneNumber": "+44 15 40 66 16 19",
    "addressOne": "",
    "addressTwo": "",
    "city": "Tongue",
    "county": "",
    "region": "Scotland",
    "country": "United Kingdom",
    "rooms": 8,
    "dollar": 1,
    "updated": "2020-11-19T12:20:06.923",
    "created": "2020-11-19T11:53:04.453",
    "medias": [
      {
        "mediaId": 42872,
        "title": "Lundies House - North Coast 500",
        "url": "https://pkslist.azureedge.net/media/images/1/02529c66-12b0-4dfb-b9b0-41a99eb81324/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/02529c66-12b0-4dfb-b9b0-41a99eb81324/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/02529c66-12b0-4dfb-b9b0-41a99eb81324/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 42861,
        "title": "Lundies House is a \"Scandi-Scot\" guest home inside a 17th-century ...",
        "url": "https://pkslist.azureedge.net/media/images/1/a142d3e2-5f1f-42a5-a942-b5012a38c7af/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a142d3e2-5f1f-42a5-a942-b5012a38c7af/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a142d3e2-5f1f-42a5-a942-b5012a38c7af/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 42853,
        "title": "The kitchen at Lundies House, blending practicality, beauty and ...",
        "url": "https://pkslist.azureedge.net/media/images/1/7f52f60e-edcf-49cb-9815-4fb3a74a7f77/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7f52f60e-edcf-49cb-9815-4fb3a74a7f77/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7f52f60e-edcf-49cb-9815-4fb3a74a7f77/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 42854,
        "title": "Lundies House: A Historical Manse Converted Into a Hygge-Ready ...",
        "url": "https://pkslist.azureedge.net/media/images/1/e7539d2e-9daa-4333-af18-a591dd07e218/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e7539d2e-9daa-4333-af18-a591dd07e218/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e7539d2e-9daa-4333-af18-a591dd07e218/256x192.png",
        "viewOrder": 3
      },
      {
        "mediaId": 42865,
        "title": "Lundies House: A Historical Manse Converted Into a Hygge-Ready ...",
        "url": "https://pkslist.azureedge.net/media/images/1/c989074e-39ec-48bf-b758-eb2698202fdb/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c989074e-39ec-48bf-b758-eb2698202fdb/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c989074e-39ec-48bf-b758-eb2698202fdb/256x192.png",
        "viewOrder": 4
      },
      {
        "mediaId": 42867,
        "title": "Lundies House Scotalnd | Design Addicts | Global Interior Design blog",
        "url": "https://pkslist.azureedge.net/media/images/1/d8a64bf3-a627-4d77-884c-dfa744f21c96/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d8a64bf3-a627-4d77-884c-dfa744f21c96/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d8a64bf3-a627-4d77-884c-dfa744f21c96/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 42866,
        "title": "Lundies House is a \"Scandi-Scot\" guest home inside a 17th-century ...",
        "url": "https://pkslist.azureedge.net/media/images/1/a82bf814-f7f1-4985-92fe-3c72cf802803/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a82bf814-f7f1-4985-92fe-3c72cf802803/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a82bf814-f7f1-4985-92fe-3c72cf802803/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 42862,
        "title": "https://www.dezeen.com/2019/08/29/lundies-house-tongue-guesthouses ...",
        "url": "https://pkslist.azureedge.net/media/images/1/f34c610d-8456-4151-abab-e1adc55a2b1a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f34c610d-8456-4151-abab-e1adc55a2b1a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f34c610d-8456-4151-abab-e1adc55a2b1a/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 42870,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d8c5443e-18e7-44c7-b37a-2b8d3599caff/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d8c5443e-18e7-44c7-b37a-2b8d3599caff/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d8c5443e-18e7-44c7-b37a-2b8d3599caff/256x192.jpg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2780,
      "latitude": 58.4780591,
      "longitude": -4.4173984,
      "parentGeographicAreaId": 0,
      "title": "Lundies House, Tongue, Lairg IV27 4XF, UK",
      "updated": null,
      "created": "2020-11-19T12:11:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 31,
        "tagName": "Countryside",
        "tagType": 2
      },
      {
        "tagId": 34,
        "tagName": "Cosy",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 134,
        "tagName": "Wilderness Walks",
        "tagType": 2
      },
      {
        "tagId": 204,
        "tagName": "Courtyard",
        "tagType": 2
      },
      {
        "tagId": 205,
        "tagName": "Design",
        "tagType": 2
      },
      {
        "tagId": 317,
        "tagName": "Gourmet",
        "tagType": 2
      },
      {
        "tagId": 324,
        "tagName": "Local Village",
        "tagType": 2
      },
      {
        "tagId": 439,
        "tagName": "Honesty Bar",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 702,
        "venueCategoryId": 34,
        "venueId": 2994,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2918,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Kinloch Lodge",
    "description": "A place so remote and far from your daily life, that you only have time to enjoy nature and appreciate all the place has to offer. A conservatory with wonderful views overlooking Ben Loyal. With great rooms for everybody if you need time on your own. With a chef and hospitality team who takes care of you in every detail, and a head keeper to assist you with your outdoor experiences. \n\nWhether you spend your time getting lost in the rugged landscape or curled up by the fire in the living room or chatting with the chef in the kitchen we hope you will make yourself at home.",
    "brand": "",
    "blockQuote": "A great place for salmon and sea trout fishing on the River Kinloch and Hope systems.",
    "emailAddress": "hello@kinloch.scot",
    "website": "kinloch.scot/Kinloch/Kinloch_Home.html",
    "bookingURL": "https://www.kinloch.scot/Kinloch/Kinloch_Booking_%26_Prices.html",
    "redirectNotes": null,
    "phoneNumber": "+44 154 066 1619",
    "addressOne": "",
    "addressTwo": "",
    "city": "Tongue",
    "county": "",
    "region": "Scotland",
    "country": "United Kingdom",
    "rooms": 7,
    "dollar": 3,
    "updated": "2020-10-28T22:33:10.847",
    "created": "2020-10-28T22:19:03.247",
    "medias": [
      {
        "mediaId": 41433,
        "title": "Kinloch Lodge - Sutherland, Scottish Highlands - Fish&Pips",
        "url": "https://pkslist.azureedge.net/media/images/1/9d1eb7a1-4dd1-49e0-8756-1d6acf50bb41/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9d1eb7a1-4dd1-49e0-8756-1d6acf50bb41/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9d1eb7a1-4dd1-49e0-8756-1d6acf50bb41/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 41438,
        "title": "Kinloch Lodge - Sutherland, Scottish Highlands - Fish&Pips",
        "url": "https://pkslist.azureedge.net/media/images/1/5a3465d2-06b2-48ed-95ed-8d86b84c241e/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5a3465d2-06b2-48ed-95ed-8d86b84c241e/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5a3465d2-06b2-48ed-95ed-8d86b84c241e/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 41432,
        "title": "Kinloch Lodge - Sutherland, Scottish Highlands - Fish&Pips",
        "url": "https://pkslist.azureedge.net/media/images/1/08722940-2f1d-4f1a-8f7c-b408a3bfca12/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/08722940-2f1d-4f1a-8f7c-b408a3bfca12/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/08722940-2f1d-4f1a-8f7c-b408a3bfca12/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 41439,
        "title": "Martin Kaufmann Photography - Kinloch Lodge in Scotland... in 2020 ...",
        "url": "https://pkslist.azureedge.net/media/images/1/a60f055e-a25e-4908-b939-1d3650af2db8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a60f055e-a25e-4908-b939-1d3650af2db8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a60f055e-a25e-4908-b939-1d3650af2db8/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 41440,
        "title": "Estates Archive - Wildland",
        "url": "https://pkslist.azureedge.net/media/images/1/935aecf0-e400-456a-a5cf-9e2460c4cd76/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/935aecf0-e400-456a-a5cf-9e2460c4cd76/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/935aecf0-e400-456a-a5cf-9e2460c4cd76/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 41434,
        "title": "Kinloch Lodge - Sutherland, Scottish Highlands - Fish&Pips",
        "url": "https://pkslist.azureedge.net/media/images/1/54fee0b2-94b7-4c84-8062-c4cee946c13b/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/54fee0b2-94b7-4c84-8062-c4cee946c13b/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/54fee0b2-94b7-4c84-8062-c4cee946c13b/256x192.jpeg",
        "viewOrder": 5
      },
      {
        "mediaId": 41435,
        "title": "Kinloch Lodge - Sutherland, Scottish Highlands - Fish&Pips",
        "url": "https://pkslist.azureedge.net/media/images/1/58e2c029-e8ff-4845-89f5-477c7ffa2ad9/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/58e2c029-e8ff-4845-89f5-477c7ffa2ad9/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/58e2c029-e8ff-4845-89f5-477c7ffa2ad9/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 41437,
        "title": "Kinloch Lodge - Sutherland, Scottish Highlands - Fish&Pips",
        "url": "https://pkslist.azureedge.net/media/images/1/fd17a3db-c33d-40c7-847e-d2750ff97b63/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fd17a3db-c33d-40c7-847e-d2750ff97b63/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fd17a3db-c33d-40c7-847e-d2750ff97b63/256x192.jpeg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2703,
      "latitude": 58.4327649,
      "longitude": -4.481222499999999,
      "parentGeographicAreaId": 0,
      "title": "Kinloch Lodge, Tongue, Lairg IV27 4YL, UK",
      "updated": null,
      "created": "2020-10-28T22:23:00"
    },
    "personalities": [
      {
        "tagId": 189,
        "tagName": "Exclusively Yours",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 34,
        "tagName": "Cosy",
        "tagType": 2
      },
      {
        "tagId": 109,
        "tagName": "Fishing",
        "tagType": 2
      },
      {
        "tagId": 156,
        "tagName": "Fireplace",
        "tagType": 2
      },
      {
        "tagId": 172,
        "tagName": "Biking",
        "tagType": 2
      },
      {
        "tagId": 198,
        "tagName": "Boat Excursion",
        "tagType": 2
      },
      {
        "tagId": 243,
        "tagName": "Outdoor Activities",
        "tagType": 2
      },
      {
        "tagId": 266,
        "tagName": "Nature Walk",
        "tagType": 2
      },
      {
        "tagId": 329,
        "tagName": "Hunting & Stalking",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 695,
        "venueCategoryId": 34,
        "venueId": 2918,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2830,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Ultima Thule",
    "description": "Ultima Thule is for people who want to experience the real Alaska. People who are craving wild places and raw experiences. To escape, for a moment into nature and come away with memories that will last a lifetime.\n\nUltima Thule is an adventure centric wilderness outpost inside the vast Wrangell/St. Elias Mountains. In a wilderness area where there are no roads, no maintained trails - it is all about the combination of land-based and bush plane powered adventures.\n\nWe sit on the banks of a raging glacier river inside the largest National Park in North America, part of UNESCO World Heritage Monument and the largest protected land mass on earth. Weather dictates our days and stays are available May-September.",
    "brand": "",
    "blockQuote": "Let an Alaskan bushpilot take you to some far out places in his Piper Super Cub.",
    "emailAddress": "info@ultimathulelodge.com",
    "website": "ultimathulelodge.com/",
    "bookingURL": "http://www.ultimathulelodge.com/contact",
    "redirectNotes": null,
    "phoneNumber": "+1 907 854 4500",
    "addressOne": "18714",
    "addressTwo": "Mink Creek Drive",
    "city": "Wrangell-St Elias National Park",
    "county": "",
    "region": "Alaska",
    "country": "United States",
    "rooms": 6,
    "dollar": 3,
    "updated": "2020-09-18T15:56:19.08",
    "created": "2020-09-18T15:06:03.517",
    "medias": [
      {
        "mediaId": 39488,
        "title": "Ultima Thule Lodge, Alaska | Luxury Hotels in The USA | Black Tomato",
        "url": "https://pkslist.azureedge.net/media/images/1/d0118200-32c1-4589-9f64-2c0a879f3c4f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d0118200-32c1-4589-9f64-2c0a879f3c4f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d0118200-32c1-4589-9f64-2c0a879f3c4f/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 39491,
        "title": "Ultima Thule Lodge in Alaska - 100 miles from nearest road | Daily ...",
        "url": "https://pkslist.azureedge.net/media/images/1/3729795a-540f-4b7a-a2af-a67c1b0d63b3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3729795a-540f-4b7a-a2af-a67c1b0d63b3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3729795a-540f-4b7a-a2af-a67c1b0d63b3/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 39493,
        "title": "The World's 8 Most Remote Hotels - EscapeHere",
        "url": "https://pkslist.azureedge.net/media/images/1/ca1a5de9-1b52-43d0-a7da-ed5ddb7daa97/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ca1a5de9-1b52-43d0-a7da-ed5ddb7daa97/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ca1a5de9-1b52-43d0-a7da-ed5ddb7daa97/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 39495,
        "title": "ULTIMA THULE LODGE | MASSERIAS",
        "url": "https://pkslist.azureedge.net/media/images/1/5ff60083-9e2d-4046-b40e-bb81e610ec1d/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5ff60083-9e2d-4046-b40e-bb81e610ec1d/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5ff60083-9e2d-4046-b40e-bb81e610ec1d/256x192.png",
        "viewOrder": 4
      },
      {
        "mediaId": 39503,
        "title": "Ultima Thule Alaska Lodge | An Exclusive Wilderness Adventure",
        "url": "https://pkslist.azureedge.net/media/images/1/bd07eb09-7ff5-4e39-9b31-922ee74d6dc6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bd07eb09-7ff5-4e39-9b31-922ee74d6dc6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bd07eb09-7ff5-4e39-9b31-922ee74d6dc6/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 39504,
        "title": "Alaska Wilderness Lodge | The True Alaskan Lodge Experience By ...",
        "url": "https://pkslist.azureedge.net/media/images/1/0f703326-a8ec-4cc3-be43-2f3cbeb7d920/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0f703326-a8ec-4cc3-be43-2f3cbeb7d920/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0f703326-a8ec-4cc3-be43-2f3cbeb7d920/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 39505,
        "title": "Ultima Thule Lodge | Uncrate",
        "url": "https://pkslist.azureedge.net/media/images/1/1ede6cf7-e7ef-4185-88ae-a20bfd1ffe02/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1ede6cf7-e7ef-4185-88ae-a20bfd1ffe02/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1ede6cf7-e7ef-4185-88ae-a20bfd1ffe02/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 39492,
        "title": "Ultima Thule Lodge - Wilderness Lodges, Alaska | Steppes Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/ff026d6d-0d19-4895-aac9-f0c248f8502f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ff026d6d-0d19-4895-aac9-f0c248f8502f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ff026d6d-0d19-4895-aac9-f0c248f8502f/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 39494,
        "title": "Ultima Thule Lodge, Alaska | Luxury Hotels in The USA | Black Tomato",
        "url": "https://pkslist.azureedge.net/media/images/1/32f67ab0-404e-45b9-b64c-90ca7d9d65df/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/32f67ab0-404e-45b9-b64c-90ca7d9d65df/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/32f67ab0-404e-45b9-b64c-90ca7d9d65df/256x192.jpg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2616,
      "latitude": 61.384222,
      "longitude": -149.534509,
      "parentGeographicAreaId": 0,
      "title": "18714 Mink Creek Dr, Chugiak, AK 99567, USA",
      "updated": null,
      "created": "2020-09-18T15:19:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 109,
        "tagName": "Fishing",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 193,
        "tagName": "Outdoor Dining",
        "tagType": 2
      },
      {
        "tagId": 237,
        "tagName": "Northern Lights",
        "tagType": 2
      },
      {
        "tagId": 243,
        "tagName": "Outdoor Activities",
        "tagType": 2
      },
      {
        "tagId": 252,
        "tagName": "National Park",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 348,
        "tagName": "Unesco World Heritage",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 657,
        "venueCategoryId": 34,
        "venueId": 2830,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2671,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Octola",
    "description": "In this project, colleagues from the world’s leading luxury travel companies have helped us with contributions to design and ideas. OCTOLA, the first five-star Lodge in Lapland was built for frequent, global travelers accustomed to luxurious five-star hotels. Being accommodated in a wooden chalet is the essence of Lapland, providing the most authentic stay in the Arctic. We offer total privacy in our own secluded wilderness area and all the exciting outdoor Arctic activities are on your doorstep. Our wilderness guides, chef and butler ensure that all your requirements and wishes are attended to.",
    "brand": "",
    "blockQuote": "In the winter, you can visit the private reindeer herding area and feed the reindeers.",
    "emailAddress": "janne@octola.com",
    "website": "octola.com/",
    "bookingURL": "http://octola.com/",
    "redirectNotes": "Octola's reservations system",
    "phoneNumber": "+358 44 515 17 96",
    "addressOne": "14",
    "addressTwo": "Teknotie",
    "city": "Rovaniemi",
    "county": "",
    "region": "Lapland",
    "country": "Finland",
    "rooms": 11,
    "dollar": 3,
    "updated": "2023-12-14T09:35:17.603",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 43382,
        "title": "Check in to the Northernmost hotel in the world",
        "url": "https://pkslist.azureedge.net/media/images/1/47e3e259-1a74-499c-936e-3015d71424b9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/47e3e259-1a74-499c-936e-3015d71424b9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/47e3e259-1a74-499c-936e-3015d71424b9/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 43375,
        "title": "Octola Lodge World Luxury Hotel Awards Nominee",
        "url": "https://pkslist.azureedge.net/media/images/1/8f0f04bf-5ae0-4192-872b-21a7de619f87/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8f0f04bf-5ae0-4192-872b-21a7de619f87/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8f0f04bf-5ae0-4192-872b-21a7de619f87/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 43376,
        "title": "Octola Lodge World Luxury Hotel Awards Nominee",
        "url": "https://pkslist.azureedge.net/media/images/1/a778cdf2-db20-4b47-91b9-9529f216529f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a778cdf2-db20-4b47-91b9-9529f216529f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a778cdf2-db20-4b47-91b9-9529f216529f/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 35482,
        "title": "Octola, Finland l Traveller Made",
        "url": "https://pkslist.azureedge.net/media/images/1/291de918-233e-49f2-8ff1-ade68956da04/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/291de918-233e-49f2-8ff1-ade68956da04/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/291de918-233e-49f2-8ff1-ade68956da04/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 35487,
        "title": "OCTOLA - Private Wilderness",
        "url": "https://pkslist.azureedge.net/media/images/1/09b0ca2a-270d-408b-90c5-a51af029de97/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/09b0ca2a-270d-408b-90c5-a51af029de97/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/09b0ca2a-270d-408b-90c5-a51af029de97/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 35480,
        "title": "OCTOLA - Private Wilderness",
        "url": "https://pkslist.azureedge.net/media/images/1/9b00623a-81c0-478d-a129-0ca1af6bd03c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9b00623a-81c0-478d-a129-0ca1af6bd03c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9b00623a-81c0-478d-a129-0ca1af6bd03c/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 43381,
        "title": "Octola | DJets",
        "url": "https://pkslist.azureedge.net/media/images/1/659455f2-9883-4860-b9f9-1350f9b6ae5f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/659455f2-9883-4860-b9f9-1350f9b6ae5f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/659455f2-9883-4860-b9f9-1350f9b6ae5f/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 35483,
        "title": "OCTOLA - Private Wilderness",
        "url": "https://pkslist.azureedge.net/media/images/1/01857fd2-dda4-4e75-bcdf-c41e64fbf93d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/01857fd2-dda4-4e75-bcdf-c41e64fbf93d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/01857fd2-dda4-4e75-bcdf-c41e64fbf93d/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 35488,
        "title": "Octola, Finland l Traveller Made",
        "url": "https://pkslist.azureedge.net/media/images/1/049b6f62-bbf0-439e-9342-de85f7cf3be5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/049b6f62-bbf0-439e-9342-de85f7cf3be5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/049b6f62-bbf0-439e-9342-de85f7cf3be5/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 43380,
        "title": "Octola Private Wilderness, Finland • Leo Trippi",
        "url": "https://pkslist.azureedge.net/media/images/1/5b9dcf01-ead7-4d73-b8e8-b97e97f8710f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5b9dcf01-ead7-4d73-b8e8-b97e97f8710f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5b9dcf01-ead7-4d73-b8e8-b97e97f8710f/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 35479,
        "title": "OCTOLA - Private Wilderness",
        "url": "https://pkslist.azureedge.net/media/images/1/34cdad1e-dc50-4c61-b731-5a6e7d3a8e2d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/34cdad1e-dc50-4c61-b731-5a6e7d3a8e2d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/34cdad1e-dc50-4c61-b731-5a6e7d3a8e2d/256x192.jpg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2448,
      "latitude": 66.5462314,
      "longitude": 25.8271961,
      "parentGeographicAreaId": 0,
      "title": "Teknotie 14, 96900 Rovaniemi, Finland",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 66,
        "tagName": "Skiing",
        "tagType": 2
      },
      {
        "tagId": 109,
        "tagName": "Fishing",
        "tagType": 2
      },
      {
        "tagId": 134,
        "tagName": "Wilderness Walks",
        "tagType": 2
      },
      {
        "tagId": 235,
        "tagName": "Hot Tub",
        "tagType": 2
      },
      {
        "tagId": 236,
        "tagName": "Dog Sledding",
        "tagType": 2
      },
      {
        "tagId": 237,
        "tagName": "Northern Lights",
        "tagType": 2
      },
      {
        "tagId": 243,
        "tagName": "Outdoor Activities",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 327,
        "tagName": "Snowmobiles",
        "tagType": 2
      },
      {
        "tagId": 340,
        "tagName": "Snow Shoeing",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 707,
        "venueCategoryId": 34,
        "venueId": 2671,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2564,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Casa Galavanta",
    "description": "Casa Galavanta is a private property located in the heart of Sierra Nevada de Santa Marta, a lush mountain range that rises from the Caribbean ocean up to 5,776 masl (meters above sea level). The house sits at 1,500 masl, between dense rainforest and coffee fields, with a perfect view of the ocean. This ecosystem is particularly unique because of the cloud forest formations that shelter an incredible biodiversity of flora and fauna.\n\nOnly here, one can experience a flood of clouds rise from the mountains, enter the house, and then slowly fade up into the sky for a breathtaking sunset – every afternoon.",
    "brand": "",
    "blockQuote": "A remote and isolated lodge with many incredible activities to be discovered.",
    "emailAddress": "info@galavanta.com",
    "website": "galavanta.com/portfolio/casagalavanta/",
    "bookingURL": "https://www.galavanta.com/contact-us/",
    "redirectNotes": null,
    "phoneNumber": "+57 320 809 6867 ",
    "addressOne": "",
    "addressTwo": "",
    "city": "Sierra Nevada de Santa Marta",
    "county": "",
    "region": "",
    "country": "Colombia",
    "rooms": 3,
    "dollar": 3,
    "updated": "2020-10-14T07:09:50.913",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 33956,
        "title": "Casa Galavanta | Luxury Hotels & Lodges in Colombia | Black Tomato",
        "url": "https://pkslist.azureedge.net/media/images/1/cbec2112-77e4-4d2e-88a4-fac20ae17628/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cbec2112-77e4-4d2e-88a4-fac20ae17628/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cbec2112-77e4-4d2e-88a4-fac20ae17628/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 33952,
        "title": "Casa Galavanta",
        "url": "https://pkslist.azureedge.net/media/images/1/1a7326a2-f9ca-4190-a144-ad59a529da58/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1a7326a2-f9ca-4190-a144-ad59a529da58/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1a7326a2-f9ca-4190-a144-ad59a529da58/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 33962,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/fe3eb4a1-5a27-4477-a556-998fa8a828ec/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fe3eb4a1-5a27-4477-a556-998fa8a828ec/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fe3eb4a1-5a27-4477-a556-998fa8a828ec/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 33953,
        "title": "Casa Galavanta, private property | Galavanta |",
        "url": "https://pkslist.azureedge.net/media/images/1/7baf2fb2-8585-4e0b-bc7e-3fa078f5e503/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7baf2fb2-8585-4e0b-bc7e-3fa078f5e503/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7baf2fb2-8585-4e0b-bc7e-3fa078f5e503/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 33949,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/3214f654-3ebf-464f-aeb2-16e144286ec5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3214f654-3ebf-464f-aeb2-16e144286ec5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3214f654-3ebf-464f-aeb2-16e144286ec5/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 33963,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b9b29890-89c2-46cd-b2c2-82925eda4454/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b9b29890-89c2-46cd-b2c2-82925eda4454/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b9b29890-89c2-46cd-b2c2-82925eda4454/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 33960,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/4acfd855-3bd0-4b67-a1fd-cacf614f23b2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4acfd855-3bd0-4b67-a1fd-cacf614f23b2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4acfd855-3bd0-4b67-a1fd-cacf614f23b2/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 33959,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a62de3cb-5d8c-4407-b246-dbd67d7a11a1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a62de3cb-5d8c-4407-b246-dbd67d7a11a1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a62de3cb-5d8c-4407-b246-dbd67d7a11a1/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2349,
      "latitude": 10.9331265,
      "longitude": -73.6001268,
      "parentGeographicAreaId": 0,
      "title": "Colombia",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 189,
        "tagName": "Exclusively Yours",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 77,
        "tagName": "Cooking Classes",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 124,
        "tagName": "Helicopter",
        "tagType": 2
      },
      {
        "tagId": 135,
        "tagName": "Bird Watching",
        "tagType": 2
      },
      {
        "tagId": 183,
        "tagName": "Butler Service",
        "tagType": 2
      },
      {
        "tagId": 198,
        "tagName": "Boat Excursion",
        "tagType": 2
      },
      {
        "tagId": 247,
        "tagName": "Coffee Plantation",
        "tagType": 2
      },
      {
        "tagId": 324,
        "tagName": "Local Village",
        "tagType": 2
      },
      {
        "tagId": 349,
        "tagName": "4X4 Off Roading",
        "tagType": 2
      },
      {
        "tagId": 365,
        "tagName": "Private Chef",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 691,
        "venueCategoryId": 34,
        "venueId": 2564,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2343,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Vipp Shelter",
    "description": "A peaceful hideaway awaits you in the Swedish woods. A 55 m2 black steel structure with all the necessities and nothing more. A chance to go offline and connect with nature. Every piece of the shelter's dark-toned interior is carefully selected to keep focus on what is important - nature.\n\nVipp’s legacy in steel work was put to use in designing Vipp Shelter. The shelter is a livable design object dropped down in nature. Check in to the Vipp shelter and check out from everyday life; gaze at the lake, listen to the sounds of the forest and cook to the calming crackling of the fireplace.",
    "brand": "",
    "blockQuote": "It's a nature and design lover's dream getaway.",
    "emailAddress": "stay@vipp.com",
    "website": "vipp.com/en/guesthouses/vipp-shelter",
    "bookingURL": "https://www.planyo.com/booking.php?calendar=31971&custom-language=EN&attribute_string=resource_id%3D88671%26mode%3Dresource_details%26lang%3DEN",
    "redirectNotes": "Vipp Shelter's reservations system",
    "phoneNumber": "+45 4588 8800",
    "addressOne": "",
    "addressTwo": "",
    "city": "Lake Immeln",
    "county": "",
    "region": "",
    "country": "Sweden",
    "rooms": 1,
    "dollar": 3,
    "updated": "2023-12-24T09:18:49.237",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 30094,
        "title": "THE PERFECT AUTUMN HIDEAWAY: VIPP SHELTER IN SWEDEN",
        "url": "https://pkslist.azureedge.net/media/images/1/e5e165f9-c5ec-483e-9f92-e990075b2e8a/1200x900.jpg?w=1120",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e5e165f9-c5ec-483e-9f92-e990075b2e8a/800x600.jpg?w=1120",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e5e165f9-c5ec-483e-9f92-e990075b2e8a/256x192.jpg?w=1120",
        "viewOrder": 0
      },
      {
        "mediaId": 63068,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/8639a183-a73d-4492-9bb5-a39067b914ad/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8639a183-a73d-4492-9bb5-a39067b914ad/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8639a183-a73d-4492-9bb5-a39067b914ad/256x192.png",
        "viewOrder": 1
      },
      {
        "mediaId": 30090,
        "title": "Vipp Shelter Photos - BluPrint",
        "url": "https://pkslist.azureedge.net/media/images/1/42d9d8a5-a1ca-4c23-8e94-9ff9045c08a8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/42d9d8a5-a1ca-4c23-8e94-9ff9045c08a8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/42d9d8a5-a1ca-4c23-8e94-9ff9045c08a8/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 30078,
        "title": "Vipp Shelter: Danish retailer creates idyllic retreat that can be ...",
        "url": "https://pkslist.azureedge.net/media/images/1/d400c584-3ec1-4efb-9df6-73c717b9a31e/1200x900.jpg?w968h681",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d400c584-3ec1-4efb-9df6-73c717b9a31e/800x600.jpg?w968h681",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d400c584-3ec1-4efb-9df6-73c717b9a31e/256x192.jpg?w968h681",
        "viewOrder": 3
      },
      {
        "mediaId": 63066,
        "title": "Vipp offers \"out of the ordinary\" hotel stays in a woodland cabin ...",
        "url": "https://pkslist.azureedge.net/media/images/1/e8e40b99-d285-42e6-92d2-68add151fd93/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e8e40b99-d285-42e6-92d2-68add151fd93/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e8e40b99-d285-42e6-92d2-68add151fd93/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 63070,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/2f38a629-4d7c-41e0-b0cc-c4fcd96d201b/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2f38a629-4d7c-41e0-b0cc-c4fcd96d201b/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2f38a629-4d7c-41e0-b0cc-c4fcd96d201b/256x192.png",
        "viewOrder": 5
      },
      {
        "mediaId": 30088,
        "title": "Visiting the Vipp Shelter | Ems Designblogg",
        "url": "https://pkslist.azureedge.net/media/images/1/c0e3370b-189d-4796-ac92-a901146cba31/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c0e3370b-189d-4796-ac92-a901146cba31/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c0e3370b-189d-4796-ac92-a901146cba31/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 30077,
        "title": "hadi shahabi - vipp shelter",
        "url": "https://pkslist.azureedge.net/media/images/1/5dd3c1dc-a5f1-4691-857b-526a30b39bad/1200x900.jpg?1514116639",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5dd3c1dc-a5f1-4691-857b-526a30b39bad/800x600.jpg?1514116639",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5dd3c1dc-a5f1-4691-857b-526a30b39bad/256x192.jpg?1514116639",
        "viewOrder": 7
      },
      {
        "mediaId": 30082,
        "title": "PETITE PASSPORT | VIPP SHELTER IMMELN - PETITE PASSPORT",
        "url": "https://pkslist.azureedge.net/media/images/1/3ed17ebd-937c-4471-88a9-72703f769c86/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3ed17ebd-937c-4471-88a9-72703f769c86/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3ed17ebd-937c-4471-88a9-72703f769c86/256x192.jpg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2140,
      "latitude": 56.2678729,
      "longitude": 14.325106499999947,
      "parentGeographicAreaId": 0,
      "title": "Immeln, Sweden",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 53,
        "tagName": "Cutting Edge",
        "tagType": 1
      },
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 34,
        "tagName": "Cosy",
        "tagType": 2
      },
      {
        "tagId": 104,
        "tagName": "Cycling",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 109,
        "tagName": "Fishing",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 135,
        "tagName": "Bird Watching",
        "tagType": 2
      },
      {
        "tagId": 156,
        "tagName": "Fireplace",
        "tagType": 2
      },
      {
        "tagId": 205,
        "tagName": "Design",
        "tagType": 2
      },
      {
        "tagId": 257,
        "tagName": "Canoeing",
        "tagType": 2
      },
      {
        "tagId": 263,
        "tagName": "Star Gazing",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 861,
        "venueCategoryId": 34,
        "venueId": 2343,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 862,
        "venueCategoryId": 9,
        "venueId": 2343,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 9,
          "title": "Scandinavian Break",
          "region": "Northern Europe",
          "viewOrder": 21,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2341,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Inis Meain Restaurant & Suites",
    "description": "Inis Meáin Restaurant & Suites was created by Marie-Thérèse and Ruairí de Blacam in 2007, with the aim of enabling guests to best experience their island location of unique natural beauty.\n\nThey designed and built it in a way that every detail is inspired by the island and a way of living that is necessary in such a remote place exposed to all the elements. There are just five private suites, each a minimum of 50 square meters and with a 10 meter expanse of glass revealing panoramic views of the island and sea. These expansive views are reflected in the suite interiors which feature wood, lime, stone, and wool.\n\nIt is an intimately private retreat, run personally by Ruairí and Marie-Thérèse and a small team. Both are widely travelled and have curated their best hotel experiences into their very unique hospitality offering - Ireland's original luxury nature lodge.",
    "brand": "",
    "blockQuote": "It's the kind of place where you'll hope for all four seasons in one day for an otherworldly experience.",
    "emailAddress": "post@inismeain.com",
    "website": "inismeain.com/",
    "bookingURL": "http:// https://inismeain.com/book",
    "redirectNotes": null,
    "phoneNumber": "+353 86 8266026",
    "addressOne": "",
    "addressTwo": "",
    "city": "Inis Meain",
    "county": "",
    "region": "",
    "country": "Ireland",
    "rooms": 5,
    "dollar": 2,
    "updated": "2020-09-28T18:34:19.103",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 40190,
        "title": "A Luxury Getaway to the Inis Meáin Restaurant & Suites in the Aran ...",
        "url": "https://pkslist.azureedge.net/media/images/1/f32a23b3-5b89-47ca-957c-39ebe3a5445c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f32a23b3-5b89-47ca-957c-39ebe3a5445c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f32a23b3-5b89-47ca-957c-39ebe3a5445c/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 40188,
        "title": "de Blacam and Meagher Architects · Inis Meáin Restaurant & Suites ...",
        "url": "https://pkslist.azureedge.net/media/images/1/df0e0ff3-84f3-496b-9f91-cb8867e57cbd/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/df0e0ff3-84f3-496b-9f91-cb8867e57cbd/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/df0e0ff3-84f3-496b-9f91-cb8867e57cbd/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 30050,
        "title": "Inis Meáin Restaurant, Aran Islands | Birding and Dining at the ...",
        "url": "https://pkslist.azureedge.net/media/images/1/0e8921fb-7aa8-439d-8933-304849ec3141/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0e8921fb-7aa8-439d-8933-304849ec3141/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0e8921fb-7aa8-439d-8933-304849ec3141/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 30052,
        "title": "Suites | Inis Meáin Restaurant & Suites",
        "url": "https://pkslist.azureedge.net/media/images/1/b07cbe30-44aa-4a01-bb75-255c628dc777/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b07cbe30-44aa-4a01-bb75-255c628dc777/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b07cbe30-44aa-4a01-bb75-255c628dc777/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 30053,
        "title": "Suites | Inis Meáin Restaurant & Suites",
        "url": "https://pkslist.azureedge.net/media/images/1/e4d34039-a99c-49f1-b856-e7cc7cf501bf/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e4d34039-a99c-49f1-b856-e7cc7cf501bf/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e4d34039-a99c-49f1-b856-e7cc7cf501bf/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 30044,
        "title": "Inis Meáin Restaurant & Suites : URLAUBSARCHITEKTUR ...",
        "url": "https://pkslist.azureedge.net/media/images/1/d792d533-f49b-49ee-a5e4-cb9b194fbe90/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d792d533-f49b-49ee-a5e4-cb9b194fbe90/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d792d533-f49b-49ee-a5e4-cb9b194fbe90/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 40191,
        "title": "de Blacam and Meagher Architects · Inis Meáin Restaurant & Suites ...",
        "url": "https://pkslist.azureedge.net/media/images/1/ec8316ae-0728-45d5-a893-42c48075bdd1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ec8316ae-0728-45d5-a893-42c48075bdd1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ec8316ae-0728-45d5-a893-42c48075bdd1/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 30048,
        "title": "Suites | Inis Meáin Restaurant & Suites",
        "url": "https://pkslist.azureedge.net/media/images/1/5a00fb6e-2d78-48f6-b3f5-130ecb8c2f6c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5a00fb6e-2d78-48f6-b3f5-130ecb8c2f6c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5a00fb6e-2d78-48f6-b3f5-130ecb8c2f6c/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 30051,
        "title": "Inis Meáin Restaurant & Suites | Aran Islands Georgina Campbell Guides",
        "url": "https://pkslist.azureedge.net/media/images/1/18f8acae-cfab-4409-aaa5-8ac4608539b8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/18f8acae-cfab-4409-aaa5-8ac4608539b8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/18f8acae-cfab-4409-aaa5-8ac4608539b8/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 40192,
        "title": "An Dun B&B and Café, Inis Meain, Ireland - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/3f3041d0-ba91-43ff-8551-e67328173dc7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3f3041d0-ba91-43ff-8551-e67328173dc7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3f3041d0-ba91-43ff-8551-e67328173dc7/256x192.jpg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2138,
      "latitude": 53.08651279999999,
      "longitude": -9.584231499999987,
      "parentGeographicAreaId": 0,
      "title": "Inis Meáin Restaurant & Suites Inis Meáin, Carrownlisheen, Inishmaan, Co. Galway, Ireland",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 58,
        "tagName": "Beach",
        "tagType": 2
      },
      {
        "tagId": 59,
        "tagName": "Cultural",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 109,
        "tagName": "Fishing",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 114,
        "tagName": "Pub",
        "tagType": 2
      },
      {
        "tagId": 172,
        "tagName": "Biking",
        "tagType": 2
      },
      {
        "tagId": 205,
        "tagName": "Design",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 317,
        "tagName": "Gourmet",
        "tagType": 2
      },
      {
        "tagId": 324,
        "tagName": "Local Village",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 679,
        "venueCategoryId": 34,
        "venueId": 2341,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2315,
    "venueCategoryId": 0,
    "status": 1,
    "title": "La Lancha",
    "description": "Set in the rainforest high above the shores of Lago Petén Itzá is La Lancha, a quaint, 10-room lodge decorated with furniture, fabrics, and carvings from Guatemala personally selected by the Coppola family. Nearby is Tikal, the epicenter of Maya spirituality. Buried deep within the rainforest, the ruins include more than 3,000 structures where moss clings to the steps of ancient temples and plazas.\n\nWith its beautiful lakeshore setting, relaxed ambiance, and close proximity to the lost world of Maya, La Lancha is an ideal destination to both enjoy a languid pace and explore the many adventures of the Petén region.",
    "brand": null,
    "blockQuote": "Lake View Suites are my favorite since look out over the gorgeous Lago Petén Itzá.",
    "emailAddress": "info@thefamilycoppolahideaways.com",
    "website": "thefamilycoppolahideaways.com/en/la-lancha",
    "bookingURL": "https://www.booking.com/hotel/gt/la-lancha.en-gb.html?aid=925669;label=gog235jc-1DCAsoXkIJbGEtbGFuY2hhSDNYA2iwAYgBAZgBCbgBGMgBDNgBA-gBAYgCAagCBLgC3sSH5gXAAgE;sid=6b10f0abd2fe50a3592a2bb374b7ab7f;dist=0&keep_landing=1&sb_price_type=total&type=total&",
    "redirectNotes": null,
    "phoneNumber": "+502 3045 0817",
    "addressOne": "",
    "addressTwo": "",
    "city": "Lake Peten Itza",
    "county": "",
    "region": "",
    "country": "Guatemala",
    "rooms": 10,
    "dollar": 1,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 29627,
        "title": "Guatemala Luxury Resort, Boutique Guatemala Hotel Amenities ...",
        "url": "https://pkslist.azureedge.net/media/images/1/55828ecd-bd57-49e2-b6b3-6c45c36fad94/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/55828ecd-bd57-49e2-b6b3-6c45c36fad94/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/55828ecd-bd57-49e2-b6b3-6c45c36fad94/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 29626,
        "title": "Lodge La Lancha, El Remate, Guatemala - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/e52ae9bf-4761-4e0e-a271-355c70070d2a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e52ae9bf-4761-4e0e-a271-355c70070d2a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e52ae9bf-4761-4e0e-a271-355c70070d2a/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 29630,
        "title": "Boutique Hotels Guatemala, Guatemala Resort - Coppola ...",
        "url": "https://pkslist.azureedge.net/media/images/1/be9a4901-efd0-4d5a-a08f-5b4e8bce60e4/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/be9a4901-efd0-4d5a-a08f-5b4e8bce60e4/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/be9a4901-efd0-4d5a-a08f-5b4e8bce60e4/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 29635,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/61afa601-feb7-44c8-a860-5b16d1ee49a8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/61afa601-feb7-44c8-a860-5b16d1ee49a8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/61afa601-feb7-44c8-a860-5b16d1ee49a8/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 29634,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c68cab2c-5589-4415-8957-5b2b379ddd0e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c68cab2c-5589-4415-8957-5b2b379ddd0e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c68cab2c-5589-4415-8957-5b2b379ddd0e/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 29640,
        "title": "La Lancha | Luxury Hotel in Guatemala Central America",
        "url": "https://pkslist.azureedge.net/media/images/1/4236eb31-e599-4e91-9851-e22129a660cd/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4236eb31-e599-4e91-9851-e22129a660cd/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4236eb31-e599-4e91-9851-e22129a660cd/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 29639,
        "title": "La Lancha, Guatemala, The Family Coppola Resorts – Belize Travel ...",
        "url": "https://pkslist.azureedge.net/media/images/1/69e79043-029e-462d-995e-3ffaa385b540/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/69e79043-029e-462d-995e-3ffaa385b540/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/69e79043-029e-462d-995e-3ffaa385b540/256x192.jpg",
        "viewOrder": 6
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2115,
      "latitude": 16.99932,
      "longitude": -89.70032000000003,
      "parentGeographicAreaId": 0,
      "title": "Lake Peten Itza, El Remate, Guatemala",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 47,
        "tagName": "Intimate Luxury ",
        "tagType": 1
      },
      {
        "tagId": 57,
        "tagName": "Romantic",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 75,
        "tagName": "Casual Dining",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 164,
        "tagName": "Excursions",
        "tagType": 2
      },
      {
        "tagId": 171,
        "tagName": "Trekking",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 229,
        "tagName": "Private Balconies",
        "tagType": 2
      },
      {
        "tagId": 235,
        "tagName": "Hot Tub",
        "tagType": 2
      },
      {
        "tagId": 257,
        "tagName": "Canoeing",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 650,
        "venueCategoryId": 34,
        "venueId": 2315,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 676,
        "venueCategoryId": 33,
        "venueId": 2315,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 33,
          "title": "Rustic Elegance",
          "region": "Global",
          "viewOrder": 11,
          "updated": "2020-09-04T09:04:00",
          "created": "2020-09-04T09:04:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2303,
    "venueCategoryId": 19,
    "status": 1,
    "title": "Islas Secas",
    "description": "At Islas Secas, we share with our guests a spirit of adventure and discovery by providing them with world-class experiences that connect them to the timeless natural beauty and ecological abundance of Panama’s Pacific Coast. With 75% of the archipelago left undeveloped and a property that is 100% powered by natural energy, we aim to create a model for sustainable tourism, combining exhilarating recreation and effortless luxury with a strong ethos of environmental stewardship and community support.\n\n14 islands, 20 miles from civilization. Welcoming up to 18 guests at a time. Guests return from daily explorations to their own private sanctuary of modern comfort and barefoot luxury. The casitas are nestled in tropical gardens with astounding views, giving guests privacy and intimacy at all times of the day and night. Each site has an expansive outdoor deck complete with a plunge pool and thatched-roof cabana. Relax into the sea views. At Islas Secas, your Panama experience is a true escape from the outside world, ready for you to explore at your own pace.",
    "brand": "",
    "blockQuote": "Go for a romantic castaway picnic on your own private island.",
    "emailAddress": "info@islassecas.com",
    "website": "islassecas.com/",
    "bookingURL": "https://islassecas.com/trip-planner/",
    "redirectNotes": null,
    "phoneNumber": "+1 800 377 8877",
    "addressOne": "",
    "addressTwo": "",
    "city": "Islas Secas Archipelago",
    "county": "",
    "region": "",
    "country": "Panama",
    "rooms": 8,
    "dollar": 3,
    "updated": "2020-05-13T20:15:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 29382,
        "title": "Exploring Islas Secas, Panama's Hottest New Luxury Destination ...",
        "url": "https://pkslist.azureedge.net/media/images/1/edda6c9c-cebf-4360-8fed-7d9abc41d7a2/1200x900.jpg?itok=v5oflEgr",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/edda6c9c-cebf-4360-8fed-7d9abc41d7a2/800x600.jpg?itok=v5oflEgr",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/edda6c9c-cebf-4360-8fed-7d9abc41d7a2/256x192.jpg?itok=v5oflEgr",
        "viewOrder": 0
      },
      {
        "mediaId": 29377,
        "title": "Eco-Luxury Island Resort Islas Secas Reserve & Lodge Opens in ...",
        "url": "https://pkslist.azureedge.net/media/images/1/c90c93ba-03fd-4357-98f0-4d5acd4a3030/1200x900.jpg?w=1000",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c90c93ba-03fd-4357-98f0-4d5acd4a3030/800x600.jpg?w=1000",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c90c93ba-03fd-4357-98f0-4d5acd4a3030/256x192.jpg?w=1000",
        "viewOrder": 1
      },
      {
        "mediaId": 29376,
        "title": "Panama Eco-Retreat Proves the World Is Still Full of Surprises ...",
        "url": "https://pkslist.azureedge.net/media/images/1/50812a57-0b3d-437d-924e-0f6cb0519f84/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/50812a57-0b3d-437d-924e-0f6cb0519f84/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/50812a57-0b3d-437d-924e-0f6cb0519f84/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 29379,
        "title": "A Pacific island escape in Panama: Islas Secas Private Marine ...",
        "url": "https://pkslist.azureedge.net/media/images/1/94b8ecd1-7b10-4206-9e7a-938c4d3e8451/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/94b8ecd1-7b10-4206-9e7a-938c4d3e8451/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/94b8ecd1-7b10-4206-9e7a-938c4d3e8451/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 29383,
        "title": "Islas Secas Bring Eco-Luxury to Panama",
        "url": "https://pkslist.azureedge.net/media/images/1/d4a82326-36fc-4922-ad8c-821f4f3b69d9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d4a82326-36fc-4922-ad8c-821f4f3b69d9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d4a82326-36fc-4922-ad8c-821f4f3b69d9/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 29374,
        "title": "Islas Secas Reserve & Lodge on Twitter: \"We love this @InsideHook ...",
        "url": "https://pkslist.azureedge.net/media/images/1/28a896b3-39a7-4287-91b3-63e6ffc2c411/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/28a896b3-39a7-4287-91b3-63e6ffc2c411/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/28a896b3-39a7-4287-91b3-63e6ffc2c411/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 29386,
        "title": "Islas Secas Reserve & Lodge",
        "url": "https://pkslist.azureedge.net/media/images/1/dc6a6c39-b0b2-4753-981f-7f7b53df7be1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/dc6a6c39-b0b2-4753-981f-7f7b53df7be1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/dc6a6c39-b0b2-4753-981f-7f7b53df7be1/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 29380,
        "title": "Islas Secas Reserve & Lodge",
        "url": "https://pkslist.azureedge.net/media/images/1/60d7801e-f03e-4ad0-b192-8b32f4cd57c6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/60d7801e-f03e-4ad0-b192-8b32f4cd57c6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/60d7801e-f03e-4ad0-b192-8b32f4cd57c6/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 29385,
        "title": "10 x gloedjenieuw: deze hotels openen in 2019 |",
        "url": "https://pkslist.azureedge.net/media/images/1/4ab7fe0d-2740-4230-855c-b9d20419eccd/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4ab7fe0d-2740-4230-855c-b9d20419eccd/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4ab7fe0d-2740-4230-855c-b9d20419eccd/256x192.jpg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2102,
      "latitude": 7.981983199999999,
      "longitude": -82.03117780000002,
      "parentGeographicAreaId": 0,
      "title": "Islas Secas, Panama",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 50,
        "tagName": "Barefoot Luxury",
        "tagType": 1
      },
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 58,
        "tagName": "Beach",
        "tagType": 2
      },
      {
        "tagId": 70,
        "tagName": "Families",
        "tagType": 2
      },
      {
        "tagId": 71,
        "tagName": "Group of Friends",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 109,
        "tagName": "Fishing",
        "tagType": 2
      },
      {
        "tagId": 116,
        "tagName": "Scuba Diving",
        "tagType": 2
      },
      {
        "tagId": 187,
        "tagName": "Island",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 441,
        "venueCategoryId": 19,
        "venueId": 2303,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 19,
          "title": "Private Islands",
          "region": "Global",
          "viewOrder": 15,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 532,
        "venueCategoryId": 5,
        "venueId": 2303,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 5,
          "title": "Romantic Getaways",
          "region": "Global",
          "viewOrder": 20,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 646,
        "venueCategoryId": 34,
        "venueId": 2303,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2234,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Ol Malo",
    "description": "Perched on the cliffs of the Laikipia Plateau and overlooking a panoramic skyline of untouched valley, Ol Malo is Northern Kenya’s original luxury bush home. The rooms are safari-inspired and made with wood and natural materials from the land, with added Samburu beading flare. Ol Malo Lodge is ideal for couples and honeymooners looking for an escape. Lovebirds can enjoy private safaris, romantic dinners under the stars, and overnighting in The Treehouse, and much more.\n\nFor private use, we can accommodate an exclusive buyout of the six-bedroom Ol Malo House.\n\nTake your Kenya expedition one step further with an unforgettable Ol Malo Nomad trek. Nomad expeditions can accommodate two to eight people for a minimum of two nights.",
    "brand": "",
    "blockQuote": "A safari with camels is such a unique experience, I've never seen anything like it!",
    "emailAddress": "info@olmalo.com",
    "website": "olmalo.com/",
    "bookingURL": "https://olmalo.com/stay-with-us/",
    "redirectNotes": "Ol Malo's reservations system",
    "phoneNumber": "+254 20 600 0457",
    "addressOne": "",
    "addressTwo": "",
    "city": "Laikipia Plateau",
    "county": "",
    "region": "Laikipia",
    "country": "Kenya",
    "rooms": 10,
    "dollar": 3,
    "updated": "2021-10-11T06:22:02.863",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 28164,
        "title": "Secluded Safaris | Ol Malo Lodge",
        "url": "https://pkslist.azureedge.net/media/images/1/82dfc695-b160-4953-bb35-e7089b717575/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/82dfc695-b160-4953-bb35-e7089b717575/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/82dfc695-b160-4953-bb35-e7089b717575/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 49912,
        "title": "Ol Malo Lodge & House | Laikipia, Kenya | Extraordinary Journeys",
        "url": "https://pkslist.azureedge.net/media/images/1/ffadd975-b63e-47a6-9431-3aebf1dcaadc/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ffadd975-b63e-47a6-9431-3aebf1dcaadc/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ffadd975-b63e-47a6-9431-3aebf1dcaadc/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 49906,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c703b6fa-b237-4cde-8071-31a64de95640/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c703b6fa-b237-4cde-8071-31a64de95640/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c703b6fa-b237-4cde-8071-31a64de95640/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 49905,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/628702b3-511a-432b-80e4-fbd542276c36/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/628702b3-511a-432b-80e4-fbd542276c36/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/628702b3-511a-432b-80e4-fbd542276c36/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 49915,
        "title": "OL MALO - Updated 2021 Campground Reviews (Nanyuki Municipality ...",
        "url": "https://pkslist.azureedge.net/media/images/1/08102f8f-4e89-4ce0-8631-17dd571fe1a2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/08102f8f-4e89-4ce0-8631-17dd571fe1a2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/08102f8f-4e89-4ce0-8631-17dd571fe1a2/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 49918,
        "title": "OL MALO - Updated 2021 Campground Reviews (Nanyuki Municipality ...",
        "url": "https://pkslist.azureedge.net/media/images/1/e018f8f7-47b5-4707-9339-3be09742bbbe/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e018f8f7-47b5-4707-9339-3be09742bbbe/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e018f8f7-47b5-4707-9339-3be09742bbbe/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 49907,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/4e510f5c-22a4-46ac-b3c0-c30936d1c2bb/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4e510f5c-22a4-46ac-b3c0-c30936d1c2bb/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4e510f5c-22a4-46ac-b3c0-c30936d1c2bb/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 28165,
        "title": "Ol Malo House - Bush and Beyond - Bush and Beyond",
        "url": "https://pkslist.azureedge.net/media/images/1/2dbb6c5b-c2a1-4632-8478-aa77f44b916f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2dbb6c5b-c2a1-4632-8478-aa77f44b916f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2dbb6c5b-c2a1-4632-8478-aa77f44b916f/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 49908,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/057c7d22-abaf-44e9-891f-aae2ad9aa101/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/057c7d22-abaf-44e9-891f-aae2ad9aa101/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/057c7d22-abaf-44e9-891f-aae2ad9aa101/256x192.png",
        "viewOrder": 12
      },
      {
        "mediaId": 49911,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/73202716-d6f1-4a4e-8192-089087ef89db/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/73202716-d6f1-4a4e-8192-089087ef89db/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/73202716-d6f1-4a4e-8192-089087ef89db/256x192.jpeg",
        "viewOrder": 14
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2037,
      "latitude": 0.6121821,
      "longitude": 36.883622100000025,
      "parentGeographicAreaId": 0,
      "title": "Isiolo, Kenya",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 82,
        "tagName": "Horseback Riding",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 109,
        "tagName": "Fishing",
        "tagType": 2
      },
      {
        "tagId": 124,
        "tagName": "Helicopter",
        "tagType": 2
      },
      {
        "tagId": 132,
        "tagName": "Camel Trek",
        "tagType": 2
      },
      {
        "tagId": 159,
        "tagName": "Mountain Biking",
        "tagType": 2
      },
      {
        "tagId": 263,
        "tagName": "Star Gazing",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 324,
        "tagName": "Local Village",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 729,
        "venueCategoryId": 34,
        "venueId": 2234,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2227,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Hotel MariMari",
    "description": "With its idyllic setting of 9,000 acres of lush terrain and some 13 miles of Pacific coastline, Mari Mari Natural Reserve is South America’s premier luxury eco resort. This spectacular natural paradise is the setting for explorations on land or at sea, on foot or horseback, by kayak or boat, with binoculars or snorkels, while providing the creature comforts and exquisite cuisine expected of a five-star resort.",
    "brand": null,
    "blockQuote": "A beach walk is lovely any time of the day, but at sunset, it's the most beautiful.",
    "emailAddress": "manuel@hotelmarimari.com",
    "website": "hotelmarimari.com/eng/",
    "bookingURL": "https://app.thebookingbutton.com/properties/marimarispadirect?check_in_date=13-03-2019&check_out_date=14-03-2019&number_adults=2&iata=96027890",
    "redirectNotes": null,
    "phoneNumber": "+56 9 90912364",
    "addressOne": "",
    "addressTwo": "",
    "city": "Los Muermos",
    "county": "",
    "region": "",
    "country": "Chile",
    "rooms": 6,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 28058,
        "title": "Estadounidense invirtió cerca de US$11 millones en resort de Los ...",
        "url": "https://pkslist.azureedge.net/media/images/1/0839ed73-9137-4bd9-9406-47c9b5ffe9f3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0839ed73-9137-4bd9-9406-47c9b5ffe9f3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0839ed73-9137-4bd9-9406-47c9b5ffe9f3/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 28056,
        "title": "Hotel Mari Mari | Luxury African Safaris,South America & South ...",
        "url": "https://pkslist.azureedge.net/media/images/1/f058eeac-e427-406a-b7fb-23fce9553766/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f058eeac-e427-406a-b7fb-23fce9553766/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f058eeac-e427-406a-b7fb-23fce9553766/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 28052,
        "title": "Hotel Mari Mari | Luxury African Safaris,South America & South ...",
        "url": "https://pkslist.azureedge.net/media/images/1/fa9873ff-b9eb-4ab0-b981-f87c0476eb3e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fa9873ff-b9eb-4ab0-b981-f87c0476eb3e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fa9873ff-b9eb-4ab0-b981-f87c0476eb3e/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 28054,
        "title": "Hotel Mari Mari | Luxury African Safaris,South America & South ...",
        "url": "https://pkslist.azureedge.net/media/images/1/93d0b596-d4bd-42a4-bcf2-7857e442600c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/93d0b596-d4bd-42a4-bcf2-7857e442600c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/93d0b596-d4bd-42a4-bcf2-7857e442600c/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 28051,
        "title": "Home - Hotel MariMari",
        "url": "https://pkslist.azureedge.net/media/images/1/d9bab90a-1063-4445-b55c-e6d4bbc88c89/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d9bab90a-1063-4445-b55c-e6d4bbc88c89/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d9bab90a-1063-4445-b55c-e6d4bbc88c89/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 28055,
        "title": "HOTEL MARI MARI - Updated 2019 Prices & Lodge Reviews (Los Muermos ...",
        "url": "https://pkslist.azureedge.net/media/images/1/724ffc39-be42-491d-b3bc-65c21846da1f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/724ffc39-be42-491d-b3bc-65c21846da1f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/724ffc39-be42-491d-b3bc-65c21846da1f/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 28057,
        "title": "Mari Mari Hotel & Natural Reserve | Swoop Patagonia",
        "url": "https://pkslist.azureedge.net/media/images/1/80e3a0ff-8976-42d9-9185-2a4b68585df1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/80e3a0ff-8976-42d9-9185-2a4b68585df1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/80e3a0ff-8976-42d9-9185-2a4b68585df1/256x192.jpg",
        "viewOrder": 6
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2030,
      "latitude": -41.4634543,
      "longitude": -73.84066310000003,
      "parentGeographicAreaId": 0,
      "title": "Los Muermos, Los Lagos Region, Chile",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 58,
        "tagName": "Beach",
        "tagType": 2
      },
      {
        "tagId": 82,
        "tagName": "Horseback Riding",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 115,
        "tagName": "Snorkeling",
        "tagType": 2
      },
      {
        "tagId": 159,
        "tagName": "Mountain Biking",
        "tagType": 2
      },
      {
        "tagId": 170,
        "tagName": "Kayaking",
        "tagType": 2
      },
      {
        "tagId": 198,
        "tagName": "Boat Excursion",
        "tagType": 2
      },
      {
        "tagId": 238,
        "tagName": "Whale Watching",
        "tagType": 2
      },
      {
        "tagId": 268,
        "tagName": "Massage",
        "tagType": 2
      },
      {
        "tagId": 276,
        "tagName": "Gym",
        "tagType": 2
      },
      {
        "tagId": 293,
        "tagName": "Meeting Space",
        "tagType": 2
      },
      {
        "tagId": 318,
        "tagName": "Stand Up Paddle",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 651,
        "venueCategoryId": 34,
        "venueId": 2227,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2214,
    "venueCategoryId": 0,
    "status": 1,
    "title": "EOLO - Patagonia's Spirit",
    "description": "EOLO embodies the spirit of Patagonia. Built on the land where the first creole and European immigrants of the area initially set foot, one can't help but sense the aura of adventure that the thousands of travelers who journeyed through this area have left behind. Visitors not only leave their mark but also depart with a sense of having been deeply transformed by the experience.\n\nThe hotel is conveniently located halfway between the city of El Calafate and Los Glaciares National Park, yet far from the commotion of the tourist activity in the area. The 10,000 acres property is a paradise for horseback riding, mountain bike rides, trekking, bird watching and, of course, to fully enjoy the subtle elegance of the installations.\n\nFrom anywhere on the property, and from all of its 17 rooms, guests can witness the magnificence of a scenery that exposes its soul to the privileged eyes that contemplate it and to the hearts that wish to feel its true essence.",
    "brand": "",
    "blockQuote": "Perito Moreno Glacier is a must-visit and choose the option to walk on the ice using crampons.",
    "emailAddress": "reservas@eolopatagonia.com",
    "website": "eolopatagonia.com/",
    "bookingURL": "https://be.synxis.com/?adult=2&agencyid=96027890&arrive=2022-05-12&chain=8565&child=0&config=EOLOconfig&currency=USD&depart=2022-05-13&hotel=27573&level=hotel&locale=en-US&rooms=1&theme=EOLOtheme",
    "redirectNotes": "EOLO - Patagonia's Spirit's reservations system",
    "phoneNumber": "+54 11  4700 0075",
    "addressOne": "",
    "addressTwo": "",
    "city": "El Calafate",
    "county": "",
    "region": "",
    "country": "Argentina",
    "rooms": 17,
    "dollar": 3,
    "updated": "2022-05-12T06:52:35.063",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 27816,
        "title": "EOLO - Patagonia's Spirit",
        "url": "https://pkslist.azureedge.net/media/images/1/b2fa7f2f-3371-4890-996a-fc8855abc112/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b2fa7f2f-3371-4890-996a-fc8855abc112/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b2fa7f2f-3371-4890-996a-fc8855abc112/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 27815,
        "title": "EOLO - Patagonia's Spirit",
        "url": "https://pkslist.azureedge.net/media/images/1/f338434b-f31c-4412-bcf5-24935154121a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f338434b-f31c-4412-bcf5-24935154121a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f338434b-f31c-4412-bcf5-24935154121a/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 27819,
        "title": "EOLO - Patagonia's Spirit was conceived as a place of quiet charm ...",
        "url": "https://pkslist.azureedge.net/media/images/1/e4b89d02-42df-457d-9a2d-83752a65fa24/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e4b89d02-42df-457d-9a2d-83752a65fa24/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e4b89d02-42df-457d-9a2d-83752a65fa24/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 27810,
        "title": "Hotel Eolo Patagonia Spirit, El Calafate, Argentina - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/ed27bc9a-7a41-478f-a926-a64a3272f007/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ed27bc9a-7a41-478f-a926-a64a3272f007/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ed27bc9a-7a41-478f-a926-a64a3272f007/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 27811,
        "title": "Hotel Eolo Patagonia Spirit, El Calafate, Argentina - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/003fc5a1-5758-4fbe-abdc-710a260a1a50/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/003fc5a1-5758-4fbe-abdc-710a260a1a50/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/003fc5a1-5758-4fbe-abdc-710a260a1a50/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 27821,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d64941fb-0aab-40f1-bc08-2c029badcd89/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d64941fb-0aab-40f1-bc08-2c029badcd89/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d64941fb-0aab-40f1-bc08-2c029badcd89/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 27820,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ae33b7b0-b26d-4cec-b8a2-0425221a7d69/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ae33b7b0-b26d-4cec-b8a2-0425221a7d69/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ae33b7b0-b26d-4cec-b8a2-0425221a7d69/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 27822,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b2ca2c6b-fe74-4d7a-a185-61b05746397b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b2ca2c6b-fe74-4d7a-a185-61b05746397b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b2ca2c6b-fe74-4d7a-a185-61b05746397b/256x192.jpg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2017,
      "latitude": -50.36626,
      "longitude": -72.57513399999999,
      "parentGeographicAreaId": 0,
      "title": "Ruta 11 km 23, Z9405 El Calafate, Santa Cruz, Argentina",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 47,
        "tagName": "Intimate Luxury ",
        "tagType": 1
      },
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 60,
        "tagName": "Gastronomic",
        "tagType": 2
      },
      {
        "tagId": 82,
        "tagName": "Horseback Riding",
        "tagType": 2
      },
      {
        "tagId": 86,
        "tagName": "Indoor Pool",
        "tagType": 2
      },
      {
        "tagId": 135,
        "tagName": "Bird Watching",
        "tagType": 2
      },
      {
        "tagId": 159,
        "tagName": "Mountain Biking",
        "tagType": 2
      },
      {
        "tagId": 171,
        "tagName": "Trekking",
        "tagType": 2
      },
      {
        "tagId": 252,
        "tagName": "National Park",
        "tagType": 2
      },
      {
        "tagId": 349,
        "tagName": "4X4 Off Roading",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 652,
        "venueCategoryId": 34,
        "venueId": 2214,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2101,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Kachi Lodge",
    "description": "Standing directly on the Uyuni Salt Flats,at an altitude of 3’600 m, at the foot of Tunupa Volcano, our luxury domes lodge is waiting for you. From a distance, the white pods look like a space station designed by Buckminsterfuller. The dome camp also has something of an observatory, or a folly built for a passionate astrophysicist! Composed of repeated equilateral triangles, their metallic structure is the very essence of mathematical elegance. In front or on top of the dome, a transparent panel offers wide-open views on the Salar and its star-studded sky. Lit up at night, with solar panels placed in between the domes, they are one of the coolest, and most surrealist viewing station ever seen in the arid region.",
    "brand": null,
    "blockQuote": "Opening Spring 2019. A morning hike to the nearby island covered with giant cactus with spectacular views of the Salar is not to be missed.",
    "emailAddress": "reservations@kachilodge.com",
    "website": "kachilodge.com/",
    "bookingURL": "https://www.kachilodge.com/copie-de-the-experience",
    "redirectNotes": null,
    "phoneNumber": "+41 79 212 10 28 ",
    "addressOne": "",
    "addressTwo": "",
    "city": "Uyuni",
    "county": "",
    "region": "",
    "country": "Bolivia",
    "rooms": 6,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 26019,
        "title": "A Unique Retreat! The Kachi Lodge | stupidDOPE.com",
        "url": "https://pkslist.azureedge.net/media/images/1/46c46058-18d8-4e60-b480-2cdb34ec210c/1200x900.jpg?w=640",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/46c46058-18d8-4e60-b480-2cdb34ec210c/800x600.jpg?w=640",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/46c46058-18d8-4e60-b480-2cdb34ec210c/256x192.jpg?w=640",
        "viewOrder": 0
      },
      {
        "mediaId": 26016,
        "title": "Kachi-Lodge-Amazing-Escapes_05LR - LATTE Luxury News",
        "url": "https://pkslist.azureedge.net/media/images/1/a4406180-e675-4b1e-9745-3159a7f50b7c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a4406180-e675-4b1e-9745-3159a7f50b7c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a4406180-e675-4b1e-9745-3159a7f50b7c/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 26015,
        "title": "Kachi Lodge | Uncrate",
        "url": "https://pkslist.azureedge.net/media/images/1/25ae7654-5e7f-4941-90fd-ecd4de3fb7e1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/25ae7654-5e7f-4941-90fd-ecd4de3fb7e1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/25ae7654-5e7f-4941-90fd-ecd4de3fb7e1/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 26022,
        "title": "Photos - JG Black Book Collection",
        "url": "https://pkslist.azureedge.net/media/images/1/926e3ae7-7c77-4ea6-a322-f6995ad8db9a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/926e3ae7-7c77-4ea6-a322-f6995ad8db9a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/926e3ae7-7c77-4ea6-a322-f6995ad8db9a/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 26021,
        "title": "Photos - JG Black Book Collection",
        "url": "https://pkslist.azureedge.net/media/images/1/d260a7b9-bf73-463f-bf1c-915ce98940f8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d260a7b9-bf73-463f-bf1c-915ce98940f8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d260a7b9-bf73-463f-bf1c-915ce98940f8/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 26025,
        "title": "Kachi Lodge: Uma experiência Astrofísica na Bolívia",
        "url": "https://pkslist.azureedge.net/media/images/1/0272b950-eb50-4a81-a354-8ed16ac7c4a5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0272b950-eb50-4a81-a354-8ed16ac7c4a5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0272b950-eb50-4a81-a354-8ed16ac7c4a5/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 26024,
        "title": "Kachi Lodge: Uma experiência Astrofísica na Bolívia",
        "url": "https://pkslist.azureedge.net/media/images/1/b16f60b8-2fcc-448a-89ca-074086b7c1aa/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b16f60b8-2fcc-448a-89ca-074086b7c1aa/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b16f60b8-2fcc-448a-89ca-074086b7c1aa/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 26023,
        "title": "This Isn't an Off-World Space Base, Just the Kachi Lodge on the ...",
        "url": "https://pkslist.azureedge.net/media/images/1/daf41747-39d4-42c0-9ce0-3ce7a4f2d7d6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/daf41747-39d4-42c0-9ce0-3ce7a4f2d7d6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/daf41747-39d4-42c0-9ce0-3ce7a4f2d7d6/256x192.jpg",
        "viewOrder": 7
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1910,
      "latitude": -20.1337772,
      "longitude": -67.48913449999998,
      "parentGeographicAreaId": 0,
      "title": "Uyuni Salt Flat, Bolivia",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 53,
        "tagName": "Cutting Edge",
        "tagType": 1
      },
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 164,
        "tagName": "Excursions",
        "tagType": 2
      },
      {
        "tagId": 172,
        "tagName": "Biking",
        "tagType": 2
      },
      {
        "tagId": 205,
        "tagName": "Design",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 263,
        "tagName": "Star Gazing",
        "tagType": 2
      },
      {
        "tagId": 286,
        "tagName": "Volcano",
        "tagType": 2
      },
      {
        "tagId": 317,
        "tagName": "Gourmet",
        "tagType": 2
      },
      {
        "tagId": 324,
        "tagName": "Local Village",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 647,
        "venueCategoryId": 34,
        "venueId": 2101,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2087,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Explora Rapa Nui",
    "description": "Rapa Nui may be a tiny island stranded in the infinite Pacific Ocean, yet it’s safe to say that never has a single place puzzled the world as much as this one. A miniscule triangle born from the eruption of three volcanoes, the outmost border of the Polynesian triangle sits 2,180 miles off the South American continent and is surrounded by the clearest waters on the planet – the very definition of ‘remote’.\n\nRapa Nui was once the cradle of a unique and enigmatic culture that continues to endure today. The island’s language, music, and countless traditions are kept alive by its proud inhabitants, while the enormous carved stone Moai are tangible testimonies of its enigmatic past.",
    "brand": "",
    "blockQuote": "Hiking to the rim of the volcano will be one highlight, but you'll have so many memorable adventures here.",
    "emailAddress": "reserve@explora.com",
    "website": "explora.com/easter-island-hotels/",
    "bookingURL": "http://www.booking.com/Share-lRMQsf?aid=925669",
    "redirectNotes": "https://reservations.explora.com/ibe/paso1?language=EN",
    "phoneNumber": "+56 2 2395 2800",
    "addressOne": "",
    "addressTwo": "",
    "city": "Hanga Roa",
    "county": "",
    "region": "Easter Island",
    "country": "Chile",
    "rooms": 30,
    "dollar": 3,
    "updated": "2022-01-10T15:28:49.177",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 25791,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/02e69b85-8465-4405-be66-86a6e15c2549/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/02e69b85-8465-4405-be66-86a6e15c2549/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/02e69b85-8465-4405-be66-86a6e15c2549/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 25785,
        "title": "Resort Explora Rapa Nui, Hanga Roa, Chile - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/0549f237-91b0-4955-b3c1-d1b11f61de15/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0549f237-91b0-4955-b3c1-d1b11f61de15/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0549f237-91b0-4955-b3c1-d1b11f61de15/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 25790,
        "title": "Book explora Rapa Nui - All Inclusive in Hanga Roa | Hotels.com",
        "url": "https://pkslist.azureedge.net/media/images/1/1d017951-e3ef-43ed-8ebd-62a48d94492f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1d017951-e3ef-43ed-8ebd-62a48d94492f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1d017951-e3ef-43ed-8ebd-62a48d94492f/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 25783,
        "title": "Resort Explora Rapa Nui, Hanga Roa, Chile - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/1b24fb19-bceb-4153-a1cf-2e76476965ba/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1b24fb19-bceb-4153-a1cf-2e76476965ba/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1b24fb19-bceb-4153-a1cf-2e76476965ba/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 25793,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d3abf7b9-79a1-471f-9685-43a71886e4a1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d3abf7b9-79a1-471f-9685-43a71886e4a1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d3abf7b9-79a1-471f-9685-43a71886e4a1/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 25786,
        "title": "Resort Explora Rapa Nui, Hanga Roa, Chile - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/9792fe85-e737-45e5-be9a-b3a997eed4f5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9792fe85-e737-45e5-be9a-b3a997eed4f5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9792fe85-e737-45e5-be9a-b3a997eed4f5/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 51472,
        "title": "Explora Rapa Nui: Private Luxury Hotel on Easter Island, Chile ...",
        "url": "https://pkslist.azureedge.net/media/images/1/a839cc79-212b-4215-94f0-8269be609099/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a839cc79-212b-4215-94f0-8269be609099/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a839cc79-212b-4215-94f0-8269be609099/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 51469,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/4f7a1fcd-d874-4c4b-a623-721533fa0bc3/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4f7a1fcd-d874-4c4b-a623-721533fa0bc3/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4f7a1fcd-d874-4c4b-a623-721533fa0bc3/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 51468,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/19d265b2-bd8c-4b99-b597-2fed0d9d9c17/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/19d265b2-bd8c-4b99-b597-2fed0d9d9c17/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/19d265b2-bd8c-4b99-b597-2fed0d9d9c17/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 25789,
        "title": "Resort Explora Rapa Nui, Hanga Roa, Chile - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/35115103-06cb-4cd7-8817-84c0d783c0f5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/35115103-06cb-4cd7-8817-84c0d783c0f5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/35115103-06cb-4cd7-8817-84c0d783c0f5/256x192.jpg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1896,
      "latitude": -27.142135,
      "longitude": -109.35705200000001,
      "parentGeographicAreaId": 0,
      "title": "Easter Island, Hanga Roa, Isla de Pascua, Región de Valparaíso, Chile",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 75,
        "tagName": "Casual Dining",
        "tagType": 2
      },
      {
        "tagId": 82,
        "tagName": "Horseback Riding",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 108,
        "tagName": "Jacuzzi",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 115,
        "tagName": "Snorkeling",
        "tagType": 2
      },
      {
        "tagId": 116,
        "tagName": "Scuba Diving",
        "tagType": 2
      },
      {
        "tagId": 164,
        "tagName": "Excursions",
        "tagType": 2
      },
      {
        "tagId": 172,
        "tagName": "Biking",
        "tagType": 2
      },
      {
        "tagId": 268,
        "tagName": "Massage",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 653,
        "venueCategoryId": 34,
        "venueId": 2087,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1873,
    "venueCategoryId": 0,
    "status": 1,
    "title": "La Ferme de Georges",
    "description": "Neighboring the coastal dunes of northeastern Brazil, La Ferme de Georges beckons those who crave slowed down culinary indulgence, locally inspired design intertwined with modern luxury and stunning natural landscapes.\n\nLa Ferme de Georges covers several acres midway between the sea and the river, at the very edge of the natural park of Lençóis Maranhenses. The 7 chalets can accommodate up to 4 guests (5 in the Park Chalet). La Ferme de Georges can also be fully privatized or in a series of three chalets at a time. It's a great place to escape and to live an exceptional moment \"à la carte\", with a personalized service.",
    "brand": null,
    "blockQuote": "Trekking through the sand dunes is a wonderful experience, but it's even more exciting on horseback or ATV.",
    "emailAddress": "hello@georges.life",
    "website": "georges.life/en/atins/",
    "bookingURL": "http://www.booking.com/Share-6FKjz7t?aid=925669",
    "redirectNotes": null,
    "phoneNumber": "+55 98 91138196",
    "addressOne": "",
    "addressTwo": "",
    "city": "Atins",
    "county": "",
    "region": "",
    "country": "Brazil",
    "rooms": 12,
    "dollar": 2,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 22474,
        "title": "Hotel La Ferme De Georges, Atins, Brazil - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/2daa595d-ed30-4f01-840e-0bd58c74a621/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2daa595d-ed30-4f01-840e-0bd58c74a621/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2daa595d-ed30-4f01-840e-0bd58c74a621/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 22473,
        "title": "La Ferme de Georges | Atins, Lençóis Maranhenses, Brazil",
        "url": "https://pkslist.azureedge.net/media/images/1/09120016-9968-45c6-9de7-b4928cd6bec1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/09120016-9968-45c6-9de7-b4928cd6bec1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/09120016-9968-45c6-9de7-b4928cd6bec1/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 27042,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/0d91b32c-a91c-4634-b7dc-c54f1e0f4fbc/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0d91b32c-a91c-4634-b7dc-c54f1e0f4fbc/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0d91b32c-a91c-4634-b7dc-c54f1e0f4fbc/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 22480,
        "title": "La Ferme de Georges (Atins, Brazil) | Design Hotels™",
        "url": "https://pkslist.azureedge.net/media/images/1/ee45e6c7-b8bc-4adf-8df1-86d7464dc6f2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ee45e6c7-b8bc-4adf-8df1-86d7464dc6f2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ee45e6c7-b8bc-4adf-8df1-86d7464dc6f2/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 22478,
        "title": "La Ferme de Georges (Atins, Brazil) | Design Hotels™",
        "url": "https://pkslist.azureedge.net/media/images/1/07a56b04-599f-49f6-ae03-5acff2f9a527/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/07a56b04-599f-49f6-ae03-5acff2f9a527/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/07a56b04-599f-49f6-ae03-5acff2f9a527/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 22479,
        "title": "La Ferme de Georges (Atins, Brazil) | Design Hotels™",
        "url": "https://pkslist.azureedge.net/media/images/1/d4136ff5-5f37-42bc-a383-05fbc64d3e0c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d4136ff5-5f37-42bc-a383-05fbc64d3e0c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d4136ff5-5f37-42bc-a383-05fbc64d3e0c/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 22482,
        "title": "La Ferme de Georges (Atins, Brazil) | Design Hotels™",
        "url": "https://pkslist.azureedge.net/media/images/1/202d7bb0-1554-49f6-abfa-8e9c5f40fa5c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/202d7bb0-1554-49f6-abfa-8e9c5f40fa5c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/202d7bb0-1554-49f6-abfa-8e9c5f40fa5c/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 22476,
        "title": "Hotel La Ferme De Georges, Atins, Brazil - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/932551ad-9896-405c-acdf-19424f5724d2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/932551ad-9896-405c-acdf-19424f5724d2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/932551ad-9896-405c-acdf-19424f5724d2/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1688,
      "latitude": -2.5722892,
      "longitude": -42.752486999999974,
      "parentGeographicAreaId": 0,
      "title": "La Ferme de Georges, Parque Nacional dos Lençóis Maranhenses, CP 185, Barreirinhas - MA, 65590-000, Brazil",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 75,
        "tagName": "Casual Dining",
        "tagType": 2
      },
      {
        "tagId": 82,
        "tagName": "Horseback Riding",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 152,
        "tagName": "Kite Surfing",
        "tagType": 2
      },
      {
        "tagId": 229,
        "tagName": "Private Balconies",
        "tagType": 2
      },
      {
        "tagId": 252,
        "tagName": "National Park",
        "tagType": 2
      },
      {
        "tagId": 310,
        "tagName": "ATV Touring",
        "tagType": 2
      },
      {
        "tagId": 320,
        "tagName": "Organic Garden",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 649,
        "venueCategoryId": 34,
        "venueId": 1873,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 666,
        "venueCategoryId": 33,
        "venueId": 1873,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 33,
          "title": "Rustic Elegance",
          "region": "Global",
          "viewOrder": 11,
          "updated": "2020-09-04T09:04:00",
          "created": "2020-09-04T09:04:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1787,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Killiehuntly Farmhouse & Cottages",
    "description": "Killiehuntly farmhouse has stood watch over the sprawling Cairngorm mountains since 1850. A lot may have changed since then, but the slow and gentle rhythm of life at Killiehuntly certainly hasn’t. Then, as now, the house sat as a haven in the landscape - a beautiful, simple shelter from the hurry and haste of everyday life.\n\nKILLIEHUNTLY BOTHY: A cosy hideaway, perfect for a family or a couple. This small cottage spans a double room, a room with twin bunk beds, a bathroom and living & cooking space. GEORDIE’S COTTAGE: A larger cottage with a wonderful view of the mountains and forest, Geordie’s has room for up to five adults staying in a double room, a twin room and a cosy single room. \n\nThe main house is stylishly refurbished with contemporary furniture created by Danish designers and local Scottish craftsmen.\n\nAt Killiehuntly you can experience the spirit and values of a simpler time. The sturdy stone walls of the farmhouse have seen wars and generations come and go. You can feel the history of the Scottish Highlands unfold as you enter Killiehuntly.",
    "brand": "",
    "blockQuote": "Make a visit to the farm and organic garden, it's great to see where your food comes from.",
    "emailAddress": "hello@killiehuntly.scot",
    "website": "killiehuntly.scot/",
    "bookingURL": "http://killiehuntly.scot/Killiehuntly_Reservation.html",
    "redirectNotes": null,
    "phoneNumber": "+44 1540 661 619",
    "addressOne": "",
    "addressTwo": "",
    "city": "Kingussie",
    "county": "Cairngorms National Park",
    "region": "Scotland",
    "country": "United Kingdom",
    "rooms": 7,
    "dollar": 2,
    "updated": "2020-11-18T08:58:23.143",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 21119,
        "title": "Call of the Wild at Killiehuntly Hotel, Scotland | Yatzer",
        "url": "https://pkslist.azureedge.net/media/images/1/d01fea5f-bc2c-4d15-86db-b044f3233821/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d01fea5f-bc2c-4d15-86db-b044f3233821/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d01fea5f-bc2c-4d15-86db-b044f3233821/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 42737,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/3aa28506-e6bd-4cd6-a7dc-36b8149abd9c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3aa28506-e6bd-4cd6-a7dc-36b8149abd9c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3aa28506-e6bd-4cd6-a7dc-36b8149abd9c/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 21110,
        "title": "Killiehuntly Farmhouse | Restoration | Projects | Groves-Raines",
        "url": "https://pkslist.azureedge.net/media/images/1/5850baf7-0e53-4102-a0ce-da69c4f66417/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5850baf7-0e53-4102-a0ce-da69c4f66417/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5850baf7-0e53-4102-a0ce-da69c4f66417/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 21117,
        "title": "Killiehuntly Farmhouse and Cottages: A Luxurious Retreat with a ...",
        "url": "https://pkslist.azureedge.net/media/images/1/822380ed-339f-435a-9ea9-467f03f4701c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/822380ed-339f-435a-9ea9-467f03f4701c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/822380ed-339f-435a-9ea9-467f03f4701c/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 21125,
        "title": "Scotland and Killiehuntly Farmhouse in the Cairngorms - images ...",
        "url": "https://pkslist.azureedge.net/media/images/1/f284286c-6f87-4d30-ad57-c3d4f02a2515/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f284286c-6f87-4d30-ad57-c3d4f02a2515/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f284286c-6f87-4d30-ad57-c3d4f02a2515/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 42738,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f717cdb0-3e08-47ff-bd9c-b22f66be3b0c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f717cdb0-3e08-47ff-bd9c-b22f66be3b0c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f717cdb0-3e08-47ff-bd9c-b22f66be3b0c/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 21118,
        "title": "The Best British Design Boutique Hotels in the UK",
        "url": "https://pkslist.azureedge.net/media/images/1/9cc63e06-6b4b-4492-a98e-7b9b38659982/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9cc63e06-6b4b-4492-a98e-7b9b38659982/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9cc63e06-6b4b-4492-a98e-7b9b38659982/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 21115,
        "title": "Killiehuntly Farmhouse & Cottage - an extraordinary getaway in the ...",
        "url": "https://pkslist.azureedge.net/media/images/1/0914c564-2819-4b88-9779-c4e6e568fec6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0914c564-2819-4b88-9779-c4e6e568fec6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0914c564-2819-4b88-9779-c4e6e568fec6/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 21127,
        "title": "Living: Kom indenfor på Killiehuntly Farmhouse - COVER | Bedrooms ...",
        "url": "https://pkslist.azureedge.net/media/images/1/fe5bd860-3ed9-4c28-9353-ae56ce456107/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fe5bd860-3ed9-4c28-9353-ae56ce456107/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fe5bd860-3ed9-4c28-9353-ae56ce456107/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 21111,
        "title": "Killiehuntly Farmhouse | Restoration | Projects | Groves-Raines",
        "url": "https://pkslist.azureedge.net/media/images/1/5497e57c-ddc6-4979-af89-d1e0d6560ed6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5497e57c-ddc6-4979-af89-d1e0d6560ed6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5497e57c-ddc6-4979-af89-d1e0d6560ed6/256x192.jpg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1603,
      "latitude": 57.063348,
      "longitude": -3.9957461000000194,
      "parentGeographicAreaId": 0,
      "title": "Kingussie PH21 1NY, UK",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 47,
        "tagName": "Intimate Luxury ",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 109,
        "tagName": "Fishing",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 156,
        "tagName": "Fireplace",
        "tagType": 2
      },
      {
        "tagId": 203,
        "tagName": "Sauna",
        "tagType": 2
      },
      {
        "tagId": 252,
        "tagName": "National Park",
        "tagType": 2
      },
      {
        "tagId": 259,
        "tagName": "Picnic",
        "tagType": 2
      },
      {
        "tagId": 266,
        "tagName": "Nature Walk",
        "tagType": 2
      },
      {
        "tagId": 317,
        "tagName": "Gourmet",
        "tagType": 2
      },
      {
        "tagId": 320,
        "tagName": "Organic Garden",
        "tagType": 2
      },
      {
        "tagId": 329,
        "tagName": "Hunting & Stalking",
        "tagType": 2
      },
      {
        "tagId": 330,
        "tagName": "Swimming",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 700,
        "venueCategoryId": 34,
        "venueId": 1787,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1714,
    "venueCategoryId": 0,
    "status": 1,
    "title": "The 5 Million Star Hotel",
    "description": "Sleeping under the stars or watching the magical dance of the aurora borealis is a lifelong dream for many. Here at the 5 Million Star Hotel this dream becomes a reality. As close to nature as you can possibly get the bubble concept combines innovation, luxury and the magnificence of the iconic Icelandic landscape.\n\nThe perfect antidote to the everyday chaos of work and city life, our unique accommodation creates a space to unwind and recharge under the night sky. Unlike any other hotel in Iceland, our transparent bubble stays offer you the experience of a lifetime as we situate you within the nature of the land of fire and ice.  Whilst of course we cannot guarantee you will see the northern lights, if the conditions are right you will experience the most magical of nights under the breath-taking aurora borealis and a sky full of stars.\n\nIn winter we might be graced with the northern lights, but in the summer you will experience the bubbles’ proximity to nature. Immersed in a world of wonderful bird-life, butterflies and the spectacular Icelandic scenery in the midst of the never ending midnight sun.",
    "brand": "",
    "blockQuote": "Perfect for cuddling as you gaze out at unobstructed 360 degree views of the twinkling stars and sky.",
    "emailAddress": "members@pks-list.com",
    "website": "buubble.com/",
    "bookingURL": "https://www.buubble.com/contact-us/",
    "redirectNotes": null,
    "phoneNumber": "+44 203 664 7123",
    "addressOne": "",
    "addressTwo": "",
    "city": "Skalholt",
    "county": "",
    "region": "",
    "country": "Iceland",
    "rooms": 5,
    "dollar": 2,
    "updated": "2020-11-18T16:06:55.273",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 42848,
        "title": "Smart Designs For Small Homes on Instagram: “The 5 Million Star ...",
        "url": "https://pkslist.azureedge.net/media/images/1/7f1c0c4d-5263-43e8-920a-e81ed7175058/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7f1c0c4d-5263-43e8-920a-e81ed7175058/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7f1c0c4d-5263-43e8-920a-e81ed7175058/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 42845,
        "title": "Spend a Night at This 5 Million Star Hotel - Traveler Master",
        "url": "https://pkslist.azureedge.net/media/images/1/6a06e6c2-e656-4a3f-84ce-196a79f7767c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6a06e6c2-e656-4a3f-84ce-196a79f7767c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6a06e6c2-e656-4a3f-84ce-196a79f7767c/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 42847,
        "title": "Aurora Borealis bubble & igloo hotels in Iceland",
        "url": "https://pkslist.azureedge.net/media/images/1/eb56ab03-0937-4c82-af05-c61ed008aa18/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/eb56ab03-0937-4c82-af05-c61ed008aa18/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/eb56ab03-0937-4c82-af05-c61ed008aa18/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 42844,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/89a9addd-aa39-4c53-8a9b-262f7efe6b4d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/89a9addd-aa39-4c53-8a9b-262f7efe6b4d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/89a9addd-aa39-4c53-8a9b-262f7efe6b4d/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 42846,
        "title": "Iceland's '5 Million Star Hotel' Lets You Sleep in a Bubble Under ...",
        "url": "https://pkslist.azureedge.net/media/images/1/a82d951e-01ba-4910-bc13-dbe121da9d73/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a82d951e-01ba-4910-bc13-dbe121da9d73/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a82d951e-01ba-4910-bc13-dbe121da9d73/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 42849,
        "title": "THE FIVE MILLION STAR HOTEL: INSIDE THE AMAZING BUBBLE HOTEL WHICH ...",
        "url": "https://pkslist.azureedge.net/media/images/1/f150ba71-0ef4-453f-89d4-b9f97c03182d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f150ba71-0ef4-453f-89d4-b9f97c03182d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f150ba71-0ef4-453f-89d4-b9f97c03182d/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 42851,
        "title": "Aurora Borealis bubble & igloo hotels in Iceland",
        "url": "https://pkslist.azureedge.net/media/images/1/8a59e8d1-ac1a-46dc-b9dd-f7c8de085add/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8a59e8d1-ac1a-46dc-b9dd-f7c8de085add/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8a59e8d1-ac1a-46dc-b9dd-f7c8de085add/256x192.jpg",
        "viewOrder": 7
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2779,
      "latitude": 64.1650367,
      "longitude": -20.5011814,
      "parentGeographicAreaId": 0,
      "title": "Unnamed Road, Iceland",
      "updated": null,
      "created": "2020-11-18T16:06:00"
    },
    "personalities": [
      {
        "tagId": 53,
        "tagName": "Cutting Edge",
        "tagType": 1
      },
      {
        "tagId": 57,
        "tagName": "Romantic",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 133,
        "tagName": "Glamping",
        "tagType": 2
      },
      {
        "tagId": 135,
        "tagName": "Bird Watching",
        "tagType": 2
      },
      {
        "tagId": 164,
        "tagName": "Excursions",
        "tagType": 2
      },
      {
        "tagId": 237,
        "tagName": "Northern Lights",
        "tagType": 2
      },
      {
        "tagId": 243,
        "tagName": "Outdoor Activities",
        "tagType": 2
      },
      {
        "tagId": 263,
        "tagName": "Star Gazing",
        "tagType": 2
      },
      {
        "tagId": 296,
        "tagName": "Guided Walk",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 701,
        "venueCategoryId": 34,
        "venueId": 1714,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1037,
    "venueCategoryId": 1,
    "status": 1,
    "title": "Fogo Island Inn",
    "description": "Our ancestors made their living by fishing for cod on the turbulent North Atlantic. Today, Fogo Island Inn honours and carries forward the history and culture of this singular place. Fogo Island Inn has guest suites and four suite types that showcase the richness of their locality and clearly express a modern take on traditional Newfoundland outport design and décor.\n\nFogo Island has seven seasons consisting of warm summers, snowy winters, a spectacular ice season, hopeful spring, June's trap berth season, bountiful wild berry picking in fall's berry season, and a temperamental late fall. Each provides a different perspective for getting to know Fogo Island.",
    "brand": null,
    "blockQuote": "Unbelievable architecture and design in the most spectacular location for a once in a lifetime experience.",
    "emailAddress": "reservations@fogoislandinn.ca",
    "website": "fogoislandinn.ca/",
    "bookingURL": "http://www.fogoislandinn.ca/b/reservation-enquiry",
    "redirectNotes": null,
    "phoneNumber": "+1 709 658 3444",
    "addressOne": "210",
    "addressTwo": "Main Road",
    "city": "Joe Batt's Arm",
    "county": "",
    "region": "Newfoundland",
    "country": "Canada",
    "rooms": 29,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 10371,
        "title": "More Than A Review: Community-Based Hospitality at the Fogo Island Inn",
        "url": "https://pkslist.azureedge.net/media/images/1/3a5997c3-22d4-42ee-bc0f-13d79336eb60/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3a5997c3-22d4-42ee-bc0f-13d79336eb60/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3a5997c3-22d4-42ee-bc0f-13d79336eb60/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 10375,
        "title": "Fogo Island Inn, Winter - Picture of Fogo Island Inn, Joe Batt's ...",
        "url": "https://pkslist.azureedge.net/media/images/1/ce949a89-4059-43d6-b8c2-339382709e53/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ce949a89-4059-43d6-b8c2-339382709e53/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ce949a89-4059-43d6-b8c2-339382709e53/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 10373,
        "title": "Fogo Island Inn | Luxury Newfoundland Hotel | Andrew Harper",
        "url": "https://pkslist.azureedge.net/media/images/1/3a8290be-f4ef-4b9e-baea-4e45b636ec3d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3a8290be-f4ef-4b9e-baea-4e45b636ec3d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3a8290be-f4ef-4b9e-baea-4e45b636ec3d/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 10370,
        "title": "Fogo Island Inn in Newfoundland, Canada",
        "url": "https://pkslist.azureedge.net/media/images/1/195ea7e0-21ac-41a8-b93c-aa227eabf823/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/195ea7e0-21ac-41a8-b93c-aa227eabf823/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/195ea7e0-21ac-41a8-b93c-aa227eabf823/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 10367,
        "title": "Fogo Island Inn - UPDATED 2017 Prices, Reviews & Photos ...",
        "url": "https://pkslist.azureedge.net/media/images/1/9b246f13-77ab-4771-91b7-a2636e893886/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9b246f13-77ab-4771-91b7-a2636e893886/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9b246f13-77ab-4771-91b7-a2636e893886/256x192.jpg",
        "viewOrder": 4
      }
    ],
    "geographicArea": {
      "geographicAreaId": 866,
      "latitude": 49.73044489999999,
      "longitude": -54.17824059999998,
      "parentGeographicAreaId": 0,
      "title": "210 Main Rd, Joe Batt's Arm, NL A0G 2X0, Canada",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 53,
        "tagName": "Cutting Edge",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 58,
        "tagName": "Beach",
        "tagType": 2
      },
      {
        "tagId": 59,
        "tagName": "Cultural",
        "tagType": 2
      },
      {
        "tagId": 60,
        "tagName": "Gastronomic",
        "tagType": 2
      },
      {
        "tagId": 66,
        "tagName": "Skiing",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 101,
        "tagName": "Cinema",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 108,
        "tagName": "Jacuzzi",
        "tagType": 2
      },
      {
        "tagId": 109,
        "tagName": "Fishing",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 131,
        "tagName": "Library",
        "tagType": 2
      },
      {
        "tagId": 172,
        "tagName": "Biking",
        "tagType": 2
      },
      {
        "tagId": 198,
        "tagName": "Boat Excursion",
        "tagType": 2
      },
      {
        "tagId": 238,
        "tagName": "Whale Watching",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 194,
        "venueCategoryId": 1,
        "venueId": 1037,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 1,
          "title": "Escape from the City",
          "region": "Global",
          "viewOrder": 14,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 655,
        "venueCategoryId": 34,
        "venueId": 1037,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 740,
    "venueCategoryId": 19,
    "status": 1,
    "title": "Time + Tide Miavana",
    "description": "Time+Tide Miavana is a luxury lodge on the private island of Nosy Ankao off the northeast coast of Madagascar. With Madagascar's history created from a rich tapestry of diverse human immigrants and unique endemic animal and plant species, it is only fitting that the design is reflected in this island paradise. The natural elements of water, sand and air merge elegantly with luxurious interiors unique to Madagascar and local stone and palm branch bring texture to every corner of the villas. Strung along the island, every villa has direct access to the white sandy beaches, turquoise water and coral reefs. The colours of the sand, wood and water have been brought inside the villas and appear in hand dyed curtains, hand crafted chairs, and light fittings resembling old glass buoys.\n\nUse the villa's electric buggies to traverse the island and transport you to the Village Piazza, Aquatic Center and Beach Lounge.",
    "brand": "",
    "blockQuote": "Be a pioneer and scuba dive reefs with no name.",
    "emailAddress": "miavana@timeandtideafrica",
    "website": "timeandtideafrica.com/miavana",
    "bookingURL": "https://miavana.com/traveller-enquiry/",
    "redirectNotes": "Time + Tide Miavana's reservations system",
    "phoneNumber": "+260 216 246025",
    "addressOne": "",
    "addressTwo": "",
    "city": "Nosy Ankao",
    "county": "",
    "region": "",
    "country": "Madagascar",
    "rooms": 14,
    "dollar": 3,
    "updated": "2022-08-08T14:38:45.977",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 22993,
        "title": "Time + Tide Miavana, Madagascar | Traveller Made",
        "url": "https://pkslist.azureedge.net/media/images/1/8fd1216f-9682-4fa4-92e0-9e869c5842ff/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8fd1216f-9682-4fa4-92e0-9e869c5842ff/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8fd1216f-9682-4fa4-92e0-9e869c5842ff/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 6238,
        "title": "Enquire | Miavana, Madagascar - Africa Travel | Africa Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/f4d2cad2-213c-401b-879f-e71566c1e5f9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f4d2cad2-213c-401b-879f-e71566c1e5f9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f4d2cad2-213c-401b-879f-e71566c1e5f9/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 22998,
        "title": "Miavana | Madagascar | Luxury Beach Lodges & Private Islands",
        "url": "https://pkslist.azureedge.net/media/images/1/193e12dd-ff56-4ccd-bf6d-e9682c7f720e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/193e12dd-ff56-4ccd-bf6d-e9682c7f720e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/193e12dd-ff56-4ccd-bf6d-e9682c7f720e/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 22989,
        "title": "Miavana, Madagascar - Natural World Safaris",
        "url": "https://pkslist.azureedge.net/media/images/1/10d65084-e0f8-47fc-b3a7-e595f8ca81bc/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/10d65084-e0f8-47fc-b3a7-e595f8ca81bc/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/10d65084-e0f8-47fc-b3a7-e595f8ca81bc/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 22992,
        "title": "Miavana, Madagascar - Natural World Safaris",
        "url": "https://pkslist.azureedge.net/media/images/1/41bcd49d-6901-4f07-ab49-b2654d292bf9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/41bcd49d-6901-4f07-ab49-b2654d292bf9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/41bcd49d-6901-4f07-ab49-b2654d292bf9/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 22991,
        "title": "Miavana, Madagascar - Natural World Safaris",
        "url": "https://pkslist.azureedge.net/media/images/1/90cecf52-7382-4762-bd64-ffae72dde71b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/90cecf52-7382-4762-bd64-ffae72dde71b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/90cecf52-7382-4762-bd64-ffae72dde71b/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 22990,
        "title": "Miavana | Miavana Madagascar at Nosy Ankao with Africa Odyssey",
        "url": "https://pkslist.azureedge.net/media/images/1/719d966a-9875-449d-9d67-aa79800f6e15/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/719d966a-9875-449d-9d67-aa79800f6e15/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/719d966a-9875-449d-9d67-aa79800f6e15/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 22996,
        "title": "Miavana, Madagascar, Africa| An exclusive review of Miavana | CN ...",
        "url": "https://pkslist.azureedge.net/media/images/1/eccf7772-5531-4765-add3-b1187a1af655/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/eccf7772-5531-4765-add3-b1187a1af655/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/eccf7772-5531-4765-add3-b1187a1af655/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 6240,
        "title": "Miavana.jpg",
        "url": "https://pkslist.azureedge.net/media/images/1/e07a05fd-7a07-43ce-893b-6262eabb4dd3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e07a05fd-7a07-43ce-893b-6262eabb4dd3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e07a05fd-7a07-43ce-893b-6262eabb4dd3/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 6239,
        "title": "Tailor Made Luxury Madagascar – Introducing Miavana – Equatours ...",
        "url": "https://pkslist.azureedge.net/media/images/1/b6344e38-65ca-4d42-8265-1f2f46ecb4ad/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b6344e38-65ca-4d42-8265-1f2f46ecb4ad/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b6344e38-65ca-4d42-8265-1f2f46ecb4ad/256x192.jpg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 577,
      "latitude": -12.7916705,
      "longitude": 49.81819189999999,
      "parentGeographicAreaId": 0,
      "title": "Nosy Anko,, Madagascar",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 157,
        "tagName": "Beach Escape",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 88,
        "tagName": "Tennis",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 109,
        "tagName": "Fishing",
        "tagType": 2
      },
      {
        "tagId": 116,
        "tagName": "Scuba Diving",
        "tagType": 2
      },
      {
        "tagId": 124,
        "tagName": "Helicopter",
        "tagType": 2
      },
      {
        "tagId": 181,
        "tagName": "Private/Suite Pools",
        "tagType": 2
      },
      {
        "tagId": 206,
        "tagName": "Private Beach",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 126,
        "venueCategoryId": 20,
        "venueId": 740,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 20,
          "title": "BucketList Hotels",
          "region": "Global",
          "viewOrder": 0,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 347,
        "venueCategoryId": 15,
        "venueId": 740,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 15,
          "title": "Beach Paradise",
          "region": "Global",
          "viewOrder": 6,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 348,
        "venueCategoryId": 19,
        "venueId": 740,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 19,
          "title": "Private Islands",
          "region": "Global",
          "viewOrder": 15,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 656,
        "venueCategoryId": 34,
        "venueId": 740,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 487,
    "venueCategoryId": 20,
    "status": 1,
    "title": "Nihi Sumba Island",
    "description": "This is not an escape from everyday life. It is the return to a life well lived. Where rugged luxury meets unregulated freedom. A place to connect with something larger than oneself. A haven for the adventurer, for the wanderer. The endlessly passionate and curious. Arrive with an open heart and leave changed forever. This is vacation with a purpose. A destination with meaning.\n\nNihi Sumba Island is an award-winning luxury resort on remote Sumba Island, Indonesia. A wide range of land and ocean-based experiences ensures all tastes are catered to. Attentive service and gourmet meals add to the refined and intimate ambience. A romantic getaway idyll with pristine private beach stretching in excess of 2.5km of length ideal for honeymooners and the unique tribal culture on the island offers guests a glimpse of a bygone era.",
    "brand": null,
    "blockQuote": "They are known for their extraordinary NihiOka spa safari which is a must-do while you're there.",
    "emailAddress": "info@nihi.com",
    "website": "nihi.com/",
    "bookingURL": "http://nihi.com/booking/",
    "redirectNotes": null,
    "phoneNumber": "+62 361 757149",
    "addressOne": "",
    "addressTwo": "",
    "city": "Sumba",
    "county": "",
    "region": "",
    "country": "Indonesia",
    "rooms": 33,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 18968,
        "title": "Best hotel in the world is Nihi Sumba — built by fashion mogul ...",
        "url": "https://pkslist.azureedge.net/media/images/1/d66e5db3-94d7-4f0c-984d-43310621192f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d66e5db3-94d7-4f0c-984d-43310621192f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d66e5db3-94d7-4f0c-984d-43310621192f/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 18970,
        "title": "Nihiwatu Resort - Sumba Island - YouTube",
        "url": "https://pkslist.azureedge.net/media/images/1/abfa3d84-cf77-41f9-ac3e-ad81a093b6b2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/abfa3d84-cf77-41f9-ac3e-ad81a093b6b2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/abfa3d84-cf77-41f9-ac3e-ad81a093b6b2/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 18969,
        "title": "Nihi Sumba Island . Indonesia . | J.MAK Hospitality",
        "url": "https://pkslist.azureedge.net/media/images/1/9b927cef-cd93-4fd0-b112-65e03fbeed6e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9b927cef-cd93-4fd0-b112-65e03fbeed6e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9b927cef-cd93-4fd0-b112-65e03fbeed6e/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 18974,
        "title": "Best hotel in the world is Nihi Sumba — built by fashion mogul ...",
        "url": "https://pkslist.azureedge.net/media/images/1/7e7a7fad-0bb5-4dfd-b076-8886be4bb120/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7e7a7fad-0bb5-4dfd-b076-8886be4bb120/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7e7a7fad-0bb5-4dfd-b076-8886be4bb120/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 18973,
        "title": "Best hotel in the world is Nihi Sumba — built by fashion mogul ...",
        "url": "https://pkslist.azureedge.net/media/images/1/195176ee-d815-4f5f-b033-6e4ef709dabb/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/195176ee-d815-4f5f-b033-6e4ef709dabb/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/195176ee-d815-4f5f-b033-6e4ef709dabb/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 18972,
        "title": "Nihi Sumba Island | LuxuryHunt.com | Ultimate Collection",
        "url": "https://pkslist.azureedge.net/media/images/1/262675b4-8c31-460e-9577-8fa2975ac60c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/262675b4-8c31-460e-9577-8fa2975ac60c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/262675b4-8c31-460e-9577-8fa2975ac60c/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 3440,
        "title": "Olivia Palermo | Travel: Nihiwatu Beach - Sumba Island, Indonesia ...",
        "url": "https://pkslist.azureedge.net/media/images/1/483292e6-b16c-4f83-ae5c-09e21ddb31ee/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/483292e6-b16c-4f83-ae5c-09e21ddb31ee/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/483292e6-b16c-4f83-ae5c-09e21ddb31ee/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 3441,
        "title": "Nihiwatu Resort, Sumba Island, Indonesia: Sand and surf perfection ...",
        "url": "https://pkslist.azureedge.net/media/images/1/ea093a8c-7e2e-4930-8bd1-108c04a6bb2a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ea093a8c-7e2e-4930-8bd1-108c04a6bb2a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ea093a8c-7e2e-4930-8bd1-108c04a6bb2a/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 18976,
        "title": "Nihi Sumba Island Bali | Worldwide Vacation Spots | Pinterest ...",
        "url": "https://pkslist.azureedge.net/media/images/1/0f0a2b27-e105-46f6-9cd4-d27a3a960114/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0f0a2b27-e105-46f6-9cd4-d27a3a960114/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0f0a2b27-e105-46f6-9cd4-d27a3a960114/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 3436,
        "title": "Stay in a Treehouse with a Private Infinity Pool, at Nihiwatu ...",
        "url": "https://pkslist.azureedge.net/media/images/1/43dfe8b4-98da-4588-9d31-5d9ec00a9b7e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/43dfe8b4-98da-4588-9d31-5d9ec00a9b7e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/43dfe8b4-98da-4588-9d31-5d9ec00a9b7e/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 3435,
        "title": "Nihiwatu Sumba Island - The Bali Bible",
        "url": "https://pkslist.azureedge.net/media/images/1/26cd1670-8364-44d2-8a91-aec276a2d9f6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/26cd1670-8364-44d2-8a91-aec276a2d9f6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/26cd1670-8364-44d2-8a91-aec276a2d9f6/256x192.jpg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 331,
      "latitude": -9.7776614,
      "longitude": 119.37614959999996,
      "parentGeographicAreaId": 0,
      "title": "Nusa Tenggara Tim, Hoba Wawi, Wanokaka, Hoba Wawi, Wanokaka, Kabupaten Sumba Barat, Nusa Tenggara Tim., Indonesia",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 50,
        "tagName": "Barefoot Luxury",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 59,
        "tagName": "Cultural",
        "tagType": 2
      },
      {
        "tagId": 82,
        "tagName": "Horseback Riding",
        "tagType": 2
      },
      {
        "tagId": 90,
        "tagName": "Water Sports",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 122,
        "tagName": "Surfing",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 266,
        "tagName": "Nature Walk",
        "tagType": 2
      },
      {
        "tagId": 283,
        "tagName": "Restaurants",
        "tagType": 2
      },
      {
        "tagId": 324,
        "tagName": "Local Village",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 84,
        "venueCategoryId": 20,
        "venueId": 487,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 20,
          "title": "BucketList Hotels",
          "region": "Global",
          "viewOrder": 0,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 342,
        "venueCategoryId": 5,
        "venueId": 487,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 5,
          "title": "Romantic Getaways",
          "region": "Global",
          "viewOrder": 20,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 622,
        "venueCategoryId": 32,
        "venueId": 487,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 32,
          "title": "Spa & Wellness ",
          "region": "Global",
          "viewOrder": 3,
          "updated": "2020-05-29T09:42:00",
          "created": "2020-05-29T09:42:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 645,
        "venueCategoryId": 34,
        "venueId": 487,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 677,
        "venueCategoryId": 33,
        "venueId": 487,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 33,
          "title": "Rustic Elegance",
          "region": "Global",
          "viewOrder": 11,
          "updated": "2020-09-04T09:04:00",
          "created": "2020-09-04T09:04:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 373,
    "venueCategoryId": 5,
    "status": 1,
    "title": "Longitude 131º",
    "description": "Longitude 131° offers immersion in the Australian outback, a true sense of stillness and beauty in this desert landscape, rich in cultural heritage and history. Explore the World Heritage listed wilderness of Uluru-Kata Tjuta and discover some of the ancient creation stories of the Anangu, the land's traditional custodians. Spend time exploring the diverse flora and fauna of the desert and come face to face with this irresistible land with the lodge's personally guided adventures. In the spirit of the original pioneers, stay in canopied pavilions that seem to float over the rust-red dunes. Luxuriously contemporised, today's explorers are cosseted from the elements in surrounds that favour refined comfort over ostentation. Classic furnishings meld with indigenous textures to evoke a sanctuary of earthy elegance. A private glass wall yields unparalleled views of Uluru (Ayers Rock). Exceptional cuisine celebrates a fusion of modern and local flavours. Enjoy an outback sundowner at the con vivial open bar in the Dune House and dine under a glittering canopy of stars at Table 131°. Quieten to the night sounds of the desert and be mesmerised by the movement and songs of an ancient culture by firelight. Timeless and enriching, Longitude 131° promises a transcendent connection with this spirited land.",
    "brand": "",
    "blockQuote": "Reserve your spot at Table 131° for a magical experience dining under a blanket of twinkling stars.",
    "emailAddress": "reserve@baillielodges.com.au",
    "website": "longitude131.com.au/",
    "bookingURL": "https://longitude131.com.au/reserve-now/",
    "redirectNotes": null,
    "phoneNumber": "+61 2 9918 4355 ",
    "addressOne": "",
    "addressTwo": "Yulara Drive",
    "city": "Yulara",
    "county": "",
    "region": "Northern Territory",
    "country": "Australia",
    "rooms": 15,
    "dollar": 3,
    "updated": "2020-05-29T09:57:45.08",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 1977,
        "title": "Longitude 131˚ | Luxury Hotel in Ayers Rock Australia",
        "url": "https://pkslist.azureedge.net/media/images/1/81e2e81a-e70f-44d0-b5bf-7c8ea58e154b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/81e2e81a-e70f-44d0-b5bf-7c8ea58e154b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/81e2e81a-e70f-44d0-b5bf-7c8ea58e154b/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 37587,
        "title": "Longitude 131 – Max Pritchard Gunner Architects",
        "url": "https://pkslist.azureedge.net/media/images/1/73f8d7f2-a3e5-4735-b1a0-f22ca7f50cb7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/73f8d7f2-a3e5-4735-b1a0-f22ca7f50cb7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/73f8d7f2-a3e5-4735-b1a0-f22ca7f50cb7/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 37589,
        "title": "campement-longitude-131-australie-alice-springs-ayers-rock-uluru ...",
        "url": "https://pkslist.azureedge.net/media/images/1/577a0a56-55f5-4645-83e2-11b884e33c92/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/577a0a56-55f5-4645-83e2-11b884e33c92/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/577a0a56-55f5-4645-83e2-11b884e33c92/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 37590,
        "title": "Hotels - Andrew Harper Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/714aabd0-2d55-40fe-b4e6-513601ce783e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/714aabd0-2d55-40fe-b4e6-513601ce783e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/714aabd0-2d55-40fe-b4e6-513601ce783e/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 37578,
        "title": "Longitude 131, Australia from Eco-Chic Resorts Perfect for Earth ...",
        "url": "https://pkslist.azureedge.net/media/images/1/a61db49a-39e9-45b5-8172-eb8db32dadfb/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a61db49a-39e9-45b5-8172-eb8db32dadfb/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a61db49a-39e9-45b5-8172-eb8db32dadfb/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 37575,
        "title": "Gallery | Longitude 131",
        "url": "https://pkslist.azureedge.net/media/images/1/0deb272c-732e-416c-8ecc-883467f7570e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0deb272c-732e-416c-8ecc-883467f7570e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0deb272c-732e-416c-8ecc-883467f7570e/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 37577,
        "title": "Longitude 131° - Hotels in Heaven",
        "url": "https://pkslist.azureedge.net/media/images/1/cb98fa1f-da8c-48b8-a567-0d71fa6be6b3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cb98fa1f-da8c-48b8-a567-0d71fa6be6b3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cb98fa1f-da8c-48b8-a567-0d71fa6be6b3/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 37579,
        "title": "Longitude 131 | Australia | Vita Brevis Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/a0f52dd4-7ddc-44f3-80e6-e1dd0be7a0d0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a0f52dd4-7ddc-44f3-80e6-e1dd0be7a0d0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a0f52dd4-7ddc-44f3-80e6-e1dd0be7a0d0/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 37592,
        "title": "Dine | Longitude 131",
        "url": "https://pkslist.azureedge.net/media/images/1/7751db1b-0991-4b5e-915a-d29e1425a125/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7751db1b-0991-4b5e-915a-d29e1425a125/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7751db1b-0991-4b5e-915a-d29e1425a125/256x192.jpg",
        "viewOrder": 11
      },
      {
        "mediaId": 37580,
        "title": "Longitude 131°(Uluru, Australia): impressions & review - YouTube",
        "url": "https://pkslist.azureedge.net/media/images/1/1fe31eb8-2efd-41ee-89ba-97d5f968bcbc/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1fe31eb8-2efd-41ee-89ba-97d5f968bcbc/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1fe31eb8-2efd-41ee-89ba-97d5f968bcbc/256x192.jpg",
        "viewOrder": 12
      }
    ],
    "geographicArea": {
      "geographicAreaId": 228,
      "latitude": -25.2500625,
      "longitude": 131.0083531,
      "parentGeographicAreaId": 0,
      "title": "Yulara Dr, Yulara NT 0872, Australia",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 57,
        "tagName": "Romantic",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 132,
        "tagName": "Camel Trek",
        "tagType": 2
      },
      {
        "tagId": 133,
        "tagName": "Glamping",
        "tagType": 2
      },
      {
        "tagId": 164,
        "tagName": "Excursions",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 263,
        "tagName": "Star Gazing",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 296,
        "tagName": "Guided Walk",
        "tagType": 2
      },
      {
        "tagId": 348,
        "tagName": "Unesco World Heritage",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 54,
        "venueCategoryId": 5,
        "venueId": 373,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 5,
          "title": "Romantic Getaways",
          "region": "Global",
          "viewOrder": 20,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 654,
        "venueCategoryId": 34,
        "venueId": 373,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  }
]
import React from 'react'
import { placesStore32} from '../store/Venue32'
import "../styles/Tab1.css";
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/bundle';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { FiHeart } from "react-icons/fi"
import { IoBedOutline } from "react-icons/io5"
import { BsStars } from "react-icons/bs"
import { Pagination } from "swiper";


//TAB 1 = TROPICAL 

const Tab1 = ({ pushDown }) => {


    return (
        <div>
            <div className='tab1-hold flex justify-center md:mb-48 mb-28 items-center sm:gap-12 gap-0 -mt-16 flex-wrap w-full'>
                {placesStore32.map((item => {
                    if (item.status === 1) {
                        return (
                            <div className='card boxsh' key={item.id}>

                                <Link to={`/${item.venueId}`}>

                                    <Swiper
                                        spaceBetween={5}
                                        slidesPerView={1}
                                        navigation={{
                                            nextEl: ".swiper-button-next",
                                            prevEl: ".swiper-button-prev",
                                        }}
                                        loop={true}
                                        observer={true}
                                        observeParents={true}
                                        parallax={true}
                                        pagination={{
                                            clickable: true,
                                        }}
                                        modules={[Pagination]}

                                    >
                                        <SwiperSlide>
                                            <img src={item.medias[0].url_Low} className="cardImg" />
                                        </SwiperSlide>

                                        <SwiperSlide>
                                        <img src={item.medias[1].url_Low} className="cardImg" />
                                        </SwiperSlide>

                                        <SwiperSlide>
                                        <img src={item.medias[2].url_Low} className="cardImg" />
                                        </SwiperSlide>

                                        <SwiperSlide>
                                        <img src={item.medias[3].url_Low} className="cardImg" />
                                        </SwiperSlide>

                                        <SwiperSlide>
                                        <img src={item.medias[4].url_Low} className="cardImg" />
                                        </SwiperSlide>

                                        <SwiperSlide>
                                        <img src={item.medias[4].url_Low} className="cardImg" />
                                        </SwiperSlide>

                                    </Swiper>

                                    {item.pets && <div className='flex label'> <BsStars /> POPULAR </div>}

                                    <div className='flex gap-0 pl-3 pr-3 justify-between'>

                                        <div className='flex'>
                                            <p className='text-violet-600 font-semibold mr-1 text-2xl'>
                                                {item.dollar < 2 ? '$' : (item.dollar < 3 ? '$$' : '$$$')}
                                            </p>
                                            <p className='text-gray-400 text-sm pt-2'>/stay</p>
                                        </div>


                                        <FiHeart className='text-sm heartCrad  boxsh' />

                                    </div>

                                    <p className='text-2xl pl-3'> {item.title} </p>

                                    <p className='text-gray-400 text-sm pl-3 mb-3 mt-2'> {item.blockQuote} </p>
                                    <hr className='mb-4' />

                                    <div className='flex justify-center pl-2 gap-4 md:gap-3'>
                                        <span className='flex items-center'> <IoBedOutline className=' md:text-2xl text-xl mr-2' /> <p className='text-sm text-gray-400 w-16'>{item.rooms} Rooms </p></span>
                                    </div>

                                </Link>
                            </div>
                        )
                    }

                }))}
            </div>

        </div >
    )
}

export default Tab1
//

// 20240123100509
// https://app.pks-list.com/api/Venue/v1/getlist/35

export const placesStore13=
[
  {
    "venueId": 3845,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Angama Amboseli",
    "description": "An intimate lodge in the heart of Kimana Sanctuary, Kenya’s first community-owned conservancy, renowned for its lush landscape and remarkable density of wildlife — along with its famed backdrop, Mount Kilimanjaro, the highest peak in Africa. Set within a fever tree forest where some of Africa’s last Super Tuskers roam ­— elephants with tusks so large they drag along the ground as they walk — Angama Amboseli is a gentle start or finish to any East African safari.",
    "brand": "",
    "blockQuote": "Embark on a sunrise hot-air balloon safari for wonderful views over the plains.",
    "emailAddress": "enquiry@angama.com",
    "website": "angama.com/stay/angama-amboseli/",
    "bookingURL": "https://angama.com/rates-availability/",
    "redirectNotes": "Angama Amboseli's reservations system",
    "phoneNumber": "+254 730 630 630",
    "addressOne": "",
    "addressTwo": "",
    "city": "Amboseli",
    "county": "",
    "region": "",
    "country": "Kenya",
    "rooms": 10,
    "dollar": 3,
    "updated": "2023-12-26T09:34:52.04",
    "created": "2023-12-20T11:39:53.57",
    "medias": [
      {
        "mediaId": 62616,
        "title": "Angama Amboseli opens in Kenya's Kimana Sanctuary | OutThere magazine",
        "url": "https://pkslist.azureedge.net/media/images/1/12366d2a-a9f6-4c3c-9dc8-eb2738d332ba/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/12366d2a-a9f6-4c3c-9dc8-eb2738d332ba/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/12366d2a-a9f6-4c3c-9dc8-eb2738d332ba/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 62609,
        "title": "Angama Amboseli opens in Kenya • Hotel Designs",
        "url": "https://pkslist.azureedge.net/media/images/1/ac91a44a-b782-4dc2-a702-36c550bc421f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ac91a44a-b782-4dc2-a702-36c550bc421f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ac91a44a-b782-4dc2-a702-36c550bc421f/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 62612,
        "title": "Angama Amboseli",
        "url": "https://pkslist.azureedge.net/media/images/1/29962b56-7995-4d1f-b093-fd039ebd153a/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/29962b56-7995-4d1f-b093-fd039ebd153a/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/29962b56-7995-4d1f-b093-fd039ebd153a/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 62625,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/3a15eca4-d333-4943-ae2d-7c5f609c46a1/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3a15eca4-d333-4943-ae2d-7c5f609c46a1/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3a15eca4-d333-4943-ae2d-7c5f609c46a1/256x192.png",
        "viewOrder": 3
      },
      {
        "mediaId": 62626,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/5a8e9c3c-9b37-4512-a542-f14ec25a2188/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5a8e9c3c-9b37-4512-a542-f14ec25a2188/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5a8e9c3c-9b37-4512-a542-f14ec25a2188/256x192.png",
        "viewOrder": 4
      },
      {
        "mediaId": 62619,
        "title": "Angama Amboseli lands in Kenya's Kimana Sanctuary - Sleeper",
        "url": "https://pkslist.azureedge.net/media/images/1/aab80286-3210-419c-b954-cb76f7f18a7d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/aab80286-3210-419c-b954-cb76f7f18a7d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/aab80286-3210-419c-b954-cb76f7f18a7d/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 62610,
        "title": "inTHESPOTLIGHT – Angama Amboseli, Kenya — inVOYAGE",
        "url": "https://pkslist.azureedge.net/media/images/1/11932d34-6dc9-46b3-be68-e5c5e966e971/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/11932d34-6dc9-46b3-be68-e5c5e966e971/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/11932d34-6dc9-46b3-be68-e5c5e966e971/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 62614,
        "title": "Angama Amboseli",
        "url": "https://pkslist.azureedge.net/media/images/1/419fe3d5-e08a-44f6-9654-8090f87da425/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/419fe3d5-e08a-44f6-9654-8090f87da425/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/419fe3d5-e08a-44f6-9654-8090f87da425/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 62621,
        "title": "New Openings For 2023 | Mahlatini Luxury Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/93f0a5d5-a9cb-4ef1-94a4-56a4a7aee584/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/93f0a5d5-a9cb-4ef1-94a4-56a4a7aee584/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/93f0a5d5-a9cb-4ef1-94a4-56a4a7aee584/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3581,
      "latitude": -2.7999571,
      "longitude": 37.5333759,
      "parentGeographicAreaId": 0,
      "title": "Sanctuary, Kimana, Kenya",
      "updated": null,
      "created": "2023-12-21T14:13:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 133,
        "tagName": "Glamping",
        "tagType": 2
      },
      {
        "tagId": 252,
        "tagName": "National Park",
        "tagType": 2
      },
      {
        "tagId": 265,
        "tagName": "Hot Air Balloon",
        "tagType": 2
      },
      {
        "tagId": 268,
        "tagName": "Massage",
        "tagType": 2
      },
      {
        "tagId": 269,
        "tagName": "Photography",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 296,
        "tagName": "Guided Walk",
        "tagType": 2
      },
      {
        "tagId": 410,
        "tagName": "Sundownders",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 855,
        "venueCategoryId": 13,
        "venueId": 3845,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3456,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Time + Tide Kakuli",
    "description": "Its five bright and airy safari rooms (including a two-bedroom, two-bathroom family safari room) are designed to make the most of the panoramic setting, each offering a feather-top bed, indoor and outdoor showers and a covered open-air seating area.\n\nThe elevated main lounge overlooks the river and a permanent lagoon. Beneath it, a wildlife hide offers up-close views of visitors stopping by for a drink. Take a deep breath and admire the sweeping vista with a refreshing drink of your own before sampling traditional Zambian dishes at dinner.\n\nThe camp makes a great base for exploring by vehicle and on foot, with a resident lion pride, a seasonal colony of jewel-like carmine bee-eaters and the most sightings of leopards in a single drive every year. During the lush emerald ‘green season’ venture out by boat and on foot to enjoy some of the best opportunities for wildlife and landscape photography.",
    "brand": "",
    "blockQuote": "Sundowners by the river are magical.",
    "emailAddress": "travel@timeandtideafrica.com",
    "website": "timeandtideafrica.com/camp/time-tide-kakuli/",
    "bookingURL": "https://www.timeandtideafrica.com/traveller-enquiry/?title=Time%20+%20Tide%20Kakuli&box=kakuli",
    "redirectNotes": "Time + Tide Kakuli's reservations system",
    "phoneNumber": "+27 60 642 4004",
    "addressOne": "",
    "addressTwo": "",
    "city": "South Luangwa",
    "county": "",
    "region": "",
    "country": "Zambia",
    "rooms": 5,
    "dollar": 3,
    "updated": "2022-08-31T08:39:47.877",
    "created": "2022-08-31T08:20:38.7",
    "medias": [
      {
        "mediaId": 54614,
        "title": "TIME + TIDE KAKULI - Updated 2022 Prices & Specialty Resort ...",
        "url": "https://pkslist.azureedge.net/media/images/1/da4b3825-f507-4946-8361-b3dc431dead0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/da4b3825-f507-4946-8361-b3dc431dead0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/da4b3825-f507-4946-8361-b3dc431dead0/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 54628,
        "title": "Zambia Low-Impact Adventure | AMADI | Design Your Journey",
        "url": "https://pkslist.azureedge.net/media/images/1/59c16aba-404d-40bf-be46-de1d58672413/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/59c16aba-404d-40bf-be46-de1d58672413/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/59c16aba-404d-40bf-be46-de1d58672413/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 54627,
        "title": "Time + Tide Kakuli Lodge, South Luangwa | Zambia In Style",
        "url": "https://pkslist.azureedge.net/media/images/1/89db1c8d-e73f-4cb1-862a-c1c9a9209641/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/89db1c8d-e73f-4cb1-862a-c1c9a9209641/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/89db1c8d-e73f-4cb1-862a-c1c9a9209641/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 54624,
        "title": "Time + Tide Kakuli in South Luangwa, Zambia - Journeys by Design",
        "url": "https://pkslist.azureedge.net/media/images/1/e691db5a-38ac-4315-9419-afe04764e1e2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e691db5a-38ac-4315-9419-afe04764e1e2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e691db5a-38ac-4315-9419-afe04764e1e2/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 54625,
        "title": "Time + Tide Kakuli in South Luangwa, Zambia - Journeys by Design",
        "url": "https://pkslist.azureedge.net/media/images/1/11118525-1180-4ddd-970f-5d845a99866b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/11118525-1180-4ddd-970f-5d845a99866b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/11118525-1180-4ddd-970f-5d845a99866b/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 54622,
        "title": "Time + Tide Kakuli | South Luangwa, Zambia",
        "url": "https://pkslist.azureedge.net/media/images/1/f21574cb-01ed-4321-8fd5-75a214990565/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f21574cb-01ed-4321-8fd5-75a214990565/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f21574cb-01ed-4321-8fd5-75a214990565/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 54611,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/5d7a3bec-8651-4816-aaef-741d5fc5024e/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5d7a3bec-8651-4816-aaef-741d5fc5024e/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5d7a3bec-8651-4816-aaef-741d5fc5024e/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 54615,
        "title": "Time + Tide Kakuli | South Luangwa, Zambia",
        "url": "https://pkslist.azureedge.net/media/images/1/7ba9fcf3-2a56-4261-a7e4-a90268b51da8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7ba9fcf3-2a56-4261-a7e4-a90268b51da8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7ba9fcf3-2a56-4261-a7e4-a90268b51da8/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 54617,
        "title": "Time + Tide Kakuli | South Luangwa, Zambia",
        "url": "https://pkslist.azureedge.net/media/images/1/1b31bd06-88a6-4dbe-bf62-ca44855f61e6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1b31bd06-88a6-4dbe-bf62-ca44855f61e6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1b31bd06-88a6-4dbe-bf62-ca44855f61e6/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 54616,
        "title": "Time + Tide Kakuli | South Luangwa, Zambia",
        "url": "https://pkslist.azureedge.net/media/images/1/3557f2ac-ba0f-46bc-be8d-1d39ec203720/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3557f2ac-ba0f-46bc-be8d-1d39ec203720/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3557f2ac-ba0f-46bc-be8d-1d39ec203720/256x192.jpg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3243,
      "latitude": -12.9962835,
      "longitude": 31.89580999999999,
      "parentGeographicAreaId": 0,
      "title": "Zambia",
      "updated": null,
      "created": "2022-08-31T08:36:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 131,
        "tagName": "Library",
        "tagType": 2
      },
      {
        "tagId": 202,
        "tagName": "Private Terraces",
        "tagType": 2
      },
      {
        "tagId": 263,
        "tagName": "Star Gazing",
        "tagType": 2
      },
      {
        "tagId": 269,
        "tagName": "Photography",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 296,
        "tagName": "Guided Walk",
        "tagType": 2
      },
      {
        "tagId": 350,
        "tagName": "Campfire",
        "tagType": 2
      },
      {
        "tagId": 410,
        "tagName": "Sundownders",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 790,
        "venueCategoryId": 13,
        "venueId": 3456,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3443,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Time + Tide Chongwe Suites",
    "description": "Named after indigenous plants growing in the area, the Cassia and Albida Suites are nestled under ancient towering trees at the confluence of the Chongwe and Zambezi Rivers.\n\nThe one-bedroom Cassia Suite is a private suite for two, perfect for honeymooners and couples. The two-bedroom, two-bathroom Albida Suite sleeps four guests and is great for families wanting their own space or friends travelling together. Both have en-suite open air bathrooms with a clawfoot bath, a canopied lounge and dining area, a pool overlooking the river, a fire pit and a dedicated butler.\n\nDine privately in your suite or in the main camp lounge on the riverbank, enjoying cuisine that celebrates local seasonal ingredients. Notice how much better your nightcap tastes when it’s served in a crystal glass by the fire.",
    "brand": "",
    "blockQuote": "An uber exclusive camp with spectacular views of Chongwe & Zambezi rivers.",
    "emailAddress": "travel@timeandtideafrica.com",
    "website": "timeandtideafrica.com/camp/time-tide-chongwe-suites/",
    "bookingURL": "Time + Tide Chongwe Suites' reservations system",
    "redirectNotes": "https://www.timeandtideafrica.com/traveller-enquiry/",
    "phoneNumber": "+27 60 642 4004",
    "addressOne": "",
    "addressTwo": "",
    "city": "Zambezi",
    "county": "",
    "region": "",
    "country": "Zambia",
    "rooms": 2,
    "dollar": 3,
    "updated": "2022-08-10T08:36:56.823",
    "created": "2022-08-08T14:39:47.513",
    "medias": [
      {
        "mediaId": 54391,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/3cf42b20-1c33-48eb-913a-1010c4fd85ae/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3cf42b20-1c33-48eb-913a-1010c4fd85ae/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3cf42b20-1c33-48eb-913a-1010c4fd85ae/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 54400,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/721bce81-3081-433c-b4af-428a320bb223/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/721bce81-3081-433c-b4af-428a320bb223/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/721bce81-3081-433c-b4af-428a320bb223/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 54399,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/3c500bd0-6f0b-469e-8daa-2fcda158e941/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3c500bd0-6f0b-469e-8daa-2fcda158e941/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3c500bd0-6f0b-469e-8daa-2fcda158e941/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 54394,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/6ce0080a-d429-4208-a008-afd1aed5cffc/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6ce0080a-d429-4208-a008-afd1aed5cffc/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6ce0080a-d429-4208-a008-afd1aed5cffc/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 54403,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/9f46d579-aa08-42da-8dea-2e07b12bb6f1/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9f46d579-aa08-42da-8dea-2e07b12bb6f1/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9f46d579-aa08-42da-8dea-2e07b12bb6f1/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 54406,
        "title": "Time + Tide Chongwe Suites – Vilu Private Collection – Vilu Travel ...",
        "url": "https://pkslist.azureedge.net/media/images/1/6ae73110-0468-4887-b4c1-5634150d16e2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6ae73110-0468-4887-b4c1-5634150d16e2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6ae73110-0468-4887-b4c1-5634150d16e2/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 54401,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/63db4b79-016c-4142-b108-cff9d39f5fa5/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/63db4b79-016c-4142-b108-cff9d39f5fa5/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/63db4b79-016c-4142-b108-cff9d39f5fa5/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 54404,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/fc77ad7c-7f7e-466a-9c3c-07709fb8c0b6/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fc77ad7c-7f7e-466a-9c3c-07709fb8c0b6/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fc77ad7c-7f7e-466a-9c3c-07709fb8c0b6/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 54396,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/3e212c48-4946-48c0-9c7f-ecd4feeee94d/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3e212c48-4946-48c0-9c7f-ecd4feeee94d/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3e212c48-4946-48c0-9c7f-ecd4feeee94d/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 54407,
        "title": "Time + Tide Chongwe Suites, Lower Zambezi | Crafted Africa",
        "url": "https://pkslist.azureedge.net/media/images/1/62235d57-d95e-431e-9db0-72935ca41a99/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/62235d57-d95e-431e-9db0-72935ca41a99/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/62235d57-d95e-431e-9db0-72935ca41a99/256x192.jpg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3231,
      "latitude": -15.7165262,
      "longitude": 29.3379762,
      "parentGeographicAreaId": 0,
      "title": "Plot 18, House 5, Lilayi Road, Lilayi, Zambia",
      "updated": null,
      "created": "2022-08-08T14:45:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 59,
        "tagName": "Cultural",
        "tagType": 2
      },
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 69,
        "tagName": "Couples",
        "tagType": 2
      },
      {
        "tagId": 70,
        "tagName": "Families",
        "tagType": 2
      },
      {
        "tagId": 120,
        "tagName": "Private Dining",
        "tagType": 2
      },
      {
        "tagId": 133,
        "tagName": "Glamping",
        "tagType": 2
      },
      {
        "tagId": 257,
        "tagName": "Canoeing",
        "tagType": 2
      },
      {
        "tagId": 263,
        "tagName": "Star Gazing",
        "tagType": 2
      },
      {
        "tagId": 269,
        "tagName": "Photography",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 296,
        "tagName": "Guided Walk",
        "tagType": 2
      },
      {
        "tagId": 410,
        "tagName": "Sundownders",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 786,
        "venueCategoryId": 13,
        "venueId": 3443,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3441,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Time + Tide Mchenja",
    "description": "Mchenja is the local name for ebony, and the camp is tucked into a shady ebony grove beside the Luangwa River in the heart of South Luangwa National Park.\n\nIts five safari rooms (including a two-bedroom, two-bathroom family safari room) are pure, natural elegance with grass walls, soft cushions and light-as-air curtains. Each has a feather-top bed, large picture windows and open air en-suite bathroom with a bath looking out onto the river.\n\nAt breakfast, listen to a chorus of birdsong and laughing hippos. Between safari activities, relax in the refreshing pool overlooking the Luangwa river.",
    "brand": "",
    "blockQuote": "For a unique experience, book the Sleepout Under the Stars.",
    "emailAddress": "travel@timeandtideafrica.com",
    "website": "timeandtideafrica.com/camp/time-tide-mchenja/",
    "bookingURL": "https://www.timeandtideafrica.com/traveller-enquiry/?title=Time%20+%20Tide%20Mchenja&selection=mchenja",
    "redirectNotes": "Time + Tide Mchenja's reservatioons system",
    "phoneNumber": "+27 60 642 4004",
    "addressOne": "",
    "addressTwo": "",
    "city": "South Luangwa",
    "county": "",
    "region": "",
    "country": "Zambia",
    "rooms": 5,
    "dollar": 3,
    "updated": "2022-08-08T10:32:10.18",
    "created": "2022-08-05T07:28:20.82",
    "medias": [
      {
        "mediaId": 54365,
        "title": "Time + Tide Mchenja Bush Camp, Zambia's South Luangwa NP",
        "url": "https://pkslist.azureedge.net/media/images/1/d4494687-1485-4eb3-9f31-dcb1fc40e3c3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d4494687-1485-4eb3-9f31-dcb1fc40e3c3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d4494687-1485-4eb3-9f31-dcb1fc40e3c3/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 54366,
        "title": "Time + Tide Mchanjea Camp in South Luangwa, Zambia - Journeys by ...",
        "url": "https://pkslist.azureedge.net/media/images/1/672c50df-f4a1-4cbe-9e41-10f500b84705/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/672c50df-f4a1-4cbe-9e41-10f500b84705/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/672c50df-f4a1-4cbe-9e41-10f500b84705/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 54367,
        "title": "Time + Tide Mchenja | South Luangwa, Zambia",
        "url": "https://pkslist.azureedge.net/media/images/1/f495bcd7-191e-4e84-b09c-756979e97fd2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f495bcd7-191e-4e84-b09c-756979e97fd2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f495bcd7-191e-4e84-b09c-756979e97fd2/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 54359,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/00dbcbab-0584-411a-8c2f-c2ed8b838e93/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/00dbcbab-0584-411a-8c2f-c2ed8b838e93/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/00dbcbab-0584-411a-8c2f-c2ed8b838e93/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 54362,
        "title": "TIME + TIDE MCHENJA - Prices & Specialty Resort Reviews (Zambia ...",
        "url": "https://pkslist.azureedge.net/media/images/1/da866791-d61c-4133-ab9a-ccf1a6f2d507/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/da866791-d61c-4133-ab9a-ccf1a6f2d507/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/da866791-d61c-4133-ab9a-ccf1a6f2d507/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 54358,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/866b6efd-3193-404e-a42d-88d505661b43/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/866b6efd-3193-404e-a42d-88d505661b43/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/866b6efd-3193-404e-a42d-88d505661b43/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 54355,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f76947e4-0eb5-46da-8f8e-b5a1fc4bc97e/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f76947e4-0eb5-46da-8f8e-b5a1fc4bc97e/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f76947e4-0eb5-46da-8f8e-b5a1fc4bc97e/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 54360,
        "title": "TIME + TIDE MCHENJA - Prices & Specialty Resort Reviews (Zambia ...",
        "url": "https://pkslist.azureedge.net/media/images/1/59e66a2b-8479-43f9-97e0-f91aedb52a69/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/59e66a2b-8479-43f9-97e0-f91aedb52a69/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/59e66a2b-8479-43f9-97e0-f91aedb52a69/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 54370,
        "title": "Time + Tide Mchenja Bush Camp, Zambia's South Luangwa NP",
        "url": "https://pkslist.azureedge.net/media/images/1/c276fa0f-70e8-4c61-8065-75ab7cf2eec6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c276fa0f-70e8-4c61-8065-75ab7cf2eec6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c276fa0f-70e8-4c61-8065-75ab7cf2eec6/256x192.jpg",
        "viewOrder": 12
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3229,
      "latitude": -12.9409803,
      "longitude": 31.904538,
      "parentGeographicAreaId": 0,
      "title": "South Luangwa National Park, Zambia",
      "updated": null,
      "created": "2022-08-08T10:29:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 183,
        "tagName": "Butler Service",
        "tagType": 2
      },
      {
        "tagId": 198,
        "tagName": "Boat Excursion",
        "tagType": 2
      },
      {
        "tagId": 263,
        "tagName": "Star Gazing",
        "tagType": 2
      },
      {
        "tagId": 269,
        "tagName": "Photography",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 296,
        "tagName": "Guided Walk",
        "tagType": 2
      },
      {
        "tagId": 317,
        "tagName": "Gourmet",
        "tagType": 2
      },
      {
        "tagId": 410,
        "tagName": "Sundownders",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 785,
        "venueCategoryId": 13,
        "venueId": 3441,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3421,
    "venueCategoryId": 0,
    "status": 1,
    "title": "White Desert Echo",
    "description": "Echo is as close as you can get to feeling like you’re off the planet without leaving Earth.\n\nThe space-age design of the ‘Sky Pods’ makes them look like they’ve been beamed down from Mars. Futuristic and luxurious, the six bedrooms are created from composite fibreglass with floor to ceiling windows allowing guests to soak in the moon-like landscape beyond.\n\nOriginal photos taken from the International Space Station (ISS) by former Commander, White Desert guest and retired astronaut, colonel Terry Virts will be featured in each pod. Says Virts of the Echo landscape, “The mountains are the most beautiful I have seen across Earth, Venus and Mars.”\n\nPerfect for exclusive use groups and catering for up to 12 people, Echo allows guests to combine an ultra-luxury experience with a once in lifetime adventure on the 7th Continent. As with all White Desert’s camps, Echo is designed to be dismantled, leaving no trace on the Antarctic landscape.",
    "brand": "",
    "blockQuote": "You may never get to outer space but you can live like an astronaut.",
    "emailAddress": "info@white-desert.com",
    "website": "white-desert.com/echo/",
    "bookingURL": "https://white-desert.com/dates-and-rates/2022-2023/",
    "redirectNotes": "White Desert Echo's reservations system",
    "phoneNumber": "+1 815 534 4533",
    "addressOne": "",
    "addressTwo": "",
    "city": "Somewhere",
    "county": "",
    "region": "",
    "country": "Antarctica",
    "rooms": 6,
    "dollar": 3,
    "updated": "2022-07-19T11:01:09.663",
    "created": "2022-07-19T10:45:54.977",
    "medias": [
      {
        "mediaId": 53963,
        "title": "White Desert Echo Camp | Uncrate",
        "url": "https://pkslist.azureedge.net/media/images/1/0a295c1e-eaad-4b59-9b36-e2e0129386ee/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0a295c1e-eaad-4b59-9b36-e2e0129386ee/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0a295c1e-eaad-4b59-9b36-e2e0129386ee/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 53964,
        "title": "White Desert Antarctica launch 'Echo': a new camp inspired by ...",
        "url": "https://pkslist.azureedge.net/media/images/1/cc1bf930-fa0f-4ee1-bae6-a677f718228c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cc1bf930-fa0f-4ee1-bae6-a677f718228c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cc1bf930-fa0f-4ee1-bae6-a677f718228c/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 53965,
        "title": "White Desert's New Antarctica Camp : Echo | Luxsphere",
        "url": "https://pkslist.azureedge.net/media/images/1/9683e493-63fc-4977-bcbc-cf12589514f6/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9683e493-63fc-4977-bcbc-cf12589514f6/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9683e493-63fc-4977-bcbc-cf12589514f6/256x192.png",
        "viewOrder": 2
      },
      {
        "mediaId": 53970,
        "title": "White Desert Echo Camp Antarctica | stupidDOPE.com",
        "url": "https://pkslist.azureedge.net/media/images/1/0d31d609-55e9-41c0-97b2-b5e70542077d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0d31d609-55e9-41c0-97b2-b5e70542077d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0d31d609-55e9-41c0-97b2-b5e70542077d/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 53967,
        "title": "Echo Camp from White Desert - UnnamedProject",
        "url": "https://pkslist.azureedge.net/media/images/1/7d1fc25b-21b8-4e7e-ac21-396c73dd0ca0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7d1fc25b-21b8-4e7e-ac21-396c73dd0ca0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7d1fc25b-21b8-4e7e-ac21-396c73dd0ca0/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 53962,
        "title": "First Look at Echo, White Desert's New Space-Inspired Antarctic Camp",
        "url": "https://pkslist.azureedge.net/media/images/1/f0d9eaca-d606-47bc-96b9-5096ef689e4b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f0d9eaca-d606-47bc-96b9-5096ef689e4b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f0d9eaca-d606-47bc-96b9-5096ef689e4b/256x192.jpg",
        "viewOrder": 5
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3209,
      "latitude": -75.250973,
      "longitude": -0.071389,
      "parentGeographicAreaId": 0,
      "title": "Antarctica",
      "updated": null,
      "created": "2022-07-19T10:54:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 53,
        "tagName": "Cutting Edge",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 172,
        "tagName": "Biking",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 243,
        "tagName": "Outdoor Activities",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 296,
        "tagName": "Guided Walk",
        "tagType": 2
      },
      {
        "tagId": 327,
        "tagName": "Snowmobiles",
        "tagType": 2
      },
      {
        "tagId": 338,
        "tagName": "Cross Country Skiing",
        "tagType": 2
      },
      {
        "tagId": 365,
        "tagName": "Private Chef",
        "tagType": 2
      },
      {
        "tagId": 453,
        "tagName": "Abseiling",
        "tagType": 2
      },
      {
        "tagId": 454,
        "tagName": "Ice Climbing",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 757,
        "venueCategoryId": 34,
        "venueId": 3421,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 758,
        "venueCategoryId": 17,
        "venueId": 3421,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 759,
        "venueCategoryId": 13,
        "venueId": 3421,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 760,
        "venueCategoryId": 20,
        "venueId": 3421,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 20,
          "title": "BucketList Hotels",
          "region": "Global",
          "viewOrder": 0,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 778,
        "venueCategoryId": 35,
        "venueId": 3421,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3420,
    "venueCategoryId": 0,
    "status": 1,
    "title": "White Desert Wolf’s Fang",
    "description": "Offering laidback yet sophisticated interiors that evoke the bygone age of explorers, Wolf’s Fang features six heated bedroom tents that are equipped with en-suite washrooms, replete with shower rooms. The relaxed lounge area is a haven of comfort and warmth, no matter what the conditions outside. The inviting dining area is the ideal space to revisit the day’s exploration with fellow adventurers.\n\nAdrenalin seekers will enjoy a variety of exhilarating activities on this vast continent including abseiling, ice-climbing and rope-walks. Guests looking for peace and serenity will revel in gentle hikes, skidoo rides or simply taking in the dramatic vistas – guests can be as active or as relaxed as they wish during their stay.",
    "brand": "",
    "blockQuote": "You're going to love getting a drink at the world's most remote ice bar.",
    "emailAddress": "info@white-desert.com",
    "website": "white-desert.com/our-camps/wolfs-fang/",
    "bookingURL": "https://white-desert.com/dates-and-rates/2022-2023/",
    "redirectNotes": "White Desert Wolf’s Fang's reservations system",
    "phoneNumber": "+1 815 534 4533",
    "addressOne": "",
    "addressTwo": "",
    "city": "Queen Maud Land",
    "county": "",
    "region": "",
    "country": "Antarctica",
    "rooms": 6,
    "dollar": 3,
    "updated": "2022-07-19T11:05:15.973",
    "created": "2022-07-19T10:18:25.84",
    "medias": [
      {
        "mediaId": 53952,
        "title": "White Desert unveils new Antarctic camp | Aspire Travel Club",
        "url": "https://pkslist.azureedge.net/media/images/1/a5079944-3478-4380-bd78-c17d5f7b8c85/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a5079944-3478-4380-bd78-c17d5f7b8c85/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a5079944-3478-4380-bd78-c17d5f7b8c85/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 53945,
        "title": "How to Build a Hotel in Antarctica - Bloomberg",
        "url": "https://pkslist.azureedge.net/media/images/1/c5c81550-094c-4ad4-9562-dddf37abaed1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c5c81550-094c-4ad4-9562-dddf37abaed1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c5c81550-094c-4ad4-9562-dddf37abaed1/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 53960,
        "title": "How to Build a Hotel in Antarctica - Bloomberg",
        "url": "https://pkslist.azureedge.net/media/images/1/9a8f8fec-7d58-483c-b8ff-8adf30e4e8f0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9a8f8fec-7d58-483c-b8ff-8adf30e4e8f0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9a8f8fec-7d58-483c-b8ff-8adf30e4e8f0/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 53944,
        "title": "Tempus News Tags - Antarctica",
        "url": "https://pkslist.azureedge.net/media/images/1/a23ed882-caa3-45b8-b85f-177e34b96acf/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a23ed882-caa3-45b8-b85f-177e34b96acf/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a23ed882-caa3-45b8-b85f-177e34b96acf/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 53947,
        "title": "Experience Antarctica Without Getting Seasick - EliteVoyage",
        "url": "https://pkslist.azureedge.net/media/images/1/0986c4f6-7b32-4333-8935-6206dc634aa5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0986c4f6-7b32-4333-8935-6206dc634aa5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0986c4f6-7b32-4333-8935-6206dc634aa5/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 53943,
        "title": "Tempus News - White Desert launches Wolf's Fang, a new camp in ...",
        "url": "https://pkslist.azureedge.net/media/images/1/52f0eafd-cdec-41de-9949-d169ed75ecc7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/52f0eafd-cdec-41de-9949-d169ed75ecc7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/52f0eafd-cdec-41de-9949-d169ed75ecc7/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 53961,
        "title": "How to Build a Hotel in Antarctica - Bloomberg",
        "url": "https://pkslist.azureedge.net/media/images/1/59e72f7c-08a5-4db6-8b64-ecb7cfa97c22/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/59e72f7c-08a5-4db6-8b64-ecb7cfa97c22/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/59e72f7c-08a5-4db6-8b64-ecb7cfa97c22/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 53950,
        "title": "White Desert Tours Antarctica Opens Wolf's Fang Camp",
        "url": "https://pkslist.azureedge.net/media/images/1/e1859777-ac73-46dc-9955-00c647648f17/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e1859777-ac73-46dc-9955-00c647648f17/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e1859777-ac73-46dc-9955-00c647648f17/256x192.jpg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3208,
      "latitude": -86,
      "longitude": -160,
      "parentGeographicAreaId": 0,
      "title": "Queen Maud Mountains, Antarctica",
      "updated": null,
      "created": "2022-07-19T10:23:00"
    },
    "personalities": [
      {
        "tagId": 53,
        "tagName": "Cutting Edge",
        "tagType": 1
      },
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 133,
        "tagName": "Glamping",
        "tagType": 2
      },
      {
        "tagId": 172,
        "tagName": "Biking",
        "tagType": 2
      },
      {
        "tagId": 243,
        "tagName": "Outdoor Activities",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 296,
        "tagName": "Guided Walk",
        "tagType": 2
      },
      {
        "tagId": 317,
        "tagName": "Gourmet",
        "tagType": 2
      },
      {
        "tagId": 327,
        "tagName": "Snowmobiles",
        "tagType": 2
      },
      {
        "tagId": 453,
        "tagName": "Abseiling",
        "tagType": 2
      },
      {
        "tagId": 454,
        "tagName": "Ice Climbing",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 754,
        "venueCategoryId": 34,
        "venueId": 3420,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 755,
        "venueCategoryId": 17,
        "venueId": 3420,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 756,
        "venueCategoryId": 13,
        "venueId": 3420,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 779,
        "venueCategoryId": 35,
        "venueId": 3420,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 35,
          "title": "New Openings",
          "region": "Global",
          "viewOrder": 1,
          "updated": "2022-07-26T08:22:00",
          "created": "2022-07-26T08:22:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3224,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Ol Seki Hemingways Mara",
    "description": "Ol Seki Hemingways Mara is located in the heart of the Naboisho Conservancy which borders the Maasai Mara Reserve. The conservancy limits entry to clients actually staying inside the area and offers an incredibly private safari experience.  We have a choice of ten exclusive tents in which to host you accompanied by some breathtaking views and the warmest Kenyan hospitality you could wish for. There is no typical day at Ol Seki Hemingways Mara. We’re small and aim to personally tailor every day to suit what you want making your time in the Mara an unparalleled adventure in the realms of safari.",
    "brand": "",
    "blockQuote": "Go on a Balloon Safari for a unique bird's eye view of the plains and its wildlife.",
    "emailAddress": "olseki@hemingways.co",
    "website": "hemingways-collection.com/mara/",
    "bookingURL": "https://www.hemingways-collection.com/mara/availability/",
    "redirectNotes": "Ol Seki Hemingways Mara's reservations system",
    "phoneNumber": "+254 716 613 051",
    "addressOne": "",
    "addressTwo": "",
    "city": "Mara Naboisho Conservancy",
    "county": "",
    "region": "Masai Mara",
    "country": "Kenya",
    "rooms": 10,
    "dollar": 3,
    "updated": "2021-09-09T14:05:55.09",
    "created": "2021-09-09T13:22:48.197",
    "medias": [
      {
        "mediaId": 49363,
        "title": "Ol Seki Hemingways Mara | Zuru Kenya",
        "url": "https://pkslist.azureedge.net/media/images/1/89f9f840-744b-49ac-81d2-597cc6a39ca6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/89f9f840-744b-49ac-81d2-597cc6a39ca6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/89f9f840-744b-49ac-81d2-597cc6a39ca6/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 49358,
        "title": "Ol Seki Hemingways Mara | Luxury Camps in Kenya | Yellow Zebra Safaris",
        "url": "https://pkslist.azureedge.net/media/images/1/c4323c73-c9c4-4a3b-b64b-bb45b736621c/1200x900.505&mode=crop&width=2048&height=1024&format=jpg&rnd=132108778860000000",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c4323c73-c9c4-4a3b-b64b-bb45b736621c/800x600.505&mode=crop&width=2048&height=1024&format=jpg&rnd=132108778860000000",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c4323c73-c9c4-4a3b-b64b-bb45b736621c/256x192.505&mode=crop&width=2048&height=1024&format=jpg&rnd=132108778860000000",
        "viewOrder": 1
      },
      {
        "mediaId": 49356,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/2a5eacfb-5804-485c-a8c1-5d9f9a6f2293/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2a5eacfb-5804-485c-a8c1-5d9f9a6f2293/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2a5eacfb-5804-485c-a8c1-5d9f9a6f2293/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 49364,
        "title": "Ol Seki Hemingways Mara, Masai Mara National Reserve, Kenya - 2020 ...",
        "url": "https://pkslist.azureedge.net/media/images/1/33f98c62-13c4-4f29-8279-81927dfa2090/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/33f98c62-13c4-4f29-8279-81927dfa2090/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/33f98c62-13c4-4f29-8279-81927dfa2090/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 49375,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/01d7750b-5ec9-4ef3-bf97-582f88411e3c/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/01d7750b-5ec9-4ef3-bf97-582f88411e3c/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/01d7750b-5ec9-4ef3-bf97-582f88411e3c/256x192.jpeg",
        "viewOrder": 4
      },
      {
        "mediaId": 49368,
        "title": "Ol Seki Hemingways Mara | Africa Safari Hotels & Lodges",
        "url": "https://pkslist.azureedge.net/media/images/1/31a07ddd-f932-4332-bc2e-e918f6816f68/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/31a07ddd-f932-4332-bc2e-e918f6816f68/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/31a07ddd-f932-4332-bc2e-e918f6816f68/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 49370,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/bc929da6-dda0-4b64-a680-d15ccff8cb87/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bc929da6-dda0-4b64-a680-d15ccff8cb87/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bc929da6-dda0-4b64-a680-d15ccff8cb87/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 49361,
        "title": "Ol Seki Hemingways Mara - Home",
        "url": "https://pkslist.azureedge.net/media/images/1/bd9aa257-820e-4d28-a906-b3dd262087ea/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bd9aa257-820e-4d28-a906-b3dd262087ea/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bd9aa257-820e-4d28-a906-b3dd262087ea/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 49374,
        "title": "Family Kenya's Amboseli & Masai Mara - Package Cost & Prices",
        "url": "https://pkslist.azureedge.net/media/images/1/447b134f-7e16-4345-996d-9c99b07b6cb0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/447b134f-7e16-4345-996d-9c99b07b6cb0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/447b134f-7e16-4345-996d-9c99b07b6cb0/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 3012,
      "latitude": -1.4017368,
      "longitude": 35.3917731,
      "parentGeographicAreaId": 0,
      "title": "Naboisho Conservancy, Narok, Kenya",
      "updated": null,
      "created": "2021-09-09T13:28:00"
    },
    "personalities": [
      {
        "tagId": 50,
        "tagName": "Barefoot Luxury",
        "tagType": 1
      },
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 133,
        "tagName": "Glamping",
        "tagType": 2
      },
      {
        "tagId": 135,
        "tagName": "Bird Watching",
        "tagType": 2
      },
      {
        "tagId": 263,
        "tagName": "Star Gazing",
        "tagType": 2
      },
      {
        "tagId": 265,
        "tagName": "Hot Air Balloon",
        "tagType": 2
      },
      {
        "tagId": 269,
        "tagName": "Photography",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 296,
        "tagName": "Guided Walk",
        "tagType": 2
      },
      {
        "tagId": 350,
        "tagName": "Campfire",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 736,
        "venueCategoryId": 13,
        "venueId": 3224,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3101,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Forest Chem Chem",
    "description": "Find a place where the rustling of the trees, the warmth of the fire and privacy of a camp all to yourselves set the scene for an unforgettable African experience. Immerse your senses in the wilderness all around and enjoy the freedom of a personal bush camp. ‘Kuungana’ means ‘to connect’ in Swahili and this is the essence of Forest Chem Chem. The camp has been designed to bring people together over the warmth of a crackling camp fire.It is a place for families and friends to connect with each other, with the bush, with Africa and with themselves. Found on the same land as Little Chem Chem in a secluded pocket of fever trees, this private camp enjoys 16 000ha of exclusive wilderness areas adjoining the Tarangire National Park.\n\nPrivate game drives with personal safari guides mean the freedom to explore together with open vehicles on game-rich land. From gentle herds of breeding elephant only a few metres away to prides of lion, large groups of zebra, lavish birdlife and of course, the plentiful visits from our monkey neighbours, Forest Chem Chem embraces the balance of the land.",
    "brand": "",
    "blockQuote": "Children of all ages are welcomed at Forest Chem Chem.",
    "emailAddress": "reservations@chemchemsafari.co.tz",
    "website": "chemchemsafari.com/lodge-camp/forest-chem-chem",
    "bookingURL": "http://www.chemchemsafari.com/availability",
    "redirectNotes": "Forest Chem Chem's reservations system",
    "phoneNumber": "+255 682 564 293",
    "addressOne": "",
    "addressTwo": "",
    "city": "Tarangire National Park",
    "county": "",
    "region": "",
    "country": "Tanzania",
    "rooms": 3,
    "dollar": 3,
    "updated": "2021-02-15T10:28:40.29",
    "created": "2021-02-12T11:12:15.197",
    "medias": [
      {
        "mediaId": 45359,
        "title": "Forest Chem Chem - Home",
        "url": "https://pkslist.azureedge.net/media/images/1/ba7d5e5a-4c3c-49af-8f37-e59924ede5b8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ba7d5e5a-4c3c-49af-8f37-e59924ede5b8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ba7d5e5a-4c3c-49af-8f37-e59924ede5b8/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 45371,
        "title": "Forest Chem Chem - Tarangire and Lake Manyara National Parks ...",
        "url": "https://pkslist.azureedge.net/media/images/1/edffa140-cdbc-4723-a641-0df70e7760f3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/edffa140-cdbc-4723-a641-0df70e7760f3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/edffa140-cdbc-4723-a641-0df70e7760f3/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 45369,
        "title": "Forest Chem Chem, on the Tarangire River in a private concession ...",
        "url": "https://pkslist.azureedge.net/media/images/1/2747c031-f63b-4eae-9388-01d212bb38f1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2747c031-f63b-4eae-9388-01d212bb38f1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2747c031-f63b-4eae-9388-01d212bb38f1/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 45360,
        "title": "Forest Chem Chem Hotel Review | Tatler",
        "url": "https://pkslist.azureedge.net/media/images/1/b9cc52b8-f703-46e6-839b-40b3a3530f65/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b9cc52b8-f703-46e6-839b-40b3a3530f65/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b9cc52b8-f703-46e6-839b-40b3a3530f65/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 45370,
        "title": "Forest Chem Chem, on the Tarangire River in a private concession ...",
        "url": "https://pkslist.azureedge.net/media/images/1/0e56ea2e-b1d1-4e1d-bb3e-595e6c5267c4/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0e56ea2e-b1d1-4e1d-bb3e-595e6c5267c4/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0e56ea2e-b1d1-4e1d-bb3e-595e6c5267c4/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 45372,
        "title": "Chem Chem Safaris - Forest Chem Chem",
        "url": "https://pkslist.azureedge.net/media/images/1/afb9c5ae-c1e2-4aaf-9663-58f1216f1e13/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/afb9c5ae-c1e2-4aaf-9663-58f1216f1e13/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/afb9c5ae-c1e2-4aaf-9663-58f1216f1e13/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 45363,
        "title": "Forest Chem Chem, on the Tarangire River in a private concession ...",
        "url": "https://pkslist.azureedge.net/media/images/1/a0579302-856f-40c5-b206-0f7731c69058/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a0579302-856f-40c5-b206-0f7731c69058/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a0579302-856f-40c5-b206-0f7731c69058/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 45362,
        "title": "Forest Chem Chem Tanzania | Safari Guru",
        "url": "https://pkslist.azureedge.net/media/images/1/460ba79c-dc7a-4345-900f-5d38dd2800e0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/460ba79c-dc7a-4345-900f-5d38dd2800e0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/460ba79c-dc7a-4345-900f-5d38dd2800e0/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 45368,
        "title": "Forest Chem Chem | Tarangire National Park | Expert Africa",
        "url": "https://pkslist.azureedge.net/media/images/1/40b623bc-f668-48bd-8c89-3c2ff15ca441/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/40b623bc-f668-48bd-8c89-3c2ff15ca441/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/40b623bc-f668-48bd-8c89-3c2ff15ca441/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2888,
      "latitude": -3.794874,
      "longitude": 35.9122671,
      "parentGeographicAreaId": 0,
      "title": "Forest Chem Chem - Tarangire River, Mdori, Tanzania",
      "updated": null,
      "created": "2021-02-12T11:18:00"
    },
    "personalities": [
      {
        "tagId": 189,
        "tagName": "Exclusively Yours",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 120,
        "tagName": "Private Dining",
        "tagType": 2
      },
      {
        "tagId": 133,
        "tagName": "Glamping",
        "tagType": 2
      },
      {
        "tagId": 135,
        "tagName": "Bird Watching",
        "tagType": 2
      },
      {
        "tagId": 265,
        "tagName": "Hot Air Balloon",
        "tagType": 2
      },
      {
        "tagId": 268,
        "tagName": "Massage",
        "tagType": 2
      },
      {
        "tagId": 269,
        "tagName": "Photography",
        "tagType": 2
      },
      {
        "tagId": 278,
        "tagName": "Conservation",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 296,
        "tagName": "Guided Walk",
        "tagType": 2
      },
      {
        "tagId": 324,
        "tagName": "Local Village",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 724,
        "venueCategoryId": 13,
        "venueId": 3101,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3076,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Ol Jogi",
    "description": "Nestled into a protective rocky outcrop that is surrounded by lush greenery, the manor house contains a dining room, games room, bar/lounge, and a wonderful collection of the owner's private art.  There is also an large pool complex, complete with a waterfall, that is also home to the spa and fitness center.\n\nSurrounding the main house,  a collection of quaint cottages are set under grand shady trees. Ol Jogi sits on a family-operated wildlife conservancy on the famous Laikipia Plateau in Kenya. The reserve is home to roughly 7000 large mammals including the Big 5, the worlds largest population of endangered Grevy's zebra, and significant numbers of other endangered animals. The core focus of the conservancy is wildlife conservation - there is a vetinary clinic, wildlife sanctuary and wildlife rehabilitation centre. During your stay you can walk with rescued elephants, and may even be invited on a vetinary intervention.",
    "brand": "",
    "blockQuote": "Do ask to see the 'top secret' James Bond-worthy wine cellar.",
    "emailAddress": "reservations@oljogi.org",
    "website": "oljogihome.com/sliding-home2/#/home",
    "bookingURL": "http://oljogihome.com/sliding-home2/#/contact-us",
    "redirectNotes": "Ol Jogi's reservations system",
    "phoneNumber": "+254 62 203 1650",
    "addressOne": "",
    "addressTwo": "",
    "city": "Nanyuki",
    "county": "",
    "region": "Laikipia",
    "country": "Kenya",
    "rooms": 13,
    "dollar": 3,
    "updated": "2021-09-23T12:06:21.53",
    "created": "2021-01-24T14:57:12.3",
    "medias": [
      {
        "mediaId": 44802,
        "title": "Spotlight on Ol Jogi",
        "url": "https://pkslist.azureedge.net/media/images/1/73eebf78-df24-47f8-8d94-94f3c08a668b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/73eebf78-df24-47f8-8d94-94f3c08a668b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/73eebf78-df24-47f8-8d94-94f3c08a668b/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 50017,
        "title": "Ol Jogi – Hotel Review | Condé Nast Traveler",
        "url": "https://pkslist.azureedge.net/media/images/1/3b2fa7f9-5631-4121-a84b-ae8ecaeb2cc5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3b2fa7f9-5631-4121-a84b-ae8ecaeb2cc5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3b2fa7f9-5631-4121-a84b-ae8ecaeb2cc5/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 44795,
        "title": "Ol Jogi Ranch - Travel Discover Kenya",
        "url": "https://pkslist.azureedge.net/media/images/1/69e7ae7b-7ebb-4bcf-acee-3b585866157a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/69e7ae7b-7ebb-4bcf-acee-3b585866157a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/69e7ae7b-7ebb-4bcf-acee-3b585866157a/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 44812,
        "title": "Ol Jogi Ranch - Micato Luxury Safaris and Journeys",
        "url": "https://pkslist.azureedge.net/media/images/1/ddb2e2aa-40b5-4311-bdfd-722d4ea3756b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ddb2e2aa-40b5-4311-bdfd-722d4ea3756b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ddb2e2aa-40b5-4311-bdfd-722d4ea3756b/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 44814,
        "title": "Ol Jogi Ranch - Micato Luxury Safaris and Journeys",
        "url": "https://pkslist.azureedge.net/media/images/1/c334928b-9e0c-4127-bd0d-4ed9b1d4738e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c334928b-9e0c-4127-bd0d-4ed9b1d4738e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c334928b-9e0c-4127-bd0d-4ed9b1d4738e/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 44797,
        "title": "Ol Jogi Home",
        "url": "https://pkslist.azureedge.net/media/images/1/a2d8f159-a496-445a-9388-bccff9c13b08/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a2d8f159-a496-445a-9388-bccff9c13b08/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a2d8f159-a496-445a-9388-bccff9c13b08/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 50016,
        "title": "Ol Jogi Ranch, Kenya-Luxury Africa Camps & Lodges-Micato Safaris",
        "url": "https://pkslist.azureedge.net/media/images/1/782daf57-c68a-4abc-b677-a2cc331294cd/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/782daf57-c68a-4abc-b677-a2cc331294cd/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/782daf57-c68a-4abc-b677-a2cc331294cd/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 44811,
        "title": "Ol Jogi Wildlife Conservancy, Kenya - Pelorus",
        "url": "https://pkslist.azureedge.net/media/images/1/83b71470-47a4-4c3a-826f-51ce925fa6da/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/83b71470-47a4-4c3a-826f-51ce925fa6da/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/83b71470-47a4-4c3a-826f-51ce925fa6da/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 44813,
        "title": "Ol Jogi Home Kenya|Laikipia Luxury Safari Homes",
        "url": "https://pkslist.azureedge.net/media/images/1/451fee06-51de-4eae-9f8e-5bc8bb1fac36/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/451fee06-51de-4eae-9f8e-5bc8bb1fac36/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/451fee06-51de-4eae-9f8e-5bc8bb1fac36/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 50018,
        "title": "SPECIAL: OL JOGI RANCH | Especially Africa",
        "url": "https://pkslist.azureedge.net/media/images/1/bb29ebe6-1ec5-4262-b665-4962b5f1be4f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bb29ebe6-1ec5-4262-b665-4962b5f1be4f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bb29ebe6-1ec5-4262-b665-4962b5f1be4f/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 44806,
        "title": "Pin on Ol jogi",
        "url": "https://pkslist.azureedge.net/media/images/1/d28f75d0-23f8-45cc-94c3-bb34415d02aa/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d28f75d0-23f8-45cc-94c3-bb34415d02aa/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d28f75d0-23f8-45cc-94c3-bb34415d02aa/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 44796,
        "title": "Ol Jogi Home",
        "url": "https://pkslist.azureedge.net/media/images/1/c609fd70-0a03-41ea-ac7e-93eea06246d4/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c609fd70-0a03-41ea-ac7e-93eea06246d4/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c609fd70-0a03-41ea-ac7e-93eea06246d4/256x192.jpg",
        "viewOrder": 11
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2862,
      "latitude": 0.3145033,
      "longitude": 36.9755982,
      "parentGeographicAreaId": 0,
      "title": "Kenya",
      "updated": null,
      "created": "2021-01-24T15:28:00"
    },
    "personalities": [
      {
        "tagId": 189,
        "tagName": "Exclusively Yours",
        "tagType": 1
      },
      {
        "tagId": 359,
        "tagName": "Colonial Chic",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 82,
        "tagName": "Horseback Riding",
        "tagType": 2
      },
      {
        "tagId": 131,
        "tagName": "Library",
        "tagType": 2
      },
      {
        "tagId": 135,
        "tagName": "Bird Watching",
        "tagType": 2
      },
      {
        "tagId": 266,
        "tagName": "Nature Walk",
        "tagType": 2
      },
      {
        "tagId": 276,
        "tagName": "Gym",
        "tagType": 2
      },
      {
        "tagId": 278,
        "tagName": "Conservation",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 334,
        "tagName": "Heated Pool",
        "tagType": 2
      },
      {
        "tagId": 351,
        "tagName": "Meditation",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 716,
        "venueCategoryId": 13,
        "venueId": 3076,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 717,
        "venueCategoryId": 34,
        "venueId": 3076,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3071,
    "venueCategoryId": 0,
    "status": 1,
    "title": "White Desert Whichaway",
    "description": "Whichaway is our flagship camp and sits on the shores of one of the freshwater lakes of the ice-free Schirmacher Oasis.\n\nWe’ve designed 6 heated ‘polar pods’ with cutting-edge exteriors and old-world interiors that conjure up images of Antarctica’s rich heritage of exploration. Our guests really love to sit back and chat in the communal lounge, library and dining area which is a wonderful space to relax with amazing views.\n\nSavour gourmet meals prepared by your private chef, enjoy a sauna with view of the glacier, sip cocktails by the fire, and cross off bucket list adventures like visiting the South Pole, ice climbing, hiking over ice waves, and coming face-to-face with 28,000 emperor penguins.",
    "brand": "",
    "blockQuote": "You can be David Attenborough for a day when you visit the Atka Bay Emperor Penguin Colony.",
    "emailAddress": "info@white-desert.com",
    "website": "white-desert.com/our-camps/whichaway/",
    "bookingURL": "https://white-desert.com/dates-and-rates/2022-2023/",
    "redirectNotes": "White Desert Whichaway's reservations system",
    "phoneNumber": "+1 815 534 4533",
    "addressOne": "",
    "addressTwo": "",
    "city": "Schirmacher Oasis",
    "county": "",
    "region": "",
    "country": "Antarctica",
    "rooms": 6,
    "dollar": 3,
    "updated": "2022-07-19T11:05:06.367",
    "created": "2021-01-21T17:13:19.397",
    "medias": [
      {
        "mediaId": 53936,
        "title": "White Desert Antarctic Adventures | Uncrate",
        "url": "https://pkslist.azureedge.net/media/images/1/0e478a51-9a93-4c0d-a822-337c1794df9e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0e478a51-9a93-4c0d-a822-337c1794df9e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0e478a51-9a93-4c0d-a822-337c1794df9e/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 44673,
        "title": "Ice ice baby: White Desert",
        "url": "https://pkslist.azureedge.net/media/images/1/d2642b2b-b3e7-46a8-adea-db953c1aa7ec/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d2642b2b-b3e7-46a8-adea-db953c1aa7ec/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d2642b2b-b3e7-46a8-adea-db953c1aa7ec/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 44674,
        "title": "This Luxury, One-day Trip to Antarctica Will Cost You a Mere ...",
        "url": "https://pkslist.azureedge.net/media/images/1/db56d0d5-b4b4-48a3-a8fc-5d53c30f5b28/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/db56d0d5-b4b4-48a3-a8fc-5d53c30f5b28/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/db56d0d5-b4b4-48a3-a8fc-5d53c30f5b28/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 44688,
        "title": "Antarctica: Luxury Cruises & Activities | Extraordinary Journeys",
        "url": "https://pkslist.azureedge.net/media/images/1/5835c4e6-3f71-483d-8ded-28b674c4dfbd/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5835c4e6-3f71-483d-8ded-28b674c4dfbd/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5835c4e6-3f71-483d-8ded-28b674c4dfbd/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 44684,
        "title": "Luxury campsite in Antarctica offers tiny domed pods",
        "url": "https://pkslist.azureedge.net/media/images/1/56cc4684-8e91-44d9-86a1-61e4cebbdb07/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/56cc4684-8e91-44d9-86a1-61e4cebbdb07/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/56cc4684-8e91-44d9-86a1-61e4cebbdb07/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 53940,
        "title": "White Desert Antarctica - World of Glamping - Luxury glamping escapes",
        "url": "https://pkslist.azureedge.net/media/images/1/8bc7bc9c-4bff-4e76-ba50-b78039160095/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8bc7bc9c-4bff-4e76-ba50-b78039160095/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8bc7bc9c-4bff-4e76-ba50-b78039160095/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 53941,
        "title": "Whichaway Oasis – Safari Architects",
        "url": "https://pkslist.azureedge.net/media/images/1/e5ac3b5d-d3d8-45db-8c26-d4d7464664d6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e5ac3b5d-d3d8-45db-8c26-d4d7464664d6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e5ac3b5d-d3d8-45db-8c26-d4d7464664d6/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 53938,
        "title": "White Desert Antarctica - World of Glamping - Luxury glamping escapes",
        "url": "https://pkslist.azureedge.net/media/images/1/8508eb52-7b4e-407a-a04d-c02349759f6b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8508eb52-7b4e-407a-a04d-c02349759f6b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8508eb52-7b4e-407a-a04d-c02349759f6b/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 44671,
        "title": "White Desert Antarctic Adventures | HiConsumption",
        "url": "https://pkslist.azureedge.net/media/images/1/69dd104f-669f-4901-85d0-0d05fd3a0c1c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/69dd104f-669f-4901-85d0-0d05fd3a0c1c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/69dd104f-669f-4901-85d0-0d05fd3a0c1c/256x192.jpg",
        "viewOrder": 12
      },
      {
        "mediaId": 44677,
        "title": "Flying the last frontier - Skies Mag",
        "url": "https://pkslist.azureedge.net/media/images/1/0cc4c21f-06b2-4f9a-a0d1-f73b9f645e2a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0cc4c21f-06b2-4f9a-a0d1-f73b9f645e2a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0cc4c21f-06b2-4f9a-a0d1-f73b9f645e2a/256x192.jpg",
        "viewOrder": 13
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2857,
      "latitude": -70.75,
      "longitude": 11.666667,
      "parentGeographicAreaId": 0,
      "title": "Schirmacher Ponds, Antarctica",
      "updated": null,
      "created": "2021-01-21T17:52:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 53,
        "tagName": "Cutting Edge",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 102,
        "tagName": "Eco-friendly",
        "tagType": 2
      },
      {
        "tagId": 131,
        "tagName": "Library",
        "tagType": 2
      },
      {
        "tagId": 134,
        "tagName": "Wilderness Walks",
        "tagType": 2
      },
      {
        "tagId": 164,
        "tagName": "Excursions",
        "tagType": 2
      },
      {
        "tagId": 171,
        "tagName": "Trekking",
        "tagType": 2
      },
      {
        "tagId": 186,
        "tagName": "Rock Climbing",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 259,
        "tagName": "Picnic",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 317,
        "tagName": "Gourmet",
        "tagType": 2
      },
      {
        "tagId": 338,
        "tagName": "Cross Country Skiing",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 712,
        "venueCategoryId": 17,
        "venueId": 3071,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 17,
          "title": "Winter Wonderland",
          "region": "Global",
          "viewOrder": 12,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 713,
        "venueCategoryId": 34,
        "venueId": 3071,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 34,
          "title": "Extremely Remote",
          "region": "Global",
          "viewOrder": 13,
          "updated": "2020-09-04T09:11:00",
          "created": "2020-09-04T09:11:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 714,
        "venueCategoryId": 13,
        "venueId": 3071,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3009,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Mount Camdeboo Private Game Reserve",
    "description": "Privately owned and with a passion for conservation, Mount Camdeboo Private Game Reserve is home to numerous endangered species that have been successfully rehabilitated and reintroduced into South Africa’s malaria-free Great Karoo. Experience a personalised safari in a region that is steeped in history and culture.\n\nAccommodation forms either three historic and beautifully restored Cape Dutch houses or two luxurious safari tents that lie cupped in the palm of the majestic Sneeuberg Mountain range.",
    "brand": "",
    "blockQuote": "The landscape is breathtakingly beautiful and unique to any other game reserve.",
    "emailAddress": "reservations@newmarkhotels.com",
    "website": "newmarkhotels.com/places/reserves/mount-camdeboo/",
    "bookingURL": "https://bookings.newmarkhotels.com/reservation/roomdetails",
    "redirectNotes": "Mount Camdeboo Private Game Reserve's reservations system",
    "phoneNumber": "+27 49 891 0570",
    "addressOne": "",
    "addressTwo": "Petersburg Road",
    "city": "Graaff Reinet",
    "county": "",
    "region": "",
    "country": "South Africa",
    "rooms": 12,
    "dollar": 2,
    "updated": "2020-12-12T04:53:04.257",
    "created": "2020-12-12T04:37:04.233",
    "medias": [
      {
        "mediaId": 43471,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/47fc7e19-aeaf-44e8-9eb8-197ca8096550/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/47fc7e19-aeaf-44e8-9eb8-197ca8096550/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/47fc7e19-aeaf-44e8-9eb8-197ca8096550/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 43463,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/6fc7dfca-77d3-4ef8-9129-a497e0d4b4a9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6fc7dfca-77d3-4ef8-9129-a497e0d4b4a9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6fc7dfca-77d3-4ef8-9129-a497e0d4b4a9/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 43465,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/9e6f0b42-5397-42d6-8cf4-33cedbea46e4/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9e6f0b42-5397-42d6-8cf4-33cedbea46e4/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9e6f0b42-5397-42d6-8cf4-33cedbea46e4/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 43466,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ca1c3780-8919-4595-8220-f7898e4126ad/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ca1c3780-8919-4595-8220-f7898e4126ad/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ca1c3780-8919-4595-8220-f7898e4126ad/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 43470,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/abc6580a-8ccc-4150-938e-1fd49d49470a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/abc6580a-8ccc-4150-938e-1fd49d49470a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/abc6580a-8ccc-4150-938e-1fd49d49470a/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 43475,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/bc6af43a-eaaa-4705-88c4-9e1fd871a383/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bc6af43a-eaaa-4705-88c4-9e1fd871a383/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bc6af43a-eaaa-4705-88c4-9e1fd871a383/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 43469,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/fbf8e802-37da-44f1-bd6d-7daae7b96c83/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fbf8e802-37da-44f1-bd6d-7daae7b96c83/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fbf8e802-37da-44f1-bd6d-7daae7b96c83/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 43478,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/4ab6a958-c04f-4ed3-badf-20df81ded042/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4ab6a958-c04f-4ed3-badf-20df81ded042/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4ab6a958-c04f-4ed3-badf-20df81ded042/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 43474,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/4d286e91-55f3-4847-ad8c-e8f8b24c97a2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4d286e91-55f3-4847-ad8c-e8f8b24c97a2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4d286e91-55f3-4847-ad8c-e8f8b24c97a2/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 43472,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b449c35e-2d72-47e7-89e3-13652357e517/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b449c35e-2d72-47e7-89e3-13652357e517/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b449c35e-2d72-47e7-89e3-13652357e517/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 43467,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/59b7ccc0-5c11-4c8e-8ec0-0dae12cb74fc/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/59b7ccc0-5c11-4c8e-8ec0-0dae12cb74fc/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/59b7ccc0-5c11-4c8e-8ec0-0dae12cb74fc/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 43477,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c29505a2-6c0c-45e2-b771-07f4d4ba6233/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c29505a2-6c0c-45e2-b771-07f4d4ba6233/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c29505a2-6c0c-45e2-b771-07f4d4ba6233/256x192.jpg",
        "viewOrder": 11
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2795,
      "latitude": -32.3366134,
      "longitude": 24.91296569999999,
      "parentGeographicAreaId": 0,
      "title": "Petersburg Rd, Graaff-Reinet, 6280, South Africa",
      "updated": null,
      "created": "2020-12-12T04:47:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 359,
        "tagName": "Colonial Chic",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 61,
        "tagName": "Mountain",
        "tagType": 2
      },
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 135,
        "tagName": "Bird Watching",
        "tagType": 2
      },
      {
        "tagId": 159,
        "tagName": "Mountain Biking",
        "tagType": 2
      },
      {
        "tagId": 263,
        "tagName": "Star Gazing",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 296,
        "tagName": "Guided Walk",
        "tagType": 2
      },
      {
        "tagId": 410,
        "tagName": "Sundownders",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 709,
        "venueCategoryId": 13,
        "venueId": 3009,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 3008,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Tiger Canyon",
    "description": "Once you have visited Tiger Canyon, you’ll know why it is different. Besides the obvious tiger which sets us apart, there are antelope that are typically not seen in the game reserves of Limpopo, Mpumalanga and Kwa-Zulu Natal provinces. The solar powered lodge on the canyon, the vehicles, the vistas, the surreal spirituality, the stars, our guides, our fences, our night drives, the golden hour for photography, the personal touch – all go together to make Tiger Canyon distinctly different… definitely not average!",
    "brand": "",
    "blockQuote": "You're not imagining it! These are wild tigers in Africa.",
    "emailAddress": "info@tigercanyon.com",
    "website": "tigercanyon.com/",
    "bookingURL": "http://tigercanyon.com/book-now/",
    "redirectNotes": "Tiger Canyon's reservations system",
    "phoneNumber": "+27 71 607 9279",
    "addressOne": "",
    "addressTwo": "",
    "city": "Philippolis",
    "county": "",
    "region": "",
    "country": "South Africa",
    "rooms": 3,
    "dollar": 3,
    "updated": "2020-12-12T04:54:24.423",
    "created": "2020-12-08T19:56:03.717",
    "medias": [
      {
        "mediaId": 43452,
        "title": "Tigers in the Karoo?",
        "url": "https://pkslist.azureedge.net/media/images/1/d1f642f5-dacf-4073-b49e-eabd3a8d623e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d1f642f5-dacf-4073-b49e-eabd3a8d623e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d1f642f5-dacf-4073-b49e-eabd3a8d623e/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 43456,
        "title": "Tiger Canyon Private Game Reserve, Free State, South Africa ...",
        "url": "https://pkslist.azureedge.net/media/images/1/5543e676-8d4d-496b-ae51-b79ebcb28206/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5543e676-8d4d-496b-ae51-b79ebcb28206/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5543e676-8d4d-496b-ae51-b79ebcb28206/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 43451,
        "title": "Tiger Canyon Private Game Reserve, Free State, South Africa ...",
        "url": "https://pkslist.azureedge.net/media/images/1/703b12c6-794e-44a6-9088-124731afe996/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/703b12c6-794e-44a6-9088-124731afe996/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/703b12c6-794e-44a6-9088-124731afe996/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 43457,
        "title": "Tiger Canyon Private Game Reserve, Free State, South Africa ...",
        "url": "https://pkslist.azureedge.net/media/images/1/2769dbfb-0b33-44aa-a41b-27b2baddec9f/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2769dbfb-0b33-44aa-a41b-27b2baddec9f/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2769dbfb-0b33-44aa-a41b-27b2baddec9f/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 43448,
        "title": "Tiger Canyon Private Game Reserve | Great Karoo | Safari.com",
        "url": "https://pkslist.azureedge.net/media/images/1/6bf6ae85-b332-42e5-aad2-c0fa8f1b1b50/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6bf6ae85-b332-42e5-aad2-c0fa8f1b1b50/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6bf6ae85-b332-42e5-aad2-c0fa8f1b1b50/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 43462,
        "title": "Designing Tiger Canyons Lodge | Premiere Design Studio | Interior ...",
        "url": "https://pkslist.azureedge.net/media/images/1/308c4a78-329e-4c38-8f5a-f0c650e7c29c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/308c4a78-329e-4c38-8f5a-f0c650e7c29c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/308c4a78-329e-4c38-8f5a-f0c650e7c29c/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 43450,
        "title": "tigeress-julie-lodge-tiger-canyon-south-africa-free-state-karoo ...",
        "url": "https://pkslist.azureedge.net/media/images/1/69a312f8-d913-43cb-89df-61b57d1d1c93/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/69a312f8-d913-43cb-89df-61b57d1d1c93/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/69a312f8-d913-43cb-89df-61b57d1d1c93/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 43455,
        "title": "Tiger Canyon Private Game Reserve, Free State, South Africa ...",
        "url": "https://pkslist.azureedge.net/media/images/1/4ad09fc4-a5f5-453f-b8ef-03d625d5cf8b/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4ad09fc4-a5f5-453f-b8ef-03d625d5cf8b/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4ad09fc4-a5f5-453f-b8ef-03d625d5cf8b/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 43454,
        "title": "Tigers in the Karoo?",
        "url": "https://pkslist.azureedge.net/media/images/1/5900b668-92e8-475c-bd53-f82fbf5eb965/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5900b668-92e8-475c-bd53-f82fbf5eb965/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5900b668-92e8-475c-bd53-f82fbf5eb965/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2794,
      "latitude": -30.251448,
      "longitude": 25.0393709,
      "parentGeographicAreaId": 0,
      "title": "Tiger Canyon Farm Uithou 469 District of, Philippolis, 9970, South Africa",
      "updated": null,
      "created": "2020-12-08T20:00:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 102,
        "tagName": "Eco-friendly",
        "tagType": 2
      },
      {
        "tagId": 135,
        "tagName": "Bird Watching",
        "tagType": 2
      },
      {
        "tagId": 163,
        "tagName": "Private 4WD tours",
        "tagType": 2
      },
      {
        "tagId": 269,
        "tagName": "Photography",
        "tagType": 2
      },
      {
        "tagId": 278,
        "tagName": "Conservation",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 296,
        "tagName": "Guided Walk",
        "tagType": 2
      },
      {
        "tagId": 410,
        "tagName": "Sundownders",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 708,
        "venueCategoryId": 13,
        "venueId": 3008,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2780,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Sasaab",
    "description": "Perched on the edge of a river valley, the architecture of Sasaab blends Swahili and Moroccan design principals in which the African heat is of primary consideration. Each of the nine canvas-sided rooms is over 100m² with private plunge pools and breath-taking views across the Laikipia Plateau toward the jagged peaks of Mount Kenya. Its position above the river naturally facilitates watching the herds of elephant that come to bathe below as well as a host of other wildlife.\n\nSasaab is a spectacular luxury tented camp offering the ultimate safari in Samburu. Situated just outside Samburu National Reserve in Kenya’s Northern Frontier District, the landscape is dotted with mountains, wildlife and a rich cultural heritage. It is a place of abundant natural diversity with an astounding number of animals, from elephants to cheetah and wild dogs to the ‘Samburu Special Five’ (five species unique to the area): the Beisa oryx, reticulated giraffe, Grevy’s zebra, Gerenuk antelope and Somali ostrich.",
    "brand": "",
    "blockQuote": "For an extraordinary experience, spend a night in the wilderness sleeping under the stars.",
    "emailAddress": "info@thesafaricollection.com",
    "website": "thesafaricollection.com/properties/sasaab/",
    "bookingURL": "https://www.thesafaricollection.com/about/contact/",
    "redirectNotes": "Sasaab's reservations system",
    "phoneNumber": "+254 728 601 211",
    "addressOne": "",
    "addressTwo": "",
    "city": "Samburu National Reserve",
    "county": "",
    "region": "",
    "country": "Kenya",
    "rooms": 9,
    "dollar": 3,
    "updated": "2021-12-15T10:35:19.997",
    "created": "2020-07-28T18:25:41.607",
    "medias": [
      {
        "mediaId": 50437,
        "title": "Moroccan-Styled Luxury at Sasaab Lodge in Kenya - Tailormade Africa",
        "url": "https://pkslist.azureedge.net/media/images/1/ba693383-37e3-41d1-870d-906d25d4b667/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ba693383-37e3-41d1-870d-906d25d4b667/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ba693383-37e3-41d1-870d-906d25d4b667/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 50448,
        "title": "SaSaab Camp | Rates & Prices | Safari Travel Plus",
        "url": "https://pkslist.azureedge.net/media/images/1/0820817e-992d-44cf-856b-33ff9ea08f08/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0820817e-992d-44cf-856b-33ff9ea08f08/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0820817e-992d-44cf-856b-33ff9ea08f08/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 50442,
        "title": "Sasaab Camp, Kenya - Natural World Safaris",
        "url": "https://pkslist.azureedge.net/media/images/1/aef8a472-ee3d-4183-9855-cbf3a8097e38/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/aef8a472-ee3d-4183-9855-cbf3a8097e38/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/aef8a472-ee3d-4183-9855-cbf3a8097e38/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 50436,
        "title": "Sasaab Camp | Samburu | Mount Kenya | andBeyond",
        "url": "https://pkslist.azureedge.net/media/images/1/e3a5fa77-22bd-40eb-878d-80cb85d86006/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e3a5fa77-22bd-40eb-878d-80cb85d86006/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e3a5fa77-22bd-40eb-878d-80cb85d86006/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 50445,
        "title": "Moroccan-Styled Luxury at Sasaab Lodge in Kenya - Tailormade Africa",
        "url": "https://pkslist.azureedge.net/media/images/1/e1e2295f-5426-43cb-bc13-8f720db7d3db/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e1e2295f-5426-43cb-bc13-8f720db7d3db/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e1e2295f-5426-43cb-bc13-8f720db7d3db/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 50441,
        "title": "Sasaab Camp, Kenya - Natural World Safaris",
        "url": "https://pkslist.azureedge.net/media/images/1/e28029f7-e4fb-457d-9362-717d2570360d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e28029f7-e4fb-457d-9362-717d2570360d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e28029f7-e4fb-457d-9362-717d2570360d/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 50450,
        "title": "Sasaab Lodge | Samburu National Reserve | Kenya",
        "url": "https://pkslist.azureedge.net/media/images/1/6b161979-851a-46a5-b654-da34f3e8965f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6b161979-851a-46a5-b654-da34f3e8965f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6b161979-851a-46a5-b654-da34f3e8965f/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 50446,
        "title": "Sasaab Camp, Kenya - Natural World Safaris",
        "url": "https://pkslist.azureedge.net/media/images/1/963f08d5-f0c8-4610-b028-1a99f33e73e7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/963f08d5-f0c8-4610-b028-1a99f33e73e7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/963f08d5-f0c8-4610-b028-1a99f33e73e7/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 38559,
        "title": "Sasaab | SafariFRANK",
        "url": "https://pkslist.azureedge.net/media/images/1/dc9b7469-c72f-48e5-b9d5-fb86c409eae8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/dc9b7469-c72f-48e5-b9d5-fb86c409eae8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/dc9b7469-c72f-48e5-b9d5-fb86c409eae8/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 38558,
        "title": "Sasaab Lodge, Samburu, Kenya - Safari Holiday | Far and Wild Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/b5661918-12de-4988-a68f-a7cf64f52f45/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b5661918-12de-4988-a68f-a7cf64f52f45/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b5661918-12de-4988-a68f-a7cf64f52f45/256x192.jpg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2566,
      "latitude": 0.6311773,
      "longitude": 37.35051,
      "parentGeographicAreaId": 0,
      "title": "Westgate, Samburu National Reserve, Kenya",
      "updated": null,
      "created": "2020-07-28T18:33:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 124,
        "tagName": "Helicopter",
        "tagType": 2
      },
      {
        "tagId": 132,
        "tagName": "Camel Trek",
        "tagType": 2
      },
      {
        "tagId": 263,
        "tagName": "Star Gazing",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 296,
        "tagName": "Guided Walk",
        "tagType": 2
      },
      {
        "tagId": 324,
        "tagName": "Local Village",
        "tagType": 2
      },
      {
        "tagId": 410,
        "tagName": "Sundownders",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 640,
        "venueCategoryId": 13,
        "venueId": 2780,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2777,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Kubili House",
    "description": "Kubili House is a 26900 ft 2 private residence set within Thornybush Game Reserve, in the Limpopo province of South Africa, part of the Greater Kruger National Park. What we offer is a Safari Lifestyle and not just the experience. The house includes 5 bedrooms, 3 swimming pools, a spa and steam room, a private game viewer and 3 fully equipped camping vehicles. The house is directly situated on the bank of the largest dam in the reserve which is the main watering hole in the north of the reserve, providing a consistent flow of animal activity viewable from any of the many decks. Game drives accompanied by a personal field guide and tracker are available at any time. Our style is discreet and tailored specifically to guest requirements.",
    "brand": "",
    "blockQuote": "You will not only fall in love with the wildlife, you will fall in love with the architecture and design of this house.",
    "emailAddress": "reservations@kubilihouse.com",
    "website": "kubilihouse.com/",
    "bookingURL": "https://www.kubilihouse.com/enquire/",
    "redirectNotes": null,
    "phoneNumber": "+27 82 749 6656",
    "addressOne": "",
    "addressTwo": "Guernsey Road",
    "city": "Thornybush",
    "county": "",
    "region": "Greater Kruger",
    "country": "South Africa",
    "rooms": 5,
    "dollar": 3,
    "updated": "2020-07-30T08:59:55.783",
    "created": "2020-07-28T14:58:48.547",
    "medias": [
      {
        "mediaId": 38502,
        "title": "Kubili House - Home",
        "url": "https://pkslist.azureedge.net/media/images/1/14dbb2b6-1382-4a28-a1ba-93edcd47eb31/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/14dbb2b6-1382-4a28-a1ba-93edcd47eb31/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/14dbb2b6-1382-4a28-a1ba-93edcd47eb31/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 38486,
        "title": "Safari to Kubili House with Africa Travel Resource",
        "url": "https://pkslist.azureedge.net/media/images/1/7393964c-a0fb-4f00-9244-327aa0305f70/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7393964c-a0fb-4f00-9244-327aa0305f70/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7393964c-a0fb-4f00-9244-327aa0305f70/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 38489,
        "title": "Exciting Kubili House - Luxury Safari Lodge - Kruger",
        "url": "https://pkslist.azureedge.net/media/images/1/56558e11-7e12-4bfb-8f66-a4f2c74807c8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/56558e11-7e12-4bfb-8f66-a4f2c74807c8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/56558e11-7e12-4bfb-8f66-a4f2c74807c8/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 38493,
        "title": "Safari to Kubili House with Africa Travel Resource",
        "url": "https://pkslist.azureedge.net/media/images/1/8e63b170-fc4a-485a-9489-c174bc1e6d34/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8e63b170-fc4a-485a-9489-c174bc1e6d34/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8e63b170-fc4a-485a-9489-c174bc1e6d34/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 38492,
        "title": "Exciting Kubili House - Luxury Safari Lodge - Kruger",
        "url": "https://pkslist.azureedge.net/media/images/1/a92d9006-67cc-4d1a-9312-75b2a2ac4fb6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a92d9006-67cc-4d1a-9312-75b2a2ac4fb6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a92d9006-67cc-4d1a-9312-75b2a2ac4fb6/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 38503,
        "title": "Luxury Villa Rentals in Cape Town - Kubili House | In Residence",
        "url": "https://pkslist.azureedge.net/media/images/1/d0a0d75d-fd67-4ac7-b5cf-02867000d863/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d0a0d75d-fd67-4ac7-b5cf-02867000d863/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d0a0d75d-fd67-4ac7-b5cf-02867000d863/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 38506,
        "title": "Luxury Villa Rentals in Cape Town - Kubili House | In Residence",
        "url": "https://pkslist.azureedge.net/media/images/1/83cdf203-0386-49c8-af0b-175faea9eb60/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/83cdf203-0386-49c8-af0b-175faea9eb60/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/83cdf203-0386-49c8-af0b-175faea9eb60/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 38507,
        "title": "Luxury Villa Rentals in Cape Town - Kubili House | In Residence",
        "url": "https://pkslist.azureedge.net/media/images/1/16b8a75a-4024-4e5b-843c-0cce733f0da6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/16b8a75a-4024-4e5b-843c-0cce733f0da6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/16b8a75a-4024-4e5b-843c-0cce733f0da6/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 38509,
        "title": "Safari to Kubili House with Africa Travel Resource",
        "url": "https://pkslist.azureedge.net/media/images/1/ca3ff9f8-0006-4aee-893f-0565d48f63f7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ca3ff9f8-0006-4aee-893f-0565d48f63f7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ca3ff9f8-0006-4aee-893f-0565d48f63f7/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 38505,
        "title": "Luxury Villa Rentals in Cape Town - Kubili House | In Residence",
        "url": "https://pkslist.azureedge.net/media/images/1/443e64d1-7d99-4ff6-8ebc-e684dfadf276/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/443e64d1-7d99-4ff6-8ebc-e684dfadf276/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/443e64d1-7d99-4ff6-8ebc-e684dfadf276/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 38508,
        "title": "Safari to Kubili House with Africa Travel Resource",
        "url": "https://pkslist.azureedge.net/media/images/1/e4c0031e-eea2-444e-bd1a-23fea800056e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e4c0031e-eea2-444e-bd1a-23fea800056e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e4c0031e-eea2-444e-bd1a-23fea800056e/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 38501,
        "title": "Safari to Kubili House with Africa Travel Resource",
        "url": "https://pkslist.azureedge.net/media/images/1/119ee1ea-924a-4ab3-ae5b-3cff41b19f29/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/119ee1ea-924a-4ab3-ae5b-3cff41b19f29/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/119ee1ea-924a-4ab3-ae5b-3cff41b19f29/256x192.jpg",
        "viewOrder": 11
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2563,
      "latitude": -24.4701298,
      "longitude": 31.1177162,
      "parentGeographicAreaId": 0,
      "title": "Thornybush Private Game Reserve, Greater Kruger National Park, Guernsey Rd, Hoedspruit, 1380, South Africa",
      "updated": null,
      "created": "2020-07-28T15:09:00"
    },
    "personalities": [
      {
        "tagId": 189,
        "tagName": "Exclusively Yours",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 135,
        "tagName": "Bird Watching",
        "tagType": 2
      },
      {
        "tagId": 205,
        "tagName": "Design",
        "tagType": 2
      },
      {
        "tagId": 226,
        "tagName": "Outdoor Fireplace",
        "tagType": 2
      },
      {
        "tagId": 269,
        "tagName": "Photography",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 296,
        "tagName": "Guided Walk",
        "tagType": 2
      },
      {
        "tagId": 365,
        "tagName": "Private Chef",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 639,
        "venueCategoryId": 13,
        "venueId": 2777,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2774,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Selinda Camp",
    "description": "Set in one of the most pristine wilderness areas left on our planet, the brand new Selinda Camp rests on the banks of the Selinda Spillway, as it enters the Linyanti River. The private 130,000 hectare Selinda Reserve boasts elephants by the thousands, regular sightings of the Selinda pack of African wild dogs as well as the famous Selinda Lion Pride recently feature in the National Geographic film “Birth of a Pride” by Dereck and Beverly Joubert. The area has a sense of remoteness, a true spirit of Africa, and is a great example of Botswana bush embodying a variety of species and rare animals.\n\nThe camp has three expansive guest tents, each covered under thatch and with large en-suite bathrooms, large private veranda and private swimming pool. Each guest tents is unique, designed with its own colour palette and theme. There is in some cultures a fifth element, ‘metal’, which is also incorporated into the design. Every space enhances what Livingstone would have had, what he would have travelled with and found. But beyond that, we go back to the Robinson Crusoe feel, including textures of rope, rooms under thatch, hammocks, lots of space, nautical elements and salvaged wood.\n\nIn addition to the three main tents, the Selinda Suite (like its fellow Zarafa Dhow Suite and the Duba Plains Suite), consists of a two-bedroom tented villa. The Selinda Suite operates exclusively, as guests enjoy their own private vehicle, safari guide, chef, manager/butler and entrance to their residence.\n\nThe safari drives, walks, boating, tracking, birding and other bespoke viewing activities are designed to place you in front of the best wildlife the area has to offer for a unique one on one life changing experience, from leopards to elephants and hippos and aim to tick off as many as the 200 or more bird species in the are",
    "brand": "",
    "blockQuote": "Book the sunrise scenic helicopter tour (sans doors) for a once-in-a-lifetime experience.",
    "emailAddress": "info@greatplainsconservation.com",
    "website": "https://greatplainsconservation.com/portfolio/selinda-camp/",
    "bookingURL": "https://greatplainsconservation.com/book-your-journey/",
    "redirectNotes": null,
    "phoneNumber": "+27 87 354 6591",
    "addressOne": "",
    "addressTwo": "",
    "city": "Selinda Reserve",
    "county": "",
    "region": "",
    "country": "Botswana",
    "rooms": 4,
    "dollar": 3,
    "updated": "2020-07-22T11:00:45.72",
    "created": "2020-07-22T10:41:25.75",
    "medias": [
      {
        "mediaId": 38432,
        "title": "Selinda Camp | Selinda Concession hotels | Audley Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/4b86f2da-8cb1-4c0a-bbff-565a4f54650b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4b86f2da-8cb1-4c0a-bbff-565a4f54650b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4b86f2da-8cb1-4c0a-bbff-565a4f54650b/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 38435,
        "title": "Selinda Explorers Camp - Timeless Africa Safaris",
        "url": "https://pkslist.azureedge.net/media/images/1/71051f9a-49e4-4fa9-90cf-8cec45a501ba/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/71051f9a-49e4-4fa9-90cf-8cec45a501ba/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/71051f9a-49e4-4fa9-90cf-8cec45a501ba/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 38443,
        "title": "Selinda Camp - Botswana Luxury Camp - Iconic Africa",
        "url": "https://pkslist.azureedge.net/media/images/1/63caad0b-3ab4-4950-a1a7-137eb3cdf973/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/63caad0b-3ab4-4950-a1a7-137eb3cdf973/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/63caad0b-3ab4-4950-a1a7-137eb3cdf973/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 38431,
        "title": "Selinda Camp, Selinda Reserve | Luxury Hotels in Botswana | Black ...",
        "url": "https://pkslist.azureedge.net/media/images/1/16fa52ef-e9e3-4657-bfaa-4bb7668bbdde/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/16fa52ef-e9e3-4657-bfaa-4bb7668bbdde/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/16fa52ef-e9e3-4657-bfaa-4bb7668bbdde/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 38430,
        "title": "Selinda Camp - Selinda Reserve, Botswana | Steppes Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/ff3c9f31-a495-453b-adcc-c2dbdee0827a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ff3c9f31-a495-453b-adcc-c2dbdee0827a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ff3c9f31-a495-453b-adcc-c2dbdee0827a/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 38440,
        "title": "Selinda Camp | Botswana Safari Lodges | Africa Odyssey",
        "url": "https://pkslist.azureedge.net/media/images/1/281acdb3-bc97-4e1b-8325-bbfe1beb645b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/281acdb3-bc97-4e1b-8325-bbfe1beb645b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/281acdb3-bc97-4e1b-8325-bbfe1beb645b/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 38448,
        "title": "Selinda Camp - Camps & Lodges - Micato Luxury Africa Safaris",
        "url": "https://pkslist.azureedge.net/media/images/1/ef88e8bb-9380-4df9-bcb0-9b4d1097b852/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ef88e8bb-9380-4df9-bcb0-9b4d1097b852/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ef88e8bb-9380-4df9-bcb0-9b4d1097b852/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 38446,
        "title": "How to design the perfect wildlife camp | Condé Nast Traveller ...",
        "url": "https://pkslist.azureedge.net/media/images/1/830a7f39-e660-47c0-b819-63456077b4b8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/830a7f39-e660-47c0-b819-63456077b4b8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/830a7f39-e660-47c0-b819-63456077b4b8/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 38450,
        "title": "Selinda Camp | Chobe National Park | Pure Africa Experiences",
        "url": "https://pkslist.azureedge.net/media/images/1/0a4befc5-323c-4032-bd74-00beee9a8b7b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0a4befc5-323c-4032-bd74-00beee9a8b7b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0a4befc5-323c-4032-bd74-00beee9a8b7b/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 38429,
        "title": "Selinda Camp – Great Plains Conservation | Great Plains Conservation",
        "url": "https://pkslist.azureedge.net/media/images/1/7954a404-ec93-4247-9ed9-a4fbef3a16d0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7954a404-ec93-4247-9ed9-a4fbef3a16d0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7954a404-ec93-4247-9ed9-a4fbef3a16d0/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 38441,
        "title": "Great Plains Selinda Camp | Selinga Spillway | andBeyond",
        "url": "https://pkslist.azureedge.net/media/images/1/273d7f8d-9623-471f-9dfc-6c54a87d7f87/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/273d7f8d-9623-471f-9dfc-6c54a87d7f87/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/273d7f8d-9623-471f-9dfc-6c54a87d7f87/256x192.jpg",
        "viewOrder": 11
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2560,
      "latitude": -18.5328932,
      "longitude": 23.5220696,
      "parentGeographicAreaId": 0,
      "title": "Ngamiland West, Botswana",
      "updated": null,
      "created": "2020-07-22T11:01:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 181,
        "tagName": "Private/Suite Pools",
        "tagType": 2
      },
      {
        "tagId": 263,
        "tagName": "Star Gazing",
        "tagType": 2
      },
      {
        "tagId": 268,
        "tagName": "Massage",
        "tagType": 2
      },
      {
        "tagId": 269,
        "tagName": "Photography",
        "tagType": 2
      },
      {
        "tagId": 296,
        "tagName": "Guided Walk",
        "tagType": 2
      },
      {
        "tagId": 410,
        "tagName": "Sundownders",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 638,
        "venueCategoryId": 13,
        "venueId": 2774,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2772,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Duba Explorers Camp",
    "description": "Duba Explorers Camp is a wildlife connoisseur’s getaway; situated on an island in the northern reaches of the 33,000 hectare Duba concession and surrounded on all sides by pristine Okavango Delta. This intimate camp, with its own private traversing area within the concession and away from that of Duba Plains Camp, consists of five expedition-style tents of light airy canvas on slightly raised decking, each with views of the surrounding floodplain.\n\nEach tent is stylishly furnished and designed by Great Plains Conservation Founders and National Geographic Explorers-in-Residence Dereck and Beverly Joubert, using their unique sensibilities to capture a spirit of exploration. Each room has a campaign writing desk and private verandah as well as en-suite facilities consisting of flush loo, double vanities and indoor shower. Fans and open air design maintain a comfortable climate for rest and relaxation. The main area is open-air, under sweeping marquee canvas, on raised decking and consists of an open-concept dining and lounge area.",
    "brand": "",
    "blockQuote": "The mekoro boat experience will give you a different perspective on the wildlife and bird viewing.",
    "emailAddress": "info@greatplainsconservation.com",
    "website": "https://greatplainsconservation.com/portfolio/duba-explorers-camp/",
    "bookingURL": "https://greatplainsconservation.com/book-your-journey/",
    "redirectNotes": null,
    "phoneNumber": "+27 87 354 6591",
    "addressOne": "",
    "addressTwo": "",
    "city": "Okavango Delta",
    "county": "",
    "region": "",
    "country": "Botswana",
    "rooms": 5,
    "dollar": 3,
    "updated": "2020-07-18T07:08:03.323",
    "created": "2020-07-17T20:02:51.423",
    "medias": [
      {
        "mediaId": 38399,
        "title": "Duba Explorers Camp in Botswana | My Guide Botswana",
        "url": "https://pkslist.azureedge.net/media/images/1/cdea8391-48b4-4f65-9748-78ee7daeff52/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cdea8391-48b4-4f65-9748-78ee7daeff52/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cdea8391-48b4-4f65-9748-78ee7daeff52/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 38390,
        "title": "Duba Explorers Camp – Great Plains Conservation | Great Plains ...",
        "url": "https://pkslist.azureedge.net/media/images/1/589fbd21-40b7-4223-b089-9cddf3736a20/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/589fbd21-40b7-4223-b089-9cddf3736a20/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/589fbd21-40b7-4223-b089-9cddf3736a20/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 38396,
        "title": "Duba Explorers Camp - Botswana Specialists",
        "url": "https://pkslist.azureedge.net/media/images/1/dc657580-6aea-4855-96a8-29a452c4973e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/dc657580-6aea-4855-96a8-29a452c4973e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/dc657580-6aea-4855-96a8-29a452c4973e/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 38402,
        "title": "Safari to Duba Explorers Camp with Africa Travel Resource",
        "url": "https://pkslist.azureedge.net/media/images/1/552c0256-bc8f-42af-8748-b2f4145585b3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/552c0256-bc8f-42af-8748-b2f4145585b3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/552c0256-bc8f-42af-8748-b2f4145585b3/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 38406,
        "title": "Safari to Duba Explorers Camp with Africa Travel Resource",
        "url": "https://pkslist.azureedge.net/media/images/1/a5f016dc-fee8-479d-855f-a1dff6b0b115/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a5f016dc-fee8-479d-855f-a1dff6b0b115/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a5f016dc-fee8-479d-855f-a1dff6b0b115/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 38391,
        "title": "Duba Explorers Camp – Great Plains Conservation | Great Plains ...",
        "url": "https://pkslist.azureedge.net/media/images/1/1641970e-5a45-4a4b-abce-9cd3fe295b85/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1641970e-5a45-4a4b-abce-9cd3fe295b85/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1641970e-5a45-4a4b-abce-9cd3fe295b85/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 38392,
        "title": "Duba Explorers Camp – Great Plains Conservation | Great Plains ...",
        "url": "https://pkslist.azureedge.net/media/images/1/33e70256-2d9a-4950-bdfa-a67beb84fe08/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/33e70256-2d9a-4950-bdfa-a67beb84fe08/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/33e70256-2d9a-4950-bdfa-a67beb84fe08/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 38400,
        "title": "Duba Explorers camp, Bostwana | Traveller Made",
        "url": "https://pkslist.azureedge.net/media/images/1/4022e6aa-9929-43f7-a2ed-165e3586c915/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4022e6aa-9929-43f7-a2ed-165e3586c915/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4022e6aa-9929-43f7-a2ed-165e3586c915/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2558,
      "latitude": -18.9578312,
      "longitude": 22.6743085,
      "parentGeographicAreaId": 0,
      "title": "Okavango Delta, Botswana",
      "updated": null,
      "created": "2020-07-17T20:15:00"
    },
    "personalities": [
      {
        "tagId": 47,
        "tagName": "Intimate Luxury ",
        "tagType": 1
      },
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 133,
        "tagName": "Glamping",
        "tagType": 2
      },
      {
        "tagId": 135,
        "tagName": "Bird Watching",
        "tagType": 2
      },
      {
        "tagId": 193,
        "tagName": "Outdoor Dining",
        "tagType": 2
      },
      {
        "tagId": 198,
        "tagName": "Boat Excursion",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 296,
        "tagName": "Guided Walk",
        "tagType": 2
      },
      {
        "tagId": 350,
        "tagName": "Campfire",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 637,
        "venueCategoryId": 13,
        "venueId": 2772,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2740,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Little Ongava",
    "description": "Little Ongava is perched on the crest of a hill commanding magnificent vistas of the plains stretching for miles to the horizon, offering an extraordinary experience as the focal point of an Etosha journey.\n\nThis intimate camp has only three spacious suites each with its own plunge pool, en-suite bathroom, “sala”, and outdoor shower. The lounge and dining areas have wonderful views of a productive waterhole below the camp, the open deck allowing for relaxed, stylish dining under the African sky. Guests at Little Ongava share a dedicated guide and vehicle, ensuring the best possible nature experience at one of Africa’s great wildlife destinations. Day and night wildlife-viewing drives, visiting hides overlooking waterholes, walks and rhino tracking with experienced guides can all be enjoyed exclusively on this reserve. Game drives and day trips into nearby Etosha National Park are also offered.",
    "brand": "",
    "blockQuote": "The rhino tracking and approaching experience with your dedicated guide will definitely be a highlight.",
    "emailAddress": "reservations@ongava.com",
    "website": "https://www.ongava.com/little-ongava-lodge/",
    "bookingURL": "https://www.ongava.com/contact",
    "redirectNotes": null,
    "phoneNumber": "+264 83 330 3920",
    "addressOne": "",
    "addressTwo": "",
    "city": "Etosha",
    "county": "",
    "region": "",
    "country": "Namibia",
    "rooms": 3,
    "dollar": 3,
    "updated": "2020-05-26T11:48:00.703",
    "created": "2020-05-24T13:36:19.933",
    "medias": [
      {
        "mediaId": 37354,
        "title": "Little Ongava Lodge - African Special Tours",
        "url": "https://pkslist.azureedge.net/media/images/1/a0513186-ddfb-40e4-ba8b-9c6c63f3a39f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a0513186-ddfb-40e4-ba8b-9c6c63f3a39f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a0513186-ddfb-40e4-ba8b-9c6c63f3a39f/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 37348,
        "title": "Safari to Little Ongava Lodge with Africa Travel Resource",
        "url": "https://pkslist.azureedge.net/media/images/1/e78995ac-9d9d-4915-aae2-7b1dfa7abca0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e78995ac-9d9d-4915-aae2-7b1dfa7abca0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e78995ac-9d9d-4915-aae2-7b1dfa7abca0/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 37347,
        "title": "Luxury hotel, Little Ongava Lodge, Okaukuejo, Namibia - Luxury ...",
        "url": "https://pkslist.azureedge.net/media/images/1/e43a1f83-919d-4a50-b647-b6cd31561cd5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e43a1f83-919d-4a50-b647-b6cd31561cd5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e43a1f83-919d-4a50-b647-b6cd31561cd5/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 37356,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c581936b-6a64-4221-beec-b0af92ff3dbe/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c581936b-6a64-4221-beec-b0af92ff3dbe/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c581936b-6a64-4221-beec-b0af92ff3dbe/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 37358,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e44d2c1e-a9cd-4500-a2a5-480b851f0d70/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e44d2c1e-a9cd-4500-a2a5-480b851f0d70/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e44d2c1e-a9cd-4500-a2a5-480b851f0d70/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 37345,
        "title": "Little Ongava - Ongava - Home of the Luxury Safari",
        "url": "https://pkslist.azureedge.net/media/images/1/d10eebe8-1c8f-46f6-935a-130ff5a601f3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d10eebe8-1c8f-46f6-935a-130ff5a601f3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d10eebe8-1c8f-46f6-935a-130ff5a601f3/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 37349,
        "title": "Little Ongava Lodge - Etosha National Park - Namibia",
        "url": "https://pkslist.azureedge.net/media/images/1/67b1aed0-ad9d-4a8b-9ed4-6495ec1a7d08/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/67b1aed0-ad9d-4a8b-9ed4-6495ec1a7d08/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/67b1aed0-ad9d-4a8b-9ed4-6495ec1a7d08/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 37360,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/2db5a712-6dd2-42fa-a47f-5616e7289ceb/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2db5a712-6dd2-42fa-a47f-5616e7289ceb/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2db5a712-6dd2-42fa-a47f-5616e7289ceb/256x192.jpg",
        "viewOrder": 7
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2526,
      "latitude": -19.333683,
      "longitude": 15.872314,
      "parentGeographicAreaId": 0,
      "title": "C38 | Andersson Gate, Etosha, Namibia",
      "updated": null,
      "created": "2020-05-24T13:41:00"
    },
    "personalities": [
      {
        "tagId": 47,
        "tagName": "Intimate Luxury ",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 164,
        "tagName": "Excursions",
        "tagType": 2
      },
      {
        "tagId": 181,
        "tagName": "Private/Suite Pools",
        "tagType": 2
      },
      {
        "tagId": 252,
        "tagName": "National Park",
        "tagType": 2
      },
      {
        "tagId": 263,
        "tagName": "Star Gazing",
        "tagType": 2
      },
      {
        "tagId": 269,
        "tagName": "Photography",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 296,
        "tagName": "Guided Walk",
        "tagType": 2
      },
      {
        "tagId": 324,
        "tagName": "Local Village",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 583,
        "venueCategoryId": 13,
        "venueId": 2740,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2725,
    "venueCategoryId": 0,
    "status": 1,
    "title": "andBeyond Chobe Under Canvas",
    "description": "Northern Botswana’s famous Chobe National Park is a must-see wildlife destination accessed via Kasane Airport. Covering almost 11 000 km² (6 836 mi.²) it was the country’s first national park. A haven for wildlife in the dry season, Chobe is renowned for its large concentration of elephant and buffalo, which congregate along the Chobe River. Chobe Under Canvas is situated in the park’s northern reaches, ideally positioned close to the river.\n\nLive the adventure of a lifetime as close to nature as you can get. Our exclusive, semi-permanent campsites are perfectly situated near the Chobe River at the northern end of the Chobe National Park. The common guest area comprises a spacious tent for dining, pre-dinner drinks, or simply whiling away the time between game viewing adventures with a good book and a frosted beer or glass of wine. Delicious meals cooked over the coals are shared around a blazing fire.",
    "brand": "andBeyond",
    "blockQuote": "The photographic boat cruise on the Chobe River is unforgettable and will leave you with pictures to cherish.",
    "emailAddress": "safaris@andbeyond.com",
    "website": "https://www.andbeyond.com/our-lodges/africa/botswana/chobe-national-park/andbeyond-chobe-under-canvas/",
    "bookingURL": "https://www.andbeyond.com/enquire-now/",
    "redirectNotes": null,
    "phoneNumber": "+267 686 1979",
    "addressOne": "Ngoma Gate Chobe National Park",
    "addressTwo": "",
    "city": "Chobe",
    "county": "",
    "region": "",
    "country": "Botswana",
    "rooms": 5,
    "dollar": 3,
    "updated": "2020-05-20T16:02:53.56",
    "created": "2020-05-20T15:48:16.873",
    "medias": [
      {
        "mediaId": 36902,
        "title": "andBeyond Chobe Under Canvas | Botswana | Botswana Safari",
        "url": "https://pkslist.azureedge.net/media/images/1/e6f21872-6686-49f0-b606-4bc2ebfaf749/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e6f21872-6686-49f0-b606-4bc2ebfaf749/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e6f21872-6686-49f0-b606-4bc2ebfaf749/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 36911,
        "title": "andBeyond Savute Under Canvas | Botswana Luxury Safari | Savute",
        "url": "https://pkslist.azureedge.net/media/images/1/cbd03fb7-b769-4dff-9013-d1094ffd25dd/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cbd03fb7-b769-4dff-9013-d1094ffd25dd/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cbd03fb7-b769-4dff-9013-d1094ffd25dd/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 36901,
        "title": "&Beyond Chobe Under Canvas | Chobe National Park",
        "url": "https://pkslist.azureedge.net/media/images/1/f563bb37-0a26-4f03-ba63-4812638c2c67/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f563bb37-0a26-4f03-ba63-4812638c2c67/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f563bb37-0a26-4f03-ba63-4812638c2c67/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 36909,
        "title": "andBeyond Chobe under Canvas, Botswana - Cedarberg Africa",
        "url": "https://pkslist.azureedge.net/media/images/1/21552e2c-d960-4458-bd2f-7576510f94a4/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/21552e2c-d960-4458-bd2f-7576510f94a4/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/21552e2c-d960-4458-bd2f-7576510f94a4/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 36907,
        "title": "andBeyond Chobe Under Canvas | Botswana | Botswana Safari",
        "url": "https://pkslist.azureedge.net/media/images/1/ed88d74c-3d85-41fb-be7f-57b18e5589f8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ed88d74c-3d85-41fb-be7f-57b18e5589f8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ed88d74c-3d85-41fb-be7f-57b18e5589f8/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 36903,
        "title": "andBeyond Chobe Under Canvas | Botswana | Botswana Safari",
        "url": "https://pkslist.azureedge.net/media/images/1/52550884-8863-48dd-b44e-f5bfe06ba59f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/52550884-8863-48dd-b44e-f5bfe06ba59f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/52550884-8863-48dd-b44e-f5bfe06ba59f/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 36910,
        "title": "andBeyond Chobe Under Canvas | Botswana | Botswana Safari",
        "url": "https://pkslist.azureedge.net/media/images/1/d33337ec-62b7-49e5-8c6e-cb00d5f72842/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d33337ec-62b7-49e5-8c6e-cb00d5f72842/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d33337ec-62b7-49e5-8c6e-cb00d5f72842/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 36905,
        "title": "Chobe Under Canvas in Chobe National Park - Luxury safari in Botswana",
        "url": "https://pkslist.azureedge.net/media/images/1/bbe545a2-9ead-48b7-9aa0-25294176fdfe/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bbe545a2-9ead-48b7-9aa0-25294176fdfe/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bbe545a2-9ead-48b7-9aa0-25294176fdfe/256x192.jpg",
        "viewOrder": 11
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2511,
      "latitude": -17.834191,
      "longitude": 25.025579,
      "parentGeographicAreaId": 0,
      "title": "Ngoma Gate Chobe National Park, Botswana",
      "updated": null,
      "created": "2020-05-20T15:53:00"
    },
    "personalities": [
      {
        "tagId": 47,
        "tagName": "Intimate Luxury ",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 133,
        "tagName": "Glamping",
        "tagType": 2
      },
      {
        "tagId": 183,
        "tagName": "Butler Service",
        "tagType": 2
      },
      {
        "tagId": 193,
        "tagName": "Outdoor Dining",
        "tagType": 2
      },
      {
        "tagId": 198,
        "tagName": "Boat Excursion",
        "tagType": 2
      },
      {
        "tagId": 269,
        "tagName": "Photography",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 296,
        "tagName": "Guided Walk",
        "tagType": 2
      },
      {
        "tagId": 350,
        "tagName": "Campfire",
        "tagType": 2
      },
      {
        "tagId": 410,
        "tagName": "Sundownders",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 568,
        "venueCategoryId": 13,
        "venueId": 2725,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2712,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Zannier Hotels Sonop",
    "description": "Set within our own 13,800 private acres of untouched Namibian desert wilderness, our lodge with 10 tented suites is constructed on top of granite boulders in authentic 1920’s British colonial style. Stretching from horizon to horizon, captivating beauty and boundless freedom surround you, with adventures from sunrise to sunset.\n\nOur guided excursions include horse-riding, electric fat bike or walking trails, hot air balloon safari and private sunrise or sunset safaris. After dark, the magic continues with an open-air cinema and stargazing under the endless African skies.",
    "brand": "",
    "blockQuote": "One of the most remote and exceptional landscapes I've ever stayed in.",
    "emailAddress": "reservations@sonop.com",
    "website": "https://www.zannierhotels.com/sonop/en/",
    "bookingURL": "https://be.synxis.com/?adult=1&agencyid=96027890&arrive=2023-12-28&chain=22139&child=0&config=EXTRA&currency=NAD&depart=2023-12-29&hotel=5971&level=hotel&locale=en-US&rooms=1",
    "redirectNotes": "Zannier Hotels Sonop's reservations system",
    "phoneNumber": "+264 81 125 4932",
    "addressOne": "",
    "addressTwo": "",
    "city": "Sonop",
    "county": "",
    "region": "",
    "country": "Namibia",
    "rooms": 10,
    "dollar": 3,
    "updated": "2023-12-28T12:29:13.207",
    "created": "2020-05-18T15:53:17.897",
    "medias": [
      {
        "mediaId": 63434,
        "title": "Review: Zannier Hotels Sonop (Namibia) - the Luxury Travel Expert",
        "url": "https://pkslist.azureedge.net/media/images/1/1e631782-8ada-4bd4-8495-703ed58115bc/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1e631782-8ada-4bd4-8495-703ed58115bc/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1e631782-8ada-4bd4-8495-703ed58115bc/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 36633,
        "title": "Sonop (Namib Desert, Karas) Verified Reviews | Tablet Hotels",
        "url": "https://pkslist.azureedge.net/media/images/1/445f8ce5-6d81-42c9-8319-82521ce1e3f0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/445f8ce5-6d81-42c9-8319-82521ce1e3f0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/445f8ce5-6d81-42c9-8319-82521ce1e3f0/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 63419,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/9059f33f-5187-4cf4-a8a0-6cc20369ac5f/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9059f33f-5187-4cf4-a8a0-6cc20369ac5f/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9059f33f-5187-4cf4-a8a0-6cc20369ac5f/256x192.jpeg",
        "viewOrder": 7
      },
      {
        "mediaId": 63420,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/524cc400-7363-4383-b1d2-c8becb76a1fc/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/524cc400-7363-4383-b1d2-c8becb76a1fc/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/524cc400-7363-4383-b1d2-c8becb76a1fc/256x192.jpeg",
        "viewOrder": 8
      },
      {
        "mediaId": 63421,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d95ab006-79e0-454c-95e1-533ce1a1d52d/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d95ab006-79e0-454c-95e1-533ce1a1d52d/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d95ab006-79e0-454c-95e1-533ce1a1d52d/256x192.jpeg",
        "viewOrder": 9
      },
      {
        "mediaId": 63424,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/cce89346-0f45-40ac-ab63-afa6b3621b8b/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cce89346-0f45-40ac-ab63-afa6b3621b8b/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cce89346-0f45-40ac-ab63-afa6b3621b8b/256x192.jpeg",
        "viewOrder": 10
      },
      {
        "mediaId": 63423,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f3875aad-5e75-4196-9794-882ccd82466d/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f3875aad-5e75-4196-9794-882ccd82466d/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f3875aad-5e75-4196-9794-882ccd82466d/256x192.jpeg",
        "viewOrder": 12
      },
      {
        "mediaId": 63425,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c0ea4e30-df5b-40f4-b400-d23f79402c6e/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c0ea4e30-df5b-40f4-b400-d23f79402c6e/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c0ea4e30-df5b-40f4-b400-d23f79402c6e/256x192.jpeg",
        "viewOrder": 14
      },
      {
        "mediaId": 63418,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ae9db308-9073-49cd-ba5a-bca301d576c5/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ae9db308-9073-49cd-ba5a-bca301d576c5/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ae9db308-9073-49cd-ba5a-bca301d576c5/256x192.jpeg",
        "viewOrder": 15
      },
      {
        "mediaId": 63422,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e69f2d52-7776-4aa5-81d7-3c5b89000691/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e69f2d52-7776-4aa5-81d7-3c5b89000691/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e69f2d52-7776-4aa5-81d7-3c5b89000691/256x192.jpeg",
        "viewOrder": 16
      },
      {
        "mediaId": 36613,
        "title": "Zannier Sonop Lodge - wbpstars.com - World's Best Places",
        "url": "https://pkslist.azureedge.net/media/images/1/d832b04f-018b-463f-9428-7f2fdcf35deb/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d832b04f-018b-463f-9428-7f2fdcf35deb/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d832b04f-018b-463f-9428-7f2fdcf35deb/256x192.jpg",
        "viewOrder": 17
      },
      {
        "mediaId": 36629,
        "title": "Hotel Sonop - Zannier Hotels Group - Namibia",
        "url": "https://pkslist.azureedge.net/media/images/1/3457da08-de7f-4231-95c4-a68542efbad7/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3457da08-de7f-4231-95c4-a68542efbad7/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3457da08-de7f-4231-95c4-a68542efbad7/256x192.png",
        "viewOrder": 18
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2499,
      "latitude": -25.4977992,
      "longitude": 16.1818891,
      "parentGeographicAreaId": 0,
      "title": "Sonop, Namibia",
      "updated": null,
      "created": "2020-05-18T16:02:00"
    },
    "personalities": [
      {
        "tagId": 57,
        "tagName": "Romantic",
        "tagType": 1
      },
      {
        "tagId": 359,
        "tagName": "Colonial Chic",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 82,
        "tagName": "Horseback Riding",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 101,
        "tagName": "Cinema",
        "tagType": 2
      },
      {
        "tagId": 133,
        "tagName": "Glamping",
        "tagType": 2
      },
      {
        "tagId": 153,
        "tagName": "Cigar Lounge",
        "tagType": 2
      },
      {
        "tagId": 159,
        "tagName": "Mountain Biking",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 263,
        "tagName": "Star Gazing",
        "tagType": 2
      },
      {
        "tagId": 265,
        "tagName": "Hot Air Balloon",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 566,
        "venueCategoryId": 20,
        "venueId": 2712,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 20,
          "title": "BucketList Hotels",
          "region": "Global",
          "viewOrder": 0,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 567,
        "venueCategoryId": 13,
        "venueId": 2712,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2699,
    "venueCategoryId": 0,
    "status": 1,
    "title": "One&Only Gorilla's Nest",
    "description": "Surrounded by swaying eucalyptus trees, One&Only Gorilla’s Nest awakens a wild spirit of adventure. A place that encourages you to gaze into the mirror through eye opening encounters with majestic mountain gorillas and experience the story of Rwanda.\n\nEye to eye with swaying eucalyptus trees, One&Only Gorilla’s Nest is the closest resort to Volcanoes National Park – a historic rainforest home to a diverse tapestry of wildlife, including over 340 mountain gorillas, golden monkeys, and 300 bird species. In and around the resort, there is an incredible topography of peaceful sleeping volcanoes that defy gravity, creating an awe-inspiring atmosphere and perfect setting for cyclists.",
    "brand": "One&Only",
    "blockQuote": "You will be left breathless after seeing your first gorilla.",
    "emailAddress": "reservations@oneandonlygorillasnest.com",
    "website": "oneandonlyresorts.com/gorillas-nest",
    "bookingURL": "https://www.oneandonlyresorts.com/gorillas-nest/reservations/best-available-rate/search-results?w=1",
    "redirectNotes": "One&Only Gorilla's Nest's reservations system",
    "phoneNumber": "+250 221 011 111",
    "addressOne": "",
    "addressTwo": "",
    "city": "Volcanoes National Park",
    "county": "",
    "region": "",
    "country": "Rwanda",
    "rooms": 21,
    "dollar": 3,
    "updated": "2021-07-07T12:55:49.403",
    "created": "2020-05-15T08:01:32.297",
    "medias": [
      {
        "mediaId": 35916,
        "title": "Introducing One&Only Gorilla's Nest, Rwanda | Vendôm",
        "url": "https://pkslist.azureedge.net/media/images/1/93581da1-8fb8-4a65-9e85-435a7425ee6e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/93581da1-8fb8-4a65-9e85-435a7425ee6e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/93581da1-8fb8-4a65-9e85-435a7425ee6e/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 35905,
        "title": "One & Only Gorilla's Nest in Rwanda Honors the mountain gorillas ...",
        "url": "https://pkslist.azureedge.net/media/images/1/ee64a015-0d96-466f-a0c3-1a1a0a231cb4/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ee64a015-0d96-466f-a0c3-1a1a0a231cb4/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ee64a015-0d96-466f-a0c3-1a1a0a231cb4/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 35911,
        "title": "Safari to One and Only Gorillas Nest with Africa Travel Resource",
        "url": "https://pkslist.azureedge.net/media/images/1/8318e416-7449-4165-8570-389aa301f027/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8318e416-7449-4165-8570-389aa301f027/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8318e416-7449-4165-8570-389aa301f027/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 35920,
        "title": "One&Only Gorilla's Nest | Holidays 2020/2021 | Luxury & Tailor ...",
        "url": "https://pkslist.azureedge.net/media/images/1/4a3cf38a-3c80-41c9-acd4-8e78aa4e1db2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4a3cf38a-3c80-41c9-acd4-8e78aa4e1db2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4a3cf38a-3c80-41c9-acd4-8e78aa4e1db2/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 35913,
        "title": "Forest Lodge in Rwanda | One&Only Gorilla's Nest",
        "url": "https://pkslist.azureedge.net/media/images/1/fffc5929-3ff4-404a-98e6-a97ca2de6107/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fffc5929-3ff4-404a-98e6-a97ca2de6107/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fffc5929-3ff4-404a-98e6-a97ca2de6107/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 35904,
        "title": "One&Only Gorilla's Nest Is Finally Open | Tailormade Africa",
        "url": "https://pkslist.azureedge.net/media/images/1/48572bff-8d19-4231-84c6-90c6276e6190/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/48572bff-8d19-4231-84c6-90c6276e6190/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/48572bff-8d19-4231-84c6-90c6276e6190/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 35918,
        "title": "Safari to One and Only Gorillas Nest with Africa Travel Resource",
        "url": "https://pkslist.azureedge.net/media/images/1/b03fe9db-5c62-4cac-ae67-6fb2142a4194/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b03fe9db-5c62-4cac-ae67-6fb2142a4194/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b03fe9db-5c62-4cac-ae67-6fb2142a4194/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 35923,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/777c1c61-7bef-4d81-bd8d-a0946b987bbf/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/777c1c61-7bef-4d81-bd8d-a0946b987bbf/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/777c1c61-7bef-4d81-bd8d-a0946b987bbf/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 35910,
        "title": "One&Only Gorilla's Nest Is Finally Open | Tailormade Africa",
        "url": "https://pkslist.azureedge.net/media/images/1/64b8b2ca-8d5f-4ae9-b963-f09cba90b1f0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/64b8b2ca-8d5f-4ae9-b963-f09cba90b1f0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/64b8b2ca-8d5f-4ae9-b963-f09cba90b1f0/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2486,
      "latitude": -1.442093,
      "longitude": 29.5783818,
      "parentGeographicAreaId": 0,
      "title": "Nyonirima, Kinigi, Ruhengeri, Rwanda",
      "updated": null,
      "created": "2020-05-15T08:26:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 104,
        "tagName": "Cycling",
        "tagType": 2
      },
      {
        "tagId": 135,
        "tagName": "Bird Watching",
        "tagType": 2
      },
      {
        "tagId": 171,
        "tagName": "Trekking",
        "tagType": 2
      },
      {
        "tagId": 251,
        "tagName": "Rainforest",
        "tagType": 2
      },
      {
        "tagId": 252,
        "tagName": "National Park",
        "tagType": 2
      },
      {
        "tagId": 286,
        "tagName": "Volcano",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 320,
        "tagName": "Organic Garden",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 549,
        "venueCategoryId": 13,
        "venueId": 2699,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2679,
    "venueCategoryId": 0,
    "status": 1,
    "title": "andBeyond Tengile River Lodge",
    "description": "Situated in a perfect position on the banks of the Sand River, with sweeping river views from every vantage point, lies Tengile River Lodge. Meaning ‘tranquil’ in the local language, the lodge certainly has an advantage over all others in the Sabi Sand Game Reserve.\n\nThe reserve benefits from a great diversity of wildlife including the Big Five as it neighbours and shares a 50 km (31mi) unfenced boundary with the Kruger National Park. World renowned for its prolific leopard sightings, strict vehicle limits at sightings ensure the exclusivity of an up-close game viewing experience with game drives traversing an area of 10 500 hectares (26 000 acres).\n\nThis magnificent lodge features nine enormous, air-conditioned, secluded suites, dotted in the dappled shade of the bushveld, each with panoramic river views. In addition to a master bedroom, bathroom and outdoor shower, there is a lounge, dining area, fully-stocked bar, coffee station and outdoor seating and dining area that guaranteed to take your breath away. A private lap pool on your spacious private deck comes complete with sunken outdoor lounge looking out onto the famed Sand River. For families, two of the lodge’s suites are joined by a covered walkway to create a family suite.",
    "brand": "andBeyond",
    "blockQuote": "Don't be afraid to do a wilderness walk, it gives another perspective on the wildlife.",
    "emailAddress": "safaris@andbeyond.com",
    "website": "andbeyond.com/our-lodges/africa/south-africa/sabi-sand-game-reserve/andbeyond-tengile-river-lodge/",
    "bookingURL": "https://www.andbeyond.com/contact-us/",
    "redirectNotes": null,
    "phoneNumber": "+27 11 809 4300",
    "addressOne": "",
    "addressTwo": "",
    "city": "Sabi Sand",
    "county": "",
    "region": "Greater Kruger",
    "country": "South Africa",
    "rooms": 9,
    "dollar": 3,
    "updated": "2020-05-18T15:46:02.62",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 35606,
        "title": "andBeyond Tengile River Lodge | New Safari Lodge in the Sabi Sand",
        "url": "https://pkslist.azureedge.net/media/images/1/a6bd76a6-0a32-4f96-bbdd-18a92213e849/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a6bd76a6-0a32-4f96-bbdd-18a92213e849/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a6bd76a6-0a32-4f96-bbdd-18a92213e849/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 35615,
        "title": "African Safari And Beach Holiday NewsIntroducing AndBeyond Tengile ...",
        "url": "https://pkslist.azureedge.net/media/images/1/e9a0f755-a115-483a-8030-d18a230eb39e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e9a0f755-a115-483a-8030-d18a230eb39e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e9a0f755-a115-483a-8030-d18a230eb39e/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 35613,
        "title": "&Beyond Tengile River Lodge in Sabi Sands Game Reserve - South ...",
        "url": "https://pkslist.azureedge.net/media/images/1/ff7a703a-ca6c-4ec8-a29c-e207ad0e9bf7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ff7a703a-ca6c-4ec8-a29c-e207ad0e9bf7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ff7a703a-ca6c-4ec8-a29c-e207ad0e9bf7/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 35611,
        "title": "ANDBEYOND TENGILE RIVER LODGE - Updated 2020 Prices & Reviews ...",
        "url": "https://pkslist.azureedge.net/media/images/1/cd39ea3c-e499-4f46-ba34-6d53c61dad8d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cd39ea3c-e499-4f46-ba34-6d53c61dad8d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cd39ea3c-e499-4f46-ba34-6d53c61dad8d/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 35608,
        "title": "ANDBEYOND TENGILE RIVER LODGE - Updated 2020 Prices & Reviews ...",
        "url": "https://pkslist.azureedge.net/media/images/1/db98dbbf-69f0-46dc-8c38-33e4308eb742/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/db98dbbf-69f0-46dc-8c38-33e4308eb742/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/db98dbbf-69f0-46dc-8c38-33e4308eb742/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 35609,
        "title": "&Beyond Tengile River Lodge in Sabi Sands Game Reserve - South ...",
        "url": "https://pkslist.azureedge.net/media/images/1/b12a17bb-91c2-4076-99a5-b673ae71b9bc/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b12a17bb-91c2-4076-99a5-b673ae71b9bc/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b12a17bb-91c2-4076-99a5-b673ae71b9bc/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 35614,
        "title": "andBeyond Tengile River Lodge | New Safari Lodge in the Sabi Sand",
        "url": "https://pkslist.azureedge.net/media/images/1/7ea9dfb9-129d-4a75-8912-ca020a36da67/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7ea9dfb9-129d-4a75-8912-ca020a36da67/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7ea9dfb9-129d-4a75-8912-ca020a36da67/256x192.jpg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2455,
      "latitude": -24.9325265,
      "longitude": 31.5559094,
      "parentGeographicAreaId": 0,
      "title": "Sabi Sand Private Game Reserve Sabi Sand, Sabie Park, 1350, South Africa",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 73,
        "tagName": "Outdoor Terraces",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 135,
        "tagName": "Bird Watching",
        "tagType": 2
      },
      {
        "tagId": 256,
        "tagName": "Boutique",
        "tagType": 2
      },
      {
        "tagId": 263,
        "tagName": "Star Gazing",
        "tagType": 2
      },
      {
        "tagId": 266,
        "tagName": "Nature Walk",
        "tagType": 2
      },
      {
        "tagId": 268,
        "tagName": "Massage",
        "tagType": 2
      },
      {
        "tagId": 269,
        "tagName": "Photography",
        "tagType": 2
      },
      {
        "tagId": 276,
        "tagName": "Gym",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 489,
        "venueCategoryId": 13,
        "venueId": 2679,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2549,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Arctic Watch Wilderness Lodge",
    "description": "Located 800 km north of the Arctic Circle on the shores of the Northwest Passage in Cunningham Inlet, Somerset Island, Nunavut, is the most northerly fly-in lodge on earth - Arctic Watch Wilderness Lodge. Years ago, travel to the high Arctic meant a tent on the tundra. The construction of the lodge in 1992 changed all that - and now both our locations set a high standard for guest accommodation and comfort in Nunavut. If you’re not out exploring the land or on the water, it’s all about great Canadian cuisine. Experience gourmet fare inspired by every province from locally sourced Arctic Char and Baffin Bay turbot to Alberta Organic Beef, French Canadian cheeses, and more. Our talented chef bakes breads, yogurts, ice creams and other delights daily to satisfy you at breakfast, lunch and dinner. Before or after a meal, relax, socialize or attend a lecture in the main lodge’s Great Room or explore the extensive Arctic Library and Interpretive Center housing local artifacts, maps, and 42 million year-old fossils.\n\nThere are 16 private guest cabins are each equipped with a marine toilet and sink. Private showers are located in the main complex. At night hot water bottles are provided to place under the thick duvets to add an extra level of comfort. A generator provides electricity to the cabins from early morning until 11:00 pm. There’s no electricity at night, not that you need it under the midnight sun. There are not permanent human residents on the 25,000 sq. km island, but it’s home to polar bears, muskox, arctic foxes and is a world-class beluga observations site (one of the late beluga nurseries on earth). Experience the beauty and wonder of the Arctic in remarkable comfort and hospitality at 74° North.",
    "brand": null,
    "blockQuote": "Somerset Island is not only home to polar bears, musk oxen and fox kits, it also has a world class beluga whale observation site.",
    "emailAddress": "mail@WeberArctic.com",
    "website": "weberarctic.com/",
    "bookingURL": "https://www.weberarctic.com/adventures",
    "redirectNotes": null,
    "phoneNumber": "+1 819 923 0932",
    "addressOne": "",
    "addressTwo": "",
    "city": "Cunningham Inlet",
    "county": "",
    "region": "Nunavut",
    "country": "Canada",
    "rooms": 16,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 33682,
        "title": "Arctic Watch Wilderness Lodge - Admire the unique beauty of the ...",
        "url": "https://pkslist.azureedge.net/media/images/1/f5bc2ad2-d27b-4164-8680-427b472f79d1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f5bc2ad2-d27b-4164-8680-427b472f79d1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f5bc2ad2-d27b-4164-8680-427b472f79d1/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 33671,
        "title": "Weber Arctic",
        "url": "https://pkslist.azureedge.net/media/images/1/d314c095-c8b4-45c9-a1f9-30f7e2f89357/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d314c095-c8b4-45c9-a1f9-30f7e2f89357/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d314c095-c8b4-45c9-a1f9-30f7e2f89357/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 33672,
        "title": "A Unique Wilderness Adventure: Arctic Watch Wilderness Lodge",
        "url": "https://pkslist.azureedge.net/media/images/1/427217fb-9135-4ad7-b0a1-24f68d39ec2f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/427217fb-9135-4ad7-b0a1-24f68d39ec2f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/427217fb-9135-4ad7-b0a1-24f68d39ec2f/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 33681,
        "title": "Arctic Watch Wilderness Lodge – 8-Day Lodge Stay | ExpeditionTrips.com",
        "url": "https://pkslist.azureedge.net/media/images/1/279ea839-98a4-40a5-9621-36e6c45a2024/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/279ea839-98a4-40a5-9621-36e6c45a2024/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/279ea839-98a4-40a5-9621-36e6c45a2024/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 33680,
        "title": "Locations of Weber Arctic | Weber Arctic",
        "url": "https://pkslist.azureedge.net/media/images/1/a5f7b567-74fa-40f7-bfca-de5f55df7fcd/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a5f7b567-74fa-40f7-bfca-de5f55df7fcd/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a5f7b567-74fa-40f7-bfca-de5f55df7fcd/256x192.jpg",
        "viewOrder": 11
      },
      {
        "mediaId": 33676,
        "title": "Arctic Watch Wilderness Lodge | Ultimate Travel Co",
        "url": "https://pkslist.azureedge.net/media/images/1/ce13468b-e974-45ba-ab28-6fb83273cc87/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ce13468b-e974-45ba-ab28-6fb83273cc87/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ce13468b-e974-45ba-ab28-6fb83273cc87/256x192.jpg",
        "viewOrder": 12
      },
      {
        "mediaId": 33679,
        "title": "Arctic Watch Discovery Experience - 9 Nights | Blog | Weber Arctic",
        "url": "https://pkslist.azureedge.net/media/images/1/3de89d3b-7a33-471e-9a36-de863045c671/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3de89d3b-7a33-471e-9a36-de863045c671/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3de89d3b-7a33-471e-9a36-de863045c671/256x192.jpg",
        "viewOrder": 13
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2334,
      "latitude": 74.1100374,
      "longitude": -93.80349160000003,
      "parentGeographicAreaId": 0,
      "title": "Cunningham Inlet, Baffin, Unorganized, NU, Canada",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 53,
        "tagName": "Cutting Edge",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 124,
        "tagName": "Helicopter",
        "tagType": 2
      },
      {
        "tagId": 131,
        "tagName": "Library",
        "tagType": 2
      },
      {
        "tagId": 134,
        "tagName": "Wilderness Walks",
        "tagType": 2
      },
      {
        "tagId": 135,
        "tagName": "Bird Watching",
        "tagType": 2
      },
      {
        "tagId": 170,
        "tagName": "Kayaking",
        "tagType": 2
      },
      {
        "tagId": 172,
        "tagName": "Biking",
        "tagType": 2
      },
      {
        "tagId": 237,
        "tagName": "Northern Lights",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 303,
        "tagName": "Games Room",
        "tagType": 2
      },
      {
        "tagId": 317,
        "tagName": "Gourmet",
        "tagType": 2
      },
      {
        "tagId": 324,
        "tagName": "Local Village",
        "tagType": 2
      },
      {
        "tagId": 349,
        "tagName": "4X4 Off Roading",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 454,
        "venueCategoryId": 13,
        "venueId": 2549,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2514,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Singita Kwitonda Lodge",
    "description": "Singita Kwitonda Lodge sits lightly on a pristine 178-acre stretch of land right on the edge of Rwanda’s Volcanoes National Park, where more than a third of the world’s endangered mountain gorillas find refuge.\n\nThe lodge forms part of an ambitious undertaking to rehabilitate, reforest and maintain the biological integrity of the land, thereby increasing the range and numbers of the gorillas, while uplifting and empowering surrounding communities.\n\nDesigned with respect to its remarkable geological location, Singita Kwitonda Lodge was built with sustainability as a fundamental focus. It offers an intimate engagement with the landscape and a sense of solitude within nature.\n\nEach of the lodge's eight luxurious suites boasts cosy couches, indoor and outdoor fireplaces and heated plunge pools, with breathtaking views of the Sabyinyo, Gahinga and Muhabura volcanoes.",
    "brand": "Singita",
    "blockQuote": "A memory for a lifetime is that feeling you get when you spot your first mountain gorilla.",
    "emailAddress": "enquiries@singita.com",
    "website": "singita.com/lodge/singita-kwitonda-lodge/",
    "bookingURL": "https://availability.singita.com/search?adults=2&children=0&endDate=2019-10-11&lodges[]=RWK-KL&regions[]=RWK&startDate=2019-10-09&toddler=0",
    "redirectNotes": null,
    "phoneNumber": "+27 21 683 3424",
    "addressOne": "",
    "addressTwo": "",
    "city": "Volcanoes National Park",
    "county": "",
    "region": "",
    "country": "Rwanda",
    "rooms": 8,
    "dollar": 3,
    "updated": "2020-05-18T08:13:40.203",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 32905,
        "title": "An Eco-Gorilla Opens in Rwanda",
        "url": "https://pkslist.azureedge.net/media/images/1/161435c1-6a46-4a16-aa3a-cbc43567e976/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/161435c1-6a46-4a16-aa3a-cbc43567e976/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/161435c1-6a46-4a16-aa3a-cbc43567e976/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 32908,
        "title": "Kwitonda Lodge | Singita",
        "url": "https://pkslist.azureedge.net/media/images/1/2f386ecf-f9d7-4381-8d7e-7f4c98e35dfb/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2f386ecf-f9d7-4381-8d7e-7f4c98e35dfb/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2f386ecf-f9d7-4381-8d7e-7f4c98e35dfb/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 32909,
        "title": "Luxury Lodge Supporting Gorilla Conservation Opens in Rwanda",
        "url": "https://pkslist.azureedge.net/media/images/1/3ee790dd-81b6-45f6-b997-048028765277/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3ee790dd-81b6-45f6-b997-048028765277/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3ee790dd-81b6-45f6-b997-048028765277/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 32916,
        "title": "Luxury Lodge Supporting Gorilla Conservation Opens in Rwanda",
        "url": "https://pkslist.azureedge.net/media/images/1/93e905a5-69b0-4bab-a8a5-4d51c0286a37/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/93e905a5-69b0-4bab-a8a5-4d51c0286a37/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/93e905a5-69b0-4bab-a8a5-4d51c0286a37/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 32920,
        "title": "Luxury African Safari Lodges & Reserves | Singita",
        "url": "https://pkslist.azureedge.net/media/images/1/0f270641-29bb-431a-9aec-9c9ad1dd89ab/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0f270641-29bb-431a-9aec-9c9ad1dd89ab/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0f270641-29bb-431a-9aec-9c9ad1dd89ab/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 32915,
        "title": "Singita Kwitonda Lodge And Kataza House Open In Rwanda",
        "url": "https://pkslist.azureedge.net/media/images/1/573676b2-f5f0-4ab9-92a0-a3b6f1c03f66/1200x900.jpg?fit=scale",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/573676b2-f5f0-4ab9-92a0-a3b6f1c03f66/800x600.jpg?fit=scale",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/573676b2-f5f0-4ab9-92a0-a3b6f1c03f66/256x192.jpg?fit=scale",
        "viewOrder": 6
      },
      {
        "mediaId": 32912,
        "title": "Singita Opens a New Lodge and Villa in Rwanda's Gorilla Country ...",
        "url": "https://pkslist.azureedge.net/media/images/1/2a1d97b7-fa3a-4774-9f0a-c612506f22de/1200x900.jpg?w=1000&h=563",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2a1d97b7-fa3a-4774-9f0a-c612506f22de/800x600.jpg?w=1000&h=563",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2a1d97b7-fa3a-4774-9f0a-c612506f22de/256x192.jpg?w=1000&h=563",
        "viewOrder": 8
      },
      {
        "mediaId": 32904,
        "title": "Singita Kwitonda Lodge and Kataza House: Embodying the Spirit of ...",
        "url": "https://pkslist.azureedge.net/media/images/1/d9ccfb63-dbef-4fc1-b4cc-f01c4d864b5d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d9ccfb63-dbef-4fc1-b4cc-f01c4d864b5d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d9ccfb63-dbef-4fc1-b4cc-f01c4d864b5d/256x192.jpg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2299,
      "latitude": -1.432074,
      "longitude": 29.56661299999996,
      "parentGeographicAreaId": 0,
      "title": "kinigi, Ruhengeri, Rwanda",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 156,
        "tagName": "Fireplace",
        "tagType": 2
      },
      {
        "tagId": 171,
        "tagName": "Trekking",
        "tagType": 2
      },
      {
        "tagId": 181,
        "tagName": "Private/Suite Pools",
        "tagType": 2
      },
      {
        "tagId": 252,
        "tagName": "National Park",
        "tagType": 2
      },
      {
        "tagId": 269,
        "tagName": "Photography",
        "tagType": 2
      },
      {
        "tagId": 278,
        "tagName": "Conservation",
        "tagType": 2
      },
      {
        "tagId": 286,
        "tagName": "Volcano",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 324,
        "tagName": "Local Village",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 452,
        "venueCategoryId": 13,
        "venueId": 2514,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2394,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Wolwedans Dunes Lodge",
    "description": "The Dunes Lodge is perched on top of a dune plateau, overlooking panoramic vistas in all directions and capturing the beauty of the desert in a most memorable way. \n\nThe building style is a combination of wooden structures and canvas blinds, opening up to the desert beyond. The lodge reflects the ambience of a tented camp, but provides the comfort and protection of a permanent building. Each of the nine spacious chalets with en-suite bathrooms has a private verandah, which connects you to vast stretches of untouched sand. Sleeping with your canvas blinds open is just like sleeping under the stars.\n\nThe main complex  consists of two lounges, a number of sundowner decks, a fireplace, a tea deck, a library, the wine cellar and two dining rooms. \n\nThe lodge also has a swimming pool, which is suspended above the sand. All communal areas of the lodge open out onto decks with superb views. The variety of recreational space makes a visit both exciting and relaxing.",
    "brand": null,
    "blockQuote": "Request a room closer to the main camp if you require easy accessibility.",
    "emailAddress": "reservations@wolwedans.com",
    "website": "wolwedans.com/lodges-camps/dunes-lodge/",
    "bookingURL": "https://www.wolwedans.com/reservations/enquire/?action=2&bbid=16555&lang=en",
    "redirectNotes": null,
    "phoneNumber": "+264 61 230616",
    "addressOne": "",
    "addressTwo": "",
    "city": "NamibRand Nature Reserve",
    "county": "",
    "region": "",
    "country": "Namibia",
    "rooms": 9,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 30708,
        "title": "African Safari Destinations: Luxury Bespoke Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/47c6fd20-e1f6-4c1c-b768-d6f56371d959/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/47c6fd20-e1f6-4c1c-b768-d6f56371d959/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/47c6fd20-e1f6-4c1c-b768-d6f56371d959/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 30723,
        "title": "Wolwedans-Dune-Lodge-Exterior - Journeys by Design",
        "url": "https://pkslist.azureedge.net/media/images/1/7e9c3e09-f816-4c41-b5eb-eff08fcb444e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7e9c3e09-f816-4c41-b5eb-eff08fcb444e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7e9c3e09-f816-4c41-b5eb-eff08fcb444e/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 30710,
        "title": "Dunes Lodge - Wolwedans - NamibRand - Namibia",
        "url": "https://pkslist.azureedge.net/media/images/1/30d86bfb-11c8-40b9-bc0b-16581766322d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/30d86bfb-11c8-40b9-bc0b-16581766322d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/30d86bfb-11c8-40b9-bc0b-16581766322d/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 30716,
        "title": "Wolwedans Dunes Lodge in Windhoek | Hotel Rates & Reviews on Orbitz",
        "url": "https://pkslist.azureedge.net/media/images/1/0bea7393-652d-4df5-a0e1-f82d8b3dbc85/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0bea7393-652d-4df5-a0e1-f82d8b3dbc85/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0bea7393-652d-4df5-a0e1-f82d8b3dbc85/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 30714,
        "title": "Wolwedans – A lesson in sustainability in the Namib Desert | La ...",
        "url": "https://pkslist.azureedge.net/media/images/1/c1c96b59-bc28-4d12-81a1-f8a6f5b702a3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c1c96b59-bc28-4d12-81a1-f8a6f5b702a3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c1c96b59-bc28-4d12-81a1-f8a6f5b702a3/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 30718,
        "title": "Wolwedans Dunes Lodge « Luxury Hotels TravelPlusStyle",
        "url": "https://pkslist.azureedge.net/media/images/1/697a7874-26ad-4615-a83d-92952441245f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/697a7874-26ad-4615-a83d-92952441245f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/697a7874-26ad-4615-a83d-92952441245f/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 30719,
        "title": "African Safari Destinations: Luxury Bespoke Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/37a0ebb3-9ccc-4522-8dcf-8216b4cd3ed7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/37a0ebb3-9ccc-4522-8dcf-8216b4cd3ed7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/37a0ebb3-9ccc-4522-8dcf-8216b4cd3ed7/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 30713,
        "title": "Dunes Lodge - Wolwedans - NamibRand - Namibia",
        "url": "https://pkslist.azureedge.net/media/images/1/8b4a8cb1-febb-4b97-bb78-9c67d4c570b4/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8b4a8cb1-febb-4b97-bb78-9c67d4c570b4/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8b4a8cb1-febb-4b97-bb78-9c67d4c570b4/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 30721,
        "title": "Index of /assets/images/1920x1200/southern-africa/namibia ...",
        "url": "https://pkslist.azureedge.net/media/images/1/d6bde3ab-3e94-4a5f-99c6-8683fcbe6237/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d6bde3ab-3e94-4a5f-99c6-8683fcbe6237/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d6bde3ab-3e94-4a5f-99c6-8683fcbe6237/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2180,
      "latitude": -22.56088,
      "longitude": 17.065755999999965,
      "parentGeographicAreaId": 0,
      "title": "NamibRand Nature Reserve, Windhoek, Namibia",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 131,
        "tagName": "Library",
        "tagType": 2
      },
      {
        "tagId": 164,
        "tagName": "Excursions",
        "tagType": 2
      },
      {
        "tagId": 219,
        "tagName": "Wine Cellar",
        "tagType": 2
      },
      {
        "tagId": 243,
        "tagName": "Outdoor Activities",
        "tagType": 2
      },
      {
        "tagId": 263,
        "tagName": "Star Gazing",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 317,
        "tagName": "Gourmet",
        "tagType": 2
      },
      {
        "tagId": 350,
        "tagName": "Campfire",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 443,
        "venueCategoryId": 13,
        "venueId": 2394,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2099,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Jabali Ridge",
    "description": "Set high on a rocky kopje overlooking a landscape dotted with spiky palms and bulbous baobabs, Jabali Ridge is a sophisticated base from which to explore Ruaha National Park. The remarkable beauty of Ruaha is not only in the landscape and its diversity of flora and fauna, or the impressive big game, but in the feeling of having the wilderness all to yourself. With only a handful of visitors at any one time in an area the size of New Jersey, this is an exceptional safari experience.\n\nJabali Ridge’s 8 suites are hidden amongst the rocky boulders that cocoon the property. An infinity pool, spa and plenty of places to laze ensure that time off is as memorable as time out in the bush. Expert guides lead walking safaris, game drives and night drives. Located close to the lodge is a private villa that sleeps six, has its own pool, guide and chef.",
    "brand": null,
    "blockQuote": "All 8 suites have spectacular views, but if you like sunrises, Suite 1 has the best sunrise view.",
    "emailAddress": "enquiries@asiliaafrica.com",
    "website": "asiliaafrica.com/east-africa-safari/tanzania/ruaha/jabali-ridge/",
    "bookingURL": "https://www.asiliaafrica.com/make-an-enquiry/",
    "redirectNotes": null,
    "phoneNumber": "+255 736 500 515",
    "addressOne": "",
    "addressTwo": "",
    "city": "Ruaha National Park",
    "county": "",
    "region": "",
    "country": "Tanzania",
    "rooms": 9,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 26000,
        "title": "Jabali Ridge Hotel Review, Tanzania | Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/76ed97ab-fe25-4211-969c-9c9fca77bbb9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/76ed97ab-fe25-4211-969c-9c9fca77bbb9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/76ed97ab-fe25-4211-969c-9c9fca77bbb9/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 25995,
        "title": "Jabali Ridge Hotel Review, Tanzania | Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/8dcfb997-0561-42d5-9cbf-6eb8826b30f4/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8dcfb997-0561-42d5-9cbf-6eb8826b30f4/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8dcfb997-0561-42d5-9cbf-6eb8826b30f4/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 25998,
        "title": "Jabali Ridge Lodge, Tanzania - Natural World Safaris",
        "url": "https://pkslist.azureedge.net/media/images/1/2a714375-6bc7-4265-8ec7-3e7f2997c40c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2a714375-6bc7-4265-8ec7-3e7f2997c40c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2a714375-6bc7-4265-8ec7-3e7f2997c40c/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 25996,
        "title": "September - Jabali Ridge | Tanzania | Noteworthy Hotel Openings ...",
        "url": "https://pkslist.azureedge.net/media/images/1/01e70b97-2941-4623-851d-f88dfec741ff/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/01e70b97-2941-4623-851d-f88dfec741ff/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/01e70b97-2941-4623-851d-f88dfec741ff/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 25993,
        "title": "Jabali Ridge | Asilia Africa",
        "url": "https://pkslist.azureedge.net/media/images/1/e27e5ecc-df86-4f8f-9273-71e9a49b2bb0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e27e5ecc-df86-4f8f-9273-71e9a49b2bb0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e27e5ecc-df86-4f8f-9273-71e9a49b2bb0/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 25997,
        "title": "Jabali Ridge | Ruaha Camps with Tanzania Odyssey",
        "url": "https://pkslist.azureedge.net/media/images/1/ce3e3ac2-d14d-4f34-817a-0dbce56aa33c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ce3e3ac2-d14d-4f34-817a-0dbce56aa33c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ce3e3ac2-d14d-4f34-817a-0dbce56aa33c/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 26001,
        "title": "Jabali Ridge Ruaha National Park / Tansania - XO Private",
        "url": "https://pkslist.azureedge.net/media/images/1/25e9782e-b940-424d-88f2-3462915c3269/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/25e9782e-b940-424d-88f2-3462915c3269/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/25e9782e-b940-424d-88f2-3462915c3269/256x192.jpg",
        "viewOrder": 6
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1908,
      "latitude": -7.614876099999999,
      "longitude": 34.839582000000064,
      "parentGeographicAreaId": 0,
      "title": "Iringa, Tanzania",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 73,
        "tagName": "Outdoor Terraces",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 135,
        "tagName": "Bird Watching",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 252,
        "tagName": "National Park",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 296,
        "tagName": "Guided Walk",
        "tagType": 2
      },
      {
        "tagId": 317,
        "tagName": "Gourmet",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 419,
        "venueCategoryId": 13,
        "venueId": 2099,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2092,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Arijiju",
    "description": "Arijiju is a beautiful, private home in the game-rich, malaria-free, Kenyan Highlands with rare, truly wild luxuries.\n\nArijiju lies low on the land. The building behaves as though it has always been on that warm Kenyan hillside with living grassed roofs that move with the air and connect it into the earth and forest. It is there but not there. Partly sculpted into the rock-bed, its subtle entrance opens into a fragrant, greened space with generous Swahili curves and an air of monastic calm. Walls of stone built on stone create vaulted walkways that lure you into cool shade and lead you along earth paths towards a simply luxurious gym, spa and hammam, dappled suntraps and Lamu daybeds. Alongside a gnarled African Olive tree, a slice of pool hangs over the untamed wild of the Sieku valley in the Borana Conservancy – a thriving piece of the game-rich Kenyan Highlands and a sanctuary for adventure.",
    "brand": "",
    "blockQuote": "A showstopper safari resort unlike anything you've ever seen before.",
    "emailAddress": "enquiries@arijiju.com",
    "website": "arijiju.com/",
    "bookingURL": "http://arijijumanagement@arijiju.com",
    "redirectNotes": "Arijiju's reservations system",
    "phoneNumber": "+44 020 3664 7123",
    "addressOne": "",
    "addressTwo": "",
    "city": "Borana Conservancy",
    "county": "",
    "region": "Laikipia",
    "country": "Kenya",
    "rooms": 5,
    "dollar": 3,
    "updated": "2021-09-21T13:04:41.133",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 49899,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/55c40e2c-7ac7-4905-9755-3c7cf7827b0c/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/55c40e2c-7ac7-4905-9755-3c7cf7827b0c/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/55c40e2c-7ac7-4905-9755-3c7cf7827b0c/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 49898,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ea6f7283-00db-444c-ba54-c623712eed79/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ea6f7283-00db-444c-ba54-c623712eed79/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ea6f7283-00db-444c-ba54-c623712eed79/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 49902,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f2678137-7f9b-4725-a1b7-dd724ca9b740/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f2678137-7f9b-4725-a1b7-dd724ca9b740/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f2678137-7f9b-4725-a1b7-dd724ca9b740/256x192.jpeg",
        "viewOrder": 2
      },
      {
        "mediaId": 49903,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/7e6be1ee-35b5-43f1-9825-7708ab80db2c/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7e6be1ee-35b5-43f1-9825-7708ab80db2c/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7e6be1ee-35b5-43f1-9825-7708ab80db2c/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 25853,
        "title": "Inside Arijiju, Kenya's unique new exclusive-use villa | Travel ...",
        "url": "https://pkslist.azureedge.net/media/images/1/3c2997d7-43b1-458c-96e6-18711921aa01/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3c2997d7-43b1-458c-96e6-18711921aa01/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3c2997d7-43b1-458c-96e6-18711921aa01/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 25857,
        "title": "Arijiju - BIRD Luxury Travel PR",
        "url": "https://pkslist.azureedge.net/media/images/1/f56bca6b-023b-42ef-a780-a5742e0bcd78/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f56bca6b-023b-42ef-a780-a5742e0bcd78/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f56bca6b-023b-42ef-a780-a5742e0bcd78/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 49904,
        "title": "Arijiju Retreat, Laikipia Plateau • Villa Guru",
        "url": "https://pkslist.azureedge.net/media/images/1/deb57d5b-2a12-4a12-8403-8c1ae01a19c3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/deb57d5b-2a12-4a12-8403-8c1ae01a19c3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/deb57d5b-2a12-4a12-8403-8c1ae01a19c3/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 25865,
        "title": "Arijiju Resort / Life Grand Group |",
        "url": "https://pkslist.azureedge.net/media/images/1/9b041653-efc3-4bb0-af05-de8e4fab7178/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9b041653-efc3-4bb0-af05-de8e4fab7178/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9b041653-efc3-4bb0-af05-de8e4fab7178/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 25864,
        "title": "This Incredible Private Safari Lodge Is Now Available to Rent ...",
        "url": "https://pkslist.azureedge.net/media/images/1/99fe8b45-ae32-43b9-80ce-f5c4d6a594ad/1200x900.jpg?itok=rMiIv0iU",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/99fe8b45-ae32-43b9-80ce-f5c4d6a594ad/800x600.jpg?itok=rMiIv0iU",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/99fe8b45-ae32-43b9-80ce-f5c4d6a594ad/256x192.jpg?itok=rMiIv0iU",
        "viewOrder": 10
      },
      {
        "mediaId": 49901,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/395735be-d24a-4410-99d1-dab9d8145a6b/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/395735be-d24a-4410-99d1-dab9d8145a6b/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/395735be-d24a-4410-99d1-dab9d8145a6b/256x192.jpeg",
        "viewOrder": 11
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1901,
      "latitude": 0.3969873,
      "longitude": 37.15877599999999,
      "parentGeographicAreaId": 0,
      "title": "Laikipia County, Kenya",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 189,
        "tagName": "Exclusively Yours",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 82,
        "tagName": "Horseback Riding",
        "tagType": 2
      },
      {
        "tagId": 88,
        "tagName": "Tennis",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 101,
        "tagName": "Cinema",
        "tagType": 2
      },
      {
        "tagId": 124,
        "tagName": "Helicopter",
        "tagType": 2
      },
      {
        "tagId": 135,
        "tagName": "Bird Watching",
        "tagType": 2
      },
      {
        "tagId": 159,
        "tagName": "Mountain Biking",
        "tagType": 2
      },
      {
        "tagId": 264,
        "tagName": "Quad Bike",
        "tagType": 2
      },
      {
        "tagId": 303,
        "tagName": "Games Room",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 417,
        "venueCategoryId": 13,
        "venueId": 2092,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 2012,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Earth Lodge",
    "description": "Earth Lodge is a sanctuary symbolising a new era in luxury South African safari lodges. Sculpted into a slope of the earth, almost invisible in the landscape, the lodge uses texture, light and space to present a lodge like no other. Taking its cue from the surrounding environment, it has been described as the most environmentally sensitive lodge in Africa.\n\nHome to 13 ultra-luxurious suites including the Amber Presidential Suite, the lodge breaks with traditional bush lodge style to create a masterpiece of artistry and innovation. Each of the suites at this luxury lodge features individually designed furniture, private plunge pool, en-suite glass fronted bathroom and indoor and outdoor shower. The natural wooden sculptures by renowned South African artist Geoffrey Armstrong add a powerful magic.",
    "brand": "",
    "blockQuote": "A walking safari sounds scary, but it's actually not when you're with a highly trained ranger and you'll experience something new and exciting.",
    "emailAddress": "earthreception@sabisabi.com",
    "website": "sabisabi.com/lodges/earthlodge/",
    "bookingURL": "https://apps.hti-systems.com/sabi/bookings/desktop.html",
    "redirectNotes": null,
    "phoneNumber": "+ 27 13 735 5261",
    "addressOne": "",
    "addressTwo": "",
    "city": "Sabi Sand",
    "county": "",
    "region": "Greater Kruger",
    "country": "South Africa",
    "rooms": 13,
    "dollar": 3,
    "updated": "2020-11-13T12:39:14.587",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 42330,
        "title": "Sabi Sabi Earth Lodge Hotel Review, Sabi Sand Game Reserve, Kruger ...",
        "url": "https://pkslist.azureedge.net/media/images/1/03564627-c9cf-451a-9e8c-de9c0ee25515/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/03564627-c9cf-451a-9e8c-de9c0ee25515/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/03564627-c9cf-451a-9e8c-de9c0ee25515/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 42332,
        "title": "Earth Lodge | Luxury Safari Lodge | Sabi Sabi",
        "url": "https://pkslist.azureedge.net/media/images/1/4b2e9ad4-1f9f-40bd-afc0-78d63ecce1f7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4b2e9ad4-1f9f-40bd-afc0-78d63ecce1f7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4b2e9ad4-1f9f-40bd-afc0-78d63ecce1f7/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 42325,
        "title": "Earth Lodge | Luxury Safari Lodge | Sabi Sabi",
        "url": "https://pkslist.azureedge.net/media/images/1/5c7b8c7e-07ef-4ac2-ae80-321eef5f685e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5c7b8c7e-07ef-4ac2-ae80-321eef5f685e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5c7b8c7e-07ef-4ac2-ae80-321eef5f685e/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 42324,
        "title": "SABI SABI EARTH LODGE - Updated 2020 Prices & Reviews (South ...",
        "url": "https://pkslist.azureedge.net/media/images/1/9311e4d0-6177-4fd0-bc97-5120d98c3628/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9311e4d0-6177-4fd0-bc97-5120d98c3628/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9311e4d0-6177-4fd0-bc97-5120d98c3628/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 42329,
        "title": "Sabi Sabi Earth Lodge | Sabi Sabi Safari Lodges | Safari Guide Africa",
        "url": "https://pkslist.azureedge.net/media/images/1/745e4246-e2c8-4076-be13-e4ebe9cfc54b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/745e4246-e2c8-4076-be13-e4ebe9cfc54b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/745e4246-e2c8-4076-be13-e4ebe9cfc54b/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 42328,
        "title": "Earth Lodge Shines | Sabi Sabi Private Game Reserve",
        "url": "https://pkslist.azureedge.net/media/images/1/e2a6e576-d68f-4fd4-acc8-0eae0deef5bf/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e2a6e576-d68f-4fd4-acc8-0eae0deef5bf/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e2a6e576-d68f-4fd4-acc8-0eae0deef5bf/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 24804,
        "title": "Earth Lodge | Luxury Safari Lodge | Sabi Sabi",
        "url": "https://pkslist.azureedge.net/media/images/1/1b2374b3-1abd-400d-a615-e15005d70345/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1b2374b3-1abd-400d-a615-e15005d70345/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1b2374b3-1abd-400d-a615-e15005d70345/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 24805,
        "title": "Earth Lodge | Luxury Safari Lodge | Sabi Sabi",
        "url": "https://pkslist.azureedge.net/media/images/1/c952044a-15ab-471c-a57c-fee8c1ec5cfb/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c952044a-15ab-471c-a57c-fee8c1ec5cfb/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c952044a-15ab-471c-a57c-fee8c1ec5cfb/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 42326,
        "title": "Earth Lodge | Luxury Safari Lodge | Sabi Sabi",
        "url": "https://pkslist.azureedge.net/media/images/1/e923b696-76c7-49c8-88ff-d2e53d2c138b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e923b696-76c7-49c8-88ff-d2e53d2c138b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e923b696-76c7-49c8-88ff-d2e53d2c138b/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 42331,
        "title": "Earth Lodge Photo Gallery | Luxury Safari Lodge Photography | Sabi ...",
        "url": "https://pkslist.azureedge.net/media/images/1/60ed79f6-14b7-45d4-bbb6-162bd64c5e1b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/60ed79f6-14b7-45d4-bbb6-162bd64c5e1b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/60ed79f6-14b7-45d4-bbb6-162bd64c5e1b/256x192.jpg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1822,
      "latitude": -24.9459177,
      "longitude": 31.470127400000024,
      "parentGeographicAreaId": 0,
      "title": "Sabi Sabi Game Reserve, Sabie Park, Sabi Sands Private Game Reserve, 2146, South Africa",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 135,
        "tagName": "Bird Watching",
        "tagType": 2
      },
      {
        "tagId": 181,
        "tagName": "Private/Suite Pools",
        "tagType": 2
      },
      {
        "tagId": 193,
        "tagName": "Outdoor Dining",
        "tagType": 2
      },
      {
        "tagId": 199,
        "tagName": "Art Gallery",
        "tagType": 2
      },
      {
        "tagId": 219,
        "tagName": "Wine Cellar",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 296,
        "tagName": "Guided Walk",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 410,
        "venueCategoryId": 13,
        "venueId": 2012,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1784,
    "venueCategoryId": 13,
    "status": 1,
    "title": "Alladale Wilderness Reserve",
    "description": "Alladale Wilderness Reserve is a 23,000-acre gem in the Scottish Highlands, about 1.5 hours North of Inverness. They work hard to keep this part of Scotland truly wild, by replanting lush forest and reintroducing original Highland plant & animal species. Alladale’s rugged terrain, dramatic glens, colorful hills, glistening rivers, and herds of majestic red deer will take your breath away.\n\nAlladale offers three types of accommodation on site: the luxury Victorian lodge, two comfortable self/catered cottages, and a rustic bunkhouse tucked away deep in the reserve. 7% of their accommodation revenue is transferred to their partner The European Nature Trust. So by staying with them, you will contribute directly to a variety of conservation and restorations efforts throughout Europe.",
    "brand": null,
    "blockQuote": "You'll experience the Highlands in its most rugged, wildest yet breathtakingly beautiful form.",
    "emailAddress": "enquiries@alladale.com",
    "website": "alladale.com/",
    "bookingURL": "http://www.booking.com/Share-jz7lXY?aid=925669",
    "redirectNotes": null,
    "phoneNumber": "+44 1863 755 338",
    "addressOne": "",
    "addressTwo": "",
    "city": "Ardgay",
    "county": "",
    "region": "Scotland",
    "country": "United Kingdom",
    "rooms": 7,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 21073,
        "title": "Home - Alladale Wilderness Reserve Scottish Highlands",
        "url": "https://pkslist.azureedge.net/media/images/1/b048807c-cc85-499f-a4eb-62bc029f7e77/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b048807c-cc85-499f-a4eb-62bc029f7e77/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b048807c-cc85-499f-a4eb-62bc029f7e77/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 21075,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/1757c523-4bcf-4671-bfd0-73bd1e2298d7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1757c523-4bcf-4671-bfd0-73bd1e2298d7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1757c523-4bcf-4671-bfd0-73bd1e2298d7/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 21078,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b8505b7f-628b-49ef-98bd-019b9a3c82ed/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b8505b7f-628b-49ef-98bd-019b9a3c82ed/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b8505b7f-628b-49ef-98bd-019b9a3c82ed/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 21077,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/7e36e195-26a4-4c1f-9a39-0d70d6a7dc56/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7e36e195-26a4-4c1f-9a39-0d70d6a7dc56/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7e36e195-26a4-4c1f-9a39-0d70d6a7dc56/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 21076,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/0c85b167-d92f-4dbe-aba3-80328e24c844/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0c85b167-d92f-4dbe-aba3-80328e24c844/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0c85b167-d92f-4dbe-aba3-80328e24c844/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 21079,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/df6557fa-ab59-472d-8c52-5cd4094c32cb/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/df6557fa-ab59-472d-8c52-5cd4094c32cb/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/df6557fa-ab59-472d-8c52-5cd4094c32cb/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 21083,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/51a1f05c-3cec-4ed1-ac97-5312c42c1088/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/51a1f05c-3cec-4ed1-ac97-5312c42c1088/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/51a1f05c-3cec-4ed1-ac97-5312c42c1088/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 21080,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/7f757b60-9ff3-4b04-9711-3d736754b9a4/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7f757b60-9ff3-4b04-9711-3d736754b9a4/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7f757b60-9ff3-4b04-9711-3d736754b9a4/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 21081,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e1d32672-94b9-416d-9b8c-b47252828774/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e1d32672-94b9-416d-9b8c-b47252828774/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e1d32672-94b9-416d-9b8c-b47252828774/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 21082,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/1dad9da4-e5d4-4c30-a17b-7fd14d416042/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1dad9da4-e5d4-4c30-a17b-7fd14d416042/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1dad9da4-e5d4-4c30-a17b-7fd14d416042/256x192.jpg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1600,
      "latitude": 57.8699669,
      "longitude": -4.633370499999955,
      "parentGeographicAreaId": 0,
      "title": "Alladale Wilderness Reserve, Ardgay IV24 3BS, UK",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 47,
        "tagName": "Intimate Luxury ",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 61,
        "tagName": "Mountain",
        "tagType": 2
      },
      {
        "tagId": 103,
        "tagName": "Clay Pigeon Shooting",
        "tagType": 2
      },
      {
        "tagId": 109,
        "tagName": "Fishing",
        "tagType": 2
      },
      {
        "tagId": 134,
        "tagName": "Wilderness Walks",
        "tagType": 2
      },
      {
        "tagId": 159,
        "tagName": "Mountain Biking",
        "tagType": 2
      },
      {
        "tagId": 243,
        "tagName": "Outdoor Activities",
        "tagType": 2
      },
      {
        "tagId": 268,
        "tagName": "Massage",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 310,
        "tagName": "ATV Touring",
        "tagType": 2
      },
      {
        "tagId": 317,
        "tagName": "Gourmet",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 315,
        "venueCategoryId": 13,
        "venueId": 1784,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1754,
    "venueCategoryId": 13,
    "status": 1,
    "title": "Barahi Jungle Lodge",
    "description": "Barahi Jungle Lodge situated in the pacific Chitwan, reflects the serenity of the National Park. Chitwan’s tranquility and the lodge’s peace very well complement one another. Experiencing refined luxury in the panoramic jungles adorned with the Rapti River when viewed from Barahi’s vantage point is a parallel universe retreat. They take immense pride in strengthening the bond of nature and mankind through their experiences.",
    "brand": null,
    "blockQuote": "Spend special moments feeding and bathing the elephants when you book the Elephant Briefing experience.",
    "emailAddress": "jungle@barahi.com",
    "website": "barahijunglelodge.com/",
    "bookingURL": "http://www.booking.com/Share-eFxdG3?aid=925669",
    "redirectNotes": null,
    "phoneNumber": "+977 56 695447",
    "addressOne": "",
    "addressTwo": "",
    "city": "Megauli",
    "county": "",
    "region": "",
    "country": "Nepal",
    "rooms": 35,
    "dollar": 1,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 20673,
        "title": "Hotels and Resorts in Chitwan National Park | Luxury Hotels",
        "url": "https://pkslist.azureedge.net/media/images/1/123df969-9aec-4ff9-83b9-ed57f04a371b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/123df969-9aec-4ff9-83b9-ed57f04a371b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/123df969-9aec-4ff9-83b9-ed57f04a371b/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 20674,
        "title": "Barahi Jungle Lodge, Meghauli, Nepal - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/478bae48-347b-4e88-a6bc-d254979e430a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/478bae48-347b-4e88-a6bc-d254979e430a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/478bae48-347b-4e88-a6bc-d254979e430a/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 20677,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/248e168b-0b09-4620-ac37-1563715c22f1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/248e168b-0b09-4620-ac37-1563715c22f1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/248e168b-0b09-4620-ac37-1563715c22f1/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 20676,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/39cef6c7-31aa-4ab8-9c7d-936d4abd84dd/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/39cef6c7-31aa-4ab8-9c7d-936d4abd84dd/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/39cef6c7-31aa-4ab8-9c7d-936d4abd84dd/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 20678,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/4d468a1e-6b2c-47a1-9b48-5c296c173cba/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4d468a1e-6b2c-47a1-9b48-5c296c173cba/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4d468a1e-6b2c-47a1-9b48-5c296c173cba/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 20679,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b9f56263-984e-4f24-b5f7-d3dbfac658b6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b9f56263-984e-4f24-b5f7-d3dbfac658b6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b9f56263-984e-4f24-b5f7-d3dbfac658b6/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 20675,
        "title": "Barahi Jungle Lodge, Meghauli, Nepal - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/3db4458c-c6c4-438d-b6bd-b82b42c60f8f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3db4458c-c6c4-438d-b6bd-b82b42c60f8f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3db4458c-c6c4-438d-b6bd-b82b42c60f8f/256x192.jpg",
        "viewOrder": 7
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1570,
      "latitude": 27.5676086,
      "longitude": 84.19510590000004,
      "parentGeographicAreaId": 0,
      "title": "Andrauli, West Chitwan, Megauli 97761, Nepal",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 68,
        "tagName": "Business",
        "tagType": 2
      },
      {
        "tagId": 75,
        "tagName": "Casual Dining",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 131,
        "tagName": "Library",
        "tagType": 2
      },
      {
        "tagId": 252,
        "tagName": "National Park",
        "tagType": 2
      },
      {
        "tagId": 257,
        "tagName": "Canoeing",
        "tagType": 2
      },
      {
        "tagId": 293,
        "tagName": "Meeting Space",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 310,
        "venueCategoryId": 13,
        "venueId": 1754,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1728,
    "venueCategoryId": 13,
    "status": 1,
    "title": "One&Only Nyungwe House",
    "description": "In Southwest Rwanda, just south of Lake Kivu on the Burundi border, One&Only Nyungwe House is set amongst the rich tea-plantations of Gisakura, on the edge of the iconic Nyungwe National Park. The park itself covers approximately 970 km² of rainforest, bamboo, grassland, swamps and bogs, and is one of the last mountain rainforest habitats on the planet.\n\nBoasting just 22 authentic rooms and suites situated in 6 wooden villas, all have open fireplaces and exceptional views of the forest.  Every creature comfort have been thoughtfully considered with private decks to offer all guests private spaces to watch the wildlife. Interiors and artwork is inspired by the natural beauty and traditions of Rwanda.",
    "brand": "One&Only",
    "blockQuote": "The hotel's location on the edge of Nyungwe Forest National  Park is a haven for primates in Africa’s largest mountain rainforest.",
    "emailAddress": "info@nyungwehouse.com",
    "website": "oneandonlyresorts.com/one-and-only-nyungwe-house-rwanda",
    "bookingURL": "https://www.oneandonlyresorts.com/nyungwe-house/reservations/best-available-rate/search-results?w=1",
    "redirectNotes": "One&Only Nyungwe House's reservations system",
    "phoneNumber": "+971 4 426 1099",
    "addressOne": "",
    "addressTwo": "",
    "city": "Nyungwe Forest Reserve",
    "county": "",
    "region": "",
    "country": "Rwanda",
    "rooms": 22,
    "dollar": 3,
    "updated": "2021-07-07T12:54:48.663",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 20187,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/5d1e5b79-8a4a-45f6-b4d1-9ee1a8765981/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5d1e5b79-8a4a-45f6-b4d1-9ee1a8765981/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5d1e5b79-8a4a-45f6-b4d1-9ee1a8765981/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 20196,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/8eb28a91-d70e-4c48-9960-ef32c7c80d62/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8eb28a91-d70e-4c48-9960-ef32c7c80d62/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8eb28a91-d70e-4c48-9960-ef32c7c80d62/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 20198,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/01e87c83-40e4-4d13-b413-c5cf3e130fbd/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/01e87c83-40e4-4d13-b413-c5cf3e130fbd/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/01e87c83-40e4-4d13-b413-c5cf3e130fbd/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 20186,
        "title": "Lodge Nyungwe House, Rwumba, Rwanda - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/50852a49-48b7-4048-bc86-498c9916ada2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/50852a49-48b7-4048-bc86-498c9916ada2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/50852a49-48b7-4048-bc86-498c9916ada2/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 20192,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/0db5135b-4e4e-410f-b4ee-63d968fdd020/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0db5135b-4e4e-410f-b4ee-63d968fdd020/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0db5135b-4e4e-410f-b4ee-63d968fdd020/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 20193,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/6333110c-ecc0-427c-82ce-8a356a21f654/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6333110c-ecc0-427c-82ce-8a356a21f654/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6333110c-ecc0-427c-82ce-8a356a21f654/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 20191,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a151f67c-310a-405d-8542-3eca95ef73ff/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a151f67c-310a-405d-8542-3eca95ef73ff/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a151f67c-310a-405d-8542-3eca95ef73ff/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 20195,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/5724f08c-45d4-4cd5-96b5-4b3302574993/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5724f08c-45d4-4cd5-96b5-4b3302574993/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5724f08c-45d4-4cd5-96b5-4b3302574993/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 20185,
        "title": "Lodge Nyungwe House, Rwumba, Rwanda - Booking.com",
        "url": "https://pkslist.azureedge.net/media/images/1/40bc0492-ab2e-42d9-874c-6aea3765d3ca/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/40bc0492-ab2e-42d9-874c-6aea3765d3ca/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/40bc0492-ab2e-42d9-874c-6aea3765d3ca/256x192.jpg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1544,
      "latitude": -2.446958,
      "longitude": 29.103924000000006,
      "parentGeographicAreaId": 0,
      "title": "Gisakura, Nyamasheke, Nyungwe Forest Reserve, Rwanda",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 61,
        "tagName": "Mountain",
        "tagType": 2
      },
      {
        "tagId": 75,
        "tagName": "Casual Dining",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 156,
        "tagName": "Fireplace",
        "tagType": 2
      },
      {
        "tagId": 170,
        "tagName": "Kayaking",
        "tagType": 2
      },
      {
        "tagId": 171,
        "tagName": "Trekking",
        "tagType": 2
      },
      {
        "tagId": 172,
        "tagName": "Biking",
        "tagType": 2
      },
      {
        "tagId": 251,
        "tagName": "Rainforest",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 305,
        "venueCategoryId": 13,
        "venueId": 1728,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1408,
    "venueCategoryId": 13,
    "status": 1,
    "title": "Meghauli Serai, A Taj Safari",
    "description": "Meghauli Serai, A Taj Safari is set on the banks of the Rapti River overlooking the core zone of this spectacular lush landscape with the lower Himalayan ranges as the backdrop. \n\nThe quaint riverside setting offers a magical view from all of the rooms and villas. The Rapti Villas, each with a plunge pool offer a close to nature experience while the Meghauli Rooms with generous balconies provide a perch point for unhindered views of the forest landscape. The walls are adorned with floor to ceiling sketches depicting the daily life of Tharu villagers. The guest areas include the arrival lounge, library, dining room, bar, the Rapti bank deck and the swimming pool overlooking the river.\n\nChitwan, a UNESCO World Heritage site is renowned as one of the best wildlife–viewing national parks in Asia. Guests have the option of exploring the jungle through walking safari, elephant safari and boat safari. Our Naturalists make it an enriching experience.",
    "brand": "",
    "blockQuote": "One of the best wildlife-viewing safari's in Asia",
    "emailAddress": "tajsafaris@tajhotels.com",
    "website": "tajhotels.com/en-in/taj/meghauli-serai-chitwan-national-park/",
    "bookingURL": "http://www.booking.com/Share-Zp2m9Q?aid=925669",
    "redirectNotes": null,
    "phoneNumber": "+91 22 6601 1825",
    "addressOne": "",
    "addressTwo": "",
    "city": "Meghauli",
    "county": "",
    "region": "",
    "country": "Nepal",
    "rooms": 30,
    "dollar": 1,
    "updated": "2020-07-28T17:02:34.763",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 38537,
        "title": "Meghauli Serai- Taj Safari Chitwan National Park, Chitwan Price ...",
        "url": "https://pkslist.azureedge.net/media/images/1/c13784af-e084-4dda-b735-ff4ebe52f03c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c13784af-e084-4dda-b735-ff4ebe52f03c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c13784af-e084-4dda-b735-ff4ebe52f03c/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 38533,
        "title": "Explore the wild side of Chitwan at The Meghauli Serai, a Taj ...",
        "url": "https://pkslist.azureedge.net/media/images/1/ffbbbf0e-2621-4f25-b1bb-0c9ba83f8d23/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ffbbbf0e-2621-4f25-b1bb-0c9ba83f8d23/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ffbbbf0e-2621-4f25-b1bb-0c9ba83f8d23/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 38538,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/7ff7c056-278c-4709-b4d9-69ceda06470f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7ff7c056-278c-4709-b4d9-69ceda06470f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7ff7c056-278c-4709-b4d9-69ceda06470f/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 15091,
        "title": "Book Meghauli Serai, A Taj Safari Lodge in Meghauli | Hotels.com",
        "url": "https://pkslist.azureedge.net/media/images/1/cd5317f8-29f0-4e3a-aad0-1a2561899341/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cd5317f8-29f0-4e3a-aad0-1a2561899341/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cd5317f8-29f0-4e3a-aad0-1a2561899341/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 38542,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/0a898e81-6da6-457e-8958-61b9c67d98e9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0a898e81-6da6-457e-8958-61b9c67d98e9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0a898e81-6da6-457e-8958-61b9c67d98e9/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 38543,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/af38f303-f635-49fb-874c-0de5d3881e38/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/af38f303-f635-49fb-874c-0de5d3881e38/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/af38f303-f635-49fb-874c-0de5d3881e38/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 38544,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/cf6eafe3-3b16-47a1-8f90-12f183c25898/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cf6eafe3-3b16-47a1-8f90-12f183c25898/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cf6eafe3-3b16-47a1-8f90-12f183c25898/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 38534,
        "title": "Meghauli Serai | Chitwan National Park | andBeyond",
        "url": "https://pkslist.azureedge.net/media/images/1/45b9e537-e846-4fd6-945a-9e349de8ff05/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/45b9e537-e846-4fd6-945a-9e349de8ff05/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/45b9e537-e846-4fd6-945a-9e349de8ff05/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 38541,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/5b2d4f49-e31e-427b-b1cd-84fdb98a93fe/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5b2d4f49-e31e-427b-b1cd-84fdb98a93fe/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5b2d4f49-e31e-427b-b1cd-84fdb98a93fe/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 38539,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c92d2319-c7cf-43ab-bbb9-2959ac67c00e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c92d2319-c7cf-43ab-bbb9-2959ac67c00e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c92d2319-c7cf-43ab-bbb9-2959ac67c00e/256x192.jpg",
        "viewOrder": 10
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1228,
      "latitude": 27.5695526,
      "longitude": 84.20190359999992,
      "parentGeographicAreaId": 0,
      "title": "Ward no 1 Narayani Nager Palika Chitwan National Park Nepal, Meghauli 44207, Nepal",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 49,
        "tagName": "Modern Classic ",
        "tagType": 1
      },
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 134,
        "tagName": "Wilderness Walks",
        "tagType": 2
      },
      {
        "tagId": 164,
        "tagName": "Excursions",
        "tagType": 2
      },
      {
        "tagId": 181,
        "tagName": "Private/Suite Pools",
        "tagType": 2
      },
      {
        "tagId": 183,
        "tagName": "Butler Service",
        "tagType": 2
      },
      {
        "tagId": 224,
        "tagName": "Villas",
        "tagType": 2
      },
      {
        "tagId": 229,
        "tagName": "Private Balconies",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 261,
        "venueCategoryId": 13,
        "venueId": 1408,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1385,
    "venueCategoryId": 20,
    "status": 1,
    "title": "Bisate Lodge",
    "description": "Bisate Lodge is located in the natural amphitheatre of an eroded volcanic cone – the word bisate means ‘pieces’ in Kinyarwanda, describing how the cone was once whole but worn away by natural erosion. The area has dramatic views of the peaks of the volcanoes Bisoke, Karisimbi and Mikeno rearing up through the Afro-alpine forests of nearby Volcanoes National Park. Six sumptuous en-suite rooms maximise comfort, warmth and views while retaining environmental principles and reflecting the culture of surrounding rural Rwanda.\n\nBisate is within easy driving distance of Park Headquarters, from where gorilla treks depart daily. Its vision of reforestation and rehabilitation means that each guest is invited to participate in our biodiversity conservation efforts, as well as engage and meet the local community – in addition to learning about and making a far-reaching positive impact on an iconic Critically Endangered species: the mountain gorilla.",
    "brand": null,
    "blockQuote": "You come here for the gorilla trekking which is amazing, but do try the Golden Monkey Trek to see these handsomely colored primates which are endemic to the area.",
    "emailAddress": "enquiry@wilderness.co.za",
    "website": "wilderness-safaris.com/camps/bisate-lodge",
    "bookingURL": "http://www.wilderness-safaris.com/camps/bisate-lodge/contact-us",
    "redirectNotes": null,
    "phoneNumber": "+27 11 257 5000",
    "addressOne": "",
    "addressTwo": "",
    "city": "Ruhengeri",
    "county": "",
    "region": "",
    "country": "Rwanda",
    "rooms": 6,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 14893,
        "title": "Rwanda's eco-retreat Bisate Lodge is built into a volcanic cone",
        "url": "https://pkslist.azureedge.net/media/images/1/35479580-ea84-4c5e-9f15-2af28d9a64cb/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/35479580-ea84-4c5e-9f15-2af28d9a64cb/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/35479580-ea84-4c5e-9f15-2af28d9a64cb/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 14898,
        "title": "Rwanda's eco-retreat Bisate Lodge is built into a volcanic cone",
        "url": "https://pkslist.azureedge.net/media/images/1/bae36ba4-b13c-40de-8c6f-8eac8ebf5daa/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/bae36ba4-b13c-40de-8c6f-8eac8ebf5daa/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/bae36ba4-b13c-40de-8c6f-8eac8ebf5daa/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 14894,
        "title": "bisate-lodge-guest-area - Love Uganda Safaris - Gorilla Trekking ...",
        "url": "https://pkslist.azureedge.net/media/images/1/c3e09388-5a5e-4feb-a9ea-e70a6df538bb/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c3e09388-5a5e-4feb-a9ea-e70a6df538bb/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c3e09388-5a5e-4feb-a9ea-e70a6df538bb/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 14896,
        "title": "Rwanda's eco-retreat Bisate Lodge is built into a volcanic cone",
        "url": "https://pkslist.azureedge.net/media/images/1/c1d1db5f-22e3-4a4a-8a9e-a0a0cd1e45c8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c1d1db5f-22e3-4a4a-8a9e-a0a0cd1e45c8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c1d1db5f-22e3-4a4a-8a9e-a0a0cd1e45c8/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 14903,
        "title": "Bisate Lodge - Journeys by Design",
        "url": "https://pkslist.azureedge.net/media/images/1/871bfe86-22b6-423a-9b90-d6e7eda27a0e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/871bfe86-22b6-423a-9b90-d6e7eda27a0e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/871bfe86-22b6-423a-9b90-d6e7eda27a0e/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 14897,
        "title": "Bisate Lodge - Photos",
        "url": "https://pkslist.azureedge.net/media/images/1/54df3a76-33c9-4cd8-ba4e-af6bf5a91e50/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/54df3a76-33c9-4cd8-ba4e-af6bf5a91e50/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/54df3a76-33c9-4cd8-ba4e-af6bf5a91e50/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 14901,
        "title": "Discover the Beauty of Rwanda's National Parks at Bisate Lodge",
        "url": "https://pkslist.azureedge.net/media/images/1/c35ac925-fc65-46da-90b8-209d4edb2904/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c35ac925-fc65-46da-90b8-209d4edb2904/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c35ac925-fc65-46da-90b8-209d4edb2904/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 14902,
        "title": "Rwanda's eco-retreat Bisate Lodge is built into a volcanic cone",
        "url": "https://pkslist.azureedge.net/media/images/1/3f6d26e6-7eec-4c86-af18-7fefc00dc71b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3f6d26e6-7eec-4c86-af18-7fefc00dc71b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3f6d26e6-7eec-4c86-af18-7fefc00dc71b/256x192.jpg",
        "viewOrder": 7
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1206,
      "latitude": -1.5064644,
      "longitude": 29.593360599999983,
      "parentGeographicAreaId": 0,
      "title": "Ruhengeri, Rwanda",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 135,
        "tagName": "Bird Watching",
        "tagType": 2
      },
      {
        "tagId": 171,
        "tagName": "Trekking",
        "tagType": 2
      },
      {
        "tagId": 269,
        "tagName": "Photography",
        "tagType": 2
      },
      {
        "tagId": 278,
        "tagName": "Conservation",
        "tagType": 2
      },
      {
        "tagId": 286,
        "tagName": "Volcano",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 296,
        "tagName": "Guided Walk",
        "tagType": 2
      },
      {
        "tagId": 324,
        "tagName": "Local Village",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 256,
        "venueCategoryId": 20,
        "venueId": 1385,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 20,
          "title": "BucketList Hotels",
          "region": "Global",
          "viewOrder": 0,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 340,
        "venueCategoryId": 13,
        "venueId": 1385,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1339,
    "venueCategoryId": 13,
    "status": 1,
    "title": "The Royal Madikwe",
    "description": "The Royal Madikwe is an intimate luxury safari lodge run by a close-knit, experienced team with a dynamic workplace culture based on participation and democratic decision-making. Through an uncompromising dedication to service excellence, The Royal Madikwe offers nothing short of luxurious accommodation, sincere hospitality and spectacular wildlife encounters!\nThe Royal Madikwe offers 5 suite categories, including The Royal Villa. All featuring a large private viewing deck with plush sun loungers conveniently placed beside the plunge pool to soak up the Madikwe sunshine all year round! And with breathtaking views of the surrounding wilderness and mountains.",
    "brand": null,
    "blockQuote": "A super-luxurious property thats great for couples and families",
    "emailAddress": "info@royalmadikwe.com",
    "website": "royalmadikwe.com",
    "bookingURL": "http://www.booking.com/Share-GIBExh?aid=925669",
    "redirectNotes": null,
    "phoneNumber": "+27 825688867",
    "addressOne": "",
    "addressTwo": "",
    "city": "Madikwe",
    "county": "Ngaka Modiri Molema",
    "region": "North West",
    "country": "South Africa",
    "rooms": 5,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 14228,
        "title": "Royal Madikwe Luxury Safari Lodge | Madikwe Game Reserve",
        "url": "https://pkslist.azureedge.net/media/images/1/d996b1e2-5c9f-44ce-9458-6ab77f5d06da/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d996b1e2-5c9f-44ce-9458-6ab77f5d06da/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d996b1e2-5c9f-44ce-9458-6ab77f5d06da/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 14230,
        "title": "Royal Madikwe | Wildlife South Africa",
        "url": "https://pkslist.azureedge.net/media/images/1/0df15c5a-317a-4189-bcae-990a7f97e8f3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0df15c5a-317a-4189-bcae-990a7f97e8f3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0df15c5a-317a-4189-bcae-990a7f97e8f3/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 14231,
        "title": "Royal Madikwe Luxury Safari Lodge - InTomorrow",
        "url": "https://pkslist.azureedge.net/media/images/1/8f69aed2-4a35-405c-8ffe-8227c28ae342/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8f69aed2-4a35-405c-8ffe-8227c28ae342/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8f69aed2-4a35-405c-8ffe-8227c28ae342/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 14225,
        "title": "Royal Madikwe | Wildlife South Africa",
        "url": "https://pkslist.azureedge.net/media/images/1/c6beff00-5f15-42eb-9fde-f02ca74f57a5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c6beff00-5f15-42eb-9fde-f02ca74f57a5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c6beff00-5f15-42eb-9fde-f02ca74f57a5/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 14227,
        "title": "Royal Madikwe Luxury Safari Lodge | Madikwe Game Reserve",
        "url": "https://pkslist.azureedge.net/media/images/1/89ecd449-67ac-4e35-9eb7-c6112761ef0a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/89ecd449-67ac-4e35-9eb7-c6112761ef0a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/89ecd449-67ac-4e35-9eb7-c6112761ef0a/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 14226,
        "title": "Royal Madikwe Luxury Safari Lodge | Madikwe Game Reserve",
        "url": "https://pkslist.azureedge.net/media/images/1/0ea4c1ee-ff42-4355-a376-dae756a179fa/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0ea4c1ee-ff42-4355-a376-dae756a179fa/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0ea4c1ee-ff42-4355-a376-dae756a179fa/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 14223,
        "title": "Royal Madikwe Luxury Safari Lodge, Madikwe Game Reserve, South ...",
        "url": "https://pkslist.azureedge.net/media/images/1/12980417-49f6-47af-985f-4b6fccf2f27d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/12980417-49f6-47af-985f-4b6fccf2f27d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/12980417-49f6-47af-985f-4b6fccf2f27d/256x192.jpg",
        "viewOrder": 6
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1161,
      "latitude": -24.7775898,
      "longitude": 26.18540770000004,
      "parentGeographicAreaId": 0,
      "title": "R49, Madikwe Game Reserve, Madikwe, 2874, South Africa",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 40,
        "tagName": "Boutique Bolthole ",
        "tagType": 1
      },
      {
        "tagId": 50,
        "tagName": "Barefoot Luxury",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 69,
        "tagName": "Couples",
        "tagType": 2
      },
      {
        "tagId": 70,
        "tagName": "Families",
        "tagType": 2
      },
      {
        "tagId": 73,
        "tagName": "Outdoor Terraces",
        "tagType": 2
      },
      {
        "tagId": 75,
        "tagName": "Casual Dining",
        "tagType": 2
      },
      {
        "tagId": 83,
        "tagName": "Kid's Club",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 102,
        "tagName": "Eco-friendly",
        "tagType": 2
      },
      {
        "tagId": 156,
        "tagName": "Fireplace",
        "tagType": 2
      },
      {
        "tagId": 181,
        "tagName": "Private/Suite Pools",
        "tagType": 2
      },
      {
        "tagId": 202,
        "tagName": "Private Terraces",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 247,
        "venueCategoryId": 13,
        "venueId": 1339,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1338,
    "venueCategoryId": 13,
    "status": 1,
    "title": "Morukuru Family Madikwe",
    "description": "Morukuru Family Madikwe is situated in a private concession within the malaria free Madikwe Game Reserve. This allows us the opportunity to offer our guests the freedom to explore the reserve with no restrictions.\n\nOur \"Morukuru Freedom Concept\" at Morukuru Family Madikwe means that we do not maintain fixed schedules and our guests are able to spend their days under the African skies as they wish. Guests are able to go on game drives as often as they would like to, go fishing, do bush walks, pizza picnics, bush breakfasts or champagne parties under the stars....Our activities cater for the young and old and are enjoyed within our private concession as well as within the rest of Madikwe Game Reserve.\n\nGuests are accommodated in one of our three amazing exclusive-use safari houses; 2-bedroom Morukuru Owner's House, 3-bedroom Morukuru River House and 5-bedroom Morukuru Farm House, each of them being offered on an exclusive-use basis only. Each house includes a private game drive vehicle, own personal staff, private guide & tracker, babysitting services, personal chef, private plunge pool and most of all complete freedom. No sharing with other guests.",
    "brand": "",
    "blockQuote": "All 3 houses are exclusive-use safari homes ideal for multi-generational travel.",
    "emailAddress": "reservations@morukuru.com",
    "website": "morukuru.com",
    "bookingURL": "http://www.nightsbridge.co.za/bridge/book?bbid=14273",
    "redirectNotes": "Morukuru Family Madikwe's reservations system",
    "phoneNumber": "+27 11 615 4303 ",
    "addressOne": "",
    "addressTwo": "",
    "city": "Madikwe Game Reserve",
    "county": "",
    "region": "",
    "country": "South Africa",
    "rooms": 3,
    "dollar": 3,
    "updated": "2021-07-13T13:14:52.3",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 48627,
        "title": "Morukuru Owner's House - Morukuru Family",
        "url": "https://pkslist.azureedge.net/media/images/1/0c8903d5-4595-48ff-b86f-a51227977693/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0c8903d5-4595-48ff-b86f-a51227977693/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0c8903d5-4595-48ff-b86f-a51227977693/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 48637,
        "title": "Morukuru Farm House - Morukuru Family",
        "url": "https://pkslist.azureedge.net/media/images/1/107b6f0a-2d11-4221-b119-e9bf451aee43/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/107b6f0a-2d11-4221-b119-e9bf451aee43/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/107b6f0a-2d11-4221-b119-e9bf451aee43/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 48633,
        "title": "A new look for Morukuru Owner's House & River House - Morukuru Family",
        "url": "https://pkslist.azureedge.net/media/images/1/08576012-1c49-4a9c-840d-f82304ed5bb5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/08576012-1c49-4a9c-840d-f82304ed5bb5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/08576012-1c49-4a9c-840d-f82304ed5bb5/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 48632,
        "title": "Morukuru Owner's House - Madikwe Game Reserve | Rhino Africa",
        "url": "https://pkslist.azureedge.net/media/images/1/07ad29db-35df-4a1e-aeb4-f06f254087d6/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/07ad29db-35df-4a1e-aeb4-f06f254087d6/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/07ad29db-35df-4a1e-aeb4-f06f254087d6/256x192.jpeg",
        "viewOrder": 3
      },
      {
        "mediaId": 48631,
        "title": "A new look for Morukuru Owner's House & River House - Morukuru Family",
        "url": "https://pkslist.azureedge.net/media/images/1/a0cbfd43-9868-424c-ae3f-0157870b18e6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a0cbfd43-9868-424c-ae3f-0157870b18e6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a0cbfd43-9868-424c-ae3f-0157870b18e6/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 38719,
        "title": "Morukuru Family - RECONNECTING. Family. Friends. Nature. You.",
        "url": "https://pkslist.azureedge.net/media/images/1/a0f5c03c-12c3-4254-9097-db772b4fae12/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a0f5c03c-12c3-4254-9097-db772b4fae12/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a0f5c03c-12c3-4254-9097-db772b4fae12/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 48630,
        "title": "A new look for Morukuru Owner's House & River House - Morukuru Family",
        "url": "https://pkslist.azureedge.net/media/images/1/db800af0-0cd9-4888-a7f0-98259e32b42a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/db800af0-0cd9-4888-a7f0-98259e32b42a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/db800af0-0cd9-4888-a7f0-98259e32b42a/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 38729,
        "title": "Morukuru Family Madikwe - bedroom at Farm House - Timeless Africa ...",
        "url": "https://pkslist.azureedge.net/media/images/1/7c7fc9c6-25a8-447d-a3c6-7898b889624d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7c7fc9c6-25a8-447d-a3c6-7898b889624d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7c7fc9c6-25a8-447d-a3c6-7898b889624d/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 38722,
        "title": "Morukuru Family Madikwe | XO Private",
        "url": "https://pkslist.azureedge.net/media/images/1/03863f3b-fbb2-4c8c-b53d-89d0ba81dccd/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/03863f3b-fbb2-4c8c-b53d-89d0ba81dccd/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/03863f3b-fbb2-4c8c-b53d-89d0ba81dccd/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 38724,
        "title": "Morukuru Lodge",
        "url": "https://pkslist.azureedge.net/media/images/1/5d36d825-d939-4aa6-a82b-5c287b675d31/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5d36d825-d939-4aa6-a82b-5c287b675d31/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5d36d825-d939-4aa6-a82b-5c287b675d31/256x192.jpg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1160,
      "latitude": -24.782581,
      "longitude": 26.435453000000052,
      "parentGeographicAreaId": 0,
      "title": "Middlepoort Farm KP93, Portion 1 - 5, Madikwe Game Reserve, 2874, South Africa",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 189,
        "tagName": "Exclusively Yours",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 109,
        "tagName": "Fishing",
        "tagType": 2
      },
      {
        "tagId": 135,
        "tagName": "Bird Watching",
        "tagType": 2
      },
      {
        "tagId": 183,
        "tagName": "Butler Service",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 259,
        "tagName": "Picnic",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 296,
        "tagName": "Guided Walk",
        "tagType": 2
      },
      {
        "tagId": 410,
        "tagName": "Sundownders",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 246,
        "venueCategoryId": 13,
        "venueId": 1338,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1337,
    "venueCategoryId": 13,
    "status": 1,
    "title": "Sanctuary Gorilla Forest Camp",
    "description": "Once-in-a-lifetime encounters with Uganda’s endangered gorillas await when you stay in one of these only ten private luxury tents in the heart of the Bwindi Impenetrable Forest.\n\nEcologically respectful, contemporary-yet-classic in style, Sanctuary Gorilla Forest Camp blends in sensitively with the dramatic African mountain landscape and, situated uniquely inside the national park, you are only minutes away from the start of your gorilla trekking adventure.",
    "brand": "",
    "blockQuote": "This is a once in a lifetime trip to track the endangered mountain gorillas.",
    "emailAddress": "reservations.kenya@sanctuaryretreats.com",
    "website": "anctuaryretreats.com/uganda-camps-gorilla-forest",
    "bookingURL": "http://www.sanctuaryretreats.com/contact-us-make-an-enquiry",
    "redirectNotes": "Sanctuary Gorilla Forest Camp's reservations system",
    "phoneNumber": "+256 772 753619",
    "addressOne": "",
    "addressTwo": "",
    "city": "Bwindi National Park",
    "county": "",
    "region": "",
    "country": "Uganda",
    "rooms": 10,
    "dollar": 3,
    "updated": "2022-03-25T12:46:17.377",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 53247,
        "title": "Sanctuary Gorilla Forest Camp | Bwindi | andBeyond",
        "url": "https://pkslist.azureedge.net/media/images/1/7b7b9417-8ceb-433a-96b7-a44d9b3be1f4/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7b7b9417-8ceb-433a-96b7-a44d9b3be1f4/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7b7b9417-8ceb-433a-96b7-a44d9b3be1f4/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 53252,
        "title": "Sanctuary Gorilla Forest Camp (Bwindi Park, Uganda) - AfricanMecca ...",
        "url": "https://pkslist.azureedge.net/media/images/1/ab6ad13d-ba3f-4862-bd5b-053a4e34cdf5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ab6ad13d-ba3f-4862-bd5b-053a4e34cdf5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ab6ad13d-ba3f-4862-bd5b-053a4e34cdf5/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 53253,
        "title": "Sanctuary Gorilla Forest Camp | Uganda Tourism Center",
        "url": "https://pkslist.azureedge.net/media/images/1/cdd19ba8-87eb-4799-8e8d-d96936c3a6ea/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cdd19ba8-87eb-4799-8e8d-d96936c3a6ea/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cdd19ba8-87eb-4799-8e8d-d96936c3a6ea/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 53261,
        "title": "Sanctuary Gorilla Forest Camp | African Safaris Network - Africa's ...",
        "url": "https://pkslist.azureedge.net/media/images/1/208e7dde-aede-4fe8-909b-bb219aa413b6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/208e7dde-aede-4fe8-909b-bb219aa413b6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/208e7dde-aede-4fe8-909b-bb219aa413b6/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 53248,
        "title": "Sanctuary Gorilla Forest Camp | Bwindi | andBeyond",
        "url": "https://pkslist.azureedge.net/media/images/1/f82d1fed-0a16-4c42-a4d5-ad910689f5f3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f82d1fed-0a16-4c42-a4d5-ad910689f5f3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f82d1fed-0a16-4c42-a4d5-ad910689f5f3/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 53260,
        "title": "Sanctuary Gorilla Forest Camp, Bwindi Impenetrable National Park ...",
        "url": "https://pkslist.azureedge.net/media/images/1/51b9f8f7-21b3-4e12-89a0-1d65b5078c7d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/51b9f8f7-21b3-4e12-89a0-1d65b5078c7d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/51b9f8f7-21b3-4e12-89a0-1d65b5078c7d/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 53251,
        "title": "Sanctuary Gorilla Forest Camp - Safari Lodges in Bwindi, Uganda",
        "url": "https://pkslist.azureedge.net/media/images/1/b86012f8-bf30-46c8-be5d-ae6e313c7745/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b86012f8-bf30-46c8-be5d-ae6e313c7745/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b86012f8-bf30-46c8-be5d-ae6e313c7745/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 53258,
        "title": "Gorilla Forest Camp, Bwindi Impenetrable NP, Uganda",
        "url": "https://pkslist.azureedge.net/media/images/1/189566d7-b515-408f-a866-436d8d78e94d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/189566d7-b515-408f-a866-436d8d78e94d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/189566d7-b515-408f-a866-436d8d78e94d/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 53263,
        "title": "Sanctuary Gorilla Forest Camp, Bwindi, Uganda | Discover Africa ...",
        "url": "https://pkslist.azureedge.net/media/images/1/9c97f24e-ed02-4ffa-addc-d0667b26124c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9c97f24e-ed02-4ffa-addc-d0667b26124c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9c97f24e-ed02-4ffa-addc-d0667b26124c/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1159,
      "latitude": -0.9893172,
      "longitude": 29.6138832,
      "parentGeographicAreaId": 0,
      "title": "0°59’20.98”S 29°36’48.54”E, Bwindi National Park, Uganda",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 102,
        "tagName": "Eco-friendly",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 135,
        "tagName": "Bird Watching",
        "tagType": 2
      },
      {
        "tagId": 171,
        "tagName": "Trekking",
        "tagType": 2
      },
      {
        "tagId": 252,
        "tagName": "National Park",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 324,
        "tagName": "Local Village",
        "tagType": 2
      },
      {
        "tagId": 350,
        "tagName": "Campfire",
        "tagType": 2
      },
      {
        "tagId": 410,
        "tagName": "Sundownders",
        "tagType": 2
      },
      {
        "tagId": 440,
        "tagName": "Unesco",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 245,
        "venueCategoryId": 13,
        "venueId": 1337,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1336,
    "venueCategoryId": 13,
    "status": 1,
    "title": "Madikwe Hills Private Game Lodge",
    "description": "Set within 75 000 hectares of safari park, Madikwe Hills Private Game Lodge is situated on a rocky outcrop in the heart of the malaria-free Madikwe Game Reserve in the North West Province of South Africa. Ingeniously set amongst boulders and age-old Tamboti trees, the private game lodge is situated in the middle of Madikwe, offering visitors the most amazing South African safari adventure. Accommodating up to 24 guests in vast, well-spaced and utterly private glass-fronted suites scattered across the hillside, camouflaged by tamboti-beamed thatch roofs. The central area is an important part of everyday life at the Lodge. From the threshold of majestic, age-old East African doors, you immediately feel the cool breeze emanating throughout our central area. Each table in our lounge is stocked with beautiful books, waiting to be read and explored.\nThe total dedication and passion of the team at the Madikwe Hills Private Game Lodge ensure that every guest departs with a memory of an African safari never to be forgotten. From outstanding wildlife holidays to unforgettable safari weddings and honeymoons – Madikwe offers the complete adventure in South Africa.",
    "brand": null,
    "blockQuote": "Along with a warm and friendly atmosphere, Madikwe Hills offers the highest standard of service and amazingly designed suites",
    "emailAddress": "reservations@madikwehills.com",
    "website": "madikwehills.com",
    "bookingURL": "http://www.booking.com/Share-WFxG3C?aid=925669",
    "redirectNotes": null,
    "phoneNumber": "+27 11 781 5431",
    "addressOne": "Madikwe Game Reserve",
    "addressTwo": "",
    "city": "Ngaka Modiri Molema",
    "county": "",
    "region": "North West",
    "country": "South Africa",
    "rooms": 10,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 14196,
        "title": "luxury-bathing-experience-in- ...",
        "url": "https://pkslist.azureedge.net/media/images/1/508f33c4-a697-4fe0-a719-56ba94dd5712/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/508f33c4-a697-4fe0-a719-56ba94dd5712/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/508f33c4-a697-4fe0-a719-56ba94dd5712/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 14201,
        "title": "Madikwe Hills Private Game Lodge, Madikwe Game Reserve, South ...",
        "url": "https://pkslist.azureedge.net/media/images/1/c09a3db5-c108-45c2-af7a-55763d659715/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c09a3db5-c108-45c2-af7a-55763d659715/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c09a3db5-c108-45c2-af7a-55763d659715/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 14202,
        "title": "Luxury safari south africa - South Africa safari tours",
        "url": "https://pkslist.azureedge.net/media/images/1/db49826d-bb54-4422-a6ca-f2597948c4ca/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/db49826d-bb54-4422-a6ca-f2597948c4ca/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/db49826d-bb54-4422-a6ca-f2597948c4ca/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 14204,
        "title": "Africa Direct USA | Madikwe Hills Private Game Lodge",
        "url": "https://pkslist.azureedge.net/media/images/1/6df634cc-16f9-48cc-8884-f7cac5028cdf/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6df634cc-16f9-48cc-8884-f7cac5028cdf/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6df634cc-16f9-48cc-8884-f7cac5028cdf/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 14199,
        "title": "Madikwe Hills Private Game Lodge",
        "url": "https://pkslist.azureedge.net/media/images/1/ce8e19eb-a545-406a-8e80-46bc559d804f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ce8e19eb-a545-406a-8e80-46bc559d804f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ce8e19eb-a545-406a-8e80-46bc559d804f/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 14198,
        "title": "romantic-dinner-in-you-madikwe ...",
        "url": "https://pkslist.azureedge.net/media/images/1/c5ce722d-9793-4b70-b8b6-13191800136c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c5ce722d-9793-4b70-b8b6-13191800136c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c5ce722d-9793-4b70-b8b6-13191800136c/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 14203,
        "title": "Madikwe Hills Private Game Lodge | Madikwe Game Reserve | Discount ...",
        "url": "https://pkslist.azureedge.net/media/images/1/c8372695-7877-42f0-af7c-cede25b784f6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c8372695-7877-42f0-af7c-cede25b784f6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c8372695-7877-42f0-af7c-cede25b784f6/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 14200,
        "title": "Madikwe Hills Private Game Lodge, Madikwe Game Reserve, South ...",
        "url": "https://pkslist.azureedge.net/media/images/1/aa5beb31-b18d-4970-b871-9ddafebaf272/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/aa5beb31-b18d-4970-b871-9ddafebaf272/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/aa5beb31-b18d-4970-b871-9ddafebaf272/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1158,
      "latitude": -24.724721,
      "longitude": 26.347051999999962,
      "parentGeographicAreaId": 0,
      "title": "Madikwe Game Reserve, 2865, South Africa",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 40,
        "tagName": "Boutique Bolthole ",
        "tagType": 1
      },
      {
        "tagId": 50,
        "tagName": "Barefoot Luxury",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 69,
        "tagName": "Couples",
        "tagType": 2
      },
      {
        "tagId": 70,
        "tagName": "Families",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 100,
        "tagName": "Wedding",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 131,
        "tagName": "Library",
        "tagType": 2
      },
      {
        "tagId": 134,
        "tagName": "Wilderness Walks",
        "tagType": 2
      },
      {
        "tagId": 156,
        "tagName": "Fireplace",
        "tagType": 2
      },
      {
        "tagId": 181,
        "tagName": "Private/Suite Pools",
        "tagType": 2
      },
      {
        "tagId": 202,
        "tagName": "Private Terraces",
        "tagType": 2
      },
      {
        "tagId": 219,
        "tagName": "Wine Cellar",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 244,
        "venueCategoryId": 13,
        "venueId": 1336,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1207,
    "venueCategoryId": 0,
    "status": 1,
    "title": "andBeyond Sossusvlei Desert Lodge",
    "description": "The lodge is situated within &BEYOND Sossusvlei Private Desert Reserve, a vast 12 715 hectare (31 419 acre) expanse deep in the Namib, the world’s oldest living desert.\nSet amidst an ocean of dunes, rocky outcrops and gravel plains, the utter tranquility and extraordinary clarity of light makes this area one of Africa’s most compelling landscapes. The night skies are heavenly, with the concession bordering the International Dark Sky Reserve of the NamibRand Nature Reserve. A canvas of beauty that invites reconnection with yourself and nature.\n\nSustainability and uncompromised luxury go hand in hand at Sossusvlei. 10 beautifully appointed stone and glass suites, spread out along the curve of the escarpment, open onto ever-changing outlooks. Each spacious air conditioned suite offers a secluded, shaded veranda complete with private plunge pool, a living room with a fireplace, a large retractable skylight above the bed, and an ensuite bathroom with a glass-encased rain shower offering 180º desert views. The two-bedroomed Star Dune Suite is an ideal option for families or groups of friends.",
    "brand": "andBeyond",
    "blockQuote": "The hot air balloon adventure will allow for excellent photographic opportunities and lifelong memories.",
    "emailAddress": "safaris@andBeyond.com",
    "website": "https://www.andbeyond.com/our-lodges/africa/namibia/sossusvlei-desert/andbeyond-sossusvlei-desert-lodge/",
    "bookingURL": "https://www.andbeyond.com/our-lodges/africa/namibia/sossusvlei-desert/andbeyond-sossusvlei-desert-lodge/?utm_source=Organic&utm_medium=GMBSossusvlei&utm_campaign=GoogleMyBusiness",
    "redirectNotes": null,
    "phoneNumber": "+27 11 809 4300",
    "addressOne": "",
    "addressTwo": "",
    "city": "Sossusvlei",
    "county": "",
    "region": "",
    "country": "Namibia",
    "rooms": 10,
    "dollar": 3,
    "updated": "2020-05-18T15:27:01.993",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 36568,
        "title": "&Beyond Sossusvlei Desert Lodge | Namibia Safaris | Red Savannah",
        "url": "https://pkslist.azureedge.net/media/images/1/88984c17-f4f1-48b8-a8f0-3e4471cc6163/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/88984c17-f4f1-48b8-a8f0-3e4471cc6163/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/88984c17-f4f1-48b8-a8f0-3e4471cc6163/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 36560,
        "title": "andBeyond Sossusvlei Desert Lodge | Namib Desert | Namibia",
        "url": "https://pkslist.azureedge.net/media/images/1/856b502a-b8c9-4db2-94a8-62ecda7212cb/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/856b502a-b8c9-4db2-94a8-62ecda7212cb/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/856b502a-b8c9-4db2-94a8-62ecda7212cb/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 36559,
        "title": "AndBeyond Sossusvlei Desert Lodge Review: First In - Condé Nast ...",
        "url": "https://pkslist.azureedge.net/media/images/1/fa631ab9-a7cc-4ab8-97fb-a900162b7a5e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fa631ab9-a7cc-4ab8-97fb-a900162b7a5e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fa631ab9-a7cc-4ab8-97fb-a900162b7a5e/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 36558,
        "title": "andBeyond Sossusvlei Desert Lodge | Namib Desert | Namibia",
        "url": "https://pkslist.azureedge.net/media/images/1/91faeff3-30d3-4c93-bde7-51a081b5ff56/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/91faeff3-30d3-4c93-bde7-51a081b5ff56/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/91faeff3-30d3-4c93-bde7-51a081b5ff56/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 36562,
        "title": "andBeyond Sossusvlei Desert Lodge | Namib Desert | Namibia",
        "url": "https://pkslist.azureedge.net/media/images/1/689f6961-6f94-4ea3-a781-225a5aa8d230/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/689f6961-6f94-4ea3-a781-225a5aa8d230/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/689f6961-6f94-4ea3-a781-225a5aa8d230/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 36567,
        "title": "&Beyond Sossusvlei Desert Lodge Hotel Review, Namib-naukluft park ...",
        "url": "https://pkslist.azureedge.net/media/images/1/4a222773-f0ea-4c25-bc66-626461bcb69c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4a222773-f0ea-4c25-bc66-626461bcb69c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4a222773-f0ea-4c25-bc66-626461bcb69c/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 36565,
        "title": "andBeyond-Sossusvlei-Desert-Lodge-Suite-View-Render | Luxury ...",
        "url": "https://pkslist.azureedge.net/media/images/1/a46d413d-05c4-48ac-bf45-56133206799d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a46d413d-05c4-48ac-bf45-56133206799d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a46d413d-05c4-48ac-bf45-56133206799d/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 36572,
        "title": "A favourite destination is reborn in Namibia | How To Spend It",
        "url": "https://pkslist.azureedge.net/media/images/1/a76c58ef-47aa-469c-a353-d67b504a6f1a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a76c58ef-47aa-469c-a353-d67b504a6f1a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a76c58ef-47aa-469c-a353-d67b504a6f1a/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 36574,
        "title": "andBeyond Sossusvlei Desert Lodge in NamibRand Reserve, Namibia",
        "url": "https://pkslist.azureedge.net/media/images/1/34cad358-b7ce-4a36-8ad4-f3fcd42babb1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/34cad358-b7ce-4a36-8ad4-f3fcd42babb1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/34cad358-b7ce-4a36-8ad4-f3fcd42babb1/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 36575,
        "title": "andBeyond Sossusvlei Desert Lodge in NamibRand Reserve, Namibia",
        "url": "https://pkslist.azureedge.net/media/images/1/5d607e8a-16a6-4176-b55b-652fa801dfa7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5d607e8a-16a6-4176-b55b-652fa801dfa7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5d607e8a-16a6-4176-b55b-652fa801dfa7/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 36571,
        "title": "andBeyond Sossusvlei Desert Lodge | Namib Desert | Namibia",
        "url": "https://pkslist.azureedge.net/media/images/1/8dca9dd7-65a2-44fe-8c66-e4fc14a0425a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8dca9dd7-65a2-44fe-8c66-e4fc14a0425a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8dca9dd7-65a2-44fe-8c66-e4fc14a0425a/256x192.jpg",
        "viewOrder": 11
      }
    ],
    "geographicArea": {
      "geographicAreaId": 1032,
      "latitude": -24.779536,
      "longitude": 15.888596000000007,
      "parentGeographicAreaId": 0,
      "title": "Sossusvlei, Namib-Naukluft Park, Namibia, Namibia",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 59,
        "tagName": "Cultural",
        "tagType": 2
      },
      {
        "tagId": 61,
        "tagName": "Mountain",
        "tagType": 2
      },
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 134,
        "tagName": "Wilderness Walks",
        "tagType": 2
      },
      {
        "tagId": 164,
        "tagName": "Excursions",
        "tagType": 2
      },
      {
        "tagId": 193,
        "tagName": "Outdoor Dining",
        "tagType": 2
      },
      {
        "tagId": 219,
        "tagName": "Wine Cellar",
        "tagType": 2
      },
      {
        "tagId": 263,
        "tagName": "Star Gazing",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 564,
        "venueCategoryId": 13,
        "venueId": 1207,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 565,
        "venueCategoryId": 20,
        "venueId": 1207,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 20,
          "title": "BucketList Hotels",
          "region": "Global",
          "viewOrder": 0,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1063,
    "venueCategoryId": 13,
    "status": 1,
    "title": "Bosque del Cabo Rainforest Lodge",
    "description": "Crowning a point 500 feet above where the Golfo Dulce meets the Pacific Ocean, nestled in the unspoiled jungles of Costa Rica’s Osa Peninsula is a paradise found.  Bosque del Cabo is a premier Costa Rica Eco Lodge offering a romantic rainforest getaway and a naturalist’s dream come true. Located in a unique and fortunate position at the very tip of the Osa Peninsula in the southern Pacific region of Costa Rica, this 750+ acre forest reserve features private, spacious bungalows and gorgeous houses perched atop the cliffs of Cabo Matapalo, each with beautiful ocean views, tropical breezes and the rainforest at your doorstep; or choose beautiful and rustic garden view accommodations surrounded by thriving rainforests.  Hiking trails lead you through the forest to deserted beaches on both the Golfo Dulce and Pacific Ocean.  Bosque del Cabo is dedicated to forest reservation.",
    "brand": null,
    "blockQuote": "You will be blown away by the abundance of wildlife in this natural rugged splendor.",
    "emailAddress": "reservations@bosquedelcabo.com",
    "website": "bosquedelcabo.com/",
    "bookingURL": "http://bosquedelcabo.com/reservations/contact/reservationform.php",
    "redirectNotes": null,
    "phoneNumber": "+506 2735 5206",
    "addressOne": "",
    "addressTwo": "",
    "city": "Puerto Jimenez",
    "county": "",
    "region": "Osa Peninsula",
    "country": "Costa Rica",
    "rooms": 18,
    "dollar": 2,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 10677,
        "title": "View from our deck -- about a 500' drop to the Pacific! - Picture ...",
        "url": "https://pkslist.azureedge.net/media/images/1/a8b8f55a-fe4a-46ed-86e0-1ecf1c630c6d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a8b8f55a-fe4a-46ed-86e0-1ecf1c630c6d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a8b8f55a-fe4a-46ed-86e0-1ecf1c630c6d/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 22653,
        "title": "Bosque del Cabo Rain Forest Lodge: 2018 Room Prices from $450 ...",
        "url": "https://pkslist.azureedge.net/media/images/1/b62aa123-dac3-4554-a7bb-81676ce6d8c4/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b62aa123-dac3-4554-a7bb-81676ce6d8c4/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b62aa123-dac3-4554-a7bb-81676ce6d8c4/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 22654,
        "title": "Bosque del Cabo Lodge in Southern Pacific, Costa Rica",
        "url": "https://pkslist.azureedge.net/media/images/1/9deafc02-8450-4d4b-a2f3-4929a493e439/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9deafc02-8450-4d4b-a2f3-4929a493e439/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9deafc02-8450-4d4b-a2f3-4929a493e439/256x192.png",
        "viewOrder": 0
      },
      {
        "mediaId": 22655,
        "title": "Bosque del Cabo Lodge in Southern Pacific, Costa Rica",
        "url": "https://pkslist.azureedge.net/media/images/1/635df14d-a0bf-4b82-a567-7048b2b4a917/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/635df14d-a0bf-4b82-a567-7048b2b4a917/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/635df14d-a0bf-4b82-a567-7048b2b4a917/256x192.png",
        "viewOrder": 0
      },
      {
        "mediaId": 10678,
        "title": "Boutique ecolodge osa penisula costa rica the bosque del cabo ...",
        "url": "https://pkslist.azureedge.net/media/images/1/4a6b6f75-6352-46f5-971d-d6610c9826ca/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4a6b6f75-6352-46f5-971d-d6610c9826ca/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4a6b6f75-6352-46f5-971d-d6610c9826ca/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 10676,
        "title": "Costa Rica Nature Lodge in Osa Peninsula is a Premier Costa Rica ...",
        "url": "https://pkslist.azureedge.net/media/images/1/83ca40f1-90e8-4532-be5f-a9c64de3ef37/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/83ca40f1-90e8-4532-be5f-a9c64de3ef37/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/83ca40f1-90e8-4532-be5f-a9c64de3ef37/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 10679,
        "title": "Bosque del Cabo Lodge - Cabo Matapalo Costa Rica Hotel",
        "url": "https://pkslist.azureedge.net/media/images/1/ee60dda0-cd30-4744-9c4a-ce927bf4ea57/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ee60dda0-cd30-4744-9c4a-ce927bf4ea57/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ee60dda0-cd30-4744-9c4a-ce927bf4ea57/256x192.jpg",
        "viewOrder": 4
      }
    ],
    "geographicArea": {
      "geographicAreaId": 892,
      "latitude": 8.378757,
      "longitude": -83.29359399999998,
      "parentGeographicAreaId": 0,
      "title": "Cabo Matapalo, Puntarenas Province, Puerto Jimenez, Costa Rica",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 50,
        "tagName": "Barefoot Luxury",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 82,
        "tagName": "Horseback Riding",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 102,
        "tagName": "Eco-friendly",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 109,
        "tagName": "Fishing",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 122,
        "tagName": "Surfing",
        "tagType": 2
      },
      {
        "tagId": 135,
        "tagName": "Bird Watching",
        "tagType": 2
      },
      {
        "tagId": 193,
        "tagName": "Outdoor Dining",
        "tagType": 2
      },
      {
        "tagId": 198,
        "tagName": "Boat Excursion",
        "tagType": 2
      },
      {
        "tagId": 202,
        "tagName": "Private Terraces",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 198,
        "venueCategoryId": 13,
        "venueId": 1063,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 1038,
    "venueCategoryId": 13,
    "status": 1,
    "title": "Nimmo Bay Resort",
    "description": "Nimmo Bay Resort is a luxurious high-end helicopter fly fishing and helicopter adventure destination nestled at the foot of Mount Stevens along the Great Bear Rainforest coastline in British Columbia, Canada. Nimmo is a BC Fishing Lodge offering luxury wilderness adventure tours including heli fishing and other wilderness adventure packages. For over 26 years, the Murray family has specialized in world class hospitality. Our tailor made helicopter adventure tours will exceed your highest expectations. Listen to your favorite music in your helicopter, enjoy your favorite flavor of house-made ice cream, and request your favorite song from the evening’s entertainment before you embark on one of the most unique wilderness adventure tours on Earth. Every aspect of your adventure is tailored to your personal request. Let your trip of a lifetime begin here! After all, Nimmo Bay Wilderness Adventure and Fishing Resort is one of the 1000 Places to See Before you Die.",
    "brand": null,
    "blockQuote": "Salmon fishing in a spectacular setting.",
    "emailAddress": "heli@nimmobay.com",
    "website": "nimmobay.com/",
    "bookingURL": "http://nimmobay.com/book-a-trip/",
    "redirectNotes": null,
    "phoneNumber": "+1 250 956 4000",
    "addressOne": "",
    "addressTwo": "",
    "city": "Port McNeil",
    "county": "",
    "region": "British Columbia",
    "country": "Canada",
    "rooms": 9,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 20611,
        "title": "Nimmo Bay Resort Canada's Wilderness - Triptoes Canada",
        "url": "https://pkslist.azureedge.net/media/images/1/148d0d05-a531-41bd-a40c-0c0401900db0/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/148d0d05-a531-41bd-a40c-0c0401900db0/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/148d0d05-a531-41bd-a40c-0c0401900db0/256x192.jpeg",
        "viewOrder": 0
      },
      {
        "mediaId": 10378,
        "title": "Nimmo Bay: The Ultimate Resort - Salt Magazine",
        "url": "https://pkslist.azureedge.net/media/images/1/4899df96-8a51-4e8c-ab18-3b7c9f97a97e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4899df96-8a51-4e8c-ab18-3b7c9f97a97e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4899df96-8a51-4e8c-ab18-3b7c9f97a97e/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 10380,
        "title": "Nimmo Bay | Vancouver Resort | Andrew Harper Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/b3add711-9463-4a6e-9925-cb065e998e2c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b3add711-9463-4a6e-9925-cb065e998e2c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b3add711-9463-4a6e-9925-cb065e998e2c/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 20610,
        "title": "Nimmo Bay Wilderness Resort In British Columbia, Canada | Glamping.com",
        "url": "https://pkslist.azureedge.net/media/images/1/79462cac-8851-4577-a5e5-25bb5663d078/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/79462cac-8851-4577-a5e5-25bb5663d078/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/79462cac-8851-4577-a5e5-25bb5663d078/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 20609,
        "title": "Nimmo Bay Wilderness Resort In British Columbia, Canada | Glamping.com",
        "url": "https://pkslist.azureedge.net/media/images/1/e43f90a2-8b06-41f2-8e02-fe022fd87fe6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e43f90a2-8b06-41f2-8e02-fe022fd87fe6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e43f90a2-8b06-41f2-8e02-fe022fd87fe6/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 20607,
        "title": "Nimmo Bay | Vancouver Resort | Andrew Harper Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/68360606-1f08-4aac-90c0-da342cf5c154/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/68360606-1f08-4aac-90c0-da342cf5c154/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/68360606-1f08-4aac-90c0-da342cf5c154/256x192.jpg",
        "viewOrder": 5
      }
    ],
    "geographicArea": {
      "geographicAreaId": 867,
      "latitude": 50.9388443,
      "longitude": -126.6844562,
      "parentGeographicAreaId": 0,
      "title": "Mount Waddington A, BC, Canada",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 50,
        "tagName": "Barefoot Luxury",
        "tagType": 1
      },
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 69,
        "tagName": "Couples",
        "tagType": 2
      },
      {
        "tagId": 70,
        "tagName": "Families",
        "tagType": 2
      },
      {
        "tagId": 102,
        "tagName": "Eco-friendly",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 109,
        "tagName": "Fishing",
        "tagType": 2
      },
      {
        "tagId": 110,
        "tagName": "Hiking",
        "tagType": 2
      },
      {
        "tagId": 121,
        "tagName": "Yoga",
        "tagType": 2
      },
      {
        "tagId": 170,
        "tagName": "Kayaking",
        "tagType": 2
      },
      {
        "tagId": 198,
        "tagName": "Boat Excursion",
        "tagType": 2
      },
      {
        "tagId": 238,
        "tagName": "Whale Watching",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 195,
        "venueCategoryId": 13,
        "venueId": 1038,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 588,
    "venueCategoryId": 13,
    "status": 1,
    "title": "Jamala Madikwe",
    "description": "Jamala Madikwe is an intimate and elegant safari lodge located within the 75 000 HA malaria-free Madikwe Game Reserve in South Africa's North West Province. Each of the five sumptuous and elegantly appointed suites offer private decks and rim-flow swimming pools. Madikwe is home to all of the Big 5 and most of Africa's top game species which includes a spectacular array of general game in addition to a very successful breeding program for the endangered wild dog.",
    "brand": null,
    "blockQuote": "Sofa safari in front of the waterhole where abundant wildlife come to play is extraordinary.",
    "emailAddress": "info@jamalamadikwe.com",
    "website": "jamalamadikwe.com/",
    "bookingURL": "http://booking.com/a413bc427a44c8f6?aid=925669",
    "redirectNotes": null,
    "phoneNumber": "+27 82 927 3129",
    "addressOne": "",
    "addressTwo": "",
    "city": "Madikwe",
    "county": "Ngaka Modiri Molema",
    "region": "North West",
    "country": "South Africa",
    "rooms": 5,
    "dollar": 3,
    "updated": "2020-05-06T12:20:00",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 4741,
        "title": "Jamala Madikwe Royal Safari Lodge - Inspirational Places",
        "url": "https://pkslist.azureedge.net/media/images/1/b91344c3-6d08-47f6-aba5-22f7a824e066/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b91344c3-6d08-47f6-aba5-22f7a824e066/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b91344c3-6d08-47f6-aba5-22f7a824e066/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 4737,
        "title": "Jamala Madikwe | Royal Safari Lodge",
        "url": "https://pkslist.azureedge.net/media/images/1/d7d4d4c3-3823-4024-8c8f-1cd96daa2ff3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d7d4d4c3-3823-4024-8c8f-1cd96daa2ff3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d7d4d4c3-3823-4024-8c8f-1cd96daa2ff3/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 4736,
        "title": "Jamala Madikwe | Royal Safari Lodge",
        "url": "https://pkslist.azureedge.net/media/images/1/22bdc299-2c8b-44b0-9b35-7b718ef73d21/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/22bdc299-2c8b-44b0-9b35-7b718ef73d21/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/22bdc299-2c8b-44b0-9b35-7b718ef73d21/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 4744,
        "title": "Images of Jamala Madikwe Royal Safari Lodge | Madikwe Game Reserve",
        "url": "https://pkslist.azureedge.net/media/images/1/42aa4d5e-4c31-4612-be23-940d5100d4af/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/42aa4d5e-4c31-4612-be23-940d5100d4af/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/42aa4d5e-4c31-4612-be23-940d5100d4af/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 4734,
        "title": "Jamala Madikwe Royal Safari Game Lodge",
        "url": "https://pkslist.azureedge.net/media/images/1/80a15553-12b6-4a20-ae56-610d357a85be/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/80a15553-12b6-4a20-ae56-610d357a85be/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/80a15553-12b6-4a20-ae56-610d357a85be/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 4743,
        "title": "Jamala Madikwe Royal Safari Lodge | Game Reserve",
        "url": "https://pkslist.azureedge.net/media/images/1/876f94f1-359b-4b42-9c16-32d7227534e8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/876f94f1-359b-4b42-9c16-32d7227534e8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/876f94f1-359b-4b42-9c16-32d7227534e8/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 4735,
        "title": "Jamala Madikwe Royal Safari Lodge - Greatest Africa",
        "url": "https://pkslist.azureedge.net/media/images/1/1b9b5eed-590a-4d35-95c3-34ef6d1a0d52/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1b9b5eed-590a-4d35-95c3-34ef6d1a0d52/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1b9b5eed-590a-4d35-95c3-34ef6d1a0d52/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 4738,
        "title": "Jamala Madikwe Royal Safari Lodge | Game Reserve | Madikwe Game ...",
        "url": "https://pkslist.azureedge.net/media/images/1/c6574718-d650-453d-bf1a-41fe3a347575/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c6574718-d650-453d-bf1a-41fe3a347575/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c6574718-d650-453d-bf1a-41fe3a347575/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 4740,
        "title": "Jamala Madikwe Royal Safari Lodge",
        "url": "https://pkslist.azureedge.net/media/images/1/b3907aa5-91ea-4d22-82c3-8c0c8049a102/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b3907aa5-91ea-4d22-82c3-8c0c8049a102/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b3907aa5-91ea-4d22-82c3-8c0c8049a102/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 428,
      "latitude": -24.722853,
      "longitude": 26.388158999999973,
      "parentGeographicAreaId": 0,
      "title": "Madikwe Game Reserve, Molatedi Gate, Madikwe, 2838, South Africa",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 47,
        "tagName": "Intimate Luxury ",
        "tagType": 1
      },
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 73,
        "tagName": "Outdoor Terraces",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 120,
        "tagName": "Private Dining",
        "tagType": 2
      },
      {
        "tagId": 135,
        "tagName": "Bird Watching",
        "tagType": 2
      },
      {
        "tagId": 181,
        "tagName": "Private/Suite Pools",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 112,
        "venueCategoryId": 13,
        "venueId": 588,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 537,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Thanda Safari",
    "description": "Thanda Safari is a world of wilderness and wildlife, matched with a deep commitment to Zulu culture and environmental conservation. Thanda means ‘Love’ in the language of the Zulu people, and Thanda Safari lives this ethos in the management of its 14 000-hectare reserve, its three unique properties, and its community partnerships. Experience the ultimate safari and game lodge experience with Thanda Safari, which has repeatedly been named the World’s Leading Luxury Lodge.\n\nA luxury private game reserve and member of The Leading Hotels of the World, Thanda Safari has three distinct accommodation options: Thanda Safari Lodge offers 9 exquisite bush suites; Villa iZulu is a completely private and exclusive bush retreat, ideal for celebrities, small groups and families. Thanda Tented Camp has 15 luxury safari tents for an evocative experience of the wild.\n\nThe public facilities at Thanda Safari Lodge include a lounge, library, dining room, boma, dining deck, wine cellar, cigar bar, private business area and viewing decks.",
    "brand": "",
    "blockQuote": "Keep a lookout by the waterhole, it attracts a plethora of wildlife.",
    "emailAddress": "reservations@thanda.co.za",
    "website": "thandasafari.co.za/",
    "bookingURL": "https://be.synxis.com/?_ga=2.42958115.72987780.1612512691-1220065833.1612512691&adult=1&agencyid=96027890&arrive=2021-02-05&chain=24447&child=0&currency=ZAR&depart=2021-02-06&hotel=7020&level=hotel&locale=en-US&rooms=1&src=30",
    "redirectNotes": "Thanda Safari's reservations system",
    "phoneNumber": "+27 32 586 0149",
    "addressOne": "",
    "addressTwo": "",
    "city": "Hluhluwe",
    "county": "",
    "region": "",
    "country": "South Africa",
    "rooms": 25,
    "dollar": 3,
    "updated": "2021-02-05T08:40:31.197",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 34152,
        "title": "Thanda Game Reserve - RTMC Tourism Marketing",
        "url": "https://pkslist.azureedge.net/media/images/1/7004e381-9821-4d96-bb93-1d0143d66eeb/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7004e381-9821-4d96-bb93-1d0143d66eeb/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7004e381-9821-4d96-bb93-1d0143d66eeb/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 45226,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/32c8e6e2-2856-441b-ab2a-a6beb7a841f9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/32c8e6e2-2856-441b-ab2a-a6beb7a841f9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/32c8e6e2-2856-441b-ab2a-a6beb7a841f9/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 45228,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/c47259e8-95ff-4a86-997d-c83c6f93c57e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c47259e8-95ff-4a86-997d-c83c6f93c57e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c47259e8-95ff-4a86-997d-c83c6f93c57e/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 45230,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/cd3be93b-fc0e-45d8-9c5a-dde1bb70fb45/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cd3be93b-fc0e-45d8-9c5a-dde1bb70fb45/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cd3be93b-fc0e-45d8-9c5a-dde1bb70fb45/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 45231,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/9fadd61b-6834-40e7-9810-98851f6374cd/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9fadd61b-6834-40e7-9810-98851f6374cd/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9fadd61b-6834-40e7-9810-98851f6374cd/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 45219,
        "title": "Tented Camps KZN | Luxury Tented Camp in Big 5 Game Reserve ...",
        "url": "https://pkslist.azureedge.net/media/images/1/88f29ebf-f9c4-4b69-8d91-56edce94769f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/88f29ebf-f9c4-4b69-8d91-56edce94769f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/88f29ebf-f9c4-4b69-8d91-56edce94769f/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 45218,
        "title": "Tented Camps KZN | Luxury Tented Camp in Big 5 Game Reserve ...",
        "url": "https://pkslist.azureedge.net/media/images/1/c9e53108-44f1-4edd-8670-c42434e9c435/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c9e53108-44f1-4edd-8670-c42434e9c435/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c9e53108-44f1-4edd-8670-c42434e9c435/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 45214,
        "title": "Thanda Safari Private Villa iZulu | KwaZulu Natal",
        "url": "https://pkslist.azureedge.net/media/images/1/e212b93c-92c5-4117-bba9-7ec3d005956b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e212b93c-92c5-4117-bba9-7ec3d005956b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e212b93c-92c5-4117-bba9-7ec3d005956b/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 45222,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/a20632f7-ced0-4995-a767-313d092adcc9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a20632f7-ced0-4995-a767-313d092adcc9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a20632f7-ced0-4995-a767-313d092adcc9/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 45215,
        "title": "Safari to Thanda Villa iZulu with Africa Travel Resource",
        "url": "https://pkslist.azureedge.net/media/images/1/357f1315-a0e6-42cd-95d6-0facd227d695/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/357f1315-a0e6-42cd-95d6-0facd227d695/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/357f1315-a0e6-42cd-95d6-0facd227d695/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 34151,
        "title": "Thanda Safari Lodge Hotel Review, South Africa | Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/1246a1e7-8ef4-4dc4-97a7-ff1c1014fc6c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1246a1e7-8ef4-4dc4-97a7-ff1c1014fc6c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1246a1e7-8ef4-4dc4-97a7-ff1c1014fc6c/256x192.jpg",
        "viewOrder": 11
      },
      {
        "mediaId": 45232,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/7818fe2e-9056-496e-9a99-e94983c2ec4b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7818fe2e-9056-496e-9a99-e94983c2ec4b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7818fe2e-9056-496e-9a99-e94983c2ec4b/256x192.jpg",
        "viewOrder": 12
      }
    ],
    "geographicArea": {
      "geographicAreaId": 2359,
      "latitude": -27.8655185,
      "longitude": 32.13437840000006,
      "parentGeographicAreaId": 0,
      "title": "P450 Bayala, Bayala, Hluhluwe, 3966, South Africa",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 42,
        "tagName": "Classic Traditionalist ",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 135,
        "tagName": "Bird Watching",
        "tagType": 2
      },
      {
        "tagId": 153,
        "tagName": "Cigar Lounge",
        "tagType": 2
      },
      {
        "tagId": 181,
        "tagName": "Private/Suite Pools",
        "tagType": 2
      },
      {
        "tagId": 278,
        "tagName": "Conservation",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 296,
        "tagName": "Guided Walk",
        "tagType": 2
      },
      {
        "tagId": 317,
        "tagName": "Gourmet",
        "tagType": 2
      },
      {
        "tagId": 324,
        "tagName": "Local Village",
        "tagType": 2
      },
      {
        "tagId": 349,
        "tagName": "4X4 Off Roading",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 459,
        "venueCategoryId": 13,
        "venueId": 537,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 529,
    "venueCategoryId": 20,
    "status": 1,
    "title": "Singita Pamushana Lodge",
    "description": "In 1994, the not-for-profit Malilangwe Trust was established as a result of the largest ever single donor investment in Zimbabwe, in order to purchase, protect, and manage the extraordinary wilderness area next to the Gonarezhou National Park in southeastern Zimbabwe.\n\nSingita Pamushana Lodge is the ecotourism arm of this 130,000 acre reserve, and its role is to help foster the sustainability of the wildlife and broader ecology, while enabling guests to share the magic of the lodge and the Malilangwe Wildlife Reserve. Singita manages the lodge on behalf of the Trust and all proceeds from the lodge benefit the numerous conservation and community outreach and development programmes underway.\n\nThe organic shaped buildings of Singita Pamushana Lodge are reminiscent of the ruins of Great Zimbabwe. Thick walls, meticulous thatching and incredibly detailed stonework lay the foundation for bold interiors that are in marked contrast to the surrounding landscape.\n\nThere are 6 palatial suites and a 5-bedroom villa, all with private plunge pools and panoramic views.",
    "brand": "Singita",
    "blockQuote": "If you've never experienced a guided safari walk, here's your chance to do it.",
    "emailAddress": "enquiries@singita.com",
    "website": "singita.com/lodge/singita-pamushana-lodge/",
    "bookingURL": "https://singita.com/lodge/singita-pamushana-lodge/",
    "redirectNotes": "",
    "phoneNumber": "+27 21 683 3424",
    "addressOne": "",
    "addressTwo": "",
    "city": "Malilangwe Reserve",
    "county": "",
    "region": "",
    "country": "Zimbabwe",
    "rooms": 9,
    "dollar": 3,
    "updated": "2020-05-17T09:02:47.687",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 23031,
        "title": "Pamushana Lodge - Pamushane Zimbabwe - Singita",
        "url": "https://pkslist.azureedge.net/media/images/1/2e1b18ae-ff2e-4600-914e-425c26e34042/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2e1b18ae-ff2e-4600-914e-425c26e34042/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2e1b18ae-ff2e-4600-914e-425c26e34042/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 33927,
        "title": "Pamushana Lodge - Pamushane Zimbabwe - Singita",
        "url": "https://pkslist.azureedge.net/media/images/1/00cf3282-dd65-44f0-b474-462eb460f3de/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/00cf3282-dd65-44f0-b474-462eb460f3de/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/00cf3282-dd65-44f0-b474-462eb460f3de/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 33937,
        "title": "Singita Pamushana Lodge | Singita Safari in Zimbabwe | Hideaway Report",
        "url": "https://pkslist.azureedge.net/media/images/1/1290f9d5-5391-4311-a8d4-9b19527ec451/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1290f9d5-5391-4311-a8d4-9b19527ec451/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1290f9d5-5391-4311-a8d4-9b19527ec451/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 33936,
        "title": "The New-Look Singita Pamushana Lodge is Revealed | Lodges and ...",
        "url": "https://pkslist.azureedge.net/media/images/1/1eae1a8e-3053-41dd-a568-403c2dd7c719/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1eae1a8e-3053-41dd-a568-403c2dd7c719/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1eae1a8e-3053-41dd-a568-403c2dd7c719/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 33929,
        "title": "The New-Look Singita Pamushana Lodge is Revealed | Lodges and ...",
        "url": "https://pkslist.azureedge.net/media/images/1/4d4d08d1-bc31-457e-9458-98e7f43b1479/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4d4d08d1-bc31-457e-9458-98e7f43b1479/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4d4d08d1-bc31-457e-9458-98e7f43b1479/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 33930,
        "title": "Singita Pamushana Lodge | Singita Safari in Zimbabwe | Hideaway Report",
        "url": "https://pkslist.azureedge.net/media/images/1/630f8f71-ff4e-47d5-bd27-e88f24c8605b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/630f8f71-ff4e-47d5-bd27-e88f24c8605b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/630f8f71-ff4e-47d5-bd27-e88f24c8605b/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 33935,
        "title": "Singita Pamushana Lodge | Singita Safari in Zimbabwe | Hideaway Report",
        "url": "https://pkslist.azureedge.net/media/images/1/3d381e1d-89eb-4b38-9c20-f441eb3ee4ac/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3d381e1d-89eb-4b38-9c20-f441eb3ee4ac/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3d381e1d-89eb-4b38-9c20-f441eb3ee4ac/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 33933,
        "title": "Singita Pamushana Lodge | Singita Safari in Zimbabwe | Hideaway Report",
        "url": "https://pkslist.azureedge.net/media/images/1/6f066f36-f71e-40aa-9f8c-5611d9cc0015/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6f066f36-f71e-40aa-9f8c-5611d9cc0015/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6f066f36-f71e-40aa-9f8c-5611d9cc0015/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 23030,
        "title": "Pamushana | Malilangwe Wildlife Reserve Zimbawe | Singita",
        "url": "https://pkslist.azureedge.net/media/images/1/d69a8b29-f8ff-4b24-9aac-db7022a3c29f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d69a8b29-f8ff-4b24-9aac-db7022a3c29f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d69a8b29-f8ff-4b24-9aac-db7022a3c29f/256x192.jpg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 368,
      "latitude": -20.605872,
      "longitude": 31.566956900000037,
      "parentGeographicAreaId": 0,
      "title": "Unnamed Road, Zimbabwe",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 60,
        "tagName": "Gastronomic",
        "tagType": 2
      },
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 92,
        "tagName": "Infinity Pool",
        "tagType": 2
      },
      {
        "tagId": 109,
        "tagName": "Fishing",
        "tagType": 2
      },
      {
        "tagId": 135,
        "tagName": "Bird Watching",
        "tagType": 2
      },
      {
        "tagId": 164,
        "tagName": "Excursions",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 263,
        "tagName": "Star Gazing",
        "tagType": 2
      },
      {
        "tagId": 269,
        "tagName": "Photography",
        "tagType": 2
      },
      {
        "tagId": 278,
        "tagName": "Conservation",
        "tagType": 2
      },
      {
        "tagId": 324,
        "tagName": "Local Village",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 93,
        "venueCategoryId": 20,
        "venueId": 529,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 20,
          "title": "BucketList Hotels",
          "region": "Global",
          "viewOrder": 0,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 343,
        "venueCategoryId": 13,
        "venueId": 529,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 469,
    "venueCategoryId": 13,
    "status": 1,
    "title": "andBeyond Ngorongoro Crater Lodge",
    "description": "Perched on the edge of the Crater itself, this award-winning lodge is fit for a king and queen. Steeped in romance, each stilted, handcrafted banana thatch suite at andBeyond Ngorongoro Crater Lodge is adorned with graceful antiques and unforgettable opulence. Ensuite bathrooms, with a centre piece of fresh roses, feature huge showers and chandelier-lit bathtubs, while floor-to-ceiling windows ensure breathtaking views from all around.",
    "brand": "andBeyond",
    "blockQuote": "The views are more incredible than imagined, you'll need one day just to absorb and more for everything else.",
    "emailAddress": "ngorongoro.reception@andbeyond.com",
    "website": "andbeyond.com/ngorongoro-crater-lodge/",
    "bookingURL": "http://www.andbeyond.com/reservation/reservation.htm",
    "redirectNotes": null,
    "phoneNumber": "+255 27 2537038",
    "addressOne": "",
    "addressTwo": "",
    "city": "Ngorongoro Crater",
    "county": "",
    "region": "",
    "country": "Tanzania",
    "rooms": 30,
    "dollar": 3,
    "updated": "2020-05-18T13:26:04.82",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 3215,
        "title": "Photos, andBeyond Ngorongoro Crater Lodge",
        "url": "https://pkslist.azureedge.net/media/images/1/4bbd0fbb-89a2-4ed4-8a7a-fb88fcd6aaa4/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4bbd0fbb-89a2-4ed4-8a7a-fb88fcd6aaa4/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4bbd0fbb-89a2-4ed4-8a7a-fb88fcd6aaa4/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 3219,
        "title": "ngorongoro crater lodge, tanzania",
        "url": "https://pkslist.azureedge.net/media/images/1/1e68666c-cf1e-4142-bb9f-ab655cb29656/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1e68666c-cf1e-4142-bb9f-ab655cb29656/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1e68666c-cf1e-4142-bb9f-ab655cb29656/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 36465,
        "title": "andBeyond Ngorongoro Crater Lodge (Ngorongoro Conservation Area ...",
        "url": "https://pkslist.azureedge.net/media/images/1/b6489d44-01d0-4e34-80f9-520e2a37e0bb/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b6489d44-01d0-4e34-80f9-520e2a37e0bb/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b6489d44-01d0-4e34-80f9-520e2a37e0bb/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 36460,
        "title": "Hotel Profile",
        "url": "https://pkslist.azureedge.net/media/images/1/ab4289ff-0f30-4e03-9c96-9c8014c950cf/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ab4289ff-0f30-4e03-9c96-9c8014c950cf/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ab4289ff-0f30-4e03-9c96-9c8014c950cf/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 36462,
        "title": "Safari Lodges in Africa: World's Best 2019 (With images) | Safari ...",
        "url": "https://pkslist.azureedge.net/media/images/1/938c2232-4dcb-43c5-b3e0-b20973c408d5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/938c2232-4dcb-43c5-b3e0-b20973c408d5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/938c2232-4dcb-43c5-b3e0-b20973c408d5/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 36454,
        "title": "About Ngorongoro Crater Lodge | National Geographic Lodges",
        "url": "https://pkslist.azureedge.net/media/images/1/479c9d70-1932-471f-a9db-3f1a2fb7cdce/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/479c9d70-1932-471f-a9db-3f1a2fb7cdce/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/479c9d70-1932-471f-a9db-3f1a2fb7cdce/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 36458,
        "title": "andBeyond Ngorongoro Crater Lodge | Official Site | Tanzania",
        "url": "https://pkslist.azureedge.net/media/images/1/35f0167a-e81d-40ad-b265-c575364724a5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/35f0167a-e81d-40ad-b265-c575364724a5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/35f0167a-e81d-40ad-b265-c575364724a5/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 3225,
        "title": "Ngorongoro Crater Lodge, Tanzania | Go2Africa.com",
        "url": "https://pkslist.azureedge.net/media/images/1/08b948b9-73c1-4c28-896d-4682d154927e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/08b948b9-73c1-4c28-896d-4682d154927e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/08b948b9-73c1-4c28-896d-4682d154927e/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 3222,
        "title": "andBeyond Ngorongoro Crater Lodge - Bateleur Club",
        "url": "https://pkslist.azureedge.net/media/images/1/fb9d6dee-a073-49a5-b2fa-6d65793c031e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fb9d6dee-a073-49a5-b2fa-6d65793c031e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fb9d6dee-a073-49a5-b2fa-6d65793c031e/256x192.jpg",
        "viewOrder": 11
      },
      {
        "mediaId": 3218,
        "title": "AndBeyond Ngorongoro Crater Lodge, Ngorongoro, Tanzania - Resort ...",
        "url": "https://pkslist.azureedge.net/media/images/1/6aa98f60-d72a-4e76-adf8-7f01ef064c5d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6aa98f60-d72a-4e76-adf8-7f01ef064c5d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6aa98f60-d72a-4e76-adf8-7f01ef064c5d/256x192.jpg",
        "viewOrder": 12
      }
    ],
    "geographicArea": {
      "geographicAreaId": 312,
      "latitude": -3.23991,
      "longitude": 35.49912519999998,
      "parentGeographicAreaId": 0,
      "title": "Ngorongoro Conservation Area, Tanzania",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 48,
        "tagName": "Iconic/Landmark ",
        "tagType": 1
      },
      {
        "tagId": 57,
        "tagName": "Romantic",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 59,
        "tagName": "Cultural",
        "tagType": 2
      },
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 73,
        "tagName": "Outdoor Terraces",
        "tagType": 2
      },
      {
        "tagId": 102,
        "tagName": "Eco-friendly",
        "tagType": 2
      },
      {
        "tagId": 135,
        "tagName": "Bird Watching",
        "tagType": 2
      },
      {
        "tagId": 156,
        "tagName": "Fireplace",
        "tagType": 2
      },
      {
        "tagId": 164,
        "tagName": "Excursions",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 79,
        "venueCategoryId": 13,
        "venueId": 469,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 468,
    "venueCategoryId": 13,
    "status": 1,
    "title": "andBeyond Lake Manyara Tree Lodge",
    "description": "The only permanent luxury lodge in Lake Manyara National Park, Lake Manyara Tree Lodge is renowned for its romantic stilted tree house suites that are shaded by a mahogany forest. After an exhilarating game drive, guests stop for sundowners on the lakeshore below the Great Rift Valley wall.\n\nHidden away high in the trees in a secluded corner of the national park, Lake Manyara Tree Lodge offers a sophisticated blend of natural indulgence and romance. Nestled in dense forest between the lakeshore and the steep Rift Valley escarpment, the lodge is perfectly positioned to explore the park’s wealth of habitats. Set in the remote southern region of the park, you would be surprised to see another vehicle on your game drive.\n\nEach suite is a lofty tree house with private veranda and large windows that open up to the lush forest, welcoming in the sights and sounds of the African bush. Woodchip paths and natural branch railings blur the edges between the natural and the manmade, while wooden interiors and thatched roofs invite the surrounding forest indoors.",
    "brand": "andBeyond",
    "blockQuote": "No air conditioning, no windows, it's just you and the music of the jungle.",
    "emailAddress": "manyara.reception@andbeyond.com",
    "website": "andbeyond.com/lake-manyara-tree-lodge/the-lodge.htm",
    "bookingURL": "http://www.andbeyond.com/reservation/reservation.htm",
    "redirectNotes": null,
    "phoneNumber": "+255 27 2539139",
    "addressOne": "",
    "addressTwo": "",
    "city": "Lake Manyara",
    "county": "",
    "region": "",
    "country": "Tanzania",
    "rooms": 10,
    "dollar": 3,
    "updated": "2020-05-18T13:13:07.473",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 36450,
        "title": "Lake Manyara Tree Lodge | Luxury Tanzania Safaris | SafariFRANK",
        "url": "https://pkslist.azureedge.net/media/images/1/7bbce94c-8b2b-4f69-aab3-4cff22c9d1e8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7bbce94c-8b2b-4f69-aab3-4cff22c9d1e8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7bbce94c-8b2b-4f69-aab3-4cff22c9d1e8/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 36445,
        "title": "andBeyond Lake Manyara Tree Lodge • Hotel Review by TravelPlusStyle",
        "url": "https://pkslist.azureedge.net/media/images/1/a39ccd5b-9a41-46b2-9834-b7d4f02d2995/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a39ccd5b-9a41-46b2-9834-b7d4f02d2995/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a39ccd5b-9a41-46b2-9834-b7d4f02d2995/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 36441,
        "title": "Lake Manyara Tree Lodge | Luxury Tanzania Safaris | SafariFRANK",
        "url": "https://pkslist.azureedge.net/media/images/1/9cd5b376-85ac-4b58-86e3-e09a0f4eefba/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9cd5b376-85ac-4b58-86e3-e09a0f4eefba/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9cd5b376-85ac-4b58-86e3-e09a0f4eefba/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 36434,
        "title": "andBeyond Lake Manyara Tree Lodge | National Park | Tanzania",
        "url": "https://pkslist.azureedge.net/media/images/1/a314fc63-cb0a-4372-ab1d-b3652ae3d921/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a314fc63-cb0a-4372-ab1d-b3652ae3d921/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a314fc63-cb0a-4372-ab1d-b3652ae3d921/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 3212,
        "title": "andBeyond Lake Manyara Tree Lodge - UPDATED 2017 Reviews (Lake ...",
        "url": "https://pkslist.azureedge.net/media/images/1/282107e2-a224-4eaf-b38a-b6e3a75273c3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/282107e2-a224-4eaf-b38a-b6e3a75273c3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/282107e2-a224-4eaf-b38a-b6e3a75273c3/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 36432,
        "title": "andBeyond Lake Manyara Tree Lodge • Hotel Review by TravelPlusStyle",
        "url": "https://pkslist.azureedge.net/media/images/1/543c6379-a13a-4d26-bf68-61ab96dcaaad/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/543c6379-a13a-4d26-bf68-61ab96dcaaad/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/543c6379-a13a-4d26-bf68-61ab96dcaaad/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 36440,
        "title": "andBeyond Lake Manyara Tree Lodge • Hotel Review by TravelPlusStyle",
        "url": "https://pkslist.azureedge.net/media/images/1/ff0e910f-187d-4be7-8a86-1d5e2c273f13/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ff0e910f-187d-4be7-8a86-1d5e2c273f13/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ff0e910f-187d-4be7-8a86-1d5e2c273f13/256x192.jpg",
        "viewOrder": 11
      },
      {
        "mediaId": 3202,
        "title": "andBeyond Lake Manyara Tree Lodge",
        "url": "https://pkslist.azureedge.net/media/images/1/7ca91e0d-0bf9-4e77-892e-cbebc0ea67c6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/7ca91e0d-0bf9-4e77-892e-cbebc0ea67c6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/7ca91e0d-0bf9-4e77-892e-cbebc0ea67c6/256x192.jpg",
        "viewOrder": 12
      }
    ],
    "geographicArea": {
      "geographicAreaId": 311,
      "latitude": -3.668764,
      "longitude": 35.746448,
      "parentGeographicAreaId": 0,
      "title": "Lake Manyara National Park, Tanzania",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 34,
        "tagName": "Cosy",
        "tagType": 2
      },
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 73,
        "tagName": "Outdoor Terraces",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 93,
        "tagName": "Private",
        "tagType": 2
      },
      {
        "tagId": 102,
        "tagName": "Eco-friendly",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 133,
        "tagName": "Glamping",
        "tagType": 2
      },
      {
        "tagId": 135,
        "tagName": "Bird Watching",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 324,
        "tagName": "Local Village",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 78,
        "venueCategoryId": 13,
        "venueId": 468,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 439,
    "venueCategoryId": 13,
    "status": 1,
    "title": "Singita Sabora Tented Camp",
    "description": "With travellers yearning for wide-open spaces, intuitive service and unfiltered space and time, this modern interpretation of luxury tented safaris reflects a new dawn on the continent – and presents the perfect opportunity to return to Africa to rest, reset and be restored.\n\nRetaining the quintessential elements of a romantic safari under canvas, the camp’s nine tented suites are now set even closer to the ground. Subtle décor and design elements merge seamlessly with the continent’s creative energy and contemporary cultural identity; while hand-stitched leather finishes, durable canvas and campaign furniture echo the spirit of classic East African adventures.\n\nHere, set against the backdrop of the sweeping savannahs, teeming herds of wildlife and powder-blue skies, a sense of spaciousness and peace abounds.",
    "brand": "Singita",
    "blockQuote": "Out of Africa 1920's, you're not dreaming.",
    "emailAddress": "Sabora@grumeti.singita.com",
    "website": "singita.com/lodge/singita-sabora-tented-camp/",
    "bookingURL": "https://availability.singita.com/search?adults=2&children=0&endDate=2020-12-09&lodges[]=TZG-STC&regions[]=TZG&startDate=2020-12-04&toddler=0&iata=96027890",
    "redirectNotes": null,
    "phoneNumber": "+1 770 947 7049",
    "addressOne": "",
    "addressTwo": "",
    "city": "Grumeti",
    "county": "",
    "region": "Serengeti",
    "country": "Tanzania",
    "rooms": 9,
    "dollar": 3,
    "updated": "2020-11-24T08:36:35.83",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 43326,
        "title": "Sabora Tented Camp, Tanzania - Natural World Safaris",
        "url": "https://pkslist.azureedge.net/media/images/1/e2427e00-1f1f-4395-b8a1-59392caafc1d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e2427e00-1f1f-4395-b8a1-59392caafc1d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e2427e00-1f1f-4395-b8a1-59392caafc1d/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 36234,
        "title": "Singita Grumeti - Sabora Tented Camp, Serengeti National Park ...",
        "url": "https://pkslist.azureedge.net/media/images/1/434031ad-2b81-4b18-9af6-48de25fffa15/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/434031ad-2b81-4b18-9af6-48de25fffa15/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/434031ad-2b81-4b18-9af6-48de25fffa15/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 43307,
        "title": "The New Singita Sabora Tented Camp In Tanzania Is The Perfect ...",
        "url": "https://pkslist.azureedge.net/media/images/1/38e8f5c6-7bfd-4478-83e7-a706671fc86b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/38e8f5c6-7bfd-4478-83e7-a706671fc86b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/38e8f5c6-7bfd-4478-83e7-a706671fc86b/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 43308,
        "title": "The New Singita Sabora Tented Camp In Tanzania Is The Perfect ...",
        "url": "https://pkslist.azureedge.net/media/images/1/4976d72f-0722-4f88-aec9-5c145f7ab23a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4976d72f-0722-4f88-aec9-5c145f7ab23a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4976d72f-0722-4f88-aec9-5c145f7ab23a/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 43323,
        "title": "Singita-Sabora-Tennis-Court",
        "url": "https://pkslist.azureedge.net/media/images/1/4617d944-9059-4e52-8e39-fe67c4b28217/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4617d944-9059-4e52-8e39-fe67c4b28217/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4617d944-9059-4e52-8e39-fe67c4b28217/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 43309,
        "title": "Singita",
        "url": "https://pkslist.azureedge.net/media/images/1/14c1f6f0-7cbd-4a75-84ad-98f7389815e1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/14c1f6f0-7cbd-4a75-84ad-98f7389815e1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/14c1f6f0-7cbd-4a75-84ad-98f7389815e1/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 43318,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e608555b-ec1c-4f2e-aaf0-95f6e2a892e9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e608555b-ec1c-4f2e-aaf0-95f6e2a892e9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e608555b-ec1c-4f2e-aaf0-95f6e2a892e9/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 43317,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/0001da13-da99-4664-9fbe-f4bb68c18779/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0001da13-da99-4664-9fbe-f4bb68c18779/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0001da13-da99-4664-9fbe-f4bb68c18779/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 43306,
        "title": "Sabora Tented Camp",
        "url": "https://pkslist.azureedge.net/media/images/1/9747968f-da17-4925-9d9e-4db81a7e62d9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/9747968f-da17-4925-9d9e-4db81a7e62d9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/9747968f-da17-4925-9d9e-4db81a7e62d9/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 43320,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/d95f5357-8efd-4a61-9bcc-42c8a1f495d7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d95f5357-8efd-4a61-9bcc-42c8a1f495d7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d95f5357-8efd-4a61-9bcc-42c8a1f495d7/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 43319,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/ed46319b-c7e7-4140-a4cc-e6b556de86b7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ed46319b-c7e7-4140-a4cc-e6b556de86b7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ed46319b-c7e7-4140-a4cc-e6b556de86b7/256x192.jpg",
        "viewOrder": 11
      },
      {
        "mediaId": 43315,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/570a18fb-9b63-4d62-b32b-247e956d5803/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/570a18fb-9b63-4d62-b32b-247e956d5803/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/570a18fb-9b63-4d62-b32b-247e956d5803/256x192.jpg",
        "viewOrder": 12
      },
      {
        "mediaId": 43316,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/41eea9ca-1b9e-4342-b2d9-a6378cb54d28/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/41eea9ca-1b9e-4342-b2d9-a6378cb54d28/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/41eea9ca-1b9e-4342-b2d9-a6378cb54d28/256x192.jpg",
        "viewOrder": 13
      },
      {
        "mediaId": 43321,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e4eb1610-adc5-48f4-b666-d15d277f6e88/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e4eb1610-adc5-48f4-b666-d15d277f6e88/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e4eb1610-adc5-48f4-b666-d15d277f6e88/256x192.jpg",
        "viewOrder": 14
      }
    ],
    "geographicArea": {
      "geographicAreaId": 286,
      "latitude": -2.037341,
      "longitude": 34.07127800000001,
      "parentGeographicAreaId": 0,
      "title": "Grumeti Reserves, Grumeti Game Reserve, Tanzania",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 57,
        "tagName": "Romantic",
        "tagType": 1
      },
      {
        "tagId": 359,
        "tagName": "Colonial Chic",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 88,
        "tagName": "Tennis",
        "tagType": 2
      },
      {
        "tagId": 133,
        "tagName": "Glamping",
        "tagType": 2
      },
      {
        "tagId": 134,
        "tagName": "Wilderness Walks",
        "tagType": 2
      },
      {
        "tagId": 135,
        "tagName": "Bird Watching",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 349,
        "tagName": "4X4 Off Roading",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 72,
        "venueCategoryId": 13,
        "venueId": 439,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 436,
    "venueCategoryId": 13,
    "status": 1,
    "title": "Singita Faru Faru Lodge",
    "description": "Singita Faru Faru Lodge is set in Grumeti in northern Tanzania, forming part of the Serengeti Mara ecosystem. Built on a gently sloping hill, this riverine lodge, positioned on the renowned migratory route, is a place where you can quite literally expect the unexpected. Informal, unconventional and surprising at every turn, Singita Faru Faru Lodge is a mix of contemporary, organic style and the quirky practicality of a traditional botanist's camp. With such close proximity to the river and plains, guests have the unique opportunity to experience a very close connection with the wilderness.",
    "brand": "Singita",
    "blockQuote": "Contemporary, cool, chic, classic and comfortable, the 5 C's of Faru Faru, a diamond in the wild.",
    "emailAddress": "FaruFaru@grumeti.singita.com",
    "website": "singita.com/lodge/singita-faru-faru-lodge/",
    "bookingURL": "https://availability.singita.com/singita/Trip-Planner",
    "redirectNotes": null,
    "phoneNumber": "+1 770 947 7049",
    "addressOne": "",
    "addressTwo": "",
    "city": "Grumeti",
    "county": "",
    "region": "Serengeti",
    "country": "Tanzania",
    "rooms": 9,
    "dollar": 3,
    "updated": "2020-05-20T15:29:05.273",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 36198,
        "title": "Singita Faru Faru Lodge, Grumeti, Tanzania | Singita",
        "url": "https://pkslist.azureedge.net/media/images/1/f67f6e77-539d-4f1c-b52f-2aac1d836c09/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f67f6e77-539d-4f1c-b52f-2aac1d836c09/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f67f6e77-539d-4f1c-b52f-2aac1d836c09/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 36199,
        "title": "SINGITA FARU FARU LODGE - Updated 2020 Reviews (Serengeti National ...",
        "url": "https://pkslist.azureedge.net/media/images/1/3b2fd5c8-12ea-4f31-b702-b6d9d6fb1c94/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3b2fd5c8-12ea-4f31-b702-b6d9d6fb1c94/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3b2fd5c8-12ea-4f31-b702-b6d9d6fb1c94/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 36205,
        "title": "Singita Faru Faru Lodge • Luxury Hotel Review by TravelPlusStyle",
        "url": "https://pkslist.azureedge.net/media/images/1/a80603ed-27d2-45fb-b388-efca234037ae/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a80603ed-27d2-45fb-b388-efca234037ae/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a80603ed-27d2-45fb-b388-efca234037ae/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 36211,
        "title": "Singita Faru Faru Lodge • Luxury Hotel Review by TravelPlusStyle",
        "url": "https://pkslist.azureedge.net/media/images/1/16fc4337-e3b7-455d-a683-0cbd834718c8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/16fc4337-e3b7-455d-a683-0cbd834718c8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/16fc4337-e3b7-455d-a683-0cbd834718c8/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 36210,
        "title": "Singita Faru Faru Lodge • Luxury Hotel Review by TravelPlusStyle",
        "url": "https://pkslist.azureedge.net/media/images/1/f572aea5-e1c0-42b7-872e-bd6409d8d6b4/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f572aea5-e1c0-42b7-872e-bd6409d8d6b4/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f572aea5-e1c0-42b7-872e-bd6409d8d6b4/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 36203,
        "title": "Singita Faru Faru Lodge • Luxury Hotel Review by TravelPlusStyle",
        "url": "https://pkslist.azureedge.net/media/images/1/69880ab7-605a-4aef-b0c8-b8413b8f7387/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/69880ab7-605a-4aef-b0c8-b8413b8f7387/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/69880ab7-605a-4aef-b0c8-b8413b8f7387/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 36207,
        "title": "Singita Faru Faru Lodge • Luxury Hotel Review by TravelPlusStyle",
        "url": "https://pkslist.azureedge.net/media/images/1/d35681ec-6740-44f9-95d1-521f8a823574/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d35681ec-6740-44f9-95d1-521f8a823574/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d35681ec-6740-44f9-95d1-521f8a823574/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 36204,
        "title": "Singita Faru Faru – Camp Review - African-Born Safaris",
        "url": "https://pkslist.azureedge.net/media/images/1/62adbd80-0ca5-4149-a4d4-1ea18b452351/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/62adbd80-0ca5-4149-a4d4-1ea18b452351/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/62adbd80-0ca5-4149-a4d4-1ea18b452351/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 36206,
        "title": "Singita Faru Faru Lodge - Botanical theme -Timeless Africa Safaris",
        "url": "https://pkslist.azureedge.net/media/images/1/f1ce4b96-678d-409c-a499-f46db3724dc9/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f1ce4b96-678d-409c-a499-f46db3724dc9/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f1ce4b96-678d-409c-a499-f46db3724dc9/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 36202,
        "title": "Singita Faru Faru Lodge • Luxury Hotel Review by TravelPlusStyle",
        "url": "https://pkslist.azureedge.net/media/images/1/d7768422-4cbf-4426-a949-f83d935339a6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d7768422-4cbf-4426-a949-f83d935339a6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d7768422-4cbf-4426-a949-f83d935339a6/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 36208,
        "title": "Singita Faru Faru Lodge • Luxury Hotel Review by TravelPlusStyle",
        "url": "https://pkslist.azureedge.net/media/images/1/b1cdba4c-7546-4cd4-b6af-63406eff0512/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b1cdba4c-7546-4cd4-b6af-63406eff0512/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b1cdba4c-7546-4cd4-b6af-63406eff0512/256x192.jpg",
        "viewOrder": 11
      },
      {
        "mediaId": 2876,
        "title": "Singita Faru Faru Lodge - Serengeti luxury safari lodge - Singita ...",
        "url": "https://pkslist.azureedge.net/media/images/1/baef03dd-575b-4368-b352-014afd0c198d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/baef03dd-575b-4368-b352-014afd0c198d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/baef03dd-575b-4368-b352-014afd0c198d/256x192.jpg",
        "viewOrder": 13
      },
      {
        "mediaId": 2878,
        "title": "Thank You For Being Sophisticated > Blog - TYFBS",
        "url": "https://pkslist.azureedge.net/media/images/1/5d7cba09-585e-473b-9f20-baabedfe2821/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5d7cba09-585e-473b-9f20-baabedfe2821/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5d7cba09-585e-473b-9f20-baabedfe2821/256x192.jpg",
        "viewOrder": 14
      }
    ],
    "geographicArea": {
      "geographicAreaId": 283,
      "latitude": -2.025204,
      "longitude": 34.24601899999993,
      "parentGeographicAreaId": 0,
      "title": "Grumeti Reserves, Serengeti National Park, Tanzania",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 60,
        "tagName": "Gastronomic",
        "tagType": 2
      },
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 73,
        "tagName": "Outdoor Terraces",
        "tagType": 2
      },
      {
        "tagId": 79,
        "tagName": "Fitness",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 134,
        "tagName": "Wilderness Walks",
        "tagType": 2
      },
      {
        "tagId": 135,
        "tagName": "Bird Watching",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 71,
        "venueCategoryId": 13,
        "venueId": 436,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 420,
    "venueCategoryId": 13,
    "status": 1,
    "title": "andBeyond Klein's Camp",
    "description": "With unbroken views over a gorgeous game-filled valley and the hills beyond, which play host to one of the greatest wildlife migrations on earth, &Beyond Klein’s Camp embodies the very soul of the Serengeti.\n\nThis private and remote camp offers guests the spirit of wild Africa - wooded hillsides, rolling grasslands and forested river banks all teeming with the rich wildlife of this natural sanctuary lie sprawled at your feet. Set in a landscape that is gentle yet rugged, &Beyond Klein’s Camp reflects the heart of this untamed land with a vintage safari style.",
    "brand": "andBeyond",
    "blockQuote": "Sundowners at the bar with sweeping views of the Serengeti and wildlife surpassed my expectations.",
    "emailAddress": "safaris@andbeyond.com",
    "website": "andbeyond.com/kleins-camp/",
    "bookingURL": "http://www.andbeyond.com/reservation/reservation.htm",
    "redirectNotes": null,
    "phoneNumber": "+27 11 809 4300",
    "addressOne": "",
    "addressTwo": "",
    "city": "Serengeti",
    "county": "",
    "region": "",
    "country": "Tanzania",
    "rooms": 10,
    "dollar": 3,
    "updated": "2020-05-18T11:56:06.193",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 35619,
        "title": "andBeyond Klein's Camp | Luxury Serengeti Safari Lodge | Tanzania",
        "url": "https://pkslist.azureedge.net/media/images/1/91a884ca-d12a-4c0d-844e-64197fcc9396/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/91a884ca-d12a-4c0d-844e-64197fcc9396/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/91a884ca-d12a-4c0d-844e-64197fcc9396/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 35620,
        "title": "andBeyond Klein's Camp and Bateleur Camp know how to do luxury ...",
        "url": "https://pkslist.azureedge.net/media/images/1/0f9a3e0b-8ee3-4389-a452-21070c9c95b2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0f9a3e0b-8ee3-4389-a452-21070c9c95b2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0f9a3e0b-8ee3-4389-a452-21070c9c95b2/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 35621,
        "title": "&Beyond Klein's Camp, Tanzania | Timbuktu Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/8c68f266-d751-4d49-843b-451d778aee28/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8c68f266-d751-4d49-843b-451d778aee28/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8c68f266-d751-4d49-843b-451d778aee28/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 35624,
        "title": "andBeyond Klein's Camp | Luxury Serengeti Safari Lodge | Tanzania",
        "url": "https://pkslist.azureedge.net/media/images/1/2c5af28d-2f66-494a-9d53-32b3714f6085/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2c5af28d-2f66-494a-9d53-32b3714f6085/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2c5af28d-2f66-494a-9d53-32b3714f6085/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 2666,
        "title": "andBeyond Klein's Camp - UPDATED 2017 Lodge Reviews (Serengeti ...",
        "url": "https://pkslist.azureedge.net/media/images/1/f3ca56a7-a961-4805-89f6-535cbe6a2695/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f3ca56a7-a961-4805-89f6-535cbe6a2695/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f3ca56a7-a961-4805-89f6-535cbe6a2695/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 35617,
        "title": "How do I get to andBeyond Klein's Camp? | Tanzania",
        "url": "https://pkslist.azureedge.net/media/images/1/28a7cd89-5d45-472d-ba97-e06e9a66a8cc/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/28a7cd89-5d45-472d-ba97-e06e9a66a8cc/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/28a7cd89-5d45-472d-ba97-e06e9a66a8cc/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 268,
      "latitude": -1.835334,
      "longitude": 35.24600600000008,
      "parentGeographicAreaId": 0,
      "title": "Serengeti National Park, Tanzania",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 59,
        "tagName": "Cultural",
        "tagType": 2
      },
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 73,
        "tagName": "Outdoor Terraces",
        "tagType": 2
      },
      {
        "tagId": 75,
        "tagName": "Casual Dining",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 93,
        "tagName": "Private",
        "tagType": 2
      },
      {
        "tagId": 134,
        "tagName": "Wilderness Walks",
        "tagType": 2
      },
      {
        "tagId": 135,
        "tagName": "Bird Watching",
        "tagType": 2
      },
      {
        "tagId": 268,
        "tagName": "Massage",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 67,
        "venueCategoryId": 13,
        "venueId": 420,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 416,
    "venueCategoryId": 0,
    "status": 1,
    "title": "Singita Castleton Lodge",
    "description": "Formerly the family home of Singita founder Luke Bailes’ grandfather, Singita Castleton is an exclusive use lodge. Set within 45,000 acres of private reserve, Singita Castleton consists of a main house with communal living spaces, and accommodation located in six individual cottages within the grounds, catering for up to 12 people. It is steeped in history, capturing the spirit of the original Castleton house, giving the lodge a historical and comforting nature. Singita Castleton has been designed to combine the best elements of a private safari lodge with the rustic charms of a country farmhouse, with the added benefit of extensive high-end facilities, including a vast garden, swimming pool, wine cellar, gym, tennis court and spa treatment room. Guests can relax together in the courtyard, gather around the traditional ‘boma’ or meet in the country-style kitchen, yet the individual cottages allow guests to retreat to the privacy of their own space as and when it’s needed. All of this i s overlooking a waterhole where animals regularly gather to drink.",
    "brand": "Singita",
    "blockQuote": "The place for family reunions in the bsuh.",
    "emailAddress": "enquiries@singita.com",
    "website": "singita.com/lodge/singita-castleton/",
    "bookingURL": "https://availability.singita.com/singita/Trip-Planner",
    "redirectNotes": null,
    "phoneNumber": "+27 21 683 3424",
    "addressOne": "",
    "addressTwo": "",
    "city": "Sabi Sand",
    "county": "",
    "region": "Greater Kruger",
    "country": "South Africa",
    "rooms": 6,
    "dollar": 3,
    "updated": "2020-05-17T07:59:39.767",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 2643,
        "title": "Afri-Unique Tours - Singita Castleton Lodge",
        "url": "https://pkslist.azureedge.net/media/images/1/89599927-3e9a-43d2-b230-3e10e49d4da0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/89599927-3e9a-43d2-b230-3e10e49d4da0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/89599927-3e9a-43d2-b230-3e10e49d4da0/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 2644,
        "title": "Castleton",
        "url": "https://pkslist.azureedge.net/media/images/1/535637f9-2dc1-4878-9e04-6e492eaa0b4d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/535637f9-2dc1-4878-9e04-6e492eaa0b4d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/535637f9-2dc1-4878-9e04-6e492eaa0b4d/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 36197,
        "title": "Africa Direct USA | Singita Castleton",
        "url": "https://pkslist.azureedge.net/media/images/1/f1fb6e88-f6c6-443f-b973-d0c1684becaa/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f1fb6e88-f6c6-443f-b973-d0c1684becaa/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f1fb6e88-f6c6-443f-b973-d0c1684becaa/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 36190,
        "title": "In Pictures - Singita Castleton - Blog - Iconic Africa",
        "url": "https://pkslist.azureedge.net/media/images/1/5c9e79aa-6c1c-4dd1-87af-e73988314395/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5c9e79aa-6c1c-4dd1-87af-e73988314395/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5c9e79aa-6c1c-4dd1-87af-e73988314395/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 36192,
        "title": "Singita Castleton, South Africa (2020) - 5-Star Lodge For Families",
        "url": "https://pkslist.azureedge.net/media/images/1/96370294-e164-4d61-8bd7-a585a9032c68/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/96370294-e164-4d61-8bd7-a585a9032c68/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/96370294-e164-4d61-8bd7-a585a9032c68/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 2635,
        "title": "Castleton Lodge - Sabi Sand - Singita",
        "url": "https://pkslist.azureedge.net/media/images/1/5df24203-904f-4d63-8955-07b8130fe52e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5df24203-904f-4d63-8955-07b8130fe52e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5df24203-904f-4d63-8955-07b8130fe52e/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 2637,
        "title": "Singita Castleton Dining - Picture of Singita Castleton, Singita ...",
        "url": "https://pkslist.azureedge.net/media/images/1/71c5b52b-e7aa-4c20-b76f-190143409cc3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/71c5b52b-e7aa-4c20-b76f-190143409cc3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/71c5b52b-e7aa-4c20-b76f-190143409cc3/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 2636,
        "title": "Singita Castleton Lodge | Sun Safaris",
        "url": "https://pkslist.azureedge.net/media/images/1/68e59930-24bb-4cbe-b85c-f74aad2d057c/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/68e59930-24bb-4cbe-b85c-f74aad2d057c/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/68e59930-24bb-4cbe-b85c-f74aad2d057c/256x192.jpg",
        "viewOrder": 7
      }
    ],
    "geographicArea": {
      "geographicAreaId": 266,
      "latitude": -24.8305746,
      "longitude": 31.442572100000007,
      "parentGeographicAreaId": 0,
      "title": "Sabi Sand Game Reserve, Kruger National Park, Mpumalanga, Hazyview, 1242, South Africa",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 189,
        "tagName": "Exclusively Yours",
        "tagType": 1
      },
      {
        "tagId": 239,
        "tagName": "Family Friendly",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 32,
        "tagName": "Garden",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 70,
        "tagName": "Families",
        "tagType": 2
      },
      {
        "tagId": 71,
        "tagName": "Group of Friends",
        "tagType": 2
      },
      {
        "tagId": 73,
        "tagName": "Outdoor Terraces",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 88,
        "tagName": "Tennis",
        "tagType": 2
      },
      {
        "tagId": 135,
        "tagName": "Bird Watching",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 349,
        "tagName": "4X4 Off Roading",
        "tagType": 2
      },
      {
        "tagId": 365,
        "tagName": "Private Chef",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 457,
        "venueCategoryId": 13,
        "venueId": 416,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 415,
    "venueCategoryId": 13,
    "status": 1,
    "title": "Londolozi Private Game Reserve",
    "description": "Londolozi is one of South Africa’s original private game reserves. A pillar of global ecotourism, it is also a proudly family-run business. The reserve has been lauded internationally for its far-sighted and progressive land and wildlife management, as well as its productive community involvement.The emphasis on conservation and a village ethos stands alongside a commitment to luxurious accommodation, fine cuisine and exceptional service. In 1993, Londolozi became the first game reserve in the world to be accorded Relais & Châteaux status.\n\nEach of the five exquisite Londolozi camps offer luxury accommodation, stunning views, and exceptional cuisine. All camps offer the same access to 34,000 acres of the best big five game viewing in Africa, led by some of the continent’s finest rangers and trackers.\n\nLondolozi is situated on the Sand River at the heart of the Sabi Sand Game Reserve within the Greater Kruger National Park.",
    "brand": "",
    "blockQuote": "Two of only 17 master trackers reside at this resort so you'll enjoy a top notch safari experience.",
    "emailAddress": "reservations@londolozi.co.za",
    "website": "londolozi.com/en/",
    "bookingURL": "https://www.londolozi.com/en/enquire/#booking",
    "redirectNotes": "Londolozi Private Game Reserve's reservations system",
    "phoneNumber": "+27 11 280 6655",
    "addressOne": "",
    "addressTwo": "",
    "city": "Sabi Sand",
    "county": "",
    "region": "Greater Kruger",
    "country": "South Africa",
    "rooms": 32,
    "dollar": 3,
    "updated": "2021-03-04T12:36:50.937",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 45876,
        "title": "Unique Sleeps: Londolozi, South Africa | Expedia.com.au",
        "url": "https://pkslist.azureedge.net/media/images/1/06650367-6286-464f-b42a-4227de09a5a6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/06650367-6286-464f-b42a-4227de09a5a6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/06650367-6286-464f-b42a-4227de09a5a6/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 45880,
        "title": "Londolozi Game Reserve - Luxury Safari Lodges | Rhino Africa",
        "url": "https://pkslist.azureedge.net/media/images/1/8674795f-2487-4a66-8f75-84404990aee9/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8674795f-2487-4a66-8f75-84404990aee9/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8674795f-2487-4a66-8f75-84404990aee9/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 45871,
        "title": "Londolozi Private Game Reserve, Sabi Sand Game Reserve, South ...",
        "url": "https://pkslist.azureedge.net/media/images/1/dc6245b5-dac2-4920-90ab-36ac39c64b7d/1200x900.png",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/dc6245b5-dac2-4920-90ab-36ac39c64b7d/800x600.png",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/dc6245b5-dac2-4920-90ab-36ac39c64b7d/256x192.png",
        "viewOrder": 2
      },
      {
        "mediaId": 45872,
        "title": "Londolozi Private Game Reserve - One of a kind hotels",
        "url": "https://pkslist.azureedge.net/media/images/1/8916954b-4015-4745-8bfc-934a4068d30d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/8916954b-4015-4745-8bfc-934a4068d30d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/8916954b-4015-4745-8bfc-934a4068d30d/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 45877,
        "title": "LONDOLOZI PIONEER CAMP - Lodge Reviews & Price Comparison ...",
        "url": "https://pkslist.azureedge.net/media/images/1/287ecd3c-06bf-47fb-b143-c48db7ccdbb3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/287ecd3c-06bf-47fb-b143-c48db7ccdbb3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/287ecd3c-06bf-47fb-b143-c48db7ccdbb3/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 45868,
        "title": "Londolozi - Granite Suites - Londolozi Private Game Reserve ...",
        "url": "https://pkslist.azureedge.net/media/images/1/35180a55-1d78-4a07-8d8f-dcf1d8a58416/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/35180a55-1d78-4a07-8d8f-dcf1d8a58416/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/35180a55-1d78-4a07-8d8f-dcf1d8a58416/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 45874,
        "title": "Londolozi Private Granite Suites - Kruger Park | Rhino Africa",
        "url": "https://pkslist.azureedge.net/media/images/1/c7615616-a647-4081-9e63-396477ec3a05/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c7615616-a647-4081-9e63-396477ec3a05/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c7615616-a647-4081-9e63-396477ec3a05/256x192.jpeg",
        "viewOrder": 6
      },
      {
        "mediaId": 45885,
        "title": "londolozi private granite suites - Google Search | Luxury safari ...",
        "url": "https://pkslist.azureedge.net/media/images/1/ae9efbcb-9319-4a70-a377-cfb3d5b5e33d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ae9efbcb-9319-4a70-a377-cfb3d5b5e33d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ae9efbcb-9319-4a70-a377-cfb3d5b5e33d/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 45878,
        "title": "Londolozi Private Game Reserve on Instagram: “A Private Granite ...",
        "url": "https://pkslist.azureedge.net/media/images/1/f7f0800b-1b2d-46cf-954c-9e7babbec41a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f7f0800b-1b2d-46cf-954c-9e7babbec41a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f7f0800b-1b2d-46cf-954c-9e7babbec41a/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 45883,
        "title": "Londolozi Private Game Reserve - One of a kind hotels",
        "url": "https://pkslist.azureedge.net/media/images/1/da4e6ea1-0e77-4b35-a81e-e3c21e505d59/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/da4e6ea1-0e77-4b35-a81e-e3c21e505d59/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/da4e6ea1-0e77-4b35-a81e-e3c21e505d59/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 45887,
        "title": "Varty Camp - Luxury Superior Chalets | Londolozi Game Reserve ...",
        "url": "https://pkslist.azureedge.net/media/images/1/578ee7bf-096e-4b70-b189-6934c1cb067d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/578ee7bf-096e-4b70-b189-6934c1cb067d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/578ee7bf-096e-4b70-b189-6934c1cb067d/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 45870,
        "title": "Londolozi Pioneer Camp: Luxury Safari in Sabi Sand | The Luxe Voyager",
        "url": "https://pkslist.azureedge.net/media/images/1/6b3a7eb3-925f-4fc4-93c1-b3996be653e8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6b3a7eb3-925f-4fc4-93c1-b3996be653e8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6b3a7eb3-925f-4fc4-93c1-b3996be653e8/256x192.jpg",
        "viewOrder": 11
      }
    ],
    "geographicArea": {
      "geographicAreaId": 265,
      "latitude": -24.797097,
      "longitude": 31.499004000000014,
      "parentGeographicAreaId": 0,
      "title": "Londolozi, Sabi Sands Game Reserve, 1350, South Africa",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 73,
        "tagName": "Outdoor Terraces",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 135,
        "tagName": "Bird Watching",
        "tagType": 2
      },
      {
        "tagId": 164,
        "tagName": "Excursions",
        "tagType": 2
      },
      {
        "tagId": 269,
        "tagName": "Photography",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 317,
        "tagName": "Gourmet",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 66,
        "venueCategoryId": 13,
        "venueId": 415,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 414,
    "venueCategoryId": 20,
    "status": 1,
    "title": "Singita Lebombo Lodge",
    "description": "This dramatic lodge, comprises 13 suites and one private-use villa that give guests a front row seat to the Kruger National Park with bold, contemporary open plan suites suspended above the N’Wanetsi River. The exclusive concession is a richly diverse habitat, teeming with game, beneath endless African skies.\n\nOther highlights of the lodge include a roof terrace and wine studio (a multi-level retreat which boasts a library, multi-media room, wine studio and rooftop viewing area), an interactive kitchen, an espresso bar and help-yourself deli, and an additional 25-metre lap pool. All communal areas of the lodge have been pushed out to ensure that the outside bursts in, offering elegant, calm and meditative spaces immersed in a dramatic landscape of giant euphorbia trees.",
    "brand": "Singita",
    "blockQuote": "A 100% solar powered, incredibly chic and stylish safari experience.",
    "emailAddress": "enquiries@singita.com",
    "website": "singita.com/lodge/singita-lebombo-lodge/",
    "bookingURL": "https://availability.singita.com/singita/Trip-Planner/Lodging",
    "redirectNotes": "",
    "phoneNumber": "+27 13 735 5500",
    "addressOne": "",
    "addressTwo": "",
    "city": "Kruger Park",
    "county": "",
    "region": "Greater Kruger",
    "country": "South Africa",
    "rooms": 15,
    "dollar": 3,
    "updated": "2020-05-17T07:39:06.2",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 36184,
        "title": "Singita Lebombo's soul-restoring beauty | Lodges and Camps | Singita",
        "url": "https://pkslist.azureedge.net/media/images/1/b089edd3-ae2f-4915-8efd-59cd027fa422/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b089edd3-ae2f-4915-8efd-59cd027fa422/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b089edd3-ae2f-4915-8efd-59cd027fa422/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 36182,
        "title": "Singita Lebombo Lodge - Kruger National Park | Rhino Africa",
        "url": "https://pkslist.azureedge.net/media/images/1/fb954db8-cf91-4c94-b467-e75db1317ffa/1200x900.jpeg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fb954db8-cf91-4c94-b467-e75db1317ffa/800x600.jpeg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fb954db8-cf91-4c94-b467-e75db1317ffa/256x192.jpeg",
        "viewOrder": 1
      },
      {
        "mediaId": 2617,
        "title": "Enjoying Amazing Natural Landscape at Singita Lebombo Lodge, South ...",
        "url": "https://pkslist.azureedge.net/media/images/1/db4627b0-0edf-4c8c-bf67-06b5db04a6b2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/db4627b0-0edf-4c8c-bf67-06b5db04a6b2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/db4627b0-0edf-4c8c-bf67-06b5db04a6b2/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 2619,
        "title": "Singita Lebombo Lodge - XO Private",
        "url": "https://pkslist.azureedge.net/media/images/1/62a492de-1e9e-445e-8cd0-c1ba8ed50dcc/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/62a492de-1e9e-445e-8cd0-c1ba8ed50dcc/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/62a492de-1e9e-445e-8cd0-c1ba8ed50dcc/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 36180,
        "title": "Singita Lebombo Lodge - Evolved | Lodges and Camps | Singita",
        "url": "https://pkslist.azureedge.net/media/images/1/b0cc4daf-bf34-43cc-854b-eacb5da0eea5/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b0cc4daf-bf34-43cc-854b-eacb5da0eea5/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b0cc4daf-bf34-43cc-854b-eacb5da0eea5/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 36187,
        "title": "For The Soul-Restoring Place Look No Further Than Singita Lebombo ...",
        "url": "https://pkslist.azureedge.net/media/images/1/b76788e2-2961-4f7b-9fe7-37c7b3cf68ba/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b76788e2-2961-4f7b-9fe7-37c7b3cf68ba/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b76788e2-2961-4f7b-9fe7-37c7b3cf68ba/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 36181,
        "title": "A kaleidoscope of contrasts: Singita's Southern African journey ...",
        "url": "https://pkslist.azureedge.net/media/images/1/b99f7c26-20db-4921-8684-2b2a6ad3ceca/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b99f7c26-20db-4921-8684-2b2a6ad3ceca/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b99f7c26-20db-4921-8684-2b2a6ad3ceca/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 2620,
        "title": "Singita Lebombo Lodge - Haute Grandeur",
        "url": "https://pkslist.azureedge.net/media/images/1/cca611c2-6ed4-4787-89f2-ce3107c88b01/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cca611c2-6ed4-4787-89f2-ce3107c88b01/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cca611c2-6ed4-4787-89f2-ce3107c88b01/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 2616,
        "title": "Singita-Lebombo-Lodge-Nexus-Travel-Solutions-Luxury-Bespoke ...",
        "url": "https://pkslist.azureedge.net/media/images/1/f2b9a781-0b9b-4548-ba80-b238277e6af3/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f2b9a781-0b9b-4548-ba80-b238277e6af3/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f2b9a781-0b9b-4548-ba80-b238277e6af3/256x192.jpg",
        "viewOrder": 8
      }
    ],
    "geographicArea": {
      "geographicAreaId": 264,
      "latitude": -24.4520529,
      "longitude": 31.97922029999995,
      "parentGeographicAreaId": 0,
      "title": "N'wanetsi River, Kruger National Park, Mpumalanga, Kruger Park, 1350, South Africa",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 47,
        "tagName": "Intimate Luxury ",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 134,
        "tagName": "Wilderness Walks",
        "tagType": 2
      },
      {
        "tagId": 135,
        "tagName": "Bird Watching",
        "tagType": 2
      },
      {
        "tagId": 207,
        "tagName": "Lounge/Bar",
        "tagType": 2
      },
      {
        "tagId": 243,
        "tagName": "Outdoor Activities",
        "tagType": 2
      },
      {
        "tagId": 269,
        "tagName": "Photography",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 317,
        "tagName": "Gourmet",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 65,
        "venueCategoryId": 20,
        "venueId": 414,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 20,
          "title": "BucketList Hotels",
          "region": "Global",
          "viewOrder": 0,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      },
      {
        "venueCategoryAssignId": 341,
        "venueCategoryId": 13,
        "venueId": 414,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 413,
    "venueCategoryId": 13,
    "status": 1,
    "title": "Singita Boulders Lodge",
    "description": "Singita Boulders Lodge is set along the banks of the Sand River in Singita's privately owned reserve within the Sabi Sand Reserve. The lodge is a celebration of the tranquillity, space, and light which flows throughout this incredibly vast area. Inspired by the geometry of the boulders on which it rests, Boulders Lodge is an inviting oasis where organic interiors integrate seamlessly with the raw African beauty outside.",
    "brand": "Singita",
    "blockQuote": "Your sophisticated \"man-cave\" in the bush.",
    "emailAddress": "enquiries@singita.com",
    "website": "singita.com/lodge/singita-boulders-lodge/",
    "bookingURL": "https://availability.singita.com/singita/Trip-Planner",
    "redirectNotes": null,
    "phoneNumber": "+27 13 735 9800",
    "addressOne": "",
    "addressTwo": "",
    "city": "Sabi Sand",
    "county": "",
    "region": "Greater Kruger",
    "country": "South Africa",
    "rooms": 12,
    "dollar": 3,
    "updated": "2020-05-17T07:30:34.213",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 36178,
        "title": "SINGITA BOULDERS LODGE - Reviews (South Africa/Singita Private ...",
        "url": "https://pkslist.azureedge.net/media/images/1/5064ddfa-6e21-48ef-94ff-c0bf41ba4fe8/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5064ddfa-6e21-48ef-94ff-c0bf41ba4fe8/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5064ddfa-6e21-48ef-94ff-c0bf41ba4fe8/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 36173,
        "title": "Singita Boulders Lodge, South Africa - Natural World Safaris",
        "url": "https://pkslist.azureedge.net/media/images/1/ac153816-1de5-4769-bd9e-e4ea8123732e/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/ac153816-1de5-4769-bd9e-e4ea8123732e/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/ac153816-1de5-4769-bd9e-e4ea8123732e/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 2605,
        "title": "Singita Boulders safari lodge, South Africa (Condé Nast Traveller)",
        "url": "https://pkslist.azureedge.net/media/images/1/58592455-da57-419f-bf56-9fff07531c1b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/58592455-da57-419f-bf56-9fff07531c1b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/58592455-da57-419f-bf56-9fff07531c1b/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 2599,
        "title": "Singita Boulders Lodge in Sabi Sands Game Reserve - Kruger ...",
        "url": "https://pkslist.azureedge.net/media/images/1/39611612-22fb-477a-ab94-de6298985444/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/39611612-22fb-477a-ab94-de6298985444/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/39611612-22fb-477a-ab94-de6298985444/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 36175,
        "title": "Singita Boulders Lodge, Kruger National Park,Sabi Sand Private ...",
        "url": "https://pkslist.azureedge.net/media/images/1/4ed6649a-c4e7-44c1-b0e2-c9a1deb5e131/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4ed6649a-c4e7-44c1-b0e2-c9a1deb5e131/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4ed6649a-c4e7-44c1-b0e2-c9a1deb5e131/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 36176,
        "title": "Singita Boulders Lodge by Luxe Travel",
        "url": "https://pkslist.azureedge.net/media/images/1/4d3da65b-3e81-4603-87ee-fb5853f2a220/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4d3da65b-3e81-4603-87ee-fb5853f2a220/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4d3da65b-3e81-4603-87ee-fb5853f2a220/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 2606,
        "title": "Singita-Boulders-Lodge-21 - Eye See Africa",
        "url": "https://pkslist.azureedge.net/media/images/1/4c509bf6-7352-435e-9f4f-1b5361de4473/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4c509bf6-7352-435e-9f4f-1b5361de4473/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4c509bf6-7352-435e-9f4f-1b5361de4473/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 2603,
        "title": "Singita Boulders Lodge : Sabi Sand Reserve, South Africa - YouTube",
        "url": "https://pkslist.azureedge.net/media/images/1/a53fc9bd-fd13-44ae-8b8f-3911b95f2cac/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a53fc9bd-fd13-44ae-8b8f-3911b95f2cac/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a53fc9bd-fd13-44ae-8b8f-3911b95f2cac/256x192.jpg",
        "viewOrder": 11
      }
    ],
    "geographicArea": {
      "geographicAreaId": 263,
      "latitude": -24.7864777,
      "longitude": 31.429697499999975,
      "parentGeographicAreaId": 0,
      "title": "Sabi Sands Game Reserve, Kruger National Park, Mpumalanga, Hazyview, 1242, South Africa",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 5,
        "tagName": "Billiards",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 60,
        "tagName": "Gastronomic",
        "tagType": 2
      },
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 70,
        "tagName": "Families",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 73,
        "tagName": "Outdoor Terraces",
        "tagType": 2
      },
      {
        "tagId": 93,
        "tagName": "Private",
        "tagType": 2
      },
      {
        "tagId": 156,
        "tagName": "Fireplace",
        "tagType": 2
      },
      {
        "tagId": 159,
        "tagName": "Mountain Biking",
        "tagType": 2
      },
      {
        "tagId": 219,
        "tagName": "Wine Cellar",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 64,
        "venueCategoryId": 13,
        "venueId": 413,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 409,
    "venueCategoryId": 13,
    "status": 1,
    "title": "Lion Sands Ivory Lodge",
    "description": "Lions Sands Ivory Lodge has taken their  cue from Mother Nature to create a lodge built ‘of the earth’, with natural materials which will only enhance the surrounding bush environment. The timeless design incorporates steel, wood, thatch, glass, water & sand. A sophisticated mid-century architectural contemporary theme defines the interiors, with bold furniture and edgy décor, ambient lighting, inferences of tribal design and highly textured finishes – all of which combine to make Lion Sands Ivory Lodge an utterly sensory experience.\n\nIvory Lodge’s interiors reflect an elegant balance between intelligent design and an organic expression of the earth. Light, sound, flow, wood, water and air weave into the golden thread of the lodge’s architectural inspiration.\n\nDespite being well grounded, there is an ‘otherwordly’ quality to Ivory Lodge, represented by the feather. Each of the Villas takes its name from an indigenous bird, and spectacular man-sized prints find pride of place throughout the lodge.",
    "brand": "",
    "blockQuote": "Stay a night in one of the two treehouses for a truly unique and super romantic experience.",
    "emailAddress": "mpt@more.co.za",
    "website": "lionsands.com/",
    "bookingURL": "http://www.booking.com/hotel/za/lion-sands-private-game-reserve.html?aid=925669",
    "redirectNotes": null,
    "phoneNumber": "+27 13 735 5000",
    "addressOne": "",
    "addressTwo": "",
    "city": "Sabi Sand",
    "county": "",
    "region": "Greater Kruger",
    "country": "South Africa",
    "rooms": 9,
    "dollar": 3,
    "updated": "2020-05-18T14:27:58.763",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 2562,
        "title": "90071.jpg",
        "url": "https://pkslist.azureedge.net/media/images/1/cba11f51-2c27-418b-a186-0ac60b5e25c1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/cba11f51-2c27-418b-a186-0ac60b5e25c1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/cba11f51-2c27-418b-a186-0ac60b5e25c1/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 2565,
        "title": "Ivory Lodge - Lion Sands",
        "url": "https://pkslist.azureedge.net/media/images/1/90f86d4d-5495-4e6a-9652-83010af3a500/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/90f86d4d-5495-4e6a-9652-83010af3a500/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/90f86d4d-5495-4e6a-9652-83010af3a500/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 2559,
        "title": "Lion Sands Ivory Lodge in Sabi Sands Game Reserve - Kruger ...",
        "url": "https://pkslist.azureedge.net/media/images/1/57003f42-08e5-4330-8a3f-a499a16bae20/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/57003f42-08e5-4330-8a3f-a499a16bae20/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/57003f42-08e5-4330-8a3f-a499a16bae20/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 2563,
        "title": "Lion Sands (Safari Lodges) - Special rates! :: EWTC",
        "url": "https://pkslist.azureedge.net/media/images/1/0d6be8e2-e5a1-4810-adc9-f9bfab9a6a02/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/0d6be8e2-e5a1-4810-adc9-f9bfab9a6a02/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/0d6be8e2-e5a1-4810-adc9-f9bfab9a6a02/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 2568,
        "title": "Lion Sands Ivory Lodge, Sabi Sands - Cedarberg Africa",
        "url": "https://pkslist.azureedge.net/media/images/1/a8b8901f-c4de-4cd3-9b60-956664c65316/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a8b8901f-c4de-4cd3-9b60-956664c65316/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a8b8901f-c4de-4cd3-9b60-956664c65316/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 2560,
        "title": "Lion Sands Ivory Lodge, Mpumalanga, South Africa",
        "url": "https://pkslist.azureedge.net/media/images/1/6c082b39-0c7c-4dfc-88e7-a896085f64d1/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6c082b39-0c7c-4dfc-88e7-a896085f64d1/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6c082b39-0c7c-4dfc-88e7-a896085f64d1/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 2567,
        "title": "Lion Sands Ivory Lodge | Sabi Sand Reserve - Safari Lodge ...",
        "url": "https://pkslist.azureedge.net/media/images/1/de5bfd80-125c-4f6c-9e43-9d9423573e73/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/de5bfd80-125c-4f6c-9e43-9d9423573e73/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/de5bfd80-125c-4f6c-9e43-9d9423573e73/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 2566,
        "title": "Hotel of the Week: Lion Sands, Ivory Lodge - WordSmith: The Mr ...",
        "url": "https://pkslist.azureedge.net/media/images/1/628d4517-58c4-4689-af24-fb04756dc1e7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/628d4517-58c4-4689-af24-fb04756dc1e7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/628d4517-58c4-4689-af24-fb04756dc1e7/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 2561,
        "title": "Lion Sands Ivory Lodge, Sabi Sands | Wanderforthâ ¢",
        "url": "https://pkslist.azureedge.net/media/images/1/c68a7932-92f1-43f7-aa38-01df71e3dd61/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/c68a7932-92f1-43f7-aa38-01df71e3dd61/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/c68a7932-92f1-43f7-aa38-01df71e3dd61/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 2564,
        "title": "Lion Sands Ivory Lodge | Perfect Africa",
        "url": "https://pkslist.azureedge.net/media/images/1/3fe8cb68-3e00-46d1-b6d9-7a9ca9ab4e66/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/3fe8cb68-3e00-46d1-b6d9-7a9ca9ab4e66/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/3fe8cb68-3e00-46d1-b6d9-7a9ca9ab4e66/256x192.jpg",
        "viewOrder": 11
      }
    ],
    "geographicArea": {
      "geographicAreaId": 260,
      "latitude": -24.927939,
      "longitude": 31.524909999999977,
      "parentGeographicAreaId": 0,
      "title": "Portion 4, Shaws Gate, Kruger, 1350, South Africa",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 43,
        "tagName": "Design Slickster",
        "tagType": 1
      },
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 5,
        "tagName": "Billiards",
        "tagType": 2
      },
      {
        "tagId": 33,
        "tagName": "Luxury",
        "tagType": 2
      },
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 72,
        "tagName": "Bar(s)",
        "tagType": 2
      },
      {
        "tagId": 73,
        "tagName": "Outdoor Terraces",
        "tagType": 2
      },
      {
        "tagId": 134,
        "tagName": "Wilderness Walks",
        "tagType": 2
      },
      {
        "tagId": 135,
        "tagName": "Bird Watching",
        "tagType": 2
      },
      {
        "tagId": 263,
        "tagName": "Star Gazing",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 63,
        "venueCategoryId": 13,
        "venueId": 409,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 407,
    "venueCategoryId": 13,
    "status": 1,
    "title": "Royal Malewane",
    "description": "Royal Malewane is a luxury safari lodge in South Africa’s Greater Kruger National Park that exudes the romance and beauty of a bygone age while offering every modern comfort. The most qualified guiding team in Africa delivers an unrivaled wildlife experience providing fascinating insights into the complex ecosystems of the South African bushveld.\n\nThere are two accommodation options at Royal Malewane – Main Lodge which is more classic and grand in style and The Farmstead which is more intimate and contemporary.\n\nWhichever style you choose, our expert guides will bring you thrillingly close to the Big Five. Indulge in sensual spa treatments and let the passion of our chefs delight you with nightly feasts under the African stars.",
    "brand": "",
    "blockQuote": "You'll come for the big five but leave here a birder!",
    "emailAddress": "info@royalmalewane.com",
    "website": "theroyalportfolio.com/royal-malewane/overview/",
    "bookingURL": "http://www.booking.com/hotel/za/safari-lodge-royal-malewane.html?aid=925669",
    "redirectNotes": null,
    "phoneNumber": "+27 15 793 0150",
    "addressOne": "",
    "addressTwo": "",
    "city": "Thornybush",
    "county": "",
    "region": "Greater Kruger",
    "country": "South Africa",
    "rooms": 13,
    "dollar": 3,
    "updated": "2021-03-04T08:11:01.127",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 45865,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/93556e81-a945-4604-99a1-42e7265ce5f0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/93556e81-a945-4604-99a1-42e7265ce5f0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/93556e81-a945-4604-99a1-42e7265ce5f0/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 45860,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/e5a0ac3f-4ee2-44e5-9ef5-80921c3bea3a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e5a0ac3f-4ee2-44e5-9ef5-80921c3bea3a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e5a0ac3f-4ee2-44e5-9ef5-80921c3bea3a/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 45859,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/28141261-3e52-4159-bfcb-cb08ed21a494/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/28141261-3e52-4159-bfcb-cb08ed21a494/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/28141261-3e52-4159-bfcb-cb08ed21a494/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 45861,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/616788a3-a998-4f4a-b396-fb931c2561c6/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/616788a3-a998-4f4a-b396-fb931c2561c6/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/616788a3-a998-4f4a-b396-fb931c2561c6/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 45862,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/1ea8014c-6df9-4518-960f-f27fe39e65a7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1ea8014c-6df9-4518-960f-f27fe39e65a7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1ea8014c-6df9-4518-960f-f27fe39e65a7/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 45858,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/2017f918-2cb5-4a05-904f-0062caa3c24d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2017f918-2cb5-4a05-904f-0062caa3c24d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2017f918-2cb5-4a05-904f-0062caa3c24d/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 45856,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/2609c362-91f8-472f-a128-1215c187413f/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/2609c362-91f8-472f-a128-1215c187413f/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/2609c362-91f8-472f-a128-1215c187413f/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 45857,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/5afd5a4b-5972-4a29-a66a-388acfec7da2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5afd5a4b-5972-4a29-a66a-388acfec7da2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5afd5a4b-5972-4a29-a66a-388acfec7da2/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 45867,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/21faa2dd-63a6-4713-8560-da3a5a9b5229/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/21faa2dd-63a6-4713-8560-da3a5a9b5229/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/21faa2dd-63a6-4713-8560-da3a5a9b5229/256x192.jpg",
        "viewOrder": 9
      },
      {
        "mediaId": 45863,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/b02f3413-d098-476b-a4a7-f29933fabccb/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/b02f3413-d098-476b-a4a7-f29933fabccb/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/b02f3413-d098-476b-a4a7-f29933fabccb/256x192.jpg",
        "viewOrder": 10
      },
      {
        "mediaId": 45855,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/f8534f7f-1352-4888-a1b2-de5384f1815d/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/f8534f7f-1352-4888-a1b2-de5384f1815d/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/f8534f7f-1352-4888-a1b2-de5384f1815d/256x192.jpg",
        "viewOrder": 11
      },
      {
        "mediaId": 45866,
        "title": "New Image",
        "url": "https://pkslist.azureedge.net/media/images/1/1081ab80-dbb3-4ee8-80f4-e307fd579462/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/1081ab80-dbb3-4ee8-80f4-e307fd579462/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/1081ab80-dbb3-4ee8-80f4-e307fd579462/256x192.jpg",
        "viewOrder": 13
      }
    ],
    "geographicArea": {
      "geographicAreaId": 259,
      "latitude": -24.517525,
      "longitude": 31.16392099999996,
      "parentGeographicAreaId": 0,
      "title": "Avoca Road, Thornybush Private Game Reserve, Hoedspruit, 1380, South Africa",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      },
      {
        "tagId": 200,
        "tagName": "Rustic Elegance",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 87,
        "tagName": "Outdoor Pool",
        "tagType": 2
      },
      {
        "tagId": 135,
        "tagName": "Bird Watching",
        "tagType": 2
      },
      {
        "tagId": 193,
        "tagName": "Outdoor Dining",
        "tagType": 2
      },
      {
        "tagId": 263,
        "tagName": "Star Gazing",
        "tagType": 2
      },
      {
        "tagId": 269,
        "tagName": "Photography",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      },
      {
        "tagId": 296,
        "tagName": "Guided Walk",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 62,
        "venueCategoryId": 13,
        "venueId": 407,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  },
  {
    "venueId": 406,
    "venueCategoryId": 13,
    "status": 1,
    "title": "andBeyond Ngala Tented Camp",
    "description": "The romance of canvas, with big night sounds and flickering lanterns lighting the camp grounds, mingles with the simple sophistication of polished wooden decks, textured fabrics and clean lines.\n\nNgala is a true safari experience where guests go to bed to the sounds of lion calling, safely cosseted in modern tented suites and pampered with the warm hearted service that embodies the soul of Africa.\n\nCaught between the dense riverine forest and the sandy bed of the Timbavati, extensive viewing decks give onto the river, giving guests a frontline view of game wandering down to drink. A rim flow pool mimicks the contours of the river bed, inviting in for a refreshing dip. Plush armchairs beguile you to spend a lazy afternoon idly gazing into the river bed. At night, when tales of the day’s adventure are shared and compared, a roaring fireplace springs to life. The stirring scents of Africa’s famous spices waft up from a meal shared in a spectacular setting – among islands or reeds in the dry Timbavati riverbed, beneath the canopy of massive trees or on our expansive deck.",
    "brand": "andBeyond",
    "blockQuote": "Book a magical night sleeping under the stars in the Ngala Treehouse.",
    "emailAddress": "safaris@andBeyond.com",
    "website": "andbeyond.com/ngala-tented-camp/the-camp.htm",
    "bookingURL": "http://www.andbeyond.com/reservation/reservation.htm",
    "redirectNotes": null,
    "phoneNumber": "+27 15 793 0169",
    "addressOne": "",
    "addressTwo": "",
    "city": "Timbavati River",
    "county": "",
    "region": "Greater Kruger",
    "country": "South Africa",
    "rooms": 9,
    "dollar": 3,
    "updated": "2020-05-18T10:35:49.93",
    "created": "2020-05-06T12:20:00",
    "medias": [
      {
        "mediaId": 36344,
        "title": "andBeyond Ngala Tented Camp, South Africa. Review by TravelPlusStyle",
        "url": "https://pkslist.azureedge.net/media/images/1/028175b3-fb28-4e7b-b10f-7262d13bd6e7/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/028175b3-fb28-4e7b-b10f-7262d13bd6e7/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/028175b3-fb28-4e7b-b10f-7262d13bd6e7/256x192.jpg",
        "viewOrder": 0
      },
      {
        "mediaId": 36355,
        "title": "andBeyond Ngala Tented Camp, South Africa. Review by TravelPlusStyle",
        "url": "https://pkslist.azureedge.net/media/images/1/a3f720d1-6143-4b46-90c7-c555b69f049b/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/a3f720d1-6143-4b46-90c7-c555b69f049b/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/a3f720d1-6143-4b46-90c7-c555b69f049b/256x192.jpg",
        "viewOrder": 1
      },
      {
        "mediaId": 36352,
        "title": "andBeyond Ngala Tented Camp, South Africa. Review by TravelPlusStyle",
        "url": "https://pkslist.azureedge.net/media/images/1/e270afce-04e2-4296-99bd-5e82b3f49094/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e270afce-04e2-4296-99bd-5e82b3f49094/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e270afce-04e2-4296-99bd-5e82b3f49094/256x192.jpg",
        "viewOrder": 2
      },
      {
        "mediaId": 36347,
        "title": "andBeyond Ngala Safari Lodge | Kruger | Big Five | South Africa",
        "url": "https://pkslist.azureedge.net/media/images/1/4f4213ce-a401-47d4-ac18-b8b3351bfb03/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/4f4213ce-a401-47d4-ac18-b8b3351bfb03/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/4f4213ce-a401-47d4-ac18-b8b3351bfb03/256x192.jpg",
        "viewOrder": 3
      },
      {
        "mediaId": 36348,
        "title": "andBeyond Ngala Tented Camp, South Africa. Review by TravelPlusStyle",
        "url": "https://pkslist.azureedge.net/media/images/1/fef54072-71df-4b6e-a560-8e45e634388a/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/fef54072-71df-4b6e-a560-8e45e634388a/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/fef54072-71df-4b6e-a560-8e45e634388a/256x192.jpg",
        "viewOrder": 4
      },
      {
        "mediaId": 36351,
        "title": "andBeyond Ngala Tented Camp - Photos",
        "url": "https://pkslist.azureedge.net/media/images/1/5f5dd20e-6e26-44cd-b12a-2908e4114002/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/5f5dd20e-6e26-44cd-b12a-2908e4114002/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/5f5dd20e-6e26-44cd-b12a-2908e4114002/256x192.jpg",
        "viewOrder": 5
      },
      {
        "mediaId": 36345,
        "title": "andBeyond Ngala Tented Camp | Kruger National Park | Luxury Safari",
        "url": "https://pkslist.azureedge.net/media/images/1/e1f7d2ce-b92a-448c-be07-8740727ea795/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/e1f7d2ce-b92a-448c-be07-8740727ea795/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/e1f7d2ce-b92a-448c-be07-8740727ea795/256x192.jpg",
        "viewOrder": 6
      },
      {
        "mediaId": 36357,
        "title": "andBeyond Ngala Tented Camp | Kruger National Park | Luxury Safari",
        "url": "https://pkslist.azureedge.net/media/images/1/aeff87ac-d94a-4053-ae49-85844462c6fb/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/aeff87ac-d94a-4053-ae49-85844462c6fb/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/aeff87ac-d94a-4053-ae49-85844462c6fb/256x192.jpg",
        "viewOrder": 7
      },
      {
        "mediaId": 36356,
        "title": "andBeyond Ngala Safari Lodge | Kruger | Big Five | South Africa",
        "url": "https://pkslist.azureedge.net/media/images/1/6a71613a-90a8-4b45-a5eb-31a3759cfac0/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/6a71613a-90a8-4b45-a5eb-31a3759cfac0/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/6a71613a-90a8-4b45-a5eb-31a3759cfac0/256x192.jpg",
        "viewOrder": 8
      },
      {
        "mediaId": 36343,
        "title": "andBeyond Ngala Tented Camp | Kruger National Park | Luxury Safari",
        "url": "https://pkslist.azureedge.net/media/images/1/d496d841-9a8f-4bb9-9d2f-a9df4368e6d2/1200x900.jpg",
        "url_Medium": "https://pkslist.azureedge.net/media/images/1/d496d841-9a8f-4bb9-9d2f-a9df4368e6d2/800x600.jpg",
        "url_Low": "https://pkslist.azureedge.net/media/images/1/d496d841-9a8f-4bb9-9d2f-a9df4368e6d2/256x192.jpg",
        "viewOrder": 9
      }
    ],
    "geographicArea": {
      "geographicAreaId": 258,
      "latitude": -25.1563171,
      "longitude": 31.19730949999996,
      "parentGeographicAreaId": 0,
      "title": "South Africa",
      "updated": "2020-05-06T12:20:00",
      "created": "2020-05-06T12:20:00"
    },
    "personalities": [
      {
        "tagId": 50,
        "tagName": "Barefoot Luxury",
        "tagType": 1
      },
      {
        "tagId": 56,
        "tagName": "Quiet/Peaceful",
        "tagType": 1
      }
    ],
    "tags": [
      {
        "tagId": 4,
        "tagName": "Spa",
        "tagType": 2
      },
      {
        "tagId": 5,
        "tagName": "Billiards",
        "tagType": 2
      },
      {
        "tagId": 63,
        "tagName": "Safari",
        "tagType": 2
      },
      {
        "tagId": 73,
        "tagName": "Outdoor Terraces",
        "tagType": 2
      },
      {
        "tagId": 106,
        "tagName": "Nature",
        "tagType": 2
      },
      {
        "tagId": 133,
        "tagName": "Glamping",
        "tagType": 2
      },
      {
        "tagId": 134,
        "tagName": "Wilderness Walks",
        "tagType": 2
      },
      {
        "tagId": 193,
        "tagName": "Outdoor Dining",
        "tagType": 2
      },
      {
        "tagId": 263,
        "tagName": "Star Gazing",
        "tagType": 2
      },
      {
        "tagId": 287,
        "tagName": "Wildlife",
        "tagType": 2
      }
    ],
    "venueCategoryAssigns": [
      {
        "venueCategoryAssignId": 61,
        "venueCategoryId": 13,
        "venueId": 406,
        "venue": null,
        "venueCategory": {
          "venueCategoryId": 13,
          "title": "Wildlife Encounters",
          "region": "Global",
          "viewOrder": 4,
          "updated": "2020-05-06T12:20:00",
          "created": "2020-05-06T12:20:00",
          "venueCategoryAssign": [
            
          ]
        }
      }
    ]
  }
]